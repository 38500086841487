import { DigitsFilter } from '@community_dev/filter-dsl/lib/digits'
import {
  MemberDataFilter,
  RelationshipOperators,
  RelationshipFilter,
  FieldTypes,
  isBetweenFilter,
  isRelationshipFilter,
  isSelectorFilter,
  SelectorFilter,
  isRelationshipOperator,
  FieldDefinition,
} from '@community_dev/filter-dsl/lib/subscription-data'

import dayjs from 'utils/dayjs'

function adjustFilterToUTC(filter: MemberDataFilter): MemberDataFilter {
  if (isSelectorFilter(filter) && filter.operand.type === FieldTypes.DATETIME) {
    return {
      ...filter,
      operand: {
        ...filter.operand,
        value: dayjs(filter.operand.value).hour(0).minute(0).utc(true).toISOString(),
      },
    } as SelectorFilter
  }

  if (isRelationshipFilter(filter)) {
    return {
      ...filter,
      operands: filter.operands.map((operandFilter) => adjustFilterToUTC(operandFilter)),
    }
  }

  return filter
}

function adjustFilterToLocalTimezone(filter: MemberDataFilter): MemberDataFilter {
  if (isSelectorFilter(filter) && filter.operand.type === FieldTypes.DATETIME) {
    return {
      ...filter,
      operand: {
        ...filter.operand,
        value: dayjs(filter.operand.value).toISOString(),
      },
    } as SelectorFilter
  }

  if (isRelationshipFilter(filter)) {
    return {
      ...filter,
      operands: filter.operands.map((operandFilter) => adjustFilterToUTC(operandFilter)),
    }
  }

  return filter
}

// de-nests relationship filters with a single operand
function flattenFilterCollection(filter: MemberDataFilter): MemberDataFilter {
  const isRelationship = isRelationshipFilter(filter)

  if (
    isRelationship &&
    isRelationshipOperator(filter.operator) &&
    ([RelationshipOperators.OR, RelationshipOperators.AND] as RelationshipOperators[]).includes(filter.operator) &&
    filter.operands.length === 1
  ) {
    return flattenFilterCollection(filter.operands[0])
  }

  if (isRelationship) {
    return {
      ...filter,
      operands: filter.operands.map((operand) => flattenFilterCollection(operand)),
    }
  }

  return filter
}

export function serializeMemberDataFilters(filters: MemberDataFilter[]): DigitsFilter | null {
  if (filters.length === 0) {
    return null
  }

  if (filters.length === 1 && isRelationshipFilter(filters[0])) {
    const adjustedFilter = flattenFilterCollection(adjustFilterToUTC(filters[0]))

    if (adjustedFilter.operator === RelationshipOperators.AND) {
      return { subscription_data: adjustedFilter }
    }

    return {
      subscription_data: {
        operator: RelationshipOperators.AND,
        operands: [adjustedFilter],
      } as RelationshipFilter,
    }
  }

  return {
    subscription_data: {
      operator: RelationshipOperators.AND,
      operands: filters.map((filter) => flattenFilterCollection(adjustFilterToUTC(filter))),
    },
  }
}

export function deserializeMemberDataFilters(memberDataQuery: DigitsFilter): MemberDataFilter[] {
  if (memberDataQuery.subscription_data && isBetweenFilter(memberDataQuery.subscription_data)) {
    return [adjustFilterToLocalTimezone(memberDataQuery.subscription_data)]
  }

  if (memberDataQuery.subscription_data && isRelationshipFilter(memberDataQuery.subscription_data)) {
    return memberDataQuery.subscription_data.operands.map((filter) => adjustFilterToLocalTimezone(filter))
  }

  return []
}

export function wrapSingleFilter(filter: MemberDataFilter): MemberDataFilter {
  if (isSelectorFilter(filter)) {
    return {
      operator: RelationshipOperators.AND,
      operands: [filter],
    }
  }

  return filter
}

export function unwrapSingleFilter(filter: MemberDataFilter): MemberDataFilter {
  if (isRelationshipFilter(filter) && filter.operands.length === 1 && filter.operator !== RelationshipOperators.NOT) {
    filter = filter.operands[0]
  }

  return filter
}

export function findDropdownFields(fields: FieldDefinition[]): FieldDefinition[] {
  return fields.filter((field) => field.value_type === FieldTypes.STRING && field.value_options?.length)
}

export function createFieldLookup(fields: FieldDefinition[]): Record<string, FieldDefinition> {
  return fields.reduce((acc, field) => ({ ...acc, [`${field.source}-${field.key}`]: field }), {})
}

export function hasDropdownFilter(filter: MemberDataFilter, fields: FieldDefinition[]): boolean {
  const fieldLookup = createFieldLookup(findDropdownFields(fields))

  if (isSelectorFilter(filter)) {
    return !!fieldLookup[`${filter.operand.source}-${filter.operand.field_key}`]
  }

  if (isRelationshipFilter(filter)) {
    return filter.operands.some((operand) => hasDropdownFilter(operand, fields))
  }

  return false
}
