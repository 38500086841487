import { MemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useEffect, useState } from 'react'

import { AddToCommunities } from '../AddToCommunities/AddToCommunities'

import { Community } from 'api/community'
import { useClientId } from 'hooks/useClient'
import { useCommunity } from 'hooks/useCommunity'

export function AddToCommunitiesWithFilters({
  communicationChannel,
  filters,
  recipientCount,
}: {
  communicationChannel: CommunicationChannel
  filters: MemberDataFilter
  recipientCount: number
}): JSX.Element {
  const [added, setAdded] = useState<string[]>([])
  const clientId = useClientId()
  const { assignMembersToCommunity } = useCommunity({
    clientId,
  })

  useEffect(() => {
    setAdded([])
  }, [filters])

  const handleAdd = (community: Community, fanSubscriptionTags?: any): void => {
    assignMembersToCommunity({
      communicationChannel,
      recipientCount,
      tagId: community.id,
      filters,
      fanSubscriptionTags,
      community,
    })
    setAdded([...added, community.id])
  }

  function handleCreateCommunity(community: Community, fanSubscriptionTags?: any): void {
    assignMembersToCommunity({
      communicationChannel,
      recipientCount,
      tagId: community.id,
      filters,
      fanSubscriptionTags,
      community,
    })
  }

  function checkUserAdded(community: Community): boolean {
    return added.includes(community.id)
  }

  return (
    <AddToCommunities checkUserAdded={checkUserAdded} onAdd={handleAdd} onCommunityCreated={handleCreateCommunity} />
  )
}
