import { parseFilters, findOne, BirthdayMatch } from '@community_dev/filter-dsl/lib/subscription-data'
import { SPACING, Tooltip, InfoIcon, withErrorBoundary, Skeleton } from '@community_dev/pixels'
import { convertKeysToSnakeCase } from '@community_dev/requests'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import SuggestionCards from 'components/SuggestionCards'
import { useInbox } from 'contexts/InboxProvider'
import { useBirthdayCount } from 'hooks/useCountByQuery/useBirthdayCount'
import Sentry from 'integrations/Sentry'
import { useCampaignList } from 'screens/Campaigns/hooks/useCampaignList'
import { useScheduledCampaigns } from 'screens/Scheduled/useScheduledCampaigns'
import dayjs from 'utils/dayjs'
import { SuggestionCardTemplate, templates } from 'utils/suggestion-helpers'

const StyledHomeSuggestion = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${SPACING[5]} ${SPACING[4]} ${SPACING[4]};

  > div {
    width: 100%;
    // Same as FeaturedAddOns
    max-width: 720px;
  }
`

const CommunityCaresWrapper = styled.div`
  margin-bottom: ${SPACING[7]};
`
const IconWrapper = styled.span`
  svg {
    vertical-align: middle;
  }
`

function useHomeSuggestions() {
  const currentDate = dayjs().format('YYYY-MM-DD')

  const birthday = useBirthdayCount({ traceId: 'home-suggestion-birthday' })
  const sentCampaigns = useCampaignList()
  const scheduledCampaigns = useScheduledCampaigns()
  const inbox = useInbox()

  const loading = birthday.isLoading || sentCampaigns.isLoading || scheduledCampaigns.isLoading || inbox.isLoading
  const history = useHistory()

  // build unread fan card
  const unreadCard = useMemo(() => {
    if (inbox.items) {
      const item = inbox.items.find((item) => item.unread === true)
      if (item) {
        return templates.unreadFan(
          {
            firstName: item.fan.firstName,
            id: item.fan.id,
          },
          history,
        )
      }
    }
  }, [inbox.items, history])

  // build birthday card
  const birthdayCard = useMemo(() => {
    const allCampaigns = [...(sentCampaigns?.data?.pages || []), ...(scheduledCampaigns.campaigns || [])]
    let birthdayMessageSent = false

    allCampaigns.forEach((campaign) => {
      const dateOfMessage = dayjs(campaign.createdAt).format('YYYY-MM-DD')

      if (dateOfMessage === currentDate) {
        const filtersAst = parseFilters(convertKeysToSnakeCase(campaign.filters?.subscriptionData ?? null))
        const birthdayFilter = findOne(filtersAst, BirthdayMatch)
        if (birthdayFilter) {
          birthdayMessageSent = true
        }
      }
    })

    const count = birthday.data?.count || 0

    if (!birthdayMessageSent && count > 0) {
      return templates.birthday({
        count,
        ts: currentDate,
      })
    }
  }, [scheduledCampaigns, sentCampaigns, currentDate])

  // build last sent card
  const lastSentCard = useMemo(() => {
    try {
      const sent = sentCampaigns[0]
      const { id, text, media, placeholders } = sent || {}
      if (!id || (!text && !(media && media.mime_type))) return
      if (
        text &&
        placeholders &&
        placeholders.length > 0 &&
        placeholders.some((p) => text.includes(`{${p.key}:${p.source}}`))
      ) {
        const matchingPlaceholders = placeholders.filter((p) => text.includes(`{${p.key}:${p.source}}`))
        if (matchingPlaceholders.length > 0) {
          return templates.lastSent(
            {
              id,
              media,
              body: matchingPlaceholders.reduce((t, ph) => t.replace(`{${ph.key}:${ph.source}}`, `{${ph.name}}`), text),
            },
            history,
          )
        }
      }
      return templates.lastSent({ id, media, body: text }, history)
    } catch (e) {
      return undefined
    }
  }, [sentCampaigns, history])

  // build promote number card
  const promoteNumberCard = templates.promoteNumber(history)

  const items: SuggestionCardTemplate[] = [birthdayCard, lastSentCard, unreadCard, promoteNumberCard].filter(
    (item) => typeof item === 'object',
  )

  return { items, loading }
}

function HomeSuggestion(): JSX.Element {
  const { t } = useTranslation()

  const { items, loading } = useHomeSuggestions()

  const communityCaresCards = items?.filter((i) => i.type === 'custom_community_cares_Compose')
  const suggestionCards = items?.filter((i) => i.type !== 'custom_community_cares_Compose')

  return (
    <StyledHomeSuggestion>
      <div>
        {communityCaresCards && communityCaresCards.length > 0 && (
          <CommunityCaresWrapper>
            <h2>
              {t('communityCares.header')}{' '}
              <Tooltip content={t('communityCares.tooltip')} maxWidth={265} placement="bottom">
                <IconWrapper>
                  <InfoIcon size={18} />
                </IconWrapper>
              </Tooltip>
            </h2>
            <SuggestionCards items={communityCaresCards} />
          </CommunityCaresWrapper>
        )}
        <h2>Things To Do</h2>
        <Skeleton borderRadius="16px" height="72px" loading={loading} marginBottom="8px" width="100%">
          <SuggestionCards items={suggestionCards.slice(0, 3)} />
        </Skeleton>
        <Skeleton borderRadius="16px" height="72px" loading={loading} marginBottom="8px" width="100%" />
        <Skeleton borderRadius="16px" height="72px" loading={loading} width="100%" />
      </div>
    </StyledHomeSuggestion>
  )
}

export default withErrorBoundary({
  ErrorComponent: null,
  onCatch: (e) => Sentry.captureException(e),
})(HomeSuggestion)
