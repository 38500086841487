export enum BRAND_TYPE {
  STANDARD = 'standard',
  STARTER = 'starter',
}

export enum PAY_BY {
  CREDIT_CARD = 'credit_card',
  INVOICE = 'invoice',
  FREE = 'free',
}

export enum STATUS {
  APPROVED = 'approved',
  FAILED = 'failed',
  SUBMITTED = 'submitted',
  NOT_SUBMITTED = 'not_submitted',
  ADDITIONAL_DATA_COLLECTION_REQUIRED = 'additional_data_collection_required',
  UNREGISTERED = 'unregistered',
}

export enum BusinessRegistrationIdentifier {
  EIN = 'EIN',
  CBN = 'CBN',
}
