import { usePrevious } from '@community_dev/hooks'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { TCR_SKIPPED } from 'constants/web-storage'
import { useClientId } from 'hooks/useClient'
import { useTCRRegistrationData, useTCRSeen } from 'hooks/useTCR'

export const TCRContext = createContext({})

TCRContext.displayName = 'TCRContext'

export function useTCRContext(): any {
  const context = useContext(TCRContext)
  if (context === undefined) {
    throw new Error('useTCRContext must be used within a TCRProvider')
  }
  return context
}

type TCRProviderProps = {
  children?: React.ReactNode
}

export function TCRProvider({ children }: TCRProviderProps): JSX.Element {
  const clientId = useClientId()
  const prevClientId = usePrevious(clientId)
  const [, setSkippedState] = useState<boolean>(() => sessionStorage.getItem(TCR_SKIPPED) === 'true')
  const { data: tcrDetails, ...rest } = useTCRRegistrationData()
  const { mutateAsync: setSeen } = useTCRSeen()

  const skipped = sessionStorage.getItem(`${TCR_SKIPPED}-${clientId}`) === 'true'

  const setSkipped = useCallback(
    (value?: boolean, client = clientId): void => {
      if (value) {
        sessionStorage.setItem(`${TCR_SKIPPED}-${client}`, 'true')
      } else {
        sessionStorage.removeItem(`${TCR_SKIPPED}-${client}`)
      }
      setSkippedState(value || false)
    },
    [clientId],
  )

  useEffect(() => {
    if (prevClientId && prevClientId !== clientId && skipped) {
      setSkipped(false, prevClientId)
    }
  }, [clientId, prevClientId, skipped, setSkipped])

  const status = skipped ? 'skipped' : tcrDetails?.data?.status
  const values = {
    ...rest,
    ...tcrDetails?.data,
    status,
    setSkipped,
    setSeen,
  }

  return <TCRContext.Provider value={values}>{children}</TCRContext.Provider>
}
