import { SPACING, Typography } from '@community_dev/pixels'
import { useTranslation, Trans } from 'react-i18next'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'

import { SetLiveIcon } from './components/Icons/SetLiveIcon'

import { ROUTES } from 'constants/routes'
import { Setting } from 'layouts/SettingsLayout'

export function SetLive(): JSX.Element {
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Setting
      action={t('settings.integrations.instructions')}
      icon={<SetLiveIcon size={22} />}
      label={t('settings.integrations.setLive.title')}
      onClickAction={() => {
        history.push(ROUTES.SETTINGS.INTEGRATIONS.SETLIVE)
      }}
      text={
        <Typography color={COLORS.SUBTEXT} marginTop={SPACING[0]} variant="body2">
          <Trans i18nKey="settings.integrations.setLive.description"></Trans>
        </Typography>
      }
    />
  )
}
