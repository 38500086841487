import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { getCarrierUsage } from 'api/tcr'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

type CarrierUsage = {
  isLimitReached: boolean
  quotaResetsAt?: string
}

export function useCarrierUsage(): CarrierUsage {
  const clientId = useClientId()
  const [isLimitReached, setIsLimitReached] = useState(false)
  const [quotaResetsAt, setQuotaResetsAt] = useState('')
  const { data } = useQuery([QUERY_CACHE.TCR.CARRIER_USAGE, clientId], () => getCarrierUsage({ clientId }), {
    onSuccess: (data) => {
      // T-Mobile is the only carrier we care about for now. When we support more we'll need to update this logic
      if (data?.data[0] && data?.data[0].carrier === 'T-Mobile' && data?.data[0].percentUsed >= 100) {
        setIsLimitReached(true)
        setQuotaResetsAt(data?.data[0].quotaResetsAt)
      }
    },
    enabled: Boolean(clientId),
    refetchOnWindowFocus: true,
    refetchInterval: false,
  })

  if (!data) {
    return {
      isLimitReached,
    }
  }

  return {
    isLimitReached,
    quotaResetsAt,
  }
}
