import { BORDER_RADIUS, Layout, SPACING, Typography } from '@community_dev/pixels'
import { WorkflowType } from '@community_dev/workflow-manifest'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
import styled from 'styled-components'

import EditFlowSettingsIcon from '../../svg/edit-flow.svg?react'
import FlowPerformanceIcon from '../../svg/flow-performance.svg?react'

import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { ROUTES } from 'constants/routes'
import { route } from 'utils/router'

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
  padding: ${SPACING[4]} ${SPACING[4]} ${SPACING[3]} ${SPACING[4]};
  background: none;
  border-radius: ${BORDER_RADIUS[1]};
  cursor: pointer;
  margin-right: ${SPACING[1]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  text-decoration: none;
  &.active {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  }
  &:hover {
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  }
`

export function WorkflowSidebarTabs(): JSX.Element {
  const { t } = useTranslation()
  const { workflowId } = useParams<{ workflowId: string }>()
  const { workflow } = useWorkflowProvider()
  // in abandoned cart workflows, we display the performance tab by default
  // instead of the edit flow tab.
  const routeFlowPerformance =
    workflow?.type === WorkflowType.shopify_abandoned_checkout
      ? ROUTES.AUTOMATIONS.FLOW_EDIT
      : ROUTES.AUTOMATIONS.FLOW_PERFORMANCE
  return (
    <Layout display="flex">
      {workflow?.type !== WorkflowType.shopify_abandoned_checkout && (
        <StyledNavLink
          aria-label={t('automations.openWorkflowEditFlowTab')}
          to={route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId })}
        >
          <EditFlowSettingsIcon height="16" />
          <Typography component="span" fontSize="14px" fontWeight="600" margin="3px 0 0 0">
            {t('automations.editFlow')}
          </Typography>
        </StyledNavLink>
      )}
      <StyledNavLink
        aria-label={t('automations.openWorkflowPerformanceTab')}
        exact
        to={route(routeFlowPerformance, { workflowId })}
      >
        <FlowPerformanceIcon height="16" />
        <Typography component="span" fontSize="14px" fontWeight="600" margin="3px 0 0 0">
          {t('automations.analytics')}
        </Typography>
      </StyledNavLink>
    </Layout>
  )
}
