import React from 'react'

type ShopifyIconProps = {
  size?: number
}

export const ShopifyIcon = ({ size = 24 }: ShopifyIconProps): JSX.Element => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9173 6.46064C17.9044 6.36993 17.8266 6.3181 17.7618 6.3181C17.697 6.3181 16.4141 6.29218 16.4141 6.29218C16.4141 6.29218 15.3386 5.25551 15.2349 5.13888C15.1313 5.03522 14.9239 5.06113 14.8462 5.08705C14.8462 5.08705 14.6388 5.15184 14.3019 5.25551C14.2501 5.07409 14.1594 4.8538 14.0428 4.62055C13.667 3.89488 13.0968 3.50613 12.423 3.50613C12.3711 3.50613 12.3322 3.50613 12.2804 3.51908C12.2545 3.49317 12.2415 3.46725 12.2156 3.45429C11.9176 3.14329 11.5418 2.98779 11.0882 3.00075C10.22 3.02667 9.35182 3.66163 8.6391 4.77605C8.14669 5.56651 7.77089 6.55135 7.65427 7.31589C6.65647 7.62689 5.95672 7.84719 5.9308 7.84719C5.42542 8.00269 5.41246 8.01565 5.34767 8.49511C5.32176 8.85794 4 19.0821 4 19.0821L15.0924 21L19.8999 19.8078C19.887 19.8078 17.9303 6.55135 17.9173 6.46064ZM13.7447 5.43693C13.4855 5.51468 13.2005 5.60539 12.8895 5.70905C12.8895 5.26847 12.8247 4.64646 12.6303 4.12813C13.2912 4.2318 13.6151 4.98338 13.7447 5.43693ZM12.3063 5.87751C11.7232 6.05893 11.0882 6.2533 10.4533 6.44768C10.6347 5.76089 10.9716 5.08705 11.3863 4.63351C11.5418 4.46505 11.7621 4.28363 12.0083 4.17996C12.2675 4.68534 12.3193 5.39805 12.3063 5.87751ZM11.1142 3.58388C11.3215 3.58388 11.49 3.62275 11.6325 3.72642C11.3992 3.84304 11.166 4.02446 10.9587 4.25771C10.4014 4.8538 9.97382 5.77384 9.80536 6.66797C9.27407 6.83643 8.75573 6.99193 8.28923 7.13448C8.60023 5.72201 9.77944 3.62275 11.1142 3.58388Z"
        fill="#95BF47"
      />
      <path
        d="M17.7617 6.31822C17.6969 6.31822 16.414 6.29231 16.414 6.29231C16.414 6.29231 15.3385 5.25564 15.2348 5.13901C15.196 5.10014 15.1441 5.07422 15.0923 5.07422V21.0001L19.8998 19.8079C19.8998 19.8079 17.9431 6.55147 17.9302 6.46077C17.9043 6.37006 17.8265 6.31822 17.7617 6.31822Z"
        fill="#5E8E3E"
      />
      <path
        d="M12.8434 8.81728L12.2862 10.9036C12.2862 10.9036 11.6642 10.6185 10.9256 10.6703C9.83709 10.7351 9.83709 11.4219 9.83709 11.5904C9.90188 12.5234 12.351 12.7307 12.4936 14.9207C12.5972 16.6441 11.5865 17.8234 10.1092 17.9141C8.34688 18.0048 7.375 16.9681 7.375 16.9681L7.75079 15.3742C7.75079 15.3742 8.73563 16.1129 9.51313 16.061C10.0185 16.0351 10.2129 15.6075 10.187 15.3224C10.1092 14.1043 8.11363 14.1821 7.98404 12.1735C7.88038 10.4889 8.98184 8.79136 11.418 8.63586C12.364 8.57107 12.8434 8.81728 12.8434 8.81728Z"
        fill="white"
      />
    </svg>
  )
}
