import { SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledFilterModalFooter = styled.div`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  border-top: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  display: flex;
  padding: ${SPACING[4]};
  justify-content: flex-end;
  align-items: center;
`

export const StyledFilterModalCount = styled.div`
  margin-right: 16px;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  letter-spacing: 0.16px;
  font-weight: 500;
  font-size: 12px;
`
