import { useHasCapability } from './useUserCapability'

import { CAPABILITIES } from 'constants/capabilities'

// permissions matrix doc for CMD:
// https://community-com.quip.com/T88wAtjqIOG2/CMD-permissions-matrix

type UseMemberDataPermissionsReturn = {
  canReadHistory: boolean
  canReadMemberData: boolean
  canPollHistory: boolean
  canUpload: boolean
  canFilter: boolean
}

export function useMemberDataPermissions(): UseMemberDataPermissionsReturn {
  const isAdmin = useHasCapability(CAPABILITIES.GLOBAL.ADMIN.ALL)
  const isCMDEnabled = useHasCapability(CAPABILITIES.FEATURE.CUSTOM_MEMBER_DATA.ALL)

  const canRead = useHasCapability(CAPABILITIES.CLIENT.CUSTOM_MEMBER_DATA.READ) || isAdmin
  const canWrite = useHasCapability(CAPABILITIES.CLIENT.CUSTOM_MEMBER_DATA.WRITE)

  return {
    canReadHistory: isCMDEnabled && canRead,
    canReadMemberData: isCMDEnabled,
    canPollHistory: isCMDEnabled && canWrite && canRead,
    canUpload: isCMDEnabled && canWrite,
    canFilter: isCMDEnabled,
  }
}
