import { humanizeBuiltInFilterShortened } from '@community_dev/filter-dsl/lib/humanize/humanizeBuiltInFilters'
import {
  humanizeOperandValue,
  humanizeOperandValues,
} from '@community_dev/filter-dsl/lib/humanize/humanizeClientDataFilter'
import {
  BuiltInFields,
  FieldSources,
  getFieldKey,
  getFieldLabel,
  getFieldSource,
  isBetweenFilter,
  isCommunityFilter,
  isIndividualsFilter,
  isMemberDataFilter,
  isSelectorFilter,
  MemberDataFilter,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { Avatar, Layout, ListItem, SPACING, ThinkingFlag, Typography } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { getOperatorTranslation } from '../RecommendationsCustomFields/fields/utils'
import { StyledAddSuffix, StyledButton } from '../styled-recipients'

import { CommunityDot } from 'components/CommunityDot'
import { useActiveFilterTypes } from 'components/ComposeMessage/hooks/useActiveFilterTypes'
import { ROUTES } from 'constants/routes'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useCommunityLookup } from 'hooks/useCommunities'

const StyledHeading = styled.h3`
  padding-left: ${SPACING[4]};
  margin-top: ${SPACING[5]};
`

const IndividualRow = ({ individual, onRemove }) => {
  const { COLORS } = useTheme() || {}
  const name = `${individual.firstName} ${individual.lastName}`
  const history = useHistory()

  return (
    <ListItem
      as="div"
      data-testid={`member-${individual.id}`}
      label={name}
      onClick={() => history.push(ROUTES.RECIPIENT_PANE.INDIVIDUAL)}
      prefix={<Avatar name={name} />}
      subtext={[].filter(Boolean).join(' | ')}
      suffix={
        <StyledAddSuffix>
          <StyledButton $color={COLORS?.ERRORS} onClick={() => onRemove()}>
            Remove
          </StyledButton>
        </StyledAddSuffix>
      }
    ></ListItem>
  )
}

const CommunityRow = ({ community, onRemove }) => {
  const { COLORS } = useTheme() || {}
  const history = useHistory()

  return (
    <ListItem
      as="div"
      data-testid={`community-item-${community.id}`}
      label={community.title}
      onClick={() => history.push(ROUTES.RECIPIENT_PANE.COMMUNITY)}
      prefix={<CommunityDot color={community.color} icon={community.title === 'Favorites' ? 'star' : undefined} />}
      suffix={
        <StyledAddSuffix>
          <StyledButton $color={COLORS?.ERRORS} onClick={onRemove}>
            Remove
          </StyledButton>
        </StyledAddSuffix>
      }
    ></ListItem>
  )
}

const subtitleExclusions: string[] = [BuiltInFields.TIME_ZONE_GROUP]

const MemberDataFilterRow = ({ filter, onRemove }) => {
  const { COLORS } = useTheme() || {}
  let title = getFieldLabel(filter)
  const key = getFieldKey(filter) || ''
  const { t } = useTranslation(undefined, { keyPrefix: 'customData' })
  const history = useHistory()
  const source = getFieldSource(filter)

  let subtitle = ''
  let filterRoute = route(ROUTES.RECIPIENT_PANE.CUSTOM_FIELD, { fieldKey: key })

  if (source === FieldSources.BUILT_IN) {
    title = humanizeBuiltInFilterShortened(filter)
    subtitle = subtitleExclusions.includes(key) ? '' : key.replace(/_/g, ' ')
    filterRoute = ROUTES.RECIPIENT_PANE[key.toUpperCase()]
  } else {
    if (isBetweenFilter(filter)) {
      const operator = getOperatorTranslation({
        t,
        operator: filter.operator,
        type: filter?.operands?.[0]?.operand?.type,
      })

      subtitle = `${operator.toLowerCase()} ${humanizeOperandValues(filter.operands)}`
    }

    if (isSelectorFilter(filter)) {
      const operator = getOperatorTranslation({
        t,
        operator: filter.operator,
        type: filter.operand.type,
      })

      subtitle = `${operator.toLowerCase()} ${humanizeOperandValue(filter.operand)}`
    }
  }

  return (
    <ListItem
      as="div"
      data-testid={`member-data-filter-${getFieldKey(filter)}`}
      label={title}
      onClick={() => history.push(filterRoute)}
      subtext={subtitle}
      suffix={
        <StyledAddSuffix>
          <StyledButton
            $color={COLORS?.ERRORS}
            onClick={(event) => {
              event.stopPropagation()
              onRemove()
            }}
          >
            Remove
          </StyledButton>
        </StyledAddSuffix>
      }
    ></ListItem>
  )
}

export const SelectedFilters = (): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { individuals, removeFilter, activeSubtreeFilters } = useFilters()
  const activeFilterTypes = useActiveFilterTypes({ filters: activeSubtreeFilters })

  const communityLookup = useCommunityLookup()
  const individualsLookup = useMemo(
    () => Object.fromEntries(individuals.map((individual) => [individual.fanSubscriptionId, individual])),
    [individuals],
  )

  return (
    <>
      {activeSubtreeFilters ? (
        <StyledHeading>Selected Filters</StyledHeading>
      ) : (
        <Layout alignItems="center" display="flex" flexDirection="column" paddingTop="96px">
          <ThinkingFlag color={COLORS?.BORDERS} size={150} />
          <Typography color={COLORS?.SUBTEXT} variant="body2">
            No filters applied
          </Typography>
        </Layout>
      )}

      {activeFilterTypes.communitiesIncluded.length > 0 &&
        activeFilterTypes.communitiesIncluded.filter(isCommunityFilter).map((filter) => {
          const community = communityLookup[filter.operand.value]

          return <CommunityRow community={community} key={community.id} onRemove={() => removeFilter(filter)} />
        })}

      {activeFilterTypes.subscribersIncludedIndividualFilters.length > 0 &&
        activeFilterTypes.subscribersIncludedIndividualFilters.filter(isIndividualsFilter).map((filter, i) => {
          const individual = individualsLookup[filter.operand.value]

          return <IndividualRow individual={individual} key={i} onRemove={() => removeFilter(filter)} />
        })}

      {activeFilterTypes.cmd &&
        activeFilterTypes.cmd.filter(isMemberDataFilter).map((filter, i) => {
          return <MemberDataFilterRow filter={filter} key={i} onRemove={() => removeFilter(filter)} />
        })}

      {activeFilterTypes.age && (
        <MemberDataFilterRow
          filter={activeFilterTypes.age}
          onRemove={() => removeFilter(activeFilterTypes.age as MemberDataFilter)}
        />
      )}

      {activeFilterTypes.birthday && (
        <MemberDataFilterRow
          filter={activeFilterTypes.birthday}
          onRemove={() => removeFilter(activeFilterTypes.birthday as MemberDataFilter)}
        />
      )}

      {activeFilterTypes.everyone && (
        <MemberDataFilterRow
          filter={activeFilterTypes.everyone}
          onRemove={() => removeFilter(activeFilterTypes.everyone as MemberDataFilter)}
        />
      )}

      {activeFilterTypes.gender && (
        <MemberDataFilterRow
          filter={activeFilterTypes.gender}
          onRemove={() => removeFilter(activeFilterTypes.gender as MemberDataFilter)}
        />
      )}

      {activeFilterTypes.joinDate && (
        <MemberDataFilterRow
          filter={activeFilterTypes.joinDate}
          onRemove={() => removeFilter(activeFilterTypes.joinDate as MemberDataFilter)}
        />
      )}

      {activeFilterTypes.location && (
        <MemberDataFilterRow
          filter={activeFilterTypes.location}
          onRemove={() => removeFilter(activeFilterTypes.location as MemberDataFilter)}
        />
      )}

      {activeFilterTypes.mostActiveMembersPercent && (
        <MemberDataFilterRow
          filter={activeFilterTypes.mostActiveMembersPercent}
          onRemove={() => removeFilter(activeFilterTypes.mostActiveMembersPercent as MemberDataFilter)}
        />
      )}

      {activeFilterTypes.newMember && (
        <MemberDataFilterRow
          filter={activeFilterTypes.newMember}
          onRemove={() => removeFilter(activeFilterTypes.newMember as MemberDataFilter)}
        />
      )}

      {activeFilterTypes.timezone && (
        <MemberDataFilterRow
          filter={activeFilterTypes.timezone}
          onRemove={() => removeFilter(activeFilterTypes.timezone as MemberDataFilter)}
        />
      )}
    </>
  )
}
