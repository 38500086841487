import {
  ChevronRightIcon,
  DIALOG_VARIANTS,
  Dialog,
  FONT_SIZE,
  FONT_WEIGHT,
  Image,
  SPACING,
  TABLET_BREAK,
  Typography,
} from '@community_dev/pixels'
import { useQueryClient } from '@tanstack/react-query'
import * as filestack from 'filestack-js'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { useGoToDataExport } from './useGoToDataExport'
import { useShouldShowDataExport } from './useShouldShowDataExport'

import { putClientContactPhoto } from 'api/client'
import { QUERY_CACHE } from 'constants/query-cache'
import { DESKTOP_BREAK } from 'constants/theme'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useAuth } from 'hooks/useAuth'
import { useClient, useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import Sentry from 'integrations/Sentry'
import { Setting, SettingsSection } from 'layouts/SettingsLayout'
import { baseConfig } from 'utils/config'
import { formatPhone } from 'utils/normalize'

const StyledLinks = styled.ul`
  width: 100%;
  list-style: none;
  font-size: ${FONT_SIZE[3]};
  line-height: 18px;
  font-weight: ${FONT_WEIGHT[6]};
  position: relative;
  margin: 0;
  padding: ${SPACING[3]} 0;

  li {
    padding: 6px 0;
  }

  a {
    color: ${({ theme }) => theme?.COLORS?.LINKS};

    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: ${TABLET_BREAK}) {
    display: flex;
    justify-content: center;
    font-size: ${FONT_SIZE[2]};
    padding: ${SPACING[5]} 0;

    li {
      padding: 0;
    }

    li:not(:last-child)::after {
      content: '|';
      font-weight: normal;
      margin-left: ${SPACING[2]};
      font-size: ${FONT_SIZE[1]};
      color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
    }

    a {
      margin-left: ${SPACING[2]};
    }
  }

  @media (min-width: ${DESKTOP_BREAK}) {
    justify-content: flex-end;
  }
`

const StyledLogout = styled.button`
  color: ${({ theme }) => theme?.COLORS?.ERRORS};
  padding: 0;
  background: none;
  border: none;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
`

const StyledImage = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: inline-block;
`

const StyledVersion = styled.span`
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

function SettingsGeneral(): JSX.Element {
  const { t } = useTranslation()
  const { COLORS } = useTheme() || {}
  const { showToastMessage } = useToastMessage()
  const queryClient = useQueryClient()
  const { logout } = useAuth()
  const { data: client } = useClient()
  const clientId = useClientId()
  const { data: seat } = useSeat()
  const [loggingOut, setLoggingOut] = useState<boolean>()
  const pickerRef = useRef<filestack.PickerInstance>()
  const goToDataExport = useGoToDataExport()
  useEffect(() => {
    const client = filestack.init(baseConfig.filestackApiKey)
    const options = {
      maxFiles: 1,
      accept: ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/gif'],
      fromSources: ['local_file_system'],
      uploadInBackground: false,
      transformations: {
        crop: {
          aspectRatio: 1,
          force: true,
        },
      },
      maxSize: 50 * 1024 * 1024,
      onFileUploadFinished: async (res) => {
        try {
          const response = await fetch(res.url)
          const blob = await response.blob()
          const profileImage = new File([blob], res.filename)
          const body = new FormData()
          body.append('profile_image', profileImage)
          await putClientContactPhoto({ body })
          await queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
          showToastMessage({
            message: 'Contact Card Photo updated successfully.',
          })
        } catch (e) {
          Sentry.captureException(e)
          showToastMessage({
            message: 'There was a problem. Contact Card Photo was not updated.',
            success: false,
          })
        }
      },
    }
    pickerRef.current = client.picker(options)
  }, [clientId, queryClient, showToastMessage])
  const shouldShowDataExport = useShouldShowDataExport()
  useEffect(() => {
    queryClient.invalidateQueries([QUERY_CACHE.CLIENT, { clientId }])
  }, [clientId, queryClient])

  const handleLogout = () => setLoggingOut(true)

  if (!client) return <></>

  const { graphicUrlSmall, fullName, masterPhoneNumber, communicationChannels } = client

  return (
    <>
      <div>
        <SettingsSection>
          <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
            {t('settings.general.accountInfo')}
          </Typography>
          <Setting
            action={
              <>
                {t('edit')}
                <ChevronRightIcon color={COLORS?.SUBTEXT} size={18} />
              </>
            }
            label={t('settings.general.contactCardPhoto')}
            onClickAction={() => {
              pickerRef.current?.open()
            }}
          >
            <StyledImage alt={t('settings.general.avatar')} src={graphicUrlSmall} />
          </Setting>
          <Setting label={t('name')}>{fullName}</Setting>
          {communicationChannels?.length && communicationChannels?.length > 0 ? (
            <Setting label={t('number')}>{formatPhone(masterPhoneNumber)}</Setting>
          ) : null}
        </SettingsSection>
        <SettingsSection>
          <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
            {t('settings.general.yourInfo')}
          </Typography>
          <Setting label={t('name')}>{`${seat?.firstName || ''} ${seat?.lastName || ''}`.trim()}</Setting>
          <Setting label={t('email')}>{seat?.email}</Setting>
          {shouldShowDataExport && (
            <Setting
              action={
                <>
                  {t('download')}
                  <ChevronRightIcon color={COLORS?.SUBTEXT} size={18} />
                </>
              }
              label={t('settings.general.accountData')}
              onClickAction={goToDataExport}
            />
          )}
        </SettingsSection>
        <SettingsSection>
          <Setting label={<StyledLogout onClick={handleLogout}>{t('logout')}</StyledLogout>} />
        </SettingsSection>
      </div>

      {loggingOut && (
        <Dialog message={t('settings.general.areYouSureLogout')} title={t('logout')}>
          <Dialog.Action onClick={() => setLoggingOut(false)}>{t('cancel')}</Dialog.Action>
          <Dialog.Action onClick={() => logout()} variant={DIALOG_VARIANTS.DESTRUCTIVE}>
            {t('logout')}
          </Dialog.Action>
        </Dialog>
      )}

      <StyledLinks>
        <li>
          <StyledVersion>{import.meta.env.VITE_APP_VERSION}</StyledVersion>
        </li>
        <li>
          <a href="mailto:yourfriends@community.com?subject=View Preregistration Messages">
            {t('settings.general.viewPreregistrationMessages')}
          </a>
        </li>
        <li>
          <a href="https://www.community.com/privacy">{t('settings.general.privacyPolicy')}</a>
        </li>
        <li>
          <a href="https://www.community.com/terms">{t('settings.general.userTerms')}</a>
        </li>
        <li>
          <a href="https://www.community.com/client-terms">{t('settings.general.customerTerms')}</a>
        </li>
      </StyledLinks>
    </>
  )
}

export default SettingsGeneral
