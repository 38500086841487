import { GenderLabels } from '@community_dev/filter-dsl/lib/humanize/builtInFilters/humanizeGenderFilter'
import {
  BuiltInFields,
  findOne,
  GenderFilter,
  isSelectorFilter,
  serializeFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ListItem } from '@community_dev/pixels'
import { FanRegistrationCoreFieldName } from '@community_dev/types/lib/api/FanRegistration'
import { useEffect, useRef, useMemo } from 'react'
import { useTheme } from 'styled-components'

import { RecommendationsLoadingIndicator } from '../RecommendationsLoadingIndicator'

import { FilterMemberCount } from './FilterMemberCount'
import { useRecipientField } from './RecipientFieldContext'
import {
  StyledAddSuffix,
  StyledButton,
  StyledHeader,
  StyledHeading,
  StyledMeta,
  StyledPanel,
  StyledResults,
  StyledVirtualList,
} from './styled-recipients'

import { VirtualListHandle } from 'components/VirtualList'
import { FilterSelectionType, useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useFanRegistrationField } from 'hooks/useClientSettings/useFanRegistrationField'
import { useCurrentFiltersWithGenderCounts } from 'hooks/useCountByQuery/useGenderCount'
import { pluralizeNumeral } from 'utils/general'

type RecommendationsGenderIdentityProps = {
  type: FilterSelectionType
}

export function RecommendationsGenderIdentity({ type = 'includes' }: RecommendationsGenderIdentityProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const virtualRef = useRef<VirtualListHandle>(null)
  const { addFilter, removeFilter, activeSubtree } = useFilters()
  const { setIsOpen } = useRecipientField()
  const { options, counts } = useCurrentFiltersWithGenderCounts(type)

  useEffect(() => {
    virtualRef.current?.measure()
  }, [options])

  const selectedFilterNode = useMemo(() => {
    return findOne(activeSubtree, {
      operand: {
        field_key: BuiltInFields.GENDER_IDENTITY,
      },
    })
  }, [activeSubtree])

  const selected = useMemo(() => {
    if (selectedFilterNode) {
      return serializeFilters(selectedFilterNode) as GenderFilter
    }
  }, [selectedFilterNode])

  const { label: genderLabel } = useFanRegistrationField(FanRegistrationCoreFieldName.GENDER_NAME) || {}

  if (selected) {
    return (
      <StyledPanel>
        <StyledMeta>
          <StyledHeader>
            <StyledHeading>{genderLabel || 'Gender Identity'}</StyledHeading>
          </StyledHeader>
        </StyledMeta>
        <StyledResults>
          <ListItem
            as={'div'}
            label={GenderLabels[selected.operand.value]}
            suffix={
              <StyledAddSuffix>
                <StyledButton
                  $color={COLORS?.ERRORS}
                  onClick={() => {
                    removeFilter(selected)
                    setIsOpen(false)
                  }}
                >
                  Remove
                </StyledButton>
              </StyledAddSuffix>
            }
          />
        </StyledResults>
      </StyledPanel>
    )
  }

  return (
    <StyledPanel>
      <StyledMeta>
        <StyledHeader>
          <StyledHeading>{genderLabel || 'Gender Identity'}</StyledHeading>
        </StyledHeader>
      </StyledMeta>
      {options.length === 0 ? (
        <RecommendationsLoadingIndicator />
      ) : (
        <StyledResults>
          <StyledVirtualList ref={virtualRef} rows={options} testId="gender-list">
            {({ virtualRow }) => {
              const { label, filter } = options[virtualRow.index]
              const { data: { count = 0 } = {}, isLoading } = counts[virtualRow.index]

              const value = isSelectorFilter(filter) ? filter.operand.value : undefined
              return (
                <ListItem
                  as={selected ? 'div' : 'button'}
                  data-testid={`gender-${value}`}
                  label={label}
                  onClick={
                    selected
                      ? undefined
                      : () => {
                          addFilter(filter, type)
                          setIsOpen(false)
                        }
                  }
                  subtext={
                    <FilterMemberCount
                      count={pluralizeNumeral(String(count), '0,0', 'Member', 'Members')}
                      isLoading={isLoading}
                    />
                  }
                  suffix={
                    selected ? (
                      <StyledAddSuffix>
                        <StyledButton
                          $color={COLORS?.ERRORS}
                          onClick={() => {
                            removeFilter(selected)
                            setIsOpen(false)
                          }}
                        >
                          Remove
                        </StyledButton>
                      </StyledAddSuffix>
                    ) : undefined
                  }
                />
              )
            }}
          </StyledVirtualList>
        </StyledResults>
      )}
    </StyledPanel>
  )
}
