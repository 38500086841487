import { convertKeysToCamelCase } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { MessageSourceType } from '@community_dev/types/lib/api/v2/MessageSourceType'
import { InfiniteData, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { QUERY_CACHE } from 'constants/query-cache'
import { UploadMediaFileResult, useUpload } from 'contexts/UploadProvider'
import { useClientId } from 'hooks/useClient'
import analytics from 'utils/analytics'
import { request } from 'utils/api'
import { createMediaFromFile } from 'utils/createMediaFromFile'
import { MediaDimensions, getMediaDimensions } from 'utils/getMediaDimensions'
import { route } from 'utils/router'

type UseSendMediaDMReturn = UseMutationResult<
  UseSendMediaDMMutationReturn,
  unknown,
  UseSendMediaDMMutationOptions,
  { previousData: InfiniteData<CamelCasedPropertiesDeep<Api.V2.MessageHistory>> | undefined }
>

type UseSendMediaDMMutationOptions = {
  message: SendMediaMessageOptions
  file: File
  fanId: string
  usedAiSuggestion: boolean
}

type UseSendMediaDMMutationReturn = {
  sendMediaDMResult: PostMediaMessageResponse
  uploadResult: UploadMediaFileResult
  mediaDimensions: MediaDimensions
}

export function useSendMediaDM({ onComplete }: { onComplete?(): void }): UseSendMediaDMReturn {
  const clientId = useClientId()
  const { uploadFile } = useUpload()

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (options: UseSendMediaDMMutationOptions) => {
      const { message, file } = options

      const sendMediaDMResult = await postMediaMessage(message)
      const uploadResult = (await uploadFile({ url: sendMediaDMResult.data.upload_url, file })) as UploadMediaFileResult
      const mediaDimensions = await getMediaDimensions(file)

      return { uploadResult, sendMediaDMResult, mediaDimensions }
    },
    onSuccess: async (context, variables) => {
      const queryKey = [QUERY_CACHE.MESSAGE_HISTORY.DMS, clientId, variables.fanId]

      analytics.track(analytics.events.DMSent({ fanId: variables.fanId, usedAiSuggestion: variables.usedAiSuggestion }))

      await queryClient.cancelQueries(queryKey)

      const media = await createMediaFromFile(variables.file, {
        disposition: variables.message.data.media_disposition || null,
        height: context.mediaDimensions.height,
        width: context.mediaDimensions.width,
      })

      const dm = {
        createdAt: new Date().toISOString(),
        id: context.sendMediaDMResult.data.message_id,
        inbound: false,
        media: convertKeysToCamelCase(media),
        sourceType: MessageSourceType.DM,
        status: 'success',
        text: variables.message.data.message_body || null,
      }

      const previousData =
        queryClient.getQueryData<InfiniteData<CamelCasedPropertiesDeep<Api.V2.MessageHistory>>>(queryKey)

      await queryClient.setQueryData<InfiniteData<CamelCasedPropertiesDeep<Api.V2.MessageHistory>>>(
        queryKey,
        (data) => {
          if (data) {
            const nextData = cloneDeep(data)

            nextData.pages[0].data.push(dm)

            return nextData
          }

          return undefined
        },
      )

      if (onComplete) {
        onComplete()
      }

      return { previousData }
    },
  })
}

export type SendMediaMessageOptions = {
  clientId: string
  fanId: string
  data: PostMediaMessageBody
}

type PostMediaMessageBody = {
  message_body: string
  media_file_extension: string
  shorten_links: boolean
  media_disposition?: MediaDisposition
}

type PostMediaMessageResponse = {
  data: {
    message_id: string
    upload_url: string
  }
}

export const postMediaMessage = ({
  clientId,
  fanId,
  data,
}: SendMediaMessageOptions): Promise<PostMediaMessageResponse> => {
  return request(route(ENDPOINTS.MESSAGING.MEDIA, { clientId, fanId }), {
    camelCase: false,
    body: data,
    method: 'POST',
  })
}
