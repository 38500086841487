import { Block } from '@community_dev/pixels'

import { MetricsChart } from '../MetricsChart'
import { useMetrics } from '../useMetrics'

export function LinkClicks({ totalRecipients }: { totalRecipients: number }): JSX.Element | null {
  const { hasLinkMetrics, linkMetrics, isLoading } = useMetrics(totalRecipients)

  return hasLinkMetrics ? (
    <Block title="Link Clicks">
      {linkMetrics?.map((metric, i) => (
        <MetricsChart isLoading={isLoading} key={i} metric={metric} totalRecipients={totalRecipients} />
      ))}
    </Block>
  ) : null
}
