import { BORDER_RADIUS, Button, InfoIcon, Modal, SPACING, Tooltip } from '@community_dev/pixels'
import { useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CarrierLimitsTable } from 'components/CarrierLimitsTable'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useTCRContext } from 'contexts/TCRProvider'
import { useClient, useClientId } from 'hooks/useClient'
import analytics from 'utils/analytics'

const SUPPORT_EMAIL = 'yourfriends@community.com'

const StyledTitle = styled.h1`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  text-align: center;
`

const StyledLink = styled.a`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  margin-top: ${SPACING[1]};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const StyledBody = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${SPACING[3]};
`

const StyledFooter = styled.div`
  text-align: center;
`

const InfoTooltip = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'tcr.modals.approval' })
  return (
    <Tooltip
      content={t('tooltip')}
      css={`
        border-radius: ${BORDER_RADIUS[1]};
        .tippy-content {
          text-align: left;
          padding: ${SPACING[2]} ${SPACING[4]};
        }
      `}
      maxWidth={265}
      placement="bottom"
    >
      <span
        css={`
          svg {
            vertical-align: text-bottom;
          }
        `}
      >
        <InfoIcon size={18} />
      </span>
    </Tooltip>
  )
}

export const ApprovalTCRModal = (): JSX.Element => {
  const { t } = useTranslation(undefined, { keyPrefix: 'tcr.modals.approval' })
  const { t: messageLimitsTranslation } = useTranslation(undefined, { keyPrefix: 'settings.billing.messageLimits' })

  const { data: client } = useClient()
  const clientId = useClientId()
  const { setSeen } = useTCRContext()
  const [runConfetti, setRunConfetti] = useState(true)
  const { data: seat } = useSeat()

  useEffect(() => {
    setTimeout(() => setRunConfetti(false), 5000)
  }, [])

  const markAsSeen = () => {
    analytics.track(analytics.events.TCR.ApplicationApproved())
    setSeen()
  }

  const subject = messageLimitsTranslation('mailSubject')
  const body = messageLimitsTranslation('mailBody', {
    clientId: clientId,
    clientName: client?.fullName,
    seatName: `${seat?.firstName} ${seat?.lastName}`,
    seatEmail: seat?.email,
  })
  const mailLink = `mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`

  return (
    <Modal maxHeight="auto" onClose={markAsSeen} open>
      <Modal.Header>{t('modalTitle')}</Modal.Header>
      <Modal.Body>
        <Confetti numberOfPieces={500} recycle={runConfetti} />
        <StyledBody>
          <StyledTitle>{t('title')}</StyledTitle>
          <p>
            <Trans i18nKey="tcr.modals.approval.tableDescription">
              Use the following table to see how many segments per day you can send to your Community Members.{' '}
              <InfoTooltip />
            </Trans>
          </p>
          <p>{t('rateWarning')}</p>
          <CarrierLimitsTable />
          <p>
            {t('segmentMessage')}{' '}
            <StyledLink
              href={mailLink}
              onClick={() => analytics.track(analytics.events.TCR.ApplicationApprovedGetMoreSegments())}
            >
              {t('emailText')}
            </StyledLink>
          </p>
        </StyledBody>
      </Modal.Body>
      <Modal.Footer>
        <StyledFooter>
          <Button onClick={markAsSeen}>Done</Button>
        </StyledFooter>
      </Modal.Footer>
    </Modal>
  )
}
