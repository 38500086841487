import { Button, CloseIcon, Modal, SPACING, Select, TextInput, TextInputVariant } from '@community_dev/pixels'
import { RoleGroup } from '@community_dev/types/lib/api/v1/Roles'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { CapabilityList } from './CapabilityList'
import { StyledLabel } from './styled'

import { sendInvitation } from 'api/invite'
import { getTeamRoles } from 'api/team-seats'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import { isEmail } from 'utils/validators'

const StyledForm = styled.form`
  padding: ${SPACING[5]} ${SPACING[7]} 0 ${SPACING[7]};
`

type InviteDetails = {
  email: string
  role: string
}

type TeamMemberInviteModalProps = {
  onClose?: () => void
  onSaved?: (d: InviteDetails) => void
}

export function TeamMemberInviteModal({ onClose, onSaved }: TeamMemberInviteModalProps): JSX.Element {
  const { t } = useTranslation()
  const clientId = useClientId()
  const { COLORS } = useTheme() || {}
  const { showToastMessage } = useToastMessage()
  const queryClient = useQueryClient()
  const { mutateAsync: mutateInvite, isLoading } = useMutation(sendInvitation, {
    onSuccess() {
      queryClient.invalidateQueries([QUERY_CACHE.SETTINGS.TEAM_INVITES, { clientId }])
    },
  })
  const { data: roles } = useQuery([QUERY_CACHE.SETTINGS.TEAM_ROLES], {
    initialData: [],
    queryFn: getTeamRoles,
  })
  const selectableRoles = useMemo(() => {
    return roles
      .filter((role) => role.group === RoleGroup.PRIMARY && role.protected === false)
      .map((role) => ({ label: role.displayName, value: role.name }))
  }, [roles])
  async function handleSubmit(values: InviteDetails) {
    try {
      await mutateInvite({ clientId, email: values.email, role: values.role })
      showToastMessage({
        message: t('settings.team.sendInviteSuccess', { email: values.email }),
      })
      onSaved?.(values)
      onClose?.()
    } catch (e) {
      showToastMessage({ message: t('settings.team.errorSendInvite'), success: false })
    }
  }

  return (
    <Modal maxWidth={600} onClose={onClose} open={true} overflow="visible">
      <Modal.Header>
        <Modal.Header.Center>
          <span>{t('settings.team.inviteTeamMember')}</span>
        </Modal.Header.Center>
        <Modal.Header.Right onClose={onClose}>
          <CloseIcon color={COLORS.SUBTEXT} size={12} />
        </Modal.Header.Right>
      </Modal.Header>
      <Form<InviteDetails>
        initialValues={{ email: '', role: selectableRoles[0]?.value }}
        onSubmit={handleSubmit}
        render={({ values, handleSubmit, valid }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Modal.Body>
              <Field<string> name="email" validate={isEmail.validator}>
                {({ input, meta: { dirty, error } }) => (
                  <TextInput
                    css={{ width: '100%' }}
                    error={dirty && error && isEmail.message}
                    label={t('emailAddress')}
                    variant={TextInputVariant.OUTLINED}
                    {...input}
                  />
                )}
              </Field>
              <Field<string> name="role">
                {({ input }) => {
                  return (
                    <>
                      <StyledLabel id="role-label">Role</StyledLabel>
                      <Select
                        aria-labelledby="role-label"
                        inline={false}
                        onChange={(value: any) => input.onChange({ target: { value: value.value } })}
                        options={selectableRoles}
                        value={{
                          label: selectableRoles.find((role) => role.value === input.value)?.label,
                          value: input.value,
                        }}
                      />
                    </>
                  )
                }}
              </Field>
              <CapabilityList roles={roles.filter((role) => role.name === values.role)} />
            </Modal.Body>
            <Modal.Footer $sticky={true}>
              <Button disabled={!valid || isLoading} role="button" type="submit">
                {t('settings.team.sendInvite')}
              </Button>
            </Modal.Footer>
          </StyledForm>
        )}
      />
    </Modal>
  )
}
