import { LightBulbIcon } from '@community_dev/pixels'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BestPracticeCard } from './BestPracticeCard'

import { GrowYourCommunityModal } from 'screens/HomeScreen/screens/ProductEducation/GrowYourCommunityCard/GrowYourCommunityModal'

export const GrowYourCommunityCard = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation(undefined, { keyPrefix: 'growthTools.bestPractices' })

  return (
    <>
      <BestPracticeCard
        Icon={LightBulbIcon}
        body={t('growYourCommunity.body')}
        buttonText={t('buttonText')}
        dataTestId="grow-your-community-card"
        header={t('growYourCommunity.header')}
        onClick={() => setIsOpen(true)}
        subHeader={t('growYourCommunity.subHeader')}
      />
      {isOpen ? <GrowYourCommunityModal initialSlide={0} isOpen={isOpen} onClose={() => setIsOpen(false)} /> : null}
    </>
  )
}
