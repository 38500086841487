import { humanizeJoinFilterShortened } from '@community_dev/filter-dsl/lib/humanize/builtInFilters/humanizeJoinFilter'
import {
  JoinedMatch,
  findOne,
  isJoinedFilter,
  isSelectorFilter,
  serializeFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ListItem } from '@community_dev/pixels'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { CustomJoinDateForm } from './CustomJoinDateForm'
import { FilterMemberCount } from './FilterMemberCount'
import { useRecipientField } from './RecipientFieldContext'
import {
  StyledAddSuffix,
  StyledButton,
  StyledHeader,
  StyledHeading,
  StyledMeta,
  StyledPanel,
  StyledSubHeading,
} from './styled-recipients'

import { FilterSelectionType, useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useCurrentFiltersWithJoinedCount } from 'hooks/useCountByQuery/useJoinedCounts'
import { pluralizeNumeral } from 'utils/general'

type RecommendationsJoinProps = {
  type: FilterSelectionType
}

export const RecommendationsJoin = ({ type = 'includes' }: RecommendationsJoinProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { addFilter, removeFilter, activeSubtree } = useFilters()
  const { options, counts } = useCurrentFiltersWithJoinedCount()
  const activeFilter = useMemo(() => serializeFilters(findOne(activeSubtree, JoinedMatch)), [activeSubtree])

  const { setIsOpen } = useRecipientField()

  return (
    <StyledPanel>
      <StyledMeta>
        <StyledHeader>
          <StyledHeading>Join Date</StyledHeading>
        </StyledHeader>
      </StyledMeta>
      {activeFilter && (
        <ListItem
          as="div"
          label={humanizeJoinFilterShortened(activeFilter)}
          subtext="join date"
          suffix={
            <StyledAddSuffix>
              <StyledButton
                $color={COLORS?.ERRORS}
                onClick={() => {
                  removeFilter(activeFilter)
                  setIsOpen(false)
                }}
              >
                Remove
              </StyledButton>
            </StyledAddSuffix>
          }
        />
      )}
      {!activeFilter && (
        <>
          <StyledSubHeading>Suggested dates</StyledSubHeading>
          <div>
            {options.map(({ filter, label }, index) => {
              const { data: { count = 0 } = {}, isInitialLoading } = counts[index]

              const disabled = count === 0
              const handleClick = () => {
                addFilter(filter, type)
                setIsOpen(false)
              }

              if (isSelectorFilter(filter) && isJoinedFilter(filter)) {
                return (
                  <ListItem
                    as={disabled ? 'div' : 'button'}
                    disabled={disabled}
                    key={filter.operand.value}
                    label={label}
                    onClick={!disabled ? handleClick : undefined}
                    subtext={
                      <FilterMemberCount
                        count={pluralizeNumeral(String(count), '0,0', 'Member', 'Members')}
                        isLoading={isInitialLoading}
                      />
                    }
                  />
                )
              }
            })}
          </div>
          <StyledSubHeading $topPadding>Custom</StyledSubHeading>
          <CustomJoinDateForm
            onAdd={(filter) => {
              addFilter(filter, type)
              setIsOpen(false)
            }}
          />
        </>
      )}
    </StyledPanel>
  )
}
