import { MemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useQuery } from '@tanstack/react-query'

import { ActionPanel } from '../ActionPanel'
import { AddToCommunitiesByFan } from '../AddToCommunitiesByFan'
import { AddToCommunitiesWithFilters } from '../AddToCommunitiesWithFilters'

import { getFan, Fan } from 'api/fans'
import { PANELS } from 'constants/panels'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'

type AddToCommunityActionPanelProps = {
  communicationChannel?: CommunicationChannel
  fan?: Fan
  filters?: MemberDataFilter
  onBack: () => void
  recipientCount?: number
}

export function AddToCommunityActionPanel({
  communicationChannel = CommunicationChannel.SMS,
  fan,
  filters,
  onBack,
  recipientCount,
}: AddToCommunityActionPanelProps): JSX.Element {
  const { data } = useQuery([QUERY_CACHE.FAN, fan?.id], () => getFan(fan?.id), {
    enabled: Boolean(fan?.id),
    staleTime: STALE_TIME.FIVE_MINUTES,
  })

  return (
    <ActionPanel flush name={PANELS.ADD_TO_COMMUNITIES} onBack={onBack}>
      {filters && recipientCount ? (
        <AddToCommunitiesWithFilters
          communicationChannel={communicationChannel}
          filters={filters}
          recipientCount={recipientCount}
        />
      ) : (
        <AddToCommunitiesByFan fan={data} />
      )}
    </ActionPanel>
  )
}
