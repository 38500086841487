import {
  UndoableAction,
  UndoableReducerControl,
  useInterval,
  usePrevious,
  useUndoableReducer,
} from '@community_dev/hooks'
import { Api } from '@community_dev/types'
import { MediaDisposition, MediaProcessingStatus } from '@community_dev/types/lib/api/v2/Media'
import { Manifest, isSendMessageAction } from '@community_dev/workflow-manifest'
import { useQueries } from '@tanstack/react-query'
import noop from 'lodash/noop'
import React, { useContext, useEffect, useReducer } from 'react'
import { Merge } from 'type-fest'

import { formatMediaForFlow } from '../components/WorkflowSidebarAddSms'
import { useRemoteWorkflow } from '../hooks/useRemoteWorkflow'

import { getMedia } from 'api/media'
import { Workflow } from 'api/workflows'
import { QUERY_CACHE } from 'constants/query-cache'
import { WORKFLOW_ID_UNSAVED } from 'constants/workflow'
import { useClientId } from 'hooks/useClient'

export const initialState: WorkflowState = {
  isLoading: false,
  isError: false,
} as WorkflowState

export type WorkflowContextValue = WorkflowState & {
  dispatch: React.Dispatch<UndoableAction<React.Reducer<WorkflowState, WorkflowAction>>>
  error?: unknown
  history: UndoableReducerControl<WorkflowState>
  mediaUpload: Map<string, { media: Api.V2.Media; file: File }>
  mediaUploadDispatch: React.Dispatch<MediaUploadUpdateAction | MediaUploadRemoveAction>
}

export const WorkflowContext = React.createContext<WorkflowContextValue>({
  ...initialState,
  dispatch: noop,
  mediaUpload: new Map(),
  mediaUploadDispatch: noop,
  history: {
    canRedo: false,
    canUndo: false,
    future: [],
    past: [],
    redo: noop,
    reset: noop,
    undo: noop,
  },
})

WorkflowContext.displayName = 'WorkflowContext'

export function useWorkflowProvider(): WorkflowContextValue {
  const context = useContext(WorkflowContext)

  if (!context) {
    throw new Error('useWorkflowProvider must be used within a WorkflowProvider')
  }

  return context
}

export type UnsavedWorkflow = Workflow & {
  id: typeof WORKFLOW_ID_UNSAVED
  created_at: null
  last_manifest: undefined
  active_end_at?: string | null
  active_start_at?: string | null
}

export type WorkflowStateSaved = {
  workflow: Workflow
  manifest: Manifest
  isLoading: boolean
  isError: boolean
}

export type WorkflowStateUnsaved = Merge<
  WorkflowStateSaved,
  {
    workflow: UnsavedWorkflow
  }
>

export type WorkflowState = WorkflowStateSaved | WorkflowStateUnsaved

export type WorkflowUpdateAction = {
  type: 'update'
  state: Partial<WorkflowState>
}

export type WorkflowAction = WorkflowUpdateAction

export function workflowReducer(state: WorkflowState, action: WorkflowAction): WorkflowState {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...(action.state || {}),
      }
    }
    default:
      return state
  }
}

export type MediaUploadUpdateAction = {
  type: 'update'
  media: Api.V2.Media
  file: File
}

export type MediaUploadRemoveAction = {
  type: 'remove'
  id: string
}

export function mediaUploadReducer(
  state: Map<string, { media: Api.V2.Media; file: File }>,
  action: MediaUploadUpdateAction | MediaUploadRemoveAction,
): Map<string, { media: Api.V2.Media; file: File }> {
  switch (action.type) {
    case 'update': {
      const map = new Map(state)
      const existing = map.get(action.media.id)
      map.set(action.media.id, { media: action.media || existing?.media, file: action.file || existing?.file })
      return map
    }
    case 'remove': {
      const map = new Map(state)
      map.delete(action.id)
      return map
    }
    default:
      return state
  }
}

type WorkflowProviderProps = {
  children?: React.ReactNode
}

export function WorkflowProvider({ children }: WorkflowProviderProps): JSX.Element {
  const clientId = useClientId()
  const [mediaUpload, mediaUploadDispatch] = useReducer(mediaUploadReducer, new Map())
  const [workflowState, workflowDispatch, history] = useUndoableReducer(workflowReducer, initialState)
  const { data: remoteWorkflow, manifest, isInitialLoading: isLoading, isError, error } = useRemoteWorkflow()

  const previousWorkflowId = usePrevious(remoteWorkflow?.id)

  const queries = useQueries({
    queries: Array.from(mediaUpload.entries()).map(([, { media, file }]) => ({
      queryKey: [QUERY_CACHE.MEDIA.GET, { clientId, mediaId: media?.id }],
      queryFn: () => getMedia({ clientId, mediaId: media?.id }),
      onSuccess: (data) => {
        mediaUploadDispatch({
          type: 'update',
          media: data.media,
          file,
        })

        if (data.media.status === MediaProcessingStatus.COMPLETED) {
          const nextManifest = { ...workflowState.manifest }

          nextManifest.actions = Object.keys(nextManifest.actions).reduce((acc, key) => {
            const action = nextManifest.actions[key]

            if (isSendMessageAction(action) && action.params.media?.[0]?.id === data.media.id) {
              const media = action.params.media?.[0]

              if (media?.id === data.media.id) {
                acc[key] = {
                  ...action,
                  params: {
                    ...action.params,
                    media: [formatMediaForFlow(data.media, media?.disposition as MediaDisposition)],
                  },
                }
              }
            } else {
              acc[key] = action
            }

            return acc
          }, {})

          workflowDispatch({
            type: 'update',
            state: {
              manifest: nextManifest,
            },
          })
        }
      },
    })),
  })

  useInterval(
    () => {
      queries.forEach((query) => {
        if (
          query.data?.media.status === MediaProcessingStatus.PROCESSING ||
          query.data?.media.status === MediaProcessingStatus.UPLOAD_URL_ISSUED
        ) {
          query.refetch()
        }
      })
    },
    1000,
    {
      enabled: queries.some(
        (query) =>
          query.data?.media.status === MediaProcessingStatus.PROCESSING ||
          query.data?.media.status === MediaProcessingStatus.UPLOAD_URL_ISSUED,
      ),
    },
  )

  useEffect(() => {
    // if we’re loading this workflow for the first time, reset the history.
    // Otherwise, it’s the result of an intermediary save, in which case we want
    // to keep the history.
    if (previousWorkflowId !== remoteWorkflow?.id) {
      history.reset()
    }
    if (remoteWorkflow !== undefined) {
      workflowDispatch({
        skipUndoHistory: true,
        type: 'update',
        state: {
          workflow: remoteWorkflow,
          manifest,
        },
      })
    }
  }, [previousWorkflowId, remoteWorkflow])

  return (
    <WorkflowContext.Provider
      value={{
        ...workflowState,
        dispatch: workflowDispatch,
        mediaUpload,
        mediaUploadDispatch,
        isLoading,
        isError,
        error,
        history,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  )
}
