import { humanizeComplexFilter } from '@community_dev/filter-dsl/lib/humanize/humanizeComplexFilter'
import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  getRoot,
  insertSubscriberExcludes,
  insertSubscriberIncludes,
  isSelectorFilter,
  MemberDataFilter,
  parseFilters,
  RelationshipOperators,
  removeNode,
  SelectorOperators,
  serializeFilters,
  visitFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { memberDataFilterToDigitsFilter } from '@community_dev/filter-dsl/lib/transformers/memberDataFilterToDigitsFilter'
import { useInterval } from '@community_dev/hooks'
import {
  ColorGrid,
  BUTTON_VARIANTS,
  Button,
  DIALOG_VARIANTS,
  Dialog,
  Filter,
  InfiniteScroll,
  NerdyFlag,
  SPACING,
  SearchBar,
  ThinkingFlag,
  Menu,
  Layout,
  AppleIconCircled,
  AppleIcon,
  WhatsAppIcon,
  SmsIcon,
  InfoIcon,
} from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { FanStateFlag } from '@community_dev/types/lib/api/Fan'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQueryClient } from '@tanstack/react-query'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import kebabCase from 'lodash/kebabCase'
import numeral from 'numeral'
import { createRef, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { useRouteMatch } from 'react-router-dom'
import { useTheme } from 'styled-components'
import styled from 'styled-components'

import { CommunityDot } from '../../components/CommunityDot'

import {
  StyledCenteredContainer,
  StyledCenteredHeading,
  StyledCenteredCopy,
  StyledStar,
  StyledTitle,
  StyledEditPencil,
  StyledHeader,
  FiltersAppliedTitle,
  StyledFilterApplied,
  StyledNLP,
  StyledSentContainer,
  StyledFilterTitle,
  StyledActionButton,
  StyledSearch,
  StyledFiltersContainer,
  StyledFiltersControls,
  StyledFiltersSelect,
  StyledFiltersFilter,
  StyledFiltersFilterButton,
  StyledRoot,
  StyledLeftColumn,
  StyledRightColumn,
  StyledRightHeader,
  StyledRightContent,
  StyledSwitcherHeader,
  StyledFilterPills,
} from './Communities.style'
import Messages from './Messages'

import { Community, deleteCommunity as _deleteCommunity } from 'api/community'
import { Fan } from 'api/fans'
import { AddToCommunityActionPanel } from 'components/ActionPanel'
import { useActionPanel } from 'components/ActionPanel/ActionPanelContext'
import { MemberListItem, CommunityMemberSelectionActionPanel } from 'components/Communities'
import { ComposeMessage } from 'components/ComposeMessage'
import { SelectWithIcon } from 'components/ComposeMessage/components/SelectWithIcon'
import CreateCommunityModal from 'components/CreateCommunityModal'
import { EditPencil } from 'components/icons'
import MembersModal from 'components/MembersModal/MembersModal'
import { CAPABILITIES } from 'constants/capabilities'
import { COMMUNITY_TYPES } from 'constants/communities'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import CommunitiesList from 'containers/CommunitiesList'
import FilterModal from 'containers/FilterModal'
import {
  useAddFansToCommunity,
  useRemoveFanFromCommunity,
} from 'containers/InboxList/MemberDetailActionPanel/member-detail-queries'
import { MemberDetailActionPanelRouter } from 'containers/InboxList/MemberDetailActionPanel/MemberDetailActionPanelRouter'
import { KeywordsCreateModal } from 'containers/KeywordsModal/KeywordsCreateModal'
import { useGoToDataExport } from 'containers/Settings/useGoToDataExport'
import { useShouldShowDataExport } from 'containers/Settings/useShouldShowDataExport'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useInbox } from 'contexts/InboxProvider'
import { useAudienceQuery } from 'hooks/useAudienceQuery'
import { canReceiveCampaigns } from 'hooks/useCanSendDms'
import { useClient, useClientId } from 'hooks/useClient'
import { useCommunities, useCommunity, useCommunityLookup, useUpdateCommunity } from 'hooks/useCommunities'
import { useCountByQuery } from 'hooks/useCountByQuery'
import {
  toCommunityFilter,
  useAllCommunityCount,
  useInvalidateCommunityCount,
} from 'hooks/useCountByQuery/useCommunityCount'
import { everyoneFilter, useAllMemberCount } from 'hooks/useCountByQuery/useEveryoneCount'
import { useFan } from 'hooks/useFan'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import { Shopify } from 'screens/InsightsScreen/Shopify'
import { stringToColor } from 'utils/colors'
import { normalizeFan, normalizeKeywords } from 'utils/normalize'
import { pathParams, route } from 'utils/router'

const StyledDataExportButton = styled(Button)`
  margin-left: ${SPACING[2]};
`
const NAV_MAP = {
  MEMBERS: 'members',
  WHATS_APP_MEMBERS: 'whats-app-members',
  WHATS_APP_MESSAGES: 'whats-app-messages',
  AMB_MEMBERS: 'amb-members',
  AMB_MESSAGES: 'amb-messages',
  MESSAGES: 'messages',
  INSIGHTS: 'insights',
}

// logic which determines if the current view is all members with amb members selected
const isAllMembersAmb = (activeNav: string, activeCommunityId?: string) =>
  !activeCommunityId && activeNav === NAV_MAP.AMB_MEMBERS

const isSendMessageVisible = (open: boolean, activeNav: string, activeCommunityId?: string) => {
  // if the action panel is open, we don't want to show the send message button
  if (open) return false

  // if we are in all members, we don't want to show the send message button for amb
  return !isAllMembersAmb(activeNav, activeCommunityId)
}

const isSelectAllCheckboxVisible = (empty: boolean, activeNav: string, activeCommunityId?: string): boolean => {
  // If the list is empty, we don't want to show the select all checkbox
  if (empty) return false

  // We should not be able to target everyone for amb members, so we hide the checkbox
  return !isAllMembersAmb(activeNav, activeCommunityId)
}

const toSelectionFilters = (selected: string[], deselected: string[]): MemberDataFilter | null => {
  if (selected.length) {
    return selected.reduce<MemberDataFilter | null>((acc, id) => {
      return serializeFilters(insertSubscriberIncludes(parseFilters(acc), id))
    }, null)
  }

  if (deselected.length) {
    return deselected.reduce<MemberDataFilter | null>((acc, id) => {
      return serializeFilters(insertSubscriberExcludes(parseFilters(acc), id))
    }, null)
  }

  return null
}

const Communities = (): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const history = useHistory()
  const { showToastMessage } = useToastMessage()
  const [activeNav, setActiveNav] = useState<string>(NAV_MAP.MEMBERS)
  const shouldShowDataExport = useShouldShowDataExport()
  const goToDataExport = useGoToDataExport()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [isMemberDetailOpen, setIsMemberDetailOpen] = useState<boolean>(false)
  const [lastIndex, setLastIndex] = useState<number>(0)
  const [isAddToCommunityOpen, setIsAddToCommunityOpen] = useState<boolean>(false)
  const [isComposeModalOpen, setIsComposeModalOpen] = useState<boolean>(false)
  const [isEditable, setIsEditable] = useState<boolean>(false)
  const [isAddMembersModalOpen, setIsAddMembersModalOpen] = useState<boolean>(false)
  const { refetch: refetchInbox } = useInbox()
  const { removeFan: removeFanFromCommunity } = useRemoveFanFromCommunity()

  const [fanId, setFanId] = useState<string>()

  const { data: fan } = useFan(fanId)

  const selectAllRef = createRef<HTMLInputElement>()

  const matchCommunitiesId = useRouteMatch(ROUTES.COMMUNITY)
  const selectedCommunityRef = useRef<string>('')
  const { isOpen, openActionPanel, closeActionPanel } = useActionPanel()
  const { t } = useTranslation()
  const { addFans } = useAddFansToCommunity()

  const queryClient = useQueryClient()

  const { id: activeCommunityId } = useParams<{ id?: string }>()
  const { mutateAsync: updateCommunity } = useUpdateCommunity()
  const { total: totalSubscriptions } = useAllMemberCount()
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)

  const [selection, setSelection] = useState<{
    selected: string[]
    deselected: string[]
    selectAll: boolean
  }>({
    selected: [],
    deselected: [],
    selectAll: false,
  })

  const defaultSorting = [{ id: 'joined_community_at', desc: true }]

  const [isCreateCommunityModalOpen, setIsCreateCommunityModalOpen] = useState(false)
  const [isEditKeywordsModalOpen, setIsEditKeywordsModalOpen] = useState<boolean>(false)
  const { data: communities, refetch: refetchCommunities } = useCommunities()
  const communityLookup = useCommunityLookup()

  const allCommunities = communities?.all

  const isShopifyCommunity =
    communities?.protected?.find((community) => community.title === 'Shopify')?.id === activeCommunityId

  const [sorters, setAudienceSort] = useState<{ id: string; desc: boolean }[]>([])

  const {
    filters: allFilters,
    includedFilters: filterModalFilters,
    setIncludedFilters,
    individuals,
    setIndividuals,
    setCommunicationChannel,
    setBaseFilters,
  } = useFilters()

  const isAnySelected = selection.selectAll || !!selection.selected.length

  const { data: client } = useClient()
  const clientId = useClientId()
  // We disable the ability to open compose if the client has no communication channels
  const isOpenComposeDisabled = !client?.communicationChannels?.length
  const isWhatsAppEnabled =
    useHasCapability(CAPABILITIES.FEATURE.WHATSAPP.ALL) &&
    client?.communicationChannels.includes(CommunicationChannel.WHATS_APP)
  const isAmbEnabled =
    useHasCapability(CAPABILITIES.FEATURE.AMB.ALL) && client?.communicationChannels.includes(CommunicationChannel.AMB)

  const { data: community } = useCommunity(activeCommunityId)

  const fanCounts = useAllCommunityCount({
    communityId: activeCommunityId,
    title: community?.title,
    traceId: 'selected-community-counts',
  })
  const allFanCounts = useAllMemberCount()

  const communicationChannel = useMemo(() => {
    if (activeNav === NAV_MAP.WHATS_APP_MEMBERS || activeNav === NAV_MAP.WHATS_APP_MESSAGES) {
      return CommunicationChannel.WHATS_APP
    } else if (activeNav === NAV_MAP.AMB_MEMBERS || activeNav === NAV_MAP.AMB_MESSAGES) {
      return CommunicationChannel.AMB
    } else {
      return CommunicationChannel.SMS
    }
  }, [activeNav])

  const { setFilters: setMessagesFilter, searchTerm, setSearchTerm } = useInbox()

  useEffect(() => {
    setMessagesFilter(
      compact([
        activeCommunityId && {
          field: 'search[tag_ids]',
          key: 'tag_ids',
          label: 'Community',
          value: activeCommunityId,
        },
        {
          field: 'search[communication_channel]',
          key: 'communication_channel',
          label: 'Communication Channel',
          value: communicationChannel,
        },
      ]),
    )
  }, [activeCommunityId, filterModalFilters, communicationChannel, setMessagesFilter])

  // When the communication channel changes, we close the member panel.
  useEffect(() => {
    closeActionPanel()
    setIsMemberDetailOpen(false)
    resetSelection()
    setIncludedFilters(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNav])

  const activeCommunity = useMemo(() => {
    const activeCommunity = activeCommunityId && communities?.all.find((c) => c.id === activeCommunityId)

    if (activeCommunity) {
      return activeCommunity
    }

    return {
      id: null,
      title: t('communities.activeMembers_other', { total: 'All' }),
      communicationChannels: client?.communicationChannels,
      fanCount: totalSubscriptions,
      color: null,
      updateInProgress: false,
      type: null,
      messages: {
        loading: false,
        items: [],
      },
    }
  }, [totalSubscriptions, activeCommunityId, communities, client, t])

  useEffect(() => {
    const id = matchCommunitiesId?.url.split('/')[2] || ''
    const currentId = selectedCommunityRef.current
    if (!currentId) {
      selectedCommunityRef.current = id
    }

    if (isAnySelected && !isOpen) {
      openActionPanel()
    }

    // NOTE: Close the action panel when the id of the communities are
    // no longer the same
    if (currentId !== id) {
      selectedCommunityRef.current = id
    }
    if (!isOpen) return
    if (id === currentId) return
    closeActionPanel()
  }, [
    closeActionPanel,
    isAnySelected,
    isOpen,
    matchCommunitiesId,
    openActionPanel,
    selection.selectAll,
    selection.selected,
  ])

  const getRowKeys = () => (data?.pages || []).map((c) => c.id)

  const updating = activeCommunity.updateInProgress

  useEffect(() => {
    setIncludedFilters(null)

    if (!activeCommunityId) {
      return
    }

    return () => {
      setIncludedFilters(null)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeCommunityId) {
      setActiveNav(
        activeCommunity.communicationChannels?.includes(CommunicationChannel.AMB)
          ? NAV_MAP.AMB_MESSAGES
          : NAV_MAP.MESSAGES,
      )
    } else {
      setActiveNav(NAV_MAP.MEMBERS)
    }

    setIsEditable(activeCommunity.type === COMMUNITY_TYPES.CUSTOM)
  }, [activeCommunityId, activeCommunity.type]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeCommunityId) {
      setAudienceSort([{ id: 'joined_community_at', desc: true }])
    } else {
      setAudienceSort([{ id: 'inserted_at', desc: true }])
    }
  }, [activeNav, activeCommunityId, setAudienceSort, communicationChannel])

  useEffect(() => {
    const params: any = pathParams(history.location.pathname, {
      path: ROUTES.COMMUNITY,
    })

    if (!isMemberDetailOpen && !isAnySelected) {
      closeActionPanel()
    }

    if (!selection.selectAll && selectAllRef.current) {
      selectAllRef.current.checked = false
    }

    /* fresh page load. select the communityId in the url */
    if (params.id && activeCommunity.id !== params.id) {
      const queriedCommunity = allCommunities?.find((c: any) => c.id === params.id)
      if (!queriedCommunity) {
        history.push(ROUTES.COMMUNITIES)
      }
    }
  }, [activeCommunity, allCommunities, isAnySelected]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchChange = (event) => {
    resetSelection()
    const searchTerm = event.target.value
    setSearchTerm(searchTerm)
  }

  const invalidateCommunityMemberCount = useInvalidateCommunityCount()

  // Used to refetch the communities page while the community is being updated
  // Usually happens when a community is created
  useInterval(
    () => {
      // Refetch the communities to update the sidebar communities list
      refetchCommunities()
      // Refetch the inbox to update the communities MESSAGES
      refetchInbox()
      // Refetch the audience to update the communities MEMBERS
      refetchAudience()
      // Invalidates the community member COUNT
      activeCommunityId && invalidateCommunityMemberCount(activeCommunityId, activeCommunity.title)
    },
    4000,
    {
      enabled: !!activeCommunity.updateInProgress,
    },
  )

  const onSelectAll = (e) => {
    const { checked } = e.target
    const selection = {
      deselected:
        data?.pages
          .filter(
            (item) =>
              communicationChannel === CommunicationChannel.AMB &&
              !item.stateFlags?.includes(FanStateFlag.CAN_RECEIVE_CAMPAIGNS),
          )
          .map((item) => item.fanSubscriptionId) || [],
      selected: [],
      selectAll: checked,
      communicationChannel,
      clientId,
    }
    setSelection(selection)
    setIndividuals([])

    setIsMemberDetailOpen(false)

    openActionPanel()
  }

  const onMemberToggle = (member, e) => {
    const shift = e.nativeEvent.shiftKey
    const rowKeys = getRowKeys()
    const rowIndex = rowKeys?.indexOf(member.fanSubscriptionId)
    const { selected, deselected, selectAll } = selection
    const checked = selectAll
      ? deselected.includes(member.fanSubscriptionId)
      : !selected.includes(member.fanSubscriptionId)
    const newSelection = {
      selected,
      deselected,
      selectAll,
    }

    if (selectAll) {
      if (!checked) {
        const deselected = shift
          ? newSelection.deselected.concat(shiftSelect(rowIndex))
          : newSelection.deselected.concat(member.fanSubscriptionId)
        setSelection({ ...newSelection, deselected })
      } else {
        const deselected = shift
          ? newSelection.deselected.filter((id) => !shiftSelect(rowIndex).includes(id))
          : newSelection.deselected.filter((id) => id !== member.fanSubscriptionId)
        setSelection({ ...newSelection, deselected })
      }
    } else if (checked) {
      const selected = shift
        ? newSelection.selected.concat(shiftSelect(rowIndex))
        : newSelection.selected.concat(member.fanSubscriptionId)
      setSelection({ ...newSelection, selected })
      const individuals = data?.pages.filter((fan) => selected.includes(fan.fanSubscriptionId))
      setIndividuals(individuals as Fan[])
      openActionPanel()
    } else {
      const selected = shift
        ? newSelection.selected.filter((id) => !shiftSelect(rowIndex).includes(id))
        : newSelection.selected.filter((id) => id !== member.fanSubscriptionId)
      setSelection({ ...newSelection, selected })
      const individuals = data?.pages.filter((fan) => selected.includes(fan.fanSubscriptionId))
      setIndividuals(individuals as Fan[])
    }
    setLastIndex(rowIndex || 0)
  }

  const shiftSelect = (rowIndex, rowKeys = getRowKeys()) => {
    const startIndex = Math.min(rowIndex, lastIndex)
    const endIndex = Math.max(rowIndex, lastIndex)
    return rowKeys.slice(startIndex, endIndex + 1)
  }

  const toggleNav = (payload) => {
    if (payload === NAV_MAP.MESSAGES || payload === NAV_MAP.WHATS_APP_MESSAGES || payload === NAV_MAP.AMB_MESSAGES) {
      setSearchTerm('')
      refetchInbox()
    }

    setActiveNav(payload)

    if (
      (payload === NAV_MAP.MESSAGES || payload === NAV_MAP.WHATS_APP_MESSAGES || payload === NAV_MAP.AMB_MESSAGES) &&
      filterModalFilters !== null
    ) {
      setIncludedFilters(null)
    }
  }

  const openDeleteDialog = () => setDeleteDialogOpen(true)

  const closeDeleteDialog = () => setDeleteDialogOpen(false)

  const onSelectColor = (color) => {
    if (activeCommunity) {
      updateCommunity({ clientId, id: activeCommunityId as string, color, title: activeCommunity.title as string })
    }
  }

  const deleteCommunity = async () => {
    if (activeCommunityId) {
      if (deleteDialogOpen) {
        closeDeleteDialog()
        const title = activeCommunity.title
        await _deleteCommunity({ clientId, id: activeCommunityId })
        history.push(ROUTES.COMMUNITIES)
        queryClient.invalidateQueries([QUERY_CACHE.TAGS])
        showToastMessage({ message: `${title} deleted` })
      } else {
        openDeleteDialog()
      }
    }
  }

  const addMembers = () => {
    setIsAddMembersModalOpen(true)
  }

  const onAction = (action) => {
    switch (action) {
      case 'Send Message':
        return openCompose()
      case 'Edit Community':
        return openEditCommunityModal()
      case 'Edit Community Keywords':
        return openEditKeywordsModal()
      case 'Delete Community':
        return deleteCommunity()
      case 'Add Members':
        return addMembers()
      case 'Export Data':
        return goToDataExport()
      default:
    }
  }

  const loadMore = () => {
    fetchNextPage()
  }

  const onMemberClick = (fan) => {
    openActionPanel()
    setFanId(fan.id)
    setIsMemberDetailOpen(true)

    openActionPanel()
  }

  const sortBy = (sort) => () => {
    const sorted = [
      {
        id: sort.value,
        desc: false,
      },
    ]

    setAudienceSort(sorted)
  }

  const sortByDirection = (direction) => {
    setAudienceSort([
      {
        id: sorters[0]?.id,
        desc: direction === 'descending',
      },
    ])
  }

  // Its what fetches the fans
  // We want all the filters except the checkboxes
  const audienceFilters = useMemo(() => {
    if (!filterModalFilters && !activeCommunity?.id && !searchTerm) {
      return {
        operator: SelectorOperators.EQUALS,
        operand: {
          field_key: BuiltInFields.EVERYONE,
          field_label: fieldLabelFor(BuiltInFields.EVERYONE),
          source: FieldSources.BUILT_IN,
          value: '*',
          type: FieldTypes.STRING,
        },
      }
    }

    const communityFilter = activeCommunity?.id
      ? {
          operator: SelectorOperators.EQUALS,
          operand: {
            field_key: BuiltInFields.TAG_ID,
            field_label: activeCommunity!.title,
            source: FieldSources.TAGS,
            type: FieldTypes.UUID,
            value: activeCommunity.id,
          },
        }
      : undefined

    const searchTermFilterAst = searchTerm
      ? {
          operator: SelectorOperators.CONTAINS,
          operand: {
            field_key: BuiltInFields.NAME,
            field_label: fieldLabelFor(BuiltInFields.NAME),
            source: FieldSources.BUILT_IN,
            type: FieldTypes.STRING,
            value: searchTerm,
          },
        }
      : undefined

    return {
      operator: RelationshipOperators.AND,
      operands: compact([communityFilter, searchTermFilterAst, filterModalFilters]),
    }
  }, [filterModalFilters, activeCommunity, searchTerm])

  const {
    data,
    isLoading: loading,
    fetchNextPage,
    hasNextPage,
    refetch: refetchAudience,
  } = useAudienceQuery({
    filters: memberDataFilterToDigitsFilter(audienceFilters)?.filters,
    sorters,
    tags: activeCommunityId ? [activeCommunityId] : undefined,
    communicationChannel,
  })
  const invalidateCommunityCount = useInvalidateCommunityCount()
  const openCompose = () => {
    setCommunicationChannel(communicationChannel)
    setIsComposeModalOpen(true)
  }

  const closeCompose = () => {
    setIncludedFilters(null)
    setIsComposeModalOpen(false)
  }

  const openEditCommunityModal = () => {
    if (!isEditable) return

    setIsCreateCommunityModalOpen(true)
  }

  const openEditKeywordsModal = () => {
    setIsEditKeywordsModalOpen(true)
  }

  const onCommunityClick = (community) => {
    if (community.id === activeCommunityId) return
    if (community.id === null) {
      history.push(ROUTES.COMMUNITIES)
      setAudienceSort([{ id: 'inserted_at', desc: true }])
      return
    }
    setIncludedFilters(null)
    setSearchTerm('')
    history.push(route(ROUTES.COMMUNITY, { id: community.id }))
  }

  const handleToggleAddToCommunity = () => {
    setIsAddToCommunityOpen((isAddToCommunityOpen) => !isAddToCommunityOpen)
  }

  const handleRemoveFromCommunity = () => {
    resetSelection()
    // removal is async, so we need to wait for the BE to do its thing before refetching
    // it's not great but it's better than instantly refetching and having the BE not have the updated data yet
    setTimeout(() => {
      refetchAudience()
      queryClient.invalidateQueries([
        QUERY_CACHE.SUBSCRIPTION_DATA.COUNT,
        clientId,
        community?.id,
        communicationChannel,
      ])
    }, 1000)
  }

  const resetSelection = () => {
    setIndividuals([])
    setSelection({
      selected: [],
      deselected: [],
      selectAll: false,
    })
  }

  useEffect(() => {
    // Current community filter
    const communityFilter = activeCommunity.id ? toCommunityFilter(activeCommunity.id, activeCommunity.title) : null

    // If no community, must be we are in the "All Members" view
    const allMembersFilter = !activeCommunity.id ? everyoneFilter : null

    // Selection checkboxes
    const selectionFilters = toSelectionFilters(selection.selected, selection.deselected)

    // Search bar filter
    const searchBarFilter = searchTerm.length
      ? {
          operator: SelectorOperators.CONTAINS,
          operand: {
            field_key: BuiltInFields.NAME,
            field_label: fieldLabelFor(BuiltInFields.NAME),
            source: FieldSources.BUILT_IN,
            type: FieldTypes.STRING,
            value: searchTerm,
          },
        }
      : null

    // Base filters are all the filters that are not in the filter modal
    const filters = compact([communityFilter, allMembersFilter, selectionFilters, searchBarFilter])
    if (filters.length) {
      setBaseFilters({
        operator: RelationshipOperators.AND,
        operands: filters,
      })
    }
    return () => {
      setBaseFilters(null)
    }
  }, [activeCommunity, searchTerm, selection.deselected, selection.selectAll, selection.selected, setBaseFilters])

  const { data: { count: recipientCount = 0 } = {}, isInitialLoading: isInitialRecipientCountLoading } =
    useCountByQuery({
      filters: allFilters,
      traceId: 'communities',
      communicationChannel,
    })

  const renderUpdating = () => {
    return (
      <StyledCenteredContainer>
        <NerdyFlag size={150} />
        <StyledCenteredHeading>Adding Members to your community.</StyledCenteredHeading>
        <StyledCenteredCopy>This should only take a moment.</StyledCenteredCopy>
      </StyledCenteredContainer>
    )
  }

  const renderEmpty = () => {
    const isSearching = searchTerm.length > 0
    const viewingAllMembers = history.location.pathname === '/communities'
    const emptyHeader = isSearching ? 'No results found' : 'This community has no Members'

    return (
      <StyledCenteredContainer>
        <ThinkingFlag color={COLORS?.BORDERS} size={150} />
        <StyledCenteredCopy>{emptyHeader}</StyledCenteredCopy>
        {!isSearching &&
          !viewingAllMembers &&
          filterModalFilters !== null &&
          activeCommunity.type !== COMMUNITY_TYPES.PROTECTED && (
            <Button color={COLORS?.LINKS} onClick={addMembers} variant={BUTTON_VARIANTS.SOLID_PILL}>
              + Add Members
            </Button>
          )}
      </StyledCenteredContainer>
    )
  }

  const renderTitle = () => {
    const dotColor =
      activeCommunity.color ||
      (activeCommunity.title ? stringToColor(activeCommunity.title) : COLORS?.COMMUNITY_COLOR_DEFAULT)
    const updating = activeCommunity.updateInProgress
    const isFavorite =
      activeCommunity.type === COMMUNITY_TYPES.DEFAULT && activeCommunity.title?.toLocaleLowerCase() === 'favorites'

    let indicator
    if (isFavorite) {
      indicator = (
        <StyledStar>
          <FontAwesomeIcon color={dotColor} icon="star" />
        </StyledStar>
      )
    } else {
      indicator = (
        <ColorGrid align="left" color={dotColor} offsetTop={10} onChange={onSelectColor}>
          <CommunityDot
            badge={
              activeCommunity.communicationChannels?.includes(CommunicationChannel.AMB) && (
                <AppleIconCircled
                  data-testid="dot-apple-icon"
                  filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))"
                  size={18}
                  style={{ marginTop: SPACING[2] }}
                />
              )
            }
            color={dotColor}
          />
        </ColorGrid>
      )
    }

    return (
      <>
        {activeCommunityId && indicator}
        <StyledTitle>{activeCommunity.title}</StyledTitle>
        {activeCommunityId && isEditable && !updating && (
          <StyledEditPencil onClick={openEditCommunityModal}>
            <EditPencil color={COLORS?.SUBTEXT} data-testid={`edit-pencil-${activeCommunityId}`} size="15" />
          </StyledEditPencil>
        )}
      </>
    )
  }

  const renderHeaderWithFilters = () => {
    const recipients = numeral(recipientCount).format('0[.]0a')
    const newMessageLabel =
      recipientCount > 0 && filterModalFilters !== null ? `Send Message (${recipients})` : 'Send Message'
    const nlpLanguage = humanizeComplexFilter(filterModalFilters, recipientCount, { communities: communityLookup })

    const nlpText = `Showing ${nlpLanguage}`

    return (
      <StyledHeader>
        <div>
          <FiltersAppliedTitle>
            {recipientCount} Members{activeCommunity?.title && ` in ${activeCommunity.title}`}
          </FiltersAppliedTitle>
          <StyledFilterApplied onClick={() => setIsFilterModalOpen(true)}>Filters applied</StyledFilterApplied>
          <StyledNLP onClick={() => setIsFilterModalOpen(true)}>{nlpText}</StyledNLP>
        </div>
        <StyledSentContainer>
          {shouldShowDataExport && (
            <StyledDataExportButton onClick={goToDataExport} variant={BUTTON_VARIANTS.OUTLINE}>
              {t('exportData')}
            </StyledDataExportButton>
          )}
          {!isOpen && (
            <Button disabled={isOpenComposeDisabled} onClick={openCompose}>
              {newMessageLabel}
            </Button>
          )}
        </StyledSentContainer>
      </StyledHeader>
    )
  }

  const renderHeader = () => {
    const updating = activeCommunity.updateInProgress
    const abbrevTitle =
      activeCommunity.title && activeCommunity.title.length > 33
        ? `${activeCommunity.title.substr(0, 33)}...`
        : activeCommunity.title

    const recipients = numeral(recipientCount).format('0[.]0a')
    const newMessageLabel =
      recipientCount > 0 && filterModalFilters !== null ? `Send Message (${recipients})` : 'Send Message'

    const sendMessageAction = isOpenComposeDisabled ? [] : ['Send Message']
    const communityActions = activeCommunityId ? ['Add Members', 'Edit Community', 'Edit Community Keywords'] : []
    const dataExportsAction = shouldShowDataExport ? ['Export Data'] : []
    const deleteCommunityAction = activeCommunityId ? ['Delete Community'] : []
    const actions = [...sendMessageAction, ...communityActions, ...dataExportsAction, ...deleteCommunityAction]

    const shouldShowSendMessage = isSendMessageVisible(isOpen, activeNav, activeCommunityId)

    const showDottedMenu = activeCommunityId && isEditable
    const showExportButton = shouldShowDataExport && !showDottedMenu
    return (
      <StyledHeader>
        <StyledFilterTitle>{renderTitle()}</StyledFilterTitle>
        {!updating && (
          <StyledSentContainer>
            {showExportButton && (
              <StyledDataExportButton onClick={goToDataExport} variant={BUTTON_VARIANTS.OUTLINE}>
                {t('exportData')}
              </StyledDataExportButton>
            )}
            {shouldShowSendMessage && (
              <Button disabled={isOpenComposeDisabled} onClick={openCompose}>
                {newMessageLabel}
              </Button>
            )}
            {showDottedMenu && (
              <Menu
                trigger={
                  <StyledActionButton aria-label="Actions" data-testid={`actions-dropdown-${activeCommunityId}`}>
                    <FontAwesomeIcon color={COLORS?.TEXT} icon="ellipsis-h" />
                  </StyledActionButton>
                }
              >
                {actions.map((action) => (
                  <Menu.Item
                    data-testid={`action-${kebabCase(action)}`}
                    key={`action-${kebabCase(action)}`}
                    onClick={() => onAction(action)}
                  >
                    <Layout color={action === 'Delete Community' ? COLORS?.ERRORS : COLORS?.TEXT}>{action}</Layout>
                  </Menu.Item>
                ))}
              </Menu>
            )}
            {deleteDialogOpen && (
              <Dialog
                message={
                  <>
                    This will delete <strong>{abbrevTitle}</strong>, but none of the Members or messages.
                  </>
                }
                title={`Delete ${activeCommunity.title}`}
              >
                <Dialog.Action onClick={closeDeleteDialog}>Cancel</Dialog.Action>
                <Dialog.Action onClick={deleteCommunity} variant={DIALOG_VARIANTS.DESTRUCTIVE}>
                  Delete
                </Dialog.Action>
              </Dialog>
            )}
          </StyledSentContainer>
        )}
      </StyledHeader>
    )
  }

  const NavListOptions = useMemo(() => {
    const counts = activeCommunityId ? fanCounts : allFanCounts
    const smsPrefix = isWhatsAppEnabled || isAmbEnabled ? 'SMS ' : ''
    const smsRecipients = numeral(counts.sms || 0).format('0[.]0a')
    const smsRecipientsLabel = `${smsPrefix}Members (${smsRecipients})`

    const whatsAppRecipients = numeral(counts.whatsapp || 0).format('0[.]0a')
    const whatsAppRecipientsLabel = `WhatsApp Members (${whatsAppRecipients})`

    const ambRecipients = numeral(counts.amb || 0).format('0[.]0a')
    const ambRecipientsLabel = `Apple Members (${ambRecipients})`

    const communicationChannels = activeCommunityId
      ? (activeCommunity.communicationChannels &&
          activeCommunity.communicationChannels.length > 0 &&
          activeCommunity.communicationChannels) ||
        compact([CommunicationChannel.SMS, isWhatsAppEnabled && CommunicationChannel.WHATS_APP])
      : client?.communicationChannels

    const options = compact(
      flatten(
        communicationChannels
          ?.sort((a) => (a === CommunicationChannel.SMS ? -1 : a === CommunicationChannel.WHATS_APP ? 0 : 1))
          .map((channel) => {
            switch (channel) {
              case CommunicationChannel.SMS:
                return [
                  activeCommunityId && {
                    label: `${smsPrefix}Messages`,
                    icon: <SmsIcon color={COLORS.HEADLINES} size={18} />,
                    value: NAV_MAP.MESSAGES,
                  },
                  {
                    label: smsRecipientsLabel,
                    icon: <SmsIcon color={COLORS.HEADLINES} size={18} />,
                    value: NAV_MAP.MEMBERS,
                  },
                ]
              case CommunicationChannel.WHATS_APP:
                return isWhatsAppEnabled
                  ? [
                      activeCommunityId && {
                        label: 'WhatsApp Messages',
                        icon: <WhatsAppIcon size={18} />,
                        value: NAV_MAP.WHATS_APP_MESSAGES,
                      },
                      {
                        label: whatsAppRecipientsLabel,
                        icon: <WhatsAppIcon size={18} />,
                        value: NAV_MAP.WHATS_APP_MEMBERS,
                      },
                    ]
                  : []
              case CommunicationChannel.AMB:
                return isAmbEnabled
                  ? [
                      activeCommunityId && {
                        label: 'Apple Messages',
                        icon: <AppleIcon size={18} />,
                        value: NAV_MAP.AMB_MESSAGES,
                      },
                      {
                        label: ambRecipientsLabel,
                        icon: <AppleIcon size={18} />,
                        value: NAV_MAP.AMB_MEMBERS,
                      },
                    ]
                  : []
              default:
                return []
            }
          }),
      ),
    )

    if (isShopifyCommunity) {
      options.push({
        label: 'Insights',
        icon: <InfoIcon size={18} />,
        value: NAV_MAP.INSIGHTS,
      })
    }

    return options
  }, [
    COLORS.HEADLINES,
    activeCommunity.communicationChannels,
    activeCommunityId,
    client?.communicationChannels,
    fanCounts,
    allFanCounts,
    isAmbEnabled,
    isShopifyCommunity,
    isWhatsAppEnabled,
  ])

  const renderSearchbar = () => {
    return (
      <StyledSearch>
        <SearchBar
          onChange={handleSearchChange}
          placeholder={
            [NAV_MAP.MEMBERS, NAV_MAP.WHATS_APP_MEMBERS, NAV_MAP.AMB_MEMBERS].includes(activeNav)
              ? 'Search members'
              : 'Search messages'
          }
          value={searchTerm}
        />
        {activeCommunityId && activeCommunity.type !== COMMUNITY_TYPES.PROTECTED && (
          <Button color={COLORS?.LINKS} onClick={addMembers} variant={BUTTON_VARIANTS.SOLID_PILL}>
            + Add Members
          </Button>
        )}
      </StyledSearch>
    )
  }

  const renderSelectAndFilter = () => {
    if (activeNav !== NAV_MAP.MEMBERS && activeNav !== NAV_MAP.WHATS_APP_MEMBERS && activeNav !== NAV_MAP.AMB_MEMBERS) {
      return null
    }

    let sortOptions = [
      {
        label: 'Last Name',
        value: 'name',
      },
      {
        label: 'Age',
        value: 'age',
      },
      {
        label: 'City',
        value: 'city',
      },
      {
        label: 'State',
        value: 'state',
      },
      {
        label: 'Join Date',
        value: 'inserted_at',
      },
    ]

    if (activeCommunityId) {
      sortOptions = sortOptions.concat({
        label: 'Date Added',
        value: 'joined_community_at',
      })
    }

    let activeSorter = sortOptions.find((filter) => sorters[0]?.id === filter.value)
    if (activeSorter === undefined) activeSorter = sortOptions[0]
    const direction = sorters[0]?.desc ? 'descending' : 'ascending'

    const empty = !data?.pages.length && sortOptions.length === 0
    const shouldRenderSortAndFilter =
      activeNav === NAV_MAP.MEMBERS && !empty && (!isOpen || (isOpen && window.innerWidth > 1250))

    const shouldShowSelectAll = isSelectAllCheckboxVisible(empty, activeNav, activeCommunityId)

    if (!shouldRenderSortAndFilter && !shouldShowSelectAll) return null

    return (
      <StyledFiltersContainer>
        <StyledFiltersControls>
          {shouldShowSelectAll && (
            <StyledFiltersSelect>
              <input onChange={onSelectAll} ref={selectAllRef} type="checkbox" />
              <span>Select All</span>
            </StyledFiltersSelect>
          )}
          {shouldRenderSortAndFilter && (
            <StyledFiltersFilter>
              <Filter activeFilter={activeSorter.label} align="right" icon={false} label="Sort By">
                {sortOptions.map((option) => {
                  let selected = false
                  if (option.value === sorters[0]?.id) {
                    selected = true
                  }

                  return (
                    <Filter.Option key={option.value} onClick={sortBy(option)} selected={selected}>
                      {option.label}
                    </Filter.Option>
                  )
                })}
                <Filter.Sort direction={direction} onClick={sortByDirection} />
              </Filter>
              <StyledFiltersFilterButton
                onClick={() => {
                  resetSelection()
                  setIsFilterModalOpen(true)
                }}
              >
                Filter
                {filterModalFilters !== null
                  ? ` (${Array.from(visitFilters(filterModalFilters)).filter(isSelectorFilter).length})`
                  : ''}
                <FontAwesomeIcon icon="caret-down" />
              </StyledFiltersFilterButton>
            </StyledFiltersFilter>
          )}
        </StyledFiltersControls>
      </StyledFiltersContainer>
    )
  }

  const renderActiveFilters = () => {
    return (
      <Layout margin="0px 16px">
        <StyledFilterPills
          communityLookup={communityLookup}
          filters={filterModalFilters}
          onRemove={(_, node) => {
            const updatedSubtree = removeNode(node)
            const root = getRoot(updatedSubtree)

            setIncludedFilters(serializeFilters(root))
          }}
        />
      </Layout>
    )
  }

  const renderItems = () => {
    const isRemoveMemberDisabled =
      !activeCommunityId || selection.selected.length > 0 || selection.deselected.length > 0 || selection.selectAll

    const sortField = sorters && sorters[0] && sorters[0]?.id
    if (data?.pages.length === 0 || activeCommunity.updateInProgress) {
      return null
    }

    return data?.pages.map((item) => {
      let checked = false
      if (selection.selectAll) {
        checked = true
        if (selection.deselected.includes(item.fanSubscriptionId)) {
          checked = false
        }
      } else {
        checked = selection.selected.includes(item.fanSubscriptionId)
      }

      const isDisabled = !canReceiveCampaigns({
        communicationChannel: item.communicationChannel,
        stateFlags: item.stateFlags,
      })

      const disabledReason =
        isDisabled && item.communicationChannel === CommunicationChannel.AMB
          ? t('convo.ambMessagingDisabled')
          : undefined

      return (
        <MemberListItem
          checked={checked}
          disabled={isDisabled}
          disabledReason={disabledReason}
          fan={normalizeFan(item) as Fan}
          highlight={sortField}
          key={item.id}
          onChange={onMemberToggle}
          onClick={onMemberClick}
          onRemove={
            isRemoveMemberDisabled
              ? undefined
              : async () => {
                  await removeFanFromCommunity({
                    fan: normalizeFan(item) as Fan,
                    community: activeCommunity as unknown as Community,
                  })
                  setIndividuals([])
                  setIncludedFilters(null)
                  setAudienceSort(sorters.length ? sorters : defaultSorting)
                  handleRemoveFromCommunity()
                  refetchInbox()
                }
          }
        />
      )
    })
  }

  const renderMembers = () => {
    const updating = activeCommunity.updateInProgress

    if (updating) {
      return renderUpdating()
    }

    if (!data?.pages.length && !loading) {
      return renderEmpty()
    }

    return (
      <InfiniteScroll
        data-testid="community-members-list"
        hasMore={loading || hasNextPage}
        loadMore={loadMore}
        loading={loading}
        threshold={100}
      >
        {renderItems()}
      </InfiniteScroll>
    )
  }

  const renderMessages = () => {
    const updating = activeCommunity.updateInProgress

    return (
      <>
        {updating && renderUpdating()}
        {!updating && <Messages communicationChannel={communicationChannel} />}
      </>
    )
  }

  return (
    <StyledRoot>
      <FilterModal
        active={isFilterModalOpen}
        hasFilters={filterModalFilters !== null}
        onApplyFilters={() => resetSelection()}
        onClose={() => setIsFilterModalOpen(false)}
        recipientCount={recipientCount}
      />
      <StyledLeftColumn>
        <CommunitiesList onItemClick={onCommunityClick} title="Communities" />
      </StyledLeftColumn>
      <StyledRightColumn
        $backgroundColor={
          activeNav === NAV_MAP.INSIGHTS ? COLORS?.APP_BACKGROUND_LEVEL_1 : COLORS?.APP_BACKGROUND_LEVEL_3
        }
        data-testid="communities-right-pane"
      >
        <StyledRightHeader>
          {filterModalFilters === null ? renderHeader() : renderHeaderWithFilters()}
          <StyledSwitcherHeader>
            <SelectWithIcon
              className="community-item-type"
              data-testid="community-item-type-selector"
              menuName="community-item-type"
              onChange={toggleNav}
              options={NavListOptions}
              value={activeNav}
            />
            {!updating && activeNav !== NAV_MAP.INSIGHTS && renderSearchbar()}
          </StyledSwitcherHeader>
          {!updating && renderSelectAndFilter()}
          {!updating && renderActiveFilters()}
        </StyledRightHeader>
        <StyledRightContent>
          {(activeNav === NAV_MAP.MEMBERS ||
            activeNav === NAV_MAP.WHATS_APP_MEMBERS ||
            activeNav === NAV_MAP.AMB_MEMBERS) &&
            renderMembers()}
          {(activeNav === NAV_MAP.MESSAGES ||
            activeNav === NAV_MAP.WHATS_APP_MESSAGES ||
            activeNav === NAV_MAP.AMB_MESSAGES) &&
            renderMessages()}
          {activeNav === NAV_MAP.INSIGHTS && (
            <Shopify
              css={`
                margin: ${SPACING[4]};
              `}
              hideCommunityLink
            />
          )}
        </StyledRightContent>
      </StyledRightColumn>
      {isAddToCommunityOpen ? (
        <AddToCommunityActionPanel
          communicationChannel={communicationChannel}
          filters={allFilters as MemberDataFilter}
          onBack={handleToggleAddToCommunity}
          recipientCount={recipientCount}
        />
      ) : isAnySelected ? (
        <CommunityMemberSelectionActionPanel
          communicationChannel={communicationChannel}
          filters={allFilters as MemberDataFilter}
          onClickAddToCommunity={handleToggleAddToCommunity}
          onClickRemoveFromCommunity={handleRemoveFromCommunity}
          onClose={resetSelection}
          recipientCount={recipientCount}
          recipientCountIsLoading={isInitialRecipientCountLoading}
          selectAll={selection.selectAll}
          selectedCommunity={activeCommunity as Community}
          selectedFans={individuals}
        />
      ) : (
        isMemberDetailOpen && <MemberDetailActionPanelRouter communities={allCommunities} fan={fan as unknown as Fan} />
      )}
      <ComposeMessage
        enableAiMessageGenerator
        initialFilterState={{ communicationChannel }}
        onClose={closeCompose}
        open={isComposeModalOpen}
        readOnlyRecipients
      />
      {isAddMembersModalOpen && (
        <MembersModal
          isOpen={isAddMembersModalOpen}
          onClose={() => setIsAddMembersModalOpen(false)}
          onSave={async (fans) => {
            await addFans({ community: activeCommunity as unknown as Community, fans })
            showToastMessage({
              message: `${fans.length} fans has been added to ${activeCommunity.title}`,
            })
            const sorted = { id: 'joined_community_at', desc: true }
            refetchInbox()
            setAudienceSort([sorted])
            setSelection({
              deselected: [],
              selected: [],
              selectAll: false,
            })
            setIndividuals([])
            invalidateCommunityCount(activeCommunity.id || '', activeCommunity.title || '')
          }}
        />
      )}
      {isCreateCommunityModalOpen && (
        <CreateCommunityModal
          communityId={activeCommunityId}
          onClose={() => setIsCreateCommunityModalOpen(false)}
          onUpdated={() => {
            const sorted = { id: 'joined_community_at', desc: true }
            refetchInbox()
            setAudienceSort([sorted])
            setSelection({
              deselected: [],
              selected: [],
              selectAll: false,
            })
            setIndividuals([])
          }}
        />
      )}
      {isEditKeywordsModalOpen && (
        <KeywordsCreateModal
          keywords={normalizeKeywords(community?.tagKeywords)}
          onClose={() => setIsEditKeywordsModalOpen(false)}
          onSave={({ keywords, keywordResponder }) => {
            if (activeCommunity.id) {
              updateCommunity({
                clientId,
                id: activeCommunity.id,
                title: activeCommunity.title as string,
                color: activeCommunity.color as string,
                autoResponderText: keywordResponder,
                unsetAutoResponder: keywordResponder === '',
                oldKeywords: community?.tagKeywords,
                newKeywords: keywords,
              })
            }
          }}
        />
      )}
    </StyledRoot>
  )
}

export default Communities
