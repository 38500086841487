import { BUTTON_VARIANTS, Button } from '@community_dev/pixels'
import React from 'react'

import { ROUTES } from 'constants/routes'
import { StyledAction, StyledBack, StyledVideo } from 'screens/Onboarding/styled'
import { generalConfig } from 'utils/config'

type DescriptionProps = {
  actionLabel: string
  animation: string
  alt?: string
  color?: string
  children: React.ReactNode
  disabled?: boolean
  onAction: (...args: any[]) => any
}

const Content = ({ url, alt }) => {
  if (url.endsWith('.mp4')) {
    return (
      <video autoPlay height="240" muted playsInline width="240">
        <source src={`${generalConfig.staticUrl}/${url}`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    )
  }

  if (url.endsWith('.gif')) {
    return <img alt={alt} src={`${generalConfig.staticUrl}/${url}`} width="400" />
  }

  return null
}
export function Description({
  actionLabel,
  animation,
  children,
  color = '#f6f8fc',
  disabled = false,
  onAction,
  alt,
}: DescriptionProps): JSX.Element {
  return (
    <>
      <StyledVideo color={color}>
        <Content alt={alt} url={animation} />
      </StyledVideo>
      {children}
      <StyledAction>
        <Button disabled={disabled} onClick={onAction} variant={BUTTON_VARIANTS.ACTION}>
          {actionLabel}
        </Button>
      </StyledAction>

      <StyledBack to={ROUTES.ONBOARDING.SUMMARY}>Back</StyledBack>
    </>
  )
}
