import { Block, ChevronRightIcon, SPACING, Tags } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import styled, { useTheme } from 'styled-components'

import { Community } from 'api/community'
import { Fan } from 'api/fans'
import { COMMUNITY_TYPES } from 'constants/communities'
import { useRemoveFanFromCommunity } from 'containers/InboxList/MemberDetailActionPanel/member-detail-queries'

const StyledCommunities = styled.button`
  margin-top: ${SPACING[4]};
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  cursor: pointer;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledEmpty = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
`

type MemberCommunitiesProps = {
  communities: Community[]
  fan?: Fan
  isLoading: boolean
  onAddToCommunity(): void
}

export function MemberCommunities({ communities, fan, onAddToCommunity }: MemberCommunitiesProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { removeFan } = useRemoveFanFromCommunity()

  function handleRemove(tagId: string) {
    const community = communities.find((tag) => tag.id === tagId)
    if (!fan || !community) return
    removeFan({ fan, community })
  }

  // Amb fans can't be added or removed from communities
  // They select their own interest groups (communities) when they sign up
  const isAmb = fan?.communicationChannel === CommunicationChannel.AMB

  return (
    <Block title="Communities">
      {fan && fan.communities?.length > 0 ? (
        <Tags
          onRemove={isAmb ? undefined : handleRemove}
          tags={fan.communities.filter((c) => c.type !== COMMUNITY_TYPES.DEFAULT)}
        />
      ) : (
        <StyledEmpty>None</StyledEmpty>
      )}
      {isAmb ? null : (
        <StyledCommunities onClick={onAddToCommunity}>
          Add to Community <ChevronRightIcon color={COLORS?.DIVIDERS} />
        </StyledCommunities>
      )}
    </Block>
  )
}
