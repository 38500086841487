import { ListItem, SPACING, Tooltip, Layout } from '@community_dev/pixels'
import noop from 'lodash/noop'
import React, { MouseEvent } from 'react'
import styled, { css } from 'styled-components'

import { Fan } from 'api/fans'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { AddedButton } from 'components/Communities'
import { MemberAvatar } from 'components/MemberAvatar'

type StyledDetailsProps = {
  $highlight?: boolean
}

const StyledDetail = styled.span<StyledDetailsProps>`
  ${({ $highlight, theme }) => $highlight && theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-size: 13px;
`

type StyledMemberProps = {
  $acting?: boolean
}

const StyledMember = styled.div<StyledMemberProps>`
  ${({ $acting }) =>
    $acting &&
    css`
      height: 0px;
      opacity: 0;
      transition: all 0.3s;
    `}
`

const StyledPrefix = styled.button`
  border: none;
  padding: 0;
  background: none;
  display: flex;
  cursor: pointer;
  align-items: center;
`

const StyledAction = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: ${SPACING[4]};
`

type MemberListItemProps = {
  fan: Fan
  checked?: boolean
  highlight?: string
  onChange: (...args: any[]) => any
  onClick: (...args: any[]) => any
  onAdd?: (...args: any[]) => any
  onRemove?: (...args: any[]) => any
  disabled?: boolean
  disabledReason?: string
}

class MemberListItem extends React.PureComponent<MemberListItemProps> {
  static defaultProps = {
    fan: {
      id: '',
      initials: '',
      fullName: '',
      age: '',
      gender: '',
      city: '',
      stateAbbrev: '',
      communities: [],
    },
    checked: false,
    highlight: '',
    onChange: noop,
    onClick: noop,
    onAdd: null,
    onRemove: null,
    disabled: false,
    disabledReason: undefined,
  }

  constructor(props: MemberListItemProps) {
    super(props)
    this.state = { acting: false }
  }

  onChange = (e: MouseEvent): void => {
    e.stopPropagation()
    this.props.onChange(this.props.fan, e)
  }

  onClick = (e: MouseEvent): void => {
    const target = e.target as any
    if (target.type !== 'checkbox') {
      this.props.onClick(this.props.fan)
    }
  }

  onAction = (e: Event): void => {
    e.stopPropagation()
    const { fan, onAdd, onRemove } = this.props
    this.setState({ acting: true })
    if (onAdd) return onAdd(fan)
    if (onRemove) return onRemove(fan)
  }

  render(): JSX.Element {
    const { fan, checked, highlight, onAdd, onRemove, disabled, disabledReason } = this.props
    const actionType = onAdd ? 'add' : onRemove ? 'remove' : ''
    const isFavorite = fan.communities?.find((c) => c.type === 'default' && c.title.toLocaleLowerCase() === 'favorites')

    return (
      <StyledMember>
        <ListItem
          data-testid={`community-member-${fan.id}`}
          label={<span data-private="redact">{fan.fullName}</span>}
          onClick={this.onClick}
          prefix={
            <StyledPrefix disabled={disabled} onClick={this.onChange}>
              <Tooltip content={disabledReason} disabled={!disabled || !disabledReason}>
                <Layout left="-2px" marginRight={SPACING[2]} position="relative">
                  <input checked={checked} disabled={disabled} readOnly type="checkbox" />
                </Layout>
              </Tooltip>
              <MemberAvatar
                badge={
                  <CommunicationChannelIcon
                    communicationChannel={fan.communicationChannel}
                    filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))"
                    size={19}
                    style={{ marginTop: SPACING[2] }}
                  />
                }
                fan={fan}
                graphicUrl={fan.graphicUrl}
              />
            </StyledPrefix>
          }
          subtext={
            <span data-private="redact">
              <StyledDetail $highlight={highlight === 'gender'}>{fan.gender}</StyledDetail>
              <span> | </span>
              <StyledDetail $highlight={highlight === 'age'}>{fan.age || fan.ageText}</StyledDetail>
              <span> | </span>
              <StyledDetail $highlight={highlight === 'city'}>{fan.city || fan.cityState}</StyledDetail>
              {fan.city && fan.countryCode && (
                <>
                  <span>, </span>
                  {fan.countryCode === 'US' || fan.countryCode === 'CA' ? (
                    <StyledDetail $highlight={highlight === 'state'}>{fan.stateAbbrev}</StyledDetail>
                  ) : (
                    // for locations outside of north america, we display the
                    // country instead of the state.
                    <StyledDetail $highlight={highlight === 'state'}>{fan.country}</StyledDetail>
                  )}
                </>
              )}
              {highlight === 'inserted_at' && (
                <span>
                  <span> | </span>
                  <span>Joined {new Date(fan.joined).toLocaleDateString()}</span>
                </span>
              )}
              {highlight === 'joined_community_at' && (
                <span>
                  <span> | </span>
                  <StyledDetail $highlight>Added {new Date(fan.joinedCommunityAt).toLocaleDateString()}</StyledDetail>
                </span>
              )}
            </span>
          }
          suffix={
            <StyledAction>
              {actionType && <AddedButton added id={fan.id} onAdd={this.onAction} onRemove={this.onAction} />}
            </StyledAction>
          }
        >
          {isFavorite && <ListItem.Indicators.Favorite />}
        </ListItem>
      </StyledMember>
    )
  }
}

export default MemberListItem
