import { BORDER_RADIUS, MissingLinkImageIcon, SPACING } from '@community_dev/pixels'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { Campaign } from 'api/campaign'
import { useUrlMetadata } from 'components/links/useUrlMetadata'
import { getHost, isVideo } from 'utils/link'

const StyledMissingIcon = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme?.COLORS?.BORDERS};
  border-top-left-radius: ${SPACING[2]};
  border-bottom-left-radius: ${SPACING[2]};
  display: flex;
  height: 72px;
  justify-content: center;
  width: 72px;
`

const StyledVideo = styled.video`
  max-height: 72px;
  border-radius: ${SPACING[2]};
  box-shadow: ${({ theme }) => theme.SHADOWS.CARD};
`

const StyledLinkContent = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-left: ${SPACING[3]};
`

const StyledLinkTitle = styled.h4`
  margin: 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
`

const StyledLinkUrl = styled.span`
  margin: 0;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

const StyledLinkImage = styled.div<{ $imgSrc: string }>`
  height: 72px;
  width: 72px;
  border-top-left-radius: ${SPACING[2]};
  border-bottom-left-radius: ${SPACING[2]};
  background-image: url(${({ $imgSrc }) => $imgSrc});
  background-position: center;
  background-size: cover;
`

const StyledLink = styled.a`
  border-radius: ${BORDER_RADIUS[1]};
  box-shadow: ${({ theme }) => theme.SHADOWS.CARD};
  display: flex;
  height: 72px;
  background: ${({ theme }) => theme?.COLORS?.LINK_PREVIEW_BACKGROUND};
  padding-right: ${SPACING[3]};
  color: ${({ theme }) => theme?.COLORS?.BUTTON_DEFAULT_TEXT};
  text-decoration: none;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

const StyledLinkLoader = styled.div`
  align-items: center;
  border-radius: ${BORDER_RADIUS[1]};
  box-shadow: ${({ theme }) => theme.SHADOWS.CARD};
  display: flex;
  height: 72px;
  width: 200px;
  justify-content: center;
  padding: ${SPACING[6]};
  color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
  text-align: center;
  text-decoration: none;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

const StyledMoreLinks = styled.span`
  padding-left: ${SPACING[4]};
  display: flex;
  align-items: center;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

const shortenText = (string: string, maxLength = 25): string => {
  const shortened = string.length > maxLength

  return `${string.slice(0, maxLength)}${shortened ? '...' : ''}`
}

type DisplayLinksProps = {
  linkCount: number
  links: Campaign['linkInfo']
}

export function DisplayLinks({ links, linkCount }: DisplayLinksProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const onClick = (event) => {
    event.stopPropagation()
  }
  const link = links[0]
  const hasMoreLinks = linkCount > 1

  const {
    isInitialLoading,
    isError,
    data: urlMetadata,
  } = useUrlMetadata({
    url: link?.url,
  })

  if (isVideo(link.url)) {
    return <StyledVideo aria-label={t('campaigns.mediaPreview')} muted={true} src={link.url} />
  }

  if (isInitialLoading) {
    return (
      <StyledLinkLoader aria-label="loading">
        <ContentLoader
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          height={72}
          speed={2}
          viewBox="0 0 272 116"
          width={270}
        >
          <rect height="48" rx="4" ry="4" width="272" x="0" y="0" />
          <rect height="48" rx="4" ry="4" width="272" x="0" y="52" />
        </ContentLoader>
      </StyledLinkLoader>
    )
  }

  if (isError) {
    return (
      <>
        <StyledLink href={link.url} onClick={onClick} target="_blank">
          <StyledMissingIcon>
            <MissingLinkImageIcon color={COLORS?.APP_BACKGROUND_LEVEL_3} />
          </StyledMissingIcon>
          <StyledLinkContent>
            <StyledLinkUrl>{shortenText(link.url)}</StyledLinkUrl>
          </StyledLinkContent>
        </StyledLink>
        {hasMoreLinks && <StyledMoreLinks>{t('campaigns.moreLinks', { number: linkCount - 1 })}</StyledMoreLinks>}
      </>
    )
  }

  return (
    <>
      <StyledLink href={link.url} onClick={onClick} target="_blank">
        {urlMetadata?.image?.url ? (
          <StyledLinkImage
            $imgSrc={urlMetadata.image.url}
            aria-label={urlMetadata.title || t('campaigns.imagePreview')}
          />
        ) : (
          <StyledMissingIcon>
            <MissingLinkImageIcon color={COLORS?.APP_BACKGROUND_LEVEL_3} />
          </StyledMissingIcon>
        )}
        <StyledLinkContent>
          {urlMetadata?.title && <StyledLinkTitle>{shortenText(urlMetadata?.title || '')}</StyledLinkTitle>}
          <StyledLinkUrl>{getHost(urlMetadata?.url)}</StyledLinkUrl>
        </StyledLinkContent>
      </StyledLink>
      {hasMoreLinks && <StyledMoreLinks>{t('campaigns.moreLinks', { number: linkCount - 1 })}</StyledMoreLinks>}
    </>
  )
}
