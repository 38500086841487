import { MemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { ActionButton, MessageDisabledIcon, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { SelectionFavoriteActionButton } from './SelectionFavoriteActionButton'

import { ComposeMessage } from 'components/ComposeMessage'
import { ComposeProvider } from 'components/ComposeMessage/ComposeContext'
import { FilterProvider } from 'contexts/FilterProvider/FilterProvider'
import { useClient } from 'hooks/useClient'

const StyledContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: ${SPACING[4]};
`

const StyledIcon = styled.div`
  position: relative;
  top: -2px;
  margin-bottom: -4px;
`

type CommunityMemberSelectionActionButtonsProps = {
  communicationChannel: CommunicationChannel
  filters: MemberDataFilter
  recipientCount: number
  onClickAddToCommunity(): void
}

export function CommunityMemberSelectionActionButtons({
  communicationChannel,
  filters,
  recipientCount,
  onClickAddToCommunity,
}: CommunityMemberSelectionActionButtonsProps): JSX.Element {
  const { COLORS } = useTheme()
  const [isComposeModalOpen, setIsComposeModalOpen] = useState(false)
  const { data: client } = useClient()
  const hasCommunicationChannel = client?.communicationChannels?.some((channel) => channel === communicationChannel)

  return (
    <StyledContent>
      <ActionButton
        disabled={!hasCommunicationChannel}
        icon={
          !hasCommunicationChannel ? (
            <StyledIcon>
              <MessageDisabledIcon color={COLORS.INPUT_TEXT_PLACEHOLDER} size={20} />
            </StyledIcon>
          ) : (
            'comment'
          )
        }
        id="detail-pane-message"
        label="message"
        onClick={() => setIsComposeModalOpen(true)}
      />

      <ActionButton
        disabled={
          // Apple Messages does not support adding to community
          communicationChannel === CommunicationChannel.AMB
        }
        icon="plus"
        id="detail-pane-add-to"
        label="add to"
        onClick={onClickAddToCommunity}
      />
      <SelectionFavoriteActionButton
        communicationChannel={communicationChannel}
        disabled={
          // Apple Messages does not support adding to community
          communicationChannel === CommunicationChannel.AMB
        }
        filters={filters}
        recipientCount={recipientCount}
      />

      <FilterProvider includedFilters={filters}>
        <ComposeProvider>
          <ComposeMessage
            dynamicFields={false}
            initialFilterState={{
              communicationChannel,
            }}
            onClose={() => setIsComposeModalOpen(false)}
            open={isComposeModalOpen}
            readOnlyRecipients
          />
        </ComposeProvider>
      </FilterProvider>
    </StyledContent>
  )
}
