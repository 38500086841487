import { InfiniteScroll } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledRoot = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const StyledInfiniteScroll = styled(InfiniteScroll)`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`
