import React from 'react'

export function HighlightMatchedText({ children }: { children: string }): JSX.Element {
  const items: Array<string | React.ReactElement> = []
  const startIndex = children.indexOf('[[')
  if (startIndex !== -1) {
    const start = children.substring(0, startIndex)
    items.push(start)
    const rest = children.substring(startIndex + 2)
    const endIndex = rest.indexOf(']]')

    if (endIndex !== -1) {
      const highlight = rest.substring(0, endIndex)
      items.push(<em>{highlight}</em>)
      const remaining = rest.substring(endIndex + 2)
      if (remaining.length) {
        items.push(<HighlightMatchedText>{remaining}</HighlightMatchedText>)
      }
    } else {
      // If we don't find the end tag, just add the rest of the string
      items.push(rest)
    }
  } else {
    items.push(children)
  }

  return <>{items}</>
}
