import { useDebouncedValue } from '@community_dev/hooks'
import {
  AppleIcon,
  BUTTON_VARIANTS,
  ElasticInput,
  FONT_SIZE,
  FONT_WEIGHT,
  Layout,
  Menu,
  SPACING,
  SmsIcon,
  Tabs,
  Typography,
  WhatsAppIcon,
} from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useQuery } from '@tanstack/react-query'
import copy from 'copy-to-clipboard'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  StyledActionButton,
  StyledColumn,
  StyledDivider,
  StyledGridLayout,
  StyledInput,
  StyledLink,
  StyledTabs,
} from '../GrowthTools.style'
import IconCopy from '../svg/copy.svg?react'
import IconDownload from '../svg/download.svg?react'

import { CharacterCounter } from './CharacterCounter'

import { getAmbSignUpUrl } from 'api/amb'
import QRCode from 'components/QRCode'
import { CAPABILITIES, CapabilityName } from 'constants/capabilities'
import { useClient } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import { useCapabilityChecks } from 'hooks/useUserCapability'
import analytics from 'utils/analytics'
import { downloadAsPng, downloadAsSvg, getShareUrl } from 'utils/shareCommunity'

const INPUT_MAX_LENGTH = 160

export type CommunicationChannelConfig = {
  capabilities?: CapabilityName[]
  tabIcon: JSX.Element
  name: string
  tabLabel: string
  urlSubtext: JSX.Element
  qrSubtext: JSX.Element
}

export function ShareCommunityUrl({
  communicationChannels,
}: {
  communicationChannels: CommunicationChannel[]
}): JSX.Element {
  const { data: client } = useClient()
  const { userHasCapability } = useCapabilityChecks()
  const [message, setMessage] = useState('')
  const debouncedMessage = useDebouncedValue(message, 500)
  const { showToastMessage } = useToastMessage()
  const { t } = useTranslation()

  const channelConfig: { [key in CommunicationChannel]: CommunicationChannelConfig } = useMemo(
    () => ({
      [CommunicationChannel.WHATS_APP]: {
        capabilities: [CAPABILITIES.FEATURE.WHATSAPP.ALL],
        tabIcon: <WhatsAppIcon size={18} style={{ marginRight: '3px' }} />,
        name: 'WhatsApp',
        tabLabel: 'WhatsApp',
        urlSubtext: <Trans i18nKey="growthTools.whatsapp.myUrlSubtext" />,
        qrSubtext: <Trans i18nKey="growthTools.whatsapp.myQrCodeSubtext" />,
      },
      [CommunicationChannel.AMB]: {
        capabilities: [CAPABILITIES.FEATURE.AMB.ALL],
        tabIcon: <AppleIcon size={17} />,
        name: 'Apple',
        tabLabel: 'Apple',
        urlSubtext: <Trans i18nKey="growthTools.amb.myUrlSubtext" />,
        qrSubtext: <Trans i18nKey="growthTools.amb.myQrCodeSubtext" />,
      },
      [CommunicationChannel.SMS]: {
        tabIcon: <SmsIcon size={17} style={{ marginRight: '4px' }} />,
        name: 'SMS',
        tabLabel: 'Community',
        urlSubtext: <Trans i18nKey="growthTools.sms.myUrlSubtext" />,
        qrSubtext: <Trans i18nKey="growthTools.sms.myQrCodeSubtext" />,
      },
    }),
    [],
  )

  const communicationChannelsSorted = useMemo(() => {
    const tabOrder = [CommunicationChannel.SMS, CommunicationChannel.WHATS_APP, CommunicationChannel.AMB]
    return (Object.keys(channelConfig) as CommunicationChannel[])
      .sort((a, b) => tabOrder.indexOf(a) - tabOrder.indexOf(b))
      .filter((channel) => {
        return (
          communicationChannels.includes(channel) &&
          (channelConfig[channel].capabilities === undefined ||
            channelConfig[channel].capabilities?.every(userHasCapability))
        )
      })
  }, [channelConfig, communicationChannels, userHasCapability])

  const { data: ambShareUrl } = useQuery({
    queryFn: () => getAmbSignUpUrl({ clientId: client!.id }).then((data) => data.url),
    enabled: client && communicationChannelsSorted.includes(CommunicationChannel.AMB),
  })

  const [selectedCommunicationChannel, setSelectedCommunicationChannel] = useState(
    communicationChannelsSorted[0] || CommunicationChannel.SMS,
  )

  const url = useMemo(
    () =>
      getShareUrl({
        communicationChannel: selectedCommunicationChannel,
        messageText: debouncedMessage,
        client,
        ambShareUrl,
      }),
    [ambShareUrl, client, debouncedMessage, selectedCommunicationChannel],
  )

  useEffect(() => {
    if (debouncedMessage) {
      analytics.track(
        analytics.events.GrowthTools.ActivationTextCustomized({
          text: debouncedMessage,
          url,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMessage])

  const onCopyUrl = (url: string) => {
    copy(url)
    showToastMessage({
      message: t('copiedToClipboard'),
    })
    analytics.track(analytics.events.GrowthTools.UrlCopied({ url }))
  }

  const handleDownloadAsSvg = (url: string, fileName: string) => {
    downloadAsSvg(url, fileName, client?.fullName)
    analytics.track(
      analytics.events.GrowthTools.QrCodeDownloaded({
        url,
      }),
    )
  }

  const handleDownloadAsPng = (url: string, fileName: string) => {
    downloadAsPng(url, fileName, client?.fullName)
    analytics.track(
      analytics.events.GrowthTools.QrCodeDownloaded({
        url,
      }),
    )
  }

  return (
    <Layout>
      <StyledTabs active={selectedCommunicationChannel} onTabSelect={setSelectedCommunicationChannel}>
        {communicationChannelsSorted.map((channel) => {
          const config = channelConfig[channel]
          return (
            <Tabs.Tab
              key={channel}
              label={
                <>
                  {config.tabIcon}
                  {config.tabLabel}
                </>
              }
              name={channel}
            >
              <StyledGridLayout>
                <Layout display="flex" flexDirection="column" height="100%">
                  <Typography component="h3" textTransform="capitalize" variant="h4">
                    {t('growthTools.myUrl', { channel: communicationChannels.length > 1 ? config.name : '' })}
                  </Typography>
                  <StyledColumn>
                    <Layout maxWidth="100%">
                      <Typography marginTop="0" variant="body2">
                        {config.urlSubtext}
                      </Typography>
                      <Typography
                        color="#919191"
                        data-testid="community-url"
                        fontWeight="bold"
                        marginRight={SPACING[4]}
                        variant="body2"
                        wordBreak="break-all"
                      >
                        {url}
                      </Typography>
                    </Layout>
                    <StyledActionButton
                      aria-label={t('growthTools.copyCommunityUrlToClipboard')}
                      onClick={() => onCopyUrl(url)}
                      variant={BUTTON_VARIANTS.LINK}
                    >
                      <IconCopy style={{ height: '18px', marginRight: SPACING[2] }} />
                      {t('copy')}
                    </StyledActionButton>
                  </StyledColumn>
                </Layout>
                <Layout display="flex" flexDirection="column" height="100%">
                  <Typography component="h3" textTransform="capitalize" variant="h4">
                    {t('growthTools.myQrCode', { channel: communicationChannels.length > 1 ? config.name : '' })}
                  </Typography>
                  <Layout alignItems="flex-start" display="flex" height="100%" marginBottom={SPACING[5]}>
                    <StyledColumn>
                      <Typography marginTop="0" variant="body2">
                        {config.qrSubtext}
                      </Typography>
                      <Menu
                        style={{ left: 0, marginTop: SPACING[2] }}
                        trigger={
                          <StyledActionButton
                            aria-label={t('growthTools.downloadQrCode', { channel: config.name })}
                            variant={BUTTON_VARIANTS.LINK}
                          >
                            <IconDownload style={{ height: '18px', marginRight: SPACING[2] }} />
                            {t('download')}
                          </StyledActionButton>
                        }
                        width={160}
                      >
                        <Menu.Item onClick={() => handleDownloadAsPng(url, `Community ${config.name} QR Code`)}>
                          {t('png')}
                        </Menu.Item>
                        <Menu.Item onClick={() => handleDownloadAsSvg(url, `Community ${config.name} QR Code`)}>
                          {t('svg')}
                        </Menu.Item>
                      </Menu>
                    </StyledColumn>
                    <Layout background="white" height="132px" marginTop="-12px" width="132px">
                      <QRCode height={132} id="qr-code-canvas" size={1000} text={url} width={132} />
                    </Layout>
                  </Layout>
                </Layout>
              </StyledGridLayout>
              <StyledDivider />
              <Typography component="h3" marginBottom={SPACING[2]} marginTop={SPACING[5]} variant="h4">
                {t('growthTools.customizeMessage')}
              </Typography>
              <StyledGridLayout>
                <Layout>
                  <Typography margin="0" variant="body2">
                    {t('growthTools.customizeMessageSubtext')}&nbsp;
                    <StyledLink
                      href="https://incommunity.zendesk.com/hc/en-us/articles/7264471248147-Pre-Populate-Texts-for-your-Audience"
                      target="_blank"
                    >
                      {t('learnMore')}
                    </StyledLink>
                  </Typography>
                </Layout>
                <Layout alignItems="center" display="flex">
                  <StyledInput>
                    <ElasticInput
                      aria-label={t('growthTools.customizeMessage')}
                      maxLength={INPUT_MAX_LENGTH}
                      maxRows={5}
                      minRows={1}
                      onChange={({ target }) => {
                        setMessage(target?.value?.substring(0, INPUT_MAX_LENGTH))
                      }}
                      placeholder={t('growthTools.customizeMessagePlaceholder')}
                      value={message}
                    />
                    <Layout
                      alignItems="center"
                      display="flex"
                      fontSize={FONT_SIZE[4]}
                      fontWeight={FONT_WEIGHT[6]}
                      paddingRight={SPACING[1]}
                    >
                      <CharacterCounter count={message.length} max={INPUT_MAX_LENGTH} />
                    </Layout>
                  </StyledInput>
                </Layout>
              </StyledGridLayout>
            </Tabs.Tab>
          )
        })}
      </StyledTabs>
    </Layout>
  )
}
