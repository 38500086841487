import { BORDER_RADIUS, SPACING, hasMedia } from '@community_dev/pixels'
import numeral from 'numeral'
import { useMemo } from 'react'
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import styled, { useTheme } from 'styled-components'

import { LinkPreview } from '../LinkPreview'
import { MediaPreview } from '../MediaPreview'
import { LinkMetric, MediaMetric } from '../useMetrics'

function determineIntervals(numberOfMinutes) {
  if (numberOfMinutes <= 10) return 1
  if (numberOfMinutes <= 30) return 9
  return 14
}

function determinePrecision(number) {
  if (number < 1000) return number
  if (number < 10000) {
    const remainder = Math.round((number % 1000) / 100)
    const roundedNumeral = numeral(number - (number % 1000)).format('0a')
    const formattedNumeral = roundedNumeral.slice(0, 1) + '.' + remainder + roundedNumeral.slice(1, 2)
    return formattedNumeral
  }
  return numeral(number).format('0a')
}

const toMetricLabelStyle = (COLORS) => ({
  fill: COLORS?.SUBTEXT,
  fontSize: 12,
})

const StyledDetailListMetrics = styled.div`
  display: flex;
`

const StyledDetailListMeta = styled.div`
  flex: 1;
`
const StyledDetailListCount = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
`

const StyledDetailListConversion = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  margin: 0;

  &::after {
    content: '%';
    font-size: 12px;
  }
`

const StyledDetailListNoData = styled.div`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.16px;
`

const StyledDetailLoadingText = styled.div`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.16px;
  margin: 10px 0px 100px 40px;
`

type StyledConversionProps = {
  $hasSummary: boolean
}

const StyledConversion = styled.div<StyledConversionProps>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: ${BORDER_RADIUS[1]};
  display: flex;
  justify-content: ${({ $hasSummary }) => ($hasSummary ? 'space-between' : 'flex-end')};
  align-items: center;
  padding-left: ${({ $hasSummary }) => ($hasSummary ? SPACING[2] : 0)};
  margin-bottom: ${SPACING[4]};
  min-height: 40px;
  overflow: hidden;
`

function extrapolateClickToEndTime(metric) {
  const extrapolatedClicks: any[] = []
  const currentClickCount = Number.isNaN(metric?.clicks[metric?.clicks.length - 2].count)
    ? NaN
    : metric?.clicks[metric?.clicks.length - 2].count
  const currentTotalCount = metric?.clicks[metric?.clicks.length - 2].totalCount
  for (let i = 61; i < 75; i += 1)
    extrapolatedClicks.push({
      totalCount: currentTotalCount,
      count: currentClickCount,
      tick: `${i}m`,
    })
  extrapolatedClicks.push({ totalCount: currentTotalCount, tick: 'now' })
  return extrapolatedClicks
}

function transformToWholeNumberConversion(portion, total) {
  return (portion / total) * 100
}

export function MetricsChart({
  metric,
  isLoading,
  totalRecipients,
}: {
  metric: LinkMetric | MediaMetric
  isLoading: boolean
  totalRecipients: number
}): JSX.Element {
  const { COLORS } = useTheme() || {}
  const hasClicks = Boolean(metric?.clicks?.length)
  if (hasClicks && metric?.clicks?.length > 61) {
    metric.clicks.pop()
    const extrapolatedClicks = extrapolateClickToEndTime(metric)
    metric.clicks.push(...extrapolatedClicks)
  }
  const totalHits = metric?.totalHits
  const currentClicksOverTotal = transformToWholeNumberConversion(totalHits, totalRecipients)
  const numberOfMinutes = metric?.clicks?.filter(
    (click) => click.tick !== 'now' || (typeof click.tick === 'number' && click.tick !== 0),
  ).length
  const metricUrl = metric?.url
  const isMedia = hasMedia(metricUrl)
  const formattedTotalHits = determinePrecision(totalHits)
  const formattedTotalRecipients = determinePrecision(totalRecipients)
  const currentTicks = currentClicksOverTotal >= 50 ? [0, 25, 50, 75, 100] : [0, 10, 20, 30, 40, 50]
  const isAggregatingData = totalHits === 0 && numberOfMinutes < 60
  const metricLabelStyle = useMemo(() => toMetricLabelStyle(COLORS), [COLORS])

  return (
    <div data-testid="metric-chart" key={metricUrl}>
      {isLoading && <StyledDetailListNoData>No data to show yet</StyledDetailListNoData>}
      {!isLoading && hasClicks && (
        <>
          {!isMedia && <LinkPreview metric={metric} />}
          <StyledConversion $hasSummary={!isAggregatingData}>
            {!isAggregatingData && (
              <StyledDetailListCount>
                {formattedTotalHits} of {formattedTotalRecipients} Members
              </StyledDetailListCount>
            )}
            {isMedia && <MediaPreview metric={metric} />}
          </StyledConversion>

          <StyledDetailListMetrics>
            {!isAggregatingData && (
              <StyledDetailListMeta>
                <StyledDetailListConversion>{numeral(currentClicksOverTotal).format('0.0')}</StyledDetailListConversion>
              </StyledDetailListMeta>
            )}
          </StyledDetailListMetrics>

          {isAggregatingData ? (
            <>
              <StyledDetailLoadingText>Gathering data come back soon!</StyledDetailLoadingText>
            </>
          ) : (
            <ResponsiveContainer height={200} width="100%">
              <LineChart data={metric?.clicks} margin={{ top: 16, right: 16, left: -16, bottom: 0 }}>
                <XAxis
                  dataKey="tick"
                  interval={determineIntervals(numberOfMinutes)}
                  stroke={COLORS?.BORDERS}
                  tick={metricLabelStyle}
                />
                <YAxis
                  axisLine={false}
                  domain={[0, 'dataMax']}
                  tick={metricLabelStyle}
                  tickLine={false}
                  ticks={currentTicks}
                  unit="%"
                />
                {/* NOTE: This is to provide past data that we want to render in the future */}
                {/* <Area dataKey="average" type="monotone" stroke="none" fill={COLORS?.DIVIDERS} /> */}
                <Line
                  dataKey={({ count }) => count ?? currentClicksOverTotal}
                  dot={({ index, cx, cy, value }) => {
                    const isLastDot = index + 1 === metric.clicks.length
                    if (isLastDot && !Number.isNaN(value))
                      return (
                        <svg fill={COLORS?.LINKS} height={6} viewBox="0 0 100 100" width={6} x={cx - 3} y={cy - 3}>
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                      )
                  }}
                  isAnimationActive={false}
                  stroke={COLORS?.LINKS}
                  strokeDasharray="2 2"
                  strokeWidth={1}
                  type="monotone"
                ></Line>
                <Line
                  dataKey={({ count }) => count ?? currentClicksOverTotal}
                  dot={false}
                  isAnimationActive={false}
                  stroke={COLORS?.LINKS}
                  strokeWidth={2}
                  type="monotone"
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </>
      )}
    </div>
  )
}
