import { CreatableSelect, SPACING, selectStyles } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import noop from 'lodash/noop'
import stubTrue from 'lodash/stubTrue'
import take from 'lodash/take'
import uniq from 'lodash/uniq'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css, useTheme } from 'styled-components'

import { Input } from './Input'

import { useRecentSearches } from 'hooks'

const EmptyComponent = () => null

const StyledCreatableSelect = styled(CreatableSelect)`
  margin: 0 0 ${SPACING[4]};
`

type StyledOptionIconProps = {
  $rotate?: boolean
  icon: string
}
const StyledOptionIcon = styled(FontAwesomeIcon)<StyledOptionIconProps>`
  ${({ $rotate }) => css`
    ${$rotate ? 'transform: rotate(-45deg);' : ''}
  `}

  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

type SearchInputProps = {
  className?: string
  initialValue?: string
  onInputChange?(value: string): void
  onSearch?(term: string): void
}
export function SearchInput({ onInputChange = noop, onSearch, initialValue, ...props }: SearchInputProps): JSX.Element {
  const { t } = useTranslation()
  const [searchInputValue, setSearchInputValue] = useState(() => initialValue || '')
  const [recentSearches, setRecentSearches] = useRecentSearches()
  const theme = useTheme() || {}
  const styles = {
    ...selectStyles(theme),
    control: () => ({}),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      overflow: 'visible',
    }),
  }

  function setRecentSearch(value: any): void {
    const newRecentSearches = uniq([value, ...recentSearches])

    setRecentSearches(take(newRecentSearches, 5))
  }

  function handleInputChange(value: any): void {
    onInputChange(value)

    setSearchInputValue(value)
  }

  function handleChange(selected, meta) {
    const value = selected?.value
    const action = meta?.action
    const isOptionSelected = action === 'select-option'
    const isOptionCreated = action === 'create-option'

    if (value && (isOptionSelected || isOptionCreated)) {
      setRecentSearch(value)
      onSearch?.(value)
    }
  }

  function handleFormatOptionLabel(selected) {
    const value = selected?.value
    const isNew = !recentSearches.includes(value)

    return (
      <>
        {isNew ? (
          <>
            <StyledOptionIcon $rotate icon="arrow-right" /> {t('search.browseAllResultsFor')} '{value}'
          </>
        ) : (
          <>
            <StyledOptionIcon icon="clock" /> {value}
          </>
        )}
      </>
    )
  }

  const options = recentSearches
    .filter((value) => value.toLowerCase() !== searchInputValue.toLowerCase())
    .map((value) => ({
      value,
      label: value,
    }))

  return (
    <StyledCreatableSelect
      {...props}
      backspaceRemovesValue={false}
      classNamePrefix="react-select"
      components={{
        Input: Input as any,
        SingleValue: EmptyComponent,
        Placeholder: EmptyComponent,
        IndicatorsContainer: EmptyComponent,
      }}
      createOptionPosition="first"
      filterOption={stubTrue}
      formatOptionLabel={handleFormatOptionLabel}
      inputValue={searchInputValue}
      noOptionsMessage={EmptyComponent}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      styles={styles}
      value={searchInputValue}
    />
  )
}
