import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query'
import flatten from 'lodash/flatten'
import { useMemo } from 'react'

import { ScheduledCampaign, getScheduledCampaigns } from 'api/scheduled'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { PaginationNormalized } from 'utils/normalize'

function replacePlaceholders(campaign: ScheduledCampaign) {
  if (
    campaign?.text &&
    campaign?.placeholders &&
    campaign?.placeholders.length > 0 &&
    campaign.placeholders.some((p) => campaign.text!.includes(`{${p.key}:${p.source}}`))
  ) {
    const matchingPlaceholders = campaign.placeholders.filter((p) => campaign.text!.includes(`{${p.key}:${p.source}}`))
    if (matchingPlaceholders.length > 0) {
      return {
        ...campaign,
        text: matchingPlaceholders.reduce(
          (t, ph) => t.replace(`{${ph.key}:${ph.source}}`, `{${ph.name}}`),
          campaign.text,
        ),
      }
    }
  }
  return campaign
}

function formatCampaigns(data: any): ScheduledCampaign[] {
  return flatten(data.pages.map(({ data }) => data))
}

type ScheduledCampaignsPaged = {
  campaigns: (ScheduledCampaign | Record<string, never>)[]
  pagination: PaginationNormalized
}

type UseScheduledCampaignsReturn = UseInfiniteQueryResult<ScheduledCampaignsPaged, unknown> & {
  campaigns?: ScheduledCampaign[]
}

export function useScheduledCampaigns(): UseScheduledCampaignsReturn {
  const clientId = useClientId()
  const result: UseScheduledCampaignsReturn = useInfiniteQuery(
    [QUERY_CACHE.SCHEDULED.LIST, { clientId }],
    ({ pageParam }) => getScheduledCampaigns({ clientId, pageParam }),
    {
      staleTime: STALE_TIME.FIVE_MINUTES,
      getNextPageParam: (lastPage) =>
        lastPage?.paginationData?.hasNextPage && (lastPage?.paginationData?.pageNumber || 0) + 1,
    },
  )

  const campaigns = useMemo(
    () => (result?.data ? formatCampaigns(result?.data) : []).map(replacePlaceholders),
    [result?.data],
  )

  return {
    ...result,
    campaigns,
  }
}
