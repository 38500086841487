import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { AnalyticsEvent } from 'utils/analytics/events'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type SubscriptionV2 = CamelCasedPropertiesDeep<Api.Billing.Subscription>

export function getSubscription({ clientId }: { clientId: string }): Promise<SubscriptionV2> {
  return request(route(ENDPOINTS.BILLING.SUBSCRIPTION, { clientId }), { ignoreReportingErrorCodes: [404] })
}

export function putBillingInfo({
  clientId,
  body,
}: {
  clientId: string
  body: Api.Billing.BillingInfoPutBody
}): Promise<CamelCasedPropertiesDeep<Api.Billing.BillingInfoPut>> {
  return request(route(ENDPOINTS.BILLING.INFO, { clientId }), {
    method: HTTP.PUT,
    body,
  })
}

export type BillingClient = CamelCasedPropertiesDeep<Api.Billing.BillingClient>

export function getBillingClient({ clientId }: { clientId: string }): Promise<BillingClient> {
  return request(route(ENDPOINTS.BILLING.CLIENT, { clientId }))
}

type TrackBilling = {
  clientId: string
  event: AnalyticsEvent
}

export function postTrackBilling({ clientId, event }: TrackBilling): Promise<void> {
  const requestRoute = route(ENDPOINTS.BILLING.TRACK, { clientId })
  const options = {
    method: HTTP.POST,
    body: event,
  }
  return request(requestRoute, options)
}

export type SetupIntent = CamelCasedPropertiesDeep<Api.Billing.ClientSecret>

export const postSetupIntent = async ({ clientId }: { clientId: string }): Promise<SetupIntent> => {
  return request(route(ENDPOINTS.BILLING.SETUP_INTENT, { clientId }), { method: HTTP.POST })
}
