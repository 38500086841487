import { BUTTON_VARIANTS, CheckBox } from '@community_dev/pixels'
import React, { useState } from 'react'

import followInstructionImage from '../../assets/follow-instruction.png'
import { StyledButton, StyledCheckboxLabel, StyledInstructionTitle, StyledParagraph } from '../styled'

import { VisualFormSection } from 'containers/Settings/components/Integrations/VisualFormSection'
import analytics from 'utils/analytics'

type Props = {
  onPrimaryButtonClick: any
  onBackButtonClick: any
}

export const SetupInstruction = (props: Props): JSX.Element => {
  const [completedInstruction, setCompletedInstruction] = useState(false)
  return (
    <VisualFormSection
      backButton="Back"
      backButtonClick={props.onBackButtonClick}
      image={followInstructionImage}
      primaryButton="Continue"
      primaryButtonClick={props.onPrimaryButtonClick}
      primaryButtonDisabled={!completedInstruction}
      title="Follow Setup Instructions"
    >
      <div>
        <StyledParagraph>
          You will be configuring your checkout page and creating a new ‘custom app’ in your Shopify settings.
        </StyledParagraph>
        <StyledInstructionTitle>Setup Instructions</StyledInstructionTitle>
        <StyledButton variant={BUTTON_VARIANTS.OUTLINE}>
          <span>Connect Shopify Store</span>
          <a
            href="https://incommunity.zendesk.com/hc/en-us/articles/1500002146762"
            onClick={() => analytics.track(analytics.events.ShopifyLearnMore())}
            rel="noreferrer noopener"
            target="_blank"
          >
            View
          </a>
        </StyledButton>
        <StyledCheckboxLabel>
          <CheckBox
            name="I’ve completed the setup instructions"
            onChange={(checked) => setCompletedInstruction(checked)}
            selected={completedInstruction}
          />
          I’ve completed the setup instructions
        </StyledCheckboxLabel>
      </div>
    </VisualFormSection>
  )
}
