import {
  BUTTON_VARIANTS,
  Block,
  Button,
  ChevronRightIcon,
  CloseIcon,
  Dropdown,
  FONT_SIZE,
  Modal,
  SPACING,
} from '@community_dev/pixels'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { REASON_OPTIONS } from './constants'

import analytics from 'utils/analytics'

type ReportFanProps = {
  fanId: string
  fanName: string
  fanSubscriptionId: string
  lastMessageBody?: string
  lastMessageId?: string
  lastMessageTimestamp?: string
  lastMessageHasMedia?: boolean
}

const StyledHeading = styled.h4`
  color: ${({ theme }) => theme?.COLORS?.ERRORS};
`

const StyledHeader = styled.div`
  display: flex;
  margin-top: 0;
  align-items: center;
  padding: 0 ${SPACING[4]} ${SPACING[4]} 0;
  justify-content: space-between;

  ${StyledHeading} {
    margin: 0;
  }
`

const StyledBody = styled.div`
  padding: ${SPACING[4]} ${SPACING[4]} ${SPACING[4]} 0;
  border-top: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  text-align: left;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
`

const StyledContainer = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
`

const StyledModalBody = styled.div`
  padding: ${SPACING[3]} ${SPACING[5]} 0;
`

const StyledModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const StyledText = styled.div`
  text-align: center;
`

const StyledFootnote = styled.p`
  margin: 0;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  text-align: center;
  font-size: ${FONT_SIZE[3]};
`

export function ReportFan({
  fanId,
  fanName,
  fanSubscriptionId,
  lastMessageBody,
  lastMessageId,
  lastMessageTimestamp,
  lastMessageHasMedia,
}: ReportFanProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [reason, setReason] = useState({ value: '', label: '' })

  function handleReport() {
    analytics.track(
      analytics.events.ReportFan({
        fanId,
        fanSubscriptionId,
        lastMessageBody,
        lastMessageId,
        lastMessageTimestamp,
        lastMessageHasMedia,
        reason: reason?.label,
      }),
    )
    setIsModalOpen(false)
    setReason({ value: '', label: '' })
    toast.success(t('toasts.reportSuccess', { name: fanName || 'Member' }))
  }

  function handleClose() {
    setIsModalOpen(false)
    setReason({ value: '', label: '' })
  }

  return (
    <>
      <StyledContainer onClick={() => setIsModalOpen(true)}>
        <Block flush id="reportFan">
          <StyledHeader>
            <StyledHeading>{t('memberActions.report')}</StyledHeading>
            <ChevronRightIcon color={COLORS?.DIVIDERS} />
          </StyledHeader>
          <StyledBody>{t('memberActions.reportPrompt')}</StyledBody>
        </Block>
      </StyledContainer>
      {isModalOpen && (
        <Modal maxWidth={490} minHeight="initial" onClose={handleClose} open={isModalOpen} overflow="initial">
          <Modal.Header>
            <Modal.Header.Center>
              <span>{`Report ${fanName || 'Member'}?`}</span>
            </Modal.Header.Center>
            <Modal.Header.Right onClose={handleClose}>
              <CloseIcon color={COLORS?.SUBTEXT} size={12} />
            </Modal.Header.Right>
          </Modal.Header>
          <Modal.Body>
            <StyledModalBody>
              <StyledText>
                <p>{t('memberActions.reportPrompt')}</p>
                <p>
                  {t('memberActions.pleaseSelectReason', {
                    action: 'reporting',
                  })}
                </p>
              </StyledText>
              <Dropdown label="Reason" onChange={setReason} options={REASON_OPTIONS} value={reason} />
              <StyledFootnote>{t('memberActions.pleaseDisableAdblocker')}</StyledFootnote>
            </StyledModalBody>
          </Modal.Body>
          <Modal.Footer>
            <StyledModalFooter>
              <Button onClick={handleClose} variant={BUTTON_VARIANTS.OUTLINE}>
                {t('memberActions.cancel')}
              </Button>
              <Button
                color={COLORS?.ERRORS}
                disabled={!reason.value}
                hoverColor={COLORS?.ERRORS_HOVER}
                onClick={handleReport}
              >
                {t('memberActions.report')}
              </Button>
            </StyledModalFooter>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
