import {
  CheckBox,
  ColorGrid,
  DIALOG_VARIANTS,
  Dialog,
  DropdownArrowIcon,
  Pill,
  SPACING,
  Typography,
} from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'

import {
  StyledCreateCommunityModal,
  StyledInputLabel,
  StyledModalHeader,
  StyledModalSubHeader,
  StyledCommunityNameContainer,
  StyledColorInput,
  StyledBoxInputContainer,
  StyledAddTextButton,
  StyledPillContainer,
  StyledButtonContainer,
  StyledButton,
  StyledInputTitle,
  StyledNameInput,
  StyledLabel,
  StyledPills,
} from './CreateCommunityModal.styled'

import { Community } from 'api/community'
import { Fan } from 'api/fans'
import { CommunityDot } from 'components/CommunityDot'
import MembersModal from 'components/MembersModal/MembersModal'
import { ROUTES } from 'constants/routes'
import { KeywordsCreateModal } from 'containers/KeywordsModal/KeywordsCreateModal'
import { useKeywordResponders } from 'hooks/keywordResponders/useKeywordResponders'
import { useClient, useClientId } from 'hooks/useClient'
import { useCommunities, useCommunity, useCreateCommunity, useUpdateCommunity } from 'hooks/useCommunities'
import { useAllCommunityCount } from 'hooks/useCountByQuery/useCommunityCount'
import { NormalizedKeyword, normalizeKeywords } from 'utils/normalize'
import { route } from 'utils/router'

type CreateCommunityModalProps = {
  communityId?: string
  defaultTitle?: string
  allowRetroactiveCommunity?: boolean
  onCreated?: (communityId: string) => any
  onUpdated?: (community: Community) => any
  onClose: () => void
}

export default function CreateCommunityModal({
  communityId,
  defaultTitle = '',
  allowRetroactiveCommunity,
  onCreated,
  onUpdated,
  onClose,
}: CreateCommunityModalProps): JSX.Element {
  const history = useHistory()
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditKeywordsModalOpen, setIsEditKeywordsModalOpen] = useState<boolean>(false)
  const [isAddMembersModalOpen, setIsAddMembersModalOpen] = useState<boolean>(false)
  const [communityMembers, setCommunityMembers] = useState<Fan[]>([])
  const [keywords, setKeywords] = useState<Omit<NormalizedKeyword, 'id'>[]>([])
  const [communityKeywordAutoResponder, setCommunityKeywordAutoResponder] = useState<string>('')
  const [activeColor, setActiveColor] = useState<string>('#414B58')
  const [displayExitDialog, setDisplayExitDialog] = useState<boolean>(false)
  const [displayKeywordAutoResponderOverwriteDialog, setDisplayKeywordAutoResponderOverwriteDialog] =
    useState<boolean>(false)
  const [maxCommunitiesDialog, setMaxCommunitiesDialog] = useState<boolean>(false)
  const [includeFansRetroactively, setIncludeFansRetroactively] = useState<boolean>(allowRetroactiveCommunity === true)
  const [title, setTitle] = useState<string>(defaultTitle)
  const previousCommunityValues = useRef({
    title: title,
    color: activeColor,
  })
  const clientId = useClientId()
  const { data: client } = useClient()
  const { tagLimit = 0 } = client || {}

  const { data: keywordMessages = { data: [] } } = useKeywordResponders()

  const { data: communities } = useCommunities()

  const { data: community } = useCommunity(communityId, {
    onSuccess: (community) => {
      setTitle(community.title)
      setActiveColor(community.color)
      setKeywords(normalizeKeywords(community.tagKeywords))
      previousCommunityValues.current = {
        title: community.title,
        color: community.color,
      }
    },
  })

  const fanCounts = useAllCommunityCount({ communityId: community?.id, title: community?.title })

  const { mutateAsync: updateCommunity } = useUpdateCommunity()
  const { mutateAsync: createCommunity } = useCreateCommunity()

  const titleInputRef = useRef<HTMLInputElement | null>(null)

  const addMembers = (e) => {
    e.preventDefault()

    setIsAddMembersModalOpen(true)
  }

  const openKeywordsCreateModal = (e) => {
    e.preventDefault()

    setIsEditKeywordsModalOpen(true)
  }

  const hideMaxCommunitiesDialog = () => {
    setMaxCommunitiesDialog(false)
  }

  const onSelectColor = (color) => {
    setActiveColor(color)
  }

  const isNameTaken = useMemo(() => {
    return (
      communities?.all.some((community) => community.title.toLowerCase() === title.toLowerCase()) &&
      title !== previousCommunityValues.current.title
    )
  }, [communities?.all, title])

  const submitDisabled = useMemo(() => {
    return isNameTaken || !title || isSaving
  }, [isNameTaken, isSaving, title])

  const onSave = async () => {
    setIsSaving(true)
    const isEditingCommunity = !!communityId
    const isCreatingCommunity = !isEditingCommunity
    const canCreateCommunity = communities && communities.custom.length < tagLimit

    if (!isEditingCommunity && !canCreateCommunity) {
      setMaxCommunitiesDialog(true)
      setIsSaving(false)
      return
    }

    if (isEditingCommunity) {
      const updatedCommunity = await updateCommunity({
        clientId,
        id: communityId,
        title,
        color: activeColor,
        fans: communityMembers,
        oldKeywords: community?.tagKeywords,
        newKeywords: keywords,
        autoResponderText: communityKeywordAutoResponder,
        includeFansRetroactively: includeFansRetroactively,
      })
      onUpdated?.(updatedCommunity)
    }

    if (isCreatingCommunity) {
      const newCommunity = await createCommunity({
        clientId,
        title,
        color: activeColor,
        description: '',
        fans: communityMembers,
        keywords,
        autoResponderText: communityKeywordAutoResponder,
        includeFansRetroactively: includeFansRetroactively,
      })
      history.push(route(ROUTES.COMMUNITY, { id: newCommunity.id }))
      onCreated?.(newCommunity.id)
    }
    setIsSaving(false)
    setTimeout(() => onClose(), 250)
  }

  const onNameChange = (event) => {
    const title = event.target.value
    setTitle(title)
  }

  /** Generates strings like "”word1”, ”word2” or ”word3”" from a keyword list */
  const formatKeywordsAsText = (keywords: Array<{ word: string }>): string => {
    return keywords.reduce((acc, { word }, i, list) => {
      if (i === 0) {
        // first
        return `”${word}”`
      } else if (i === list.length - 1) {
        // last
        return `${acc} or ”${word}”`
      } else {
        // others
        return `${acc}, ”${word}”`
      }
    }, '')
  }

  const getOverwrittenKeywordResponders = () => {
    if (!communityKeywordAutoResponder) {
      return []
    }

    const overwrittenKeywordResponders = keywords?.filter((keyword) =>
      keywordMessages.data.find((km) => km.keyword === keyword.word),
    )

    return overwrittenKeywordResponders
  }

  const renderKeywordAutoResponderOverwriteDialog = () => {
    if (!displayKeywordAutoResponderOverwriteDialog) return

    const overwrittenKeywordResponders = getOverwrittenKeywordResponders()

    const keywordString = overwrittenKeywordResponders?.map((kw) => kw.word).join(', ')

    const message = (
      <>
        There is already a keyword responder set up in Settings for the following keyword(s):{' '}
        <strong>{keywordString}</strong>. Clicking confirm will overwrite the old keyword response(s) with this new
        response.
      </>
    )

    return (
      <Dialog maxWidth={500} message={message} title="Overwrite Existing Keyword Response">
        <Dialog.Action onClick={() => setDisplayKeywordAutoResponderOverwriteDialog(false)}>Cancel</Dialog.Action>
        <Dialog.Action onClick={() => onSave()} variant={DIALOG_VARIANTS.DESTRUCTIVE}>
          Confirm
        </Dialog.Action>
      </Dialog>
    )
  }

  const renderExitDialog = () => {
    if (!displayExitDialog) return

    const isEditingCommunity = !!communityId
    const endToDialogSentence = isEditingCommunity
      ? 'These changes will not be saved'
      : 'This community will not be created'

    return (
      <Dialog maxWidth={330} message={`${endToDialogSentence}.`} title="Are you sure you want to leave?">
        <Dialog.Action
          onClick={() => {
            setDisplayExitDialog(false)
          }}
        >
          Cancel
        </Dialog.Action>
        <Dialog.Action onClick={onClose} variant={DIALOG_VARIANTS.DESTRUCTIVE}>
          Leave
        </Dialog.Action>
      </Dialog>
    )
  }

  const renderMaxCommunitiesDialog = () => {
    if (!maxCommunitiesDialog) return

    return (
      <Dialog
        message={t('communities.error.maxNumberOfCommunitiesDialog.body', {
          max_count: tagLimit,
        })}
        title={t('communities.error.maxNumberOfCommunitiesDialog.title')}
      >
        <Dialog.Action onClick={hideMaxCommunitiesDialog}>Got It</Dialog.Action>
      </Dialog>
    )
  }

  return (
    <StyledCreateCommunityModal
      onClose={() => {
        setDisplayExitDialog(true)
      }}
    >
      <div>
        <StyledModalHeader>{communityId ? 'Edit' : 'New'} Community</StyledModalHeader>
        <StyledModalSubHeader>Communities are an easy way to organize your contacts.</StyledModalSubHeader>
      </div>
      <StyledCommunityNameContainer>
        <StyledBoxInputContainer>
          <StyledInputLabel>Community Name</StyledInputLabel>
          <StyledNameInput
            aria-label="Community Name"
            autoFocus
            data-cypress="community-name"
            maxLength={60}
            onChange={onNameChange}
            placeholder="Enter a name"
            ref={titleInputRef}
            type="text"
            value={title}
          />
          {isNameTaken && (
            <Typography color={COLORS.ERRORS} marginTop={SPACING[1]} textAlign="left" variant="caption1">
              {t('communities.error.duplicateName', { title: 'This name' })}
            </Typography>
          )}
        </StyledBoxInputContainer>
        <StyledBoxInputContainer>
          <StyledInputLabel>Color</StyledInputLabel>
          <ColorGrid align="left" color={activeColor} offsetTop={10} onChange={onSelectColor}>
            <StyledColorInput id="color-input">
              <CommunityDot color={activeColor} />
              <DropdownArrowIcon color={COLORS?.INPUT_TEXT} />
            </StyledColorInput>
          </ColorGrid>
        </StyledBoxInputContainer>
      </StyledCommunityNameContainer>
      <div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledInputTitle>{t('members')}</StyledInputTitle>
            <StyledAddTextButton data-cypress={`community-members`} onClick={addMembers}>
              + Add Members
            </StyledAddTextButton>
          </div>
          <StyledPillContainer onClick={addMembers}>
            <StyledPills>
              {communityId && (
                <Pill icon={<FontAwesomeIcon icon="user" />} key="fan-count">
                  Members ({fanCounts?.total})
                </Pill>
              )}
              {communityMembers.length > 0 &&
                communityMembers.map((fan) => {
                  const text = fan.fullName
                  return (
                    <Pill
                      key={text + fan.id}
                      onClick={addMembers}
                      onRemove={() => setCommunityMembers(communityMembers.filter((m) => m !== fan))}
                      removable
                    >
                      {text}
                    </Pill>
                  )
                })}
            </StyledPills>
          </StyledPillContainer>
        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledInputTitle>Keywords</StyledInputTitle>
            <StyledAddTextButton data-cypress={'community-keywords'} onClick={openKeywordsCreateModal}>
              + {communityId ? 'Edit' : 'Add'} Keywords
            </StyledAddTextButton>
          </div>
          <StyledPillContainer onClick={openKeywordsCreateModal}>
            <StyledPills>
              {keywords?.length > 0 &&
                keywords.map((keyword) => (
                  <Pill
                    key={keyword.word}
                    onClick={openKeywordsCreateModal}
                    onRemove={() => setKeywords(keywords.filter((k) => k !== keyword))}
                    removable
                  >
                    {keyword.word}
                  </Pill>
                ))}
            </StyledPills>
          </StyledPillContainer>
        </div>
        {communityKeywordAutoResponder && (
          <div>
            <h4>Automated Keyword Response</h4>
            <StyledModalSubHeader>{communityKeywordAutoResponder}</StyledModalSubHeader>
          </div>
        )}

        {allowRetroactiveCommunity && !!keywords?.length && (
          <StyledLabel>
            <CheckBox
              disabled={false}
              onChange={(v) => setIncludeFansRetroactively(v)}
              selected={includeFansRetroactively && keywords?.length !== 0}
            />
            Add all members who previously messaged {formatKeywordsAsText(keywords || [])}
          </StyledLabel>
        )}
      </div>
      <StyledButtonContainer>
        <StyledButton
          disabled={submitDisabled}
          onClick={() => {
            const overwrittenKeywordResponders = getOverwrittenKeywordResponders()

            if (overwrittenKeywordResponders?.length) {
              setDisplayKeywordAutoResponderOverwriteDialog(true)
            } else {
              onSave()
            }
          }}
        >
          Save
        </StyledButton>
      </StyledButtonContainer>
      {renderExitDialog()}
      {renderKeywordAutoResponderOverwriteDialog()}
      {renderMaxCommunitiesDialog()}
      {isEditKeywordsModalOpen && (
        <KeywordsCreateModal
          keywords={keywords}
          onClose={() => setIsEditKeywordsModalOpen(false)}
          onSave={({ keywords, keywordResponder }) => {
            setKeywords(keywords)
            if (keywordResponder) {
              setCommunityKeywordAutoResponder(keywordResponder)
            }
          }}
        />
      )}
      {isAddMembersModalOpen && (
        <MembersModal
          fans={communityMembers}
          isOpen={isAddMembersModalOpen}
          onClose={() => setIsAddMembersModalOpen(false)}
          onSave={setCommunityMembers}
        />
      )}
    </StyledCreateCommunityModal>
  )
}
