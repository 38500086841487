import { WorkflowEditorCardTypes, WORKFLOW_CARDS } from '@community_dev/flexible-canvas'
import { Layout, SPACING } from '@community_dev/pixels'
import { SleepAction, updateAction, isSleepAction, removeStep } from '@community_dev/workflow-manifest'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import * as Duration from 'tinyduration'

import { WorkflowSidebarHeader } from '../WorkflowSidebarHeader/WorkflowSidebarHeader'

import {
  DelaySelect,
  DelaySelectProps,
  DelaySelectValues,
  DelaySelectOption,
  DelaySelectMode,
} from 'components/EditOnboardingMessage/EditMessage/DelaySelect'
import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { useManifestAction } from 'components/WorkflowEditor/hooks/useManifestAction'
import { ROUTES } from 'constants/routes'
import { useToastMessage } from 'hooks/useToastMessage'
import Sentry from 'integrations/Sentry'
import { CancelLink, DoneButton } from 'screens/WorkflowScreen/WorkflowScreen.style'
import { route } from 'utils/router'

const WorkflowAction = WORKFLOW_CARDS[WorkflowEditorCardTypes.SLEEP]

export const DURATION_ONE_DAY = 'P1D'

export type WorkflowSidebarEditSleepProps = {
  mode: 'add' | 'edit'
}

export function WorkflowSidebarEditSleep(props: WorkflowSidebarEditSleepProps): JSX.Element | null {
  const { t } = useTranslation()
  const history = useHistory()
  const { COLORS } = useTheme() || {}
  const { showToastMessage } = useToastMessage()
  const { workflowId, functionKey, functionIndex } =
    useParams<{ workflowId: string; functionKey: string; functionIndex: string }>()
  const { manifest, isLoading, dispatch } = useWorkflowProvider()

  const { action } = useManifestAction<SleepAction>()

  let delayDuration = ''

  if (isSleepAction(action)) {
    delayDuration = action?.params?.duration
  }

  const parsedDelay = Duration.parse(delayDuration || 'P1D')

  let delaySelectProps: DelaySelectProps | undefined = undefined

  if (parsedDelay.seconds) {
    delaySelectProps = {
      initialDelay: parsedDelay.seconds,
      initialMode: DelaySelectMode.Delayed,
      initialUnitValue: DelaySelectOption.seconds,
    }
  } else if (parsedDelay.minutes) {
    delaySelectProps = {
      initialDelay: parsedDelay.minutes,
      initialMode: DelaySelectMode.Delayed,
      initialUnitValue: DelaySelectOption.minutes,
    }
  } else if (parsedDelay.hours) {
    delaySelectProps = {
      initialDelay: parsedDelay.hours,
      initialMode: DelaySelectMode.Delayed,
      initialUnitValue: DelaySelectOption.hours,
    }
  } else if (parsedDelay.days) {
    delaySelectProps = {
      initialDelay: parsedDelay.days,
      initialMode: DelaySelectMode.Delayed,
      initialUnitValue: DelaySelectOption.days,
    }
  }

  const onSubmit = async (values: DelaySelectValues) => {
    if (!action) {
      return
    }
    const { delay, unit } = values

    const duration = Duration.serialize({ [unit.value]: delay })

    const nextManifest = updateAction(
      manifest,
      functionKey,
      Number(functionIndex),
      set(cloneDeep(action), 'params.duration', duration),
    )

    try {
      dispatch({
        type: 'update',
        state: {
          manifest: nextManifest,
        },
      })

      history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          workflowId,
          manifest,
        },
      })

      showToastMessage({
        message: t('automations.errorSavingFlow'),
        success: false,
      })
    }
  }

  if (isLoading) {
    return null
  }

  return (
    <Layout display="flex" flexDirection="column" height="100%" width="100%">
      <Layout flex="1" marginTop={SPACING[2]} padding={SPACING[4]}>
        <WorkflowSidebarHeader
          workflowAction={{
            ...WorkflowAction,
            subtitle: t('automations.addDelayToFlow'),
          }}
        />

        <Layout marginTop={SPACING[5]}>
          <Form
            onSubmit={onSubmit}
            render={(formRenderProps) => {
              const { handleSubmit } = formRenderProps

              return (
                <form id="delayForm" onSubmit={handleSubmit}>
                  <DelaySelect allowImmediate={false} {...delaySelectProps} />
                </form>
              )
            }}
          />
        </Layout>
      </Layout>
      <Layout
        alignItems="center"
        borderTop={`1px solid ${COLORS?.BORDERS}`}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding="45px 16px"
      >
        <DoneButton form="delayForm" type="submit">
          {t('done')}
        </DoneButton>

        <CancelLink
          onClick={async () => {
            if (props.mode === 'add') {
              const nextManifest = removeStep(manifest, functionKey, Number(functionIndex))

              try {
                dispatch({
                  type: 'update',
                  state: {
                    manifest: nextManifest,
                  },
                })
              } catch (e) {
                Sentry.captureException(e)
              }
            }
          }}
          style={{ marginTop: SPACING[5] }}
          to={route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId })}
        >
          {t('cancel')}
        </CancelLink>
      </Layout>
    </Layout>
  )
}
