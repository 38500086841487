import {
  Layout,
  Typography,
  ChevronLeftIcon,
  ChevronRightIcon,
  Button,
  BUTTON_VARIANTS,
  SwitchButton,
  TABLET_BREAK,
  SpinnerIcon,
  Badge,
} from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { CampaignStatus } from '@community_dev/types/lib/api/v2/Campaign'
import { useIsFetching } from '@tanstack/react-query'
import { transparentize } from 'polished'
import Toolbar from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled, { useTheme } from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'

import { useHasAiIdeasEvents } from './useHasAiIdeasEvents'

import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { useCurrentMonthScheduledCampaignsQuery } from 'hooks/useScheduledCampaignsQuery'
import { ScheduledLocalStorageKeys } from 'screens/Scheduled/constants'

const StyledIconButton = styled(Button)`
  padding: 2px 0;
  min-width: 0;
  border: none;

  &:hover {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  }
`
const StyledTodayButton = styled(Button)`
  margin-right: 12px;
`

const StyledNavigationContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${TABLET_BREAK}) {
    flex-basis: 100%;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;

  *:not(:last-child) {
    margin-right: 12px;
  }

  @media (max-width: ${TABLET_BREAK}) {
    flex-basis: 100%;
  }
`

const StyledBadge = styled(Badge)<{ $selected: boolean }>`
  position: absolute;
  border-color: ${({ $selected, theme }) =>
    $selected ? transparentize(0.2, theme?.COLORS?.EVENT_NEEDS_APPROVAL_BACKGROUND) : theme.COLORS.BORDERS};
  border-width: 2px;
  top: -10px;
  right: -20px;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const nextMonthAndYear = (date: Date): { month: number; year: number } => {
  const nextDate = new Date(date)
  nextDate.setMonth(date.getMonth() + 1)
  return { month: nextDate.getMonth() + 1, year: nextDate.getFullYear() }
}

const prevMonthAndYear = (date: Date): { month: number; year: number } => {
  const prevDate = new Date(date)
  prevDate.setMonth(date.getMonth() - 1)
  return { month: prevDate.getMonth() + 1, year: prevDate.getFullYear() }
}

const useIsFetchingEvents = (): boolean => {
  const scheduled = useIsFetching({ queryKey: [QUERY_CACHE.SCHEDULED.RANGE] })
  const recurring = useIsFetching({ queryKey: [QUERY_CACHE.SCHEDULED.RECURRING] })
  const sent = useIsFetching({ queryKey: [QUERY_CACHE.CAMPAIGN.RANGE] })
  return scheduled + recurring + sent > 0
}
export const CalendarToolbar = (props: Toolbar): JSX.Element => {
  const isFetchingEvents = useIsFetchingEvents()
  const { label, onNavigate, date } = props
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const history = useHistory()
  const { isAiIdeaSelected, setAiIdeaSelected, shouldShowAiIdeas } = useHasAiIdeasEvents()

  const [isScheduledMessagesSelected, setScheduleMessagesSelected] = useLocalStorageState<boolean>(
    ScheduledLocalStorageKeys.SCHEDULED_MESSAGES,
    {
      defaultValue: true,
    },
  )
  const [isSentMessagesSelected, setSentMessagesSelected] = useLocalStorageState<boolean>(
    ScheduledLocalStorageKeys.SENT_MESSAGES,
    {
      defaultValue: false,
    },
  )
  const [isRecurringMessagesSelected, setRecurringMessagesSelected] = useLocalStorageState<boolean>(
    ScheduledLocalStorageKeys.RECURRING_MESSAGES,
    {
      defaultValue: true,
    },
  )
  const [isSubmittedForApprovalMessagesSelected, setSubmittedForApprovalMessagesSelected] =
    useLocalStorageState<boolean>(ScheduledLocalStorageKeys.SUBMITTED_FOR_APPROVAL_MESSAGES, {
      defaultValue: true,
    })

  const { data: numberOfCampaignsNeedApproval } = useCurrentMonthScheduledCampaignsQuery({
    statuses: [CampaignStatus.SUBMITTED_FOR_APPROVAL],
    options: {
      select: (campaigns) => campaigns.data.length,
    },
  })
  return (
    <Layout
      alignItems="center"
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      padding="20px"
      rowGap="20px"
    >
      <StyledNavigationContainer>
        <StyledTodayButton
          onClick={() => {
            onNavigate('TODAY')
            const today = new Date()
            history.push(
              route(ROUTES.SCHEDULED.MONTH, { 'month?': today.getMonth() + 1, 'year?': today.getFullYear() }),
            )
          }}
          variant={BUTTON_VARIANTS.OUTLINE}
        >
          {t('scheduled.calendar.today')}
        </StyledTodayButton>
        <StyledIconButton
          onClick={() => {
            onNavigate('PREV')
            const { month, year } = prevMonthAndYear(date)
            history.push(route(ROUTES.SCHEDULED.MONTH, { 'month?': month, 'year?': year }))
          }}
          variant={BUTTON_VARIANTS.OUTLINE}
        >
          <ChevronLeftIcon size={30} />
        </StyledIconButton>
        <StyledIconButton
          onClick={() => {
            onNavigate('NEXT')
            const { month, year } = nextMonthAndYear(date)
            history.push(route(ROUTES.SCHEDULED.MONTH, { 'month?': month, 'year?': year }))
          }}
          variant={BUTTON_VARIANTS.OUTLINE}
        >
          <ChevronRightIcon size={30} />
        </StyledIconButton>
        <Typography margin="0 12px 0 12px" padding="0px" variant="h3">
          {label}
        </Typography>
        {isFetchingEvents && <SpinnerIcon />}
      </StyledNavigationContainer>
      <StyledButtonContainer>
        <SwitchButton
          color={COLORS.EVENT_SCHEDULED_BACKGROUND}
          on={isScheduledMessagesSelected}
          onClick={() => setScheduleMessagesSelected(!isScheduledMessagesSelected)}
        >
          {t('scheduled.calendar.scheduled')}
        </SwitchButton>
        <SwitchButton
          color={COLORS.EVENT_RECURRING_BACKGROUND}
          on={isRecurringMessagesSelected}
          onClick={() => setRecurringMessagesSelected(!isRecurringMessagesSelected)}
        >
          {t('scheduled.calendar.recurring')}
        </SwitchButton>
        <SwitchButton
          color={COLORS.EVENT_SENT_BACKGROUND}
          on={isSentMessagesSelected}
          onClick={() => setSentMessagesSelected(!isSentMessagesSelected)}
        >
          {t('scheduled.calendar.sent')}
        </SwitchButton>
        {Boolean(numberOfCampaignsNeedApproval) && (
          <Layout position="relative">
            <StyledBadge
              $selected={isSubmittedForApprovalMessagesSelected}
              color={COLORS.EVENT_NEEDS_APPROVAL_BACKGROUND}
            >
              {numberOfCampaignsNeedApproval}
            </StyledBadge>
            <SwitchButton
              color={COLORS.EVENT_NEEDS_APPROVAL_BACKGROUND}
              on={isSubmittedForApprovalMessagesSelected}
              onClick={() => setSubmittedForApprovalMessagesSelected(!isSubmittedForApprovalMessagesSelected)}
            >
              {t('scheduled.calendar.submittedForApproval')}
            </SwitchButton>
          </Layout>
        )}
        {shouldShowAiIdeas ? (
          <SwitchButton
            color={COLORS.EVENT_AI_IDEA_BACKGROUND}
            on={isAiIdeaSelected}
            onClick={() => setAiIdeaSelected(!isAiIdeaSelected)}
          >
            {t('scheduled.calendar.aiIdeas')}
          </SwitchButton>
        ) : null}
      </StyledButtonContainer>
    </Layout>
  )
}
