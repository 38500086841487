import styled from 'styled-components'

import { useActionPanel } from './ActionPanelContext'

import { PANE_WIDTH } from 'constants/theme'

type StyledRootProps = {
  $open?: boolean
  $animate?: boolean
}

const StyledRoot = styled.aside<StyledRootProps>`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  height: 100%;
  min-width: ${({ $open }) => ($open ? PANE_WIDTH : 0)};
  max-width: ${({ $open }) => ($open ? `calc(${PANE_WIDTH} + 15px)` : 0)};
  flex-grow: 0;
  flex-shrink: 0;
  border-left: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  position: relative;
  overflow: hidden;
`

const StyledContentWrapper = styled.div`
  width: ${PANE_WIDTH};
  height: 100%;
`

export const ActionPanelRenderer = (): JSX.Element => {
  const { portalTarget, isOpen, animatePanelToggle } = useActionPanel()

  return (
    <StyledRoot $animate={animatePanelToggle} $open={isOpen} aria-label="Details pane" data-testid="right-pane">
      <StyledContentWrapper ref={portalTarget} />
    </StyledRoot>
  )
}
