import { CheckBox, SPACING } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router'
import styled from 'styled-components'

import { useSearchState } from '../SearchProvider'

import { CommunicationChannelSelect } from 'components/ComposeMessage/components/CommunicationChannelSelect'
import { SearchInput } from 'components/SearchInput'
import { ROUTES } from 'constants/routes'
import {
  DESKTOP_LARGE_WITH_SIDEBAR_BREAK,
  DESKTOP_XL_WITH_SIDEBAR_BREAK,
  FLOATING_CONTAINER_LEFT_SPACING,
  FLOATING_CONTAINER_WIDTH,
} from 'constants/theme'

const StyledHeader = styled.header`
  border-top: ${({ theme }) => `1px solid ${theme?.COLORS?.APP_BACKGROUND_LEVEL_1}`};
  width: 100%;
  top: 0;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  padding: ${SPACING[4]} 0;
  border-top: ${({ theme }) => `1px solid ${theme?.COLORS?.APP_BACKGROUND_LEVEL_1}`};
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  margin: 0 ${SPACING[4]} 0;

  @media (min-width: ${DESKTOP_XL_WITH_SIDEBAR_BREAK}) {
    margin-left: ${FLOATING_CONTAINER_LEFT_SPACING};
    max-width: ${FLOATING_CONTAINER_WIDTH};
  }

  @media (min-width: ${DESKTOP_LARGE_WITH_SIDEBAR_BREAK}) and (max-width: ${DESKTOP_XL_WITH_SIDEBAR_BREAK}) {
    margin: 0 auto;
    max-width: ${FLOATING_CONTAINER_WIDTH};
  }
`

const StyledHeaderTop = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
`

const StyledBackButton = styled(FontAwesomeIcon)`
  margin: 0 ${SPACING[4]} 0 0;
  transform: scaleX(-1);
  cursor: pointer;
`

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
  margin: 0 ${SPACING[4]} 0 0;
`

const StyledCheckboxLabel = styled.label`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  display: flex;
  align-items: center;
`

const StyledCheckbox = styled(CheckBox)`
  margin: 0 ${SPACING[2]} 0 0;
`

const StyledCommunicationChannelSelect = styled(CommunicationChannelSelect)`
  margin-right: ${SPACING[4]};
  .communication-channel-control {
    height: 36px;
  }
`

export function SearchHeader(): JSX.Element {
  const { t } = useTranslation()
  const { changeParams, exact, search, communicationChannel } = useSearchState()
  const [redirect, setRedirect] = useState('')

  function handleClickBackButton() {
    setRedirect(ROUTES.MESSAGES.INBOX)
  }

  function handleSearch(term: string) {
    changeParams({ term })
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <StyledHeader>
      <StyledHeaderTop>
        <StyledBackButton icon="arrow-right" onClick={handleClickBackButton} />
        <StyledSearchInput initialValue={search} key={search} onSearch={handleSearch} />
        <StyledCommunicationChannelSelect
          compact
          onChange={(value) => changeParams({ communicationChannel: value })}
          value={communicationChannel}
        />
        <StyledCheckboxLabel>
          <StyledCheckbox
            name="exact"
            onChange={(value) => {
              changeParams({ exact: !value })
            }}
            selected={!exact}
          />{' '}
          {t('search.includeCloseMatches')}
        </StyledCheckboxLabel>
      </StyledHeaderTop>
    </StyledHeader>
  )
}
