import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  SelectorOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { Layout, SPACING, Tooltip } from '@community_dev/pixels'
import { ActionButton } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { CampaignStatus } from '@community_dev/types/lib/api/v2/Campaign'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ActionPanel } from 'components/ActionPanel'
import { ActionPanelContactsHeader } from 'components/ActionPanel/ActionPanelPresentationalComponents'
import { CampaignReactions } from 'components/ActionPanel/Reactions/CampaignReactions'
import { ResponseRate } from 'components/ActionPanel/ResponseRate'
import { CampaignResponses } from 'components/ActionPanel/Responses'
import { ComposeMessage } from 'components/ComposeMessage/ComposeMessage'
import { LinkClicks } from 'components/Metrics/LinkClicks'
import { MediaViews } from 'components/Metrics/MediaViews'
import { TeaserChart } from 'components/Metrics/TeaserChart'
import { CAPABILITIES } from 'constants/capabilities'
import { PANELS } from 'constants/panels'
import RecipientModal from 'containers/RecipientModal'
import { useCampaignDetails, useCampaignFilterSummary } from 'hooks/useCampaign'
import { useClient } from 'hooks/useClient'
import { useHasCapability } from 'hooks/useUserCapability'
import dayjs from 'utils/dayjs'

const StyledActionPanel = styled(ActionPanel)`
  > div,
  > button {
    margin: 0 0 ${SPACING[4]};
  }
`

const StyledActionButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(1fr);
  gap: ${SPACING[4]};
`

const StyledActionButton = styled(ActionButton)`
  width: 100%;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme.COLORS.TOOLTIP_TEXT};
`

type RecipientsActionPanelProps = {
  onClickAddToCommunity(): void
  campaignId: string
}

const communicationChannelLabels = {
  [CommunicationChannel.WHATS_APP]: 'WhatsApp',
  [CommunicationChannel.AMB]: 'Apple',
}

const TooltipContent = ({ status }: { status: CampaignStatus }): JSX.Element | null => {
  const { t } = useTranslation()
  return status === CampaignStatus.MEDIA_TRANSCODING_FAILED ? (
    <Layout>
      <Trans i18nKey="campaigns.mediaTranscodingFailed">
        <StyledLink href="mailto:yourfriends@community.com" />
      </Trans>
    </Layout>
  ) : (
    <Layout textAlign="center">{t('campaigns.campaignProcessing')}</Layout>
  )
}

// This thing is intended to replace the previous implementation found at
// containers/ContactsDetailPane/SentContactsDetailPane.tsx
//
// The intention is that it:
//
// (1) Should not do any internal routing
// (2) Should load its own data via react-query calls where appropriate.
// (3) Where data must come from Redux, this should mostly be passed down by the
//     parent SentThread instance rather than having this component access
//     Redux itself.
//
// This should be a relatively short file. Sections with their own data loading
// and/or complex render logic should be defined in their own component files.
export const RecipientsActionPanel = ({
  onClickAddToCommunity,
  campaignId,
}: RecipientsActionPanelProps): JSX.Element => {
  const { data: client } = useClient()
  const [isRecipientModalOpen, setIsRecipientModalOpen] = useState(false)
  const { t } = useTranslation()
  const { data, isLoading: campaignDetailsIsLoading } = useCampaignDetails({
    campaignId,
  })
  const filtersString = useCampaignFilterSummary({
    campaignId,
  })

  const now = dayjs()
  const campaignCreatedUnderTwelveMonths = now.diff(dayjs(data?.createdAt), 'month') < 12

  const hasLinkTracking = useHasCapability(CAPABILITIES.FEATURE.LINK_TRACKING.ALL)

  function handleClickMessage() {
    setIsComposeModalOpen(true)
  }

  // new compose modal
  const [isComposeModalOpen, setIsComposeModalOpen] = useState(false)

  function handleCloseComposeMessage() {
    setIsComposeModalOpen(false)
  }

  const hasRemessageCommunicationChannel = client?.communicationChannels?.some(
    (channel) => channel === data?.communicationChannel,
  )
  const remessageDisabled =
    data?.status === CampaignStatus.MEDIA_TRANSCODING_FAILED ||
    data?.responseMetadata?.responseDataViewable === false ||
    campaignDetailsIsLoading ||
    !hasRemessageCommunicationChannel

  const recipientCount = data?.recipientCount || 0

  return (
    <>
      {isRecipientModalOpen && <RecipientModal onClose={() => setIsRecipientModalOpen(false)} />}
      <StyledActionPanel name={PANELS.RECIPIENT}>
        <ActionPanelContactsHeader
          communicationChannel={data?.communicationChannel}
          isUserSelection={false}
          onClickCount={() => setIsRecipientModalOpen(true)}
          recipientCountString={t('campaigns.recipientsActionPanel.numberOfRecipients', {
            count: recipientCount,
            communicationChannel: communicationChannelLabels[String(data?.communicationChannel)],
          })}
          recipientCountSubheading={filtersString}
        />
        {data && data?.nestLevel <= 2 && (
          <StyledActionButtons data-testid="action-buttons">
            <Tooltip content={<TooltipContent status={data?.status} />} disabled={!remessageDisabled}>
              <Layout display="flex" justifyContent="center">
                <StyledActionButton
                  disabled={remessageDisabled}
                  icon="comment"
                  id="detail-pane-message"
                  label="message"
                  onClick={handleClickMessage}
                />
              </Layout>
            </Tooltip>
            <Tooltip content={<TooltipContent status={data?.status} />} disabled={!remessageDisabled}>
              <Layout display="flex" justifyContent="center">
                <StyledActionButton
                  disabled={
                    remessageDisabled ||
                    // Apple Messages does not support adding to community
                    data?.communicationChannel === CommunicationChannel.AMB
                  }
                  icon="plus"
                  id="detail-pane-add-to"
                  label="add to"
                  onClick={onClickAddToCommunity}
                />
              </Layout>
            </Tooltip>
          </StyledActionButtons>
        )}
        {campaignCreatedUnderTwelveMonths && <ResponseRate campaignId={campaignId} />}
        {
          // Dont show reactions for AMB and WhatsApp
          ![CommunicationChannel.WHATS_APP, CommunicationChannel.AMB].includes(
            data?.communicationChannel as CommunicationChannel,
          ) && <CampaignReactions campaignId={campaignId} />
        }
        {isComposeModalOpen && (
          <ComposeMessage
            initialFilterState={{
              communicationChannel: data?.communicationChannel,
              includedFilters: {
                operand: {
                  type: FieldTypes.UUID,
                  value: campaignId,
                  source: FieldSources.BUILT_IN,
                  field_key: BuiltInFields.CAMPAIGN_RECIPIENTS,
                  field_label: fieldLabelFor(BuiltInFields.CAMPAIGN_RECIPIENTS),
                },
                operator: SelectorOperators.QUERY,
              },
              excludedFilters: null,
            }}
            isRemessage={true}
            onClose={handleCloseComposeMessage}
            readOnlyRecipients={true}
            respondingToCampaign={campaignId}
            shouldDisableScheduleMessages={false}
          />
        )}
        <CampaignResponses campaignId={campaignId} />
        {hasLinkTracking ? (
          <>
            <LinkClicks totalRecipients={recipientCount} />
            <MediaViews totalRecipients={recipientCount} />
          </>
        ) : (
          <TeaserChart />
        )}
      </StyledActionPanel>
    </>
  )
}
