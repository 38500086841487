import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'

import { postTrackBilling } from 'api/billing'
import { useClientId } from 'hooks/useClient'
import { AnalyticsEvent } from 'utils/analytics/events'

export const useTrackBilling = (): ((event: AnalyticsEvent) => void) => {
  const clientId = useClientId()
  const { mutate: track } = useMutation(postTrackBilling)
  const trackBilling = useCallback(
    (event: AnalyticsEvent) => {
      track({ clientId, event })
    },
    [clientId, track],
  )
  return trackBilling
}
