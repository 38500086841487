import { Ellipsis, ListItem, MessageDisabledIcon, SPACING, Tooltip, buildLastSentTitle } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useQuery } from '@tanstack/react-query'
import noop from 'lodash/noop'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { MemberAvatar } from 'components/MemberAvatar'
import { ItemTime } from 'components/time'
import { QUERY_CACHE } from 'constants/query-cache'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useUpdateChat } from 'hooks/useUpdateChat'
import analytics from 'utils/analytics'
import { request } from 'utils/api'

const STALE_TIME = 100 * 60 * 5

const StyledTitle = styled.div<{ color?: string }>`
  display: flex;
  justify-content: space-between;
  color: ${({ theme, color }) => color || theme?.COLORS?.TEXT};
`

const StyledTime = styled.span`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  font-weight: normal;
`

const StyledIconContainer = styled.div`
  display: inline-block;
  margin-left: ${SPACING[1]};
`

const StyledMarkUnreadButton = styled.button`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 22px;
  left: 0;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  ${ListItem.Indicators.Read} {
    position: relative;
    top: unset;
    left: 4px;
    margin-top: unset;
  }

  &:hover {
    ${ListItem.Indicators.Read} {
      // TODO - Update this color once https://github.com/Shimmur/web-ui/pull/394 is merged
      border-color: #888888;
    }
  }
`

const Indicator = (props: InboxItemProps) => {
  const { unread, favorited, incoming } = props
  const { t } = useTranslation()

  const { mutateAsync: updateChat } = useUpdateChat()

  if (favorited) {
    return <ListItem.Indicators.Favorite />
  }

  if (unread) {
    return <ListItem.Indicators.Unread />
  }

  if (!incoming) {
    return <ListItem.Indicators.Sent />
  }

  if (!unread) {
    return (
      <StyledMarkUnreadButton
        aria-label={t('markUnread')}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          analytics.track(analytics.events.MarkedUnread())

          updateChat({
            fanSubscriptionId: props.fan.fanSubscriptionId,
            fanId: props.fan.fanId,
            chat: {
              has_new: true,
            },
          })
        }}
      >
        <ListItem.Indicators.Read />
      </StyledMarkUnreadButton>
    )
  }

  return null
}

type InboxItemProps = {
  active?: boolean
  body?: string
  canReply?: boolean
  communicationChannel?: CommunicationChannel | null
  fan?: any
  favorited?: boolean
  fullName?: string
  graphicUrl?: string
  id?: string
  incoming?: boolean
  media?: any
  multilineDensity?: number
  onItemClick?: (...args: any[]) => any
  ts?: string
  unread?: boolean
}

const InboxItem = React.forwardRef<any, InboxItemProps>((props, ref) => {
  const { t } = useTranslation()
  const { data: seat } = useSeat()
  const {
    id,
    fan,
    onItemClick = noop,
    canReply = true,
    unread,
    body,
    media,
    communicationChannel = CommunicationChannel.SMS,
    graphicUrl,
    active,
    fullName,
    ts,
    multilineDensity = 6,
  } = props
  const { COLORS } = useTheme()
  const { data }: { data: any } = useQuery(
    [QUERY_CACHE.PREFERENCES],
    async () => request(`/preferences/v1/seats/${seat!.id}`, { camelCase: false }),
    {
      enabled: Boolean(seat?.id),
      staleTime: STALE_TIME,
    },
  )
  const density = data?.data?.inbox_display_density === 'multiline' ? multilineDensity : 2

  function handleOnClickInboxItemRoot() {
    onItemClick(fan, unread)
  }

  const isWhatsAppConversation = communicationChannel === CommunicationChannel.WHATS_APP
  const isAmbConversation = communicationChannel === CommunicationChannel.AMB

  const cannotReplyTooltip = isWhatsAppConversation
    ? t('convo.whatsAppMessagingDisabled')
    : isAmbConversation
    ? t('convo.ambMessagingDisabled')
    : undefined

  return (
    <ListItem
      data-testid="inbox-item"
      id={id}
      label={
        <StyledTitle color={canReply === false ? COLORS.SUBTEXT : undefined}>
          <span data-private="redact">{fullName}</span>
          <StyledTime>
            <ItemTime ts={ts} />
            {canReply === false && (
              <Tooltip content={cannotReplyTooltip}>
                <StyledIconContainer aria-label="Messaging Disabled" role="img">
                  <MessageDisabledIcon size={16} />
                </StyledIconContainer>
              </Tooltip>
            )}
          </StyledTime>
        </StyledTitle>
      }
      onClick={handleOnClickInboxItemRoot}
      prefix={
        <MemberAvatar
          badge={
            <CommunicationChannelIcon
              communicationChannel={fan?.communicationChannel || communicationChannel}
              filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))"
              size={19}
              style={{ marginTop: SPACING[2] }}
            />
          }
          fan={fan}
          graphicUrl={graphicUrl}
        />
      }
      ref={ref}
      selected={active}
      subtext={
        <div data-private="redact">
          <Ellipsis aria-label="Message Body Content" lines={density}>
            {buildLastSentTitle(body, media)}
          </Ellipsis>
        </div>
      }
    >
      <Indicator {...props} />
    </ListItem>
  )
})

export default InboxItem
