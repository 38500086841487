import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { buildLastSentTitle } from '@community_dev/pixels'
import IconCake from '@community_dev/pixels/dist/svg/illustrations/cake.svg?react'
import IconMessage from '@community_dev/pixels/dist/svg/illustrations/message.svg?react'
import IconPound from '@community_dev/pixels/dist/svg/illustrations/pound.svg?react'
import IconYo from '@community_dev/pixels/dist/svg/illustrations/yo.svg?react'
import { History } from 'history'
import React from 'react'

import analytics from './analytics'
import { route } from './router'

import { ROUTES } from 'constants/routes'
import { pluralizeNumeral } from 'utils/general'

export enum SuggestionCardCustomTypes {
  COMPOSE = 'Compose',
  URL = 'URL',
}

export enum SuggestionCardTemplateTypes {
  BIRTHDAY = 'birthday',
  ONBOARDING = 'onboarding',
  COMPOSE = `custom_${SuggestionCardCustomTypes.COMPOSE}`,
  COMMUNITY_CARES = `custom_community_cares_${SuggestionCardCustomTypes.COMPOSE}`,
  URL = `custom_${SuggestionCardCustomTypes.URL}`,
  LAST_SENT = 'view_campaign_responses',
  PROMOTE_NUMBER = 'promote_number',
  UNREAD_FAN = 'unread_fan',
}

const buildBirthdayTitle = (count: number) => {
  const plural = 'people have birthdays'
  const singular = 'person has a birthday'
  const countText = pluralizeNumeral(count.toString(), '0,0', singular, plural)
  return `${countText} today`
}

const trackCardClick = (
  filters: MemberDataFilter | Record<string, never>,
  title: string,
  type: string,
  url?: string,
) => {
  analytics.track(analytics.events.SuggestionCardClicked(filters, title, type, url))
}

type CustomCardReturnType = {
  title: string
  subtitle: string
  composeMessage?: undefined
  type: string
  icon: React.ReactNode
  onClick: (setRecipientFilters: React.Dispatch<React.SetStateAction<MemberDataFilter | null>>) => void
}

type CommunityCaresReturnType = {
  title: string
  subtitle: string
  composeMessage: string
  type: string
  icon: React.ReactNode
  onClick: (setRecipientFilters: React.Dispatch<React.SetStateAction<MemberDataFilter | null>>) => void
}

type BirthdayReturnType = {
  title: string
  subtitle: string
  composeMessage?: undefined
  type: string
  icon: React.ReactNode
  onClick: (setRecipientFilters: React.Dispatch<React.SetStateAction<MemberDataFilter | null>>) => void
}

export type SuggestionCardTemplate = CustomCardReturnType | BirthdayReturnType | CommunityCaresReturnType

export const templates = {
  birthday: (meta: { count: number; ts: string }): BirthdayReturnType => {
    const title = buildBirthdayTitle(meta.count)
    const type = SuggestionCardTemplateTypes.BIRTHDAY
    return {
      title,
      subtitle: 'Send them a message',
      type,
      icon: <IconCake />,
      onClick: (setRecipientFilters) => {
        const filters: MemberDataFilter = {
          operator: SelectorOperators.EQUALS,
          operand: {
            field_key: BuiltInFields.BIRTHDAY,
            field_label: fieldLabelFor(BuiltInFields.BIRTHDAY),
            source: FieldSources.BUILT_IN,
            type: FieldTypes.DATE,
            value: meta.ts,
          },
        }

        setRecipientFilters(filters)
        trackCardClick(filters, title, type)
      },
    }
  },
  lastSent: (meta: any, history: History): any => {
    const title = buildLastSentTitle(meta.body, meta.media, true)
    const type = SuggestionCardTemplateTypes.LAST_SENT
    return {
      title,
      subtitle: 'View responses',
      type,
      icon: <IconMessage />,
      onClick: () => {
        history.push(route(ROUTES.CAMPAIGN_DETAILS, { campaignId: meta.id }))
        trackCardClick({}, title, type)
      },
    }
  },
  promoteNumber: (history: History): any => {
    const title = 'Share your phone number'
    const type = SuggestionCardTemplateTypes.PROMOTE_NUMBER
    return {
      title,
      subtitle: 'Get more contacts',
      type,
      icon: <IconPound />,
      onClick: () => {
        history.push(ROUTES.GROWTH_TOOLS)
        trackCardClick({}, title, type)
      },
    }
  },
  unreadFan: (meta: { firstName: string; id: string }, history: History): any => {
    const title = `${meta.firstName} sent you a message`
    const type = SuggestionCardTemplateTypes.UNREAD_FAN
    return {
      title,
      subtitle: 'Make their day',
      type,
      icon: <IconYo />,
      onClick: () => {
        history.push(`/messages/inbox/${meta.id}`)
        trackCardClick({}, title, type)
      },
    }
  },
}
