import { ColorPicker, TextInput } from '@community_dev/pixels'
import React, { ChangeEvent, InputHTMLAttributes } from 'react'
import styled from 'styled-components'

export type CombinedInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'children'> & {
  name: string
  label: string
  colorValue: string
  onColorChange(value: string): any
  textValue: string
  error?: string
  onTextChange(value: string): any
}

const StyledInput = styled.div`
  display: flex;

  button {
    padding-right: 0;
  }
`

export const CombinedInput = (props: CombinedInputProps): JSX.Element => {
  const { colorValue, textValue, onColorChange, onTextChange, ...rest } = props

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    onTextChange(e.target.value)
  }
  return (
    <>
      <StyledInput>
        <TextInput {...rest} onChange={handleTextChange} value={textValue} />
        <ColorPicker align="right" color={colorValue} onChange={onColorChange} />
      </StyledInput>
    </>
  )
}
