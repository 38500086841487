import { AddOn } from 'api/addOns'
import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'

export enum AddOnsActionType {
  conditional = 'CONDITIONAL',
  route = 'ROUTE',
  link = 'LINK',
  formstack = 'FORMSTACK_API',
}

export enum FormstackActionNames {
  clientId = 'client_id',
  seatEmail = 'seat_email',
}

export enum AddOnsContentType {
  text = 'TEXT',
  image = 'IMAGE',
  heading = 'HEADING',
}

export const LINK_CLIENT_ID_PARAM = 'client_id'
export const LINK_SEAT_EMAIL_PARAM = 'seat_email'

export const addOns: AddOn[] = [
  {
    description: 'Supercharge your creativity & save time drafting campaigns',
    details: {
      action: {
        capabilities: [CAPABILITIES.FEATURE.AI_MESSAGE_GENERATOR.ALL],
        type: AddOnsActionType.conditional,
        whenFalse: {
          fields: [
            { fieldId: 147286622, fieldName: 'seat_email' },
            { fieldId: 147286621, fieldName: 'client_id' },
          ],
          formId: 5348842,
          text: 'Request Access',
          type: AddOnsActionType.formstack,
        },
        whenTrue: {
          text: 'Try Now',
          to: '#compose/?ac=ai_generator',
          type: AddOnsActionType.route,
        },
      },
      content: [
        {
          type: AddOnsContentType.text,
          value: 'Supercharge your creativity & save time drafting campaigns with this Beta feature',
        },
        {
          imageSrc: [
            '/add-ons/details/messaging/ai-message-generator-beta%403x.png 3x',
            '/add-ons/details/messaging/ai-message-generator-beta%402x.png 2x',
            '/add-ons/details/messaging/ai-message-generator-beta.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            "This innovative new tool uses OpenAI's artificial intelligence to help you craft personalized and engaging campaign messages.<ul><li>Save time and effort</li><li>Supercharge your creativity</li> <li>Optimize content to reach your goals</li><li>Increase member engagement</li></ul> The tool analyzes your input and generates messages tailored to your specific needs, saving you time and effort. You can easily edit and fine-tune the generated messages to align with your brand voice, tone, and marketing goals. Don't worry - the tool will never send messages on your behalf. You'll always be in full control of which generated messages are sent to your audience.",
        },
        {
          type: AddOnsContentType.text,
          value:
            'Visit the <a href="https://help.community.com/hc/en-us/articles/17764824605587" target="_blank" rel="noopener noreferrer">help page</a> to learn more.',
        },
        {
          type: AddOnsContentType.heading,
          value: 'Requirements',
        },
        {
          type: AddOnsContentType.text,
          value:
            "We'll be gradually rolling this feature out to eligible accounts. Access may be subject to additional terms.",
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/messaging/ai-message-generator-beta%403x.png 3x',
      '/add-ons/cards/messaging/ai-message-generator-beta%402x.png 2x',
      '/add-ons/cards/messaging/ai-message-generator-beta.png 1x',
    ],
    featureRank: 1,
    title: 'AI Message Generator (Beta)',
    type: 'Messaging',
  },
  {
    description: 'Import your existing customer list into Community',
    details: {
      action: {
        params: ['client_id', 'seat_email'],
        text: 'Request Import',
        type: AddOnsActionType.link,
        url: 'https://community.formstack.com/forms/number_import',
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Upload a list of existing phone numbers into Community to quickly grow your audience. Once numbers are imported, all new Members and their associated data will display within the Community dashboard for messaging and insights.',
        },
        {
          imageSrc: [
            '/add-ons/details/grow-your-list/list-imports%403x.png 3x',
            '/add-ons/details/grow-your-list/list-imports%402x.png 2x',
            '/add-ons/details/grow-your-list/list-imports.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'Requirements' },
        {
          type: AddOnsContentType.text,
          value:
            '<a href="https://help.community.com/hc/en-us/articles/5638991844883-List-Imports" target="_blank" rel="noopener noreferrer">Number imports</a> may be available for qualified customers on our usage-based plans. Number imports are available by request only and the numbers must have been obtained in a TCPA-compliant manner. Feature availability and functionality may vary by service plan.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/grow-your-list/list-imports%403x.png 3x',
      '/add-ons/cards/grow-your-list/list-imports%402x.png 2x',
      '/add-ons/cards/grow-your-list/list-imports.png 1x',
    ],
    featureRank: 2,
    title: 'List Imports',
    type: 'Grow Your List',
  },
  {
    details: {
      action: {
        capabilities: [CAPABILITIES.FEATURE.MMS_MESSAGING.ALL],
        type: AddOnsActionType.conditional,
        whenFalse: {
          disabled: {
            capabilities: [CAPABILITIES.CLIENT.BILLING.WRITE],
            tooltip: 'Only seats with "Owner" and "Admin" access can request',
          },
          params: ['client_id', 'seat_email'],
          text: 'Request',
          type: AddOnsActionType.link,
          url: 'https://community.formstack.com/forms/mms',
        },
        whenTrue: {
          text: 'Enable',
          to: ROUTES.SETTINGS.CUSTOMIZE.ROOT,
          type: AddOnsActionType.route,
        },
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Enrich your texts and grab attention with MMS! Send stunning photos, voice memos, and catchy GIFs to skyrocket your message engagement.',
        },
        {
          imageSrc: [
            '/add-ons/details/messaging/mms%403x.png 3x',
            '/add-ons/details/messaging/mms%402x.png 2x',
            '/add-ons/details/messaging/mms.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'Attach media files to your messages and send! Captivate your audience and boost engagement with rich, visual content. Your media files reach Members directly without requiring clicks on Community link trackers.',
        },
        {
          type: AddOnsContentType.text,
          value:
            '<h3>Supported Message Types:</h3><ul><li>Direct Messages (DMs): Engage in one-on-one conversations</li><li>Campaigns: Send targeted messages to specific audiences</li> <li>Scheduled Campaigns: Plan and automate your messaging for future delivery</li><li>Flows: Create personalized experiences with welcome series, keyword triggers, and more</li></ul>',
        },
        {
          type: AddOnsContentType.text,
          value:
            '<h3>Supported File Types (Max 600 kB):</h3><ul><li>Images: JPEG, PNG, GIF</li><li>Audio Recordings: MP3</li><li>GIFs: Uploaded directly (not from GIF picker)</li></ul>',
        },
        {
          type: AddOnsContentType.text,
          value: '<h3>Unsupported Feature:</h3><ul><li>Video: Due to file size limitations set by carriers</li></ul>',
        },
        { type: AddOnsContentType.heading, value: 'Requirements' },
        {
          type: AddOnsContentType.text,
          value:
            'MMS may be available for qualified customers on our usage-based plans. Feature availability and functionality may vary by service plan.',
        },
      ],
    },
    description: 'Level up your messaging experience with rich photos, GIFs & voice memos',
    imageSrc: [
      '/add-ons/cards/messaging/mms%403x.png 3x',
      '/add-ons/cards/messaging/mms%402x.png 2x',
      '/add-ons/cards/messaging/mms.png 1x',
    ],
    featureRank: 3,
    title: 'Send Media using MMS',
    type: 'Messaging',
  },
  {
    description: 'Generate a QR code that customers can scan to text you',
    details: {
      action: {
        text: 'View QR Code',
        to: '/growth-tools?ac=qr',
        type: AddOnsActionType.route,
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Turn your Community URL into a QR Code, making it easy for anyone to text your Community Number by automatically opening a new message on their devices.',
        },
        {
          imageSrc: [
            '/add-ons/details/grow-your-list/qr-code%403x.png 3x',
            '/add-ons/details/grow-your-list/qr-code%402x.png 2x',
            '/add-ons/details/grow-your-list/qr-code.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'Prepopulate Text' },
        {
          type: AddOnsContentType.text,
          value:
            'By default the message will be blank; however, you can <a href="https://help.community.com/hc/en-us/articles/7264471248147-Pre-Populate-Texts-for-your-Audience" target="_blank" rel="noopener noreferrer">pre-populate a message</a> to help guide your audience on what to text you, depending on the activation at hand. This helps to avoid typos and lower the barrier to entry for your Community. Test your customized URL and scan your QR code on your personal phone to make sure your audience will have the streamlined experience you intended for them.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/grow-your-list/qr-code%403x.png 3x',
      '/add-ons/cards/grow-your-list/qr-code%402x.png 2x',
      '/add-ons/cards/grow-your-list/qr-code.png 1x',
    ],
    title: 'QR Code',
    type: 'Grow Your List',
  },
  {
    description: 'Generate a website popup to capture phone numbers',
    details: {
      action: {
        text: 'Create Popup',
        to: '/settings/integrations?ac=wpu',
        type: AddOnsActionType.route,
      },
      content: [
        {
          type: AddOnsContentType.text,
          value: 'Add a high-performing website popup to your website and convert web visitors into Community Members.',
        },
        {
          imageSrc: [
            '/add-ons/details/grow-your-list/website-popup%403x.png 3x',
            '/add-ons/details/grow-your-list/website-popup%402x.png 2x',
            '/add-ons/details/grow-your-list/website-popup.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'Maximize Value' },
        {
          type: AddOnsContentType.text,
          value:
            'This popup is designed in the Community dashboard to match your brand, and the code is provided to <a href="https://help.community.com/hc/en-us/articles/1500008851302-Website-sign-up-unit-set-up-instructions" target="_blank" rel="noopener noreferrer">directly inject</a> onto your website. Use an incentive to encourage Members to signup on your website. Website popups will display seamlessly on both web and mobile.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/grow-your-list/website-popup%403x.png 3x',
      '/add-ons/cards/grow-your-list/website-popup%402x.png 2x',
      '/add-ons/cards/grow-your-list/website-popup.png 1x',
    ],
    title: 'Website Popup',
    type: 'Grow Your List',
  },
  {
    description: 'Generate an embeddable website form to capture phone numbers',
    details: {
      action: {
        text: 'Create Embed',
        to: '/settings/integrations?ac=we',
        type: AddOnsActionType.route,
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Add a high-performing embedded signup form up to your website and convert web visitors into Community Members.',
        },
        {
          imageSrc: [
            '/add-ons/details/grow-your-list/website-embed%403x.png 3x',
            '/add-ons/details/grow-your-list/website-embed%402x.png 2x',
            '/add-ons/details/grow-your-list/website-embed.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'Maximize Value' },
        {
          type: AddOnsContentType.text,
          value:
            'This embed is designed in the Community dashboard to match your brand, and the code is provided to <a href="https://help.community.com/hc/en-us/articles/1500008851302-Website-sign-up-unit-set-up-instructions" target="_blank" rel="noopener noreferrer">directly inject</a> onto your website. Use an incentive to encourage Members to signup on your website. Website embeds will display seamlessly on both web and mobile.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/grow-your-list/website-embed%403x.png 3x',
      '/add-ons/cards/grow-your-list/website-embed%402x.png 2x',
      '/add-ons/cards/grow-your-list/website-embed.png 1x',
    ],
    title: 'Website Embed',
    type: 'Grow Your List',
  },
  {
    description: 'Automatically add New Members from your external TCPA compliant web forms',
    details: {
      action: {
        params: ['client_id', 'seat_email'],
        text: 'Request Integration',
        type: AddOnsActionType.link,
        url: 'https://community.formstack.com/forms/integrate_external_forms',
      },
      content: [
        {
          type: AddOnsContentType.text,
          value: 'Directly connect a signup form on your website to send numbers to Community to opt in new Members.',
        },
        {
          imageSrc: [
            '/add-ons/details/grow-your-list/integrate-external-forms%403x.png 3x',
            '/add-ons/details/grow-your-list/integrate-external-forms%402x.png 2x',
            '/add-ons/details/grow-your-list/integrate-external-forms.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How It Works' },
        {
          type: AddOnsContentType.text,
          value:
            'The Community team will work with you to find the right integration for your website form. We offer both custom webhooks, in addition to connections via Zapier. Once a Member submits the form, they will receive a message and be prompted to opt in to your Community.',
        },
        { type: AddOnsContentType.heading, value: 'Requirements' },
        {
          type: AddOnsContentType.text,
          value:
            'Integrated External Forms may be available for qualified customers on our usage-based plans. External Forms are available by request only and the numbers must be obtained in a TCPA-compliant manner. Feature availability and functionality may vary by service plan.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/grow-your-list/integrate-external-forms%403x.png 3x',
      '/add-ons/cards/grow-your-list/integrate-external-forms%402x.png 2x',
      '/add-ons/cards/grow-your-list/integrate-external-forms.png 1x',
    ],
    title: 'Integrate External Forms',
    type: 'Grow Your List',
  },
  {
    description: 'Simplify the onboarding experience by only requiring customers to text back “Y”',
    details: {
      action: {
        params: ['client_id', 'seat_email'],
        text: 'Request Feature',
        type: AddOnsActionType.link,
        url: 'https://community.formstack.com/forms/reply_y',
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'The Reply Y onboarding flow is a fully TCPA-compliant opt-in flow to enable ongoing 2-way communication with your audience. This flow enables anyone to opt-in by simply replying Y, and does not require additional steps or information from your Members.',
        },
        {
          imageSrc: [
            '/add-ons/details/onboarding-experience/reply-y%403x.png 3x',
            '/add-ons/details/onboarding-experience/reply-y%402x.png 2x',
            '/add-ons/details/onboarding-experience/reply-y.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'The person signing up will first receive your personal Welcome Message, with the call to action to <a href="https://help.community.com/hc/en-us/articles/5639235505299-Reply-Y-" target="_blank" rel="noopener noreferrer">Reply Y</a> to opt-in, including required legal language. When they respond Y or Yes (case insensitive), they will automatically be opted-in to receive all future texts from your Community number. Immediately they are greeted with your personal Confirmation Message, along with required STOP/HELP language. Your contact card gets sent immediately after the Confirmation Message.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/onboarding-experience/reply-y%403x.png 3x',
      '/add-ons/cards/onboarding-experience/reply-y%402x.png 2x',
      '/add-ons/cards/onboarding-experience/reply-y.png 1x',
    ],
    title: 'Reply Y',
    type: 'Onboarding Experience',
  },
  {
    description: 'Request custom data from contacts on your registration form',
    details: {
      action: {
        fields: [
          { fieldId: 131641937, fieldName: 'seat_email' },
          { fieldId: 131641936, fieldName: 'client_id' },
        ],
        formId: 4990078,
        text: 'Request Custom Fields',
        type: AddOnsActionType.formstack,
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Collect custom data from your Members when they sign up for your Community. This data is collected alongside our standard fields, which include first name, last name, location, date of birth and email. You may also elect to remove any unwanted standard fields',
        },
        {
          imageSrc: [
            '/add-ons/details/onboarding-experience/custom-signup-fields%403x.png 3x',
            '/add-ons/details/onboarding-experience/custom-signup-fields%402x.png 2x',
            '/add-ons/details/onboarding-experience/custom-signup-fields.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'Once Members sign up and provide the custom data, this data can be used for both targeting and message content. Community allows you to target all members that match specific criteria, in addition to letting that data be inserted directly into a message.',
        },
        { type: AddOnsContentType.heading, value: 'Requirements' },
        {
          type: AddOnsContentType.text,
          value:
            '<a href=" https://help.community.com/hc/en-us/articles/11327509855123--Add-Ons-Custom-Signup-Fields" target="_blank" rel="noopener noreferrer">Custom Signup Fields</a> may be available for qualified customers on our usage-based plans. Feature availability and functionality may vary by service plan.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/onboarding-experience/custom-signup-fields%403x.png 3x',
      '/add-ons/cards/onboarding-experience/custom-signup-fields%402x.png 2x',
      '/add-ons/cards/onboarding-experience/custom-signup-fields.png 1x',
    ],
    featureRank: 5,
    title: 'Custom Signup Fields',
    type: 'Onboarding Experience',
  },
  {
    description: 'Add custom branding to your registration form',
    details: {
      action: {
        fields: [
          { fieldId: 131918400, fieldName: 'seat_email' },
          { fieldId: 131918399, fieldName: 'client_id' },
        ],
        formId: 4997382,
        text: 'Request Custom Branding',
        type: AddOnsActionType.formstack,
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Customize your Member’s signup experience by injecting your own branding onto the signup form. This includes setting a custom background image and logo that will appear for all new Members.',
        },
        {
          imageSrc: [
            '/add-ons/details/onboarding-experience/custom-branded-signup%403x.png 3x',
            '/add-ons/details/onboarding-experience/custom-branded-signup%402x.png 2x',
            '/add-ons/details/onboarding-experience/custom-branded-signup.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'The Community team will work with you to select the correct images and sizes, and then upload those images to display during the signup process. The signup form text will automatically be adjusted to be legible when displayed on top of your custom background. ',
        },
        { type: AddOnsContentType.heading, value: 'Requirements' },
        {
          type: AddOnsContentType.text,
          value:
            '<a href="https://help.community.com/hc/en-us/articles/13834431687443--Add-Ons-Custom-Branded-Signup" target="_blank" rel="noopener noreferrer">Custom Branded Signup</a> may be available for qualified customers on our usage-based plans. Feature availability and functionality may vary by service plan.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/onboarding-experience/custom-branded-signup%403x.png 3x',
      '/add-ons/cards/onboarding-experience/custom-branded-signup%402x.png 2x',
      '/add-ons/cards/onboarding-experience/custom-branded-signup.png 1x',
    ],
    title: 'Custom Branded Signup',
    type: 'Onboarding Experience',
  },
  {
    description: 'Set up a custom series of messages for contacts after they signup',
    details: {
      action: {
        capabilities: [CAPABILITIES.FEATURE.WORKFLOWS.ALL],
        type: AddOnsActionType.conditional,
        whenFalse: {
          fields: [
            { fieldId: 131918535, fieldName: 'seat_email' },
            { fieldId: 131918534, fieldName: 'client_id' },
          ],
          formId: 4997388,
          text: 'Request Welcome Series',
          type: AddOnsActionType.formstack,
        },
        whenTrue: {
          text: 'Enable Welcome Series',
          to: '/automations/flows?ac=ws',
          type: AddOnsActionType.route,
        },
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Automatically trigger a series of messages to new Community Members upon signup to increase retention and engagement with your Members.',
        },
        {
          imageSrc: [
            '/add-ons/details/onboarding-experience/welcome-series%403x.png 3x',
            '/add-ons/details/onboarding-experience/welcome-series%402x.png 2x',
            '/add-ons/details/onboarding-experience/welcome-series.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'Key Capabilities and Benefits' },
        {
          type: AddOnsContentType.text,
          value:
            'Immediately convert new Members by automating your SMS messaging with valuable calls-to-action. <a href="https://help.community.com/hc/en-us/articles/10142282457491-Workflow-Automations-How-to-set-up-an-Automated-Flow" target="_blank" rel="noopener noreferrer">Create custom engagement flows</a> by automatically triggering multiple time delayed messages. You may also improve future campaign personalization by including specific questions in your signup series to capture Member interests and segment the audience.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/onboarding-experience/welcome-series%403x.png 3x',
      '/add-ons/cards/onboarding-experience/welcome-series%402x.png 2x',
      '/add-ons/cards/onboarding-experience/welcome-series.png 1x',
    ],
    title: 'Welcome Series',
    type: 'Onboarding Experience',
  },
  {
    description: 'Automatically respond to contacts when they text certain keywords or emojis',
    details: {
      action: {
        text: 'Set up Keywords',
        to: '/settings/customize?ac=kr',
        type: AddOnsActionType.route,
      },
      content: [
        {
          type: AddOnsContentType.text,
          value: 'Trigger an automated response to a member who texts you a specific word, phrase, emoji, or hashtag.',
        },
        {
          imageSrc: [
            '/add-ons/details/messaging/keyword-responders%403x.png 3x',
            '/add-ons/details/messaging/keyword-responders%402x.png 2x',
            '/add-ons/details/messaging/keyword-responders.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'The message Members send must be match with the keyword to trigger the response. <a href="https://help.community.com/hc/en-us/articles/360052338994-How-do-Keyword-Responders-work-Can-I-automate-a-response-for-Community-Keywords-" target="_blank" rel="noopener noreferrer">Keywords</a> can be a word, phrase, emoji, or hashtag. The Keyword Responder works with members who have already opted in to your community. New members will receive the response once they complete the opt-in process. Community has smart logic in place to avoid multiple attempts of triggering the same response within a limited time frame.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/messaging/keyword-responders%403x.png 3x',
      '/add-ons/cards/messaging/keyword-responders%402x.png 2x',
      '/add-ons/cards/messaging/keyword-responders.png 1x',
    ],
    title: 'Keyword Responders',
    type: 'Messaging',
  },
  {
    description: 'Import customer data for segmentation and messaging',
    details: {
      action: {
        capabilities: [CAPABILITIES.FEATURE.CUSTOM_MEMBER_DATA.ALL, CAPABILITIES.FEATURE.CSV_SELF_IMPORT.ALL],
        type: AddOnsActionType.conditional,
        whenFalse: {
          fields: [
            { fieldId: 131918538, fieldName: 'seat_email' },
            { fieldId: 131918537, fieldName: 'client_id' },
          ],
          formId: 4997389,
          text: 'Request Custom Data',
          type: AddOnsActionType.formstack,
        },
        whenTrue: {
          text: 'Upload Custom Member Data',
          to: '/settings/customize',
          type: AddOnsActionType.route,
        },
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Custom Member Data allows you to upload custom data into your Community from an outside source allowing for a more personalized experience.',
        },
        {
          imageSrc: [
            '/add-ons/details/messaging/custom-member-data%403x.png 3x',
            '/add-ons/details/messaging/custom-member-data%402x.png 2x',
            '/add-ons/details/messaging/custom-member-data.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'Upon uploading your <a href="https://help.community.com/hc/en-us/articles/9347078450195-What-is-Custom-Member-Data-CMD-" target="_blank" rel="noopener noreferrer">Custom Member Data</a>, you will be able to message Members based on the data. We support plain text, date, integers, decimals, and true/false. Community also allows for this data to be inserted into campaigns dynamically as the message is delivered.',
        },
        { type: AddOnsContentType.heading, value: 'Requirements' },
        {
          type: AddOnsContentType.text,
          value:
            '<a href="https://help.community.com/hc/en-us/articles/11327666941971--Add-Ons-Custom-Member-Data" target="_blank" rel="noopener noreferrer">Custom Member Data</a> may be available for qualified customers on our usage-based plans. Feature availability and functionality may vary by service plan.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/messaging/custom-member-data%403x.png 3x',
      '/add-ons/cards/messaging/custom-member-data%402x.png 2x',
      '/add-ons/cards/messaging/custom-member-data.png 1x',
    ],
    title: 'Custom Member Data',
    type: 'Messaging',
  },
  {
    description: 'Set up filters to hide messages with specific words or phrases',
    details: {
      action: {
        text: 'Set up Filters',
        to: '/settings/customize?ac=foc',
        type: AddOnsActionType.route,
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Community offers the ability to hide messages from the dashboard when they contain keywords that are considered offensive.',
        },
        {
          imageSrc: [
            '/add-ons/details/messaging/filter-offensive-content%403x.png 3x',
            '/add-ons/details/messaging/filter-offensive-content%402x.png 2x',
            '/add-ons/details/messaging/filter-offensive-content.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'In the Community dashboard, simply provide a list of words that are offensive. All messages that contain these keywords will not be displayed. Members will not know that their messages are being hidden from the dashboard.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/messaging/filter-offensive-content%403x.png 3x',
      '/add-ons/cards/messaging/filter-offensive-content%402x.png 2x',
      '/add-ons/cards/messaging/filter-offensive-content.png 1x',
    ],
    title: 'Filter Offensive Content',
    type: 'Messaging',
  },
  {
    description: 'Programmatically send messages and update contact data',
    details: {
      action: {
        fields: [
          { fieldId: 131918663, fieldName: 'seat_email' },
          { fieldId: 131918662, fieldName: 'client_id' },
        ],
        formId: 4997392,
        text: 'Request API Access',
        type: AddOnsActionType.formstack,
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Interested in setting up a custom programmatic integration to import new Members, export data to another platform, or send messages? We offer a variety of custom solutions via API and Webhooks.',
        },
        {
          imageSrc: [
            '/add-ons/details/developer-tools-and-integrations/api%403x.png 3x',
            '/add-ons/details/developer-tools-and-integrations/api%402x.png 2x',
            '/add-ons/details/developer-tools-and-integrations/api.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'The Community team will work with your technical stakeholders to design the right solution based on your specific needs.',
        },
        { type: AddOnsContentType.heading, value: 'Requirements' },
        {
          type: AddOnsContentType.text,
          value:
            'APIs may be available for qualified customers on our usage-based plans. Feature availability and functionality may vary by service plan.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/developer-tools-and-integrations/api%403x.png 3x',
      '/add-ons/cards/developer-tools-and-integrations/api%402x.png 2x',
      '/add-ons/cards/developer-tools-and-integrations/api.png 1x',
    ],
    title: 'API',
    type: 'Developer Tools & Integrations',
  },
  {
    description: 'Enable Zapier to send messages and update contact data',
    details: {
      action: {
        capabilities: [CAPABILITIES.FEATURE.ZAPIER_INTEGRATION.EMBED],
        type: AddOnsActionType.conditional,
        whenFalse: {
          fields: [
            { fieldId: 131918786, fieldName: 'seat_email' },
            { fieldId: 131918785, fieldName: 'client_id' },
          ],
          formId: 4997394,
          text: 'Request Zapier',
          type: AddOnsActionType.formstack,
        },
        whenTrue: {
          text: 'Manage integration',
          to: '/settings/integrations/zapier',
          type: AddOnsActionType.route,
        },
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Zapier is an IpaaS (Integration Platform as a Service) that Community uses to enable self-serve customer integrations. By integrating with Community, Leaders minimize manual overhead, create robust marketing automations that make use of customer data to increase personalization and create customer experiences that drive revenue and loyalty.',
        },
        {
          imageSrc: [
            '/add-ons/details/developer-tools-and-integrations/zapier%403x.png 3x',
            '/add-ons/details/developer-tools-and-integrations/zapier%402x.png 2x',
            '/add-ons/details/developer-tools-and-integrations/zapier.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'Community\'s <a href="https://help.community.com/hc/en-us/articles/6883360847123" target="_blank" rel="noopener noreferrer">Zapier integration</a> allows Leaders to connect their existing systems to Community using Zapier to create powerful messaging automations, sync new Members acquired through Community to their CDP or CRM to increase audience reach, and increase revenue by deploying personalized SMS conversations at scale..',
        },
        { type: AddOnsContentType.heading, value: 'Requirements' },
        {
          type: AddOnsContentType.text,
          value:
            '<a href="https://help.community.com/hc/en-us/articles/11341504681235--Add-Ons-Zapier" target="_blank" rel="noopener noreferrer">Zapier</a> may be available for qualified customers on our usage-based plans. Feature availability and functionality may vary by service plan.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/developer-tools-and-integrations/zapier%403x.png 3x',
      '/add-ons/cards/developer-tools-and-integrations/zapier%402x.png 2x',
      '/add-ons/cards/developer-tools-and-integrations/zapier.png 1x',
    ],
    featureRank: 4,
    title: 'Zapier',
    type: 'Developer Tools & Integrations',
  },
  {
    description: 'Capture number on checkout, send abandon cart texts, and understand ROI',
    details: {
      action: {
        capabilities: [CAPABILITIES.FEATURE.SHOPIFY_INTEGRATION],
        type: AddOnsActionType.conditional,
        whenFalse: {
          fields: [
            { fieldId: 131918790, fieldName: 'seat_email' },
            { fieldId: 131918789, fieldName: 'client_id' },
          ],
          formId: 4997396,
          text: 'Request Shopify',
          type: AddOnsActionType.formstack,
        },
        whenTrue: {
          text: 'Enable Shopify',
          to: '/settings/integrations?ac=shop',
          type: AddOnsActionType.route,
        },
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Add the Shopify integration to your Community account in order to convert Shopify visitors into Community Members.',
        },
        {
          imageSrc: [
            '/add-ons/details/developer-tools-and-integrations/shopify%403x.png 3x',
            '/add-ons/details/developer-tools-and-integrations/shopify%402x.png 2x',
            '/add-ons/details/developer-tools-and-integrations/shopify.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'With a connected store, <a href="https://help.community.com/hc/en-us/sections/7732208860435-Shopify" target="_blank" rel="noopener noreferrer">Shopify</a> customers will have the option to receive an invite to join your Community from the checkout flow. After completing registration, these new Members will be automatically added to a Shopify community for you. This can be a great way to communicate with your Shopify customers directly from the Community app.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/developer-tools-and-integrations/shopify%403x.png 3x',
      '/add-ons/cards/developer-tools-and-integrations/shopify%402x.png 2x',
      '/add-ons/cards/developer-tools-and-integrations/shopify.png 1x',
    ],
    title: 'Shopify',
    type: 'Developer Tools & Integrations',
  },
  {
    description: 'Sync your customer data with Salesforce',
    details: {
      action: {
        capabilities: [CAPABILITIES.FEATURE.SALESFORCE_INTEGRATION],
        type: AddOnsActionType.conditional,
        whenFalse: {
          fields: [
            { fieldId: 131918788, fieldName: 'seat_email' },
            { fieldId: 131918787, fieldName: 'client_id' },
          ],
          formId: 4997395,
          text: 'Request Salesforce',
          type: AddOnsActionType.formstack,
        },
        whenTrue: {
          text: 'Enable Salesforce',
          to: '/settings/integrations?ac=sf',
          type: AddOnsActionType.route,
        },
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Directly integrate Salesforce with Community by identifying overlapping contacts and creating new Leads for Community Members not already tracked in Salesforce. ',
        },
        {
          imageSrc: [
            '/add-ons/details/developer-tools-and-integrations/salesforce%403x.png 3x',
            '/add-ons/details/developer-tools-and-integrations/salesforce%402x.png 2x',
            '/add-ons/details/developer-tools-and-integrations/salesforce.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'You must have a Salesforce account with administrator access in order to install the <a href="https://help.community.com/hc/en-us/articles/4405062400403--Salesforce-Sync-Members-w-Community-Integration" target="_blank" rel="noopener noreferrer">Salesforce integration</a> to sync data with Community. This integration will identify Community Members already in Salesforce as Contacts or Leads. This integration can also be configured to create new Leads for Community Members not already tracked in Salesforce.',
        },
        { type: AddOnsContentType.heading, value: 'Requirements' },
        {
          type: AddOnsContentType.text,
          value:
            '<a href="https://help.community.com/hc/en-us/articles/11341447305363--Add-Ons-Salesforce" target="_blank" rel="noopener noreferrer">Salesforce</a> integration may be available for qualified customers on our usage-based plans. Feature availability and functionality may vary by service plan.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/developer-tools-and-integrations/salesforce%403x.png 3x',
      '/add-ons/cards/developer-tools-and-integrations/salesforce%402x.png 2x',
      '/add-ons/cards/developer-tools-and-integrations/salesforce.png 1x',
    ],
    title: 'Salesforce',
    type: 'Developer Tools & Integrations',
  },
  {
    description: 'Automatically add UTM parameters to links you send to Members',
    details: {
      action: {
        text: 'Set up UTM Tracking',
        to: '/settings/customize?ac=utm',
        type: AddOnsActionType.route,
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Track the efficacy of your campaigns by automatically adding UTM parameters to the end of your links. This allows Leaders to attribute revenue and conversions in external platforms.',
        },
        {
          imageSrc: [
            '/add-ons/details/reporting-and-tracking/utm-tracking%403x.png 3x',
            '/add-ons/details/reporting-and-tracking/utm-tracking%402x.png 2x',
            '/add-ons/details/reporting-and-tracking/utm-tracking.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'What are UTM Parameters?' },
        {
          type: AddOnsContentType.text,
          value:
            'Adding UTM (<a href="https://help.community.com/hc/en-us/articles/4405473341587-How-to-Enable-UTM-Parameters-In-Community-to-Measure-Results" target="_blank" rel="noopener noreferrer">Urchin Tracking Module</a>) parameters allows you to better understand the users of your website. These parameters are simple tags added onto the end of a URL. When a visitor clicks a link with UTMs, those parameters can be tracked by your web analytics platform (i.e. Google Analytics).',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/reporting-and-tracking/utm-tracking%403x.png 3x',
      '/add-ons/cards/reporting-and-tracking/utm-tracking%402x.png 2x',
      '/add-ons/cards/reporting-and-tracking/utm-tracking.png 1x',
    ],
    title: 'UTM Tracking',
    type: 'Reporting & Tracking',
  },
  {
    description: 'Enable data exports with contact and campaign data',
    details: {
      action: {
        capabilities: [CAPABILITIES.CLIENT.DATA_DOWNLOAD.VIEW, CAPABILITIES.FEATURE.DATA_DOWNLOAD_STANDARD],
        type: AddOnsActionType.conditional,
        whenFalse: {
          fields: [
            { fieldId: 131918540, fieldName: 'seat_email' },
            { fieldId: 131918539, fieldName: 'client_id' },
          ],
          formId: 4997390,
          text: 'Request Data Exports',
          type: AddOnsActionType.formstack,
        },
        whenTrue: {
          text: 'Download Data',
          to: '/settings/?ac=des',
          type: AddOnsActionType.route,
        },
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Export your Community data as a CSV directly from the dashboard to understand the composition of your audience and the efficacy of your campaigns.',
        },
        {
          imageSrc: [
            '/add-ons/details/reporting-and-tracking/data-exports%403x.png 3x',
            '/add-ons/details/reporting-and-tracking/data-exports%402x.png 2x',
            '/add-ons/details/reporting-and-tracking/data-exports.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'What is Available' },
        {
          type: AddOnsContentType.text,
          value:
            '<a href="https://help.community.com/hc/en-us/articles/360052810614-Where-can-I-find-data-on-my-Community-members-What-data-exports-are-available-to-me-" target="_blank" rel="noopener noreferrer">Data exports</a> are available in the settings portion of the dashboard. The data exports currently offered include campaign and link performance, segment usage, member details and subscription status, communities, and community membership.',
        },
        { type: AddOnsContentType.heading, value: 'Requirements' },
        {
          type: AddOnsContentType.text,
          value:
            'Data Exports may be available for qualified customers on our usage-based plans. Feature availability and functionality may vary by service plan.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/reporting-and-tracking/data-exports%403x.png 3x',
      '/add-ons/cards/reporting-and-tracking/data-exports%402x.png 2x',
      '/add-ons/cards/reporting-and-tracking/data-exports.png 1x',
    ],
    title: 'Data Exports',
    type: 'Reporting & Tracking',
  },
  {
    description: 'Download the Community iOS app',
    details: {
      action: {
        text: 'Get the App',
        type: AddOnsActionType.link,
        url: 'https://apps.apple.com/us/app/community-real-conversations/id1494783989',
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Download the Community iOS app for access to many of Community’s core features directly from your iPhone.',
        },
        {
          imageSrc: [
            '/add-ons/details/other-features/ios-app%403x.png 3x',
            '/add-ons/details/other-features/ios-app%402x.png 2x',
            '/add-ons/details/other-features/ios-app.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'Community offers many features directly from the mobile app for easy access to sending campaigns and reading messages, in addition to many other frequently used features.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/other-features/ios-app%403x.png 3x',
      '/add-ons/cards/other-features/ios-app%402x.png 2x',
      '/add-ons/cards/other-features/ios-app.png 1x',
    ],
    title: 'iOS App',
    type: 'Other Features',
  },
  {
    description: 'Customize your dashboard appearance on web',
    details: {
      action: {
        text: 'Turn out the lights',
        to: '/settings/appearance?ac=dark',
        type: AddOnsActionType.route,
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Customize your web dashboard display based on your personal preferences. Community currently offers both light and dark modes.',
        },
        {
          imageSrc: [
            '/add-ons/details/other-features/dark-mode%403x.png 3x',
            '/add-ons/details/other-features/dark-mode%402x.png 2x',
            '/add-ons/details/other-features/dark-mode.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How it Works' },
        {
          type: AddOnsContentType.text,
          value:
            'Head to settings to specify the desired appearance of the dashboard, which includes both a light and a dark mode. You will also find other appearance settings here, including the inbox display density, and default emoji skin tones.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/other-features/dark-mode%403x.png 3x',
      '/add-ons/cards/other-features/dark-mode%402x.png 2x',
      '/add-ons/cards/other-features/dark-mode.png 1x',
    ],
    title: 'Dark Mode',
    type: 'Other Features',
  },
  {
    description: 'Reduce your costs by pre-purchasing messages at a discounted rate',
    details: {
      action: {
        fields: [
          { fieldId: 138473616, fieldName: 'seat_email' },
          { fieldId: 138473615, fieldName: 'client_id' },
        ],
        formId: 5147913,
        text: 'Request more info',
        type: AddOnsActionType.formstack,
      },
      content: [
        {
          type: AddOnsContentType.text,
          value:
            'Maximize your savings and stay ahead of your messaging needs by pre-purchasing your messages. By pre-purchasing messages at a discounted rate, you can reduce your costs and ensure that you always have the messaging resources you need.',
        },
        {
          imageSrc: [
            '/add-ons/details/messaging/pre-purchase_messages%403x.png 3x',
            '/add-ons/details/messaging/pre-purchase_messages%402x.png 2x',
            '/add-ons/details/messaging/pre-purchase_messages.png 1x',
          ],
          type: AddOnsContentType.image,
        },
        { type: AddOnsContentType.heading, value: 'How many messages will I need?' },
        {
          type: AddOnsContentType.text,
          value:
            'Our team is here to help you understand your messaging volume and find a package that is right for you at a price that works for you. With this flexible and cost-effective solution, you can stay connected and communicate with ease. Pre-purchase your messages today and start saving.',
        },
      ],
    },
    imageSrc: [
      '/add-ons/cards/messaging/pre-purchase_messages%403x.png 3x',
      '/add-ons/cards/messaging/pre-purchase_messages%402x.png 2x',
      '/add-ons/cards/messaging/pre-purchase_messages.png 1x',
    ],
    title: 'Pre-Purchase Messages',
    type: 'Messaging',
  },
]
