import { HTTP } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { ApiResponse } from 'types/api'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type ValidateCommunityKeywordArgs = {
  keyword: string
}

export type AddCommunityKeywordArgs = {
  communityId: string
  keyword: string
}

export type CheckCommunityKeywordResponse = {
  originalKeyword: string
}

export type AddCommunityKeywordResponse = {
  id: string
  originalKeyword: string
}

export function validateCommunityKeyword({
  keyword,
}: ValidateCommunityKeywordArgs): Promise<CheckCommunityKeywordResponse> {
  const path = ENDPOINTS.TAG.CHECK_KEYWORD
  const body = { original_keyword: keyword }
  return request(path, {
    body,
    method: HTTP.POST,
  })
}

export function addCommunityKeyword({
  communityId,
  keyword,
}: AddCommunityKeywordArgs): Promise<ApiResponse<AddCommunityKeywordResponse>> {
  const path = route(ENDPOINTS.TAG.KEYWORDS, { tagId: communityId })
  const body = { original_keyword: keyword }
  return request(path, {
    body,
    method: HTTP.POST,
  })
}

export type RemoveCommunityKeywordArgs = {
  communityId: string
  keywordId: string
}

export function removeCommunityKeyword({ communityId, keywordId }: RemoveCommunityKeywordArgs): Promise<void> {
  const path = route(ENDPOINTS.TAG.KEYWORD, { tagId: communityId, keywordId })
  return request(path, {
    method: HTTP.DELETE,
    responseMethod: 'text',
  })
}
