import { Tooltip } from '@community_dev/pixels'
import { forwardRef } from 'react'
import styled from 'styled-components'

import { useDataColorGradient } from 'screens/InsightsScreen/useDataColorGradient'

type ChartContainerProps = {
  $height: number
  $columnGap: number
  $columnWidth: number | string
}

const ChartContainer = styled.div<ChartContainerProps>`
  height: ${({ $height }) => $height};
  font-size: 14px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: ${({ $columnWidth }) => $columnWidth};
  grid-template-columns: repeat(auto-fit, minmax(1fr));
  grid-column-gap: ${({ $columnGap }) => $columnGap}px;
  grid-template-rows: ${({ $height }) => $height - 22 + 'px'} 22px;
  text-align: center;
`

const StyledTooltip = styled(Tooltip)`
  border-radius: 4px;
`

export const StyledBar = styled.div<{
  $height: string
  $borderRadius: number
  $background: string
}>`
  height: ${({ $height }) => $height};
  background: ${({ $background }) => $background};
  border-radius: ${({ $borderRadius }) => $borderRadius}px;
  transition: height 800ms cubic-bezier(0.22, 1, 0.36, 1), color 800ms ease;
`

export type Bar = {
  label?: string | React.ReactNode
  valueLabel?: JSX.Element
  value: number
  tooltip: string | JSX.Element
  color?: string
  key?: string
}

type BarChartProps = {
  barBorderRadius?: number
  barMinHeight?: number
  bars: Bar[]
  className?: string
  columnGap?: number
  columnWidth?: string
  height: number
}

export const BarChart = forwardRef<HTMLDivElement, BarChartProps>(
  (
    {
      barBorderRadius = 4,
      barMinHeight = 0,
      bars,
      className,
      columnGap = 16,
      columnWidth = '1fr',
      height,
    }: BarChartProps,
    ref,
  ): JSX.Element => {
    const values = bars.map((b) => b.value)
    const dataMin = Math.min(...values)
    const dataMax = Math.max(...values)
    const labelHeight = 22
    const barMaxHeight = height - labelHeight * 2
    const yScale = (value) => (dataMax > 0 ? (value / dataMax) * barMaxHeight : 0)
    const colorGradient = useDataColorGradient(dataMin, dataMax)
    return (
      <ChartContainer
        $columnGap={columnGap}
        $columnWidth={columnWidth}
        $height={height}
        className={className}
        ref={ref}
      >
        {bars.map((bar) => (
          <BarBlock
            barBorderRadius={barBorderRadius}
            color={bar.color || colorGradient(bar.value)}
            dataLabel={bar.label}
            height={(yScale(bar.value) || barMinHeight).toFixed(0) + 'px'}
            key={typeof bar.label === 'string' ? bar.label : bar.key}
            tooltip={bar.tooltip}
            valueLabel={bar.valueLabel}
          />
        ))}
      </ChartContainer>
    )
  },
)

// easing function used: https://easings.net/#easeOutQuint
// t^5 easing gives a nice fluid 'like physics but snappier' feeling
const BarBlock = ({ barBorderRadius, dataLabel, height, valueLabel, color, tooltip }) => (
  <>
    <div style={{ gridRow: '1', alignSelf: 'end' }}>
      {valueLabel && <div>{valueLabel}</div>}
      <StyledTooltip content={tooltip}>
        <StyledBar
          $background={color}
          $borderRadius={barBorderRadius}
          $height={height}
          className="bar-column"
          data-testid={`bar-${typeof dataLabel === 'string' ? dataLabel : 'column'}`}
        />
      </StyledTooltip>
    </div>
    <div data-testid={`bar-label-${typeof dataLabel === 'string' ? dataLabel : 'text'}`} style={{ gridRow: '2' }}>
      {dataLabel}
    </div>
  </>
)
