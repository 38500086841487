import { Block, FlightIcon, Tooltip } from '@community_dev/pixels'
import numeral from 'numeral'
import { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import styled, { useTheme } from 'styled-components'

import { ErrorCard } from './ErrorCard'

import { CAPABILITIES } from 'constants/capabilities'
import { STALE_TIME } from 'constants/query-cache'
import { useCampaignDetails } from 'hooks/useCampaign'
import { useHasCapability } from 'hooks/useUserCapability'
import dayjs from 'utils/dayjs'

const StyledRow = styled.div`
  display: flex;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  :last-child {
    border-bottom: none;
  }
`
const StyledLeftColumn = styled.div`
  flex: 1;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  :hover {
    color: ${({ theme }) => theme?.COLORS?.TEXT};
    cursor: pointer;
  }
`

const StyledRightColumn = styled.div`
  flex: 1;
`

const StyledGatheringData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  height: 200px;
`

const StyledGatheringTitle = styled.h2`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  margin: 0;
`

const StyledGatheringText = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  margin: 0;
`

const isCampaignOlderThanOneDay = (data) => {
  const now = dayjs()

  return now.diff(dayjs(data?.createdAt), 'day') > 1
}

const StyledTooltip = styled(Tooltip)`
  border-radius: 4px;
`

const StyledToolTipHeader = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`

const StyledToolTipBody = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`

const StyledToolTipWrapper = styled.div`
  width: 216px;
  padding: 0 8px;
`

const ToolTipMessage = ({ responseType }: { responseType: 'responded' | 'unresponded' | 'stop' }): JSX.Element => {
  let header = ''
  let body = ''
  switch (responseType) {
    case 'responded':
      header = 'Responded'
      body = 'Members who sent tapbacks and responded since receiving your initial message.'
      break
    case 'unresponded':
      header = "Didn't Respond"
      body = "Members who didn't respond since receiving your message."
      break
    case 'stop':
      header = 'Sent STOP'
      body = 'Members who sent a request to stop receiving messages.'
      break
    default:
      break
  }
  return (
    <StyledToolTipWrapper>
      <StyledToolTipHeader>{header}</StyledToolTipHeader>
      <StyledToolTipBody>{body}</StyledToolTipBody>
    </StyledToolTipWrapper>
  )
}

type ResponseRateProps = Partial<HTMLDivElement> & {
  campaignId: string
}

export const ResponseRate = ({ campaignId, ...props }: ResponseRateProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const [staleTime, setStaleTime] = useState(0)

  const showStopRate = useHasCapability(CAPABILITIES.FEATURE.CAMPAIGN_STOP_RATE)

  const {
    data,
    isLoading: isDetailsLoading,
    isError: isDetailsError,
    isSuccess: isDetailsSuccess,
  } = useCampaignDetails({
    campaignId,
  })

  const isProcessing = data?.status === 'media_processing'

  const isDataNotViewable = isDetailsSuccess && data?.responseMetadata?.responseDataViewable === false

  useEffect(() => {
    const isOlderThanOneDay = isCampaignOlderThanOneDay(data)
    isOlderThanOneDay ? setStaleTime(STALE_TIME.FIVE_MINUTES) : setStaleTime(0)
  }, [staleTime, data])

  if (isProcessing || isDataNotViewable) {
    return (
      <Block>
        <StyledGatheringData>
          <div>
            <FlightIcon color={COLORS?.SUBTEXT} />
          </div>
          <StyledGatheringTitle>Gathering responses</StyledGatheringTitle>
          <StyledGatheringText>Please check back soon!</StyledGatheringText>
        </StyledGatheringData>
      </Block>
    )
  }

  if (isDetailsError) {
    return <ErrorCard />
  }

  const { stopCount, stopRate } = data?.stopInfo || {}
  const { responseRate } = data?.responseInfo || {}

  return (
    <>
      <Block {...props} data-testid="response-rate" flush id="response-rate" title="Response Rate">
        {isDetailsLoading ? (
          <ContentLoader
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            height={116}
            speed={2}
            viewBox="0 0 272 116"
            width={272}
          >
            <rect height="48" rx="4" ry="4" width="272" x="0" y="0" />
            <rect height="48" rx="4" ry="4" width="272" x="0" y="52" />
          </ContentLoader>
        ) : (
          <div>
            <StyledRow>
              <StyledLeftColumn>
                <StyledTooltip content={<ToolTipMessage responseType="responded" />} placement="bottom-end">
                  <div>Responded</div>
                </StyledTooltip>
              </StyledLeftColumn>
              <StyledRightColumn data-testid="responded-value">
                {numeral(responseRate).format('0[.]0')}%
              </StyledRightColumn>
            </StyledRow>
            {!!stopCount && !!showStopRate && (
              <StyledRow>
                <StyledLeftColumn>
                  <StyledTooltip content={<ToolTipMessage responseType="stop" />} placement="bottom-end">
                    <div>Sent "STOP"</div>
                  </StyledTooltip>
                </StyledLeftColumn>
                <StyledRightColumn data-testid="stop-value">{numeral(stopRate).format('0[.]0')}%</StyledRightColumn>
              </StyledRow>
            )}
          </div>
        )}
      </Block>
    </>
  )
}
