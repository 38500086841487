import { Button, CloseIcon, DIALOG_VARIANTS, Dialog, Modal } from '@community_dev/pixels'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { StyledFilterModalCount, StyledFilterModalFooter } from './FilterModal.style'

import {
  RecommendationsLocation,
  RecommendationsAge,
  RecommendationsGenderIdentity,
  RecommendationsJoin,
} from 'components/ComposeMessage/components'
import { RecipientFieldContextProvider } from 'components/ComposeMessage/components/RecipientFieldContext'
import { RecommendationsTimezone } from 'components/ComposeMessage/components/RecommendationsTimezone'
import { SelectedFilters } from 'components/ComposeMessage/components/RecommendationsTop/SelectedFilters'
import { RecipientPane } from 'components/ComposeMessage/RecipientPane'
import { FilterPane } from 'containers/RecipientRecommendations/recommendation-constants'

type FilterModalProps = {
  active: boolean
  hasFilters: boolean
  recipientCount: number
  onApplyFilters: (...args: any[]) => void
  onClose: () => any
}

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`

const PANES: Record<string, any> = {
  [FilterPane.TOP]: () => SelectedFilters,
  [FilterPane.LOCATION]: () => RecommendationsLocation,
  [FilterPane.TIMEZONE]: () => RecommendationsTimezone,
  [FilterPane.AGE]: () => RecommendationsAge,
  [FilterPane.GENDER]: () => RecommendationsGenderIdentity,
  [FilterPane.JOIN]: () => RecommendationsJoin,
}

const FilterModal = (props: FilterModalProps): JSX.Element => {
  const { active, hasFilters, recipientCount } = props
  const [closing, setClosing] = useState(false)
  const [dirty, setDirty] = useState(false)

  const countLabel = recipientCount === 1 ? `${recipientCount} Member` : `${recipientCount} Members`

  const { COLORS } = useTheme()

  const onClose = () => {
    if (dirty) return setClosing(true)
    props.onClose()
  }

  return (
    <Modal onClose={onClose} open={active}>
      <Modal.Header>
        <Modal.Header.Center>Manage Filters</Modal.Header.Center>
        <Modal.Header.Right onClose={onClose}>
          <StyledCloseIcon color={COLORS.SUBTEXT} size={12} />
        </Modal.Header.Right>
      </Modal.Header>
      <RecipientFieldContextProvider>
        <RecipientPane
          initialPane={FilterPane.TOP}
          panes={PANES}
          recipientCount={recipientCount}
          style={{ maxHeight: '418px' }}
          topIcon="filter"
          topLabel="Filters"
        />
      </RecipientFieldContextProvider>
      <StyledFilterModalFooter>
        {hasFilters && <StyledFilterModalCount>{countLabel}</StyledFilterModalCount>}
        <Button onClick={onClose}>Done</Button>
      </StyledFilterModalFooter>
      {dirty && closing && (
        <Dialog title="Discard changes without applying filters?">
          <Dialog.Action onClick={() => setClosing(false)}>Cancel</Dialog.Action>
          <Dialog.Action
            onClick={() => {
              setClosing(false)
              setDirty(false)
              props.onClose()
            }}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            Remove
          </Dialog.Action>
        </Dialog>
      )}
    </Modal>
  )
}

export default FilterModal
