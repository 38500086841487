import {
  BUTTON_VARIANTS,
  Box,
  Button,
  HiddenIcon,
  SPACING,
  TextInput,
  VisibleIcon,
  Typography,
} from '@community_dev/pixels'
import noop from 'lodash/noop'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import { ValidationRequirement } from './ValidationRequirement'

import { Notif } from 'components/base'
import { MOBILE_BREAK } from 'constants/theme'
import notifs from 'utils/notifs'
import validatePassword from 'utils/validatePassword'

const StyledForm = styled.form`
  text-align: left;
  margin-top: ${SPACING[5]};
`

const StyledValidations = styled.div`
  margin-top: ${SPACING[5]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${MOBILE_BREAK}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledPassword = styled.div`
  position: relative;
`

const StyledShowIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  right: 0;
  top: 0;
`

const StyledSubmitButton = styled(Button)`
  width: 100%;
  max-width: 100%;
  margin-top: ${SPACING[5]};
  border: 0;
`

export type NotifArgs = {
  body?: string
  level?: 'success' | 'info' | 'warn' | 'danger'
}

type Validations = {
  length: boolean
  space: boolean
  letter: boolean
  number: boolean
  symbol: boolean
  valid: boolean
}

type PasswordFormProps = {
  className?: string
  onSubmit: (...args: any[]) => any
  onChange?: (...args: any[]) => any
  notif?: NotifArgs
  titleText?: string
  buttonText?: string
  labelText?: string
}

export const PasswordForm = ({
  className,
  onChange = noop,
  onSubmit = noop,
  notif = {},
  titleText = 'Please Create A Password',
  buttonText = 'Activate Account',
  labelText = 'Create Password',
}: PasswordFormProps): JSX.Element => {
  const [notification, setNotification] = useState<NotifArgs>(notif)
  const [showPassword, setShowPassword] = useState(false)
  const [validations, setValidations] = useState<Validations>({
    length: false,
    space: false,
    letter: false,
    number: false,
    symbol: false,
    valid: false,
  })
  const { valid } = validations

  useEffect(() => {
    setNotification(notif)
  }, [notif])

  const validate = (password = ''): void => {
    let notif = {}

    const validations: any = validatePassword(password)
    if (password.length && !validations.space) notif = notifs.passwordSpace
    setValidations(validations)
    setNotification(notif)

    onChange({
      password,
      validations,
      notif,
    })
  }

  const handleSubmit = ({ password }) => {
    if (validations.valid) {
      onSubmit({
        password,
        validations,
        notif,
      })
    }
  }

  const toggleVisibility = (e) => {
    e.preventDefault()

    setShowPassword((showPassword) => !showPassword)
  }

  return (
    <div className={className}>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <Box>
            <div>{notification.level && <Notif {...notification} />}</div>
            <Box.Content>
              <Typography variant="h1">{titleText}</Typography>
              <StyledForm onSubmit={handleSubmit}>
                <Field id="password" name="password" validate={validate}>
                  {({ input }) => (
                    <StyledPassword>
                      <TextInput label={labelText} type={showPassword ? 'text' : 'password'} {...input} />
                      <StyledShowIcon onClick={toggleVisibility}>
                        {showPassword ? <HiddenIcon /> : <VisibleIcon />}
                      </StyledShowIcon>
                    </StyledPassword>
                  )}
                </Field>
                <StyledValidations>
                  <ValidationRequirement isValid={!!validations.length} text="8+ characters" />
                  <ValidationRequirement isValid={!!validations.letter} text="Letter" />
                  <ValidationRequirement isValid={!!validations.number} text="Number" />
                  <ValidationRequirement isValid={!!validations.symbol} text="Symbol (!, @, #, $)" />
                </StyledValidations>
                <StyledSubmitButton disabled={!valid} type="submit" variant={BUTTON_VARIANTS.ACTION}>
                  {buttonText}
                </StyledSubmitButton>
              </StyledForm>
            </Box.Content>
          </Box>
        )}
      />
    </div>
  )
}
