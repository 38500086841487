import { SPACING } from '@community_dev/pixels'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DAY_KEY, TICK_NUM_NEW_MEMBERS } from '../constants'
import { TimeRange } from '../InsightsMasthead'
import { LearnHowToGrow } from '../LearnHowToGrow'
import { LoadingHeader } from '../LoadingHeader'

import { BarChart } from './BarChart'
import { NewMembersHeader } from './NewMembersHeader'
import { formatDateFns, tooltipFormatFns } from './utils'

import { getNewMembersOverTime } from 'api/insights'
import { MeasuredContainer } from 'components/MeasuredContainer'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { ErrorMessage } from 'screens/InsightsScreen/presentation/InsightsBox'

const StyledMeasuredContainer = styled(MeasuredContainer)`
  flex-direction: column;
`

const StyledNewMembersHeader = styled(NewMembersHeader)<{ $withMargin: boolean }>`
  margin-bottom: ${({ $withMargin }) => ($withMargin ? SPACING[4] : 0)};
`

const StyledErrorMessage = styled(ErrorMessage)`
  margin: ${SPACING[6]} 0;
`

type TotalNewMembersChartProps = {
  timeRange: TimeRange
  timezone: string
  isMocked: boolean
  activeSubscriptions: number
  locked: boolean
  onResizeHeaderHeight: (height: number) => void
}

const shouldShowResourceBox = (activeSubscriptions, sumOfNewMembers) => {
  if (activeSubscriptions < 25) return true
  if (sumOfNewMembers === 0) return true
  return false
}

const toIsMocked = (isMocked: boolean, locked: boolean): boolean => {
  if (isMocked) return true
  // we dont allow when locked because we will just get a 403 so we mock it
  if (locked) return true
  return false
}
export const TotalNewMembersChart = ({
  timeRange,
  timezone,
  isMocked,
  activeSubscriptions,
  onResizeHeaderHeight,
  locked,
}: TotalNewMembersChartProps): JSX.Element => {
  const clientId = useClientId()
  const { t } = useTranslation()

  const { data, isLoading, isError } = useQuery(
    [QUERY_CACHE.INSIGHTS.TOTAL_NEW_MEMBERS, { clientId, timeRange, timezone }],
    () => getNewMembersOverTime({ clientId, timeRange, timezone, mocked: toIsMocked(isMocked, locked) }),
  )

  if (isError) return <StyledErrorMessage msg={t('failedToLoadData')} />

  const sumOfNewMembers = data?.data.reduce((acc, curr) => acc + curr.count, 0)

  const timeType = timeRange === DAY_KEY ? 'hours' : 'days'
  return (
    <div>
      {isLoading ? (
        <LoadingHeader />
      ) : (
        <StyledMeasuredContainer
          onResize={({ height }) => {
            onResizeHeaderHeight(height)
          }}
        >
          <StyledNewMembersHeader
            $withMargin={!shouldShowResourceBox(activeSubscriptions, sumOfNewMembers)}
            locked={locked}
            numberOfNewMembers={sumOfNewMembers || 0}
            subHeader={t('insightMasthead.newMembersInsights.newMembersJoined', {
              number: data?.data.length,
              timeType,
            })}
          />
          {shouldShowResourceBox(activeSubscriptions, sumOfNewMembers) && <LearnHowToGrow />}
        </StyledMeasuredContainer>
      )}
      {activeSubscriptions > 0 && (
        <BarChart
          bottomNumTicks={TICK_NUM_NEW_MEMBERS[timeRange]}
          data={data?.data || []}
          formatBottomAxis={formatDateFns[timeRange]}
          formatTooltipValue={tooltipFormatFns[timeRange]}
          isLoading={isLoading}
          tooltipCountLabel={t('insightMasthead.newMembersInsights.Members')}
          tooltipDateLabel={
            timeRange === DAY_KEY
              ? t('insightMasthead.newMembersInsights.Time')
              : t('insightMasthead.newMembersInsights.Date')
          }
        />
      )}
    </div>
  )
}
