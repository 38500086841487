import { ArrowRightIcon, SPACING } from '@community_dev/pixels'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Flip } from 'components/Flip'
import { ROUTES } from 'constants/routes'
import { PasswordChange } from 'containers/PasswordChange'
import BoxFormLayout from 'layouts/BoxFormLayout'

const StyledLink = styled(Link)`
  margin-left: ${SPACING[4]};
`

export const PasswordChangeScreen = (): JSX.Element => {
  const { COLORS } = useTheme() || {}
  return (
    <BoxFormLayout>
      <StyledLink to={ROUTES.HOME}>
        <Flip>
          <ArrowRightIcon color={COLORS?.TEXT} size={20} />
        </Flip>
      </StyledLink>
      <PasswordChange />
    </BoxFormLayout>
  )
}
