import { SPACING, Typography } from '@community_dev/pixels'
import isEmpty from 'lodash/isEmpty'
import { useMemo } from 'react'
import { useHistory } from 'react-router'

import { MessageLimitRow } from './Billing/MessageLimitRow'

import { SubscriptionV2 } from 'api/billing'
import { ROUTES } from 'constants/routes'
import { useSubscription } from 'hooks/billing/useSubscription'
import { Setting, SettingsSection } from 'layouts/SettingsLayout'
import { getNextPaymentDate } from 'utils/payments'

function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount)
}

function getPlanDetails(subscriptionData?: SubscriptionV2) {
  const cardType = subscriptionData?.billingInfo?.cardType
  const expMonth = subscriptionData?.billingInfo?.expMonth
  const expYear = subscriptionData?.billingInfo?.expYear
  const lastFour = subscriptionData?.billingInfo?.lastFour
  const payment = subscriptionData?.billingInfo?.paymentMethod

  const planName = subscriptionData?.subscription.planName
  const currentPeriodEndsAt = subscriptionData?.subscription.currentPeriodEndsAt
  const nextBillingCycleSubtotal = subscriptionData?.subscription.nextBillingCycleSubtotal

  const planRenewalDate = currentPeriodEndsAt ? getNextPaymentDate(currentPeriodEndsAt) : ''
  // if there is no billing info it should be empty
  const paymentMethod = isEmpty(payment) ? '' : ` ${cardType} ending in ${lastFour}, exp: ${expMonth}/${expYear}` || ''
  const planSubtotal = nextBillingCycleSubtotal ? formatCurrency(nextBillingCycleSubtotal) : ''

  return { planName, planRenewalDate, paymentMethod, planSubtotal }
}

export function NonStandardRecurlySubscription(): JSX.Element {
  const history = useHistory()
  const label = 'Payment method'
  const { data } = useSubscription()

  const { planName, paymentMethod, planRenewalDate, planSubtotal } = useMemo(() => {
    return getPlanDetails(data)
  }, [data])

  const edit = () => {
    history.push(ROUTES.SETTINGS.BILLING_EDIT)
  }

  return (
    <>
      <SettingsSection>
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          Your Plan
        </Typography>
        <Setting className="Setting-billing" label={planName}>
          Plan Renews on {planRenewalDate} - Subtotal: {planSubtotal}
        </Setting>
        <Setting action="Edit" className="Setting-payment" label={label} onClickAction={edit}>
          {paymentMethod}
        </Setting>
      </SettingsSection>
      <MessageLimitRow />
    </>
  )
}
