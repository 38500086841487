import { Placeholder } from '@community_dev/filter-dsl/lib/subscription-data'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getRoot, $isParagraphNode, LexicalNode } from 'lexical'
import { useEffect, useMemo, useState } from 'react'

import { $isPlaceholderNode } from '../nodes/PlaceholderNode'

export const PLACEHOLDER_LIMIT = 12

export type UsePlaceholderNodesReturn = {
  hasExceededLimit: boolean
  hasPlaceholderNodes: boolean
  hasReachedLimit: boolean
  placeholderNodes: LexicalNode[]
  placeholders: Placeholder[]
}

/**
 * This hook registers an editor update listener which tracks any placeholder nodes in the editor and returns booleans representing
 * whether the limit (12) has been reached or exceeded. This hook will also update useSendMessage whenever placeholders are added or removed.
 * Since this hook registers an update listener to the editor, it should only be called once, otherwise multiple listeners will be added.
 */
export function usePlaceholderNodes(): UsePlaceholderNodesReturn {
  const [editor] = useLexicalComposerContext()
  const [placeholderNodes, setPlaceholderNodes] = useState<LexicalNode[]>([])
  const hasPlaceholderNodes = useMemo(() => placeholderNodes.length > 0, [placeholderNodes.length])
  const hasReachedLimit = useMemo(() => placeholderNodes.length >= PLACEHOLDER_LIMIT, [placeholderNodes.length])
  const hasExceededLimit = useMemo(() => placeholderNodes.length > PLACEHOLDER_LIMIT, [placeholderNodes.length])

  const placeholders = useMemo(() => {
    return placeholderNodes.map((node) => ({
      key: node.__id,
      name: node.__name,
      source: node.__source,
    }))
  }, [placeholderNodes])

  useEffect(() => {
    // Set placeholder nodes on initial render
    const editorState = editor.getEditorState()
    editorState.read(() => {
      const root = $getRoot()
      const children = root.getChildren()

      // There should always be a root paragraph node
      if ($isParagraphNode(children[0])) {
        const paragraphChildren = children[0].getChildren()
        setPlaceholderNodes(paragraphChildren.filter((node) => $isPlaceholderNode(node)))
      }
    })
  }, [editor])

  useEffect(() => {
    // Listen for any editor updates
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const root = $getRoot()
        const children = root.getChildren()

        // There should always be a root paragraph node
        if ($isParagraphNode(children[0])) {
          const paragraphChildren = children[0].getChildren()
          setPlaceholderNodes(paragraphChildren.filter((node) => $isPlaceholderNode(node)))
        }
      })
    })
  }, [editor])

  return {
    hasPlaceholderNodes,
    hasReachedLimit,
    hasExceededLimit,
    placeholderNodes,
    placeholders,
  }
}
