import { linkify, PhonePreviewBubble } from '@community_dev/pixels'
import { useQueries, UseQueryOptions } from '@tanstack/react-query'
import { useMemo } from 'react'

import { Media } from 'api/campaign'
import { getUrlMetadata } from 'api/urlMetadata'
import { composeInputToPhonePreview, generateShortId } from 'components/ComposeMessage/utils/composeInputToPhonePreview'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

function isPhonePreviewBubble(bubble: unknown): bubble is PhonePreviewBubble {
  return typeof bubble === 'object' && bubble !== null && ('link' in bubble || 'text' in bubble || 'media' in bubble)
}

type UsePhonePreviewBubblesOptions = {
  media?: Media | null
  text: string
  shouldShortenLinks: boolean
}

export function usePhonePreviewBubbles(options: UsePhonePreviewBubblesOptions): PhonePreviewBubble[] {
  const { media, text, shouldShortenLinks } = options
  const clientId = useClientId()

  const bubbles = useMemo(() => composeInputToPhonePreview(text, media), [text, media])

  const bubblesWithFullLinkMetadata = useQueries<UseQueryOptions<PhonePreviewBubble>[]>({
    queries: bubbles.map((bubble) => ({
      queryKey: [QUERY_CACHE.URL_METADATA, { clientId, bubble, shouldShortenLinks }],
      queryFn: async (): Promise<PhonePreviewBubble> => {
        if ('link' in bubble) {
          try {
            const linkMetadata = await getUrlMetadata({ url: bubble.link, clientId })

            return {
              shortened: shouldShortenLinks ? bubble.shortened : bubble.link,
              link: linkMetadata,
            }
          } catch (e) {
            return {
              shortened: shouldShortenLinks ? bubble.shortened : bubble.link,
              link: {
                contentType: null,
                image: null,
                title: null,
                url: bubble.link,
              },
            }
          }
        }

        if ('text' in bubble) {
          const parsed = linkify.match(bubble.text) || []
          const urlLookup = parsed.reduce((acc, match) => {
            if (shouldShortenLinks || match.raw.startsWith('blob:')) {
              acc[match.raw] = generateShortId(match.raw)
            }
            return acc
          }, {})

          return {
            text: bubble.text,
            urlLookup,
          }
        }

        return bubble
      },
    })),
  })

  const phonePreviewBubbles = useMemo<PhonePreviewBubble[]>(() => {
    const ret = bubblesWithFullLinkMetadata.map((query) => query.data).filter(isPhonePreviewBubble)

    return ret
  }, [bubblesWithFullLinkMetadata])

  return phonePreviewBubbles
}
