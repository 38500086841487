import React, { ComponentProps } from 'react'
import Moment from 'react-moment/src/index'

import { buildCalenderOpts } from 'utils/moment-config'

const calendarOpts = buildCalenderOpts('M/D/YY', {
  sameDay: 'h:mm A',
  lastDay: '[Yesterday]',
  lastWeek: 'dddd',
})

type ItemTimeProps = ComponentProps<typeof Moment> & {
  ts?: string
}

export function ItemTime({ ts, ...rest }: ItemTimeProps): JSX.Element {
  return (
    <Moment calendar={calendarOpts} data-testid="time" {...rest}>
      {ts}
    </Moment>
  )
}
