import { useRecurly } from '@recurly/react-recurly'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { RefObject, useCallback } from 'react'

import { putBillingInfo } from 'api/billing'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

type UpdateRecurlyPaymentProps = {
  formRef: RefObject<HTMLFormElement>
  onSuccess: () => void
  onError: (message: string) => void
}

type UpdateRecurlyPayment = {
  updatePayment: () => void
  isLoading
}

// Has to be used inside a Recurly Provider
export const useUpdateRecurlyPayment = ({
  formRef,
  onSuccess,
  onError,
}: UpdateRecurlyPaymentProps): UpdateRecurlyPayment => {
  const recurly = useRecurly()
  const queryClient = useQueryClient()

  const clientId = useClientId()

  const { mutate: submitBilling, isLoading } = useMutation(putBillingInfo, {
    onError: (error: any) => {
      const message = error?.errors?.[0]?.message || error?.errors?.[0]?.detail || 'Invalid payment.'
      const punctuation = /\.$/.test(message) ? '' : '.'
      onError(`${message}${punctuation} Please try again.`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_CACHE.BILLING.SUBSCRIPTION, { clientId }])
      onSuccess()
    },
  })

  const updatePayment = useCallback(() => {
    if (!formRef.current) return
    recurly.token(formRef.current, (err, recurlyToken) => {
      if (err) {
        onError(err?.message || 'Invalid payment. Please try again')
      } else {
        submitBilling({ clientId: clientId, body: { recurly_token: recurlyToken.id } })
      }
    })
  }, [recurly, onError, submitBilling, clientId, formRef])

  return { updatePayment, isLoading }
}
