import { WhatsAppIcon, AppleIcon, AppleIconCircled } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'

const COMMUNICATION_CHANNEL_ICON_MAP_AVATARS = {
  [CommunicationChannel.WHATS_APP]: WhatsAppIcon,
  [CommunicationChannel.AMB]: AppleIconCircled,
}

const COMMUNICATION_CHANNEL_ICON_MAP = {
  [CommunicationChannel.WHATS_APP]: WhatsAppIcon,
  [CommunicationChannel.AMB]: AppleIcon,
}

type CommunicationChannelIconProps = {
  communicationChannel?: CommunicationChannel
  isAvatar?: boolean
} & React.ComponentProps<typeof WhatsAppIcon>

const CommunicationChannelIcon = ({
  communicationChannel = CommunicationChannel.SMS,
  isAvatar = true,
  ...rest
}: CommunicationChannelIconProps): JSX.Element | null => {
  const Icon = isAvatar
    ? COMMUNICATION_CHANNEL_ICON_MAP_AVATARS[communicationChannel]
    : COMMUNICATION_CHANNEL_ICON_MAP[communicationChannel]
  if (Icon) return <Icon {...rest} />
  return null
}

export default CommunicationChannelIcon
