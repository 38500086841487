import { Placeholder } from '@community_dev/filter-dsl/lib/subscription-data'
import { Button, CheckBox, Layout, Popover, SPACING, Typography } from '@community_dev/pixels'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { StyledButton, StyledIcon, StyledLabel } from './CampaignFollowUp.style'
import IconClick from './svg/icon-click.svg?react'
import IconNoClick from './svg/icon-no-click.svg?react'

import { replacePlaceholdersWithNames } from 'components/ComposeEditor/util/replacePlaceholders'
import { parseUrl } from 'utils/link'

export type LinkFollowUpButtonProps = {
  hasClickIntent: boolean
  linkUrls: string[]
  placeholders: Placeholder[]
  disabled?: boolean
  onClick: (links: string[]) => void
  isSelected?: boolean
}

type Link = {
  label: string
  selected: boolean
  value: string
}

export const LinkFollowUpButton = ({
  hasClickIntent = true,
  linkUrls = [],
  placeholders = [],
  onClick,
  disabled = false,
  isSelected = false,
}: LinkFollowUpButtonProps): JSX.Element | null => {
  const { t } = useTranslation()
  const { COLORS } = useTheme()
  const toggleRef = useRef<HTMLDivElement>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const [links, setLinks] = useState<Link[]>(
    linkUrls
      .map((link) => replacePlaceholdersWithNames(link, placeholders))
      .map((link) => {
        const { hostname } = parseUrl(link) || {}

        return {
          label: decodeURIComponent(hostname || ''),
          selected: true,
          value: link,
        }
      }),
  )

  const selectedLinks = useMemo(() => links.filter((link) => link.selected), [links])

  if (linkUrls.length === 0) return null

  return (
    <>
      <StyledButton
        $isSelected={isSelected}
        data-testid="link-follow-up-button"
        disabled={disabled}
        onClick={() => {
          if (linkUrls.length === 1) {
            onClick(linkUrls)
            return
          }
          setIsPopoverOpen(true)
        }}
        ref={toggleRef}
      >
        <StyledIcon>{hasClickIntent ? <IconClick /> : <IconNoClick />}</StyledIcon>
        {hasClickIntent ? t('campaignFollowUp.click') : t('campaignFollowUp.noClick')}
      </StyledButton>
      <Popover
        align="left"
        isOpen={isPopoverOpen}
        offsetTop={12}
        onClose={() => setIsPopoverOpen(false)}
        showBorder={true}
        targetRef={toggleRef}
        width="auto"
      >
        <Layout data-testid="link-selection" minWidth="250px" padding={SPACING[4]}>
          {links.map((link) => (
            <StyledLabel htmlFor={link.label}>
              <CheckBox
                name={link.label}
                onChange={(value) => {
                  setLinks((links) => links.map((l) => (l.label === link.label ? { ...link, selected: value } : l)))
                }}
                selected={link.selected}
              />
              <Typography component="span" fontWeight="600" marginLeft={SPACING[2]} variant="body1">
                {link.label}
              </Typography>
            </StyledLabel>
          ))}
          <Layout borderTop={`1px solid ${COLORS.BORDERS}`} margin={`${SPACING[4]} 0`} />
          <Layout display="flex" justifyContent="flex-end">
            <Button
              disabled={selectedLinks.length === 0}
              onClick={() => {
                setIsPopoverOpen(false)
                onClick(selectedLinks.map((link) => link.value))
              }}
            >
              {isSelected ? t('campaignFollowUp.clearFilter') : t('campaignFollowUp.selectFilter')}
            </Button>
          </Layout>
        </Layout>
      </Popover>
    </>
  )
}
