// when we mock the endpoint we also mock some total members
export const MOCKED_ACTIVE_SUBSCRIPTIONS = 50000

export const WEEK_KEY = 'week'
export const MONTH_KEY = 'month'
export const DAY_KEY = 'day'

export const TIME_RANGE = {
  WEEK_KEY,
  MONTH_KEY,
  DAY_KEY,
} as const

export const TABS = {
  [DAY_KEY]: 'Day',
  [WEEK_KEY]: 'Week',
  [MONTH_KEY]: 'Month',
}

export const TICK_NUM_MEMBER_GROWTH = {
  [DAY_KEY]: 24,
  [WEEK_KEY]: 6,
  [MONTH_KEY]: 4,
}

export const TICK_NUM_NEW_MEMBERS = {
  [DAY_KEY]: 24,
  [WEEK_KEY]: 7,
  [MONTH_KEY]: 5,
}

export const TIME_LABEL = {
  [DAY_KEY]: '24 hours',
  [WEEK_KEY]: '7 days',
  [MONTH_KEY]: '30 days',
}

export const TOTAL_MEMBER_GROWTH_KEY = 'totalMemberGrowth'
export const NEW_MEMBER_GROWTH_KEY = 'newMemberGrowth'

export const CHART_OPTIONS = {
  TOTAL_MEMBER_GROWTH_KEY,
  NEW_MEMBER_GROWTH_KEY,
} as const

export const CHART_MIXPANEL_TYPE = {
  TOTAL_MEMBER_GROWTH_KEY: 'member-growth',
  NEW_MEMBER_GROWTH_KEY: 'total-members',
}
