import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { Manifest, WorkflowType } from '@community_dev/workflow-manifest'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type LastManifest<M = Manifest> = {
  body: M
  created_at: string
  id: string
  version: string
}

export type Workflow<M = Manifest> = {
  created_at: string
  id: string
  name: string
  type: WorkflowType
  updated_at: string
  last_manifest?: LastManifest<M>
  active_end_at: string | null
  active_start_at: string | null
}

type ListWorkflowsArgs = {
  clientId: string
  type?: string
  getDetails?: boolean
}

export async function listWorkflows({ clientId, type, getDetails }: ListWorkflowsArgs): Promise<Workflow[]> {
  let url = `${route(ENDPOINTS.WORKFLOW_CONFIG.WORKFLOWS, { clientId })}`

  if (type) {
    url += `?type=${type}`
  }

  const manifestsRequest = request<Workflow[]>(url, {
    camelCase: false,
    method: HTTP.GET,
    headers: { accept: 'application/json' },
  })

  if (getDetails) {
    const manifests = await manifestsRequest
    return Promise.all(
      manifests.map((manifest) => {
        return getWorkflow({ clientId, workflowId: manifest.id })
      }),
    )
  }

  return manifestsRequest
}

type GetWorkflowArgs = {
  clientId: string
  workflowId: string
}

export async function getWorkflow({ clientId, workflowId }: GetWorkflowArgs): Promise<Workflow> {
  return request<Workflow<Manifest>>(route(ENDPOINTS.WORKFLOW_CONFIG.WORKFLOW, { clientId, id: workflowId }), {
    camelCase: false,
    method: HTTP.GET,
    headers: { accept: 'application/json' },
  }).then((workflow) => {
    return workflow as Workflow<Manifest>
  })
}

type CreateWorkflowArgs = {
  clientId: string
  name?: string
  type?: string
}

export async function createWorkflow({ clientId, name, type }: CreateWorkflowArgs): Promise<Workflow> {
  return request(route(ENDPOINTS.WORKFLOW_CONFIG.WORKFLOWS, { clientId }), {
    camelCase: false,
    method: HTTP.POST,
    headers: { accept: 'application/json' },
    body: {
      type,
      name,
    },
  })
}

export interface CreateManifestArgs {
  clientId: string
  workflowId: string
  manifest: Manifest
}

export type CreateManifestReturn = {
  version: string
  manifest_id: string
}

export async function createManifest({
  clientId,
  workflowId,
  manifest,
}: CreateManifestArgs): Promise<CreateManifestReturn> {
  return request(route(ENDPOINTS.WORKFLOW_CONFIG.MANIFESTS, { clientId, id: workflowId }), {
    camelCase: false,
    method: HTTP.POST,
    headers: { accept: 'application/json' },
    // we manually serialize here to avoid convertKeysToSnakeCase garbling the keys in definitions.actions
    serialize: (manifest) => JSON.stringify(manifest),
    body: manifest,
  })
}

export interface PatchWorkflowArgs {
  clientId: string
  workflowId: string
  body: Partial<Workflow>
}

export async function patchWorkflow({ clientId, workflowId, body }: PatchWorkflowArgs): Promise<unknown> {
  return request(route(ENDPOINTS.WORKFLOW_CONFIG.WORKFLOW, { clientId, id: workflowId }), {
    camelCase: false,
    method: HTTP.PATCH,
    headers: { accept: 'application/json' },
    responseMethod: 'text',
    body,
  })
}

export interface ResetWorkflowArgs {
  clientId: string
  workflowId: string
}

export async function resetWorkflow({ clientId, workflowId }: ResetWorkflowArgs): Promise<unknown> {
  return request(route(ENDPOINTS.WORKFLOW_CONFIG.WORKFLOW, { clientId, id: workflowId }), {
    camelCase: false,
    method: HTTP.PATCH,
    headers: { accept: 'application/json' },
    body: {
      selectedManifestId: '00000000-0000-0000-0000-000000000000',
    },
  })
}

export interface DeleteWorkflowArgs {
  clientId: string
  workflowId: string
}

export async function deleteWorkflow({ clientId, workflowId }: DeleteWorkflowArgs): Promise<unknown> {
  return request(route(ENDPOINTS.WORKFLOW_CONFIG.WORKFLOW, { clientId, id: workflowId }), {
    camelCase: false,
    method: HTTP.DELETE,
    headers: { accept: 'application/json' },
    responseMethod: 'text',
  })
}

export interface GetWorkflowAnalyticsArgs {
  clientId: string
  workflowId: string
}

export type WorkflowMetics = CamelCasedPropertiesDeep<Api.V1.Metrics.Workflow>

export function getWorkflowAnalytics({
  clientId,
  workflowId,
}: GetWorkflowAnalyticsArgs): Promise<{ data: WorkflowMetics }> {
  return request(route(ENDPOINTS.WORKFLOW_CONFIG.METRICS, { clientId, workflowId }), {
    method: HTTP.GET,
  })
}
