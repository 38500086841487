import {
  FieldTypes,
  isCollectionFilter,
  isRelationshipFilter,
  isSelectorFilter,
  MemberDataFilter,
  RelationshipFilter,
  RelationshipOperators,
  SelectorFilter,
  SelectorOperators,
  FilterOperator,
  FieldDefinition,
  BaseFilter,
} from '@community_dev/filter-dsl/lib/subscription-data'
import camelCase from 'lodash/camelCase'

import { dateOperators } from './Date'

import dayjs from 'utils/dayjs'

export type TranslationOperator =
  | 'greaterThan'
  | 'lessThan'
  | 'greaterThanOrEquals'
  | 'lessThanOrEquals'
  | 'equals'
  | 'and'
  | 'or'

export type TypeOperators = Partial<Record<SelectorOperators | RelationshipOperators, TranslationOperator>>

type GenerateOptionsArgs = {
  t: (arg: string) => string
  typeOperators: TypeOperators
  type: FieldTypes
}

type GenerateOptionsReturn = {
  value: SelectorOperators | RelationshipOperators
  label: string
}[]

export function generateOptions({ t, typeOperators, type }: GenerateOptionsArgs): GenerateOptionsReturn {
  return Object.entries({ ...SelectorOperators, ...RelationshipOperators })
    .map((option) => {
      return option
    })
    .filter(([, operator]) => {
      return !!typeOperators[operator]
    })
    .map(([, operator]) => {
      return {
        value: operator,
        label: getOperatorTranslation({ t, operator, type }),
      }
    })
}

type GetOperatorTranslation = {
  t: (arg: string) => string
  operator: SelectorOperators | RelationshipOperators
  type: FieldTypes
}

export function getOperatorTranslation({ t, operator, type }: GetOperatorTranslation): string {
  if (type === FieldTypes.DATETIME) {
    return t(`dateOperators.${camelCase(operator) as TranslationOperator}`)
  }

  return t(`operators.${camelCase(operator) as TranslationOperator}`)
}

export function getCollectionOperands(filter: MemberDataFilter): SelectorFilter[] {
  if (isCollectionFilter(filter)) {
    if (filter.operator === RelationshipOperators.NOT) {
      return filter.operands[0].operands
    }

    return filter.operands
  }

  if (isSelectorFilter(filter)) return [filter]

  throw new Error('Invalid collection format')
}

export const getOperandBetweenValue = (idx: number, relationshipFilter: RelationshipFilter): string => {
  const filter = relationshipFilter.operands?.[idx]

  if (isSelectorFilter(filter)) {
    return getOperandValue(filter)
  }

  return ''
}

export const getOperandValue = (filter: SelectorFilter): string => {
  const value = filter?.operand?.value

  if (typeof value === 'string') {
    switch (filter?.operand?.type) {
      case FieldTypes.DATETIME:
        return value ? dayjs.utc(value).format('YYYY-MM-DD') : ''
      default:
        return value
    }
  }

  return ''
}

type HasChangedBetweenArgs = {
  nextOperator: FilterOperator
  previousValue: MemberDataFilter | null
}

export const hasChangedToBetween = ({ nextOperator, previousValue }: HasChangedBetweenArgs): boolean => {
  return nextOperator === dateOperators.and && previousValue?.operator !== dateOperators.and
}

export const hasChangedFromBetween = ({ nextOperator, previousValue }: HasChangedBetweenArgs): boolean => {
  return nextOperator !== dateOperators.and && previousValue?.operator === dateOperators.and
}

type ConvertToBetweenValue = {
  field: FieldDefinition
  previousValue: MemberDataFilter | null
}

const fieldDefinitionToFilterOperand = (field: FieldDefinition): Partial<BaseFilter['operand']> =>
  ({
    field_key: field.key,
    field_label: field.name,
    source: field.source,
    type: field.value_type,
  } as Partial<BaseFilter['operand']>)

export const convertToBetweenValue = ({ field, previousValue }: ConvertToBetweenValue): MemberDataFilter => {
  if ((previousValue && isSelectorFilter(previousValue)) || !previousValue) {
    return {
      operator: RelationshipOperators.AND,
      operands: [
        {
          operator: SelectorOperators.GREATER_THAN_OR_EQUALS,
          operand: {
            value: '',
            ...(previousValue?.operand || fieldDefinitionToFilterOperand(field)),
          },
        },
        {
          operator: SelectorOperators.LESS_THAN_OR_EQUALS,
          operand: {
            ...(previousValue?.operand || fieldDefinitionToFilterOperand(field)),
            value: '',
          },
        },
      ],
    } as MemberDataFilter
  }

  return previousValue
}

type ConvertFromBetweenValue = {
  field: FieldDefinition
  previousValue: MemberDataFilter | null
  operator: SelectorOperators
}

export const convertFromBetweenValue = ({
  field,
  previousValue,
  operator,
}: ConvertFromBetweenValue): MemberDataFilter => {
  if ((previousValue && isRelationshipFilter(previousValue)) || !previousValue) {
    const firstFilter = previousValue?.operands?.[0] || null
    const operand =
      firstFilter && isSelectorFilter(firstFilter) ? firstFilter.operand : fieldDefinitionToFilterOperand(field)

    return {
      operator,
      operand: {
        ...operand,
      },
    } as MemberDataFilter
  }

  return previousValue
}

export const validateDate = (value: MemberDataFilter): string | void => {
  if (!isSelectorFilter(value) && !isRelationshipFilter(value)) {
    return 'Date is invalid'
  }

  if (isSelectorFilter(value) && !value?.operand?.value) {
    return 'Date is invalid'
  }

  if (isRelationshipFilter(value)) {
    if (!value.operands[0] || !value.operands[1]) {
      return 'Date is invalid'
    }

    if (isSelectorFilter(value.operands[0])) {
      if (!value.operands[0]?.operand?.value) {
        return 'Date is invalid'
      }
    }

    if (isSelectorFilter(value.operands[1])) {
      if (!value.operands[1]?.operand?.value) {
        return 'Date is invalid'
      }
    }
  }
}
