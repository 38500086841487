import { CampaignStatus } from '@community_dev/types/lib/api/v2/Campaign'
import { useCallback, useMemo } from 'react'
import useLocalStorageState from 'use-local-storage-state'

import { toScheduledCampaignLabel } from '../List/ScheduledList'

import holidays from './holidays.json'
import { useCurrentMonthDateRange } from './useCurrentMonthDateRange'
import { useHasAiIdeasEvents } from './useHasAiIdeasEvents'

import { Campaign, PaginatedCampaigns } from 'api/campaign'
import { ScheduledCampaign, ScheduledCampaignResponse } from 'api/scheduled'
import { useCurrentMonthCampaignsQuery } from 'hooks/useCampaignsQuery'
import { useCommunities } from 'hooks/useCommunities'
import { useScheduledRecurringCampaignsQuery } from 'hooks/useSchedueldRecuringCampaignsQuery'
import { useCurrentMonthScheduledCampaignsQuery } from 'hooks/useScheduledCampaignsQuery'
import { ScheduledLocalStorageKeys } from 'screens/Scheduled/constants'
import dayjs from 'utils/dayjs'

export type CalendarMonthEvent = {
  allDay?: boolean
  end: Date
  scheduledCampaign?: ScheduledCampaign
  sentCampaign?: Campaign
  recurringCampaign?: ScheduledCampaign
  submittedForApprovalCampaign?: ScheduledCampaign
  start: Date
  title: string
}

const toScheduledMessagesEvents = (
  campaigns: ScheduledCampaignResponse,
  toCampaignTitle: (ScheduledCampaign) => string,
): CalendarMonthEvent[] => {
  return (
    campaigns.data
      // Filter out recurring campaigns
      // They have their own section in the calendar
      .filter((campaign) => !campaign.recurrence)
      .map((campaign) => ({
        title: campaign.name || toCampaignTitle(campaign),
        start: new Date(campaign.scheduledAt),
        end: new Date(campaign.scheduledAt),
        scheduledCampaign: campaign,
      }))
  )
}

const toScheduledMessagesSubmittedForApprovalEvents = (
  campaigns: ScheduledCampaignResponse,
  toCampaignTitle: (ScheduledCampaign) => string,
): CalendarMonthEvent[] => {
  return campaigns.data
    .filter((campaign) => campaign.status === CampaignStatus.SUBMITTED_FOR_APPROVAL)
    .map((campaign) => ({
      title: campaign.name || toCampaignTitle(campaign),
      start: new Date(campaign.scheduledAt),
      end: new Date(campaign.scheduledAt),
      submittedForApprovalCampaign: campaign,
    }))
}

const toSentMessagesEvents = (
  campaigns: PaginatedCampaigns,
  toCampaignTitle: (campaign) => string,
): CalendarMonthEvent[] => {
  return campaigns.data.map((campaign) => ({
    title: campaign.name || toCampaignTitle(campaign),
    start: new Date(campaign.scheduledAt || campaign.createdAt),
    end: new Date(campaign.scheduledAt || campaign.createdAt),
    sentCampaign: campaign,
  }))
}

type Holiday = {
  title: string
  date: string // YYYY-MM-DD
}

const toAiIdeaEvents = (holidays: Holiday[]): CalendarMonthEvent[] => {
  const today = dayjs()
  return holidays
    .map((holiday) => {
      const holidayDate = dayjs(holiday.date)
      if (!holidayDate.isBefore(today)) {
        return {
          allDay: true,
          end: dayjs(holiday.date).toDate(),
          start: dayjs(holiday.date).toDate(),
          title: holiday.title,
        }
      }
      return false
    })
    .filter((x) => x) as CalendarMonthEvent[]
}

const extrapolateRecurringEvents = (campaign: ScheduledCampaign, endDate: Date): ScheduledCampaign[] => {
  const events: ScheduledCampaign[] = []
  if (campaign.recurrence) {
    const end = dayjs(endDate)
    let current = dayjs(campaign.scheduledAt)
    while (current.isBefore(end)) {
      events.push({ ...campaign, scheduledAt: current.toISOString() })
      current = current.add(1, campaign.recurrence.frequency.includes('WEEKLY') ? 'week' : 'day')
    }
    return events
  }
  return [campaign]
}
const toRecurringEvents = (
  campaigns: ScheduledCampaignResponse,
  toCampaignTitle: (campaign) => string,
  end: Date,
): CalendarMonthEvent[] => {
  const campaignsExtrapolated = campaigns.data?.reduce((campaigns, campaign) => {
    const extrapolatedCampaigns = extrapolateRecurringEvents(campaign, end)
    return [...campaigns, ...extrapolatedCampaigns]
  }, [] as ScheduledCampaign[])

  return campaignsExtrapolated.map((campaign) => ({
    title: campaign.name || toCampaignTitle(campaign),
    start: new Date(campaign.scheduledAt),
    end: new Date(campaign.scheduledAt),
    recurringCampaign: campaign,
  }))
}

export const useCalendarMonthEvents = (): CalendarMonthEvent[] => {
  const { end } = useCurrentMonthDateRange()
  const { isAiIdeaSelected } = useHasAiIdeasEvents()
  const [isScheduledMessagesSelected] = useLocalStorageState<boolean>(ScheduledLocalStorageKeys.SCHEDULED_MESSAGES, {
    defaultValue: true,
  })
  const [isSentMessagesSelected] = useLocalStorageState<boolean>(ScheduledLocalStorageKeys.SENT_MESSAGES, {
    defaultValue: false,
  })
  const [isRecurringMessagesSelected] = useLocalStorageState<boolean>(ScheduledLocalStorageKeys.RECURRING_MESSAGES, {
    defaultValue: true,
  })

  const [isSubmittedForApprovalMessagesSelected] = useLocalStorageState<boolean>(
    ScheduledLocalStorageKeys.SUBMITTED_FOR_APPROVAL_MESSAGES,
    { defaultValue: true },
  )

  const { data: communities } = useCommunities()

  const toCampaignTitle = useCallback(
    (campaign: ScheduledCampaign) => {
      return toScheduledCampaignLabel(campaign, communities)
    },
    [communities],
  )

  const { data: scheduledCampaigns = [] } = useCurrentMonthScheduledCampaignsQuery({
    statuses: [CampaignStatus.SCHEDULED, CampaignStatus.MEDIA_PROCESSING, CampaignStatus.TEMPLATE_PENDING],
    options: {
      enabled: isScheduledMessagesSelected,
      select: (campaigns) => (isScheduledMessagesSelected ? toScheduledMessagesEvents(campaigns, toCampaignTitle) : []),
    },
  })

  const { data: sentCampaigns = [] } = useCurrentMonthCampaignsQuery({
    enabled: isSentMessagesSelected,
    select: (campaigns) => (isSentMessagesSelected ? toSentMessagesEvents(campaigns, toCampaignTitle) : []),
  })

  const aiIdeaEvents = useMemo(() => (isAiIdeaSelected ? toAiIdeaEvents(holidays) : []), [isAiIdeaSelected])

  const { data: recurringEvents = [] } = useScheduledRecurringCampaignsQuery({
    enabled: isRecurringMessagesSelected,
    select: (campaigns) => (isRecurringMessagesSelected ? toRecurringEvents(campaigns, toCampaignTitle, end) : []),
  })

  const { data: submittedForApprovalCampaigns = [] } = useCurrentMonthScheduledCampaignsQuery({
    statuses: [CampaignStatus.SUBMITTED_FOR_APPROVAL],
    options: {
      enabled: isSubmittedForApprovalMessagesSelected,
      select: (campaigns) =>
        isSubmittedForApprovalMessagesSelected
          ? toScheduledMessagesSubmittedForApprovalEvents(campaigns, toCampaignTitle)
          : [],
    },
  })

  return useMemo(() => {
    return [
      ...scheduledCampaigns,
      ...sentCampaigns,
      ...aiIdeaEvents,
      ...recurringEvents,
      ...submittedForApprovalCampaigns,
    ]
  }, [aiIdeaEvents, scheduledCampaigns, sentCampaigns, recurringEvents, submittedForApprovalCampaigns])
}
