import {
  Layout,
  Modal,
  ModalProps,
  Typography,
  SuggestionCard,
  Button,
  BUTTON_VARIANTS,
  Dropdown,
  ComposeMessage as ComposeMessageLottieAnimation,
  required,
  composeValidators,
  maxLength,
  InfoIcon,
  Tooltip,
  InvisibleCharacters,
  ChatBubbleDirectionValues,
  ChatBubble,
} from '@community_dev/pixels'
import { TextInput } from '@community_dev/pixels'
import { TextInputVariant } from '@community_dev/pixels/dist/js/components/TextInput/TextInput'
import IconConvert from '@community_dev/pixels/dist/svg/illustrations/convert.svg?react'
import IconHeartBubble from '@community_dev/pixels/dist/svg/illustrations/heart-bubble.svg?react'
import IconPieChart from '@community_dev/pixels/dist/svg/illustrations/pie-chart.svg?react'
import { Api } from '@community_dev/types'
import { ConversionGoalType } from '@community_dev/types/lib/api/v1/AiInterface'
import { useIsMutating } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { Field, Form, useForm, useFormState } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { AI_INTERFACE } from '../AiMessageGeneratorButton/AiMessageGeneratorButton'

import {
  SelectionTitle,
  StyledCloseButton,
  StyledContentLeft,
  StyledContentRight,
  StyledContentWrapper,
  StyledDivider,
  StyledGenerateButton,
  StyledToneSuggestButton,
} from './AiMessageGeneratorModal.style'
import { AiMessageGeneratorHeader } from './components/AiMessageGeneratorModalHeader'
import { useSubmitFullAiMessageGenerator } from './hooks/useSubmitFullAiMessageGenerator'
import { useSuccessMetric } from './hooks/useSuccessMetric'
import IconClose from './svg/close.svg?react'
import IconShuffle from './svg/shuffle.svg?react'
import IconWand from './svg/wand.svg?react'

import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { MiniLinkPreview } from 'components/links'
import {
  AnalyticsEventSuggestMode,
  AnalyticsEventSuggested,
  useAiMessageGenerator,
} from 'contexts/AIMessageGeneratorProvider'
import useBreakpoints from 'hooks/useBreakpoints'
import useMediaQuery from 'hooks/useMediaQuery'
import Sentry from 'integrations/Sentry'
import { isError } from 'screens/TCRFlow/utils'
import analytics from 'utils/analytics'
import { parseUrl } from 'utils/link'

export type AiMessageGeneratorModalProps = Omit<ModalProps, 'children'>

export function validateUrl(url: string): string | undefined {
  if (parseUrl(url)) {
    return undefined
  }

  return 'is invalid'
}

export function useDynamicModalSize(): Pick<
  React.CSSProperties,
  'margin' | 'minHeight' | 'maxHeight' | 'borderRadius' | 'maxWidth'
> {
  const break1 = useMediaQuery(`(min-height: 900px)`)
  const break2 = useMediaQuery(`(min-height: 1200px)`)
  const break3 = useMediaQuery(`(min-height: 1500px)`)

  return useMemo(() => {
    if (break3) {
      return {
        margin: '128px 16px 128px 16px',
        minHeight: 'calc(100vh - 256px)',
        maxHeight: 'calc(100vh - 256px)',
        borderRadius: '40px',
        maxWidth: '1440px',
      }
    }

    if (break2) {
      return {
        margin: '64px 16px 64px 16px',
        minHeight: 'calc(100vh - 128px)',
        maxHeight: 'calc(100vh - 128px)',
        borderRadius: '40px',
        maxWidth: '1440px',
      }
    }

    if (break1) {
      return {
        margin: '16px',
        minHeight: 'calc(100vh - 32px)',
        maxHeight: 'calc(100vh - 32px)',
        borderRadius: '40px',
        maxWidth: '1440px',
      }
    }

    return {
      margin: '0px',
      minHeight: '100vh',
      maxHeight: '100vh',
      borderRadius: '0px',
      maxWidth: '100vw',
    }
  }, [break1, break2, break3])
}

export function AiMessageGeneratorModal(props: AiMessageGeneratorModalProps): JSX.Element {
  const theme = useTheme() || {}
  const isLoadingSuggestions = useIsMutating([AI_INTERFACE])
  const { goal, suggestions } = useAiMessageGenerator()
  const { t } = useTranslation()

  const { margin, minHeight, maxHeight, maxWidth, borderRadius } = useDynamicModalSize()

  return (
    <Modal
      {...props}
      borderRadius={borderRadius}
      margin={margin}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      minHeight={minHeight}
      style={{ overflow: 'hidden' }}
    >
      <StyledContentWrapper>
        <StyledContentLeft>
          <AiMessageGeneratorHeader />

          <StyledDivider />

          {typeof goal === 'undefined' && <NoGoalSelectedScreen />}

          {goal?.type === ConversionGoalType.Conversion && <ConversionGoalSelectedScreen />}

          {goal?.type === ConversionGoalType.BrandEngagement && <BrandEngagementGoalSelectedScreen />}

          {goal?.type === ConversionGoalType.FirstPartyData && <FirstPartyDataGoalSelectedScreen />}
        </StyledContentLeft>
        <StyledContentRight>
          {isLoadingSuggestions ? (
            <Layout
              alignItems="center"
              display="flex"
              height="100%"
              justifyContent="center"
              textAlign="center"
              width="100%"
            >
              <ComposeMessageLottieAnimation isPlaying={true} />
            </Layout>
          ) : suggestions ? (
            <SuggestionsList />
          ) : (
            <Layout
              alignItems="center"
              display="flex"
              height="100%"
              justifyContent="center"
              textAlign="center"
              width="100%"
            >
              <Typography color={theme.COLORS.SUBTEXT} lineHeight="2em">
                <Trans i18nKey="aiMessageGenerator.needHelp">
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <a
                    href="https://help.community.com/hc/en-us/articles/17764824605587"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit' }}
                    target="_blank"
                  />
                </Trans>
              </Typography>
            </Layout>
          )}
        </StyledContentRight>
        <StyledCloseButton aria-label={t('close')} onClick={props.onClose}>
          <IconClose />
        </StyledCloseButton>
      </StyledContentWrapper>
    </Modal>
  )
}

export function SuggestionsList(): JSX.Element {
  const { setComposeMessage, setComposeModalOpen } = useCompose()
  const {
    suggestions,
    link,
    setAiMessageGeneratorPopoverOpen,
    setInsertedSuggestedMessage,
    setAnalyticsEventSuggested,
    setAnalyticsEventSuggestMode,
  } = useAiMessageGenerator()
  const theme = useTheme() || {}
  const { t } = useTranslation()

  const linkIsValid = useMemo(() => parseUrl(link || ''), [link])

  const showLink = link && linkIsValid

  return (
    <Layout display="flex" flexDirection="column" maxWidth="400px">
      <SelectionTitle>{t('aiMessageGenerator.suggestions')}</SelectionTitle>

      <Layout flex="1" overflowY="auto">
        {suggestions?.map((suggestion, i) => {
          return (
            <Layout
              backgroundColor={theme?.COLORS?.APP_BACKGROUND_LEVEL_3}
              border={`1px solid ${theme?.COLORS?.BORDERS}`}
              borderRadius="4px"
              key={i}
              marginBottom="16px"
              padding="25px 20px 8px 20px"
            >
              {showLink && (
                <ChatBubble
                  color={theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_SENT}
                  direction={ChatBubbleDirectionValues.RIGHT}
                  tail={!!showLink}
                >
                  <MiniLinkPreview title="" url={link} />
                </ChatBubble>
              )}

              <ChatBubble
                color={theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_SENT}
                direction={ChatBubbleDirectionValues.RIGHT}
                tail={!showLink}
              >
                <InvisibleCharacters>{suggestion.text}</InvisibleCharacters>
              </ChatBubble>

              <Layout display="flex" justifyContent="flex-end" marginTop="8px">
                <Button
                  onClick={() => {
                    let message = suggestion.text

                    if (link) {
                      message = link + '\n' + message
                    }

                    setComposeModalOpen(true)
                    setAiMessageGeneratorPopoverOpen(false)

                    setInsertedSuggestedMessage(message)
                    setAnalyticsEventSuggested(AnalyticsEventSuggested.FULL)
                    setAnalyticsEventSuggestMode(AnalyticsEventSuggestMode.FULL)

                    // TODO - this is super hacky, we need be able to set the initial lexical editor text before compose is opened
                    // the editor does not exist in the DOM until `composeModalOpen` is true, and if this is called before that
                    // there is no effect.
                    //
                    // order matters here, the message set needs to come after the modal is opened
                    //
                    setTimeout(() => setComposeMessage(message), 0)

                    analytics.track(analytics.events.GPT.GeneratedCampaignSelected({ text: suggestion.text }))
                  }}
                  variant={BUTTON_VARIANTS.OUTLINE}
                >
                  {t('aiMessageGenerator.selectAndInsertDraft')}
                </Button>
              </Layout>
            </Layout>
          )
        })}
      </Layout>
    </Layout>
  )
}

export function NoGoalSelectedScreen(): JSX.Element {
  const { setGoal } = useAiMessageGenerator()
  const { t } = useTranslation()

  return (
    <Layout>
      <SelectionTitle>{t('aiMessageGenerator.goals.selectionTitle')}</SelectionTitle>

      <Layout>
        <SuggestionCard
          aria-label={t('aiMessageGenerator.goals.conversion.selectButtonLabel')}
          icon={<IconConvert />}
          onClick={() => {
            setGoal({ type: ConversionGoalType.Conversion })

            analytics.track(
              analytics.events.GPT.GoalSelected({
                goal: ConversionGoalType.Conversion,
              }),
            )
          }}
          style={{ margin: '12px 0', borderRadius: 8, maxWidth: 600 }}
          subtitle={t('aiMessageGenerator.goals.conversion.subtitle')}
          title={t('aiMessageGenerator.goals.conversion.title')}
        />

        <SuggestionCard
          aria-label={t('aiMessageGenerator.goals.firstPartyData.selectButtonLabel')}
          icon={<IconPieChart />}
          onClick={() => {
            setGoal({ type: ConversionGoalType.FirstPartyData })

            analytics.track(
              analytics.events.GPT.GoalSelected({
                goal: ConversionGoalType.FirstPartyData,
              }),
            )
          }}
          style={{ margin: '12px 0', borderRadius: 8, maxWidth: 600 }}
          subtitle={t('aiMessageGenerator.goals.firstPartyData.subtitle')}
          title={t('aiMessageGenerator.goals.firstPartyData.title')}
        />

        <SuggestionCard
          aria-label={t('aiMessageGenerator.goals.brandEngagement.selectButtonLabel')}
          icon={<IconHeartBubble />}
          onClick={() => {
            setGoal({ type: ConversionGoalType.BrandEngagement })

            analytics.track(
              analytics.events.GPT.GoalSelected({
                goal: ConversionGoalType.BrandEngagement,
              }),
            )
          }}
          style={{ margin: '12px 0', borderRadius: 8, maxWidth: 600 }}
          subtitle={t('aiMessageGenerator.goals.brandEngagement.subtitle')}
          title={t('aiMessageGenerator.goals.brandEngagement.title')}
        />
      </Layout>
    </Layout>
  )
}

export function ConversionGoalSelectedScreen(): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme() || {}
  const { reset, goal, setGoal, link, setLink, tone, keywords, additionalDetails } = useAiMessageGenerator()

  const { successMetricValue, successMetricOptions } = useSuccessMetric()

  const submitFullAiMessageGenerator = useSubmitFullAiMessageGenerator()

  return (
    <Form
      initialValues={{
        successMetric: successMetricValue,
        link: link,
        tone: tone,
        keywords: keywords,
        additionalDetails: additionalDetails,
        description: goal?.description,
      }}
      onSubmit={submitFullAiMessageGenerator}
    >
      {() => (
        <Layout>
          <SelectionTitle>{t('aiMessageGenerator.goals.selectionTitle')}</SelectionTitle>

          <Layout alignItems="center" display="inline-flex">
            <SuggestionCard
              icon={<IconConvert />}
              style={{
                margin: '0 12px 0 0',
                border: `1px solid ${theme?.COLORS?.TEXT}`,
                borderRadius: 8,
                maxWidth: 600,
              }}
              subtitle={t('aiMessageGenerator.goals.conversion.subtitle')}
              title={t('aiMessageGenerator.goals.conversion.title')}
            />
            <Button
              aria-label={t('aiMessageGenerator.goals.clearGoalSelection')}
              onClick={() => reset()}
              variant={BUTTON_VARIANTS.OUTLINE}
            >
              {t('clear')}
            </Button>
          </Layout>

          <SelectionTitle>{t('aiMessageGenerator.goals.conversion.description')}</SelectionTitle>

          <Layout maxWidth="400px">
            <Field name="successMetric" validate={required} validateFields={['link']}>
              {({ input }) => {
                return (
                  <Dropdown
                    {...input}
                    aria-label={t('aiMessageGenerator.goals.conversion.conversionDropdownLabel')}
                    id="conversion-dropdown"
                    label={
                      successMetricValue
                        ? t('aiMessageGenerator.successfulConversion')
                        : t('aiMessageGenerator.selectOne')
                    }
                    onChange={(option) => {
                      setGoal({
                        type: ConversionGoalType.Conversion,
                        success_metric: option.value,
                      })
                      input.onChange(option.value)
                    }}
                    options={successMetricOptions}
                    value={successMetricValue}
                  />
                )
              }}
            </Field>
          </Layout>

          <Layout margin="12px 0">
            <Field name="link" required type="url" validate={composeValidators(required, validateUrl)} validateOnBlur>
              {({ input, meta }) => (
                <TextInput
                  {...input}
                  error={isError(meta) || undefined}
                  label={
                    <Layout alignItems="center" display="flex" position="relative" top="2px">
                      <Layout as="span" marginRight="4px">
                        {t('aiMessageGenerator.linkUrl')}
                      </Layout>

                      <Tooltip content={<Trans i18nKey="aiMessageGenerator.linkUrlTooltip" />}>
                        <span>
                          <InfoIcon color={theme?.COLORS?.INPUT_LABEL} size={18} />
                        </span>
                      </Tooltip>
                    </Layout>
                  }
                  onChange={(e) => {
                    setLink(e.target.value)
                    input.onChange(e)
                  }}
                  placeholder={t('aiMessageGenerator.linkUrlPlaceholder')}
                  style={{
                    width: '100%',
                  }}
                  title={t('aiMessageGenerator.linkUrl')}
                  value={link}
                  variant={TextInputVariant.OUTLINED}
                />
              )}
            </Field>
          </Layout>

          <SettingTheToneSection />

          <GeneratingButton />
        </Layout>
      )}
    </Form>
  )
}

const toneSuggestions = [
  'Warm, approachable, engaging',
  'Enthusiastic, lively, upbeat',
  'Casual, conversational, friendly, approachable',
  'Articulate, eloquent, educational, insightful',
  'Intelligent, knowledgeable, insightful',
  'Analytical, logical, detailed',
  'Sophisticated, cultured, refined, elegant',
  'Poetic, romantic, expressive, artistic',
  'Trustworthy, sincere, calm',
  'Reliable, trustworthy, credible',
  'Empathetic, compassionate, understanding',
  'Sympathetic, sensitive, gentle, caring',
  'Comforting, soothing, nurturing',
  'Peaceful, tranquil, relaxing',
  'Optimistic, hopeful, uplifting, inspiring',
  'Grateful, appreciative, humble',
  'Charismatic, energetic, vibrant, dynamic',
  'Motivated, inspiring, ambitious, encouraging',
  'Bold, confident, audacious',
  'Exuberant, passionate, expressive',
  'Curious, inquisitive, exploratory, adventurous',
  'Dreamy, imaginative, creative',
  'Humorous, light-hearted, quirky, whimsical',
  'Playful, joyous, child-like, spontaneous',
  'Witty, sharp, clever, entertaining',
  'Meticulous, precise, informative',
  'Practical, realistic, grounded',
  'Reflective, introspective, thoughtful',
  'Mysterious, cryptic, intriguing',
  'Dramatic, theatrical, expressive, animated',
  'Futuristic, innovative, visionary',
]

function getRandomValueFromArray(arr: any[]) {
  if (!Array.isArray(arr) || arr.length === 0) {
    return undefined
  }

  const randomIndex = Math.floor(Math.random() * arr.length)

  return arr[randomIndex]
}

export function SettingTheToneSection(): JSX.Element {
  const { t } = useTranslation()
  const { tone, setTone, keywords, setKeywords, additionalDetails, setAdditionalDetails } = useAiMessageGenerator()
  const { lg } = useBreakpoints()
  const theme = useTheme() || {}

  return (
    <>
      <StyledDivider />

      <SelectionTitle>{t('aiMessageGenerator.settingTheTone')}</SelectionTitle>

      <Layout display="grid" gridGap={lg ? '24px' : 0} gridTemplateColumns={lg ? '1fr 1fr' : '1fr'}>
        <Layout>
          <Layout position="relative">
            <Layout position="absolute" right="0" top="2px" zIndex="1">
              <StyledToneSuggestButton
                aria-label={t('aiMessageGenerator.toneOfVoiceSuggestionButtonLabel')}
                onClick={() => {
                  setTone(getRandomValueFromArray(toneSuggestions))

                  analytics.track(analytics.events.GPT.ToneSuggestionsButtonPressed())
                }}
              >
                <Layout as="span" marginRight="4px">
                  {t('aiMessageGenerator.toneOfVoiceSuggestionButton')}
                </Layout>

                <IconShuffle />
              </StyledToneSuggestButton>
            </Layout>

            <Field name="tone" validate={maxLength(120)}>
              {({ input, meta }) => (
                <TextInput
                  {...input}
                  error={isError(meta) || undefined}
                  label={
                    <Layout alignItems="center" display="flex" position="relative" top="2px">
                      <Layout as="span" marginRight="4px">
                        {t('aiMessageGenerator.toneOfVoice')}
                      </Layout>

                      <Tooltip content={<Trans i18nKey="aiMessageGenerator.toneOfVoiceTooltip" />}>
                        <span>
                          <InfoIcon color={theme?.COLORS?.INPUT_LABEL} size={18} />
                        </span>
                      </Tooltip>
                    </Layout>
                  }
                  onChange={(e) => {
                    setTone(e.target.value)
                    input.onChange(e)
                  }}
                  placeholder={t('aiMessageGenerator.toneOfVoicePlaceholder')}
                  style={{
                    width: '100%',
                  }}
                  title={t('aiMessageGenerator.toneOfVoice')}
                  value={tone}
                  variant={TextInputVariant.OUTLINED}
                />
              )}
            </Field>
          </Layout>

          <Field name="keywords" validate={maxLength(120)}>
            {({ input, meta }) => (
              <TextInput
                {...input}
                error={isError(meta) || undefined}
                label={
                  <Layout alignItems="center" display="flex" position="relative" top="2px">
                    <Layout as="span" marginRight="4px">
                      {t('aiMessageGenerator.keywordsOrPhrases')}
                    </Layout>

                    <Tooltip content={<Trans i18nKey="aiMessageGenerator.keywordsOrPhrasesTooltip" />}>
                      <span>
                        <InfoIcon color={theme?.COLORS?.INPUT_LABEL} size={18} />
                      </span>
                    </Tooltip>
                  </Layout>
                }
                onChange={(e) => {
                  setKeywords(e.target.value)
                  input.onChange(e)
                }}
                placeholder={t('aiMessageGenerator.keywordsOrPhrasesPlaceholder')}
                style={{
                  width: '100%',
                }}
                title={t('aiMessageGenerator.keywordsOrPhrases')}
                value={keywords}
                variant={TextInputVariant.OUTLINED}
              />
            )}
          </Field>
        </Layout>

        <Field name="additionalDetails" validate={maxLength(200)}>
          {({ input, meta }) => (
            <TextInput
              {...input}
              as="textarea"
              error={isError(meta) || undefined}
              label={
                <Layout alignItems="center" display="flex" position="relative" top="2px">
                  <Layout as="span" marginRight="4px">
                    {t('aiMessageGenerator.additionalDetail')}
                  </Layout>

                  <Tooltip content={<Trans i18nKey="aiMessageGenerator.additionalDetailTooltip" />}>
                    <span>
                      <InfoIcon color={theme?.COLORS?.INPUT_LABEL} size={18} />
                    </span>
                  </Tooltip>
                </Layout>
              }
              onChange={(e) => {
                setAdditionalDetails(e.target.value)
                input.onChange(e)
              }}
              placeholder={t('aiMessageGenerator.additionalDetailPlaceholder')}
              rows="6"
              style={{
                width: '100%',
              }}
              title={t('aiMessageGenerator.additionalDetail')}
              value={additionalDetails}
              variant={TextInputVariant.OUTLINED}
            />
          )}
        </Field>
      </Layout>
    </>
  )
}

export function FirstPartyDataGoalSelectedScreen(): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme() || {}
  const { reset, goal, setGoal, link, tone, keywords, additionalDetails } = useAiMessageGenerator()

  const { successMetricValue } = useSuccessMetric()

  const submitFullAiMessageGenerator = useSubmitFullAiMessageGenerator()

  return (
    <Form
      initialValues={{
        successMetric: successMetricValue,
        link: link,
        tone: tone,
        keywords: keywords,
        additionalDetails: additionalDetails,
        description: goal?.description,
      }}
      onSubmit={submitFullAiMessageGenerator}
    >
      {() => (
        <Layout>
          <SelectionTitle>{t('aiMessageGenerator.goals.selectionTitle')}</SelectionTitle>

          <Layout alignItems="center" display="inline-flex">
            <SuggestionCard
              icon={<IconPieChart />}
              style={{
                margin: '0 12px 0 0',
                border: `1px solid ${theme?.COLORS?.TEXT}`,
                borderRadius: 8,
                maxWidth: 600,
              }}
              subtitle={t('aiMessageGenerator.goals.firstPartyData.subtitle')}
              title={t('aiMessageGenerator.goals.firstPartyData.title')}
            />
            <Button
              aria-label={t('aiMessageGenerator.goals.clearGoalSelection')}
              onClick={() => reset()}
              variant={BUTTON_VARIANTS.OUTLINE}
            >
              {t('clear')}
            </Button>
          </Layout>

          <SelectionTitle id="goal-description-label">
            {t('aiMessageGenerator.goals.firstPartyData.description')}
          </SelectionTitle>

          <Layout margin="12px 0">
            <Field name="description" validate={maxLength(200)}>
              {({ input, meta }) => (
                <TextInput
                  {...input}
                  aria-labelledby="goal-description-label"
                  error={isError(meta) || undefined}
                  onChange={(e) => {
                    setGoal({
                      type: ConversionGoalType.FirstPartyData,
                      description: e.target.value,
                    })
                    input.onChange(e)
                  }}
                  placeholder={t('aiMessageGenerator.goals.firstPartyData.descriptionFieldLabel')}
                  style={{
                    width: '100%',
                  }}
                  title={t('field')}
                  value={goal?.description}
                  variant={TextInputVariant.OUTLINED}
                />
              )}
            </Field>
          </Layout>

          <SettingTheToneSection />

          <GeneratingButton />
        </Layout>
      )}
    </Form>
  )
}

export function BrandEngagementGoalSelectedScreen(): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme() || {}
  const { reset, goal, setGoal, link, tone, keywords, additionalDetails } = useAiMessageGenerator()

  const { successMetricValue } = useSuccessMetric()

  const submitFullAiMessageGenerator = useSubmitFullAiMessageGenerator()

  return (
    <Form
      initialValues={{
        successMetric: successMetricValue,
        link: link,
        tone: tone,
        keywords: keywords,
        additionalDetails: additionalDetails,
        description: goal?.description,
      }}
      onSubmit={submitFullAiMessageGenerator}
    >
      {() => (
        <Layout>
          <SelectionTitle>{t('aiMessageGenerator.goals.selectionTitle')}</SelectionTitle>

          <Layout alignItems="center" display="inline-flex">
            <SuggestionCard
              icon={<IconHeartBubble />}
              style={{
                margin: '0 12px 0 0',
                border: `1px solid ${theme?.COLORS?.TEXT}`,
                borderRadius: 8,
                maxWidth: 600,
              }}
              subtitle={t('aiMessageGenerator.goals.brandEngagement.subtitle')}
              title={t('aiMessageGenerator.goals.brandEngagement.title')}
            />
            <Button
              aria-label={t('aiMessageGenerator.goals.clearGoalSelection')}
              onClick={() => reset()}
              variant={BUTTON_VARIANTS.OUTLINE}
            >
              {t('clear')}
            </Button>
          </Layout>

          <SelectionTitle id="goal-description-label">
            {t('aiMessageGenerator.goals.brandEngagement.description')}
          </SelectionTitle>

          <Layout margin="12px 0">
            <Field name="description" validate={maxLength(200)}>
              {({ input, meta }) => (
                <TextInput
                  {...input}
                  aria-labelledby="goal-description-label"
                  error={isError(meta) || undefined}
                  onChange={(e) => {
                    setGoal({
                      type: ConversionGoalType.BrandEngagement,
                      description: e.target.value,
                    })

                    input.onChange(e)
                  }}
                  placeholder={t('aiMessageGenerator.goals.brandEngagement.descriptionFieldLabel')}
                  style={{
                    width: '100%',
                  }}
                  title={t('field')}
                  value={goal?.description}
                  variant={TextInputVariant.OUTLINED}
                />
              )}
            </Field>
          </Layout>

          <SettingTheToneSection />

          <GeneratingButton />
        </Layout>
      )}
    </Form>
  )
}

export function GeneratingButton(): JSX.Element {
  const { t } = useTranslation()
  const isLoadingSuggestions = useIsMutating([AI_INTERFACE])
  const { goal, tone, keywords, additionalDetails, link } = useAiMessageGenerator()

  const { invalid } = useFormState()
  const { submit } = useForm()

  const [count, setCount] = useState(0)

  useEffect(() => {
    if (isLoadingSuggestions) {
      setCount((prevCount) => prevCount + 1)
    }
  }, [isLoadingSuggestions])

  return (
    <Layout marginTop="20px">
      <StyledGenerateButton
        disabled={!!isLoadingSuggestions || invalid}
        icon={<IconWand />}
        onClick={() => {
          try {
            const eventFn =
              count > 0
                ? analytics.events.GPT.ReGenerateCampaignsButtonPressed
                : analytics.events.GPT.GenerateCampaignsButtonPressed

            analytics.track(
              eventFn({
                goal: (goal as Api.V1.RequestGoal)?.type,
                tone,
                keywords,
                additionalDetails,
                successMetric: goal?.success_metric,
                description: goal?.description,
                link,
              }),
            )
          } catch (e) {
            Sentry.captureException(e)
          }

          return submit()
        }}
        type="submit"
        variant={BUTTON_VARIANTS.ACTION}
      >
        {isLoadingSuggestions
          ? t('aiMessageGenerator.generating')
          : count > 0
          ? t('aiMessageGenerator.regenerate')
          : t('aiMessageGenerator.generate')}
      </StyledGenerateButton>
    </Layout>
  )
}
