import { AxisBottom, AxisLeft } from '@visx/axis'
import { localPoint } from '@visx/event'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { Bar, Line } from '@visx/shape'
import { Tooltip, useTooltip } from '@visx/tooltip'
import { max } from 'd3-array'
import { useMemo, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { MemberCount } from 'api/insights'
import { LineChartLoadingIndicator } from 'components/LineChart/LineChartLoadingIndicator'
import { MeasuredContainer } from 'components/MeasuredContainer'
import dayjs from 'utils/dayjs'
import { formatLargeNumber } from 'utils/number'

const VERTICAL_MARGIN = 20
const AXIS_BOTTOM_OFFSET = 6

const StyledBar = styled(Bar)`
  border-radius: 4px;
`

const StyledTooltip = styled(Tooltip)<{ $left?: number }>`
  ${({ $left }) =>
    $left &&
    css`
      left: ${$left}px;
    `}
  width: 130px;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme?.COLORS?.TOOLTIP_BACKGROUND} !important;
  color: ${({ theme }) => theme?.COLORS?.TOOLTIP_TEXT} !important;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION2};
`

const StyledTooltipLine = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledTooltipKey = styled.span`
  color: ${({ theme }) => theme?.COLORS?.TOOLTIP_LABEL};
`

const StyledLine = styled(Line)`
  pointer-events: none;
`

type BarChartProps = {
  data: MemberCount[]
  margin?: { top: number; right: number; bottom: number; left: number }
  formatBottomAxis: (d: any) => any
  bottomNumTicks: number
  tooltipCountLabel: string
  tooltipDateLabel: string
  formatTooltipValue?: (d: any) => any
  isLoading?: boolean
}

const toShortenedNumber = (number) => {
  if (number % 1 !== 0) return ''
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(number)
}

const getCount = (data: MemberCount): number => data.count
const getDate = (data: MemberCount): Date => new Date(data.timestamp)

const defaultTooltipDateFormat = (date: Date) => dayjs(date).format('M/DD/YYYY')

//tooltip
const handleTooltip =
  (tooltipData: MemberCount, showTooltip) =>
  (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
    const { x } = localPoint(event) || { x: 0 }

    if (!tooltipData) return
    showTooltip({
      tooltipData: tooltipData,
      tooltipLeft: x,
    })
  }

export const BarChart = ({
  data,
  margin = { top: 10, right: 30, bottom: 0, left: 30 },
  formatBottomAxis,
  bottomNumTicks,
  formatTooltipValue = defaultTooltipDateFormat,
  tooltipCountLabel,
  tooltipDateLabel,
  isLoading,
}: BarChartProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const [width, setWidth] = useState(600)
  const [height, setHeight] = useState(202)
  const { tooltipData, tooltipLeft, tooltipOpen, showTooltip, hideTooltip } = useTooltip<MemberCount>()

  // bounds
  const innerWidth = width - margin.left - margin.right
  const yMax = height - margin.top - VERTICAL_MARGIN - AXIS_BOTTOM_OFFSET
  const axisBottomTop = height - margin.top - VERTICAL_MARGIN

  const yScale = useMemo(() => {
    const maxCount = max(data, getCount) * 1.1 || 3000
    return (
      data &&
      scaleLinear({
        range: [yMax, 0],
        domain: [0, maxCount],
        nice: true,
      })
    )
  }, [yMax, data])

  // scales
  const xScale = useMemo(() => {
    return (
      data &&
      scaleBand({
        range: [0, innerWidth],
        domain: data.map(getDate),
        padding: 0.1,
      })
    )
  }, [data, innerWidth])

  if (isLoading) return <LineChartLoadingIndicator />
  return (
    <MeasuredContainer
      onResize={({ width, height }) => {
        setWidth(width)
        setHeight(height)
      }}
    >
      <svg height={height} width={width}>
        <Group left={margin.left} top={margin.top}>
          {data.map((d) => {
            const date = getDate(d)
            const barHeight = yMax - (yScale(getCount(d)) ?? 0) || 4
            const barWidth = xScale.bandwidth()
            const barX = xScale(date)
            const barY = yMax - barHeight
            return (
              <>
                <StyledBar
                  fill={COLORS?.DATA_TO}
                  height={barHeight}
                  key={`bar-${date}`}
                  onMouseLeave={hideTooltip}
                  onMouseMove={handleTooltip(d, showTooltip)}
                  onTouchMove={handleTooltip(d, showTooltip)}
                  onTouchStart={handleTooltip(d, showTooltip)}
                  rx={4}
                  width={barWidth}
                  x={barX}
                  y={barY}
                />
                {tooltipOpen && tooltipData && tooltipLeft && (
                  <>
                    <StyledLine
                      from={{
                        x: tooltipLeft - margin.left,
                        y: margin.top + VERTICAL_MARGIN,
                      }}
                      stroke={COLORS?.TOOLTIP_BACKGROUND}
                      strokeWidth={2}
                      to={{
                        x: tooltipLeft - margin.left,
                        y: yMax,
                      }}
                    />
                  </>
                )}
              </>
            )
          })}
          <AxisLeft
            hideAxisLine
            hideTicks
            numTicks={3}
            scale={yScale}
            stroke={COLORS?.LINKS}
            tickFormat={toShortenedNumber}
            tickLabelProps={() => ({
              fill: COLORS?.TEXT,
              fontSize: 11,
              textAnchor: 'end',
              dy: '0.33em',
              width: 10,
            })}
            tickStroke={COLORS?.LINKS}
          />
          <AxisBottom
            hideTicks
            numTicks={bottomNumTicks}
            scale={xScale}
            stroke={COLORS?.BORDERS}
            tickFormat={formatBottomAxis}
            tickLabelProps={() => ({
              fill: COLORS?.TEXT,
              fontSize: 11,
              textAnchor: 'middle',
            })}
            tickStroke={COLORS?.BORDERS}
            top={axisBottomTop}
          />
        </Group>
      </svg>
      {tooltipOpen && tooltipData && (
        <StyledTooltip $left={tooltipLeft}>
          <StyledTooltipLine>
            <StyledTooltipKey>{tooltipCountLabel}:</StyledTooltipKey>{' '}
            <span>{formatLargeNumber(getCount(tooltipData))}</span>
          </StyledTooltipLine>
          <StyledTooltipLine>
            <StyledTooltipKey>{tooltipDateLabel}:</StyledTooltipKey>{' '}
            <span>{formatTooltipValue(getDate(tooltipData))}</span>
          </StyledTooltipLine>
        </StyledTooltip>
      )}
    </MeasuredContainer>
  )
}
