import { Typography } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'

import { StyledGridLayout, StyledSection } from '../../GrowthTools.style'

import { GrowYourCommunityCard } from './GrowYourCommunityCard'
import { ShareYourCommunityCard } from './ShareYourCommunityCard'
import { ShareYourNumberCard } from './ShareYourNumberCard'

export const BestPractices = (): JSX.Element => {
  const { t } = useTranslation(undefined, { keyPrefix: 'growthTools.bestPractices' })
  return (
    <StyledSection>
      <Typography fontSize="24px" textTransform="capitalize" variant="h2">
        {t('title')}
      </Typography>
      <StyledGridLayout>
        <ShareYourNumberCard />
        <ShareYourCommunityCard />
        <GrowYourCommunityCard />
      </StyledGridLayout>
    </StyledSection>
  )
}
