import { ROUTES } from 'constants/routes'
import { ONBOARDING_TASKS, ONBOARDING_TASK_COMPLETE } from 'screens/Onboarding/constants'

export function onboardingRedirect({ tasks = {}, complete }: any): string | undefined {
  if (!Object.keys(tasks).length) return
  const name = tasks[ONBOARDING_TASKS.UPDATE_NAME]
  if (name && name.status !== ONBOARDING_TASK_COMPLETE) {
    return ROUTES.ONBOARDING.NAME
  }

  if (!complete) {
    return ROUTES.ONBOARDING.SUMMARY
  }
}
