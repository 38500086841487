import { SPACING } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import ClientAvatar from 'components/ClientAvatar'
import { ROUTES } from 'constants/routes'
import { DESKTOP_BREAK, NAVBAR_HEIGHT } from 'constants/theme'
import { useSidebar } from 'contexts/SidebarProvider'
import { useClient } from 'hooks/useClient'

const StyledNavbar = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: ${NAVBAR_HEIGHT};
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${SPACING[4]};

  @media (min-width: ${DESKTOP_BREAK}) {
    display: none;
  }
`

type StyledButtonProps = {
  $opened?: boolean
}

const StyledButton = styled.button<StyledButtonProps>`
  height: 100%;
  background: none;
  padding: 0 ${SPACING[4]};
  border: none;
  cursor: pointer;
  opacity: ${({ $opened }) => ($opened ? 0 : 1)};
`

type NavbarProps = {
  opened?: boolean
}

function Navbar({ opened = false }: NavbarProps): JSX.Element {
  const { setOpened } = useSidebar()
  const { data: client } = useClient()
  const { COLORS } = useTheme() || {}

  return (
    <StyledNavbar>
      <StyledButton $opened={opened} aria-label="Show menu" onClick={() => setOpened(true)}>
        <FontAwesomeIcon color={COLORS?.TEXT} icon="bars" />
      </StyledButton>
      <Link className="Navbar-right" to={ROUTES.SETTINGS.ROOT}>
        <ClientAvatar graphicUrl={client?.graphicUrlSmall} size={32} />
      </Link>
    </StyledNavbar>
  )
}

export default Navbar
