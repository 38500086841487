import { BORDER_RADIUS, SPACING, Typography } from '@community_dev/pixels'
import { opacify } from 'polished'
import React from 'react'
import styled, { useTheme } from 'styled-components'

export const StyledWorkflowCreateCard = styled.button<{
  $color?: string
  $selected?: boolean
  $clickable?: boolean
  disabled?: boolean
}>`
  width: 100%;
  min-height: 144px;
  background: ${({ theme, $selected, $color }) =>
    $selected && $color ? opacify(0.2, $color) : theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border: ${({ theme, $selected }) => ($selected ? `2px solid #000` : `0.5px solid ${theme?.COLORS?.BORDERS}`)};
  border-radius: ${BORDER_RADIUS[2]};
  padding: ${SPACING[4]};
  display: flex;
  align-items: center;
  cursor: ${({ $clickable, disabled }) => ($clickable && !disabled ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.COLORS.TEXT};
`

export const StyledIconContainer = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  border-radius: ${BORDER_RADIUS[1]};
  width: 112px;
  height: 112px;
  display: flex;
  flex: 112px 0 0;
  align-items: center;
  justify-content: center;
  margin-right: ${SPACING[4]};
  align-self: flex-start;
`

export const StyledTextContainer = styled.div`
  text-align: left;
`
export interface WorkflowCreateCardProps extends React.ComponentPropsWithoutRef<'button'> {
  color: string
  selected?: boolean
  icon: JSX.Element
  overline: string
  title: string
  description: string
  id?: string
  style?: React.CSSProperties
  className?: string
}

export const WorkflowCreateCard = React.forwardRef<HTMLButtonElement, WorkflowCreateCardProps>(
  (props, ref): JSX.Element => {
    const { color, selected, icon, overline, title, description, onClick, ...rest } = props
    const { COLORS } = useTheme()

    return (
      <StyledWorkflowCreateCard
        $clickable={Boolean(onClick)}
        $color={color}
        $selected={selected}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        <StyledIconContainer $color={color}>{icon}</StyledIconContainer>
        <StyledTextContainer>
          <Typography
            color={COLORS?.SUBTEXT}
            component="div"
            fontSize="14px"
            lineHeight="20px"
            marginBottom="4px"
            textTransform="none"
            variant="overline"
          >
            {overline}
          </Typography>
          <Typography
            component="div"
            fontSize="16px"
            fontWeight="bold"
            lineHeight="22px"
            marginBottom="4px"
            textDecoration="underline"
            variant="body1"
          >
            {title}
          </Typography>
          <Typography component="div" fontSize="14px" lineHeight="20px" variant="body2">
            {description}
          </Typography>
        </StyledTextContainer>
      </StyledWorkflowCreateCard>
    )
  },
)
