import { ReactNode } from 'react'
import Measure from 'react-measure'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
`
type Bounds = {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
}
type MeasuredContainerProps = {
  className?: string
  children: ReactNode
  onResize: (bounds: Bounds) => void
}

export function MeasuredContainer({ className, children, onResize }: MeasuredContainerProps): JSX.Element {
  return (
    <Measure
      bounds
      onResize={({ bounds }) => {
        if (bounds) {
          onResize(bounds)
        }
      }}
    >
      {({ measureRef }) => (
        <StyledContainer className={className} ref={measureRef}>
          {children}
        </StyledContainer>
      )}
    </Measure>
  )
}
