import { SPACING } from '@community_dev/pixels'
import styled, { css } from 'styled-components'

import { DESKTOP_BREAK, SIDEBAR_EASING, SIDEBAR_TRANSITION_SPEED } from 'constants/theme'

export type StyledMenuItemProps = {
  $depth?: number
  $collapsed?: boolean
  $onlyDesktop?: boolean
  $onlyMobile?: boolean
}

export const StyledMenuItem = styled.li<StyledMenuItemProps>`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};

  a,
  button {
    width: 100%;
    display: inline-flex;
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    color: ${({ theme }) => theme?.COLORS?.MENU_TEXT_PRIMARY};
    text-decoration: none;
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
    font-weight: 600;
    padding: ${({ $collapsed, $depth }) =>
      $collapsed ? `${SPACING[2]} 10px` : `${SPACING[2]} ${SPACING[4]} ${SPACING[2]} ${16 + ($depth || 0) * 10}px`};
    -webkit-appearance: none;
    border: 0px;
    cursor: pointer;
    background: none;
    white-space: nowrap;
    transition: all ${SIDEBAR_TRANSITION_SPEED} ${SIDEBAR_EASING};

    &.active {
      background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
    }

    &:hover:not(.active) {
      background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
    }
  }

  ${({ $onlyDesktop }) =>
    $onlyDesktop &&
    css`
      display: none;
      @media (min-width: ${DESKTOP_BREAK}) {
        display: flex;
      }
    `}
`
