import { AiBubble, Layout, Typography } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { ConversionGoalType } from '@community_dev/types/lib/api/v1/AiInterface'
import { useMutation } from '@tanstack/react-query'
import moment from 'moment'
import { transparentize } from 'polished'
import { useCallback, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'

import { CalendarToolbar } from './CalendarToolbar'
import { MonthDateHeader } from './MonthDateHeader'
import { MonthEvent } from './MonthEvent'
import { ScheduledCampaignModal } from './ScheduledCampaignModal'
import { CalendarMonthEvent, useCalendarMonthEvents } from './useCalendarMonthEvents'
import { useCurrentMonthDateRange } from './useCurrentMonthDateRange'

import { postSuggestFull } from 'api/ai-interface'
import { ScheduledCampaign } from 'api/scheduled'
import {
  AI_INTERFACE,
  FULL_SUGGEST,
} from 'components/ComposeMessage/components/AiMessageGeneratorButton/AiMessageGeneratorButton'
import { ScheduledState, useCompose } from 'components/ComposeMessage/ComposeContext'
import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { useClient, useClientId } from 'hooks/useClient'
import { useHasCapability } from 'hooks/useUserCapability'
import analytics from 'utils/analytics'
import { AnalyticsEvent } from 'utils/analytics/events'
import dayjs from 'utils/dayjs'
// ideally we would use dayjsLocalizer but March is currently broken
// https://github.com/jquense/react-big-calendar/issues/2534
const localizer = momentLocalizer(moment)
const views = ['month']
const calendarComponents = {
  toolbar: CalendarToolbar,
  month: {
    event: MonthEvent,
    dateHeader: MonthDateHeader,
  },
}

const dayPropGetter = (date: Date) => {
  if (dayjs(date).isBefore(dayjs(), 'day')) {
    return { className: 'before-today' }
  }
}

const eventPropGetter = (event: CalendarMonthEvent) => {
  if (event?.allDay) {
    return { className: 'ai-idea' }
  }
  if (event?.scheduledCampaign) {
    return { className: 'scheduled' }
  }
  if (event?.sentCampaign) {
    return { className: 'sent' }
  }
  if (event?.recurringCampaign) {
    return { className: 'recurring' }
  }
  if (event?.submittedForApprovalCampaign) {
    return { className: 'submitted-for-approval' }
  }
}

export function ScheduledMonth(): JSX.Element {
  const history = useHistory()
  const { data: client } = useClient()
  const clientId = useClientId()

  const { t } = useTranslation()
  const events = useCalendarMonthEvents()
  const { firstDayOfMonth } = useCurrentMonthDateRange()
  const {
    scheduledAt,
    setComposeMessage,
    setComposeModalOpen,
    setName,
    setScheduledAt,
    toggleScheduled,
    setAnalyticsOnSentEvent,
  } = useCompose()
  const [loadingAiMessage, setLoadingAiMessage] = useState(false)
  const [scheduledCampaign, setScheduledCampaign] = useState<ScheduledCampaign | null>(null)
  const { COLORS } = useTheme() || {}
  const mutationPostSuggestFull = useMutation(postSuggestFull, { mutationKey: [AI_INTERFACE, FULL_SUGGEST], retry: 3 })

  const hasAiEnabled = useHasCapability(CAPABILITIES.FEATURE.AI_MESSAGE_GENERATOR.ALL)
  const hasAddOnsEnabled = useHasCapability(CAPABILITIES.FEATURE.ADD_ONS.ALL)

  const openComposeModal = useCallback(
    (start: ScheduledState, text?: string, name?: string, analyticsOnSentEvent?: AnalyticsEvent) => {
      if (name) {
        setName(name)
      }
      if (!scheduledAt) {
        toggleScheduled()
      }
      setScheduledAt(start)
      if (text) {
        setComposeMessage(text)
      }
      if (analyticsOnSentEvent) {
        setAnalyticsOnSentEvent(analyticsOnSentEvent)
      }
      setComposeModalOpen(true)
    },
    [
      scheduledAt,
      setAnalyticsOnSentEvent,
      setComposeMessage,
      setComposeModalOpen,
      setName,
      setScheduledAt,
      toggleScheduled,
    ],
  )

  const handleSelectEvent = useCallback(
    async (event: CalendarMonthEvent) => {
      // if its a scheduled or recurring campaign, open the modal
      const scheduledEvent = event.scheduledCampaign || event.recurringCampaign || event.submittedForApprovalCampaign
      if (scheduledEvent) {
        setScheduledCampaign(scheduledEvent || null)
      }
      // if its a sent campaign, go to the campaign details
      else if (event.sentCampaign?.id) {
        history.push(route(ROUTES.CAMPAIGNS.DETAILS, { campaignId: event.sentCampaign?.id }))
      }
      // if its an all day event(Ai Idea), open compose with ai message if they have capability
      else if (event.allDay) {
        if (hasAiEnabled) {
          setLoadingAiMessage(true)
          const { data } = await mutationPostSuggestFull.mutateAsync({
            clientId,
            body: {
              client_name: client?.fullName,
              count: 1,
              max_length: 140,
              message_date: event.start?.toISOString(),
              tone: 'neutral',
              keywords: event.title,
              goal: {
                type: ConversionGoalType.BrandEngagement,
              } as Api.V1.RequestGoal,
            },
          })
          openComposeModal(
            dayjs(event.start).hour(11),
            data.suggestions[0].text,
            event.title,
            analytics.events.Calendar.CampaignCalendarAiMessageScheduled(),
          )
          setLoadingAiMessage(false)
        } else if (hasAddOnsEnabled) {
          history.push(route(ROUTES.ADD_ONS.VIEW, { addOn: 'ai-message-generator-beta' }))
        }
      }
    },
    [client?.fullName, clientId, history, mutationPostSuggestFull, openComposeModal, hasAiEnabled, hasAddOnsEnabled],
  )

  const handleOpenScheduledCompose = useCallback(
    (date: Date) => {
      const timeStart = dayjs(date).add(11, 'hours') // from midnight to 11am
      const timeNow = dayjs()
      const timeScheduled = timeStart.isSame(timeNow, 'day') ? timeNow.add(1, 'hours') : timeStart

      if (timeScheduled >= timeNow) {
        openComposeModal(
          timeScheduled,
          undefined,
          undefined,
          analytics.events.Calendar.CampaignCalendarMessageScheduled(),
        )
      }
    },
    [openComposeModal],
  )

  const handleSelectSlot = useCallback(
    ({ start }: { start: Date; end: Date }) => {
      handleOpenScheduledCompose(start)
    },
    [handleOpenScheduledCompose],
  )

  return (
    <Layout flex="1" position="relative">
      <Calendar
        components={calendarComponents}
        date={firstDayOfMonth}
        dayPropGetter={dayPropGetter}
        endAccessor="end"
        eventPropGetter={eventPropGetter}
        events={events}
        localizer={localizer}
        onDrillDown={handleOpenScheduledCompose}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        popup
        selectable="ignoreEvents"
        startAccessor="start"
        // Drill down happens when you click on the day number
        views={views}
      />
      {loadingAiMessage && (
        <Layout
          alignItems="center"
          backgroundColor={transparentize(0.15, COLORS?.APP_BACKGROUND_LEVEL_3)}
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          left="0"
          position="absolute"
          top="0"
          width="100%"
          zIndex="1000"
        >
          <AiBubble isPlaying style={{ height: '60px', width: '60px' }} />
          <Typography fontWeight="500" marginBottom="0" marginTop="8px" variant="body1">
            {t('loading')}
          </Typography>
        </Layout>
      )}
      <ScheduledCampaignModal scheduledCampaign={scheduledCampaign} setScheduledCampaign={setScheduledCampaign} />
    </Layout>
  )
}
