import { Api } from '@community_dev/types'

export const normalizeReplyTo = (threadId: string, individuals: any): Api.V2.ReplyTo | undefined => {
  if (!threadId || !individuals.length) {
    return
  }

  const messages = individuals.map((fan) => ({
    fan_id: fan.id,
    id: fan.item.id,
    inserted_at: fan.item.ts,
  }))

  const selectedMessage = individuals[0].item
  const media = selectedMessage.media && Object.keys(selectedMessage.media).length > 0 ? selectedMessage.media : null

  return {
    messages,
    parent_sms_campaign_id: threadId,
    representative_message: {
      id: selectedMessage.id,
      text: selectedMessage.body,
      media,
    },
  }
}
