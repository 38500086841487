import { BORDER_RADIUS } from '@community_dev/pixels'
import styled from 'styled-components'

import { StyledVideoMain, StyledVideoRoot } from 'components/media/Video/Video.style'
import { generalConfig } from 'utils/config'

const PLACEHOLDER_URL = `${generalConfig.staticUrl}/refresh-to-see-preview.png`

export const MediaPreviewRoot = styled.div<{ hasSize: boolean; inbound?: boolean }>`
  display: flex;
  justify-content: ${({ inbound }) => (inbound ? 'flex-start' : 'flex-end')};
  border-radius: ${BORDER_RADIUS[2]};
  width: 100%;

  ${StyledVideoRoot} {
    width: ${({ hasSize }) => hasSize && 'auto'};
  }

  ${StyledVideoMain} {
    padding-bottom: ${({ hasSize }) => (hasSize ? 0 : '')};
    height: ${({ hasSize }) => hasSize && 'auto'};
    width: ${({ hasSize }) => hasSize && 'auto'};
  }

  video,
  img {
    position: ${({ hasSize }) => hasSize && 'relative'};
    height: ${({ hasSize }) => hasSize && 'auto'};
  }
`

export const MediaPreviewPlaceholder = styled.div`
  width: 268px;
  height: 200px;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
  background-image: url(${PLACEHOLDER_URL});
  background-size: contain;
  border-radius: ${BORDER_RADIUS[2]};
`
