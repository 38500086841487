import { Button, Layout, Tooltip } from '@community_dev/pixels'
import { Link } from 'react-router-dom'

import { RouteAction } from 'api/addOns'
import { useHasCapabilitiesAndFeatureFlags } from 'hooks/useAddons/useHasCapabilitiesAndFeatureFlags'
import analytics from 'utils/analytics'

type RouteButtonProps = RouteAction & {
  className?: string
}
export const RouteButton = ({ text, to, disabled = {}, className }: RouteButtonProps): JSX.Element => {
  const isDisabled = !useHasCapabilitiesAndFeatureFlags({
    capabilities: disabled.capabilities,
    featureFlags: disabled.featureFlags,
  })

  if (isDisabled) {
    return (
      <Tooltip content={disabled?.tooltip}>
        <Layout className={className}>
          <Button disabled>{text}</Button>
        </Layout>
      </Tooltip>
    )
  }

  return (
    <Link className={className} onClick={() => analytics.track(analytics.events.AddOnCtaClicked(text))} to={to}>
      <Button>{text}</Button>
    </Link>
  )
}
