const LOCALITY_MAP = {
  USA: 'state',
  CANADA: 'province',
} as const

export const COUNTRY_LOOKUP = {
  USA: 'USA',
  CA: 'CANADA',
} as const

export type LocalityMap = typeof LOCALITY_MAP[keyof typeof LOCALITY_MAP]

export default LOCALITY_MAP
