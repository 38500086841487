import {
  Block,
  ChevronRightIcon,
  DefinitionList,
  InfoIcon,
  LoadingIndicator,
  SPACING,
  Tooltip,
} from '@community_dev/pixels'
import Moment from 'react-moment/src/index'
import styled, { useTheme } from 'styled-components'

const StyledMissingPermissions = styled.div`
  display: flex !important;
  margin-right: 8px;
`

const StyledMissingData = styled(StyledMissingPermissions)`
  cursor: pointer;
`

const StyledMissingDataText = styled.div`
  margin-left: 4px;
`

const StyledMissingDataTitle = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
`

const StyledMissingDataIcon = styled.div`
  margin-right: 4px;
`

const StyledMissingDataDescription = styled.div`
  margin-top: ${SPACING[1]};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`
const StyledLink = styled.a`
  display: block;
  margin-top: ${SPACING[1]};
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  font-weight: 600;
  text-decoration: none;
`

const StyledTooltipContent = styled.div`
  padding: ${SPACING[2]};
`
const StyledShopifyLink = styled.a`
  padding: ${SPACING[4]} ${SPACING[4]} ${SPACING[4]} 0;
  border-top: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  cursor: pointer;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  text-decoration: none;
`

const StyledDefinitionList = styled(DefinitionList)`
  dt {
    width: 100px;
  }
`

const calendarOpts = {
  sameDay: '[Today]',
  sameElse: 'ddd, MMM D, YYYY',
  sameYear: 'ddd, MMM D',
}

type MemberShopifyProps = {
  customerDetails?: any
  errors: any[]
  isLoading: boolean
}

export function MemberShopify({ customerDetails, errors, isLoading }: MemberShopifyProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const currencySymbol =
    customerDetails?.totalSpent?.currency === 'USD' ? '' : ` ${customerDetails?.totalSpent?.currency}`

  const missingPermissions = errors[0]?.status === 422
  const missingData = customerDetails?.lastOrderDatetime === null

  const MissingDataTooltipContent = () => (
    <StyledTooltipContent>Currently unable to access Shopify data</StyledTooltipContent>
  )

  const renderContent = missingPermissions ? (
    <StyledMissingPermissions>
      <StyledMissingDataIcon>
        <InfoIcon />
      </StyledMissingDataIcon>
      <StyledMissingDataText>
        <StyledMissingDataTitle>Unable to access</StyledMissingDataTitle>
        <StyledMissingDataDescription>
          API access is required (Customers: Read and Write). Edit your 'Custom App' settings in Shopify.
          <br />
          <StyledLink
            href="https://incommunity.zendesk.com/hc/en-us/articles/1500002146762-Connect-Shopify-Store-for-non-Plus-merchants-#troubleshooting"
            target="_blank"
          >
            Learn more
          </StyledLink>
        </StyledMissingDataDescription>
      </StyledMissingDataText>
    </StyledMissingPermissions>
  ) : (
    <>
      <StyledDefinitionList css={customerDetails?.url ? 'margin-bottom: 0' : ''}>
        <DefinitionList.Item label="Total Spent">
          ${customerDetails?.totalSpent?.amount}
          {currencySymbol}
        </DefinitionList.Item>
        <DefinitionList.Item label="Last Order">
          {missingData ? (
            <Tooltip content={<MissingDataTooltipContent />}>
              <StyledMissingData>
                <StyledMissingDataIcon>
                  <InfoIcon />
                </StyledMissingDataIcon>
                <StyledMissingDataDescription>Unable to access</StyledMissingDataDescription>
              </StyledMissingData>
            </Tooltip>
          ) : (
            <Moment calendar={calendarOpts} data-testid="time" element="div">
              {customerDetails?.lastOrderDatetime}
            </Moment>
          )}
        </DefinitionList.Item>
      </StyledDefinitionList>
      {customerDetails?.url && (
        <StyledShopifyLink href={customerDetails.url} rel="noopener noreferrer" target="_blank">
          View in Shopify <ChevronRightIcon color={COLORS?.DIVIDERS} />
        </StyledShopifyLink>
      )}
    </>
  )

  return (
    <Block flush={!missingPermissions} title="Shopify">
      {isLoading ? <LoadingIndicator /> : renderContent}
    </Block>
  )
}
