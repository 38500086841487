import {
  BORDER_RADIUS,
  BUTTON_VARIANTS,
  Badge,
  Button,
  FONT_SIZE,
  Info,
  InfoIcon,
  InsightsIcon,
  Layout,
  LoadingIndicator,
  SPACING,
  Tooltip,
  Typography,
} from '@community_dev/pixels'
import { WebhookEventType, WebhookResponseStatus } from '@community_dev/types/lib/api/v1/Webhook'
import keyBy from 'lodash/keyBy'
import { mix } from 'polished'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { WebhookStatisticsResponse } from 'api/webhooks'
import { CommunityDot } from 'components/CommunityDot'
import { Pie } from 'screens/InsightsScreen/charts/PieChart'
import { SignpostText } from 'screens/InsightsScreen/presentation/InsightsBox'
import dayjs from 'utils/dayjs'

const StyledBadge = styled(Badge)`
  padding: ${SPACING[2]};
  line-height: 1;
`
const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.COLORS.LINKS};
`
const StyledInfo = styled(Info)`
  margin-top: ${SPACING[4]};
  * {
    font-size: ${FONT_SIZE[2]};
    color: ${({ theme }) => theme.COLORS.SUBTEXT};
    line-height: 1.4;
  }
`
export function WebhookStatistics({
  value,
  onRefresh,
  isLoading,
}: {
  isLoading?: boolean
  value: WebhookStatisticsResponse
  onRefresh: () => void
}): JSX.Element {
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const [highlightedType, setHighlighted] = useState<WebhookResponseStatus | undefined>()
  const typeNames = t('settings.integrations.webhooks.webhookResponseTypes', { returnObjects: true })
  const responsesSum = useMemo(
    () =>
      value.webhook.counts.reduce(
        (m, count) => ({
          total: m.total + count.count,
          errors: count.type === WebhookResponseStatus.SUCCESS ? m.errors : m.errors + count.count,
        }),
        { errors: 0, total: 0 },
      ),
    [value.webhook.counts],
  )
  const isEmpty = useMemo(() => responsesSum.total === 0, [responsesSum.total])
  const sections = useMemo(() => {
    return value.webhook.counts
      .sort((a, b) => (b.type === WebhookResponseStatus.SUCCESS ? 1 : b.count - a.count))
      .map((count, i) => ({
        highlight: count.type === highlightedType,
        type: count.type,
        label: `${typeNames[count.type]} (${Math.round((count.count / responsesSum.total) * 100)}%)`,
        value: count.count,
        valueLabel: count.count.toString(),
        color: count.type === WebhookResponseStatus.SUCCESS ? COLORS.BORDERS : mix(i * 0.2, '#953553', '#FFA500'),
      }))
  }, [COLORS.BORDERS, highlightedType, responsesSum.total, typeNames, value.webhook.counts])
  const sectionsByType = useMemo(() => keyBy(sections, 'type'), [sections])
  if (isLoading) {
    return (
      <Layout marginBottom={SPACING[9]} marginTop={SPACING[7]}>
        <LoadingIndicator size={30} />
      </Layout>
    )
  } else if (isEmpty) {
    return (
      <StyledInfo
        footer={
          <Layout marginTop={SPACING[2]} textAlign="center">
            <StyledButton onClick={onRefresh} variant={BUTTON_VARIANTS.LINK}>
              {t('refresh')}
            </StyledButton>
          </Layout>
        }
        icon={<InsightsIcon color={COLORS.SUBTEXT} />}
      >
        {t('settings.integrations.webhooks.emptyStatisticsMessage')}
      </StyledInfo>
    )
  } else {
    return (
      <Layout>
        <Layout marginTop={SPACING[5]}>
          <SignpostText>
            {t('settings.integrations.webhooks.responseSummary')}
            <Tooltip
              content={t('settings.integrations.webhooks.responseSummaryTooltip', {
                total: responsesSum.total,
                errors: responsesSum.errors,
                successes: sectionsByType[WebhookResponseStatus.SUCCESS]?.value,
              })}
            >
              <span>
                <InfoIcon size={20} />
              </span>
            </Tooltip>
          </SignpostText>
          <Layout
            alignItems="center"
            display="flex"
            flexDirection="row"
            gap={SPACING[5]}
            justifyItems="center"
            marginBottom={SPACING[5]}
            marginTop={SPACING[5]}
          >
            <Pie sortedSlices={sections} />
            <Layout display="flex" flex="1" flexDirection="column" flexWrap="wrap">
              {sections.map((section) => {
                return (
                  <Layout
                    alignItems="center"
                    background={highlightedType === section.type ? COLORS.APP_BACKGROUND_LEVEL_2 : ''}
                    borderRadius={BORDER_RADIUS[1]}
                    display="flex"
                    flex=".5"
                    flexDirection="row"
                    gap={SPACING[2]}
                    key={section.type}
                    onMouseOut={() => setHighlighted(undefined)}
                    onMouseOver={() => setHighlighted(section.type)}
                    padding={`${SPACING[1]} ${SPACING[2]}`}
                  >
                    <CommunityDot color={section.color} size={20} />
                    <Layout>
                      <Typography padding={0} variant="caption1" whiteSpace="nowrap">
                        {section.label}
                      </Typography>
                      <Typography margin={0} variant="body1">
                        {section.valueLabel}
                      </Typography>
                    </Layout>
                  </Layout>
                )
              })}
            </Layout>
          </Layout>
        </Layout>
        <Layout>
          <SignpostText>
            {t('settings.integrations.webhooks.mostRecentErrors')} (7 {t('settings.days')}){' '}
          </SignpostText>
          <Layout
            as="ul"
            data-testid="webhook-error-list"
            display="flex"
            flexDirection="column"
            gap={SPACING[3]}
            marginTop={SPACING[4]}
            paddingLeft={0}
          >
            {value.webhook.lastErrors
              .sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))
              .map((error, i) => {
                return (
                  <Layout
                    alignItems="center"
                    as="li"
                    data-testid={`webhook-error-${error.timestamp}`}
                    display="flex"
                    flexDirection="row"
                    key={`${error.timestamp}-${i}`}
                  >
                    <Typography
                      color={COLORS.SUBTEXT}
                      component="time"
                      display="inline-block"
                      variant="body2"
                      width="12em"
                    >
                      {dayjs(error.timestamp).format('YYYY-MM-DD [at] HH:mm:ss')}
                    </Typography>
                    <Layout display="flex" flex="1" gap={SPACING[2]}>
                      <StyledBadge background={COLORS.BUTTON_DEFAULT}>
                        {error.eventType === WebhookEventType.MEMBER_UPDATE &&
                          t('settings.integrations.webhooks.typeNames.memberUpdate')}
                        {error.eventType === WebhookEventType.INBOUND_MESSAGE &&
                          t('settings.integrations.webhooks.typeNames.inboundMessage')}
                        {error.eventType === WebhookEventType.OUTBOUND_MESSAGE &&
                          t('settings.integrations.webhooks.typeNames.outboundMessage')}
                      </StyledBadge>
                      <div
                        onMouseOut={() => setHighlighted(undefined)}
                        onMouseOver={() => setHighlighted(error.errorType)}
                      >
                        <StyledBadge
                          background={sectionsByType[error.errorType]?.color}
                          borderColor={sectionsByType[error.errorType]?.color}
                          color={COLORS.APP_BACKGROUND_LEVEL_3}
                        >
                          {typeNames[error.errorType]}
                          {error.errorType === WebhookResponseStatus.ERROR_TYPE_STATUS_CODE &&
                            `: ${error.responseStatusCode}`}
                        </StyledBadge>
                      </div>
                    </Layout>
                  </Layout>
                )
              })}
          </Layout>
        </Layout>
      </Layout>
    )
  }
}
