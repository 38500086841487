import { Button, BUTTON_VARIANTS } from '@community_dev/pixels'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledEditButtonContainer } from './EditButton.styled'

type EditButtonProps = {
  onSave?: (...args: any[]) => void
  onCancel?: (...args: any[]) => void
  loading?: boolean
  disabled?: boolean
}

const EditButtons = ({ onSave, onCancel, disabled }: EditButtonProps): JSX.Element => {
  const { t } = useTranslation()
  const handleSave = useCallback(
    (e: Event) => {
      if (onSave) {
        e.preventDefault()
        onSave(e)
      }
    },
    [onSave],
  )

  const handleCancel = useCallback(
    (e: Event) => {
      if (onCancel) {
        e.preventDefault()
        onCancel(e)
      }
    },
    [onCancel],
  )

  return (
    <StyledEditButtonContainer>
      {onCancel && (
        <Button onClick={handleCancel} variant={BUTTON_VARIANTS.OUTLINE}>
          <span>{t('cancel')}</span>
        </Button>
      )}
      {onSave && (
        <Button disabled={disabled} onClick={disabled ? null : handleSave}>
          <span>{t('save')}</span>
        </Button>
      )}
    </StyledEditButtonContainer>
  )
}

export default EditButtons
