import { ApiError } from '@community_dev/requests'
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { useClientId } from './useClient'

import { getMonthlyUsage, MonthlyUsage } from 'api/billingUsage'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'

export const useMonthlyUsageQuery = <SelectData = MonthlyUsage>(
  options?: UseQueryOptions<MonthlyUsage, ApiError, SelectData>,
): UseQueryResult<SelectData, ApiError> => {
  const clientId = useClientId()
  return useQuery({
    queryKey: [QUERY_CACHE.BILLING.SEGMENT_USAGE_CURRENT_MONTH, { clientId }],
    queryFn: () => getMonthlyUsage({ clientId }),
    staleTime: STALE_TIME.FIVE_MINUTES,
    ...options,
  })
}
