import { Button, BUTTON_VARIANTS, Dialog, DIALOG_VARIANTS, InfoIcon } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { CampaignStatus } from '@community_dev/types/lib/api/v2/Campaign'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { Badge } from './Badge'

import { Campaign, deleteCampaign } from 'api/campaign'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'

const StyledErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
`

const StyledDeleteButton = styled(Button)`
  border-radius: 20px;
  font-size: 12px;
  padding: 7px 12px;

  &:hover {
    background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
  }
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme.COLORS.TOOLTIP_TEXT};
`

type CampaignErrorProps = {
  campaign: Campaign
}
export const campaignErrorStatuses = [
  CampaignStatus.MEDIA_TRANSCODING_FAILED,
  CampaignStatus.FAILED,
  CampaignStatus.TEMPLATE_REJECTED,
  CampaignStatus.TEMPLATE_FAILED,
  CampaignStatus.TEMPLATE_PENDING,
]

export function CampaignError(props: CampaignErrorProps): JSX.Element {
  const { campaign } = props
  const clientId = useClientId()
  const queryClient = useQueryClient()
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const { mutate: deleteCampaignMutation, isLoading: deleteCampaignIsLoading } = useMutation(
    () => deleteCampaign({ campaignId: campaign.id, clientId: clientId }),
    {
      onError() {
        showToastMessage({
          message: t('campaigns.campaignDeleteFailure'),
          success: false,
        })
      },
      onSuccess() {
        queryClient.invalidateQueries([QUERY_CACHE.CAMPAIGN.LIST])

        setShowDeleteDialog(false)

        showToastMessage({
          message: t('campaigns.campaignDeleteSuccess'),
        })
      },
    },
  )

  return (
    <>
      <StyledErrorContainer>
        {campaign.status === CampaignStatus.TEMPLATE_PENDING &&
          campaign.communicationChannel === CommunicationChannel.WHATS_APP && (
            <Badge icon={<InfoIcon color={COLORS.INFO} size={20} />}>{t('campaigns.templatePendingReview')}</Badge>
          )}
        {campaign.status === CampaignStatus.TEMPLATE_REJECTED && (
          <Badge
            color={COLORS.ERRORS}
            tooltip={
              <div
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <Trans i18nKey="campaigns.templateRejectedHelp">
                  <StyledLink href="mailto:yourfriends@community.com" />
                </Trans>
              </div>
            }
          >
            {t('campaigns.templateRejected')}
          </Badge>
        )}
        {campaign.status === CampaignStatus.MEDIA_TRANSCODING_FAILED && (
          <Badge
            color={COLORS.ERRORS}
            tooltip={
              <div
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <Trans i18nKey="campaigns.mediaTranscodingFailed">
                  <StyledLink href="mailto:yourfriends@community.com" />
                </Trans>
              </div>
            }
          >
            {t('campaigns.failedToSend')}
          </Badge>
        )}
        {(campaign.status === CampaignStatus.FAILED || campaign?.status === CampaignStatus.TEMPLATE_FAILED) && (
          <Badge color={COLORS.ERRORS}>{t('campaigns.failedToSend')}</Badge>
        )}
        <StyledDeleteButton
          aria-label={t('campaigns.deleteCampaignButtonLabel')}
          disabled={deleteCampaignIsLoading}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()

            setShowDeleteDialog(true)
          }}
          variant={BUTTON_VARIANTS.OUTLINE}
        >
          {t('delete')}
        </StyledDeleteButton>
      </StyledErrorContainer>

      {showDeleteDialog && (
        <Dialog
          message={t('campaigns.confirmDeletionMessage')}
          onCancel={() => setShowDeleteDialog(false)}
          title={t('campaigns.confirmDeletion')}
        >
          <Dialog.Action onClick={() => setShowDeleteDialog(false)}>{t('cancel')}</Dialog.Action>
          <Dialog.Action onClick={() => deleteCampaignMutation()} variant={DIALOG_VARIANTS.DESTRUCTIVE}>
            {t('confirm')}
          </Dialog.Action>
        </Dialog>
      )}
    </>
  )
}
