import { BUTTON_VARIANTS, Button, SPACING, Typography } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PRODUCT_EDUCATION_THRESHOLD } from './constants'
import { FeaturedAddOns } from './FeaturedAddOns'
import { ProductEducation } from './screens/ProductEducation'
import { TrendingResponses } from './TrendingResponses'

import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { CAPABILITIES } from 'constants/capabilities'
import HomeSuggestion from 'containers/HomeSuggestion'
import { useClient } from 'hooks/useClient'
import { useAllMemberCount } from 'hooks/useCountByQuery/useEveryoneCount'
import { useHasCapability } from 'hooks/useUserCapability'
import { SidebarLayout } from 'layouts/SidebarLayout'
import { partOfDay } from 'utils/time'

export const MAX_CENTER_COLUMN_WIDTH = '720px'

const StyledHomeScreen = styled.div`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;

  & > div:last-child {
    margin-bottom: ${SPACING[9]};
  }
`

const StyledInsight = styled.div`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex: 0 0 auto;
  align-self: center;
  flex-direction: column;
  padding: 0 ${SPACING[4]};
`

const StyledGreeting = styled(Typography).attrs({
  component: 'h1',
  variant: 'h1',
})`
  padding: ${SPACING[9]} ${SPACING[0]} ${SPACING[0]};
  margin: ${SPACING[0]} ${SPACING[0]} ${SPACING[4]};
  align-self: center;
  text-align: center;
`

const StyledButton = styled(Button)`
  align-self: center;
  margin-bottom: ${SPACING[8]};
`

const StyledContainerMatchingHomeSuggestionLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 ${SPACING[4]};

  > div {
    width: 100%;
    max-width: ${MAX_CENTER_COLUMN_WIDTH};
  }

  h2 {
    margin-top: 0;
  }
`

function HomeScreen(): JSX.Element {
  const { t } = useTranslation()
  const { setComposeModalOpen } = useCompose()
  const { total: totalSubscribers = 0, isLoading: isInitialLoading } = useAllMemberCount()
  const { data: client } = useClient()

  // We disable the send new message if the client has no communication channels
  const isSendNewMessageDisabled = !client?.communicationChannels?.length

  const showProductEducation = totalSubscribers < PRODUCT_EDUCATION_THRESHOLD

  const isMessageClusteringEnabled = useHasCapability(CAPABILITIES.FEATURE.MESSAGE_CLUSTERING.ALL)

  const showTrendingResponses = !showProductEducation && isMessageClusteringEnabled

  const isAddOnsEnabled = useHasCapability(CAPABILITIES.FEATURE.ADD_ONS.ALL)

  const showFeaturedAddOns = isAddOnsEnabled

  return (
    <SidebarLayout>
      <StyledHomeScreen>
        <StyledInsight>
          {client && (
            <StyledGreeting>
              {`Good ${partOfDay()},`}
              {client.fullName?.length > 20 ? <br /> : ' '}
              {client.fullName}
            </StyledGreeting>
          )}
          <StyledButton
            disabled={isSendNewMessageDisabled}
            onClick={() => setComposeModalOpen(true)}
            variant={BUTTON_VARIANTS.ACTION}
          >
            {t('sendNewMessage')}
          </StyledButton>
        </StyledInsight>
        {isInitialLoading ? null : (
          <>
            {showFeaturedAddOns && <FeaturedAddOns />}
            {showProductEducation ? <ProductEducation /> : <HomeSuggestion />}
            {showTrendingResponses && (
              <StyledContainerMatchingHomeSuggestionLayout>
                <div>
                  <TrendingResponses />
                </div>
              </StyledContainerMatchingHomeSuggestionLayout>
            )}
          </>
        )}
      </StyledHomeScreen>
    </SidebarLayout>
  )
}

export default HomeScreen
