import {
  Modal,
  Button,
  CloseIcon,
  BUTTON_VARIANTS,
  Typography,
  EditableText,
  Layout,
  Dialog,
  DIALOG_VARIANTS,
  SPACING,
  CheckIcon2,
  WarningIcon,
  Card,
  PhonePreview,
} from '@community_dev/pixels'
import { CampaignStatus } from '@community_dev/types/lib/api/v2/Campaign'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { Dispatch, SetStateAction, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import styled from 'styled-components'

import { toScheduledCampaignLabel } from '../List/ScheduledList'

import { patchMessageQuery, postScheduledMessageApprovalStatus } from 'api/message'
import { ScheduledCampaign, deleteScheduledCampaign } from 'api/scheduled'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { replacePlaceholdersWithNames } from 'components/ComposeEditor/util/replacePlaceholders'
import { ComposeRole, useComposeRole } from 'components/ComposeMessage/hooks/useComposeRole'
import { usePhonePreviewBubbles } from 'components/ComposeMessage/hooks/usePhonePreviewBubbles'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClient, useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useToastMessage } from 'hooks/useToastMessage'
import Sentry from 'integrations/Sentry'
import analytics from 'utils/analytics'
import { screens } from 'utils/analytics/events'
import dayjs from 'utils/dayjs'
import { titleize } from 'utils/normalize'
import { parseRecurrenceRule, RecurrenceDay, RecurrenceFrequency, RecurrenceRule } from 'utils/recurrence'

const StyledCard = styled(Card)`
  margin-top: 16px;
  padding: 16px 16px;
  box-sizing: content-box;
  text-align: left;
`

const StyledBody = styled(Modal.Body)`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme.COLORS.TOOLTIP_TEXT};
`

const StyledApproveButton = styled(Button)`
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-left: ${SPACING[3]};

  svg {
    margin-right: ${SPACING[1]};
    path {
      fill: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY_TEXT};
    }
  }
`

type ScheduledCampaignModalProps = {
  scheduledCampaign: ScheduledCampaign | null
  setScheduledCampaign: Dispatch<SetStateAction<ScheduledCampaign | null>>
}

export const ScheduledCampaignModal = ({
  scheduledCampaign,
  setScheduledCampaign,
}: ScheduledCampaignModalProps): JSX.Element | null => {
  const { COLORS } = useTheme() || {}
  const queryClient = useQueryClient()
  const clientId = useClientId()
  const { data: client } = useClient()
  const { t } = useTranslation()
  const { data: communities } = useCommunities()
  const { showToastMessage } = useToastMessage()
  const [showCancel, setShowCancel] = useState(false)
  const needsApproval = scheduledCampaign?.status === CampaignStatus.SUBMITTED_FOR_APPROVAL
  const isCampaignWriter = useComposeRole() === ComposeRole.CAMPAIGN_WRITER

  const { mutate: mutateApproveCampaign } = useMutation(postScheduledMessageApprovalStatus, {
    onSuccess() {
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.RANGE, { clientId }])
      showToastMessage({
        message: t('scheduled.campaignApproved'),
        position: 'bottom-right',
      })
      handleClose()
    },
    onError() {
      showToastMessage({
        message: t('scheduled.errorApprovingCampaign'),
        success: false,
        position: 'bottom-right',
      })
    },
  })

  const bubbles = usePhonePreviewBubbles({
    media: scheduledCampaign?.media,
    shouldShortenLinks: Boolean(scheduledCampaign?.clientUrls?.length),
    text: replacePlaceholdersWithNames(scheduledCampaign?.text || '', scheduledCampaign?.placeholders || []),
  })

  const { mutate: patchMessage } = useMutation(patchMessageQuery, {
    onSuccess: () => {
      analytics.track(
        analytics.events.EditCampaignName({
          page: screens.Scheduled,
        }),
      )
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.LIST, { clientId }])
      queryClient.invalidateQueries([QUERY_CACHE.CAMPAIGN.RANGE])
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.RANGE])
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.RECURRING])
      showToastMessage({
        message: t('updatedCampaignName'),
        position: 'bottom-right',
      })
    },
    onError: (error) => {
      showToastMessage({
        message: t('failedToUpdateCampaignName'),
        position: 'bottom-right',
        success: false,
      })

      Sentry.captureException(error)
    },
  })

  const { mutate: mutateDeleteScheduledCampaign } = useMutation(deleteScheduledCampaign, {
    onSuccess() {
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.LIST, { clientId }])
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.RANGE])
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.RECURRING])
      showToastMessage({
        message: t('scheduled.deletedCampaign'),
        position: 'bottom-right',
      })
      handleClose()
    },
    onError() {
      showToastMessage({
        message: t('scheduled.errorDeletingCampaign'),
        position: 'bottom-right',
        success: false,
      })
    },
  })

  const handleClose = () => {
    setScheduledCampaign(null)
  }

  if (!scheduledCampaign) return null

  const handleSave = (name: string) => {
    if (name.trim() && name.trim() !== scheduledCampaign.name) {
      patchMessage({
        body: {
          name: name.trim(),
        },
        campaignId: scheduledCampaign.id,
        clientId,
      })
    }
  }

  const recurrenceParams = parseRecurrenceRule(scheduledCampaign?.recurrence?.frequency as RecurrenceRule)

  return (
    <>
      <Modal onClose={handleClose} open>
        <Modal.Header>
          <Modal.Header.Left>
            <Layout alignItems="center" display="flex" marginLeft="24px" maxWidth="560px" width="100%">
              <CommunicationChannelIcon
                communicationChannel={scheduledCampaign.communicationChannel}
                css={`
                  margin-right: 12px;
                `}
                isAvatar={false}
                size={18}
              />
              <EditableText
                aria-label={t('editCampaignName')}
                defaultValue={scheduledCampaign.name || undefined}
                emptyValue={toScheduledCampaignLabel(scheduledCampaign, communities)}
                onSave={handleSave}
                placeholder={t('enterCampaignName')}
                saveOnClickOutside
                typographyProps={{ fontSize: '18px', fontWeight: 700 }}
              />
            </Layout>
          </Modal.Header.Left>
          <Modal.Header.Center />
          <Modal.Header.Right onClose={handleClose}>
            <CloseIcon color={COLORS?.SUBTEXT} size={12} />
          </Modal.Header.Right>
        </Modal.Header>

        <StyledBody>
          <PhonePreview bubbles={bubbles} client={client} />

          <Typography color={COLORS?.SUBTEXT} marginTop="18px" padding="0 48px" textAlign="center" variant="body1">
            {scheduledCampaign.status === CampaignStatus.SCHEDULED &&
              `Will be sent on ${dayjs(scheduledCampaign.scheduledAt).format('LLLL')}${
                scheduledCampaign.recipientCount > 1
                  ? ` to ${toScheduledCampaignLabel(scheduledCampaign, communities)}`
                  : ''
              }. `}
            {scheduledCampaign.recurrence &&
              scheduledCampaign.status === CampaignStatus.SCHEDULED &&
              `${
                recurrenceParams?.frequency === RecurrenceFrequency.WEEKLY && recurrenceParams.day
                  ? t('compose.repeatsWeeklyOn', {
                      day: titleize(
                        Object.keys(RecurrenceDay)[Object.values(RecurrenceDay).indexOf(recurrenceParams.day)],
                      ),
                    })
                  : t('compose.repeatsDaily')
              }.`}
            {(scheduledCampaign.status === CampaignStatus.IN_PROGRESS ||
              scheduledCampaign.status === CampaignStatus.MEDIA_PROCESSING) &&
              t('campaigns.processingMedia')}
            {scheduledCampaign.status === CampaignStatus.MEDIA_TRANSCODING_FAILED && (
              <Trans i18nKey="campaigns.mediaTranscodingFailed">
                <StyledLink href="mailto:yourfriends@community.com" />
              </Trans>
            )}
            {(scheduledCampaign.status === CampaignStatus.FAILED ||
              scheduledCampaign.status === CampaignStatus.TEMPLATE_FAILED) &&
              t('campaigns.failedToSend')}
            {scheduledCampaign.status === CampaignStatus.TEMPLATE_REJECTED && (
              <Trans i18nKey="campaigns.templateRejectedHelp">
                <StyledLink href="mailto:yourfriends@community.com" />
              </Trans>
            )}
            {scheduledCampaign.status === CampaignStatus.TEMPLATE_PENDING && t('campaigns.templatePendingReview')}
            {scheduledCampaign.status === CampaignStatus.SUBMITTED_FOR_APPROVAL && (
              <>
                {`Scheduled for ${dayjs(scheduledCampaign.scheduledAt).format('LLLL')}. `}
                <StyledCard
                  icon={<WarningIcon color={COLORS.BADGE_WARNING_BACKGROUND} />}
                  text={t('scheduled.needsApprovalTooltip')}
                />
              </>
            )}
          </Typography>
        </StyledBody>

        <Modal.Footer>
          {isCampaignWriter && (
            <Layout display="flex">
              <Button onClick={() => setShowCancel(true)} variant={BUTTON_VARIANTS.OUTLINE}>
                {t('scheduled.cancelCampaign')}
              </Button>
              {needsApproval ? (
                <StyledApproveButton
                  onClick={() =>
                    mutateApproveCampaign({ clientId, campaignId: scheduledCampaign.id, approvalStatus: 'approve' })
                  }
                  variant={BUTTON_VARIANTS.INLINE_ACTION}
                >
                  <CheckIcon2 />
                  {t('scheduled.approveCampaign')}
                </StyledApproveButton>
              ) : null}
            </Layout>
          )}
        </Modal.Footer>
      </Modal>
      {showCancel && (
        <Dialog
          maxWidth={330}
          message={t(
            scheduledCampaign.recurrence ? 'scheduled.dialogContentRecurring' : 'scheduled.dialogContentScheduled',
          )}
          onCancel={() => setShowCancel(false)}
          title={t(
            scheduledCampaign.recurrence ? 'scheduled.dialogHeadingRecurring' : 'scheduled.dialogHeadingScheduled',
          )}
        >
          <Dialog.Action onClick={() => setShowCancel(false)}>{t('back')}</Dialog.Action>
          <Dialog.Action
            onClick={() => {
              mutateDeleteScheduledCampaign({ clientId, id: scheduledCampaign.id })
              setShowCancel(false)
            }}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            {t(
              scheduledCampaign.recurrence ? 'scheduled.deleteRecurringCampaign' : 'scheduled.deleteScheduledCampaign',
            )}
          </Dialog.Action>
        </Dialog>
      )}
    </>
  )
}
