import { Api } from '@community_dev/types'
import { OnboardingMessagesLabels } from '@community_dev/types/lib/api/OnboardingMessages'
import { useMemo } from 'react'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { useOnboardingMessage } from './useOnboardingMessage'

import { useIsReplyYOn } from 'hooks/useIsReplyYOn'
import { WELCOME_MESSAGE_KEY } from 'screens/Onboarding/constants'

export type OnboardingWelcomeMessageApi = CamelCasedPropertiesDeep<Api.OnboardingWelcomeMessage>

export type OnboardingWelcomeMessage = OnboardingWelcomeMessageApi & {
  legalMessage?: string
}

const withSpace = (text) => (text ? ` ${text}` : text)

export const useOnboardingWelcomeMessage = (withTransformations = true): OnboardingWelcomeMessage => {
  const welcomeMessage = useOnboardingMessage(WELCOME_MESSAGE_KEY)

  const isReplyYOn = useIsReplyYOn()
  const replyYLegalMessage = useOnboardingMessage(OnboardingMessagesLabels.REPLY_Y_ONBOARDING_LEGAL)

  const newWelcomeMessage = useMemo(() => {
    if (withTransformations) {
      return {
        ...welcomeMessage,
        // Reply Y clients have an onboarding legal message as part of their welcome message
        // Even though its 2 separate onboarding messages in the backend (welcomeMessage and reply y legal) it appears as 1 text bubble
        // We have to check if repl y is on because if it gets switched back to frost, we shouldn't show the legal message
        legalMessage: withSpace(isReplyYOn ? replyYLegalMessage?.text : undefined),
      }
    }
    return welcomeMessage
  }, [welcomeMessage, withTransformations, isReplyYOn, replyYLegalMessage]) as OnboardingWelcomeMessage

  return newWelcomeMessage
}
