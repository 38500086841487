import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import uniqBy from 'lodash/uniqBy'

import { Fan } from 'api/fans'
import { SearchTabType } from 'constants/messages'
import { useInboxMessages } from 'hooks/useInboxMessages'

function isFan(fan: Fan | Record<string, never>): fan is Fan {
  return Object.keys(fan).length > 0
}

export type UseRecentMembersProps = {
  communicationChannel?: CommunicationChannel
}

export function useRecentMembers({ communicationChannel }: UseRecentMembersProps): { members: Fan[] } {
  const { items = [] } = useInboxMessages({
    searchTab: SearchTabType.LATEST_MESSAGE,
    filters: communicationChannel
      ? [
          {
            field: 'search[communication_channel]',
            key: 'communication_channel',
            label: 'Communication Channel',
            value: communicationChannel,
          },
        ]
      : [],
  })

  const members = uniqBy(items, (item) => item.fan.id)
    .map((i) => i.fan)
    .filter(isFan)
    .slice(0, 5)

  return {
    members,
  }
}
