import { BORDER_RADIUS, BUTTON_VARIANTS, Button, SPACING } from '@community_dev/pixels'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { LEGACY_STARTER_PLAN_ID } from 'constants/plans'
import { ROUTES } from 'constants/routes'
import { useSubscription } from 'hooks/billing/useSubscription'
import { useTrackBilling } from 'hooks/billing/useTrackBilling'
import events from 'utils/analytics/events'

const StyledUpgradeBanner = styled(Link)`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  padding: ${SPACING[4]} ${SPACING[5]};
  border-radius: ${BORDER_RADIUS[1]};
  margin: ${SPACING[5]};
  flex-direction: column;
  text-decoration: none;
`

const StyledUpgradeText = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  text-align: center;
  padding-bottom: 12px;
`

export type UpgradeBannerProps = {
  collapsed: boolean
}

export const UpgradeBanner = ({ collapsed }: UpgradeBannerProps): JSX.Element | null => {
  const { data } = useSubscription()
  const isStarter = LEGACY_STARTER_PLAN_ID === data?.subscription.planId
  const track = useTrackBilling()
  if (collapsed || !isStarter) {
    return null
  }

  return (
    <StyledUpgradeBanner onClick={() => track(events.UpgradeCTAClicks())} to={ROUTES.PRICING.CONTACT_US}>
      <StyledUpgradeText>Get the most out of Community.</StyledUpgradeText>
      <Button variant={BUTTON_VARIANTS.OUTLINE}>Upgrade Now</Button>
    </StyledUpgradeBanner>
  )
}
