import {
  BUTTON_VARIANTS,
  Button,
  Filter,
  ListItem,
  LoadingIndicator,
  PlusCircleIcon,
  SPACING,
  SearchBar,
} from '@community_dev/pixels'
import escapeStringRegexp from 'escape-string-regexp'
import noop from 'lodash/noop'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { useUpdatingCommunities } from './useUpdatingCommunities'

import { Community } from 'api/community'
import { Fan } from 'api/fans'
import { CommunityDot } from 'components/CommunityDot'
import { CreateCommunity } from 'components/CreateCommunity'
import { VirtualList, VirtualListHandle } from 'components/VirtualList'
import { COMMUNITY_SORT_BY_MEMBER_COUNT } from 'constants/launch-darkly-flags'
import { useCampaign } from 'hooks/useCampaign'
import { userHasFlag } from 'hooks/useLaunchDarkly'
import { formatLargeNumber } from 'utils/number'

const StyledMeta = styled.div`
  padding: 0 ${SPACING[4]} 0;
`

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${SPACING[2]} 0 ${SPACING[4]};
`

const StyledNoResults = styled.p`
  text-align: center;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  margin: 0;
`

const StyledAdd = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`

const StyledAddSuffix = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: ${SPACING[4]};
`

const StyledFilters = styled.div`
  margin-bottom: ${SPACING[2]};
`

const StyledVirtualList = styled(VirtualList)`
  margin: 0;
  overflow: auto;
`

const StyledHeading = styled.h3`
  margin: 0;
`

const StyledButton = styled(Button).attrs({ variant: BUTTON_VARIANTS.INLINE_ACTION })<{ $color?: string }>`
  min-width: 72px;
  min-height: 24px;
  background-color: ${({ $color }) => $color};
`

enum SortField {
  COUNT = 'count',
  DATE = 'date',
  NAME = 'name',
}

enum SortDirection {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

type AddToCommunitiesProps = {
  fan?: Fan
  checkUserAdded?: (community: Community) => boolean
  onAdd(community: Community, fanSubscriptionTags?: any): void
  onRemove?: (community: Community) => void
  onCommunityCreated?(community: Community, fanSubscriptionTags?: any): void
  showHeader?: boolean
  addedCommunities?: {
    [communityId: string]: boolean
  }
}

export function AddToCommunities({
  fan,
  checkUserAdded: checkMemberAdded,
  onAdd,
  onRemove,
  onCommunityCreated = noop,
  showHeader = true,
  addedCommunities: addedCommunitiesProp,
}: AddToCommunitiesProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const virtualRef = useRef<VirtualListHandle>(null)
  const isSortByMemberCountEnabled = userHasFlag(COMMUNITY_SORT_BY_MEMBER_COUNT)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortField, setSortField] = useState<SortField>(SortField.NAME)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASCENDING)
  const { isLoading, communities } = useUpdatingCommunities()
  const { campaignId: threadId }: any = useParams()
  const { fanSubscriptionTags } = useCampaign({
    campaignId: threadId,
    options: {
      communities: [...(communities?.custom || []), ...(communities?.default || [])],
    },
  })

  const up = sortDirection === 'ascending' ? 1 : -1
  const down = -up

  const addedCommunities = useMemo(
    () =>
      fan?.communities
        ? fan?.communities.reduce((acc, community) => {
            return { ...acc, [community.id]: true }
          }, {})
        : [],
    [fan?.communities],
  )

  function onSearchChange(event) {
    setSearchTerm(event.target.value)
  }

  const filteredItems = communities?.custom
    ?.filter((community) =>
      searchTerm ? new RegExp(escapeStringRegexp(searchTerm), 'ig').test(community.title) : true,
    )
    .sort((a, b) => {
      switch (sortField) {
        case 'name':
          if (a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase()) return down
          return a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() ? up : 0
        case 'date':
          if (a.insertedAt < b.insertedAt) return down
          return a.insertedAt > b.insertedAt ? up : 0
        case 'count':
          if (a?.fanCount !== undefined && b?.fanCount !== undefined && a.fanCount < b.fanCount) return down
          return a?.fanCount !== undefined && b?.fanCount !== undefined && a.fanCount > b.fanCount ? up : 0
        default:
          return 0
      }
    })

  useEffect(() => {
    virtualRef.current?.measure()
  }, [communities?.custom, filteredItems])

  const sortOptions = useMemo(() => {
    let options = [
      { value: SortField.NAME, label: t('communities.name') },
      { value: SortField.DATE, label: t('communities.dateCreated') },
    ]
    if (isSortByMemberCountEnabled) {
      options = options.concat({
        value: SortField.COUNT,
        label: t('communities.memberCount'),
      })
    }
    return options
  }, [t, isSortByMemberCountEnabled])
  const activeFilter = sortOptions.find((option) => option.value === sortField) || sortOptions[0]

  return (
    <>
      <StyledMeta>
        {showHeader && (
          <StyledHeader>
            <StyledHeading>
              {t('addToCommunity_one', {
                count: 0,
              })}
            </StyledHeading>
            <CreateCommunity onCreate={(community) => onCommunityCreated(community, fanSubscriptionTags)}>
              {({ toggleOpen }) => (
                <StyledAdd aria-label={t('communities.addCommunity')} onClick={toggleOpen}>
                  <PlusCircleIcon color={COLORS?.BUTTON_PRIMARY_TEXT} fill={COLORS?.BUTTON_PRIMARY} size={32} />
                </StyledAdd>
              )}
            </CreateCommunity>
          </StyledHeader>
        )}
        <StyledFilters>
          <SearchBar onChange={onSearchChange} placeholder={t('communities.searchCommunities')} value={searchTerm} />
          <Filter activeFilter={activeFilter.label} icon={false} label={t('communities.sortBy')} width="300px">
            {sortOptions.map((option) => (
              <Filter.Option onClick={() => setSortField(option.value)} selected={sortField === option.value}>
                {option.label}
              </Filter.Option>
            ))}
            <Filter.Sort direction={sortDirection} onClick={setSortDirection} />
          </Filter>
        </StyledFilters>
      </StyledMeta>
      {isLoading || !filteredItems ? (
        <LoadingIndicator />
      ) : filteredItems.length ? (
        <StyledVirtualList ref={virtualRef} rows={filteredItems} testId="communities-list">
          {({ virtualRow }) => {
            const community = filteredItems[virtualRow.index]
            const { id, color, title, updateInProgress } = community
            let hasBeenAdded = addedCommunitiesProp ? addedCommunitiesProp[id] : addedCommunities[id]
            if (!hasBeenAdded && checkMemberAdded) {
              hasBeenAdded = checkMemberAdded(community)
            }
            const activeMemberCount = t('communities.activeMembers', {
              count: community?.fanCount,
              total: formatLargeNumber(community?.fanCount || 0),
            })
            const subtext = updateInProgress
              ? t('communities.addingMembers')
              : community?.fanCount !== undefined
              ? activeMemberCount
              : ''

            function handleClickAdd() {
              onAdd(community, fanSubscriptionTags)
            }

            return (
              <ListItem
                as="div"
                data-testid={`community-item-${id}`}
                label={title}
                prefix={<CommunityDot color={color} />}
                subtext={subtext}
                suffix={
                  <StyledAddSuffix>
                    {hasBeenAdded ? (
                      onRemove ? (
                        <StyledButton $color={COLORS?.ERRORS} onClick={() => onRemove(community)}>
                          {t('communities.remove')}
                        </StyledButton>
                      ) : (
                        <StyledButton disabled>{t('communities.added')}</StyledButton>
                      )
                    ) : (
                      <StyledButton $color={COLORS?.LINKS} onClick={handleClickAdd}>
                        {t('communities.add')}
                      </StyledButton>
                    )}
                  </StyledAddSuffix>
                }
              />
            )
          }}
        </StyledVirtualList>
      ) : (
        <StyledNoResults>No results for "{searchTerm}"</StyledNoResults>
      )}
    </>
  )
}
