import {
  BuiltInFields,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  RelationshipOperators,
  SelectorOperators,
  createRelationshipNode,
  fieldLabelFor,
  parseFilters,
  serializeFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { DIALOG_VARIANTS, Dialog, SPACING } from '@community_dev/pixels'
import { ActionButton } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import compact from 'lodash/compact'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { useRepliedClusters } from './useRepliedClusters'

import { getMatchingClusters } from 'api/cluster'
import { FavoriteFilterActionButton } from 'components/ActionPanel'
import { ComposeMessage } from 'components/ComposeMessage'
import { QUERY_CACHE } from 'constants/query-cache'
import { useCampaignResponseFilterState } from 'contexts/CampaignResponseFilterProvider'
import { useCurrentCampaignDetails } from 'hooks/useCampaign'
import { useClient, useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import analytics from 'utils/analytics'
import { unwrapSingleFilter } from 'utils/memberDataFilters/memberDataFiltersUtils'

const StyledContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: ${SPACING[4]};
`

type SelectionActionButtonsProps = {
  communicationChannel?: CommunicationChannel
  onClickAddToCommunity(): void
}

export function SelectionActionButtons({
  onClickAddToCommunity,
  communicationChannel,
}: SelectionActionButtonsProps): JSX.Element {
  const { data: client } = useClient()
  const clientId = useClientId()
  const hasSelectedCommunicationChannel = client?.communicationChannels?.some(
    (channel) => channel === communicationChannel,
  )
  const queryClient = useQueryClient()
  const { filters: filtersFromFilterState } = useCampaignResponseFilterState()
  const [isUnrepliedSelected, setIsUnrepliedSelected] = useState(false)
  const [isComposeModalOpen, setIsComposeModalOpen] = useState(false)
  const [showMixedSelectionDialog, setShowMixedSelectionDialog] = useState(false)
  const { data: campaignDetails } = useCurrentCampaignDetails()
  const { nestLevel: level = 0 } = campaignDetails || {}
  const { showToastMessage } = useToastMessage()
  const repliedClusters = useRepliedClusters()
  const hasSomePartiallyRepliedClusters = repliedClusters.partially_replied
  const hasUnrepliedClusters = repliedClusters.unreplied
  const hasRepliedClusters = repliedClusters.replied
  const { campaignId } = useParams<{ campaignId: string }>()

  useQuery([QUERY_CACHE.CLUSTER.LIST, { clientId, campaignId }], () =>
    getMatchingClusters({
      clientId,
      campaignId,
      tagsAll: '',
      tagsNot: '',
    }),
  )

  const handleSuccessfulSend = useCallback((): void => {
    queryClient.invalidateQueries([QUERY_CACHE.CLUSTER.LIST])
    queryClient.invalidateQueries([QUERY_CACHE.CAMPAIGN.RESPONSES])
    queryClient.invalidateQueries([QUERY_CACHE.CLUSTER.DETAILS])
  }, [campaignId, clientId, queryClient])

  function openComposeModal() {
    setIsComposeModalOpen(true)
  }

  function handleClickMessage() {
    if (level > 2) {
      showToastMessage({
        message: 'Thread Limit Reached. Try sending a direct message to these Members instead.',
        success: false,
      })

      return
    }

    if (hasSomePartiallyRepliedClusters || (hasUnrepliedClusters && hasRepliedClusters)) {
      setShowMixedSelectionDialog(true)

      analytics.track(analytics.events.MixedSelectionDialogDisplayed())

      return
    }

    openComposeModal()
  }

  function handleCloseComposeMessage() {
    setIsComposeModalOpen(false)
    setIsUnrepliedSelected(false)

    analytics.track(analytics.events.MixedSelectionDialogClosed())
  }

  function handleSendToAllRecipients() {
    analytics.track(
      analytics.events.MixedSelectionOptionChosen({
        option: 'All',
      }),
    )

    setShowMixedSelectionDialog(false)
    openComposeModal()
  }

  function handleSendToOnlyUnreplied() {
    analytics.track(
      analytics.events.MixedSelectionOptionChosen({
        option: 'Only Unreplied',
      }),
    )

    setShowMixedSelectionDialog(false)
    setIsUnrepliedSelected(true)
    openComposeModal()
  }

  const includedFilters = useMemo(() => {
    if (isUnrepliedSelected) {
      const filtersFromFilterStateAst = filtersFromFilterState ? parseFilters(filtersFromFilterState) : undefined
      const campaignRespondersRepliedToAst = parseFilters({
        operands: [
          {
            operator: SelectorOperators.QUERY,
            operand: {
              field_key: BuiltInFields.CAMPAIGN_RESPONDERS_REPLIED_TO,
              field_label: fieldLabelFor(BuiltInFields.CAMPAIGN_RESPONDERS_REPLIED_TO),
              source: FieldSources.BUILT_IN,
              type: FieldTypes.UUID,
              value: campaignId,
            },
          },
        ],
        operator: RelationshipOperators.NOT,
      })

      return unwrapSingleFilter(
        serializeFilters(
          createRelationshipNode(
            RelationshipOperators.AND,
            ...compact([filtersFromFilterStateAst, campaignRespondersRepliedToAst]),
          ),
        ) as MemberDataFilter,
      )
    }

    return filtersFromFilterState
  }, [campaignId, filtersFromFilterState, isUnrepliedSelected])

  return (
    <>
      <StyledContent data-testid="action-buttons">
        <ActionButton
          disabled={!hasSelectedCommunicationChannel}
          icon="comment"
          id="detail-pane-message"
          label="message"
          onClick={handleClickMessage}
        />
        <ActionButton
          disabled={
            // Apple Messages does not support adding to community
            communicationChannel === CommunicationChannel.AMB
          }
          icon="plus"
          id="detail-pane-add-to"
          label="add to"
          onClick={onClickAddToCommunity}
        />
        <FavoriteFilterActionButton
          disabled={
            // Apple Messages does not support adding to community
            communicationChannel === CommunicationChannel.AMB
          }
        />
        {isComposeModalOpen && (
          <ComposeMessage
            dynamicFields={false}
            initialFilterState={{
              communicationChannel,
              includedFilters,
              excludedFilters: null,
            }}
            isSentReply
            onClose={handleCloseComposeMessage}
            onSuccessfulSend={handleSuccessfulSend}
            readOnlyRecipients
            respondingToCampaign={campaignId}
            shouldDisableScheduleMessages
            shouldRedirect={false}
          />
        )}
      </StyledContent>
      {showMixedSelectionDialog && (
        <Dialog
          maxWidth={349}
          message="Your selection contains messages you’ve already replied to. Do you want to reply to them again?"
          onCancel={() => setShowMixedSelectionDialog(false)}
          title="Reply to your Members again?"
        >
          <Dialog.Action onClick={handleSendToAllRecipients}>Yes, include all</Dialog.Action>
          <Dialog.Action onClick={handleSendToOnlyUnreplied} variant={DIALOG_VARIANTS.EMPHASIZED}>
            No, include only unreplied
          </Dialog.Action>
        </Dialog>
      )}
    </>
  )
}
