import { useCallback, useMemo } from 'react'

import { CapabilitiesAndFeatureFlags } from 'api/addOns'
import { useCapabilities } from 'contexts/bootstrap/useCapabilities'
import { useLaunchDarklyContext } from 'contexts/LaunchDarklyProvider'

type HasCapabilitiesAndFeatureFlags = (capabilitiesAndFeatureFlags?: CapabilitiesAndFeatureFlags) => boolean

export const useHasCapabilitiesAndFeatureFlagsFn = (): HasCapabilitiesAndFeatureFlags => {
  const allCapabilities = useCapabilities()
  const launchDarklyClient = useLaunchDarklyContext()

  return useCallback(
    ({ capabilities = [], featureFlags = [] } = {}) => {
      const allFlags = launchDarklyClient?.allFlags() || {}
      const hasAllCapabilities = capabilities.every((capability) => allCapabilities?.includes(capability))
      const hasAllFeatureFlags = featureFlags.every((flag) => allFlags[flag] === true)

      return hasAllCapabilities && hasAllFeatureFlags
    },
    [allCapabilities, launchDarklyClient],
  )
}

export const useHasCapabilitiesAndFeatureFlags = ({
  capabilities = [],
  featureFlags = [],
}: CapabilitiesAndFeatureFlags): boolean => {
  const hasCapabilitiesAndFeatureFlagsFn = useHasCapabilitiesAndFeatureFlagsFn()
  return useMemo(
    () => hasCapabilitiesAndFeatureFlagsFn({ capabilities, featureFlags }),
    [capabilities, featureFlags, hasCapabilitiesAndFeatureFlagsFn],
  )
}
