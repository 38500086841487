import {
  BirthdayMatch,
  BuiltInFields,
  CommunityIncludesSingleMatch,
  CustomMemberDataMatch,
  FilterAst,
  findOne,
  isSelectorFilter,
  MemberDataFilter,
  parseFilters,
  SubscriberIncludesSingleMatch,
  visitFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useCallback, useMemo } from 'react'

export type FilterSupportMatrix = Record<BuiltInFields, FilterSupport>

/** extend if new communication channels become available */
export type CommunicationChannelSupport = {
  [CommunicationChannel.SMS]: boolean
  [CommunicationChannel.WHATS_APP]: boolean
  [CommunicationChannel.AMB]: boolean
}
export type FilterSupport = {
  /**
   * list of filters it cannot be combined with
   */
  mutualExclusion: BuiltInFields[]
  /**
   * which communication channels support the filter
   */
  communicationChannel: CommunicationChannelSupport
  /**
   * if filter supports scheduled campaigns
   */
  scheduling: boolean
}

export type useFilterSupportMatrixValue = {
  filterSupportMatrix: FilterSupportMatrix
  isFilterEnabled: (fieldKey: BuiltInFields, filter?: MemberDataFilter | null) => boolean
  isFilterSupported: (fieldKey: BuiltInFields) => boolean
  siblingsHaveAnyRequiredFilterType: (node: FilterAst | null) => boolean
  hasAnyRequiredFilterType: (nodes: FilterAst | FilterAst[] | null) => boolean
  supportsScheduling: (filter?: MemberDataFilter | null) => boolean
}

type FilterMatcher = {
  operand: {
    field_key: BuiltInFields
  }
}

const defaultRequiredFilters = {
  [CommunicationChannel.AMB]: [CommunityIncludesSingleMatch, SubscriberIncludesSingleMatch, BirthdayMatch],
  [CommunicationChannel.SMS]: [],
  [CommunicationChannel.WHATS_APP]: [],
}

export function useFilterSupportMatrix({
  communicationChannel,
  customFilterSupportMatrix,
  requiredFilters = defaultRequiredFilters,
}: {
  communicationChannel: CommunicationChannel
  customFilterSupportMatrix?: FilterSupportMatrix
  requiredFilters?: { [key in CommunicationChannel]: FilterMatcher[] }
}): useFilterSupportMatrixValue {
  const filterSupportMatrix: FilterSupportMatrix = useMemo(
    () =>
      customFilterSupportMatrix || {
        [BuiltInFields.AGE]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.BIRTHDAY]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.CAMPAIGN_RECIPIENTS]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: false,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.CAMPAIGN_RESPONDERS]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: false,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.CAMPAIGN_RESPONDERS_CLUSTER]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.CAMPAIGN_RESPONDERS_REPLIED_TO]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.CITY]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
            BuiltInFields.TIME_ZONE_GROUP,
          ],
          scheduling: true,
        },
        [BuiltInFields.COUNTRY]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
            BuiltInFields.TIME_ZONE_GROUP,
          ],
          scheduling: true,
        },
        [BuiltInFields.COUNTRY_CODE]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
            BuiltInFields.TIME_ZONE_GROUP,
          ],
          scheduling: true,
        },
        [BuiltInFields.EVERYONE]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: false,
          },
          mutualExclusion: [
            BuiltInFields.AGE,
            BuiltInFields.BIRTHDAY,
            BuiltInFields.CAMPAIGN_RECIPIENTS,
            BuiltInFields.CAMPAIGN_RESPONDERS,
            BuiltInFields.CITY,
            BuiltInFields.COUNTRY_CODE,
            BuiltInFields.COUNTRY,
            BuiltInFields.GENDER_IDENTITY,
            BuiltInFields.JOINED,
            BuiltInFields.LOCATION,
            BuiltInFields.MESSAGE_TEXT,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.NAME,
            BuiltInFields.NEW_MEMBER,
            BuiltInFields.STATE_CODE,
            BuiltInFields.STATE,
            BuiltInFields.SUBSCRIPTION_ID,
            BuiltInFields.TAG_ID,
            BuiltInFields.TIME_ZONE_GROUP,
            BuiltInFields.UNRESPONSIVE_CAMPAIGN_RECIPIENTS,
          ],
          scheduling: true,
        },
        [BuiltInFields.FULL_TEXT_SEARCH]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.AGE,
            BuiltInFields.BIRTHDAY,
            BuiltInFields.CAMPAIGN_RECIPIENTS,
            BuiltInFields.CAMPAIGN_RESPONDERS,
            BuiltInFields.CITY,
            BuiltInFields.COUNTRY_CODE,
            BuiltInFields.COUNTRY,
            BuiltInFields.EVERYONE,
            BuiltInFields.GENDER_IDENTITY,
            BuiltInFields.JOINED,
            BuiltInFields.LOCATION,
            BuiltInFields.MESSAGE_TEXT,
            BuiltInFields.NAME,
            BuiltInFields.NEW_MEMBER,
            BuiltInFields.STATE_CODE,
            BuiltInFields.STATE,
            BuiltInFields.TAG_ID,
            BuiltInFields.TIME_ZONE_GROUP,
            BuiltInFields.UNRESPONSIVE_CAMPAIGN_RECIPIENTS,
          ],
          scheduling: true,
        },
        [BuiltInFields.GENDER_IDENTITY]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.JOINED]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.LINK_HIT]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: false,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: false,
        },
        [BuiltInFields.LOCATION]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: false,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.AGE,
            BuiltInFields.BIRTHDAY,
            BuiltInFields.CAMPAIGN_RECIPIENTS,
            BuiltInFields.CAMPAIGN_RESPONDERS,
            BuiltInFields.CITY,
            BuiltInFields.COUNTRY_CODE,
            BuiltInFields.COUNTRY,
            BuiltInFields.EVERYONE,
            BuiltInFields.GENDER_IDENTITY,
            BuiltInFields.JOINED,
            BuiltInFields.LOCATION,
            BuiltInFields.MESSAGE_TEXT,
            BuiltInFields.NAME,
            BuiltInFields.NEW_MEMBER,
            BuiltInFields.STATE_CODE,
            BuiltInFields.STATE,
            BuiltInFields.SUBSCRIPTION_ID,
            BuiltInFields.TAG_ID,
            BuiltInFields.TIME_ZONE_GROUP,
            BuiltInFields.UNRESPONSIVE_CAMPAIGN_RECIPIENTS,
          ],
          scheduling: false,
        },
        [BuiltInFields.NAME]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.MESSAGE_TEXT]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: false,
            [CommunicationChannel.AMB]: false,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.NEW_MEMBER]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: false,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.STATE]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
            BuiltInFields.TIME_ZONE_GROUP,
          ],
          scheduling: true,
        },
        [BuiltInFields.STATE_CODE]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
            BuiltInFields.TIME_ZONE_GROUP,
          ],
          scheduling: true,
        },
        [BuiltInFields.SUBSCRIPTION_ID]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.AGE,
            BuiltInFields.BIRTHDAY,
            BuiltInFields.CITY,
            BuiltInFields.COUNTRY_CODE,
            BuiltInFields.COUNTRY,
            BuiltInFields.EVERYONE,
            BuiltInFields.GENDER_IDENTITY,
            BuiltInFields.JOINED,
            BuiltInFields.LOCATION,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.NEW_MEMBER,
            BuiltInFields.STATE_CODE,
            BuiltInFields.STATE,
            BuiltInFields.TAG_ID,
            BuiltInFields.TIME_ZONE_GROUP,
          ],
          scheduling: true,
        },
        [BuiltInFields.TAG_ID]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.TIME_ZONE_GROUP]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.CITY,
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: true,
        },
        [BuiltInFields.UNRESPONSIVE_CAMPAIGN_RECIPIENTS]: {
          communicationChannel: {
            [CommunicationChannel.SMS]: true,
            [CommunicationChannel.WHATS_APP]: true,
            [CommunicationChannel.AMB]: true,
          },
          mutualExclusion: [
            BuiltInFields.EVERYONE,
            BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
            BuiltInFields.SUBSCRIPTION_ID,
          ],
          scheduling: false,
        },
      },
    [customFilterSupportMatrix],
  )

  const supportsScheduling = useCallback(
    (filter?: MemberDataFilter | null): boolean => {
      if (!filter) return true

      const hasCMDFilter = findOne(parseFilters(filter), CustomMemberDataMatch)

      if (hasCMDFilter) return true

      const selectorFilters = Array.from(visitFilters(filter)).filter(isSelectorFilter)

      return selectorFilters.every((filter) => filterSupportMatrix[filter.operand.field_key]?.scheduling)
    },
    [filterSupportMatrix],
  )

  const hasAnyRequiredFilterType = useCallback(
    (filters: FilterAst | FilterAst[] | null) => {
      if (requiredFilters[communicationChannel].length === 0) {
        return true
      } else {
        const filtersToCheck = Array.isArray(filters) ? filters : [filters]
        return Boolean(
          filtersToCheck?.find((filter) =>
            requiredFilters[communicationChannel].find((matcher) => findOne(filter, matcher)),
          ),
        )
      }
    },
    [communicationChannel, requiredFilters],
  )

  const siblingsHaveAnyRequiredFilterType = useCallback(
    (node: FilterAst | null): boolean => {
      const siblings = node?.parent?.children.filter((n) => n !== node) || null
      return hasAnyRequiredFilterType(siblings)
    },
    [hasAnyRequiredFilterType],
  )

  const isFilterSupported = useCallback(
    (fieldKey: BuiltInFields): boolean => {
      return filterSupportMatrix[fieldKey].communicationChannel[communicationChannel] === true
    },
    [communicationChannel, filterSupportMatrix],
  )

  const isFilterEnabled = useCallback(
    (fieldKey: BuiltInFields, filter?: MemberDataFilter | null): boolean => {
      if (!filter) {
        return (
          requiredFilters[communicationChannel].length === 0 ||
          requiredFilters[communicationChannel].some((matcher) => matcher.operand.field_key === fieldKey)
        )
      } else {
        const selectorFilters = Array.from(visitFilters(filter)).filter(isSelectorFilter)
        return selectorFilters.every(
          (filter) =>
            !filterSupportMatrix[fieldKey].mutualExclusion.includes(filter.operand.field_key as BuiltInFields),
        )
      }
    },
    [communicationChannel, filterSupportMatrix, requiredFilters],
  )
  return {
    filterSupportMatrix,
    isFilterEnabled,
    isFilterSupported,
    hasAnyRequiredFilterType,
    siblingsHaveAnyRequiredFilterType,
    supportsScheduling,
  }
}
