import { Button, BUTTON_VARIANTS, ChevronRightIcon, ProgressBar, SPACING, Typography } from '@community_dev/pixels'
import { STATUS } from '@community_dev/types/lib/api/v2/TCR'
import isNumber from 'lodash/isNumber'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'

import { ContentWrapper, Description, StyledSettings, Subtitle } from './styled'

import { CarrierLimitsTable } from 'components/CarrierLimitsTable'
import { ROUTES } from 'constants/routes'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useTCRContext } from 'contexts/TCRProvider'
import { useClient, useClientId } from 'hooks/useClient'
import { useMessagesLimits } from 'hooks/useMessagesLimits'
import { Setting, SettingsSection } from 'layouts/SettingsLayout'
import analytics from 'utils/analytics'
import { pluralizeNumeral } from 'utils/general'

const SUPPORT_EMAIL = 'yourfriends@community.com'

export function MessageLimitRow(): JSX.Element {
  const { COLORS } = useTheme() || {}
  const history = useHistory()
  const { t } = useTranslation(undefined, { keyPrefix: 'settings.billing.messageLimits' })
  const { data: client } = useClient()
  const clientId = useClientId()
  const { data: seat } = useSeat()
  const { hasLimitEnabled, monthlyRemaining, percentageUsed } = useMessagesLimits({})
  const { isSuccess, status, recurringFee } = useTCRContext()
  const showCarrierLimits = isSuccess && status === STATUS.APPROVED && client?.phoneNumberType === 'ten_dlc'
  const showMessageLimits = hasLimitEnabled && client?.phoneNumberType === 'ten_dlc'

  const messagesLimits = () => {
    history.push(ROUTES.SETTINGS.MESSAGE_LIMITS)
  }

  const subject = t('mailSubject')
  const body = t('mailBody', {
    clientId: clientId,
    clientName: client?.fullName,
    seatName: `${seat?.firstName} ${seat?.lastName}`,
    seatEmail: seat?.email,
  })
  const mailLink = `mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
  const trackSegmentClick = () => analytics.track(analytics.events.TCR.GetMoreSegments())

  const tcrMonthlyFee = useMemo(
    () => (recurringFee / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    [recurringFee],
  )

  if (!showMessageLimits && !showCarrierLimits) {
    return <></>
  }

  return (
    <SettingsSection>
      <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
        Message Limits
      </Typography>
      {showMessageLimits && (
        <StyledSettings
          action={
            <>
              View Details <ChevronRightIcon color={COLORS?.SUBTEXT} size={18} />
            </>
          }
          label={
            <ContentWrapper>
              <ProgressBar percentFilled={percentageUsed} width={125} />
              <Subtitle>
                {isNumber(monthlyRemaining) &&
                  pluralizeNumeral(monthlyRemaining.toString(), '0,0', 'message', 'messages')}{' '}
                left this month
              </Subtitle>
              <Description>Your monthly message limit will reset on the first of the month</Description>
            </ContentWrapper>
          }
          onClickAction={messagesLimits}
        />
      )}
      {showCarrierLimits && (
        <>
          <Subtitle>{t('segmentTitle')}</Subtitle>
          <CarrierLimitsTable />
          <Setting
            label={t('registrationTitle')}
            text={t('cost', {
              amount: tcrMonthlyFee,
            })}
          >
            <Button forwardedAs="a" href={mailLink} onClick={trackSegmentClick} variant={BUTTON_VARIANTS.OUTLINE}>
              {t('getMoreSegments')}
            </Button>
          </Setting>
        </>
      )}
    </SettingsSection>
  )
}
