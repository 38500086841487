import { InfoIconFilled, Typography, SPACING, FONT_WEIGHT } from '@community_dev/pixels'
import { transparentize } from 'polished'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { toWorkflowStatus } from 'components/WorkflowEditor/utils/toWorkflowStatus'
import { DATE_INPUT_FORMAT, TIME_INPUT_FORMAT } from 'constants/date'
import { WorkflowStatusTypes } from 'screens/WorkflowsScreen/WorkflowStatus'
import dayjs from 'utils/dayjs'

const StyledContainer = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  white-space: nowrap;
  background-color: ${({ $isActive, theme }) =>
    $isActive
      ? transparentize(0.92, theme?.COLORS?.WORKFLOW_EDITOR_ACTIVE_STATUSBAR_BACKGROUND)
      : theme?.COLORS?.WORKFLOW_EDITOR_PAUSED_STATUSBAR_BACKGROUND};
`

export const WorkflowStatusbar = (): JSX.Element => {
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const { workflow } = useWorkflowProvider()
  const isActive = toWorkflowStatus(workflow) === WorkflowStatusTypes.active

  const isScheduledToPause = useMemo(() => {
    if (workflow.active_end_at) return dayjs(workflow.active_end_at).isAfter(dayjs())
  }, [workflow.active_end_at])
  const scheduledToPause = useMemo(() => {
    if (!isScheduledToPause) return ''
    const dateTime = dayjs(workflow.active_end_at)
    return t('automations.scheduled.scheduledToPauseOn', {
      date: dateTime.format(DATE_INPUT_FORMAT),
      time: dateTime.format(TIME_INPUT_FORMAT),
    })
  }, [isScheduledToPause, t, workflow.active_end_at])
  const textColor = isActive
    ? COLORS.WORKFLOW_EDITOR_ACTIVE_STATUSBAR_TEXT
    : COLORS.WORKFLOW_EDITOR_PAUSED_STATUSBAR_TEXT
  return (
    <StyledContainer $isActive={isActive}>
      <InfoIconFilled color={textColor} size={24} />
      <Typography color={textColor} fontWeight={FONT_WEIGHT[5]} marginLeft={SPACING[2]} variant="body2">
        {isActive ? (isScheduledToPause ? scheduledToPause : t('automations.activeFlow')) : t('automations.pausedFlow')}
      </Typography>
    </StyledContainer>
  )
}
