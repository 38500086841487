import { useMemo } from 'react'

import { FormstackApiButton } from './FormstackApiButton'
import { LinkButton } from './LinkButton'
import { RouteButton } from './RouteButton'

import { AddOnAction, FormstackAction, LinkAction, RouteAction } from 'api/addOns'
import { AddOnsActionType } from 'hooks/useAddons/constants'
import { useHasCapabilitiesAndFeatureFlagsFn } from 'hooks/useAddons/useHasCapabilitiesAndFeatureFlags'

const useReduceAction = (action: AddOnAction): RouteAction | LinkAction | FormstackAction => {
  const hasCapabilitiesAndFeatureFlagsFn = useHasCapabilitiesAndFeatureFlagsFn()

  return useMemo(() => {
    // If its of type conditional we check for the flags and capabilities
    if (action.type === AddOnsActionType.conditional) {
      const { whenTrue, whenFalse, capabilities, featureFlags } = action
      return hasCapabilitiesAndFeatureFlagsFn({
        capabilities,
        featureFlags,
      })
        ? { ...whenTrue }
        : { ...whenFalse }
    }

    return action
  }, [action, hasCapabilitiesAndFeatureFlagsFn])
}

type DynamicButtonProps = AddOnAction & {
  className?: string
}

export const DynamicButton = (action: DynamicButtonProps): JSX.Element => {
  const reducedAction = useReduceAction(action)

  if (reducedAction.type === AddOnsActionType.route)
    return <RouteButton className={action.className} {...reducedAction} />
  if (reducedAction.type === AddOnsActionType.link)
    return <LinkButton className={action.className} {...reducedAction} />
  if (reducedAction.type === AddOnsActionType.formstack)
    return <FormstackApiButton className={action.className} {...reducedAction} />

  return <></>
}
