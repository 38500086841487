import { HTTP } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'

export function postMfaCode({
  totp,
  token,
}: {
  totp: string
  token?: string
}): Promise<{ token: string; clientId: string }> {
  return request(ENDPOINTS.LOGIN.MFA, {
    method: HTTP.POST,
    token,
    body: { totp },
    skipAuthCheck: true,
  })
}
