import { SelectorFilter, SelectorOperators, FieldDefinition } from '@community_dev/filter-dsl/lib/subscription-data'
import { Select } from '@community_dev/pixels'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

type BooleanFieldProps = {
  field: FieldDefinition
}

type SelectOption = {
  label: string
  value: string
}

export function BooleanField({ field }: BooleanFieldProps): JSX.Element {
  const { t } = useTranslation(undefined, { keyPrefix: 'customData' })
  const options = [
    {
      label: t('true'),
      value: 'true',
    },
    {
      label: t('false'),
      value: 'false',
    },
  ]

  const validateBoolean = (value: SelectorFilter): string | void => {
    if (!value?.operand?.value) {
      return 'Boolean value is invalid'
    }
  }

  return (
    <Field name={`custom-field-${field.key}`} validate={validateBoolean}>
      {({ input }) => {
        const handleSelectChange = (option: SelectOption | null): void => {
          if (option) {
            input.onChange({
              operator: SelectorOperators.EQUALS,
              operand: {
                field_key: field.key,
                field_label: field.name,
                source: field.source,
                type: field.value_type,
                value: option.value,
              },
            } as SelectorFilter)
          }
        }

        const selectedOption = options.find((option) => option.value === input?.value?.operand?.value)

        return <Select inline onChange={handleSelectChange} options={options} value={selectedOption} />
      }}
    </Field>
  )
}
