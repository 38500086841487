import { CountryCode, countries as allCountries } from '@community_dev/location-data'

type Country = {
  name: string
  abbrev: string
  code: CountryCode
  lookups: string[]
}

export const countryUsa: Country = {
  name: 'United States',
  abbrev: 'USA',
  code: 'US',
  lookups: ['United States', 'Estados Unidos', 'USA', 'America'],
}

export const countryCanada: Country = { name: 'Canada', abbrev: 'CA', code: 'CA', lookups: ['CA', 'Canada'] }

export const countries: Country[] = allCountries.map((country) => {
  if (country.abbrev === 'US') {
    return countryUsa
  } else {
    return {
      name: country.name,
      abbrev: country.abbrev,
      code: country.abbrev,
      lookups: [
        country.abbrev,
        // remove diacritics from the name
        country.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      ],
    }
  }
})
