import { BUTTON_VARIANTS, Button, MOBILE_BREAK, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledButton = styled(Button).attrs({ variant: BUTTON_VARIANTS.SOLID_PILL })<{
  $isSelected: boolean
}>`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.COLORS.TEXT};
  background-color: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_3};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  border: none;
  padding: ${SPACING[3]} ${SPACING[5]};
  margin-right: ${SPACING[2]};
  margin-bottom: ${SPACING[2]};
  font-size: 14px;
  height: 40px;
  border: ${({ $isSelected, theme }) =>
    $isSelected ? `2px solid ${theme.COLORS.BUTTON_BORDER_SELECTED}` : '2px solid transparent'};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_1};
  }

  @media (max-width: ${MOBILE_BREAK}) {
    width: 100%;
    justify-content: center;
  }
`

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${SPACING[2]};
  height: 24px;
  width: 24px;
`

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: ${SPACING[3]};
  }
`
