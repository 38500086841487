import React from 'react'

import cartIcon from '../../assets/cart-icon.svg'
import currencyIcon from '../../assets/currency-icon.svg'
import getStartedImage from '../../assets/get-started.png'
import peopleIcon from '../../assets/people-icon.svg'
import { StyledListIcon } from '../styled'

import { VisualFormSection } from 'containers/Settings/components/Integrations/VisualFormSection'

type GetStartedProps = {
  onPrimaryButtonClick: any
}

export const GetStarted = (props: GetStartedProps): JSX.Element => {
  return (
    <VisualFormSection
      image={getStartedImage}
      primaryButton="Get Started"
      primaryButtonClick={props.onPrimaryButtonClick}
      title="Why Connect Shopify?"
    >
      <StyledListIcon className="get-started-guides">
        <li>
          <img alt="cart icon" src={cartIcon} />
          Shopify Customers that opt-in and provide a phone number will receive a 1-time sign-up link and customizable
          welcome message upon completing checkout.
        </li>
        <li>
          <img alt="communities icon" src={peopleIcon} />
          See Community Members that are also Shopify customers.
        </li>
        <li>
          <img alt="currency icon" src={currencyIcon} />
          View total spent and last order date in the Community app.
        </li>
      </StyledListIcon>
    </VisualFormSection>
  )
}
