import { CaretUpIcon } from '@community_dev/pixels'
import styled from 'styled-components'

const StyledCaret = styled(CaretUpIcon)`
  fill: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledNumberContainer = styled.div<{ $locked: boolean }>`
  display: flex;
  align-items: baseline;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  font-family: ${({ theme }) => theme?.TYPOGRAPHY?.FAMILY?.SECONDARY};
  filter: ${({ $locked }) => ($locked ? 'blur(4px)' : 'none')};
`

const StyledSubHeader = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
`

type NewMembersDiffProps = {
  numberOfNewMembers: number
  subHeader: string
  className?: string
  locked: boolean
}

const formatNumber = new Intl.NumberFormat('en', {
  notation: 'standard',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
})

export const NewMembersHeader = ({
  numberOfNewMembers,
  subHeader,
  className,
  locked,
}: NewMembersDiffProps): JSX.Element => {
  return (
    <div className={className}>
      <StyledNumberContainer $locked={locked}>
        <StyledCaret />
        {formatNumber.format(numberOfNewMembers)}
      </StyledNumberContainer>
      <StyledSubHeader>{subHeader}</StyledSubHeader>
    </div>
  )
}
