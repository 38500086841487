import { Button, SPACING, TextInput, composeValidators, required } from '@community_dev/pixels'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import isFunction from 'lodash/isFunction'
import noop from 'lodash/noop'
import { useCallback } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { Community, postCreateCommunity } from 'api/community'
import { CommunityColorField } from 'components/CommunityColorField'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useToastMessage } from 'hooks/useToastMessage'

const StyledRow = styled.div`
  display: flex;
`

const StyledFooter = styled.footer`
  text-align: right;
  margin-top: ${SPACING[4]};
`

type CreateCommunityFormProps = {
  children?: React.ReactNode
  onCreate?: (community: Community) => void
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void
}

export function CreateCommunityForm({
  children,
  onCreate = noop,
  onSubmit = noop,
  ...props
}: CreateCommunityFormProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { mutateAsync } = useMutation(postCreateCommunity)
  const { showToastMessage } = useToastMessage()
  const { data: communities } = useCommunities()
  const clientId = useClientId()
  async function handleSubmit(values) {
    await mutateAsync({ clientId, ...values })
      .then((community) => {
        queryClient.invalidateQueries([QUERY_CACHE.TAGS])
        if (isFunction(onCreate)) {
          onCreate(community)
          return
        }
        showToastMessage({
          message: `${community.title} created.`,
        })
      })
      .catch(({ errors }) => {
        if (errors?.length) {
          showToastMessage({
            message: errors[0].detail,
            success: false,
          })
          return
        }
        showToastMessage({
          message: `${values.title} failed to create.`,
          success: false,
        })
      })
    onSubmit(values)
  }

  const uniqueName = useCallback(
    (name: string) => {
      return (
        (communities?.all.some((community) => community.title.toLowerCase() === name.toLowerCase()) &&
          t('communities.error.duplicateName', { title: '' })) ||
        undefined
      )
    },
    [communities?.all, t],
  )

  return (
    <>
      <h2>New Community</h2>
      <p>Communities are an easy way to organize your contacts.</p>
      <Form
        initialValues={{ color: COLORS?.COMMUNITY_COLOR_DEFAULT }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, valid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <StyledRow>
              <Field name="title" validate={composeValidators(required, uniqueName)} validateOnBlur={false}>
                {({ input, meta }) => (
                  <TextInput
                    aria-label="Community Name"
                    error={(meta.modified && meta.error) || ''}
                    label="Community Name"
                    placeholder="Enter name"
                    {...input}
                  />
                )}
              </Field>

              <Field name="color" validate={required} validateOnBlur>
                {({ input }) => <CommunityColorField {...input} />}
              </Field>
            </StyledRow>

            <StyledFooter>
              <Button disabled={!valid || submitting} type="submit">
                {submitting ? 'Saving...' : 'Save'}
              </Button>
            </StyledFooter>
          </form>
        )}
        {...props}
      />
    </>
  )
}
