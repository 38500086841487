import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import noop from 'lodash/noop'
import { ComponentProps } from 'react'

import { ComposeNavigation } from 'components/ComposeNavigation'

export const FilterItem = ({
  comIcon,
  disabled = false,
  icon,
  onClick = noop,
  selected,
  title,
  type,
  to,
  ...rest
}: {
  disabled?: boolean
  icon?: FontAwesomeIconProps['icon']
  onClick?(): void
  selected: boolean
  title: React.ReactNode
  comIcon?: React.ReactNode
  type: 'includes' | 'excludes'
  to?: string
} & Partial<ComponentProps<typeof ComposeNavigation.Item>>): JSX.Element => {
  return (
    <ComposeNavigation.Item disabled={disabled} onItemClick={onClick} selected={selected} to={to} type={type} {...rest}>
      {icon && <ComposeNavigation.Icon faIcon={icon} />}
      {comIcon && <ComposeNavigation.Icon comIcon={comIcon} />}
      <ComposeNavigation.Content>{title}</ComposeNavigation.Content>
    </ComposeNavigation.Item>
  )
}
