import { Button, InfiniteScroll, LoadingIndicator, SpeechBubble, Tabs } from '@community_dev/pixels'
import React, { RefObject } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import EmptyBlock from 'components/EmptyBlock'
import InboxItem from 'components/InboxItem'
import { InboxFilter, useInbox } from 'contexts/InboxProvider'
import { canReceiveDms } from 'hooks/useCanSendDms'
import { InboxItemNormalized } from 'utils/normalize'

const StyledInboxItemBody = styled.div`
  &&& {
    width: 100%;
    padding: 0 16px 0 0;
  }
`

const StyledInfiniteScroll = styled(InfiniteScroll)`
  touch-action: auto;
  display: block;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
`

type SearchTabsProps = {
  name: string
  showLoading: boolean
  loading: boolean
  moreLoading: boolean
  hasNextPage?: boolean
  items: InboxItemNormalized[]
  fanId?: string
  scroller?: RefObject<HTMLDivElement>
  label: string
  loadMore: (...args: any[]) => void
  onItemClick: (...args: any[]) => void
}

export const SearchTabs = React.forwardRef(
  (
    {
      name,
      showLoading,
      loading,
      moreLoading,
      hasNextPage,
      loadMore,
      items,
      fanId,
      scroller,
      label,
      onItemClick,
    }: SearchTabsProps,
    ref,
  ) => {
    const { empty, searchTerm, filters } = useInbox()

    const hasAllFilterSelected = filters.some((filter: InboxFilter) => 'value' in filter && filter.value === '')

    return (
      <Tabs.Tab label={label} name={name}>
        {showLoading && loading ? (
          <LoadingIndicator height="100px" />
        ) : empty && hasAllFilterSelected && !searchTerm ? (
          <EmptyBlock
            button={
              <Link to="/share">
                <Button>Promote Your Number</Button>
              </Link>
            }
            glyph={<SpeechBubble />}
            subtitle="Your Direct Messages will appear here"
            title="No Direct Messages"
          />
        ) : (
          <StyledInfiniteScroll
            data-testid="inbox-list-main"
            hasMore={hasNextPage}
            loadMore={loadMore}
            loading={moreLoading}
            ref={scroller}
            threshold={240}
          >
            {items &&
              items
                .filter((value) => !!Object.keys(value).length)
                .map((item) => {
                  const { id, unread, incoming, fan } = item
                  const { fullName, graphicUrl, initials, communicationChannel, stateFlags, conversationWindowEndAt } =
                    fan
                  const { body, media, ts } = item.last
                  const active = item.fan.id === fanId
                  const canReply = canReceiveDms({
                    communicationChannel: communicationChannel || undefined,
                    stateFlags,
                    conversationWindowEndAt,
                  })
                  return (
                    <InboxItem
                      {...{
                        communicationChannel: communicationChannel,
                        canReply,
                        id,
                        active,
                        body,
                        fullName,
                        graphicUrl,
                        incoming,
                        initials,
                        ts,
                        unread,
                        media,
                        fan: item.fan,
                        key: id,
                        onItemClick,
                        ref: active ? ref : undefined,
                        renderBody: (body) => <StyledInboxItemBody>{body}</StyledInboxItemBody>,
                      }}
                    />
                  )
                })}
          </StyledInfiniteScroll>
        )}
      </Tabs.Tab>
    )
  },
)
