import { Layout, Menu, SPACING, linkify } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import queryString from 'query-string'
import { useRef, useState } from 'react'
import styled, { keyframes, useTheme } from 'styled-components'

import IconRotate from './arrows-rotate.svg?react'

import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { useCampaignDetails } from 'hooks/useCampaign'
import { useToastMessage } from 'hooks/useToastMessage'
import Sentry from 'integrations/Sentry'
import analytics from 'utils/analytics'
import { getFileFromUrl } from 'utils/file'
import { parseUrl } from 'utils/link'

const StyledActionButton = styled.button`
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: 50%;
  user-select: none;

  &:hover {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  }
`

const rotate = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`

const StyledIconRotate = styled(IconRotate)`
  transition: opacity 0.2s;
  animation-name: ${rotate};
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-play-state: running;
`

enum CommunityQueryParam {
  CMTY_CAMPAIGN = 'cmty_campaign',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_MEDIUM = 'utm_medium',
  UTM_SOURCE = 'utm_source',
}

const communityQueryParams = Object.values(CommunityQueryParam)

export type DuplicateClickProps = {
  file?: File
  initialComposeState: {
    text: string
  }
  initialFilterState: {
    communicationChannel: CommunicationChannel
  }
  mediaDisposition?: MediaDisposition | null
}

export type CampaignMenuProps = {
  campaignId: string
  onDuplicateClick: (duplicateClickProps: DuplicateClickProps) => void
}

export const CampaignMenu = ({ campaignId, onDuplicateClick }: CampaignMenuProps): JSX.Element => {
  const { showToastMessage } = useToastMessage()
  const { COLORS } = useTheme() || {}
  const [isLoading, setIsLoading] = useState(false)
  const { setAnalyticsOnSentEvent } = useCompose()
  const { data: campaignData } = useCampaignDetails({ campaignId })
  const { communicationChannel = CommunicationChannel.SMS, id, media = null, text = '' } = campaignData || {}
  const message = text || ''

  const containerRef = useRef<HTMLDivElement | null>(null)

  async function handleDuplicate(e) {
    e.stopPropagation()
    setIsLoading(true)
    // parse media if present
    // m4a should have the audio/mp4 mime type
    const mimeType = media?.filename?.endsWith('m4a') ? 'audio/mp4' : media?.mimeType
    let file
    if (media?.url && mimeType && media?.filename) {
      try {
        // add a query param to bust the cache for video media
        file = await getFileFromUrl(`${media.url}?no-cache=true`, media.filename, {
          type: mimeType,
        })
      } catch (e) {
        Sentry.captureException(e)
        showToastMessage({
          message: 'Unable to copy media',
          success: false,
        })
      }
    }
    let newMessage = message
    // parse links if present
    if (linkify.match(message)) {
      try {
        // extract valid links from message text and parse query strings if present
        const links =
          linkify.match(message)?.flatMap((link) => {
            const linkHref = parseUrl(link.raw)?.href || ''
            if (linkHref) {
              return { ...queryString.parseUrl(linkHref, { parseFragmentIdentifier: true }), raw: link.raw }
            }
            return []
          }) || []
        // replace raw links with clean versions without our tracking params
        links.forEach((link) => {
          if (link.url) {
            // remove our tracking params but preserve any others
            const filteredQueries = Object.fromEntries(
              Object.entries(link.query).filter(([key]) => {
                // include utm_campaign if it was set by Leader on original link
                if (key === CommunityQueryParam.UTM_CAMPAIGN && link.query[key] !== id) {
                  return true
                }
                return !communityQueryParams.includes(key as CommunityQueryParam)
              }),
            )
            // generate query string or '' if no params
            const query = queryString.stringify(filteredQueries)
            // append fragment identifier and query to origin if they exist
            const newLink = `${link.url}${query ? `?${query}` : ''}${
              link.fragmentIdentifier ? `#${link.fragmentIdentifier}` : ''
            }`
            newMessage = newMessage.replace(link.raw, newLink)
          }
        })
      } catch (e) {
        Sentry.captureException(e)
        showToastMessage({
          message: 'Unable to parse links',
          success: false,
        })
      }
    }
    setIsLoading(false)
    onDuplicateClick({
      file,
      initialComposeState: { text: newMessage || message },
      initialFilterState: { communicationChannel },
      mediaDisposition: media?.disposition,
    })
    setAnalyticsOnSentEvent(analytics.events.CampaignDuplicateCreated)
  }

  return (
    <Layout ref={containerRef}>
      {isLoading ? (
        <Layout alignItems="center" display="flex">
          <StyledIconRotate height="18px" width="18px" />
        </Layout>
      ) : (
        <Menu
          style={{ marginTop: SPACING[2] }}
          trigger={
            <StyledActionButton data-testid={`campaign-menu-${campaignId}`}>
              <FontAwesomeIcon color={COLORS?.TEXT} icon="ellipsis-h" />
            </StyledActionButton>
          }
        >
          <Menu.Item data-testid="duplicate-campaign" key="duplicate-campaign" onClick={handleDuplicate}>
            Duplicate
          </Menu.Item>
        </Menu>
      )}
    </Layout>
  )
}
