import {
  SelectorOperators,
  BuiltInFields,
  FieldSources,
  FieldTypes,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { UseQueryOptions, UseQueryResult, useQuery, QueryObserverBaseResult } from '@tanstack/react-query'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Client, getClient } from 'api/client'
import { Community } from 'api/community'
import { postCountByQuery } from 'api/fan-count'
import {
  ShopifyInsights,
  getShopifyCustomerDetails,
  getShopifyInsights,
  getShopifyStoreDetails,
  ShopifyStoreDetails,
  ShopifyCustomerDetails,
} from 'api/shopify'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import Sentry from 'integrations/Sentry'
import { combinedQueryState } from 'utils/query'

export function useShopifyStoreDetails(
  clientId: string,
  options?: UseQueryOptions,
): UseQueryResult<ShopifyStoreDetails> & { shopifyExists: boolean } {
  const [enabled, setEnabled] = useState(true)
  const result = useQuery<any, any>(
    [QUERY_CACHE.SHOPIFY.STORE_DETAILS, clientId],
    () => getShopifyStoreDetails(clientId),
    { enabled, retryOnMount: false, ...options },
  )
  const shopifyExists =
    !result.isLoading && (!result.isError || result.error?.status === 422) && result.error?.status !== 404

  useEffect(() => {
    if (result.isError && !shopifyExists) {
      setEnabled(false)
    }
  }, [result.isError, shopifyExists])

  return {
    ...result,
    shopifyExists,
  }
}

export type UseShopifyReturn = {
  setEnabled: Dispatch<SetStateAction<boolean>>
  shopifyExists: boolean
  refetchShopify: QueryObserverBaseResult<ShopifyStoreDetails>['refetch']
  shopify?: ShopifyStoreDetails
  client?: Client
  communityCount?: number
  customerDetails?: ShopifyCustomerDetails
  isLoading: boolean
  isInitialLoading: boolean
  errors: any[]
}

export function useShopify(clientId: string, community?: Community, fanSubscriptionId?: string): UseShopifyReturn {
  const [enabled, setEnabled] = useState(true)
  const client = useQuery([QUERY_CACHE.CLIENT], () => getClient(), { onError: Sentry.captureException })

  const shopify = useShopifyStoreDetails(clientId, { enabled })

  useEffect(() => {
    if (shopify.isError && !shopify.shopifyExists) {
      setEnabled(false)
    }
  }, [shopify.isError, shopify.shopifyExists])

  const communityCount = useQuery(
    [QUERY_CACHE.SHOPIFY.COUNT, community?.id, clientId],
    () =>
      community &&
      postCountByQuery({
        filters: {
          operator: SelectorOperators.EQUALS,
          operand: {
            field_key: BuiltInFields.TAG_ID,
            field_label: community.title,
            source: FieldSources.TAGS,
            type: FieldTypes.UUID,
            value: community.id,
          },
        },
        traceId: 'shopify',
        clientId,
      }),
    {
      enabled: shopify.isSuccess && !!community?.id,
    },
  )

  const customerDetails = useQuery<any, any>(
    [QUERY_CACHE.SHOPIFY.CUSTOMER_DETAILS, fanSubscriptionId],
    () => fanSubscriptionId && getShopifyCustomerDetails(clientId, fanSubscriptionId),
    {
      staleTime: STALE_TIME.FIVE_MINUTES,
      retry: false,
      onError(err) {
        if (err.status !== 404) return
      },

      enabled: Boolean(fanSubscriptionId),
    },
  )

  const combined = combinedQueryState<any>({
    client,
    shopify,
    communityCount,
    customerDetails,
  })

  const shopifyExists =
    !combined.isInitialLoading &&
    (!combined.isError || ((combined.errors[0] as any)?.status === 422 && (combined.errors[0] as any)?.status !== 404))

  return {
    client: combined.queries.client.data,
    communityCount: combined.queries.communityCount.data,
    customerDetails: combined.queries.customerDetails.data,
    shopify: combined.queries.shopify.data,
    ...combined,
    refetchShopify: shopify.refetch,
    setEnabled,
    shopifyExists,
  }
}

export function useShopifyInsights(clientId: string): UseQueryResult<ShopifyInsights> {
  return useQuery([QUERY_CACHE.SHOPIFY.INSIGHTS, clientId], () => getShopifyInsights(clientId), {
    staleTime: STALE_TIME.FIVE_MINUTES,
  })
}
