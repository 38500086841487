import { SPACING, Typography } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledList = styled.ul`
  list-style-type: none;
  padding-left: ${SPACING[5]};
  padding-top: 0;
  li {
    margin: ${SPACING[2]} 0;
  }
`

export const StyledTooltipContent = styled.div`
  padding: ${SPACING[1]};
  a {
    color: ${({ theme }) => theme.COLORS.TOOLTIP_TEXT};
  }
`

export const StyledLabel = styled(Typography).attrs({
  margin: `${SPACING[4]} 0 ${SPACING[1]} 0`,
  variant: 'body2',
})`
  color: ${({ theme }) => theme.COLORS.SUBTEXT};
  /* this matches the font size of a text field label */
  font-size: 13px;
`
