import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { useMemo } from 'react'

import { LINK_CLIENT_ID_PARAM, LINK_SEAT_EMAIL_PARAM } from './constants'

import { useSeat } from 'contexts/bootstrap/useSeat'
import { useClientId } from 'hooks/useClient'

// EX:
// url = https://community.formstack.com/forms/add_team_member
// params = ['client_id', 'seat_email']
// returns = https://community.formstack.com/forms/add_team_member?client_id=a3206820-3fe1-45cc-b305-f5745461064c&seat_email=example@community.com

export const useLinkWithParams = ({ url, params = [] }: { url: string; params?: string[] }): string => {
  const { data: seat } = useSeat()
  const clientId = useClientId()
  const { email } = seat || {}

  return useMemo(() => {
    const paramsWithData = params.reduce((acc, param) => {
      if (param === LINK_CLIENT_ID_PARAM) return { ...acc, [param]: clientId }
      if (param === LINK_SEAT_EMAIL_PARAM) return { ...acc, [param]: email }
      return {}
    }, {})

    const query = isEmpty(paramsWithData) ? '' : `?${queryString.stringify(paramsWithData, { encode: false })}`

    return url + query
  }, [email, clientId, params, url])
}
