import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { MouseEventHandler, useCallback } from 'react'

import { SelectionActionButtons } from './SelectionActionButtons'

import { ActionPanel } from 'components/ActionPanel'
import { ActionPanelContactsHeader } from 'components/ActionPanel/ActionPanelPresentationalComponents'
import { PANELS } from 'constants/panels'
import { useCampaignResponseFilterState } from 'contexts/CampaignResponseFilterProvider'
import { formatLargeNumber } from 'utils/number'

type SelectionActionPanelProps = {
  communicationChannel?: CommunicationChannel
  selectionCountIsLoading?: boolean
  selectionCount?: number
  onClickAddToCommunity(): void
  onClickDeselect?: MouseEventHandler<HTMLButtonElement>
}

export const SelectionActionPanel = ({
  communicationChannel,
  selectionCountIsLoading,
  selectionCount,
  onClickAddToCommunity,
  onClickDeselect,
}: SelectionActionPanelProps): JSX.Element => {
  const { setCampaignFollowUpFilter } = useCampaignResponseFilterState()
  const clearCampaignFollowUpFilter = useCallback(() => {
    setCampaignFollowUpFilter(null)
  }, [setCampaignFollowUpFilter])
  return (
    <ActionPanel name={PANELS.SENT_SELECTION} onClose={clearCampaignFollowUpFilter}>
      <ActionPanelContactsHeader
        communicationChannel={communicationChannel}
        isUserSelection
        onClickDeselect={onClickDeselect}
        recipientCountIsLoading={selectionCountIsLoading}
        recipientCountString={`${formatLargeNumber(selectionCount || 0)} Selected`}
      />
      <SelectionActionButtons
        communicationChannel={communicationChannel}
        onClickAddToCommunity={onClickAddToCommunity}
      />
    </ActionPanel>
  )
}
