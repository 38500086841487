import { useEffect, useState } from 'react'

import { getValidateToken } from 'api/login'

type UseValidateTokenArgs = {
  token: string
  onError: () => void
}

type UseValidateTokenReturn = {
  token: string
  email: string
  isLoading: boolean
}

export const useValidateToken = ({ token, onError }: UseValidateTokenArgs): UseValidateTokenReturn => {
  const [validatedToken, setToken] = useState('')
  const [email, setEmail] = useState('')
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    getValidateToken({ token })
      .then(({ data }) => {
        setToken(data.id)
        setEmail(data.email)
        setLoading(false)
      })
      .catch(() => {
        onError()
      })
  }, [token, onError])

  return {
    token: validatedToken,
    email,
    isLoading,
  }
}
