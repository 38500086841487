import { Embed, EmbedStatus } from 'api/mgu'

export const EMBED_DEFAULTS: Embed = {
  jsonConfig: {
    backgroundColor: '#ffffff',
    buttonBackgroundColor: '#000000',
    buttonTextColor: '#ffffff',
    displayType: 'popup',
    headline: '10% off your next order',
    headlineTextColor: '#000000',
    launchDelayInSeconds: 10,
    name: '',
    pathRules: [],
    phoneNumber: '',
    subtext: '',
    subtextTextColor: '#333333',
  },
  status: EmbedStatus.published,
  subscriptionConfirmationMessage: '',
}

export const MAX_HEADLINE_LENGTH = 80

export const PREVIEW_MODES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
}
