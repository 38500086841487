import { useDebouncedValue } from '@community_dev/hooks'
import {
  Button,
  COLORS,
  DIALOG_VARIANTS,
  Dialog,
  Modal,
  SPACING,
  LoadingIndicator,
  SearchingFlag,
} from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import compact from 'lodash/compact'
import numeral from 'numeral'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Fan } from 'api/fans'
import { StyledCloseIcon } from 'containers/KeywordsModal/KeywordsModal.style'
import RecommendationRow from 'containers/RecipientRecommendations/RecommendationRow'
import {
  StyledPaneContainer,
  StyledPaneEmpty,
  StyledPaneMain,
  StyledRecommendationHeader,
  StyledSearchBar,
} from 'containers/RecipientRecommendations/styled'
import { useClient } from 'hooks/useClient'
import { useFans } from 'hooks/useFan'
import { useInboxMessages } from 'hooks/useInboxMessages'

const StyledMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledFooter = styled(Modal.Footer)`
  background: ${({ theme }) => theme?.COLORS?.DIALOG_FOOTER_BACKGROUND};
  display: flex;
  padding: ${SPACING[4]};
  justify-content: flex-end;
  align-items: center;
`

const StyledCount = styled.span`
  margin-right: ${SPACING[4]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

type MembersModalProps = {
  fans?: Fan[]
  isOpen: boolean
  onClose: (...args: any[]) => void
  onSave: (...args: any[]) => void
}

const MembersModal = ({
  fans: initialFans = [],
  isOpen,
  onClose: onCloseCallback,
  onSave: onSaveCallback,
}: MembersModalProps): JSX.Element => {
  const [closing, setClosing] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedFans, setSelectedFans] = useState<Fan[]>(initialFans)
  const debouncedSearch = useDebouncedValue(search, 500)
  const { data: client } = useClient()
  const searchBar = useRef<HTMLInputElement | null>(null)

  const { items = [] } = useInboxMessages({})

  const { data: smsFans, isInitialLoading: isSmsLoading } = useFans(debouncedSearch, CommunicationChannel.SMS)
  const { data: whatsAppFans, isInitialLoading: isWhatsAppLoading } = useFans(
    debouncedSearch,
    CommunicationChannel.WHATS_APP,
    {
      enabled: client?.communicationChannels?.includes(CommunicationChannel.WHATS_APP),
    },
  )

  const isLoading = isSmsLoading || isWhatsAppLoading || search !== debouncedSearch

  const fans = useMemo(() => {
    return (smsFans || []).concat(whatsAppFans || []).sort((a, b) => (a.fullName || '').localeCompare(b.fullName || ''))
  }, [smsFans, whatsAppFans])

  const recentFans = useMemo(
    () => compact(items.map((item) => fans.find((fan) => fan.id === item.fan.id))).slice(0, 5),
    [fans, items],
  )

  useEffect(() => {
    setSelectedFans([])
    setClosing(false)
  }, [isOpen])

  const onClose = () => {
    if (selectedFans.length > 0) return setClosing(true)
    onCloseCallback()
  }

  const onSave = () => {
    onSaveCallback(selectedFans)
    onCloseCallback()
  }

  const addFan = (fan) => {
    if (selectedFans.some((f) => f.id === fan.id)) {
      return
    }

    setSelectedFans((selectedFans) => [...selectedFans, fan])
    setSearch('')
    searchBar?.current?.focus()
  }

  const removeFan = (fan) => {
    setSelectedFans((selectedFans) => selectedFans.filter((selectedFan) => selectedFan.id !== fan.id))
  }

  const recipientLabel = numeral(selectedFans.length).format('0,0')

  const countLabel = selectedFans.length === 1 ? `Add ${recipientLabel} Member` : `Add ${recipientLabel} Members`

  const renderFan = (fan) => (
    <RecommendationRow
      data-testid="recommendation-row"
      fan={fan}
      initials={fan.initials}
      key={fan.id}
      onClear={() => removeFan(fan)}
      onClick={() => addFan(fan)}
      primary={fan.fullName}
      removable={selectedFans.some((f) => f.id === fan.id)}
      secondary={fan.overview}
    />
  )

  return (
    <Modal onClose={onClose} open={isOpen}>
      <Modal.Header>
        <Modal.Header.Center>
          <div>Add Members</div>
        </Modal.Header.Center>
        <Modal.Header.Right onClose={onClose}>
          <StyledCloseIcon color={COLORS?.SUBTEXT} size={12} />
        </Modal.Header.Right>
      </Modal.Header>
      <Modal.Body>
        <StyledMain>
          <StyledPaneMain>
            <header>
              <h3>Add Members {selectedFans.length !== 0 && `(${recipientLabel})`}</h3>
              <StyledSearchBar
                autoFocus
                className="individualsSearch"
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Search to add Members"
                ref={searchBar}
                value={search}
              />
            </header>
            <StyledPaneContainer
              css={`
                min-height: 300px;
              `}
            >
              {isLoading ? (
                <StyledPaneEmpty
                  css={`
                    min-height: 300px;
                  `}
                >
                  <LoadingIndicator />
                </StyledPaneEmpty>
              ) : search && fans.length > 0 ? (
                fans.map(renderFan)
              ) : search ? (
                <StyledPaneEmpty>
                  <SearchingFlag size={150} />
                  No results found.
                </StyledPaneEmpty>
              ) : selectedFans.length > 0 ? (
                selectedFans.map(renderFan)
              ) : (
                <>
                  <StyledRecommendationHeader>Recent Suggestions</StyledRecommendationHeader>
                  {recentFans.map(renderFan)}
                </>
              )}
            </StyledPaneContainer>
          </StyledPaneMain>
        </StyledMain>
      </Modal.Body>
      <StyledFooter>
        {selectedFans.length > 0 && <StyledCount>{countLabel}</StyledCount>}
        <Button onClick={onSave}>Add</Button>
      </StyledFooter>
      {closing && (
        <Dialog title="Discard changes without applying filters?">
          <Dialog.Action onClick={() => setClosing(false)}>Cancel</Dialog.Action>
          <Dialog.Action onClick={() => onCloseCallback()} variant={DIALOG_VARIANTS.DESTRUCTIVE}>
            Discard
          </Dialog.Action>
        </Dialog>
      )}
    </Modal>
  )
}

export default MembersModal
