import { SPACING, Tooltip } from '@community_dev/pixels'
import styled, { useTheme } from 'styled-components'

import CircleAlertIcon from './circle-alert.svg?react'

const StyledBadgeWrapper = styled.div`
  border-radius: 4px;
  padding: ${SPACING[2]} ${SPACING[4]} ${SPACING[2]} ${SPACING[3]};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

const StyledText = styled.span`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  margin-left: ${SPACING[2]};
  display: inline-block;
`

type BadgeProps = {
  color?: string
  children?: React.ReactNode
  icon?: React.ReactNode
  tooltip?: React.ReactNode
}

export function Badge({ color, children, icon, tooltip }: BadgeProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const content = (
    <StyledBadgeWrapper>
      {icon || <CircleAlertIcon style={{ color: color || COLORS.INFO }} />}
      <StyledText>{children}</StyledText>
    </StyledBadgeWrapper>
  )
  return tooltip ? (
    <Tooltip content={tooltip} interactive>
      {content}
    </Tooltip>
  ) : (
    content
  )
}
