import { ArrowRightIcon, BUTTON_VARIANTS, DIALOG_VARIANTS, Dialog, SPACING } from '@community_dev/pixels'
import { useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { BREAKPOINTS } from './constants'
import { PREVIEW_MODES } from './constants/embed'
import { useEmbed } from './hooks/useEmbed'
import { PopUpDesigner } from './PopUpDesigner'

import { Embed } from 'api/mgu'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { useToastMessage } from 'hooks/useToastMessage'
import { StyledOutlineButton } from 'screens/SettingsScreen/components/styled'
import strings from 'texts.json'
import { route } from 'utils/router'

type StyledHeaderActionButtonProps = {
  active: boolean
}

const StyledHeader = styled.div`
  padding: 0 ${SPACING[1]};
`

const StyledNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

const StyledContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.FULL_WIDTH_FORM}) {
    max-width: 960px;
  }
`

const StyledBackButton = styled.a`
  cursor: pointer;

  svg {
    transform: rotate(180deg);
  }
`
const StyledHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const StyledHeading = styled.h1`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
`

const StyledHeaderActionButton = styled.button<StyledHeaderActionButtonProps>`
  border: none;
  background: none;
  cursor: pointer;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  color: ${({ theme, active }) => (active ? theme?.COLORS?.BUTTON_PRIMARY : theme?.COLORS?.SUBTEXT)};
`

export const EmbedSettings = (): JSX.Element | null => {
  const { COLORS } = useTheme() || {}
  const queryClient = useQueryClient()
  const [previewViewMode, setPreviewViewMode] = useState(PREVIEW_MODES.DESKTOP)
  const { params } = useRouteMatch<{ embedId?: string }>()
  const history = useHistory<{ isCreation?: boolean }>()
  const { showToastMessage } = useToastMessage()
  const [showRemoveDialog, setShowRemoveDialog] = useState(false)
  const [workingEmbed, setWorkingEmbed] = useState<Embed>()
  const [isCreation, setIsCreation] = useState(!params.embedId || Boolean(history.location.state?.isCreation))

  const shouldGoBack = useCallback(() => {
    queryClient.invalidateQueries([QUERY_CACHE.MGU.EMBEDS])
    history.goBack()
  }, [history, queryClient])

  const showError = () => {
    showToastMessage({
      message: strings.errors.generic,
      success: false,
    })
  }

  const handlePreviewToggle = () => {
    const mode = previewViewMode === PREVIEW_MODES.DESKTOP ? PREVIEW_MODES.MOBILE : PREVIEW_MODES.DESKTOP
    setPreviewViewMode(mode)
  }

  const { embed, save, remove, isSaving } = useEmbed(params.embedId, {
    onSaveSuccess(embed: Embed) {
      const message = 'Sign Up Form ' + (embed.status === 'published' ? 'Published' : 'Saved')
      showToastMessage({
        message,
        success: true,
      })
      setWorkingEmbed(embed)
      setIsCreation(false)
    },
    onRemoveSuccess() {
      const message = 'Sign Up Form Removed'
      showToastMessage({
        message,
        success: true,
      })
      setShowRemoveDialog(false)
      history.replace(ROUTES.SETTINGS.INTEGRATIONS.ROOT)
    },
    onSaveError: showError,
    onRemoveError: showError,
  })

  useEffect(() => {
    if (embed?.id && !params.embedId) {
      history.replace(route(ROUTES.SETTINGS.INTEGRATIONS.EDIT_EMBED, { embedId: embed.id }), { isCreation })
    } else if (embed?.id) {
      setWorkingEmbed(embed)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embed?.id, params.embedId])

  if (!workingEmbed) {
    return null
  }

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledNav>
          <StyledBackButton onClick={shouldGoBack}>
            <ArrowRightIcon color={COLORS?.BUTTON_PRIMARY} size={20} />
          </StyledBackButton>
          <StyledOutlineButton onClick={() => setShowRemoveDialog(true)} variant={BUTTON_VARIANTS.OUTLINE}>
            Remove
          </StyledOutlineButton>
        </StyledNav>
        <StyledHeadingWrapper>
          <StyledHeading>{isCreation ? 'Create' : 'Edit'} Signup Form</StyledHeading>
          <div>
            <StyledHeaderActionButton
              active={previewViewMode === PREVIEW_MODES.DESKTOP}
              onClick={() => setPreviewViewMode(PREVIEW_MODES.DESKTOP)}
            >
              Desktop
            </StyledHeaderActionButton>
            <StyledHeaderActionButton
              active={previewViewMode === PREVIEW_MODES.MOBILE}
              onClick={() => setPreviewViewMode(PREVIEW_MODES.MOBILE)}
            >
              Mobile
            </StyledHeaderActionButton>
          </div>
        </StyledHeadingWrapper>
      </StyledHeader>
      <PopUpDesigner
        embed={workingEmbed}
        isCreate={isCreation}
        isSaving={isSaving}
        onChange={setWorkingEmbed}
        onSave={save}
        previewViewMode={previewViewMode}
        togglePreviewMode={handlePreviewToggle}
      />
      {showRemoveDialog && (
        <Dialog
          message="Are you sure you want to do this?"
          onCancel={() => setShowRemoveDialog(false)}
          title="Remove Sign Up Form"
        >
          <Dialog.Action onClick={() => setShowRemoveDialog(false)}>Cancel</Dialog.Action>
          <Dialog.Action
            onClick={() => workingEmbed.id && remove(workingEmbed.id)}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            Remove
          </Dialog.Action>
        </Dialog>
      )}
    </StyledContainer>
  )
}
