import { useMessageBillingUsage } from '@community_dev/hooks'
import { BUTTON_VARIANTS, Button, TCPAMessage, Typography } from '@community_dev/pixels'
import React, { useState } from 'react'

import { MultimediaInput } from 'components/MultimediaInput'
import { useSettings } from 'contexts/SettingsProvider'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import { StyledAction, StyledMultimedia, StyledTextButton } from 'screens/Onboarding/styled'

type EditMessageProps = {
  charMax?: number
  complete?: boolean
  defaultValue?: string
  legalMessage?: string
  segmentMax?: number
  onCancel: (...args: any[]) => any
  onSave: (...args: any[]) => any
}

export function EditMessage({
  charMax,
  complete = false,
  defaultValue = '',
  legalMessage,
  segmentMax,
  onCancel,
  onSave,
}: EditMessageProps): JSX.Element {
  const currentTheme = useCurrentTheme()
  const { settings } = useSettings()
  const [value, setValue] = useState(defaultValue)
  const { links } = useShortenableLinks(value)
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = useMessageBillingUsage({
    charMax,
    linksToShorten: links,
    message: value,
    messageSuffix: legalMessage ? ` ${legalMessage}` : '',
    segmentMax,
  })
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }

  return (
    <>
      <Typography variant="h1">Personalize your message</Typography>
      <p>When people text your number, your welcome message will be sent automatically.</p>
      <StyledMultimedia>
        {legalMessage ? (
          <TCPAMessage
            defaultValue={value}
            emojiSkinTone={settings.skinTone}
            emojiTheme={currentTheme.type}
            onChange={setValue}
            requiredSuffix={` ${legalMessage}`}
            usageCounterProps={usageCounterProps}
          />
        ) : (
          <MultimediaInput
            emojiSkinTone={settings.skinTone}
            emojiTheme={currentTheme.type}
            gallery={false}
            gif={false}
            onChange={(e) => setValue(e.target.value)}
            onEmojiSelect={(_, value) => {
              setValue(value)
            }}
            usageCounterProps={usageCounterProps}
            value={value}
          />
        )}
      </StyledMultimedia>
      <StyledAction>
        <Button
          disabled={!value.length || !!error}
          onClick={() => {
            onSave(value)
          }}
          variant={BUTTON_VARIANTS.ACTION}
        >
          Save
        </Button>
      </StyledAction>
      {complete && <StyledTextButton onClick={onCancel}>Cancel</StyledTextButton>}
    </>
  )
}
