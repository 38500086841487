import { Info, LoadingIndicator } from '@community_dev/pixels'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { getSalesforceMarketingCloudOAuthStartUrl } from 'api/salesforce'
import { ROUTES } from 'constants/routes'
import { useClientId } from 'hooks/useClient'
import SimpleLayout from 'layouts/SimpleLayout'

export function SalesforceMarketingCloudOauth(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessageKey, setErrorMessageKey] = useState<
    'salesforce.genericErrorMessage' | 'salesforce.forbiddenMessage' | null
  >(null)
  const clientId = useClientId()
  useEffect(() => {
    if (clientId) {
      const useSandbox = queryString.parse(window.location.search).sandbox === 'true'
      getSalesforceMarketingCloudOAuthStartUrl(clientId, useSandbox)
        .then((data) => {
          window.location.assign(data.url)
        })
        .catch((err) => {
          setIsLoading(false)
          if (err.name === 'ApiError' && err.status === 403) {
            setErrorMessageKey('salesforce.forbiddenMessage')
          } else {
            setErrorMessageKey('salesforce.genericErrorMessage')
          }
        })
    }
  }, [clientId])
  return (
    <SimpleLayout>
      {isLoading && <LoadingIndicator />}
      {errorMessageKey !== null && (
        <Info>
          <Trans i18nKey={errorMessageKey}>
            <Link to={ROUTES.PRICING.CONTACT_US}></Link>
          </Trans>
        </Info>
      )}
    </SimpleLayout>
  )
}
