import localforage from 'localforage'
import last from 'lodash/last'
import upperFirst from 'lodash/upperFirst'

import { mediaConfig } from './config'

localforage.setDriver([localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE])

const media = {
  cache: {
    length: localforage.length,
    removeItem: localforage.removeItem,
    setItem: localforage.setItem,
    clear: localforage.clear,
    getItem: (id: string): Promise<any> =>
      localforage.getItem(id).then((media) => {
        if (!media) return null
        try {
          return Object.assign(media as Record<any, any>, {
            url: window.URL.createObjectURL(media as Blob),
          })
        } catch (e) {
          return { url: mediaConfig.placeholderImage }
        }
      }),
  },
}

type Media = {
  type: string
  filename: string
  [x: string]: any
}

export function getMediaAttachmentType(media?: Media): string {
  if (!media) return ''
  const type = media.type.split('/')[0]
  if (type) return upperFirst(type)
  return last(media.filename.split('.')) === 'm4a' ? 'Audio' : ''
}

export default media
