import { buildLastSentTitle } from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import { MessageSourceType } from '@community_dev/types/lib/api/v2/MessageSourceType'
import { UseInfiniteQueryResult, useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useRef } from 'react'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { useUpdateInbox } from './useUpdateInbox'

import { getMessageHistory } from 'api/messages'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import dayjs from 'utils/dayjs'

type UseMessageHistoryOptions = {
  fanId: string
}

type UseMessageHistoryReturn = UseInfiniteQueryResult<CamelCasedPropertiesDeep<Api.V2.Message>, unknown> & {
  messageHistory: CamelCasedPropertiesDeep<Api.V2.Message>[]
  lastMessage: CamelCasedPropertiesDeep<Api.V2.Message>
}

export function useMessageHistory(options: UseMessageHistoryOptions): UseMessageHistoryReturn {
  const { fanId } = options

  const clientId = useClientId()
  const queryClient = useQueryClient()

  const updateInbox = useUpdateInbox()

  const queryKey = [QUERY_CACHE.MESSAGE_HISTORY.DMS, clientId, fanId]

  const firstPageWindow = useRef<string>(dayjs().add(1, 'day').toISOString())

  useEffect(() => {
    queryClient.invalidateQueries(queryKey)
  }, [clientId, fanId])

  const queryResult = useInfiniteQuery({
    enabled: Boolean(fanId),
    queryKey: queryKey,
    queryFn: ({ pageParam = firstPageWindow.current }) => getMessageHistory({ fanId, clientId, nextTs: pageParam }),
    getNextPageParam: (lastPage: CamelCasedPropertiesDeep<Api.V2.MessageHistory>) =>
      lastPage?.paginationData?.nextEndDate || firstPageWindow.current,
    getPreviousPageParam: () => undefined,
  })

  const messageHistory = useMemo(() => {
    if (queryResult.data?.pages) {
      return queryResult.data.pages
        .reduce<CamelCasedPropertiesDeep<Api.V2.Message>[]>((acc, cur) => [...acc, ...cur.data], [])
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        .filter((message, i, self) => i === self.findIndex((t) => t.id === message.id))
    }

    return []
  }, [queryResult.data, queryKey, queryResult.isFetchingNextPage])

  const lastMessage = messageHistory[messageHistory.length - 1]

  useEffect(() => {
    if (lastMessage?.sourceType === MessageSourceType.DM) {
      updateInbox(fanId, {
        incoming: lastMessage.inbound,
        last: {
          body: buildLastSentTitle(lastMessage.text, lastMessage.media || undefined),
        },
      })
    }
  }, [lastMessage])
  ;(queryResult as UseMessageHistoryReturn).messageHistory = messageHistory
  ;(queryResult as UseMessageHistoryReturn).lastMessage = lastMessage

  return queryResult as UseMessageHistoryReturn
}
