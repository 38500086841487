export enum MessageSourceType {
  DM = 'DM',
  CAMPAIGN = 'CAMPAIGN',
  KEYWORD_RESPONSE = 'KEYWORD_RESPONSE',
  SEAT_ONBOARDING = 'SEAT_ONBOARDING',
  AUTOMATED_METADATA_TYPE_NOT_SET = 'AUTOMATED_METADATA_TYPE_NOT_SET',
  AUTOMATED_METADATA_TYPE_WORKFLOW = 'AUTOMATED_METADATA_TYPE_WORKFLOW',
  AUTOMATED_METADATA_TYPE_ZAPIER = 'AUTOMATED_METADATA_TYPE_ZAPIER',
  NOT_SET = 'NOT_SET',
}
