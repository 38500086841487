import { Block, ChevronRightIcon, SPACING } from '@community_dev/pixels'
import { MouseEventHandler } from 'react'
import styled, { useTheme } from 'styled-components'

import { CustomMemberDataDefinitionList } from '../../CustomMemberDataDefinitionList'

import { Fan } from 'api/fans'
import { useCustomMemberData } from 'hooks/useCustomMemberData'

type MemberDetailsProps = {
  fan?: Fan
  maxRows?: number
  onViewMoreClick?: MouseEventHandler<HTMLButtonElement>
}

const StyledViewAllButton = styled.button`
  margin-top: ${SPACING[4]};
  padding: 0 ${SPACING[4]} 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  cursor: pointer;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledBlock = styled(Block)`
  padding-right: 0;

  dl {
    margin-top: 0;
  }

  dt {
    min-width: 120px;
    max-width: 120px;
    width: 120px;
  }

  dd {
    width: 100%;
  }
`

const DEFAULT_MAX_CMD_ROWS = 8

export function CustomMemberData({
  fan,
  maxRows = DEFAULT_MAX_CMD_ROWS,
  onViewMoreClick,
}: MemberDetailsProps): JSX.Element | null {
  const { COLORS } = useTheme() || {}
  const { data: customMemberData = { fields: [] } } = useCustomMemberData({
    fanSubscriptionId: fan?.fanSubscriptionId || '',
  })

  const showViewMoreButton = (customMemberData.fields.length || 0) > maxRows

  return (
    <StyledBlock
      flush={showViewMoreButton ? false : true}
      title={`Custom Member Data${customMemberData.fields.length > 0 ? ` (${customMemberData.fields.length})` : ''}`}
    >
      <CustomMemberDataDefinitionList fields={customMemberData.fields} maxRows={maxRows} />
      {showViewMoreButton && (
        <StyledViewAllButton aria-label="View all Custom Member Data" onClick={onViewMoreClick}>
          View All <ChevronRightIcon color={COLORS?.DIVIDERS} />
        </StyledViewAllButton>
      )}
    </StyledBlock>
  )
}
