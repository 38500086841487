import kebabCase from 'lodash/kebabCase'
import styled, { createGlobalStyle, css } from 'styled-components'
import 'react-big-calendar/lib/css/react-big-calendar.css'

// convert our theme colors into CSS vars so they can be used with
// Sass/CSS based libraries.
const ThemeCssVars = css`
  :root {
    ${({ theme }) => {
      return css`
        ${Object.entries(theme.COLORS)
          .map(([key, color]) => {
            return `--color-${kebabCase(key)}: ${color};`
          })
          .join('\n')}
      `
    }}
  }
`

const ReactBigCalendarOverrides = css`
  .rbc-month-view {
    border-left: none;
    border-right: none;
  }
  .rbc-selected-cell {
    background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }
  .rbc-today {
    background-color: ${({ theme }) => theme?.COLORS?.WORKFLOW_CARD_HIGHLIGHT_BACKGROUND};
  }
  .rbc-off-range {
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  }
  .rbc-off-range-bg {
    background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
  }
  .rbc-overlay-header {
    font-size: 16px;
    font-weight: 600;
    padding: 8px;
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  }
  .rbc-overlay {
    z-index: 0;
    background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }
  .rbc-header,
  .rbc-header + .rbc-header,
  .rbc-rtl .rbc-header + .rbc-header,
  .rbc-month-view,
  .rbc-month-row + .rbc-month-row,
  .rbc-day-bg + .rbc-day-bg,
  .rbc-rtl .rbc-day-bg + .rbc-day-bg,
  .rbc-overlay,
  .rbc-overlay-header,
  .rbc-agenda-view table.rbc-agenda-table,
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td,
  .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td,
  .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr,
  .rbc-agenda-view table.rbc-agenda-table thead > tr > th,
  .rbc-timeslot-group,
  .rbc-day-slot .rbc-time-slot,
  .rbc-time-view,
  .rbc-time-view .rbc-allday-cell + .rbc-allday-cell,
  .rbc-time-header.rbc-overflowing,
  .rbc-rtl .rbc-time-header.rbc-overflowing,
  .rbc-time-header > .rbc-row:first-child,
  .rbc-time-header > .rbc-row.rbc-row-resource,
  .rbc-time-header-content,
  .rbc-rtl .rbc-time-header-content,
  .rbc-time-header-content > .rbc-row.rbc-row-resource,
  .rbc-time-content,
  .rbc-time-content > * + * > *,
  .rbc-rtl .rbc-time-content > * + * > * {
    border-color: ${({ theme }) => theme?.COLORS?.BORDERS};
  }
  .rbc-toolbar button {
    cursor: pointer;
    color: ${({ theme }) => theme?.COLORS?.BUTTON_OUTLINED_TEXT};
    border-color: ${({ theme }) => theme?.COLORS?.BORDERS};
  }
  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
    border-color: ${({ theme }) => theme?.COLORS?.BORDERS};
  }
  .rbc-toolbar button:active:hover,
  .rbc-toolbar button:active:focus,
  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button.rbc-active:focus {
    color: ${({ theme }) => theme?.COLORS?.BUTTON_OUTLINED_TEXT};
    background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
    border-color: ${({ theme }) => theme?.COLORS?.BORDERS};
  }
  .rbc-toolbar button:focus {
    color: ${({ theme }) => theme?.COLORS?.BUTTON_OUTLINED_TEXT};
    background-color: ${({ theme }) => theme?.COLORS?.BUTTON_OUTLINED_HOVER};
    border-color: ${({ theme }) => theme?.COLORS?.BORDERS};
  }
  .rbc-toolbar button:hover {
    color: ${({ theme }) => theme?.COLORS?.BUTTON_OUTLINED_TEXT};
    background-color: ${({ theme }) => theme?.COLORS?.BUTTON_OUTLINED_HOVER};
    border-color: ${({ theme }) => theme?.COLORS?.BORDERS};
  }
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background-color: #3174ad;
    border-radius: 6px;
    padding: 2px 8px;
    color: #fff;
  }
  .rbc-event.rbc-selected,
  .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: #265985;
  }
  .rbc-event:focus,
  .rbc-day-slot .rbc-background-event:focus {
    outline-color: #3b99fc;
  }
  .rbc-show-more {
    font-size: 12px;
    font-weight: 500;
    background: none;
    padding-left: 4px;
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  }
  .rbc-show-more:hover,
  .rbc-show-more:focus {
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  }
  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border-color: #265985;
  }
  .rbc-time-view-resources .rbc-time-gutter,
  .rbc-time-view-resources .rbc-time-header-gutter {
    background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
    border-color: ${({ theme }) => theme?.COLORS?.BORDERS};
  }
  .rbc-slot-selection {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }
  .rbc-current-time-indicator {
    background-color: #74ad31;
  }
  .rbc-event-content {
    font-size: 14px;
  }
  .rbc-event {
    &.ai-idea {
      background-color: ${({ theme }) => theme?.COLORS?.EVENT_AI_IDEA_BACKGROUND};
      color: ${({ theme }) => theme?.COLORS?.EVENT_AI_IDEA_TEXT};
    }
    &.scheduled {
      background-color: ${({ theme }) => theme?.COLORS?.EVENT_SCHEDULED_BACKGROUND};
      color: ${({ theme }) => theme?.COLORS?.EVENT_SCHEDULED_TEXT};
    }
    &.sent {
      background-color: ${({ theme }) => theme?.COLORS?.EVENT_SENT_BACKGROUND};
      color: ${({ theme }) => theme?.COLORS?.EVENT_SENT_TEXT};
    }
    &.recurring {
      background-color: ${({ theme }) => theme?.COLORS?.EVENT_RECURRING_BACKGROUND};
      color: ${({ theme }) => theme?.COLORS?.EVENT_RECURRING_TEXT};
    }
    &.submitted-for-approval {
      background-color: ${({ theme }) => theme?.COLORS?.EVENT_NEEDS_APPROVAL_BACKGROUND};
      color: ${({ theme }) => theme?.COLORS?.EVENT_NEEDS_APPROVAL_TEXT};
    }
  }

  // Adds pointer cursor to day cells
  .rbc-day-bg {
    cursor: pointer;
  }
  // Disables pointer cursor for days before today
  .rbc-day-bg.before-today {
    cursor: auto;
  }
`

export const GlobalStyle = createGlobalStyle`
  ${ThemeCssVars}

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  }

  html {
    position: fixed;
    inset: 0px;
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }

  body {
    position: fixed;
    inset: 0px;
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }

  #root {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme?.COLORS?.HEADLINES};
  }

  input,
  textarea {
    color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT};
    background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};

    &::placeholder {
      color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT_PLACEHOLDER};
    }
  }

  // Hide filestack filter and deselect all
  .fsp-summary__filter, .fsp-footer__nav--left .fsp-button--cancel {
    display: none;
  }

  [data-whatintent='mouse'] *:focus {
    outline: none;
  }

  ${ReactBigCalendarOverrides}
`

export const StyledApp = styled.main`
  max-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  min-width: 280px;
`
