import { Api } from '@community_dev/types'
import noop from 'lodash/noop'
import React, { useContext, useEffect, useState } from 'react'

import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { CAPABILITIES } from 'constants/capabilities'
import { useHasCapability } from 'hooks/useUserCapability'

export enum AnalyticsEventSuggested {
  FULL = 'full',
  PARTIAL = 'partial',
}

export enum AnalyticsEventSuggestMode {
  FULL = 'full',
  QUICK = 'quick',
}

export const initialState: AiMessageGeneratorContextValue = {
  capabilityEnabled: false,
  aiMessageGeneratorPopoverOpen: false,
  setAiMessageGeneratorPopoverOpen: noop,
  aiMessageGeneratorOpen: false,
  setAiMessageGeneratorOpen: noop,
  tone: '',
  setTone: noop,
  keywords: '',
  setKeywords: noop,
  additionalDetails: '',
  setAdditionalDetails: noop,
  goal: undefined,
  setGoal: noop,
  link: '',
  setLink: noop,
  suggestions: undefined,
  setSuggestions: noop,
  requestId: undefined,
  setRequestId: noop,
  analyticsEventSuggested: undefined,
  setAnalyticsEventSuggested: noop,
  analyticsEventSuggestMode: undefined,
  setAnalyticsEventSuggestMode: noop,
  insertedSuggestedMessage: undefined,
  setInsertedSuggestedMessage: noop,
  reset: noop,
}

export type AiMessageGeneratorContextValue = {
  capabilityEnabled: boolean
  aiMessageGeneratorPopoverOpen: boolean
  setAiMessageGeneratorPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>
  aiMessageGeneratorOpen: boolean
  setAiMessageGeneratorOpen: React.Dispatch<React.SetStateAction<boolean>>
  tone: string | undefined
  setTone: React.Dispatch<React.SetStateAction<string | undefined>>
  keywords: string | undefined
  setKeywords: React.Dispatch<React.SetStateAction<string | undefined>>
  additionalDetails: string | undefined
  setAdditionalDetails: React.Dispatch<React.SetStateAction<string | undefined>>
  goal: Partial<Api.V1.RequestGoal> | undefined
  setGoal: React.Dispatch<React.SetStateAction<Partial<Api.V1.RequestGoal> | undefined>>
  link: string | undefined
  setLink: React.Dispatch<React.SetStateAction<string | undefined>>
  suggestions: Api.V1.SuggestResponseSuggestion[] | undefined
  setSuggestions: React.Dispatch<React.SetStateAction<Api.V1.SuggestResponseSuggestion[] | undefined>>
  requestId: string | undefined
  setRequestId: React.Dispatch<React.SetStateAction<string | undefined>>
  analyticsEventSuggested: AnalyticsEventSuggested | undefined
  setAnalyticsEventSuggested: React.Dispatch<React.SetStateAction<AnalyticsEventSuggested | undefined>>
  analyticsEventSuggestMode: AnalyticsEventSuggestMode | undefined
  setAnalyticsEventSuggestMode: React.Dispatch<React.SetStateAction<AnalyticsEventSuggestMode | undefined>>
  insertedSuggestedMessage: string | undefined
  setInsertedSuggestedMessage: React.Dispatch<React.SetStateAction<string | undefined>>
  reset: () => void
}

export const AiMessageGeneratorContext = React.createContext<AiMessageGeneratorContextValue>(initialState)

AiMessageGeneratorContext.displayName = 'AiMessageGeneratorContext'

export function useAiMessageGenerator(): AiMessageGeneratorContextValue {
  const context = useContext(AiMessageGeneratorContext)

  if (!context) {
    throw new Error('useAiMessageGenerator must be used within a AiMessageGeneratorProvider')
  }

  return context
}

type AiMessageGeneratorProviderProps = {
  children?: React.ReactNode
}

export function AiMessageGeneratorProvider({ children }: AiMessageGeneratorProviderProps): JSX.Element {
  const { text } = useCompose()

  const [aiMessageGeneratorPopoverOpen, setAiMessageGeneratorPopoverOpen] = useState(false)
  const [aiMessageGeneratorOpen, setAiMessageGeneratorOpen] = useState(false)
  const [tone, setTone] = useState<string>()
  const [keywords, setKeywords] = useState<string>()
  const [additionalDetails, setAdditionalDetails] = useState<string>()
  const [goal, setGoal] = useState<Partial<Api.V1.RequestGoal>>()
  const [link, setLink] = useState<string>()
  const [suggestions, setSuggestions] = useState<Api.V1.SuggestResponseSuggestion[]>()
  const [requestId, setRequestId] = useState<string>()

  const [analyticsEventSuggested, setAnalyticsEventSuggested] = useState<AnalyticsEventSuggested>()
  const [analyticsEventSuggestMode, setAnalyticsEventSuggestMode] = useState<AnalyticsEventSuggestMode>()
  const [insertedSuggestedMessage, setInsertedSuggestedMessage] = useState<string>()

  const capabilityEnabled = useHasCapability(CAPABILITIES.FEATURE.AI_MESSAGE_GENERATOR.ALL)

  useEffect(() => {
    if (insertedSuggestedMessage === text) {
      setAnalyticsEventSuggested(AnalyticsEventSuggested.FULL)
    } else if (insertedSuggestedMessage && text) {
      setAnalyticsEventSuggested(AnalyticsEventSuggested.PARTIAL)
    } else if (!text) {
      setAnalyticsEventSuggested(undefined)
      setAnalyticsEventSuggestMode(undefined)
      setInsertedSuggestedMessage(undefined)
    }
  }, [text])

  const reset = () => {
    setTone(undefined)
    setKeywords(undefined)
    setAdditionalDetails(undefined)
    setGoal(undefined)
    setLink(undefined)
    setRequestId(undefined)
    setSuggestions(undefined)
    setAnalyticsEventSuggested(undefined)
    setAnalyticsEventSuggestMode(undefined)
    setInsertedSuggestedMessage(undefined)
  }

  return (
    <AiMessageGeneratorContext.Provider
      value={{
        capabilityEnabled,
        aiMessageGeneratorPopoverOpen,
        setAiMessageGeneratorPopoverOpen,
        aiMessageGeneratorOpen,
        setAiMessageGeneratorOpen,
        tone,
        setTone,
        keywords,
        setKeywords,
        additionalDetails,
        setAdditionalDetails,
        goal,
        setGoal,
        link,
        setLink,
        suggestions,
        setSuggestions,
        requestId,
        setRequestId,
        analyticsEventSuggested,
        setAnalyticsEventSuggested,
        analyticsEventSuggestMode,
        setAnalyticsEventSuggestMode,
        insertedSuggestedMessage,
        setInsertedSuggestedMessage,
        reset,
      }}
    >
      {children}
    </AiMessageGeneratorContext.Provider>
  )
}
