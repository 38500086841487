import { WorkflowType } from '@community_dev/workflow-manifest'

import { Workflow } from 'api/workflows'
import { WORKFLOW_ID_UNSAVED } from 'constants/workflow'

export const shouldAllowScheduling = (workflow: Workflow): boolean => {
  const { id, type } = workflow
  const isKeywordFlow = type === WorkflowType.keyword

  // We only want to show the scheduling option for keyword workflows
  if (!isKeywordFlow) return false

  // We don't want to allow scheduling for unsaved workflows
  if (id === WORKFLOW_ID_UNSAVED) return false

  return true
}
