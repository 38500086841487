import { HTTP } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

type InvitationSeat = {
  active: boolean
  client: {
    clientImage: string
    firstName: string | null
    lastName: string | null
  }
  seat: {
    email: string
    id: null
  }
}

export function getInvitationSeat({ token }: { token: string }): Promise<InvitationSeat> {
  return request(route(ENDPOINTS.INVITES.BY_TOKEN, { token })).then(({ data }) => data)
}

export function sendInvitation({
  email,
  role,
  clientId,
}: {
  email: string
  role: string
  clientId: string
}): Promise<unknown> {
  return request(route(ENDPOINTS.INVITES.INVITES, { clientId }), { body: { email, role }, method: HTTP.POST })
}

export function deleteInvitation({
  clientId,
  invitationId,
}: {
  clientId: string
  invitationId: string
}): Promise<unknown> {
  return request(route(ENDPOINTS.INVITES.INVITE, { clientId, inviteId: invitationId }), { method: HTTP.DELETE })
}
