import { Button } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledOutlineButton = styled(Button)`
  background: none;
  color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};

  &:hover {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  }

  &:disabled,
  &[aria-disabled='true'] {
    color: ${({ theme }) => theme?.COLORS?.BORDERS};
  }
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  cursor: pointer;
  text-decoration: none;
`
