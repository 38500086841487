import { ChevronRightIcon } from '@community_dev/pixels'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { WebFormIcon } from './components/Icons/WebFormIcon'
import { useMguQuery } from './queries'

import { ROUTES } from 'constants/routes'
import { useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { Setting } from 'layouts/SettingsLayout'
import { route } from 'utils/router'

type StyledCommunityProps = {
  $color?: string
}

const StyledCommunity = styled.button<StyledCommunityProps>`
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  cursor: pointer;
  color: ${({ theme }) => theme?.COLORS?.TEXT};

  &::before {
    content: '';
    display: inline-block;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: ${({ $color }) => $color};
    position: relative;
    top: -1px;
    margin: 0 5px 0 2px;
  }
`

const StyledChevron = styled.div`
  display: inline-block;
  position: relative;
  top: 2px;
  left: -4px;
`

export function Embeds(): JSX.Element {
  const { COLORS } = useTheme() || {}
  const history = useHistory()
  const clientId = useClientId()
  const { t } = useTranslation()
  const { data: webCommunity } = useCommunities({
    select: (communities) => {
      return communities.all.find((c) => c.title === 'Web Visitors')
    },
  })
  const { isInitialLoading, communityCount, embeds } = useMguQuery(clientId, webCommunity)

  function handleAction() {
    const url = embeds[0]?.id ? ROUTES.SETTINGS.INTEGRATIONS.EDIT_EMBED : ROUTES.SETTINGS.INTEGRATIONS.CREATE_EMBED

    history.push(
      route(url, {
        embedId: embeds[0]?.id,
      }),
    )
  }

  return (
    <>
      <Setting
        action={embeds?.length ? 'Edit' : 'Create'}
        icon={<WebFormIcon />}
        label={t('settings.integrations.signupUnit.addSignupUnit')}
        loading={isInitialLoading}
        onClick={handleAction}
        onClickAction={handleAction}
        text={
          Boolean(embeds?.length) &&
          Boolean(webCommunity) && (
            <div>
              {communityCount} Members added to{' '}
              <StyledCommunity
                $color={webCommunity?.color}
                onClick={() => {
                  history.push(`${ROUTES.COMMUNITIES}/${webCommunity?.id}`)
                }}
              >
                {webCommunity?.title}&nbsp;
                <StyledChevron>
                  <ChevronRightIcon color={COLORS?.TEXT} size={16} />
                </StyledChevron>
              </StyledCommunity>
            </div>
          )
        }
      />
    </>
  )
}
