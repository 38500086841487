import { SPACING } from '@community_dev/pixels'
import styled, { DefaultTheme } from 'styled-components'

type IconColorProps = {
  disabled?: boolean
  selected?: boolean
  $activated?: boolean
  $type?: 'includes' | 'excludes'
  theme: DefaultTheme
}

type IconColorArgs = Pick<IconColorProps, 'disabled' | 'theme'>

export const iconColor = (props: IconColorArgs): string => {
  if (props.disabled) {
    return props.theme?.COLORS?.BORDERS
  }

  return props.theme?.COLORS?.TEXT
}

export const StyledListButton = styled.button<IconColorProps>`
  display: flex;
  align-items: flex-start;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  position: relative;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  text-decoration: none;
  font-weight: 600;
  transition: all 0.2s ease-in;
  color: ${(props) => iconColor(props)};
  border: none;
  background: ${({ theme, selected }) => (selected ? theme?.COLORS?.APP_BACKGROUND_LEVEL_1 : 'initial')};
  padding: 14px;
  width: 100%;
  user-select: none;

  &:hover {
    background: ${({ disabled, selected, theme }) =>
      selected ? theme?.COLORS?.APP_BACKGROUND_LEVEL_1 : disabled ? 'none' : theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  }
`

export const StyledListIcon = styled.div<IconColorProps>`
  margin-right: 8px;
  font-size: 11px;
  color: ${(props) => iconColor(props)};
`

export const StyledListCommunityIcon = styled.div`
  position: relative;
  top: 1px;
  margin-left: -5px;
  margin-right: 2px;
`

export const ActivatedItemIndicator = styled.div`
  background: ${({ theme }) => theme?.COLORS?.TEXT};
  height: 4px;
  width: 4px;
  border-radius: 50%;
  position: absolute;
  right: 14px;
`

export const StyledListHeader = styled.li`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.OVERLINE};
  margin: ${SPACING[5]} 0 ${SPACING[2]} ${SPACING[4]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`
