import { useScript } from '@community_dev/hooks'
import Color from 'color'
import { useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { useTheme } from 'styled-components'

import { useClient } from './useClient'

import { ROUTES } from 'constants/routes'
import { useSeat } from 'contexts/bootstrap/useSeat'

const SCRIPT_SRC = 'https://static.zdassets.com/ekr/snippet.js?key=b565cc30-17e7-41a6-b0d3-4028420e5d84'

export const useZendeskWidget = (): void => {
  const status = useScript(
    SCRIPT_SRC,
    {
      type: 'text/javascript',
      id: 'ze-snippet',
      async: true,
      src: SCRIPT_SRC,
    },
    { cleanup: false, enabled: !window.Cypress },
  )

  const { COLORS } = useTheme() || {}
  const { data: seat } = useSeat()
  const { data: client } = useClient()
  const hasWidgetLoaded = typeof window.zE === 'function' && status === 'ready'
  const shouldShowWidget = Boolean(useRouteMatch([ROUTES.GROWTH_TOOLS, ROUTES.SETTINGS.ROOT]))

  useEffect(() => {
    if (hasWidgetLoaded && client?.id && client?.firstName && seat?.email && seat?.firstName) {
      // initialize zendesk web widget settings
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          launcher: {
            label: {
              '*': 'Help Center',
            },
          },
          color: {
            launcher: Color(COLORS.BUTTON_PRIMARY).hex(),
            launcherText: Color(COLORS.BUTTON_PRIMARY_TEXT).hex(),
          },
          contactForm: {
            fields: [
              // Client ID
              { id: 20356149909651, prefill: { '*': client.id }, readOnly: true },
              // App Version
              { id: 20189516642451, prefill: { '*': import.meta.env.VITE_APP_VERSION } },
              // Community Leader
              { id: 20355679841427, prefill: { '*': client.firstName } },
            ],
          },
        },
      })
      window.zE('webWidget', 'prefill', {
        email: {
          value: seat.email,
          readOnly: true,
        },
        name: {
          value: `${seat.firstName} ${seat.lastName}`,
        },
      })
      if (shouldShowWidget) {
        window.zE('webWidget', 'show')
      } else {
        window.zE('webWidget', 'hide')
      }
    }
  }, [
    COLORS.BUTTON_PRIMARY,
    COLORS.BUTTON_PRIMARY_TEXT,
    client?.firstName,
    client?.id,
    hasWidgetLoaded,
    seat?.email,
    seat?.firstName,
    seat?.lastName,
    shouldShowWidget,
  ])
}
