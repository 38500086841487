import { ApiError } from '@community_dev/requests'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { useClientId } from './useClient'

import { ScheduledCampaignResponse, getScheduledRecurringCampaigns } from 'api/scheduled'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'

export const useScheduledRecurringCampaignsQuery = <SelectData = ScheduledCampaignResponse>(
  options?: UseQueryOptions<ScheduledCampaignResponse, ApiError, SelectData>,
): UseQueryResult<SelectData, ApiError> => {
  const clientId = useClientId()

  return useQuery({
    queryKey: [QUERY_CACHE.SCHEDULED.RECURRING, { clientId }],
    queryFn: () => getScheduledRecurringCampaigns({ clientId }),
    enabled: !!clientId,
    staleTime: STALE_TIME.ONE_MINUTE,
    ...options,
  })
}
