import { Filter } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { useCampaignResponseFilterState } from 'contexts/CampaignResponseFilterProvider'

const StyledMessageFilter = styled.div`
  border-radius: 50%;
  border-color: ${({ theme }) => theme?.COLORS?.BORDERS};
  border-width: 1px;
  border-style: solid;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ResponseFilters = (): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const responsesFilterLabel = (
    <StyledMessageFilter data-testid="chain-responses-msg-filter">
      <FontAwesomeIcon icon="ellipsis-h" style={{ color: COLORS?.DEPRECATED_SENT }} />
    </StyledMessageFilter>
  )

  const { tagFilters, unsetTagFilter, applyTagFilter }: any = useCampaignResponseFilterState()

  const hasReplied = tagFilters.get('replied-to') === 'all'
  const hasNotReplied = tagFilters.get('replied-to') === 'not'
  const allSelected = !tagFilters.has('replied-to')

  const handleAllClick = () => {
    unsetTagFilter('replied-to')
  }
  const handleHasRepliedClick = () => {
    applyTagFilter('replied-to', 'all')
  }
  const handleHasNotRepliedClick = () => {
    applyTagFilter('replied-to', 'not')
  }

  return (
    <Filter align="right" icon={false} label={responsesFilterLabel}>
      <Filter.Option onClick={handleAllClick} selected={allSelected}>
        All
      </Filter.Option>
      <Filter.Option onClick={handleHasRepliedClick} selected={hasReplied}>
        People I've Replied To
      </Filter.Option>
      <Filter.Option onClick={handleHasNotRepliedClick} selected={hasNotReplied}>
        People I Haven't Replied To
      </Filter.Option>
    </Filter>
  )
}
