import React from 'react'
import styled from 'styled-components'

import { TABLET_BREAK } from 'constants/theme'
import SimpleLayout from 'layouts/SimpleLayout'

type BoxFormLayoutProps = {
  children: React.ReactNode
  maxWidth?: string
}

const StyledRoot = styled.div<{ $maxWidth: string }>`
  padding: 12px;
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth};
`

export const StyledMain = styled.div`
  width: 100%;
  padding-top: 24px;
  @media (min-width: ${TABLET_BREAK}) {
    padding-top: 60px;
  }
`

function BoxFormLayout({ children, maxWidth = '600px' }: BoxFormLayoutProps): JSX.Element {
  return (
    <SimpleLayout>
      <StyledRoot $maxWidth={maxWidth}>
        <StyledMain>{children}</StyledMain>
      </StyledRoot>
    </SimpleLayout>
  )
}

export default BoxFormLayout
