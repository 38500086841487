import { useFlexibleCanvas } from '@community_dev/flexible-canvas'
import { useEffect, useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { ROUTES } from 'constants/routes'

const ROUTES_WITH_WORKFLOW_ID = Object.values(ROUTES.AUTOMATIONS).filter((path) => path.includes(':workflowId'))

/**
 * Reset the flexible canvas when the workflow changes
 */
export function useResetFlexibleCanvas(): void {
  const match = useRouteMatch<{ workflowId?: string }>(ROUTES_WITH_WORKFLOW_ID)
  const workflowId = useMemo(() => match?.params?.workflowId, [match])

  const { reset: resetFlexibleCanvas } = useFlexibleCanvas()

  useEffect(() => {
    if (workflowId) {
      resetFlexibleCanvas()
    }
  }, [workflowId])
}
