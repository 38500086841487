import {
  BUTTON_VARIANTS,
  Button,
  CheckIcon2,
  CircleMinusIcon,
  DIALOG_VARIANTS,
  Dialog,
  Layout,
  SPACING,
} from '@community_dev/pixels'
import { CampaignStatus } from '@community_dev/types/lib/api/v2/Campaign'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { toScheduledCampaignLabel } from './ScheduledList'

import { postScheduledMessageApprovalStatus } from 'api/message'
import { ScheduledCampaign, deleteScheduledCampaign } from 'api/scheduled'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { ComposeRole, useComposeRole } from 'components/ComposeMessage/hooks/useComposeRole'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useToastMessage } from 'hooks/useToastMessage'

const StyledCancelButton = styled(Button)`
  border-radius: 30px;
  display: flex;
  align-items: center;
  font-size: 12px;
  svg {
    margin-right: ${SPACING[1]};
    path {
      fill: ${({ theme }) => theme?.COLORS?.BUTTON_OUTLINED_TEXT};
    }
  }
`

const StyledApproveButton = styled(Button)`
  border-radius: 30px;
  display: flex;
  align-items: center;
  margin-left: ${SPACING[3]};

  svg {
    margin-right: ${SPACING[1]};
    path {
      fill: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY_TEXT};
    }
  }
`

type CampaignFiltersHeaderProps = {
  campaign: ScheduledCampaign
}

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  position: sticky;
  top: 0;
  overflow: hidden;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  z-index: 1;
  padding: 0 ${SPACING[5]};
  flex-shrink: 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

export function CampaignFiltersHeader({ campaign }: CampaignFiltersHeaderProps): JSX.Element | null {
  const queryClient = useQueryClient()
  const { data: communities } = useCommunities()
  const text = toScheduledCampaignLabel(campaign, communities)
  const [showCancel, setShowCancel] = useState(false)
  const clientId = useClientId()
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const { mutate: mutateDeleteScheduledCampaign } = useMutation(deleteScheduledCampaign, {
    onSuccess() {
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.LIST, { clientId }])
      showToastMessage({
        message: t('scheduled.deletedCampaign'),
        position: 'bottom-right',
      })
    },
    onError() {
      showToastMessage({
        message: t('scheduled.errorDeletingCampaign'),
        success: false,
        position: 'bottom-right',
      })
    },
  })

  const { mutate: mutateApproveCampaign } = useMutation(postScheduledMessageApprovalStatus, {
    onSuccess() {
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.LIST, { clientId }])
      showToastMessage({
        message: t('scheduled.campaignApproved'),
        position: 'bottom-right',
      })
    },
    onError() {
      showToastMessage({
        message: t('scheduled.errorApprovingCampaign'),
        success: false,
        position: 'bottom-right',
      })
    },
  })

  const isCampaignWriter = useComposeRole() === ComposeRole.CAMPAIGN_WRITER

  const recurrence = campaign?.recurrence
  const needsApproval = campaign?.status === CampaignStatus.SUBMITTED_FOR_APPROVAL

  return (
    <>
      <StyledHeader>
        <p>
          <span>{t('to')}: </span>
          {text}
          <CommunicationChannelIcon
            communicationChannel={campaign.communicationChannel}
            css={`
              margin-left: 5px;
              vertical-align: middle;
            `}
            size={16}
          />
        </p>
        {isCampaignWriter ? (
          <Layout display="flex">
            <StyledCancelButton onClick={() => setShowCancel(true)} variant={BUTTON_VARIANTS.OUTLINE}>
              <CircleMinusIcon size={16} />
              {t('scheduled.cancelCampaign')}
            </StyledCancelButton>
            {needsApproval ? (
              <StyledApproveButton
                onClick={() => mutateApproveCampaign({ clientId, campaignId: campaign.id, approvalStatus: 'approve' })}
                variant={BUTTON_VARIANTS.INLINE_ACTION}
              >
                <CheckIcon2 />
                {t('scheduled.approveCampaign')}
              </StyledApproveButton>
            ) : null}
          </Layout>
        ) : null}
      </StyledHeader>
      {showCancel && (
        <Dialog
          maxWidth={330}
          message={t(recurrence ? 'scheduled.dialogContentRecurring' : 'scheduled.dialogContentScheduled')}
          onCancel={() => setShowCancel(false)}
          title={t(recurrence ? 'scheduled.dialogHeadingRecurring' : 'scheduled.dialogHeadingScheduled')}
        >
          <Dialog.Action onClick={() => setShowCancel(false)}>{t('back')}</Dialog.Action>
          <Dialog.Action
            onClick={() => {
              mutateDeleteScheduledCampaign({ clientId, id: campaign.id })
              setShowCancel(false)
            }}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            {t(recurrence ? 'scheduled.deleteRecurringCampaign' : 'scheduled.deleteScheduledCampaign')}
          </Dialog.Action>
        </Dialog>
      )}
    </>
  )
}
