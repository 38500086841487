import { useContext, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LoginError, deleteLogout, isLoginError, postLogin, responseHasSetupCodes } from 'api/login'
import { postMfaCode } from 'api/multi-factor-auth'
import { ROUTES } from 'constants/routes'
import { AuthContext, AuthActionTypes, AuthErrors, AuthProviderValue, AuthStates } from 'contexts/AuthProvider'
import Sentry from 'integrations/Sentry'
import analytics from 'utils/analytics'

export type UseAuth = AuthProviderValue & {
  error?: string
  isLoading: boolean
  login: (email: string, password: string) => Promise<void>
  logout: () => void
  sendMfa: (totp: string) => Promise<void>
}

export function useAuth(): UseAuth {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const auth = useContext(AuthContext)
  if (auth === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  const login = useCallback(
    async (email, password) => {
      setIsLoading(true)
      try {
        const rawResp = await postLogin({ body: { email, password } })
        const resp = await rawResp.json()
        if (rawResp.status === 206) {
          auth.transition({
            type: AuthActionTypes.MFA_REQUIRED,
            payload: {
              clientId: resp.client_id,
              mfaToken: resp.token,
            },
          })
        } else {
          auth.transition({
            type: AuthActionTypes.LOGIN_SUCCESS,
            payload: {
              clientId: resp.client_id,
              token: resp.token,
            },
          })
        }
      } catch (e) {
        const err = e as Error | LoginError
        let errorKey
        if (isLoginError(err)) {
          if (err.errors?.[0]?.field === 'fetch_token') {
            errorKey = AuthErrors.ACTIVATION_EXPIRED
          } else if (err.errors?.[0]?.field === 'email') {
            errorKey = AuthErrors.EMAIL_USED
          } else if (err.status === 409) {
            const errorBody = await (err as LoginError).response?.json()
            // In this case, 2FA is required for a client, but the seat hasn’t set it up yet.
            // If the response contains the necessary information to set up 2FA
            if (responseHasSetupCodes(errorBody)) {
              auth.transition({
                type: AuthActionTypes.MFA_SETUP_REQUIRED,
                payload: {
                  authUrl: errorBody?.auth_url,
                  authSecret: errorBody?.auth_secret,
                  mfaToken: errorBody?.token,
                  error: AuthErrors.MFE_REQUIRED,
                },
              })
              setIsLoading(false)
              return
            } else {
              errorKey = AuthErrors.GENERIC
            }
          } else if (err.status === 429) {
            if (err.errors[0]?.message?.toLowerCase().includes('too many failed login attempts')) {
              errorKey = AuthErrors.TOO_MANY_ATTEMPTS
            } else if (err.errors[0]?.message?.toLowerCase().includes('locked out')) {
              errorKey = AuthErrors.MFA_LOCKED_OUT
            }
          } else if (err.status === 500) {
            errorKey = AuthErrors.GENERIC
          } else {
            errorKey = AuthErrors.INVALID_EMAIL_OR_PASSWORD
          }
        } else {
          errorKey = AuthErrors.GENERIC
        }
        auth.transition({ type: AuthActionTypes.LOGIN_FAILED, payload: { error: errorKey } })
        analytics.track(analytics.events.LoginFailure())
      }
      setIsLoading(false)
    },
    [auth],
  )

  const logout = useCallback(() => {
    try {
      if (auth.token) {
        deleteLogout(auth.token)
      }
    } catch (e) {
      Sentry.captureException(e)
    }
    auth.setClientId(undefined)
    analytics.reset()

    if (auth.state !== AuthStates.UNAUTHENTICATED) {
      auth.transition({ type: AuthActionTypes.LOGOUT })
    }

    // we append the search params, so we can use the next query params.
    // we reload the page to clear the state and the token in memory
    window.location.assign(`${import.meta.env.VITE_PUBLIC_URL}${ROUTES.LOGIN}${window.location.search}`)
  }, [auth])

  const sendMfa = useCallback(
    async (totp: string) => {
      setIsLoading(true)
      try {
        const { token, clientId } = await postMfaCode({ totp, token: auth.mfaToken || undefined })
        auth.transition({ type: AuthActionTypes.MFA_SUCCESS, payload: { token, clientId } })
      } catch (e) {
        const error = e as Error | LoginError
        if (isLoginError(error)) {
          auth.transition({
            type: AuthActionTypes.MFA_FAILED,
            payload: { error: error.status === 401 ? AuthErrors.MFA_WRONG : AuthErrors.MFA_GENERIC },
          })
        } else {
          Sentry.captureException(e)
        }
      }

      setIsLoading(false)
    },
    [auth],
  )

  return { ...auth, error: auth.error ? t(auth.error) : undefined, isLoading, login, logout, sendMfa }
}
