import { Layout } from '@community_dev/pixels'
import React, { useEffect, useRef, useState } from 'react'
import Measure from 'react-measure'
import styled, { css } from 'styled-components'

import { SidebarLayoutFloatingContainer } from './SidebarLayoutFloatingContainer'

import { ActionPanelRenderer } from 'components/ActionPanel/ActionPanelRenderer'
import { AppBanner } from 'components/AppBanner/AppBanner'
import {
  DESKTOP_BREAK,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EASING,
  SIDEBAR_MOBILE_WIDTH,
  SIDEBAR_TRANSITION_SPEED,
  SIDEBAR_WIDTH,
} from 'constants/theme'
import Navbar from 'containers/Navbar'
import Sidebar from 'containers/Sidebar'
import { useSidebar } from 'contexts/SidebarProvider'
import { useAppBanners } from 'hooks/useAppBanner'

type StyledSidebarLayoutProps = {
  $opened?: boolean
}

const StyledSidebarLayout = styled.div<StyledSidebarLayoutProps>`
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  + .AppToast-root {
    transition: transform 0.25s ease;
    @media (min-width: ${DESKTOP_BREAK}) {
      transition: none;
    }
  }

  ${({ $opened }) =>
    $opened &&
    css`
      + .AppToast-root {
        transform: translateX(${SIDEBAR_MOBILE_WIDTH});
        opacity: 0;
        @media (min-width: ${DESKTOP_BREAK}) {
          transform: none;
        }
      }
    `}
`

type StyledMainProps = {
  $greyBg?: boolean
  $collapsed?: boolean
  $opened?: boolean
}

export const StyledMain = styled.div<StyledMainProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // We are intentionally using absolute positioning instead of transform / translateX,
  // because of an issue with text selection and transformed elements.
  // See https://issues.chromium.org/issues/41439320
  transition: left ${SIDEBAR_TRANSITION_SPEED} ${SIDEBAR_EASING}, width ${SIDEBAR_TRANSITION_SPEED} ${SIDEBAR_EASING};
  background: ${({ $greyBg, theme }) =>
    $greyBg ? theme?.COLORS?.APP_BACKGROUND_LEVEL_2 : theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  pointer-events: auto;
  touch-action: auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: hidden auto;
  width: 100%;
  left: 0px;

  @media (min-width: ${DESKTOP_BREAK}) {
    width: ${({ $collapsed }) => `calc(100% - ${$collapsed ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_WIDTH})`};
    left: ${({ $collapsed }) => `${$collapsed ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_WIDTH}`};
  }

  ${({ $opened }) =>
    $opened &&
    css`
      overflow: hidden;
      left: ${SIDEBAR_MOBILE_WIDTH};
    `}
`

const StyledDimmer = styled.div<{ $visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity ${SIDEBAR_TRANSITION_SPEED} ${SIDEBAR_EASING};
  pointer-events: ${({ $visible }) => ($visible ? 'auto' : 'none')};
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  z-index: 2;

  @media (min-width: ${DESKTOP_BREAK}) {
    display: none;
    visibility: hidden;
  }
`

type SidebarLayoutProps = {
  children: React.ReactNode
  hideNavbar?: boolean
  greyBg?: boolean
}

export const SidebarLayout = ({ children, hideNavbar = false, greyBg = false }: SidebarLayoutProps): JSX.Element => {
  const dimmerRef = useRef<HTMLDivElement>(null)
  const [navbarHeight, setNavbarHeight] = useState(0)
  const [warningHeight, setWarningHeight] = useState(0)
  const appBanners = useAppBanners()
  const { setCollapsed, setOpened, collapsed, opened } = useSidebar()

  useEffect(() => {
    const onResize = () => {
      if (opened && window.innerWidth >= 1080) {
        setOpened(false)
      }

      if (collapsed && window.innerWidth < 1080) {
        setTimeout(() => setCollapsed(false), 300)
      }
    }

    const onDimmerTouch = (e: Event) => {
      if (e.target === dimmerRef.current) {
        e.preventDefault()
        setOpened(false)
        return false
      }
    }

    window.addEventListener('resize', onResize)
    window.addEventListener('touchstart', onDimmerTouch, {
      passive: false,
    })

    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('touchstart', onDimmerTouch)
    }
  }, [opened, collapsed, setOpened, setCollapsed])

  return (
    <StyledSidebarLayout $opened={opened}>
      <StyledMain $collapsed={collapsed} $greyBg={greyBg} $opened={opened}>
        {!hideNavbar && (
          <Measure
            bounds
            onResize={({ bounds }) => {
              if (bounds) {
                setNavbarHeight(bounds.height)
              }
            }}
          >
            {({ measureRef }) => (
              <div ref={measureRef} style={{ zIndex: 2 }}>
                <Navbar opened={opened} />
              </div>
            )}
          </Measure>
        )}
        {appBanners.length > 0 ? (
          <Measure
            bounds
            onResize={({ bounds }) => {
              if (bounds) {
                setWarningHeight(bounds.height)
              }
            }}
          >
            {({ measureRef }) => (
              <div data-testid="app-banners" ref={measureRef} style={{ zIndex: 2 }}>
                {appBanners.map((props, index) => (
                  <AppBanner key={index} {...props} />
                ))}
              </div>
            )}
          </Measure>
        ) : null}
        <Layout display="flex" minHeight={`calc(100% - ${navbarHeight + warningHeight || 0}px)`} position="relative">
          {children}
          <ActionPanelRenderer />
        </Layout>
      </StyledMain>
      <StyledDimmer $visible={opened} data-testid="sidebar-overlay" onClick={() => setOpened(false)} ref={dimmerRef} />
      <Sidebar />
    </StyledSidebarLayout>
  )
}

SidebarLayout.FloatingContainer = SidebarLayoutFloatingContainer
