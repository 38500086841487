import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'

/**
 * This simple plugin will autofocus the compose editor when rendered
 */
export function useAutoFocusPlugin(): null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => editor.focus(), [editor])

  return null
}
