import React from 'react'

import { IconProps } from './IconProps'

export const MembersIcon = ({ size = 18, color = 'currentColor' }: IconProps): JSX.Element => {
  const width = size * 1.58
  const height = size
  return (
    <svg fill="none" height={height} viewBox="0 0 19 12" width={width} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4454 5.84831C13.9817 5.84831 15.163 4.53792 15.163 2.90479C15.163 1.35557 13.9688 0 12.4454 0C10.9285 0 9.72781 1.35557 9.73427 2.90479C9.74072 4.53792 10.9091 5.84831 12.4454 5.84831ZM4.84131 5.99032C6.17751 5.99032 7.20387 4.84131 7.20387 3.42119C7.20387 2.07208 6.15815 0.890802 4.84131 0.890802C3.51802 0.890802 2.4723 2.07208 2.47875 3.42119C2.49166 4.84131 3.49866 5.99032 4.84131 5.99032ZM1.4653 12H6.00968C5.23507 10.9607 5.6482 9.05003 7.41044 7.57181C6.75202 7.21033 5.82248 6.93276 4.84131 6.93276C2.09145 6.93276 0 8.94675 0 10.5282C0 11.4384 0.348574 12 1.4653 12ZM8.5659 11.9871H16.3249C17.6095 11.9871 18.0097 11.5159 18.0097 10.7864C18.0097 9.15331 15.7891 6.93921 12.4454 6.93921C9.10167 6.93921 6.88112 9.15331 6.88112 10.7864C6.88112 11.5159 7.28779 11.9871 8.5659 11.9871Z"
        fill={color}
      />
    </svg>
  )
}
