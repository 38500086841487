import { PREVIEW_MODES } from '../../../constants/embed'

import { DesktopPreview } from './DesktopPreview'
import { MobilePreview } from './MobilePreview'

import { JsonConfig } from 'api/mgu'

export type PreviewProps = {
  mode: string
  config: JsonConfig
}

export const Preview = ({ mode, config }: PreviewProps): JSX.Element => {
  return mode === PREVIEW_MODES.MOBILE ? <MobilePreview jsonConfig={config} /> : <DesktopPreview jsonConfig={config} />
}
