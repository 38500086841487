import {
  AiBubble,
  BUTTON_VARIANTS,
  Typography,
  Button,
  DMSuggestions,
  DMSuggestionsItem,
  Layout,
} from '@community_dev/pixels'
import { convertKeysToSnakeCase } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { STATUS } from '@community_dev/types/lib/api/v2/TCR'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { postSuggestDms } from 'api/ai-interface'
import { QUERY_CACHE } from 'constants/query-cache'
import { useAiMessageGenerator } from 'contexts/AIMessageGeneratorProvider'
import { useConvo } from 'contexts/ConvoProvider'
import { useTCRContext } from 'contexts/TCRProvider'
import { useCanSendDms } from 'hooks/useCanSendDms'
import { useClient, useClientId } from 'hooks/useClient'
import { useFan } from 'hooks/useFan'
import { FileContext, useMediaConfig } from 'hooks/useMediaConfig'
import { useMessageHistory } from 'hooks/useMessageHistory'
import { useSendDM } from 'hooks/useSendDM'
import Sentry from 'integrations/Sentry'
import analytics from 'utils/analytics'
import { formatFullName } from 'utils/formatters'

type ConvoDMSuggestionsProps = {
  fanId: string
}

const StyledEditButton = styled(Button)`
  min-width: 0px;
  width: unset;
  height: 27px;
  padding: 0px 8px;
  margin-left: 8px;
  margin-right: 16px;

  background-color: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_3};

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_3};
  }
`

const StyledSendButton = styled(Button)`
  min-width: 0px;
  height: 27px;
  padding: 0px 8px;

  background-color: ${({ theme }) => theme.COLORS.LINKS};
  border-color: ${({ theme }) => theme.COLORS.LINKS};

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.LINKS};
    border-color: ${({ theme }) => theme.COLORS.LINKS};
  }
`

const SUGGESTION_COUNT = 3
const SUGGESTION_MAX_LENGTH = 100

export function ConvoDMSuggestions(props: ConvoDMSuggestionsProps): JSX.Element | null {
  const { fanId } = props
  const { hideAiSuggestions, replyInput, setHideAiSuggestions, setReplyBody, setUsedAiSuggestion } = useConvo()
  const { t } = useTranslation()
  const { data: client } = useClient()
  const clientId = useClientId()
  const { messageHistory, lastMessage } = useMessageHistory({ fanId })
  const { data: fan } = useFan(fanId)
  const { mutateAsync: sendDM } = useSendDM({})
  const { capabilityEnabled } = useAiMessageGenerator()
  const { status: tcrStatus } = useTCRContext()
  const canReceiveReply = useCanSendDms({
    communicationChannel: fan?.communicationChannel,
    stateFlags: fan?.stateFlags,
    conversationWindowEndAt: fan?.conversationWindowEndAt,
  })

  const communicationChannel = fan?.communicationChannel || CommunicationChannel.SMS

  const { mediaDisposition } = useMediaConfig({
    communicationChannel,
    context: FileContext.DM,
  })

  useEffect(() => {
    setHideAiSuggestions(false)
  }, [fanId, setHideAiSuggestions])

  const showSuggestedDMs = useMemo(() => {
    if (!canReceiveReply) {
      return false
    }

    if (
      client?.phoneNumberType === 'ten_dlc' &&
      tcrStatus !== STATUS.APPROVED &&
      communicationChannel === CommunicationChannel.SMS
    ) {
      return false
    }

    if (!capabilityEnabled) {
      return false
    }

    if (lastMessage?.inbound === false) {
      return false
    }

    if (lastMessage?.media) {
      return false
    }

    return true
  }, [canReceiveReply, tcrStatus, communicationChannel, capabilityEnabled, lastMessage?.inbound, lastMessage?.media])

  const {
    data: suggestions,
    isLoading,
    isError,
  } = useQuery({
    enabled: showSuggestedDMs && Boolean(fan),
    queryKey: [QUERY_CACHE.AI_INTERFACE.SUGGEST_DMS, fanId, lastMessage?.createdAt],
    // only get new suggestions once the queryKey changes after a new message is received from the fan
    staleTime: Infinity,
    retry: 3,
    queryFn: () =>
      postSuggestDms({
        clientId,
        body: {
          client_name: formatFullName(client),
          max_length: SUGGESTION_MAX_LENGTH,
          count: SUGGESTION_COUNT,
          message_history: convertKeysToSnakeCase(messageHistory),
          fan_name: fan?.fullName,
          fan_subscription_id: fan?.fanSubscriptionId,
        },
      }),
  })

  if (!showSuggestedDMs) {
    return null
  }

  if (isError) {
    return null
  }

  if (!isLoading && !suggestions?.data?.suggestions?.length) {
    return null
  }

  return (
    <Layout
      marginBottom="16px"
      marginLeft="auto"
      maxWidth="100%"
      opacity={hideAiSuggestions ? 0 : 1}
      pointerEvents={hideAiSuggestions ? 'none' : 'all'}
      transition=".2s opacity"
      width="550px"
    >
      <Layout alignItems="center" display="flex" margin="32px 0px 10px">
        <AiBubble isPlaying={!!isLoading} style={{ width: 22, height: 22, marginRight: 5 }} />

        <Typography fontSize="14px" fontWeight="700" margin="0px" variant="body2">
          {t('aiMessageGenerator.suggestedResponses')}:
        </Typography>
      </Layout>
      <DMSuggestions data-testid="dm-suggestions">
        {[...new Array(SUGGESTION_COUNT)].map((_, i) => {
          const text = suggestions?.data?.suggestions[i]?.text
          if (!isLoading && !text) {
            return null
          }

          const onSuggestionClick = (e) => {
            analytics.track(analytics.events.GPT.AIMessageOnDmPressed())
            onEditClick(e)
          }

          const onEditClick = (e) => {
            e.preventDefault()
            e.stopPropagation()
            analytics.track(analytics.events.GPT.AIMessageOnDmEditButtonPressed())

            setHideAiSuggestions(true)
            setUsedAiSuggestion(true)
            setReplyBody(text || '')
            setTimeout(() => {
              if (replyInput?.current && text) {
                replyInput.current.focus()
                replyInput.current.selectionStart = text?.length
                replyInput.current.selectionEnd = text?.length
              }
            }, 0)
          }

          const onSendClick = async (e) => {
            e.preventDefault()
            e.stopPropagation()
            analytics.track(analytics.events.GPT.AIMessageOnDmSendNowButtonPressed())

            try {
              await sendDM({
                usedAiSuggestion: true,
                fanId,
                message: {
                  clientId,
                  data: {
                    fan_id: fanId,
                    text: text || '',
                    media: null,
                    shorten_links: false,
                    media_disposition: mediaDisposition,
                  },
                },
              })
            } catch (e) {
              Sentry.captureException(e)
            }
          }

          return (
            <DMSuggestionsItem
              as="button"
              data-testid="dm-suggestions-item"
              key={i}
              loading={isLoading}
              onClick={onSuggestionClick}
              onKeyPress={(e) => {
                if (e.code === 'Enter') {
                  onSuggestionClick(e)
                }
              }}
              renderAction={() => {
                return (
                  <Layout display="block">
                    <StyledEditButton onClick={onEditClick} variant={BUTTON_VARIANTS.OUTLINE}>
                      {t('edit')}
                    </StyledEditButton>
                    <StyledSendButton onClick={onSendClick}>{t('sendNow')}</StyledSendButton>
                  </Layout>
                )
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              {text}
            </DMSuggestionsItem>
          )
        })}
      </DMSuggestions>
    </Layout>
  )
}
