import { useSubscription } from './useSubscription'

import { CAPABILITIES } from 'constants/capabilities'
import { useHasCapability } from 'hooks/useUserCapability'

export const useIsSegmentBasedSubscription = (): {
  isSegmentBasedSubscription: boolean
  isLoading: boolean
} => {
  const isSegmentBasedBilling = useHasCapability(CAPABILITIES.FEATURE.SEGMENT_BILLING.ALL)
  const { data, isInitialLoading: isLoading } = useSubscription()

  const hasSubscription = isSegmentBasedBilling && Boolean(data)

  return { isSegmentBasedSubscription: hasSubscription, isLoading }
}
