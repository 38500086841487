import { Placeholder } from '@community_dev/filter-dsl/lib/subscription-data'
import { Layout, SearchBar, SPACING, Typography } from '@community_dev/pixels'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import sortBy from 'lodash/sortBy'
import { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { INSERT_PLACEHOLDER_COMMAND } from 'components/ComposeEditor'
import { PlaceholderPayload, StyledPlaceholder } from 'components/ComposeEditor/nodes/PlaceholderNode'

const StyledSearchBar = styled(SearchBar)`
  border-radius: 4px;
`

const StyledList = styled.div`
  padding: ${SPACING[2]} 0;
  border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};

  table {
    width: 100%;
    table-layout: auto;
    border-spacing: 0;
    border-collapse: collapse;
  }
`

const StyledScroll = styled.div`
  max-height: 120px;
  overflow: auto;
`

const StyledListItem = styled.tr`
  cursor: pointer;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};

  &:hover,
  &:focus {
    outline: none;
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  }
`

const StyledColumn = styled.td`
  white-space: nowrap;
  min-width: 100px;
  padding: ${SPACING[1]} ${SPACING[4]};

  &:not(:first-of-type) {
    padding-left: 0;
    width: 100%;
  }
`

const StyledFieldName = styled(StyledPlaceholder)`
  ${StyledListItem}:hover &,
  ${StyledListItem}:focus & {
    color: ${({ theme }) => theme?.COLORS?.LINKS};
    text-decoration: underline;
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }
`

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${SPACING[1]};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
`

const MAX_FIELD_NAME_LENGTH = 28

type DynamicFieldListProps = {
  hasReachedLimit?: boolean
  isLoading?: boolean
  placeholders: Placeholder[]
  onInsert?(): void
}

/**
 * This component must be rendered within the context of LexicalComposer
 */
export function DynamicFieldList({
  hasReachedLimit = false,
  isLoading = false,
  placeholders,
  onInsert,
}: DynamicFieldListProps): JSX.Element | null {
  const { t } = useTranslation()
  const { COLORS } = useTheme() || {}
  const [editor] = useLexicalComposerContext()
  const [searchTerm, setSearchTerm] = useState('')
  const sourceOriginMap = useMemo(
    () => ({
      built_in: t('compose.sourceBuiltIn'),
      client: t('compose.sourceClient'),
      shopify_checkout: t('compose.sourceShopifyCheckout'),
      fan: t('compose.sourceFan'),
    }),
    [t],
  )
  const filteredPlaceholders = useMemo(() => {
    if (searchTerm) {
      return sortBy(
        placeholders?.filter((p) => p.name.toLowerCase().includes(searchTerm.toLowerCase())),
        [(p) => p.name.toLowerCase()],
      )
    }

    return sortBy(placeholders, [(p) => p.name.toLowerCase()])
  }, [placeholders, searchTerm])

  if (hasReachedLimit) return null

  function handleInsert({ id, name, source }: PlaceholderPayload) {
    if (editor?.dispatchCommand && !hasReachedLimit) {
      editor.dispatchCommand(INSERT_PLACEHOLDER_COMMAND, {
        id,
        name,
        source,
      })

      if (onInsert) {
        onInsert()
      }
    }
  }

  function handleKeyDown(event: KeyboardEvent<HTMLDivElement>, { id, name, source }: PlaceholderPayload) {
    if (event.key === 'Enter' && editor?.dispatchCommand && !hasReachedLimit) {
      event.preventDefault()
      editor.dispatchCommand(INSERT_PLACEHOLDER_COMMAND, {
        id,
        name,
        source,
      })

      if (onInsert) {
        onInsert()
      }
    }
  }

  function handleSearch(event: ChangeEvent<HTMLInputElement>): void {
    setSearchTerm(event.target.value)
  }

  function truncateFieldName(name: string): string {
    if (name?.length > MAX_FIELD_NAME_LENGTH + 2) {
      return `{${name.slice(1, 29)}...}`
    }

    return `{${name}}`
  }

  return (
    <Layout marginBottom={SPACING[4]} marginTop={`-${SPACING[2]}`}>
      <StyledLabel>{t('compose.insertDynamicField')}</StyledLabel>
      <StyledSearchBar onChange={handleSearch} placeholder={t('compose.searchDynamicFields')} value={searchTerm} />
      <StyledList>
        <StyledScroll>
          <table data-testid="dynamic-field-list">
            {isLoading ? (
              <tr key="content-loader">
                <td>
                  <ContentLoader
                    backgroundColor={COLORS?.APP_BACKGROUND_LEVEL_2}
                    foregroundColor={COLORS?.APP_BACKGROUND_LEVEL_1}
                    height={32}
                    speed={2}
                    viewBox="0 0 440 32"
                    width={440}
                  >
                    <rect height="16" rx="4" width="100" x="0" y="8" />
                    <rect height="16" rx="4" width="320" x="120" y="8" />
                  </ContentLoader>
                </td>
              </tr>
            ) : filteredPlaceholders && filteredPlaceholders?.length > 0 ? (
              filteredPlaceholders?.map(({ key, name, source }: Placeholder) => (
                <StyledListItem
                  key={`${key}:${source}`}
                  onClick={() => handleInsert({ id: key, name, source })}
                  onKeyDown={(event) => handleKeyDown(event, { id: key, name, source })}
                  tabIndex={0}
                >
                  <StyledColumn>
                    <StyledFieldName title={name}>{truncateFieldName(name)}</StyledFieldName>
                  </StyledColumn>
                  <StyledColumn>
                    <Typography component="strong" fontSize="14px" fontWeight={600} variant="body2">{`${t(
                      'compose.source',
                    )}: `}</Typography>
                    <Typography component="span" fontSize="14px" variant="body2">
                      {sourceOriginMap[source] || t('compose.unknown')}
                    </Typography>
                  </StyledColumn>
                </StyledListItem>
              ))
            ) : (
              <tr key="no-results">
                <td>
                  <Typography component="span" fontSize="14px" paddingLeft={SPACING[4]} variant="body2">
                    {t('compose.noResultsFor')}
                    {` "${searchTerm.trim()}"`}
                  </Typography>
                </td>
              </tr>
            )}
          </table>
        </StyledScroll>
      </StyledList>
    </Layout>
  )
}
