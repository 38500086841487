import {
  BUTTON_VARIANTS,
  Button,
  CaretUpIcon,
  ComposeCircleIcon,
  Filter,
  InfiniteScroll,
  InfoIcon,
  Layout,
  LoadingIndicator,
  SPACING,
  SearchBar,
  Tooltip,
  Typography,
} from '@community_dev/pixels'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { useReactTable, createColumnHelper, flexRender, getCoreRowModel, ColumnDef } from '@tanstack/react-table'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled, { css } from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'

import { CampaignCell } from './CampaignCell/CampaignCell'
import { CampaignError, campaignErrorStatuses } from './CampaignError/CampaignError'
import { CampaignsError } from './CampaignsError'
import { EmptyCampaigns } from './EmptyCampaigns'
import { FilterDropdown } from './FilterDropdown'
import { useCampaignList } from './hooks/useCampaignList'
import { useCampaignListFilters } from './hooks/useCampaignListFilters'
import { useSearch } from './hooks/useSearch'

import { Campaign, SortDirection, SortField } from 'api/campaign'
import { CampaignMenu, DuplicateClickProps } from 'components/CampaignMenu'
import { ComposeMessage } from 'components/ComposeMessage'
import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { ItemTime } from 'components/time'
import { CAPABILITIES } from 'constants/capabilities'
import { TOGGLE_FILTER_API_CAMPAIGNS } from 'constants/launch-darkly-flags'
import { QUERY_CACHE } from 'constants/query-cache'
import { DESKTOP_BREAK, TABLET_BREAK } from 'constants/theme'
import { useGoToDataExport } from 'containers/Settings/useGoToDataExport'
import { useShouldShowDataExport } from 'containers/Settings/useShouldShowDataExport'
import useBreakpoints from 'hooks/useBreakpoints'
import { useClient } from 'hooks/useClient'
import { userHasFlag } from 'hooks/useLaunchDarkly'
import { useHasCapability } from 'hooks/useUserCapability'
import { SidebarLayout } from 'layouts/SidebarLayout'
import dayjs from 'utils/dayjs'
import { formatLargeNumber, formatPercentage } from 'utils/number'

const StyledSearchBar = styled(SearchBar)`
  max-width: 305px;
`

const StyledCompose = styled.button`
  align-self: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  margin-left: ${SPACING[5]};

  &:disabled {
    cursor: auto;
    svg {
      path:first-child {
        fill: ${({ theme }) => theme?.COLORS?.BUTTON_DISABLED};
      }
    }
  }
`

const StyledCampaignContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const StyledInfiniteScroll = styled(InfiniteScroll)`
  flex: 1;
`

const StyledHeader = styled.div`
  padding: ${SPACING[6]} ${SPACING[4]} ${SPACING[0]};
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-wrap: wrap;
  top: 0px;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`

const StyledTable = styled.table<{ $fullHeight?: boolean }>`
  border-spacing: 0;
  border-collapse: collapse;
  min-width: 100%;
  position: relative;
  table-layout: fixed;

  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      min-height: 100%;
    `}
`

const StyledExportButton = styled(Button)`
  margin-left: ${SPACING[1]};
  height: 40px;
`
const StyledTableHead = styled.thead``

const StyledTableHeadRow = styled.tr``

const StyledTableRow = styled.tr<{ $showBorder?: boolean; onClick?: any; $totalVisibleColumns?: number }>`
  ${({ $showBorder }) =>
    $showBorder &&
    css`
      border-top: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
      &:first-of-type {
        border-top: none;
      }
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:hover {
        background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
      }
    `}

  @media (max-width: ${DESKTOP_BREAK}) {
    display: grid;
    grid-template-columns: ${({ $totalVisibleColumns }) =>
      $totalVisibleColumns ? `repeat(${$totalVisibleColumns - 1}, 1fr)` : undefined};
  }

  @media (max-width: ${TABLET_BREAK}) {
    grid-template-columns: ${({ $totalVisibleColumns }) =>
      $totalVisibleColumns ? `repeat(${Math.ceil(($totalVisibleColumns - 1) / 2)}, 1fr)` : undefined};
  }
`
const StyledTableBody = styled.tbody``

const StyledTableTH = styled.th<{ $cellId?: CellId }>`
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 1.2;
  padding: 16px;
  font-weight: 600;
  position: sticky;
  top: 0px;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  text-align: right;
  vertical-align: bottom;
  z-index: 1;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
    bottom: 0;
    left: 0;
  }

  ${({ $cellId }) =>
    $cellId !== CellId.Campaign &&
    css`
      @media (max-width: ${DESKTOP_BREAK}) {
        display: none;
      }
    `}

  ${({ $cellId }) =>
    $cellId === CellId.Campaign &&
    css`
      @media (max-width: ${DESKTOP_BREAK}) {
        width: 100%;
      }
    `}
`
const StyledTableTD = styled.td<{ $cellId?: CellId }>`
  padding: 0;
  margin: 0;
  font-size: 15px;
  padding: 16px;
  font-weight: 600;
  text-align: right;

  ${({ $cellId }) =>
    $cellId === CellId.Campaign &&
    css`
      max-width: 500px;

      @media (max-width: ${DESKTOP_BREAK}) {
        grid-column: 1 / -1;
        max-width: none;
      }
    `}

  @media (max-width: ${DESKTOP_BREAK}) {
    grid-column: 1fr;
    text-align: left;
  }
`
const StyledColumnHeader = styled.button`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 600;
  padding: 0;
  line-height: 1.2;
  width: 100%;
`
const StyledCaret = styled(CaretUpIcon)<{ $up: boolean }>`
  margin-left: 2px;
  transform: rotate(${({ $up }) => ($up ? '0' : '180')}deg);
`

function LinkClicks(props: { campaign: Campaign }): string {
  const { campaign } = props
  const linkClicks = campaign?.linkInfo?.[0]?.linkClickInfo?.linkClickRate

  if (typeof linkClicks === 'undefined') {
    return '--'
  }

  return formatPercentage(linkClicks)
}

function MediaClicks(props: { campaign: Campaign }): string {
  const { campaign } = props
  const mediaClicks =
    // we cannot track clicks of MMS media at this time
    campaign?.media?.disposition === MediaDisposition.ATTACHMENT
      ? undefined
      : campaign?.media?.linkClickInfo?.linkClickRate

  if (typeof mediaClicks === 'undefined') {
    return '--'
  }

  return formatPercentage(mediaClicks)
}

function CampaignSentAt(props: { campaign: Campaign }): JSX.Element {
  const { scheduledAt, createdAt } = props.campaign

  const campaignDate = scheduledAt || createdAt

  const campaignDateText = useMemo(() => dayjs(campaignDate).format('LLL'), [campaignDate])

  return (
    <Tooltip content={campaignDateText}>
      <span>
        <Typography data-testid="scheduled-at" fontSize="14px" whiteSpace="nowrap">
          <ItemTime ts={scheduledAt || createdAt} />
        </Typography>
      </span>
    </Tooltip>
  )
}

const useSortField = () => {
  return useLocalStorageState<SortField>('campaignList.sortField', {
    defaultValue: SortField.SCHEDULED_AT,
  })
}

const useSortDirection = () => {
  return useLocalStorageState<SortDirection>('campaignList.sortDirection', {
    defaultValue: SortDirection.DESCENDING,
  })
}

function ColumnHeader(props: { field: SortField; text: React.ReactNode }): JSX.Element {
  const { field, text } = props
  const { t } = useTranslation()
  const [sortField, setSortField] = useSortField()
  const [sortDirection, setSortDirection] = useSortDirection()

  const handleClick = () => {
    if (field !== sortField) {
      setSortField(field)
      setSortDirection(SortDirection.DESCENDING)
    } else if (field === sortField && sortDirection === SortDirection.DESCENDING) {
      setSortDirection(SortDirection.ASCENDING)
    } else if (field === sortField && sortDirection === SortDirection.ASCENDING) {
      setSortDirection(SortDirection.DESCENDING)
    }
  }

  let caret
  let title = t('campaigns.sortDescending')

  if (field === sortField) {
    caret = <StyledCaret $up={sortDirection === SortDirection.ASCENDING} />
    title = sortDirection === SortDirection.DESCENDING ? t('campaigns.sortAscending') : t('campaigns.sortDescending')
  }

  return (
    <StyledColumnHeader onClick={handleClick} title={title}>
      {text}
      {caret}
    </StyledColumnHeader>
  )
}

const columnHelper = createColumnHelper<Campaign>()

enum CellId {
  Campaign = 'campaign',
  Recipients = 'recipients',
  LinkClicks = 'link_clicks',
  MediaClicks = 'media_clicks',
  Responded = 'responded',
  SentStop = 'sent_stop',
  TimeSent = 'time_sent',
  Menu = 'menu',
}

function isCampaignColumnDef(columnDef: any): columnDef is ColumnDef<Campaign> {
  return !!columnDef
}

function flattenCampaignReplies(campaign: Campaign): Campaign[] {
  const flattened: Campaign[] = []

  // Helper function to recursively flatten the campaigns
  const flatten = (currentCampaign: Campaign) => {
    flattened.push(currentCampaign)

    // nestLevel isn't available on nested campaigns,
    // but comes through as `0` for the initial campaign,
    // so we have to set it manually
    currentCampaign.replies.forEach((c) => flatten({ ...c, nestLevel: currentCampaign.nestLevel + 1 }))
  }

  // Start flattening with the initial campaign
  flatten(campaign)

  // Return the flattened array
  return flattened
}

type OpenRepliesMap = Map<string, boolean>

export function Campaigns(): JSX.Element {
  const { t } = useTranslation()
  const history = useHistory<{ scrollPosition?: number; searchValue?: string }>()
  const [duplicateCampaign, setDuplicateCampaign] = useState<DuplicateClickProps | undefined>(undefined)
  const { lgDown } = useBreakpoints()
  const { data: client } = useClient()
  const { setComposeModalOpen } = useCompose()
  const {
    onChange: onSearchChange,
    debounceValue: debounceSearchValue,
    inputValue: searchValue,
  } = useSearch(history.location.state?.searchValue)

  const scrollRef = useRef<HTMLDivElement>(null)

  const [openReplies, setOpenReplies] = useState<OpenRepliesMap>(new Map<string, boolean>())

  const updateOpenReplies = (key: string, value: boolean) => {
    setOpenReplies((prev) => new Map(prev.set(key, value)))
  }

  const [filters, setFilters] = useCampaignListFilters()

  // We don't want to pass the api filter to the API if the feature flag is off or if the user doesn't have the capability
  const hasCampaignScheduleApiCapability = useHasCapability(CAPABILITIES.FEATURE.SCHEDULE_CAMPAIGN_API.ALL)
  const isCampaignApiFilterEnabled = userHasFlag(TOGGLE_FILTER_API_CAMPAIGNS)
  const { api, ...filtersWithoutApi } = filters
  const filterParams = isCampaignApiFilterEnabled && hasCampaignScheduleApiCapability ? filters : filtersWithoutApi

  const [sortField, setSortField] = useSortField()
  const [sortDirection, setSortDirection] = useSortDirection()

  const sortOptions = useMemo(() => {
    return [
      { value: SortField.RECIPIENT_COUNT, label: t('campaigns.recipients') },
      { value: SortField.LINK_CLICK_RATE, label: t('campaigns.clicksPercentage') },
      { value: SortField.MEDIA_VIEW_RATE, label: t('campaigns.mediaViewPercentage') },
      { value: SortField.RESPONSE_RATE, label: t('campaigns.responsePercentage') },
      { value: SortField.STOP_RATE, label: t('campaigns.stopPercentage') },
      { value: SortField.SCHEDULED_AT, label: t('campaigns.date') },
    ]
  }, [t])
  const activeFilter = sortOptions.find((option) => option.value === sortField) || sortOptions[0]

  const { data, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage } = useCampaignList({
    filters: filterParams,
    searchTerm: debounceSearchValue,
    sortDirection,
    sortField,
    select: (data) => ({
      ...data,
      pages: data.pages
        .flatMap((page) => page.data)
        .reduce<Campaign[]>((acc, campaign) => {
          if (openReplies.get(campaign.id)) {
            acc.push(...flattenCampaignReplies(campaign))
          } else {
            acc.push(campaign)
          }
          return acc
        }, []),
    }),
  })

  // We disable the ability to open compose if the client has no communication channels
  const isOpenComposeDisabled = !client?.communicationChannels?.length

  const showStopRate = useHasCapability(CAPABILITIES.FEATURE.CAMPAIGN_STOP_RATE)

  const columns: ColumnDef<Campaign>[] = useMemo(() => {
    return [
      columnHelper.display({
        minSize: 300,
        maxSize: 500,
        id: CellId.Campaign,
        cell: (info) => (
          <Layout textAlign="left">
            <CampaignCell
              campaign={info.row.original}
              onToggleReplies={() => updateOpenReplies(info.row.original.id, !openReplies.get(info.row.original.id))}
              repliesVisible={openReplies.get(info.row.original.id)}
            />

            {campaignErrorStatuses.includes(info.row.original.status) && (
              <Layout marginLeft="auto">
                <CampaignError campaign={info.row.original} />
              </Layout>
            )}
          </Layout>
        ),
      }),
      columnHelper.display({
        id: CellId.Recipients,
        cell: (info) => {
          return (
            <>
              {lgDown && (
                <Typography component="div" fontSize="15px" fontWeight="600" whiteSpace="nowrap">
                  {t('campaigns.recipients')}
                </Typography>
              )}
              <Typography data-testid="recipient-count" fontSize="15px" fontWeight="600">
                {campaignErrorStatuses.includes(info.row.original.status)
                  ? '--'
                  : formatLargeNumber(info.row.original.recipientCount)}
              </Typography>
            </>
          )
        },
        header: () => (
          <ColumnHeader
            field={SortField.RECIPIENT_COUNT}
            text={
              <Layout userSelect="none">
                <span>{t('campaigns.recipients')}</span>
                <Tooltip
                  content={<Layout padding={SPACING[1]}>{t('campaigns.recipientsTooltip')}</Layout>}
                  maxWidth={410}
                >
                  <Layout display="inline-block" marginLeft={SPACING[1]} marginTop="2px">
                    <InfoIcon size={18} />
                  </Layout>
                </Tooltip>
              </Layout>
            }
          />
        ),
      }),
      columnHelper.display({
        id: CellId.LinkClicks,
        cell: (info) => {
          return (
            <>
              {lgDown && (
                <Typography component="div" fontSize="15px" fontWeight="600" whiteSpace="nowrap">
                  {t('campaigns.clicksPercentage')}
                </Typography>
              )}
              <Typography data-testid="link-clicks" fontSize="15px" fontWeight="600">
                {campaignErrorStatuses.includes(info.row.original.status) ? (
                  '--'
                ) : (
                  <LinkClicks campaign={info.row.original} />
                )}
              </Typography>
            </>
          )
        },
        header: () => <ColumnHeader field={SortField.LINK_CLICK_RATE} text={t('campaigns.clicksPercentage')} />,
      }),
      columnHelper.display({
        id: CellId.MediaClicks,
        cell: (info) => {
          return (
            <>
              {lgDown && (
                <Typography component="div" fontSize="15px" fontWeight="600" whiteSpace="nowrap">
                  {t('campaigns.mediaViewPercentage')}
                </Typography>
              )}
              <Typography data-testid="media-clicks" fontSize="15px" fontWeight="600">
                {campaignErrorStatuses.includes(info.row.original.status) ? (
                  '--'
                ) : (
                  <MediaClicks campaign={info.row.original} />
                )}
              </Typography>
            </>
          )
        },
        header: () => <ColumnHeader field={SortField.MEDIA_VIEW_RATE} text={t('campaigns.mediaViewPercentage')} />,
      }),
      columnHelper.display({
        id: CellId.Responded,
        cell: (info) => {
          return (
            <>
              {lgDown && (
                <Typography component="div" fontSize="15px" fontWeight="600" whiteSpace="nowrap">
                  {t('campaigns.responsePercentage')}
                </Typography>
              )}
              <Typography data-testid="response-rate" fontSize="15px" fontWeight="600">
                {campaignErrorStatuses.includes(info.row.original.status)
                  ? '--'
                  : formatPercentage(info.row.original.responseInfo?.responseRate || 0)}
              </Typography>
            </>
          )
        },
        header: () => <ColumnHeader field={SortField.RESPONSE_RATE} text={t('campaigns.responsePercentage')} />,
      }),
      showStopRate &&
        columnHelper.display({
          id: CellId.SentStop,
          cell: (info) => {
            return (
              <>
                {lgDown && (
                  <Typography component="div" fontSize="15px" fontWeight="600">
                    {t('campaigns.stopPercentage')}
                  </Typography>
                )}
                <Typography data-testid="stop-rate" fontSize="15px" fontWeight="600">
                  {campaignErrorStatuses.includes(info.row.original.status)
                    ? '--'
                    : formatPercentage(info.row.original?.stopInfo?.stopRate || 0)}
                </Typography>
              </>
            )
          },
          header: () => <ColumnHeader field={SortField.STOP_RATE} text={t('campaigns.stopPercentage')} />,
        }),
      columnHelper.display({
        id: CellId.TimeSent,
        cell: (info) => {
          return (
            <>
              {lgDown && (
                <Typography
                  component="div"
                  data-testid="scheduled-at"
                  fontSize="15px"
                  fontWeight="600"
                  whiteSpace="nowrap"
                >
                  {t('campaigns.date')}
                </Typography>
              )}
              <CampaignSentAt campaign={info.row.original} />
            </>
          )
        },
        header: () => <ColumnHeader field={SortField.SCHEDULED_AT} text={t('campaigns.date')} />,
      }),
      columnHelper.display({
        id: CellId.Menu,
        cell: (info) => <CampaignMenu campaignId={info.row.original.id} onDuplicateClick={setDuplicateCampaign} />,
      }),
    ].filter(isCampaignColumnDef)
  }, [showStopRate, openReplies, lgDown, t])

  const shouldShowDataExport = useShouldShowDataExport()
  const goToDataExport = useGoToDataExport()
  const parsedCampaigns = useMemo(
    () =>
      (data?.pages || []).map((campaign: Campaign) => {
        if (
          campaign?.text &&
          campaign?.placeholders &&
          campaign?.placeholders.length > 0 &&
          campaign.placeholders.some((p) => campaign.text?.includes(`{${p.key}:${p.source}}`))
        ) {
          const matchingPlaceholders = campaign.placeholders.filter((p) =>
            campaign.text?.includes(`{${p.key}:${p.source}}`),
          )
          if (matchingPlaceholders.length > 0) {
            return {
              ...campaign,
              text: matchingPlaceholders.reduce(
                (t, ph) => t.replace(`{${ph.key}:${ph.source}}`, `{${ph.name}}`),
                campaign.text,
              ),
            }
          }
        }
        return campaign
      }),
    [data?.pages],
  )

  const handleClick = (campaign: Campaign) => {
    history.replace(history.location.pathname, {
      scrollPosition: scrollRef.current?.scrollTop || 0,
      searchValue,
    })
    history.push(`/campaigns/${campaign.id}`)
  }

  const onSort = (sortField) => () => {
    setSortField(sortField)
  }

  const table = useReactTable({
    columns: columns,
    data: parsedCampaigns,
    getCoreRowModel: getCoreRowModel(),
  })

  const isEmpty = !data?.pages?.length

  return (
    <SidebarLayout>
      <StyledCampaignContainer>
        <StyledHeader>
          <Typography marginBottom="0px" variant="h1">
            {t('campaigns.list')}
            <StyledCompose
              aria-label={t('compose.composeMessage')}
              disabled={isOpenComposeDisabled}
              onClick={() => setComposeModalOpen(true)}
            >
              <ComposeCircleIcon size={32} />
            </StyledCompose>
          </Typography>
          <Layout alignItems="center" display="flex">
            {lgDown && (
              <Layout marginRight="12px">
                <Filter activeFilter={activeFilter.label} align="left" icon={false} label={t('communities.sortBy')}>
                  {sortOptions.map((option) => (
                    <Filter.Option onClick={onSort(option.value)} selected={sortField === option.value}>
                      {option.label}
                    </Filter.Option>
                  ))}
                  <Filter.Sort direction={sortDirection} onClick={setSortDirection} />
                </Filter>
              </Layout>
            )}
            <FilterDropdown
              filters={filters}
              queryKey={[
                QUERY_CACHE.CAMPAIGN.LIST,
                {
                  clientId: client?.id,
                  filters,
                  searchTerm: debounceSearchValue,
                  communicationChannels: undefined,
                  pageSize: 50,
                },
              ]}
              setFilters={setFilters}
            />
            {shouldShowDataExport ? (
              <StyledExportButton onClick={goToDataExport} variant={BUTTON_VARIANTS.OUTLINE}>
                {t('exportData')}
              </StyledExportButton>
            ) : null}
          </Layout>
        </StyledHeader>

        <StyledInfiniteScroll
          hasMore={hasNextPage}
          initialScrollTop={history.location.state?.scrollPosition || 0}
          loadMore={fetchNextPage}
          loading={isLoading || isFetchingNextPage}
          ref={scrollRef}
          threshold={100}
        >
          <StyledTable $fullHeight={isLoading || isError || isEmpty} data-testid="campaigns-list">
            <StyledTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <StyledTableHeadRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    if (header.id === CellId.Campaign) {
                      return (
                        <StyledTableTH $cellId={header.id as CellId} key={header.id}>
                          <StyledSearchBar
                            onChange={onSearchChange}
                            placeholder={t('campaigns.searchSentCampaigns')}
                            value={searchValue}
                          />
                        </StyledTableTH>
                      )
                    }

                    return (
                      <StyledTableTH $cellId={header.id as CellId} key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </StyledTableTH>
                    )
                  })}
                </StyledTableHeadRow>
              ))}
            </StyledTableHead>
            <StyledTableBody>
              {isLoading ? (
                <StyledTableRow>
                  <StyledTableTD colSpan={table.getVisibleLeafColumns().length}>
                    <LoadingIndicator />
                  </StyledTableTD>
                </StyledTableRow>
              ) : isError ? (
                <StyledTableRow>
                  <StyledTableTD colSpan={table.getVisibleLeafColumns().length}>
                    <CampaignsError />
                  </StyledTableTD>
                </StyledTableRow>
              ) : isEmpty ? (
                <StyledTableRow>
                  <StyledTableTD colSpan={table.getVisibleLeafColumns().length}>
                    <EmptyCampaigns onCompose={() => setComposeModalOpen(true)} />
                  </StyledTableTD>
                </StyledTableRow>
              ) : (
                table.getRowModel().rows.map((row) => {
                  return (
                    <StyledTableRow
                      $showBorder={!row.original.parentSmsCampaignId}
                      $totalVisibleColumns={table.getVisibleLeafColumns().length}
                      aria-label={t('campaigns.viewCampaignDetails')}
                      data-testid={`campaign-item-${row.original.id}`}
                      key={row.id}
                      onClick={(e) => {
                        if (!e.isDefaultPrevented()) {
                          handleClick(row.original)
                        }
                      }}
                      role="button"
                    >
                      {row.getVisibleCells().map((cell) => (
                        <StyledTableTD $cellId={cell.column.id as CellId} key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </StyledTableTD>
                      ))}
                    </StyledTableRow>
                  )
                })
              )}
            </StyledTableBody>
          </StyledTable>
        </StyledInfiniteScroll>
      </StyledCampaignContainer>
      {duplicateCampaign && (
        <ComposeMessage
          file={duplicateCampaign.file}
          initialComposeState={duplicateCampaign.initialComposeState}
          initialFilterState={duplicateCampaign.initialFilterState}
          mediaDisposition={duplicateCampaign.mediaDisposition || undefined}
          onClose={() => setDuplicateCampaign(undefined)}
        />
      )}
    </SidebarLayout>
  )
}
