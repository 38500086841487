import { WORKFLOW_CARDS, WorkflowEditorCardTypes } from '@community_dev/flexible-canvas'
import { MessageBillingUsage, useMessageBillingUsage } from '@community_dev/hooks'
import { Layout, SPACING, TCPAMessage } from '@community_dev/pixels'
import IconConfirmationMessage from 'components/WorkflowEditor/svg/confirmation-message.svg?react'
import IconWelcomeWave from 'components/WorkflowEditor/svg/welcome-wave.svg?react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { WorkflowSidebarHeader } from '../WorkflowSidebarHeader/WorkflowSidebarHeader'

import { MultimediaInput } from 'components/MultimediaInput'
import { ROUTES } from 'constants/routes'
import { useSettings } from 'contexts/SettingsProvider'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useOnboardingMessages } from 'hooks/useOnboardingMessages'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import { useToastMessage } from 'hooks/useToastMessage'
import { WELCOME_MESSAGE_KEY } from 'screens/Onboarding/constants'
import { CancelLink, DoneButton } from 'screens/WorkflowScreen/WorkflowScreen.style'
import { route } from 'utils/router'

const WorkflowCardConfig = WORKFLOW_CARDS[WorkflowEditorCardTypes.SEND_MESSAGE]

export type OnboardingMessageType = 'welcome-message' | 'signup-confirmation'

const isSaveDisabled = (initialValue: string, currentValue: string, messageBillingUsage: MessageBillingUsage) => {
  if (initialValue === currentValue) return true
  if (currentValue?.length < 1) return true
  const { charCount, charsAllowed, segmentCount, segmentsAllowed } = messageBillingUsage

  // If there is a segment limit, we check for both
  if (segmentsAllowed && charsAllowed) {
    if (segmentCount > segmentsAllowed || charCount > charsAllowed) {
      return true
    }
  } else if (charsAllowed && charCount > charsAllowed) {
    return true
  }

  return false
}

export function WorkflowEditOnboardingMessage(): JSX.Element {
  const { t } = useTranslation()
  const history = useHistory()
  const currentTheme = useCurrentTheme()
  const { settings } = useSettings()
  const { COLORS } = useTheme()
  const { showToastMessage } = useToastMessage()

  const { workflowId, messageType } = useParams<{ workflowId: string; messageType: OnboardingMessageType }>()

  const { welcomeMessage, confirmationMessage, updateMessage } = useOnboardingMessages()

  const message = messageType === 'welcome-message' ? welcomeMessage : confirmationMessage
  const messageSuffix = messageType === 'welcome-message' ? welcomeMessage?.legalMessage : confirmationMessage?.tcpa

  const [messageText, setMessageText] = useState(message.text)

  useEffect(() => {
    setMessageText(message.text)
  }, [message.text])

  const { links } = useShortenableLinks(messageText)
  const messageBillingUsage = useMessageBillingUsage({
    linksToShorten: links,
    message: messageText,
    segmentMax: message?.maxSegmentCount,
    charMax: message?.maxLength,
    messageSuffix,
  })
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = messageBillingUsage
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }

  return (
    <Layout display="flex" flexDirection="column" height="100%" width="100%">
      <Layout marginTop={SPACING[2]} padding={SPACING[4]}>
        <WorkflowSidebarHeader
          workflowAction={{
            ...WorkflowCardConfig,
            backgroundColor: '#000',
            title:
              message.label === WELCOME_MESSAGE_KEY
                ? t('automations.welcomeMessage')
                : t('automations.confirmationMessage'),
            subtitle: t('automations.thisIsTheMessage'),
            icon: message.label === WELCOME_MESSAGE_KEY ? IconWelcomeWave : IconConfirmationMessage,
          }}
        />
      </Layout>
      <Layout flex="1" padding={SPACING[4]}>
        {messageSuffix ? (
          <TCPAMessage
            defaultValue={message.text}
            emojiSkinTone={settings.skinTone}
            emojiTheme={currentTheme.type}
            onChange={(value) => setMessageText(value)}
            requiredSuffix={messageSuffix}
            style={{
              fontSize: '14px',
              lineHeight: '20px',
            }}
            usageCounterProps={usageCounterProps}
          />
        ) : (
          <MultimediaInput
            emojiSkinTone={settings.skinTone}
            emojiTheme={currentTheme.type}
            gallery={false}
            gif={false}
            onChange={(e) => setMessageText(e.target.value)}
            onEmojiSelect={(_, value) => setMessageText(value)}
            usageCounterProps={usageCounterProps}
            value={messageText}
          />
        )}
      </Layout>

      <Layout
        alignItems="center"
        borderTop={`1px solid ${COLORS?.BORDERS}`}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding="45px 16px"
      >
        <DoneButton
          disabled={isSaveDisabled(message.text, messageText, messageBillingUsage)}
          onClick={async () => {
            try {
              await updateMessage({
                id: message.id,
                text: messageText,
              })

              history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
            } catch (e) {
              showToastMessage({
                message: (e as Error).message,
                success: false,
              })
            }
          }}
          type="button"
        >
          {t('save')}
        </DoneButton>
        <CancelLink style={{ marginTop: SPACING[5] }} to={route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId })}>
          {t('cancel')}
        </CancelLink>
      </Layout>
    </Layout>
  )
}
