import { Button, BUTTON_VARIANTS, CloseIcon, Modal, Select, SPACING, Typography } from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import { RoleGroup } from '@community_dev/types/lib/api/v1/Roles'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { CapabilityList } from './CapabilityList'
import { StyledLabel } from './styled'
import { RoleList } from './TeamMemberRoleList'

import { getTeamRoles, updateClientSeatRole } from 'api/team-seats'
import { QUERY_CACHE } from 'constants/query-cache'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'

const StyledForm = styled.form`
  padding: ${SPACING[5]} ${SPACING[7]} 0 ${SPACING[7]};
`

type TeamMemberEditProps = {
  seat: CamelCasedPropertiesDeep<Api.V1.Seat>
  onClose: () => void
  onSaved: () => void
  isReadOnly?: boolean
}

type SeatDetails = {
  firstName: string
  lastName: string
  email: string
  roles: CamelCasedPropertiesDeep<Api.V1.Role[]>
}

export function TeamMemberEditModal({ seat, isReadOnly = false, onClose, onSaved }: TeamMemberEditProps): JSX.Element {
  const { t } = useTranslation()
  const { COLORS } = useTheme() || {}
  const { data: userSeat } = useSeat()
  const clientId = useClientId()
  const { showToastMessage } = useToastMessage()
  const isOwner = seat.roles.some((role) => role.name === 'owner')
  const formStackUrl = `https://community.formstack.com/forms/change_owner?client_id=${encodeURIComponent(
    clientId,
  )}&seat_email=${encodeURIComponent(userSeat?.email || '')}`
  const queryClient = useQueryClient()
  const { mutateAsync: mutateSeatRole, isLoading } = useMutation(updateClientSeatRole, {
    onSuccess() {
      queryClient.invalidateQueries([QUERY_CACHE.SETTINGS.TEAM_SEATS, { clientId }])
    },
  })
  const { data: roles } = useQuery([QUERY_CACHE.SETTINGS.TEAM_ROLES], {
    queryFn: getTeamRoles,
    initialData: [],
  })
  const selectableRoles = useMemo(
    () => roles.filter((role) => role.group === RoleGroup.PRIMARY && role.protected === false),
    [roles],
  )
  async function handleSubmit(values: SeatDetails) {
    if (isReadOnly) {
      onClose()
    } else {
      try {
        await mutateSeatRole({
          clientId,
          seatMappingId: seat.seatMappingId,
          roleIds: values.roles.map((role) => role.id),
        })
        onSaved()
        onClose()
      } catch (e) {
        showToastMessage({ message: t('unknownServerError'), success: false })
      }
    }
  }

  return (
    <Modal maxWidth={650} onClose={onClose} open={true} overflow="visible">
      <Modal.Header>
        <Modal.Header.Center>
          <span>{isReadOnly ? t('settings.team.teamMember') : t('settings.team.editMember')}</span>
        </Modal.Header.Center>
        <Modal.Header.Right onClose={onClose}>
          <CloseIcon color={COLORS.SUBTEXT} size={12} />
        </Modal.Header.Right>
      </Modal.Header>
      <Form<SeatDetails>
        initialValues={{ email: seat.email, firstName: seat.firstName, lastName: seat.lastName, roles: seat.roles }}
        onSubmit={handleSubmit}
        render={({ values, handleSubmit, valid }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Modal.Body>
              <StyledLabel>{t('firstName')}</StyledLabel>
              <Typography fontWeight={500}>{values.firstName}</Typography>
              <StyledLabel>{t('lastName')}</StyledLabel>
              <Typography fontWeight={500}>{values.lastName}</Typography>
              <StyledLabel>{t('emailAddress')}</StyledLabel>
              <Typography fontWeight={500}>{values.email}</Typography>
              <StyledLabel id="role-label">{t('settings.team.role')}</StyledLabel>
              {isReadOnly ? (
                <Typography fontWeight={500}>
                  <RoleList roles={values.roles} />
                </Typography>
              ) : (
                <Field<CamelCasedPropertiesDeep<Api.V1.Role[]>> name="roles">
                  {({ input }) => {
                    const selectedRole = input.value.find((role) => role.group === RoleGroup.PRIMARY)
                    return (
                      <Select
                        aria-labelledby="role-label"
                        inline={false}
                        onChange={(value) => {
                          if (value) {
                            input.onChange({
                              target: {
                                value: input.value
                                  .filter((role) => role.group !== RoleGroup.PRIMARY)
                                  .concat(roles.find((role) => role.id === value.value)!),
                              },
                            })
                          }
                        }}
                        options={selectableRoles.map((role) => ({ label: role.displayName, value: role.id }))}
                        value={{ label: selectedRole?.displayName, value: selectedRole?.id }}
                      />
                    )
                  }}
                </Field>
              )}
              <CapabilityList roles={values.roles} />
            </Modal.Body>
            <Modal.Footer $sticky={true}>
              {isOwner && (
                <Button
                  onClick={() => window.open(formStackUrl)}
                  style={{ marginRight: SPACING[2] }}
                  variant={BUTTON_VARIANTS.OUTLINE}
                >
                  Change Ownership
                </Button>
              )}
              <Button disabled={!valid || isLoading} role="button" type="submit">
                {isReadOnly ? t('close') : t('save')}
              </Button>
            </Modal.Footer>
          </StyledForm>
        )}
      />
    </Modal>
  )
}
