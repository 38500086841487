import { Layout, Typography, AiBubble, Badge, TABLET_BREAK } from '@community_dev/pixels'
import { useIsMutating } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AI_INTERFACE } from 'components/ComposeMessage/components/AiMessageGeneratorButton/AiMessageGeneratorButton'

const StyledHeading = styled(Typography).attrs({
  variant: 'h1',
})`
  font-size: 22px;
  line-height: 1.2em;
  margin: 0 10px 0 0;

  @media (min-width: ${TABLET_BREAK}) {
    font-size: 37px;
  }
`

const StyledSubheading = styled(Typography)`
  font-size: 16px;
  line-height: 1.25;
  margin: 0;

  @media (min-width: ${TABLET_BREAK}) {
    font-size: 19px;
  }
`

const StyledBadge = styled(Badge)`
  fontsize: 14px;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;

  @media (min-width: ${TABLET_BREAK}) {
    top: 2px;
  }
`

export function AiMessageGeneratorHeader(): JSX.Element {
  const isPlaying = useIsMutating([AI_INTERFACE])
  const { t } = useTranslation()

  return (
    <Layout display="flex">
      <AiBubble isPlaying={!!isPlaying} style={{ width: 67, height: 67, marginRight: 10 }} />
      <Layout>
        <Layout alignItems="center" display="flex" justifyContent="flex-start">
          <StyledHeading variant="h1">{t('aiMessageGenerator.mainTitle')}</StyledHeading>
          <StyledBadge>{t('beta')}</StyledBadge>
        </Layout>
        <StyledSubheading>{t('aiMessageGenerator.mainDescription')}</StyledSubheading>
      </Layout>
    </Layout>
  )
}
