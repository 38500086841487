import { LoadingIndicator, SPACING } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { ClusterRepliedState } from '@community_dev/types/lib/api/v2/Clusters'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { SentActionPanel } from './components/SentActionPanel'
import { StyledInfiniteScroll, StyledRoot } from './styled'

import { Fan } from 'api/fans'
import { useActionPanel } from 'components/ActionPanel/ActionPanelContext'
import { ClusterThreadItems } from 'components/ClusterThreadItems'
import { Checkbox } from 'components/IncludeExcludeCheckbox'
import { ROUTES } from 'constants/routes'
import { useIncludeExcludeContext } from 'contexts/IncludeExcludeProvider'
import { Selection } from 'contexts/IncludeExcludeProvider/IncludeExcludeState'
import { useCampaign } from 'hooks/useCampaign'
import { useCurrentClusterQuery } from 'hooks/useClusterQuery'
import { useCommunities } from 'hooks/useCommunities'
import { useGoBack } from 'hooks/useGoBack'
import { normalizeFan } from 'utils/normalize'

const StyledHeader = styled.div`
  margin: 0 0 24px;
  padding: ${SPACING[4]};
  border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  z-index: 1;
`
const StyledBackButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
`
const StyledClusterFilterButtons = styled.div`
  padding: 0 ${SPACING[4]} ${SPACING[4]};
`

type StyledClusterFilterButtonProps = {
  $isSelected?: boolean
}

const StyledClusterFilterButton = styled(Link)<StyledClusterFilterButtonProps>`
  margin: 0 16px 0 0;
  font-size: 22px;
  font-weight: 700;
  color: ${({ theme }) => theme?.COLORS?.BORDERS};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: ${({ theme }) => theme?.COLORS?.TEXT};
    `}
`

const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
`

const StyledCheckbox = styled(Checkbox)`
  margin: 0 26px 0 16px;
`

export default function ClusterChain(): JSX.Element {
  const { data: communities } = useCommunities()
  const customCommunities = communities?.custom || []
  const defaultCommunities = communities?.default || []

  const { isOpen, openActionPanel } = useActionPanel()

  const [selectedDetailMember, setSelectedDetailMember] = useState<Fan | undefined>()

  const { selectedFilter }: any = useParams()
  const isUnrepliedSelected = selectedFilter === ClusterRepliedState.UNREPLIED
  const isRepliedSelected = selectedFilter === ClusterRepliedState.REPLIED

  const { campaignId: pathCampaignId, id, clusterId }: any = useParams()
  const campaignId = pathCampaignId || id

  const { communicationChannel, responseMetadata } = useCampaign({
    campaignId,
    options: {
      communities: [...customCommunities, ...defaultCommunities],
    },
  })
  const supportsFollowUps = responseMetadata?.supportsFollowUps
  const goBack = useGoBack(route(ROUTES.CAMPAIGNS.DETAILS, { campaignId }))
  const { isFetching, isLoading, isFetchingNextPage, data, hasNextPage, fetchNextPage } = useCurrentClusterQuery()

  const flattenedData = useMemo(() => {
    if (!data?.pages) {
      return []
    }

    return data?.pages
      ?.map?.((d) => d?.data)
      .flat()
      .map((message) => ({ fan: normalizeFan(message), ...message }))
  }, [data])

  const { isSelected, toggleSelected: includeExcludeToggleSelected } = useIncludeExcludeContext()

  const selectAllCheckboxState = isSelected([clusterId])

  // let the IncludeExcludeProvider know which messages are children of
  // which clusters so that it can prune appropriately when all children are
  // selected or deselected
  const { recordChildMapping } = useIncludeExcludeContext()
  useEffect(() => {
    if (!flattenedData) {
      return
    }
    const parentId = clusterId
    const childIds = flattenedData.map((d) => d.fan.fanSubscriptionId)
    recordChildMapping(parentId, childIds)
  }, [recordChildMapping, clusterId, flattenedData])

  const loadMore = () => {
    if (!data?.pages?.length) return
    const nextCursor = data?.pages?.length + 1

    if (hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage({ pageParam: nextCursor })
    }
  }

  const handleMemberSelect = (member: Fan) => {
    if (!isOpen) openActionPanel()

    setSelectedDetailMember(member)
  }

  return (
    <StyledRoot>
      <StyledInfiniteScroll hasMore={hasNextPage} loadMore={loadMore} loading={isFetchingNextPage} threshold={240}>
        <div data-testid="chain-responses">
          <StyledHeader>
            <StyledBackButton aria-label="Back" onClick={goBack}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </StyledBackButton>
          </StyledHeader>
          <StyledClusterFilterButtons>
            <StyledClusterFilterButton
              $isSelected={isUnrepliedSelected}
              replace
              to={route(ROUTES.CAMPAIGNS.CLUSTER, {
                campaignId,
                clusterId,
                selectedFilter: ClusterRepliedState.UNREPLIED,
              })}
            >
              Unreplied
            </StyledClusterFilterButton>
            <StyledClusterFilterButton
              $isSelected={isRepliedSelected}
              replace
              to={route(ROUTES.CAMPAIGNS.CLUSTER, {
                campaignId,
                clusterId,
                selectedFilter: ClusterRepliedState.REPLIED,
              })}
            >
              Replied
            </StyledClusterFilterButton>
          </StyledClusterFilterButtons>
          <div className="Chain-responses" data-testid="chain-response-header">
            {supportsFollowUps && (
              <SelectAllWrapper>
                <StyledCheckbox
                  aria-label="Select all responses"
                  checked={selectAllCheckboxState === Selection.SELECTED}
                  disabled={false}
                  indeterminate={selectAllCheckboxState === Selection.PARTIAL}
                  key="responses-select-all"
                  name="responses-select-all"
                  onChange={() => {
                    includeExcludeToggleSelected([clusterId], 'cluster', isRepliedSelected)
                  }}
                />{' '}
                Select All
              </SelectAllWrapper>
            )}
            {isLoading && <LoadingIndicator height="100px" style={{ margin: '100px 0 100vh 0' }} />}
            <ClusterThreadItems items={flattenedData} onMemberSelect={handleMemberSelect} />
          </div>
        </div>
        <SentActionPanel
          communicationChannel={communicationChannel}
          selectedDetailMember={selectedDetailMember}
          setSelectedDetailMember={setSelectedDetailMember}
        />
      </StyledInfiniteScroll>
    </StyledRoot>
  )
}
