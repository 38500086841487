import { FONT_SIZE, FONT_WEIGHT, SPACING, Typography } from '@community_dev/pixels'
import { useTranslation, Trans } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { AiMessageGeneratorIcon } from './components/Icons/AiMessageGeneratorIcon'

import { BetaBadge } from 'components/BetaBadge'
import { THIRD_PARTY_DISCLOSURES } from 'constants/communityLinks'
import { useAddons } from 'hooks/useAddons'
import { Setting } from 'layouts/SettingsLayout'
import { DynamicButton } from 'screens/DetailedAddOnScreen/DynamicButton'

const StyledSetting = styled(Setting)`
  button {
    padding: 0;
    border: none;
    background: none;
    color: ${({ theme }) => theme?.COLORS.TEXT};
    font-weight: ${FONT_WEIGHT[6]};
    font-size: ${FONT_SIZE[3]};
    line-height: 18px;
    margin-left: ${SPACING[4]};
    white-space: nowrap;
    :hover {
      background: none;
    }
  }
`

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.COLORS.LINKS};
`

export function AiMessageGenerator(): JSX.Element {
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const { allAddOns } = useAddons()
  const aiMessageGeneratorAddon = allAddOns?.find((addOn) => addOn.title === 'AI Message Generator (Beta)')

  return (
    <StyledSetting
      action={aiMessageGeneratorAddon ? <DynamicButton {...aiMessageGeneratorAddon?.details.action} /> : null}
      actionAs="div"
      icon={<AiMessageGeneratorIcon size={22} />}
      label={
        <>
          {t('aiMessageGenerator.mainTitle')} <BetaBadge />
        </>
      }
      loading={!aiMessageGeneratorAddon}
      text={
        <Typography color={COLORS.SUBTEXT} marginTop={SPACING[0]} variant="body2">
          <Trans i18nKey="settings.integrations.aiMessageGenerator.description">
            <StyledLink href={THIRD_PARTY_DISCLOSURES} rel="noopener noreferrer" target="_blank" />
          </Trans>
        </Typography>
      }
    />
  )
}
