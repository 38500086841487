import { useMutation, UseMutationResult } from '@tanstack/react-query'

import { createManifest, CreateManifestArgs, CreateManifestReturn, Workflow } from 'api/workflows'

export type UseCreateManifestOptimisticUpdateContext = {
  previousWorkflow: Workflow
  nextWorkflow: Workflow
}

export type UseCreateManifestProps = {
  onSuccess?: (
    data: CreateManifestReturn,
    variables: CreateManifestArgs,
    context: UseCreateManifestOptimisticUpdateContext | undefined,
  ) => void
  onError?: (
    error: unknown,
    variables: CreateManifestArgs,
    context: UseCreateManifestOptimisticUpdateContext | undefined,
  ) => void
}

export type UseCreateManifestResult = UseMutationResult<
  CreateManifestReturn,
  unknown,
  CreateManifestArgs,
  { previousWorkflow: Workflow; nextWorkflow: Workflow }
>

export type CreateManifestFn = (args: CreateManifestArgs) => Promise<CreateManifestReturn>

export function useCreateManifest({ onSuccess, onError }: UseCreateManifestProps = {}): UseCreateManifestResult {
  return useMutation(createManifest, {
    onSuccess,
    onError,
  })
}
