import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ROUTES } from 'constants/routes'

interface InviteError {
  status: number
  errors?: any
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.COLORS.LINKS};
`

export function humanizeInviteError(err: InviteError | string): JSX.Element | string {
  if (typeof err === 'string') return err
  if (err.status === 409)
    return 'To keep your account safe, we have enabled two-factor authentication. Please check your email for next steps. Any difficulty, please contact yourfriends@community.com.'
  switch (err?.errors?.[0]?.detail?.toLowerCase()) {
    case 'invalid password':
      return 'Incorrect password.'
    case 'token inactive':
    case 'token expired':
      return (
        <>
          This invitation has either been accepted or expired. If you want to log in, visit the{' '}
          <StyledLink to={ROUTES.LOGOUT}>Dashboard</StyledLink>.
        </>
      )
    default:
      return 'Sorry, something went wrong.'
  }
}

/**
 * Get an ApiError or return the normal error
 *
 * @param error The error object. Maybe an ApiError
 * @param allErrors If all errors should be returned. Most of the time we only have one error.
 */
export function getError(error: any, allErrors = false): any[] | any {
  if (error.errors) {
    return allErrors ? error.errors : error.errors[0]
  }

  // Not an ApiError
  return error
}
