import { MemberCount } from 'api/insights'
import dayjs from 'utils/dayjs'

export const createNewMemberGrowthData = (nDays: number): { data: MemberCount[] } => {
  const min = 0
  const max = 100

  // subtract by hour if its 1 day
  const filterBy = nDays === 1 ? 'hour' : 'day'

  const dataPoints = nDays === 1 ? 24 : nDays

  const data = Array.from(Array(dataPoints), (e, i) => {
    const randomCount = Math.floor(Math.random() * (max - min)) + min
    return {
      count: randomCount,
      timestamp: dayjs()
        // subtracting 1 since array is 0 indexed
        .subtract(dataPoints - 1 - i, filterBy)
        .startOf(filterBy)
        .toISOString(),
    }
  })
  return { data }
}
