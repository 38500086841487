import {
  BuiltInFields,
  FieldSources,
  findAll,
  isSelectorFilter,
  isTimeZoneFilter,
  serializeFilters,
  TimeZoneFilter,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { Info, SPACING } from '@community_dev/pixels'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useCompose } from '../ComposeContext'

import { useTimezones } from './useTimezones'

import { Timezone } from 'api/timezones'
import { DATE_INPUT_FORMAT, TIME_INPUT_FORMAT } from 'constants/date'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'

const StyledInfo = styled(Info)`
  margin-top: ${SPACING[2]};
  margin-bottom: 0;
`

export function ScheduledTimezoneWarning(): JSX.Element | null {
  const { t } = useTranslation()
  const { scheduledAt } = useCompose()
  const { includedFiltersAst } = useFilters()
  const { timezones, communityLocalTimezone } = useTimezones()

  const selected: TimeZoneFilter[] = useMemo(() => {
    return Array.from(
      findAll(includedFiltersAst, {
        operand: {
          field_key: BuiltInFields.TIME_ZONE_GROUP,
          source: FieldSources.BUILT_IN,
        },
      }),
    )
      .map(serializeFilters)
      .filter(isSelectorFilter)
      .filter(isTimeZoneFilter)
  }, [includedFiltersAst])

  if (!scheduledAt) return null

  if (selected.length === 0) {
    return null
  }

  if (!timezones) return null

  return (
    <>
      {selected.map((filter: TimeZoneFilter) => {
        const timezone = timezones.find((timezone: Timezone) => timezone.timeZoneGroup === filter.operand.value)

        if (!timezone || timezone.timeZoneGroup === communityLocalTimezone?.timeZoneGroup) {
          return null
        }

        const theirScheduledAt = scheduledAt.clone().tz(timezone?.representativeTzid)

        return (
          <StyledInfo>
            {t('timezoneFilter.scheduledMessage', {
              timezone: timezone && timezone.timeZoneGroup && t(`timezones.${timezone?.timeZoneGroup}`),
              date: theirScheduledAt.format(DATE_INPUT_FORMAT),
              time: theirScheduledAt.format(TIME_INPUT_FORMAT),
            })}
            <strong>
              {t('timezoneFilter.scheduledMessageDetails', {
                timezone: timezone && timezone.timeZoneGroup && t(`timezones.${timezone?.timeZoneGroup}`),
                date: theirScheduledAt.format(DATE_INPUT_FORMAT),
                time: theirScheduledAt.format(TIME_INPUT_FORMAT),
              })}
            </strong>
            .
          </StyledInfo>
        )
      })}
    </>
  )
}
