import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export function getSeat(token?: string): Promise<CamelCasedPropertiesDeep<Api.V1.Seat>> {
  return request(ENDPOINTS.CURRENT_SEAT, { token }).then(({ data }) => data)
}

export function getSeatPreferences({ seatId }: { seatId: string }): Promise<any> {
  return request(`${ENDPOINTS.PREFERENCES}/${seatId}`).then(({ data }) => data)
}

export function patchSeatInboxDensity({ seatId, body }: { seatId: string; body: any }): Promise<any> {
  return request(`${ENDPOINTS.PREFERENCES}/${seatId}`, {
    method: HTTP.PATCH,
    body,
  })
}

export function putSeatPassword(password: string): Promise<any> {
  return request(route(ENDPOINTS.LOGIN.CHANGE_PASSWORD), {
    method: HTTP.PUT,
    body: {
      password: password,
      password_confirmation: password,
    },
  })
}
