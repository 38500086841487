import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// Make react-router to respond to hash changes properly
//
// https://github.com/remix-run/react-router/issues/394
//
export function useHashChangeListener(): null {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash

      history.push(hash)
    }

    window.addEventListener('hashchange', handleHashChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [history, location])

  return null
}
