import { WorkflowEditorCardTypes, WORKFLOW_CARDS } from '@community_dev/flexible-canvas'
import { Layout, SPACING } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import {
  Entrypoint,
  ShopifyAbandonedCheckoutEntrypoint,
  updateTrigger,
  WorkflowTrigger,
} from '@community_dev/workflow-manifest'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { useTheme } from 'styled-components'
import * as Duration from 'tinyduration'

import { WorkflowSidebarHeader } from '../WorkflowSidebarHeader/WorkflowSidebarHeader'

import {
  DelaySelect,
  DelaySelectMode,
  DelaySelectOption,
  DelaySelectProps,
  DelaySelectValues,
} from 'components/EditOnboardingMessage/EditMessage/DelaySelect'
import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { ROUTES } from 'constants/routes'
import { useToastMessage } from 'hooks/useToastMessage'
import Sentry from 'integrations/Sentry'
import { CancelLink, DoneButton } from 'screens/WorkflowScreen/WorkflowScreen.style'

function isShopifyEntrypoint(entrypoint: Entrypoint): entrypoint is ShopifyAbandonedCheckoutEntrypoint {
  return entrypoint.type === WorkflowTrigger.ShopifyAbandonedCheckout
}

export function WorkflowSidebarEditTriggerShopify(): JSX.Element | null {
  const { t } = useTranslation()
  const history = useHistory()
  const { COLORS } = useTheme() || {}
  const { showToastMessage } = useToastMessage()
  const { workflowId, triggerName } = useParams<{ workflowId: string; triggerName: string }>()
  const { manifest, isLoading, dispatch } = useWorkflowProvider()

  const entrypoint = manifest.entrypoints[triggerName]

  if (!isShopifyEntrypoint(entrypoint)) {
    return null
  }

  const delayDuration = entrypoint.params?.shopify_checkout_updated.abandoned_threshold

  const WorkflowAction = WORKFLOW_CARDS[WorkflowEditorCardTypes.SLEEP]

  const parsedDelay = Duration.parse(delayDuration || 'PT1H')

  let delaySelectProps: DelaySelectProps | undefined = undefined

  if (parsedDelay.hours) {
    delaySelectProps = {
      allowedUnits: ['days', 'hours'],
      initialDelay: parsedDelay.hours,
      initialMode: DelaySelectMode.Delayed,
      initialUnitValue: DelaySelectOption.hours,
    }
  } else if (parsedDelay.days) {
    delaySelectProps = {
      allowedUnits: ['days', 'hours'],
      initialDelay: parsedDelay.days,
      initialMode: DelaySelectMode.Delayed,
      initialUnitValue: DelaySelectOption.days,
    }
  }

  const onSubmit = async (values: DelaySelectValues) => {
    const { delay, unit } = values

    const duration = Duration.serialize({ [unit.value]: delay })

    const nextManifest = updateTrigger(manifest, triggerName, {
      type: WorkflowTrigger.ShopifyAbandonedCheckout,
      params: {
        shopify_checkout_updated: {
          abandoned_threshold: duration,
        },
      },
    })

    try {
      dispatch({
        type: 'update',
        state: {
          manifest: nextManifest,
        },
      })

      history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
    } catch (e) {
      Sentry.captureException(e)

      showToastMessage({
        message: (e as Error).message,
        success: false,
      })
    }
  }

  if (isLoading) {
    return null
  }

  return (
    <Layout display="flex" flex="1" flexDirection="column" height="100%">
      <Layout flex="1" marginTop={SPACING[2]} padding={SPACING[4]}>
        <WorkflowSidebarHeader
          workflowAction={{
            ...WorkflowAction,
            subtitle: t('automations.waitHoursOrDays'),
          }}
        />

        <Layout marginTop={SPACING[5]}>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form id="delayForm" onSubmit={handleSubmit}>
                <DelaySelect allowImmediate={false} {...delaySelectProps} />
              </form>
            )}
          />
        </Layout>
      </Layout>
      <Layout
        alignItems="center"
        borderTop={`1px solid ${COLORS?.BORDERS}`}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding="45px 16px"
      >
        <DoneButton form="delayForm" type="submit">
          {t('done')}
        </DoneButton>

        <CancelLink style={{ marginTop: SPACING[5] }} to={route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId })}>
          {t('cancel')}
        </CancelLink>
      </Layout>
    </Layout>
  )
}
