import { useLaunchDarklyContext } from 'contexts/LaunchDarklyProvider'

export function userHasFlag<T = boolean>(key: string, defaultValue?: T): T {
  const _defaultValue = typeof defaultValue === 'undefined' ? false : defaultValue

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const launchDarklyClient = useLaunchDarklyContext()

  return launchDarklyClient?.variation(key, _defaultValue) || _defaultValue
}
