import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import { useContext, useEffect, useReducer } from 'react'
import React from 'react'

import { themes } from 'contexts/ThemeProvider'

type Settings = {
  colorMode: 'light' | 'dark' | 'auto'
  lightTheme: keyof typeof themes
  darkTheme: keyof typeof themes
  skinTone: number
}

const defaultSettings: Settings = {
  colorMode: 'light' as const,
  lightTheme: 'light',
  darkTheme: 'dark',
  skinTone: 1,
}

type UpdateSettingsAction = {
  type: 'UPDATE_KEY'
  params: {
    key: string
    value: any
  }
}

type SettingsAction = UpdateSettingsAction

const settingsReducer = (state: Settings, action: SettingsAction) => {
  switch (action.type) {
    case 'UPDATE_KEY':
      return set(cloneDeep(state), action.params.key, action.params.value)
    default:
      return state
  }
}

type SettingsProviderProps = {
  children: JSX.Element
}

export const SettingsContext = React.createContext<{
  settings: Settings
  dispatchSettings: React.Dispatch<UpdateSettingsAction>
}>({
  settings: {} as Settings,
  dispatchSettings: (state) => state,
})

SettingsContext.displayName = 'SettingsContext'

export const SettingsProvider = (props: SettingsProviderProps): JSX.Element => {
  const { children } = props
  const localSettings = JSON.parse(localStorage.getItem('settings') as string)
  const initialSettings: Settings = {
    ...defaultSettings,
    ...localSettings,
  }
  const [settings, dispatch] = useReducer(settingsReducer, initialSettings)

  useEffect(() => {
    localStorage.setItem('settings', JSON.stringify(settings))
  }, [settings])

  return (
    <SettingsContext.Provider value={{ dispatchSettings: dispatch, settings }}>{children}</SettingsContext.Provider>
  )
}

export function useSettings(): {
  dispatchSettings: React.Dispatch<UpdateSettingsAction>
  settings: Settings
} {
  const { dispatchSettings, settings } = useContext(SettingsContext)

  return { dispatchSettings, settings }
}
