import {
  FilterAst,
  isCampaignRecipientsFilter,
  isCampaignRespondersClusterFilter,
  isCampaignRespondersFilter,
  isCampaignRespondersRepliedToFilter,
  isIndividualsFilter,
  isJoinedFilter,
  isLinkHitFilter,
  isUnresponsiveMembersFilter,
  MemberDataFilter,
  parseFilters,
  SelectorFilter,
  serializeFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { isBirthdayFilter, isEveryoneFilter, isMemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { digitsFilterToMemberDataFilter } from '@community_dev/filter-dsl/lib/transformers/digitsFilterToMemberDataFilter'
import { convertKeysToSnakeCase } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'
import { breadthFirstTraversal } from 'm-ary-tree'
import { useMemo } from 'react'

import { FilterOptionsWithCounts } from './types'
import { useCountByQueries } from './useCountByQuery'

import { Campaign } from 'api/campaign'
import { CAPABILITIES } from 'constants/capabilities'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useHasCapability } from 'hooks/useUserCapability'
import { useCampaignList } from 'screens/Campaigns/hooks/useCampaignList'
import { unwrapSingleFilter } from 'utils/memberDataFilters/memberDataFiltersUtils'

export type UseRecentCampaignFiltersReturn = {
  filter: MemberDataFilter
  campaignName: string | null
  campaignId: string
}

function checkSubFilters(filters: MemberDataFilter, cbs: ((value: SelectorFilter) => value is any)[]): boolean {
  return Array.from(breadthFirstTraversal(parseFilters(filters) as FilterAst)).some((f) => {
    return cbs.some((fn) => fn(serializeFilters(f) as SelectorFilter))
  })
}

export const toRecentCampaignFilters = (
  campaigns: Campaign[] = [],
  count: number = 10,
): UseRecentCampaignFiltersReturn[] => {
  const data = campaigns
    .filter((campaign) => !campaign.parentCampaign)
    .map(convertKeysToSnakeCase)
    .map((campaign) => {
      if (campaign.filters.subscription_data) {
        return {
          campaignId: campaign.id,
          campaignName: campaign.name,
          filter: campaign.filters.subscription_data,
        }
      }

      return {
        campaignId: campaign.id,
        campaignName: campaign.name,
        filter: digitsFilterToMemberDataFilter(campaign.filters, campaign.fan_subscription_tags),
      }
    })
    .filter(({ filter }) => isMemberDataFilter(filter))
    .map(({ filter, campaignName, campaignId }) => ({
      filter: unwrapSingleFilter(filter as MemberDataFilter),
      campaignName,
      campaignId,
    }))
    .filter(({ filter }) => !checkSubFilters(filter, [isIndividualsFilter, isBirthdayFilter, isJoinedFilter]))
    .filter(({ filter }) => {
      return [
        isEveryoneFilter,
        isCampaignRespondersFilter,
        isCampaignRespondersClusterFilter,
        isCampaignRecipientsFilter,
        isCampaignRespondersRepliedToFilter,
        isUnresponsiveMembersFilter,
        isLinkHitFilter,
      ].every((fn) => !fn(filter as SelectorFilter))
    })

  const uniqueCampaignData = uniqWith(data, (a, b) =>
    isEqual({ filters: a.filter, name: a.campaignName }, { filters: b.filter, name: b.campaignName }),
  ).splice(0, count)

  return uniqueCampaignData
}

export const useRecentCampaignFilters = (): UseRecentCampaignFiltersReturn[] => {
  const { communicationChannel } = useFilters()

  const { data: campaigns } = useCampaignList({
    communicationChannels: [communicationChannel],
    pageSize: 100,
  })

  const recentCampaignFilters = useMemo(() => {
    if (campaigns) {
      return toRecentCampaignFilters(campaigns.pages, 15)
    }

    return []
  }, [campaigns])

  return recentCampaignFilters
}

type UseRecentCampaignFiltersCountArgs = {
  traceId?: string
  communicationChannel?: CommunicationChannel
}

export const useRecentCampaignsCount = ({
  traceId,
  communicationChannel,
}: UseRecentCampaignFiltersCountArgs = {}): FilterOptionsWithCounts => {
  const isRecentCampaignsEnabled = useHasCapability(CAPABILITIES.RECENT_CAMPAIGNS.ALL)
  const recentCampaignFilters = useRecentCampaignFilters()
  const recentCampaignCounts = useCountByQueries({
    filters: recentCampaignFilters.map(({ filter }) => filter),
    traceId,
    communicationChannel,
    options: {
      refetchOnWindowFocus: false,
      enabled: isRecentCampaignsEnabled && recentCampaignFilters.length > 0,
    },
  })

  return {
    options: recentCampaignFilters.map(({ campaignName, filter }) => ({
      label: campaignName || '',
      filter: filter,
    })),
    counts: recentCampaignCounts,
  }
}
