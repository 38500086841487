import { CaretUpIcon, Layout, SPACING, Typography } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TIME_LABEL } from '../constants'
import { TimeRange } from '../InsightsMasthead'
import { LearnHowToGrow } from '../LearnHowToGrow'

const StyledCaret = styled(CaretUpIcon)<{ $positive: boolean }>`
  transform: rotate(${({ $positive }) => ($positive ? '0' : '180')}deg);
`

const StyledSubHeader = styled.div<{ $locked: boolean }>`
  display: flex;
  align-items: center;
  filter: ${({ $locked }) => ($locked ? 'blur(4px)' : 'none')};
`

const StyledNetChange = styled.strong`
  margin-right: ${SPACING[2]};
`

const shouldShowCaret = (delta: number, locked?: boolean): boolean => {
  // we dont show caret when there is no change
  if (delta === 0) return false
  // we dont want to show caret when the delta is blurred
  if (locked) return false
  return true
}

const shouldShowNetChange = (activeSubscriptions: number, delta: number): boolean => {
  /// we show this line whenever there is delta change
  if (delta !== 0) return true
  // we dont show this when there are 0 members and no delta changes
  // we show the growth tools box instead
  if (activeSubscriptions === 0) return false
  return true
}

const shouldShowLearnHowToGrow = (activeSubscriptions: number, delta: number): boolean => {
  // if there is no growth, we show learn to grow
  if (delta === 0) return true
  // if they have no members, we show learn to grow
  if (activeSubscriptions === 0) return true
  return false
}

const formatNumber = new Intl.NumberFormat('en', {
  notation: 'standard',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
})

type NewMemberDiffProps = {
  delta: number
  locked: boolean
  currentSubscriptions: number
  timeRange: TimeRange
}

export const NewMemberDiff = ({ delta, locked, currentSubscriptions, timeRange }: NewMemberDiffProps): JSX.Element => {
  const { t } = useTranslation()
  const showCaret = shouldShowCaret(delta, locked)
  const showNetChange = shouldShowNetChange(currentSubscriptions, delta)
  const showLearnHowToGrow = shouldShowLearnHowToGrow(currentSubscriptions, delta)

  return (
    <Layout marginBottom={SPACING[4]}>
      {showCaret || showNetChange ? (
        <StyledSubHeader $locked={locked}>
          {showCaret && <StyledCaret $positive={delta > 0} />}
          {showNetChange && (
            <Typography margin="0" variant="body1">
              <StyledNetChange>{formatNumber.format(delta)}</StyledNetChange>
              {t('insightMasthead.newMembersInsights.netChange', {
                number: delta,
                timeRange: TIME_LABEL[timeRange],
              })}
            </Typography>
          )}
        </StyledSubHeader>
      ) : null}
      {showLearnHowToGrow && <LearnHowToGrow />}
    </Layout>
  )
}
