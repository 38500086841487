import { useEffect } from 'react'
import { Redirect } from 'react-router'

import { ROUTES } from 'constants/routes'
import { useAuth } from 'hooks/useAuth'

export function Logout(): JSX.Element {
  const { logout } = useAuth()

  useEffect(() => {
    logout()
  }, [logout])

  return <Redirect to={ROUTES.LOGIN} />
}
