import { SPACING } from '@community_dev/pixels'
import range from 'lodash/range'
import ContentLoader from 'react-content-loader'
import styled, { useTheme } from 'styled-components'

const StyledLoader = styled.div`
  margin: 0;
  padding: ${SPACING[2]} ${SPACING[4]} 0;
`

export function RecommendationsLoadingIndicator(): JSX.Element {
  const { COLORS } = useTheme() || {}
  return (
    <StyledLoader data-testid="recommendations-loader">
      {range(0, 2).map((i) => (
        <ContentLoader
          backgroundColor={COLORS?.APP_BACKGROUND_LEVEL_2}
          foregroundColor={COLORS?.APP_BACKGROUND_LEVEL_1}
          height={150}
          key={i}
          speed={2}
          viewBox="0 0 390 150"
          width={374}
        >
          <rect height="10" rx="0" ry="0" width="100" x="0" y="5" />
          <rect height="45" rx="0" ry="0" width="390" x="0" y="35" />
          <rect height="45" rx="0" ry="0" width="390" x="0" y="85" />
        </ContentLoader>
      ))}
    </StyledLoader>
  )
}
