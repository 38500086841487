import { BUTTON_VARIANTS, Button } from '@community_dev/pixels'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { GrowYourCommunityData } from '../../GrowYourCommunityCard/growYourCommunityData'

import { ListOfCategories } from './components/ListOfCategories'

import { ROUTES } from 'constants/routes'
import { MOBILE_BREAK } from 'constants/theme'
import { route } from 'utils/router'

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  a {
    text-align: center;
  }
  padding: 0 32px;
  @media only screen and (max-width: ${MOBILE_BREAK}) {
    padding: 0 4px;
  }
`

const partitionByCategories = (data: GrowYourCommunityData[]): GrowYourCommunityData[][] => {
  if (!data) return []
  const objectOfCategories = data.reduce((acc, curr) => {
    const accCurrentCategories = acc[curr.category] || []
    return { ...acc, [curr.category]: [...accCurrentCategories, curr] }
  }, {})
  return Object.values(objectOfCategories)
}

const TOP_CATEGORY = 'Featured'
const byTopThenAlphabetical = (a, b) => {
  const [{ category: categoryA }] = a
  const [{ category: categoryB }] = b
  if (categoryA === TOP_CATEGORY) return -1
  if (categoryB === TOP_CATEGORY) return 1
  return categoryA > categoryB ? 1 : -1
}

type SlideWithLaunchExamplesProps = {
  body: GrowYourCommunityData[]
  onClick: Dispatch<SetStateAction<GrowYourCommunityData | null>>
  onClose: () => void
}

export function SlideWithLaunchExamples({ body, onClick, onClose }: SlideWithLaunchExamplesProps): JSX.Element {
  const { t } = useTranslation()
  const isGrowthToolsRoute = useRouteMatch(ROUTES.GROWTH_TOOLS)
  const categories = useMemo(() => partitionByCategories(body).sort(byTopThenAlphabetical), [body])
  return (
    <SlideContainer>
      <ListOfCategories list={categories} onClick={onClick} />
      {isGrowthToolsRoute ? (
        <Button onClick={onClose} variant={BUTTON_VARIANTS.ACTION} width="100%">
          {t('growthTools.viewMoreInGrowthTools')}
        </Button>
      ) : (
        <Button forwardedAs={Link} to={route(ROUTES.GROWTH_TOOLS)} variant={BUTTON_VARIANTS.ACTION} width="100%">
          {t('growthTools.viewMoreInGrowthTools')}
        </Button>
      )}
    </SlideContainer>
  )
}
