import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useQuery } from '@tanstack/react-query'

import { useAgeCounts } from './useAgeCount'
import { useBirthdayCount } from './useBirthdayCount'
import { useEveryoneCount } from './useEveryoneCount'
import { useGenderCounts } from './useGenderCount'
import { useJoinedCounts } from './useJoinedCounts'
import { useLocationCounts } from './useLocationCount'
import { useTimezoneCounts } from './useTimezoneCount'

import { getInboxMessages } from 'api/messages'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'

// Prefetches recent filters for the compose message page
export function usePrefetchFilters(enabled: boolean): void {
  // Fetches gender counts
  useGenderCounts({
    traceId: 'recent-filters-gender',
    communicationChannel: CommunicationChannel.SMS,
    options: { staleTime: STALE_TIME.ONE_HOUR, enabled },
  })
  // Fetches age counts
  useAgeCounts({
    traceId: 'recent-filters-age',
    communicationChannel: CommunicationChannel.SMS,
    options: { staleTime: STALE_TIME.ONE_HOUR, enabled },
  })
  // Fetches location counts
  useLocationCounts({
    traceId: 'recent-filters-location',
    communicationChannel: CommunicationChannel.SMS,
    options: { staleTime: STALE_TIME.ONE_HOUR, enabled },
  })
  // Fetches sent
  useQuery([QUERY_CACHE.RECENT_FILTERS.SENT], () => getInboxMessages({ filters: [] }), {
    enabled,
  })
  // Fetches birthday count
  useBirthdayCount({
    traceId: 'recent-filters-birthday',
    communicationChannel: CommunicationChannel.SMS,
    options: { staleTime: STALE_TIME.ONE_HOUR, enabled },
  })
  // Fetches everyone count
  useEveryoneCount({
    traceId: 'recent-filters-everyone',
    communicationChannel: CommunicationChannel.SMS,
    options: { staleTime: STALE_TIME.ONE_HOUR, enabled },
  })
  // Fetches joined count
  useJoinedCounts({
    traceId: 'recent-filters-joined',
    communicationChannel: CommunicationChannel.SMS,
    options: { staleTime: STALE_TIME.ONE_HOUR, enabled },
  })
  // Fetches timezone counts
  useTimezoneCounts({
    traceId: 'recent-filters-timezone',
    communicationChannel: CommunicationChannel.SMS,
    options: { staleTime: STALE_TIME.ONE_HOUR, enabled },
  })
}
