import React from 'react'
import Moment from 'react-moment/src/index'

const calendarOpts = {
  nextDay: '[Tomorrow] [|]h:mm A',
  nextWeek: 'dddd [|]h:mm A',
  lastDay: '[Yesterday] [|]h:mm A',
  lastWeek: 'dddd [|]h:mm A',
  sameDay: '[Today] [|]h:mm A',
  sameElse: 'ddd, MMM D, YYYY, [|]h:mm A',
  sameYear: 'ddd, MMM D, [|]h:mm A',
}

const momentFilter = (d) => {
  const parts = d.split('|')
  return (
    <>
      <span className="ConvoTime-day">{parts[0]}</span>
      <span className="ConvoTime-time">{parts[1]}</span>
    </>
  )
}

type ConvoTimeProps = {
  ts?: string
}

class ConvoTime extends React.PureComponent<ConvoTimeProps> {
  render(): JSX.Element {
    return (
      <div className="ConvoTime-root">
        <Moment calendar={calendarOpts} data-testid="time" filter={momentFilter}>
          {this.props.ts}
        </Moment>
      </div>
    )
  }
}

export default ConvoTime
