import mixpanel from 'mixpanel-browser'

let enabled = false

const track = ({ event, properties = {} }: { event: any; properties?: any }): void => {
  if (!event || !enabled) return
  mixpanel.track(event, properties)
}

const mix = {
  identify: (user: any, seat: any, planName: string | undefined, planType: string | undefined): any => {
    if (!user || !enabled) return
    if (typeof user === 'string') return mixpanel.identify(user)
    const { id, firstName, lastName, fullName, phoneNumber, email } = user
    mixpanel.people.set({
      $email: email,
      $first_name: firstName,
      $last_name: lastName,
      $phone: phoneNumber,
      $plan_name: planName,
      $plan_type: planType,
    })
    mixpanel.register({
      'Customer Name': fullName,
      'Customer Phone': phoneNumber,
      'Seat Email': seat.email,
      'Seat ID': seat.id,
      'Seat Name': seat.name,
    })
    mixpanel.identify(id)
  },
  init: (token: string, clientId: string): void => {
    if (!token) return
    enabled = true
    mixpanel.init(token, {
      disable_cookie: true,
      persistence: 'localStorage',
    })

    mixpanel.register({
      App: 'Leader Dashboard',
      'App Version': import.meta.env.VITE_APP_VERSION,
      'Client ID': clientId,
      Platform: 'Web',
    })

    mixpanel.set_config({ debug: import.meta.env.VITE_MIXPANEL_DEBUG === 'true' })
  },
  reset: (): void => {
    if (!enabled) return
    mixpanel.reset()
  },
  track,
}

export default mix
