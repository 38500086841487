import { FLIPPABLE_CAPABILITIES } from 'constants/capabilities'

const onReady = (fn) => {
  if (document.readyState !== 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

// use message passing to expose the flippable capabilities to our flipper
// extension
export const exposeLocalCapabilityOverrides = (): any => {
  onReady(() => {
    const EXTENSION_ID = 'enhlneggoamffgfkboahipkcbnkcphof'
    const onMessage = (messageEvent) => {
      if (messageEvent.origin === window.origin && messageEvent.data?.type === 'flipper-request-available') {
        try {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'chrome' does not exist on type 'Window &... Remove this comment to see the full error message
          window.chrome.runtime.sendMessage(EXTENSION_ID, {
            availableOverrides: FLIPPABLE_CAPABILITIES,
          })
        } catch (e) {
          console.warn('error sending available overrides to flipper: ', e)
        }
      }
    }
    window.addEventListener('message', onMessage, false)
  })
}
