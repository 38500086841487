export enum ScheduledTabView {
  LIST = 'list',
  MONTH = 'month',
}

export enum ScheduledLocalStorageKeys {
  VIEW_KEY = 'calendar.scheduledView',
  SCHEDULED_MESSAGES = 'calendar.scheduledMessages',
  SENT_MESSAGES = 'calendar.sentMessages',
  AI_IDEA = 'calendar.aiIdea',
  RECURRING_MESSAGES = 'calendar.recurringMessages',
  SUBMITTED_FOR_APPROVAL_MESSAGES = 'calendar.submittedForApprovalMessages',
}
