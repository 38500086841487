import {
  ChatBubbleDirectionValues,
  ChatBubble,
  LoadingIndicator,
  SPACING,
  InvisibleCharacters,
} from '@community_dev/pixels'
import mime from 'mime-types'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { useScheduledCampaigns } from '../../useScheduledCampaigns'

import { CampaignFiltersHeader } from './CampaignFiltersHeader'

import { RichLinkPreview } from 'components/links'
import MediaPreview from 'components/MediaPreview'
import parseStringForRichLinks, { ParsedStringForRichLinks } from 'utils/parseStringForRichLinks'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING[5]} ${SPACING[4]} ${SPACING[4]} ${SPACING[4]};
  align-items: flex-end;
  overflow-y: auto;
`

const StyledMediaPreview = styled(MediaPreview)`
  margin-bottom: ${SPACING[2]};
  justify-content: flex-end;
`

export function ScheduledDetail(): JSX.Element | null {
  const theme = useTheme()

  const { scheduledCampaignId } = useParams<{ scheduledCampaignId: string }>()
  const { isLoading, campaigns } = useScheduledCampaigns()
  const campaign = campaigns?.find((message) => message.id === scheduledCampaignId)

  const { media, link, body } = useMemo(() => {
    if (!campaign) {
      return { media: null, link: null, body: null }
    }

    const parsedLink = parseStringForRichLinks(campaign.text || '')

    let body: string | undefined
    let link: ParsedStringForRichLinks | undefined

    if (parsedLink.url) {
      body = parsedLink.body
      link = parsedLink
    } else {
      body = campaign.text || undefined
    }

    return {
      body,
      link,
      media: !campaign?.media?.url
        ? null
        : {
            href: campaign.media.url,
            type: (campaign.media.mimeType || mime.lookup(campaign.media.url) || 'application/octet-stream').split(
              '/',
            )[0],
            mediaProps: {
              src: campaign.media.url,
              height: campaign.media.height || 0,
              width: campaign.media.width || 0,
              poster: campaign.media.thumbnailUrl || '',
              mimetype: campaign.media.mimeType || mime.lookup(campaign.media.url) || 'application/octet-stream',
              sensitive: campaign.media.sensitive,
            },
          },
    }
  }, [campaign])

  if (isLoading) return <LoadingIndicator />

  if (!campaign) return null

  return (
    <>
      <CampaignFiltersHeader campaign={campaign} />
      <StyledWrapper>
        {link?.startsWith === true && <RichLinkPreview incoming={false} url={link.url} />}
        {media && <StyledMediaPreview {...media} />}
        {body && (
          <ChatBubble
            color={theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_SENT}
            direction={ChatBubbleDirectionValues.RIGHT}
            maxWidth="300px"
            tail
          >
            <InvisibleCharacters>{body}</InvisibleCharacters>
          </ChatBubble>
        )}
        {link?.endsWith === true && <RichLinkPreview incoming={false} url={link.url} />}
      </StyledWrapper>
    </>
  )
}
