import { Image, MissingMediaImageIcon } from '@community_dev/pixels'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { isAudio, isVideo } from 'utils/link'

export const StyledDetailListMediaImg = styled(Image)`
  width: 40px;
  height: 40px;
  object-fit: cover;
  order: 2;
`
const StyledMissingImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-height: 40px;
  background: ${({ theme }) => theme?.COLORS?.BORDERS};
  order: 2;
`

const StyledVideo = styled.video`
  max-height: 40px;
`

type MediaPreviewProps = {
  metric: {
    img?: string
    title?: string
  }
}

export const MediaPreview = ({ metric }: MediaPreviewProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const altText = metric.title ? `Link preview image for ${metric.title}` : 'Link preview image'

  if (!metric.img || isAudio(metric.img)) {
    return (
      <StyledMissingImage>
        <MissingMediaImageIcon aria-label="missing-media-image-icon" color={COLORS?.APP_BACKGROUND_LEVEL_3} />
      </StyledMissingImage>
    )
  }

  if (isVideo(metric.img)) {
    return <StyledVideo aria-label="Media preview" muted={true} src={metric.img} />
  }

  return <StyledDetailListMediaImg alt={altText} src={metric.img} />
}
