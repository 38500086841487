import { FONT_SIZE, FONT_WEIGHT, SPACING } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

export const StyledAction = styled.button`
  padding: 0;
  border: none;
  background: none;
  font-weight: ${FONT_WEIGHT[6]};
  font-size: ${FONT_SIZE[3]};
  line-height: 18px;
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  cursor: ${({ disabled, onClick }) => (disabled ? 'not-allowed' : onClick ? 'pointer' : 'default')};
  margin-left: ${SPACING[4]};
  white-space: nowrap;
`

const StyledSetting = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
`
const StyledBody = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
`

const StyledLabel = styled.div<{ onClick?: React.EventHandler<React.MouseEvent> }>`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  font-weight: 600;
  align-self: center;
`

export const StyledIcon = styled.div`
  padding-right: ${SPACING[2]};
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 32px;
`

export const StyledText = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  margin-top: ${SPACING[2]};
`

const StyledFooter = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

export const StyledContent = styled.div`
  font-weight: ${FONT_WEIGHT[4]};
  font-size: ${FONT_SIZE[3]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  line-height: 18px;
  display: flex;
`

type SettingProps = {
  action?: React.ReactNode
  actionAs?: string | React.ElementType
  children?: React.ReactNode
  icon?: React.ReactNode
  footer?: React.ReactNode
  className?: string
  disabled?: boolean
  loading?: boolean
  isActionDestructive?: boolean
  isNested?: boolean
  label?: string | React.ReactNode
  text?: string | React.ReactNode
  onClick?: React.MouseEventHandler<HTMLElement>
  onClickAction?: (...args: any[]) => any
  actionProps?: React.ComponentProps<typeof StyledAction>
}

export function Setting({
  loading,
  label,
  action,
  actionAs = 'button',
  text,
  footer,
  icon,
  children,
  className,
  disabled = false,
  onClick,
  onClickAction,
  actionProps,
}: SettingProps): JSX.Element {
  return (
    <StyledSetting className={className}>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      <StyledBody>
        <StyledLabel onClick={disabled ? undefined : onClick}>
          <div>{label}</div>
          {text && <StyledText>{text}</StyledText>}
          {footer && <StyledFooter>{footer}</StyledFooter>}
        </StyledLabel>
        <StyledContent>
          {loading ? (
            <FontAwesomeIcon className="fa-spin voicemail-spinner" icon="spinner" />
          ) : (
            <>
              {children}
              {action && (
                <StyledAction
                  as={actionAs}
                  disabled={disabled}
                  onClick={disabled ? undefined : onClickAction || onClick}
                  {...actionProps}
                >
                  {action}
                </StyledAction>
              )}
            </>
          )}
        </StyledContent>
      </StyledBody>
    </StyledSetting>
  )
}

Setting.selector = StyledSetting
