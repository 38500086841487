import { ClusterRepliedState } from '@community_dev/types/lib/api/v2/Clusters'
import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { ChainBubble } from 'components/ChainBubble'
import { VirtualList, VirtualListHandle } from 'components/VirtualList'
import { ROUTES } from 'constants/routes'
import dayjs from 'utils/dayjs'
import { route } from 'utils/router'

type ClusterItemsProps = {
  allowSelection?: boolean
  campaignId: string
  items: any[]
}

export const ClusterItems = React.memo(({ allowSelection = true, campaignId, items }: ClusterItemsProps) => {
  const virtualRef = useRef<VirtualListHandle>(null)
  const history = useHistory()

  const onClusterClick = (clusterId: string) => {
    history.push(
      route(ROUTES.CAMPAIGNS.CLUSTER, {
        campaignId,
        clusterId,
        selectedFilter: ClusterRepliedState.UNREPLIED,
      }),
    )
  }

  const bubbles = items?.reduce(
    (accumulator, item) => {
      const itemTimeStamp = item.inserted_at && dayjs(item.inserted_at)
      const prevTimeStamp = accumulator?.timeStamp
      const shouldShowTime = !prevTimeStamp || itemTimeStamp.diff(prevTimeStamp, 'hours', true) < -1
      const repliedTo = [ClusterRepliedState.REPLIED, ClusterRepliedState.PARTIALLY_REPLIED].includes(item.replied_info)
      const isPartiallyReplied = item.replied_info === ClusterRepliedState.PARTIALLY_REPLIED
      const title = isPartiallyReplied ? 'some' : `${item.response_count} responses`
      const preTitle = isPartiallyReplied && `${item.response_count} responses `

      return {
        ...accumulator,
        timeStamp: item.inserted_at,
        bubbles: [
          ...accumulator.bubbles,
          <ChainBubble
            allowSelection={allowSelection}
            body={item.representative_text}
            clusterId={item.cluster_id}
            incoming
            isCluster
            key={item.cluster_id}
            openConvoModal={() => onClusterClick(item.cluster_id)}
            preTitle={preTitle}
            repliedTo={repliedTo}
            repliedToSome={isPartiallyReplied}
            showTime={shouldShowTime}
            showUser
            title={title}
            ts={item.inserted_at}
          />,
        ],
      }
    },
    {
      timeStamp: null,
      bubbles: [],
    },
  ).bubbles
  return (
    <VirtualList ref={virtualRef} rows={bubbles} testId="items-list">
      {({ virtualRow }) => {
        const bubble = bubbles[virtualRow.index]
        return <>{bubble}</>
      }}
    </VirtualList>
  )
})
