import { Button, BUTTON_VARIANTS, Filter as FilterComponent, Layout, SPACING, Typography } from '@community_dev/pixels'
import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { CampaignFilters } from './hooks/useCampaignList'
import { SetCampaignFilters } from './hooks/useCampaignListFilters'

import { CAPABILITIES } from 'constants/capabilities'
import { TOGGLE_FILTER_API_CAMPAIGNS } from 'constants/launch-darkly-flags'
import { userHasFlag } from 'hooks/useLaunchDarkly'
import { useHasCapability } from 'hooks/useUserCapability'

const StyledButton = styled(Button)`
  height: 40px;
`

const StyledFilterComponent = styled(FilterComponent)`
  & > div {
    padding: 0;
  }

  &:focus {
    outline: none;

    ${StyledButton} {
      outline: none;
      box-shadow: ${({ theme }) => `0 0 0 2px ${theme?.COLORS?.LINKS}`};
    }
  }
`

type FilterProps = {
  filters: CampaignFilters
  setFilters: SetCampaignFilters
  queryKey: QueryKey
}

export const FilterDropdown = ({ filters, setFilters, queryKey }: FilterProps): JSX.Element => {
  const { t } = useTranslation()
  const { COLORS } = useTheme()
  const queryClient = useQueryClient()
  const isCampaignTestCapabilityEnabled = useHasCapability(CAPABILITIES.FEATURE.CAMPAIGN_TEST.ALL)
  const isCampaignApiFilterEnabled = userHasFlag(TOGGLE_FILTER_API_CAMPAIGNS)
  const hasCampaignScheduleApiCapability = useHasCapability(CAPABILITIES.FEATURE.SCHEDULE_CAMPAIGN_API.ALL)

  const filterOptions = useMemo(() => {
    let options = [
      {
        label: 'Birthday Campaigns',
        value: 'birthday',
      },
    ]
    if (isCampaignTestCapabilityEnabled) {
      options = options.concat({
        label: 'Test Campaigns',
        value: 'test',
      })
    }
    if (isCampaignApiFilterEnabled && hasCampaignScheduleApiCapability) {
      options = [
        {
          label: 'API Campaigns',
          value: 'api',
        },
      ].concat(options)
    }
    return options
  }, [isCampaignApiFilterEnabled, isCampaignTestCapabilityEnabled, hasCampaignScheduleApiCapability])

  return (
    <Layout data-testid="campaign-filter">
      <StyledFilterComponent
        align="right"
        aria-label="campaign-filters"
        icon={false}
        label={<StyledButton variant={BUTTON_VARIANTS.OUTLINE}>Filter</StyledButton>}
        offsetTop={4}
        width="200px"
      >
        <Layout marginBottom={SPACING[1]}>
          <Typography
            color={COLORS.SUBTEXT}
            fontSize="12px"
            fontWeight="600"
            margin="0"
            padding={`${SPACING[2]} ${SPACING[4]}`}
            variant="body2"
          >
            {t('campaigns.showInList')}
          </Typography>
          {filterOptions.map((option) => (
            <FilterComponent.Option
              cypress={`${option.value}${filters[option.value] ? '-selected' : ''}`}
              key={option.value}
              multiSelect
              onClick={() => {
                setFilters((prev) => ({
                  ...prev,
                  [option.value]: !prev[option.value],
                }))
                queryClient.invalidateQueries(queryKey)
              }}
              selected={filters[option.value]}
            >
              <span data-testid={`option-${option.value}`}>{option.label}</span>
            </FilterComponent.Option>
          ))}
        </Layout>
      </StyledFilterComponent>
    </Layout>
  )
}
