import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import { TRCRegistration, getTRCRegistration, patchTCR } from 'api/tcr'
import { QUERY_CACHE, REFETCH_INTERVAL_TIME } from 'constants/query-cache'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useClientId } from 'hooks/useClient'

export function useTCRRegistrationData(): UseQueryResult<TRCRegistration> {
  const { data: seat } = useSeat()
  const clientId = useClientId()
  const [status, setStatus] = useState('')
  return useQuery(
    [QUERY_CACHE.TCR.REGISTRATION, clientId, seat?.id],
    () => getTRCRegistration({ clientId, seatId: seat!.id }),
    {
      onSuccess: (data: TRCRegistration) => {
        setStatus(data?.data?.status)
      },
      enabled: Boolean(status !== 'approved' && clientId && seat?.id),
      refetchOnWindowFocus: status === 'submitted' || status === 'failed',
      refetchInterval:
        status === 'submitted'
          ? REFETCH_INTERVAL_TIME.THIRTY_SECONDS
          : status === 'failed'
          ? REFETCH_INTERVAL_TIME.FIVE_MINUTES
          : undefined,
    },
  )
}

export function useTCRSeen(): UseMutationResult<TRCRegistration> {
  const { data: seat } = useSeat()
  const clientId = useClientId()
  const queryClient = useQueryClient()

  return useMutation(() => patchTCR({ clientId, seatId: seat!.id, body: { statusSeen: true } }), {
    onSuccess: (data: TRCRegistration) => {
      queryClient.setQueryData([QUERY_CACHE.TCR.REGISTRATION, clientId, seat?.id], data)
    },
  })
}
