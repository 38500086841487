import { Image, SPACING } from '@community_dev/pixels'
import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

import { MOBILE_BREAK } from 'constants/theme'
import { GrowYourCommunityData } from 'screens/HomeScreen/screens/ProductEducation/GrowYourCommunityCard/growYourCommunityData'

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${SPACING[5]};
  width: 100%;
  flex: 0 0 auto;
`
const CategoryTitle = styled.h3`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  margin-top: 0;
  @media only screen and (max-width: ${MOBILE_BREAK}) {
    padding-left: 12px;
  }
`

const LeaderListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${SPACING[4]};
  @media only screen and (max-width: ${MOBILE_BREAK}) {
    margin: 0 ${SPACING[2]};
  }
`

const LeaderCard = styled.button`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border: none;
  padding: 0;
  box-shadow: ${({ theme }) => theme.SHADOWS.CARD};
  border-radius: 8px;
  max-width: 167px;
  max-height: 142px;
  cursor: pointer;
  overflow: hidden;
  flex: 1;
  @media only screen and (max-width: ${MOBILE_BREAK}) {
    max-height: 167px;
  }
`
const LeaderContent = styled.div`
  flex: 1;
  overflow: hidden;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 12px 12px 12px;
`
const LeaderName = styled.h4`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  margin: ${SPACING[2]} 0 0 0;
`
const LeaderSummary = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  margin: 0;
`

const LeaderImageContainer = styled.div`
  overflow: hidden;
  height: 71px;
`

const LeaderImage = styled(Image)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

type LeaderLaunchCardProps = {
  leader?: Partial<GrowYourCommunityData>
  onClick: Dispatch<SetStateAction<GrowYourCommunityData | null>>
}

const LeaderLaunchCard = ({ leader = {}, onClick }: LeaderLaunchCardProps) => {
  const { name, heroImage: { url = [] } = {}, summary } = leader

  return (
    <LeaderCard
      data-testid="leader-card"
      onClick={() => {
        onClick(leader as GrowYourCommunityData)
      }}
    >
      <LeaderImageContainer>
        <LeaderImage alt={name} src={url} />
      </LeaderImageContainer>
      <LeaderContent>
        <LeaderName>{name}</LeaderName>
        <LeaderSummary>{summary}</LeaderSummary>
      </LeaderContent>
    </LeaderCard>
  )
}

type LeaderLaunchListProps = {
  list: {
    number: string
  }[]
  onClick: Dispatch<SetStateAction<GrowYourCommunityData | null>>
}

const LeaderLaunchList = ({ list, onClick }: LeaderLaunchListProps) => {
  return (
    <LeaderListContainer>
      {list.map((leader: any) => (
        <LeaderLaunchCard key={leader.number} leader={leader} onClick={onClick} />
      ))}
    </LeaderListContainer>
  )
}

type ListOfCategoriesProps = {
  list: GrowYourCommunityData[][]
  onClick: Dispatch<SetStateAction<GrowYourCommunityData | null>>
}

export const ListOfCategories = ({ list, onClick }: ListOfCategoriesProps): JSX.Element => {
  return (
    <>
      {list.map((leaders: any) => {
        const [{ category }] = leaders
        return (
          <CategoryContainer key={category}>
            <CategoryTitle>{category}</CategoryTitle>
            <LeaderLaunchList list={leaders} onClick={onClick} />
          </CategoryContainer>
        )
      })}
    </>
  )
}
