import { route } from '@community_dev/requests'
import { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'

import { ROUTES } from 'constants/routes'
import dayjs from 'utils/dayjs'

type DateRange = {
  start: Date
  end: Date
  firstDayOfMonth: Date
}

// Checks params and returns the current date range + - 6 days
// It is done to account incomplete weeks at the start and end of the month
export const useCurrentMonthDateRange = (): DateRange => {
  const { month, year } = useParams<{ month: string; year: string }>()
  const history = useHistory()

  // check that the params are valid
  const monthInt = parseInt(month, 10)
  const yearInt = parseInt(year, 10)

  const isValidMonth = monthInt >= 1 && monthInt <= 12
  const isValidYear = yearInt >= 2015

  // If the month or year is invalid, redirect to the current month
  useEffect(() => {
    if (!isValidMonth || !isValidYear) {
      const start = dayjs().startOf('month').toDate()

      history.replace(route(ROUTES.SCHEDULED.MONTH, { 'month?': start.getMonth() + 1, 'year?': start.getFullYear() }))
    }
  }, [isValidMonth, isValidYear, history])

  return useMemo(() => {
    // If the month or year is invalid, return the current month
    if (!isValidMonth || !isValidYear) {
      const today = dayjs()
      const firstDayOfMonth = today.startOf('month').toDate()
      const start = today.startOf('month').subtract(6, 'day').toDate()
      const end = today.endOf('month').add(6, 'day').toDate()

      return {
        start,
        end,
        firstDayOfMonth,
      }
    }

    const paramDate = dayjs(`${yearInt}-${monthInt}-1`)
    return {
      start: paramDate.startOf('month').subtract(6, 'day').toDate(),
      end: paramDate.endOf('month').add(6, 'day').toDate(),
      firstDayOfMonth: paramDate.startOf('month').toDate(),
    }
  }, [monthInt, yearInt, isValidMonth, isValidYear])
}
