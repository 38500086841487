import { useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { ROUTES } from 'constants/routes'

const ROUTES_WITH_WORKFLOW_ID = Object.values(ROUTES.AUTOMATIONS).filter((path) => path.includes(':workflowId'))

/**
 * Note:
 *
 * Intentionally not using `useParams` which returns undefined for workflowId
 * if this hook is used outside of a Route that has workflowId in the path.
 */
export function useWorkflowId(): { workflowId?: string } {
  const match = useRouteMatch<{ workflowId?: string }>(ROUTES_WITH_WORKFLOW_ID)
  const workflowId = useMemo(() => match?.params?.workflowId, [match])

  return {
    workflowId,
  }
}
