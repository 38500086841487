import {
  ChatBubble,
  CheckBox,
  ConversationIcon,
  FONT_SIZE,
  Info,
  InfoIconFilled,
  InvisibleCharacters,
  SPACING,
  UsageCounterProps,
} from '@community_dev/pixels'
import { TCPA_MESSAGE } from '@community_dev/pixels'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css, useTheme } from 'styled-components'

import { MembersIcon } from '../../../Icons'
import { CommunityName } from '../CommunityName'
import { FormSection } from '../FormSection'

import { ProtectedCommunityNames } from 'api/community'
import { EditSignUpConfirmationMessage, EditWelcomeMessage } from 'components/EditOnboardingMessage'
import { MultimediaInput } from 'components/MultimediaInput'
import { useSettings } from 'contexts/SettingsProvider'
import { useCommunities } from 'hooks/useCommunities'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useOnboardingMessages } from 'hooks/useOnboardingMessages'

const StyledPadding = styled.div`
  padding: ${SPACING[2]} 0;
`

const StyledMessage = styled(StyledPadding)`
  max-width: 250px;
`

const StyledMessageLabel = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  padding: ${SPACING[1]};
  margin-left: ${SPACING[4]};
  display: flex;
  align-content: center;
`

const StyledCheckboxLabel = styled.label`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  margin-left: ${SPACING[1]};
  display: flex;
  align-items: center;
  font-size: ${FONT_SIZE[3]};

  & > input {
    margin-right: ${SPACING[2]};
  }
`

const editMessageStyles = css`
  margin-left: ${SPACING[2]};
`
const StyledEditSignUpConfirmationMessage = styled(EditSignUpConfirmationMessage)`
  ${editMessageStyles}
`

const StyledEditWelcomeMessage = styled(EditWelcomeMessage)`
  ${editMessageStyles}
`

export type ConfirmationFormProps = {
  additionalMessage: string | null
  usageCounterProps: UsageCounterProps
  onChange(additionalMessage: string | null): any
}

export const ConfirmationForm = ({
  additionalMessage,
  usageCounterProps,
  onChange,
}: ConfirmationFormProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const currentTheme = useCurrentTheme()
  const { settings } = useSettings()

  const { data: webVisitors } = useCommunities({
    select: (communities) =>
      communities.all.find((community) => community.title === ProtectedCommunityNames.WEB_VISITORS),
  })
  const { t } = useTranslation(undefined, { keyPrefix: 'settings.integrations.signupUnit.confirmationForm' })

  const [useAdditionalConfirmation, setUseAdditionalConfirmation] = useState(additionalMessage !== null)
  const { welcomeMessage, confirmationMessage, updateMessage } = useOnboardingMessages()
  const tcpaMessage = confirmationMessage?.tcpa || TCPA_MESSAGE

  const handleConfirmationToggle = (checked: boolean) => {
    setUseAdditionalConfirmation(checked)
    // Null is valid but an empty string is not so opting out of additional confirmation sets the value to null
    onChange(checked ? '' : null)
  }

  return (
    <>
      <FormSection name={t('title')}>
        <StyledPadding>
          <Info icon={<ConversationIcon color={COLORS?.SUBTEXT} size={18} />}>{t('info')}</Info>
        </StyledPadding>
        <StyledMessage>
          <ChatBubble>
            <InvisibleCharacters>{`${welcomeMessage?.text}${
              welcomeMessage?.legalMessage ? `${welcomeMessage?.legalMessage}` : ''
            }`}</InvisibleCharacters>
          </ChatBubble>
          <StyledMessageLabel>
            {t('welcomeMessage')}
            <StyledEditWelcomeMessage
              charMax={welcomeMessage?.maxLength}
              defaultValue={welcomeMessage?.text}
              legalLanguage={welcomeMessage?.legalMessage}
              onSave={(values) => updateMessage({ id: welcomeMessage?.id, text: values.message })}
              segmentMax={welcomeMessage?.maxSegmentCount}
            />
          </StyledMessageLabel>
        </StyledMessage>
        <StyledMessage>
          <ChatBubble>
            <InvisibleCharacters>{`${confirmationMessage?.text}${tcpaMessage}`}</InvisibleCharacters>
          </ChatBubble>
          <StyledMessageLabel>
            {t('signupConfirmationMessage')}
            <StyledEditSignUpConfirmationMessage
              charMax={confirmationMessage?.maxLength}
              defaultValue={confirmationMessage?.text}
              onSave={(values) => updateMessage({ id: confirmationMessage?.id, text: values.message })}
              segmentMax={confirmationMessage?.maxSegmentCount}
              tcpaMessage={tcpaMessage}
            />
          </StyledMessageLabel>
        </StyledMessage>
        <StyledPadding>
          <StyledCheckboxLabel>
            <CheckBox onChange={(checked) => handleConfirmationToggle(checked)} selected={useAdditionalConfirmation} />
            Send Additional Confirmation
          </StyledCheckboxLabel>
        </StyledPadding>
        {useAdditionalConfirmation && (
          <StyledPadding>
            <MultimediaInput
              emojiSkinTone={settings.skinTone}
              emojiTheme={currentTheme.type}
              gallery={false}
              gif={false}
              onChange={(e) => onChange(e.target.value)}
              onEmojiSelect={(_, text) => {
                onChange(text)
              }}
              usageCounterProps={usageCounterProps}
              value={additionalMessage ?? undefined}
            />
          </StyledPadding>
        )}
        <StyledPadding>
          <Info icon={<InfoIconFilled color={COLORS?.SUBTEXT} size={20} />}>
            If you're offering a discount code, remember to paste it into your '<b>Additional Confirmation</b>'
          </Info>
        </StyledPadding>
        <StyledPadding>
          <Info icon={<MembersIcon color={COLORS?.SUBTEXT} size={13} />}>
            These new Members will be added to your{' '}
            {webVisitors && <CommunityName color={webVisitors?.color}>{webVisitors?.title}</CommunityName>} Community
          </Info>
        </StyledPadding>
      </FormSection>
    </>
  )
}
