import { Pill, SPACING, Tooltip } from '@community_dev/pixels'
import noop from 'lodash/noop'

export type WorkflowKeywordsProps = {
  keywords: string[]
  removable?: boolean
  onRemoveKeyword?(keyword: string): any
}

export function WorkflowKeywords({
  keywords,
  removable = true,
  onRemoveKeyword = noop,
}: WorkflowKeywordsProps): JSX.Element {
  return (
    <>
      {keywords.map((keyword) => {
        return (
          <Tooltip content={keyword.length >= 30 ? keyword : undefined} key={keyword} placement="bottom">
            <span>
              <Pill
                onRemove={() => onRemoveKeyword(keyword)}
                removable={removable}
                style={{ marginRight: SPACING[3], marginBottom: SPACING[3] }}
              >
                {keyword.length >= 30 ? `${keyword.substring(0, 30)}…` : keyword}
              </Pill>
            </span>
          </Tooltip>
        )
      })}
    </>
  )
}
