import { SPACING } from '@community_dev/pixels'
import React from 'react'
import styled from 'styled-components'

import { Bubble } from 'components/Bubble'
import { ChainBubble } from 'components/ChainBubble'
import { replacePlaceholdersWithNames } from 'components/ComposeEditor/util/replacePlaceholders'
import { useCurrentParentCampaignDetails } from 'hooks/useCampaign'
import { pluralizeNumeral } from 'utils/general'
import { normalizeSent } from 'utils/normalize'

const StyledThreadCount = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  margin: ${SPACING[2]} ${SPACING[5]};
`

export const Thread = ({ recipientCount }: any): JSX.Element | null => {
  const { data: parentCampaignData } = useCurrentParentCampaignDetails()

  const { text, media } = parentCampaignData?.representativeMessage || {}

  if (!parentCampaignData) return null

  const parentItem = normalizeSent(parentCampaignData)

  const countLabel = pluralizeNumeral(recipientCount, '0,0', 'selected response', 'selected responses')
  const stackedCount = recipientCount > 3 ? 3 : recipientCount

  const body = parentItem?.body ? replacePlaceholdersWithNames(parentItem?.body, parentItem?.placeholders) : undefined

  return (
    <div data-testid="message-thread">
      <ChainBubble body={body} incoming={false} showTime title="You" ts={parentItem?.ts} />
      <div>
        {(text || media) && <StyledThreadCount>{countLabel}</StyledThreadCount>}
        <Bubble
          body={text || null}
          incoming
          isCluster={recipientCount > 1}
          media={media}
          showDrilldownArrow={false}
          stacked={stackedCount}
          tail={false}
        />
      </div>
    </div>
  )
}
