import mapKeys from 'lodash/mapKeys'
import snakeCase from 'lodash/snakeCase'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { MockBody } from './MockBody'
import { MockFooter } from './MockFooter'
import { PhoneChrome } from './PhoneChrome'

import { JsonConfig } from 'api/mgu'

const previewDomain = `${import.meta.env.VITE_EMBED_PREVIEW_URL}${import.meta.env.VITE_PUBLIC_URL}`

const StyledMobilePreviewIFrame = styled.iframe`
  border: none;
  flex: 1;
  min-height: 248px;
`

type MobilePreviewProps = {
  jsonConfig: JsonConfig
}

export const MobilePreview = ({ jsonConfig }: MobilePreviewProps): JSX.Element => {
  const previewFrame = useRef<HTMLIFrameElement>(null)
  useEffect(() => {
    // embed expects snake_case since that is what the API provides and it doesn't want overhead.
    const snakeCaseJsonConfig = {
      ...mapKeys(jsonConfig, (v, k) => snakeCase(k)),
      force_view: 'mobile',
    }
    previewFrame?.current?.contentWindow?.postMessage(
      window.JSON.stringify({
        action: 'setConfig',
        payload: snakeCaseJsonConfig,
      }),
      '*',
    )
    const handleMessage = (event) => {
      if (previewFrame?.current?.contentWindow !== event.source) return
      let { data } = event
      try {
        data = window.JSON.parse(data)
      } catch (e) {
        // may already be parsed
      }
      switch (data.action) {
        case 'ready':
          previewFrame?.current?.contentWindow?.postMessage(
            window.JSON.stringify({
              action: 'setConfig',
              payload: snakeCaseJsonConfig,
            }),
            '*',
          )
          break
        default:
      }
    }
    window.addEventListener('message', handleMessage, false)
    return () => window.removeEventListener('message', handleMessage, false)
  }, [jsonConfig])

  if (jsonConfig.displayType !== 'inline') {
    return (
      <PhoneChrome>
        <StyledMobilePreviewIFrame allowTransparency ref={previewFrame} src={previewDomain} title="Mobile Preview" />
      </PhoneChrome>
    )
  }

  return (
    <PhoneChrome>
      <MockBody />
      <StyledMobilePreviewIFrame allowTransparency ref={previewFrame} src={previewDomain} title="Mobile Preview" />
      <MockFooter />
    </PhoneChrome>
  )
}
