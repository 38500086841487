import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'

import { putClientContactPhoto } from 'api/client'
import { putClientFanOnboardingMessage, putClientName, putClientTask, putClientVoicemail } from 'api/onboarding'
import { QUERY_CACHE } from 'constants/query-cache'

type ResponseHandlers = {
  onError?: (...args: any[]) => void
  onMutate?: (...args: any[]) => void
  onSuccess?: (...args: any[]) => void
}

export function useCompleteClientTask({ onError, onSuccess }: ResponseHandlers): UseMutationResult<
  unknown,
  unknown,
  {
    id: string
    status?: string
  },
  unknown
> {
  const queryClient = useQueryClient()
  return useMutation(putClientTask, {
    onError,
    onSuccess,
    onSettled() {
      queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
    },
  })
}

export function useUpdateFanMessage({ onError, onSuccess }: ResponseHandlers): UseMutationResult<
  unknown,
  unknown,
  {
    id: string
    text: string
  },
  unknown
> {
  const queryClient = useQueryClient()

  return useMutation(putClientFanOnboardingMessage, {
    onError,
    onSuccess,
    onSettled() {
      queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
    },
  })
}

export function useUpdateContactPhoto({ onError, onMutate, onSuccess }: ResponseHandlers): UseMutationResult<
  unknown,
  unknown,
  {
    body: any
  },
  unknown
> {
  const queryClient = useQueryClient()

  return useMutation(putClientContactPhoto, {
    onError,
    onMutate,
    onSuccess,
    onSettled() {
      queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
    },
  })
}

export function useUpdateName({ onError, onMutate, onSuccess }: ResponseHandlers): UseMutationResult<
  unknown,
  unknown,
  {
    body: any
    id: string
  },
  unknown
> {
  const queryClient = useQueryClient()

  return useMutation(putClientName, {
    onError,
    onMutate,
    onSuccess,
    onSettled() {
      queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
    },
  })
}

export function useUpdateVoicemail({ onError, onMutate, onSuccess }: ResponseHandlers): UseMutationResult<
  unknown,
  unknown,
  {
    body: any
  },
  unknown
> {
  const queryClient = useQueryClient()

  return useMutation(putClientVoicemail, {
    onError,
    onMutate,
    onSuccess,
    onSettled() {
      queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
    },
  })
}
