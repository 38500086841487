import { BORDER_RADIUS, SPACING } from '@community_dev/pixels'
import mapKeys from 'lodash/mapKeys'
import snakeCase from 'lodash/snakeCase'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { BrowserChrome } from './BrowserChrome'
import { MockBody } from './MockBody'
import { MockFooter } from './MockFooter'

import { JsonConfig } from 'api/mgu'

const previewDomain = `${import.meta.env.VITE_EMBED_PREVIEW_URL}${import.meta.env.VITE_PUBLIC_URL}`

const StyledDesktopPreviewIFrame = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
  padding: 0;
`

const StyledInlinePreview = styled.div`
  height: auto;

  &,
  iframe {
    min-height: 400px;
  }
`

const StyledPreviewFrame = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS[1]};
  overflow: hidden;
  width: 100%;
  max-width: 518px;
  margin: ${SPACING[1]};
`

type DesktopPreviewProps = {
  jsonConfig: JsonConfig
}

export const DesktopPreview = ({ jsonConfig }: DesktopPreviewProps): JSX.Element => {
  const previewFrame = useRef<HTMLIFrameElement>(null)
  useEffect(() => {
    // embed expects snake_case since that is what the API provides and it doesn't want overhead.
    const snakeCaseJsonConfig = {
      ...mapKeys(jsonConfig, (v, k) => snakeCase(k)),
      force_view: 'desktop',
    }
    previewFrame?.current?.contentWindow?.postMessage(
      window.JSON.stringify({
        action: 'setConfig',
        payload: snakeCaseJsonConfig,
      }),
      '*',
    )
    const handleMessage = (event) => {
      if (previewFrame?.current?.contentWindow !== event.source) return
      let { data } = event
      try {
        data = window.JSON.parse(data)
      } catch (e) {
        // may already be parsed
      }
      switch (data.action) {
        case 'ready':
          previewFrame?.current?.contentWindow?.postMessage(
            window.JSON.stringify({
              action: 'setConfig',
              payload: snakeCaseJsonConfig,
            }),
            '*',
          )
          break
        default:
      }
    }
    window.addEventListener('message', handleMessage, false)
    return () => window.removeEventListener('message', handleMessage, false)
  }, [jsonConfig])

  if (jsonConfig.displayType !== 'inline') {
    return (
      <StyledDesktopPreviewIFrame allowTransparency ref={previewFrame} src={previewDomain} title="Desktop Preview" />
    )
  }

  return (
    <StyledPreviewFrame>
      <BrowserChrome />
      <MockBody />
      <StyledInlinePreview style={{ background: jsonConfig.backgroundColor }}>
        <StyledDesktopPreviewIFrame allowTransparency ref={previewFrame} src={previewDomain} title="Desktop Preview" />
      </StyledInlinePreview>
      <MockFooter />
    </StyledPreviewFrame>
  )
}
