import { SPACING, Typography } from '@community_dev/pixels'
import { History } from 'history'
import React from 'react'

import { ROUTES } from 'constants/routes'
import { Setting, SettingsSection } from 'layouts/SettingsLayout'

type SecurityProps = {
  history: History
}

class Security extends React.PureComponent<SecurityProps> {
  render(): JSX.Element {
    return (
      <SettingsSection>
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          Authentication
        </Typography>
        <Setting
          action="Change"
          label="My password"
          onClick={() => this.props.history.push(ROUTES.SETTINGS.SECURITY.EDIT)}
        >
          *******
        </Setting>
      </SettingsSection>
    )
  }
}

export default Security
