const base62Characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

// Function to convert a UTF-8 string to a hexadecimal string
export const utf8ToHex = (str: string): string => {
  return Array.from(str)
    .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
    .join('')
}

// Function to convert a hexadecimal string to a BigInt
export const hexToBigInt = (hex: string): bigint => {
  if (!hex) {
    return BigInt(0)
  }
  return BigInt('0x' + hex)
}

// Function to encode a BigInt to a base62 string
export const base62Encode = (num: bigint): string => {
  let result = ''
  while (num > 0) {
    // Get the remainder of num divided by 62
    const remainder = Number(num % BigInt(62))
    // Prepend the corresponding base62 character to the result string
    result = base62Characters[remainder] + result
    // Divide num by 62 to process the next digit
    num = num / BigInt(62)
  }
  return result || '0' // Return '0' if the result is an empty string
}

// Function to convert a UTF-8 string to a base62 string
export const utf8ToBase62 = (str: string): string => {
  // Convert the UTF-8 string to a hexadecimal string
  const hex = utf8ToHex(str)
  // Convert the hexadecimal string to a BigInt
  const bigIntValue = hexToBigInt(hex)
  // Encode the BigInt to a base62 string
  return base62Encode(bigIntValue)
}
