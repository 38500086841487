import { ApiError } from '@community_dev/requests'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { BillingClient, getBillingClient } from 'api/billing'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

export function useBillingClient(): UseQueryResult<BillingClient, ApiError> {
  const clientId = useClientId()
  const [enabled, setEnabled] = useState(true)
  return useQuery([QUERY_CACHE.BILLING.CLIENT, { clientId }], () => getBillingClient({ clientId }), {
    enabled: enabled && Boolean(clientId),
    onError(err) {
      if (err.status !== 404) return
      setEnabled(false)
    },
    staleTime: STALE_TIME.TEN_MINUTES,
    retryOnMount: false,
  })
}
