import React from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import { mockDemographicInsights } from './insights-test-support'
import { InsightsLocationView } from './InsightsLocationView'
import { InsightsRootView } from './InsightsRootView'
import { useDemographicInsights } from './use-demographic-insights'
import { UnitsPreferenceProvider } from './use-units-preference'

import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { useHasCapability } from 'hooks/useUserCapability'
import { SidebarLayout } from 'layouts/SidebarLayout'

// Based on conversations with design, the responsive rules for this container
// should be:
//
// - for non-ultrawide screen widths, the content should be centered on the page
//   with a max-width of 896px, and margin: auto for the sides
// - for narrow screen widths, the side margins should never decrease below
//   16px
// - for extra wide screen widths, the left-side margin should never increase
//   above 160px, even if that prevents the content from being centered.
//   note - this results in content that appears *slightly* off-center on many
//   common laptop screen resolutions. This behavior may not be desirable.
const maxContentWidth = 896
// I *would* use SPACING[4] except that we're exporting strings ('16px') instead
// of Number data types for these constants, which means we can't do math
// with our layout variables without calling parseInt, which breaks if someone
// down the line decides to get clever and change '16px' to '2rem' or something.
const minMargin = 16
const maxLeftMargin = 160
const ResponsiveWrapper = styled.div`
  // we're (ab)using padding as a way to specify a min margin here, so
  // we need to offset the max width by the padding factor since padding
  // factors into width in the border-box model
  max-width: ${maxContentWidth + 2 * minMargin}px;
  padding: 0 ${minMargin}px;
  margin: 60px auto;
  position: relative;
`

const PinLeftWrapper = styled.div`
  max-width: ${maxContentWidth + 2 * maxLeftMargin}px;
  position: relative;
`

const ScrollContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`

export const InsightsScreen = (): JSX.Element => {
  const insightsEnabled = useHasCapability(CAPABILITIES.FEATURE.INSIGHTS.ALL)
  const demographicInsights = useDemographicInsights({
    enabled: insightsEnabled,
  })
  const mockInsights = mockDemographicInsights()

  return (
    <UnitsPreferenceProvider>
      <SidebarLayout greyBg>
        <ScrollContainer>
          <PinLeftWrapper>
            <ResponsiveWrapper>
              <Switch>
                {insightsEnabled && (
                  <Route path={ROUTES.INSIGHTS.LOCATION_DETAIL}>
                    <InsightsLocationView demographicInsights={insightsEnabled ? demographicInsights : mockInsights} />
                  </Route>
                )}
                <Route>
                  <InsightsRootView
                    demographicInsights={insightsEnabled ? demographicInsights : mockInsights}
                    enabled={insightsEnabled}
                  />
                </Route>
              </Switch>
            </ResponsiveWrapper>
          </PinLeftWrapper>
        </ScrollContainer>
      </SidebarLayout>
    </UnitsPreferenceProvider>
  )
}
