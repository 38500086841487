import { Layout, Typography, SPACING, ChevronLeftIcon } from '@community_dev/pixels'
import { useEffect } from 'react'
import { Redirect, useHistory, useParams } from 'react-router'
import styled from 'styled-components'

import { DynamicBody } from './DynamicBody'
import { DynamicButton } from './DynamicButton'

import { ROUTES } from 'constants/routes'
import { useAddons } from 'hooks/useAddons'
import { SidebarLayout } from 'layouts/SidebarLayout'
import analytics from 'utils/analytics'

const MAX_WIDTH = '670px'

const StyledBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${SPACING[5]};
  }
`

export const DetailedAddOnScreen = (): JSX.Element => {
  const { addOn }: { addOn: string } = useParams()
  const history = useHistory()
  const { selectedAddOn } = useAddons(addOn)

  useEffect(() => {
    if (selectedAddOn) {
      analytics.track(analytics.events.AddOnDetailsViewed(selectedAddOn.title))
    }
  }, [selectedAddOn])

  if (!selectedAddOn) {
    return <Redirect to={ROUTES.ADD_ONS.ROOT} />
  }

  return (
    <SidebarLayout>
      <Layout alignItems="flex-start" display="flex" justifyContent="center" paddingRight={SPACING[3]} width="100%">
        {selectedAddOn && (
          <Layout display="flex" maxWidth={MAX_WIDTH} paddingTop={SPACING[9]} width="100%">
            <Layout
              alignSelf="flex-start"
              as="button"
              backgroundColor="unset"
              border="none"
              cursor="pointer"
              onClick={() => history.goBack()}
              paddingRight={SPACING[5]}
              paddingTop={SPACING[2]}
            >
              <ChevronLeftIcon />
            </Layout>
            <StyledBody>
              <Layout alignItems="center" display="flex" justifyContent="space-between" width="100%">
                <Typography margin="0" variant="h1">
                  {selectedAddOn.title}
                </Typography>
                <DynamicButton {...selectedAddOn?.details?.action} />
              </Layout>
              <DynamicBody body={selectedAddOn?.details?.content || []} />
            </StyledBody>
          </Layout>
        )}
      </Layout>
    </SidebarLayout>
  )
}
