import { useMutation, useQueryClient } from '@tanstack/react-query'

import { putClientFanOnboardingMessage } from 'api/onboarding'
import { QUERY_CACHE } from 'constants/query-cache'
import { useToastMessage } from 'hooks/useToastMessage'
import texts from 'texts.json'

export type UpdateMessage = {
  id: string
  text: string
}

type UpdateOnboardingMessage = {
  updateMessage: (o: UpdateMessage) => void
  isProcessing: boolean
}

export const useUpdateOnboardingMessage = (): UpdateOnboardingMessage => {
  const { showToastMessage } = useToastMessage()
  const queryClient = useQueryClient()

  const { mutate: updateMessage, isLoading: isProcessing } = useMutation(putClientFanOnboardingMessage, {
    onError() {
      showToastMessage({
        message: texts.errors.generic,
        success: false,
      })
    },
    onSettled() {
      queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
    },
    onSuccess() {
      showToastMessage({
        message: 'Message updated',
        success: true,
      })
    },
  })

  return {
    updateMessage,
    isProcessing,
  }
}
