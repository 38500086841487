import { BORDER_RADIUS } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledVideoRoot = styled.div`
  width: 100%;
  height: auto;
  cursor: pointer;
`

export const StyledVideoOverlay = styled.div`
  height: 60px;
  width: 60px;
  background-color: ${({ theme }) => theme?.COLORS?.OVERLAY};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  svg {
    color: white;
    font-size: 22px;
    position: relative;
    left: 3px;
  }
`

export const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;

  border-radius: ${BORDER_RADIUS[2]};
  outline: none;
  transition: opacity 0.1s;
  transform: scale(0.98);
  opacity: 0;

  &:fullscreen {
    object-fit: contain;
  }
`

type StyledVideoMainProps = {
  $overlay: boolean
  $loaded: boolean
  $unsupported: boolean
}

export const StyledVideoMain = styled.div<StyledVideoMainProps>`
  position: relative;
  overflow: hidden;
  width: ${({ $unsupported }) => ($unsupported ? '300px' : '100%')};
  height: 0;
  outline: none;
  border-radius: ${BORDER_RADIUS[2]};
  background-color: ${({ theme, $loaded, $unsupported }) =>
    $loaded || $unsupported ? 'transparent' : theme?.COLORS?.MEDIA_BACKGROUND_COLOR};
  transition: ${({ $loaded }) => $loaded && 'background-color 1s'};

  ${StyledVideoOverlay} {
    display: ${({ $overlay }) => ($overlay ? 'flex' : 'none')};
  }

  ${StyledVideo} {
    opacity: ${({ $loaded }) => $loaded && 1};
    transform: ${({ $loaded }) => $loaded && 'scale(1)'};
  }
`
