import { ContentBlur } from '@community_dev/pixels'
import { MouseEventHandler } from 'react'

import { MediaPreviewPlaceholder, MediaPreviewRoot } from './MediaPreview.style'

import { Audio, Image, Video } from 'components/media'
import analytics from 'utils/analytics'

type MediaProps = {
  src: string
  width?: number
  height?: number
  maxWidth?: number
  maxHeight?: number
  alt?: string
  type?: string
  poster?: string
  mimetype?: string
  sensitive?: string[]
}

export type MediaPreviewProps = {
  className?: string
  href?: string
  type?: string
  mediaProps?: MediaProps
  inbound?: boolean
  onImageClick?: MouseEventHandler<HTMLButtonElement>
}

function MediaPreview({ className, href, mediaProps, type, inbound, onImageClick }: MediaPreviewProps): JSX.Element {
  function renderImage(mediaProps: MediaProps): JSX.Element {
    if (mediaProps.sensitive?.length) {
      return (
        <ContentBlur
          id={mediaProps.src}
          onClickSeePhoto={() => {
            analytics.track(analytics.events.SensitiveContentViewed())
          }}
        >
          <Image
            alt={mediaProps.alt}
            height={mediaProps.height}
            maxHeight={mediaProps.maxHeight}
            maxWidth={mediaProps.maxWidth}
            onClick={onImageClick}
            src={mediaProps.src}
            width={mediaProps.width}
          />
        </ContentBlur>
      )
    }

    return (
      <Image
        alt={mediaProps.alt}
        height={mediaProps.height}
        maxHeight={mediaProps.maxHeight}
        maxWidth={mediaProps.maxWidth}
        onClick={onImageClick}
        src={mediaProps.src}
        width={mediaProps.width}
      />
    )
  }

  function renderAudio(mediaProps: MediaProps): JSX.Element {
    return <Audio src={href || (mediaProps && mediaProps.src)} />
  }

  function renderVideo(mediaProps: MediaProps): JSX.Element {
    return (
      <Video
        height={mediaProps.height}
        maxHeight={mediaProps.maxHeight}
        maxWidth={mediaProps.maxWidth}
        poster={mediaProps.poster}
        src={mediaProps.src}
        type={mediaProps.type}
        width={mediaProps.width}
      />
    )
  }

  function renderMedia(): JSX.Element | null {
    let showPlaceholder = false

    if (mediaProps?.mimetype?.includes('quicktime')) showPlaceholder = true
    if (!mediaProps) return <MediaPreviewPlaceholder />
    if (showPlaceholder) return <MediaPreviewPlaceholder />
    const mediaComponent = type?.includes('image')
      ? renderImage(mediaProps)
      : type?.includes('audio')
      ? renderAudio(mediaProps)
      : type?.includes('video')
      ? renderVideo(mediaProps)
      : null

    return mediaComponent
  }

  let hasSize = true
  if (mediaProps) {
    const { height, width } = mediaProps

    if (width === 0 || Number.isNaN(width) || height === 0 || Number.isNaN(height)) hasSize = false
  }

  return (
    <MediaPreviewRoot className={className} hasSize={hasSize} inbound={inbound}>
      {renderMedia()}
    </MediaPreviewRoot>
  )
}

export default MediaPreview
