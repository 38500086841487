import { Placeholder } from '@community_dev/filter-dsl/lib/subscription-data'
import { LexicalNode } from 'lexical'

/**
 * Generic method to replace the placeholder keys (e. g. {key:source}) with
 * their names (e. g. {My Placeholder}).
 * @param text
 * @param placeholders
 * @returns string
 */
export function replacePlaceholdersWithNames(text: string, placeholders: Placeholder[]): string {
  if (placeholders?.length > 0) {
    return placeholders.reduce((message, placeholder) => {
      // using split/join here because of limited runtime support for .replaceAll
      return message.split(`${placeholder.key}:${placeholder.source}`).join(placeholder.name)
    }, text)
  } else {
    return text
  }
}

export function placeholderNodeToText(node: LexicalNode): string {
  return `{${node.__id}:${node.__source}}`
}
