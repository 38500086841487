import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { ResponseTags } from '@community_dev/types/lib/api/v2/Campaign'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { CampaignFilters } from 'screens/Campaigns/hooks/useCampaignList'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type CampaignDetails = CamelCasedPropertiesDeep<Api.V2.Campaign>
export type Campaign = CamelCasedPropertiesDeep<Api.V2.Campaign>
export type Media = CamelCasedPropertiesDeep<Api.V2.Media>
export type PaginatedCampaigns = CamelCasedPropertiesDeep<Api.V2.Pagination<Api.V2.Campaign>>

export type CampaignResponses = CamelCasedPropertiesDeep<Api.V2.Pagination<Api.V2.CampaignResponses>>

export enum SortField {
  RECIPIENT_COUNT = 'recipient_count',
  LINK_CLICK_RATE = 'link_click_rate',
  MEDIA_VIEW_RATE = 'media_view_rate',
  RESPONSE_RATE = 'response_rate',
  SCHEDULED_AT = 'scheduled_at',
  STOP_RATE = 'stop_rate',
}

export enum SortDirection {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export function campaignDetailsQuery({
  clientId,
  campaignId,
}: {
  clientId: string
  campaignId?: string
}): Promise<CampaignDetails> {
  const requestRoute = route(ENDPOINTS.CAMPAIGN.DETAILS, {
    clientId,
    campaignId,
  })

  return request(requestRoute, { method: HTTP.GET }).then(({ data }) => data)
}

export function getCampaignList({
  clientId,
  communicationChannels = [CommunicationChannel.SMS, CommunicationChannel.WHATS_APP, CommunicationChannel.AMB],
  endDatetime,
  filters,
  pageParam,
  pageSize,
  searchTerm,
  sortDirection = SortDirection.DESCENDING,
  sortField = SortField.SCHEDULED_AT,
  startDatetime,
}: {
  clientId: string
  communicationChannels?: CommunicationChannel[]
  endDatetime?: string
  filters?: CampaignFilters
  pageParam?: number
  pageSize?: number
  searchTerm?: string
  sortDirection?: SortDirection
  sortField?: SortField | null
  startDatetime?: string
}): Promise<PaginatedCampaigns> {
  const requestRoute = route(
    ENDPOINTS.CAMPAIGNS.LIST,
    {
      clientId,
    },
    {
      page_number: pageParam,
      page_size: pageSize,
      show_pending: true,
      start_datetime: startDatetime,
      end_datetime: endDatetime,
      communication_channels: communicationChannels.join(','),
      ...(searchTerm && { 'search[text]': searchTerm }),
      ...(sortDirection && { direction: sortDirection }),
      ...(sortField && { sort_by: sortField }),
      // we do not want to pass these params along when they are true
      ...(filters?.birthday === false && { birthday: false }),
      ...(filters?.test === false && { test_campaign: false }),
      ...(filters?.api === false && { api_campaign: false }),
    },
  )

  return request(requestRoute, { method: HTTP.GET })
}

export function deleteCampaign({
  clientId,
  campaignId,
}: {
  clientId: string
  campaignId: string
}): Promise<CampaignDetails> {
  const requestRoute = route(ENDPOINTS.CAMPAIGN.DETAILS, {
    clientId,
    campaignId,
  })

  return request(requestRoute, { method: HTTP.DELETE })
}

export type CampaignResponsesProps = {
  clientId: string
  campaignId: string
  pageSize?: number
  endDate?: string
  hasClusters?: boolean
  tagsAll?: ResponseTags[]
  tagsNot?: ResponseTags[]
}

export function getCampaignResponses({
  clientId,
  campaignId,
  pageSize = 100,
  endDate = new Date().toISOString(),
  hasClusters,
  tagsAll = [],
  tagsNot = [],
}: any): Promise<CampaignResponses> {
  const clusters = { has_cluster: hasClusters }
  const tagsAllObj = tagsAll.length ? { 'tags[all]': tagsAll.join(',') } : {}

  const defaultTagsNot = ['auto-response']

  const tagsNotObj = tagsNot.length
    ? { 'tags[not]': [...defaultTagsNot, ...tagsNot].join(',') }
    : { 'tags[not]': defaultTagsNot.join(',') }

  return request(
    route(
      ENDPOINTS.CAMPAIGN.RESPONSES,
      { clientId, campaignId },
      { end_date: endDate, page_size: pageSize, ...clusters, ...tagsAllObj, ...tagsNotObj },
    ),
    { method: HTTP.GET },
  )
}
