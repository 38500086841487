import {
  SelectorOperators,
  MemberDataFilter,
  RelationshipOperators,
  isRelationshipFilter,
  isSelectorFilter,
  FilterOperator,
  FieldDefinition,
  isSelectorOperator,
  SelectorFilter,
  isRelationshipOperator,
  RelationshipFilter,
  SUBSCRIPTION_DATA_FILTER_DATE_FORMAT,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { Select, SPACING } from '@community_dev/pixels'
import { useMemo } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { StyledCompoundField, StyledBetweenContainer } from '../FieldPane.style'

import {
  TypeOperators,
  generateOptions,
  hasChangedToBetween,
  convertToBetweenValue,
  hasChangedFromBetween,
  convertFromBetweenValue,
  getOperandValue,
  getOperandBetweenValue,
  validateDate,
} from './utils'

import dayjs from 'utils/dayjs'

const StyledBetweenDate = styled.div`
  padding: ${SPACING[1]};
`

const StyledDateInput = styled.input`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 10px 8px 10px;
  outline: none;
  line-height: normal;
  color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT};
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  border-radius: 4px;
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};

  &::placeholder {
    color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT_PLACEHOLDER};
  }
`

export const dateOperators: TypeOperators = {
  [SelectorOperators.GREATER_THAN_OR_EQUALS]: 'greaterThan',
  [SelectorOperators.LESS_THAN_OR_EQUALS]: 'lessThan',
  [SelectorOperators.EQUALS]: 'equals',
  [RelationshipOperators.AND]: 'and',
}

type DateFieldProps = {
  field: FieldDefinition
}

export function DateField({ field }: DateFieldProps): JSX.Element {
  const { t } = useTranslation(undefined, { keyPrefix: 'customData' })

  const options = useMemo(() => {
    return generateOptions({ t, typeOperators: dateOperators, type: field.value_type })
  }, [t, field.value_type])

  return (
    <Field name={`custom-field-${field.key}`} validate={validateDate}>
      {({ input }) => {
        const handleSelectChange = (option: { value: FilterOperator; label: string } | null): void => {
          if (option) {
            const { value: operator } = option
            if (hasChangedToBetween({ nextOperator: operator, previousValue: input?.value })) {
              input.onChange(convertToBetweenValue({ field, previousValue: input?.value }))
              return
            }

            if (hasChangedFromBetween({ nextOperator: operator, previousValue: input?.value })) {
              input.onChange(
                convertFromBetweenValue({
                  field,
                  previousValue: input?.value,
                  operator: operator as SelectorOperators,
                }),
              )
              return
            }

            input.onChange({
              ...(input?.value || {}),
              operator,
            } as MemberDataFilter)
          }
        }

        const handleSingleValueChange = (value): void => {
          input.onChange({
            operator: input.value.operator,
            operand: {
              field_key: field.key,
              field_label: field.name,
              source: field.source,
              type: field.value_type,
              value: dayjs(value).format(SUBSCRIPTION_DATA_FILTER_DATE_FORMAT).slice(0, 10) + 'T00:00:00.000Z',
            },
          } as MemberDataFilter)
        }

        const handleBetweenValueChange =
          (idx: number) =>
          (value): void => {
            if (isRelationshipFilter(input?.value)) {
              const operands = input?.value?.operands.map((filter, operandIdx) => {
                if (operandIdx === idx && isSelectorFilter(filter)) {
                  return {
                    ...filter,
                    operand: {
                      field_key: field.key,
                      field_label: field.name,
                      source: field.source,
                      type: field.value_type,
                      value: dayjs(value).format(SUBSCRIPTION_DATA_FILTER_DATE_FORMAT).slice(0, 10) + 'T00:00:00.000Z',
                    },
                  }
                }

                return filter
              })

              input.onChange({
                ...input.value,
                operands,
              } as MemberDataFilter)
            }
          }

        const selectedOperator = options.find((operator) => operator.value === input?.value?.operator)

        return (
          <div>
            <StyledCompoundField>
              <div>is</div>
              <Select
                inline
                instanceId="datetime-"
                onChange={handleSelectChange}
                options={options}
                value={selectedOperator}
                width="120px"
              />
              {isSelectorOperator(selectedOperator?.value) && (
                <StyledDateInput
                  data-testid="date-input-selector"
                  onChange={(e) => handleSingleValueChange(e.target.value)}
                  type="date"
                  value={input?.value ? getOperandValue(input?.value as SelectorFilter) : undefined}
                />
              )}
            </StyledCompoundField>
            {isRelationshipOperator(selectedOperator?.value) && (
              <StyledBetweenContainer>
                <StyledBetweenDate>
                  <StyledDateInput
                    data-testid="date-input-relationship-1"
                    onChange={(e) => handleBetweenValueChange(0)(e.target.value)}
                    type="date"
                    value={getOperandBetweenValue(0, input?.value as RelationshipFilter)}
                  />
                </StyledBetweenDate>
                {'and'}
                <StyledBetweenDate>
                  <StyledDateInput
                    data-testid="date-input-relationship-2"
                    onChange={(e) => handleBetweenValueChange(1)(e.target.value)}
                    type="date"
                    value={getOperandBetweenValue(1, input?.value as RelationshipFilter)}
                  />
                </StyledBetweenDate>
              </StyledBetweenContainer>
            )}
          </div>
        )
      }}
    </Field>
  )
}
