import {
  BUTTON_VARIANTS,
  Box,
  Button,
  CommunityFlagIcon,
  SPACING,
  SalesforceLogo,
  Typography,
} from '@community_dev/pixels'
import React, { useMemo } from 'react'
import 'styled-components'
import { useTranslation } from 'react-i18next'

import { ROUTES } from 'constants/routes'
import { useQueryParams } from 'hooks/useQueryParams'

export function SalesforceConnectScreen(): JSX.Element {
  const { t } = useTranslation()
  const params = useQueryParams()

  const redirectUrl = useMemo(() => {
    if (params.salesforce_module === 'marketing-cloud') {
      return encodeURIComponent(ROUTES.SALESFORCE.MARKETING_CLOUD_OAUTH)
    } else {
      return encodeURIComponent(ROUTES.SALESFORCE.OAUTH)
    }
  }, [params])

  return (
    <Box
      css={`
        margin-top: 0;
        padding-top: ${SPACING[9]};
        background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
        height: 100%;
      `}
    >
      <Box.Header>
        <div
          css={`
            text-align: center;
            margin-top: ${SPACING[5]};
          `}
        >
          <CommunityFlagIcon size={64} />
          <SalesforceLogo size={64} />
        </div>
      </Box.Header>
      <Box.Content>
        <Typography variant="h1">{t('salesforce.completeSetup')}</Typography>
        <p>{t('salesforce.connectYourAccount')}</p>
        <p>
          <Button
            forwardedAs="a"
            href={`${ROUTES.CLIENT_SELECTION}?next=${redirectUrl}`}
            target="_top"
            variant={BUTTON_VARIANTS.ACTION}
          >
            {t('salesforce.action.connectLive')}
          </Button>
        </p>

        <p>
          <Button
            forwardedAs="a"
            href={`${ROUTES.CLIENT_SELECTION}?next=${redirectUrl}${encodeURIComponent('?sandbox=true')}`}
            target="_top"
            variant={BUTTON_VARIANTS.LINK}
          >
            {t('salesforce.action.connectSandbox')}
          </Button>
        </p>
      </Box.Content>
    </Box>
  )
}
