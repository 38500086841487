import { uploadConfig } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import compact from 'lodash/compact'
import omit from 'lodash/omit'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Accept } from 'react-dropzone'

import { useClientSettings } from 'hooks/useClientSettings/useClientSettings'

export enum FileContext {
  COMPOSE = 'compose',
  DM = 'dm',
}

export enum MaxSize {
  // 50 MB, this should be the same as the max size for the largest value in this enum
  MAX = 50000000,
  // 10 MB
  SMS_GIF = 10000000,
  // 600 kB
  MMS = 600000,
  // 5 MB
  WHATS_APP_IMAGE = 5000000,
  // 16 MB
  WHATS_APP_AUDIO_VIDEO = 16000000,
}

type BaseMediaConfig = {
  accept: Accept
  maxSize: MaxSize | { [key: string]: MaxSize | undefined }
  unsupportedFileTypes: string[]
}

type MediaConfig = BaseMediaConfig & {
  mediaDisposition: MediaDisposition
  resetMediaDisposition: () => void
  setMediaDisposition: Dispatch<SetStateAction<MediaDisposition>>
}

type useMediaConfigProps = {
  communicationChannel: CommunicationChannel
  context?: FileContext
  initialMediaDisposition?: MediaDisposition
}

type GetMediaConfigProps = {
  communicationChannel: CommunicationChannel
  mediaDisposition: MediaDisposition
  context?: FileContext
  flags?: Record<string, boolean>
}

export function getMediaConfig({ communicationChannel, mediaDisposition }: GetMediaConfigProps): BaseMediaConfig {
  let accept = uploadConfig.acceptedFileTypes
  let maxSize: MaxSize | { [key: string]: MaxSize | undefined } = { 'image/gif': MaxSize.SMS_GIF, '*': MaxSize.MAX }
  let unsupportedFileTypes: string[] = []

  if (mediaDisposition === MediaDisposition.ATTACHMENT && communicationChannel === CommunicationChannel.SMS) {
    // video is not supported in MMS but is supported by SMS, so we use unsupportedFileTypes rather than updating the accept
    unsupportedFileTypes = ['video/*']
    maxSize = MaxSize.MMS
  } else if (communicationChannel === CommunicationChannel.WHATS_APP) {
    // gif files are not supported by WhatsApp so we omit them from accept
    accept = omit(uploadConfig.acceptedFileTypes, compact(['image/gif']))
    maxSize = {
      'image/*': MaxSize.WHATS_APP_IMAGE,
      'video/*': MaxSize.WHATS_APP_AUDIO_VIDEO,
      'audio/*': MaxSize.WHATS_APP_AUDIO_VIDEO,
    }
  }

  return {
    accept,
    maxSize,
    unsupportedFileTypes,
  }
}

export function useMediaConfig({
  communicationChannel,
  context,
  initialMediaDisposition,
}: useMediaConfigProps): MediaConfig {
  const [mediaDisposition, _setMediaDisposition] = useState<MediaDisposition>(
    communicationChannel === CommunicationChannel.WHATS_APP
      ? MediaDisposition.ATTACHMENT
      : initialMediaDisposition || MediaDisposition.LINK,
  )
  // WhatsApp doesn't support LINK, so it can only be set to ATTACHMENT
  const setMediaDisposition = useCallback(
    (disposition: SetStateAction<MediaDisposition>) => {
      return _setMediaDisposition(
        communicationChannel === CommunicationChannel.WHATS_APP ? MediaDisposition.ATTACHMENT : disposition,
      )
    },
    [communicationChannel],
  )
  const { data: clientSettings } = useClientSettings()

  useEffect(() => {
    if (clientSettings?.defaultMediaDisposition) {
      setMediaDisposition(clientSettings.defaultMediaDisposition)
    }
  }, [clientSettings?.defaultMediaDisposition])

  const { accept, maxSize, unsupportedFileTypes } = getMediaConfig({
    communicationChannel,
    mediaDisposition,
    context,
  })
  const resetMediaDisposition = useCallback(
    () =>
      setMediaDisposition(
        initialMediaDisposition ||
          (clientSettings?.defaultMediaDisposition as MediaDisposition) ||
          MediaDisposition.LINK,
      ),
    [clientSettings?.defaultMediaDisposition, initialMediaDisposition, setMediaDisposition],
  )

  useEffect(() => {
    if (communicationChannel === CommunicationChannel.WHATS_APP) {
      _setMediaDisposition(MediaDisposition.ATTACHMENT)
    }
  }, [communicationChannel])

  return {
    accept,
    maxSize,
    unsupportedFileTypes,
    mediaDisposition,
    resetMediaDisposition,
    setMediaDisposition,
  }
}
