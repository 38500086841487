import React from 'react'
import Moment from 'react-moment/src/index'

export const calendarOpts = {
  nextDay: '[Tomorrow] [|]h:mm A',
  nextWeek: 'dddd [|]h:mm A',
  lastDay: '[Yesterday] [|]h:mm A',
  lastWeek: 'dddd [|]h:mm A',
  sameDay: '[Today] [|]h:mm A',
  sameElse: 'ddd, MMM D, YYYY, [|]h:mm A',
  sameYear: 'ddd, MMM D, [|]h:mm A',
}

const momentFilter = (d) => {
  const parts = d.split('|')
  return (
    <>
      <span className="ts-day">{parts[0]}</span>
      <span className="ts-time">{parts[1]}</span>
    </>
  )
}

type FullTimeProps = {
  element?: string
  ts?: string
}

class FullTime extends React.PureComponent<FullTimeProps> {
  static defaultProps = {
    element: 'div',
  }

  render(): JSX.Element | null {
    const { ts, ...rest } = this.props
    if (!ts) return null

    return (
      <Moment calendar={calendarOpts} data-testid="time" filter={momentFilter} {...rest}>
        {ts}
      </Moment>
    )
  }
}

export default FullTime
