import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { usePatchWorkflow } from './usePatchWorkflow'

import { Workflow } from 'api/workflows'
import { DATE_INPUT_FORMAT, TIME_INPUT_FORMAT } from 'constants/date'
import { useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import dayjs from 'utils/dayjs'

type UseScheduleWorkflowProps = {
  workflow: Workflow
  onSuccess?: () => void
  onError?: () => void
}

type UseScheduleWorkflow = {
  onPause: () => void
  onResume: () => void
  onSchedulePause: (active_end_at: string) => void
  onCancelSchedule: () => void
}

export const useScheduleWorkflow = ({
  workflow,
  onSuccess,
  onError,
}: UseScheduleWorkflowProps): UseScheduleWorkflow => {
  const clientId = useClientId()
  const { showToastMessage } = useToastMessage()
  const { t } = useTranslation()

  const { mutate: patchWorkflowMutation } = usePatchWorkflow({ onSuccess, onError })

  const onPause = useCallback(() => {
    patchWorkflowMutation(
      {
        clientId,
        workflowId: workflow.id,
        body: {
          active_end_at: dayjs().toISOString(),
        },
      },
      {
        onSuccess: () =>
          showToastMessage({
            message: t('automations.scheduled.pauseSuccess', { name: workflow.name }),
            position: 'bottom-right',
          }),
        onError: () =>
          showToastMessage({
            message: t('automations.scheduled.pauseError', { name: workflow.name }),
            success: false,
            position: 'bottom-right',
          }),
      },
    )
  }, [clientId, patchWorkflowMutation, showToastMessage, t, workflow.id, workflow.name])

  const onResume = useCallback(() => {
    patchWorkflowMutation(
      {
        clientId,
        workflowId: workflow.id,
        body: {
          active_end_at: null,
        },
      },
      {
        onSuccess: () =>
          showToastMessage({
            message: t('automations.scheduled.resumeSuccess', { name: workflow.name }),
            position: 'bottom-right',
          }),
        onError: () =>
          showToastMessage({
            message: t('automations.scheduled.resumeError', { name: workflow.name }),
            success: false,
            position: 'bottom-right',
          }),
      },
    )
  }, [clientId, patchWorkflowMutation, showToastMessage, t, workflow.id, workflow.name])

  const onSchedulePause = useCallback(
    (active_end_at: string) => {
      const dateTime = dayjs(active_end_at)
      patchWorkflowMutation(
        {
          clientId,
          workflowId: workflow.id,
          body: {
            active_end_at,
          },
        },
        {
          onSuccess: () =>
            showToastMessage({
              message: t('automations.scheduled.scheduledPausedSuccess', {
                name: workflow.name,
                date: dateTime.format(DATE_INPUT_FORMAT),
                time: dateTime.format(TIME_INPUT_FORMAT),
              }),
              position: 'bottom-right',
            }),
          onError: () =>
            showToastMessage({
              message: t('automations.scheduled.pauseError', { name: workflow.name }),
              success: false,
              position: 'bottom-right',
            }),
        },
      )
    },
    [clientId, patchWorkflowMutation, showToastMessage, t, workflow.id, workflow.name],
  )

  const onCancelSchedule = useCallback(() => {
    patchWorkflowMutation(
      {
        clientId,
        workflowId: workflow.id,
        body: {
          active_end_at: null,
        },
      },
      {
        onSuccess: () =>
          showToastMessage({
            message: t('automations.scheduled.cancelScheduleSuccess', { name: workflow.name }),
            position: 'bottom-right',
          }),
        onError: () =>
          showToastMessage({
            message: t('automations.scheduled.cancelScheduleError', { name: workflow.name }),
            success: false,
            position: 'bottom-right',
          }),
      },
    )
  }, [])

  return { onPause, onResume, onSchedulePause, onCancelSchedule }
}
