import { FieldTypes, SubscriberField } from '@community_dev/filter-dsl/lib/subscription-data'
import { DefinitionList, SPACING, Tooltip, Typography } from '@community_dev/pixels'
import { capitalCase } from 'capital-case'
import { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import dayjs from 'utils/dayjs'

const StyledDefinitionList = styled(DefinitionList)`
  margin-bottom: 0;
`

const StyledListDefitionItem = styled(DefinitionList.Item)<{ $tooltipOpen: boolean }>`
  z-index: 1;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    width: calc(100% + ${SPACING[4]});
    left: -${SPACING[4]};
    height: calc(100% - 1px);
    transition: background 0.2s;
    z-index: -1;
  }

  ${(props) =>
    props.$tooltipOpen &&
    css`
      &:before {
        background: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_1};
      }
    `}
`

export type CustomMemberDataDefinitionListProps = {
  fields?: SubscriberField[]
  maxRows?: number
}

export function formatFieldValue(field: SubscriberField): string {
  if (typeof field.value === 'undefined' || field.value === null) {
    return ''
  }

  switch (field.value_type) {
    case FieldTypes.BOOLEAN:
      return capitalCase(field.value)
    case FieldTypes.DATE:
      return dayjs(field.value).format('MMM DD, YYYY')
    case FieldTypes.DATETIME:
      return dayjs(field.value).format('MMM DD, YYYY hh:mm a')
    case FieldTypes.DECIMAL_2:
      return Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(Number(field.value))
    case FieldTypes.DECIMAL_4:
      return Intl.NumberFormat('en-US', { maximumFractionDigits: 4 }).format(Number(field.value))
    case FieldTypes.INTEGER:
      return Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Number(field.value))
    case FieldTypes.LOCATION:
    case FieldTypes.STRING:
    default:
      return field.value
  }
}

export function CustomMemberDataDefinitionList(props: CustomMemberDataDefinitionListProps): JSX.Element {
  const { fields = [], maxRows } = props

  if (fields.length === 0) {
    return (
      <Typography component="p" variant="caption1">
        No custom data
      </Typography>
    )
  }

  return (
    <StyledDefinitionList>
      {fields.slice(0, maxRows).map((field, i) => {
        return <CustomMemberDataDefitionListItem field={field} key={i} />
      })}
    </StyledDefinitionList>
  )
}

export type CustomMemberDataDefitionListItemProps = {
  field: SubscriberField
}

function CustomMemberDataDefitionListItem(props: CustomMemberDataDefitionListItemProps): JSX.Element {
  const { field } = props

  const formattedValue = formatFieldValue(field)

  const [tooltipOpen, setTooltipOpen] = useState(false)

  // detect if content is clamped due to max rows
  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const fieldRequiresTooltip = useMemo(() => {
    if (!ref) {
      return false
    }

    const ellipsisWrapper1 = ref.querySelector<HTMLDivElement>('dt div')
    const ellipsisWrapper2 = ref.querySelector<HTMLDivElement>('dd div')

    if (ellipsisWrapper1 && ellipsisWrapper2) {
      return (
        ellipsisWrapper1.offsetHeight < ellipsisWrapper1.scrollHeight ||
        ellipsisWrapper2.offsetHeight < ellipsisWrapper2.scrollHeight
      )
    }

    return false
  }, [ref])

  const tooltipContent = useMemo(() => {
    // only render tooltip if content is clamped
    if (fieldRequiresTooltip) {
      return (
        <div>
          <Typography color="#fff" component="div" fontSize="14px" fontWeight="500" lineHeight="20px">
            {field.name}
          </Typography>
          <Typography color="#fff" component="div" fontSize="12px" lineHeight="18px">
            {formattedValue}
          </Typography>
        </div>
      )
    }

    return undefined
  }, [field, formattedValue, fieldRequiresTooltip])

  return (
    <Tooltip content={tooltipContent} offset={[0, -7]} triggerTarget={ref} visible={tooltipOpen}>
      <div style={{ width: '100%' }}>
        <StyledListDefitionItem
          $tooltipOpen={fieldRequiresTooltip && tooltipOpen}
          label={field.name}
          maxLines={2}
          onClick={() => setTooltipOpen(!tooltipOpen)}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
          ref={(ref) => setRef(ref)}
        >
          {formattedValue}
        </StyledListDefitionItem>
      </div>
    </Tooltip>
  )
}
