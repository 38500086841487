import { Filter as FilterComponent, Pill, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import compact from 'lodash/compact'
import indexBy from 'lodash/fp/indexBy'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import uniqBy from 'lodash/uniqBy'
import { useCallback, useMemo } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { FilterOption } from './FilterOption'
import { FilterPane } from './FilterPane'

import { Community } from 'api/community'
import { FILTER_STATE } from 'constants/filter-states'
import { InboxFilter } from 'contexts/InboxProvider'

const StyledFilterSection = styled.div`
  position: relative;
`

const StyledFilterComponent = styled(FilterComponent)`
  ${Pill} {
    border: 1px solid ${({ theme }) => theme.COLORS.BORDERS};
    background-color: ${({ theme }) => theme.COLORS.INPUT_BACKGROUND};
    color: ${({ theme }) => theme.COLORS.TEXT};
  }

  &:focus {
    outline: none;

    ${Pill} {
      outline: none;
      box-shadow: ${({ theme }) => `0 0 0 2px ${theme?.COLORS?.LINKS}`};
    }
  }
`

type StyledFilterSectionHeadingProps = {
  $disabled?: boolean
}

const StyledFilter = styled.div`
  padding-left: ${SPACING[2]};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledFilterSectionHeading = styled.span<StyledFilterSectionHeadingProps>`
  display: block;
  padding: 14px 16px;
  font-weight: 800;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  width: 100%;
  color: ${(props) =>
    props.$disabled ? `${props?.theme?.COLORS?.APP_BACKGROUND_LEVEL_1}` : `${props?.theme?.COLORS?.TEXT}`};

  ${(props) =>
    props.$disabled &&
    css`
      background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
      &:hover {
        background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
      }
    `}
`

const FilterPill = () => <Pill>Filter</Pill>

type FilterProps = {
  filters: InboxFilter[]
  communities?: Community[]
  setFilter: (filters: InboxFilter[]) => void
}

type InboxFilterOption = InboxFilter & {
  /**
   * Options can be disabled individually based on selected filters
   */
  disabled?(filters: { [key: string]: InboxFilter }): boolean
  /**
   * Options in the same radioGroup are exclusive, i.e. only one of them can be
   * selected.
   */
  radioGroup?: string
  color?: string
  icon?: IconName
}

type InboxFilterSection = {
  section: string
  /**
   * Sections can be disabled based on selected filters
   */
  disabled?(filters: { [key: string]: InboxFilter }): boolean
  options: InboxFilterOption[]
}

const optionToFilter = (option: InboxFilterOption): InboxFilter => {
  return {
    field: option.field,
    key: option.key,
    label: option.label,
    value: option.value,
  }
}

export const Filter = ({ filters = [], communities = [], setFilter }: FilterProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const isFavorite = (c: Community) => c.type === 'default' && c.title.toLocaleLowerCase() === 'favorites'

  const clearFilters = () => {
    setFilter(FILTER_STATE.INITIAL)
  }

  const sections = useMemo(
    () =>
      compact<InboxFilterSection>([
        {
          section: 'Filter Messages',
          disabled: (filters: { [key: string]: InboxFilter }) => 'loving' in filters || 'questions' in filters,
          options: [
            {
              key: 'unread',
              label: 'Unread',
              field: 'search[has_new]',
              value: 'true',
            },
            {
              key: 'never-dm',
              label: 'Never Direct Messaged',
              field: 'search[has_dmed]',
              value: 'false',
              radioGroup: 'has-messaged',
            },
            {
              key: 'direct-messaged',
              label: 'Direct Messaged',
              field: 'search[has_dmed]',
              value: 'true',
              radioGroup: 'has-messaged',
            },
          ],
        },
        {
          section: 'Last Direct Message',
          disabled: (filters: { [key: string]: InboxFilter }) => 'loving' in filters || 'questions' in filters,
          options: [
            {
              key: 'inbound-message',
              label: 'Inbound',
              field: 'search[last_message_direction]',
              value: 'inbound',
              radioGroup: 'direction',
            },
            {
              key: 'outbound-message',
              label: 'Outbound',
              field: 'search[last_message_direction]',
              value: 'outbound',
              radioGroup: 'direction',
            },
          ],
        },
        {
          section: 'Communities',
          disabled: (filters: { [key: string]: InboxFilter }) => 'loving' in filters || 'questions' in filters,
          options: communities.map((c) => {
            const favoriteProps = isFavorite(c) ? { key: 'favorites', icon: 'star' as IconName } : undefined

            return {
              key: c.id,
              label: c.title,
              color: c.color,
              field: 'search[tag_ids]',
              value: c.id,
              ...favoriteProps,
            }
          }),
        },
        {
          section: 'Categories',
          disabled: (filters) =>
            !Object.values(filters).every(
              (filter) =>
                filter.key === CommunicationChannel.SMS || filter.key === 'questions' || filter.key === 'loving',
            ),
          options: [
            {
              key: 'questions',
              label: 'Questions',
              field: 'search[message_tags]',
              value: 'question',
              radioGroup: 'sentiment',
            },
            {
              key: 'loving',
              label: 'Loving',
              field: 'search[message_tags]',
              value: 'loving',
              radioGroup: 'sentiment',
            },
          ],
        },
      ]),
    [communities],
  )

  const optionsByRadioGroup = useMemo(() => {
    const options = sections.flatMap((value) => value.options)
    return groupBy(options, 'radioGroup')
  }, [sections])

  const filtersByKey = useMemo(() => indexBy('key', filters), [filters])

  const getFiltersWithSelection = useCallback(
    (filters: { [key: string]: InboxFilter }, option: InboxFilterOption) => {
      if (option.key in filters) {
        // deselect filter
        return Object.values(omit(filters, option.key))
      } else if (option.radioGroup !== undefined && optionsByRadioGroup[option.radioGroup] !== undefined) {
        // add filter, remove others in radioGroup
        const otherGroupKeys = optionsByRadioGroup[option.radioGroup].map((option) => option.key) || []
        return Object.values(omit(filters, otherGroupKeys)).concat(optionToFilter(option))
      } else {
        // select filter
        return uniqBy(Object.values(filters).concat(optionToFilter(option)), 'key')
      }
    },
    [optionsByRadioGroup],
  )

  const handleFilterSelect = useCallback(
    (
      filters: { [key: string]: InboxFilter },
      option: InboxFilterOption,
      onFilterSelect: (filters: InboxFilter[]) => void,
    ) => {
      const newFilters = getFiltersWithSelection(filters, option)
      onFilterSelect(isEmpty(newFilters) ? FILTER_STATE.INITIAL : newFilters)
    },
    [getFiltersWithSelection],
  )

  return (
    <StyledFilter data-testid="inbox-filter">
      <StyledFilterComponent
        align="right"
        aria-label="Inbox-filters"
        icon={false}
        label={<FilterPill />}
        maxHeight="calc(100vh - 180px)"
        offsetTop={4}
      >
        <FilterPane onClearFilters={clearFilters}>
          {sections.map((section) => {
            const isSectionDisabled = section.disabled?.(filtersByKey)
            return (
              <StyledFilterSection key={section.section}>
                <StyledFilterSectionHeading>{section.section}</StyledFilterSectionHeading>
                {section.options.map((option) => (
                  <FilterComponent.Option
                    cypress={`${option.key}${option.key in filtersByKey ? '-selected' : ''}`}
                    disabled={isSectionDisabled || option.disabled?.(filtersByKey)}
                    key={option.key}
                    multiSelect
                    onClick={() => handleFilterSelect(filtersByKey, option, setFilter)}
                    selected={option.key in filtersByKey}
                  >
                    <FilterOption
                      {...option}
                      color={
                        (option.disabled?.(filtersByKey) || isSectionDisabled) && option.color
                          ? COLORS.BUTTON_DISABLED
                          : option.color
                      }
                    />
                  </FilterComponent.Option>
                ))}
              </StyledFilterSection>
            )
          })}
        </FilterPane>
      </StyledFilterComponent>
    </StyledFilter>
  )
}
