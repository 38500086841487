import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const StyledHeading = styled.h1`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  margin: 0;
`

export const StyledSubheading = styled.h2`
  margin: 0;
  text-align: center;
  max-width: 300px;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`
