import {
  BUTTON_VARIANTS,
  Button,
  SPACING,
  Layout,
  Typography,
  LoadingIndicator,
  ChevronLeftIcon,
  FONT_SIZE,
} from '@community_dev/pixels'
import { Trans, useTranslation } from 'react-i18next'
import { Link, Redirect, Route, Switch } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { CONTACT_US_EMAIL } from './constants'
import InactiveAccountIcon from './svg/InactiveAccountIcon.svg?react'

import { ROUTES } from 'constants/routes'
import { InvoicesTable } from 'containers/Settings/Billing/EnterpriseBilling/EnterpriseBillingSummary/InvoicesDetails/InvoicesTable'
import { useAuth } from 'hooks/useAuth'
import { useInvoices } from 'hooks/useInvoices'
import SimpleLayout from 'layouts/SimpleLayout'

const StyledContactUsButton = styled(Button).attrs({
  forwardedAs: 'a',
  variant: BUTTON_VARIANTS.LINK,
  href: CONTACT_US_EMAIL,
})`
  margin-top: ${SPACING[6]};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledLinkToPastDueInvoicesButton = styled(Link)`
  width: 100%;
  max-width: 312px;
`
const StyleLogout = styled(Button).attrs({
  variant: BUTTON_VARIANTS.LINK,
})`
  margin-top: ${SPACING[6]};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const usePastDueInvoices = () => {
  return useInvoices({
    select: (data) => {
      return data.invoices.filter((invoice) => invoice.isPastDue)
    },
  })
}
const PastDueInvoices = (): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const { data: pastDueInvoices, isInitialLoading } = usePastDueInvoices()
  return (
    <Layout alignSelf="stretch" background={COLORS?.APP_BACKGROUND_LEVEL_3} flex={1} justifyContent="center">
      <Layout display="flex" justifyContent="center">
        <Layout display="flex" marginTop={SPACING[10]}>
          <span>
            <Link aria-label="back" to={ROUTES.ONBOARDING.CONTACT_US.ROOT}>
              <ChevronLeftIcon />
            </Link>
          </span>
          <Layout marginLeft={SPACING[5]} width="600px">
            <Typography fontSize={FONT_SIZE[6]} marginBottom={SPACING[5]} marginTop={0} variant="h3">
              {t('contactUs.pastDueInvoices')}
            </Typography>
            <InvoicesTable invoicesData={pastDueInvoices} isLoading={isInitialLoading} />
            <Typography fontSize={FONT_SIZE[3]}>
              <Trans i18nKey="contactUs.customerSupportNotified" />
            </Typography>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  )
}

const ContactUs = (): JSX.Element => {
  const { t } = useTranslation()
  const { COLORS } = useTheme() || {}
  const { logout } = useAuth()

  const { data: pastDueInvoices } = usePastDueInvoices()

  return (
    <Layout alignSelf="stretch" background={COLORS?.APP_BACKGROUND_LEVEL_3} flex={1} justifyContent="center">
      <Layout
        alignItems="center"
        alignSelf="center"
        display="flex"
        flexDirection="column"
        marginTop={SPACING[10]}
        padding={`0 ${SPACING[5]}`}
        textAlign="center"
      >
        <InactiveAccountIcon />
        <Typography marginBottom="0" marginTop={SPACING[7]} variant="h1">
          {t('contactUs.inactive')}
        </Typography>
        <Typography marginBottom={SPACING[7]} marginTop={SPACING[4]} variant="body1">
          {t('contactUs.contactSupport')}
        </Typography>
        {pastDueInvoices && pastDueInvoices.length > 0 ? (
          <>
            <StyledLinkToPastDueInvoicesButton to={ROUTES.ONBOARDING.CONTACT_US.PAST_DUE_INVOICES}>
              <Button variant={BUTTON_VARIANTS.ACTION}>{t('contactUs.submitPayment')}</Button>
            </StyledLinkToPastDueInvoicesButton>
            <StyledContactUsButton>{t('contactUs.contactUsButton')}</StyledContactUsButton>
          </>
        ) : (
          <>
            <Button forwardedAs="a" href={CONTACT_US_EMAIL} variant={BUTTON_VARIANTS.ACTION}>
              {t('contactUs.contactUsButton')}
            </Button>
            <StyleLogout onClick={logout}>{t('logout')}</StyleLogout>
          </>
        )}
      </Layout>
    </Layout>
  )
}
export const ContactUsScreen = (): JSX.Element => {
  const { data: pastDueInvoices, isInitialLoading } = usePastDueInvoices()

  if (isInitialLoading) return <LoadingIndicator />
  return (
    <SimpleLayout showContextSwitcher>
      <Switch>
        <Route component={ContactUs} exact path={ROUTES.ONBOARDING.CONTACT_US.ROOT} />
        {pastDueInvoices && pastDueInvoices.length > 0 && (
          <Route component={PastDueInvoices} path={ROUTES.ONBOARDING.CONTACT_US.PAST_DUE_INVOICES} />
        )}
        <Redirect to={ROUTES.ONBOARDING.CONTACT_US.ROOT} />
      </Switch>
    </SimpleLayout>
  )
}
