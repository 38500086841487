import { FieldDefinition, Placeholder, SubscriberField } from '@community_dev/filter-dsl/lib/subscription-data'
import { HTTP } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type Fields = FieldDefinition[]

type GetCustomMemberDataForSubscriberArgs = {
  clientId: string
  fanSubscriptionId: string
}

export type CmdForSubscriber = {
  fields: SubscriberField[]
}

export const getCustomMemberDataForSubscriber = async ({
  clientId,
  fanSubscriptionId,
}: GetCustomMemberDataForSubscriberArgs): Promise<CmdForSubscriber> => {
  const response = await request(route(ENDPOINTS.SUBSCRIPTION_DATA.FOR_SUBSCRIBER, { clientId, fanSubscriptionId }), {
    method: HTTP.GET,
  })

  return response.data
}

type GetCustomDataFieldsArgs = {
  clientId: string
}

export const getCustomMemberDataFields = async ({ clientId }: GetCustomDataFieldsArgs): Promise<Fields> => {
  return request(route(ENDPOINTS.SUBSCRIPTION_DATA.FIELDS, { clientId }), {
    method: HTTP.GET,
    camelCase: false,
  }).then(({ data }) => data)
}

type GetPlaceholdersArgs = {
  clientId: string
}

export const getPlaceholders = async ({ clientId }: GetPlaceholdersArgs): Promise<Placeholder[]> => {
  const response = await request(route(ENDPOINTS.SUBSCRIPTION_DATA.PLACEHOLDERS, { clientId }), { method: HTTP.GET })

  return response.data
}
