import { BORDER_RADIUS, Info, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

export const ResponsiveWrapper = styled.div`
  width: 100%;
  margin-right: auto;
  position: relative;
`
export const StyledIcon = styled.button`
  background: transparent;
  border: none;
  height: 100%;
  cursor: pointer;
  border-radius: ${BORDER_RADIUS[1]};
  &:hover {
    background: ${({ theme }) => theme?.COLORS.APP_BACKGROUND_LEVEL_2};
  }
`

export const StyledInfo = styled(Info)`
  margin-top: ${SPACING[4]};
  margin-bottom: 0;
`
