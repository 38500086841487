import {
  BuiltInFields,
  FieldSources,
  FieldTypes,
  RelationshipOperators,
  SelectorOperators,
  fieldLabelFor,
  findOne,
  parseFilters,
  CampaignRespondersMatch,
  LinkHitIncludesMatch,
  LinkHitExcludesMatch,
  UnresponsiveMembersMatch,
  CampaignRecipientsMatch,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { BORDER_RADIUS, Layout, SPACING, Skeleton, Typography } from '@community_dev/pixels'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { StyledButton, StyledIcon } from './CampaignFollowUp.style'
import { LinkFollowUpButton } from './LinkFollowUpButton'
import IconAll from './svg/icon-all.svg?react'
import IconNoResponse from './svg/icon-no-response.svg?react'
import IconResponse from './svg/icon-response.svg?react'
import IllustrationMessage from './svg/illustration-message.svg?react'

import { useActionPanel } from 'components/ActionPanel/ActionPanelContext'
import { CAPABILITIES } from 'constants/capabilities'
import { useCampaignResponseFilterState } from 'contexts/CampaignResponseFilterProvider/CampaignResponseFilterProvider'
import { useCampaignDetails } from 'hooks/useCampaign'
import { useClient } from 'hooks/useClient'
import { useHasCapability, useHasSomeCapabilities } from 'hooks/useUserCapability'
import analytics from 'utils/analytics'
import dayjs from 'utils/dayjs'

type CampaignFollowUpProps = {
  campaignId: string
}

export const CampaignFollowUp = ({ campaignId }: CampaignFollowUpProps): JSX.Element | null => {
  const { openActionPanel } = useActionPanel()
  const { t } = useTranslation()
  const { COLORS } = useTheme()
  const { data: client } = useClient()

  // We disable the ability to open compose if the client has no communication channels
  const isOpenComposeDisabled = !client?.communicationChannels?.length

  const isCampaignFollowUpEnabled = useHasSomeCapabilities(
    CAPABILITIES.ADMIN.ALL.VIEW,
    CAPABILITIES.CLIENT.MESSAGES.WRITE,
  )

  const hasLinkClickFilteringCapability = useHasCapability(CAPABILITIES.FEATURE.LINK_CLICK_FILTERING.ALL)

  const campaignRecipientsFilter = {
    operand: {
      type: FieldTypes.UUID,
      value: campaignId,
      source: FieldSources.BUILT_IN,
      field_key: BuiltInFields.CAMPAIGN_RECIPIENTS,
      field_label: fieldLabelFor(BuiltInFields.CAMPAIGN_RECIPIENTS),
    },
    operator: SelectorOperators.QUERY,
  }

  const { data, isInitialLoading: isCampaignDetailsLoading } = useCampaignDetails({
    campaignId,
  })
  const hasClickedLink = useMemo(
    () => !!(data?.linkInfo || []).find((info) => info?.linkClickInfo?.linkClickRate > 0),
    [data?.linkInfo],
  )
  const hasUnclickedLink = useMemo(
    () => !!(data?.linkInfo || []).find((info) => info?.linkClickInfo?.linkClickRate < 100),
    [data?.linkInfo],
  )
  const linkUrls = useMemo(
    () => (data?.linkInfo || [])?.map((info) => info?.url).filter((x) => x) || [],
    [data?.linkInfo],
  )

  const { campaignFollowUpFilter, setCampaignFollowUpFilter } = useCampaignResponseFilterState()
  const isSelectedFilter = useMemo(() => {
    const selectedFilterAst = parseFilters(campaignFollowUpFilter)
    const noResponseFollowUp = Boolean(findOne(selectedFilterAst, UnresponsiveMembersMatch))
    const responseFollowUp = Boolean(findOne(selectedFilterAst, CampaignRespondersMatch))
    const unClickedLinkFollowUp = Boolean(findOne(selectedFilterAst, LinkHitExcludesMatch))

    // We can figure out a better way to detect which filters are selected if this gets more complicated
    // Based on how the matcher works, the LinkHitExcludeMatch will match for LinkFollowUp as well
    const linkFollowUp = Boolean(findOne(selectedFilterAst, LinkHitIncludesMatch)) && !unClickedLinkFollowUp

    // allFollowUp also matches for unClickedLinkFollowUp
    // This is because the whole filter = campaign recipients and NOT Link Hit
    const allFollowUp = Boolean(findOne(selectedFilterAst, CampaignRecipientsMatch)) && !unClickedLinkFollowUp

    return {
      allFollowUp,
      noResponseFollowUp,
      responseFollowUp,
      linkFollowUp,
      unClickedLinkFollowUp,
    }
  }, [campaignFollowUpFilter])

  if (isCampaignDetailsLoading) {
    return <Skeleton height="157px" loading={isCampaignDetailsLoading} margin="16px 16px 0" width="calc(100% - 32px)" />
  }

  if (!data || !data?.responseMetadata?.responseDataViewable || data?.nestLevel > 2 || !isCampaignFollowUpEnabled) {
    return null
  }

  const { createdAt, linkInfo, parentSmsCampaignId, responseInfo, responseMetadata, placeholders } = data
  const now = dayjs()
  const isCampaignOlderThanTwelveMonths = now.diff(dayjs(createdAt), 'month') > 12

  if (isCampaignOlderThanTwelveMonths) {
    return null
  }

  const { responseRate = 0 } = responseInfo || { responseRate: 0 }
  const supportsFollowUps = responseMetadata?.supportsFollowUps
  const shouldDisplayNoResponseButton = supportsFollowUps && responseRate !== 100
  const shouldDisplayResponseButton = supportsFollowUps && responseRate > 0
  const shouldDisplayLinkButtons = !!linkInfo?.length && supportsFollowUps && hasLinkClickFilteringCapability

  const handleToAllFollowUp = () => {
    if (isSelectedFilter.allFollowUp) {
      setCampaignFollowUpFilter(null)
      return
    }
    openActionPanel()
    setCampaignFollowUpFilter(campaignRecipientsFilter)
    analytics.track(
      analytics.events.FollowUp.ToAll({
        parentSmsCampaignId: parentSmsCampaignId || campaignId,
      }),
    )
  }

  const handleNoResponseFollowUp = () => {
    if (isSelectedFilter.noResponseFollowUp) {
      setCampaignFollowUpFilter(null)
      return
    }
    openActionPanel()
    setCampaignFollowUpFilter({
      operand: {
        type: FieldTypes.UUID,
        value: campaignId,
        source: FieldSources.BUILT_IN,
        field_key: BuiltInFields.UNRESPONSIVE_CAMPAIGN_RECIPIENTS,
        field_label: fieldLabelFor(BuiltInFields.UNRESPONSIVE_CAMPAIGN_RECIPIENTS),
      },
      operator: SelectorOperators.QUERY,
    })

    analytics.track(
      analytics.events.FollowUp.MembersThatDidNotRespond({
        parentSmsCampaignId: parentSmsCampaignId || campaignId,
      }),
    )
  }

  const handleResponseFollowUp = () => {
    if (isSelectedFilter.responseFollowUp) {
      setCampaignFollowUpFilter(null)
      return
    }
    openActionPanel()
    setCampaignFollowUpFilter({
      operand: {
        type: FieldTypes.UUID,
        value: campaignId,
        source: FieldSources.BUILT_IN,
        field_key: BuiltInFields.CAMPAIGN_RESPONDERS,
        field_label: fieldLabelFor(BuiltInFields.CAMPAIGN_RESPONDERS),
      },
      operator: SelectorOperators.QUERY,
    })

    analytics.track(
      analytics.events.FollowUp.MembersThatResponded({
        parentSmsCampaignId: parentSmsCampaignId || campaignId,
      }),
    )
  }

  const handleLinkFollowUp = (linkUrls) => {
    if (isSelectedFilter.linkFollowUp) {
      setCampaignFollowUpFilter(null)
      return
    }
    openActionPanel()
    setCampaignFollowUpFilter({
      operands: linkUrls.map((url) => ({
        operand: {
          type: FieldTypes.CAMPAIGN_LINK_HIT,
          value: {
            campaign_id: campaignId,
            url,
          },
          source: FieldSources.BUILT_IN,
          field_key: BuiltInFields.LINK_HIT,
          field_label: fieldLabelFor(BuiltInFields.LINK_HIT),
        },
        operator: SelectorOperators.QUERY,
      })),
      operator: RelationshipOperators.AND,
    })

    analytics.track(
      analytics.events.FollowUp.MembersThatClicked({
        parentSmsCampaignId: parentSmsCampaignId || campaignId,
      }),
    )
  }

  const handleUnclickedLinkFollowUp = (linkUrls) => {
    if (isSelectedFilter.unClickedLinkFollowUp) {
      setCampaignFollowUpFilter(null)
      return
    }
    openActionPanel()
    if (linkUrls.length === 1) {
      setCampaignFollowUpFilter({
        operands: [
          {
            operand: {
              type: FieldTypes.UUID,
              value: campaignId,
              source: FieldSources.BUILT_IN,
              field_key: BuiltInFields.CAMPAIGN_RECIPIENTS,
              field_label: fieldLabelFor(BuiltInFields.CAMPAIGN_RECIPIENTS),
            },
            operator: SelectorOperators.QUERY,
          },
          {
            operands: [
              {
                operand: {
                  type: FieldTypes.CAMPAIGN_LINK_HIT,
                  value: {
                    campaign_id: campaignId,
                    url: linkUrls[0],
                  },
                  source: FieldSources.BUILT_IN,
                  field_key: BuiltInFields.LINK_HIT,
                  field_label: fieldLabelFor(BuiltInFields.LINK_HIT),
                },
                operator: SelectorOperators.QUERY,
              },
            ],
            operator: RelationshipOperators.NOT,
          },
        ],
        operator: RelationshipOperators.AND,
      })
    } else {
      setCampaignFollowUpFilter({
        operands: [
          {
            operand: {
              type: FieldTypes.UUID,
              value: campaignId,
              source: FieldSources.BUILT_IN,
              field_key: BuiltInFields.CAMPAIGN_RECIPIENTS,
              field_label: fieldLabelFor(BuiltInFields.CAMPAIGN_RECIPIENTS),
            },
            operator: SelectorOperators.QUERY,
          },
          {
            operands: [
              {
                operator: RelationshipOperators.OR,
                operands: linkUrls.map((url) => ({
                  operand: {
                    type: FieldTypes.CAMPAIGN_LINK_HIT,
                    value: {
                      campaign_id: campaignId,
                      url,
                    },
                    source: FieldSources.BUILT_IN,
                    field_key: BuiltInFields.LINK_HIT,
                    field_label: fieldLabelFor(BuiltInFields.LINK_HIT),
                  },
                  operator: SelectorOperators.QUERY,
                })),
              },
            ],
            operator: RelationshipOperators.NOT,
          },
        ],
        operator: RelationshipOperators.AND,
      })
    }
    analytics.track(
      analytics.events.FollowUp.MembersThatDidNotClick({
        parentSmsCampaignId: parentSmsCampaignId || campaignId,
      }),
    )
  }

  return (
    <Layout data-testid="follow-up" paddingLeft={SPACING[4]} paddingRight={SPACING[4]}>
      <Layout
        backgroundColor={COLORS.APP_BACKGROUND_LEVEL_2}
        borderRadius={BORDER_RADIUS[1]}
        padding={`${SPACING[5]} ${SPACING[4]} ${SPACING[3]}`}
      >
        <Layout alignItems="center" display="flex" margin-bottom={SPACING[4]}>
          <IllustrationMessage />
          <Layout margin-left={SPACING[4]}>
            <Typography fontWeight="600" margin={`0 0 ${SPACING[2]}`} variant="body1">
              {t('campaignFollowUp.heading')}
            </Typography>
            <Typography color={COLORS.SUBTEXT} margin="0" variant="body2">
              {t('campaignFollowUp.subheading')}
            </Typography>
          </Layout>
        </Layout>
        <Layout>
          <StyledButton
            $isSelected={isSelectedFilter.allFollowUp}
            disabled={isOpenComposeDisabled}
            onClick={handleToAllFollowUp}
          >
            <StyledIcon>
              <IconAll />
            </StyledIcon>
            {t('campaignFollowUp.toAll')}
          </StyledButton>
          {shouldDisplayNoResponseButton && (
            <StyledButton
              $isSelected={isSelectedFilter.noResponseFollowUp}
              disabled={isOpenComposeDisabled}
              onClick={handleNoResponseFollowUp}
            >
              <StyledIcon>
                <IconNoResponse />
              </StyledIcon>
              {t('campaignFollowUp.noResponse')}
            </StyledButton>
          )}
          {shouldDisplayResponseButton && (
            <StyledButton
              $isSelected={isSelectedFilter.responseFollowUp}
              disabled={isOpenComposeDisabled}
              onClick={handleResponseFollowUp}
            >
              <StyledIcon>
                <IconResponse />
              </StyledIcon>
              {t('campaignFollowUp.response')}
            </StyledButton>
          )}
          {shouldDisplayLinkButtons && (
            <>
              {hasClickedLink && (
                <LinkFollowUpButton
                  disabled={isOpenComposeDisabled}
                  hasClickIntent={true}
                  isSelected={isSelectedFilter.linkFollowUp}
                  linkUrls={linkUrls}
                  onClick={handleLinkFollowUp}
                  placeholders={placeholders}
                />
              )}
              {hasUnclickedLink && (
                <LinkFollowUpButton
                  disabled={isOpenComposeDisabled}
                  hasClickIntent={false}
                  isSelected={isSelectedFilter.unClickedLinkFollowUp}
                  linkUrls={linkUrls}
                  onClick={handleUnclickedLinkFollowUp}
                  placeholders={placeholders}
                />
              )}
            </>
          )}
        </Layout>
      </Layout>
    </Layout>
  )
}
