import { ErrorBoundary, Layout, Skeleton, SPACING, Tooltip } from '@community_dev/pixels'
import { ResponseTags } from '@community_dev/types/lib/api/v2/Campaign'
import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ClusterItems } from './ClusterItems'
import { ClusteringBanner } from './components/ClusteringBanner'
import { Recipients } from './components/Recipients'
import { SentActionPanel } from './components/SentActionPanel'
import EmptyResponse from './EmptyResponse'
import { Items } from './Items'
import { ResponseFilters } from './ResponseFilters'
import { StyledInfiniteScroll, StyledRoot } from './styled'
import { Thread } from './Thread'

import { getMatchingClusters } from 'api/cluster'
import { Fan } from 'api/fans'
import { useActionPanel } from 'components/ActionPanel/ActionPanelContext'
import { CampaignFollowUp } from 'components/CampaignFollowUp'
import { CampaignSummary } from 'components/CampaignSummary'
import { ChainBubble } from 'components/ChainBubble'
import { Checkbox } from 'components/IncludeExcludeCheckbox'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERY_CACHE } from 'constants/query-cache'
import { SENTIMENT_FILTERS } from 'constants/sentiment-filters'
import { TABLET_BREAK } from 'constants/theme'
import { useCampaignResponseFilterState } from 'contexts/CampaignResponseFilterProvider'
import { useIncludeExcludeContext } from 'contexts/IncludeExcludeProvider'
import { Selection } from 'contexts/IncludeExcludeProvider/IncludeExcludeState'
import { useCampaignDetails } from 'hooks/useCampaign'
import { useClientId } from 'hooks/useClient'
import { useClusteringEnabled } from 'hooks/useClusteringEnabled'
import { useResponseTagsQuery } from 'hooks/useResponseTagsQuery'
import { useHasCapability } from 'hooks/useUserCapability'
import Sentry from 'integrations/Sentry'
import dayjs from 'utils/dayjs'
import { normalizeCampaignInsights } from 'utils/normalize'

const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  user-select: none;
`

type StyledFiltersProps = {
  $hasSentimentFilter?: boolean
}
const StyledFilters = styled.div<StyledFiltersProps>`
  margin: 0 0 ${({ $hasSentimentFilter }) => ($hasSentimentFilter ? 0 : SPACING[6])};
`

const StyledHeading = styled.h3`
  margin: 0;
`

type StyledResponsesHeaderContentProps = {
  $labels?: any
}

const StyledResponsesHeaderContent = styled.div<StyledResponsesHeaderContentProps>`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;

  @media (min-width: ${TABLET_BREAK}) {
    padding: 16px 0;
  }

  ${({ $labels }) =>
    $labels &&
    css`
      padding-bottom: 0;
      @media (min-width: ${TABLET_BREAK}) {
        padding-bottom: 0;
      }
    `};

  svg {
    cursor: pointer;
  }

  svg path {
    transition: fill 0.2s ease-in;
    fill: ${({ theme }) => theme?.COLORS?.TEXT};
  }

  svg.active path {
    fill: ${({ theme }) => theme?.COLORS?.LINKS};
  }
`

const StyledResponsesHeader = styled.header`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme?.COLORS?.CHAT_BACKGROUND};
  z-index: 2;
  padding: 0 ${SPACING[4]} ${SPACING[4]};

  @media (min-width: ${TABLET_BREAK}) {
    height: auto;
  }
`

const StyledResponses = styled.div`
  padding-bottom: 24px;
`

const StyledMessageTags = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
`

type StyledLabelProps = {
  $active?: boolean
}

const StyledLabel = styled.button<StyledLabelProps>`
  background: none;
  padding: 0 0 ${SPACING[2]};
  border: none;
  cursor: pointer;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  margin-right: ${SPACING[4]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};

  &:hover {
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  }

  ${({ $active }) =>
    $active &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
      color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
    `}
`

const StyledMessage = styled.div`
  padding: 0 ${SPACING[4]};
  margin-bottom: ${SPACING[4]};
`

const useIndexResponseTagsQuery = ({ campaignId }) => {
  const { sentimentFilter, allTags, notTags } = useCampaignResponseFilterState()

  // we disable clusters if any sentiment filters are active
  const clustersEnabled = useClusteringEnabled() && !sentimentFilter

  const tagsAll = useMemo(() => Array.from(allTags), [allTags])
  const tagsNot = useMemo(() => Array.from(allTags), [notTags])

  return useResponseTagsQuery({
    campaignId,
    includeClusters: !clustersEnabled,
    tagsAll: tagsAll,
    tagsNot: tagsNot,
  })
}

export default function IndexChain(): JSX.Element {
  const { t } = useTranslation()
  const clientId = useClientId()
  const campaignSummaryCapabilityEnabled = useHasCapability(CAPABILITIES.FEATURE.AI_CAMPAIGN_SUMMARIES.ALL)

  const [selectedDetailMember, setSelectedDetailMember] = useState<Fan | undefined>()
  const { isOpen, openActionPanel } = useActionPanel()

  const { campaignId } = useParams<{ campaignId: string }>()

  const { data: campaignData, isInitialLoading: isCampaignDetailsLoading } = useCampaignDetails({
    campaignId,
  })
  const { media, recipientCount, status, threadId, createdAt, communicationChannel, responseMetadata, scheduledAt } =
    campaignData || {}

  const { supportsFollowUps } = responseMetadata || {}
  const parsedBody = useMemo(() => {
    if (!campaignData) return ''
    const { text, placeholders } = campaignData
    if (text && placeholders?.length > 0 && placeholders.some((p) => text.includes(`{${p.key}:${p.source}}`))) {
      const matchingPlaceholders = placeholders.filter((p) => text.includes(`{${p.key}:${p.source}}`))
      if (matchingPlaceholders.length > 0) {
        return matchingPlaceholders.reduce((t, ph) => t.replace(`{${ph.key}:${ph.source}}`, `{${ph.name}}`), text)
      }
      return
    }
    return text || ''
  }, [campaignData])

  const {
    sentimentFilter,
    setSentimentFilter,
    tagFilters,
    setCommunicationChannel: setFilterCommunicationChannel,
    resetState: resetFilterState,
    // jank
    allTags: sentScreenAllTags,
    notTags: sentScreenNotTags,
  } = useCampaignResponseFilterState()

  const { isRootSelected, toggleRoot } = useIncludeExcludeContext()
  const selectAllCheckboxState = isRootSelected()

  const tapbacks = useMemo(() => {
    if (!responseMetadata) return []
    return normalizeCampaignInsights({ responseMetadata }).tapbacks
  }, [responseMetadata])

  // reset the global filter state
  useEffect(() => {
    resetFilterState()
  }, [])

  // set the filter communication channel when we have the thread id
  useEffect(() => {
    if (communicationChannel && threadId) {
      setFilterCommunicationChannel(communicationChannel)
    }
  }, [communicationChannel, resetFilterState, setFilterCommunicationChannel, threadId])

  // we disable clusters if any sentiment filters are active - reflect that in the
  // query state
  const clustersEnabled = useClusteringEnabled() && !sentimentFilter && !!campaignId

  // qrohlf note: the FilterProvider previous state was represented by
  // allTags: Set([tag, tag, tag])
  // notTags: Set([tag, tag])
  //
  // This is confusing, because the redux-driven getItems/getClusterIndex APIs
  // use the comma-delimited string-based format of
  // tagsAll: 'tag,tag,tag'
  // tagsNot: 'tag,tag,tag'
  //
  // It would help the readability and maintainability of this code immensely
  // to eliminate one of those two representations of the data, or at least
  // change the naming to more clearly indicate the difference between the two.
  //
  // However, the current refactor that I'm undertaking is quite large already,
  // so I'm leaving this comment to serve as documentation. Hopefully, myself
  // or someone else will have the opportunity to improve this situation in
  // a future change set.
  const { data: clusterData } = useQuery(
    [QUERY_CACHE.CLUSTER.LIST, { clientId, campaignId, tagFilters }],
    () =>
      getMatchingClusters({
        clientId,
        campaignId,
        tagsAll: [...sentScreenAllTags].join(','),
        tagsNot: [...sentScreenNotTags].join(','),
      }),
    {
      refetchOnWindowFocus: false,
      enabled: clustersEnabled,
    },
  )

  const {
    isFetching,
    isInitialLoading: isResponseTagsLoading,
    isFetchingNextPage,
    data: campaignResponses,
    hasNextPage,
    fetchNextPage,
  } = useIndexResponseTagsQuery({ campaignId })

  const flattenedData = useMemo(() => {
    if (!campaignResponses?.pages) {
      return []
    }
    return campaignResponses?.pages
      ?.map?.((d) => d?.data)
      .flat()
      .filter((response) => {
        // Tapbacks are not supported
        if (response.tags.includes(ResponseTags.TAPBACK)) {
          return false
        }
        // it has no body/media/attachments
        if (!response.text && (!response.media || !response.media?.url)) {
          return false
        }
        return true
      })
  }, [campaignResponses])

  const applySentimentFilter = useCallback(
    (sentimentFilter) => {
      setSentimentFilter(sentimentFilter)
    },
    [setSentimentFilter],
  )

  const loadMore = useCallback(() => {
    if (!campaignResponses?.pages?.length) return
    const nextEndDate = campaignResponses?.pages?.at(-1)?.paginationData.nextEndDate

    if (hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage({ pageParam: nextEndDate })
    }
  }, [campaignResponses, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage])

  // suppress sentiment filters if viewing a conversation before we started
  // tagging messages with them
  const campaignPredatesSentimentFilters =
    flattenedData?.[0]?.createdAt && dayjs(flattenedData[0].createdAt).isBefore('2019-08-24T01:00:00Z')

  const showSentimentFilters = Boolean((sentimentFilter || flattenedData.length) && !campaignPredatesSentimentFilters)

  const noItemsToDisplay = !(clusterData?.data?.length || flattenedData.length)

  const isLoading = isCampaignDetailsLoading || isResponseTagsLoading

  const handleMemberSelect = useCallback(
    (member: Fan) => {
      if (!isOpen) openActionPanel()

      setSelectedDetailMember(member)
    },
    [openActionPanel, setSelectedDetailMember],
  )

  return (
    <StyledRoot>
      <StyledInfiniteScroll hasMore={hasNextPage} loadMore={loadMore} loading={isLoading} threshold={240}>
        <Recipients />
        <SentActionPanel
          communicationChannel={communicationChannel}
          onClickDeselect={resetFilterState}
          selectedDetailMember={selectedDetailMember}
          setSelectedDetailMember={setSelectedDetailMember}
        />

        <div data-testid="chain-responses">
          <StyledMessage>
            <StyledHeading>{t('yourMessage')}</StyledHeading>
            <Skeleton
              height="32px"
              loading={isLoading}
              marginBottom="38px"
              marginLeft="calc(50% - 54px)"
              marginRight="54px"
              marginTop="36px"
              width="50%"
            >
              {(parsedBody || (media && media.url)) && (
                <>
                  <Thread recipientCount={recipientCount} />
                  <ChainBubble
                    body={parsedBody}
                    bubbleProps={{ media, status }}
                    incoming={false}
                    showTime
                    tapbacks={tapbacks}
                    title={t('you')}
                    ts={scheduledAt || createdAt}
                  />
                </>
              )}
            </Skeleton>
          </StyledMessage>
          {campaignSummaryCapabilityEnabled && (
            <ErrorBoundary ErrorComponent={null} onCatch={(e) => Sentry.captureException(e)}>
              <CampaignSummary campaignId={campaignId} />
            </ErrorBoundary>
          )}
          <CampaignFollowUp campaignId={campaignId} />
          <StyledResponses data-testid="chain-response-header">
            <StyledResponsesHeader>
              <StyledFilters $hasSentimentFilter={Boolean(sentimentFilter)}>
                <StyledResponsesHeaderContent $labels={showSentimentFilters}>
                  <StyledHeading>{t('responses')}</StyledHeading>
                  <ResponseFilters />
                </StyledResponsesHeaderContent>
                <ClusteringBanner />
                <Skeleton height="20px" loading={isLoading} marginTop="12px" width="300px">
                  {showSentimentFilters && (
                    <SentimentFilterPicker activeFilter={sentimentFilter} setSentimentFilter={applySentimentFilter} />
                  )}
                </Skeleton>
              </StyledFilters>
              <Skeleton height="16px" loading={isLoading} marginBottom="6px" marginTop="16px" width="80px">
                {!noItemsToDisplay && !sentimentFilter && (
                  <SelectAllWrapper>
                    <Layout marginRight="16px">
                      <Tooltip content={t('clusterSelectionDisabled')} disabled={supportsFollowUps} maxWidth={345}>
                        <Layout display="flex">
                          <Checkbox
                            aria-label={t('selectAllResponses')}
                            checked={selectAllCheckboxState === Selection.SELECTED}
                            disabled={!supportsFollowUps}
                            indeterminate={selectAllCheckboxState === Selection.PARTIAL}
                            key="responses-select-all"
                            name="responses-select-all"
                            onChange={toggleRoot}
                          />
                        </Layout>
                      </Tooltip>
                    </Layout>
                    <span>{t('selectAll')}</span>
                  </SelectAllWrapper>
                )}
              </Skeleton>
            </StyledResponsesHeader>
            {!isLoading && noItemsToDisplay ? (
              <EmptyResponse />
            ) : (
              <>
                {clustersEnabled && clusterData && (
                  <ClusterItems allowSelection={supportsFollowUps} campaignId={campaignId} items={clusterData.data} />
                )}

                <Items items={flattenedData} onMemberSelect={handleMemberSelect} tapbacks={tapbacks} />
              </>
            )}
          </StyledResponses>
        </div>
      </StyledInfiniteScroll>
    </StyledRoot>
  )
}

IndexChain.propTypes = {
  msgLabels: PropTypes.array,
}

const SentimentFilterPicker = ({ activeFilter, setSentimentFilter }) => {
  return (
    <StyledMessageTags data-testid="chain-responses-msg-tags">
      {SENTIMENT_FILTERS.map(({ title, value }) => (
        <li key={value}>
          <StyledLabel $active={activeFilter === value} onClick={() => setSentimentFilter(value)}>
            {title}
          </StyledLabel>
        </li>
      ))}
    </StyledMessageTags>
  )
}
