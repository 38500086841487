import { ConversionGoalSuccessMetric } from '@community_dev/types/lib/api/v1/AiInterface'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAiMessageGenerator } from 'contexts/AIMessageGeneratorProvider'

type SuccessMetricOption = {
  label: string
  value: ConversionGoalSuccessMetric
}

export type UseSuccesMetricReturn = {
  successMetricOptions: SuccessMetricOption[]
  successMetricValue?: SuccessMetricOption
}

export function useSuccessMetric(): UseSuccesMetricReturn {
  const { t } = useTranslation()
  const { goal } = useAiMessageGenerator()

  const successMetricOptions = useMemo(
    () => [
      {
        label: t('aiMessageGenerator.goals.conversion.linkClick'),
        value: ConversionGoalSuccessMetric.LinkClick,
      },
      {
        label: t('aiMessageGenerator.goals.conversion.productSale'),
        value: ConversionGoalSuccessMetric.ProductSale,
      },
      {
        label: t('aiMessageGenerator.goals.conversion.ticketSale'),
        value: ConversionGoalSuccessMetric.TicketSale,
      },
      {
        label: t('aiMessageGenerator.goals.conversion.donation'),
        value: ConversionGoalSuccessMetric.Donation,
      },
      {
        label: t('aiMessageGenerator.goals.conversion.websiteVisit'),
        value: ConversionGoalSuccessMetric.WebsiteVisit,
      },
      {
        label: t('aiMessageGenerator.goals.conversion.leadCapture'),
        value: ConversionGoalSuccessMetric.LeadCapture,
      },
      {
        label: t('aiMessageGenerator.goals.conversion.songPreSave'),
        value: ConversionGoalSuccessMetric.SongPresave,
      },
      {
        label: t('aiMessageGenerator.goals.conversion.formSubmit'),
        value: ConversionGoalSuccessMetric.FormComplete,
      },
      {
        label: t('aiMessageGenerator.goals.conversion.other'),
        value: ConversionGoalSuccessMetric.Other,
      },
    ],
    [t],
  )

  const successMetricValue = useMemo(() => {
    return successMetricOptions.find((option) => option.value === goal?.success_metric)
  }, [goal, successMetricOptions])

  return { successMetricValue, successMetricOptions }
}
