import { MemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { useMutation } from '@tanstack/react-query'
import { useCallback, useState } from 'react'

import {
  checkContentForLink,
  checkContentForLinkAtBeginning,
  checkContentForLinkAtEnd,
  checkContentForMultipleLinks,
} from './checkContentForLinks'
import { processBadWords } from './processBadWords'

import { postCheckMessageContent } from 'api/message'
import { ScheduledState } from 'components/ComposeMessage/ComposeContext'
import { checkIsAgeUnderLimit, checkIsBirthdayFilterMissing, checkIsQuietTime } from 'utils/contentWarning'

export enum WarningTypes {
  BAD_WORDS = 'BAD_WORDS',
  BIRTHDAY_FILTER = 'BIRTHDAY_FILTER',
  LINK_PREVIEW = 'LINK_PREVIEW',
  QUIET_TIME = 'QUIET_TIME',
}

export type Warning = {
  type: WarningTypes
  [key: string]: any
}

type UseCheckMessageContentProps = {
  clientId: string
  mediaDisposition?: MediaDisposition
}

type CheckMessageFunc = {
  (
    content: {
      text: string
      filters: MemberDataFilter
      scheduledAt?: ScheduledState
      hasMedia?: boolean
    },
    options?: {
      bypass: string[]
    },
  ): Promise<any>
}

type UseCheckMessageContentReturn = {
  error: Error | null
  hasViolation: boolean
  warning: Warning | null
  checkMessage: CheckMessageFunc
}

export const useCheckMessageContent = ({
  clientId,
  mediaDisposition = MediaDisposition.LINK,
}: UseCheckMessageContentProps): UseCheckMessageContentReturn => {
  const [error, setError] = useState<Error | null>(null)
  const [warning, setWarning] = useState<Warning | null>(null)
  const [hasViolation, setHasViolation] = useState(false)

  const { mutateAsync: mutateCheckMessageContent } = useMutation(postCheckMessageContent)

  const checkMessage: CheckMessageFunc = useCallback(
    async ({ text = '', filters, scheduledAt, hasMedia }, options = { bypass: [] }) => {
      setWarning(null)
      setError(null)
      setHasViolation(false)

      const { bypass } = options

      // check for link preview warning triggers, bypass if user doesn't care
      if (
        !bypass?.includes?.(WarningTypes.LINK_PREVIEW) &&
        // SMS or MMS message has multiple links
        (checkContentForMultipleLinks(text) ||
          // SMS message has media and includes link
          (mediaDisposition === MediaDisposition.LINK && hasMedia && checkContentForLink(text)) ||
          // SMS message includes link but not at the beginning
          (mediaDisposition === MediaDisposition.LINK &&
            checkContentForLink(text) &&
            !checkContentForLinkAtBeginning(text)) ||
          // MMS message includes link but not at the end
          (mediaDisposition === MediaDisposition.ATTACHMENT &&
            hasMedia &&
            checkContentForLink(text) &&
            !checkContentForLinkAtEnd(text)))
      ) {
        setWarning({
          type: WarningTypes.LINK_PREVIEW,
        })
        return { valid: false }
      }

      // check for user trying to send a birthday message
      // without specifying birthday filter, bypass if user wants to
      if (checkIsBirthdayFilterMissing(filters, text) && !bypass?.includes?.(WarningTypes.BIRTHDAY_FILTER)) {
        setWarning({
          type: WarningTypes.BIRTHDAY_FILTER,
        })
        return { valid: false }
      }

      // check if it's quiet hours
      if (checkIsQuietTime(scheduledAt?.toDate()) && !bypass?.includes?.(WarningTypes.QUIET_TIME)) {
        const warning = {
          type: WarningTypes.QUIET_TIME,
        }

        setWarning(warning)

        return { valid: false, warning }
      }

      try {
        // bypass bad words check if user wants to send the message anyways
        if (!bypass?.includes?.(WarningTypes.BAD_WORDS)) {
          // check for content with the backend
          const data = await mutateCheckMessageContent({ text, clientId })
          const badWords = processBadWords(data)
          const shouldBlock =
            badWords.violations?.length ||
            badWords.general?.length ||
            (badWords.alcohol?.length && checkIsAgeUnderLimit(filters, 21)) ||
            (badWords.tobacco?.length && checkIsAgeUnderLimit(filters, 21))

          if (badWords.violations?.length) {
            setHasViolation(true)
          }

          if (shouldBlock) {
            const warning = {
              type: WarningTypes.BAD_WORDS,
              badWords,
            }
            setWarning(warning)
            return { valid: false, warning }
          }
        }

        return {
          valid: true,
        }
      } catch (error) {
        setError(error as Error)

        return {
          valid: false,
          error,
        }
      }
    },
    [clientId, mediaDisposition, mutateCheckMessageContent],
  )

  return {
    error,
    hasViolation,
    warning,
    checkMessage,
  }
}
