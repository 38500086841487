export function EmptyIllustration(): JSX.Element {
  return (
    <svg fill="none" height="240" viewBox="0 0 240 240" width="240" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M55.3014 194.968C53.0953 196.071 53.5806 197.704 55.8088 198.807L98.8506 220.625C101.079 221.729 105.05 222.236 107.256 221.133L183.544 182.923C185.75 181.819 185.795 180.121 183.544 179.018L139.62 156.78C137.392 155.677 133.796 155.655 131.59 156.758L55.3014 194.968Z"
        fill="black"
      />
      <path
        d="M131.81 144.25C134.016 143.147 137.612 143.147 139.84 144.272L185.022 167.503V168.849V169.114C185 169.819 184.89 170.437 183.831 170.967L107.543 209.177C105.336 210.28 101.74 210.28 99.5122 209.155L55.588 186.917C54.507 186.366 53.9334 185.66 53.8893 184.954L53.8672 184.512V182.35L131.81 144.25Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-width="0.435029"
      />
      <path
        d="M55.3459 180.673L55.3458 180.673C54.2575 181.217 53.8771 181.868 53.9894 182.487C54.1048 183.123 54.746 183.788 55.8529 184.336L55.8538 184.336L98.8946 206.155C98.8947 206.155 98.8948 206.155 98.8948 206.155C99.9957 206.7 101.533 207.1 103.07 207.226C104.609 207.352 106.133 207.202 107.212 206.662C107.213 206.662 107.213 206.662 107.213 206.662L183.501 168.452L183.545 168.54L183.501 168.452C184.593 167.906 185.113 167.231 185.118 166.587C185.123 165.943 184.615 165.269 183.502 164.723L183.5 164.723L139.577 142.485C139.577 142.485 139.577 142.485 139.576 142.485C138.479 141.942 137.039 141.662 135.597 141.657C134.154 141.651 132.72 141.92 131.634 142.463L55.3459 180.673ZM55.3459 180.673L131.634 142.463L55.3459 180.673Z"
        fill="white"
        stroke="black"
        stroke-width="0.196468"
      />
      <path
        d="M156.695 153.738C156.342 153.914 156.342 154.201 156.695 154.378C157.048 154.554 157.621 154.554 157.974 154.378C158.327 154.201 158.327 153.914 157.974 153.738C157.621 153.561 157.048 153.561 156.695 153.738Z"
        fill="white"
      />
      <path
        d="M78.8858 192.475C77.8268 193.005 77.8489 193.865 78.9079 194.395C79.9668 194.924 81.6876 194.924 82.7465 194.395C83.8055 193.865 83.7835 193.005 82.7245 192.475C81.6656 191.946 79.9447 191.946 78.8858 192.475Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-width="0.435029"
      />
      <path
        d="M84.598 177.498C82.6787 178.468 83.0096 179.88 84.951 180.851L105.38 191.154C107.321 192.124 110.476 192.124 112.395 191.176L161.372 166.688C163.291 165.717 163.092 164.239 161.129 163.268L140.59 153.032C138.648 152.061 135.494 152.061 133.574 153.01L84.598 177.498Z"
        fill="black"
      />
      <path
        d="M91.8572 182.395C92.2984 182.395 92.7617 182.395 93.203 182.373C93.6883 182.373 93.9972 182.064 93.8207 181.822C93.6442 181.535 93.1588 181.402 92.6293 181.402C92.1881 181.402 91.7249 181.402 91.2836 181.424C90.7983 181.424 90.4894 181.733 90.6659 181.976C90.8644 182.263 91.3498 182.395 91.8572 182.395Z"
        fill="white"
      />
      <path
        d="M93.6881 176.9C94.3278 176.944 94.9676 176.988 95.6295 177.01C96.1148 177.032 96.5781 176.856 96.534 176.591C96.4898 176.326 96.0707 176.083 95.5412 176.061C94.9014 176.017 94.2617 175.973 93.5998 175.951C93.1145 175.929 92.6512 176.106 92.6953 176.37C92.7615 176.613 93.1586 176.856 93.6881 176.9Z"
        fill="white"
      />
      <path
        d="M119.963 174.098C121.066 174.208 122.169 174.297 123.25 174.407C124.463 174.517 124.397 173.569 123.183 173.458C122.08 173.348 120.977 173.26 119.896 173.15C118.661 173.039 118.749 173.988 119.963 174.098Z"
        fill="white"
      />
      <path
        d="M127.641 166.466C130.42 166.598 133.178 166.73 135.958 166.841C137.171 166.907 137.105 165.936 135.892 165.892C133.112 165.76 130.354 165.627 127.575 165.517C126.339 165.473 126.427 166.422 127.641 166.466Z"
        fill="white"
      />
      <path
        d="M108.977 172.774C110.102 173.59 112.065 173.965 113.676 173.634C114.735 173.414 113.72 172.531 112.683 172.752C112.043 172.884 111.072 172.752 110.587 172.399C110.278 172.178 109.749 172.024 109.285 172.134C108.888 172.244 108.668 172.553 108.977 172.774Z"
        fill="white"
      />
      <path
        d="M107.101 181.114C107.94 181.511 108.888 181.776 109.969 181.908C110.477 181.974 110.896 181.732 110.874 181.489C110.852 181.202 110.41 181.026 109.881 180.96C109.351 180.893 108.8 180.739 108.381 180.54C107.984 180.342 107.41 180.298 107.035 180.452C106.704 180.585 106.682 180.916 107.101 181.114Z"
        fill="white"
      />
      <path
        d="M109.132 189.232C109.705 189.298 110.301 189.364 110.874 189.43C111.382 189.474 111.823 189.254 111.779 189.011C111.735 188.724 111.316 188.526 110.786 188.482C110.213 188.415 109.617 188.349 109.043 188.283C108.536 188.239 108.095 188.459 108.139 188.702C108.205 188.967 108.602 189.165 109.132 189.232Z"
        fill="white"
      />
      <path
        d="M140.79 162.516C143.018 162.604 145.224 162.582 147.386 162.471C148.556 162.405 148.004 161.457 146.835 161.523C144.827 161.633 142.798 161.655 140.702 161.567C139.488 161.501 139.555 162.471 140.79 162.516Z"
        fill="white"
      />
      <path
        d="M127.906 170.9C128.656 170.856 129.406 170.9 130.178 170.988C130.685 171.054 131.104 170.811 131.082 170.569C131.06 170.282 130.619 170.105 130.09 170.039C129.163 169.929 128.237 169.907 127.332 169.951C126.185 170.017 126.736 170.966 127.906 170.9Z"
        fill="white"
      />
      <path
        d="M101.233 176.656C102.049 176.612 102.888 176.545 103.704 176.501C104.873 176.413 104.322 175.464 103.152 175.553C102.336 175.597 101.498 175.663 100.681 175.707C99.5122 175.795 100.064 176.744 101.233 176.656Z"
        fill="white"
      />
      <path
        d="M147.54 169.288C147.629 169.288 147.695 169.288 147.783 169.266C147.937 169.266 147.673 169.266 147.827 169.266C147.981 169.266 148.158 169.288 148.312 169.288C148.467 169.31 148.202 169.266 148.357 169.288C148.445 169.31 148.511 169.31 148.599 169.332C148.665 169.354 148.732 169.354 148.798 169.376C148.798 169.376 149.063 169.442 148.952 169.42C149.438 169.552 149.967 169.552 150.232 169.31C150.452 169.111 150.298 168.78 149.835 168.648C148.908 168.383 147.959 168.273 146.989 168.339C146.503 168.361 146.217 168.626 146.371 168.891C146.503 169.133 147.055 169.31 147.54 169.288Z"
        fill="white"
      />
      <path
        d="M152.35 165.119C153.85 165.229 155.328 165.449 156.784 165.78C157.953 166.045 158.35 165.163 157.181 164.898C155.571 164.545 153.938 164.302 152.261 164.17C151.048 164.082 151.114 165.03 152.35 165.119Z"
        fill="white"
      />
      <path
        d="M100.55 185.174C101.145 185.394 101.895 185.549 102.601 185.549C103.087 185.549 103.55 185.416 103.506 185.129C103.462 184.887 103.043 184.6 102.513 184.6C102.381 184.6 102.27 184.6 102.138 184.578C102.116 184.578 101.939 184.556 102.05 184.578C102.16 184.6 101.917 184.556 101.917 184.556C101.873 184.556 101.807 184.534 101.763 184.534C101.675 184.512 101.675 184.512 101.763 184.534C101.631 184.49 101.52 184.468 101.388 184.423C100.969 184.269 100.307 184.313 100.108 184.534C99.9098 184.732 100.086 184.997 100.55 185.174Z"
        fill="white"
      />
      <path
        d="M119.589 183.145C120.692 183.255 121.795 183.344 122.876 183.454C124.09 183.564 124.023 182.616 122.81 182.505C121.707 182.395 120.604 182.307 119.523 182.196C118.287 182.086 118.376 183.035 119.589 183.145Z"
        fill="white"
      />
      <path
        d="M122.412 177.584C123.736 177.739 124.993 177.695 126.162 177.496C126.626 177.43 126.714 177.077 126.471 176.878C126.184 176.614 125.633 176.548 125.17 176.614C124.287 176.746 123.316 176.724 122.346 176.636C121.838 176.57 121.419 176.812 121.441 177.055C121.441 177.32 121.905 177.518 122.412 177.584Z"
        fill="white"
      />
      <path
        d="M135.738 171.032C136.775 171.099 137.812 171.165 138.826 171.231C140.04 171.297 139.974 170.349 138.76 170.282C137.723 170.216 136.686 170.15 135.672 170.084C134.458 169.996 134.524 170.966 135.738 171.032Z"
        fill="white"
      />
      <path
        d="M133.95 177.825C134.524 177.892 135.119 177.958 135.693 178.024C136.2 178.068 136.641 177.847 136.597 177.605C136.553 177.318 136.134 177.119 135.605 177.075C135.031 177.009 134.435 176.943 133.862 176.877C133.354 176.833 132.913 177.053 132.957 177.296C133.023 177.583 133.42 177.781 133.95 177.825Z"
        fill="white"
      />
      <path
        d="M84.8853 163.818V165.009L133.619 140.631C135.538 139.66 138.693 139.683 140.634 140.653L161.196 150.89V149.72L162.608 149.765L162.519 151.154L162.63 151.882C162.608 152.5 162.365 153.184 161.438 153.647L112.462 178.136C110.543 179.106 107.653 179.239 105.689 178.268L84.7971 167.855C83.8484 167.37 83.341 166.575 83.319 165.936L83.2969 165.583V163.862L84.8853 163.818Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-width="0.435029"
      />
      <path
        d="M84.5995 162.119C82.6802 163.089 83.011 164.501 84.9524 165.472L105.381 175.775C107.323 176.745 110.478 176.745 112.397 175.797L161.373 151.309C163.292 150.338 163.094 148.86 161.13 147.889L140.591 137.653C138.65 136.682 135.495 136.682 133.576 137.631L84.5995 162.119Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-width="0.435029"
      />
      <path d="M90.2241 160.443L135.472 137.984L160.512 150.603L115.374 173.084L90.2241 160.443Z" fill="black" />
      <path
        d="M96.754 167.279C95.7171 167.786 95.7392 168.625 96.7761 169.154C97.813 169.684 99.4896 169.684 100.527 169.154C101.563 168.647 101.541 167.808 100.504 167.279C99.4455 166.772 97.7688 166.772 96.754 167.279Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-width="0.435029"
      />
      <path
        d="M168.293 79.7921L137.991 64.3874V41.4386L149.311 36.0391L194.72 59.0239V81.8003L188.598 84.7497V91.4928L177.163 96.9498L168.293 79.7921Z"
        fill="black"
      />
      <path
        d="M149.31 36.3308L194.454 59.1826V81.6178L188.346 84.5672V91.3176L177.296 96.5914L168.505 79.5844L138.271 64.2158V41.6009L149.325 36.3273L149.31 36.3308ZM149.325 35.7344L149.091 35.8458L138.037 41.1196L137.735 41.2633V64.5428L138.026 64.6899L168.106 79.9833L176.818 96.8356L177.055 97.2918L177.519 97.069L188.573 91.7989L188.878 91.6552V84.9086L194.698 82.1063L195 81.9592V58.8521L194.705 58.7049L149.562 35.853L149.325 35.7344Z"
        fill="black"
      />
      <path
        d="M183.404 64.4573L138.257 41.6055V64.2202L168.491 79.5925L177.282 96.5958V83.8999L183.404 86.9032V64.4573Z"
        fill="white"
      />
      <path d="M183.971 63.8569L138.767 41.2494L149.329 36.2773L194.4 59.061L183.971 63.8569Z" fill="white" />
      <path
        d="M66.0938 129.435V124.542L61.6499 122.401V105.872L94.6039 89.1953L102.82 93.1111V109.766L80.8302 120.946L74.3925 133.394L66.0938 129.435Z"
        fill="black"
      />
      <path
        d="M94.6041 89.41L102.626 93.236V109.643L80.6868 120.798L74.3066 133.138L66.2881 129.312V124.419L61.8441 122.263V105.975L94.6041 89.3919V89.41ZM94.6041 88.9609L94.4353 89.0471L61.6753 105.63L61.4634 105.738V122.508L61.6789 122.612L65.9037 124.649V129.542L66.1228 129.646L74.1448 133.472L74.4826 133.633L74.655 133.299L80.9778 121.085L102.806 109.988L103.018 109.88V92.9953L102.795 92.8911L94.7766 89.0651L94.6041 88.9825V88.9609Z"
        fill="black"
      />
      <path
        d="M69.8662 109.817L102.626 93.2344V109.648L80.6868 120.8L74.3065 133.14V123.929L69.8662 126.106V109.817Z"
        fill="white"
      />
      <path d="M69.4532 109.381L102.256 92.9776L94.5899 89.3672L61.8838 105.9L69.4532 109.381Z" fill="white" />
      <path
        d="M108.299 47.2636V42.9023L105.159 41.8281V29.8148L128.518 18.168L131.722 19.1954V31.5321L116.461 39.1122L111.708 48.309L108.299 47.2636Z"
        fill="white"
      />
      <path
        d="M128.528 18.3196L131.578 19.3004V31.4431L116.357 39.0052L111.636 48.1373L108.442 47.1566V42.7989L105.303 41.7212V29.8875L128.528 18.3052V18.3196ZM128.506 18L128.402 18.0502L105.173 29.6325L105.015 29.7114V41.9259L105.209 41.9906L108.159 43.0001V47.365L108.36 47.4297L111.553 48.4068L111.78 48.4752L111.891 48.2631L116.561 39.2173L131.697 31.6945L131.858 31.6155V19.11L131.657 19.0453L128.607 18.0682L128.499 18.0323L128.506 18Z"
        fill="black"
      />
      <path
        d="M108.349 30.883L131.578 19.3008V31.4434L116.356 39.0092L111.636 48.1377V41.3228L108.349 42.9322V30.883Z"
        fill="black"
      />
      <path d="M105.273 29.8281L109.516 31.3046" stroke="black" stroke-miterlimit="10" stroke-width="0.2874" />
      <path
        d="M119.371 82.783L87.5879 67.5364V55.516L90.3793 54.2227L133.92 75.3681V87.2557L131.111 88.5849L129.153 87.6293V92.6803L126.283 94.006L119.371 82.783Z"
        fill="black"
      />
      <path
        d="M90.3757 54.4519L133.716 75.5112V87.1365L131.115 88.3688L128.959 87.3089V92.5612L126.376 93.7538L119.529 82.6351L87.8106 67.4209V55.6448L90.3901 54.4519H90.3757ZM90.3901 53.9922L90.2105 54.0748L87.6274 55.2675L87.3867 55.3788V67.6688L87.6238 67.7801L119.238 82.9405L126.024 93.9587L126.218 94.2713L126.552 94.1167L129.135 92.9241L129.376 92.8127V87.9592L130.946 88.728L131.125 88.8141L131.305 88.728L133.906 87.4994L134.143 87.3843V75.2346L133.91 75.1232L90.5733 54.0783L90.3973 53.9922H90.3901Z"
        fill="black"
      />
      <path
        d="M131.132 76.6894L87.7959 55.6445V67.4064L119.514 82.6206L126.361 93.7394V85.9796L131.132 88.3183V76.6894Z"
        fill="white"
      />
      <path d="M131.575 76.2238L88.1953 55.3729L90.3939 54.4102L133.673 75.4011L131.575 76.2238Z" fill="white" />
      <path
        d="M122.93 142.585L101.124 132.493V120.469L103.915 119.18L137.48 135.166V147.057L134.671 148.387L132.713 147.431V152.482L129.842 153.808L122.93 142.585Z"
        fill="black"
      />
      <path
        d="M103.916 119.405L137.272 135.295V146.924L134.675 148.156L132.519 147.096V152.349L129.936 153.541L123.089 142.405L101.347 132.346V120.598L103.93 119.405H103.916ZM103.93 118.949L103.75 119.028L101.171 120.221L100.93 120.336V132.633L101.171 132.744L122.798 142.753L129.58 153.764L129.774 154.08L130.108 153.926L132.688 152.733L132.929 152.622V147.768L134.502 148.537L134.682 148.623L134.861 148.537L137.462 147.308L137.699 147.193V135.04L137.462 134.929L104.102 119.039L103.93 118.956V118.949Z"
        fill="black"
      />
      <path
        d="M134.693 136.491L101.333 120.602V132.36L123.075 142.422L129.922 153.541V145.781L134.693 148.12V136.491Z"
        fill="white"
      />
      <path d="M135.134 136.025L101.731 120.326L103.93 119.363L137.232 135.203L135.134 136.025Z" fill="white" />
      <path
        d="M159.068 123.099L141.411 115.306V98.5619L145.298 96.7656L179.326 112.77V129.324L175.417 131.178L172.687 129.845V136.879L168.692 138.726L159.068 123.099Z"
        fill="black"
      />
      <path
        d="M145.294 97.0847L179.038 112.953V129.141L175.417 130.854L172.399 129.381V136.692L168.807 138.352L159.272 122.872L141.705 115.119V98.7445L145.297 97.0847H145.294ZM145.297 96.4453L145.053 96.5602L141.461 98.22L141.126 98.3745V115.496L141.486 115.647L158.888 123.328L168.329 138.657L168.598 139.092L169.065 138.876L172.658 137.216L172.996 137.062V130.305L175.183 131.382L175.431 131.504L175.683 131.386L179.304 129.672L179.634 129.514V112.594L179.304 112.435L145.56 96.5674L145.315 96.4525L145.297 96.4453Z"
        fill="black"
      />
      <path
        d="M175.446 114.614L141.702 98.7422V115.117L159.269 122.869L168.803 138.353V127.543L175.446 130.802V114.614Z"
        fill="white"
      />
      <path d="M176.06 113.966L142.255 98.3598L145.319 97.0234L178.981 112.816L176.06 113.966Z" fill="white" />
      <path
        d="M58.5028 76.8095L44.1831 70.4113V59.5943L49.2952 57.1406L74.2056 68.8486V79.547L69.9162 81.6163V86.344L64.7178 88.8336L58.5028 76.8095Z"
        fill="black"
      />
      <path
        d="M49.2989 57.35L74.0189 68.9681V79.4331L69.7295 81.4988V86.2264L64.8006 88.5868L58.6394 76.6668L44.37 70.2938V59.7067L49.2989 57.3465V57.35ZM49.2989 56.9297L49.1409 57.0088L44.212 59.369L44 59.4732V70.5308L44.2228 70.6315L58.3808 76.9507L64.488 88.7556L64.6533 89.0788L64.9802 88.9208L69.9091 86.5606L70.121 86.4564V81.7286L74.1985 79.7636L74.4105 79.6629V68.7274L74.1985 68.6269L49.475 57.0051L49.3133 56.9297H49.2989Z"
        fill="black"
      />
      <path
        d="M69.0937 71.3288L44.3701 59.707V70.2905L58.6395 76.6636L64.8006 88.5871V79.6885L69.0937 81.7901V71.3288Z"
        fill="white"
      />
      <path d="M69.4926 70.9098L44.7295 59.4605L49.3135 57.3086L73.9832 68.88L69.4926 70.9098Z" fill="white" />
      <path
        d="M55.3014 194.968C53.0953 196.071 53.5806 197.704 55.8088 198.807L98.8506 220.625C101.079 221.729 105.05 222.236 107.256 221.133L183.544 182.923C185.75 181.819 185.795 180.121 183.544 179.018L139.62 156.78C137.392 155.677 133.796 155.655 131.59 156.758L55.3014 194.968Z"
        fill="black"
      />
      <path
        d="M131.81 144.25C134.016 143.147 137.612 143.147 139.84 144.272L185.022 167.503V168.849V169.114C185 169.819 184.89 170.437 183.831 170.967L107.543 209.177C105.336 210.28 101.74 210.28 99.5122 209.155L55.588 186.917C54.507 186.366 53.9334 185.66 53.8893 184.954L53.8672 184.512V182.35L131.81 144.25Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-width="0.435029"
      />
      <path
        d="M55.3459 180.673L55.3458 180.673C54.2575 181.217 53.8771 181.868 53.9894 182.487C54.1048 183.123 54.746 183.788 55.8529 184.336L55.8538 184.336L98.8946 206.155C98.8947 206.155 98.8948 206.155 98.8948 206.155C99.9957 206.7 101.533 207.1 103.07 207.226C104.609 207.352 106.133 207.202 107.212 206.662C107.213 206.662 107.213 206.662 107.213 206.662L183.501 168.452L183.545 168.54L183.501 168.452C184.593 167.906 185.113 167.231 185.118 166.587C185.123 165.943 184.615 165.269 183.502 164.723L183.5 164.723L139.577 142.485C139.577 142.485 139.577 142.485 139.576 142.485C138.479 141.942 137.039 141.662 135.597 141.657C134.154 141.651 132.72 141.92 131.634 142.463L55.3459 180.673ZM55.3459 180.673L131.634 142.463L55.3459 180.673Z"
        fill="white"
        stroke="black"
        stroke-width="0.196468"
      />
      <path
        d="M156.695 153.738C156.342 153.914 156.342 154.201 156.695 154.378C157.048 154.554 157.621 154.554 157.974 154.378C158.327 154.201 158.327 153.914 157.974 153.738C157.621 153.561 157.048 153.561 156.695 153.738Z"
        fill="white"
      />
      <path
        d="M78.8858 192.475C77.8268 193.005 77.8489 193.865 78.9079 194.395C79.9668 194.924 81.6876 194.924 82.7465 194.395C83.8055 193.865 83.7835 193.005 82.7245 192.475C81.6656 191.946 79.9447 191.946 78.8858 192.475Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-width="0.435029"
      />
      <path
        d="M84.598 177.498C82.6787 178.468 83.0096 179.88 84.951 180.851L105.38 191.154C107.321 192.124 110.476 192.124 112.395 191.176L161.372 166.688C163.291 165.717 163.092 164.239 161.129 163.268L140.59 153.032C138.648 152.061 135.494 152.061 133.574 153.01L84.598 177.498Z"
        fill="black"
      />
      <path
        d="M91.8572 182.395C92.2984 182.395 92.7617 182.395 93.203 182.373C93.6883 182.373 93.9972 182.064 93.8207 181.822C93.6442 181.535 93.1588 181.402 92.6293 181.402C92.1881 181.402 91.7249 181.402 91.2836 181.424C90.7983 181.424 90.4894 181.733 90.6659 181.976C90.8644 182.263 91.3498 182.395 91.8572 182.395Z"
        fill="white"
      />
      <path
        d="M93.6881 176.9C94.3278 176.944 94.9676 176.988 95.6295 177.01C96.1148 177.032 96.5781 176.856 96.534 176.591C96.4898 176.326 96.0707 176.083 95.5412 176.061C94.9014 176.017 94.2617 175.973 93.5998 175.951C93.1145 175.929 92.6512 176.106 92.6953 176.37C92.7615 176.613 93.1586 176.856 93.6881 176.9Z"
        fill="white"
      />
      <path
        d="M119.963 174.098C121.066 174.208 122.169 174.297 123.25 174.407C124.463 174.517 124.397 173.569 123.183 173.458C122.08 173.348 120.977 173.26 119.896 173.15C118.661 173.039 118.749 173.988 119.963 174.098Z"
        fill="white"
      />
      <path
        d="M127.641 166.466C130.42 166.598 133.178 166.73 135.958 166.841C137.171 166.907 137.105 165.936 135.892 165.892C133.112 165.76 130.354 165.627 127.575 165.517C126.339 165.473 126.427 166.422 127.641 166.466Z"
        fill="white"
      />
      <path
        d="M108.977 172.774C110.102 173.59 112.065 173.965 113.676 173.634C114.735 173.414 113.72 172.531 112.683 172.752C112.043 172.884 111.072 172.752 110.587 172.399C110.278 172.178 109.749 172.024 109.285 172.134C108.888 172.244 108.668 172.553 108.977 172.774Z"
        fill="white"
      />
      <path
        d="M107.101 181.114C107.94 181.511 108.888 181.776 109.969 181.908C110.477 181.974 110.896 181.732 110.874 181.489C110.852 181.202 110.41 181.026 109.881 180.96C109.351 180.893 108.8 180.739 108.381 180.54C107.984 180.342 107.41 180.298 107.035 180.452C106.704 180.585 106.682 180.916 107.101 181.114Z"
        fill="white"
      />
      <path
        d="M109.132 189.232C109.705 189.298 110.301 189.364 110.874 189.43C111.382 189.474 111.823 189.254 111.779 189.011C111.735 188.724 111.316 188.526 110.786 188.482C110.213 188.415 109.617 188.349 109.043 188.283C108.536 188.239 108.095 188.459 108.139 188.702C108.205 188.967 108.602 189.165 109.132 189.232Z"
        fill="white"
      />
      <path
        d="M140.79 162.516C143.018 162.604 145.224 162.582 147.386 162.471C148.556 162.405 148.004 161.457 146.835 161.523C144.827 161.633 142.798 161.655 140.702 161.567C139.488 161.501 139.555 162.471 140.79 162.516Z"
        fill="white"
      />
      <path
        d="M127.906 170.9C128.656 170.856 129.406 170.9 130.178 170.988C130.685 171.054 131.104 170.811 131.082 170.569C131.06 170.282 130.619 170.105 130.09 170.039C129.163 169.929 128.237 169.907 127.332 169.951C126.185 170.017 126.736 170.966 127.906 170.9Z"
        fill="white"
      />
      <path
        d="M101.233 176.656C102.049 176.612 102.888 176.545 103.704 176.501C104.873 176.413 104.322 175.464 103.152 175.553C102.336 175.597 101.498 175.663 100.681 175.707C99.5122 175.795 100.064 176.744 101.233 176.656Z"
        fill="white"
      />
      <path
        d="M147.54 169.288C147.629 169.288 147.695 169.288 147.783 169.266C147.937 169.266 147.673 169.266 147.827 169.266C147.981 169.266 148.158 169.288 148.312 169.288C148.467 169.31 148.202 169.266 148.357 169.288C148.445 169.31 148.511 169.31 148.599 169.332C148.665 169.354 148.732 169.354 148.798 169.376C148.798 169.376 149.063 169.442 148.952 169.42C149.438 169.552 149.967 169.552 150.232 169.31C150.452 169.111 150.298 168.78 149.835 168.648C148.908 168.383 147.959 168.273 146.989 168.339C146.503 168.361 146.217 168.626 146.371 168.891C146.503 169.133 147.055 169.31 147.54 169.288Z"
        fill="white"
      />
      <path
        d="M152.35 165.119C153.85 165.229 155.328 165.449 156.784 165.78C157.953 166.045 158.35 165.163 157.181 164.898C155.571 164.545 153.938 164.302 152.261 164.17C151.048 164.082 151.114 165.03 152.35 165.119Z"
        fill="white"
      />
      <path
        d="M100.55 185.174C101.145 185.394 101.895 185.549 102.601 185.549C103.087 185.549 103.55 185.416 103.506 185.129C103.462 184.887 103.043 184.6 102.513 184.6C102.381 184.6 102.27 184.6 102.138 184.578C102.116 184.578 101.939 184.556 102.05 184.578C102.16 184.6 101.917 184.556 101.917 184.556C101.873 184.556 101.807 184.534 101.763 184.534C101.675 184.512 101.675 184.512 101.763 184.534C101.631 184.49 101.52 184.468 101.388 184.423C100.969 184.269 100.307 184.313 100.108 184.534C99.9098 184.732 100.086 184.997 100.55 185.174Z"
        fill="white"
      />
      <path
        d="M119.589 183.145C120.692 183.255 121.795 183.344 122.876 183.454C124.09 183.564 124.023 182.616 122.81 182.505C121.707 182.395 120.604 182.307 119.523 182.196C118.287 182.086 118.376 183.035 119.589 183.145Z"
        fill="white"
      />
      <path
        d="M122.412 177.584C123.736 177.739 124.993 177.695 126.162 177.496C126.626 177.43 126.714 177.077 126.471 176.878C126.184 176.614 125.633 176.548 125.17 176.614C124.287 176.746 123.316 176.724 122.346 176.636C121.838 176.57 121.419 176.812 121.441 177.055C121.441 177.32 121.905 177.518 122.412 177.584Z"
        fill="white"
      />
      <path
        d="M135.738 171.032C136.775 171.099 137.812 171.165 138.826 171.231C140.04 171.297 139.974 170.349 138.76 170.282C137.723 170.216 136.686 170.15 135.672 170.084C134.458 169.996 134.524 170.966 135.738 171.032Z"
        fill="white"
      />
      <path
        d="M133.95 177.825C134.524 177.892 135.119 177.958 135.693 178.024C136.2 178.068 136.641 177.847 136.597 177.605C136.553 177.318 136.134 177.119 135.605 177.075C135.031 177.009 134.435 176.943 133.862 176.877C133.354 176.833 132.913 177.053 132.957 177.296C133.023 177.583 133.42 177.781 133.95 177.825Z"
        fill="white"
      />
      <path
        d="M84.8853 163.818V165.009L133.619 140.631C135.538 139.66 138.693 139.683 140.634 140.653L161.196 150.89V149.72L162.608 149.765L162.519 151.154L162.63 151.882C162.608 152.5 162.365 153.184 161.438 153.647L112.462 178.136C110.543 179.106 107.653 179.239 105.689 178.268L84.7971 167.855C83.8484 167.37 83.341 166.575 83.319 165.936L83.2969 165.583V163.862L84.8853 163.818Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-width="0.435029"
      />
      <path
        d="M84.5995 162.119C82.6802 163.089 83.011 164.501 84.9524 165.472L105.381 175.775C107.323 176.745 110.478 176.745 112.397 175.797L161.373 151.309C163.292 150.338 163.094 148.86 161.13 147.889L140.591 137.653C138.65 136.682 135.495 136.682 133.576 137.631L84.5995 162.119Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-width="0.435029"
      />
      <path d="M90.2241 160.443L135.472 137.984L160.512 150.603L115.374 173.084L90.2241 160.443Z" fill="black" />
      <path
        d="M96.754 167.279C95.7171 167.786 95.7392 168.625 96.7761 169.154C97.813 169.684 99.4896 169.684 100.527 169.154C101.563 168.647 101.541 167.808 100.504 167.279C99.4455 166.772 97.7688 166.772 96.754 167.279Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-width="0.435029"
      />
      <path
        d="M168.293 79.7921L137.991 64.3874V41.4386L149.311 36.0391L194.72 59.0239V81.8003L188.598 84.7497V91.4928L177.163 96.9498L168.293 79.7921Z"
        fill="black"
      />
      <path
        d="M149.31 36.3308L194.454 59.1826V81.6178L188.346 84.5672V91.3176L177.296 96.5914L168.505 79.5844L138.271 64.2158V41.6009L149.325 36.3273L149.31 36.3308ZM149.325 35.7344L149.091 35.8458L138.037 41.1196L137.735 41.2633V64.5428L138.026 64.6899L168.106 79.9833L176.818 96.8356L177.055 97.2918L177.519 97.069L188.573 91.7989L188.878 91.6552V84.9086L194.698 82.1063L195 81.9592V58.8521L194.705 58.7049L149.562 35.853L149.325 35.7344Z"
        fill="black"
      />
      <path
        d="M183.404 64.4573L138.257 41.6055V64.2202L168.491 79.5925L177.282 96.5958V83.8999L183.404 86.9032V64.4573Z"
        fill="white"
      />
      <path d="M183.971 63.8569L138.767 41.2494L149.329 36.2773L194.4 59.061L183.971 63.8569Z" fill="white" />
      <path
        d="M66.0938 129.435V124.542L61.6499 122.401V105.872L94.6039 89.1953L102.82 93.1111V109.766L80.8302 120.946L74.3925 133.394L66.0938 129.435Z"
        fill="black"
      />
      <path
        d="M94.6041 89.41L102.626 93.236V109.643L80.6868 120.798L74.3066 133.138L66.2881 129.312V124.419L61.8441 122.263V105.975L94.6041 89.3919V89.41ZM94.6041 88.9609L94.4353 89.0471L61.6753 105.63L61.4634 105.738V122.508L61.6789 122.612L65.9037 124.649V129.542L66.1228 129.646L74.1448 133.472L74.4826 133.633L74.655 133.299L80.9778 121.085L102.806 109.988L103.018 109.88V92.9953L102.795 92.8911L94.7766 89.0651L94.6041 88.9825V88.9609Z"
        fill="black"
      />
      <path
        d="M69.8662 109.817L102.626 93.2344V109.648L80.6868 120.8L74.3065 133.14V123.929L69.8662 126.106V109.817Z"
        fill="white"
      />
      <path d="M69.4532 109.381L102.256 92.9776L94.5899 89.3672L61.8838 105.9L69.4532 109.381Z" fill="white" />
      <path
        d="M108.299 47.2636V42.9023L105.159 41.8281V29.8148L128.518 18.168L131.722 19.1954V31.5321L116.461 39.1122L111.708 48.309L108.299 47.2636Z"
        fill="white"
      />
      <path
        d="M128.528 18.3196L131.578 19.3004V31.4431L116.357 39.0052L111.636 48.1373L108.442 47.1566V42.7989L105.303 41.7212V29.8875L128.528 18.3052V18.3196ZM128.506 18L128.402 18.0502L105.173 29.6325L105.015 29.7114V41.9259L105.209 41.9906L108.159 43.0001V47.365L108.36 47.4297L111.553 48.4068L111.78 48.4752L111.891 48.2631L116.561 39.2173L131.697 31.6945L131.858 31.6155V19.11L131.657 19.0453L128.607 18.0682L128.499 18.0323L128.506 18Z"
        fill="black"
      />
      <path
        d="M108.349 30.883L131.578 19.3008V31.4434L116.356 39.0092L111.636 48.1377V41.3228L108.349 42.9322V30.883Z"
        fill="black"
      />
      <path d="M105.273 29.8281L109.516 31.3046" stroke="black" stroke-miterlimit="10" stroke-width="0.2874" />
      <path
        d="M119.371 82.783L87.5879 67.5364V55.516L90.3793 54.2227L133.92 75.3681V87.2557L131.111 88.5849L129.153 87.6293V92.6803L126.283 94.006L119.371 82.783Z"
        fill="black"
      />
      <path
        d="M90.3757 54.4519L133.716 75.5112V87.1365L131.115 88.3688L128.959 87.3089V92.5612L126.376 93.7538L119.529 82.6351L87.8106 67.4209V55.6448L90.3901 54.4519H90.3757ZM90.3901 53.9922L90.2105 54.0748L87.6274 55.2675L87.3867 55.3788V67.6688L87.6238 67.7801L119.238 82.9405L126.024 93.9587L126.218 94.2713L126.552 94.1167L129.135 92.9241L129.376 92.8127V87.9592L130.946 88.728L131.125 88.8141L131.305 88.728L133.906 87.4994L134.143 87.3843V75.2346L133.91 75.1232L90.5733 54.0783L90.3973 53.9922H90.3901Z"
        fill="black"
      />
      <path
        d="M131.132 76.6894L87.7959 55.6445V67.4064L119.514 82.6206L126.361 93.7394V85.9796L131.132 88.3183V76.6894Z"
        fill="white"
      />
      <path d="M131.575 76.2238L88.1953 55.3729L90.3939 54.4102L133.673 75.4011L131.575 76.2238Z" fill="white" />
      <path
        d="M122.93 142.585L101.124 132.493V120.469L103.915 119.18L137.48 135.166V147.057L134.671 148.387L132.713 147.431V152.482L129.842 153.808L122.93 142.585Z"
        fill="black"
      />
      <path
        d="M103.916 119.405L137.272 135.295V146.924L134.675 148.156L132.519 147.096V152.349L129.936 153.541L123.089 142.405L101.347 132.346V120.598L103.93 119.405H103.916ZM103.93 118.949L103.75 119.028L101.171 120.221L100.93 120.336V132.633L101.171 132.744L122.798 142.753L129.58 153.764L129.774 154.08L130.108 153.926L132.688 152.733L132.929 152.622V147.768L134.502 148.537L134.682 148.623L134.861 148.537L137.462 147.308L137.699 147.193V135.04L137.462 134.929L104.102 119.039L103.93 118.956V118.949Z"
        fill="black"
      />
      <path
        d="M134.693 136.491L101.333 120.602V132.36L123.075 142.422L129.922 153.541V145.781L134.693 148.12V136.491Z"
        fill="white"
      />
      <path d="M135.134 136.025L101.731 120.326L103.93 119.363L137.232 135.203L135.134 136.025Z" fill="white" />
      <path
        d="M159.068 123.099L141.411 115.306V98.5619L145.298 96.7656L179.326 112.77V129.324L175.417 131.178L172.687 129.845V136.879L168.692 138.726L159.068 123.099Z"
        fill="black"
      />
      <path
        d="M145.294 97.0847L179.038 112.953V129.141L175.417 130.854L172.399 129.381V136.692L168.807 138.352L159.272 122.872L141.705 115.119V98.7445L145.297 97.0847H145.294ZM145.297 96.4453L145.053 96.5602L141.461 98.22L141.126 98.3745V115.496L141.486 115.647L158.888 123.328L168.329 138.657L168.598 139.092L169.065 138.876L172.658 137.216L172.996 137.062V130.305L175.183 131.382L175.431 131.504L175.683 131.386L179.304 129.672L179.634 129.514V112.594L179.304 112.435L145.56 96.5674L145.315 96.4525L145.297 96.4453Z"
        fill="black"
      />
      <path
        d="M175.446 114.614L141.702 98.7422V115.117L159.269 122.869L168.803 138.353V127.543L175.446 130.802V114.614Z"
        fill="white"
      />
      <path d="M176.06 113.966L142.255 98.3598L145.319 97.0234L178.981 112.816L176.06 113.966Z" fill="white" />
      <path
        d="M58.5028 76.8095L44.1831 70.4113V59.5943L49.2952 57.1406L74.2056 68.8486V79.547L69.9162 81.6163V86.344L64.7178 88.8336L58.5028 76.8095Z"
        fill="black"
      />
      <path
        d="M49.2989 57.35L74.0189 68.9681V79.4331L69.7295 81.4988V86.2264L64.8006 88.5868L58.6394 76.6668L44.37 70.2938V59.7067L49.2989 57.3465V57.35ZM49.2989 56.9297L49.1409 57.0088L44.212 59.369L44 59.4732V70.5308L44.2228 70.6315L58.3808 76.9507L64.488 88.7556L64.6533 89.0788L64.9802 88.9208L69.9091 86.5606L70.121 86.4564V81.7286L74.1985 79.7636L74.4105 79.6629V68.7274L74.1985 68.6269L49.475 57.0051L49.3133 56.9297H49.2989Z"
        fill="black"
      />
      <path
        d="M69.0937 71.3288L44.3701 59.707V70.2905L58.6395 76.6636L64.8006 88.5871V79.6885L69.0937 81.7901V71.3288Z"
        fill="white"
      />
      <path d="M69.4926 70.9098L44.7295 59.4605L49.3135 57.3086L73.9832 68.88L69.4926 70.9098Z" fill="white" />
    </svg>
  )
}
