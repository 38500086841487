import { route } from '@community_dev/requests'
import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'

export type RestrictedKeywords = CamelCasedPropertiesDeep<Api.RestrictedKeyword.Keywords>

export type PostRestrictedKeywordResponse = CamelCasedPropertiesDeep<Api.RestrictedKeyword.PostKeywordResponse>

export function getRestrictedKeywords(): Promise<RestrictedKeywords> {
  return request(route(ENDPOINTS.SETTINGS.RESTRICTED_KEYWORDS))
}

export function addRestrictedKeyword(keyword: string): Promise<PostRestrictedKeywordResponse> {
  return request(route(ENDPOINTS.SETTINGS.RESTRICTED_KEYWORDS), {
    method: HTTP.POST,
    body: { original_keyword: keyword },
  })
}

export function deleteRestrictedKeyword(id: string): Promise<string> {
  return request(route(ENDPOINTS.SETTINGS.RESTRICTED_KEYWORD_DELETE, { id }), {
    method: HTTP.DELETE,
  })
}
