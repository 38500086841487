import { route } from '@community_dev/requests'
import { WorkflowAction, isLogic, isAction } from '@community_dev/workflow-manifest'
import { Redirect } from 'react-router'

import { WorkflowSidebarAddCommunity } from '../WorkflowSidebarAddCommunity'
import { WorkflowSidebarEditFlow } from '../WorkflowSidebarEditFlow'
import { WorkflowSidebarEditLogic } from '../WorkflowSidebarEditLogic'
import { WorkflowSidebarEditSleep } from '../WorkflowSidebarEditSleep'

import { WorkflowSidebarAddSms } from 'components/WorkflowEditor/components/WorkflowSidebarAddSms'
import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { useManifestPlanItem } from 'components/WorkflowEditor/hooks/useManifestPlanItem'
import { ROUTES } from 'constants/routes'
import { UploadProvider } from 'contexts/UploadProvider'

export function WorkflowSidebarAddStep(): JSX.Element | null {
  const { planItem, manifest } = useManifestPlanItem()
  const { workflow } = useWorkflowProvider()
  if (isLogic(planItem)) {
    return <WorkflowSidebarEditLogic mode="add" />
  } else if (isAction(planItem)) {
    const action = manifest?.actions?.[planItem?.action]

    switch (action?.type) {
      case WorkflowAction.SendMessage:
        return (
          <UploadProvider>
            <WorkflowSidebarAddSms />
          </UploadProvider>
        )
      case WorkflowAction.Sleep:
        return <WorkflowSidebarEditSleep mode="add" />
      case WorkflowAction.AddToCommunity:
        return <WorkflowSidebarAddCommunity />
      default:
        // when the Action Type doesn’t have a Sidebar Edit form, we display the
        // default tools in the sidebar.
        return <WorkflowSidebarEditFlow />
    }
  } else if (planItem === undefined) {
    // when plan item is referenced in the path that doesn’t point to an action
    // in the tree, we redirect to the default edit link.
    return <Redirect to={route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId: workflow.id })} />
  }

  return null
}
