import styled from 'styled-components'

import { UNITS_PERCENT, UNITS_SCALAR, useUnitsPreference } from '../use-units-preference'

// Unit formatting "escape hatch" function that doesn't use any hooks
// so that we can power the tooltips, which accept plain strings and not
// React elements.
export const formatUnits = (opts: {
  count: number
  total: number
  unitsPreference: string
  scalarOptions?: Intl.NumberFormatOptions
  percentOptions?: Intl.NumberFormatOptions
}): string => {
  const { unitsPreference, count, total, percentOptions, scalarOptions } = {
    ...opts,
    ...UnitDisplay.defaultProps,
  }
  switch (unitsPreference) {
    case UNITS_SCALAR:
      return count.toLocaleString('en-US', { ...scalarOptions }).toLowerCase()
    case UNITS_PERCENT:
    default:
      // Math.max to prevent division by zero errors.
      return (count / Math.max(total, 1)).toLocaleString('en-US', {
        style: 'percent',
        ...percentOptions,
      })
  }
}

type UnitDisplayProps = {
  count?: number
  total?: number
  percentOptions: Intl.NumberFormatOptions
  scalarOptions: Intl.NumberFormatOptions
  forceUnits: string
}

// Nice friendly context-enabled unit display component, can be used anywhere
// that you can render a React component without having to manually hook up
// the unitsPreference context
export const UnitDisplay = ({
  count,
  total,
  percentOptions,
  scalarOptions,
  forceUnits,
}: UnitDisplayProps): JSX.Element => {
  const { unitsPreference: fromContext } = useUnitsPreference()
  // allow for manual overrides
  const unitsPreference = forceUnits || fromContext
  return (
    <>
      {formatUnits({
        count: count || 0,
        total: total || 0,
        unitsPreference,
        scalarOptions,
        percentOptions,
      })}
    </>
  )
}

UnitDisplay.defaultProps = {
  label: 'Member',
  labelPlural: 'Members',
  percentOptions: { maximumFractionDigits: 1, minimumFractionDigits: 0 },
  scalarOptions: {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
    notation: 'compact' as const,
  },
}

type WindowProps = {
  $height: string
}

const Window = styled.span<WindowProps>`
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  height: ${({ $height }) => $height};
`

const ANIMATION_DURATION = window.location.search.includes('slomo=yes') ? '3200ms' : '800ms'

type InnerProps = {
  $shift?: boolean
  $height: number
}

const Inner = styled.span<InnerProps>`
  display: block;
  transform: translate(0, ${({ $shift, $height }) => ($shift ? '-' + $height : 0)});
  transition: transform ${ANIMATION_DURATION} cubic-bezier(0.22, 1, 0.36, 1);
`

type PaneProps = {
  $visible?: boolean
}

const Pane = styled.span<PaneProps>`
  display: block;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: opacity ${ANIMATION_DURATION} cubic-bezier(0.22, 1, 0.36, 1);
`

// Unit display with a fancy "slot-machine" animation while switching.
const UnitDisplayAnimated = ({ height, ...props }) => {
  const { unitsPreference } = useUnitsPreference()
  const shift = unitsPreference === UNITS_SCALAR

  return (
    <Window $height={height}>
      <Inner $height={height} $shift={shift}>
        <Pane $visible={!shift}>
          <UnitDisplay {...props} forceUnits={UNITS_PERCENT} />
        </Pane>
        <Pane $visible={shift}>
          <UnitDisplay {...props} forceUnits={UNITS_SCALAR} />
        </Pane>
      </Inner>
    </Window>
  )
}
UnitDisplay.Animated = UnitDisplayAnimated
