import { Entrypoint, KeywordEntrypoint, Manifest, WorkflowTrigger } from '@community_dev/workflow-manifest'
import { UseMutateAsyncFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import compact from 'lodash/compact'
import difference from 'lodash/difference'
import keyBy from 'lodash/keyBy'
import partition from 'lodash/partition'
import { useCallback, useMemo } from 'react'

import { useToastMessage } from './useToastMessage'

import { getKeywords, deleteKeywords, Keyword, KeywordsListResponse, createKeywords } from 'api/keyword'
import { getWorkflow } from 'api/workflows'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import Sentry from 'integrations/Sentry'

export type UseKeywordsHook = {
  keywords?: KeywordsListResponse
  deleteKeywordsOfWorkflow: UseMutateAsyncFunction<(string | null)[], unknown, string, unknown>
  getKeywordsTextPreview(keywords: string[]): string
  getManifestKeywords(manifest?: Manifest): string[]
  updateManifestKeywordList(previousKeywords: string[], nextKeywords: string[]): Promise<string[]>
}

export function isKeywordEntrypoint(entrypoint: Entrypoint): entrypoint is KeywordEntrypoint {
  return entrypoint.type === WorkflowTrigger.Keyword
}

export function useWorkflowKeywords(): UseKeywordsHook {
  const clientId = useClientId()
  const queryClient = useQueryClient()
  const { showToastMessage } = useToastMessage()

  const { data: remoteKeywords } = useQuery([QUERY_CACHE.KEYWORDS, { clientId }], () => getKeywords({ clientId }), {
    staleTime: STALE_TIME.FIVE_MINUTES,
    enabled: clientId !== undefined,
  })

  // for getting keywords by original text (they are unique per client)
  const keywordsByOriginalText: { [key: string]: Keyword } = useMemo(
    () => keyBy(remoteKeywords?.data, (keyword) => keyword.originalText),
    [remoteKeywords?.data],
  )

  // get all keywords of a workflow, regardless of the trigger
  const getManifestKeywords = useCallback((manifest?: Manifest): string[] => {
    return Object.values(manifest ? manifest.entrypoints : []).reduce((acc: string[], entrypoint) => {
      if (isKeywordEntrypoint(entrypoint)) {
        return acc.concat(entrypoint.params.keyword_matched.keywords)
      } else {
        return acc
      }
    }, [])
  }, [])

  // get a text preview of a list of keywords, e.g. "keyword & 2 more"
  const getKeywordsTextPreview = useCallback((keywords: string[]) => {
    if (keywords.length === 0) {
      return ''
    } else if (keywords.length === 1) {
      return keywords[0]
    } else {
      return `${keywords[0]} & ${keywords.length - 1} more`
    }
  }, [])

  const getKeywordsByOriginalText = useCallback(
    (originalKeywordTexts: string[]) => {
      return compact(originalKeywordTexts.map((keyword) => keywordsByOriginalText[keyword]))
    },
    [keywordsByOriginalText],
  )

  /**
   * A method that diffs two lists of keywords and performs deletions and
   * insertions on the server accordingly. Returns the updated list of keywords
   * excluding those that could not be created.
   *
   * There is some complexity to this method, and ultimately the server
   * endpoints should be updated to perform this work.
   */
  const updateManifestKeywordList = useCallback(
    async (previousKeywords: string[], nextKeywords: string[]) => {
      let addedKeywords: string[] = []
      // diff the lists
      const keywordsToAdd = difference(nextKeywords, previousKeywords)
      const keywordsToDelete = difference(previousKeywords, nextKeywords)
      // get ids for keywords to delete
      const keywordIdsToDelete = getKeywordsByOriginalText(keywordsToDelete).map((keyword) => keyword.id)

      try {
        // perform the api calls
        await deleteKeywords(keywordIdsToDelete, clientId)
        const additionResult = await createKeywords(keywordsToAdd, clientId)

        const [failedAdditions, successfulAdditions] = partition(
          additionResult,
          (result): result is null => result === null,
        )

        addedKeywords = successfulAdditions

        if (failedAdditions.length > 0) {
          showToastMessage({
            message: `${failedAdditions.length} Keyword(s) could not be created.`,
            success: false,
          })
        }
        queryClient.invalidateQueries([QUERY_CACHE.KEYWORDS, { clientId }])
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            keywordsToAdd,
            keywordsToDelete,
            clientId,
          },
        })
        showToastMessage({
          message: 'Some keywords could not be saved',
          success: false,
        })
      }

      // the list after the server changes. Note that we’re removing the
      // deleted keywords, regardless of whether the server call was successful.
      const keywordsAfterUpdates = difference(previousKeywords.concat(addedKeywords), keywordsToDelete)

      return keywordsAfterUpdates
    },
    [clientId, getKeywordsByOriginalText, queryClient, showToastMessage],
  )

  // for deleting all keywords attached to a workflow (necessary when deleting a workflow)
  const { mutateAsync: deleteKeywordsOfWorkflow } = useMutation(
    [QUERY_CACHE.KEYWORDS],
    async (workflowId: string) => {
      const workflow = await getWorkflow({ clientId, workflowId })
      const entrypoint = Object.values(workflow.last_manifest?.body.entrypoints || {})[0]
      if (entrypoint !== undefined && isKeywordEntrypoint(entrypoint)) {
        const manifestKeywords = entrypoint.params.keyword_matched.keywords
        // delete all keywords that have an ID
        const keywordIds = getKeywordsByOriginalText(manifestKeywords).map((keyword) => 'id' in keyword && keyword.id)
        return await deleteKeywords(compact(keywordIds), clientId)
      } else {
        return []
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([QUERY_CACHE.KEYWORDS, { clientId }])
      },
    },
  )

  return {
    keywords: remoteKeywords,
    getManifestKeywords,
    updateManifestKeywordList,
    deleteKeywordsOfWorkflow,
    getKeywordsTextPreview,
  }
}
