import { BORDER_RADIUS, BUTTON_VARIANTS, Button, SPACING } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'

import { useSearchState } from '../SearchProvider'

import { SearchDayPickerRange } from './SearchDayPickerRange'

import { CAPABILITIES } from 'constants/capabilities'
import { DATE_INPUT_FORMAT } from 'constants/date'
import { useHasCapability } from 'hooks/useUserCapability'
import dayjs from 'utils/dayjs'

const StyledSearchDayPicker = styled.div`
  display: table;
  position: relative;
`

const StyledTimeRangeButtons = styled.div`
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: 30px;
  overflow: hidden;
`

const StyledTimeRangeButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.OUTLINE,
})<{ $active: boolean }>`
  min-width: 0;
  padding: ${SPACING[1]} ${SPACING[2]};
  border: none;
  border-radius: 0;
  &:first-child {
    padding: ${SPACING[1]} ${SPACING[2]} ${SPACING[1]} ${SPACING[4]};
  }
  &:last-child {
    padding: ${SPACING[1]} ${SPACING[4]} ${SPACING[1]} ${SPACING[2]};
  }
  ${({ $active }) =>
    $active &&
    css`
      background: rgba(30, 144, 255, 0.16);
      color: ${({ theme }) => theme?.COLORS?.TEXT};
    `}
`

const StyledCalendarIcon = styled(FontAwesomeIcon)`
  margin: 0 ${SPACING[2]} 0 0;
`

const StyledSearchDayPickerRange = styled(SearchDayPickerRange)`
  position: absolute;
  top: calc(100% + ${SPACING[2]});
  left: 0;
  border: 1px ${({ theme }) => theme?.COLORS?.BORDERS} solid;
  border-radius: ${BORDER_RADIUS[2]};
  overflow: hidden;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  box-shadow: ${({ theme }) => theme.SHADOWS.CARD};

  .CalendarMonth_caption {
    padding-bottom: ${SPACING[8]};
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  }
  .DayPicker {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
    box-shadow: none;
  }
  .DayPicker_weekHeader {
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
    font-weight: 600;
    color: ${({ theme }) => theme?.COLORS?.TEXT};
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }
  .DayPickerNavigation_button {
    border: none;
  }
  .CalendarMonthGrid {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }
  .CalendarDay__default {
    color: ${({ theme }) => theme?.COLORS?.TEXT};
    border: none;
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }
  .CalendarDay__selected {
    background: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
    border: none;
    &:active,
    &:hover {
      background: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
      border: none;
    }
  }
  .CalendarDay__before_hovered_end,
  .CalendarDay__after_hovered_start {
    background: inherit;
  }
  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    background: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
    color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY_TEXT};
  }
  .CalendarDay__selected_start {
    border-radius: ${BORDER_RADIUS[1]} 0 0 ${BORDER_RADIUS[1]};
  }
  .CalendarDay__selected_end {
    border-radius: 0 ${BORDER_RADIUS[1]} ${BORDER_RADIUS[1]} 0;
    &.CalendarDay__selected_start {
      border-radius: ${BORDER_RADIUS[1]};
    }
  }
  .CalendarDay__selected_span {
    color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
    background: rgba(30, 144, 255, 0.08);
    &:active,
    &:hover {
      border: none;
      background: ${({ theme }) => theme?.COLORS?.DIVIDERS};
      color: ${({ theme }) => theme?.COLORS?.TEXT};
    }
  }
  .CalendarDay__outside {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }
  .CalendarDay__blocked_out_of_range {
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  }
  .CalendarMonth {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }
`

type SearchDayPickerProps = {
  className?: string
  children?: React.ReactNode
}

export function SearchDayPicker({ ...props }: SearchDayPickerProps): JSX.Element {
  const searchDayPickerEl = useRef(null)
  const [isDateRangePickerVisible, setIsDateRangePickerVisible] = useState(false)
  const { changeParams, start, end } = useSearchState()
  const isFTSOver90DaysEnabled = useHasCapability(CAPABILITIES.FEATURE.FULL_TEXT_SEARCH.OVER_90_DAYS)
  const isToday = dayjs().isSame(start, 'day') && dayjs().isSame(end, 'day')
  const isSixMonths = dayjs().subtract(6, 'months').isSame(start, 'day') && dayjs().isSame(end, 'day')
  const is90Days = dayjs().subtract(90, 'days').isSame(start, 'day') && dayjs().isSame(end, 'day')
  const is60Days = dayjs().subtract(60, 'days').isSame(start, 'day') && dayjs().isSame(end, 'day')
  const is30Days = dayjs().subtract(30, 'days').isSame(start, 'day') && dayjs().isSame(end, 'day')
  const isCustom = !isToday && !isSixMonths && !is90Days && !is60Days && !is30Days

  const handleClickOutsideSearchDayPicker = (): void => {
    setIsDateRangePickerVisible(false)
  }

  useOnClickOutside(searchDayPickerEl, handleClickOutsideSearchDayPicker)

  const toggleDateRangePicker = () => {
    setIsDateRangePickerVisible(!isDateRangePickerVisible)
  }

  const handleClickToday = () => {
    setIsDateRangePickerVisible(false)

    changeParams({
      start: dayjs().startOf('day').toISOString(),
      end: dayjs().endOf('day').toISOString(),
    })
  }

  const handleClickSixMonths = () => {
    setIsDateRangePickerVisible(false)

    changeParams({
      start: dayjs().subtract(6, 'month').startOf('day').toISOString(),
      end: dayjs().endOf('day').toISOString(),
    })
  }

  const handleClickNinetyDays = () => {
    setIsDateRangePickerVisible(false)

    changeParams({
      start: dayjs().subtract(90, 'days').startOf('day').toISOString(),
      end: dayjs().endOf('day').toISOString(),
    })
  }

  const handleClickSixtyDays = () => {
    setIsDateRangePickerVisible(false)

    changeParams({
      start: dayjs().subtract(60, 'days').startOf('day').toISOString(),
      end: dayjs().endOf('day').toISOString(),
    })
  }

  const handleClickThirtyDays = () => {
    setIsDateRangePickerVisible(false)

    changeParams({
      start: dayjs().subtract(30, 'days').startOf('day').toISOString(),
      end: dayjs().endOf('day').toISOString(),
    })
  }

  const handleClickSetDate = () => {
    toggleDateRangePicker()
  }

  return (
    <StyledSearchDayPicker ref={searchDayPickerEl} {...props}>
      <StyledTimeRangeButtons>
        <StyledTimeRangeButton $active={isCustom} onClick={toggleDateRangePicker}>
          <StyledCalendarIcon icon="calendar-alt" /> Custom
          {isCustom && (
            <>
              {' '}
              {dayjs(start).format(DATE_INPUT_FORMAT)} <FontAwesomeIcon icon="arrow-right" />{' '}
              {dayjs(end).format(DATE_INPUT_FORMAT)}
            </>
          )}
        </StyledTimeRangeButton>
        <StyledTimeRangeButton $active={isToday} onClick={handleClickToday}>
          Today
        </StyledTimeRangeButton>
        {isFTSOver90DaysEnabled && (
          <StyledTimeRangeButton $active={isSixMonths} onClick={handleClickSixMonths}>
            6m
          </StyledTimeRangeButton>
        )}
        <StyledTimeRangeButton $active={is90Days} onClick={handleClickNinetyDays}>
          90d
        </StyledTimeRangeButton>
        <StyledTimeRangeButton $active={is60Days} onClick={handleClickSixtyDays}>
          60d
        </StyledTimeRangeButton>
        <StyledTimeRangeButton $active={is30Days} onClick={handleClickThirtyDays}>
          30d
        </StyledTimeRangeButton>
      </StyledTimeRangeButtons>
      {isDateRangePickerVisible && (
        <StyledSearchDayPickerRange
          onClickCancel={toggleDateRangePicker}
          onClickSetDate={handleClickSetDate}
          renderKeyboardShortcutsButton={() => null}
        />
      )}
    </StyledSearchDayPicker>
  )
}
