import {
  BaseRequestErrorHandler,
  BaseRequestOptions,
  ErrorObject,
  HTTP,
  request as baseRequest,
} from '@community_dev/requests'
import { matchPath } from 'react-router'

import { SET_DIALOG } from 'constants/dialog'
import { ENDPOINTS } from 'constants/endpoints'
import { ROUTES } from 'constants/routes'
import { AUTH_TOKENS, CLIENT_ID } from 'constants/web-storage'
import Sentry from 'integrations/Sentry'

const flattenEndpoints = (endpoints: Record<string, any>) => {
  return Object.values(endpoints)
    .map((endpoint) => {
      if (typeof endpoint === 'string') {
        return endpoint
      }

      return flattenEndpoints(endpoint)
    })
    .flat()
}

const findEndpointFromUrl = (url: string) => {
  const flattenedEndpoints = flattenEndpoints(ENDPOINTS)
  const urlObject = new URL(url)

  return flattenedEndpoints.find(
    (endpoint) =>
      matchPath(urlObject.pathname, { path: endpoint }) ||
      matchPath(urlObject.pathname + urlObject.search, { path: endpoint }),
  )
}

export const formatError = (response: Response): Error => {
  const endpoint = findEndpointFromUrl(response.url)

  let message = `${response.status}`

  if (endpoint) {
    message += ` - ${endpoint}`
  }

  return new Error(message)
}

function wait(timeout) {
  return new Promise((resolve) => {
    setTimeout(() => {
      // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
      resolve()
    }, timeout)
  })
}

function getTokenValue() {
  const authTokens = getTokens()
  const clientId = sessionStorage.getItem(CLIENT_ID) || JSON.parse(localStorage.getItem(CLIENT_ID) || '""')
  const token = sessionStorage.getItem(AUTH_TOKENS) || (clientId ? authTokens[clientId] : undefined)
  if (token === '' && token === undefined) {
    throw new Error('TOKEN REQUIRED')
  }
  return token
}

export const getTokens = (): any => {
  return JSON.parse(localStorage.getItem(AUTH_TOKENS) || '{}')
}

export async function getAsyncAuthToken(): Promise<any> {
  const MAX_RETRIES = 5
  for (let i = 0; i <= MAX_RETRIES; i += 1) {
    try {
      return await getTokenValue()
    } catch (err) {
      await wait(200)
    }
  }
}

export function getBaseUrl(url: string | undefined = import.meta.env.VITE_API_URL): string {
  return url === 'undefined' || !url ? '' : url
}

export type RequestOptions<T> = BaseRequestOptions<T> & {
  baseUrl?: string
  noAuth?: boolean
  skipAuthCheck?: boolean
  ignoreReportingErrorCodes?: number[]
  token?: string
}

export async function request<TResponse = any, TRequestBody = any>(
  path: string,
  options: RequestOptions<TRequestBody> = {},
): Promise<TResponse> {
  const { baseUrl, noAuth, skipAuthCheck, ignoreReportingErrorCodes = [], ...fetchOptions } = options
  const token = options.token || (!noAuth && (getTokenValue() || (await getAsyncAuthToken())))
  const headers = {
    ...(!noAuth && { Authorization: `Bearer ${token}` }),
    ...options.headers,
  }

  function createOnError(noAuth, skipAuthCheck): BaseRequestErrorHandler {
    return function onError(message, errors, response) {
      if (!noAuth && !skipAuthCheck && response.status === 401)
        window.location.assign(`${import.meta.env.VITE_PUBLIC_URL}${ROUTES.LOGOUT}`)
      if (!noAuth && !skipAuthCheck && response.status === 403) {
        const capabilityMissing = errors?.find((error: ErrorObject) => error.type === 'capability_missing')
        if (capabilityMissing?.detail) {
          const event = new CustomEvent(SET_DIALOG, {
            detail: {
              title: 'Permission Denied',
              message: capabilityMissing.detail,
            },
          })
          window.dispatchEvent(event)
        }
      }
      if (response.status >= 400 && !ignoreReportingErrorCodes.includes(response.status)) {
        Sentry.captureException(formatError(response), {
          tags: {
            'api.base_url': baseUrl,
            'api.path': path,
            'api.method': fetchOptions.method || HTTP.GET,
          },
          extra: {
            message,
            errors,
            response,
          },
        })
      }
    }
  }

  return baseRequest(`${getBaseUrl(baseUrl)}${path}`, {
    ...fetchOptions,
    headers,
    onError: createOnError(noAuth, skipAuthCheck),
  })
}
