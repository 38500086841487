import noop from 'lodash/noop'
import React, { createContext, useContext, useRef, useState, ReactNode, useEffect } from 'react'

interface ConvoContextType {
  hideAiSuggestions: boolean
  replyBody: string
  replyInput: React.RefObject<HTMLTextAreaElement> | null
  usedAiSuggestion: boolean
  setHideAiSuggestions: React.Dispatch<React.SetStateAction<boolean>>
  setReplyBody: React.Dispatch<React.SetStateAction<string>>
  setUsedAiSuggestion: React.Dispatch<React.SetStateAction<boolean>>
}

const ConvoContext = createContext<ConvoContextType>({
  hideAiSuggestions: false,
  replyBody: '',
  replyInput: null,
  usedAiSuggestion: false,
  setHideAiSuggestions: noop,
  setReplyBody: noop,
  setUsedAiSuggestion: noop,
})

const ConvoProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [replyBody, setReplyBody] = useState('')
  const [hideAiSuggestions, setHideAiSuggestions] = useState(false)
  const [usedAiSuggestion, setUsedAiSuggestion] = useState(false)
  const replyInput = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (!replyBody) {
      setHideAiSuggestions(false)
    }
  }, [replyBody])

  useEffect(() => {
    if (!replyBody && usedAiSuggestion) {
      setUsedAiSuggestion(false)
    }
  }, [replyBody, usedAiSuggestion])

  return (
    <ConvoContext.Provider
      value={{
        hideAiSuggestions,
        replyBody,
        replyInput,
        usedAiSuggestion,
        setHideAiSuggestions,
        setReplyBody,
        setUsedAiSuggestion,
      }}
    >
      {children}
    </ConvoContext.Provider>
  )
}

function useConvo(): ConvoContextType {
  const context = useContext(ConvoContext)
  if (!context) {
    throw new Error('useConvo must be used within a ConvoProvider')
  }
  return context
}

export { ConvoProvider, useConvo }
