import { BUTTON_VARIANTS, Button, CheckBox, Dropdown, FONT_SIZE, SPACING, TextInput } from '@community_dev/pixels'
import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { EmbedPathRule, EmbedPathRuleMatchType } from 'api/mgu'
import { CloseIconFilled } from 'screens/SettingsScreen/components/EmbedSettings/Icons'

type PathRuleSetProps = {
  selectType: EmbedPathRule['selectType']
  rules: EmbedPathRule[]
  label: string
  onChange(rules: EmbedPathRule[])
}

const StyledCheckboxLabel = styled.label`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  margin-left: ${SPACING[1]};
  display: flex;
  align-items: center;
  font-size: ${FONT_SIZE[3]};

  & > input {
    margin-right: ${SPACING[2]};
  }
`

const StyledInputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  > *:not(:last-child) {
    padding-right: ${SPACING[4]};
  }
`

const StyledDropdown = styled.div`
  width: 215px;
`

const StyledCloseIcon = styled.div`
  align-self: center;
`

const StyledTextInput = styled(TextInput)`
  ::placeholder {
    color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT_PLACEHOLDER};
  }
`

export const PathRuleSet = ({ rules, label, selectType, onChange }: PathRuleSetProps): JSX.Element | null => {
  const { COLORS } = useTheme() || {}
  const [enabled, setEnabled] = useState(Boolean(rules.length))
  const [cachedPathRules, setCachedPathRules] = useState<EmbedPathRule[]>([])

  const handleToggle = () => {
    setEnabled(!enabled)
    if (enabled) {
      setCachedPathRules(rules)
      onChange([])
    } else {
      const rules = cachedPathRules.length
        ? cachedPathRules
        : [{ selectType, matchType: EmbedPathRuleMatchType.contains, path: '' }]
      onChange(rules)
    }
  }

  const removePathRule = (index: number) => {
    if (rules.length <= 1) {
      setEnabled(false)
    }
    onChange(rules.filter((r, i) => i !== index))
  }

  const addPathRule = () => {
    onChange([...rules, { selectType, path: '', matchType: EmbedPathRuleMatchType.contains }])
  }

  const changePathRule = (index: number, update: Partial<EmbedPathRule>) => {
    onChange([...rules.slice(0, index), { ...rules[index], ...update }, ...rules.slice(index + 1)])
  }

  const matchOptions = [
    { value: EmbedPathRuleMatchType.contains, label: 'Contains' },
    { value: EmbedPathRuleMatchType.exact, label: 'Exact Match' },
  ]

  return (
    <>
      <StyledCheckboxLabel>
        <CheckBox onChange={handleToggle} selected={enabled} />
        {label}
      </StyledCheckboxLabel>
      {enabled && (
        <>
          {rules.map((rule, index) => (
            <StyledInputRow key={index}>
              <StyledDropdown>
                <Dropdown
                  aria-label="Rule match type"
                  name={`match-type-${index}`}
                  onChange={(v) => changePathRule(index, { matchType: v.value })}
                  options={matchOptions}
                  value={matchOptions.find((o) => o.value === rule.matchType)}
                />
              </StyledDropdown>
              <StyledTextInput
                //label="/page-title"
                aria-label="Rule path"
                onChange={(e) => changePathRule(index, { path: e.target.value })}
                placeholder="/example"
                value={rule.path}
              />
              <StyledCloseIcon>
                <Button aria-label="Remove rule" onClick={() => removePathRule(index)} variant={BUTTON_VARIANTS.TEXT}>
                  <CloseIconFilled />
                </Button>
              </StyledCloseIcon>
            </StyledInputRow>
          ))}
          <Button color={COLORS?.TEXT} onClick={addPathRule} variant={BUTTON_VARIANTS.OUTLINE}>
            + Add URL
          </Button>
        </>
      )}
    </>
  )
}
