import { UndoableReducerControl } from '@community_dev/hooks'
import {
  Button,
  BUTTON_VARIANTS,
  FONT_SIZE,
  Layout,
  Menu,
  SPACING,
  Tooltip,
  Typography,
  ArrowLeftIcon,
} from '@community_dev/pixels'
import { MediaProcessingStatus } from '@community_dev/types/lib/api/v2/Media'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import styled, { useTheme } from 'styled-components'

import IconMore from '../../svg/more.svg?react'
import IconRedo from '../../svg/redo.svg?react'
import IconUndo from '../../svg/undo.svg?react'
import { WorkflowEditName } from '../WorkflowEditName/WorkflowEditName'

import { PauseResumeScheduleButton } from './PauseResumeScheduleButton'

import { useWorkflowProvider, WorkflowState } from 'components/WorkflowEditor/context/WorkflowProvider'
import { useRemoteWorkflow } from 'components/WorkflowEditor/hooks/useRemoteWorkflow'
import { useScheduleWorkflow } from 'components/WorkflowEditor/hooks/useScheduleWorkflow'
import { shouldAllowScheduling } from 'components/WorkflowEditor/utils/shouldAllowScheduling'
import { toWorkflowStatus } from 'components/WorkflowEditor/utils/toWorkflowStatus'
import { ROUTES } from 'constants/routes'
import useBreakpoints from 'hooks/useBreakpoints'
import { WorkflowStatusTypes } from 'screens/WorkflowsScreen/WorkflowStatus'

export const PublishButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.PRIMARY,
  width: '100px',
})``

export const CancelButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.PILL,
})``

export const StyledHistoryButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.PILL,
  width: '35px',
})`
  padding: 0;
  min-width: auto;
`

export const StyledWorkflowToolbar = styled.div`
  padding: ${SPACING[2]} ${SPACING[4]};
  width: 100%;
  display: flex;
  align-items: space-between;
  transition: all 0.2s;
  align-items: center;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
`

export type WorkflowToolbarProps = {
  isChanged: boolean
  isSaving: boolean
  workflowHistory: UndoableReducerControl<WorkflowState>
  onCancel: () => void
  onSave: () => void
}

export function WorkflowToolbar({
  isChanged,
  isSaving,
  workflowHistory,
  onCancel,
  onSave,
}: WorkflowToolbarProps): JSX.Element {
  const { t } = useTranslation()
  const { md } = useBreakpoints()
  const { COLORS } = useTheme()
  const { mediaUpload, workflow } = useWorkflowProvider()

  const isMediaProcessing = useMemo(() => {
    return Boolean(
      Array.from(mediaUpload.entries()).find(
        ([, { media }]) =>
          media.status === MediaProcessingStatus.PROCESSING || media.status === MediaProcessingStatus.UPLOAD_URL_ISSUED,
      ),
    )
  }, [mediaUpload])

  const isMediaErrored = useMemo(() => {
    return Boolean(
      Array.from(mediaUpload.entries()).find(([, { media }]) => media.status === MediaProcessingStatus.ERRORED),
    )
  }, [mediaUpload])

  const isRootWorkflowEditorRoute = useRouteMatch([ROUTES.AUTOMATIONS.FLOW_EDIT])?.isExact

  const shouldShowScheduling = shouldAllowScheduling(workflow)
  const isWorkflowActive = toWorkflowStatus(workflow) === WorkflowStatusTypes.active

  const { refetch } = useRemoteWorkflow()
  const { onPause, onResume } = useScheduleWorkflow({ workflow, onSuccess: refetch })

  return (
    <StyledWorkflowToolbar>
      <StyledHistoryButton aria-label={t('automations.backToAllFlows')} onClick={onCancel}>
        <Layout alignItems="center" display="flex" height="32px" justifyContent="center">
          <ArrowLeftIcon size={13} />
        </Layout>
      </StyledHistoryButton>
      <WorkflowEditName style={{ marginLeft: '24px', flex: 1 }} />
      <Layout flexGrow="1" />
      {md ? (
        <>
          <Tooltip
            content={workflowHistory.canUndo ? t('automations.undo') : t('automations.nothingToUndo')}
            placement="bottom"
          >
            <span style={{ marginRight: SPACING[2] }}>
              <StyledHistoryButton
                aria-label={t('automations.undo')}
                disabled={!workflowHistory.canUndo}
                onClick={workflowHistory.undo}
              >
                <IconUndo
                  fill={workflowHistory.canUndo ? COLORS.BUTTON_DEFAULT_TEXT : COLORS.BUTTON_DISABLED_TEXT}
                  width={16}
                />
              </StyledHistoryButton>
            </span>
          </Tooltip>
          <Tooltip
            content={workflowHistory.canRedo ? t('automations.redo') : t('automations.nothingToRedo')}
            placement="bottom"
          >
            <span style={{ marginRight: SPACING[4] }}>
              <StyledHistoryButton
                aria-label={t('automations.redo')}
                disabled={!workflowHistory.canRedo}
                onClick={workflowHistory.redo}
              >
                <IconRedo
                  fill={workflowHistory.canRedo ? COLORS.BUTTON_DEFAULT_TEXT : COLORS.BUTTON_DISABLED_TEXT}
                  width={16}
                />
              </StyledHistoryButton>
            </span>
          </Tooltip>
          {shouldShowScheduling ? <PauseResumeScheduleButton /> : null}
          <Tooltip
            content={
              !isChanged && !isSaving ? (
                <Typography fontSize={FONT_SIZE[2]} margin={`0 ${SPACING[2]}`}>
                  {t('automations.yourChangesHaveAlreadyBeenPublished')}
                </Typography>
              ) : undefined
            }
            placement="bottom"
          >
            <span>
              <PublishButton
                disabled={isMediaProcessing || isMediaErrored || !isChanged || isSaving || !isRootWorkflowEditorRoute}
                onClick={onSave}
              >
                {isSaving ? t('automations.saving') : t('automations.publish')}
              </PublishButton>
            </span>
          </Tooltip>
        </>
      ) : (
        <Menu
          trigger={
            <Button variant={BUTTON_VARIANTS.PILL}>
              <IconMore />
            </Button>
          }
        >
          <Menu.Item disabled={!workflowHistory.canUndo} onClick={workflowHistory.undo}>
            {t('automations.undo')}
          </Menu.Item>
          <Menu.Item disabled={!workflowHistory.canRedo} onClick={workflowHistory.redo}>
            {t('automations.redo')}
          </Menu.Item>
          {shouldShowScheduling ? (
            <Menu.Item onClick={isWorkflowActive ? onPause : onResume}>
              {isWorkflowActive ? t('automations.scheduled.pause') : t('automations.scheduled.resume')}
            </Menu.Item>
          ) : null}
          <Menu.Item disabled={!isChanged || isSaving} onClick={onSave}>
            {t('automations.publish')}
          </Menu.Item>
        </Menu>
      )}
    </StyledWorkflowToolbar>
  )
}
