import { EditableText } from '@community_dev/pixels'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { usePatchWorkflow } from 'components/WorkflowEditor/hooks/usePatchWorkflow'
import { WORKFLOW_ID_UNSAVED } from 'constants/workflow'
import { useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import Sentry from 'integrations/Sentry'

export type WorkflowEditNameProps = {
  className?: string
  style?: React.CSSProperties
}

export function WorkflowEditName(props: WorkflowEditNameProps): JSX.Element | null {
  const { t } = useTranslation()
  const { workflow, dispatch, isLoading } = useWorkflowProvider()
  const { className, style } = props

  const clientId = useClientId()
  const { showToastMessage } = useToastMessage()

  const { mutateAsync: patchWorkflowMutation } = usePatchWorkflow({
    onSuccess: () => {
      showToastMessage({
        message: t('automations.updatedFlowName'),
        position: 'bottom-right',
      })
    },
    onError: (error) => {
      showToastMessage({
        message: t('automations.failedToUpdateFlowName'),
        success: false,
        position: 'bottom-right',
      })

      Sentry.captureException(error)
    },
  })

  const handleSave = useCallback(
    (workflowName: string) => {
      // only update if there is a name, an id and the workflow name has changed.
      if (workflowName && workflowName !== workflow?.name) {
        if (workflow?.id === WORKFLOW_ID_UNSAVED) {
          dispatch({
            type: 'update',
            state: {
              workflow: {
                ...workflow,
                name: workflowName,
              },
            },
          })
        } else {
          // if it’s a saved workflow, we update the name immediately.
          patchWorkflowMutation({
            clientId,
            workflowId: workflow.id,
            body: {
              name: workflowName,
            },
          })
        }
      }
    },
    [clientId, workflow, dispatch, patchWorkflowMutation],
  )

  if (isLoading) {
    return null
  }

  return (
    <EditableText
      className={className}
      defaultValue={workflow?.name}
      onSave={handleSave}
      saveOnClickOutside
      style={style}
      titleComponent="h1"
    />
  )
}
