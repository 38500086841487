import Color from 'color'
import { useCallback } from 'react'
import { useTheme } from 'styled-components'

type rgba = { r: number; g: number; b: number; alpha?: number }

const calculateGradient = (
  rangeStart: number,
  rangeEnd: number,
  colorStart: rgba,
  colorEnd: rgba,
  value: number,
): string => {
  const percent = (value - rangeStart) / (rangeEnd - rangeStart)
  const color = {
    r: colorStart.r + Math.floor((colorEnd.r - colorStart.r) * percent),
    g: colorStart.g + Math.floor((colorEnd.g - colorStart.g) * percent),
    b: colorStart.b + Math.floor((colorEnd.b - colorStart.b) * percent),
  }

  return `rgb(${color.r}, ${color.g}, ${color.b})`
}

type ColorFn = (value: number) => string

export const useDataColorGradient = (from: number, to: number): ColorFn => {
  const {
    COLORS: { DATA_TO, DATA_FROM },
  } = useTheme()

  const toGradientColor = useCallback(
    (value: number) => {
      const fromColor = Color(DATA_FROM).object()
      const toColor = Color(DATA_TO).object()

      if (!fromColor || !toColor) return DATA_TO
      return calculateGradient(from, to, fromColor as rgba, toColor as rgba, value)
    },
    [DATA_FROM, DATA_TO, from, to],
  )

  return toGradientColor
}
