import { BUTTON_VARIANTS, Button, Image, ModalGuidedTour } from '@community_dev/pixels'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { ComposeRole, useComposeRole } from 'components/ComposeMessage/hooks/useComposeRole'

const StyledImage = styled(Image)`
  display: block;
  width: 100%;
  height: 372px;
`

const StyledContent = styled.div`
  width: 100%;
`

const StyledDescription = styled.p`
  margin: 0;
  padding: 40px 32px 24px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.176px;
`
const EditMessageButton = styled(Button)`
  margin-bottom: 33px;
`

type LinkWarningModalProps = {
  isOpen: boolean
  mediaDisposition: MediaDisposition
  onCancel: () => void
  onSend: () => void
}

const LinkWarningModal = ({ isOpen, mediaDisposition, onCancel, onSend }: LinkWarningModalProps): JSX.Element => {
  const { t } = useTranslation()
  const { COLORS } = useTheme() || {}
  const { isMediaAttached } = useCompose()
  const isCampaignDrafter = useComposeRole() === ComposeRole.CAMPAIGN_DRAFTER

  return (
    <ModalGuidedTour isOpen={isOpen} onClose={onCancel} shouldDismissOnBodyClick={false}>
      <ModalGuidedTour.Slide
        footer={({ onDone }) => {
          const handleSend = () => {
            onSend()
            onDone()
          }

          return (
            <>
              <EditMessageButton onClick={onDone} variant={BUTTON_VARIANTS.ACTION} width="100%">
                {t('compose.editMessage')}
              </EditMessageButton>
              <Button color={COLORS?.ERRORS} onClick={handleSend} variant={BUTTON_VARIANTS.LINK} width="100%">
                {isCampaignDrafter ? t('compose.submitForApprovalAnyway') : t('compose.sendAnyway')}
              </Button>
            </>
          )
        }}
        heading={t('compose.linkPreviewMayNotGenerate')}
      >
        <StyledContent>
          <StyledImage src={`${import.meta.env.VITE_WEB_ASSETS_URL}/img-link-warning.png`} />
          {mediaDisposition === MediaDisposition.ATTACHMENT && isMediaAttached ? (
            <StyledDescription>{t('compose.linkWarningMms')}</StyledDescription>
          ) : (
            <StyledDescription>{t('compose.linkWarningSms')}</StyledDescription>
          )}
        </StyledContent>
      </ModalGuidedTour.Slide>
    </ModalGuidedTour>
  )
}

LinkWarningModal.prototypes = {
  onCancel: PropTypes.func,
  onSend: PropTypes.func,
}

export default LinkWarningModal
