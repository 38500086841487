import { useMutation } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { useValidateToken } from './useValidateToken'

import { putChangePassword } from 'api/login'
import { ROUTES } from 'constants/routes'
import { pathParams } from 'utils/router'

type ChangePasswordReturn = {
  token: string
  email: string
  isSubmitting: boolean
  isSuccess: boolean
  error: string
  isValidating: boolean
  onSubmit: any
}

export const useChangePassword = (): ChangePasswordReturn => {
  const history = useHistory()
  const [error, setError] = useState('')
  const { pathname } = useLocation()
  const { token: pathToken } = pathParams(pathname, {
    path: '/reset-password/:token',
  })
  const onError = useCallback(() => {
    history.replace(ROUTES.RESET_PASSWORD)
  }, [history])

  const {
    isLoading: isValidating,
    token,
    email,
  } = useValidateToken({
    token: pathToken,
    onError,
  })

  const {
    mutate: changePassword,
    isLoading: isSubmitting,
    isSuccess,
  } = useMutation(putChangePassword, {
    onSuccess() {
      history.push(ROUTES.HOME)
    },
    onError(err: any) {
      setError(err.details)
    },
  })

  const onSubmit = useCallback(
    ({ password }) => {
      changePassword({ password, token })
    },
    [changePassword, token],
  )

  return {
    token,
    email,
    onSubmit,
    isSubmitting,
    isSuccess,
    error,
    isValidating,
  }
}
