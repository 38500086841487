import { FONT_SIZE, FONT_WEIGHT, SPACING } from '@community_dev/pixels'
import { ReactNode } from 'react'
import styled from 'styled-components'

const StyledMessageBubble = styled.div`
  height: ${SPACING[6]};
  width: ${SPACING[6]};
  border-radius: ${SPACING[4]};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
  color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  font-size: ${FONT_SIZE[3]};
  font-weight: ${FONT_WEIGHT[5]};
`

type MessageBubbleProps = {
  children?: ReactNode
}

export const MessageBubble = ({ children }: MessageBubbleProps): JSX.Element => {
  if (typeof children === 'string') {
    return <StyledMessageBubble>{children.substring(0, 3)}</StyledMessageBubble>
  }
  return <StyledMessageBubble>{children}</StyledMessageBubble>
}
