import { ThemeColors } from '@community_dev/pixels'
import { useTheme } from 'styled-components'

import { useSubscription } from './useSubscription'

import { ENTERPRISE_BILLING_PLAN_CODES } from 'constants/plans'

const toPlanColor = (planCode?: string, COLORS?: ThemeColors): string | undefined => {
  const PLAN_COLORS = {
    [ENTERPRISE_BILLING_PLAN_CODES.EARLY_STAGE]: COLORS?.BILLING_PLAN_NAME_EARLY_STAGE,
    [ENTERPRISE_BILLING_PLAN_CODES.SMALL_BUSINESS]: COLORS?.BILLING_PLAN_NAME_SMALL_BUSINESS,
    [ENTERPRISE_BILLING_PLAN_CODES.MEDIUM_BUSINESS]: COLORS?.BILLING_PLAN_NAME_MEDIUM_BUSINESS,
  }
  return planCode ? PLAN_COLORS[planCode] : undefined
}

export const usePlanColor = (): { color?: string; isLoading: boolean } => {
  const { COLORS } = useTheme()
  const { data, isInitialLoading: isLoading } = useSubscription()

  const planCode = data?.subscription.planCode

  return { color: toPlanColor(planCode, COLORS), isLoading }
}
