import { Option, SingleValue, Select, DropdownArrowIcon, SPACING } from '@community_dev/pixels'
import React from 'react'
import { ReactNode, useMemo } from 'react'
import styled, { css } from 'styled-components'

export type SelectWithIconProps<Value = unknown> = Omit<React.ComponentProps<typeof Select>, 'options' | 'onChange'> & {
  options: SelectWithIconOption<Value>[]
  disabled?: boolean
  value: Value
  onChange: (c: Value) => void
  compact?: boolean
  menuName?: string
}

const StyledIconContainer = styled.div`
  display: inline-block;
  width: 28px;
  text-align: center;
  margin-left: -3px;
`

const StyledSelect = styled(Select)<{ $compact: boolean }>`
  .select-with-icon-control {
    border-radius: ${({ $compact }) => ($compact ? '20px' : '4px')};
    background-color: ${({ theme }) => theme.COLORS.INPUT_BACKGROUND};
    padding-left: ${SPACING[1]};
    height: ${({ $compact }) => ($compact ? '34px' : '42px')};
    min-height: ${({ $compact }) => ($compact ? '34px' : 'auto')};
    width: ${({ $compact }) => ($compact ? '60px' : 'auto')};
    min-width: ${({ $compact }) => ($compact ? '60px' : 'auto')};
  }
  ${({ $compact }) =>
    $compact &&
    css`
      .select-with-icon-select-value {
        padding-right: 0 !important;
        padding-left: 2px !important;
      }
      .select-with-icon-select-indicator {
        padding-left: 0 !important;
        svg {
          margin-right: 0 !important;
        }
      }
      .select-with-icon-select-menu {
        width: max-content;
        min-width: 100%;
      }
    `}
`

export type SelectWithIconOption<Value = unknown> = {
  label: ReactNode
  value: Value
  icon: ReactNode
}

export function SelectWithIcon<Value = unknown>({
  value,
  onChange,
  disabled = false,
  compact = false,
  classNames,
  components,
  options,
  className,
  menuName,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  theme, // not used, but needs to be removed from rest since Select has a theme prop and so does styled-components
  ...rest
}: SelectWithIconProps<Value>): JSX.Element | null {
  const selectedOption = useMemo(
    () => (options as SelectWithIconOption<Value>[]).find((o) => o.value === value),
    [value, options],
  )

  return (
    <StyledSelect
      $compact={compact}
      inline={false}
      {...rest}
      blurInputOnSelect={true}
      className={className ? `select-with-icon ${className}` : 'select-with-icon'}
      classNames={{
        control: () => `select-with-icon-control${menuName ? ` ${menuName}-control` : ''}`,
        indicatorsContainer: () =>
          `select-with-icon-select-indicator${menuName ? ` ${menuName}-select-indicator` : ''}`,
        valueContainer: () => `select-with-icon-select-value${menuName ? ` ${menuName}-select-value` : ''}`,
        menu: () => `select-with-icon-select-menu${menuName ? ` ${menuName}-select-menu` : ''}`,
        ...classNames,
      }}
      components={{
        SingleValue: (props) => (
          <SingleValue {...props}>
            <StyledIconContainer>{(props.data as SelectWithIconOption<Value>).icon}</StyledIconContainer>
            {compact ? <span className="sr-only">{props.children}</span> : props.children}
          </SingleValue>
        ),
        Option: (props) => (
          <Option {...props}>
            <StyledIconContainer>{(props.data as SelectWithIconOption<Value>).icon}</StyledIconContainer>
            {props.children}
          </Option>
        ),
        IndicatorSeparator: null,
        DropdownIndicator: () => <DropdownArrowIcon style={{ marginRight: '10px' }} />,
        ...components,
      }}
      isDisabled={disabled}
      onChange={(option: unknown) => {
        option !== null && onChange((option as SelectWithIconOption<Value>).value)
      }}
      options={options}
      value={selectedOption}
    />
  )
}

export const MemoizedSelectWithIcon = React.memo(SelectWithIcon) as typeof SelectWithIcon
