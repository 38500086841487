import { ActionButton, ActionButtonProps } from '@community_dev/pixels'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { useFavoriteCommunity } from '../useFavoriteCommunity'

import { Fan } from 'api/fans'
import {
  useAddFanToCommunity,
  useRemoveFanFromCommunity,
} from 'containers/InboxList/MemberDetailActionPanel/member-detail-queries'

type FavoriteFanActionButtonProps = {
  fan?: Fan
} & Partial<ActionButtonProps>

export function FavoriteFanActionButton({ fan, ...rest }: FavoriteFanActionButtonProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { favoriteCommunity: community } = useFavoriteCommunity()
  const isFavorite = useMemo(
    () => fan?.communities.some((c) => c.id === community?.id),
    [fan?.communities, community?.id],
  )
  const { removeFan, isLoading: removeLoading } = useRemoveFanFromCommunity()
  const { addFan, isLoading: addLoading } = useAddFanToCommunity()
  const isLoading = removeLoading || addLoading

  function handleClickFavorite() {
    if (!fan || !community) return

    if (isFavorite) {
      removeFan({ fan, community })
      return
    }

    addFan({ community, fan })
  }

  return (
    <ActionButton
      color={isFavorite ? COLORS?.WARNINGS : COLORS?.BUTTON_PRIMARY}
      icon="star"
      id={`detail-pane-${isFavorite ? 'favorited' : 'favorite'}`}
      label={isFavorite ? 'favorited' : 'favorite'}
      onClick={handleClickFavorite}
      processing={isLoading}
      {...rest}
    />
  )
}
