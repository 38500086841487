import { useMessageBillingUsage } from '@community_dev/hooks'
import {
  Button,
  ChevronRightIcon,
  CloseIcon,
  DIALOG_VARIANTS,
  Dialog,
  Modal,
  SPACING,
  ShopifyIcon,
  TCPAMessage,
  Toast,
  composeValidators,
  required,
} from '@community_dev/pixels'
import { useMutation } from '@tanstack/react-query'
import { ReactNode, useState } from 'react'
import { Field, Form, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { StyledContent, StyledFooter, StyledForm } from '../../styled'

import { putClientFanOnboardingMessage } from 'api/onboarding'
import { deleteShopifyStoreDetails } from 'api/shopify'
import { MultimediaInput } from 'components/MultimediaInput'
import { useShopifyAbandonedCheckout } from 'components/WorkflowEditor/hooks/useShopifyAbandonedCheckout'
import { ROUTES } from 'constants/routes'
import { useSettings } from 'contexts/SettingsProvider'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import { useToastMessage } from 'hooks/useToastMessage'
import analytics from 'utils/analytics'

const StyledMultimedia = styled.div`
  width: 100%;
`

const tcpaTextareaStyles = {
  fontFamily: 'Inter,sans-serif',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
}

const StyledLabel = styled.p`
  margin: ${SPACING[2]} 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledDetails = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};

  a {
    text-decoration: none;
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
    font-weight: 600;
    color: ${({ theme }) => theme?.COLORS?.LINKS};
  }
`

const StyledRemove = styled(Button)`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  color: ${({ theme }) => theme?.COLORS?.ERRORS};
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
`

const StyledCommunity = styled.button`
  background: none;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  display: flex;
  padding: 15px 20px;
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  border-radius: 8px;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: ${SPACING[5]};

  &:hover {
    background: ${({ theme }) => theme?.COLORS?.DEPRECATED_HOVER};
  }
`

type StyledCommunityLabelProps = {
  $color: string
}

const StyledCommunityLabel = styled.div<StyledCommunityLabelProps>`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: ${({ $color }) => $color};
    margin-right: 8px;
  }
`

const StyledCount = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
`

const StyledHeading = styled.h3`
  display: flex;
  align-items: center;
  margin: 0;

  svg {
    margin-right: ${SPACING[2]};
  }
`

const DIALOG_KEYS = Object.freeze({
  REMOVE: 'REMOVE',
  DISCARD: 'DISCARD',
})

const DIALOGS = Object.freeze({
  [DIALOG_KEYS.REMOVE]: {
    title: 'Remove Store?',
    message: 'Members will remain in your Shopify community.',
    action: 'Remove',
    key: DIALOG_KEYS.REMOVE,
  },
  [DIALOG_KEYS.DISCARD]: {
    title: 'Leave Without Saving?',
    message: 'Your changes will not be saved',
    action: 'Leave',
    key: DIALOG_KEYS.DISCARD,
  },
})

type EditProps = {
  client: {
    onboardingMessages: {
      label: string
      text: string
      id: string
    }[]
    id: string
  }
  community: {
    title: string
    id: string
    color: string
  }
  shopify: any
  count: number
  onCancel: (...args: any[]) => any
  onComplete: (...args: any[]) => any
}

type FormValues = {
  orderCompletedText: string
  checkoutReplyYText: string
}

function MessageField({
  style,
  name,
  validators,
  label,
  suffix,
}: {
  style?: React.CSSProperties
  name: string
  validators: any[]
  label: ReactNode
  suffix?: string
}): JSX.Element {
  const currentTheme = useCurrentTheme()
  const { settings } = useSettings()
  const { getFieldState } = useForm()
  const message = getFieldState(name)?.value
  const { links } = useShortenableLinks(message)
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = useMessageBillingUsage({
    linksToShorten: links,
    message,
  })
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }
  return (
    <Field name={name} validate={composeValidators(...validators)} validateOnBlur>
      {({ input }) => (
        <StyledMultimedia style={style}>
          <StyledLabel>{label}</StyledLabel>
          {suffix ? (
            <TCPAMessage
              defaultValue={input.value}
              emojiSkinTone={settings.skinTone}
              emojiTheme={currentTheme.type}
              onChange={input.onChange}
              requiredSuffix={suffix}
              style={tcpaTextareaStyles}
              usageCounterProps={usageCounterProps}
            />
          ) : (
            <MultimediaInput
              emojiSkinTone={settings.skinTone}
              emojiTheme={currentTheme.type}
              gallery={false}
              gif={false}
              onChange={input.onChange}
              onEmojiSelect={(_, text) => {
                input.onChange({ target: { value: text } })
              }}
              usageCounterProps={usageCounterProps}
              value={input.value}
            />
          )}
        </StyledMultimedia>
      )}
    </Field>
  )
}

export function EditShopify({ client, community, count, shopify, onCancel, onComplete }: EditProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const history = useHistory()
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const [error, setError] = useState<any>()
  const [dialog, setDialog] = useState<any>()
  const shopifyAbandonedCheckoutWorkflow = useShopifyAbandonedCheckout()

  const { mutate: submitOnboardingMessage, isLoading } = useMutation(putClientFanOnboardingMessage, {
    onSuccess() {
      showToastMessage({ message: 'Shopify Changes Saved.', success: true })
      onComplete()
    },
    onError() {
      setError({
        message: 'Could Not Be Saved',
        subtext: 'We’re having temporary problems. Please try again in a minute or two.',
      })
    },
  })
  const { mutate: remove } = useMutation(deleteShopifyStoreDetails, {
    onSuccess() {
      showToastMessage({ message: 'Shopify Removed.', success: true })
      analytics.track(analytics.events.ShopifyStoreRemoved())
      onComplete()
    },
    onError() {
      setError({
        message: 'Could Not Be Removed',
        subtext: 'We’re having temporary problems. Please try again in a minute or two.',
      })
    },
  })

  const orderCompletedMessage = client.onboardingMessages.find((i) => i.label === 'shopify_onboarding')
  const checkoutReplyYMessage = client.onboardingMessages.find((i) => i.label === 'import_welcome_message')
  const checkoutReplyYMessageSuffix = t('settings.integrations.shopify.replyYSuffix')

  function handleCreate(values: FormValues) {
    setError(undefined)
    if (orderCompletedMessage) {
      submitOnboardingMessage({ id: orderCompletedMessage.id, text: values.orderCompletedText })
    }
    if (checkoutReplyYMessage && values.checkoutReplyYText !== undefined) {
      submitOnboardingMessage({
        id: checkoutReplyYMessage.id,
        // adding the suffix back in before updating it.
        text: `${values.checkoutReplyYText} ${checkoutReplyYMessageSuffix}`.trimStart(),
      })
    }
  }

  function hasChanges(values: FormValues): boolean {
    return (
      values.orderCompletedText !== orderCompletedMessage?.text ||
      `${values.checkoutReplyYText} ${checkoutReplyYMessageSuffix}`.trimStart() !== checkoutReplyYMessage?.text
    )
  }

  function handleAction(key) {
    switch (key) {
      case DIALOG_KEYS.REMOVE:
        setDialog(undefined)
        setError(undefined)
        remove({ clientId: client.id })
        break
      default:
        onCancel()
    }
  }

  return (
    <>
      <Form<FormValues>
        initialValues={{
          orderCompletedText: orderCompletedMessage?.text || '',
          // the editable text only the bit without the required suffix.
          checkoutReplyYText: checkoutReplyYMessage?.text.replace(checkoutReplyYMessageSuffix, '').trimEnd() || '',
        }}
        onSubmit={handleCreate}
        render={({ handleSubmit, valid, values }) => (
          <div>
            <Modal.Header>
              <Modal.Header.Center>Shopify</Modal.Header.Center>
              <Modal.Header.Right
                onClose={() => {
                  if (hasChanges(values)) {
                    setDialog(DIALOGS.DISCARD)
                  } else {
                    onCancel()
                  }
                }}
              >
                <CloseIcon color={COLORS?.SUBTEXT} size={12} />
              </Modal.Header.Right>
            </Modal.Header>
            <Modal.Body>
              <StyledContent $submitting={isLoading}>
                <StyledForm>
                  <StyledHeading>
                    <ShopifyIcon />
                    {shopify.name}
                  </StyledHeading>
                  <StyledDetails>
                    With this connection, your Shopify customers will have the option to join your Community from
                    checkout.{' '}
                  </StyledDetails>
                  {error && <Toast icon message={error.message} subtext={error.subtext} success={false} />}
                  <StyledLabel>Community</StyledLabel>
                  <StyledCommunity
                    onClick={() => {
                      history.push(`${ROUTES.COMMUNITIES}/${community.id}`)
                    }}
                  >
                    <StyledCommunityLabel $color={community?.color}>{community?.title}</StyledCommunityLabel>
                    <StyledCount>
                      {count} <ChevronRightIcon color={COLORS?.SUBTEXT} size={24} />
                    </StyledCount>
                  </StyledCommunity>
                  {/* if no shopify abandoned checkout workflow exists
                      we don’t show the Reply-Y onboarding message.
                      if it does, we display both.
                    */}
                  {shopifyAbandonedCheckoutWorkflow === undefined ? (
                    <MessageField label="Shopify Welcome Message" name="orderCompletedText" validators={[required]} />
                  ) : (
                    <>
                      <MessageField label="Order Completed Message" name="orderCompletedText" validators={[required]} />
                      <MessageField
                        label="Checkout Registration Message"
                        name="checkoutReplyYText"
                        style={{ marginTop: SPACING[4] }}
                        suffix={` ${checkoutReplyYMessageSuffix}`}
                        validators={[required]}
                      />
                    </>
                  )}
                </StyledForm>
              </StyledContent>
            </Modal.Body>
            <Modal.Footer>
              <StyledFooter $multiple>
                <StyledRemove
                  onClick={() => {
                    analytics.track(analytics.events.ShopifyRemoveStoreAlert())
                    setDialog(DIALOGS.REMOVE)
                  }}
                >
                  Remove Store
                </StyledRemove>
                <Button disabled={!valid || isLoading} onClick={handleSubmit}>
                  {isLoading ? 'Saving...' : 'Save Changes'}
                </Button>
              </StyledFooter>
            </Modal.Footer>
          </div>
        )}
      />
      {dialog && (
        <Dialog message={dialog.message} title={dialog.title}>
          <Dialog.Action onClick={() => setDialog(undefined)}>Cancel</Dialog.Action>
          <Dialog.Action onClick={() => handleAction(dialog.key)} variant={DIALOG_VARIANTS.DESTRUCTIVE}>
            {dialog.action}
          </Dialog.Action>
        </Dialog>
      )}
    </>
  )
}
