import { useEffect } from 'react'

import { ScheduledState } from 'components/ComposeMessage/ComposeContext'
import { useMessagesLimits } from 'hooks/useMessagesLimits'
import analytics from 'utils/analytics'

type MessagesLimitsModalProps = {
  enabled: boolean
  scheduledAt?: ScheduledState
}

type MessagesLimitsModalReturn = {
  displayMessagesRemaining: boolean
  hasLimitReached: boolean
  hasWarningThresholdReached: boolean
  monthlyRemaining: number | null
  monthlyThreshold: number
  percentageUsed: number
}

export const useMessagesLimitsModal = ({
  enabled,
  scheduledAt,
}: MessagesLimitsModalProps): MessagesLimitsModalReturn => {
  const {
    percentageUsed,
    hasLimitEnabled,
    hasLimitReached,
    hasWarningThresholdReached,
    monthlyRemaining,
    monthlyThreshold,
  } = useMessagesLimits({ enabled })

  const displayMessagesRemaining = !scheduledAt && hasLimitEnabled

  useEffect(() => {
    if (displayMessagesRemaining) {
      analytics.track(analytics.events.MessageLimitWarningDisplayed())
    }
  }, [displayMessagesRemaining])

  if (!enabled) {
    return {
      displayMessagesRemaining: false,
      monthlyRemaining,
      hasWarningThresholdReached,
      hasLimitReached,
      monthlyThreshold,
      percentageUsed,
    }
  }

  return {
    displayMessagesRemaining,
    monthlyRemaining,
    hasWarningThresholdReached,
    hasLimitReached,
    monthlyThreshold,
    percentageUsed,
  }
}
