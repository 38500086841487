import { DIALOG_VARIANTS, Dialog, ExtraErrorCode } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import prettyBytes from 'pretty-bytes'
import { useEffect } from 'react'
import { ErrorCode } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { MaxSize, FileContext } from 'hooks/useMediaConfig'
import { useMediaValidation } from 'hooks/useMediaValidation'
import analytics from 'utils/analytics'

export { FileContext }

export type FileRejectionDialogProps = {
  communicationChannel?: CommunicationChannel
  mediaDisposition: MediaDisposition
  context: FileContext
  file?: File
  onClose?: () => void
  onContinue?: () => void
}

export const FileRejectionDialog = (props: FileRejectionDialogProps): JSX.Element | null => {
  const {
    context,
    file,
    onClose,
    onContinue,
    communicationChannel = CommunicationChannel.SMS,
    mediaDisposition,
  } = props
  const { t } = useTranslation()
  const isMms = communicationChannel === CommunicationChannel.SMS && mediaDisposition === MediaDisposition.ATTACHMENT
  const validateMedia = useMediaValidation({ communicationChannel, mediaDisposition, context })
  const validationResult = file && validateMedia(file)
  // This is a special case where the file is too large for any channel, media disposition, or file type.
  // The max max. We just want generic messages as nothing they can change will allow the file.
  const fileIsOverLargestMax = file?.size && file?.size > MaxSize.MAX
  const maxSize = prettyBytes(fileIsOverLargestMax ? MaxSize.MAX : validationResult?.maxSize || MaxSize.MAX)
  const errorCode = fileIsOverLargestMax ? ErrorCode.FileTooLarge : validationResult?.error?.code
  const translationKeyPrefix = isMms ? 'mms.' : ''
  const translationKeySuffix = validationResult?.canUseLink ? 'SendAsLink' : ''

  // the file type is invalid for MMS, but is valid for SMS (and is small enough for SMS)
  const isMmsFileInvalidType = errorCode === ErrorCode.FileInvalidType && isMms && validationResult?.canUseLink

  // the file is too large for MMS, but is small enough for SMS
  const isMmsFileTooLarge = errorCode === ErrorCode.FileTooLarge && isMms && validationResult?.canUseLink

  useEffect(() => {
    if (isMmsFileInvalidType) {
      analytics.track(analytics.events.MMS.FileRejectionType({ context }))
    }
    if (isMmsFileTooLarge) {
      analytics.track(analytics.events.MMS.FileRejectionSize({ context }))
    }
    // we only want to trigger this once, when errorCode is available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode])

  if (!errorCode) return null

  const handleClose = () => {
    if (isMmsFileInvalidType || isMmsFileTooLarge) {
      analytics.track(analytics.events.MMS.ChooseAnotherAttachment({ context }))
    }
    if (onClose) {
      onClose()
    }
  }

  const handleContinue = () => {
    if (isMmsFileInvalidType || isMmsFileTooLarge) {
      analytics.track(analytics.events.MMS.SendAsSms({ context }))
    }
    if (onContinue) {
      onContinue()
    }
  }

  const defaultActions = [
    <Dialog.Action key="gotIt" onClick={handleClose}>
      {t('gotIt')}
    </Dialog.Action>,
  ]
  const sendAsLink = [
    <Dialog.Action key="sendAsLink" onClick={handleContinue}>
      {t([`fileErrors.${translationKeyPrefix}sendAsLink`, 'fileErrors.sendAsLink'])}
    </Dialog.Action>,
    <Dialog.Action key="attachDifferentFile" onClick={handleClose} variant={DIALOG_VARIANTS.EMPHASIZED}>
      {t([`fileErrors.${translationKeyPrefix}attachDifferentFile`, 'fileErrors.attachDifferentFile'])}
    </Dialog.Action>,
  ]

  const actions = validationResult?.canUseLink ? sendAsLink : defaultActions
  let title = t('compose.uploadError')
  let message = t('login.error.mfaGeneric')

  if (errorCode === ErrorCode.FileTooLarge) {
    title = t([`fileErrors.${translationKeyPrefix}fileTooLargeTitle`, 'fileErrors.fileTooLargeTitle'])
    message = t(
      [
        `fileErrors.${translationKeyPrefix}fileTooLargeMessage${translationKeySuffix}`,
        `fileErrors.fileTooLargeMessage${translationKeySuffix}`,
      ],
      {
        maxSize,
      },
    )
  }
  if (errorCode === ErrorCode.FileInvalidType) {
    title = t([`fileErrors.${translationKeyPrefix}fileInvalidTypeTitle`, 'fileErrors.fileInvalidTypeTitle'])
    message = t([
      `fileErrors.${translationKeyPrefix}fileInvalidTypeMessage${translationKeySuffix}`,
      `fileErrors.fileInvalidTypeMessage${translationKeySuffix}`,
    ])
  }
  if (errorCode === ExtraErrorCode.FileInvalidColorProfile) {
    title = t([`fileErrors.${translationKeyPrefix}fileInvalidEncodingTitle`, 'fileErrors.fileInvalidEncodingTitle'])
    message = t([
      `fileErrors.${translationKeyPrefix}fileInvalidEncodingMessage${translationKeySuffix}`,
      `fileErrors.fileInvalidEncodingMessage${translationKeySuffix}`,
    ])
  }

  return (
    <Dialog maxWidth={348} message={message} title={title}>
      {actions}
    </Dialog>
  )
}
