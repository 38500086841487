export enum RecurrenceFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export enum RecurrenceDay {
  SUNDAY = 'SU',
  MONDAY = 'MO',
  TUESDAY = 'TU',
  WEDNESDAY = 'WE',
  THURSDAY = 'TH',
  FRIDAY = 'FR',
  SATURDAY = 'SA',
}

export type RecurrenceParams =
  | {
      frequency: RecurrenceFrequency.DAILY
      day?: never
    }
  | {
      frequency: RecurrenceFrequency.WEEKLY
      day: RecurrenceDay
    }

export type RecurrenceRule =
  | `FREQ=${RecurrenceFrequency.DAILY}`
  | `FREQ=${RecurrenceFrequency.WEEKLY};BYDAY=${RecurrenceDay}`

export function generateRecurrenceRule(params: RecurrenceParams): RecurrenceRule {
  switch (params.frequency) {
    case RecurrenceFrequency.DAILY:
      return `FREQ=${RecurrenceFrequency.DAILY}`
    case RecurrenceFrequency.WEEKLY:
      if (params.day) {
        return `FREQ=${RecurrenceFrequency.WEEKLY};BYDAY=${params.day}`
      }
      // If the day is not provided in the params, it's a type error.
      throw new Error('Day must be provided for weekly frequency')
    default:
      throw new Error('Invalid frequency')
  }
}

export function parseRecurrenceRule(recurrence: RecurrenceRule): RecurrenceParams | null {
  const result: RecurrenceParams = {} as RecurrenceParams

  if (!recurrence) return null

  // Match FREQ
  const freqMatch = recurrence.match(/FREQ=([^;]+)/)
  if (freqMatch && Object.values(RecurrenceFrequency).includes(freqMatch[1] as RecurrenceFrequency)) {
    result.frequency = freqMatch[1] as RecurrenceFrequency
  }

  // Match BYDAY
  const byDayMatch = recurrence.match(/BYDAY=([^;]+)/)
  if (byDayMatch && Object.values(RecurrenceDay).includes(byDayMatch[1] as RecurrenceDay)) {
    result.day = byDayMatch[1] as RecurrenceDay
  }

  return result.frequency ? result : null
}
