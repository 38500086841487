import React from 'react'

import { IconProps } from './IconProps'

export const BehaviorIcon = ({ size = 25, color = 'currentColor' }: IconProps): JSX.Element => (
  <svg fill="none" height={size} viewBox="0 0 25 20" width={size} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.47941 15.4454H5.16561V17.0131C5.16561 18.8907 6.12264 19.8386 8.02759 19.8386H21.8544C23.7502 19.8386 24.7164 18.8907 24.7164 17.0131V7.3243C24.7164 5.45581 23.7502 4.50789 21.8544 4.50789H20.1591V3.03133C20.1591 1.15373 19.2021 0.205811 17.2971 0.205811H3.47941C1.56535 0.205811 0.617432 1.15373 0.617432 3.03133V12.629C0.617432 14.5066 1.56535 15.4454 3.47941 15.4454ZM3.49764 13.9779C2.58618 13.9779 2.08488 13.4949 2.08488 12.547V3.11336C2.08488 2.16545 2.58618 1.67326 3.49764 1.67326H17.2789C18.1721 1.67326 18.6917 2.16545 18.6917 3.11336V4.50789H8.02759C6.12264 4.50789 5.16561 5.4467 5.16561 7.3243V13.9779H3.49764ZM8.06405 7.61597C7.61743 7.61597 7.25285 7.23316 7.25285 6.80477C7.25285 6.37638 7.61743 5.99357 8.06405 5.99357C8.51066 5.99357 8.87524 6.37638 8.87524 6.80477C8.87524 7.23316 8.51066 7.61597 8.06405 7.61597ZM10.6799 7.61597C10.2333 7.61597 9.85962 7.23316 9.85962 6.80477C9.85962 6.37638 10.2333 5.99357 10.6799 5.99357C11.1265 5.99357 11.4911 6.37638 11.4911 6.80477C11.4911 7.23316 11.1265 7.61597 10.6799 7.61597ZM13.2958 7.61597C12.8492 7.61597 12.4755 7.23316 12.4755 6.80477C12.4755 6.37638 12.8492 5.99357 13.2958 5.99357C13.7424 5.99357 14.107 6.37638 14.107 6.80477C14.107 7.23316 13.7424 7.61597 13.2958 7.61597ZM6.63306 9.10165H23.2489V16.9311C23.2489 17.879 22.7294 18.3712 21.8271 18.3712H8.05493C7.14347 18.3712 6.63306 17.879 6.63306 16.9311V9.10165Z"
      fill={color}
    />
  </svg>
)
