import { ApiError } from '@community_dev/requests'
import { CampaignStatus } from '@community_dev/types/lib/api/v2/Campaign'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { useClientId } from './useClient'

import { ScheduledCampaignResponse, getScheduledCampaigns } from 'api/scheduled'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useCurrentMonthDateRange } from 'screens/Scheduled/screens/Month/useCurrentMonthDateRange'

type ScheduledCampaignQueryProps<SelectData = ScheduledCampaignResponse> = {
  start: Date
  end: Date
  statuses?: CampaignStatus[]
  options?: UseQueryOptions<ScheduledCampaignResponse, ApiError, SelectData>
}

export const useScheduledCampaignsQuery = <SelectData = ScheduledCampaignResponse>({
  start,
  end,
  statuses,
  options,
}: ScheduledCampaignQueryProps<SelectData>): UseQueryResult<SelectData, ApiError> => {
  const clientId = useClientId()
  const isoStart = start.toISOString()
  const isoEnd = end.toISOString()
  return useQuery({
    queryKey: [QUERY_CACHE.SCHEDULED.RANGE, { clientId, start: isoStart, end: isoEnd, statuses }],
    queryFn: () => getScheduledCampaigns({ clientId, startDatetime: isoStart, endDatetime: isoEnd, statuses }),
    staleTime: STALE_TIME.ONE_MINUTE,
    ...options,
  })
}

export const useCurrentMonthScheduledCampaignsQuery = <SelectData = ScheduledCampaignResponse>({
  statuses,
  options,
}: {
  statuses?: CampaignStatus[]
  options?: UseQueryOptions<ScheduledCampaignResponse, ApiError, SelectData>
}): UseQueryResult<SelectData, ApiError> => {
  const { start, end } = useCurrentMonthDateRange()
  return useScheduledCampaignsQuery<SelectData>({ start, end, options, statuses })
}
