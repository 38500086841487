export enum FilterPane {
  TOP = 'TopRecommendations',
  INDIVIDUAL = 'IndividualRecommendations',
  COMMUNITY = 'CommunityRecommendations',
  CUSTOM_FIELDS = 'CustomFieldsRecommendations',
  LOCATION = 'LocationRecommendations',
  TIMEZONE = 'TimezoneRecommendations',
  AGE = 'AgeRecommendations',
  GENDER = 'GenderRecommendations',
  JOIN = 'JoinRecommendations',
  ACTIVITY = 'ActivityRecommendations',
  RECENT_CAMPAIGNS = 'RecentCampaignsRecommendations',
}
