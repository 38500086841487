import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { ApiResponse } from 'types/api'
import { request } from 'utils/api'
import { wrapSingleFilter } from 'utils/memberDataFilters/memberDataFiltersUtils'
import { route } from 'utils/router'

export type SuggestionCard = {
  type: string
  title: string
}

type PostSendMessageArgs = {
  body: Api.V2.SendMessageBody
  clientId: string
  hasDropdownFilter?: boolean
  suggestionCard?: SuggestionCard
  isRemessage?: boolean
}

type PostSendMessageResponse = CamelCasedPropertiesDeep<ApiResponse<Api.V2.Campaign>>

export function postSendMessage({ body, clientId }: PostSendMessageArgs): Promise<PostSendMessageResponse> {
  // The API does not support a single selector filter. It must be wrapped in a relationship filter, even if it is only a single filter.
  // We do this here, directly before send, so the rest of the code does not need to worry about this bug.
  body.filters.subscription_data = wrapSingleFilter(body.filters.subscription_data)

  return request<PostSendMessageResponse>(route(ENDPOINTS.COMPOSE_MESSAGE, { clientId }), {
    body,
    method: HTTP.POST,
  })
}

type PostScheduleMessageArgs = {
  body: Api.V2.SendMessageBody
  clientId: string
  hasDropdownFilter?: boolean
  suggestionCard?: SuggestionCard
  isRemessage?: boolean
}

export function postScheduleMessage({ body, clientId }: PostScheduleMessageArgs): Promise<any> {
  // The API does not support a single selector filter. It must be wrapped in a relationship filter, even if it is only a single filter.
  // We do this here, directly before send, so the rest of the code does not need to worry about this bug.
  body.filters.subscription_data = wrapSingleFilter(body.filters.subscription_data)

  return request<PostSendMessageResponse>(route(ENDPOINTS.COMPOSE_SCHEDULE_MESSAGE, { clientId }), {
    body,
    method: HTTP.POST,
  })
}

type PostScheduledMessageApprovalStatusParams =
  CamelCasedPropertiesDeep<Api.V2.PostScheduledMessageApprovalStatusParams>

export const postScheduledMessageApprovalStatus = ({
  campaignId,
  clientId,
  approvalStatus,
}: PostScheduledMessageApprovalStatusParams): Promise<''> => {
  return request<''>(route(ENDPOINTS.CAMPAIGNS.SCHEDULED_APPROVAL_STATUS, { clientId, approvalStatus, campaignId }), {
    method: HTTP.POST,
  })
}

type PostCheckMessageContentArgs = {
  text: string
  clientId: string
}

export type PostCheckMessageContentReturn = {
  alcohol: string[]
  general: string[]
  tobacco: string[]
  violations: string[]
}

export function postCheckMessageContent({
  text,
  clientId,
}: PostCheckMessageContentArgs): Promise<PostCheckMessageContentReturn> {
  return request(ENDPOINTS.CONTENT_WARNING_CHECK, {
    body: {
      text,
      client_id: clientId,
    },
    method: HTTP.POST,
  })
}

/** Returns a list of urls from a text body that are eligible for shortening  */
export function postShortenableMessageUrls({
  text,
  clientId,
}: {
  text: string
  clientId: string
}): Promise<{ links: string[] }> {
  return request(route(ENDPOINTS.MESSAGE_URLS, { clientId }), {
    body: {
      text,
    },
    method: HTTP.POST,
  })
}

type PostSendMediaMessageArgs = {
  body: Api.V2.SendMessageBody
  hasDropdownFilter?: boolean
  clientId: string
}

export function postSendMediaMessage({ body, clientId }: PostSendMediaMessageArgs): Promise<any> {
  // The API does not support a single selector filter. It must be wrapped in a relationship filter, even if it is only a single filter.
  // We do this here, directly before send, so the rest of the code does not need to worry about this bug.
  body.filters.subscription_data = wrapSingleFilter(body.filters.subscription_data)

  return request<PostSendMessageResponse>(route(ENDPOINTS.COMPOSE_MESSAGE_MEDIA, { clientId }), {
    body,
    method: HTTP.POST,
  })
}

export type PatchMessageQueryProps = {
  body: Api.V2.UpdateMessageBody
  campaignId: string
  clientId: string
}

export type PatchMessageQueryResponse = void

export function patchMessageQuery({
  body,
  clientId,
  campaignId,
}: PatchMessageQueryProps): Promise<PatchMessageQueryResponse> {
  return request(route(ENDPOINTS.MESSAGING.MESSAGE_CAMPAIGNS, { clientId, campaignId }), {
    body,
    method: HTTP.PATCH,
  })
}
