import { SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledEditButtonContainer = styled.div`
  margin-top: ${SPACING[3]};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & button:nth-child(2) {
    margin-left: ${SPACING[3]};
  }
`
