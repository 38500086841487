import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { listWorkflows, Workflow } from 'api/workflows'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

export type UseWorkflowsProps = {
  getDetails?: boolean
  onSuccess?: (data: Workflow[]) => void
  onError?: (err: unknown) => void
}

export type UseWorkflowsResult = UseQueryResult<Workflow[], unknown>

export function useWorkflows(props: UseWorkflowsProps = {}): UseWorkflowsResult {
  const { onSuccess, onError, getDetails } = props

  const clientId = useClientId()

  return useQuery([QUERY_CACHE.WORKFLOW_CONFIG.WORKFLOWS], async () => listWorkflows({ clientId, getDetails }), {
    retry: false,
    onSuccess,
    onError,
  })
}
