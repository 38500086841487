import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { getInboxMessages } from 'api/messages'
import { SearchTabType } from 'constants/messages'
import { QUERY_CACHE } from 'constants/query-cache'
import { InboxFilter } from 'contexts/InboxProvider'
import { useClientId } from 'hooks/useClient'
import { isInboxItemNormalized } from 'layouts/MessagesLayout/DirectMessageLayout'
import { InboxItemNormalized, PaginationNormalized } from 'utils/normalize'

export type InboxItemPaged = {
  messages: (InboxItemNormalized | Record<string, never>)[]
  pagination: PaginationNormalized
}

type UseInboxMessagesReturn = UseInfiniteQueryResult<InboxItemPaged, unknown> & {
  items?: InboxItemNormalized[]
}

export function useInboxMessages({
  filters = [],
  searchTerm,
  searchTab = SearchTabType.CONTACT_NAME,
}: {
  filters?: InboxFilter[]
  searchTerm?: string
  searchTab?: SearchTabType
}): UseInboxMessagesReturn {
  const clientId = useClientId()

  const query: UseInboxMessagesReturn = useInfiniteQuery(
    [QUERY_CACHE.CLIENT_DASHBOARD_CHATS, filters, searchTerm, searchTab, clientId],
    ({ pageParam = 1 }) => getInboxMessages({ filters, searchTerm, searchTab }, pageParam),
    {
      enabled: Boolean(filters) && Boolean(clientId),
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination?.hasNext && (lastPage?.pagination?.page || 0) + 1
      },
      staleTime: 0,
    },
  )

  const items = useMemo(() => {
    if (query.data?.pages) {
      return query.data.pages
        .reduce<(InboxItemNormalized | Record<string, never>)[]>((acc, cur) => [...acc, ...cur.messages], [])
        .filter(isInboxItemNormalized)
        .sort((a, b) => new Date(b.last.ts).getTime() - new Date(a.last.ts).getTime())
        .filter((message, i, self) => i === self.findIndex((t) => t.id === message.id))
    }

    return []
  }, [query.data])

  query.items = items
  return query
}
