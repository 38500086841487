import { Block, Exclamation, Haha, Heart, QuestionMark, SPACING, ThumbsDown, ThumbsUp } from '@community_dev/pixels'
import numeral from 'numeral'
import styled, { DefaultTheme, useTheme } from 'styled-components'

import { CampaignInsight } from 'utils/normalize'

const ICON_MAP = {
  liked: ThumbsUp,
  loved: Heart,
  disliked: ThumbsDown,
  questioned: QuestionMark,
  laughed: Haha,
  emphasized: Exclamation,
}

export const StyledReactions = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: ${SPACING[4]} 0 0 0;

  li {
    text-align: center;
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  }

  p {
    margin: 0;
  }
`

type ReactionsProps = {
  reactions?: CampaignInsight[]
}

function fillColor(COLORS: DefaultTheme['COLORS'], name: string, count: number): string {
  if (count === 0) {
    return COLORS?.BORDERS as string
  }

  return (name === 'loved' ? COLORS?.FAVORITE : COLORS?.SUBTEXT) as string
}

export function Reactions({ reactions = [] }: ReactionsProps): JSX.Element {
  const { COLORS } = useTheme() || {}

  return (
    <Block id="reactions-block" title="Reactions">
      <StyledReactions>
        {reactions.map((t) => {
          if (!t.name) return null
          const name = t.name.toLowerCase()
          const Icon = ICON_MAP[name]
          return (
            <li aria-label={name} key={t.name}>
              <Icon
                innerFill={fillColor(COLORS, name, t.count || 0)}
                outerFill={t.count === 0 ? COLORS?.APP_BACKGROUND_LEVEL_2 : COLORS?.DEPRECATED_RECEIVED}
                size={40}
              />
              <p>{numeral(t.count).format('0[.]0a')}</p>
            </li>
          )
        })}
      </StyledReactions>
    </Block>
  )
}
