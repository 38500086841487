import { composeValidators, required } from '@community_dev/pixels'
import { FieldMetaState } from 'react-final-form'

export const validateName = composeValidators(required, (v) => {
  return v.length > 255 ? 'must be 255 characters or less' : undefined
}) as (value: string) => undefined | string

export const isPhoneValid = (value: string = ''): undefined | string => {
  // Remove all non-digit characters for simplification
  const sanitizedNumber = value.replace(/\D/g, '')

  // Check for a 10-digit phone number or an 11-digit number starting with '1'
  const isValidUSNumber =
    sanitizedNumber.length === 10 || (sanitizedNumber.length === 11 && sanitizedNumber.startsWith('1'))

  return isValidUSNumber ? undefined : 'is invalid'
}

export const isError = (meta: FieldMetaState<any>): string | false => {
  return !meta.active && meta.touched && meta.modified && meta.error
}
