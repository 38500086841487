import { hideVisually } from 'polished'
import styled from 'styled-components'

import {
  DESKTOP_BREAK,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EASING,
  SIDEBAR_MOBILE_WIDTH,
  SIDEBAR_TRANSITION_SPEED,
  SIDEBAR_WIDTH,
} from 'constants/theme'

export type StyledSidebarProps = {
  $opened?: boolean
  $collapsed?: boolean
}

export const StyledSidebar = styled.aside<StyledSidebarProps>`
  position: absolute;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100%;
  width: ${SIDEBAR_MOBILE_WIDTH};
  user-select: none;
  display: block;
  border-right: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  z-index: 3;

  > div {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  transform: ${({ $opened }) => ($opened ? 'translateX(0px)' : `translateX(-100%)`)};
  transition: transform ${SIDEBAR_TRANSITION_SPEED} ${SIDEBAR_EASING},
    width ${SIDEBAR_TRANSITION_SPEED} ${SIDEBAR_EASING};

  @media (min-width: ${DESKTOP_BREAK}) {
    display: block;
    width: ${({ $collapsed }) => ($collapsed ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_WIDTH)};
    transform: translateX(0px);
  }
`

export const StyledNav = styled.nav`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  h2 {
    ${hideVisually};
  }
`

type StyledSlideProps = {
  $collapsed?: boolean
}

export const StyledSlide = styled.ul<StyledSlideProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  -webkit-overflow-scroll: touch;
  margin: 0;
  padding: 0;
`
