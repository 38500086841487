import { useDebouncedValue } from '@community_dev/hooks'
import { ChangeEvent, useState } from 'react'

type UseSearchReturn = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  debounceValue: string
  inputValue: string
}

export function useSearch(initialValue: string = ''): UseSearchReturn {
  const [inputValue, setInputValue] = useState(initialValue)

  const debounceValue = useDebouncedValue(inputValue, 200)

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  return {
    onChange,
    debounceValue,
    inputValue,
  }
}
