import * as qr from 'qrcode'

type GetQRCodeAsStringProps = {
  text: string
  size: number
}

/**
 * Returns an SVG string representation of the QR Code as XML
 */
export const getSvgString = ({ text, size = 1000 }: GetQRCodeAsStringProps): Promise<string> => {
  return qr.toString(text, {
    width: size,
    margin: 2,
    color: {
      dark: '#000000',
      light: '#ffffff',
    },
    errorCorrectionLevel: 'quartile',
    type: 'svg',
  })
}
