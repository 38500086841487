/* eslint-disable camelcase */
let gaTrackingId = ''

const ga = {
  identify: (userId: string): void => {
    try {
      if (!gaTrackingId) return
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtag' does not exist on type 'Window & t... Remove this comment to see the full error message
      window.gtag('config', 'GA_MEASUREMENT_ID', { user_id: userId })
    } catch (ex) {
      /* noop */
    }
  },
  init: (id: string): void => {
    try {
      gaTrackingId = id

      if (!gaTrackingId) return

      const script = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        // We’re using Google Tag Manager’s consent mode to initialize it
        // without cookies. This way, we don’t have to show EU-users a cookie
        // consent form.
        gtag("consent", "default", {
          ad_storage: "denied",
          analytics_storage: "denied",
          functionality_storage: "denied",
          personalization_storage: "denied",
          security_storage: "denied"
        });
        gtag('config', '${gaTrackingId}');
      `
      const script1 = document.createElement('script')
      script1.type = 'text/javascript'
      script1.appendChild(document.createTextNode(script))
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.documentElement.firstChild.appendChild(script1)

      const script2 = document.createElement('script')
      script2.type = 'text/javascript'
      script2.setAttribute('async', 'true')
      script2.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`)
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.documentElement.firstChild.appendChild(script2)
    } catch (ex) {
      /* noop */
    }
  },
  pageView: (page_path: string): void => {
    try {
      if (!gaTrackingId) return
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtag' does not exist on type 'Window & t... Remove this comment to see the full error message
      window.gtag('config', gaTrackingId, { page_path })
    } catch (ex) {
      /* noop */
    }
  },
  track: (event_name: string): void => {
    try {
      if (!gaTrackingId) return
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtag' does not exist on type 'Window & t... Remove this comment to see the full error message
      window.gtag('event', event_name)
    } catch (ex) {
      /* noop */
    }
  },
}

export default ga
