import { FONT_SIZE, SPACING } from '@community_dev/pixels'
import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { ChevronDownIcon } from '../../Icons'

type InfoAccordionProps = {
  icon: React.ReactNode
  heading: React.ReactNode
  children: React.ReactNode
  defaultOpen?: boolean
  className?: string
}

const StyledBox = styled.div`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  border-radius: 4px;
  margin-bottom: ${SPACING[1]};
  max-width: 100%;
`

const StyledHeader = styled.button`
  margin: 0;
  border: none;
  background: none;
  padding: 12px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const StyledExpandIcon = styled.div<{ $open: boolean }>`
  svg {
    transform: ${({ $open }) => (!$open ? 'rotate(-90deg)' : 'none')};
  }
`

const StyledHeading = styled.span`
  flex: 1;
  text-align: left;
  margin-left: ${SPACING[2]};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  font-size: ${FONT_SIZE[3]};
  color: ${({ theme }) => theme?.COLORS?.HEADLINES};
`

const StyledContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  padding: 12px;
`

export const InfoAccordion = (props: InfoAccordionProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const [open, setOpen] = useState(Boolean(props.defaultOpen))

  return (
    <StyledBox className={props.className}>
      <StyledHeader onClick={() => setOpen(!open)}>
        {props.icon}
        <StyledHeading>{props.heading}</StyledHeading>
        <StyledExpandIcon $open={open}>
          <ChevronDownIcon color={COLORS?.SUBTEXT} />
        </StyledExpandIcon>
      </StyledHeader>
      {open && <StyledContent>{props.children}</StyledContent>}
    </StyledBox>
  )
}
