import { Appearance } from '@stripe/stripe-js'
import { useTheme } from 'styled-components'

export const useStripeCustomTheme = (): Appearance => {
  const { COLORS, TYPOGRAPHY } = useTheme()

  return {
    theme: 'stripe',
    variables: {
      colorSuccess: COLORS.SUCCESS,
      colorDanger: COLORS.ERRORS,
      fontFamily: TYPOGRAPHY.FAMILY.PRIMARY,
    },
    rules: {
      '.Label': {
        color: COLORS.INPUT_TEXT,
      },
      '.Input': {
        backgroundColor: COLORS.APP_BACKGROUND_LEVEL_3,
        colorTextPlaceholder: COLORS.INPUT_TEXT_PLACEHOLDER,
        color: COLORS.INPUT_TEXT,
        borderColor: COLORS.BORDERS,
      },
      '.Tab': {
        backgroundColor: COLORS.APP_BACKGROUND_LEVEL_3,
        colorTextPlaceholder: COLORS.INPUT_TEXT_PLACEHOLDER,
        color: COLORS.INPUT_TEXT,
        borderColor: COLORS.BORDERS,
      },
    },
  }
}
