import {
  AppStoreIcon,
  BackwardsSentIcon,
  CommunitiesIcon,
  HomeIcon,
  InsightsIcon,
  MessagesIcon,
  ResourcesIcon,
  ScheduledIcon,
  SettingsIcon,
  TransferIcon,
  SparkleIcon,
} from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-hot-toast'

import { SidebarFooter } from './components/SidebarFooter'
import { SidebarHeader } from './components/SidebarHeader'
import { UpgradeBanner } from './components/UpgradeBanner'
import { StyledNav, StyledSidebar, StyledSlide } from './Sidebar.style'
import { SidebarLink, SidebarLinkProps } from './SidebarLink'

import { useCapabilities } from 'contexts/bootstrap/useCapabilities'
import { useLaunchDarklyContext } from 'contexts/LaunchDarklyProvider'
import { useSidebar } from 'contexts/SidebarProvider'
import { useClient } from 'hooks/useClient'

export const SIDEBAR_ICONS = {
  HomeIcon,
  MessagesIcon,
  BackwardsSentIcon,
  TransferIcon,
  CommunitiesIcon,
  InsightsIcon,
  ScheduledIcon,
  ResourcesIcon,
  SettingsIcon,
  AppStoreIcon,
  SparkleIcon,
} as const

export function filterSidebarLinks(
  items: Record<string, SidebarLinkProps>,
  flags: LDFlagSet = {},
  capabilities: string[] = [],
  communicationChannels: CommunicationChannel[],
): SidebarLinkProps[] {
  const isMobile = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

  return Object.keys(items)
    .map((id) => items[id])
    .filter((item) => {
      if (item.requiresCapabilityEnabled) {
        return item.requiresCapabilityEnabled.every((capability) => capabilities.includes(capability) === true)
      }

      return true
    })
    .filter((item) => {
      if (item.requiresCapabilityDisabled) {
        return item.requiresCapabilityDisabled.every((capability) => capabilities.includes(capability) === false)
      }

      return true
    })
    .filter((item) => {
      if (item.requiresFlagEnabled) {
        return item.requiresFlagEnabled.every((flag) => flags[flag] === true)
      }

      return true
    })
    .filter((item) => {
      if (item.requiresFlagDisabled) {
        return item.requiresFlagDisabled.every((flag) => !flags[flag])
      }

      return true
    })
    .filter((item) => {
      if (item.onlyMobile) {
        return isMobile
      }

      return true
    })
    .filter((item) => {
      if (item.onlyDesktop) {
        return !isMobile
      }

      return true
    })
    .filter((item) => {
      if (item.requiresCommunicationChannel && typeof item.requiresCommunicationChannel === 'boolean') {
        return communicationChannels?.length !== 0
      }
      if (item.requiresCommunicationChannel && Array.isArray(item.requiresCommunicationChannel)) {
        return item.requiresCommunicationChannel?.every((channel) => communicationChannels?.includes(channel))
      }
      return true
    })
}

function Sidebar(): JSX.Element {
  const { opened, collapsed, items: sidebarItems, setOpened, setCollapsed } = useSidebar()
  const { data: client } = useClient()
  const capabilities = useCapabilities()
  const launchDarklyClient = useLaunchDarklyContext()

  const onClose = () => {
    if (opened) setOpened(false)
  }

  // Since the sidebar gets dismounted and remounted on every page change, to get the effect of closing the sidebar on every page change in mobile, we need to close the sidebar, if its open, on every mount
  useEffect(() => {
    onClose()
  }, [])

  const onLinkClick = () => {
    toast.dismiss()
    onClose()
  }

  const toggleCollapse = () => {
    if (collapsed) {
      setCollapsed(false)
    } else {
      setCollapsed(true)
    }
  }

  const links: SidebarLinkProps[] = useMemo(() => {
    if (!client) return []
    const allFlags = launchDarklyClient?.allFlags() || {}

    return filterSidebarLinks(sidebarItems, allFlags, capabilities, client.communicationChannels)
  }, [sidebarItems, launchDarklyClient, capabilities, client])

  if (!client) {
    return <></>
  }

  return (
    <StyledSidebar $collapsed={collapsed} $opened={opened} data-testid="sidebar">
      <div>
        <SidebarHeader collapsed={collapsed} fullName={client?.fullName} onClose={onClose} phone={client?.phone} />
        <StyledNav aria-labelledby="sidebar-menu-label">
          <h2 id="sidebar-menu-label">Main Menu</h2>
          <StyledSlide $collapsed={collapsed}>
            {links.map((item, i) => {
              return <SidebarLink key={i} {...item} collapsed={collapsed} />
            })}
          </StyledSlide>
        </StyledNav>
        <UpgradeBanner collapsed={collapsed} />
        <SidebarFooter collapsed={collapsed} onLinkClick={onLinkClick} onToggleCollapse={toggleCollapse} />
      </div>
    </StyledSidebar>
  )
}

export default Sidebar
