import { BUTTON_VARIANTS, Button, FONT_SIZE, Image, SPACING } from '@community_dev/pixels'
import React from 'react'
import styled from 'styled-components'

import ctaImage from './scheduled-message-cta.svg'

import { PLAN_COLOR } from 'constants/plans'
import { ROUTES } from 'constants/routes'
import { useTrackBilling } from 'hooks/billing/useTrackBilling'
import events from 'utils/analytics/events'

const StyledContainer = styled.div`
  max-width: 440px;
  margin: ${SPACING[9]} auto;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 406px;
`

const StyledImage = styled(Image)`
  padding-top: ${SPACING[9]};
  width: 100%;
  max-width: 300px;
`

const StyledTitle = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  font-size: ${FONT_SIZE[5]};
  text-align: center;
  padding-top: ${SPACING[4]};
`

const StyledBody = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  text-align: center;
  padding: ${SPACING[1]} ${SPACING[2]};
  margin-bottom: ${SPACING[5]};
  max-width: 360px;
`

const StyledPlanName = styled.span`
  color: ${PLAN_COLOR.essentials};
  font-weight: bold;
`

export const SchedulePreview = (): JSX.Element | null => {
  const link = ROUTES.PRICING.CONTACT_US
  const track = useTrackBilling()
  return (
    <StyledContainer>
      <StyledContent>
        <a href={link} onClick={() => track(events.FeatureHoldPrompt({ featureName: 'scheduling' }))}>
          <StyledImage src={ctaImage} />
        </a>
        <StyledTitle>Use Message Scheduling to Boost Engagement</StyledTitle>
        <StyledBody>
          You can use schedule your messages to boost engagement on the
          <StyledPlanName> Essentials </StyledPlanName>
          plan
        </StyledBody>
        <Button
          forwardedAs="a"
          href={link}
          onClick={() => track(events.FeatureHoldPrompt({ featureName: 'scheduling' }))}
          rel="noreferer noopener"
          variant={BUTTON_VARIANTS.ACTION}
        >
          Unlock Message Scheduling
        </Button>
      </StyledContent>
    </StyledContainer>
  )
}
