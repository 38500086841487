import {
  AiBubble,
  BUTTON_VARIANTS,
  Badge,
  Button,
  FONT_SIZE,
  Layout,
  Popover,
  SPACING,
  TABLET_BREAK,
  Typography,
} from '@community_dev/pixels'
import { useIsMutating, useMutation } from '@tanstack/react-query'
import React, { useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { keyframes, useTheme } from 'styled-components'

import IconCompose from './svg/compose.svg?react'
import IconDice from './svg/dice.svg?react'
import IconNewTab from './svg/new-tab.svg?react'
import IconRefresh from './svg/refresh.svg?react'

import { postSuggestQuick } from 'api/ai-interface'
import { useCompose } from 'components/ComposeMessage/ComposeContext'
import {
  AnalyticsEventSuggestMode,
  AnalyticsEventSuggested,
  useAiMessageGenerator,
} from 'contexts/AIMessageGeneratorProvider'
import useBreakpoints from 'hooks/useBreakpoints'
import { useClient, useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import analytics from 'utils/analytics'
import { formatFullName } from 'utils/formatters'

export const AI_INTERFACE = 'AI_INTERFACE'
export const QUICK_SUGGEST = 'QUICK_SUGGEST'
export const FULL_SUGGEST = 'FULL_SUGGEST'

export type AiMessageGeneratorButtonProps = {
  className?: string
  style?: React.CSSProperties
  ButtonProps?: any
}

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme?.COLORS.LINKS};
`

const rotate = keyframes`
  from {
    transform:rotate(0deg);
  }

  to {
    transform:rotate(360deg);
  }
`

export const AiMessageGeneratorPopoverButton = styled.button<{ disabled?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 80px; 
  grid-gap: 15px 10px;
  transition: opacity .1s;
  appearance: none;
  background: none;
  border: none;
  text-align: left;
  opacity:  ${({ disabled }) => (disabled ? '.5' : '1')}};
  cursor:  ${({ disabled }) => (disabled ? 'default' : 'pointer')}};
  padding: ${SPACING[4]} ${SPACING[0]};
  color: ${({ theme }) => theme?.COLORS.TEXT};

  @media (min-width: ${TABLET_BREAK}) {
    grid-template-columns: 85px 1fr 80px; 
  }
`

export const StyledRefreshIcon = styled(IconRefresh)<{ $spinning?: boolean }>`
  transition: opacity 0.2s;

  animation-name: ${rotate};
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;

  animation-play-state: ${({ $spinning }) => ($spinning ? 'running' : 'paused')};
`

export function AiMessageGeneratorButton(props: AiMessageGeneratorButtonProps): JSX.Element | null {
  const { style, className, ButtonProps = {} } = props
  const { aiMessageGeneratorPopoverOpen, setAiMessageGeneratorPopoverOpen } = useAiMessageGenerator()
  const popoverRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const isPlaying = useIsMutating([AI_INTERFACE])

  return (
    <Layout
      alignItems="center"
      className={className}
      display="flex"
      justifyContent="center"
      marginRight="auto"
      position="relative"
      style={style}
    >
      <Button
        onClick={() => {
          setAiMessageGeneratorPopoverOpen(!aiMessageGeneratorPopoverOpen)

          analytics.track(analytics.events.GPT.AIMessageGeneratorButtonPressed())
        }}
        ref={popoverRef}
        style={{
          width: '100%',
          padding: '4px 10px 4px 5px',
          background:
            'linear-gradient(91.48deg, rgba(247, 237, 229, 0.1) 4.19%, rgba(202, 241, 225, 0.1) 29.29%, rgba(243, 228, 241, 0.1) 67.01%, rgba(237, 242, 235, 0.1) 88.76%, rgba(247, 237, 229, 0) 103.93%)',
        }}
        variant={BUTTON_VARIANTS.OUTLINE}
        {...ButtonProps}
      >
        <Layout alignItems="center" display="flex" justifyContent="center">
          <AiBubble isPlaying={!!isPlaying} style={{ width: 23, height: 23, marginRight: 10 }} />

          {t('aiMessageGenerator.buttonTitle')}
        </Layout>
      </Button>
      <Popover
        align="left"
        isOpen={aiMessageGeneratorPopoverOpen}
        onClose={() => setAiMessageGeneratorPopoverOpen(false)}
        showBorder={true}
        targetRef={popoverRef}
        width="auto"
      >
        <AiMessageGeneratorPopover />
      </Popover>
    </Layout>
  )
}

export function AiMessageGeneratorPopover(): JSX.Element {
  const theme = useTheme() || {}
  const {
    setAiMessageGeneratorOpen,
    setInsertedSuggestedMessage,
    setAnalyticsEventSuggested,
    setAnalyticsEventSuggestMode,
  } = useAiMessageGenerator()
  const { setComposeMessage, scheduledAt } = useCompose()
  const { data: client } = useClient()
  const clientId = useClientId()
  const { showToastMessage } = useToastMessage()
  const { t } = useTranslation()
  const { md } = useBreakpoints()

  const mutationPostSuggestQuick = useMutation(postSuggestQuick, {
    mutationKey: [AI_INTERFACE, QUICK_SUGGEST],
    retry: 3,
  })

  const isLoadingSuggestions = useIsMutating([AI_INTERFACE, QUICK_SUGGEST])

  return (
    <Layout maxWidth="490px" padding={`${SPACING[2]} ${SPACING[5]} ${SPACING[2]}`} width="100%">
      <Layout alignItems="center" display="flex">
        <Badge fontSize="14px" marginRight="5px" paddingLeft="10px" paddingRight="10px">
          {t('beta')}
        </Badge>
        <Typography fontSize={md ? '14px' : '12px'}>
          <Trans i18nKey="aiMessageGenerator.legalText">
            <StyledLink
              href="https://www.community.com/legal/third-party-disclosures"
              rel="noopener noreferrer"
              target="_blank"
            />
          </Trans>
        </Typography>
      </Layout>

      <Layout
        as="hr"
        borderStyle="initial"
        borderTop={`1px solid ${theme?.COLORS?.BORDERS}`}
        margin={`${SPACING[2]} ${SPACING[0]} ${SPACING[0]}`}
        width="100%"
      />

      <AiMessageGeneratorPopoverButton
        aria-label={t('aiMessageGenerator.quickSuggestButtonLabel')}
        disabled={!!isLoadingSuggestions}
        onClick={() => {
          mutationPostSuggestQuick
            .mutateAsync({
              clientId,
              body: {
                client_name: formatFullName(client),
                max_length: 300,
                count: 1,
                message_date: scheduledAt ? scheduledAt.toISOString() : new Date().toISOString(),
              },
            })
            .then((response) => {
              const text = response.data.suggestions[0].text
              setComposeMessage(text)

              setInsertedSuggestedMessage(text)
              setAnalyticsEventSuggested(AnalyticsEventSuggested.FULL)
              setAnalyticsEventSuggestMode(AnalyticsEventSuggestMode.QUICK)
            })
            .catch(() => {
              showToastMessage({
                message: t('aiMessageGenerator.quickSuggestError'),
                success: false,
              })
            })

          analytics.track(analytics.events.GPT.QuickSuggestButtonPressed())
        }}
      >
        {md && (
          <Layout alignItems="center" display="flex" height="100%" justifyContent="flexStart">
            <IconDice />
          </Layout>
        )}

        <Layout textAlign="left">
          <Badge
            background="#F8FFFB"
            color="#000000"
            fontSize={FONT_SIZE[3]}
            left="-5px"
            paddingLeft={SPACING[2]}
            paddingRight={SPACING[2]}
            position="relative"
          >
            {t('aiMessageGenerator.quickSuggest')}
          </Badge>
          <Layout>
            <Typography display="block" fontSize={FONT_SIZE[3]} lineHeight="20px" marginTop={SPACING[1]}>
              {t('aiMessageGenerator.quickSuggestDescription')}
            </Typography>
          </Layout>
        </Layout>

        <Layout alignItems="center" display="flex" height="100%" justifyContent="center">
          <StyledRefreshIcon $spinning={!!isLoadingSuggestions} />
        </Layout>
      </AiMessageGeneratorPopoverButton>

      <Layout
        as="hr"
        borderStyle="initial"
        borderTop={`1px solid ${theme?.COLORS?.BORDERS}`}
        gridColumn="1/4"
        margin={SPACING[0]}
        width="100%"
      />

      <AiMessageGeneratorPopoverButton
        aria-label={t('aiMessageGenerator.fullSuggestButtonLabel')}
        onClick={() => {
          setAiMessageGeneratorOpen(true)

          analytics.track(analytics.events.GPT.FullAIMessageGeneratorButtonPressed())
        }}
      >
        {md && (
          <Layout alignItems="center" display="flex" height="100%" justifyContent="flexStart">
            <IconCompose />
          </Layout>
        )}

        <Layout textAlign="left">
          <Badge
            background="#FFF0FC"
            color="#000000"
            fontSize={FONT_SIZE[3]}
            left="-5px"
            paddingLeft={SPACING[2]}
            paddingRight={SPACING[2]}
            position="relative"
          >
            {t('aiMessageGenerator.fullSuggest')}
          </Badge>
          <Layout>
            <Typography display="block" fontSize={FONT_SIZE[3]} lineHeight="20px" marginTop={SPACING[1]}>
              {t('aiMessageGenerator.fullSuggestDescription')}
            </Typography>
          </Layout>
        </Layout>

        <Layout alignItems="center" display="flex" height="100%" justifyContent="center">
          <IconNewTab />
        </Layout>
      </AiMessageGeneratorPopoverButton>
    </Layout>
  )
}
