import { FONT_SIZE } from '@community_dev/pixels'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { useHasCapability } from 'hooks/useUserCapability'
import analytics from 'utils/analytics'
import { route } from 'utils/router'

const StyledDiv = styled.div`
  font-size: ${FONT_SIZE[2]};
  display: block;
`

const StyledRouterLink = styled(Link)`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const StyledExternalLink = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export enum MESSAGE_SOURCE {
  DM = 'DM',
  CAMPAIGN = 'CAMPAIGN',
  KEYWORD_RESPONSE = 'KEYWORD_RESPONSE',
  SEAT_ONBOARDING = 'SEAT_ONBOARDING',
  AUTOMATED_METADATA_TYPE_NOT_SET = 'AUTOMATED_METADATA_TYPE_NOT_SET',
  AUTOMATED_METADATA_TYPE_WORKFLOW = 'AUTOMATED_METADATA_TYPE_WORKFLOW',
  AUTOMATED_METADATA_TYPE_ZAPIER = 'AUTOMATED_METADATA_TYPE_ZAPIER',
  NOT_SET = 'NOT_SET',
  EXTERNAL = 'EXTERNAL',
  EXTERNAL_CUSTOMER_SUPPORT = 'EXTERNAL_CUSTOMER_SUPPORT',
}

export type MessageSourceType = `${MESSAGE_SOURCE}`

export type MessageSourceProps = {
  className?: string
  style?: React.CSSProperties
  sourceType?: MessageSourceType
  sourceTypeId?: string
}

export const MessageSource = (props: MessageSourceProps): JSX.Element | null => {
  const { className, style, sourceType, sourceTypeId } = props
  const { t } = useTranslation()
  const isAutomationsEnabled = useHasCapability(CAPABILITIES.FEATURE.WORKFLOWS.ALL)

  switch (sourceType) {
    case MESSAGE_SOURCE.AUTOMATED_METADATA_TYPE_WORKFLOW: {
      const linkDestination = isAutomationsEnabled
        ? typeof sourceTypeId !== 'undefined'
          ? route(ROUTES.AUTOMATIONS.FLOW, { workflowId: sourceTypeId })
          : ROUTES.AUTOMATIONS.FLOWS
        : ROUTES.SETTINGS.SIGNUP_MESSAGES

      return (
        <StyledDiv className={className} data-testid="message-source-automated" style={style}>
          <StyledRouterLink
            aria-label={t('sourceType.viewAutomation')}
            onClick={() => {
              analytics.track(analytics.events.MessageSourceClicked(sourceType))
            }}
            to={linkDestination}
          >
            {t('sourceType.automation')}
          </StyledRouterLink>
        </StyledDiv>
      )
    }
    case MESSAGE_SOURCE.AUTOMATED_METADATA_TYPE_ZAPIER:
      return (
        <StyledDiv className={className} data-testid="message-source-zapier" style={style}>
          <StyledExternalLink
            aria-label={t('sourceType.viewZapier')}
            href="https://zapier.com/app/dashboard"
            onClick={() => {
              analytics.track(analytics.events.MessageSourceClicked(sourceType))
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('sourceType.zapier')}
          </StyledExternalLink>
        </StyledDiv>
      )
    case MESSAGE_SOURCE.KEYWORD_RESPONSE:
      return (
        <StyledDiv className={className} data-testid="message-source-keyword-response" style={style}>
          <StyledRouterLink
            aria-label={t('sourceType.viewKeywordResponder')}
            onClick={() => {
              analytics.track(analytics.events.MessageSourceClicked(sourceType))
            }}
            to={ROUTES.SETTINGS.CUSTOMIZE.ROOT}
          >
            {t('sourceType.keywordResponder')}
          </StyledRouterLink>
        </StyledDiv>
      )
    case MESSAGE_SOURCE.EXTERNAL:
      return (
        <StyledDiv className={className} data-testid="message-source-external" style={style}>
          {t('sourceType.external')}
        </StyledDiv>
      )
    case MESSAGE_SOURCE.EXTERNAL_CUSTOMER_SUPPORT:
      return (
        <StyledDiv className={className} data-testid="message-source-external-customer-support" style={style}>
          {t('sourceType.externalCustomerSupport')}
        </StyledDiv>
      )
    case MESSAGE_SOURCE.DM:
    case MESSAGE_SOURCE.CAMPAIGN:
    case MESSAGE_SOURCE.SEAT_ONBOARDING:
    case MESSAGE_SOURCE.AUTOMATED_METADATA_TYPE_NOT_SET:
    case MESSAGE_SOURCE.NOT_SET:
    default:
      return null
  }
}
