import merge from 'lodash/merge'
import random from 'lodash/random'
import range from 'lodash/range'

type BestHoursReturn = {
  [key: string]: {
    [key: string]: number
  }
}

export const createBestHours = (overrides: any = {}): BestHoursReturn => {
  const days = range(1, 8)
  const hours = range(0, 24)

  const bestDays = days.map((day) => {
    const bestHours = hours.map((hour) => [`${hour}`, random(0, 5)])
    return [`${day}`, Object.fromEntries(bestHours)]
  })

  return merge(Object.fromEntries(bestDays), overrides)
}
