/**
 * deterministically generate a color ('#000000') from a string so that the same string will always return the same color
 */
export const stringToColor = (str = ''): string => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let hexColor = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    hexColor += value.toString(16).padStart(2, '0')
  }
  return hexColor
}
