import { Info, SPACING } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useCompose } from '../ComposeContext'

const StyledInfo = styled(Info)`
  margin-top: ${SPACING[2]};
  margin-bottom: 0;
`

export function ScheduledBirthdayInfo(): JSX.Element | null {
  const { t } = useTranslation()
  const { isCountLoading, recipientCount, frequency } = useCompose()

  if (isCountLoading) return null

  if (recipientCount === 0 && !frequency) {
    return <StyledInfo>{t('scheduled.noBirthdaysChooseAnother')}</StyledInfo>
  }

  return null
}
