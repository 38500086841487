import { ApiError } from '@community_dev/requests'
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { useClientId } from '../useClient'
import { useHasCapability } from '../useUserCapability'

import { ClientSettings, getClientSettings } from 'api/client'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERY_CACHE } from 'constants/query-cache'

export const useClientSettingsQuery = <SelectData = ClientSettings>(
  options?: UseQueryOptions<ClientSettings, ApiError, SelectData>,
): UseQueryResult<SelectData, ApiError> => {
  const clientId = useClientId()
  const hasMMSCapability = useHasCapability(CAPABILITIES.FEATURE.MMS_MESSAGING.ALL)

  const query = useQuery({
    queryKey: [QUERY_CACHE.SETTINGS.CLIENT_SETTINGS, clientId, hasMMSCapability],
    queryFn: () => getClientSettings(clientId),
    enabled: Boolean(clientId),
    refetchOnMount: false,
    ...options,
  })

  return query
}
