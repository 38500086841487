import * as launchDarkly from 'launchdarkly-js-client-sdk'
import { LDClient } from 'launchdarkly-js-client-sdk'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

import { useSeat } from 'contexts/bootstrap/useSeat'
import { useClient } from 'hooks/useClient'
import Sentry from 'integrations/Sentry'
import { formatFullName } from 'utils/formatters'

export type LDCClientExtended = LDClient & { isReady?: boolean }

export const LaunchDarklyContext = createContext<LDCClientExtended | undefined>(undefined)

LaunchDarklyContext.displayName = 'LaunchDarklyContext'

export function useLaunchDarklyContext(): LDCClientExtended | undefined {
  const context = useContext(LaunchDarklyContext)

  return context
}

declare global {
  interface Window {
    ldcRef: LDCClientExtended
  }
}

type LaunchDarklyProviderProps = {
  children?: React.ReactNode
}

export function LaunchDarklyProvider({ children }: LaunchDarklyProviderProps): JSX.Element {
  const [isReady, setIsReady] = useState(false)
  const ldcRef = useRef<LDCClientExtended>()

  const { data: seat } = useSeat()
  const { data: client } = useClient()

  useEffect(() => {
    if (!import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID) {
      throw new Error('Launch darkly client id required')
    }

    if (seat?.id) {
      const context: launchDarkly.LDContext = {
        kind: 'user',
        key: seat.id,
        name: formatFullName(seat),
        clientId: client?.id,
        clientName: formatFullName(client || {}),
        appVersion: import.meta.env.VITE_VERSION,
      }

      const launchDarklyClient = launchDarkly.initialize(`${import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID}`, context, {
        sendEvents: import.meta.env.VITE_SEND_LAUNCH_DARKLY_EVENTS !== 'false',
      })

      ldcRef.current = launchDarklyClient

      const onReady = () => {
        setIsReady(true)
      }

      const onChange = () => {
        ldcRef.current = launchDarklyClient
      }

      launchDarklyClient.on('ready', onReady)

      if (!window.Cypress) {
        launchDarklyClient.on('change', onChange)
      }

      return () => {
        setIsReady(false)
        launchDarklyClient.off('ready', onReady)

        if (!window.Cypress) {
          launchDarklyClient.off('change', onChange)
        }
      }
    }
  }, [seat?.id, client?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    Sentry.setContext('feature flags', ldcRef.current?.allFlags() || null)
  }, [ldcRef])

  let value: LDCClientExtended | undefined

  if (ldcRef.current) {
    window.ldcRef = ldcRef.current

    value = {
      ...ldcRef.current,
      isReady,
    }
  }

  return <LaunchDarklyContext.Provider value={value}>{children}</LaunchDarklyContext.Provider>
}
