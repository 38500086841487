import { Api } from '@community_dev/types'
import noop from 'lodash/noop'
import React, { useContext, useState, useCallback, useEffect } from 'react'

import { useAuth } from 'hooks/useAuth'
import Sentry from 'integrations/Sentry'
import analytics from 'utils/analytics'
import { baseConfig } from 'utils/config'
import { formatMediaHref } from 'utils/normalize'

const { apiUrl } = baseConfig
const uploadEndpointUrl = `${apiUrl}/client-dashboard/media/upload`

type ErrorWithDetails = Error & {
  details?: any
}

export type ErrorData = {
  statusText: string
  responseText: string
  size?: number
  type?: string
}

export type UploadFileResult = {
  data: Api.V2.Media
}

export type UploadMediaFileResult = {
  data: {
    href: string
    url: string
  }
}

type UploadFile = ({ url, file }: { url?: string; file?: File }) => Promise<UploadFileResult | UploadMediaFileResult>

export const initialState: UploadContextValue = {
  file: undefined,
  setFile: noop,
  data: undefined,
  success: false,
  error: null,
  progress: 0,
  uploadFile: noop as UploadFile,
  uploading: false,
  resetUpload: noop,
}

export type UploadContextValue = {
  file: File | undefined
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>
  success: boolean
  error: ErrorData | null
  progress: number
  data?: Api.V2.Media
  uploadFile: UploadFile
  uploading: boolean
  resetUpload: () => void
}

export const UploadContext = React.createContext<UploadContextValue>(initialState)

UploadContext.displayName = 'UploadContext'

export function useUpload(): UploadContextValue {
  const context = useContext(UploadContext)

  if (!context) {
    throw new Error('useUpload must be used within a UploadProvider')
  }

  return context
}

type UploadProviderProps = {
  children?: React.ReactNode
}

export function UploadProvider({ children }: UploadProviderProps): JSX.Element {
  // file represents the local file selected by the user, not uploaded to the server
  const [file, setFile] = useState<File>()
  //  media represents the media object after we successfully upload
  const [data, setData] = useState<Api.V2.Media>()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<ErrorData | null>(null)
  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const { token } = useAuth()

  useEffect(() => {
    if (!window.Cypress) {
      window.onbeforeunload = function onbeforeunload(e) {
        if (uploading) {
          e.preventDefault()
          e.returnValue = 'Uploading'
        }
      }
    }
  }, [uploading])

  const uploadFile: UploadFile = useCallback(
    ({ url = uploadEndpointUrl, file }) => {
      return new Promise((resolve, reject) => {
        // we're using XHR so we can report on progress
        const xhr = new XMLHttpRequest()

        setSuccess(false)
        setError(null)
        setProgress(0)
        setUploading(true)

        let uploadData: FormData | File | undefined = file
        const METHOD = url === uploadEndpointUrl ? 'POST' : 'PUT'

        const onProgress = (e) => {
          if (e.lengthComputable) setProgress((e.loaded / e.total) * 100)
        }

        const onSuccess = (response) => {
          const { url } = response.data
          response.data.href = formatMediaHref(url)

          setSuccess(true)
          setData(response)
          resolve(response)
          removeEventListeners()
          setUploading(false)
          setProgress(0)
        }

        const onError = () => {
          let { responseText } = xhr
          const { statusText } = xhr

          try {
            responseText = JSON.parse(responseText)?.error
          } catch (e) {
            // do nothing
          }

          const errorData: ErrorData = {
            statusText: statusText || 'Error',
            responseText,
            size: file?.size,
            type: file?.type,
          }

          setUploading(false)
          setProgress(0)
          handleError(errorData)
        }

        const onTimeout = () => {
          const errorData: ErrorData = {
            statusText: 'Timeout',
            responseText: 'Upload Timeout',
            size: file?.size,
            type: file?.type,
          }

          setUploading(false)
          setProgress(0)
          handleError(errorData)
        }

        const onAbort = () => {
          const errorData: ErrorData = {
            statusText: 'Aborted',
            responseText: 'Upload Aborted',
            size: file?.size,
            type: file?.type,
          }

          setUploading(false)
          setProgress(0)
          handleError(errorData)
        }

        const removeEventListeners = () => {
          xhr.upload.removeEventListener('progress', onProgress)
          xhr.upload.removeEventListener('error', onError)
          xhr.upload.removeEventListener('abort', onAbort)
          xhr.upload.removeEventListener('timeout', onTimeout)
          xhr.onreadystatechange = null
          xhr.abort()
        }

        const handleError = (errorData: ErrorData) => {
          setError(errorData)
          removeEventListeners()
          analytics.track(analytics.events.UploadFailure(errorData))

          const err = new Error('File upload failed') as ErrorWithDetails

          Sentry.captureException(err, {
            extra: errorData,
          })

          err.details = errorData

          reject(err)
        }

        xhr.upload.addEventListener('progress', onProgress)
        xhr.upload.addEventListener('error', onError)
        xhr.upload.addEventListener('abort', onAbort)
        xhr.upload.addEventListener('timeout', onTimeout)
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== 4) return
          if (xhr.status >= 200 && xhr.status < 300) {
            const response = METHOD === 'POST' ? JSON.parse(xhr.response) : { data: { url } }
            onSuccess(response)
          } else {
            onError()
          }
        }

        xhr.open(METHOD, url, true)

        if (METHOD === 'POST' && file) {
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)
          uploadData = new FormData()
          uploadData.append('media', file)
        }
        xhr.send(uploadData)
      })
    },
    [token],
  )

  const resetUpload = () => {
    setFile(undefined)
    setData(undefined)
    setSuccess(false)
    setError(null)
    setProgress(0)
    setUploading(false)
  }

  return (
    <UploadContext.Provider
      value={{
        file,
        setFile,
        success,
        error,
        progress,
        data,
        uploadFile,
        uploading,
        resetUpload,
      }}
    >
      {children}
    </UploadContext.Provider>
  )
}
