import React, { useCallback, useEffect, useState } from 'react'

import { PasswordForm } from 'components/form'
import { useChangePassword } from 'hooks/useChangePassword'
import notifs from 'utils/notifs'

export const PasswordChange = (): JSX.Element => {
  const [notif, setNotif] = useState({})
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(true)
  const { isSubmitting, isSuccess, isValidating, error, onSubmit } = useChangePassword()

  useEffect(() => {
    if (isSuccess && !error) {
      setNotif(notifs.passwordChanged)
    } else if (!isSuccess && error) {
      setNotif(notifs.passwordUnchanged)
    }
  }, [isSuccess, error])

  const onChange = useCallback(
    ({ password }) => {
      setIsPasswordEmpty(!password)
    },
    [setIsPasswordEmpty],
  )

  if (isValidating) return <></>

  const buttonText = isPasswordEmpty ? 'Reset Password' : 'Save Password'

  return (
    <div>
      <PasswordForm
        buttonText={isSubmitting ? 'Saving...' : buttonText}
        labelText="New Password"
        notif={isSubmitting ? {} : notif}
        onChange={onChange}
        onSubmit={onSubmit}
        titleText="Reset Password"
      />
    </div>
  )
}
