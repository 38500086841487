import { Action, isAction, Manifest, ManifestFunction } from '@community_dev/workflow-manifest'
import { useParams } from 'react-router'

import { useWorkflowProvider } from '../context/WorkflowProvider'

export type UseWorkflowResult<T> = {
  functionKey: string
  functionIndex: number
  planItem?: ManifestFunction
  action?: T
  manifest?: Manifest
}

/**
 * This hook pulls the functionIndex, functionKey, and manifest from the URL path
 * and then attempts to retrieve the plan step as well as the action from the manifest
 */
export function useManifestAction<T extends Action>(): UseWorkflowResult<T> {
  const { manifest } = useWorkflowProvider()
  const { functionKey, functionIndex } = useParams<{ functionKey: string; functionIndex: string }>()
  const planItem = manifest?.functions?.[functionKey]?.[functionIndex]

  let action: T | undefined = undefined

  if (isAction(planItem)) {
    action = manifest?.actions?.[planItem?.action] as T
  }

  return {
    functionKey,
    functionIndex: Number(functionIndex),
    planItem,
    action,
  }
}
