import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { UseInfiniteQueryOptions, UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query'

import { Campaign, PaginatedCampaigns, SortDirection, SortField, getCampaignList } from 'api/campaign'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { addPendingMedia } from 'hooks/useCampaign'
import { useClientId } from 'hooks/useClient'
import Sentry from 'integrations/Sentry'

export enum CampaignFilter {
  API = 'api',
  BIRTHDAY = 'birthday',
  TEST = 'test',
}

export const CampaignFilterStorage = {
  [CampaignFilter.API]: `campaignList.filters.${CampaignFilter.API}`,
  [CampaignFilter.BIRTHDAY]: `campaignList.filters.${CampaignFilter.BIRTHDAY}`,
  [CampaignFilter.TEST]: `campaignList.filters.${CampaignFilter.TEST}`,
}

export type CampaignFilters = {
  // Should not be optional once fully released to every user
  [CampaignFilter.API]?: boolean
  [CampaignFilter.BIRTHDAY]: boolean
  [CampaignFilter.TEST]: boolean
}

export const DEFAULT_CAMPAIGN_FILTERS: CampaignFilters = {
  [CampaignFilter.API]: false,
  [CampaignFilter.BIRTHDAY]: true,
  [CampaignFilter.TEST]: true,
}

type UseCampaignListReturn = UseInfiniteQueryResult<Campaign, unknown>

type UseCampaignListOptions = Partial<UseInfiniteQueryOptions<PaginatedCampaigns, unknown, Campaign>> & {
  communicationChannels?: CommunicationChannel[]
  filters?: CampaignFilters
  pageSize?: number
  searchTerm?: string
  sortDirection?: SortDirection
  sortField?: SortField | null
}

export function useCampaignList(props: UseCampaignListOptions = {}): UseCampaignListReturn {
  const { searchTerm, sortDirection, sortField, communicationChannels, filters, pageSize = 50, ...options } = props
  const clientId = useClientId()

  return useInfiniteQuery({
    queryKey: [
      QUERY_CACHE.CAMPAIGN.LIST,
      { clientId, filters, searchTerm, sortDirection, sortField, communicationChannels, pageSize },
    ],
    queryFn: async ({ pageParam }): Promise<PaginatedCampaigns> => {
      const campaignList = await getCampaignList({
        clientId,
        communicationChannels,
        filters,
        pageParam,
        pageSize,
        searchTerm,
        sortDirection,
        sortField,
      })

      try {
        const dataWithPendingMedia = await Promise.all(campaignList.data.map(addPendingMedia))
        return { ...campaignList, data: dataWithPendingMedia }
      } catch (error) {
        Sentry.captureException(error)
        return campaignList
      }
    },
    enabled: !!clientId,
    staleTime: STALE_TIME.FIVE_MINUTES,
    getNextPageParam: (lastPage) =>
      lastPage?.paginationData?.hasNextPage && (lastPage?.paginationData?.pageNumber || 0) + 1,
    select: (data) => ({
      ...data,
      pages: data.pages.flatMap((page) => page.data),
    }),
    ...options,
  })
}
