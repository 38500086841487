import React from 'react'

import { SimpleLayout } from './SimpleLayout'

export const withSimpleLayout =
  (WrappedComponent: React.ComponentType<any>, showContextSwitcher = false) =>
  (): JSX.Element =>
    (
      <SimpleLayout showContextSwitcher={showContextSwitcher}>
        {({ contentRef }) => <WrappedComponent scrollRef={contentRef} />}
      </SimpleLayout>
    )
