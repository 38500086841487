import { LoadingIndicator } from '@community_dev/pixels'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { History } from 'history'
import { Suspense, useEffect, useRef } from 'react'
import { matchPath, useHistory } from 'react-router'

import { GlobalStyle, StyledApp } from './App.style'

import { LexicalComposerProvider } from 'components/ComposeMessage'
import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import AppDialog from 'containers/AppDialog'
import AppToast from 'containers/AppToast'
import { AuthenticatedApp } from 'containers/AuthenticatedApp'
import { UnauthenticatedApp } from 'containers/UnauthenticatedApp'
import { AuthStates } from 'contexts/AuthProvider'
import { BoundsProvider } from 'contexts/BoundsProvider'
import { useLaunchDarklyContext } from 'contexts/LaunchDarklyProvider'
import { useAuth } from 'hooks/useAuth'
import { useClientId } from 'hooks/useClient'
import { useHasCapability } from 'hooks/useUserCapability'
import analytics from 'utils/analytics'
import { analyticsConfig } from 'utils/config'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

const AuthenticatedStates = [AuthStates.AUTHENTICATED, AuthStates.CONTEXT_SELECTION]

// Inits analytics and sets up the pageView event
const useInitAnalytics = (history: History<unknown>) => {
  const clientId = useClientId()
  const fullPathName = useRef(`${window.location.pathname}${window.location.search}`)

  useEffect(() => {
    if (clientId) {
      // initialize analytics
      const { gaTrackingId, mixpanelToken } = analyticsConfig
      analytics.init({
        clientId,
        gaTrackingId,
        mixpanelToken,
      })

      const isImpersonateRoute = Boolean(matchPath(window.location.pathname, { path: ROUTES.IMPERSONATION }))

      // trigger a pageView onLoad page event except if its an impersonation route
      !isImpersonateRoute && analytics.pageView(fullPathName.current)

      // all other page events
      history.listen((location) => {
        const newFullPathName = `${location.pathname}${location.search}`
        // only trigger a pageView if its a new pathname
        if (fullPathName.current !== newFullPathName) {
          fullPathName.current = newFullPathName
          const isImpersonateRoute = Boolean(matchPath(location.pathname, { path: ROUTES.IMPERSONATION }))
          // trigger a pageView event except if its an impersonation route
          !isImpersonateRoute && analytics.pageView(newFullPathName)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])
}

const App = (): JSX.Element => {
  const history = useHistory()
  const { state: authState, token } = useAuth()
  const authenticated = AuthenticatedStates.includes(authState)
  const launchDarklyContext = useLaunchDarklyContext()

  const hasReactQueryDevTools = useHasCapability(CAPABILITIES.DEBUG.REACT_QUERY_DEVTOOLS)

  useInitAnalytics(history)

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <LexicalComposerProvider>
        <BoundsProvider>
          <GlobalStyle />
          <StyledApp id="app">
            {!authenticated && <UnauthenticatedApp />}
            {authenticated && (!launchDarklyContext?.isReady || !token ? <LoadingIndicator /> : <AuthenticatedApp />)}
          </StyledApp>
          <AppToast />
          <AppDialog />
          <div id="modal-root" />
        </BoundsProvider>
      </LexicalComposerProvider>
      {hasReactQueryDevTools && <ReactQueryDevtools initialIsOpen={false} />}
    </Suspense>
  )
}

export default App
