import { SPACING, Typography, Button, Layout, LoadingIndicator, Tooltip } from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import { RoleGroup } from '@community_dev/types/lib/api/v1/Roles'
import { useQuery } from '@tanstack/react-query'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { StyledTooltipContent } from './styled'
import { TeamMemberEditModal } from './TeamMemberEditModal'
import { TeamMemberInviteModal } from './TeamMemberInviteModal'
import { TeamMemberRow } from './TeamMemberRow'

import { getClientSeatInvites, getClientSeats } from 'api/team-seats'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERY_CACHE } from 'constants/query-cache'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useClientId } from 'hooks/useClient'
import { useClientSettings } from 'hooks/useClientSettings/useClientSettings'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import { SettingsSection } from 'layouts/SettingsLayout'

export function Team(): JSX.Element {
  const { data: userSeat } = useSeat()
  const clientId = useClientId()
  const canEditSeats = useHasCapability(CAPABILITIES.CLIENT.SEATS.WRITE)
  const { showToastMessage } = useToastMessage()
  const { t } = useTranslation()
  const { data: clientSettings } = useClientSettings({})
  // get seats list
  const {
    data: seats,
    isLoading: isLoadingSeats,
    refetch: refetchSeats,
  } = useQuery([QUERY_CACHE.SETTINGS.TEAM_SEATS, { clientId }], () => getClientSeats(clientId), {
    onError() {
      showToastMessage({ message: t('unknownServerError'), success: false })
    },
  })
  // get invites
  const {
    data: invites,
    isLoading: isLoadingInvites,
    refetch: refetchInvites,
  } = useQuery([QUERY_CACHE.SETTINGS.TEAM_INVITES, { clientId }], () => getClientSeatInvites(clientId), {
    initialData: [],
    onError() {
      showToastMessage({ message: t('unknownServerError'), success: false })
    },
  })
  const canAddSeats = useMemo(() => {
    if (clientSettings !== undefined && seats !== undefined && invites !== undefined) {
      return clientSettings.clientSeatsMax > seats.length + invites.length
    } else {
      return false
    }
  }, [clientSettings, invites, seats])

  const hasProtectedRole = (seat: CamelCasedPropertiesDeep<Api.V1.Seat>) =>
    seat.roles.some((role) => role.protected === true)

  const [selectedSeat, setSelectedSeat] = useState<CamelCasedPropertiesDeep<Api.V1.Seat> | null>(null)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)

  const [ownerSeats, otherSeats] = useMemo(
    () =>
      partition(
        // order seats by primary role
        orderBy(seats, (seat) => seat.roles.find((role) => role.group === RoleGroup.PRIMARY)?.name),
        // partition owners
        (seat) => seat.roles.some((r) => r.name === 'owner'),
      ),
    [seats],
  )
  return (
    <>
      <Layout display="flex" marginBottom={SPACING[7]}>
        <Typography component="h2" margin="0" variant="h3">
          {t('settings.team.seats')} {seats && `(${seats.length})`}
        </Typography>

        {canEditSeats && (
          <Tooltip
            content={
              <StyledTooltipContent>
                <Trans i18nKey="settings.team.seatsMaxReached">
                  <a href="mailto:yourfriends@community.com">yourfriends@community.com</a>
                </Trans>
              </StyledTooltipContent>
            }
            disabled={canAddSeats}
            interactive
          >
            <Button
              disabled={!canAddSeats}
              onClick={() => (canAddSeats ? setIsInviteModalOpen(true) : undefined)}
              style={{
                marginLeft: 'auto',
                pointerEvents: 'auto',
                cursor: canAddSeats ? 'pointer' : 'not-allowed',
              }}
              variant="outline"
            >
              {t('settings.team.addSeat')}
            </Button>
          </Tooltip>
        )}
      </Layout>
      {(isLoadingSeats || isLoadingInvites) && <LoadingIndicator size={20} />}
      {invites.length > 0 && (
        <SettingsSection aria-label={t('settings.team.memberInvitations')} role="list">
          {invites.map((invite) => (
            <TeamMemberRow item={invite} key={invite.id} />
          ))}
        </SettingsSection>
      )}
      <SettingsSection aria-label={t('settings.team.accountOwners')} role="list">
        {ownerSeats.map((seat) => (
          <TeamMemberRow item={seat} key={seat.id} onClick={() => setSelectedSeat(seat)} />
        ))}
      </SettingsSection>
      {otherSeats.length > 0 && (
        <SettingsSection aria-label={t('settings.team.teamMembers')} role="list">
          {otherSeats.map((seat) => (
            <TeamMemberRow item={seat} key={seat.id} onClick={() => setSelectedSeat(seat)} />
          ))}
        </SettingsSection>
      )}
      {isInviteModalOpen && (
        <TeamMemberInviteModal
          onClose={() => {
            setIsInviteModalOpen(false)
            refetchInvites()
          }}
        />
      )}
      {selectedSeat !== null && (
        <TeamMemberEditModal
          isReadOnly={!canEditSeats || selectedSeat.id === userSeat?.id || hasProtectedRole(selectedSeat)}
          onClose={() => setSelectedSeat(null)}
          onSaved={refetchSeats}
          seat={selectedSeat}
        />
      )}
    </>
  )
}
