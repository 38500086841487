import { CommunityFlagIcon, BUTTON_VARIANTS, Button, SPACING, Layout, Typography } from '@community_dev/pixels'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router'
import { useTheme } from 'styled-components'

import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { useRedirectFromSetupIntent } from 'hooks/billing/useRedirectFromSetupIntent'
import { useToastMessage } from 'hooks/useToastMessage'
import SimpleLayout from 'layouts/SimpleLayout'

const SuccessScreen = (): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()

  // Context contains the users billingProvided value
  // Once we add payment the backend will listen to the webhook and set billingProvided to true
  // We will clear cache after 3 seconds or when the user clicks next
  const clearContextCache = () => {
    queryClient.invalidateQueries([QUERY_CACHE.CONTEXTS])
  }
  const queryClient = useQueryClient()
  useEffect(() => {
    setTimeout(clearContextCache, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SimpleLayout showContextSwitcher>
      <Layout alignSelf="stretch" background={COLORS?.APP_BACKGROUND_LEVEL_3} flex={1} justifyContent="center">
        <Layout
          alignItems="center"
          alignSelf="center"
          display="flex"
          flexDirection="column"
          marginTop={SPACING[10]}
          padding={`0 ${SPACING[5]}`}
          textAlign="center"
        >
          <CommunityFlagIcon size={100} />
          <Typography marginBottom="0" marginTop={SPACING[7]} variant="h1">
            {t('billing.paymentAddedSuccessfully')}
          </Typography>
          <Typography marginBottom={SPACING[7]} marginTop={SPACING[4]} variant="body1">
            {t('billing.youWillBeRedirected')}
          </Typography>
          <Button onClick={clearContextCache} variant={BUTTON_VARIANTS.ACTION}>
            {t('next')}
          </Button>
        </Layout>
      </Layout>
    </SimpleLayout>
  )
}

const ErrorScreen = (): JSX.Element => {
  const { showToastMessage } = useToastMessage()
  const { t } = useTranslation()

  useEffect(() => {
    showToastMessage({
      message: t('settings.billing.genericError'),
      success: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Redirect to={ROUTES.CONFIRM_PAYMENT_DETAILS.ROOT} />
}

export const SetupIntentScreen = (): JSX.Element => {
  const { isSuccess, isError } = useRedirectFromSetupIntent()

  if (isSuccess) {
    return <SuccessScreen />
  }

  if (isError) {
    return <ErrorScreen />
  }
  // if no params, redirect to collect billing.
  // Should be unreachable unless we type the url directly and they still haven't inputted billing details
  return <Redirect to={ROUTES.CONFIRM_PAYMENT_DETAILS.ROOT} />
}
