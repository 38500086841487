import { Box, LoadingIndicator, Toast } from '@community_dev/pixels'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useHistory } from 'react-router'

import { EditBilling as EditRecurlyBilling } from '../EditBilling'
import { NonStandardRecurlySubscription } from '../NonStandardRecurlySubscription'

import { BillingHistory } from './BillingHistory/BillingHistory'
import { EditStripeBilling } from './EditStripeBilling'
import { EnterpriseBillingSummary } from './EnterpriseBilling/EnterpriseBillingSummary'
import { SegmentEnterpriseActivity } from './EnterpriseBilling/SegmentEnterpriseActivity/SegmentEnterpriseActivity'
import { MessageLimitRow } from './MessageLimitRow'
import { PlanDetailsRow } from './PlanDetailsRow'
import { PlanPaymentMethodRow } from './PlanPaymentMethodRow'

import { CAPABILITIES } from 'constants/capabilities'
import { LEGACY_ESSENTIALS_PLAN_ID, LEGACY_PREMIUM_PLAN_ID, LEGACY_STARTER_PLAN_ID, PLAN_TYPES } from 'constants/plans'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { useBillingClient } from 'hooks/billing/useBillingClient'
import { useRedirectFromSetupIntent } from 'hooks/billing/useRedirectFromSetupIntent'
import { useSubscription } from 'hooks/billing/useSubscription'
import { useClient, useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import { SettingsSection } from 'layouts/SettingsLayout'
import texts from 'texts.json'

// This component handles legacy way of handling billing/subscriptions through recurly
// We no longer sell recurly subscriptions but still have a large number of users on those type of subscriptions
const CreditCardBilling = () => {
  const { data, isInitialLoading: isLoadingSubscription, isError: isSubscriptionError } = useSubscription()

  if (isSubscriptionError) {
    return (
      <Box>
        <Box.ToastArea>
          <Toast icon message={texts.errors.generic} success={false} />
        </Box.ToastArea>
      </Box>
    )
  }

  if (isLoadingSubscription) {
    return <LoadingIndicator />
  }

  const planId = data?.subscription.planId

  // https://github.com/Shimmur/engineering/blob/main/design-and-decomp/web/2021-03-04-plan_selection_onboarding/plan_selection_onboarding.md
  // TP3 (Tiers and Pricing milestone 3). The main subscriptions we sold before switching to usage based billing
  const isTp3Plan =
    planId === LEGACY_STARTER_PLAN_ID || planId === LEGACY_ESSENTIALS_PLAN_ID || planId === LEGACY_PREMIUM_PLAN_ID

  if (isTp3Plan) {
    return (
      <>
        <SettingsSection>
          <PlanDetailsRow />
        </SettingsSection>
        <SettingsSection>
          <PlanPaymentMethodRow />
        </SettingsSection>
        <MessageLimitRow />
      </>
    )
  }

  // For non TP3 Plans that still have a recurly subscription
  // The only plans that fit this category should be really really old plans or Premium + plans - ex: large accounts with more than 25000 subscribers that pay with credit card
  return <NonStandardRecurlySubscription />
}

const useHandleStripeRedirect = () => {
  const queryClient = useQueryClient()
  const clientId = useClientId()
  const history = useHistory()
  const { showToastMessage } = useToastMessage()
  const { t } = useTranslation()
  const onSuccessfulSetupIntent = useCallback(() => {
    showToastMessage({
      message: t('settings.billing.paymentUpdated'),
    })
    // Reset subscription cache (Should contain new payment method)
    queryClient.invalidateQueries([QUERY_CACHE.BILLING.SUBSCRIPTION, { clientId }])
    // Push clean billing page without query params added by stripe redirect
    history.push(ROUTES.SETTINGS.BILLING)
  }, [showToastMessage, t, queryClient, clientId, history])

  const onErrorSetupIntent = useCallback(() => {
    showToastMessage({
      message: t('settings.billing.genericError'),
      success: false,
    })
    // Push clean billing page without query params added by stripe redirect
    history.push(ROUTES.SETTINGS.BILLING)
  }, [history, showToastMessage, t])

  // Listens for the stripe params after a payment method update
  useRedirectFromSetupIntent({ onSuccess: onSuccessfulSetupIntent, onError: onErrorSetupIntent })
}

export function Billing(): JSX.Element {
  const { data: client } = useClient()
  const { data: billingData, isInitialLoading: isBillingClientLoading, isError } = useBillingClient()
  const isSegmentBasedBilling = useHasCapability(CAPABILITIES.FEATURE.SEGMENT_BILLING.ALL)

  const {
    isInitialLoading: isLoadingSubscription,
    isSuccess: hasSubscription,
    data: subscriptionData,
  } = useSubscription()

  // After a payment method update, we will redirect back to the billing page
  useHandleStripeRedirect()

  if (isError) {
    return (
      <Box>
        <Box.ToastArea>
          <Toast icon message={texts.errors.generic} success={false} />
        </Box.ToastArea>
      </Box>
    )
  }

  if (isBillingClientLoading || isLoadingSubscription || !billingData) {
    return <LoadingIndicator />
  }

  // Credit card plan type were the plan type used for TP3 plans
  // These plans are now considered legacy plans
  // The majority of new plans are PLAN_TYPES.TERMED_INVOICE
  // Termed Invoice plans can be charged via credit card via stripe
  const BillingSummary = billingData.planType === PLAN_TYPES.CREDIT_CARD ? CreditCardBilling : EnterpriseBillingSummary

  const isStripe = subscriptionData?.billingProvider === 'stripe'
  const isRecurly = subscriptionData?.billingProvider === 'recurly'
  const isBillingRequired = client?.onboardingBillingState?.billingRequired
  const EditBilling = isStripe ? EditStripeBilling : EditRecurlyBilling

  // should show edit billing if the client has a subscription
  // and the billingProvider is either Recurly, or the billingProvider is Stripe and billing is required
  const shouldShowEditBilling =
    hasSubscription && ((isStripe && isBillingRequired) || isRecurly) && client?.phoneNumberType === 'ten_dlc'
  const shouldShowBillingUsage = isSegmentBasedBilling

  return (
    <Switch>
      <Route component={BillingSummary} exact path={ROUTES.SETTINGS.BILLING} />
      {
        // Only show edit billing if we have a subscription and billingProvider is either stripe or recurly
        shouldShowEditBilling && <Route component={EditBilling} exact path={ROUTES.SETTINGS.BILLING_EDIT} />
      }
      {
        // There is activity if we have are either message based or segment based billing
        shouldShowBillingUsage && (
          <Route component={SegmentEnterpriseActivity} exact path={ROUTES.SETTINGS.BILLING_USAGE} />
        )
      }
      <Route component={BillingHistory} exact path={ROUTES.SETTINGS.BILLING_HISTORY} />
      <Redirect to={ROUTES.SETTINGS.BILLING} />
    </Switch>
  )
}
