import { useMessageBillingUsage } from '@community_dev/hooks'
import { BUTTON_VARIANTS, Button, Typography, TCPAMessage, TCPA_MESSAGE } from '@community_dev/pixels'
import React, { useState } from 'react'

import { useSettings } from 'contexts/SettingsProvider'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import { StyledAction, StyledMultimedia, StyledTextButton } from 'screens/Onboarding/styled'

type EditMessageProps = {
  charMax?: number
  complete?: boolean
  defaultValue?: string
  segmentMax?: number
  tcpaMessage?: string
  onCancel: (...args: any[]) => any
  onSave: (...args: any[]) => any
}

export function EditMessage({
  charMax,
  complete = false,
  defaultValue = '',
  segmentMax,
  tcpaMessage = TCPA_MESSAGE,
  onCancel,
  onSave,
}: EditMessageProps): JSX.Element {
  const currentTheme = useCurrentTheme()
  const { settings } = useSettings()
  const [value, setValue] = useState(defaultValue)
  const { links } = useShortenableLinks(value)
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = useMessageBillingUsage({
    charMax,
    segmentMax,
    linksToShorten: links,
    message: value,
    messageSuffix: tcpaMessage,
  })
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }

  return (
    <>
      <Typography variant="h1">Personalize your message</Typography>
      <p>When people sign up with your Community, your confirmation message will be sent.</p>
      <StyledMultimedia>
        <TCPAMessage
          defaultValue={value}
          emojiSkinTone={settings.skinTone}
          emojiTheme={currentTheme.type}
          onChange={(newValue) => {
            setValue(newValue)
          }}
          requiredSuffix={tcpaMessage}
          usageCounterProps={usageCounterProps}
        />
      </StyledMultimedia>
      <StyledAction>
        <Button
          disabled={!value.length || !!error}
          onClick={() => {
            onSave(value)
          }}
          variant={BUTTON_VARIANTS.ACTION}
        >
          Save
        </Button>
      </StyledAction>
      {complete && <StyledTextButton onClick={onCancel}>Cancel</StyledTextButton>}
    </>
  )
}
