import { Typography, Button, TABLET_BREAK } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledCloseButton = styled.button`
  position: absolute;
  width: 54px;
  height: 54px;
  right: 24px;
  top: 24px;
  border: 0;
  background: none;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  border: ${({ theme }) => `1px solid ${theme.COLORS.BORDERS}`};
  color: ${({ theme }) => theme?.COLORS.TEXT};
`
export const SelectionTitle = styled(Typography).attrs({
  variant: 'h2',
  family: 'Inter, sans-serif',
})`
  font-size: 19px;
  line-height: 24px;
  margin: 36px 0px 12px;
`

export const StyledGenerateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 10px 2px 20px;
  font-size: 20px;
  font-weight: 700;
  width: 230px;
`

export const StyledDivider = styled.hr`
  width: 100%;
  border-style: initial;
  border-top: ${({ theme }) => `1px solid ${theme.COLORS.BORDERS}`};
  margin: 16px 0 16px;
`

export const StyledContentWrapper = styled.div`
  height: 100%;
  overflow: auto;
  flex: 1;
  position: relative;

  @media (min-width: ${TABLET_BREAK}) {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
`

export const StyledContentLeft = styled.div`
  background-color: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_3};
  height: 100%;
  overflow: auto;
  padding: 100px 16px 20px;
  max-width: 1200px;
  width: 100%;
  margin-left: auto;

  @media (min-width: ${TABLET_BREAK}) {
    padding: 42px 80px;
  }
`

export const StyledContentRight = styled.div`
  background-color: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_2};
  height: 100%;
  overflow: auto;
  padding: 20px 16px;

  @media (min-width: ${TABLET_BREAK}) {
    padding: 42px 24px;
  }
`

export const StyledToneSuggestButton = styled.button`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  display: flex;
  align-items: center;
  user-select: none;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 6px 0 3px 0;
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
`
