import { DAY_KEY, MONTH_KEY, WEEK_KEY } from '../constants'

import dayjs from 'utils/dayjs'

const formatDateWeekMonth = (d: Date): string => {
  return dayjs(d).format('M/DD')
}

const formatDateDay = (d: Date): string => {
  // if the hour is 12 add pm / am
  if (dayjs().hour(0).isSame(d, 'hour') || dayjs().hour(12).isSame(d, 'hour')) {
    return dayjs(d).format('h a')
  }
  return dayjs(d).format('h')
}

export const formatDateFns = {
  [DAY_KEY]: formatDateDay,
  [WEEK_KEY]: formatDateWeekMonth,
  [MONTH_KEY]: formatDateWeekMonth,
}

const formatTooltipDayDate = (d?: Date): string => {
  if (!d) return ''
  return dayjs(d).format('h a')
}

const formatTooltipDate = (d?: Date): string => {
  if (!d) return ''
  return dayjs(d).format('M/DD/YYYY')
}

export const tooltipFormatFns = {
  [DAY_KEY]: formatTooltipDayDate,
  [WEEK_KEY]: formatTooltipDate,
  [MONTH_KEY]: formatTooltipDate,
}
