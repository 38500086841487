import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect, useRef, SyntheticEvent } from 'react'

import { StyledVideo, StyledVideoMain, StyledVideoOverlay, StyledVideoRoot } from './Video.style'

import { calculateScaledDimensions } from 'utils/calculateScaledDimensions'

type VideoProps = {
  src?: string
  type?: string
  poster?: string
  width?: number
  height?: number
  maxWidth?: number
  maxHeight?: number
}

const Video = ({
  src = '',
  type = '',
  poster = '',
  width = 0,
  height = 0,
  maxWidth = 500,
  maxHeight = 500,
}: VideoProps): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [overlay, setOverlay] = useState(true)
  const [quicktime, setQuicktime] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!poster && !loaded) {
      setQuicktime(!!videoRef?.current?.canPlayType('video/quicktime') || false)
      setLoaded(true)
    }
  }, [poster, loaded])

  const onClick = (): void => {
    if (overlay) {
      setOverlay(false)
      setTimeout(() => videoRef.current?.play(), 10)
    }
  }

  const onPlayVideo = (e: SyntheticEvent): void => {
    const videos: HTMLVideoElement[] = [].slice.call(document.getElementsByTagName('video'))
    videos.forEach((video) => {
      if (video !== e.target) video.pause()
    })
  }

  const { width: finalWidth, height: finalHeight } = calculateScaledDimensions(
    { width, height },
    {
      width: maxWidth > width ? width : maxWidth,
      height: maxHeight > height ? height : maxHeight,
    },
  )

  return (
    <StyledVideoRoot style={{ width: finalWidth, height: finalHeight, overflow: 'hidden' }}>
      <StyledVideoMain
        $loaded={loaded}
        $overlay={overlay}
        $unsupported={Boolean(type?.includes('quicktime') && !quicktime)}
        onClick={onClick}
      >
        <StyledVideo controls={!overlay} onPlay={onPlayVideo} playsInline poster={poster} ref={videoRef} src={src} />
        <img alt="" onLoad={() => setLoaded(true)} src={poster} style={{ display: 'none' }} />
        <StyledVideoOverlay onClick={onClick}>
          <FontAwesomeIcon icon="play" />
        </StyledVideoOverlay>
      </StyledVideoMain>
    </StyledVideoRoot>
  )
}

export default Video
