import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type CampaignSummaryResponse = CamelCasedPropertiesDeep<Api.V1.CampaignSummaryResponse>

export function getCampaignSummary({
  clientId,
  campaignId,
}: {
  clientId: string
  campaignId: string
}): Promise<CampaignSummaryResponse> {
  const requestRoute = route(ENDPOINTS.CAMPAIGN_SUMMARY, {
    clientId,
    campaignId,
  })

  return request(requestRoute, { method: HTTP.GET, ignoreReportingErrorCodes: [404] })
}

export function postCampaignSummary({
  clientId,
  campaignId,
}: {
  clientId: string
  campaignId: string
}): Promise<CampaignSummaryResponse> {
  const requestRoute = route(ENDPOINTS.CAMPAIGN_SUMMARY, {
    clientId,
    campaignId,
  })

  return request(requestRoute, { method: HTTP.POST })
}
