import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { SearchTabType } from 'constants/messages'
import { InboxFilter } from 'contexts/InboxProvider'
import { ApiResponse } from 'types/api'
import { request } from 'utils/api'
import dayjs from 'utils/dayjs'
import { InboxItemNormalized, normalizeItem, normalizePagination, PaginationNormalized } from 'utils/normalize'
import { route } from 'utils/router'

export type Chat = CamelCasedPropertiesDeep<Api.Chat>
export type MessageHistory = CamelCasedPropertiesDeep<Api.V2.MessageHistory>

type GetInboxMessagesOptions = {
  filters: InboxFilter[]
  searchTerm?: string
  searchTab?: SearchTabType
}

export async function getInboxMessages(
  { filters = [], searchTerm, searchTab }: GetInboxMessagesOptions,
  page = 1,
): Promise<{
  messages: Array<InboxItemNormalized | Record<string, never>>
  pagination: PaginationNormalized
}> {
  const queryParams = filters
    .map((filter) => ({ field: filter.field, value: filter.value }))
    .reduce<{ 'search[tag_ids]'?: string[] }>(
      (acc, keyValue) => {
        const { field, value } = keyValue
        if (field === 'search[tag_ids]') {
          if (acc['search[tag_ids]']) {
            acc['search[tag_ids]'].push(value)
          }
          return acc
        }
        return value ? { ...acc, [field]: value } : acc
      },
      { 'search[tag_ids]': [] },
    )

  if (searchTerm) {
    const searchKey = SearchTabType.LATEST_MESSAGE === searchTab ? 'message' : 'full_name'
    queryParams[`search[${searchKey}]`] = searchTerm
  }
  if (Array.isArray(queryParams['search[tag_ids]']) && !queryParams['search[tag_ids]'].length) {
    delete queryParams['search[tag_ids]']
  }

  const pagination = `page_number=${page}&page_size=50`
  const queryParamStr = Object.entries(queryParams)
    .map((param) => {
      return param.join('=')
    })
    .join('&')

  return request(`${ENDPOINTS.CHATS}${queryParamStr ? `?${pagination}&${queryParamStr}` : `?${pagination}`}`, {
    camelCase: false,
  }).then((resp) => {
    return {
      messages: resp?.data.map((item) => normalizeItem(item)),
      pagination: normalizePagination(resp?.pagination_data),
    }
  })
}

export type GetMessageHistoryArgs = {
  fanId: string
  clientId: string
  nextTs?: string | number
}

export async function getMessageHistory({
  fanId,
  clientId,
  nextTs,
}: GetMessageHistoryArgs): Promise<CamelCasedPropertiesDeep<Api.V2.MessageHistory>> {
  const endDate = nextTs || dayjs().add(1, 'day').toISOString()
  const endpoint = route(
    ENDPOINTS.MESSAGING.HISTORY,
    { fanId: fanId, clientId },
    { end_date: endDate, page_size: 50, 'tags[not]': 'auto-response' },
  )
  return request(endpoint)
}

export type UpdateChatArgs = {
  clientId: string
  fanSubscriptionId: string
  chat: Record<string, any>
}

export async function updateChat({ clientId, fanSubscriptionId, chat }: UpdateChatArgs): Promise<ApiResponse<Chat>> {
  return request(route(ENDPOINTS.MESSAGING.CHAT, { clientId, fanSubscriptionId }), {
    method: HTTP.PATCH,
    body: chat,
  })
}
