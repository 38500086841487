import { LOCATION_TYPE } from '@community_dev/filter-dsl/lib/digits'
import { CountryCode, provinces, states } from '@community_dev/location-data'

import { countries } from 'utils/countries'

export type LocationSuggestion = {
  city: string
  country: string
  name: string
  state: string
  abbrev: string
  type: string
  code?: string
}

export function suggestProvinces(input: string): LocationSuggestion[] {
  const lowerInput = input.toLowerCase()
  return provinces
    .filter(
      (provinces) =>
        Boolean(provinces.name.toLowerCase().includes(lowerInput)) ||
        Boolean(provinces.abbrev.toLowerCase().includes(lowerInput)),
    )
    .map((result) => ({
      city: '',
      country: 'CA',
      name: result.name,
      state: result.name,
      abbrev: result.abbrev,
      type: 'state',
    }))
}

export function suggestStates(input: string): LocationSuggestion[] {
  const lowerInput = input.toLowerCase()
  return states
    .filter(
      (state) =>
        Boolean(state.name.toLowerCase().includes(lowerInput)) ||
        Boolean(state.abbrev.toLowerCase().includes(lowerInput)),
    )
    .map((result) => ({
      city: '',
      country: 'USA',
      name: result.name,
      state: result.name,
      abbrev: result.abbrev,
      type: 'state',
    }))
}

export function suggestCountries(input: string, includeCodes?: CountryCode[]): LocationSuggestion[] {
  const lowerInput = input.toLowerCase()
  return countries
    .filter((country) => {
      return (
        (includeCodes === undefined || includeCodes.includes(country.code)) &&
        country.lookups.some((lookup) => lookup.toLowerCase().includes(lowerInput))
      )
    })
    .map((result) => ({
      city: '',
      country: result.name,
      name: result.name,
      state: '',
      abbrev: result.abbrev,
      code: result.code,
      type: LOCATION_TYPE.COUNTRY_CODE,
    }))
}
