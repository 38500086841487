import { Button } from '@community_dev/pixels'
import qs from 'query-string'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import { General } from '../General'
import { Shopify } from '../Shopify'
import { UnitSwitcher } from '../UnitSwitcher'

import { ROUTES } from 'constants/routes'
import { useGoToDataExport } from 'containers/Settings/useGoToDataExport'
import { useShouldShowDataExport } from 'containers/Settings/useShouldShowDataExport'
import { useClientId } from 'hooks/useClient'
import { useShopifyStoreDetails } from 'hooks/useShopify'
import { InsightsLayout } from 'layouts/InsightsLayout'

const StyledExportDataButton = styled(Button)`
  height: 100%;
`

export type InsightsRootViewProps = {
  enabled?: boolean
  demographicInsights: any
}

export const InsightsRootView = ({
  demographicInsights,
  enabled = true,
}: InsightsRootViewProps): JSX.Element | null => {
  const clientId = useClientId()
  const { t } = useTranslation()
  const shouldShowDataExport = useShouldShowDataExport()
  const goToDataExport = useGoToDataExport()
  const { shopifyExists, isInitialLoading } = useShopifyStoreDetails(clientId)

  const location = useLocation()

  const selectedCommunityId = useMemo(() => {
    const q = qs.parse(location.search)
    const communities = q['communities[]']
    if (Array.isArray(communities)) {
      return communities[0]
    } else if (typeof communities === 'string') {
      return communities
    } else {
      return null
    }
  }, [location])

  const updatedTimestamp = useMemo(() => {
    if (selectedCommunityId !== null) {
      // don’t show the timestamp for individual communities
      // because their data is always up to date.
      return undefined
    } else {
      return demographicInsights?.ageAndGenderQuery?.data?.age_gender_insights?.[0]?.timestamp as string | undefined
    }
  }, [selectedCommunityId, demographicInsights])

  if (isInitialLoading) return null
  return (
    <InsightsLayout
      actions={
        <>
          <Route exact path={ROUTES.INSIGHTS.ROOT}>
            {enabled && selectedCommunityId === null && <UnitSwitcher />}
          </Route>
          {shouldShowDataExport ? (
            <StyledExportDataButton onClick={goToDataExport}>{t('exportData')}</StyledExportDataButton>
          ) : null}
        </>
      }
      enabled={enabled}
      updatedAtTimeStamp={updatedTimestamp}
    >
      <Switch>
        <Route exact path={ROUTES.INSIGHTS.ROOT}>
          <General demographicInsights={demographicInsights} enabled={enabled} />
        </Route>
        {shopifyExists && <Route component={Shopify} exact path={ROUTES.INSIGHTS.SHOPIFY} />}
        <Route>
          <Redirect to={ROUTES.INSIGHTS.ROOT} />
        </Route>
      </Switch>
    </InsightsLayout>
  )
}
