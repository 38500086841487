import { useMemo } from 'react'

import { OnboardingMessage } from 'api/client'
import { useClient } from 'hooks/useClient'

const toOnboardingMessage = (key: string, client) => {
  return client?.onboardingMessages?.find(({ label }) => label === key)
}

export const useOnboardingMessage = (onboardingLabelKey: string): OnboardingMessage => {
  const { data: client } = useClient()
  return useMemo(() => toOnboardingMessage(onboardingLabelKey, client), [client, onboardingLabelKey])
}
