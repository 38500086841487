import { SPACING } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { InboxChatOptions } from './InboxChatOptions'

import { Fan } from 'api/fans'
import { useActionPanel } from 'components/ActionPanel/ActionPanelContext'
import { MemberDetails } from 'components/MemberDetails/MemberDetails'
import { MOBILE_HEADER_HEIGHT, TABLET_BREAK } from 'constants/theme'

const StyledHeader = styled.header`
  flex-shrink: 0;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
`

const StyledMain = styled.div`
  display: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MOBILE_HEADER_HEIGHT};
  padding: 0 32px;

  @media (min-width: ${TABLET_BREAK}) {
    padding: ${SPACING[2]} ${SPACING[4]};
    height: auto;
  }
`

const StyledGoBack = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${MOBILE_HEADER_HEIGHT};
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;

  @media (min-width: ${TABLET_BREAK}) {
    display: none;
    visibility: hidden;
  }
`

type InboxChatHeaderProps = {
  detailsPaneOpen: boolean
  fan: Fan
  goBack: (...args: any[]) => any
  openDetailsPane: (...args: any[]) => any
}

export function InboxChatHeader({ fan, goBack }: InboxChatHeaderProps): JSX.Element {
  const { isOpen, openActionPanel } = useActionPanel()

  return (
    <StyledHeader>
      <StyledGoBack data-testid="inbox-back" onClick={goBack}>
        <FontAwesomeIcon icon="chevron-left" />
      </StyledGoBack>
      <StyledMain>
        <MemberDetails fan={fan} onClick={openActionPanel} />
        {!isOpen && <InboxChatOptions />}
      </StyledMain>
    </StyledHeader>
  )
}
