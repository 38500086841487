import { IconProps } from './IconProps'

export const PlaceIcon = ({ size = 12, color = 'currentColor' }: IconProps): JSX.Element => {
  const width = size
  const height = size * 1.41
  return (
    <svg fill="none" height={height} viewBox="0 0 12 17" width={width} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.38337 16.1772C0.842812 9.59472 0 8.91915 0 6.5C0 3.18628 2.68628 0.5 6 0.5C9.31372 0.5 12 3.18628 12 6.5C12 8.91915 11.1572 9.59472 6.61662 16.1772C6.31865 16.6076 5.68131 16.6076 5.38337 16.1772ZM6 9C7.38072 9 8.5 7.88072 8.5 6.5C8.5 5.11928 7.38072 4 6 4C4.61928 4 3.5 5.11928 3.5 6.5C3.5 7.88072 4.61928 9 6 9Z"
        fill={color}
      />
    </svg>
  )
}
