import { Button, CloseIcon, Modal } from '@community_dev/pixels'
import { useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styled, { useTheme } from 'styled-components'

import cartIcon from './assets/cart-icon.svg'
import communityImage from './assets/community.png'
import currencyIcon from './assets/currency-icon.svg'
import peopleIcon from './assets/people-icon.svg'
import { CustomizeWelcomeMessage } from './components/CustomizeWelcomeMessage'
import { EnterPrivateDetails } from './components/EnterCustomDetails'
import { GetStarted } from './components/GetStarted'
import { SetupInstruction } from './components/SetupInstruction'
import { StyledListIcon, StyledModalBody } from './components/styled'

import { ProtectedCommunityNames } from 'api/community'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { StyledFooter, StyledTitle } from 'containers/Settings/components/Integrations/VisualFormSection'
import { useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useShopify } from 'hooks/useShopify'
import HorizontalTabLayout from 'layouts/HorizontalTabLayout'

const Root = styled.div`
  width: 100%;
`

const ModalImage = styled.img`
  max-width: 195px;
`

export const ConnectStore = (): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const queryClient = useQueryClient()
  const history = useHistory()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const clientId = useClientId()
  const { data: shopifyCommunity } = useCommunities({
    select: (communities) => communities.all.find((community) => community.title === ProtectedCommunityNames.SHOPIFY),
  })

  const { client, setEnabled } = useShopify(clientId, shopifyCommunity)

  const TABS = ['Getting Started', 'Setup Instructions', 'Custom App Details', 'Welcome Message']

  function closeModal() {
    setShowSuccessModal(false)
    queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
    history.replace(ROUTES.SETTINGS.INTEGRATIONS.ROOT)
  }

  function completeOnboarding() {
    setShowSuccessModal(true)
  }

  const renderModal = () => {
    if (showSuccessModal) {
      return (
        <Modal maxHeight="100%" open>
          <Modal.Header>
            <Modal.Header.Right onClose={closeModal}>
              <CloseIcon color={COLORS?.SUBTEXT} size={12} />
            </Modal.Header.Right>
          </Modal.Header>
          <Modal.Body>
            <StyledModalBody>
              <div>
                <StyledTitle>Your store is now connected</StyledTitle>
                <StyledListIcon className="store-created-explanation">
                  <li>
                    <img alt="cart icon" src={cartIcon} />
                    Customers completing checkout (with phone number provided) will receive your Shopify Welcome Message
                    with a one-time sign-up link.
                  </li>
                  <li>
                    <img alt="communities icon" src={peopleIcon} />
                    New Members from Shopify checkout are added to your • Shopify Community.
                  </li>
                  <li>
                    <img alt="currency icon" src={currencyIcon} />
                    Open a Member’s details to see their Shopify info like total spent and last order.
                  </li>
                </StyledListIcon>
              </div>
              <ModalImage alt="Shopify Community" src={communityImage} />
            </StyledModalBody>
          </Modal.Body>
          <Modal.Footer>
            <StyledFooter>
              <Button onClick={closeModal}>Done</Button>
            </StyledFooter>
          </Modal.Footer>
        </Modal>
      )
    }
  }

  return (
    <Root>
      {renderModal()}
      <HorizontalTabLayout initialTab={0} tabs={TABS}>
        {({ selectTab }) => (
          <>
            <GetStarted onPrimaryButtonClick={() => selectTab(1)} />

            <SetupInstruction onBackButtonClick={() => selectTab(0)} onPrimaryButtonClick={() => selectTab(2)} />

            <EnterPrivateDetails
              client={client}
              clientId={clientId}
              onBackButtonClick={() => selectTab(1)}
              onPrimaryButtonClick={() => selectTab(3)}
              setEnabled={setEnabled}
            />

            {client && <CustomizeWelcomeMessage client={client} onPrimaryButtonClick={completeOnboarding} />}
          </>
        )}
      </HorizontalTabLayout>
    </Root>
  )
}
