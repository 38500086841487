import { Dayjs } from 'dayjs'

import { generateDays, generateHourRange } from './createTimeData'

import { PT_TIME_ZONE } from 'constants/date'
import { ColorScale } from 'screens/InsightsScreen/useDataColorScale'
import dayjs from 'utils/dayjs'

type FormatBestHourDataArgs = {
  [key: string]: {
    [key: string]: number
  }
}

type FormatBestHourDataReturn = {
  heatmapData: HeatmapDataStructure
  hoursRange: HeatmapHoursRange
  timezone: string
}

type FormatBestHourDataOptions = {
  today?: Dayjs
  timezone?: string
}

type HeatmapHour = {
  hour: number
  value: number
  color: string
  timezone: string
  timestamp: string
  amPm: string
  rawHour: number
}

export type HeatmapHoursRange = Pick<HeatmapHour, 'amPm' | 'hour'>[]

export type HeatmapDataStructure = {
  day: string
  hours: HeatmapHour[]
}[]

const filterQuietHours = (hour: Dayjs): boolean => {
  const hourOfDay = hour.tz(PT_TIME_ZONE).format('HH')

  const hourOfDayNumber = Number.parseInt(hourOfDay)

  return hourOfDayNumber >= 6 && hourOfDayNumber <= 19
}

const getTodayWeekday = (today) => {
  const dayNumber = today.get('day')

  if (dayNumber === 0) return 7

  return dayNumber
}

const formatHour = (hour: Dayjs, data: FormatBestHourDataArgs, colorScale: ColorScale): HeatmapHour => {
  const utcTimestamp = dayjs.tz(hour, 'UTC')
  const utcHour = utcTimestamp.get('hour')
  const utcDay = getTodayWeekday(utcTimestamp)
  const hourValue = data?.[utcDay.toString()]?.[utcHour.toString()]

  return {
    amPm: hour.format('h a').split(' ')[1],
    hour: Number(hour.format('h a').split(' ')[0]),
    rawHour: Number(hour.format('H')),
    value: hourValue,
    color: colorScale[hourValue],
    timestamp: hour.toISOString(),
    timezone: hour.format('z'),
  }
}

export const formatBestHourData = (
  data: FormatBestHourDataArgs = {},
  options: FormatBestHourDataOptions,
  colorScale: ColorScale,
): FormatBestHourDataReturn => {
  const timezone = options?.timezone || dayjs.tz.guess()
  const today = options?.today || dayjs().tz(timezone)

  const days = generateDays({ today })

  const hoursRange = generateHourRange({ today, timezone })

  const heatmapData = days.map((hoursArray, idx) => {
    const weekdayName = hoursArray[0].toDate().toLocaleString('en-US', { weekday: 'short' })

    const hours = hoursArray
      .filter(filterQuietHours)
      .map((hour) => formatHour(hour, data, colorScale))
      .sort((a, b) => {
        return a.rawHour - b.rawHour
      })

    return {
      day: weekdayName,
      dayNumber: idx + 1,
      hours,
    }
  })

  return {
    heatmapData,
    timezone: today.format('z'),
    hoursRange,
  }
}
