import {
  ChevronLeftIcon,
  FONT_SIZE,
  Layout,
  Skeleton,
  SPACING,
  Typography,
  Button,
  BUTTON_VARIANTS,
} from '@community_dev/pixels'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { CreditBundles } from './CreditBundles'
import { EstimatedInvoice } from './EstimatedInvoice'

import { getDownloadUrl } from 'api/billingUsage'
import { ROUTES } from 'constants/routes'
import { useClientId } from 'hooks/useClient'
import { useMonthlyUsageQuery } from 'hooks/useMonthlyUsageQuery'
import { useToastMessage } from 'hooks/useToastMessage'
import dayjs from 'utils/dayjs'

const StyledCreditBundles = styled(CreditBundles)`
  margin-top: ${SPACING[3]};
`

const StyledEstimatedInvoice = styled(EstimatedInvoice)`
  margin-top: ${SPACING[5]};
`

export const SegmentEnterpriseActivity = (): JSX.Element => {
  const { t } = useTranslation()
  const clientId = useClientId()
  const { data: MonthlyUsage, isInitialLoading } = useMonthlyUsageQuery()
  const { showToastMessage } = useToastMessage()
  const { mutate: downloadDailyReport, isLoading } = useMutation({
    mutationFn: async () => {
      const { url } = await getDownloadUrl(clientId, 'multi_channel_outbound_message_type_usage_channel_usage.csv.gz')

      const link = document.createElement('a')
      link.download = 'daily-report.csv'
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    onSuccess: () => {
      showToastMessage({
        message: t('settings.billing.termedInvoice.segmentBasedBilling.dailyReportDownloaded'),
      })
    },
    onError: () => {
      showToastMessage({
        message: t('settings.billing.termedInvoice.segmentBasedBilling.dailyReportDownloadError'),
        success: false,
      })
    },
  })

  return (
    <Layout display="flex" flexDirection="column" maxWidth="800px">
      <Layout display="flex" maxWidth="800px">
        <Link aria-label="back" to={ROUTES.SETTINGS.BILLING}>
          <ChevronLeftIcon />
        </Link>
        <Layout display="flex" flexDirection="column" flexGrow={1} marginLeft={SPACING[5]}>
          <Typography fontSize={FONT_SIZE[6]} margin="0" variant="h3">
            {t('settings.billing.termedInvoice.segmentBasedBilling.usageActivity')}
          </Typography>
          {isInitialLoading ? (
            <Layout marginTop={SPACING[2]}>
              <Skeleton height="20px" loading width="100px" />
            </Layout>
          ) : (
            <Typography fontSize={FONT_SIZE[3]} marginTop={SPACING[2]}>
              {t('settings.billing.termedInvoice.segmentBasedBilling.updated', {
                date: dayjs(MonthlyUsage?.updatedAt).calendar(),
              })}
            </Typography>
          )}
          <StyledCreditBundles />
          <StyledEstimatedInvoice />
          <Layout marginTop={SPACING[8]}>
            <Typography marginTop={0} variant="h4">
              {t('settings.billing.termedInvoice.segmentBasedBilling.dailyReport')}
            </Typography>
            <Button disabled={isLoading} onClick={downloadDailyReport} variant={BUTTON_VARIANTS.OUTLINE}>
              {t('settings.billing.termedInvoice.segmentBasedBilling.downloadDailyReport')}
            </Button>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  )
}
