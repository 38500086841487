import { makeEmptyManifest, Manifest } from '@community_dev/workflow-manifest'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'

import { useWorkflowId } from './useWorkflowId'

import { getWorkflow, Workflow } from 'api/workflows'
import { QUERY_CACHE } from 'constants/query-cache'
import { WORKFLOW_ID_UNSAVED } from 'constants/workflow'
import { useClientId } from 'hooks/useClient'

export type UseRemoteWorkflowProps = {
  onSuccess?: (data: Workflow) => void
  onError?: (err: unknown) => void
}

export type UseRemoteWorkflowResult = UseQueryResult<Workflow, unknown> & {
  manifest: Manifest
}

export function useRemoteWorkflow(props: UseRemoteWorkflowProps = {}): UseRemoteWorkflowResult {
  const { onSuccess, onError } = props

  const clientId = useClientId()
  const { workflowId } = useWorkflowId()

  const result = useQuery(
    [QUERY_CACHE.WORKFLOW_CONFIG.WORKFLOW, { clientId, workflowId }],
    () => getWorkflow({ clientId, workflowId: workflowId as string }),
    {
      enabled: workflowId !== WORKFLOW_ID_UNSAVED && Boolean(workflowId) && Boolean(clientId),
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess,
      onError,
    },
  )

  const manifest = useMemo(() => {
    return result?.data?.last_manifest?.body || makeEmptyManifest(result?.data?.type)
  }, [result.data])

  return {
    ...result,
    manifest,
  }
}
