import { Api } from '@community_dev/types'
import { useQueryClient, useMutation, InfiniteData, UseMutationResult } from '@tanstack/react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { useToastMessage } from './useToastMessage'

import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { request } from 'utils/api'

type DeleteMessageOptions = {
  fanId: string
}

export function useDeleteMessage(options: DeleteMessageOptions): UseMutationResult<
  void,
  unknown,
  {
    id: string
  },
  unknown
> {
  const { fanId } = options

  const queryClient = useQueryClient()
  const clientId = useClientId()

  const queryKey = [QUERY_CACHE.MESSAGE_HISTORY.DMS, clientId, fanId]

  const { showToastMessage } = useToastMessage()

  return useMutation({
    mutationFn: ({ id }: { id: string }) => deleteMessage({ id, clientId }),
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<InfiniteData<CamelCasedPropertiesDeep<Api.V2.MessageHistory>>>(queryKey, (oldData) => {
        if (oldData) {
          const updatedPages = oldData?.pages?.map((page) => {
            const data = page.data.filter((message) => message.id !== id)

            return {
              ...page,
              data,
            }
          })

          return {
            ...oldData,
            pages: updatedPages,
          }
        }

        return undefined
      })
    },
    onError: () => {
      showToastMessage({
        message: 'Could not delete message.',
        success: false,
      })
    },
  })
}

export const deleteMessage = ({ clientId, id }: { clientId: string; id: string }): Promise<void> => {
  const path = `/client-dashboard/v2/${clientId}/messaging/dm/${id}`

  return request(path, {
    camelCase: false,
    method: 'DELETE',
    responseMethod: 'text',
  })
}
