import { TextInput } from '@community_dev/pixels'
import styled from 'styled-components'

const StyledInput = styled.div`
  position: relative;
  width: 100%;
`

type PasswordInputProps = {
  id?: string
  name?: string
  label?: string
  value?: string
  error?: string
  disabled?: boolean
  onChange?: (any) => any
}

export default function PasswordInput({ error, ...rest }: PasswordInputProps): JSX.Element {
  return (
    <StyledInput>
      <TextInput {...rest} type={'password'} />
    </StyledInput>
  )
}
