import { ChevronLeftIcon, SPACING, Typography } from '@community_dev/pixels'
import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { useSidebar } from 'contexts/SidebarProvider'

const StyledRoot = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`

const StyledMain = styled.div`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  max-width: 672px;
  width: 100%;
  flex-direction: column;
  display: flex;
`

type StyledHeaderProps = {
  $collapsed?: boolean
}

const StyledBody = styled.div<StyledHeaderProps>`
  display: flex;
  flex-direction: column;
`

const StyledBack = styled.button`
  padding-left: 0;
  margin-right: ${SPACING[5]};
  margin-left: -${SPACING[2]};
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
`

type EditContainerProps = {
  children: React.ReactNode
  className?: string
  title: string
  onClose?: (...args: any[]) => void
}

export function EditContainer({ children, className, title, onClose }: EditContainerProps): JSX.Element {
  const history = useHistory()
  const { collapsed } = useSidebar()

  function handleClose() {
    if (onClose) {
      onClose()
      return
    }

    history.goBack()
  }

  return (
    <StyledRoot aria-labelledby="edit-container-title" role="form">
      <div>
        <StyledBack aria-label="back" onClick={handleClose}>
          <ChevronLeftIcon />
        </StyledBack>
      </div>

      <StyledBody $collapsed={collapsed}>
        <Typography marginBottom={SPACING[5]} marginTop="0" variant="h3">
          {title}
        </Typography>
        <StyledMain className={className}>{children}</StyledMain>
      </StyledBody>
    </StyledRoot>
  )
}
