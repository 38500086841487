import React from 'react'

import { IconProps } from './IconProps'

export const AppearanceIcon = ({ size = 25, color = 'currentColor' }: IconProps): JSX.Element => (
  <svg fill="none" height={size} viewBox="0 0 23 20" width={size} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1445 19.1276C15.9817 19.1276 18.5429 17.7695 18.5429 15.7643C18.5429 13.9687 17.0117 13.5677 17.0117 12.5104C17.0117 11.0521 22.3073 10.7604 22.3073 7.01432C22.3073 3.3138 18.4882 0.871094 12.819 0.871094C5.37236 0.871094 0.35022 4.58984 0.35022 10.1589C0.35022 15.5182 5.11715 19.1276 12.1445 19.1276ZM13.0195 6.60417C12.1809 6.60417 11.47 5.92057 11.47 5.07292C11.47 4.22526 12.1809 3.54167 13.0195 3.54167C13.8763 3.54167 14.5507 4.22526 14.5507 5.07292C14.5507 5.92057 13.8763 6.60417 13.0195 6.60417ZM8.16142 8.04427C7.0859 8.04427 6.22913 7.20573 6.22913 6.13021C6.22913 5.08203 7.0859 4.24349 8.16142 4.24349C9.20048 4.24349 10.0664 5.08203 10.0664 6.13021C10.0664 7.20573 9.20959 8.04427 8.16142 8.04427ZM17.3125 7.40625C16.6198 7.40625 16.0546 6.85937 16.0546 6.16667C16.0546 5.48307 16.6198 4.91797 17.3125 4.91797C17.9961 4.91797 18.5429 5.48307 18.5429 6.16667C18.5429 6.85937 17.9961 7.40625 17.3125 7.40625ZM4.66142 11.2708C3.82288 11.2708 3.13928 10.5781 3.13928 9.73958C3.13928 8.9375 3.85933 8.23568 4.66142 8.23568C5.49996 8.23568 6.16532 8.90104 6.16532 9.73958C6.16532 10.5781 5.49084 11.2708 4.66142 11.2708ZM11.944 15.6458C10.6132 15.6458 9.51949 14.5612 9.51949 13.2214C9.51949 11.8906 10.6132 10.7969 11.944 10.7969C13.2838 10.7969 14.3776 11.8906 14.3776 13.2214C14.3776 14.5612 13.2838 15.6458 11.944 15.6458Z"
      fill={color}
    />
  </svg>
)
