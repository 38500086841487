import { SPACING, Typography } from '@community_dev/pixels'
import { useTranslation, Trans } from 'react-i18next'
import { useTheme } from 'styled-components'

import { SeatedIcon } from './components/Icons/SeatedIcon'

import { Setting } from 'layouts/SettingsLayout'

export function Seated(): JSX.Element {
  const { COLORS } = useTheme()
  const { t } = useTranslation()

  return (
    <Setting
      action={t('settings.integrations.instructions')}
      actionAs="a"
      actionProps={{ target: '_blank', href: 'https://www.community.com/seated' }}
      icon={<SeatedIcon size={22} />}
      label={t('settings.integrations.seated.title')}
      text={
        <Typography color={COLORS.SUBTEXT} marginTop={SPACING[0]} variant="body2">
          <Trans i18nKey="settings.integrations.seated.description"></Trans>
        </Typography>
      }
    />
  )
}
