import { useMemo } from 'react'

import { useAllMemberCount } from './useCountByQuery/useEveryoneCount'

import { usePrefetchFilters } from 'hooks/useCountByQuery/usePrefetchFilters'

// preload recent filters so they are already available when opening the recipient pane
//
// only clients with over 100,000 members will preload the counts to reduce unnecessary load on the service
//
export function usePreloadRecentFilterCounts(): void {
  const { total: activeSubscriptions } = useAllMemberCount()

  const shouldPrefetchCounts = useMemo(() => {
    if (activeSubscriptions > 100000) {
      return true
    }

    return false
  }, [activeSubscriptions])

  usePrefetchFilters(shouldPrefetchCounts)
}
