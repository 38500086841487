import { WorkflowType } from '@community_dev/workflow-manifest'

import { useWorkflows } from './useWorkflows'

import { Workflow } from 'api/workflows'

export function useWelcomeSeries(): Workflow | undefined {
  const { data: workflows } = useWorkflows({ getDetails: true })

  return workflows?.find((workflow) => workflow.type === WorkflowType.signup)
}
