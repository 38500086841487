/*  eslint-disable max-len */
import noop from 'lodash/noop'
import React from 'react'

type EditPencilProps = {
  color?: string
  size?: string
  onClick?: (...args: any[]) => any
}

class EditPencil extends React.PureComponent<EditPencilProps> {
  static defaultProps = {
    color: '#B6BFC9',
    size: '15',
    onClick: noop,
  }

  render(): JSX.Element {
    const { color, size, onClick } = this.props
    return (
      <svg
        className="EditPencil"
        fill="none"
        height={size}
        onClick={onClick}
        viewBox={`0 0 ${size} ${size}`}
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 12.3333V15.5H3.66667L12.8333 6.25002L9.66667 3.08336L0.5 12.3333ZM15.25 3.83336C15.5833 3.50003 15.5833 3.00003 15.25 2.66669L13.3333 0.749999C13 0.416667 12.5 0.416667 12.1667 0.749999L10.6667 2.25L13.8333 5.41666L15.25 3.83336Z"
          fill={color}
        />
      </svg>
    )
  }
}

export default EditPencil
