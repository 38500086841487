import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useDataColorScale } from 'screens/InsightsScreen/useDataColorScale'

type StyledLegendProps = {
  color: string
  roundedLeft: boolean
  roundedRight: boolean
}

const StyledLegend = styled.div<StyledLegendProps>`
  height: 8px;
  width: 100%;
  background-color: ${({ color }) => color};
  border-radius: ${({ roundedLeft, roundedRight }) =>
    roundedLeft ? '20px 0 0 20px' : roundedRight && '0 20px 20px 0'};
`

const StyledContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 28px repeat(6, 1fr); ;
`

const StyledKey = styled.div`
  display: flex;
  width: 100%;
  padding-left: 28px;
  justify-content: space-between;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

export function HeatmapLegend(): JSX.Element {
  const { colors, colorsReversed } = useDataColorScale()
  const { t } = useTranslation()

  return (
    <>
      <StyledContainer>
        <div />
        {colorsReversed.map((color, idx) => (
          <StyledLegend
            color={color}
            key={color}
            roundedLeft={idx === 0}
            roundedRight={idx % (colors.length - 1) === 0}
          />
        ))}
      </StyledContainer>
      <StyledKey>
        <p>{t('bestHourInsights.highEngagement')}</p>
        <p>{t('bestHourInsights.lowEngagement')}</p>
      </StyledKey>
    </>
  )
}
