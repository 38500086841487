import { useEffect, useState } from 'react'

const useScript = (url: string, crossOrigin?: string): boolean => {
  const [loaded, setLoaded] = useState<null | boolean>(null)

  useEffect(() => {
    if (loaded !== null) {
      const script = document.createElement('script')

      script.src = url
      script.async = true
      script.type = 'module'
      if (crossOrigin !== undefined) {
        script.crossOrigin = crossOrigin
      }
      if (!loaded) script.onload = () => setLoaded(true)

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [url, loaded])

  useEffect(() => {
    setLoaded(Boolean(document.querySelector(`script[src="${url}"]`)))
  }, [url])

  return Boolean(loaded)
}

export default useScript
