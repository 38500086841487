import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { Invitation } from 'generators/invitations'
import { request } from 'utils/api'
import { route } from 'utils/router'

export function updateClientSeatRole({
  clientId,
  seatMappingId,
  roleIds,
}: {
  clientId: string
  seatMappingId: string
  roleIds: string[]
}): Promise<unknown> {
  return request(route(ENDPOINTS.ROLES.SEAT_ROLE, { clientId, seatMappingId }), {
    method: HTTP.PUT,
    body: {
      role_ids: roleIds,
    },
  })
}

export function deleteClientSeat(clientId: string, seatId: string): Promise<unknown> {
  return request(route(ENDPOINTS.SEATS.SEAT, { clientId, seatId }), { method: HTTP.DELETE })
}

export function getClientSeats(clientId: string): Promise<CamelCasedPropertiesDeep<Api.V1.Seat[]>> {
  return request(route(ENDPOINTS.SEATS.SEATS, { clientId })).then(({ data }) => data)
}

export function getClientSeatInvites(clientId: string): Promise<Invitation[]> {
  return request(route(ENDPOINTS.INVITES.INVITES, { clientId })).then(({ data }) => data)
}

export function getTeamRoles(): Promise<CamelCasedPropertiesDeep<Api.V1.Role[]>> {
  return request(route(ENDPOINTS.ROLES.ROLES))
}
