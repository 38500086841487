import { MemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { DIALOG_VARIANTS, Dialog, BORDER_RADIUS, FONT_SIZE, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { CommunityType } from '@community_dev/types/lib/api/Community'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CommunityMemberSelectionActionButtons } from './CommunityMemberSelectionActionButtons'

import { Community, postUnassignByQuery } from 'api/community'
import { Fan } from 'api/fans'
import { ActionPanel } from 'components/ActionPanel'
import { ActionPanelContactsHeader } from 'components/ActionPanel/ActionPanelPresentationalComponents'
import { COMMUNITY_SORT_BY_MEMBER_COUNT } from 'constants/launch-darkly-flags'
import { PANELS } from 'constants/panels'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { useInvalidateCommunityCount } from 'hooks/useCountByQuery/useCommunityCount'
import { userHasFlag } from 'hooks/useLaunchDarkly'
import { useToastMessage } from 'hooks/useToastMessage'
import { formatLargeNumber } from 'utils/number'

const StyledRemoveMembersButton = styled.button`
  color: ${({ theme }) => theme?.COLORS?.ERRORS};
  background: none;
  border: 0;
  margin-top: ${SPACING[5]};
  font-size: ${FONT_SIZE[2]};
  cursor: pointer;
  transition: background 0.2s;
  border-radius: ${BORDER_RADIUS[1]};
  padding: ${SPACING[1]};

  &:hover {
    background: ${({ theme }) => theme?.COLORS?.DEPRECATED_HOVER};
  }
`

const shouldShowRemoveMembersFromCommunity = (
  selectAll: boolean,
  selectedFans: Fan[],
  selectedCommunity: Community,
): boolean => {
  // if the community does not have an id, means we are looking at all members
  // therefore we should not show remove from community button
  if (!selectedCommunity.id) {
    return false
  }

  // if we are looking at a protected amb community, we should not show remove from community button
  if (
    selectedCommunity.type === CommunityType.PROTECTED &&
    selectedCommunity.communicationChannels?.includes(CommunicationChannel.AMB)
  ) {
    return false
  }

  return selectAll || selectedFans?.length > 0
}

export function CommunityMemberSelectionActionPanel({
  communicationChannel,
  selectAll,
  recipientCount,
  recipientCountIsLoading,
  selectedFans,
  selectedCommunity,
  filters,
  onClickAddToCommunity,
  onClickRemoveFromCommunity,
  onClose,
}: {
  communicationChannel: CommunicationChannel
  selectAll: boolean
  recipientCount: number
  recipientCountIsLoading?: boolean
  selectedFans: Fan[]
  selectedCommunity: Community
  filters: MemberDataFilter
  onClickAddToCommunity(): void
  onClickRemoveFromCommunity(): void
  onClose?(): void
}): JSX.Element {
  const clientId = useClientId()
  const queryClient = useQueryClient()
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
  const { showToastMessage } = useToastMessage()
  const { t } = useTranslation()
  const isSortByMemberCountEnabled = userHasFlag(COMMUNITY_SORT_BY_MEMBER_COUNT)
  const invalidateCommunityCount = useInvalidateCommunityCount()
  const removeSelectedFromCommunity = useMutation(postUnassignByQuery, {
    onError() {
      showToastMessage({
        message: t('communities.error.couldNotRemoveFans', {
          count: recipientCount || 0,
          community: selectedCommunity.title,
        }),
        success: false,
      })
    },
    onSuccess() {
      showToastMessage({
        message: t('communities.success.fansRemoved', {
          count: recipientCount || 0,
          community: selectedCommunity.title,
        }),
      })

      if (isSortByMemberCountEnabled) {
        queryClient.invalidateQueries([QUERY_CACHE.TAGS])
      }

      onClickRemoveFromCommunity()
    },
  })

  const dialogTitle = t('communities.confirmFansRemove', {
    count: selectedFans?.length,
  })

  return (
    <>
      <ActionPanel name={PANELS.COMMUNITY_MEMBER_SELECTION} onClose={onClose}>
        <ActionPanelContactsHeader
          communicationChannel={communicationChannel}
          recipientCountIsLoading={recipientCountIsLoading}
          recipientCountString={t('communities.totalFansSelected', { total: formatLargeNumber(recipientCount) })}
        />
        <CommunityMemberSelectionActionButtons
          communicationChannel={communicationChannel}
          filters={filters}
          onClickAddToCommunity={onClickAddToCommunity}
          recipientCount={recipientCount}
        />
        {shouldShowRemoveMembersFromCommunity(selectAll, selectedFans, selectedCommunity) && (
          <StyledRemoveMembersButton
            data-testid="remove-selected-from-community"
            onClick={() => setConfirmDialogVisible(true)}
          >
            {t('communities.removeSelected')}
          </StyledRemoveMembersButton>
        )}
      </ActionPanel>
      {confirmDialogVisible && (
        <Dialog onCancel={() => setConfirmDialogVisible(false)} title={dialogTitle}>
          <Dialog.Action onClick={() => setConfirmDialogVisible(false)}>{t('communities.cancel')}</Dialog.Action>
          <Dialog.Action
            onClick={async () => {
              setConfirmDialogVisible(false)
              await removeSelectedFromCommunity.mutateAsync({
                id: selectedCommunity.id,
                clientId,
                filters: { subscription_data: filters },
                fanSubscriptionTags: { any: [], not: [] },
                communicationChannel,
              })
              invalidateCommunityCount(selectedCommunity.id, selectedCommunity.title)
            }}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            {t('communities.remove')}
          </Dialog.Action>
        </Dialog>
      )}
    </>
  )
}
