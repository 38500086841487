import { AudioRecorder, BUTTON_VARIANTS, Button, UploadIcon, Typography } from '@community_dev/pixels'
import * as filestack from 'filestack-js'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { StyledTextButton } from 'screens/Onboarding/styled'
import { baseConfig } from 'utils/config'

const StyledRecorder = styled.div`
  margin: 56px 0 95px;
  width: 100%;
`

const StyledUploadButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.LINK,
})`
  font-weight: 900;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.08px;
  color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};

  svg {
    vertical-align: top;
  }
`

type RecordVoicemailProps = {
  onCancel?: (...args: any[]) => any
  onUrl: (...args: any[]) => any
}

export function RecordVoicemail({ onCancel, onUrl }: RecordVoicemailProps): JSX.Element {
  const picker = useRef<any>()

  useEffect(() => {
    const instance = filestack.init(baseConfig.filestackApiKey)
    const options = {
      maxFiles: 1,
      accept: ['audio/mpeg'],
      fromSources: ['local_file_system'],
      uploadInBackground: false,
      maxSize: 50 * 1024 * 1024,
      onFileUploadFinished: (file) => onUrl(file.url),
    }

    picker.current = instance.picker(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function upload() {
    picker.current && picker.current.open()
  }

  return (
    <>
      <Typography variant="h1">Recording</Typography>
      <StyledRecorder>
        <AudioRecorder maxDuration={30} onComplete={onUrl} />
      </StyledRecorder>
      <StyledUploadButton onClick={upload}>
        <UploadIcon /> Upload Pre-recorded Voicemail
      </StyledUploadButton>
      {onCancel && (
        <div>
          <StyledTextButton onClick={onCancel}>Cancel</StyledTextButton>
        </div>
      )}
    </>
  )
}
