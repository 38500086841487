import { DecoratorNode, LexicalNode, NodeKey, SerializedLexicalNode, Spread } from 'lexical'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { PLACEHOLDER_LIMIT } from '../hooks'

// Check out the docs for more info on custom nodes
// https://lexical.dev/docs/concepts/nodes

export type PlaceholderPayload = {
  key?: NodeKey
  id: string
  name: string
  source: string
}

export const StyledPlaceholder = styled.span<{ $ignored?: boolean }>`
  color: ${({ theme, $ignored }) => ($ignored ? theme?.COLORS?.SUBTEXT : theme?.COLORS?.TEXT || 'initial')};
  background: ${({ theme, $ignored }) =>
    $ignored ? theme?.COLORS?.PLACEHOLDER_NODE_DISABLED_BACKGROUND : theme?.COLORS?.PLACEHOLDER_NODE_BACKGROUND};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 4px;
`

type PlaceholderComponentProps = {
  children: ReactNode
  ignored: boolean
}

function PlaceholderComponent({ children, ignored }: PlaceholderComponentProps) {
  return <StyledPlaceholder $ignored={ignored}>{children}</StyledPlaceholder>
}

export type SerializedPlaceholderNode = Spread<
  {
    id: string
    name: string
    source: string
    type: 'placeholder'
    version: 1
  },
  SerializedLexicalNode
>

/**
 * This node represents a dynamic field placeholder token
 */
export class PlaceholderNode extends DecoratorNode<ReactNode> {
  // Private, JSON serializable node properties
  __id: string
  __name: string
  __source: string

  // Lexical uses the type to be able to reconstruct a node back with its associated class
  // prototype during deserialization (important for copy + paste!)
  static getType(): string {
    return 'placeholder'
  }

  // Lexical uses cloning to ensure consistency between creation of new EditorState snapshots
  static clone(node: PlaceholderNode): PlaceholderNode {
    return new PlaceholderNode(node.__id, node.__name, node.__source, node.__key)
  }

  static importJSON(serializedNode: SerializedPlaceholderNode): PlaceholderNode {
    return $createPlaceholderNode(serializedNode.id, serializedNode.name, serializedNode.source)
  }

  constructor(id: string, name: string, source: string, key?: NodeKey) {
    super(key)
    this.__id = id
    this.__name = name
    this.__source = source
  }

  getId(): string {
    // getLatest() ensures we are getting the most up-to-date value from the EditorState
    const self = this.getLatest()
    return self.__id
  }

  getName(): string {
    const self = this.getLatest()
    return self.__name
  }

  getSource(): string {
    const self = this.getLatest()
    return self.__source
  }

  // Set the text content, this represents what will be sent to the backend
  getTextContent(): string {
    const self = this.getLatest()
    return `{${self.__id}:${self.__source}}`
  }

  // Initialize container as empty span
  createDOM(): HTMLElement {
    return document.createElement('span')
  }

  // Returning false tells Lexical that this node does not need its DOM element replaced by createDOM
  updateDOM(): boolean {
    return false
  }

  // This specifies how the node should be represented when serialized to JSON
  exportJSON(): SerializedPlaceholderNode {
    return {
      ...super.exportJSON(),
      id: this.getId(),
      name: this.getName(),
      source: this.getSource(),
      type: 'placeholder',
      version: 1,
    }
  }

  decorate(): ReactNode {
    const self = this.getLatest()
    const parent = self.getParent()
    const parentsChildren = parent?.getChildren() || []
    const placeholderNodes = parentsChildren.filter((node) => $isPlaceholderNode(node))
    const index = placeholderNodes.findIndex((p) => p.__key === self.__key)
    const ignored = index >= PLACEHOLDER_LIMIT
    return <PlaceholderComponent ignored={ignored}>{`{${self.__name}}`}</PlaceholderComponent>
  }
}

export function $createPlaceholderNode(id: string, name: string, source: string): PlaceholderNode {
  return new PlaceholderNode(id, name, source)
}

export function $isPlaceholderNode(node: LexicalNode | null | undefined): node is PlaceholderNode {
  return node instanceof PlaceholderNode
}
