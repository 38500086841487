import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { getRestrictedKeywords, RestrictedKeywords } from 'api/restrictedKeywords'
import { QUERY_CACHE } from 'constants/query-cache'

type RestrictedKeywordsQuery<T> = {
  select?: (data: RestrictedKeywords) => T
}
export const useRestrictedKeywords = <T = RestrictedKeywords>({
  select,
}: RestrictedKeywordsQuery<T> = {}): UseQueryResult<T> => {
  return useQuery([QUERY_CACHE.SETTINGS.RESTRICTED_KEYWORDS], getRestrictedKeywords, { select })
}
