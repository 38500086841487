import { CommunityLogo, DropdownArrowIcon, SPACING } from '@community_dev/pixels'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components'

import { DESKTOP_BREAK } from 'constants/theme'

type StyledFooterProps = {
  $collapsed?: boolean
}

const StyledFooter = styled.footer<StyledFooterProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: ${SPACING[5]} ${SPACING[4]};

  ${({ $collapsed }) =>
    !$collapsed &&
    css`
      &::before {
        content: ' ';
        width: calc(100% - (${SPACING[4]} * 2));
        position: absolute;
        height: 1px;
        background: ${({ theme }) => theme?.COLORS?.BORDERS};
        top: 0;
        left: ${SPACING[4]};
      }
    `}
`

type StyledCollapseProps = {
  $collapsed?: boolean
}

const StyledCollapse = styled.button<StyledCollapseProps>`
  display: none;

  @media (min-width: ${DESKTOP_BREAK}) {
    width: ${SPACING[5]};
    height: ${SPACING[5]};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
    transform: rotate(${({ $collapsed }) => ($collapsed ? '-90deg' : '90deg')});
  }
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
  overflow: hidden;
  svg {
    image-rendering: crisp-edges;
  }
`

type SidebarFooterProps = {
  collapsed: boolean
  onLinkClick: (...args: any[]) => any
  onToggleCollapse: (...args: any[]) => any
}

export function SidebarFooter({ collapsed, onLinkClick, onToggleCollapse }: SidebarFooterProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  return (
    <StyledFooter $collapsed={collapsed}>
      {!collapsed && (
        <StyledLink id="logo" onClick={onLinkClick} to="/">
          <CommunityLogo aria-label="Community Home" color={COLORS?.LOGO} height="22px" />
        </StyledLink>
      )}
      <StyledCollapse
        $collapsed={collapsed}
        aria-label={`${collapsed ? 'Expand' : 'Collapse'} menu`}
        onClick={onToggleCollapse}
      >
        <DropdownArrowIcon color={COLORS?.LOGO} size={12} />
      </StyledCollapse>
    </StyledFooter>
  )
}
