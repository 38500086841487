import { useDebouncedValue } from '@community_dev/hooks'
import { useQuery } from '@tanstack/react-query'

import { postShortenableMessageUrls } from 'api/message'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

export function useShortenableLinks(
  text: string | undefined,
  debounceInterval = 200,
): { hasLinks: boolean; links: string[] } {
  const debouncedText = useDebouncedValue(text || '', debounceInterval, true)
  const clientId = useClientId()
  const { data: links = [] } = useQuery(
    [QUERY_CACHE.GET_MESSAGE_LINKS, debouncedText],
    () => postShortenableMessageUrls({ text: debouncedText, clientId }).then((data) => data.links),
    {
      enabled: debouncedText.trim().length > 0,
      keepPreviousData: true,
    },
  )
  if (text && links) {
    return {
      hasLinks: links.length > 0,
      links,
    }
  } else {
    return {
      hasLinks: false,
      links: [],
    }
  }
}
