import { useMemo } from 'react'

import { CAPABILITIES, CapabilityName } from 'constants/capabilities'
import { useCapabilities } from 'contexts/bootstrap/useCapabilities'
import { useHasCapability } from 'hooks/useUserCapability'

export enum ComposeRole {
  CAMPAIGN_READER = 'CAMPAIGN_READER',
  CAMPAIGN_DRAFTER = 'CAMPAIGN_DRAFTER',
  CAMPAIGN_WRITER = 'CAMPAIGN_WRITER',
}

const isCampaignWriter = (capabilities: CapabilityName[]): boolean => {
  return capabilities.includes(CAPABILITIES.CLIENT.MESSAGES.WRITE)
}

const isCampaignDraftWriter = (capabilities: CapabilityName[]): boolean => {
  const hasScheduling = capabilities.includes(CAPABILITIES.FEATURE.CAMPAIGN_SCHEDULING.ALL)
  const canReadMessages = capabilities.includes(CAPABILITIES.CLIENT.MESSAGES.READ)
  const canWriteMessages = capabilities.includes(CAPABILITIES.CLIENT.MESSAGES.WRITE)
  const canWritePendingReview = capabilities.includes(CAPABILITIES.CLIENT.MESSAGES.WRITE_PENDING_REVIEW)

  return !canWriteMessages && hasScheduling && canReadMessages && canWritePendingReview
}

export const toComposeRoleFromCapabilities = (capabilities: CapabilityName[]): ComposeRole => {
  if (isCampaignWriter(capabilities)) {
    return ComposeRole.CAMPAIGN_WRITER
  }

  if (isCampaignDraftWriter(capabilities)) {
    return ComposeRole.CAMPAIGN_DRAFTER
  }

  return ComposeRole.CAMPAIGN_READER
}

export const useComposeRole = (): ComposeRole => {
  const capabilities = useCapabilities()

  return useMemo(() => {
    return toComposeRoleFromCapabilities(capabilities as CapabilityName[])
  }, [capabilities])
}

export function useCampaignPreviewAndTestEnabled(): {
  isCampaignPreviewEnabled: boolean
  isCampaignTestEnabled: boolean
} {
  const isCampaignWriterRole = useComposeRole() === ComposeRole.CAMPAIGN_WRITER

  const isCampaignPreviewEnabled = useHasCapability(CAPABILITIES.FEATURE.CAMPAIGN_PREVIEW.ALL)

  const isCampaignTestCapabilityEnabled = useHasCapability(CAPABILITIES.FEATURE.CAMPAIGN_TEST.ALL)
  const isCampaignTestEnabled = isCampaignWriterRole && isCampaignTestCapabilityEnabled

  return {
    isCampaignPreviewEnabled,
    isCampaignTestEnabled,
  }
}
