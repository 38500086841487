import { Button, CloseIcon, LoadingIndicator, Modal, SPACING } from '@community_dev/pixels'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { ClientSettings, patchClientSettings } from 'api/client'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { useClientSettings } from 'hooks/useClientSettings'

const StyledFieldset = styled.fieldset`
  margin: ${SPACING[5]} ${SPACING[5]} 0;
  padding: 0;
  border: none;
`

const StyledRadioContent = styled.div`
  &:not(:last-of-type) {
    margin-bottom: ${SPACING[5]};
  }
`

const StyledRadioLabel = styled.label`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
  &:first-child {
    margin: 0 ${SPACING[6]} 0 0;
  }
`

const StyledRadioDetails = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-size: 14px;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  margin-left: ${SPACING[5]};
`

const StyledInputRadio = styled.input`
  opacity: 0;
  &:checked {
    + ${StyledRadioLabel}::before {
      border: ${({ theme }) => `1px solid ${theme?.COLORS?.LINKS}`};
    }
    + ${StyledRadioLabel}::after {
      background: ${({ theme }) => theme?.COLORS?.LINKS};
    }
  }
  + ${StyledRadioLabel} {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 0 0 0 9px;
    &::before {
      content: '';
      position: absolute;
      left: -22px;
      display: inline-block;
      width: ${SPACING[4]};
      height: ${SPACING[4]};
      border-radius: 50%;
      border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
      background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
      top: 3px;
    }
    &::after {
      content: '';
      position: absolute;
      left: -20px;
      display: inline-block;
      width: ${SPACING[3]};
      height: ${SPACING[3]};
      border-radius: 50%;
      top: 5px;
    }
  }
`

type UtmOutboundLinksModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const UtmOutboundLinksModal = ({ isOpen, onClose }: UtmOutboundLinksModalProps): JSX.Element => {
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const clientId = useClientId()
  const [value, setValue] = useState<ClientSettings['addUtmToOutboundUrls'] | null>(null)
  const { data, isLoading, refetch } = useClientSettings()
  const { mutate: updateClientSettings, isLoading: processing } = useMutation(patchClientSettings, {
    onMutate: (newPreference) => {
      queryClient.cancelQueries([QUERY_CACHE.SETTINGS.CLIENT_SETTINGS, clientId])

      const previousPreferences: Partial<ClientSettings> | undefined = queryClient.getQueryData([
        QUERY_CACHE.SETTINGS.CLIENT_SETTINGS,
        clientId,
      ])

      queryClient.setQueryData(
        [QUERY_CACHE.SETTINGS.CLIENT_SETTINGS, clientId],
        (old: Partial<ClientSettings> | undefined) => ({
          ...old,
          ...newPreference?.body,
        }),
      )

      return () => queryClient.setQueryData([QUERY_CACHE.SETTINGS.CLIENT_SETTINGS, clientId], previousPreferences)
    },
    onSuccess: () => {
      onClose()
      toast.success(t('settings.changesSaved'))
    },
    onError: (err, newPreference, rollback: any) => {
      rollback()
      toast.error(t('settings.changesCouldNotBeSaved'))
    },
    onSettled: () => {
      refetch()
    },
  })

  useEffect(() => {
    if (!data || value) return
    setValue(data.addUtmToOutboundUrls)
  }, [data, value])

  const handleSubmit = () => {
    updateClientSettings({
      clientId,
      body: { addUtmToOutboundUrls: value },
    })
  }

  function handleCancel() {
    onClose()
  }

  const handleChangeRadio = (evt) => {
    setValue(evt.target.value)
  }

  if (isLoading) return <LoadingIndicator />

  return (
    <Modal maxWidth={452} onClose={handleCancel} open={isOpen}>
      <Modal.Header>
        <Modal.Header.Center>
          <div>{t('settings.customize.utm.addUtmToOutboundUrls.title')}</div>
        </Modal.Header.Center>
        <Modal.Header.Right onClose={handleCancel}>
          <CloseIcon color={COLORS.SUBTEXT} size={12} />
        </Modal.Header.Right>
      </Modal.Header>
      <Modal.Body>
        <StyledFieldset>
          <StyledRadioContent>
            <StyledInputRadio
              checked={value === 'automatic'}
              id="automatic"
              name="utm-outbound-links"
              onChange={handleChangeRadio}
              type="radio"
              value="automatic"
            />
            <StyledRadioLabel htmlFor="automatic">
              {t('settings.customize.utm.addUtmToOutboundUrls.automatic')}
            </StyledRadioLabel>
            <StyledRadioDetails>
              <p>
                <Trans i18nKey="settings.customize.utm.enabledDetails.description">
                  {t('settings.customize.utm.enabledDetails.description')}
                </Trans>
              </p>
              <ul>
                {/* sadly, the types for i18n are currently unable to deal with array access like this.
                    https://github.com/microsoft/TypeScript/issues/33304  */}
                <li>{t('settings.customize.utm.enabledDetails.bullets.0' as any)}</li>
                <li>{t('settings.customize.utm.enabledDetails.bullets.1' as any)}</li>
                <li>{t('settings.customize.utm.enabledDetails.bullets.2' as any)}</li>
              </ul>
              <Trans i18nKey="settings.customize.utm.enabledDetails.learnMore">
                <a
                  href="https://incommunity.zendesk.com/hc/en-us/articles/4405473341587-Using-UTM-Parameters-to-Measure-Results"
                  rel="noreferrer"
                  target="_blank"
                >
                  Learn more
                </a>
              </Trans>
            </StyledRadioDetails>
          </StyledRadioContent>
          <StyledRadioContent>
            <StyledInputRadio
              checked={value === 'manual'}
              id="manual"
              name="utm-outbound-links"
              onChange={handleChangeRadio}
              type="radio"
              value="manual"
            />
            <StyledRadioLabel htmlFor="manual">
              {t('settings.customize.utm.addUtmToOutboundUrls.manual')}
            </StyledRadioLabel>
            <StyledRadioDetails>
              <p>{t('settings.customize.utm.disabledDetails.description')}</p>
            </StyledRadioDetails>
          </StyledRadioContent>
        </StyledFieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={isLoading || processing || value === data?.addUtmToOutboundUrls} onClick={handleSubmit}>
          {processing ? t('saving') : t('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
