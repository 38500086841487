import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export function getMessageLimits({ clientId }: { clientId: string }): Promise<any> {
  const requestRoute = route(ENDPOINTS.MESSAGE_LIMITS, {
    clientId,
  })

  return request(requestRoute).then(({ data }) => data)
}
