import { useCallback, useMemo } from 'react'
import useLocalStorageState from 'use-local-storage-state'

import { CampaignFilter, CampaignFilters, CampaignFilterStorage, DEFAULT_CAMPAIGN_FILTERS } from './useCampaignList'

export type SetCampaignFilters = (newFiltersFn: (prevFilters: CampaignFilters) => CampaignFilters) => void
//We want separate keys for each filter so that way we can remove or add filters without having to reset the default state of every user
export const useCampaignListFilters = (): [CampaignFilters, SetCampaignFilters] => {
  const [apiFilter, setApiFilter] = useLocalStorageState<boolean>(CampaignFilterStorage[CampaignFilter.API], {
    defaultValue: DEFAULT_CAMPAIGN_FILTERS[CampaignFilter.API],
  })
  const [birthdayFilter, setBirthdayFilter] = useLocalStorageState<boolean>(
    CampaignFilterStorage[CampaignFilter.BIRTHDAY],
    { defaultValue: DEFAULT_CAMPAIGN_FILTERS[CampaignFilter.BIRTHDAY] },
  )
  const [testFilter, setTestFilter] = useLocalStorageState<boolean>(CampaignFilterStorage[CampaignFilter.TEST], {
    defaultValue: DEFAULT_CAMPAIGN_FILTERS[CampaignFilter.TEST],
  })

  const filters = useMemo(
    () => ({
      [CampaignFilter.API]: apiFilter,
      [CampaignFilter.BIRTHDAY]: birthdayFilter,
      [CampaignFilter.TEST]: testFilter,
    }),
    [apiFilter, birthdayFilter, testFilter],
  )

  const setFilters = useCallback(
    (newFiltersFn: (prevFilters: CampaignFilters) => CampaignFilters) => {
      const newFilters = newFiltersFn(filters)
      setApiFilter(Boolean(newFilters[CampaignFilter.API] ?? DEFAULT_CAMPAIGN_FILTERS[CampaignFilter.API]))
      setBirthdayFilter(newFilters[CampaignFilter.BIRTHDAY])
      setTestFilter(newFilters[CampaignFilter.TEST])
    },
    [setApiFilter, setBirthdayFilter, setTestFilter, filters],
  )

  return useMemo(() => [filters, setFilters], [filters, setFilters])
}
