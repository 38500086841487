import { Button, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { useActionPanel } from 'components/ActionPanel/ActionPanelContext'
import { TABLET_BREAK } from 'constants/theme'

const StyledViewProfile = styled.div`
  display: none;
  @media (min-width: ${TABLET_BREAK}) {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    height: 100%;
  }

  ${Button} {
    margin-left: ${SPACING[2]};
  }
`

export function InboxChatOptions(): JSX.Element {
  const { openActionPanel } = useActionPanel()

  function openDetails() {
    return openActionPanel && openActionPanel()
  }

  return (
    <StyledViewProfile data-testid="inbox-actions">
      <Button onClick={openDetails}>Details</Button>
    </StyledViewProfile>
  )
}
