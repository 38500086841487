import { hasMedia } from '@community_dev/pixels'
import { convertKeysToCamelCase } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { getUrlMetadata } from 'api/urlMetadata'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useCampaignDetails } from 'hooks/useCampaign'
import { useClient } from 'hooks/useClient'
import { request } from 'utils/api'
import dayjs from 'utils/dayjs'

function transformToWholeNumberConversion(portion, total) {
  return (portion / total) * 100
}

export type Click = {
  totalCount?: number
  lastCount?: number
  count: number
  tick: number | string
}

export type LinkMetric = {
  clicks: Click[]
  image: {
    url: string
    height?: number
    type?: unknown
    width?: number
  }
  img: string
  title: string
  totalHits: number
  url: string
}

export type MediaMetric = {
  clicks: Click[]
  img: string
  totalHits: number
  url: string
}

export type CampaignMetrics = {
  links: LinkMetric[]
  media: MediaMetric[]
}

type UseMetrics = {
  isLoading: boolean
  linkMetrics: LinkMetric[]
  hasLinkMetrics: boolean
  mediaMetrics: MediaMetric[]
  hasMediaMetrics: boolean
}

function isClick(click: unknown): click is Click {
  return typeof click === 'object' && click !== null && 'tick' in click
}

function isLinkMetric(metric: unknown): metric is LinkMetric {
  return typeof metric === 'object' && metric !== null && 'url' in metric
}

function isMediaMetric(metric: unknown): metric is MediaMetric {
  return typeof metric === 'object' && metric !== null && 'img' in metric
}

export function useMetrics(totalRecipients: number): UseMetrics {
  const { data: client } = useClient()
  const clientId = client?.id
  const { campaignId: pathCampaignId, id } = useParams<{ campaignId?: string; id: string }>()
  const campaignId = pathCampaignId || id

  const { data: campaignData, isInitialLoading: campaignIsLoading } = useCampaignDetails({
    campaignId,
    enabled: Boolean(campaignId),
  })

  const { data: campaignMetricData, isInitialLoading: campaignMetricIsFetching } = useQuery(
    [QUERY_CACHE.CAMPAIGN_METRIC, { campaignId }],
    async () => {
      const { campaign: campaignLinkHitData } = await request<CamelCasedPropertiesDeep<Api.V1.Metrics.Campaign>>(
        `/metrics/v1/link-hits/clients/${clientId}/campaigns/${campaignId}`,
      )

      const startTime = dayjs(campaignLinkHitData?.startTime)
      const now = dayjs()
      const diffMinutes = Math.ceil(now.diff(startTime, 'minutes', true))
      const nowWithinHourOfStartTime = diffMinutes < 60
      const bucketsToRequest = nowWithinHourOfStartTime ? diffMinutes : 60
      const endTime = nowWithinHourOfStartTime ? now : startTime.clone().add(60, 'minute')

      return {
        ...campaignLinkHitData,
        startTime: startTime.unix(),
        endTime: endTime.unix(),
        bucketsToRequest,
      }
    },
    { enabled: Boolean(campaignId), retry: false },
  )

  const campaignHasMedia = useMemo(() => {
    // MMS campaigns do not have link media so there will be no metrics
    if (campaignData?.media?.disposition === MediaDisposition.ATTACHMENT) {
      return false
    }
    return campaignMetricData?.urls?.find?.(({ url }) => hasMedia(url))
  }, [campaignData, campaignMetricData?.urls])

  const { data: linkPreviewData, isInitialLoading: linkPreviewIsFetching } = useQuery(
    [QUERY_CACHE.URL_METADATA, { campaignId, urls: campaignMetricData?.urls }],
    async () => {
      if (campaignMetricData) {
        const promises = campaignMetricData?.urls?.map((url) => {
          if (hasMedia(url?.url)) {
            return url
          }

          return getUrlMetadata({
            url: url?.url,
            clientId: clientId!,
          })
            .then((data) => ({ ...url, ...data, img: data?.image?.url }))
            .then(convertKeysToCamelCase)
        })

        return Promise.all(promises)
      }
    },
    {
      enabled: Boolean(campaignMetricData?.urls?.length),
      retry: false,
      staleTime: STALE_TIME.FOREVER,
    },
  )

  const { data: campaignMetricTimeData, isInitialLoading: campaignMetricTimeIsFetching } = useQuery<
    CamelCasedPropertiesDeep<Api.V1.Metrics.LinkHits>
  >(
    [QUERY_CACHE.CAMPAIGN_METRIC_TIME, { campaignId }],
    () =>
      request<CamelCasedPropertiesDeep<Api.V1.Metrics.LinkHits>>(
        // eslint-disable-next-line max-len
        `/metrics/v1/link-hits/time-view/clients/${clientId}/campaigns/${campaignId}?start_time=${campaignMetricData?.startTime}&end_time=${campaignMetricData?.endTime}&buckets=${campaignMetricData?.bucketsToRequest}`,
      ),
    { enabled: Boolean(campaignMetricData?.urls?.length), retry: false },
  )

  const isLoading =
    campaignMetricIsFetching || linkPreviewIsFetching || campaignMetricTimeIsFetching || campaignIsLoading

  const campaignMetrics = campaignMetricData?.urls?.reduce<CampaignMetrics>(
    (metrics, url, index) => {
      if (isLoading) {
        return metrics
      }

      const isMedia = hasMedia(url?.url)
      const lastCount = transformToWholeNumberConversion(url?.totalHits, totalRecipients)

      const currentLinkPreviewData = isMedia
        ? {
            ...url,
            img: campaignData?.media?.thumbnailUrl || campaignData?.media?.url || url?.url,
          }
        : linkPreviewData?.[index]

      const currentLinkHits = campaignMetricTimeData?.linkHits?.find(
        ({ url: linkPreviewUrl }) => linkPreviewUrl === url?.url,
      )
      const showNowClicks = currentLinkHits?.clicks?.length === 60

      const clicks = currentLinkHits
        ? [
            ...currentLinkHits?.clicks?.reduce<Click[]>(
              (accumulatedClicks, currentClick, index) => {
                const prevClick = accumulatedClicks[accumulatedClicks.length - 1]
                const totalCount = (prevClick?.totalCount || 0) + currentClick?.totalValue
                const countConversion = transformToWholeNumberConversion(totalCount, totalRecipients)

                return [
                  ...accumulatedClicks,
                  {
                    totalCount,
                    count: countConversion,
                    tick: `${index + 1}m`,
                  },
                ]
              },
              [
                {
                  totalCount: 0,
                  count: 0,
                  tick: 0,
                },
              ],
            ),
            showNowClicks && {
              lastCount,
              tick: 'now',
            },
          ].filter(isClick)
        : []

      const result = {
        ...url,
        ...currentLinkPreviewData,
        clicks,
      }

      return {
        links: [...metrics.links, !isMedia && result].filter(isLinkMetric),
        media: [...metrics.media, isMedia && campaignHasMedia && result].filter(isMediaMetric),
      }
    },
    {
      links: [],
      media: [],
    },
  )

  const linkMetrics = campaignMetrics?.links || []
  const hasLinkMetrics = !!linkMetrics?.length
  const mediaMetrics = campaignMetrics?.media || []
  const hasMediaMetrics = !!mediaMetrics?.length

  return {
    isLoading,
    linkMetrics,
    hasLinkMetrics,
    mediaMetrics,
    hasMediaMetrics,
  }
}
