import { Layout, Typography, SPACING, FONT_WEIGHT } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { MAX_CENTER_COLUMN_WIDTH } from './HomeScreen'

import { ActionCard } from 'components/ActionCard'
import { ROUTES } from 'constants/routes'
import { useAddons } from 'hooks/useAddons'
import analytics from 'utils/analytics'

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.COLORS.LINKS};
`

const trackSeeAll = () => {
  analytics.track(analytics.events.SeeAllAddOns())
}

export const FeaturedAddOns = (): JSX.Element => {
  const { t } = useTranslation()
  const { featuredAddOns = [] } = useAddons()
  if (!featuredAddOns) return <></>
  return (
    <Layout display="flex" justifyContent="center" padding={SPACING[4]}>
      <Layout
        maxWidth={MAX_CENTER_COLUMN_WIDTH}
        //Same maxWidth as HomeSuggestions.tsx
        width="100%"
      >
        <Layout alignItems="center" display="flex" justifyContent="space-between">
          <Typography variant="h2">{t('addOns.getTheMostOutOfCommunity')}</Typography>
          <StyledLink onClick={trackSeeAll} to={ROUTES.ADD_ONS.ROOT}>
            <Typography fontWeight={FONT_WEIGHT[5]}> See All</Typography>
          </StyledLink>
        </Layout>
        <Layout display="grid" gap="1rem" gridTemplateColumns="repeat(auto-fill, minmax(335px, 1fr))">
          {featuredAddOns.map((addOn) => (
            <ActionCard {...addOn} height="106px" key={addOn.title} showArrow={false} />
          ))}
        </Layout>
      </Layout>
    </Layout>
  )
}
