import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router'

import { StyledRecipientList, StyledRecipientListScroll } from './RecipientList.style'

import { getCampaignRecipients } from 'api/campaign-recipients'
import RecipientItem from 'components/RecipientItem'
import { QUERY_CACHE } from 'constants/query-cache'
import { ConvoModal } from 'containers/ConvoModal'
import { normalizeFan } from 'utils/normalize'

export default function RecipientList(): JSX.Element {
  const match = useRouteMatch<{ campaignId: string }>('/campaigns/:campaignId')
  const { campaignId } = match?.params || {}
  const [viewingFanConvo, setViewingFanConvo] = useState<string | null>(null)
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: [QUERY_CACHE.CAMPAIGN.RECIPIENTS, campaignId],
    queryFn: ({ pageParam = 1 }) => getCampaignRecipients({ campaignId: campaignId || '', pageParam: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage?.paginationData?.hasNextPage && (lastPage?.paginationData?.pageNumber || 0) + 1
    },
  })
  const items = useMemo(() => {
    return (data?.pages?.map((page) => page?.data).flat() || []).map(normalizeFan)
  }, [data])

  return (
    <>
      {viewingFanConvo && <ConvoModal fanId={viewingFanConvo} onClose={() => setViewingFanConvo(null)} />}
      <StyledRecipientList>
        <StyledRecipientListScroll hasMore={hasNextPage} loadMore={fetchNextPage} loading={isLoading} threshold={240}>
          {items.map((item) => {
            return <RecipientItem fan={item} key={item.id} onSelectItem={(fan) => fan && setViewingFanConvo(fan.id)} />
          })}
        </StyledRecipientListScroll>
      </StyledRecipientList>
    </>
  )
}
