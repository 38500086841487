import { FanActivationMode } from '@community_dev/types/lib/api/FanRegistration'
import { UseQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'

import { useClientSettingsQuery } from './useClientSettingsQuery'

import { ClientSettings } from 'api/client'

export const FROST_ACTIVATION_MODE = 'web'
export const REPLY_Y_ACTIVATION_MODE = 'text'

export const useClientSettings = ({
  onSuccess,
}: {
  onSuccess?: (res: ClientSettings) => void
} = {}): UseQueryResult<ClientSettings & { fanActivationMode: FanActivationMode }> => {
  const query = useClientSettingsQuery({
    onSuccess(res) {
      if (onSuccess) {
        onSuccess(res)
      }
    },
  })

  const activationModeDefaulted = query.data?.fanActivationMode ?? 'web'

  const defaultedQuery = useMemo(() => {
    if (!query.data) return query
    return { ...query, data: { ...query.data, fanActivationMode: activationModeDefaulted } }
  }, [query, activationModeDefaulted])

  return defaultedQuery as UseQueryResult<ClientSettings & { fanActivationMode: FanActivationMode }>
}
