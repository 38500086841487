import {
  Button,
  BUTTON_VARIANTS,
  Dialog,
  DIALOG_VARIANTS,
  Layout,
  SPACING,
  Tooltip,
  Typography,
  WarningIcon,
  CopyIcon,
  BORDER_RADIUS,
} from '@community_dev/pixels'
import { useMutation, useQuery } from '@tanstack/react-query'
import copy from 'copy-to-clipboard'
import getDifferenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import formatDistance from 'date-fns/formatDistance'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { ApiAuthTokensEditModal } from './ApiAuthTokensEditModal'

import { ApiAuthToken, deleteApiAuthToken, getApiAuthTokens } from 'api/api-auth-tokens'
import { CAPABILITIES } from 'constants/capabilities'
import { COMMUNITY_DEVELOPER_DOCS_URL } from 'constants/communityLinks'
import { QUERY_CACHE } from 'constants/query-cache'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useClient, useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import Sentry from 'integrations/Sentry'
import { Setting } from 'layouts/SettingsLayout'

const StyledContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const StyledClientId = styled.code`
  border-radius: ${BORDER_RADIUS[1]};
  cursor: pointer;
  background: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_2};
  padding: ${SPACING[2]};
`

const StyledCopyIcon = styled(CopyIcon)`
  display: inline-block;
  margin-top: 3px;
`

const StyledWarningIcon = styled(WarningIcon).attrs({
  size: 20,
})`
  margin-right: ${SPACING[1]};
`

function ClientId() {
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const clientId = useClientId()
  return (
    <Tooltip content={t('copyToClipboard')}>
      <StyledClientId
        aria-label={t('copyToClipboard')}
        onClick={() => {
          copy(clientId)
          showToastMessage({
            message: t('copiedToClipboard'),
            success: true,
          })
        }}
      >
        {clientId} <StyledCopyIcon />
      </StyledClientId>
    </Tooltip>
  )
}

function ApiAuthTokenLastUsed({ date }: { date: string | null }): JSX.Element {
  const { COLORS } = useTheme()
  const d = date !== null ? new Date(date) : null
  return (
    <Tooltip
      content={
        d === null ? (
          <Trans i18nKey="settings.apiAuthTokens.tokenNeverUsed" />
        ) : (
          <Trans
            i18nKey="settings.apiAuthTokens.tokenLastUsedOn"
            values={{ date: format(d, "eee, MMM do y 'at' HH:mm") }}
          />
        )
      }
      placement="left"
    >
      <div>
        <Typography
          color={COLORS.SUBTEXT}
          cursor="default"
          display="inline-block"
          marginBottom={0}
          marginTop={SPACING[2]}
          variant="body2"
        >
          {d === null ? <Trans i18nKey="neverUsed" /> : `Last used ${formatDistance(d, new Date())} ago`}
        </Typography>
      </div>
    </Tooltip>
  )
}

function ApiAuthTokenExpiration({ token }: { token: ApiAuthToken }): JSX.Element | null {
  const { COLORS } = useTheme()

  const expirationDate = useMemo(() => {
    // always pick the expiration date that’s close to the present (i.e. the smaller value).
    // if we have both expirations, pick the one that’s closer to the present
    if (token.dateExpirationShort && token.dateExpirationLong) {
      const shortExpiration = new Date(token.dateExpirationShort)
      const longExpiration = new Date(token.dateExpirationLong)

      return shortExpiration.getTime() < longExpiration.getTime() ? shortExpiration : longExpiration
    }

    // otherwise, pick the one that’s not null
    if (token.dateExpirationShort) {
      return new Date(token.dateExpirationShort)
    }

    if (token.dateExpirationLong) {
      return new Date(token.dateExpirationLong)
    }

    return null
  }, [token.dateExpirationLong, token.dateExpirationShort])

  if (!expirationDate) {
    return null
  }

  return (
    <Tooltip
      content={
        <Trans
          i18nKey="settings.apiAuthTokens.tokenExpiresOn"
          values={{ date: format(expirationDate, "eee, MMM do y 'at' HH:mm") }}
        />
      }
      placement="right"
    >
      <span>
        <Typography color={COLORS.SUBTEXT} display="inline-block" margin={0} variant="body2">
          {getDifferenceInDays(expirationDate, new Date()) <= 7 && <StyledWarningIcon />}
          <Trans
            i18nKey="settings.apiAuthTokens.expiresIn"
            values={{
              duration: formatDistance(expirationDate, new Date()),
            }}
          />
        </Typography>
      </span>
    </Tooltip>
  )
}

export function ApiAuthTokens(): JSX.Element {
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const { data: seat } = useSeat()
  const { data: client } = useClient()
  const clientId = useClientId()
  const [tokenToDelete, setTokenToDelete] = useState<ApiAuthToken | undefined>()
  const [tokenToEdit, setTokenToEdit] = useState<ApiAuthToken | undefined>()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const hasApiTokenWrite = useHasCapability(CAPABILITIES.CLIENT.API_AUTH_TOKENS.WRITE)

  const { COLORS } = useTheme()
  const { data: tokens, refetch: refetchTokens } = useQuery(
    [QUERY_CACHE.SETTINGS.API_AUTH_TOKENS, { clientId }],
    () => getApiAuthTokens({ clientId }),
    {
      onError(e) {
        Sentry.captureException(e)
        showToastMessage({
          message: 'Failed to retrieve API Tokens',
          success: false,
        })
      },
    },
  )

  const { mutateAsync: deleteTokenMutation } = useMutation(
    [QUERY_CACHE.SETTINGS.API_AUTH_TOKENS, { clientId }],
    (id: string) => deleteApiAuthToken(id, clientId, seat!.id),
    {
      onError(e) {
        Sentry.captureException(e)
        showToastMessage({
          message: 'Failed to delete API Token',
          success: false,
        })
      },
      onSuccess() {
        refetchTokens()
      },
    },
  )

  return (
    <StyledContainer>
      <Layout alignItems="start" display="flex" flexDirection="row">
        <Layout flex="1">
          <Typography component="h2" margin="0" paddingBottom={SPACING[3]} variant="h3">
            {t('settings.apiAuthTokens.headline')}
          </Typography>
        </Layout>
        <Button
          disabled={!hasApiTokenWrite}
          onClick={() => setIsCreateModalOpen(true)}
          style={{ minWidth: '200px' }}
          variant={BUTTON_VARIANTS.PRIMARY}
        >
          {t('settings.apiAuthTokens.createTokenButton')}
        </Button>
      </Layout>
      <Typography lineHeight={2} marginTop={0} variant="body2">
        <Trans i18nKey="settings.apiAuthTokens.tokensExplainer">
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <Button
            color={COLORS?.LINKS}
            forwardedAs="a"
            href={COMMUNITY_DEVELOPER_DOCS_URL}
            target="_blank"
            variant={BUTTON_VARIANTS.LINK}
          />
        </Trans>
        <br />
        <Trans i18nKey="settings.apiAuthTokens.useClientId" values={{ clientName: client?.fullName }}>
          <ClientId />
        </Trans>
      </Typography>
      {tokens?.length === 0 && (
        <Setting
          text={
            <Typography color={COLORS.SUBTEXT} textAlign="center" variant="body2">
              {t('settings.apiAuthTokens.noTokensAvailable')}
            </Typography>
          }
        />
      )}
      {tokens?.map((token) => {
        return (
          <Setting
            action={
              <Layout textAlign="right">
                {hasApiTokenWrite ? (
                  <Button
                    color={COLORS.ERRORS}
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation()
                      setTokenToDelete(token)
                    }}
                    variant={BUTTON_VARIANTS.LINK}
                  >
                    {t('delete')}
                  </Button>
                ) : (
                  <Layout height="18px" />
                )}
                <ApiAuthTokenLastUsed date={token.dateLastUsed} />
              </Layout>
            }
            actionAs={Layout}
            key={token.id}
            label={token.name}
            onClick={hasApiTokenWrite ? () => setTokenToEdit(token) : undefined}
            text={<ApiAuthTokenExpiration token={token} />}
          ></Setting>
        )
      })}
      {tokenToEdit && (
        <ApiAuthTokensEditModal
          onClose={() => setTokenToEdit(undefined)}
          onSuccess={refetchTokens}
          token={tokenToEdit}
          tokens={tokens || []}
        />
      )}
      {isCreateModalOpen && (
        <ApiAuthTokensEditModal
          onClose={() => setIsCreateModalOpen(false)}
          onSuccess={refetchTokens}
          tokens={tokens || []}
        />
      )}
      {tokenToDelete && (
        <Dialog
          message={<Trans i18nKey="settings.apiAuthTokens.deleteModal.body" values={{ name: tokenToDelete.name }} />}
          onCancel={() => setTokenToDelete(undefined)}
          title={t('settings.apiAuthTokens.deleteModal.title')}
        >
          <Dialog.Action onClick={() => setTokenToDelete(undefined)}>{t('cancel')}</Dialog.Action>
          <Dialog.Action
            onClick={() => {
              deleteTokenMutation(tokenToDelete.id)
              setTokenToDelete(undefined)
            }}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            {t('delete')}
          </Dialog.Action>
        </Dialog>
      )}
    </StyledContainer>
  )
}
