import { ManifestFunctionIf } from '@community_dev/workflow-manifest'

/**
 * A logic index is the 0-based outcome index (aka function reference) for a json logic if statement.
 * A path index is the 1-based human friendly path number
 *
 * We also have to pass the length of the
 *
 * @param {number} logicIndex
 * @param {ManifestFunctionIf} jsonLogicIf
 * @returns {number}
 */
export function logicIndexToPathIndex(logicIndex: number, jsonLogicIf: ManifestFunctionIf): number {
  if (logicIndex === jsonLogicIf.length - 1) {
    return (jsonLogicIf.length + 1) / 2
  }

  if (logicIndex === 1) {
    return logicIndex
  }

  return (jsonLogicIf.length - 1) / 2
}
