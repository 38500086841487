import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export function useInitialAction(value: string, clear = true): boolean {
  const history = useHistory()
  const [isInitialAction] = useState(history.location.hash === `#${value}`)

  useEffect(() => {
    if (isInitialAction && clear) {
      history.replace(history.location.pathname + history.location.search, history.location.state)
    }
  }, [])

  return isInitialAction
}
