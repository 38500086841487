import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import { ApiAuthTokens } from './components/ApiAuthTokens'
import { EmbedSettings } from './components/EmbedSettings'
import { SetLiveInstructions } from './components/SetLiveInstructions'
import { ConnectStore } from './components/Shopify'
import { WebhooksSettings } from './components/Webhooks/WebhooksSettings'
import { Zapier } from './components/Zapier'

import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import {
  Appearance,
  Billing,
  Customize,
  EditKeyword,
  EditSecurity,
  General,
  Integrations,
  MessagesLimits,
  Security,
  SignupMessages,
  Team,
} from 'containers/Settings'
import { useHasCapability, useHasEveryCapability, useHasSomeCapabilities } from 'hooks/useUserCapability'
import SettingsLayout from 'layouts/SettingsLayout'
import { SidebarLayout } from 'layouts/SidebarLayout'

// See InsightsScreen.tsx for more insight into these CSS choices
const maxContentWidth = 896
const minMargin = 32
const maxLeftMargin = 160
const ResponsiveWrapper = styled.div`
  max-width: ${maxContentWidth + 2 * minMargin}px;
  padding: 60px ${minMargin}px ${minMargin}px;
  margin: auto;
  position: relative;
  height: 100%;
`

export const PinLeftWrapper = styled.div`
  max-width: ${maxContentWidth + 2 * maxLeftMargin}px;
  position: relative;
  height: 100%;
`

const ScrollContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`

const ImportRoutes = React.lazy(() => import('containers/Settings/Import/ImportRoutes'))

export const SETTINGS_SCROLL_CONTAINER_ID = 'settings-scroll-container'

function SettingsScreen(): JSX.Element {
  const hasSetLive = useHasCapability(CAPABILITIES.FEATURE.SET_LIVE_INTEGRATION.ALL)
  const hasSelfImport = useHasCapability(CAPABILITIES.FEATURE.CSV_SELF_IMPORT.ALL)
  const hasApiAuthTokens = useHasCapability(CAPABILITIES.FEATURE.API_AUTH_TOKENS.ALL)
  const hasBilling = useHasSomeCapabilities(
    CAPABILITIES.CLIENT.BILLING.READ,
    CAPABILITIES.CLIENT.BILLING.WRITE,
    CAPABILITIES.ADMIN.BILLING.READ,
    CAPABILITIES.ADMIN.BILLING.WRITE,
  )

  const hasOutboundWebhooks = useHasEveryCapability(
    CAPABILITIES.FEATURE.OUTBOUND_WEBHOOKS.ALL,
    CAPABILITIES.CLIENT.OUTBOUND_WEBHOOKS.WRITE,
  )

  const hasOutboundWebhookAdmin = useHasEveryCapability(
    CAPABILITIES.ADMIN.OUTBOUND_WEBHOOKS.READ,
    CAPABILITIES.FEATURE.OUTBOUND_WEBHOOKS.ALL,
  )

  return (
    <SidebarLayout>
      <ScrollContainer id={SETTINGS_SCROLL_CONTAINER_ID}>
        <PinLeftWrapper>
          <ResponsiveWrapper>
            <Switch>
              <Route component={MessagesLimits} exact path={ROUTES.SETTINGS.MESSAGE_LIMITS} />
              <Route
                component={EmbedSettings}
                path={[ROUTES.SETTINGS.INTEGRATIONS.EDIT_EMBED, ROUTES.SETTINGS.INTEGRATIONS.CREATE_EMBED]}
              />
              {(hasOutboundWebhooks || hasOutboundWebhookAdmin) && (
                <Route
                  component={WebhooksSettings}
                  path={[ROUTES.SETTINGS.INTEGRATIONS.WEBHOOK_EDIT, ROUTES.SETTINGS.INTEGRATIONS.WEBHOOKS]}
                />
              )}
              <Route component={ConnectStore} exact path={ROUTES.SETTINGS.INTEGRATIONS.SHOPIFY.CONNECT_STORE} />
              {hasSetLive && (
                <Route component={SetLiveInstructions} exact path={ROUTES.SETTINGS.INTEGRATIONS.SETLIVE} />
              )}
              <Route component={Zapier} exact path={ROUTES.SETTINGS.INTEGRATIONS.ZAPIER} />
              <Route path="/">
                <SettingsLayout>
                  <Switch>
                    <Route component={General} exact path={ROUTES.SETTINGS.ROOT} />
                    <Route component={SignupMessages} exact path={ROUTES.SETTINGS.SIGNUP_MESSAGES} />
                    <Route component={Team} exact path={ROUTES.SETTINGS.TEAM} />
                    <Route component={Customize} exact path={ROUTES.SETTINGS.CUSTOMIZE.ROOT} />
                    {hasBilling && <Route component={Billing} path={ROUTES.SETTINGS.BILLING} />}
                    <Route component={Security} exact path={ROUTES.SETTINGS.SECURITY.ROOT} />
                    <Route component={Integrations} exact path={ROUTES.SETTINGS.INTEGRATIONS.ROOT} />
                    <Route component={EditSecurity} exact path={ROUTES.SETTINGS.SECURITY.EDIT} />
                    <Route component={EditKeyword} exact path={ROUTES.SETTINGS.CUSTOMIZE.KEYWORD} />
                    <Route component={EditKeyword} exact path={ROUTES.SETTINGS.CUSTOMIZE.KEYWORDS} />
                    <Route component={Appearance} exact path={ROUTES.SETTINGS.APPEARANCE} />
                    {hasApiAuthTokens && (
                      <Route component={ApiAuthTokens} exact path={ROUTES.SETTINGS.API_AUTH_TOKENS} />
                    )}
                    {hasSelfImport && <ImportRoutes />}
                    <Redirect from={`${ROUTES.SETTINGS.CUSTOMIZE.ROOT}/*`} to={ROUTES.SETTINGS.CUSTOMIZE.ROOT} />
                  </Switch>
                </SettingsLayout>
              </Route>
            </Switch>
          </ResponsiveWrapper>
        </PinLeftWrapper>
      </ScrollContainer>
    </SidebarLayout>
  )
}

export default SettingsScreen
