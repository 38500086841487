import { BUTTON_VARIANTS, Button, LoadingIndicator, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { VirtualList } from 'components/VirtualList'

export const StyledPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const StyledResults = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`

export const StyledMeta = styled.div`
  padding: 0 ${SPACING[4]} 0;
  flex: 0;
`

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${SPACING[5]} 0 ${SPACING[4]};
`

export const StyledNoResults = styled.p`
  text-align: center;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  margin: 0;
`

export const StyledAddSuffix = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: ${SPACING[4]};
`

export const StyledFilters = styled.div`
  margin-bottom: ${SPACING[2]};
`

export const StyledVirtualList = styled(VirtualList)`
  margin: 0;
  overflow: auto;
`

export const StyledHeading = styled.h3`
  margin: 0;
`

export const StyledSubHeading = styled.h4<{ $topPadding?: boolean }>`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  padding: 0 ${SPACING[4]};
  margin: ${({ $topPadding }) => ($topPadding ? SPACING[4] : 0)} 0 ${SPACING[4]};
`

export const StyledButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.INLINE_ACTION,
})`
  min-width: 72px;
  min-height: 24px;
  background-color: ${({ $color }) => $color};
  padding: 7px 12px;
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  margin: ${SPACING[2]} 0;

  &:hover {
    background-color: ${({ theme }) => theme?.COLORS?.ERRORS};
    color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
    border-color: ${({ theme }) => theme?.COLORS?.ERRORS};
  }
`

export const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 40px;
`
