import { AppleIconCircled, ListItem, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useTranslation } from 'react-i18next'

import { Community } from 'api/community'
import { CommunityDot } from 'components/CommunityDot'
import { COMMUNITY_TYPES } from 'constants/communities'
import { COMMUNITY_SORT_BY_MEMBER_COUNT } from 'constants/launch-darkly-flags'
import { useAllCommunityCount } from 'hooks/useCountByQuery/useCommunityCount'
import { userHasFlag } from 'hooks/useLaunchDarkly'
import { stringToColor } from 'utils/colors'
import { formatLargeNumber } from 'utils/number'

export type CommunitiesListItemProps = {
  community: Community
  active: boolean
  onItemClick: (...args: any[]) => void
}

export const CommunitiesListItem = (props: CommunitiesListItemProps): JSX.Element => {
  const { community, onItemClick, active } = props

  const { t } = useTranslation()
  const isSortByMemberCountEnabled = userHasFlag(COMMUNITY_SORT_BY_MEMBER_COUNT)

  const sdsAllCommunityCount = useAllCommunityCount({
    communityId: community.id,
    title: community.title,
    enabled: !isSortByMemberCountEnabled,
  })

  const totalFanCountLoading = isSortByMemberCountEnabled ? false : sdsAllCommunityCount.isLoading
  const totalFanCount = isSortByMemberCountEnabled ? community.fanCount : sdsAllCommunityCount.total

  const communityCount = totalFanCount || 0
  const activeFans = formatLargeNumber(communityCount)

  const allContactsCount = t('communities.activeMembers', {
    count: communityCount,
    total: activeFans,
  })

  const { title, color, updateInProgress } = community

  const label = updateInProgress ? t('communities.updatingMembers') : totalFanCountLoading ? '\u00A0' : allContactsCount

  let indicator
  if (community.type === COMMUNITY_TYPES.DEFAULT && title.toLocaleLowerCase() === 'favorites') {
    indicator = <CommunityDot color={color} icon="star" />
  } else {
    indicator = (
      <CommunityDot
        badge={
          community.communicationChannels?.includes(CommunicationChannel.AMB) && (
            <AppleIconCircled
              data-testid="dot-apple-icon"
              filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))"
              size={18}
              style={{ marginTop: SPACING[2] }}
            />
          )
        }
        color={color || stringToColor(title)}
      />
    )
  }

  return (
    <ListItem
      as={'button'}
      data-testid={`community-item-${community.id}`}
      label={title}
      onClick={() => onItemClick(community)}
      prefix={indicator}
      selected={active}
      subtext={label}
    />
  )
}
