import { BUTTON_VARIANTS, Button, SPACING } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { EmptyIllustration } from './EmptyIllustration'
import { StyledContainer, StyledHeading, StyledSubheading } from './styled'

const StyledButton = styled(Button)`
  margin: ${SPACING[5]} 0;
`

type EmptyCampaignsProps = {
  onCompose: () => void
}

export function EmptyCampaigns({ onCompose }: EmptyCampaignsProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <StyledContainer>
      <EmptyIllustration />
      <StyledHeading>{t('campaigns.emptyHeading')}</StyledHeading>
      <StyledSubheading>{t('campaigns.emptySubheading')}</StyledSubheading>
      <StyledButton onClick={onCompose} variant={BUTTON_VARIANTS.ACTION}>
        {t('sendNewMessage')}
      </StyledButton>
    </StyledContainer>
  )
}
