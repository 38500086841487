import useLocalStorageState from 'use-local-storage-state'

import { CAPABILITIES } from 'constants/capabilities'
import { useHasCapability } from 'hooks/useUserCapability'
import { ScheduledLocalStorageKeys } from 'screens/Scheduled/constants'

type UseHasAiIdeasEvents = {
  isAiIdeaSelected: boolean
  setAiIdeaSelected: (value: boolean) => void
  shouldShowAiIdeas: boolean
}

export const useHasAiIdeasEvents = (): UseHasAiIdeasEvents => {
  const [isAiIdeaSelected, setAiIdeaSelected] = useLocalStorageState<boolean>(ScheduledLocalStorageKeys.AI_IDEA, {
    defaultValue: true,
  })
  const hasAiGeneration = useHasCapability(CAPABILITIES.FEATURE.AI_MESSAGE_GENERATOR.ALL)
  const hasAddons = useHasCapability(CAPABILITIES.FEATURE.ADD_ONS.ALL)

  const shouldShowAiIdeas = hasAiGeneration || hasAddons

  return {
    isAiIdeaSelected: shouldShowAiIdeas && isAiIdeaSelected,
    setAiIdeaSelected,
    shouldShowAiIdeas,
  }
}
