import {
  Box,
  InfoIconFilled,
  Layout,
  SPACING,
  Typography,
  AuthCodeInput,
  Modal,
  CloseIcon,
  BUTTON_VARIANTS,
  Button,
  BORDER_RADIUS,
  Tooltip,
  FONT_SIZE,
  AuthCodeInputRef,
} from '@community_dev/pixels'
import copy from 'copy-to-clipboard'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { MessageBubble } from 'components/MessageBubble'
import { useAuth } from 'hooks/useAuth'
import { useToastMessage } from 'hooks/useToastMessage'

const StyledContainer = styled.div`
  text-align: center;
`

const StyledSecret = styled.code`
  display: inline-block;
  font-size: ${FONT_SIZE[6]};
  margin: ${SPACING[2]} auto 0 auto;
  padding: ${SPACING[3]};
  cursor: pointer;
  border-radius: ${BORDER_RADIUS[1]};
  background-color: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_1};
`

const StyledBox = styled(Box)`
  width: 100%;
  max-width: 100%;
  text-align: initial;
`

const StyledLink = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: ${({ theme }) => theme.COLORS.LINKS};
  display: inline;
`

const StyledModalBody = styled(Modal.Body)`
  padding: ${SPACING[4]} ${SPACING[8]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export function MfaSetupForm(): JSX.Element {
  const { authUrl, authSecret, sendMfa, isLoading, error } = useAuth()
  const [isAuthenticatorInfoModalOpen, setIsAuthenticatorInfoModalOpen] = useState(false)
  const [isManualAuthCodeModalOpen, setIsManualAuthCodeModalOpen] = useState(false)
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const { COLORS } = useTheme()
  const inputRef = useRef<AuthCodeInputRef>(null)
  // when there’s an error, we clear the input
  useEffect(() => {
    if (error !== undefined) {
      inputRef.current?.clear()
    }
  }, [error])
  function copySecret() {
    copy(authSecret!)
    showToastMessage({ message: 'Copied Secret to Clipboard' })
  }
  return (
    <StyledContainer>
      <Typography fontSize="40px" variant="h1">
        {t('login.mfaSetup.headline')}
      </Typography>
      <Typography fontSize="24px" marginBottom="0" variant="body1">
        {t('login.mfaSetup.instructions')}
      </Typography>
      <StyledBox marginTop={SPACING[7]} maxWidth="100%" width="100%">
        <Layout padding={SPACING[7]}>
          <Layout display="flex" gap={SPACING[4]} marginBottom={SPACING[5]}>
            <Layout>
              <MessageBubble>1st</MessageBubble>
            </Layout>
            <Layout flexGrow="1">
              <Typography lineHeight={1.6} marginTop="0" variant="body1">
                {t('login.mfaSetup.downloadAuthenticator')}{' '}
                <StyledLink href="#" onClick={() => setIsAuthenticatorInfoModalOpen(true)}>
                  Google Authenticator, Authy, {t('and')} 1Password. <InfoIconFilled size={20} />
                </StyledLink>
              </Typography>
            </Layout>
            <Modal open={isAuthenticatorInfoModalOpen} portal={true} shouldDismissOnBodyClick={true}>
              <Modal.Header>
                <Modal.Header.Center>{t('login.mfaSetup.about.headline')}</Modal.Header.Center>
                <Modal.Header.Right onClose={() => setIsAuthenticatorInfoModalOpen(false)}>
                  <CloseIcon color={COLORS.SUBTEXT} size={12} />
                </Modal.Header.Right>
              </Modal.Header>
              <StyledModalBody>
                <Typography variant="body1">{t('login.mfaSetup.about.mfaPurpose')}</Typography>
                <Typography variant="body1">{t('login.mfaSetup.about.mfaInstructions')}</Typography>
                <Layout padding={`${SPACING[4]} 0`} textAlign="center">
                  <StyledLink href="https://googleauthenticator.net" target="_blank">
                    Google Authenticator
                  </StyledLink>
                  ,{' '}
                  <StyledLink href="https://authy.com/download/" target="_blank">
                    Authy
                  </StyledLink>{' '}
                  {t('and')}{' '}
                  <StyledLink href="https://apps.apple.com/us/app/okta-verify/id490179405" target="_blank">
                    Okta
                  </StyledLink>
                </Layout>
                <Typography variant="body1">{t('login.mfaSetup.about.guidelines')}</Typography>
              </StyledModalBody>
            </Modal>
          </Layout>
          <Layout display="flex" gap={SPACING[4]} marginBottom={SPACING[5]}>
            <Layout>
              <MessageBubble>2nd</MessageBubble>
            </Layout>
            <Layout flexGrow="1">
              <Typography marginTop="0" variant="body1">
                {t('login.mfaSetup.scanCode')}
              </Typography>
            </Layout>
          </Layout>
          <Layout justifyContent="center" marginBottom={SPACING[4]} textAlign="center">
            <img alt="qr-code" src={authUrl} width={200} />
            <Typography marginTop={SPACING[3]} variant="body1">
              {t('login.mfaSetup.cantScan')}{' '}
              <Button onClick={() => setIsManualAuthCodeModalOpen(true)} variant={BUTTON_VARIANTS.LINK}>
                {t('login.mfaSetup.manualCode')}
              </Button>
            </Typography>
            <Modal minHeight={270} open={isManualAuthCodeModalOpen} shouldDismissOnBodyClick={true}>
              <Modal.Header>
                <Modal.Header.Center>{t('login.mfaSetup.manualSetupKey')}</Modal.Header.Center>
                <Modal.Header.Right onClose={() => setIsManualAuthCodeModalOpen(false)}>
                  <CloseIcon color={COLORS.SUBTEXT} size={12} />
                </Modal.Header.Right>
              </Modal.Header>
              <StyledModalBody>
                <Typography fontWeight={600} marginTop={0} textAlign="center" variant="body1">
                  {t('login.mfaSetup.manualSetupInstructions')}
                </Typography>
                <Tooltip content={t('login.mfaSetup.copyKey')}>
                  <StyledSecret onClick={copySecret}>{authSecret}</StyledSecret>
                </Tooltip>
              </StyledModalBody>
            </Modal>
          </Layout>
          <Layout display="flex" gap={SPACING[4]} marginBottom={SPACING[3]}>
            <Layout>
              <MessageBubble>3rd</MessageBubble>
            </Layout>
            <Layout flexGrow="1">
              <Typography marginTop="0" variant="body1">
                {t('login.mfaSetup.enterAuthCode')}
              </Typography>
            </Layout>
          </Layout>
          <Layout display="flex" justifyContent="center">
            <AuthCodeInput
              autoFocus={true}
              css={`
                & > div {
                  text-align: center;
                }
              `}
              disabled={isLoading}
              onSubmit={sendMfa}
              ref={inputRef}
            >
              <Typography color={COLORS.ERRORS} textAlign="center" variant="body2">
                {error}
              </Typography>
            </AuthCodeInput>
          </Layout>
        </Layout>
      </StyledBox>
      <Typography fontWeight={600} marginTop={SPACING[6]} variant="body1">
        {t('login.mfaSetup.footerExplainer')}
      </Typography>
      <Typography marginTop={SPACING[5]} variant="body1">
        {t('login.mfaSetup.helpContact')}{' '}
        <Button href="mailto:yourfriends@community.com" variant={BUTTON_VARIANTS.LINK}>
          yourfriends@community.com
        </Button>
      </Typography>
    </StyledContainer>
  )
}
