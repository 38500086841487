import { FONT_WEIGHT } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CarrierLimitsTableLoader } from './CarrierLimitsTableLoader'

import { CarrierLimits } from 'api/tcr'
import {
  StyledTable,
  StyledHeaderRow,
  StyledHeaderCell,
  StyledRow,
  StyledCell,
} from 'components/SettingsTable/SettingsTable.style'
import { useTCRContext } from 'contexts/TCRProvider'
import { formatNumeral } from 'utils/general'

const MAX_WIDTH = '410px'

const StyledText = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: ${FONT_WEIGHT[4]};
  color: ${({ theme }) => theme?.COLORS?.HEADLINES};
  max-width: 410px;
`
type CarrierLimitsTableProps = {
  staticLimits?: CarrierLimits[]
  showAsteriskText?: boolean
}

export const CarrierLimitsTable = ({
  staticLimits,
  showAsteriskText = true,
}: CarrierLimitsTableProps): JSX.Element | null => {
  const { t } = useTranslation(undefined, { keyPrefix: 'tcr.segmentsTable' })

  const { isInitialLoading, carrierLimits: queriedCarrierLimits } = useTCRContext()
  const carrierLimits = staticLimits ? staticLimits : queriedCarrierLimits

  if (isInitialLoading && !staticLimits) {
    return <CarrierLimitsTableLoader />
  }

  return (
    <>
      <StyledTable $maxWidth={MAX_WIDTH}>
        <thead>
          <StyledHeaderRow>
            <StyledHeaderCell>{t('membersCarrier')}</StyledHeaderCell>
            <StyledHeaderCell $align="right">{t('segmentNumber')}</StyledHeaderCell>
          </StyledHeaderRow>
        </thead>
        <tbody>
          {Boolean(carrierLimits?.length) &&
            carrierLimits.map((carrier) => {
              return (
                <StyledRow key={carrier.carrier}>
                  <StyledCell>{carrier.carrier}</StyledCell>
                  <StyledCell $align="right">
                    {!carrier.limit
                      ? 'Unlimited'
                      : `${
                          typeof carrier.limit.value === 'number'
                            ? formatNumeral(carrier.limit.value)
                            : carrier.limit.value
                        }/${carrier.limit.period}`}
                  </StyledCell>
                </StyledRow>
              )
            })}
        </tbody>
      </StyledTable>
      {showAsteriskText && <StyledText>{t('asteriskText')}</StyledText>}
    </>
  )
}
