import dayjs from 'utils/dayjs'

type GrowthData = {
  count: number
  timestamp: string
}

type trend = 'up' | 'down'
type GrowthDataOptions = {
  trend?: trend
  trendWeight?: number
  maxMembers?: number
  startingMembers?: number
}

const bound = (value, higherBound: number, lowerBound?: number) => {
  const lowerBoundDefaulted = lowerBound || 0
  if (value > higherBound) return higherBound
  if (value < lowerBoundDefaulted) return lowerBoundDefaulted
  return value
}

// helper function to generate random values with mins and max that trend in one direction
const toCountFn = (
  trend: trend,
  trendWeight: number,
  maxMembers: number,
  startingMembers: number,
  nDataPoints: number,
) => {
  const isTrendingUp = trend === 'up'
  const trendMultiplier = isTrendingUp ? 1 : -1
  const changePerDay = isTrendingUp
    ? Math.floor((maxMembers - startingMembers) / nDataPoints)
    : Math.floor(startingMembers / nDataPoints)
  const prevDayCountObj = {}
  return (dataPoint: number) => {
    if (prevDayCountObj[dataPoint - 1] === undefined) {
      //set initial value
      prevDayCountObj[dataPoint] = startingMembers
      return startingMembers
    }
    const prevDayCount = prevDayCountObj[dataPoint - 1]

    // weighted randomizer skewed to the trend that we pass in
    const trendMultiplierRand = Math.random() < trendWeight ? trendMultiplier : trendMultiplier * -1

    const maxNextDay = isTrendingUp
      ? startingMembers + changePerDay * dataPoint
      : startingMembers - changePerDay * dataPoint

    const maxNextDayDiff = isTrendingUp ? maxNextDay - prevDayCount : prevDayCount - maxNextDay

    const nextDayChange = Math.floor(Math.random() * maxNextDayDiff) * trendMultiplierRand

    const nextCount = prevDayCount + nextDayChange
    const nextCountBounded = bound(nextCount, maxMembers)
    prevDayCountObj[dataPoint] = nextCountBounded
    return nextCountBounded
  }
}

export const createMemberGrowthData = (nDays: number, options?: GrowthDataOptions): { data: GrowthData[] } => {
  const { trend = 'up', trendWeight = 0.75, maxMembers = 2000, startingMembers = 500 } = options || {}

  // if its 1 day we want the last 24 hours
  const dataPoints = nDays === 1 ? 24 : nDays

  // subtract by hour if its 1 day
  const filterBy = nDays === 1 ? 'hour' : 'day'

  const countFn = toCountFn(trend, trendWeight, maxMembers, startingMembers, dataPoints)

  const data = Array.from(Array(dataPoints), (e, i) => {
    return {
      count: countFn(i),
      timestamp: dayjs()
        // subtracting 1 since array is 0 indexed
        .subtract(dataPoints - 1 - i, filterBy)
        .startOf('hour')
        .toISOString(),
    }
  })
  return { data }
}
