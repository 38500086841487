import { HTTP, route } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'

export type Keyword = {
  clientId: string
  id: string
  normalizedText: string
  originalText: string
}

export type KeywordUnsaved = Omit<Keyword, 'id'>

export type KeywordValidationResponse = {
  data: KeywordUnsaved
}

export type KeywordCreateResponse = (null | { data: Keyword })[]

export type KeywordsListResponse = { data: Keyword[]; availableKeywordsCount: number; maxKeywordsAllowed: number }

export function validateKeyword(keyword: string, clientId: string): Promise<KeywordValidationResponse> {
  return request(route(ENDPOINTS.KEYWORD.CHECK_KEYWORD, { clientId, keyword: encodeURIComponent(keyword) }))
}

export function getKeywords({ clientId }: { clientId: string }): Promise<KeywordsListResponse> {
  return request(route(ENDPOINTS.KEYWORD.KEYWORDS, { clientId }))
}

export function deleteKeywords(keywordIds: string[], clientId: string): Promise<(string | null)[]> {
  return Promise.all(
    keywordIds.map((id) => {
      return request(route(ENDPOINTS.KEYWORD.KEYWORD, { clientId, id }), {
        method: HTTP.DELETE,
        rawResponse: true,
      })
        .then(() => id)
        .catch(() => null)
    }),
  )
}

export function createKeywords(keywords: string[], clientId: string): Promise<(string | null)[]> {
  return Promise.all(
    keywords.map((keyword) => {
      return request(route(ENDPOINTS.KEYWORD.KEYWORDS, { clientId }), {
        method: HTTP.POST,
        body: {
          original_text: keyword,
        },
      })
        .then(() => keyword)
        .catch(() => null)
    }),
  )
}
