import { LoginForm as LoginFormComponent } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'

import { ROUTES } from 'constants/routes'
import { useAuth } from 'hooks/useAuth'

export function LoginForm(): JSX.Element {
  const { t } = useTranslation()
  const { login, error, isLoading } = useAuth()

  function handleSubmit({ email, password }: { email: string; password: string }) {
    login(email, password)
  }

  return (
    <LoginFormComponent
      errors={error !== undefined ? [t(error)] : []}
      forgotPasswordLink={ROUTES.RESET_PASSWORD}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  )
}
