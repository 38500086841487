import { WorkflowEditorCardTypes, WORKFLOW_CARDS } from '@community_dev/flexible-canvas'
import { Action, addAction, WorkflowAction } from '@community_dev/workflow-manifest'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { WorkflowCommunitySelect } from '../WorkflowCommunitySelect'

import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { ROUTES } from 'constants/routes'
import { useToastMessage } from 'hooks/useToastMessage'
import analytics from 'utils/analytics'
import { route } from 'utils/router'

const WorkflowCardConfig = WORKFLOW_CARDS[WorkflowEditorCardTypes.ADD_TO_COMMUNITY]

type LocationState = {
  functionKey: string
  functionIndex: number
  logicIndex: number
}

export function WorkflowSidebarAddCommunity(): JSX.Element | null {
  const { t } = useTranslation()
  const history = useHistory<LocationState>()
  const { state } = history.location
  const { showToastMessage } = useToastMessage()
  const { workflowId } = useParams<{ workflowId: string }>()
  const { manifest, workflow, dispatch } = useWorkflowProvider()
  const [tagId, setTagId] = useState<string | null>(null)

  const handleSubmit = async () => {
    if (!tagId) {
      return
    }

    const addToCommunityAction: Action = {
      type: WorkflowAction.AddToCommunity,
      params: {
        tag_id: tagId,
      },
    }

    const { manifest: nextManifest } = addAction(
      manifest,
      addToCommunityAction,
      state.functionKey,
      state.functionIndex,
      state.logicIndex,
    )

    try {
      dispatch({
        type: 'update',
        state: {
          manifest: nextManifest,
        },
      })

      analytics.track(analytics.events.Workflows.AddToCommunity({ type: workflow.type }))

      history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
    } catch (e) {
      showToastMessage({
        message: (e as Error).message,
        success: false,
      })
    }
  }

  if (state === undefined || typeof state.functionIndex !== 'number') {
    history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
    return null
  }

  return (
    <WorkflowCommunitySelect
      onSubmit={handleSubmit}
      setTagId={setTagId}
      tagId={tagId}
      workflowCardConfig={{ ...WorkflowCardConfig, subtitle: t('automations.selectACommunity') }}
    />
  )
}
