import { Box, Image, Toast, Typography } from '@community_dev/pixels'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { Confirm } from '../components/Confirm'
import { Description } from '../components/Description'
import { Examples } from '../components/Examples'
import { Preview } from '../components/Preview'
import { CONFIRMATION_MESSAGE_KEY, ONBOARDING_TASKS, ONBOARDING_TASK_COMPLETE } from '../constants'
import { useCompleteClientTask, useUpdateFanMessage } from '../queries'

import { EditMessage } from './components/EditMessage'

import { ROUTES } from 'constants/routes'
import { useClient } from 'hooks/useClient'
import { useIsReplyYOn } from 'hooks/useIsReplyYOn'
import { useOnboardingMessage, useOnboardingMessages } from 'hooks/useOnboardingMessages'
import Sentry from 'integrations/Sentry'
import texts from 'texts.json'
import { generalConfig } from 'utils/config'

const CONFIRMATION_MESSAGE_ASSETS = {
  brendonBurchard: (
    <Image
      alt="Brendon Burchard personalized his confirmation message, making his audience feel special while also notifying them of his other businesses."
      src={[
        `${generalConfig.staticUrl}/confirmation-messages/confirmation-message-brendon-burchard%403x.jpeg 3x`,
        `${generalConfig.staticUrl}/confirmation-messages/confirmation-message-brendon-burchard%402x.jpeg 2x`,
        `${generalConfig.staticUrl}/confirmation-messages/confirmation-message-brendon-burchard.jpeg 1x`,
      ]}
    />
  ),
  goldenStateWarriors: (
    <Image
      alt="In the Golden State Warriors confirmation message, they make it known that the Member has completed set up and also sets an expectation that they can expect unique messages soon."
      src={[
        // eslint-disable-next-line max-len
        `${generalConfig.staticUrl}/confirmation-messages/confirmation-message-golden-state-warriors%403x.jpeg 3x`,
        // eslint-disable-next-line max-len
        `${generalConfig.staticUrl}/confirmation-messages/confirmation-message-golden-state-warriors%402x.jpeg 2x`,
        `${generalConfig.staticUrl}/confirmation-messages/confirmation-message-golden-state-warriors.jpeg 1x`,
      ]}
    />
  ),
  spiderSociety: (
    <Image
      alt="Spider Society uniquely confirmed sign up with their Members with a message personal to their brand."
      src={[
        // eslint-disable-next-line max-len
        `${generalConfig.staticUrl}/confirmation-messages/confirmation-message-spider-society%403x.jpeg 3x`,
        // eslint-disable-next-line max-len
        `${generalConfig.staticUrl}/confirmation-messages/confirmation-message-spider-society%402x.jpeg 2x`,
        `${generalConfig.staticUrl}/confirmation-messages/confirmation-message-spider-society.jpeg 1x`,
      ]}
    />
  ),
}

const REPLY_Y_CONFIRMATION_ASSETS = {
  modernDesigns: (
    <Image
      alt="Modern Designs Example"
      src={[
        `${generalConfig.staticUrl}/confirmation-messages/reply-y-image-mockup-confirmation-message-modern-designs%403x.png 3x`,
        `${generalConfig.staticUrl}/confirmation-messages/reply-y-image-mockup-confirmation-message-modern-designs%402x.png 2x`,
        `${generalConfig.staticUrl}/confirmation-messages/reply-y-image-mockup-confirmation-message-modern-designs.png 1x`,
      ]}
    />
  ),
}

enum State {
  INITIAL = 'initial',
  EDIT = 'edit',
  PREVIEW = 'preview',
  CONFIRM = 'confirm',
}

const PREVIEW_STATES = [State.PREVIEW, State.CONFIRM]

const TIPS = [
  'Let them know you saved their contact info',
  'Ask them to save your contact',
  'Let them know the legal language is required',
]

export function ConfirmationMessage(): JSX.Element {
  const history = useHistory()
  const message = useOnboardingMessage(CONFIRMATION_MESSAGE_KEY)
  const { data: client } = useClient()
  const task = client?.onboardingTasks?.tasks[ONBOARDING_TASKS.CONFIRMATION_MESSAGE]
  const [state, setState] = useState<State>(State.INITIAL)
  const [text, setText] = useState('')
  const [error, setError] = useState<string>()
  const { t } = useTranslation()
  const {
    confirmationMessage: { tcpa, maxLength, maxSegmentCount },
  } = useOnboardingMessages()
  const { mutate: updateTask } = useCompleteClientTask({
    onError(e) {
      setError(texts.errors.generic)
      Sentry.captureException(e)
    },
    onSuccess() {
      history.push(ROUTES.ONBOARDING.SUMMARY)
    },
  })
  const { mutate: submit, isLoading: isProcessing } = useUpdateFanMessage({
    onError(e) {
      setError(texts.errors.generic)
      Sentry.captureException(e)
    },
    onSuccess() {
      updateTask({ id: task!.id })
    },
  })
  const isReplyYOn = useIsReplyYOn()

  useEffect(() => {
    if (!task) return
    setState(task.status === ONBOARDING_TASK_COMPLETE ? State.PREVIEW : State.INITIAL)
  }, [task])

  useEffect(() => {
    if (!message) return
    setText(message.text)
  }, [message])

  function handleSave(value) {
    setText(value)
    setState(State.CONFIRM)
  }

  function handleConfirm() {
    setError(undefined)
    if (message?.id) {
      submit({ id: message.id, text })
    }
  }

  return (
    <>
      <Box>
        {error && (
          <Box.ToastArea>
            <Toast icon message={error} success={false} />
          </Box.ToastArea>
        )}
        <Box.Content>
          {state === State.INITIAL && (
            <Description
              actionLabel="Set confirmation message"
              alt={t('onboarding.confirmationMessage.gifAltText')}
              animation="animation-confirmation-message.gif"
              onAction={() => setState(State.EDIT)}
            >
              <Typography variant="h1">Confirmation Message</Typography>
              <p>{t('onboarding.confirmationMessage.initialBody')}</p>
            </Description>
          )}
          {state === State.EDIT && (
            <EditMessage
              charMax={maxLength}
              complete={task?.status === ONBOARDING_TASK_COMPLETE}
              defaultValue={text}
              onCancel={() => setState(State.PREVIEW)}
              onSave={handleSave}
              segmentMax={maxSegmentCount}
              tcpaMessage={tcpa}
            />
          )}
          {state === State.PREVIEW && (
            <Preview
              message={tcpa ? `${text}${tcpa}` : text}
              onEdit={() => {
                setError(undefined)
                setState(State.EDIT)
              }}
            >
              <Typography variant="h1">Your confirmation message</Typography>
              <p>This is the message people receive after joining your Community.</p>
            </Preview>
          )}
          {state === State.CONFIRM && (
            <Confirm
              message={text}
              onConfirm={handleConfirm}
              onEdit={() => {
                setError(undefined)
                setState(State.EDIT)
              }}
              processing={isProcessing}
              tcpaMessage={tcpa}
            >
              <Typography variant="h1">Your confirmation message</Typography>
              <p>This is the message people receive after joining your Community.</p>
            </Confirm>
          )}
        </Box.Content>
      </Box>
      {!PREVIEW_STATES.includes(state) && (
        <Examples examples={isReplyYOn ? REPLY_Y_CONFIRMATION_ASSETS : CONFIRMATION_MESSAGE_ASSETS} tips={TIPS} />
      )}
    </>
  )
}
