import { Block, DefinitionList } from '@community_dev/pixels'
import { FanRegistrationCoreFieldName } from '@community_dev/types/lib/api/FanRegistration'
import styled from 'styled-components'

import { Fan } from 'api/fans'
import { useFanRegistrationField } from 'hooks/useClientSettings/useFanRegistrationField'
import dayjs from 'utils/dayjs'
import { normalizeFan } from 'utils/normalize'
import { formatLocation } from 'utils/normalizers/utils'

type MemberDetailsProps = {
  fan?: Fan
  isLoading: boolean
}

const StyledBlock = styled(Block)`
  box-sizing: content-box;
`

export function MemberDetails({ fan, isLoading }: MemberDetailsProps): JSX.Element {
  const { age, gender, city, stateAbbrev, joined, countryCode, country } = normalizeFan(fan)

  const displayAge = age ? age : '--'
  const displayLocation = city
    ? formatLocation(city, countryCode || undefined, stateAbbrev, country || undefined)
    : '--'
  const displayGender = gender?.includes?.('reported') || !gender ? '--' : gender

  const { label: genderLabel } = useFanRegistrationField(FanRegistrationCoreFieldName.GENDER_NAME) || {}

  return (
    <StyledBlock flush title="Details">
      <DefinitionList>
        <DefinitionList.Item label="Age">{!isLoading && displayAge}</DefinitionList.Item>
        <DefinitionList.Item label={genderLabel || 'Gender Identity'}>
          {!isLoading && fan && displayGender}
        </DefinitionList.Item>
        <DefinitionList.Item label="City">{!isLoading && fan && displayLocation}</DefinitionList.Item>
        <DefinitionList.Item label="Join Date">
          {!isLoading && dayjs(joined).format('MMM DD, YYYY')}
        </DefinitionList.Item>
      </DefinitionList>
    </StyledBlock>
  )
}
