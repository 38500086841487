import { useInterval } from '@community_dev/hooks'
import { LoadingIndicator } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useIsMutating } from '@tanstack/react-query'
import omit from 'lodash/omit'
import queryString from 'query-string'
import React, { useEffect, useMemo } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'

import { getSupportedAppVersions } from 'api/appVersions'
import { ActionPanelContextProvider } from 'components/ActionPanel/ActionPanelContext'
import { ComposeProvider } from 'components/ComposeMessage/ComposeContext'
import { TCRModals } from 'components/TCRModals'
import { WorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { useResetFlexibleCanvas } from 'components/WorkflowEditor/hooks/useResetFlexibleCanvas'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import Compose from 'containers/Compose'
import { AiMessageGeneratorProvider } from 'contexts/AIMessageGeneratorProvider'
import { AuthStates } from 'contexts/AuthProvider'
import { CampaignResponseFilterProvider } from 'contexts/CampaignResponseFilterProvider'
import { FilterProvider } from 'contexts/FilterProvider/FilterProvider'
import { ImportPollingProvider } from 'contexts/ImportPollingProvider'
import { InboxProvider } from 'contexts/InboxProvider'
import { IncludeExcludeProvider } from 'contexts/IncludeExcludeProvider'
import { SidebarProvider } from 'contexts/SidebarProvider'
import { TCRProvider, useTCRContext } from 'contexts/TCRProvider'
import { UploadProvider } from 'contexts/UploadProvider'
import { useShouldCollectBilling } from 'hooks/billing/useShouldCollectBilling'
import { useAuth } from 'hooks/useAuth'
import { useClient, useClientContexts, useSwitchClient } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useIdentify } from 'hooks/useIdentify'
import { useIsAccountInactive } from 'hooks/useIsAccountInactive'
import { usePreloadRecentFilterCounts } from 'hooks/usePreloadRecentFilterCounts'
import { useHasCapability } from 'hooks/useUserCapability'
import { useZendeskWidget } from 'hooks/useZendeskWidget'
import { AddOnsScreen } from 'screens/AddOnsScreen'
import CampaignDetails from 'screens/Campaigns/CampaignDetails'
import { Campaigns } from 'screens/Campaigns/Campaigns'
import ClusterDetails from 'screens/Campaigns/ClusterDetails'
import { ClientSelection } from 'screens/ClientSelection'
import { CollectBillingScreen } from 'screens/CollectBillingScreen'
import CommunitiesScreen from 'screens/CommunitiesScreen'
import { ContactUsScreen } from 'screens/ContactUsScreen'
import { DetailedAddOnScreen } from 'screens/DetailedAddOnScreen'
import { DirectMessage } from 'screens/DirectMessage'
import { GetTheAppScreen } from 'screens/GetTheAppScreen'
import GrowthTools from 'screens/GrowthTools'
import HomeScreen from 'screens/HomeScreen'
import InboxScreen from 'screens/InboxScreen'
import { InsightsScreen } from 'screens/InsightsScreen'
import { InviteScreen } from 'screens/InviteScreen'
import { Logout } from 'screens/Logout'
import {
  AccountSetupSummaryScreen,
  ConfirmationMessageScreen,
  ContactPhotoScreen,
  NameScreen,
  VoicemailScreen,
  WelcomeScreen,
} from 'screens/Onboarding'
import { SalesforceConnectScreen } from 'screens/SalesforceConnectScreen'
import { SalesforceMarketingCloudOauth, SalesforceOauth } from 'screens/SalesforceOAuth'
import { Scheduled } from 'screens/Scheduled'
import { ScheduledLocalStorageKeys, ScheduledTabView } from 'screens/Scheduled/constants'
import { SearchScreen } from 'screens/SearchScreen'
import SettingsScreen from 'screens/SettingsScreen'
import { SetupIntentScreen } from 'screens/SetupIntentScreen'
import { UpgradeContactUsScreen } from 'screens/UpgradeContactUsScreen'
import { WorkflowKeywordResponseScreen } from 'screens/WorkflowKeywordResponseScreen'
import { WorkflowsNewScreen } from 'screens/WorkflowNewScreen'
import { WorkflowScreen } from 'screens/WorkflowScreen'
import { WorkflowsScreen } from 'screens/WorkflowsScreen'
import { isAppVersionSupported } from 'utils/appVersionSupport'
import { composeProviders } from 'utils/composeProviders'
import { onboardingRedirect } from 'utils/onboarding'

const TCRFlow = React.lazy(() => import('screens/TCRFlow/TCRFlow'))

const _AuthenticatedApp = (): JSX.Element => {
  useResetFlexibleCanvas()
  usePreloadRecentFilterCounts()
  useCommunities()
  useZendeskWidget()

  const { search } = useLocation()
  const history = useHistory()
  const { data: client } = useClient()
  const { isImpersonating, state: authState } = useAuth()
  const isChangingClientContext = useIsMutating({ mutationKey: [QUERY_CACHE.CLIENT] })
  const switchClient = useSwitchClient()
  const isWorkflowsEnabled = useHasCapability(CAPABILITIES.FEATURE.WORKFLOWS.ALL)
  const isAutomationsEnabled = isWorkflowsEnabled && client?.communicationChannels?.includes(CommunicationChannel.SMS)
  const isAddOnsEnabled = useHasCapability(CAPABILITIES.FEATURE.ADD_ONS.ALL)
  const isFullTextSearchEnabled = useHasCapability(CAPABILITIES.FEATURE.FULL_TEXT_SEARCH.ALL)
  const { status: tcrStatus, presentStatusInfo: tcrPresentStatusInfo } = useTCRContext()
  const { data: contexts } = useClientContexts()
  const { isAccountInactive, isLoading: isAccountInactiveLoading } = useIsAccountInactive()
  const { shouldCollectBilling, isLoading: isCollectBillingLoading } = useShouldCollectBilling()

  const shouldLoadBeforeRenderingApp =
    isChangingClientContext > 0 || isAccountInactiveLoading || isCollectBillingLoading || !contexts

  const hasCampaignCalendarFeature = useHasCapability(CAPABILITIES.FEATURE.CAMPAIGN_SCHEDULING.ALL)

  const [scheduledView] = useLocalStorageState<ScheduledTabView>(ScheduledLocalStorageKeys.VIEW_KEY, {
    defaultValue: hasCampaignCalendarFeature ? ScheduledTabView.MONTH : ScheduledTabView.LIST,
  })
  // check `for app version info every 8 hours. Reload if the current version is
  // unsupported.
  useInterval(async () => {
    const { data: versionInfo } = await getSupportedAppVersions()
    if (!isAppVersionSupported(versionInfo)) {
      // Passing true to `forceGet` is only suported in Firefox and has not been added to TypeScript lib/dom yet.
      // @ts-expect-error Expected 0 arguments, but got 1
      window.location.reload(true)
    }
  }, 1000 * 60 * 60 * 8)

  useIdentify()

  const nextPath = useMemo(() => {
    let next = queryString.parse(search).next
      ? // its needed since it returns a string of undefined
        decodeURIComponent(queryString.parse(search).next as string)
      : undefined

    if (
      next &&
      (!next.startsWith('/') || // path has to start with `/` (be relative to the root)
        next.startsWith('/logout') || // path shouldn't redirect to logout
        next.startsWith('//') || // path cannot be a protocol-relative URL which would allow a domain
        next.includes('..')) // for extra security we do not allow upward traversal
    ) {
      next = ROUTES.DASHBOARD
    }
    return next
  }, [search])

  // Deep-Linking to a client via the `client` query param
  useEffect(() => {
    // NOTE: we’re not using `clientId` as the parameter name, so we don’t
    // interfere with the impersonation flow.
    const QUERY_PARAM_NAME = 'client'
    const queryParams = queryString.parse(search)
    // the client id can be directly in the `client` query param …
    const clientQueryParam = queryParams[QUERY_PARAM_NAME]
    // … or nested within the `next` query param
    const nextQueryParam = queryString.parse(search).next
    const selectedClientId =
      clientQueryParam ||
      (typeof nextQueryParam === 'string' && queryString.parseUrl(nextQueryParam).query[QUERY_PARAM_NAME])
    if (typeof selectedClientId === 'string') {
      // trigger the switch
      switchClient(selectedClientId)
      // remove the client id from the query params
      history.replace({
        search: queryString.stringify(omit(queryParams, QUERY_PARAM_NAME)),
      })
    }
  }, [history, search, switchClient])

  const initialOnboardingScreen = useMemo(() => {
    if (client) {
      return onboardingRedirect(client.onboardingTasks || {})
    }
    return null
  }, [client])

  if (authState === AuthStates.CONTEXT_SELECTION) {
    return (
      <Switch>
        <Route component={ClientSelection} exact path={ROUTES.CLIENT_SELECTION} />
        <Route component={Logout} path={ROUTES.LOGOUT} />
        <Redirect to={`${ROUTES.CLIENT_SELECTION}${window.location.search}`} />
      </Switch>
    )
  }

  if (nextPath) {
    return <Redirect to={nextPath} />
  }

  if (shouldLoadBeforeRenderingApp) return <LoadingIndicator />
  if (isAccountInactive) {
    return (
      <Switch>
        <Route component={ContactUsScreen} path={ROUTES.ONBOARDING.CONTACT_US.ROOT} />
        <Route component={Logout} path={ROUTES.LOGOUT} />
        <Redirect to={ROUTES.ONBOARDING.CONTACT_US.ROOT} />
      </Switch>
    )
  }

  if (shouldCollectBilling) {
    return (
      <Switch>
        <Route component={CollectBillingScreen} exact path={ROUTES.CONFIRM_PAYMENT_DETAILS.ROOT} />
        <Route component={SetupIntentScreen} path={ROUTES.CONFIRM_PAYMENT_DETAILS.SETUP_INTENT} />
        <Route component={ContactUsScreen} path={ROUTES.ONBOARDING.CONTACT_US.ROOT} />
        <Route component={Logout} path={ROUTES.LOGOUT} />
        <Redirect to={ROUTES.CONFIRM_PAYMENT_DETAILS.ROOT} />
      </Switch>
    )
  }

  if (!client) {
    return <LoadingIndicator />
  }

  // if the user is impersonating, we don't want to show the onboarding screens
  if (!isImpersonating && initialOnboardingScreen) {
    return (
      <Switch>
        <Route component={GetTheAppScreen} exact path={ROUTES.ONBOARDING.GET_THE_APP} />
        <Route path={ROUTES.ONBOARDING.ROOT}>
          <Switch>
            <Route component={VoicemailScreen} exact path={ROUTES.ONBOARDING.VOICEMAIL} />
            <Route component={AccountSetupSummaryScreen} exact path={ROUTES.ONBOARDING.SUMMARY} />
            <Route component={NameScreen} exact path={ROUTES.ONBOARDING.NAME} />
            <Route component={WelcomeScreen} exact path={ROUTES.ONBOARDING.WELCOME_MESSAGE} />
            <Route component={ConfirmationMessageScreen} exact path={ROUTES.ONBOARDING.CONFIRMATION_MESSAGE} />
            <Route component={ContactPhotoScreen} exact path={ROUTES.ONBOARDING.CONTACT_PHOTO} />
            <Redirect to={initialOnboardingScreen} />
          </Switch>
        </Route>
        <Redirect to={initialOnboardingScreen} />
      </Switch>
    )
  }

  if (tcrStatus === 'not_submitted' && tcrPresentStatusInfo) {
    return <TCRFlow />
  }

  return (
    <>
      <Switch>
        <Route component={HomeScreen} exact path="/" />

        {isAutomationsEnabled && (
          <Route path={ROUTES.AUTOMATIONS.ROOT}>
            <Switch>
              <Route component={WorkflowScreen} exact path={ROUTES.AUTOMATIONS.FLOW_EDIT_ONBOARDING_MESSAGE} />
              <Route component={WorkflowsScreen} exact path={ROUTES.AUTOMATIONS.FLOWS} />
              <Route component={WorkflowsNewScreen} exact path={ROUTES.AUTOMATIONS.FLOWS_NEW} />
              <Route
                component={WorkflowKeywordResponseScreen}
                exact
                path={ROUTES.AUTOMATIONS.FLOWS_ADD_KEYWORD_RESPONSE}
              />
              <Route
                component={WorkflowKeywordResponseScreen}
                exact
                path={ROUTES.AUTOMATIONS.FLOWS_EDIT_KEYWORD_RESPONSE}
              />
              <Route component={WorkflowScreen} path={ROUTES.AUTOMATIONS.FLOW_ADD_STEP_SMS_MESSAGE} />
              <Route component={WorkflowScreen} path={ROUTES.AUTOMATIONS.FLOW_ADD_STEP_ADD_TO_COMMUNITY} />
              <Route component={WorkflowScreen} path={ROUTES.AUTOMATIONS.FLOW_EDIT_STEP} />
              <Route component={WorkflowScreen} path={ROUTES.AUTOMATIONS.FLOW_ADD_STEP} />
              <Route component={WorkflowScreen} path={ROUTES.AUTOMATIONS.FLOW_EDIT} />
              <Route component={WorkflowScreen} path={ROUTES.AUTOMATIONS.FLOW_PERFORMANCE} />
              <Route component={WorkflowScreen} path={ROUTES.AUTOMATIONS.FLOW} />
            </Switch>
          </Route>
        )}
        <Route component={InboxScreen} exact path="/messages/inbox/:fanId?" />
        <Route component={Campaigns} exact path={ROUTES.CAMPAIGNS.ROOT} />
        <Route component={ClusterDetails} path={ROUTES.CAMPAIGNS.CLUSTER} />
        <Route component={CampaignDetails} path={ROUTES.CAMPAIGNS.DETAILS} />
        <Route component={Scheduled} path={ROUTES.SCHEDULED.VIEW} />
        <Redirect from={ROUTES.SCHEDULED.ROOT} to={route(ROUTES.SCHEDULED.VIEW, { view: scheduledView })} />
        <Redirect from="/messages/sent" to={ROUTES.CAMPAIGNS.ROOT} />
        <Route component={DirectMessage} exact path={ROUTES.MESSAGES.DIRECT_MESSAGE} />
        {isFullTextSearchEnabled && <Route component={SearchScreen} exact path={ROUTES.SEARCH} />}
        <Route component={CommunitiesScreen} exact path="/communities/:id?" />
        <Route component={SalesforceConnectScreen} exact path={ROUTES.SALESFORCE.CONNECT} />
        <Route component={SalesforceOauth} exact path={ROUTES.SALESFORCE.OAUTH} />
        <Route component={SalesforceMarketingCloudOauth} exact path={ROUTES.SALESFORCE.MARKETING_CLOUD_OAUTH} />
        <Route component={GrowthTools} path={ROUTES.GROWTH_TOOLS} />
        <Route component={SettingsScreen} path={ROUTES.SETTINGS.ROOT} />
        <Route component={InsightsScreen} path="/insights" />
        {isAddOnsEnabled && (
          <Route path={ROUTES.ADD_ONS.ROOT}>
            <Switch>
              <Route component={AddOnsScreen} exact path={ROUTES.ADD_ONS.ROOT} />
              <Route component={DetailedAddOnScreen} path={ROUTES.ADD_ONS.VIEW} />
            </Switch>
          </Route>
        )}
        <Route component={GetTheAppScreen} exact path={ROUTES.ONBOARDING.GET_THE_APP} />
        <Route path={ROUTES.ONBOARDING.ROOT}>
          <Switch>
            <Route component={VoicemailScreen} exact path={ROUTES.ONBOARDING.VOICEMAIL} />
            <Route component={AccountSetupSummaryScreen} exact path={ROUTES.ONBOARDING.SUMMARY} />
            <Route component={NameScreen} exact path={ROUTES.ONBOARDING.NAME} />
            <Route component={WelcomeScreen} exact path={ROUTES.ONBOARDING.WELCOME_MESSAGE} />
            <Route component={ConfirmationMessageScreen} exact path={ROUTES.ONBOARDING.CONFIRMATION_MESSAGE} />
            <Route component={ContactPhotoScreen} exact path={ROUTES.ONBOARDING.CONTACT_PHOTO} />
            <Redirect to={ROUTES.DASHBOARD} />
          </Switch>
        </Route>
        <Route component={UpgradeContactUsScreen} exact path={ROUTES.PRICING.CONTACT_US} />
        <Route component={Logout} path={ROUTES.LOGOUT} />
        <Redirect from="/messages" to="/messages/inbox" />
        <Redirect from={ROUTES.SHARE} to={ROUTES.GROWTH_TOOLS} />
        <Redirect from={ROUTES.RESOURCES} to={ROUTES.GROWTH_TOOLS} />
        <Route component={InviteScreen} exact path={ROUTES.INVITE} />
        <Redirect to={ROUTES.DASHBOARD} />
      </Switch>
      <TCRModals />
      <Compose />
    </>
  )
}

const providers = [
  TCRProvider,
  InboxProvider,
  UploadProvider,
  FilterProvider,
  ComposeProvider,
  AiMessageGeneratorProvider,
  ImportPollingProvider,
  ActionPanelContextProvider,
  IncludeExcludeProvider,
  CampaignResponseFilterProvider,
  WorkflowProvider,
  SidebarProvider,
]

export const AuthenticatedApp = composeProviders(_AuthenticatedApp, ...providers)
