import { Action } from '@community_dev/workflow-manifest'

import { replacePlaceholdersWithNames } from 'components/ComposeEditor/util/replacePlaceholders'
import { shopifyCheckoutPlaceholders } from 'constants/placeholder'

/**
 * Replace placeholders in an action with their readable names.
 * @param action {Action}
 * @returns {Action}
 */
export function formatPlaceholders(action: Action, placeholders = shopifyCheckoutPlaceholders): Action {
  if (action.type === 'Community_SendMessage') {
    return {
      ...action,
      params: {
        ...action.params,
        text: replacePlaceholdersWithNames(action.params.text || '', placeholders),
      },
    }
  } else {
    return action
  }
}
