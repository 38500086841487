import { convertKeysToSnakeCase } from '@community_dev/requests'
import flatten from 'lodash/flatten'

import { formatAge, formatGender, formatLocation } from './utils'

import { Fan } from 'api/fans'
import { mediaConfig } from 'utils/config'

const { mediaBucketUrl } = mediaConfig

export const normalizeFan = (data: any): Fan | Record<string, never> => {
  if (!data || data.constructor !== Object) return {}
  const fan = convertKeysToSnakeCase(data)
  const {
    age,
    avatar,
    city,
    communication_channel,
    country,
    country_code,
    display_name,
    fan_id,
    fan_subscription_id,
    first_name,
    id,
    joined_at,
    joined_community_at,
    last_name,
    msgs_from,
    msgs_to,
    phone_number,
    social_data,
    state,
    state_code,
    state_flags = [],
    tags = {},
    conversation_window_end_at,
  } = fan
  const gender = formatGender(fan.gender)
  const ageText = formatAge(age)
  const fanLocation = !city ? '' : formatLocation(city, country_code, state_code, country)
  const overview = [gender, age || ageText, fanLocation].filter((x) => !!x).join(' • ')
  const hasImage = !!id && !!social_data && !!social_data.image_url
  const graphicUrl = `${mediaBucketUrl}/profile-images/${id}_large.jpg`
  const communities = flatten(Object.values(tags))

  let names: any[] = []
  if (first_name) names = names.concat(first_name.trim().split(' '))
  if (last_name) names = names.concat(last_name.trim().split(' '))

  // this will need to be removed and fixture tests updated
  names = names.map((x) => x.trim().charAt(0).toUpperCase() + x.slice(1).toLowerCase())
  let initials = names.map((x) => x[0]).join('')
  if (initials.length > 3) initials = [initials[0], initials[1], initials[initials.length - 1]].join('')

  return {
    ...data,
    ...fan,
    avatar: avatar || null,
    age: age || null,
    ageText: ageText || '',
    city: city || '',
    cityState: fanLocation?.trim(),
    communicationChannel: communication_channel || '',
    conversationWindowEndAt: conversation_window_end_at || null,
    stateFlags: state_flags,
    communities,
    country: country || '',
    countryCode: fan.country_code || '',
    fanId: id || fan_id || '',
    fanSubscriptionId: fan_subscription_id || '',
    firstName: names[0] || '',
    fullName: display_name || names.join(' ') || '',
    displayName: display_name || names.join(' ') || '',
    gender: gender || '',
    graphicUrl: (hasImage && graphicUrl) || '',
    id: id || fan_id || '',
    initials: avatar === null ? null : avatar || initials || '',
    joined: joined_at || '',
    joinedCommunityAt: joined_community_at || '',
    lastName: names[1] || '',
    messagesFrom: msgs_from || '',
    messagesTo: msgs_to || '',
    overview: overview || '',
    phone: phone_number || '',
    state: state || '',
    stateAbbrev: state_code || '',
  }
}
