import { useMemo } from 'react'
import styled from 'styled-components'

import dayjs from 'utils/dayjs'

const StyledButton = styled.button<{ $isBeforeToday: boolean }>`
  ${({ $isBeforeToday }) => ($isBeforeToday ? 'cursor: initial;' : 'cursor: pointer;')}
  width: 100%;
  text-align: right;
`

type MonthDateHeaderProps = {
  label: string
  date: Date
  drilldownView: string
  isOffRange: boolean
  onDrillDown: (date: Date) => void
}

export const MonthDateHeader = (props: MonthDateHeaderProps): JSX.Element => {
  const { label, date } = props

  const isBeforeToday = useMemo(() => {
    return dayjs(date).isBefore(dayjs(), 'day')
  }, [date])
  return (
    <StyledButton $isBeforeToday={isBeforeToday} className="rbc-button-link" role="cell" type="button">
      {label}
    </StyledButton>
  )
}
