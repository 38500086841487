import { BillingForm as BaseForm } from '@community_dev/pixels'
import { useQueryClient } from '@tanstack/react-query'
import noop from 'lodash/noop'
import React, { useRef } from 'react'

import { QUERY_CACHE } from 'constants/query-cache'
import { useUpdateRecurlyPayment } from 'hooks/billing/useUpdateRecurlyPayment'
import analytics from 'utils/analytics'

type BillingFormProps = {
  children?: React.ReactNode
  onError?: (...args: any[]) => any
  onSuccess: (...args: any[]) => any
}

export function BillingForm({ children, onError = noop, onSuccess }: BillingFormProps): JSX.Element {
  const queryClient = useQueryClient()
  const formRef = useRef<HTMLFormElement>(null)
  const { updatePayment, isLoading } = useUpdateRecurlyPayment({
    formRef,
    onError,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
      analytics.track(analytics.events.BillingProvided())
      onSuccess()
    },
  })

  return (
    <BaseForm onSubmit={updatePayment} ref={formRef}>
      {({ valid }) => (
        <>
          <BaseForm.BillingInformation exclude={['email']} processing={isLoading} />
          {children}
          <BaseForm.SubmitButton labels={{ submit: 'Save Payment' }} processing={isLoading} valid={valid} />
        </>
      )}
    </BaseForm>
  )
}
