/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum SearchTabType {
  CONTACT_NAME = 'CONTACT_NAME',
  LATEST_MESSAGE = 'LATEST_MESSAGE',
}

export enum MessagesLimits {
  THROTTLED_LIMIT = 1000,
  THROTTLED_LIMIT_TEXT = '1,000',
}
