import { Typography, Tooltip, Button, SPACING, BUTTON_VARIANTS } from '@community_dev/pixels'
import copy from 'copy-to-clipboard'
import { JSXElementConstructor } from 'react'
import styled, { useTheme } from 'styled-components'

import { TABLET_BREAK } from 'constants/theme'
import { useToastMessage } from 'hooks/useToastMessage'
import { CardCss } from 'screens/GrowthTools/GrowthTools.style'

const StyledCardContent = styled.div`
  ${CardCss}
  overflow: hidden;
`

const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${SPACING[2]};
`

const StyledBodyContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${SPACING[3]};
`

const StyledButton = styled.button`
  text-align: left;
  word-break: break-word;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;

  @media (min-width: ${TABLET_BREAK}) {
    word-break: unset;
  }
`

const StyledHeaderText = styled.h3`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`

type BestPracticesCardProps = {
  header: string
  subHeader: string
  body: string
  buttonText: string
  Icon: JSXElementConstructor<{ size: number; color: string }>
  onClick: () => void
  dataTestId?: string
  withCopyableHeader?: boolean
}

const CopyableContent = ({
  content,
  children,
}: {
  content?: string
  children: React.ReactNode
}): JSX.Element | React.ReactNode => {
  const { showToastMessage } = useToastMessage()
  if (!content) return children

  const onCopy = () => {
    copy(content)
    showToastMessage({ message: 'Copied to clipboard' })
  }

  return (
    <Tooltip content={content} interactive={Boolean(content)}>
      <StyledButton onClick={onCopy}>{children}</StyledButton>
    </Tooltip>
  )
}

export const BestPracticeCard = ({
  header,
  subHeader,
  body,
  buttonText,
  onClick,
  Icon,
  dataTestId,
  withCopyableHeader,
}: BestPracticesCardProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  return (
    <StyledCardContent data-testid={dataTestId}>
      <CopyableContent content={withCopyableHeader ? header : undefined}>
        <StyledHeaderContent>
          <Icon color={COLORS?.BUTTON_PRIMARY} size={53} />
          <StyledHeaderText>{header}</StyledHeaderText>
        </StyledHeaderContent>
      </CopyableContent>
      <StyledBodyContent>
        <Typography margin={`0 0 ${SPACING[1]} 0`} variant="h4">
          {subHeader}
        </Typography>
        <Typography margin={0} variant="body2">
          {body}
        </Typography>
      </StyledBodyContent>
      <Button onClick={onClick} variant={BUTTON_VARIANTS.LINK}>
        <Typography>{buttonText}</Typography>
      </Button>
    </StyledCardContent>
  )
}
