import { ENDPOINTS } from 'constants/endpoints'
import en from 'languages/en.json'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type SalesforceOAuthStart = {
  url: string
}

export type SalesforceInstance = {
  instanceUrl: string
  instanceType: keyof typeof en.settings.integrations.salesforce.instanceType & string
  deletedAt?: string
}

export function getSalesforceOAuthStartUrl(clientId: string, useSandbox?: boolean): Promise<SalesforceOAuthStart> {
  return request(
    route(
      ENDPOINTS.INTEGRATIONS.SALESFORCE.OAUTH,
      {
        clientId,
      },
      useSandbox ? { sandbox: true } : undefined,
    ),
  ).then(({ data }) => data)
}

export function getSalesforceMarketingCloudOAuthStartUrl(
  clientId: string,
  useSandbox?: boolean,
): Promise<SalesforceOAuthStart> {
  return request(
    route(
      ENDPOINTS.INTEGRATIONS.SALESFORCE.MARKETING_CLOUD_OAUTH,
      {
        clientId,
      },
      useSandbox ? { sandbox: true } : undefined,
    ),
  ).then(({ data }) => data)
}

export function getSalesforceInstances(clientId: string): Promise<[SalesforceInstance]> {
  return request(
    route(ENDPOINTS.INTEGRATIONS.SALESFORCE.INSTANCES, {
      clientId,
    }),
  ).then(({ data }) => data)
}
