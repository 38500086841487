import { Button, Modal, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { MGU_INLINE_INSTRUCTIONS_LINK, MGU_POPUP_INSTRUCTIONS_LINK } from '../../constants/links'

import { InlineInstallationModal } from './BehaviorForm/components/InlineInstallationModal'
import { PopUpInstallation } from './BehaviorForm/components/PopupInstallation'

import { Embed } from 'api/mgu'
import { StyledLink } from 'screens/SettingsScreen/components/styled'

const StyledContent = styled.div`
  padding: ${SPACING[5]};
`

const StyledInstructionLink = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  flex: 1;
  align-self: center;
`

type InstructionModalProps = {
  isOpen: boolean
  onClose: () => void
  embed: Embed
}

export const InstructionModal = ({ isOpen, onClose, embed }: InstructionModalProps): JSX.Element => {
  const isInline = embed.jsonConfig.displayType === 'inline'
  const instructionsLink = isInline ? MGU_INLINE_INSTRUCTIONS_LINK : MGU_POPUP_INSTRUCTIONS_LINK

  return (
    <Modal minHeight={300} onClose={onClose} open={isOpen}>
      <Modal.Header>
        {isInline ? 'Embed Instructions' : 'Pop-up Instructions'}
        <Modal.Close onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <StyledContent>
          {isInline ? <InlineInstallationModal embed={embed} /> : <PopUpInstallation embed={embed} />}
        </StyledContent>
      </Modal.Body>
      {isInline && (
        <Modal.Footer>
          <StyledInstructionLink>
            <StyledLink href={instructionsLink} rel="noopener noreferrer" target="_blank">
              Detailed Instructions
            </StyledLink>
          </StyledInstructionLink>
          <Button onClick={onClose}>Done</Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}
