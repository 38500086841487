import { ApiError } from '@community_dev/requests'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import { getInvitationSeat } from 'api/invite'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { ExistingInvitationForm, NewInvitationForm } from 'containers/InvitationForm'
import { MfaSetupForm } from 'containers/MfaSetupForm'
import { AuthStates } from 'contexts/AuthProvider'
import { useAuth } from 'hooks/useAuth'
import BoxFormLayout from 'layouts/BoxFormLayout'
import { StyledMain } from 'layouts/BoxFormLayout/BoxFormLayout'
import { humanizeInviteError } from 'utils/errors'
import { getQueryParams } from 'utils/router'

const StyledInviteScreen = styled.div`
  height: 100%;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  ${StyledMain} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export function InviteScreen(): JSX.Element | null {
  const location = useLocation()
  const [isInviteTokenValid, setIsInviteTokenValid] = useState(true)
  const [error, setError] = useState<ApiError | string>()
  const { token } = getQueryParams(location.search)
  const { state: authState } = useAuth()
  const { isLoading, data = { client: {}, seat: {}, active: false } as any } = useQuery(
    [QUERY_CACHE.INVITE, { token }],
    () => getInvitationSeat({ token }),
    {
      staleTime: STALE_TIME.FOREVER,
      refetchOnWindowFocus: false,
      onError(err: ApiError) {
        // the invite token is expired or invalid
        if (err.status === 422) {
          setIsInviteTokenValid(false)
        }
        setError(err)
      },
    },
  )

  if (isLoading) return null

  const { active, client, seat } = data
  const { firstName, lastName, clientImage } = client
  const invitationInfo = {
    leaderImage: clientImage,
    leaderName: `${!firstName ? '' : firstName} ${!lastName ? '' : lastName}`,
    firstName,
    lastName,
    buttonText: active ? 'Login' : 'Create Account',
    subtitleText: seat.email,
    email: seat.email,
  }

  return (
    <StyledInviteScreen>
      <BoxFormLayout>
        {authState === AuthStates.MFA_SETUP ? (
          <MfaSetupForm />
        ) : active ? (
          <ExistingInvitationForm
            error={error && humanizeInviteError(error)}
            seatId={seat.id}
            token={token}
            {...invitationInfo}
          />
        ) : (
          <NewInvitationForm
            isInviteTokenValid={isInviteTokenValid}
            {...invitationInfo}
            error={error && humanizeInviteError(error)}
            token={token}
          />
        )}
      </BoxFormLayout>
    </StyledInviteScreen>
  )
}
