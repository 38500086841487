import { LinkIcon } from '@community_dev/pixels'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BestPracticeCard } from './BestPracticeCard'

import { useClient } from 'hooks/useClient'
import { ShareYourCommunityModal } from 'screens/HomeScreen/screens/ProductEducation/ShareYourCommunityCard/ShareYourCommunityModal'
import { baseConfig } from 'utils/config'

const withoutProtocol = (url: string): string => url.replace(/(^\w+:|^)\/\//, '')

export const ShareYourCommunityCard = (): JSX.Element => {
  const { data: client } = useClient()
  const { urlSlug } = client || { urlSlug: '' }
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation(undefined, { keyPrefix: 'growthTools.bestPractices' })

  return (
    <>
      <BestPracticeCard
        Icon={LinkIcon}
        body={t('shareYourCommunity.body')}
        buttonText={t('buttonText')}
        dataTestId="share-your-community-card"
        header={withoutProtocol(`${baseConfig.swipeUpUrl}/${urlSlug}`)}
        onClick={() => setIsOpen(true)}
        subHeader={t('shareYourCommunity.subHeader')}
        withCopyableHeader
      />
      {isOpen ? <ShareYourCommunityModal isOpen={isOpen} onClose={() => setIsOpen(false)} /> : null}
    </>
  )
}
