import { ExclamationCircle, Layout, SPACING, TABLET_BREAK, Typography } from '@community_dev/pixels'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSessionStorage } from 'react-use'
import styled, { useTheme } from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'

import useBreakpoints from 'hooks/useBreakpoints'

const StyledAppBanner = styled.div`
  background: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_3};
  display: flex;
  padding: ${SPACING[4]};
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.BORDERS};

  &:last-child {
    border-bottom: 0;
    box-shadow: 0px 4px 6px rgba(235, 77, 61, 0.16);
  }
`

const StyledFlex = styled.div`
  @media (min-width: ${TABLET_BREAK}) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const StyledAnimatedBody = styled.div<{ $isOpen: boolean }>`
  max-height: ${(props) => (props.$isOpen ? '1000px' : '0')};
  overflow: hidden;
  opacity: ${(props) => (props.$isOpen ? '1' : '0')};
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
`

export const StyledLink = styled.a`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  margin-top: ${SPACING[1]};
  text-decoration: none;
  border: 0;
  padding: 0;
  background: none;
  margin-left: ${SPACING[4]};

  :first-child {
    margin-left: 0;
  }

  :hover {
    text-decoration: underline;
  }
`

export const StyledRouterLink = styled(Link)``

export enum BannerTypes {
  DEFAULT = 'default',
  WARNING = 'warning',
  INFO = 'info',
}

export type AppBannerProps = {
  type: BannerTypes
  title?: React.ReactNode
  body?: React.ReactNode
  cta?: React.ReactNode
  dismissible?: boolean
  dismissKey?: string
  collapsable?: boolean
}

export const AppBanner = (props: AppBannerProps): JSX.Element | null => {
  const { type, title, body, cta, dismissible, dismissKey } = props
  const { md } = useBreakpoints()
  const { COLORS } = useTheme()
  const [viewMore, setViewMore] = useSessionStorage('bannerViewMore', true)
  const { t } = useTranslation()

  // When less than 720px and the banner has a body
  const isShowMoreButtonDisplayed = !md && body

  // If the screen goes from small to big we enforce showing the full body
  useEffect(() => {
    if (md && !viewMore) {
      return setViewMore(true)
    }
  }, [md, viewMore, setViewMore])

  const [dismissedBanners, setDissmissedBanners] = useLocalStorageState<Record<string, boolean>>('dismissedBanners', {
    defaultValue: {},
  })
  const isDismissed = dismissedBanners[dismissKey || '']

  const bannerColor = useMemo(() => {
    switch (type) {
      case BannerTypes.WARNING:
        return COLORS.ERRORS
      case BannerTypes.INFO:
        return COLORS.INFO
      default:
        return COLORS.TEXT
    }
  }, [COLORS.ERRORS, COLORS.INFO, COLORS.TEXT, type])

  if (isDismissed) {
    return null
  }

  return (
    <StyledAppBanner>
      <Layout height="20px" marginRight={SPACING[3]} width="20px">
        <ExclamationCircle color={bannerColor} size={20} />
      </Layout>

      <StyledFlex>
        <Layout>
          {title ? (
            <Typography color={bannerColor} fontWeight={600} marginBottom={SPACING[2]} marginTop={0} variant="body1">
              {title}
            </Typography>
          ) : null}
          <StyledAnimatedBody $isOpen={viewMore}>
            {body ? (
              <Typography marginBottom={md ? 0 : SPACING[4]} marginTop={0} variant="body2">
                {body}
              </Typography>
            ) : null}
          </StyledAnimatedBody>
        </Layout>
        <Layout display="flex" marginLeft={md ? SPACING[4] : 0} whiteSpace="nowrap">
          {cta}
          {isShowMoreButtonDisplayed && (
            <StyledLink as="button" onClick={() => setViewMore(viewMore ? false : true)}>
              {viewMore ? t('viewLess') : t('viewMore')}
            </StyledLink>
          )}
          {dismissible && dismissKey ? (
            <StyledLink
              as="button"
              onClick={() => {
                setDissmissedBanners((prev) => {
                  return {
                    ...prev,
                    [dismissKey]: true,
                  }
                })
              }}
            >
              Dismiss
            </StyledLink>
          ) : null}
        </Layout>
      </StyledFlex>
    </StyledAppBanner>
  )
}
