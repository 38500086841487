import { BUTTON_VARIANTS, Box, Button, SummaryCards, Toast, Typography } from '@community_dev/pixels'
import ContentLoader from 'react-content-loader'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { ONBOARDING_TASKS } from '../constants'
import { StyledAction } from '../styled'

import { ROUTES } from 'constants/routes'
import { useClient } from 'hooks/useClient'

const StyledContent = styled.div`
  max-width: 332px;
  margin: 0 auto 40px;
`

const StyledDescription = styled.p`
  margin: 8px 0 32px;
`

const taskMap = new Map()
taskMap.set(ONBOARDING_TASKS.CONTACT_PHOTO, 'This is the picture your Members will save along with your number.')
taskMap.set(ONBOARDING_TASKS.WELCOME_MESSAGE, 'This message is automatically sent to your Members when they text you.')
taskMap.set(
  ONBOARDING_TASKS.CONFIRMATION_MESSAGE,
  'This is the message your Members receive after joining your Community.',
)
taskMap.set(ONBOARDING_TASKS.VOICEMAIL, 'This is the voicemail your Members will hear when they call your number.')

export function AccountSetupSummary(): JSX.Element {
  const history = useHistory()

  const { data: client, isLoading, isError } = useClient()

  const onAction = (title) => () => {
    switch (title) {
      default:
      case ONBOARDING_TASKS.CONTACT_PHOTO:
        history.push(ROUTES.ONBOARDING.CONTACT_PHOTO)
        break
      case ONBOARDING_TASKS.WELCOME_MESSAGE:
        history.push(ROUTES.ONBOARDING.WELCOME_MESSAGE)
        break
      case ONBOARDING_TASKS.CONFIRMATION_MESSAGE:
        history.push(ROUTES.ONBOARDING.CONFIRMATION_MESSAGE)
        break
      case ONBOARDING_TASKS.VOICEMAIL:
        history.push(ROUTES.ONBOARDING.VOICEMAIL)
        break
    }
  }

  const disabled = !client?.onboardingTasks?.complete
  const tasks = client?.onboardingTasks?.tasks

  return (
    <Box>
      {isError && (
        <Box.ToastArea>
          <Toast icon message="Sorry, something went wrong." success={false} />
        </Box.ToastArea>
      )}
      <Box.Content>
        <StyledContent>
          <Typography variant="h1">Hello, {client?.firstName}</Typography>
          <StyledDescription>Personalize your account. These four steps take about 5 minutes.</StyledDescription>

          {isLoading && (
            <ContentLoader aria-busy aria-live="polite" height={464} speed={2} viewBox="0 0 332 464" width={332}>
              <rect height="110" rx="6" ry="6" width="332" x="0" y="0" />
              <rect height="110" rx="6" ry="6" width="332" x="0" y="118" />
              <rect height="110" rx="6" ry="6" width="332" x="0" y="236" />
              <rect height="110" rx="6" ry="6" width="332" x="0" y="354" />
            </ContentLoader>
          )}

          {tasks && (
            <SummaryCards>
              {Array.from(taskMap.entries())
                .map(([title, value]) => {
                  const task = tasks[title]
                  if (!task) return null
                  return (
                    <SummaryCards.Card
                      complete={task.status === 'complete'}
                      heading={title}
                      key={title}
                      onAction={onAction(title)}
                    >
                      <p>{value}</p>
                    </SummaryCards.Card>
                  )
                })
                .filter(Boolean)}
            </SummaryCards>
          )}
        </StyledContent>
        <StyledAction>
          <Button
            disabled={disabled} // This should reload the page
            forwardedAs={!disabled ? 'a' : undefined}
            href={`${import.meta.env.VITE_PUBLIC_URL}${ROUTES.ONBOARDING.GET_THE_APP}`}
            variant={BUTTON_VARIANTS.ACTION}
          >
            Done
          </Button>
        </StyledAction>
      </Box.Content>
    </Box>
  )
}
