import { SPACING } from '@community_dev/pixels'
import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import React, { useEffect, useRef, useState, MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'

import { calculateScaledDimensions } from 'utils/calculateScaledDimensions'

const StyledImage = styled.div`
  width: 100%;
`
const imgStyles = css`
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    border-radius: ${SPACING[4]};
    transition: all 0.2s;
    opacity: 0;

    &.loaded {
      opacity: 1;
    }
  }
`
const StyledImageLink = styled.a`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${SPACING[4]};

  ${imgStyles}
`

const StyleImageButton = styled.button`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${SPACING[4]};
  padding: 0;
  cursor: pointer;
  border: inherit;

  ${imgStyles}
`

type ImageProps = {
  width?: string | number
  height?: string | number
  maxWidth?: number
  maxHeight?: number
  src?: string
  alt?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

function parseDimensionValue(dimension: any): number {
  if (isNumber(dimension)) return dimension
  if (isString(dimension)) return parseInt(dimension as string, 10)
  if (isNull(dimension)) return 0

  return dimension
}

const Image = ({
  width: _width,
  height: _height,
  maxWidth = 500,
  maxHeight = 500,
  src = '',
  alt = '',
  onClick,
}: ImageProps): JSX.Element => {
  const [width, setWidth] = useState(_width)
  const [height, setHeight] = useState(_height)
  const [loaded, setLoaded] = useState(false)

  const imgRef = useRef<HTMLImageElement>(null)

  const getMediaPreviewLink = (src: string): string => src.replace('/uploads/', '/i/').replace('/images/', '/')

  const onLoad = (): void => setLoaded(true)

  useEffect(() => {
    if (loaded && imgRef.current) {
      setWidth(imgRef.current.naturalWidth)
      setHeight(imgRef.current.naturalHeight)
    }
  }, [loaded, imgRef.current])

  const parsedHeight = parseDimensionValue(height)
  const parsedWidth = parseDimensionValue(width)

  const { width: finalWidth, height: finalHeight } = calculateScaledDimensions(
    {
      width: parsedWidth,
      height: parsedHeight,
    },
    {
      width: maxWidth > parsedWidth ? parsedWidth : maxWidth,
      height: maxHeight > parsedHeight ? parsedHeight : maxHeight,
    },
  )

  return (
    <StyledImage
      style={{
        width: finalWidth,
        height: finalHeight,
        overflow: 'hidden',
      }}
    >
      {onClick ? (
        <StyleImageButton onClick={onClick}>
          <img alt={alt} className={loaded ? 'loaded' : ''} onLoad={onLoad} ref={imgRef} src={src} />
        </StyleImageButton>
      ) : (
        <StyledImageLink href={getMediaPreviewLink(src)} rel="noopener noreferrer" target="_blank">
          <img alt={alt} className={loaded ? 'loaded' : ''} onLoad={onLoad} ref={imgRef} src={src} />
        </StyledImageLink>
      )}
    </StyledImage>
  )
}

export default Image
