import { BUTTON_VARIANTS, Button, SPACING, Layout, Typography, CommunityFlagIcon } from '@community_dev/pixels'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { ROUTES } from 'constants/routes'
import SimpleLayout from 'layouts/SimpleLayout'

const CONTACT_US_EMAIL = 'mailto:yourfriends@community.com?subject=Upgrade Account'

const StyleHomeButton = styled(Button).attrs({
  as: Link,
  variant: BUTTON_VARIANTS.LINK,
  to: ROUTES.DASHBOARD,
})`
  margin-top: ${SPACING[6]};
`

export const UpgradeContactUsScreen = (): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  return (
    <SimpleLayout>
      <Layout alignSelf="stretch" background={COLORS?.APP_BACKGROUND_LEVEL_3} flex={1} justifyContent="center">
        <Layout
          alignItems="center"
          alignSelf="center"
          display="flex"
          flexDirection="column"
          marginTop={SPACING[10]}
          padding={`0 ${SPACING[5]}`}
          textAlign="center"
        >
          <CommunityFlagIcon size={100} />
          <Typography marginBottom="0" marginTop={SPACING[5]} variant="h1">
            {t('contactUs.upgradeAccount')}
          </Typography>
          <Typography marginBottom={SPACING[7]} marginTop={SPACING[4]} variant="body1">
            {t('contactUs.contactSupportForUpgrade')}
          </Typography>
          <Button forwardedAs="a" href={CONTACT_US_EMAIL} variant={BUTTON_VARIANTS.ACTION}>
            {t('contactUs.contactUsButton')}
          </Button>
          <StyleHomeButton>{t('contactUs.home')}</StyleHomeButton>
        </Layout>
      </Layout>
    </SimpleLayout>
  )
}
