import { HTTP } from '@community_dev/requests'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type ApiAuthToken = CamelCasedPropertiesDeep<{
  id: string
  name: string
  date_last_used: string | null
  date_expiration_long: string
  date_expiration_short: string
}>

export type CreateApiAuthTokenRequest = {
  seatId: string
  clientId: string
  name: string
}

export type DeleteApiAuthTokenResponse = CamelCasedPropertiesDeep<{
  status: 'deleted'
  id: string
}>

export function putApiAuthToken({
  clientId,
  id,
  name,
  seatId,
}: {
  clientId: string
  id: string
  name: string
  seatId: string
}): Promise<ApiAuthToken> {
  return request(route(ENDPOINTS.API_AUTH_TOKENS.TOKEN, { clientId, id }), {
    method: HTTP.PUT,
    body: {
      seatId,
      name,
    },
  }).then(({ data }) => data)
}

export function postApiAuthToken({
  clientId,
  name,
  seatId,
}: CreateApiAuthTokenRequest): Promise<ApiAuthToken & { token: string }> {
  return request(route(ENDPOINTS.API_AUTH_TOKENS.TOKENS, { clientId }), {
    method: HTTP.POST,
    body: {
      seatId,
      name,
    },
  }).then(({ data }) => data)
}

export function deleteApiAuthToken(id: string, clientId: string, seatId: string): Promise<DeleteApiAuthTokenResponse> {
  return request(route(ENDPOINTS.API_AUTH_TOKENS.TOKEN, { id, clientId }), { method: HTTP.DELETE, body: { seatId } })
}

export function getApiAuthTokens({ clientId }: { clientId: string }): Promise<ApiAuthToken[]> {
  return request(route(ENDPOINTS.API_AUTH_TOKENS.TOKENS, { clientId })).then(({ data }) => data)
}
