import { LoadingIndicator } from '@community_dev/pixels'
import debounce from 'lodash/debounce'
import React, { ReactNode, useEffect, useImperativeHandle, useRef } from 'react'
import { VirtualItem, useVirtual } from 'react-virtual'
import styled from 'styled-components'

export const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 30px;
`

const StyledContainer = styled.div`
  height: 100%;
  flex-grow: 1;

  ul {
    padding: 0;
    margin: 0;
  }
`

const StyledParent = styled.div`
  height: 100%;
  overflow: auto;
`

type VirtualListProps = {
  className?: string
  children: (props: { virtualRow: VirtualItem }) => ReactNode
  dynamicSize?: boolean
  hasMore?: boolean
  rows: any[]
  testId?: string
  loadMore?: () => void
}

export type VirtualListHandle = {
  measure: () => void
}

function Loader({ loadMore }: { loadMore: () => void }): JSX.Element {
  useEffect(() => {
    loadMore()
  }, [loadMore])

  return <StyledLoadingIndicator />
}

export const VirtualList = React.forwardRef<VirtualListHandle, VirtualListProps>(
  ({ children, className, rows, testId, dynamicSize = false, hasMore = false, loadMore }, ref): JSX.Element | null => {
    const parentRef = useRef<HTMLDivElement>(null)
    const rowVirtualizer = useVirtual({
      size: hasMore ? rows.length + 1 : rows.length,
      parentRef,
    })
    const { measure } = rowVirtualizer

    useImperativeHandle(ref, () => ({
      measure: () => {
        measure()
      },
    }))

    useEffect(() => {
      debounce(() => measure(), 250)
    }, [rows, measure])

    useEffect(() => {
      const observeResize = debounce(() => measure(), 250)

      window.addEventListener('resize', observeResize)

      return () => {
        window.addEventListener('resize', observeResize)
      }
    }, [])

    if (!rows.length) return null

    return (
      <StyledContainer className={className}>
        <StyledParent ref={parentRef}>
          <ul
            data-testid={testId}
            style={{
              width: '100%',
              height: `${rowVirtualizer.totalSize}px`,
              position: 'relative',
            }}
          >
            {rowVirtualizer.virtualItems.map((virtualRow) => (
              <li
                key={virtualRow.index}
                ref={(el) => virtualRow?.measureRef?.(el)}
                style={{
                  height: dynamicSize ? `${virtualRow.size}px` : undefined,
                  width: '100%',
                  top: 0,
                  left: 0,
                  position: 'absolute',
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                {rows.length === virtualRow.index && loadMore ? (
                  <Loader loadMore={loadMore} />
                ) : (
                  children({
                    virtualRow,
                  })
                )}
              </li>
            ))}
          </ul>
        </StyledParent>
      </StyledContainer>
    )
  },
)
