export const QUERY_CACHE = Object.freeze({
  AI_INTERFACE: {
    SUGGEST_DMS: 'AI_INTERFACE_SUGGEST_DMS',
  },
  API_MEDIA: 'API_MEDIA',
  AUDIENCE: {
    INDEX_BY_QUERY: 'AUDIENCE_INDEX_BY_QUERY',
  },
  BILLING: {
    CLIENT: 'SUBSCRIPTION_CLIENT',
    CREDIT_BUNDLES: 'BILLING_CREDIT_BUNDLES',
    SEGMENT_USAGE_CURRENT_MONTH: 'SEGMENT_USAGE_CURRENT_MONTH',
    SETUP_INTENT: 'SETUP_INTENT',
    SUBSCRIPTION: 'SUBSCRIPTION',
  },
  CAMPAIGN: {
    DETAILS: 'CAMPAIGN_DETAILS',
    LIST: 'CAMPAIGN_LIST',
    RANGE: 'CAMPAIGNS_RANGE',
    RECIPIENTS: 'CAMPAIGN_RECIPIENTS',
    RESPONSES: 'CAMPAIGN_RESPONSES',
  },
  CAMPAIGN_METRIC: 'CAMPAIGN_METRIC',
  CAMPAIGN_METRIC_TIME: 'CAMPAIGN_METRIC_TIME',
  CAMPAIGN_SUMMARY: 'CAMPAIGN_SUMMARY',
  CAPABILITIES: 'CAPABILITIES',
  CLIENT: 'CLIENT',
  CLIENT_DASHBOARD_CHATS: 'CLIENT_DASHBOARD_CHATS',
  CLUSTER: {
    DETAILS: 'CLUSTER_DETAILS',
    LIST: 'CLUSTER_LIST',
  },
  CONTEXTS: 'CONTEXTS',
  CUSTOM_DATA_FIELDS: 'CUSTOM_DATA_FIELDS',
  CUSTOM_DATA_FOR_SUBSCRIBER: 'CUSTOM_DATA_FOR_SUBSCRIBER',
  FAN: 'FAN',
  FANS: {
    INDEX_BY_QUERY: 'INDEX_BY_QUERY',
  },
  GET_MESSAGE_LINKS: 'GET_MESSAGE_LINKS',
  GIPHY: {
    QUERY: 'QUERY',
  },
  GOOGLE: {
    COORDINATES: 'COORDINATES',
  },
  IMPORT: {
    DETAILS: 'IMPORT_DETAILS',
    HISTORY: 'IMPORT_HISTORY',
    IMPORT: 'IMPORT',
  },
  INSIGHTS: {
    AGE_AND_GENDER: 'INSIGHTS_AGE_AND_GENDER',
    BASIC: 'INSIGHTS_BASIC',
    BEST_HOUR_OF_WEEK: 'BEST_HOUR_OF_WEEK',
    COMMUNITY_AGE_BY_GENDER: 'COMMUNITY_AGE_BY_GENDER',
    COMMUNITY_STATES_AND_GENDER: 'COMMUNITY_STATES_AND_GENDER',
    COUNTRY: 'INSIGHTS_COUNTRY',
    GROWTH_OVER_TIME: 'GROWTH_OVER_TIME',
    MSAS: 'INSIGHTS_MSAS',
    STATES: 'INSIGHTS_STATES',
    TOTAL_NEW_MEMBERS: 'TOTAL_NEW_MEMBERS',
  },
  INVITE: 'INVITE',
  INVOICES: 'INVOICES',
  KEYWORDS: 'KEYWORDS',
  MEDIA: {
    GET: 'MEDIA_GET',
  },
  MESSAGE_HISTORY: {
    DMS: 'DMS',
  },
  MESSAGE_LIMITS: 'MESSAGE_LIMITS',
  MGU: {
    COUNT: 'MGU_COUNT',
    EMBEDS: 'MGU_EMBEDS',
  },
  MOST_ACTIVE_MEMBERS: {
    FILTERS: 'MOST_ACTIVE_MEMBERS_FILTER',
  },
  ONBOARDING_TASKS: 'ONBOARDING_TASKS',
  PLACEHOLDERS: 'PLACEHOLDERS',
  PREFERENCES: 'PREFERENCES',
  RECENT_FILTERS: {
    BIRTHDAY: 'BIRTHDAY',
    CAMPAIGNS: 'CAMPAIGNS',
    FILTER_COUNTS: 'FILTER_COUNTS',
    RECIPIENT_COUNT: 'RECIPIENT_COUNT',
    SENT: 'SENT',
  },
  SALESFORCE: {
    INSTANCES: 'SALESFORCE_INSTANCES',
  },
  SCHEDULED: {
    LIST: 'LIST',
    RANGE: 'RANGE',
    RECURRING: 'RECURRING',
  },
  SEARCH_HISTOGRAM: 'SEARCH_HISTOGRAM',
  SEARCH_RESULTS: 'SEARCH_RESULTS',
  SEAT: 'SEAT',
  SETTINGS: {
    API_AUTH_TOKENS: 'API_AUTH_TOKENS',
    CLIENT_SETTINGS: 'CLIENT_SETTINGS',
    FAN_ONBOARDING_MESSAGES: 'FAN_ONBOARDING_MESSAGES',
    KEYWORD_MESSAGES: 'KEYWORD_MESSAGES',
    RESTRICTED_KEYWORDS: 'RESTRICTED_KEYWORDS',
    TEAM_INVITES: 'TEAM_INVITES',
    TEAM_ROLES: 'TEAM_ROLES',
    TEAM_SEATS: 'TEAM_SEATS',
    WELCOME_MESSAGE: 'WELCOME_MESSAGE',
    WHATSAPP_INTEGRATION_RETRY: 'WHATSAPP_INTEGRATION_RETRY',
    WHATSAPP_INTEGRATION_STATUS: 'WHATSAPP_INTEGRATION_STATUS',
    WHATSAPP_INTEGRATION_SUBMIT: 'WHATSAPP_INTEGRATION_SUBMIT',
  },
  SHOPIFY: {
    COUNT: 'COUNT',
    CUSTOMER_DETAILS: 'CUSTOMER_DETAILS',
    INSIGHTS: 'SHOPIFY_INSIGHTS',
    STORE_DETAILS: 'STORE_DETAILS',
  },
  SUBSCRIPTION_DATA: {
    COUNT: 'SUBSCRIPTON_DATA_COUNT',
    MOST_ACTIVE_MEMBERS_AVAILABILITY: 'MOST_ACTIVE_MEMBERS_AVAILABILITY',
  },
  SUGGESTION_CARDS: {
    BIRTHDAY: 'SUGGESTION_CARDS_BIRTHDAY',
  },
  TAG: {
    KEYWORDS: 'KEYWORDS',
    TAG: 'TAG',
  },
  TAGS: 'TAGS',
  TCPA_LANGUAGE: 'TCPA_LANGUAGE',
  TCR: {
    CARRIER_USAGE: 'TCR_CARRIER_USAGE',
    REGISTRATION: 'TCR_REGISTRATION',
  },
  TIMEZONES: 'TIMEZONES',
  TOKEN: 'TOKEN',
  URL_METADATA: 'URL_METADATA',
  WEBFLOW: {
    FAQS: 'WEBFLOW_FAQS',
    PLANS: 'WEBFLOW_PLANS',
    POSTS: 'WEBFLOW_POSTS',
  },
  WEBHOOKS: {
    WEBHOOK: 'WEBHOOK',
    WEBHOOKS: 'WEBHOOKS',
    WEBHOOKS_STATUS: 'WEBHOOKS_STATUS',
    WEBHOOK_STATISTICS: 'WEBHOOK_STATISTICS',
  },
  WORKFLOW_ANALYTICS: 'WORKFLOW_ANALYTICS',
  WORKFLOW_CONFIG: {
    MANIFESTS: 'MANIFESTS',
    WORKFLOW: 'WORKFLOW',
    WORKFLOWS: 'WORKFLOWS',
  },
} as const)

export const STALE_TIME = Object.freeze({
  FIVE_MINUTES: 5 * 60 * 1000,
  FIVE_SECONDS: 5000,
  FOREVER: Infinity,
  ONE_HOUR: 60 * 60 * 1000,
  ONE_MINUTE: 60 * 1000,
  TEN_MINUTES: 10 * 60 * 1000,
} as const)

export const REFETCH_INTERVAL_TIME = Object.freeze({
  FIVE_MINUTES: 5 * 60 * 1000,
  FIVE_SECONDS: 5 * 1000,
  THIRTY_SECONDS: 30 * 1000,
} as const)
