import { useState } from 'react'
import ContentLoader from 'react-content-loader'
import Measure from 'react-measure'
import { useTheme } from 'styled-components'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  position: relative;
  height: 200px;
  overflow: hidden;

  > * {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

const LABEL_WIDTH = 30

export function LineChartLoadingIndicator(): JSX.Element {
  const { COLORS } = useTheme() || {}
  const [width, setWidth] = useState(0)
  const columns = 7

  return (
    <Measure
      bounds
      onResize={({ bounds }): void => {
        if (bounds) {
          setWidth(bounds.width / columns)
        }
      }}
    >
      {({ measureRef }): JSX.Element => (
        <StyledWrapper ref={measureRef}>
          <svg fill="none" height="144" viewBox="0 0 896 144" width="876" xmlns="http://www.w3.org/2000/svg">
            <rect fill="white" height="144" width="896" />
          </svg>
          <ContentLoader
            backgroundColor={COLORS?.APP_BACKGROUND_LEVEL_2}
            foregroundColor={COLORS?.APP_BACKGROUND_LEVEL_1}
            height={144}
            speed={2}
            viewBox="0 0 896 144"
            width={896}
          >
            <rect fill={COLORS?.DIVIDERS} height="1" width="896" x="0" y="124" />
            {[...Array(columns)].map((_, index) => (
              <rect
                fill={COLORS?.APP_BACKGROUND_LEVEL_1}
                height="16"
                key={index}
                rx="4"
                width={LABEL_WIDTH}
                x={index * width + width / 2 - LABEL_WIDTH / 2}
                y="130"
              />
            ))}
          </ContentLoader>
        </StyledWrapper>
      )}
    </Measure>
  )
}
