import { Api } from '@community_dev/types'
import { ClusterRepliedState } from '@community_dev/types/lib/api/v2/Clusters'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import intersectionWith from 'lodash/fp/intersectionWith'
import { useCallback } from 'react'
import { useParams } from 'react-router'

import { FILTERS } from '../constants'

import { Cluster, ClusterResponse, getMatchingClusters } from 'api/cluster'
import { QUERY_CACHE } from 'constants/query-cache'
import { useCampaignResponseFilterState } from 'contexts/CampaignResponseFilterProvider'
import { useIncludeExcludeContext } from 'contexts/IncludeExcludeProvider'
import { Selection } from 'contexts/IncludeExcludeProvider/IncludeExcludeState'
import { useClientId } from 'hooks/useClient'

function hasUnrepliedMessages(cluster) {
  return cluster.replied_info === ClusterRepliedState.UNREPLIED
}

function hasPartiallyRepliedMessages(cluster) {
  return cluster.replied_info === ClusterRepliedState.PARTIALLY_REPLIED
}

function hasOnlyRepliedMessages(cluster) {
  return cluster.replied_info === ClusterRepliedState.REPLIED
}

export function useRepliedClusters(): {
  [ClusterRepliedState.UNREPLIED]: boolean
  [ClusterRepliedState.PARTIALLY_REPLIED]: boolean
  [ClusterRepliedState.REPLIED]: boolean
} {
  const {
    isRootSelected,
    includeExcludeState: { includes, excludes, subscriptionIdMap },
  } = useIncludeExcludeContext()
  const isSelectAll = isRootSelected()
  const params = useParams<{ campaignId?: string; id?: string }>()
  const campaignId = params.campaignId || (params.id as string)
  const clientId = useClientId()
  const { tagFilters, allTags: sentScreenAllTags, notTags: sentScreenNotTags }: any = useCampaignResponseFilterState()

  const query = useQuery([QUERY_CACHE.CLUSTER.LIST, { clientId, campaignId, tagFilters }], () =>
    getMatchingClusters({
      clientId,
      campaignId,
      tagsAll: [...sentScreenAllTags].join(','),
      tagsNot: [...sentScreenNotTags].join(','),
    }),
  )

  const queryClient = useQueryClient()
  const clusterCache = query?.data?.data || []
  const includedIds = [...Array.from(includes.keys())]
  const clusters = intersectionWith((cluster, id) => cluster.cluster_id === id, clusterCache, includedIds)

  // take a cluster and try to determine if all replies have been excluded by
  // retrieving the replies from the query cache.
  const excludedAllReplies = useCallback(
    (cluster: Cluster) => {
      const clusterRepliedToKey = [
        QUERY_CACHE.CLUSTER.DETAILS,
        {
          clientId,
          campaignId,
          clusterId: cluster.cluster_id,
          filtersSelected: {
            [FILTERS.UNREPLIED]: false,
            [FILTERS.REPLIED]: true,
          },
        },
      ]

      const replies =
        queryClient.getQueryData<{ pageParams: unknown[]; pages: Record<string, Api.V2.Pagination<ClusterResponse>> }>(
          clusterRepliedToKey,
        )

      // if there are no replies, we haven't added it to the cache yet, meaning
      // the user has definitely not gone and deselected all replies
      if (!replies) {
        return false
      }

      const flattenedReplyPages = Object.keys(replies?.pages || {})?.reduce(
        (acc: ClusterResponse[], page) => [...acc, ...(replies?.pages[page].data || [])],
        [],
      )
      const hasAllPages = Object.keys(replies?.pages || {})?.some(
        (page) => replies?.pages[page].pagination_data.has_next_page === false,
      )
      const allRepliesExcluded = flattenedReplyPages.every((reply) => excludes.get(reply.fan_subscription_id))

      // if we have not retrieved all pages, there is no way the user could have
      // manually excluded all replies
      if (!hasAllPages) {
        return false
      }

      return allRepliesExcluded
    },
    [queryClient, excludes, campaignId, clientId],
  )

  const messageTypes = [...Array.from(includes.keys())]
    .map((id) => subscriptionIdMap.get(id))
    .reduce(
      (accum, item) => {
        if (accum.replied && accum.unreplied) return accum
        const type = item?.repliedTo ? 'replied' : 'unreplied'
        accum[type] = true

        return accum
      },
      { replied: false, unreplied: false },
    )

  // NOTE: If Select All is selected, we check the cache for a mixed selection
  if (isSelectAll === Selection.SELECTED) {
    return {
      unreplied: clusterCache.some(hasUnrepliedMessages),
      partially_replied:
        Boolean(
          clusterCache.filter(hasPartiallyRepliedMessages).filter((cluster) => !excludedAllReplies(cluster)).length,
        ) || Boolean(messageTypes.replied && messageTypes.unreplied),
      replied: clusterCache.some(hasOnlyRepliedMessages),
    }
  }

  return {
    unreplied: clusters.some(hasUnrepliedMessages),
    partially_replied:
      Boolean(clusters.filter(hasPartiallyRepliedMessages).filter((cluster) => !excludedAllReplies(cluster)).length) ||
      Boolean(messageTypes.replied && messageTypes.unreplied),
    replied: clusters.some(hasOnlyRepliedMessages),
  }
}
