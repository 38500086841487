import { i18n } from 'i18n'

export const renderBodyContent = ({
  body,
  incoming,
  media,
}: {
  body?: string | null
  incoming?: boolean
  media?: any
}): string => {
  const clientMediaRegex = /https:\/\/[A-Za-z0-9_-]+\.[A-Za-z0-9_-]+\/(g|i|v)\/(\w{10})(?:$|\s+)/

  if (!incoming) {
    if (!body && media) {
      return `${i18n.t('attachments.attachment')} 1: ${
        media.video ? i18n.t('attachments.video') : i18n.t('attachments.image')
      }`
    }
    if (!body) return ''
    const matched = body.match(clientMediaRegex)
    if (!matched) return body
    if (matched[0].trim() !== body.trim()) {
      return body.replace(matched[0], '').trim()
    }
    return `${i18n.t('attachments.attachment')}: 1 ${
      matched[1] === 'v' ? i18n.t('attachments.video') : i18n.t('attachments.image')
    }`
  }
  if (body) return body
  if (!media || !media.id) return ''
  return `${i18n.t('attachments.attachment')} 1: ${
    media.video ? i18n.t('attachments.video') : i18n.t('attachments.image')
  }`
}
