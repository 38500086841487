import React from 'react'
import styled from 'styled-components'

const StyledFlip = styled.span`
  display: inline-block;
  vertical-align: top;
  transform: rotate(180deg);
`

type FlipProps = {
  children: React.ReactNode
}

export const Flip = ({ children }: FlipProps): JSX.Element => {
  return <StyledFlip>{children}</StyledFlip>
}
