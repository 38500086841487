import { Exclamation, Haha, Heart, QuestionMark, ThumbsDown, ThumbsUp } from '@community_dev/pixels'

const tapbackToIconMap = {
  liked: ThumbsUp,
  loved: Heart,
  disliked: ThumbsDown,
  questioned: QuestionMark,
  laughed: Haha,
  emphasized: Exclamation,
}

export default tapbackToIconMap
