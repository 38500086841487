import React from 'react'

type SetLiveIconProps = {
  size?: number
}

export const SetLiveIcon = ({ size = 24 }: SetLiveIconProps): JSX.Element => {
  return (
    <svg fill="none" height={size} viewBox="0 0 90 90" width={size} xmlns="http://www.w3.org/2000/svg">
      <rect fill="black" height="90" rx="10.5882" width="90" />
      <path
        d="M44.9629 39.6729C44.7824 37.8531 44.0078 36.4393 42.6392 35.4316C41.2705 34.424 39.4131 33.9201 37.0668 33.9201C35.4726 33.9201 34.1265 34.1457 33.0285 34.5969C31.9306 35.0331 31.0884 35.6422 30.5018 36.4243C29.9303 37.2064 29.6445 38.0937 29.6445 39.0864C29.6144 39.9136 29.7874 40.6355 30.1634 41.2522C30.5544 41.8688 31.0884 42.4027 31.7652 42.8539C32.442 43.2901 33.2241 43.6736 34.1114 44.0045C34.9988 44.3203 35.9463 44.5911 36.954 44.8167L41.1051 45.8093C43.1204 46.2605 44.9704 46.8621 46.6549 47.6141C48.3394 48.3661 49.7983 49.2911 51.0315 50.389C52.2648 51.487 53.2199 52.7804 53.8967 54.2694C54.5885 55.7583 54.942 57.4654 54.957 59.3905C54.942 62.2181 54.22 64.6696 52.7912 66.7451C51.3775 68.8056 49.332 70.4074 46.6549 71.5505C43.9928 72.6785 40.7817 73.2425 37.0217 73.2425C33.2917 73.2425 30.0431 72.6709 27.2757 71.5279C24.5234 70.3849 22.3726 68.6928 20.8235 66.4519C19.2894 64.1958 18.4848 61.4059 18.4096 58.082H27.8623C27.9675 59.6312 28.4112 60.9246 29.1933 61.9624C29.9904 62.9851 31.0508 63.7597 32.3743 64.2861C33.7129 64.7975 35.2244 65.0531 36.9089 65.0531C38.5633 65.0531 39.9996 64.8125 41.2179 64.3312C42.4512 63.8499 43.4062 63.1806 44.083 62.3234C44.7598 61.4661 45.0982 60.4809 45.0982 59.368C45.0982 58.3302 44.7899 57.4579 44.1733 56.751C43.5717 56.0441 42.6843 55.4425 41.5112 54.9462C40.3531 54.4499 38.9318 53.9987 37.2473 53.5926L32.2164 52.3292C28.321 51.3817 25.2453 49.9002 22.9893 47.8848C20.7332 45.8695 19.6128 43.1547 19.6278 39.7406C19.6128 36.9432 20.3572 34.4992 21.8613 32.4086C23.3803 30.318 25.4634 28.6862 28.1104 27.513C30.7575 26.3399 33.7655 25.7533 37.1345 25.7533C40.5636 25.7533 43.5566 26.3399 46.1134 27.513C48.6853 28.6862 50.6856 30.318 52.1144 32.4086C53.5432 34.4992 54.2802 36.9206 54.3253 39.6729H44.9629Z"
        fill="white"
      />
      <circle cx="60.8826" cy="18.5294" fill="#D53844" r="7.94118" />
    </svg>
  )
}
