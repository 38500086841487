import { FONT_SIZE, SPACING } from '@community_dev/pixels'
import React, { useState } from 'react'
import styled from 'styled-components'

import { UserTimezone } from '../ComposeMessage/components/UserTimezone'

import { DATE_TIME_INPUT_FORMAT } from 'constants/date'
import dayjs from 'utils/dayjs'

const StyledLabel = styled.span`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: ${SPACING[2]};
  border-radius: 4px;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
`

const StyledScheduledField = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  gap: 5px;
  font-size: ${FONT_SIZE[3]};

  .frequency-control {
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.COLORS.BORDERS};
    background-color: ${({ theme }) => theme.COLORS.INPUT_BACKGROUND};
    height: 34px;
    min-height: 34px;
    width: 164px;
    min-width: 164px;
  }
  .frequency-select-indicator {
    padding: 0 6px 0 0;
  }
  .frequency-select-value {
    padding-right: 0;
  }
  .frequency-select-menu {
    width: max-content;
    min-width: 100%;
  }
`

const StyledInput = styled.input`
  cursor: pointer;
  font-weight: 500;
  padding: 8px 10px 8px 10px;
  outline: none;
  line-height: normal;
  color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT};
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  border-radius: 4px;
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};

  &::placeholder {
    color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT_PLACEHOLDER};
  }
`

type ScheduleTimeProps = {
  className?: string
  defaultValue: string
  label: string
  onChange: (date: string) => void
}

export const ScheduleTime = ({ className, defaultValue, label, onChange }: ScheduleTimeProps): JSX.Element => {
  const [value, setValue] = useState(dayjs(defaultValue).format(DATE_TIME_INPUT_FORMAT))

  const onDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const newDate = dayjs(e.target.value).toISOString()
      setValue(e.target.value)
      onChange(newDate)
    }
  }

  return (
    <StyledScheduledField className={className}>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput
        max={dayjs().add(5, 'years').format(DATE_TIME_INPUT_FORMAT)}
        min={dayjs().format(DATE_TIME_INPUT_FORMAT)}
        onChange={onDateTimeChange}
        type="datetime-local"
        value={value}
      />
      <StyledLabel>
        <UserTimezone />
      </StyledLabel>
    </StyledScheduledField>
  )
}
