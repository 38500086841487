import { WorkflowCardDraggable } from '@community_dev/flexible-canvas'
import { Typography } from '@community_dev/pixels'
import styled from 'styled-components'

export const Wrapper = styled.div``

export const StyledHeading = styled(Typography).attrs({
  variant: 'h3',
  component: 'h2',
})`
  margin: 48px 0 8px;
  font-size: 16px;

  &:first-of-type {
    margin-top: 8px;
  }
`

export const StyledCardDraggable = styled(WorkflowCardDraggable)`
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: 8px;
  margin-bottom: 11px;

  &:last-of-type {
    margin-bottom: 0;
  }
`
