import {
  MemberDataFilter,
  SelectorOperators,
  RelationshipOperators,
  isBetweenFilter,
  isRelationshipFilter,
  isSelectorFilter,
  FilterOperator,
  FieldDefinition,
  isRelationshipOperator,
  isSelectorOperator,
  RelationshipFilter,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ElasticInput, Select } from '@community_dev/pixels'
import { ChangeEvent, useMemo } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { StyledBetweenContainer, StyledCompoundField, StyledInput } from '../FieldPane.style'

import {
  getOperandBetweenValue,
  generateOptions,
  hasChangedToBetween,
  hasChangedFromBetween,
  convertToBetweenValue,
  convertFromBetweenValue,
  TypeOperators,
} from './utils'

const validInteger = /^-?[0-9]{0,255}$/i
const validateInteger = (value: string): boolean => {
  return validInteger.test(value)
}
const validDecimal2 = /^-?[0-9]{0,255}(\.[0-9]{0,2})?$/i
const validateDecimal2 = (value: string): boolean => {
  return validDecimal2.test(value)
}
const validDecimal4 = /^-?[0-9]{0,255}(\.[0-9]{0,4})?$/i
const validateDecimal4 = (value: string): boolean => {
  return validDecimal4.test(value)
}

const VALIDATE = {
  integer: validateInteger,
  decimal2: validateDecimal2,
  decimal4: validateDecimal4,
}

type NumberFieldProps = {
  field: FieldDefinition
}

const numberOperators: TypeOperators = {
  [SelectorOperators.GREATER_THAN]: 'greaterThan',
  [SelectorOperators.LESS_THAN]: 'lessThan',
  [SelectorOperators.EQUALS]: 'equals',
  [RelationshipOperators.AND]: 'and',
}

export function NumberField({ field }: NumberFieldProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation(undefined, { keyPrefix: 'customData' })

  const options = useMemo(() => {
    return generateOptions({ t, typeOperators: numberOperators, type: field.value_type })
  }, [t, field.value_type])

  const validateNumber = (value: MemberDataFilter | null): string | void => {
    if (value && isSelectorFilter(value)) {
      const fieldValue = value?.operand?.value || ''
      const isValidNumber = VALIDATE[field.value_type](fieldValue)
      const isValidInput = typeof fieldValue === 'string' && fieldValue.length > 0

      if (!isValidNumber || !isValidInput) return 'Number is not valid'
    }

    if (value && isBetweenFilter(value)) {
      const fields = value?.operands

      return fields.reduce<string | undefined>((errors, value) => {
        if (errors) return errors

        const fieldValue = value?.operand?.value || ''
        const isValidNumber = VALIDATE[field.value_type](fieldValue)
        const isValidInput = typeof fieldValue === 'string' && fieldValue.length > 0

        if (!isValidNumber || !isValidInput) return 'Number is not valid'
      }, undefined)
    }
  }

  return (
    <Field name={`custom-field-${field.key}`} validate={validateNumber}>
      {({ input }) => {
        const handleSelectChange = (option: { value: FilterOperator; label: string } | null): void => {
          if (option) {
            const { value: operator } = option
            if (hasChangedToBetween({ nextOperator: operator, previousValue: input?.value })) {
              input.onChange(convertToBetweenValue({ field, previousValue: input?.value }))
              return
            }

            if (hasChangedFromBetween({ nextOperator: operator, previousValue: input?.value })) {
              input.onChange(
                convertFromBetweenValue({
                  field,
                  previousValue: input?.value,
                  operator: operator as SelectorOperators,
                }),
              )
              return
            }

            input.onChange({
              ...(input?.value || {
                operand: {
                  value: '',
                },
              }),
              operator,
            } as MemberDataFilter)
          }
        }

        const handleSingleValueChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
          const value = event.target.value

          if (VALIDATE[field.value_type](value)) {
            input.onChange({
              ...input.value,
              operand: {
                field_key: field.key,
                field_label: field.name,
                source: field.source,
                type: field.value_type,
                value: value,
              },
            } as MemberDataFilter)
          }
        }

        const handleBetweenValueChange =
          (idx: number) =>
          (event: ChangeEvent<HTMLTextAreaElement>): void => {
            const value = event.target.value

            if (input?.value && isRelationshipFilter(input.value)) {
              const operands = input?.value?.operands.map((filter, operandIdx) => {
                if (operandIdx === idx && isSelectorFilter(filter) && VALIDATE[field.value_type](value)) {
                  return {
                    ...filter,
                    operand: {
                      field_key: field.key,
                      field_label: field.name,
                      source: field.source,
                      type: field.value_type,
                      value,
                    },
                  }
                }

                return filter
              })

              input.onChange({
                ...input.value,
                operands,
              } as MemberDataFilter)
            }
          }

        const selectedOperator = options.find((operator) => operator.value === input?.value?.operator)

        return (
          <div>
            <StyledCompoundField>
              <div>is</div>
              <div>
                <Select inline onChange={handleSelectChange} options={options} value={selectedOperator} width="138px" />
              </div>
              {isSelectorOperator(selectedOperator?.value) &&
                isSelectorFilter(input.value) &&
                typeof input?.value?.operand?.value === 'string' && (
                  <StyledInput $background={COLORS.INPUT_BACKGROUND} $borderColor={COLORS.BORDERS}>
                    <ElasticInput
                      data-testid={`${field.key}-value`}
                      maxRows={1}
                      onChange={handleSingleValueChange}
                      placeholder={t('numberPlaceholder')}
                      value={input.value.operand.value || ''}
                    />
                  </StyledInput>
                )}
            </StyledCompoundField>
            {isRelationshipOperator(selectedOperator?.value) && (
              <StyledBetweenContainer>
                <StyledInput $background={COLORS.INPUT_BACKGROUND} $borderColor={COLORS.BORDERS} key="number-0-field">
                  <ElasticInput
                    data-testid={`${field.key}-value-1`}
                    maxRows={1}
                    onChange={handleBetweenValueChange(0)}
                    placeholder={t('numberPlaceholder')}
                    style={{
                      width: '150px',
                    }}
                    value={getOperandBetweenValue(0, input?.value as RelationshipFilter)}
                  />
                </StyledInput>
                <StyledInput $background={COLORS.INPUT_BACKGROUND} $borderColor={COLORS.BORDERS} key="number-1-field">
                  <ElasticInput
                    data-testid={`${field.key}-value-2`}
                    maxRows={1}
                    onChange={handleBetweenValueChange(1)}
                    placeholder={t('numberPlaceholder')}
                    style={{
                      width: '150px',
                    }}
                    value={getOperandBetweenValue(1, input?.value as RelationshipFilter)}
                  />
                </StyledInput>
              </StyledBetweenContainer>
            )}
          </div>
        )
      }}
    </Field>
  )
}
