import { FILTER_PREDICATES } from '@community_dev/filter-dsl/lib/digits'
import { HTTP } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { FanStateFlag } from '@community_dev/types/lib/api/Fan'

import { Community } from './community'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { normalizeFan } from 'utils/normalize'
import { route } from 'utils/router'

export type Fan = {
  age: number | null
  ageText: string
  avatar?: string
  block: boolean
  blocking: boolean
  city: string | null
  cityState?: string
  communicationChannel: CommunicationChannel
  communities: Community[]
  conversationWindowEndAt: string | null
  country: string | null
  countryCode: string | null
  delayed: boolean
  displayName?: string
  fanId: string
  fanSubscriptionId: string
  firstName: string
  fullName: string
  gender: string
  graphicUrl: string
  id: string
  initials: string
  joined: string
  joinedAt: string
  joinedCommunityAt: string
  lastName: string
  loading: boolean
  messageFrom: string
  messageTo: string
  overview: string
  phone: string
  state: string | null
  // NOTE: Is `stateCode` or `stateAbbrev` deprecated?
  stateCode?: string | null
  stateAbbrev?: string
  stateFlags: FanStateFlag[]
  successful: boolean
  unsubscribing: boolean
  tags?: any
}

export function getFan(fanId?: string): Promise<Fan> {
  return request(route(ENDPOINTS.FANS.FAN, { fanId })).then(({ data }) => {
    return normalizeFan(data) as Fan
  })
}

export const putUnsubscribeFan = (fanId: string): Promise<any> => {
  return request(route(ENDPOINTS.FANS.OPT_OUT, { fanId }), {
    method: HTTP.PUT,
    responseMethod: 'text',
  })
}

export const putBlockFan = (fanId: string): Promise<any> => {
  return request(route(ENDPOINTS.FANS.BLOCK, { fanId }), {
    method: HTTP.PUT,
    responseMethod: 'text',
  })
}

export const postFanIndexByQuery = (
  query = '',
  communicationChannel: CommunicationChannel = CommunicationChannel.SMS,
): Promise<Fan[]> => {
  return request(ENDPOINTS.FANS.INDEX_BY_QUERY, {
    method: HTTP.POST,
    body: {
      communicationChannel,
      filters: {
        ...(query
          ? {
              name: {
                predicate: FILTER_PREDICATES.CONTAINS,
                value: query,
              },
            }
          : {}),
      },
    },
  }).then(({ data }) => data.map(normalizeFan))
}
