import { InboxFilter } from 'contexts/InboxProvider'

export const FILTER_STATE: { [key: string]: InboxFilter[] } = {
  INITIAL: [
    {
      field: 'search[communication_channel]',
      key: 'sms',
      label: 'SMS',
      value: 'sms',
    },
  ],
}
