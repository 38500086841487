import { Layout } from '@community_dev/pixels'
import styled from 'styled-components'

import { WorkflowSidebarTabs } from '../WorkflowSidebarTabs'

import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'

export const StyledWorkflowEditButton = styled.button`
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
`

export function WorkflowSidebarNavigation(): JSX.Element | null {
  const { isLoading } = useWorkflowProvider()

  if (isLoading) {
    return null
  }

  return (
    <Layout padding="16px">
      <WorkflowSidebarTabs />
    </Layout>
  )
}
