import { BUTTON_VARIANTS, Button, Typography } from '@community_dev/pixels'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ROUTES } from 'constants/routes'
import { StyledAction, StyledBack, StyledTextButton } from 'screens/Onboarding/styled'

const StyledImage = styled.img`
  height: 140px;
  width: 140px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 40px;
`

type PreviewProps = {
  image: string
  isSuccess?: boolean
  isUploading?: boolean
  onEdit: (...args: any[]) => any
}

export function Preview({ isSuccess = false, isUploading = false, image, onEdit }: PreviewProps): JSX.Element {
  return (
    <>
      <StyledImage alt="Contact Photo" src={image} />
      <Typography variant="h1">Contact Photo</Typography>
      <p>Your Community Members will see this photo when you text them.</p>
      {isSuccess ? (
        <>
          <StyledAction>
            <Button
              disabled={isUploading}
              forwardedAs={Link}
              role="link"
              to={ROUTES.ONBOARDING.SUMMARY}
              variant={BUTTON_VARIANTS.ACTION}
            >
              {isUploading ? 'Uploading' : 'Done'}
            </Button>
          </StyledAction>
          <StyledTextButton disabled={isUploading} onClick={onEdit}>
            Edit
          </StyledTextButton>
        </>
      ) : (
        <>
          <StyledAction>
            <Button disabled={isUploading} onClick={onEdit} variant={BUTTON_VARIANTS.ACTION}>
              {isUploading ? 'Uploading' : 'Edit'}
            </Button>
          </StyledAction>
          <StyledBack to={ROUTES.ONBOARDING.SUMMARY}>Back</StyledBack>
        </>
      )}
    </>
  )
}
