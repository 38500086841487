import { BORDER_RADIUS, ElasticInput, FONT_SIZE, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { MessageBoxProps } from './MessageBox'

export const Wrapper = styled.div``

export const Main = styled.div<Pick<MessageBoxProps, 'disabled'>>`
  border-radius: ${BORDER_RADIUS[1]};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background-color: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};

  ${(props) =>
    props.disabled &&
    `
    background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  `}

  textarea {
    letter-spacing: 0.02em;
    line-height: 20px;
    border-radius: ${BORDER_RADIUS[1]};

    ${(props) =>
      props.disabled &&
      `
      background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
      color: ${({ theme }) => theme?.COLORS?.TEXT};
    `}
  }
`

export const StyledElasticInput = styled(ElasticInput)``

export const Remaining = styled.div<{ over: boolean }>`
  text-align: right;
  font-size: ${FONT_SIZE[2]};
  padding: ${SPACING[1]} ${SPACING[1]} 0 0;
  font-weight: 400;
  color: ${(props) => (props.over ? props?.theme?.COLORS?.ERRORS : props?.theme?.COLORS?.SUBTEXT)};
`
