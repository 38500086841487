import { ModalGuidedTour, OverlayMessage } from '@community_dev/pixels'
import React, { useState } from 'react'

import { CopyFooter } from '../components/CopyFooter'
import { SlideWithImage } from '../components/SlideWithImage'

import { useClient } from 'hooks/useClient'
import { generalConfig } from 'utils/config'

const SLIDES = {
  SHARE_YOUR_NUMBER: {
    HEADING: 'Share your number',
    BODY: `It's time to share your Community number! Post your Community number across your socials, add it to your website, or use the Growth Tools tab on your dashboard to create a QR code that opts people into your Community once scanned.`,
    SRC: [
      `${generalConfig.staticUrl}/product-education/share-your-community-number%403x.png 3x`,
      `${generalConfig.staticUrl}/product-education/share-your-community-number%402x.png 2x`,
      `${generalConfig.staticUrl}/product-education/share-your-community-number.png 1x`,
    ],
    ALT: 'Instagram post that support and feeds IRL number promotion via QR code at event',
  },
  GET_CREATIVE: {
    HEADING: 'Get creative',
    BODY: 'Think outside the box by placing your Community Number on products or in real life, where your audience can connect with you.',
    SRC: [
      `${generalConfig.staticUrl}/product-education/bev-number-promotion%403x.png 3x`,
      `${generalConfig.staticUrl}/product-education/bev-number-promotion%402x.png 2x`,
      `${generalConfig.staticUrl}/product-education/bev-number-promotion.png 1x`,
    ],
    ALT: 'Community Number on a beverage can.',
  },
  POST_ON_SOCIAL_MEDIA: {
    HEADING: 'Post on social media',
    BODY: 'Post your Community Number to your audience where they already exist. Instagram, X, Youtube, Tiktok, Discord, Reddit, and more.',
    SRC: [
      `${generalConfig.staticUrl}/product-education/wicked-instagram-launch%403x.png 3x`,
      `${generalConfig.staticUrl}/product-education/wicked-instagram-launch%402x.png 2x`,
      `${generalConfig.staticUrl}/product-education/wicked-instagram-launch.png 1x`,
    ],
    ALT: 'Social media post encouraging fans to text Wicked at 917-540-3022',
  },
  COPY_PASTE: {
    HEADING: 'Copy and Paste',
    BODY: 'Quickly copy and paste your number anytime, anywhere from the side menu.',
    SRC: [
      `${generalConfig.staticUrl}/product-education/copypaste%403x.png 3x`,
      `${generalConfig.staticUrl}/product-education/copypaste%402x.png 2x`,
      `${generalConfig.staticUrl}/product-education/copypaste.png 1x`,
    ],
    ALT: 'Copy your community number',
    COPY_BUTTON_LABEL: 'Copy my Community number',
    COPY_OVERLAY_LABEL: 'Number copied',
  },
}

type ShareYourNumberModalProps = {
  isOpen: boolean
  onClose: () => void
}
export const ShareYourNumberModal = ({ isOpen, onClose }: ShareYourNumberModalProps): JSX.Element => {
  const { data: client } = useClient()
  const { phone } = client || { phone: '' }
  const [isDisplayed, setIsDisplayed] = useState(false)
  return (
    <ModalGuidedTour adaptiveHeight isOpen={isOpen} onClose={onClose}>
      <ModalGuidedTour.Slide heading={SLIDES.SHARE_YOUR_NUMBER.HEADING}>
        <SlideWithImage
          alt={SLIDES.SHARE_YOUR_NUMBER.ALT}
          body={SLIDES.SHARE_YOUR_NUMBER.BODY}
          src={SLIDES.SHARE_YOUR_NUMBER.SRC}
        />
      </ModalGuidedTour.Slide>
      <ModalGuidedTour.Slide heading={SLIDES.GET_CREATIVE.HEADING}>
        <SlideWithImage alt={SLIDES.GET_CREATIVE.ALT} body={SLIDES.GET_CREATIVE.BODY} src={SLIDES.GET_CREATIVE.SRC} />
      </ModalGuidedTour.Slide>
      <ModalGuidedTour.Slide heading={SLIDES.POST_ON_SOCIAL_MEDIA.HEADING}>
        <SlideWithImage
          alt={SLIDES.POST_ON_SOCIAL_MEDIA.ALT}
          body={SLIDES.POST_ON_SOCIAL_MEDIA.BODY}
          src={SLIDES.POST_ON_SOCIAL_MEDIA.SRC}
        />
      </ModalGuidedTour.Slide>
      <ModalGuidedTour.Slide
        dots={false}
        footer={({ onDone }) => (
          <CopyFooter
            copyButtonLabel={SLIDES.COPY_PASTE.COPY_BUTTON_LABEL}
            onCopy={() => setIsDisplayed(true)}
            onDone={onDone}
            value={phone}
          />
        )}
        heading={SLIDES.COPY_PASTE.HEADING}
      >
        <OverlayMessage
          content={SLIDES.COPY_PASTE.COPY_OVERLAY_LABEL}
          fixed={false}
          height={'116px'}
          isDisplayed={isDisplayed}
          onDismissed={() => setIsDisplayed(false)}
          width={'193px'}
        >
          <SlideWithImage alt={SLIDES.COPY_PASTE.ALT} body={SLIDES.COPY_PASTE.BODY} src={SLIDES.COPY_PASTE.SRC} />
        </OverlayMessage>
      </ModalGuidedTour.Slide>
    </ModalGuidedTour>
  )
}
