import { useState } from 'react'
import ContentLoader from 'react-content-loader'

import { MeasuredContainer } from 'components/MeasuredContainer'

export const LoadingHeader = (): JSX.Element => {
  const [width, setWidth] = useState(600)
  return (
    <>
      <MeasuredContainer
        onResize={({ width }) => {
          setWidth(width)
        }}
      >
        <ContentLoader height={90} viewBox={`0 0 ${width} 90`}>
          <rect height="24" width="80" y="6" />
          <rect height="45" width="100%" y="40" />
        </ContentLoader>
      </MeasuredContainer>
    </>
  )
}
