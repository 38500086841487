import noop from 'lodash/noop'
import { Children, ReactNode, cloneElement, HtmlHTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ActivatedItemIndicator, StyledListButton } from './styled'

const StyledListItem = styled.li`
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  user-select: none;
`

export function ComposeNavigationItem({
  activated = false,
  children,
  className,
  disabled = false,
  onItemClick = noop,
  selected = false,
  to,
  type = 'includes',
  ...rest
}: {
  activated?: boolean
  children: ReactNode
  className?: string
  disabled?: boolean
  onItemClick?(): void
  selected?: boolean
  to?: string
  type?: 'includes' | 'excludes'
} & Partial<HtmlHTMLAttributes<HTMLButtonElement>>): JSX.Element {
  function onClick() {
    if (disabled) {
      return
    }

    onItemClick()
  }

  return (
    <StyledListItem draggable={false}>
      <StyledListButton
        $activated={activated}
        $type={type}
        as={to && !disabled ? Link : 'button'}
        className={className}
        disabled={disabled}
        draggable={false}
        onClick={onClick}
        selected={selected}
        to={to}
        {...rest}
      >
        {Children.map(children, (child: any) => {
          if (!child) return null
          return cloneElement(child, {
            activated,
            disabled,
            selected,
          })
        })}
        {activated && <ActivatedItemIndicator />}
      </StyledListButton>
    </StyledListItem>
  )
}
