import { ApiError } from '@community_dev/requests'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { getSubscription, SubscriptionV2 } from 'api/billing'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

export function useSubscription({ enabled = true, select = (x) => x } = {}): UseQueryResult<SubscriptionV2, ApiError> {
  const clientId = useClientId()
  const [isEnabled, setEnabled] = useState(enabled)

  useEffect(() => {
    setEnabled(enabled)
  }, [setEnabled, enabled])

  return useQuery<SubscriptionV2, ApiError>(
    [QUERY_CACHE.BILLING.SUBSCRIPTION, { clientId }],
    () => {
      return getSubscription({
        clientId,
      })
    },
    {
      refetchOnWindowFocus: false,
      retryOnMount: false,
      enabled: isEnabled && Boolean(clientId),
      staleTime: STALE_TIME.TEN_MINUTES,
      onError(err) {
        if (err.status !== 404) return
        setEnabled(false)
      },
      select,
    },
  )
}
