import { ChevronRightIcon, Layout, Skeleton, SPACING, Typography } from '@community_dev/pixels'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { InvoicesTable } from './InvoicesTable'

import { ROUTES } from 'constants/routes'
import { useInvoices } from 'hooks/useInvoices'
import { SettingsSection } from 'layouts/SettingsLayout'

function formatCurrency(cents) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100)
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  text-decoration: none;
`

export function InvoicesDetails(): JSX.Element | null {
  const { COLORS } = useTheme() || {}
  const { data: invoicesData, isInitialLoading } = useInvoices()
  const { t } = useTranslation()

  const { totalAmountDue = 0, invoices = [] } = invoicesData || {}

  const visibleInvoices = useMemo(() => {
    // find the indexes of each open invoice
    const indexOfOpenInvoices = invoices
      .map((invoice, index) => (invoice.status === 'open' ? index : null))
      .filter((index) => index !== null)

    // find the position of the furthest open invoice
    const furthestOpenInvoice = indexOfOpenInvoices?.[indexOfOpenInvoices.length - 1] || 0

    // determine how many invoices to display, ensuring we always show
    // all open invoices
    const maxInvoicesToDisplay = Math.max(3, furthestOpenInvoice)

    return invoices.slice(0, maxInvoicesToDisplay)
  }, [invoices])

  if (!invoices.length && !isInitialLoading) {
    return null
  }

  return (
    <>
      {totalAmountDue > 0 || isInitialLoading ? (
        <SettingsSection data-testid="billing-overview">
          <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
            {t('settings.billing.tab')}
          </Typography>
          <Skeleton height="16px" loading={isInitialLoading} marginBottom="10px">
            <Typography component="div" fontSize="16px" marginBottom="10px" variant="h3">
              {t('settings.billing.totalBalanceDue')}
            </Typography>
          </Skeleton>
          <Skeleton height="30px" loading={isInitialLoading} marginBottom="10px" width="100px">
            <Typography component="div" fontSize="30px" marginBottom="0" variant="h3">
              {formatCurrency(totalAmountDue)}
            </Typography>
          </Skeleton>
        </SettingsSection>
      ) : null}

      <SettingsSection data-testid="billing-history">
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          {t('settings.billing.billingHistory')}
        </Typography>
        <Skeleton height="30px" loading={isInitialLoading} marginBottom="8px" width="100%">
          {totalAmountDue > 0 ? (
            <Typography fontSize="14px" fontWeight="400">
              {t('settings.billing.payNowExplainer')}
            </Typography>
          ) : null}
        </Skeleton>

        <InvoicesTable invoicesData={visibleInvoices} isLoading={isInitialLoading} />

        <Layout display="flex" justifyContent="flex-end" marginBottom="24px">
          <StyledLink to={ROUTES.SETTINGS.BILLING_HISTORY}>
            {t('settings.billing.viewFullBillingHistory')}
            <ChevronRightIcon color={COLORS?.SUBTEXT} />
          </StyledLink>
        </Layout>
      </SettingsSection>
    </>
  )
}
