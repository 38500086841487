import { BORDER_RADIUS, FONT_SIZE, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

const StyledStatusContainer = styled.div`
  display: flex;
  padding: ${SPACING[4]} 0;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 100%;
    width: ${SPACING[4]};
    position: absolute;
    top: 0;
    left: -${SPACING[4]};
  }
`

const StyledStatus = styled.div`
  font-size: ${FONT_SIZE[3]};
  font-style: italic;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  height: ${SPACING[6]};
  line-height: ${SPACING[6]};
  padding: 0 ${SPACING[5]};
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  text-align: center;
  margin-left: ${SPACING[3]};
  border-radius: ${BORDER_RADIUS[2]};
`

const StyledBlock = styled.div`
  display: flex;
  justify-content: center;
  width: ${SPACING[6]};
`

const StyledCircle = styled.div<{ $isDark: boolean }>`
  background: ${({ theme, $isDark }) =>
    $isDark ? theme?.COLORS?.BUTTON_PRIMARY : theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-radius: ${SPACING[2]};
  width: ${SPACING[4]};
  height: ${SPACING[4]};
  border: 1px solid ${({ theme, $isDark }) => ($isDark ? theme?.COLORS?.BUTTON_PRIMARY : theme?.COLORS?.BORDERS)};
`

const StyledText = styled.div`
  font-size: ${FONT_SIZE[3]};
  height: ${SPACING[6]};
  line-height: ${SPACING[6]};
  text-align: center;
  margin-left: ${SPACING[3]};
`

type StatusIndicatorProps = {
  isDark?: boolean
  status?: string
  text?: string
}

export const StatusIndicator = ({ isDark = false, status, text = '' }: StatusIndicatorProps): JSX.Element => {
  return (
    <StyledStatusContainer data-testid="status-indicator">
      <StyledBlock>
        <StyledCircle $isDark={isDark} />
      </StyledBlock>
      {status ? <StyledStatus>{status}</StyledStatus> : <StyledText>{text}</StyledText>}
    </StyledStatusContainer>
  )
}
