import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { CampaignStatus } from '@community_dev/types/lib/api/v2/Campaign'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type ScheduledCampaign = CamelCasedPropertiesDeep<Api.V2.Scheduled>
export type ScheduledCampaignResponse = CamelCasedPropertiesDeep<Api.V2.Pagination<Api.V2.Scheduled>>

export function getScheduledCampaigns({
  clientId,
  pageParam,
  pageSize,
  communicationChannels = [CommunicationChannel.SMS, CommunicationChannel.WHATS_APP, CommunicationChannel.AMB],
  statuses = [
    CampaignStatus.SCHEDULED,
    CampaignStatus.MEDIA_PROCESSING,
    CampaignStatus.TEMPLATE_PENDING,
    CampaignStatus.SUBMITTED_FOR_APPROVAL,
  ],
  startDatetime,
  endDatetime,
}: {
  clientId: string
  pageParam?: number
  pageSize?: number
  communicationChannels?: CommunicationChannel[]
  statuses?: CampaignStatus[]
  startDatetime?: string
  endDatetime?: string
}): Promise<ScheduledCampaignResponse> {
  return request(
    route(
      ENDPOINTS.CAMPAIGNS.SCHEDULED,
      { clientId },
      {
        communication_channels: communicationChannels.join(','),
        page_number: pageParam,
        page_size: pageSize,
        statuses: statuses.join(','),
        start_datetime: startDatetime,
        end_datetime: endDatetime,
      },
    ),
  )
}

export function deleteScheduledCampaign({
  clientId,
  id,
}: {
  clientId: string
  id: string
}): Promise<ScheduledCampaign> {
  return request(route(`${ENDPOINTS.CAMPAIGNS.SCHEDULED}/${id}`, { clientId }), {
    method: HTTP.DELETE,
  })
}

export function getScheduledRecurringCampaigns({ clientId }: { clientId: string }): Promise<ScheduledCampaignResponse> {
  return request(route(ENDPOINTS.CAMPAIGNS.RECURRING, { clientId }))
}
