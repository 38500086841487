export const ageAndGenderInsights = {
  age_gender_insights: [
    {
      timestamp: '2021-01-29T08:06:39.317352Z',
      total_fans: 142,
      total_reported_age: 142,
      total_reported_gender: 142,
      ages: {
        '13-17': {
          total: 24,
          male: 10,
          female: 10,
          'non-binary': 2,
          'no-answer': 2,
        },
        '18-24': {
          total: 55,
          male: 30,
          female: 25,
          'non-binary': 0,
          'no-answer': 0,
        },
        '25-34': {
          total: 26,
          male: 13,
          female: 13,
          'non-binary': 0,
          'no-answer': 0,
        },
        '35-44': {
          total: 35,
          male: 25,
          female: 10,
          'non-binary': 0,
          'no-answer': 0,
        },
        '45-54': {
          total: 20,
          male: 5,
          female: 10,
          'non-binary': 2,
          'no-answer': 3,
        },
        '55-64': {
          total: 2,
          male: 2,
          female: 0,
          'non-binary': 0,
          'no-answer': 0,
        },
        '65-99': {
          total: 0,
          male: 0,
          female: 0,
          'non-binary': 0,
          'no-answer': 0,
        },
        '100-120': {
          total: 0,
          male: 0,
          female: 0,
          'non-binary': 0,
          'no-answer': 0,
        },
      },
    },
  ],
}

export const stateInsights = {
  state_insights: [
    {
      timestamp: '2020-11-09T22:17:18.734Z',
      total_fans: 5956,
      states: [
        { state: 'CA', display_name: 'California', country: 'US', count: 1602 },
        { state: 'NY', display_name: 'New York', country: 'US', count: 2304 },
        { state: 'TX', display_name: 'Texas', country: 'US', count: 405 },
        { state: 'PR', display_name: 'Puerto Rico', country: 'US', count: 1 },
        {
          state: 'NC',
          display_name: 'North Carolina',
          country: 'US',
          count: 666,
        },
        { state: 'AL', display_name: 'Alaska', country: 'US', count: 897 },
        { state: 'OK', display_name: 'Oklahoma', country: 'US', count: 20 },
        { state: 'OR', display_name: 'Oregon', country: 'US', count: 12 },
        { state: 'WY', display_name: 'Wyoming', country: 'US', count: 46 },
        { state: 'VR', display_name: 'Virginia', country: 'US', count: 3 },
      ],
    },
    {
      timestamp: '2020-11-09T10:17:18.734Z',
      total_fans: 3000,
      states: [
        { state: 'CA', display_name: 'California', country: 'US', count: 902 },
        { state: 'NY', display_name: 'New York', country: 'US', count: 900 },
        { state: 'TX', display_name: 'Texas', country: 'US', count: 305 },
        { state: 'PR', display_name: 'Puerto Rico', country: 'US', count: 1 },
      ],
    },
  ],
}

export const msaInsights = {
  msa_insights: [
    {
      timestamp: '2020-11-09T22:17:18.734Z',
      total_fans: 4000,
      msas: [
        {
          msa: 'Los Angeles-Long Beach-Anaheim, CA',
          display_name: 'Los Angeles, CA',
          country: 'US',
          count: 1102,
        },
        {
          msa: 'Portland-Metro, OR',
          display_name: 'Portland, OR',
          country: 'US',
          count: 586,
        },
        {
          msa: 'New York-Newark-Jersey City, NY-NJ-PA',
          display_name: 'New York, NY',
          country: 'US',
          count: 789,
        },
        {
          msa: 'Dallas-Fort Worth-Arlington, TX',
          display_name: 'Dallas, TX',
          country: 'US',
          count: 405,
        },
        {
          msa: 'Washington-Arlington-Alexandria, DC-VA-MD-WV',
          display_name: 'Washington, DC',
          country: 'US',
          count: 205,
        },
        {
          msa: 'San Juan, PR',
          display_name: 'San Juan, PR',
          country: 'US',
          count: 3,
        },
      ],
    },
    {
      timestamp: '2020-11-09T10:17:18.734Z',
      total_fans: 3000,
      msas: [
        {
          msa: 'Los Angeles-Long Beach-Anaheim, CA',
          country: 'US',
          count: 902,
        },
        {
          msa: 'New York-Newark-Jersey City, NY-NJ-PA',
          country: 'US',
          count: 900,
        },
        { msa: 'Dallas-Fort Worth-Arlington, TX', country: 'US', count: 305 },
        {
          msa: 'Washington-Arlington-Alexandria, DC-VA-MD-WV',
          country: 'US',
          count: 205,
        },
        { msa: 'San Juan, PR', country: 'US', count: 2 },
      ],
    },
  ],
}
