import { AddOnHeading } from './AddOnHeading'
import { AddOnImage } from './AddOnImage'
import { AddOnText } from './AddOnText'

import { Content } from 'api/addOns'
import { AddOnsContentType } from 'hooks/useAddons/constants'

type DynamicBodyProps = {
  body: Content[]
}

export const DynamicBody = ({ body }: DynamicBodyProps): JSX.Element => {
  return (
    <>
      {body.map((element, index) => {
        if (element.type === AddOnsContentType.text) {
          return <AddOnText {...element} key={index} />
        }
        if (element.type === AddOnsContentType.heading) {
          return <AddOnHeading {...element} key={index} />
        }
        if (element.type === AddOnsContentType.image) {
          return <AddOnImage {...element} key={index} />
        }

        return null
      })}
    </>
  )
}
