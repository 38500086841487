import { BirthdayMatch, findOne } from '@community_dev/filter-dsl/lib/subscription-data'
import { FONT_SIZE, SPACING, Select } from '@community_dev/pixels'
import { Dayjs } from 'dayjs'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { UserTimezone } from './components/UserTimezone'

import { DATE_INPUT_FORMAT, DATE_TIME_INPUT_FORMAT, DAY_FORMAT, TIME_INPUT_FORMAT } from 'constants/date'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import Sentry from 'integrations/Sentry'
import dayjs from 'utils/dayjs'
import { RecurrenceDay, RecurrenceFrequency, RecurrenceRule, generateRecurrenceRule } from 'utils/recurrence'

const StyledScheduledField = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  gap: 5px;
  font-size: ${FONT_SIZE[3]};

  .frequency-control {
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.COLORS.BORDERS};
    background-color: ${({ theme }) => theme.COLORS.INPUT_BACKGROUND};
    height: 34px;
    min-height: 34px;
    width: 164px;
    min-width: 164px;
  }
  .frequency-select-indicator {
    padding: 0 6px 0 0;
  }
  .frequency-select-value {
    padding-right: 0;
  }
  .frequency-select-menu {
    width: max-content;
    min-width: 100%;
  }
`

const StyledLabel = styled.span`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: ${SPACING[2]};
  border-radius: 4px;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
`

const StyledInput = styled.input`
  cursor: pointer;
  font-weight: 500;
  padding: 8px 10px 8px 10px;
  outline: none;
  line-height: normal;
  color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT};
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  border-radius: 4px;
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};

  &::placeholder {
    color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT_PLACEHOLDER};
  }
`

type FrequencyOption = {
  value: RecurrenceFrequency | null
  label: string
}

type ScheduleProps = {
  scheduledAt: Dayjs
  onChange: (datetime: Dayjs) => void
  onFrequencyChange: Dispatch<SetStateAction<RecurrenceRule | undefined>>
}

export const Schedule = ({ scheduledAt, onChange, onFrequencyChange }: ScheduleProps): JSX.Element => {
  const { t } = useTranslation()
  const { includedFiltersAst } = useFilters()
  const day = scheduledAt.format(DAY_FORMAT)
  const [frequency, setFrequency] = useState<RecurrenceFrequency | null>()
  const [value, setValue] = useState(scheduledAt.format(DATE_TIME_INPUT_FORMAT))

  const frequencyOptions: FrequencyOption[] = useMemo(
    () => [
      { value: null, label: t('compose.doesNotRepeat') },
      { value: RecurrenceFrequency.DAILY, label: t('compose.repeatsDaily') },
      { value: RecurrenceFrequency.WEEKLY, label: t('compose.repeatsWeeklyOn', { day }) },
    ],
    [day, t],
  )

  const frequencyEnabled = useMemo(() => {
    const birthdayFilter = findOne(includedFiltersAst, BirthdayMatch)
    const isBirthdayFilterRoot = includedFiltersAst === birthdayFilter

    return birthdayFilter && isBirthdayFilterRoot
  }, [includedFiltersAst])

  useEffect(() => {
    if (!frequencyEnabled) {
      setFrequency(null)
    }
  }, [frequencyEnabled])

  const onDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = dayjs(e.target.value, `${DATE_INPUT_FORMAT} ${TIME_INPUT_FORMAT}`)
    setValue(e.target.value)
    onChange(newDate)
  }

  useEffect(() => {
    try {
      if (frequency) {
        onFrequencyChange(generateRecurrenceRule({ frequency, day: RecurrenceDay[day.toUpperCase()] }))
      } else {
        onFrequencyChange(undefined)
      }
    } catch (e) {
      Sentry.captureException(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequency, day])

  return (
    <StyledScheduledField data-testid="scheduled-form">
      <StyledLabel>{t('scheduled.sendAt')}</StyledLabel>
      <StyledInput
        max={dayjs().add(5, 'years').format(DATE_TIME_INPUT_FORMAT)}
        min={dayjs().format(DATE_TIME_INPUT_FORMAT)}
        onChange={onDateTimeChange}
        type="datetime-local"
        value={value}
      />
      <StyledLabel>
        <UserTimezone />
      </StyledLabel>
      {frequencyEnabled && (
        <Select
          aria-label={t('compose.selectRecurrenceFrequency')}
          classNames={{
            control: () => 'frequency-control',
            indicatorsContainer: () => 'frequency-select-indicator',
            valueContainer: () => 'frequency-select-value',
            menu: () => 'frequency-select-menu',
          }}
          defaultValue={frequencyOptions[0]}
          id="frequency"
          onChange={(option) => {
            setFrequency(option?.value)
          }}
          options={frequencyOptions}
        />
      )}
    </StyledScheduledField>
  )
}
