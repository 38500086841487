import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { SalesforceInstance, getSalesforceInstances } from 'api/salesforce'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'

export function useSalesforceInstances(clientId: string): UseQueryResult<[SalesforceInstance]> {
  return useQuery([QUERY_CACHE.SALESFORCE.INSTANCES, clientId], () => getSalesforceInstances(clientId), {
    staleTime: STALE_TIME.FIVE_MINUTES,
  })
}
