import { useInterval } from '@community_dev/hooks'
import { AiBubble, BORDER_RADIUS, FONT_SIZE, Layout, SPACING, Skeleton, Typography } from '@community_dev/pixels'
import { ApiError } from '@community_dev/requests'
import { CampaignSummaryStatus } from '@community_dev/types/lib/api/v1/CampaignSummary'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import styled, { css, keyframes, useTheme } from 'styled-components'

import IconRefresh from './refresh.svg?react'

import { CampaignSummaryResponse, getCampaignSummary, postCampaignSummary } from 'api/campaignSummary'
import { QUERY_CACHE } from 'constants/query-cache'
import { useCampaignDetails } from 'hooks/useCampaign'
import { useClientId } from 'hooks/useClient'
import Sentry from 'integrations/Sentry'
import analytics from 'utils/analytics'
import dayjs from 'utils/dayjs'

export type CampaignSummaryProps = {
  campaignId: string
}

const StyledRegenerateButton = styled.button`
  color: ${({ theme }) => theme.COLORS.SUBTEXT};
  background: none;
  padding: 0;
  margin: 0;
  appearance: none;
  border: 0;
  font-weight: inherit;
  font-size: inherit;
  cursor: pointer;
  margin-left: ${SPACING[2]};
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledIconRefresh = styled(IconRefresh)<{ $loading?: boolean }>`
  margin-right: ${SPACING[1]};
  animation: ${({ $loading }) =>
    $loading
      ? css`
          ${rotate} 2s linear infinite
        `
      : 'none'};
`

const StyledText = styled.div`
  font-size: ${FONT_SIZE[3]};
  line-height: 20px;
  margin: 0;
  color: ${({ theme }) => theme.COLORS.TEXT};

  ul {
    padding-left: 20px;
  }
`

export function CampaignSummary({ campaignId }: CampaignSummaryProps): JSX.Element | null {
  const { t } = useTranslation()
  const { COLORS } = useTheme()
  const clientId = useClientId()
  const [campaignSummary, setCampaignSummary] = useState<CampaignSummaryResponse['data']['campaignSummary']>()

  const { data: campaignDetails, isInitialLoading } = useCampaignDetails({ campaignId })

  const shouldAllowRegenerate = useMemo(() => {
    if (!campaignDetails) {
      return false
    }

    const now = dayjs()
    const campaignSentAt = dayjs(campaignDetails.scheduledAt || campaignDetails.createdAt)

    const isCampaignOlderThanOneMonth = now.diff(campaignSentAt, 'month') >= 1

    if (isCampaignOlderThanOneMonth) {
      return false
    }

    return true
  }, [campaignDetails])

  const mutation = useMutation({
    mutationKey: [QUERY_CACHE.CAMPAIGN_SUMMARY, { clientId, campaignId }],
    mutationFn: () => postCampaignSummary({ clientId, campaignId }),
    onSuccess: ({ data }) => setCampaignSummary(data?.campaignSummary),
  })

  const query = useQuery({
    queryKey: [QUERY_CACHE.CAMPAIGN_SUMMARY, { clientId, campaignId }],
    queryFn: () => getCampaignSummary({ clientId, campaignId }),
    enabled: Boolean(clientId && campaignId),
    onSuccess: ({ data }) => setCampaignSummary(data?.campaignSummary),
    onError: (err: ApiError) => {
      // if we try to retrieve the campaign summary and we get a 404, we call the create summary mutation
      // to create a summary. Future requests to the query should return 200 with an in_progress status.
      if (err.status === 404 && err.response) {
        err.response
          .clone()
          .json()
          .then((json) => {
            if (json.data.campaign_summary.status === CampaignSummaryStatus.NOT_FOUND) {
              mutation.mutate()

              analytics.track(analytics.events.GPT.AICampaignSummaryAutoGenerate())
            }
          })
          .catch(Sentry.captureException)
      }
    },
    refetchOnWindowFocus: false,
  })

  useInterval(
    () => {
      query.refetch()
    },
    1000,
    {
      enabled: campaignSummary?.status === CampaignSummaryStatus.IN_PROGRESS,
    },
  )

  const loading = mutation.isLoading || campaignSummary?.status === CampaignSummaryStatus.IN_PROGRESS

  let lastModified: Date | undefined
  if (campaignSummary?.updatedAt) {
    lastModified = new Date(campaignSummary.updatedAt)
  }

  const text = campaignSummary?.text

  if (!campaignSummary && !loading) {
    return null
  }

  if (campaignSummary?.status === CampaignSummaryStatus.INELIGIBLE) {
    return null
  }

  return (
    <Layout data-testid="campaign-summary" marginBottom={SPACING[4]} paddingLeft={SPACING[4]} paddingRight={SPACING[4]}>
      <Layout
        backgroundColor={COLORS.APP_BACKGROUND_LEVEL_3}
        border={`1px solid ${COLORS.BORDERS}`}
        borderRadius={BORDER_RADIUS[1]}
        padding={`${SPACING[2]} ${SPACING[4]}`}
        width="100%"
      >
        <Layout alignItems="center" display="flex" marginBottom={SPACING[1]}>
          <AiBubble isPlaying={loading} style={{ width: 24, height: 24 }} />
          <Layout marginLeft={SPACING[2]}>
            <Typography component="h3" fontWeight="600" margin="0" variant="body1">
              {t('campaignSummary.campaignSummary')}
            </Typography>
          </Layout>
        </Layout>

        <Skeleton
          height="12px"
          loading={isInitialLoading || !lastModified}
          marginBottom="12px"
          marginTop="8px"
          width="33%"
        >
          <Typography color={COLORS.SUBTEXT} fontSize={FONT_SIZE[1]} fontWeight="bold" margin="0" variant="body2">
            {lastModified &&
              t('campaignSummary.updatedOn', {
                date: dayjs(lastModified).calendar(),
              })}
            {shouldAllowRegenerate && (
              <StyledRegenerateButton
                disabled={loading}
                onClick={() => {
                  mutation.mutate()

                  analytics.track(analytics.events.GPT.AICampaignSummaryButtonPressed())
                }}
              >
                <StyledIconRefresh $loading={loading} />
                {!loading && t('campaignSummary.regenerate')}
              </StyledRegenerateButton>
            )}
          </Typography>
        </Skeleton>

        <Layout marginBottom="8px" marginTop={SPACING[2]} width="100%">
          <Skeleton
            data-testid="campaign-summary-loading"
            display="block"
            height="20px"
            loading={loading}
            marginBottom={SPACING[2]}
            marginTop={SPACING[2]}
            width="100%"
          >
            {text && (
              <StyledText>
                <Markdown>
                  {text.replace('Key takeaways', '\n\nKey takeaways').replace('Recommendation', '\n\nRecommendation')}
                </Markdown>
              </StyledText>
            )}
          </Skeleton>

          <Skeleton
            display="block"
            height="20px"
            loading={loading}
            marginBottom={SPACING[2]}
            marginTop={SPACING[2]}
            width="90%"
          />
        </Layout>
      </Layout>
    </Layout>
  )
}
