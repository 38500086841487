import { useBillingClient } from './billing/useBillingClient'
import { useSubscription } from './billing/useSubscription'
import { useClientContexts, useSelectedClientContext } from './useClient'

export const useIsAccountInactive = (): { isAccountInactive: boolean; isLoading: boolean } => {
  const { isLoading: isContextLoading } = useClientContexts()
  const context = useSelectedClientContext()
  const { data: billingClient, isInitialLoading: isBillingClientLoading } = useBillingClient()
  const { data: subscription, isSuccess, isInitialLoading: isSubscriptionLoading } = useSubscription()
  const hasSubscription = subscription && isSuccess
  const isLoading = isContextLoading || isBillingClientLoading || isSubscriptionLoading

  // The billingClient.accountStatus is used to determined if we allow the user into the app or if we show them a contact us screen
  // This value can be manually set in admin in the client/billing tab
  // This value can also be automatically - ex: the user goes through the dunning cycle and fails to pay
  if (billingClient && billingClient.accountStatus === 'inactive') return { isAccountInactive: true, isLoading }

  // We also want to show the contact us page when billing required is true, there is no billing provided and there is no subscription
  // if there is a subscription, we want to give them the ability to input billing info hence why we check for a subscription
  // For more info on isBillingActive, look in the getContexts function in contexts.ts, it is derived by the billingRequired and billingProvided values

  // It is important to check here for the existence of context
  // For impersonated user the context will be undefined and we do not want to show the contact us page in that case
  if (context && !context.isBillingActive && !hasSubscription) return { isAccountInactive: true, isLoading }

  // If the subscription has no billing_provider and isBillingActive is false, show the account inactive contact us page
  // For usage plans that have a subscription but no credit card provider
  if (context && !context.isBillingActive && !subscription?.billingProvider)
    return { isAccountInactive: true, isLoading }

  return { isAccountInactive: false, isLoading }
}
