import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import identity from 'lodash/identity'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { OnboardingTask } from 'generators'
import { request } from 'utils/api'
import { formatPhone } from 'utils/normalize'
import { route } from 'utils/router'

export type OnboardingMessage = CamelCasedPropertiesDeep<Api.OnboardingMessage>

type OnboardingTasks = {
  tasks: Tasks
  complete: boolean
}

enum TaskNames {
  VOICEMAIL = 'Voicemail',
  CONFIRMATION_MESSAGE = 'Confirmation Message',
  UPDATE_NAME = 'Update Name',
  RESPOND_AT_SCALE = 'Respond at Scale',
  WELCOME_MESSAGE = 'Welcome Message',
  LINK_TRACKING = 'Link Tracking',
  CONTACT_PHOTO = 'Contact Photo',
}

type Tasks = {
  [taskName in TaskNames]: CamelCasedPropertiesDeep<Api.OnboardingTask>
}

export type Client = Omit<CamelCasedPropertiesDeep<Api.Client>, 'onboardingTasks'> & {
  graphicUrl: string
  graphicUrlSmall: string
  onboardingTasks: OnboardingTasks
  phone: string
  phoneNumber: string
  fullName: string
}

export function getClient(token?: string): Promise<Client> {
  return request(ENDPOINTS.CLIENT, { token }).then(({ data: client }: CamelCasedPropertiesDeep<Api.ClientResponse>) => {
    const complete: any[] = []
    const tasks = client.onboardingTasks.reduce((acc, task: OnboardingTask) => {
      if (task.status === 'complete' || task.description === '') complete.push(task.title)
      acc[task.title] = task
      return acc
    }, {} as Tasks)

    const names = [client.firstName, client.lastName].filter((x) => !!x).map((x) => x.trim())

    return {
      ...client,
      graphicUrl: client.profileImage?.url,
      graphicUrlSmall: client.profileImageSmall?.url,
      phone: formatPhone(client.masterPhoneNumber),
      phoneNumber: client.masterPhoneNumber,
      firstName: names[0] || '',
      fullName: names.join(' '),
      onboardingTasks: {
        tasks,
        complete: complete.length === client.onboardingTasks.length,
      },
    }
  })
}

export function putClientContactPhoto({ body }: any): Promise<any> {
  return request('/client-dashboard/settings/profile-image', {
    body,
    camelCase: false,
    method: HTTP.PUT,
    defaultHeaders: false,
    serialize: identity,
    // we need for s3 to receive the updated picture.
  }).then(() => new Promise((resolve) => setTimeout(resolve, 3000)))
}

export type ClientSettings = CamelCasedPropertiesDeep<Api.ClientSettings>

export const getClientSettings = (clientId: string): Promise<ClientSettings> =>
  request(route(ENDPOINTS.SETTINGS.CLIENT_SETTINGS, { clientId })).then(({ data }) => data)

export const patchClientSettings = ({ clientId, body }: { clientId: string; body: any }): Promise<any> =>
  request(route(ENDPOINTS.SETTINGS.CLIENT_SETTINGS, { clientId }), {
    method: HTTP.PATCH,
    body,
  }).then(({ data }) => data)
