import { BUTTON_VARIANTS, Button } from '@community_dev/pixels'
import { useState } from 'react'
import { useTheme } from 'styled-components'

type CopyButtonProps = {
  content: string
  short?: boolean
}

export const CopyButton = ({ content, short }: CopyButtonProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const animationTimeout = 300
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), animationTimeout)
      })
      .catch((e) => console.error(e))
  }

  const text = short ? 'Copy' : 'Copy Code'

  return (
    <Button color={COLORS?.SUBTEXT} disabled={copied} onClick={handleCopy} variant={BUTTON_VARIANTS.OUTLINE}>
      {copied ? 'Copied' : text}
    </Button>
  )
}
