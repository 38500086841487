import React, { createContext, ReactNode, useContext, useState } from 'react'

import {
  IncludeExcludeRecord,
  IncludeExcludeState,
  addFanSubscriptionId,
  isRootSelected as isRootSelectedIncludeExclude,
  isSelected as isSelectedIncludeExclude,
  recordChildMapping as recordChildMappingIncludeExclude,
  toggle as toggleIncludeExclude,
  toggleRoot as toggleRootIncludeExclude,
  SelectionState,
  KeyPath,
} from './IncludeExcludeState'

import { DebugViewer } from 'components/DebugViewer'
import { CAPABILITIES } from 'constants/capabilities'
import { useHasCapability } from 'hooks/useUserCapability'

// <IncludeExcludeProvider />
//
// Given a set of key paths that may be nested up to a single level, the
// IncludeExcludeProvider allows those key paths to be included or excluded
// via a simple set of rules. The bulk of the selection manipulation logic is
// encapsulated via the IncludeExcludeState module, which provides both a
// data structure for represening these inclusion and exclusion rules, and
// a set of tested utility functions for performing operations on this data
// structure.
//
// The IncludeExcludeProvider is responsible for:
// - maintaining a piece of state in which to hold a set of include/exclude
//   rules, represented via an IncludeExcludeState
// - providing an API to consuming components that allows them to manipulate
//   the IncludeExcludeState via the IncludeExcludeState.modifier() functions,
//   without having to directly hold a reference to a copy of it.

type IncludeExcludeContextValue = {
  isSelected: (keyPath: KeyPath) => SelectionState
  isRootSelected: () => SelectionState
  toggleSelected: (keyPath: KeyPath, type: string, repliedTo: boolean) => void
  toggleRoot: () => void
  recordChildMapping: (parentId: string, childIds: string[]) => void
  resetState: () => void
  includeExcludeState: IncludeExcludeRecord
}

const defaultState = IncludeExcludeState()

export const IncludeExcludeContext = createContext<IncludeExcludeContextValue | null>(null)

IncludeExcludeContext.displayName = 'IncludeExcludeContext'

type IncludeExcludeProviderProps = {
  children?: ReactNode
}

export const IncludeExcludeProvider = ({ children }: IncludeExcludeProviderProps): JSX.Element => {
  const [currentState, setState] = useState(defaultState)

  const isSelected = (keyPath: KeyPath) => isSelectedIncludeExclude(currentState, keyPath)

  const toggleSelected = (keyPath, type, repliedTo) => {
    const [clusterId, fanSubscriptionId] = keyPath
    setState(
      toggleIncludeExclude(addFanSubscriptionId(currentState, fanSubscriptionId, repliedTo, clusterId), keyPath, type),
    )
  }

  const toggleRoot = () => setState(toggleRootIncludeExclude(currentState))

  const isRootSelected = () => isRootSelectedIncludeExclude(currentState)

  const recordChildMapping = (parentId, childIds) =>
    setState(recordChildMappingIncludeExclude(currentState, parentId, childIds))

  // This context *should* be rendered at a low enough level that it gets re-mounted/reset
  // when the page changes (i.e. moving from one thread to another on the Sent screen).
  // This would eliminate the need for manual resets, since this local state would be blown
  // away on changes.
  //
  // Unfortunately, due to macro-level application architecture issues like the top-level
  // FilterProvider and custom route-change handling on the SentThread componentDidUpdate
  // callback, this isn't possible and we have to provide this "escape hatch" to allow
  // for downstream components to _manually_ reset the application-global state of this
  // context.
  //
  // It is my hope that this is no longer necessary at some point in the future.
  const resetState = () => {
    setState(IncludeExcludeState())
  }

  const value = {
    isSelected,
    isRootSelected,
    toggleSelected,
    toggleRoot,
    recordChildMapping,
    resetState,
    includeExcludeState: currentState,
  }

  const showDebugViewer = useHasCapability(CAPABILITIES.DEBUG.INCLUDE_EXCLUDE_DEBUGGER)

  return (
    <IncludeExcludeContext.Provider value={value}>
      {showDebugViewer && <DebugViewer debugValue={currentState.toJS()} title="[debug] IncludeExcludeState" />}
      {children}
    </IncludeExcludeContext.Provider>
  )
}

export const useIncludeExcludeContext = (): IncludeExcludeContextValue => {
  const context = useContext(IncludeExcludeContext)

  if (!context) {
    throw new Error('useIncludeExcludeContext must be used within a IncludeExcludeContext')
  }

  return context
}
