import { InfiniteData, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'

import { useUpdateInbox } from './useUpdateInbox'

import { Chat, updateChat } from 'api/messages'
import { QUERY_CACHE } from 'constants/query-cache'
import { useInbox } from 'contexts/InboxProvider'
import { useClientId } from 'hooks/useClient'
import { InboxItemPaged } from 'hooks/useInboxMessages'
import { ApiResponse } from 'types/api'

type UseUpdateChatReturn = UseMutationResult<
  ApiResponse<Chat>,
  unknown,
  {
    fanSubscriptionId: string
    fanId: string
    chat: {
      has_new: boolean
    }
  },
  {
    previousChats: InfiniteData<InboxItemPaged> | undefined
  }
>

export function useUpdateChat(): UseUpdateChatReturn {
  const clientId = useClientId()

  const { filters, searchTerm, searchTab } = useInbox()

  const updateInbox = useUpdateInbox()

  const queryClient = useQueryClient()

  const queryKey = [QUERY_CACHE.CLIENT_DASHBOARD_CHATS, filters, searchTerm, searchTab, clientId]

  return useMutation({
    mutationFn: ({
      fanSubscriptionId,
      chat,
    }: {
      fanSubscriptionId: string
      fanId: string
      chat: { has_new: boolean }
    }) => {
      return updateChat({
        clientId,
        fanSubscriptionId: fanSubscriptionId,
        chat,
      })
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: queryKey })

      const previousChats = queryClient.getQueryData<InfiniteData<InboxItemPaged>>(queryKey)

      updateInbox(variables.fanId, { unread: variables.chat.has_new })

      return { previousChats }
    },
    onError: (err, variables, context) => {
      if (context?.previousChats) {
        queryClient.setQueryData(queryKey, context.previousChats)
      }
    },
  })
}
