import { FeatureBanner, MessageCountIcon } from '@community_dev/pixels'
import { useCallback, useEffect, useRef, useState } from 'react'

import { ROUTES } from 'constants/routes'
import { useTrackBilling } from 'hooks/billing/useTrackBilling'
import { useClusteringEnabled } from 'hooks/useClusteringEnabled'
import events from 'utils/analytics/events'

const MIN_WIDTH = 335

export const ClusteringBanner = (): JSX.Element | null => {
  const containerRef = useRef<HTMLDivElement>(null)
  const hasClustering = useClusteringEnabled()
  const isEnabled = !hasClustering
  const [shouldShow, setShouldShow] = useState(isEnabled)
  const track = useTrackBilling()

  const handleResize = useCallback(() => {
    if (!isEnabled) {
      return
    }

    const width = containerRef.current?.clientWidth
    if (width && width < MIN_WIDTH) {
      setShouldShow(false)
    } else {
      setShouldShow(true)
    }
  }, [isEnabled])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  return (
    <div ref={containerRef}>
      {shouldShow && (
        <FeatureBanner
          appearance="dark"
          headline="Respond faster at scale"
          href={ROUTES.PRICING.CONTACT_US}
          icon={<MessageCountIcon />}
          name="Intelligent message clustering"
          onClick={() => {
            track(events.FeatureHoldPrompt({ featureName: 'clustering' }))
          }}
          target="_blank"
        />
      )}
    </div>
  )
}
