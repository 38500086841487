import numeral from 'numeral'

export const pluralizeText = (count: string, singular: string, plural?: string): string => {
  const ct = parseInt(count, 10)
  const abs = Math.abs(ct)
  if (!(abs >= 0)) return ''
  if (abs === 1) return `${singular}`.trim()

  return (plural || `${singular}s` || '').trim()
}

const pluralize = (count: string, singular: string, plural: string): string => {
  const ct = parseInt(count, 10)
  const abs = Math.abs(ct)
  if (!(abs >= 0)) return ''
  if (abs === 1) return `${ct} ${singular}`.trim()
  return `${ct} ${plural || `${singular}s` || ''}`.trim()
}

export const pluralizeNumeral = (count: string, format: string, singular: string, plural: string): string => {
  const ct = parseInt(count, 10)
  if (!(Math.abs(ct) >= 0)) return ''
  const num = numeral(ct).format(format)
  return pluralize(ct.toString(), singular, plural).replace(ct.toString(), num)
}

export const formatNumeral = (value: string | number, format = '0,0'): string => numeral(value).format(format)
