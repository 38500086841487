import { BUTTON_VARIANTS, Button, CLEAVE_DATE_PATTERN_OPTIONS, SPACING } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cleave from 'cleave.js/react'
import noop from 'lodash/noop'
import moment from 'moment'
import { useState } from 'react'
import { DayPickerRangeController } from 'react-dates'
import { END_DATE, START_DATE } from 'react-dates/constants'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { useSearchState } from '../SearchProvider'

import { DATE_INPUT_FORMAT } from 'constants/date'

const StyledCalendar = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  margin: 0 0 ${SPACING[4]};
`

const StyledDateInputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${SPACING[4]} ${SPACING[4]};
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`

type StyledInputType = {
  $focused: boolean
  $invalid: boolean
}
const StyledInput = styled(Cleave)<StyledInputType>`
  outline: none;
  ${({ $focused, $invalid }) =>
    $focused
      ? css`
          border: 2px solid ${({ theme }) => ($invalid ? theme?.COLORS?.ERRORS : theme?.COLORS?.LINKS)};
        `
      : css`
          border: 1px solid ${({ theme }) => ($invalid ? theme?.COLORS?.ERRORS : theme?.COLORS?.BORDERS)};
        `}
  width: 132px;
  padding: 12px 16px;
  border-radius: 4px;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

const StyledArrowRightIcon = styled(FontAwesomeIcon)`
  margin: 0 ${SPACING[4]};
`

const StyledNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  &:hover {
    background: rgba(51, 51, 51, 0.04);
  }
`

const StyledNavPrev = styled(StyledNav)`
  left: 22px;
`

const StyledNavNext = styled(StyledNav)`
  right: 22px;
`

const StyledNavPrevIcon = styled(FontAwesomeIcon)`
  height: 10px !important;
`

const StyledNavNextIcon = styled(FontAwesomeIcon)`
  height: 10px !important;
  transform: rotate(180deg);
`

const StyledButtonCancel = styled(Button)`
  margin: 0 ${SPACING[2]} 0 0;
`

type SearchDayPickerRangeProps = {
  className?: string
  onClickCancel?(): void
  onClickSetDate?(): void
  autoFocus?: boolean
  numberOfMonths?: number
  [key: string]: any
}

// Using moment in this file since its what the react-dates library expects
export function SearchDayPickerRange({
  className,
  onClickCancel = noop,
  onClickSetDate = noop,
  autoFocusEndDate,
  numberOfMonths = 2,
  ...props
}: SearchDayPickerRangeProps): JSX.Element {
  const { t } = useTranslation()
  const { changeParams, start, end } = useSearchState()
  const [focusedInput, setFocusedInput] = useState(autoFocusEndDate ? END_DATE : START_DATE)
  const [values, setValues] = useState({
    startDate: start,
    endDate: end,
  })
  const minDate = moment().subtract(90, 'days').startOf('day')
  const maxDate = moment()

  const handleDatesChange = ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => {
    const dates = {
      ...values,
      ...(startDate && {
        startDate: moment(startDate).startOf('day').toISOString(),
      }),
      ...(endDate && { endDate: moment(endDate).endOf('day').toISOString() }),
    }

    setValues(dates)
  }

  const handleClickSetDate = () => {
    if (onClickSetDate) {
      onClickSetDate()
    }

    if (!isDisabled) {
      changeParams({
        start: values.startDate,
        end: values.endDate,
      })
    }
  }

  const handleFocusStart = () => {
    setFocusedInput(START_DATE)
  }

  const handleBlurStart = (event) => {
    handleDatesChange({ startDate: new Date(event.target.value) })
  }

  const handleKeyDownStart = (event) => {
    if (event.key === 'Enter' && !isDisabled) {
      handleDatesChange({ startDate: new Date(event.target.value) })
    }
  }

  const handleFocusEnd = () => {
    setFocusedInput(END_DATE)
  }

  const handleBlurEnd = (event) => {
    handleDatesChange({ endDate: new Date(event.target.value) })
  }

  const handleKeyDownEnd = (event) => {
    if (event.key === 'Enter' && !isDisabled) {
      handleDatesChange({ endDate: new Date(event.target.value) })
    }
  }

  const handleFocusChange = (newFocusedInput) => {
    setFocusedInput(!newFocusedInput ? START_DATE : newFocusedInput)
  }

  const isBeforeLast90DaysOrAfterToday = (date) => {
    const isBeforeLast90Days = moment(date).isBefore(minDate)
    const isAfterToday = moment(date).isAfter(maxDate, 'day')

    return isBeforeLast90Days || isAfterToday
  }

  const isStartDateInvalid = isBeforeLast90DaysOrAfterToday(values.startDate)
  const isEndDateInvalid = isBeforeLast90DaysOrAfterToday(values.endDate)
  const isDisabled = isStartDateInvalid || isEndDateInvalid

  return (
    <div className={className} data-testid="search-day-picker-range">
      <StyledCalendar>
        <DayPickerRangeController
          disabled={isDisabled}
          enableOutsideDays
          endDate={values.endDate && moment(values.endDate)}
          focusedInput={focusedInput}
          isOutsideRange={isBeforeLast90DaysOrAfterToday}
          maxDate={maxDate}
          minDate={minDate}
          minimumNights={0}
          navNext={
            <StyledNavNext>
              <StyledNavNextIcon icon="chevron-left" />
            </StyledNavNext>
          }
          navPrev={
            <StyledNavPrev>
              <StyledNavPrevIcon icon="chevron-left" />
            </StyledNavPrev>
          }
          numberOfMonths={numberOfMonths}
          onDatesChange={handleDatesChange}
          onFocusChange={handleFocusChange}
          startDate={values.startDate && moment(values.startDate)}
          {...props}
        />
      </StyledCalendar>
      <StyledDateInputs data-testid="date-inputs">
        <div>
          <StyledInput
            $focused={focusedInput === START_DATE}
            $invalid={isStartDateInvalid}
            aria-label="start-date"
            name="startDate"
            onBlur={handleBlurStart}
            onFocus={handleFocusStart}
            onKeyDown={handleKeyDownStart}
            options={CLEAVE_DATE_PATTERN_OPTIONS}
            placeholder={t('mmddyyyy')}
            value={values.startDate ? moment(values.startDate).format(DATE_INPUT_FORMAT) : ''}
          />
          <StyledArrowRightIcon icon="arrow-right" />
          <StyledInput
            $focused={focusedInput === END_DATE}
            $invalid={isEndDateInvalid}
            aria-label="end-date"
            name="endDate"
            onBlur={handleBlurEnd}
            onFocus={handleFocusEnd}
            onKeyDown={handleKeyDownEnd}
            options={CLEAVE_DATE_PATTERN_OPTIONS}
            placeholder={t('mmddyyyy')}
            value={values.endDate ? moment(values.endDate).format(DATE_INPUT_FORMAT) : ''}
          />
        </div>
        <div>
          <StyledButtonCancel onClick={onClickCancel} variant={BUTTON_VARIANTS.OUTLINE}>
            {t('cancel')}
          </StyledButtonCancel>
          <Button disabled={isDisabled} onClick={handleClickSetDate}>
            {t('search.setDate')}
          </Button>
        </div>
      </StyledDateInputs>
    </div>
  )
}
