import { MessageBillingUsage, useMessageBillingUsage } from '@community_dev/hooks'
import { SpeechIcon, TCPA_MESSAGE } from '@community_dev/pixels'
import { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EditMessage, EditMessageIsSaveDisabledProps, EditMessageValues } from './EditMessage'
import { ExpandableList } from './EditMessage/ExpandableList'

import { useIsReplyYOn } from 'hooks/useIsReplyYOn'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import { generalConfig } from 'utils/config'

const CONFIRMATION_MESSAGE_ASSETS = `${generalConfig.staticUrl}/animation-confirmation-message.gif`

const REPLY_Y_CONFIRMATION_MESSAGE_ASSETS = [
  `${generalConfig.staticUrl}/integrations/embeds/ReplyYSignUpPreview%403x.png 3x`,
  `${generalConfig.staticUrl}/integrations/embeds/ReplyYSignUpPreview%402x.png 2x`,
  `${generalConfig.staticUrl}/integrations/embeds/ReplyYSignUpPreview.png 1x`,
]

type EditSignUpConfirmationMessageProps = {
  charMax?: number
  className?: string
  defaultValue?: string
  messageBillingUsage?: MessageBillingUsage
  messageBubbleContents?: ReactNode
  segmentMax?: number
  tcpaMessage?: string
  onSave: (values: EditMessageValues) => void
}

const hasDoubleTCPALanguage = (currentValue) => {
  return /HELP/.test(currentValue) || /STOP/.test(currentValue)
}

const isSaveDisabled = ({
  currentValue,
  defaultValue,
  messageBillingUsage,
  pristine,
  submitting,
  valid,
}: EditMessageIsSaveDisabledProps) => {
  const { charCount, charsAllowed, segmentCount, segmentsAllowed } = messageBillingUsage
  const trimmedValue = currentValue.trim()
  if (!valid || submitting) return true
  if (trimmedValue.length === 0) return true

  // If there is a segment limit, we check for both
  if (segmentsAllowed && charsAllowed) {
    if (segmentCount > segmentsAllowed || charCount > charsAllowed) {
      return true
    }
  } else if (charsAllowed && charCount > charsAllowed) {
    return true
  }

  if (defaultValue === trimmedValue && pristine) return true
  return false
}

export const EditSignUpConfirmationMessage = ({
  charMax,
  className,
  defaultValue = '',
  messageBubbleContents,
  segmentMax,
  tcpaMessage = TCPA_MESSAGE,
  onSave,
}: EditSignUpConfirmationMessageProps): JSX.Element => {
  const { t } = useTranslation()
  const isReplyYOn = useIsReplyYOn()
  const [message, setMessage] = useState(defaultValue)
  const { links } = useShortenableLinks(message)
  const messageBillingUsage = useMessageBillingUsage({
    charMax,
    linksToShorten: links,
    message,
    messageSuffix: tcpaMessage,
    segmentMax,
  })
  const doubleTCPAPrompt = useMemo(
    () => ({
      title: t('editConfirmationMessage.duplicateLanguagePromptTitle'),
      message: t('editConfirmationMessage.duplicateLanguagePromptMessage'),
      cancelLabel: t('cancel'),
      saveLabel: t('next'),
      shouldPromptWhen: hasDoubleTCPALanguage,
    }),
    [t],
  )
  const saveConfirmationPrompt = useMemo(
    () => ({
      title: t('settings.saveChanges'),
      message: t('settings.willAffectSignUpMessages'),
      cancelLabel: t('cancel'),
      saveLabel: t('save'),
    }),
    [t],
  )

  const prompts = [doubleTCPAPrompt, saveConfirmationPrompt]

  return (
    <EditMessage
      alt={t('editConfirmationMessage.imageAlt')}
      className={className}
      copy={t('editConfirmationMessage.copy')}
      defaultValue={defaultValue}
      header={t('editConfirmationMessage.header')}
      hint={
        <ExpandableList
          Icon={SpeechIcon}
          defaultOpen={false}
          list={[t('editConfirmationMessage.listItem1')]}
          title={t('editConfirmationMessage.listTitle')}
        />
      }
      isSaveDisabled={isSaveDisabled}
      message={message}
      messageBillingUsage={messageBillingUsage}
      messageBubbleContents={messageBubbleContents}
      onSave={onSave}
      prompts={prompts}
      setMessage={setMessage}
      src={isReplyYOn ? REPLY_Y_CONFIRMATION_MESSAGE_ASSETS : CONFIRMATION_MESSAGE_ASSETS}
      tcpaMessage={tcpaMessage}
    />
  )
}
