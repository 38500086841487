import { useMutation } from '@tanstack/react-query'
import { useRef, useState } from 'react'

import { postForgotPassword } from 'api/login'

const getErrorMessage = (err: any): string => {
  if (err.name === 'ApiError' && err.status === 500) {
    return 'Our systems are currently experiencing trouble, try again in a few seconds'
  }
  if (err.name === 'ApiError' && err.status === 429) {
    return err.errors[0].detail as string
  }

  return 'Unknown error'
}

type ForgotPasswordReturn = {
  error: string
  success: string
  submit: any
  isLoading: boolean
  passwordFieldRef: any
}

export const useForgotPassword = (): ForgotPasswordReturn => {
  const passwordFieldRef = useRef<any>(null)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const { mutate, isLoading } = useMutation(postForgotPassword, {
    onError(err) {
      setError(getErrorMessage(err))
      setSuccess('')
    },
  })

  const submit = ({ email, restart }) => {
    mutate(
      { email },
      {
        onSuccess() {
          setError('')
          setSuccess('Email sent! Check your inbox')
          restart()
          // we're not forwarding TextInput refs but make it available
          // on the react component itself hence the long object path
          passwordFieldRef.current?.inputRef?.current?.focus?.()
        },
      },
    )
  }

  return {
    error,
    success,
    submit,
    passwordFieldRef,
    isLoading,
  }
}
