import { Api } from '@community_dev/types'
import { FanRegistrationCoreFieldName } from '@community_dev/types/lib/api/FanRegistration'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { useClientSettingsQuery } from './useClientSettingsQuery'

type SelectField = CamelCasedPropertiesDeep<Api.FanRegistration.SelectField>
type TextField = CamelCasedPropertiesDeep<Api.FanRegistration.TextField>
type DateField = CamelCasedPropertiesDeep<Api.FanRegistration.DateField>
type EmailField = CamelCasedPropertiesDeep<Api.FanRegistration.EmailField>

type FieldTypeMap = {
  [FanRegistrationCoreFieldName.GENDER_NAME]: SelectField
  [FanRegistrationCoreFieldName.FIRST_NAME]: TextField
  [FanRegistrationCoreFieldName.LAST_NAME]: TextField
  [FanRegistrationCoreFieldName.CITY_NAME]: TextField
  [FanRegistrationCoreFieldName.EMAIL_NAME]: EmailField
  [FanRegistrationCoreFieldName.BIRTHDAY_NAME]: DateField
}

export const useFanRegistrationField = <T extends FanRegistrationCoreFieldName>(
  fieldName: T,
): FieldTypeMap[T] | undefined => {
  const { data: field } = useClientSettingsQuery<FieldTypeMap[T]>({
    select: (data: { fanRegistrationFields }) => {
      return data.fanRegistrationFields?.find((field) => field.name === fieldName)
    },
  })

  return field
}
