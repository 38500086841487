import { HTTP } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { AnalyticsEvent } from 'utils/analytics/events'
import { request } from 'utils/api'
import { route } from 'utils/router'

export enum EmbedPathRuleSelectType {
  included = 'included',
  excluded = 'excluded',
}

export enum EmbedPathRuleMatchType {
  contains = 'contains',
  exact = 'exact',
}

export type EmbedPathRule = {
  selectType: EmbedPathRuleSelectType
  matchType: EmbedPathRuleMatchType
  path: string
}

export type JsonConfig = {
  displayType: 'inline' | 'popup'
  backgroundColor: string
  headline: string
  headlineTextColor: string
  subtext: string
  subtextTextColor: string
  buttonBackgroundColor: string
  buttonTextColor: string
  launchDelayInSeconds: number
  phoneNumber: string
  name: string
  pathRules: EmbedPathRule[]
}

export enum EmbedStatus {
  published = 'published',
  draft = 'draft',
}

export type Embed = {
  id?: string
  status: EmbedStatus
  subscriptionConfirmationMessage: string | null
  jsonConfig: JsonConfig
}

export function getClientEmbeds(clientId: string): Promise<Embed[]> {
  const requestRoute = route(ENDPOINTS.MGU.EMBEDS, {
    clientId,
  })

  return request(requestRoute, { method: HTTP.GET })
}

type EmbedPostParams = {
  clientId: string
  embed: Embed
}

export function postClientEmbed(params: EmbedPostParams): Promise<Embed> {
  const { embed, ...routeParams } = params
  const requestRoute = route(ENDPOINTS.MGU.EMBEDS, routeParams)

  return request(requestRoute, { method: HTTP.POST, body: embed })
}

type EmbedTrackPostParams = {
  embedId: string
  clientId: string
  event: AnalyticsEvent
}

export function postTrackEmbed(params: EmbedTrackPostParams): Promise<void> {
  const { event, ...routeParams } = params
  const requestRoute = route(ENDPOINTS.MGU.TRACK, routeParams)
  const options = {
    method: HTTP.POST,
    body: event,
  }
  return request(requestRoute, options)
}

type EmbedPutParams = {
  embedId: string
  clientId: string
  embed: Partial<Embed>
}

export function putClientEmbed(params: EmbedPutParams): Promise<Embed> {
  const { embed, ...routeParams } = params
  const requestRoute = route(ENDPOINTS.MGU.EMBED, routeParams)

  return request(requestRoute, { method: HTTP.PUT, body: embed })
}

type EmbedDeleteParams = {
  embedId: string
  clientId: string
}

export function deleteClientEmbed(params: EmbedDeleteParams): Promise<void> {
  const requestRoute = route(ENDPOINTS.MGU.EMBED, params)
  return request(requestRoute, { method: HTTP.DELETE })
}
