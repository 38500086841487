import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { ClusterRepliedState } from '@community_dev/types/lib/api/v2/Clusters'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { Fan } from 'api/fans'
import { ChainBubble } from 'components/ChainBubble'
import EmptyResponse from 'containers/Chain/EmptyResponse'
import { ConvoModal } from 'containers/ConvoModal/ConvoModal'
import { canReceiveCampaigns } from 'hooks/useCanSendDms'
import dayjs from 'utils/dayjs'

type ClusterThreadItemsProps = {
  items?: any[]
  onMemberSelect?: (fan: Fan) => void
}

export const ClusterThreadItems = React.memo(({ items, onMemberSelect }: ClusterThreadItemsProps) => {
  const { t } = useTranslation()
  const [viewingFanConvo, setViewingFanConvo] = useState<null | string>(null)
  const { selectedFilter }: any = useParams()

  return (
    <>
      {viewingFanConvo && <ConvoModal fanId={viewingFanConvo} onClose={() => setViewingFanConvo(null)} />}
      {!items?.length ? (
        <EmptyResponse />
      ) : (
        items.reduce(
          (accumulator, item) => {
            const { communication_channel: communicationChannel, state_flags: stateFlags } = item
            const itemTimeStamp = item.inserted_at && dayjs(item.inserted_at)
            const prevTimeStamp = accumulator?.timeStamp
            const shouldShowTime = !prevTimeStamp || itemTimeStamp.diff(prevTimeStamp, 'hours', true) < -1

            const fan = item.fan

            const allowSelection = canReceiveCampaigns({ communicationChannel, stateFlags })

            const disabledAmbReason =
              !allowSelection && communicationChannel === CommunicationChannel.AMB
                ? t('convo.ambMessagingDisabled')
                : undefined

            return {
              ...accumulator,
              timeStamp: item.inserted_at,
              bubbles: [
                ...accumulator.bubbles,
                <ChainBubble
                  allowSelection={allowSelection}
                  body={item.text}
                  disabledSelectionReason={disabledAmbReason}
                  fan={fan}
                  fanSubscriptionId={fan.fanSubscriptionId}
                  incoming
                  key={fan.fanSubscriptionId}
                  onTitleClick={onMemberSelect}
                  openConvoModal={() => setViewingFanConvo(fan.id)}
                  repliedTo={selectedFilter === ClusterRepliedState.REPLIED}
                  showTime={shouldShowTime}
                  showUser
                  title={fan.fullName}
                  ts={item.inserted_at}
                />,
              ],
            }
          },
          {
            timeStamp: null,
            bubbles: [],
          },
        ).bubbles
      )}
    </>
  )
})
