import { DropdownIndicator, Layout, Option, SPACING, Select } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { CAPABILITIES } from 'constants/capabilities'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useHasCapability } from 'hooks/useUserCapability'

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${SPACING[1]};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
`

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: ${SPACING[3]};

  .select-control {
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.COLORS.BORDERS};
    background-color: ${({ theme }) => theme.COLORS.INPUT_BACKGROUND};
    height: 42px;
    min-height: auto;
    width: auto;
    min-width: auto;
  }
`

const StyledSubTitle = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

type MediaDispositionOption = {
  label: string
  subtitle: string
  value: MediaDisposition
}

type MediaDispositionSelectProps = {
  mediaDisposition: MediaDisposition
  setMediaDisposition: Dispatch<SetStateAction<MediaDisposition>>
}

export const MediaDispositionSelect = ({
  mediaDisposition,
  setMediaDisposition,
}: MediaDispositionSelectProps): JSX.Element | null => {
  const { t } = useTranslation()
  const { communicationChannel } = useFilters()
  const { file } = useCompose()
  const hasMms = useHasCapability(CAPABILITIES.FEATURE.MMS_MESSAGING.ALL)

  const mediaDispositionOptions: MediaDispositionOption[] = useMemo(() => {
    return [
      {
        label: t('settings.customize.media.attachmentOption'),
        subtitle: t('settings.customize.media.attachmentDescription'),
        value: MediaDisposition.ATTACHMENT,
      },
      {
        value: MediaDisposition.LINK,
        subtitle: t('settings.customize.media.linkDescription'),
        label: t('settings.customize.media.linkOption'),
      },
    ]
  }, [t])

  const selectedMediaDispositionOption = useMemo(() => {
    return mediaDispositionOptions.find(({ value }) => value === mediaDisposition)
  }, [mediaDisposition, mediaDispositionOptions])

  if (communicationChannel !== CommunicationChannel.SMS || !hasMms || !file) {
    return null
  }

  return (
    <Layout marginBottom={SPACING[4]}>
      <StyledLabel htmlFor="media-disposition">
        <span>{t('settings.customize.media.sendMediaAs')}</span>
      </StyledLabel>
      <StyledSelect
        aria-label={t('settings.customize.media.sendMediaAs')}
        classNames={{
          control: () => 'select-control',
        }}
        components={{
          DropdownIndicator,
          IndicatorSeparator: null,
          Option: (props) => (
            <Option {...props}>
              <Layout display="flex" flexDirection="column">
                {props.children}
                <StyledSubTitle>{(props.data as MediaDispositionOption).subtitle}</StyledSubTitle>
              </Layout>
            </Option>
          ),
        }}
        defaultValue={mediaDispositionOptions[1]}
        id="media-disposition"
        inline={false}
        onChange={(option: unknown) => {
          option !== null && setMediaDisposition((option as MediaDispositionOption).value)
        }}
        options={mediaDispositionOptions}
        value={selectedMediaDispositionOption}
      />
    </Layout>
  )
}
