import { BUTTON_VARIANTS, Button, SPACING } from '@community_dev/pixels'
import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'

const CopyButton = styled(Button)`
  margin-top: ${SPACING[5]};
`

const HiddenInput = styled.input`
  position: absolute;
  left: -999em;
`

type CopyFooterProps = {
  value: string
  onDone: (...args: any[]) => any
  onCopy: (...args: any[]) => any
  copyButtonLabel: string
}
export const CopyFooter = ({ value, onDone, onCopy, copyButtonLabel }: CopyFooterProps): JSX.Element => {
  const copyRef = useRef<HTMLInputElement>(null)
  const shouldCopy = useCallback(() => {
    copyRef.current?.select()
    document.execCommand('copy')
    onCopy(value)
  }, [value, onCopy, copyRef])

  return (
    <div>
      <Button onClick={onDone} variant={BUTTON_VARIANTS.ACTION} width="100%">
        Got it
      </Button>
      <CopyButton onClick={shouldCopy} variant={BUTTON_VARIANTS.LINK} width="100%">
        {copyButtonLabel}
      </CopyButton>
      <HiddenInput readOnly ref={copyRef} value={value} />
    </div>
  )
}
