import { MemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { ApiError } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { QueryKey, UseQueryOptions, UseQueryResult, useQueries, useQuery } from '@tanstack/react-query'

import { postCountByQuery } from 'api/fan-count'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClient, useClientId } from 'hooks/useClient'

export type CountByQueryFnReturn = {
  filter: MemberDataFilter
  count: number
}

type CountByQueryArgs<SelectData = CountByQueryFnReturn> = {
  filters: MemberDataFilter | null
  traceId?: string
  clientId: string
  communicationChannel?: CommunicationChannel
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

export const toCountByQueryKey = (
  filters: MemberDataFilter | null,
  communicationChannel: CommunicationChannel,
  clientId: string,
): QueryKey => {
  return [QUERY_CACHE.SUBSCRIPTION_DATA.COUNT, { clientId, filters, communicationChannel }]
}

const toCountByQuery = <SelectData = CountByQueryFnReturn>({
  filters,
  traceId,
  clientId,
  communicationChannel = CommunicationChannel.SMS,
  options,
}: CountByQueryArgs<SelectData>) => {
  return {
    queryKey: toCountByQueryKey(filters, communicationChannel, clientId),
    queryFn: () =>
      postCountByQuery({
        filters: filters as MemberDataFilter,
        communicationChannel,
        clientId,
        traceId,
      }).then((data) => ({ filter: filters, count: data })) as Promise<CountByQueryFnReturn>,
    staleTime: STALE_TIME.FIVE_MINUTES,
    enabled: Boolean(filters),
    refetchOnWindowFocus: false,
    ...options,
  }
}

type UseCountByQueryArgs<SelectData = CountByQueryFnReturn> = {
  filters: MemberDataFilter | null
  traceId?: string
  communicationChannel?: CommunicationChannel
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

// For a single count request, use this hook
export function useCountByQuery<SelectData = CountByQueryFnReturn>({
  filters,
  traceId,
  communicationChannel,
  options,
}: UseCountByQueryArgs<SelectData>): UseQueryResult<SelectData, ApiError> {
  const clientId = useClientId()
  return useQuery<CountByQueryFnReturn, ApiError, SelectData>(
    toCountByQuery<SelectData>({ filters, traceId, clientId, communicationChannel, options }),
  )
}

type UseCountByQueriesArgs<SelectData = CountByQueryFnReturn> = {
  filters: MemberDataFilter[]
  traceId?: string
  communicationChannel?: CommunicationChannel
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

// For multiple count requests, use this hook
export const useCountByQueries = <SelectData = CountByQueryFnReturn>({
  filters,
  traceId,
  communicationChannel,
  options,
}: UseCountByQueriesArgs<SelectData>): UseQueryResult<SelectData, ApiError>[] => {
  const clientId = useClientId()
  return useQueries({
    queries: filters.map((filter) =>
      toCountByQuery<SelectData>({ filters: filter, traceId, clientId, communicationChannel, options }),
    ),
  }) as UseQueryResult<SelectData, ApiError>[]
}

const COMMUNICATION_CHANNELS = [CommunicationChannel.SMS, CommunicationChannel.WHATS_APP, CommunicationChannel.AMB]

type UseMultiChannelCountByQueryArgs<SelectData = CountByQueryFnReturn> = {
  filters: MemberDataFilter
  traceId?: string
  communicationChannels?: CommunicationChannel[]
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

// For 1 filter with multiple communication channels, use this hook
export const useMultiChannelCountByQuery = <SelectData = CountByQueryFnReturn>({
  filters,
  traceId,
  communicationChannels = [CommunicationChannel.SMS],
  options = {},
}: UseMultiChannelCountByQueryArgs<SelectData>): Record<CommunicationChannel, UseQueryResult<SelectData, ApiError>> => {
  const { data: client } = useClient()
  const { communicationChannels: clientCommunicationChannels = [] } = client || {}

  const communicationChannelsEnabled = COMMUNICATION_CHANNELS.reduce((acc, channel) => {
    if (communicationChannels.includes(channel)) {
      const explicitlyDisabled = options.enabled === false
      if (explicitlyDisabled) {
        return { ...acc, [channel]: false }
      }
      if (!clientCommunicationChannels?.includes(channel)) {
        return { ...acc, [channel]: false }
      }
      return { ...acc, [channel]: true }
    }
    return { ...acc, [channel]: false }
  }, {} as Record<CommunicationChannel, boolean>)

  const communicationChannelQueries = {
    [CommunicationChannel.SMS]: useCountByQuery({
      filters,
      traceId,
      communicationChannel: CommunicationChannel.SMS,
      options: { ...options, enabled: communicationChannelsEnabled[CommunicationChannel.SMS] },
    }),
    [CommunicationChannel.WHATS_APP]: useCountByQuery({
      filters,
      traceId,
      communicationChannel: CommunicationChannel.WHATS_APP,
      options: { ...options, enabled: communicationChannelsEnabled[CommunicationChannel.WHATS_APP] },
    }),
    [CommunicationChannel.AMB]: useCountByQuery({
      filters,
      traceId,
      communicationChannel: CommunicationChannel.AMB,
      options: { ...options, enabled: communicationChannelsEnabled[CommunicationChannel.AMB] },
    }),
  }

  return communicationChannelQueries as Record<CommunicationChannel, UseQueryResult<SelectData, ApiError>>
}
