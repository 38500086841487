import { useCallback } from 'react'
import { toast, ToastPosition } from 'react-hot-toast'
import type { Renderable } from 'react-hot-toast'

type ToastMessageProps = {
  message: string | Renderable
  success?: boolean
  maxWidth?: string | number
  position?: ToastPosition
}

export function useToastMessage(): {
  showToastMessage: (props: ToastMessageProps) => void
} {
  const showToastMessage = useCallback(
    ({ message, success = true, maxWidth, position = 'top-right' }: ToastMessageProps): void => {
      toast[success ? 'success' : 'error'](message, { style: { maxWidth }, position })
    },
    [],
  )

  return { showToastMessage }
}
