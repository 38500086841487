import pick from 'lodash/fp/pick'
import React, { createContext, useContext } from 'react'

import { UseSearch, useSearch } from './useSearch'
import {
  SEARCH_SELECTION_STATE,
  SearchSelectionProps,
  UseSearchSelection,
  useSearchSelection,
} from './useSearchSelection'

function getSelectedNumberOfMembers({ selectionState, selection, searchResults, totalResults }) {
  switch (selectionState) {
    case SEARCH_SELECTION_STATE.PARTIALLY_SELECTED:
      return selection.length
    case SEARCH_SELECTION_STATE.EVERYONE_SELECTED:
      return searchResults?.data.results.length || 0
    case SEARCH_SELECTION_STATE.ENTIRE_QUERY_SELECTED:
      return totalResults
    default:
      return 0
  }
}

const pickSearchParams = pick([
  'search',
  'start',
  'end',
  'exact',
  'allMembers',
  'page',
  'sort',
  'communicationChannel',
]) as (obj: UseSearch) => SearchSelectionProps

type UseSearchContext = UseSearch &
  UseSearchSelection & {
    selectedNumberOfMembers: number
    totalResults: number
  }

export const SearchContext = createContext<UseSearchContext | null>(null)

SearchContext.displayName = 'SearchContext'

export function useSearchState(): UseSearchContext {
  const context = useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useSearchState must be used within a SearchProvider')
  }

  return context as UseSearchContext
}

export function SearchProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const search = useSearch()
  const selection = useSearchSelection(pickSearchParams(search))
  const totalResults = search.searchResults?.data.totalSubscriptions || 0
  const selectedNumberOfMembers = getSelectedNumberOfMembers({
    selectionState: selection.selectionState,
    selection: selection.selected,
    searchResults: search.searchResults,
    totalResults,
  })

  return (
    <SearchContext.Provider value={{ selectedNumberOfMembers, totalResults, ...search, ...selection }}>
      {children}
    </SearchContext.Provider>
  )
}
