import React from 'react'
import styled from 'styled-components'

const StyledMain = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100%;
  min-width: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;
  padding: 18px 16px 44px;
`

const StyledTitle = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledSubtitle = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  padding-top: 2px;
`

const StyledGlyph = styled.div`
  padding-bottom: 10px;
`

const StyledButton = styled.div`
  padding-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;

  > a {
    display: flex;
    width: 100%;
    max-width: 280px;
    color: inherit;
    text-decoration: inherit;
  }

  .Button {
    width: 100%;
    max-width: 280px;
  }
`

const StyledEmpty = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

type EmptyBlockProps = {
  title?: string
  subtitle?: string
  button?: React.ReactNode
  glyph?: React.ReactNode
}

function EmptyBlock({ glyph, title, subtitle, button }: EmptyBlockProps): JSX.Element {
  return (
    <StyledEmpty>
      <StyledMain>
        {glyph && <StyledGlyph>{glyph}</StyledGlyph>}
        {title && <StyledTitle>{title}</StyledTitle>}
        {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
        {button && <StyledButton>{button}</StyledButton>}
      </StyledMain>
    </StyledEmpty>
  )
}

export default EmptyBlock
