import queryString from 'query-string'
import { useEffect } from 'react'
import { toast, Toaster, ToastBar, resolveValue } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { Toast as ToastComponent } from 'components/base'
import en from 'languages/en.json'

const AppToast = (): JSX.Element | null => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    const { integration_status_code: integrationStatusCode, ...rest } = queryString.parse(location.search)
    const message = t(`toasts.${integrationStatusCode as keyof typeof en.toasts}`)
    if (integrationStatusCode && message !== `toasts.${integrationStatusCode}`) {
      if ((integrationStatusCode as string).toLowerCase().includes('success')) {
        toast.success(message)
      } else {
        toast.error(message)
      }
      history.replace(`${location.pathname}?${queryString.stringify(rest)}`)
    }
  }, [location, t, history])

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 5000,
        }}
      >
        {(t) => {
          return (
            <ToastBar style={{ padding: 0 }} toast={t}>
              {() => (
                <ToastComponent
                  icon
                  key={t.id}
                  maxWidth={t.style?.maxWidth}
                  message={resolveValue(t.message, t)}
                  onDismiss={() => toast.remove(t.id)}
                  success={t.type === 'success'}
                />
              )}
            </ToastBar>
          )
        }}
      </Toaster>
    </>
  )
}

export default AppToast
