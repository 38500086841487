import { InfoIconFilled, SPACING } from '@community_dev/pixels'
import isNumber from 'lodash/isNumber'
import styled from 'styled-components'

import { MessagesLimits } from 'constants/messages'
import { pluralizeNumeral } from 'utils/general'

const StyledContent = styled.button`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  padding: 7px ${SPACING[2]};
  border: 0;
  background: transparent;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  flex-grow: 1;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme?.COLORS?.DEPRECATED_HOVER};
  }
`

const StyledIconContainer = styled.span`
  vertical-align: middle;
`

const InfoIcon = () => (
  <StyledIconContainer>
    <InfoIconFilled size={16} />
  </StyledIconContainer>
)

type MessagesRemainingProps = {
  monthlyRemaining: number | null
  hasWarningThresholdReached?: boolean
  hasLimitReached?: boolean
  onClick: (payload: any) => void
}

export const MessagesRemaining = ({
  monthlyRemaining,
  hasWarningThresholdReached,
  hasLimitReached,
  onClick,
}: MessagesRemainingProps): JSX.Element | null => {
  const handleClick = (): void => {
    onClick({
      monthlyRemaining,
      hasWarningThresholdReached,
      hasLimitReached,
    })
  }

  if (hasLimitReached) {
    return (
      <StyledContent onClick={handleClick}>
        Message up to {MessagesLimits.THROTTLED_LIMIT_TEXT} recipients <InfoIcon />
      </StyledContent>
    )
  }

  if (hasWarningThresholdReached && isNumber(monthlyRemaining)) {
    const countLabel = pluralizeNumeral(monthlyRemaining.toString(), '0,0', 'message', 'messages')

    return (
      <StyledContent onClick={handleClick}>
        {countLabel} left <InfoIcon />
      </StyledContent>
    )
  }

  return null
}
