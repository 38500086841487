import {
  DropdownArrowIcon,
  ExclamationCircle,
  FONT_SIZE,
  Layout,
  Skeleton,
  SPACING,
  Typography,
} from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { formatCurrency } from '../utils/formatCurrency'

import {
  StyledCell,
  StyledHeaderCell,
  StyledHeaderRow,
  StyledRow,
  StyledSecondaryRow,
  StyledTable,
} from 'components/SettingsTable/SettingsTable.style'
import { useMonthlyUsageQuery } from 'hooks/useMonthlyUsageQuery'
import { SETTINGS_SCROLL_CONTAINER_ID } from 'screens/SettingsScreen/SettingsScreen'
import dayjs from 'utils/dayjs'

const StyledAnimatedIcon = styled.button<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(-90deg)')};
  transition: transform 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
`

const LoadingCell = ({
  header,
  bolded,
  children,
  indented,
  loading,
  align = 'left',
}: {
  header?: boolean
  bolded?: boolean
  children?: React.ReactNode
  indented?: boolean
  loading?: boolean
  align?: 'left' | 'right'
}): JSX.Element => {
  const CellRender = header ? StyledHeaderCell : StyledCell
  return (
    <CellRender $align={align} $bolded={bolded}>
      <Layout display="inline-block" marginLeft={indented ? SPACING[4] : '0'}>
        {loading ? <Skeleton height="20px" loading width="80px" /> : children}
      </Layout>
    </CellRender>
  )
}

type LineItemProps = CamelCasedPropertiesDeep<Api.Billing.LineItem>
const LineItem = ({ item, quantity, rate, total, messageTypes }: LineItemProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasMessageTypes = Boolean(messageTypes?.length)
  const isRowOpen = isOpen && hasMessageTypes
  const shouldBeBolded = Boolean(messageTypes?.length)
  return (
    <>
      <StyledRow>
        <StyledCell $bolded={shouldBeBolded}>
          <Layout alignItems="center" display="flex">
            {hasMessageTypes ? (
              <StyledAnimatedIcon isOpen={isRowOpen} onClick={() => setIsOpen((prev) => !prev)}>
                <DropdownArrowIcon />
              </StyledAnimatedIcon>
            ) : null}
            {item}
          </Layout>
        </StyledCell>
        <StyledCell $align="right" $bolded={shouldBeBolded}>
          {quantity.toLocaleString()}
        </StyledCell>
        <StyledCell $align="right" $bolded={shouldBeBolded}>
          {formatCurrency(rate)}
        </StyledCell>
        <StyledCell $align="right" $bolded={shouldBeBolded}>
          {formatCurrency(total)}
        </StyledCell>
      </StyledRow>
      {isRowOpen
        ? messageTypes?.map((message, index) => (
            <StyledSecondaryRow key={index}>
              <StyledCell>
                <Layout marginLeft="26px">{message.label}</Layout>
              </StyledCell>
              <StyledCell $align="right">{message.count.toLocaleString()}</StyledCell>
              <StyledCell colSpan={2}></StyledCell>
            </StyledSecondaryRow>
          ))
        : null}
    </>
  )
}

const LineItemLoading = (): JSX.Element => {
  return (
    <StyledRow>
      <LoadingCell loading />
      <LoadingCell align="right" loading />
      <LoadingCell align="right" loading />
      <LoadingCell align="right" loading />
    </StyledRow>
  )
}

export const EstimatedInvoice = ({ className }: { className?: string }): JSX.Element => {
  const { COLORS } = useTheme()
  const { t } = useTranslation(undefined, { keyPrefix: 'settings.billing.termedInvoice.segmentBasedBilling' })
  const [today] = useState(dayjs())
  const { data: monthlyUsage, isInitialLoading, isError } = useMonthlyUsageQuery()

  // We want to go to the top of the settings page when this loads
  useEffect(() => {
    const settingsContainer = document.getElementById(SETTINGS_SCROLL_CONTAINER_ID)
    if (settingsContainer) {
      settingsContainer.scrollTo(0, 0)
    }
  }, [])

  return (
    <div className={className}>
      <Typography margin={`0 0 ${SPACING[5]} 0`} variant="h3">
        {t('estimatedInvoiceMonth', { month: today.format('MMMM') })}
      </Typography>
      {isError ? (
        <Layout alignItems="center" display="flex">
          <ExclamationCircle color={COLORS.LOGO} size={FONT_SIZE[4]} />{' '}
          <Typography marginLeft={SPACING[2]}>{t('error.currentlyUnavailable')}</Typography>
        </Layout>
      ) : (
        <>
          <StyledTable style={{ width: '100%', textAlign: 'left', marginBottom: 0 }}>
            <thead>
              <StyledHeaderRow>
                <LoadingCell align="left" header loading={isInitialLoading}>
                  {t('usageTable.header.activity')}
                </LoadingCell>
                <LoadingCell align="right" header loading={isInitialLoading}>
                  {t('usageTable.header.quantity')}
                </LoadingCell>
                <LoadingCell align="right" header loading={isInitialLoading}>
                  {t('usageTable.header.rate')}
                </LoadingCell>
                <LoadingCell align="right" header loading={isInitialLoading}>
                  {t('usageTable.header.total')}
                </LoadingCell>
              </StyledHeaderRow>
            </thead>
            <tbody>
              {monthlyUsage?.lineItems?.map((item, index) => (
                <LineItem key={index} {...item} />
              ))}
              {isInitialLoading ? (
                <>
                  <LineItemLoading />
                  <LineItemLoading />
                  <LineItemLoading />
                  <LineItemLoading />
                </>
              ) : null}
              {monthlyUsage?.messagesSubtotal ? (
                <StyledRow>
                  <StyledCell $bolded>
                    <Layout marginLeft={SPACING[4]}>{t('usageTable.row.subtotalMessageFees')}</Layout>
                  </StyledCell>
                  <StyledCell colSpan={2}></StyledCell>
                  <StyledCell $align="right" $bolded>
                    {formatCurrency(monthlyUsage.messagesSubtotal)}
                  </StyledCell>
                </StyledRow>
              ) : null}
              {monthlyUsage?.totalCreditsUsed ? (
                <StyledRow>
                  <StyledCell $bolded>
                    <Layout marginLeft={SPACING[4]}>{t('usageTable.row.totalCreditsUsed')}</Layout>
                  </StyledCell>
                  <StyledCell colSpan={2}></StyledCell>
                  <StyledCell $align="right" $bolded>
                    - {formatCurrency(monthlyUsage?.totalCreditsUsed)}
                  </StyledCell>
                </StyledRow>
              ) : null}
              <StyledRow>
                <LoadingCell bolded indented loading={isInitialLoading}>
                  {t('usageTable.row.platformFee')}
                </LoadingCell>
                <StyledCell colSpan={2}></StyledCell>
                <LoadingCell align="right" bolded loading={isInitialLoading}>
                  {formatCurrency(monthlyUsage?.platformFee || 0)}
                </LoadingCell>
              </StyledRow>
              <StyledRow>
                <LoadingCell bolded loading={isInitialLoading}>
                  {t('usageTable.row.estimatedTotal')}
                </LoadingCell>
                <StyledCell colSpan={2}></StyledCell>
                <LoadingCell align="right" bolded loading={isInitialLoading}>
                  {formatCurrency(monthlyUsage?.total || 0, 2)}
                </LoadingCell>
              </StyledRow>
            </tbody>
          </StyledTable>
          <Layout marginTop={SPACING[8]}>
            <Typography whiteSpace="pre-line">
              <Trans i18nKey="settings.billing.termedInvoice.segmentBasedBilling.estimatedInvoiceDisclaimer" />
            </Typography>
          </Layout>
        </>
      )}
    </div>
  )
}
