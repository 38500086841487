import { ChevronRightIcon } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { useHasCapability } from 'hooks/useUserCapability'
import { Setting, SettingsSection } from 'layouts/SettingsLayout'

const StyledButtonLabelContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

export const UsageBasedActivitySection = (): JSX.Element | null => {
  const { COLORS } = useTheme() || {}
  const history = useHistory()
  const { t } = useTranslation()

  const isSegmentBasedBilling = useHasCapability(CAPABILITIES.FEATURE.SEGMENT_BILLING.ALL)

  if (!isSegmentBasedBilling) {
    return null
  }

  return (
    <SettingsSection>
      <Setting
        action={
          <StyledButtonLabelContainer>
            {t('settings.billing.termedInvoice.segmentBasedBilling.viewDetails')}
            <ChevronRightIcon color={COLORS?.SUBTEXT} />
          </StyledButtonLabelContainer>
        }
        label={t('settings.billing.termedInvoice.segmentBasedBilling.usageBasedBilling')}
        onClickAction={() => history.push(ROUTES.SETTINGS.BILLING_USAGE)}
        text={t('settings.billing.termedInvoice.segmentBasedBilling.subHeader')}
      />
    </SettingsSection>
  )
}
