import { Layout, MOBILE_BREAK, SPACING, Typography } from '@community_dev/pixels'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header } from '../Header'

import backfill from './backfill.png'
import setLogo from './set-logo.png'

import { SETTINGS_LAYOUT } from 'layouts/HorizontalTabLayout/constants'

const StyledRoot = styled.div`
  height: 100vh;
  width: 100%;
  max-width: ${SETTINGS_LAYOUT.MAX_WIDTH};
  margin: auto;
  padding: 32px;
`

const StyledImage = styled.img`
  margin: 0;
  padding: 0;
  height: 60px;
  margin-top: ${SPACING[3]};
  margin-bottom: ${SPACING[3]};

  @media (max-width: ${MOBILE_BREAK}) {
    max-width: 100%;
  }
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const StyledList = styled.ol`
  line-height: 1.75;
`

export const SetLiveInstructions = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <StyledRoot>
      <Header title="Connect SET.live" />
      <StyledImage src={setLogo} />
      <Layout paddingBottom={SPACING[5]}>
        <Typography fontSize="18px" variant="h2">
          {t('settings.integrations.setLive.forNewUsers')}
        </Typography>
        <p>
          <Trans i18nKey="settings.integrations.setLive.forNewUsersInstructions">
            <StyledLink href="https://signup.set.live" rel="noopener noreferrer" target="_blank" />
          </Trans>
        </p>
        <Typography fontSize="18px" variant="h2">
          {t('settings.integrations.setLive.forExistingUsers')}
        </Typography>
        <p>{}</p>
        <StyledList>
          <li>
            <Trans i18nKey="settings.integrations.setLive.goToSetPortal">
              <StyledLink href="https://artists.set.live" rel="noopener noreferrer" target="_blank" />
            </Trans>
          </li>
          <li>{t('settings.integrations.setLive.chooseTheArtist')}</li>
          <li>{t('settings.integrations.setLive.goToSettings')}</li>
          <li>{t('settings.integrations.setLive.scrollDown')}</li>
        </StyledList>
        <p>
          <Trans i18nKey="settings.integrations.setLive.tip" />
        </p>
        <StyledImage src={backfill} />
      </Layout>
    </StyledRoot>
  )
}
