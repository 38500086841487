import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export const useGoBack = (defaultPathToGoBack: string): (() => void) => {
  const history = useHistory()

  const goBack = useCallback(() => {
    if (history.location.key) {
      history.goBack()
    } else {
      // If the previous page is from outside the app, go to the default path
      history.push(defaultPathToGoBack)
    }
  }, [history, defaultPathToGoBack])
  return goBack
}
