import { DigitsFilter } from '@community_dev/filter-dsl/lib/digits'
import { Api } from '@community_dev/types'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { postIndexByQuery } from 'api/community'
import { QUERY_CACHE } from 'constants/query-cache'

export type UseAudienceQueryOptions = {
  filters?: DigitsFilter
  sorters?: { id: string; desc: boolean }[]
  tags?: string[]
  communicationChannel?: CommunicationChannel
}

export function useAudienceQuery({
  filters,
  sorters,
  tags,
  communicationChannel,
}: UseAudienceQueryOptions = {}): UseInfiniteQueryResult<CamelCasedPropertiesDeep<Api.Fan>, unknown> {
  return useInfiniteQuery({
    queryKey: [QUERY_CACHE.AUDIENCE.INDEX_BY_QUERY, { filters, sorters, tags, communicationChannel }],
    queryFn: ({ pageParam = 1 }) => postIndexByQuery({ filters, sorters, tags, communicationChannel, page: pageParam }),
    getNextPageParam: (lastPage) =>
      lastPage?.paginationData?.hasNextPage ? (lastPage.paginationData.pageNumber || 0) + 1 : undefined,
    getPreviousPageParam: () => undefined,
    select: (data) => ({
      ...data,
      pages: data.pages.flatMap((page) => page.data),
    }),
  })
}
