import {
  AgeMatch,
  BirthdayMatch,
  CampaignRecipientsMatch,
  CommunityExcludesSingleMatch,
  CommunityIncludesSingleMatch,
  CustomMemberDataRangeMatch,
  CustomMemberDataSingleMatch,
  EveryoneMatch,
  findAll,
  findOne,
  GenderMatch,
  isLogicalNode,
  isMemberDataFilter,
  JoinedMatch,
  LocationMatch,
  MemberDataFilter,
  MostActiveMembersMatch,
  NameMatch,
  NewMemberMatch,
  oneOf,
  parseFilters,
  SelectorFilter,
  serializeFilters,
  SubscriberIncludesMatch,
  SubscriberIncludesSingleMatch,
  TimeZoneGroupMatch,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { useMemo } from 'react'

export type UseActiveFilterTypesArgs = {
  filters: MemberDataFilter | null
}

export type ActiveFilters = {
  cmd: MemberDataFilter[]
  age: MemberDataFilter | null
  birthday: MemberDataFilter | null
  communitiesIncluded: SelectorFilter[]
  communitiesExcluded: SelectorFilter[]
  everyone: MemberDataFilter | null
  gender: MemberDataFilter | null
  joinDate: MemberDataFilter | null
  location: MemberDataFilter | null
  mostActiveMembersPercent: MemberDataFilter | null
  name: MemberDataFilter | null
  newMember: MemberDataFilter | null
  subscribersIncluded: MemberDataFilter | null
  subscribersIncludedIndividualFilters: SelectorFilter[]
  timezone: MemberDataFilter | null
  campaignRecipients: MemberDataFilter | null
}

export function useActiveFilterTypes(args: UseActiveFilterTypesArgs): ActiveFilters {
  return useMemo((): ActiveFilters => {
    const filtersAst = args.filters ? parseFilters(args.filters) : null

    const selectedFilters = {
      cmd: Array.from(findAll(filtersAst, oneOf(CustomMemberDataRangeMatch, CustomMemberDataSingleMatch)))
        .map(serializeFilters)
        .filter(isMemberDataFilter),
      age: serializeFilters(findOne(filtersAst, AgeMatch)),
      birthday: serializeFilters(findOne(filtersAst, BirthdayMatch)),
      communitiesIncluded: Array.from(findAll(filtersAst, CommunityIncludesSingleMatch))
        .filter(isLogicalNode)
        .map((node) => node.data.filter),
      communitiesExcluded: Array.from(findAll(filtersAst, CommunityExcludesSingleMatch))
        .filter(isLogicalNode)
        .map((node) => node.data.filter),
      everyone: serializeFilters(findOne(filtersAst, EveryoneMatch)),
      gender: serializeFilters(findOne(filtersAst, GenderMatch)),
      joinDate: serializeFilters(findOne(filtersAst, JoinedMatch)),
      location: serializeFilters(findOne(filtersAst, LocationMatch)),
      mostActiveMembersPercent: serializeFilters(findOne(filtersAst, MostActiveMembersMatch)),
      name: serializeFilters(findOne(filtersAst, NameMatch)),
      newMember: serializeFilters(findOne(filtersAst, NewMemberMatch)),
      subscribersIncluded: serializeFilters(findOne(filtersAst, SubscriberIncludesMatch)),
      subscribersIncludedIndividualFilters: Array.from(findAll(filtersAst, SubscriberIncludesSingleMatch))
        .filter(isLogicalNode)
        .map((node) => node.data.filter),
      timezone: serializeFilters(findOne(filtersAst, TimeZoneGroupMatch)),
      campaignRecipients: serializeFilters(findOne(filtersAst, CampaignRecipientsMatch)),
    }

    return selectedFilters
  }, [args.filters])
}
