import {
  MemberDataFilter,
  FilterAst,
  RelationshipOperators,
  findOne,
  getFieldKey,
  getRoot,
  insertNode,
  isComparisonNode,
  parseFilters,
  removeNode,
  serializeFilters,
  CustomMemberDataRangeMatch,
  JoinedRangeMatch,
  AgeRangeMatch,
  objectContains,
  replaceNode,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import noop from 'lodash/noop'
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { PartialDeep } from 'type-fest'

import { Fan } from 'api/fans'
import { useFilterSupportMatrix } from 'components/ComposeMessage/hooks/useFilterSupportMatrix'
import analytics from 'utils/analytics'

const RANGE_MATCHERS = [CustomMemberDataRangeMatch, JoinedRangeMatch, AgeRangeMatch]

export type FilterSelectionType = 'includes' | 'excludes'

export const initialState: FilterContextValue = {
  addFilter: noop,
  previewNextFilter: () => null,
  removeFilter: noop,
  updateFilter: noop,
  individuals: [],
  addIndividual: noop,
  removeIndividual: noop,
  setIndividuals: noop,
  setIncludedFilters: noop,
  setExcludedFilters: noop,
  setBaseFilters: noop,
  baseFilters: null,
  includedFilters: null,
  excludedFilters: null,
  filters: null,
  communicationChannel: CommunicationChannel.SMS,
  setCommunicationChannel: noop,
  includedFiltersAst: null,
  excludedFiltersAst: null,

  activeSubtree: null,
  activeSubtreeFilters: null,
  setActiveSubtree: noop,
}

export type FilterContextValue = {
  addFilter(filter: MemberDataFilter, type?: FilterSelectionType): void
  previewNextFilter(filter: MemberDataFilter, type?: FilterSelectionType): MemberDataFilter | null
  removeFilter(filter: MemberDataFilter, subtree?: FilterAst | null, type?: FilterSelectionType): void
  updateFilter: (
    previousFilter: PartialDeep<MemberDataFilter>,
    nextFilter: MemberDataFilter,
    subtree: FilterAst | null,
    type?: FilterSelectionType,
  ) => void

  individuals: Fan[]
  addIndividual: (fan: Fan) => void
  removeIndividual: (id: string) => void
  setIndividuals: Dispatch<SetStateAction<Fan[]>>

  setIncludedFilters: Dispatch<SetStateAction<MemberDataFilter | null>>
  setExcludedFilters: Dispatch<SetStateAction<MemberDataFilter | null>>
  setBaseFilters: Dispatch<SetStateAction<MemberDataFilter | null>>

  includedFilters: MemberDataFilter | null
  excludedFilters: MemberDataFilter | null

  filters: MemberDataFilter | null
  baseFilters: MemberDataFilter | null

  communicationChannel: CommunicationChannel
  setCommunicationChannel: React.Dispatch<React.SetStateAction<CommunicationChannel>>

  includedFiltersAst: FilterAst | null
  excludedFiltersAst: FilterAst | null

  activeSubtree: FilterAst | null
  activeSubtreeFilters: MemberDataFilter | null
  setActiveSubtree: Dispatch<SetStateAction<FilterAst | null>>
}

export const FilterContext = React.createContext<FilterContextValue>(initialState)

FilterContext.displayName = 'FilterContext'

export type UseFilterProps = {
  initialState?: {
    communicationChannel?: CommunicationChannel
    individuals?: Fan[]
    includedFilters?: MemberDataFilter | null
    excludedFilters?: MemberDataFilter | null
    baseFilters?: MemberDataFilter | null
  }
}

export function useFilters({ initialState = {} }: UseFilterProps = {}): FilterContextValue {
  const context = useContext(FilterContext)
  const { setIncludedFilters, setExcludedFilters, setIndividuals, setCommunicationChannel, setBaseFilters } = context

  useEffect(() => {
    if (initialState.individuals) {
      setIndividuals(initialState.individuals)
    }

    if (initialState.includedFilters) {
      setIncludedFilters(initialState.includedFilters)
    }

    if (initialState.excludedFilters) {
      setExcludedFilters(initialState.excludedFilters)
    }
    if (initialState.communicationChannel) {
      setCommunicationChannel(initialState.communicationChannel)
    }
    if (initialState.baseFilters) {
      setBaseFilters(initialState.baseFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider')
  }

  return context
}

export type FilterProviderProps = {
  children?: React.ReactNode
  // mainly used for initializing state in unit tests
  individuals?: Fan[]
  includedFilters?: MemberDataFilter | null
  excludedFilters?: MemberDataFilter | null
  baseFilters?: MemberDataFilter | null
  communicationChannel?: CommunicationChannel
}

// Special case for adding RANGE filters
// This is for when a user adds a range filter first and then adds more filters.
// A range filter is already a comparison node, so when we add another filter to it, we first need to wrap it in an AND node before we can add the new filter.
const toWrappedRangeFilter = (currentTree: FilterAst): FilterAst | null => {
  const currentFilters = serializeFilters(currentTree)
  const isRangeFilter = RANGE_MATCHERS.some((matcher) => objectContains(matcher, currentFilters))

  if (isRangeFilter && currentFilters) {
    const newRangeFilter = parseFilters({ operator: RelationshipOperators.AND, operands: [currentFilters] })
    // Check for null because parseFilters can return null
    return newRangeFilter ? replaceNode(currentTree, newRangeFilter) : currentTree
  }

  return currentTree
}

// For certain types of filters, we need to modify the tree when adding more filters
const toModifiedTreeBeforeAdding = (currentTree: FilterAst | null): FilterAst | null => {
  if (!currentTree) {
    return null
  }

  // This should only occur when a range filter has been added first and then another filter will be added next to it
  return toWrappedRangeFilter(currentTree)
}

//This is used to combine the base and included filters into a single filter
const toBaseAndIncludedFilters = (baseFilters: MemberDataFilter | null, includedFilters: MemberDataFilter | null) => {
  if (baseFilters && includedFilters) {
    return {
      operator: RelationshipOperators.AND,
      operands: [baseFilters, includedFilters],
    }
  }

  return includedFilters || baseFilters || null
}

// This is used to combine the base, included and excluded filters into a single filter
const toFilters = (
  includedFilters: MemberDataFilter | null,
  excludedFilters: MemberDataFilter | null,
  baseFilters: MemberDataFilter | null,
) => {
  const baseAndIncludedFilters = toBaseAndIncludedFilters(baseFilters, includedFilters)

  if (baseAndIncludedFilters && excludedFilters) {
    return {
      operator: RelationshipOperators.AND,
      operands: [baseAndIncludedFilters, { operator: RelationshipOperators.NOT, operands: [excludedFilters] }],
    }
  }

  return baseAndIncludedFilters || excludedFilters || null
}

// This function decides what tree to use, what operator to use, and adds a new filter to the tree
// THIS MUTATES THE TREE
const addFilterToAst = (
  type: FilterSelectionType,
  includedFiltersAst: FilterAst | null,
  excludedFiltersAst: FilterAst | null,
  activeSubtree: FilterAst | null,
  filter: MemberDataFilter,
) => {
  const root = getRoot(type === 'includes' ? includedFiltersAst : excludedFiltersAst)

  const currentTree = activeSubtree || root
  const currentActiveTree = toModifiedTreeBeforeAdding(currentTree)

  const operator = activeSubtree ? RelationshipOperators.AND : RelationshipOperators.OR
  return insertNode(currentActiveTree, filter, operator)
}

// Since insertNode mutates the tree, we will add the filter, we have to copy the tree
// This is to ensure that the tree is not mutated
const toPreviewFilter = (
  type: FilterSelectionType,
  includedFiltersAst: FilterAst | null,
  excludedFiltersAst: FilterAst | null,
  activeSubtree: FilterAst | null,
  filter: MemberDataFilter,
) => {
  const root = getRoot(type === 'includes' ? includedFiltersAst : excludedFiltersAst)
  const activeSubtreeRoot = getRoot(activeSubtree)
  const currentTree = activeSubtreeRoot || root
  const currentTreeRootCopy = parseFilters(serializeFilters(currentTree))
  // We want to find the active subtree in the copy of the current tree
  // The reason we COPY the current tree is because insertNode mutates the tree
  // For the preview function, we don't want to modify the original tree
  // The reason we dont do this in the addFilterToAst function is because we could in theory have 2 exact active subtrees, for calculating count(this preview fn), this does not matter but for adding it to a node, it does. findOne will just find the first, not the one the user might be on.
  const activeSubtreeFilter = serializeFilters(activeSubtree)
  const activeSubtreeCopy = activeSubtreeFilter ? findOne(currentTreeRootCopy, activeSubtreeFilter) : null

  const currentActiveTree = activeSubtreeCopy || currentTreeRootCopy
  const modifiedTree = toModifiedTreeBeforeAdding(currentActiveTree)

  const operator = activeSubtreeCopy ? RelationshipOperators.AND : RelationshipOperators.OR

  // Add the filter to the tree
  const updatedAst = insertNode(modifiedTree, filter, operator)

  // Get the root of the updated tree
  const newRoot = getRoot(updatedAst)

  // Serialize the tree with the new filter
  const updatedFilters = serializeFilters(newRoot)
  return updatedFilters
}

export function FilterProvider(props: FilterProviderProps): JSX.Element {
  const { children } = props
  const [individuals, setIndividuals] = useState<Fan[]>(props.individuals || [])
  const [baseFilters, setBaseFilters] = useState<MemberDataFilter | null>(props.baseFilters || null)
  const [includedFilters, setIncludedFilters] = useState<MemberDataFilter | null>(props.includedFilters || null)
  const [excludedFilters, setExcludedFilters] = useState<MemberDataFilter | null>(props.excludedFilters || null)
  const [activeSubtree, setActiveSubtree] = useState<FilterAst | null>(null)
  const [communicationChannel, setCommunicationChannel] = useState<CommunicationChannel>(
    props.communicationChannel || CommunicationChannel.SMS,
  )
  const { siblingsHaveAnyRequiredFilterType } = useFilterSupportMatrix({ communicationChannel })

  useEffect(() => {
    if (props.communicationChannel) {
      setCommunicationChannel(props.communicationChannel)
    }
  }, [props.communicationChannel])

  useEffect(() => {
    if (props.includedFilters) {
      setIncludedFilters(props.includedFilters)
    }
  }, [props.includedFilters])

  useEffect(() => {
    if (props.excludedFilters) {
      setExcludedFilters(props.excludedFilters)
    }
  }, [props.excludedFilters])

  const setFilters = useCallback(
    (filters: MemberDataFilter | null, type?: FilterSelectionType) => {
      if (type === 'excludes') {
        setExcludedFilters(filters)
      } else {
        setIncludedFilters(filters)
      }
    },
    [setIncludedFilters, setExcludedFilters],
  )

  const filters = useMemo(
    () => toFilters(includedFilters, excludedFilters, baseFilters),
    [includedFilters, excludedFilters, baseFilters],
  )

  useEffect(() => {
    if (!includedFilters && !excludedFilters) {
      setActiveSubtree(null)
    }
  }, [includedFilters, excludedFilters])

  const activeSubtreeFilters = serializeFilters(activeSubtree)

  const includedFiltersAst = parseFilters(includedFilters)
  const excludedFiltersAst = parseFilters(excludedFilters)

  const addIndividual = useCallback((individual: Fan) => {
    setIndividuals((previousIndividuals) => {
      const updated = [...previousIndividuals, individual]

      return updated
    })
  }, [])

  const removeIndividual = useCallback((fanSubscriptionId: string) => {
    setIndividuals((previousIndividuals) => {
      const updated = previousIndividuals.filter((i) => i.fanSubscriptionId !== fanSubscriptionId)

      return updated
    })
  }, [])

  const removeFilter: FilterContextValue['removeFilter'] = useCallback(
    (filter, subtree, type = 'includes'): void => {
      const node = findOne(subtree || activeSubtree, filter)
      if (node) {
        let updatedAst: FilterAst | null = null
        const analyticsOperator = node.parent && isComparisonNode(node.parent) ? node.parent.data.operator : null
        if (siblingsHaveAnyRequiredFilterType(node)) {
          // if the node has sibling nodes that are required filters,
          // we can safely remove the single node
          updatedAst = removeNode(node)
        } else if (node.parent) {
          // if the node that is to be removed represents a required
          // filter, we have to remove the entire group.
          updatedAst = removeNode(node.parent)
        } else {
          updatedAst = removeNode(node)
        }
        const root = getRoot(updatedAst)
        const updatedFilters = serializeFilters(root)

        setActiveSubtree(updatedAst)
        setFilters(updatedFilters, type)

        analytics.track(
          analytics.events.FilterRemoved({
            type: getFieldKey(filter) || '',
            operator: analyticsOperator,
            excludedFilter: type === 'excludes',
          }),
        )
      }
    },
    [activeSubtree, setFilters, siblingsHaveAnyRequiredFilterType],
  )

  const addFilter = useCallback(
    (filter: MemberDataFilter, type: FilterSelectionType = 'includes'): void => {
      const updatedAst = addFilterToAst(type, includedFiltersAst, excludedFiltersAst, activeSubtree, filter)

      const newRoot = getRoot(updatedAst)
      const updatedFilters = serializeFilters(newRoot)

      setActiveSubtree(updatedAst)
      setFilters(updatedFilters, type)

      const operator = activeSubtree ? RelationshipOperators.AND : RelationshipOperators.OR
      analytics.track(
        analytics.events.FilterAdded({
          type: getFieldKey(filter) || '',
          operator: operator,
          excludedFilter: type === 'excludes',
        }),
      )
    },
    [activeSubtree, setFilters, excludedFiltersAst, includedFiltersAst, setActiveSubtree],
  )

  const updateFilter = useCallback(
    (
      previousFilter: PartialDeep<MemberDataFilter>,
      nextFilter: MemberDataFilter,
      subtree: FilterAst | null,
      type: FilterSelectionType = 'includes',
    ): void => {
      const node = findOne(subtree, previousFilter)

      if (node) {
        const nextNode = parseFilters(nextFilter)
        if (nextNode) {
          node.replace(nextNode)
        } else {
          node.remove()
        }
      }

      const root = getRoot(subtree)

      const updatedFilters = serializeFilters(root)

      setFilters(updatedFilters, type)
    },
    [setFilters],
  )

  const previewNextFilter = useCallback(
    (filter: MemberDataFilter, type: FilterSelectionType = 'includes'): MemberDataFilter | null => {
      const updatedFilters = toPreviewFilter(type, includedFiltersAst, excludedFiltersAst, activeSubtree, filter)

      if (!updatedFilters) {
        return null
      }

      // Combine and return the new filters
      if (type === 'includes') {
        return toFilters(updatedFilters, excludedFilters, baseFilters)
      }

      return toFilters(includedFilters, updatedFilters, baseFilters)
    },
    [includedFiltersAst, excludedFiltersAst, activeSubtree, includedFilters, excludedFilters, baseFilters],
  )

  return (
    <FilterContext.Provider
      value={{
        addFilter,
        previewNextFilter,
        communicationChannel,
        setCommunicationChannel,
        removeFilter,
        updateFilter,
        individuals,
        addIndividual,
        removeIndividual,
        setIndividuals,
        setIncludedFilters,
        setExcludedFilters,
        setBaseFilters,
        includedFilters,
        excludedFilters,
        baseFilters,
        filters,
        includedFiltersAst,
        excludedFiltersAst,
        activeSubtree,
        activeSubtreeFilters,
        setActiveSubtree,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
