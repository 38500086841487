import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'

import { EMBED_DEFAULTS } from '../constants/embed'

import { Embed, deleteClientEmbed, getClientEmbeds, postClientEmbed, putClientEmbed } from 'api/mgu'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClient } from 'hooks/useClient'

type UseEmbedOptions = {
  onSaveSuccess?(embed: Embed)
  onSaveError?()
  onRemoveSuccess?()
  onRemoveError?()
}

type UseEmbedResponse = {
  embed?: Embed
  save(embed: Embed): void
  remove(embedId: string): void
  isLoading: boolean
  isQuerying: boolean
  isSaving: boolean
  isRemoving: boolean
  isCreation: boolean
}

export function useEmbed(embedId?: string, options: UseEmbedOptions = {}): UseEmbedResponse {
  const isCreation = !embedId
  const { data: client } = useClient()
  const queryClient = useQueryClient()
  const createFlag = useRef(false)
  const [embed, setEmbed] = useState<Embed>()

  // Adding a timeout to clear cache in order to solve backend async issues
  // When removing an embed we could potentially refetch the recently deleted embed SA/EC
  const clearMguCache = () => {
    setTimeout(() => queryClient.invalidateQueries([QUERY_CACHE.MGU.EMBEDS]), 100)
  }

  const { data, isLoading: isQuerying } = useQuery<Embed[], Error, Embed[]>(
    [QUERY_CACHE.MGU.EMBEDS, client?.id],
    () => getClientEmbeds(client!.id),
    {
      enabled: !!client,
      staleTime: STALE_TIME.FOREVER,
    },
  )

  const { mutate: save, isLoading: isSaving } = useMutation<Embed, Error, Embed>(
    async (embed: Embed) => {
      return embed.id
        ? putClientEmbed({ embed, clientId: client!.id, embedId: embed.id })
        : postClientEmbed({ embed, clientId: client!.id })
    },
    {
      onSuccess(embed: Embed) {
        setEmbed(embed)
        if (createFlag.current) {
          createFlag.current = false
        } else if (options.onSaveSuccess) {
          options.onSaveSuccess(embed)
        }
      },
      onError: options.onSaveError,
      onSettled: clearMguCache,
    },
  )

  const { mutate: remove, isLoading: isRemoving } = useMutation<any, Error, string>(
    async (embedId: string) => {
      await deleteClientEmbed({ clientId: client!.id, embedId })
    },
    {
      onSuccess: options.onRemoveSuccess,
      onError: options.onRemoveError,
      onSettled: clearMguCache,
    },
  )

  useEffect(() => {
    if (client && isCreation && !embed && !isQuerying && !createFlag.current) {
      createFlag.current = true
      save({
        ...EMBED_DEFAULTS,
        jsonConfig: {
          ...EMBED_DEFAULTS.jsonConfig,
          phoneNumber: client.phoneNumber,
          name: client.fullName,
        },
      })
    } else if (data) {
      setEmbed(data.find((d) => d.id === embedId))
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreation, save, data])

  const isLoading = isQuerying || isSaving || isRemoving

  return {
    save,
    remove,
    embed,
    isSaving,
    isRemoving,
    isQuerying,
    isCreation,
    isLoading,
  }
}
