import React from 'react'

import { IconProps } from './IconProps'

export const ConfirmationIcon = ({ size = 25, color = 'currentColor' }: IconProps): JSX.Element => (
  <svg fill="none" height={size} viewBox="0 0 23 20" width={size} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 19.3828C18.0768 19.3828 22.2969 15.1719 22.2969 10.0859C22.2969 5.00911 18.0677 0.789062 12.9909 0.789062C8.25132 0.789062 4.28648 4.45312 3.76695 9.07422C4.12242 9.0013 4.49611 8.96484 4.87893 8.96484C7.65887 8.96484 10.0104 10.9427 10.5938 13.4766C11.3138 13.2943 12.1159 13.194 12.9909 13.194C15.7435 13.194 17.8581 14.2148 18.7513 15.2812C17.3841 16.8398 15.2604 17.8516 12.9909 17.8516C12.0612 17.8516 11.0495 17.6693 10.1836 17.3138C10.0651 17.651 9.74611 18.1615 9.35419 18.6172C10.4844 19.1185 11.7058 19.3828 13 19.3828ZM12.9909 11.6445C11.2318 11.6263 9.87372 10.168 9.8646 8.20833C9.84637 6.36719 11.2409 4.83594 12.9909 4.83594C14.7318 4.83594 16.1172 6.36719 16.1172 8.20833C16.1172 10.168 14.7409 11.6628 12.9909 11.6445ZM4.87893 19.4831C7.38544 19.4831 9.50914 17.3867 9.50914 14.8529C9.50914 12.3099 7.4219 10.2227 4.87893 10.2227C2.34507 10.2227 0.248718 12.319 0.248718 14.8529C0.248718 17.4049 2.34507 19.4831 4.87893 19.4831ZM4.32294 17.5781C4.1771 17.5781 3.9857 17.5143 3.86721 17.3867L2.12632 15.4635C2.05341 15.3997 2.00783 15.2266 2.00783 15.099C2.00783 14.7708 2.26304 14.5247 2.57294 14.5247C2.77346 14.5247 2.91929 14.6159 3.01044 14.7253L4.30471 16.1198L6.70184 12.7839C6.81122 12.638 6.97528 12.5286 7.18492 12.5286C7.4857 12.5286 7.75914 12.7747 7.75914 13.0937C7.75914 13.194 7.71356 13.3216 7.63153 13.4401L4.80601 17.3685C4.71486 17.4961 4.52346 17.5781 4.32294 17.5781Z"
      fill={color}
    />
  </svg>
)
