import { ApiError } from '@community_dev/requests'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { postSetupIntent, SetupIntent } from 'api/billing'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

export const useClientSecret = (): UseQueryResult<SetupIntent, ApiError> => {
  const clientId = useClientId()
  return useQuery<SetupIntent, ApiError>(
    [QUERY_CACHE.BILLING.SETUP_INTENT, { clientId }],
    () => postSetupIntent({ clientId }),
    {
      staleTime: STALE_TIME.TEN_MINUTES,
    },
  )
}
