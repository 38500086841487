import { ModalGuidedTour, Button, BUTTON_VARIANTS, ChevronLeftIcon } from '@community_dev/pixels'
import { useCallback, useState } from 'react'
import styled from 'styled-components'

import { SlideWithImage } from '../components/SlideWithImage'
import { SlideWithLaunchExamples } from '../components/SlideWithLaunchExamples'
import { SlideWithLeader } from '../components/SlideWithLeader'

import { GrowYourCommunityData, growYourCommunityData } from './growYourCommunityData'

import { generalConfig } from 'utils/config'

const SLIDES = {
  GROW_YOUR_COMMUNITY: {
    HEADING: 'Grow your Community',
    BODY: 'Make sure to continue to promote your Community Number in your own voice and let your audience know what they can look forward to when they text the number.',
    SRC: `${generalConfig.staticUrl}/product-education/member-growth.gif`,
    ALT: 'Growth Tools graph showing your community growing over time',
  },
  SEE_HOW_OTHERS_LAUNCHED: {
    HEADING: 'See how others launched',
  },
}

const StyledBack = styled.div`
  position: absolute;
  right: initial;
  left: 20px;
  top: 20px;

  svg {
    vertical-align: top;
  }
`

const StyledHeader = styled.header`
  padding: 24px 46px;
  text-align: center;

  h2 {
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
    margin: 0;
  }
`

const toBackArrowHeader = (onClick, name) => () => {
  return (
    <StyledHeader>
      <StyledBack>
        <Button aria-label="Back" onClick={onClick} variant={BUTTON_VARIANTS.TEXT}>
          <ChevronLeftIcon />
        </Button>
      </StyledBack>
      <h2>{name}</h2>
    </StyledHeader>
  )
}

type GrowYourCommunityModalProps = {
  onClose: () => void
  isOpen: boolean
  initialSlide: number
}

export const GrowYourCommunityModal = ({ isOpen, onClose, initialSlide }: GrowYourCommunityModalProps): JSX.Element => {
  const [selectedLeader, setSelectedLeader] = useState<GrowYourCommunityData | null>(null)
  const clearSelectedLeader = useCallback(() => setSelectedLeader(null), [setSelectedLeader])
  return (
    <ModalGuidedTour initialSlide={initialSlide} isOpen={isOpen} onClose={onClose}>
      <ModalGuidedTour.Slide heading={SLIDES.GROW_YOUR_COMMUNITY.HEADING}>
        <SlideWithImage body={SLIDES.GROW_YOUR_COMMUNITY.BODY} src={SLIDES.GROW_YOUR_COMMUNITY.SRC} />
      </ModalGuidedTour.Slide>
      {selectedLeader ? (
        <ModalGuidedTour.Slide dots={false} heading={toBackArrowHeader(clearSelectedLeader, selectedLeader.name)}>
          <SlideWithLeader leader={selectedLeader} onClick={clearSelectedLeader} />
        </ModalGuidedTour.Slide>
      ) : (
        <ModalGuidedTour.Slide heading={SLIDES.SEE_HOW_OTHERS_LAUNCHED.HEADING}>
          <SlideWithLaunchExamples body={growYourCommunityData} onClick={setSelectedLeader} onClose={onClose} />
        </ModalGuidedTour.Slide>
      )}
    </ModalGuidedTour>
  )
}
