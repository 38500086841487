import { BRAND_TYPE, STATUS } from '@community_dev/types/lib/api/v2/TCR'

import { ApprovalTCRModal } from './ApprovalTCRModal'
import { RejectionTCRModal } from './RejectionTCRModal'
import { SubmittedTCRModal } from './SubmittedTCRModal'

import { useTCRContext } from 'contexts/TCRProvider'

export const TCRModals = (): JSX.Element | null => {
  const { presentStatusInfo, status, brandType } = useTCRContext()

  if (!presentStatusInfo) return null

  if (status === STATUS.APPROVED) {
    return <ApprovalTCRModal />
  }

  if (status === STATUS.FAILED) {
    return <RejectionTCRModal />
  }

  // should only show for starter brands
  if (status === STATUS.SUBMITTED && brandType === BRAND_TYPE.STARTER) {
    return <SubmittedTCRModal />
  }

  return null
}
