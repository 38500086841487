import { Skeleton, UsersIcon } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'

import CommunicationChannelIcon from 'components/CommunicationChannelIcon'

const StyledAvatar = styled.div`
  display: flex;
  justify-content: center;
  height: 44px;
  position: relative;
`

type StyledCountProps = {
  $clickable?: boolean
}

const StyledCount = styled.button<StyledCountProps>`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  width: 100%;
  padding: 0;
  background: none;
  border: none;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 4px;
  display: block;
  color: ${({ theme }) => theme?.COLORS?.TEXT};

  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `}
`
const StyledSubheading = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  text-align: center;
  width: 100%;

  &::first-letter {
    text-transform: capitalize;
  }
`

export const StyledDeselect = styled.button`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-size: 12px;
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  font-weight: 800;
  cursor: pointer;
  background: none;
  border: none;
  width: 100%;
`

export type ActionPanelContactsHeaderProps = {
  isUserSelection?: boolean
  communicationChannel?: CommunicationChannel
  recipientCountString: string
  recipientCountIsLoading?: boolean
  recipientCountSubheading?: string
  onClickCount?: MouseEventHandler<HTMLButtonElement>
  onClickDeselect?: MouseEventHandler<HTMLButtonElement>
}

export const ActionPanelContactsHeader = ({
  isUserSelection,
  communicationChannel,
  recipientCountString,
  recipientCountSubheading,
  recipientCountIsLoading,
  onClickCount,
  onClickDeselect,
}: ActionPanelContactsHeaderProps): JSX.Element => {
  return (
    <>
      <StyledAvatar>
        <UsersIcon size={72} />
        <CommunicationChannelIcon
          communicationChannel={communicationChannel}
          css={`
            position: absolute;
            bottom: 0;
            right: calc(50% - 40px);
          `}
          size={16}
        />
      </StyledAvatar>
      <StyledCount
        $clickable={isUserSelection || !!onClickCount}
        data-testid="contacts-detail-pane-count"
        onClick={onClickCount}
      >
        <Skeleton height="32px" loading={false || recipientCountIsLoading} marginLeft="auto" marginRight="auto">
          {recipientCountString}
        </Skeleton>
      </StyledCount>

      {recipientCountSubheading && <StyledSubheading>{recipientCountSubheading}</StyledSubheading>}
      {isUserSelection && onClickDeselect && <StyledDeselect onClick={onClickDeselect}>Deselect All</StyledDeselect>}
    </>
  )
}
