import { humanizeBuiltInFilterShortened } from '@community_dev/filter-dsl/lib/humanize/humanizeBuiltInFilters'
import { humanizeMemberDataFilterShortened } from '@community_dev/filter-dsl/lib/humanize/humanizeMemberDataFilters'
import {
  BuiltInFields,
  CommunityFilter,
  FieldSources,
  FieldTypes,
  getFieldKey,
  getFieldSource,
  isEveryoneFilter,
  isMemberDataFilter,
  isMostActiveMembersFilter,
  isSelectorFilter,
  MemberDataFilter,
  SelectorOperators,
  SUBSCRIPTION_DATA_FILTER_DATE_FORMAT,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { FONT_WEIGHT, ListItem, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { FanRegistrationCoreFieldName } from '@community_dev/types/lib/api/FanRegistration'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useCompose } from '../../ComposeContext'
import { FilterMemberCount } from '../FilterMemberCount'
import { useRecipientField } from '../RecipientFieldContext'

import { Activity } from './Activity'

import { Community } from 'api/community'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { CommunityDot } from 'components/CommunityDot'
import { useFilterSupportMatrix } from 'components/ComposeMessage/hooks/useFilterSupportMatrix'
import { CAPABILITIES } from 'constants/capabilities'
import { useMostActiveMembers } from 'containers/RecipientRecommendations/useMostActiveMembers'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useFanRegistrationField } from 'hooks/useClientSettings/useFanRegistrationField'
import { useCommunities, useCommunityLookup } from 'hooks/useCommunities'
import { FilterOptionsWithCounts } from 'hooks/useCountByQuery/types'
import { useCurrentFiltersWithAgeCounts } from 'hooks/useCountByQuery/useAgeCount'
import { useCurrentFiltersWithBirthdayCount } from 'hooks/useCountByQuery/useBirthdayCount'
import { useCurrentFiltersWithCommunityCount } from 'hooks/useCountByQuery/useCommunityCount'
import { useCurrentFiltersWithEveryoneCount } from 'hooks/useCountByQuery/useEveryoneCount'
import { useCurrentFiltersWithGenderCounts } from 'hooks/useCountByQuery/useGenderCount'
import { useHasCapability } from 'hooks/useUserCapability'
import analytics from 'utils/analytics'
import { pluralizeNumeral } from 'utils/general'

const StyledHeading = styled.h2`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: ${FONT_WEIGHT[3]};
  margin: ${SPACING[5]} 0 ${SPACING[4]} ${SPACING[4]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

type MemberDataFilterSectionProps = {
  heading?: React.ReactNode
  filters: FilterOptionsWithCounts
  testId?: string
  onClick: (filter: MemberDataFilter) => void
}

export const MemberDataFilterSection = ({
  heading,
  filters,
  testId = '',
  onClick,
}: MemberDataFilterSectionProps): JSX.Element | null => {
  const communityLookup = useCommunityLookup()
  const { options, counts } = filters

  if (!options.length) return null

  return (
    <div data-testid={testId || undefined}>
      {heading && <StyledHeading>{heading}</StyledHeading>}
      {options?.map(({ filter, label }, i) => {
        const { data: { count = 0 } = {}, isInitialLoading } = counts[i]
        const _label = label ? label : humanizeMemberDataFilterShortened(filter, { communities: communityLookup })

        return (
          <ListItem
            key={i}
            label={_label}
            onClick={() => {
              onClick(filter)
            }}
            subtext={
              <FilterMemberCount
                count={pluralizeNumeral(String(count), '0,0', 'Member', 'Members')}
                isLoading={isInitialLoading}
              />
            }
          />
        )
      })}
    </div>
  )
}

type FilterSectionProps = {
  heading: string
  filters: {
    filter: MemberDataFilter
    count: number
  }[]
  testId?: string
  onClick: (filter: MemberDataFilter) => void
}

const FilterSection = ({ heading, filters, testId = '', onClick }: FilterSectionProps): JSX.Element | null => {
  const { t } = useTranslation()
  const { toggleScheduled, scheduledAt } = useCompose()
  if (!filters.length) return null

  return (
    <div data-testid={testId || undefined}>
      <StyledHeading>{heading}</StyledHeading>
      {filters.map(({ filter, count }) => {
        if (isMemberDataFilter(filter) && isSelectorFilter(filter)) {
          const key = getFieldKey(filter)
          const value = filter.operand.value
          const label = humanizeBuiltInFilterShortened(filter)
          let subtext = ''
          if (key === BuiltInFields.BIRTHDAY && getFieldSource(filter)) {
            if (count === 0) {
              subtext = t('scheduled.zeroBirthdaysToday')
            } else {
              subtext = pluralizeNumeral(count.toString(), '0,0', t('memberToday'), t('membersToday'))
            }
          } else {
            subtext = pluralizeNumeral(count.toString(), '0,0', t('member'), t('members'))
          }

          return (
            <ListItem
              key={`${key}-${value}`}
              label={label}
              onClick={() => {
                if (key === BuiltInFields.BIRTHDAY) {
                  if (count === 0 && !scheduledAt) {
                    toggleScheduled()
                  }

                  // This checks if the user has selected a date and time
                  // For scenarios in which they select the date and time and then click on the birthday filter
                  scheduledAt
                    ? onClick({
                        ...filter,
                        operand: {
                          ...filter.operand,
                          value: scheduledAt.format(SUBSCRIPTION_DATA_FILTER_DATE_FORMAT),
                        },
                      } as MemberDataFilter)
                    : onClick(filter)

                  analytics.track(analytics.events.SchedulingBirthdayMessage())
                  return
                }

                onClick(filter)
              }}
              subtext={subtext}
            />
          )
        }

        return null
      })}
    </div>
  )
}

export const CommunitySuggestionListItem = ({
  community,
  onClick,
  communicationChannel,
}: {
  community: Community
  onClick: React.MouseEventHandler
  communicationChannel: CommunicationChannel
}): JSX.Element => {
  const {
    counts: [{ data: { count = 0 } = {}, isInitialLoading = false } = {}],
  } = useCurrentFiltersWithCommunityCount({
    communityId: community.id,
    title: community.title,
  })
  const { t } = useTranslation()
  return (
    <ListItem
      key={community.id}
      label={community.title}
      onClick={onClick}
      prefix={
        <CommunityDot
          badge={
            <CommunicationChannelIcon
              communicationChannel={communicationChannel}
              filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))"
              size={19}
              style={{ marginTop: SPACING[2] }}
            />
          }
          color={community.color}
        />
      }
      subtext={
        <FilterMemberCount
          count={t('communities.activeMembers', {
            count,
            total: count,
          })}
          isLoading={isInitialLoading}
        />
      }
    />
  )
}

export const CommunitiesFilterSection = ({
  onSelect,
}: {
  onSelect: (filter: MemberDataFilter) => unknown
}): JSX.Element => {
  const { communicationChannel } = useFilters()
  const { t } = useTranslation()
  const { data: communities } = useCommunities({
    select(data) {
      // get the first three communities for that channel
      return data.all.filter((community) => community.communicationChannels?.includes(communicationChannel)).slice(0, 3)
    },
  })
  const communityToFilter = useCallback((community: Community): CommunityFilter => {
    return {
      operator: SelectorOperators.EQUALS,
      operand: {
        field_key: BuiltInFields.TAG_ID,
        field_label: community.title,
        source: FieldSources.TAGS,
        type: FieldTypes.UUID,
        value: community.id,
      },
    }
  }, [])
  return (
    <div data-testid="suggested-communities">
      <StyledHeading>{t('communities.communities')}</StyledHeading>
      {communities?.map((community) => {
        return (
          <CommunitySuggestionListItem
            communicationChannel={communicationChannel}
            community={community}
            key={community.id}
            onClick={() => {
              onSelect(communityToFilter(community))
            }}
          />
        )
      })}
    </div>
  )
}

export const SuggestionsFilters = (): JSX.Element => {
  const hasSegmentBasedBilling = useHasCapability(CAPABILITIES.FEATURE.SEGMENT_BILLING.ALL)
  const { addFilter, includedFiltersAst, communicationChannel } = useFilters()
  const { setIsOpen } = useRecipientField()
  const { isMostActiveMembersEnabled } = useMostActiveMembers()
  const { isFilterEnabled, isFilterSupported } = useFilterSupportMatrix({ communicationChannel })
  const hasFilters = !!includedFiltersAst
  const { label: genderLabel } = useFanRegistrationField(FanRegistrationCoreFieldName.GENDER_NAME) || {}
  const ageFilters = useCurrentFiltersWithAgeCounts()
  const genderFilters = useCurrentFiltersWithGenderCounts()
  const birthdayFilter = useCurrentFiltersWithBirthdayCount()
  const birthdayFilterFormatted = birthdayFilter.options.map(({ filter }, i) => ({
    filter,
    count: birthdayFilter.counts[i].data?.count || 0,
  }))
  const everyoneFilter = useCurrentFiltersWithEveryoneCount()
  const everyoneFilterFormatted = everyoneFilter.options.map(({ filter }, i) => ({
    filter,
    count: everyoneFilter.counts[i].data?.count || 0,
  }))
  const filters = [...everyoneFilterFormatted, ...birthdayFilterFormatted]

  const suggestions = filters.filter(({ filter }) => {
    // disable most active members filter if it's not enabled
    if (isSelectorFilter(filter) && isMostActiveMembersFilter(filter) && !isMostActiveMembersEnabled) {
      return false
    }

    // disable most active members if user is on segment based billing
    if (isSelectorFilter(filter) && isMostActiveMembersFilter(filter) && hasSegmentBasedBilling) {
      return false
    }

    // if there are any active filters, remove everyone filter
    if (isSelectorFilter(filter) && isEveryoneFilter(filter) && hasFilters) {
      return false
    }

    // if the filter is not supported in the filter support matrix, remove it.
    if (isSelectorFilter(filter) && !isFilterSupported(filter.operand.field_key as BuiltInFields)) {
      return false
    }
    return true
  })

  return (
    <div>
      <div data-testid="top-recommendations">
        <FilterSection
          filters={suggestions}
          heading="Suggestions"
          onClick={(filter) => {
            addFilter(filter)
            setIsOpen(false)
          }}
          testId="suggestions"
        />
        {isFilterSupported(BuiltInFields.TAG_ID) && communicationChannel === CommunicationChannel.AMB && (
          <CommunitiesFilterSection
            onSelect={(filter) => {
              addFilter(filter)
              setIsOpen(false)
            }}
          />
        )}
        {!hasSegmentBasedBilling &&
          isFilterSupported(BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT) &&
          !!isFilterEnabled(BuiltInFields.AGE) && (
            <Activity
              filters={filters}
              heading={<StyledHeading>Activity</StyledHeading>}
              onSelect={(filter) => addFilter(filter)}
            />
          )}

        {isFilterSupported(BuiltInFields.AGE) && !!isFilterEnabled(BuiltInFields.AGE) && (
          <MemberDataFilterSection
            filters={ageFilters}
            heading="Suggested Ages"
            onClick={(filter: MemberDataFilter) => {
              addFilter(filter)
              setIsOpen(false)
            }}
            testId="suggested-ages"
          />
        )}
        {isFilterSupported(BuiltInFields.GENDER_IDENTITY) && !!isFilterEnabled(BuiltInFields.GENDER_IDENTITY) && (
          <MemberDataFilterSection
            filters={genderFilters}
            heading={genderLabel || 'Gender'}
            onClick={(filter) => {
              addFilter(filter)
              setIsOpen(false)
            }}
            testId="suggested-genders"
          />
        )}
      </div>
    </div>
  )
}
