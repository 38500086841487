import * as fixtures from 'mocks/insights-fixtures'

export const mockQuery = ({ state, data }: any): any => ({
  state,
  data: state === 'success' ? data : undefined,
  isLoading: state === 'loading',
  isError: state === 'error',
})

const mockResponseBehavior = (override, successData) => {
  switch (true) {
    case typeof override === 'object':
      return mockQuery({ state: 'success', data: override })
    case override === 'loadError':
      return mockQuery({ state: 'error' })
    default:
      return mockQuery({ state: 'success', data: successData })
  }
}

export const mockDemographicInsights = ({ age, state, msa, lastWeek }: any = {}): any => ({
  ageAndGenderQuery: mockResponseBehavior(age, fixtures.ageAndGenderInsights),
  stateQuery: mockResponseBehavior(state, fixtures.stateInsights),
  msaQuery: mockResponseBehavior(msa, fixtures.msaInsights),
  sevenDayNewMembersQuery: mockResponseBehavior(lastWeek, 7),
})
