import { useMessageBillingUsage } from '@community_dev/hooks'
import {
  Button,
  CloseIcon,
  DIALOG_VARIANTS,
  Dialog,
  Modal,
  Popover,
  SPACING,
  Typography,
  InfoIcon,
  FONT_SIZE,
  TABLET_BREAK,
  WarningIcon,
  BUTTON_VARIANTS,
  WhatsAppIcon,
  Tooltip,
  Layout,
  EditableText,
  BaseEmoji,
  PhonePreview,
  Info,
} from '@community_dev/pixels'
import { convertKeysToCamelCase, route } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { PhoneNumberType } from '@community_dev/types/lib/api/PhoneNumberType'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { STATUS } from '@community_dev/types/lib/api/v2/TCR'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { $createTextNode, $getSelection, $isRangeSelection } from 'lexical'
import compact from 'lodash/compact'
import noop from 'lodash/noop'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router'
import styled, { useTheme } from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'

import {
  ComposeDialogs,
  ComposeDialogsHandle,
  DynamicFieldList,
  InsertDynamicFieldButton,
  RecommendationsCommunities,
  RecommendationsIndividuals,
} from './components'
import { AiMessageGeneratorButton } from './components/AiMessageGeneratorButton/AiMessageGeneratorButton'
import { MemoizedCommunicationChannelSelect } from './components/CommunicationChannelSelect'
import { CommunityCaresInfo } from './components/CommunityCaresInfo'
import { ExclusionField } from './components/ExclusionField'
import { MediaDispositionSelect } from './components/MediaDispositionSelect'
import { RecipientField } from './components/RecipientField'
import { RecipientFieldContextProvider } from './components/RecipientFieldContext'
import { ScheduledBirthdayInfo } from './components/ScheduledBirthdayInfo'
import { ScheduledTimezoneWarning } from './components/ScheduledTimezoneWarning'
import { UseComposeProps, useCompose } from './ComposeContext'
import { useComposeNotify } from './hooks/useComposeNotify'
import { ComposeRole, useCampaignPreviewAndTestEnabled, useComposeRole } from './hooks/useComposeRole'
import { useFilterSupportMatrix } from './hooks/useFilterSupportMatrix'
import { useGiphySearch } from './hooks/useGiphySearch'
import { usePhonePreviewBubbles } from './hooks/usePhonePreviewBubbles'
import { Schedule } from './Schedule'

import { Media } from 'api/campaign'
import { SuggestionCard } from 'api/message'
import {
  ComposeEditor,
  INSERT_PLACEHOLDER_COMMAND,
  PLACEHOLDER_LIMIT,
  StyledRoot as StyledComposeEditorRoot,
  usePlaceholderNodes,
} from 'components/ComposeEditor'
import { Nodes } from 'components/ComposeEditor/nodes'
import { placeholderNodeToText, replacePlaceholdersWithNames } from 'components/ComposeEditor/util/replacePlaceholders'
import { MessagesRemaining, useMessagesLimitsModal } from 'components/ComposeMessage/components/MessagesLimits'
import { FileContext, FileRejectionDialog } from 'components/FileRejectionDialog'
import { LimitDialog } from 'components/LimitDialog'
import { LinkShorteningCheckbox } from 'components/LinkShorteningCheckbox'
import { MultimediaInput } from 'components/MultimediaInput'
import { CAPABILITIES } from 'constants/capabilities'
import { TCR_FAQ } from 'constants/communityLinks'
import { firstNamePlaceholder } from 'constants/placeholder'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { FilterPane } from 'containers/RecipientRecommendations/recommendation-constants'
import { useAiMessageGenerator } from 'contexts/AIMessageGeneratorProvider'
import { FilterProvider, FilterSelectionType, UseFilterProps, useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useSettings } from 'contexts/SettingsProvider'
import { useTCRContext } from 'contexts/TCRProvider'
import useBreakpoints from 'hooks/useBreakpoints'
import { useCarrierUsage } from 'hooks/useCarrierUsage'
import { useClient, useClientId } from 'hooks/useClient'
import { useCountByQuery } from 'hooks/useCountByQuery'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { usePlaceholders } from 'hooks/useCustomDataFields'
import { useMediaConfig } from 'hooks/useMediaConfig'
import { useSendMessage } from 'hooks/useSendMessage'
import { processBodyText } from 'hooks/useSendMessage/processBodyText'
import { WarningTypes } from 'hooks/useSendMessage/useCheckMessageContent'
import { CheckTypes, ErrorTypes, SendMessageError } from 'hooks/useSendMessage/useSendMessage'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import Sentry from 'integrations/Sentry'
import { ScheduledLocalStorageKeys, ScheduledTabView } from 'screens/Scheduled/constants'
import analytics from 'utils/analytics'
import { createMediaFromGiphy, createMediaFromFile } from 'utils/createMediaFromFile'

const StyledModalBody = styled(Modal.Body)`
  padding: 0px;
`
const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`
const StyledRecipientField = styled(RecipientField)`
  margin: 0 0 ${SPACING[4]};
`
const StyledMultimediaInput = styled.div`
  margin: 0 0 ${SPACING[4]};
  flex: 1;

  // the following styles allow the multimedia input to span the full height of the compose dialog
  //
  // multimedia input first di
  & > div {
    height: 100%;

    // pixels StyledMultiMediaInput root
    & > div {
      height: 100%;

      // dropzone layout wrapper
      & > div {
        height: 100%;

        // dropzone
        & > div {
          display: flex;
          flex-direction: column;
          height: 100%;

          ${StyledComposeEditorRoot},
          textarea {
            flex: 1;
          }
        }
      }
    }
  }
`
const StyledComposeButtonWrapper = styled.div<{ $centerContent: boolean }>`
  display: flex;
  margin-top: ${SPACING[3]};
  align-items: center;
  justify-content: ${({ $centerContent }) => ($centerContent ? 'space-between' : 'flex-end')};
  position: relative;
  flex-direction: column-reverse;

  @media (min-width: ${TABLET_BREAK}) {
    flex-direction: row;
  }
`
const StyledSendButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-bottom: 8px;

  @media (min-width: ${TABLET_BREAK}) {
    width: auto;
    margin-bottom: 0px;
  }
`

const StyledSendButtonLeft = styled(Button)`
  padding-left: 45px;
  min-width: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
  margin: 0 0 0 auto;

  @media (min-width: ${TABLET_BREAK}) {
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
  }

  svg path {
    fill: ${({ theme }) => theme.COLORS.BUTTON_PRIMARY_TEXT};
  }

  &[aria-disabled='true'] svg path {
    fill: ${({ theme }) => theme.COLORS.BUTTON_DISABLED_TEXT};
  }
`
const StyledSendButtonRight = styled(Button)`
  padding: 7px 12px;
  position: relative;
  min-width: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin: 0 0 0 auto;
`
const StyledDivider = styled.div`
  top: 5px;
  left: 0;
  bottom: 5px;
  width: 1px;
  position: absolute;
  border-left: 1px solid currentColor;
  pointer-events: none;
`
const StyledArrow = styled.div`
  border: solid currentColor;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin-bottom: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  pointer-events: none;
`
const StyledButtonOption = styled(Button)`
  border: none;
  text-align: left;
`

const StyledScheduleButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  width: 100%;
  margin-bottom: 8px;

  @media (min-width: ${TABLET_BREAK}) {
    margin-right: ${SPACING[3]};
    width: auto;
    margin-bottom: 0px;
  }
`

const StyledCloseButton = styled.div`
  font-size: 16px;
  margin-right: ${SPACING[2]};
`

const StyledTooltipLink = styled.a`
  color: ${({ theme }) => theme.COLORS.TOOLTIP_TEXT};
  font-weight: bold;
  text-decoration: underline;
`

const StyledAiMessageGeneratorButton = styled(AiMessageGeneratorButton)`
  margin-right: auto;
  width: 100%;

  @media (min-width: ${TABLET_BREAK}) {
    width: auto;
  }
`

const StyledUnderlineLink = styled.a`
  color: unset;
  text-decoration: underline;
`

const StyledInfo = styled(Info)`
  margin-top: ${SPACING[2]};
  margin-bottom: 0;
`

export type ComposeProps = {
  dynamicFields?: boolean
  enableAiMessageGenerator?: boolean
  file?: File
  initialComposeState?: UseComposeProps['initialState']
  initialFilterState?: UseFilterProps['initialState']
  isRemessage?: boolean
  isSentReply?: boolean
  mediaDisposition?: MediaDisposition
  readOnlyRecipients?: boolean
  remessageUnresponsiveMembers?: boolean
  respondingToCampaign?: string
  shouldDisableScheduleMessages?: boolean
  shouldRedirect?: boolean
  suggestionCard?: SuggestionCard
  open?: boolean
  onClose(): void
  onSuccessfulSend?: (scheduled: boolean) => boolean | void
}

const useSendText = (isLoading: boolean): string => {
  const { shouldShowScheduled } = useCompose()
  const isCampaignDrafterRole = useComposeRole() === ComposeRole.CAMPAIGN_DRAFTER

  const { t } = useTranslation()
  if (isLoading) {
    if (shouldShowScheduled) {
      return isCampaignDrafterRole ? t('compose.submittingForApproval') : t('compose.scheduling')
    }
    return t('compose.sending')
  }

  if (shouldShowScheduled) {
    return isCampaignDrafterRole ? t('compose.submitForApproval') : t('compose.schedule')
  }

  return t('compose.send')
}

export const useSendAnotherText = (): string => {
  const { shouldShowScheduled } = useCompose()
  const isCampaignDrafterRole = useComposeRole() === ComposeRole.CAMPAIGN_DRAFTER
  const { t } = useTranslation()

  if (shouldShowScheduled) {
    return isCampaignDrafterRole ? t('compose.submitAnotherForApproval') : t('compose.scheduleAnotherMessage')
  }
  return t('compose.sendAnotherMessage')
}

const PANES: Record<string, any> = {
  [FilterPane.INDIVIDUAL]: () => RecommendationsIndividuals,
  [FilterPane.COMMUNITY]: (type: FilterSelectionType) => () => <RecommendationsCommunities type={type} />,
}

type SelectTestRecipientsProps = {
  mediaDisposition: MediaDisposition
  onSuccess: () => void
  onError: (e: SendMessageError | null) => void
}

export function SelectTestRecipients(props: SelectTestRecipientsProps): JSX.Element {
  const { mediaDisposition, onSuccess, onError } = props
  const { t } = useTranslation()
  const clientId = useClientId()
  const { includedFilters, communicationChannel } = useFilters()
  const { text, shouldShortenLinks, gif, file } = useCompose()
  const { error, hasViolation, loading, success, warning, sendMessage } = useSendMessage({ clientId, mediaDisposition })

  const { placeholders: activePlaceholders } = usePlaceholderNodes()

  useEffect(() => {
    if (error) {
      onError(error)
    }
  }, [error, onError])

  useEffect(() => {
    if (success) {
      onSuccess()
    }
  }, [onSuccess, success])

  const { isInitialLoading: isCountLoading, data: { count: recipientCount = 0 } = {} } = useCountByQuery({
    communicationChannel,
    filters: includedFilters,
    traceId: 'recipient-count',
  })

  const isSendDisabled = !includedFilters || !recipientCount || isCountLoading || loading

  const onClickSend = (bypass: CheckTypes[] = []) => {
    sendMessage({
      bypass: compact([
        ...bypass,
        // for test campaigns, we want to ignore quiet time and birthday warnings.
        WarningTypes.QUIET_TIME,
        WarningTypes.BIRTHDAY_FILTER,
        // … and we don’t warn about multiple links, or links in the middle of the message.
        communicationChannel !== CommunicationChannel.SMS && WarningTypes.LINK_PREVIEW,
      ]),
      estimatedRecipientCount: recipientCount,
      filters: includedFilters,
      individuals: [],
      communicationChannel,
      hasDropdownFilter: false,
      isRemessage: false,
      placeholders: activePlaceholders,
      scheduledAt: null,
      shouldShortenLinks: shouldShortenLinks,
      suggestionCard: undefined,
      text: processBodyText(text, gif),
      upload: file,
      testCampaign: true,
    })
  }

  return (
    <Layout>
      <StyledRecipientField
        containerId="recipient-pane-container-test-recipients"
        initialPane={FilterPane.INDIVIDUAL}
        panes={PANES}
      />
      <Layout display="flex" justifyContent="flex-end">
        <Button disabled={isSendDisabled} onClick={() => onClickSend()}>
          {t('compose.sendTestMessage')}
        </Button>
      </Layout>
      <ComposeDialogs
        error={error}
        hasViolation={hasViolation}
        mediaDisposition={mediaDisposition}
        onSchedule={noop}
        onSend={onClickSend}
        warning={warning}
      />
    </Layout>
  )
}

function SendButton({
  isSendDisabled,
  setIsComposingAnother,
  handleClickSend,
  loading,
}: {
  loading: boolean
  isSendDisabled: boolean
  setIsComposingAnother(isComposingAnother: boolean): void
  handleClickSend(): void
}): JSX.Element {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const toggleRef = useRef<HTMLDivElement>(null)
  const isCampaignDrafterRole = useComposeRole() === ComposeRole.CAMPAIGN_DRAFTER
  const sendText = useSendText(loading)
  const sendAnotherText = useSendAnotherText()

  return (
    <StyledSendButtonWrapper>
      <StyledSendButtonLeft
        data-testid="compose-send-button"
        disabled={isSendDisabled}
        onClick={() => {
          setIsComposingAnother(false)
          handleClickSend()
        }}
      >
        {sendText}
        {isCampaignDrafterRole ? (
          <Tooltip content={t('compose.submitForApprovalTooltip')}>
            <Layout display="inline-block" marginLeft="4px">
              <InfoIcon size={18} />
            </Layout>
          </Tooltip>
        ) : null}
      </StyledSendButtonLeft>
      <StyledSendButtonRight
        data-testid="compose-send-options-button"
        disabled={isSendDisabled}
        onClick={() => setIsOpen(!isOpen)}
        ref={toggleRef}
      >
        <StyledDivider />
        <StyledArrow />
      </StyledSendButtonRight>
      <Popover
        align="right"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        showBorder={true}
        targetRef={toggleRef}
        width="auto"
      >
        <Layout display="flex" flexDirection="column">
          <StyledButtonOption
            onClick={() => {
              setIsComposingAnother(true)
              setIsOpen(false)
              handleClickSend()
            }}
            variant={BUTTON_VARIANTS.OUTLINE}
          >
            {sendAnotherText}
          </StyledButtonOption>
        </Layout>
      </Popover>
    </StyledSendButtonWrapper>
  )
}

export function ComposeMessage(props: ComposeProps): JSX.Element {
  const {
    initialComposeState = {},
    initialFilterState = {},
    readOnlyRecipients = false,
    dynamicFields = true,
    respondingToCampaign,
    shouldRedirect = true,
    suggestionCard,
    isRemessage = false,
    isSentReply = false,
    enableAiMessageGenerator = false,
    shouldDisableScheduleMessages,
    onClose,
    onSuccessfulSend,
    remessageUnresponsiveMembers,
    open = true,
  } = props
  const { capabilityEnabled: isAiMessageGeneratorCapabilityEnabled } = useAiMessageGenerator()
  const queryClient = useQueryClient()

  const clientId = useClientId()
  const { data: client } = useClient()

  // We disable the ability to send if there are no communication channels
  const isEmptyCommunicationChannels = !client?.communicationChannels?.length
  const hasSmsCommunicationChannel = client?.communicationChannels?.includes(CommunicationChannel.SMS)
  const { status: tcrStatus } = useTCRContext()

  const canDisableLinkShortener = useHasCapability(CAPABILITIES.FEATURE.LINK_SHORTENING.ALL)
  const canSendMessage = useHasCapability(CAPABILITIES.CLIENT.MESSAGES.WRITE)

  const notify = useComposeNotify()
  const isCampaignDrafterRole = useComposeRole() === ComposeRole.CAMPAIGN_DRAFTER

  const {
    activeFilterTypes,
    file,
    gif,
    handleScheduledAtChange,
    hasDropdownFilter,
    hasMediaLink,
    isMediaAttached,
    recipientCount,
    scheduledAt,
    setFile,
    setGif,
    setHasPlaceholders,
    setFrequency,
    setName,
    setText,
    shouldShowScheduled,
    text,
    toggleScheduled,
    frequency,
    shouldShortenLinks,
    setShouldShortenLinks,
    reset: resetCompose,
  } = useCompose({ initialState: initialComposeState })

  const {
    individuals,
    setIncludedFilters,
    setExcludedFilters,
    filters,
    communicationChannel,
    setCommunicationChannel,
  } = useFilters({
    initialState: initialFilterState,
  })

  const { accept, maxSize, mediaDisposition, resetMediaDisposition, setMediaDisposition } = useMediaConfig({
    communicationChannel,
    context: FileContext.COMPOSE,
    initialMediaDisposition: props.mediaDisposition,
  })

  const {
    error: messageError,
    fileUploadProgress,
    hasViolation,
    loading,
    success: messageSuccess,
    warning,
    sendMessage,
  } = useSendMessage({ mediaDisposition, clientId, remessageUnresponsiveMembers })

  useEffect(() => {
    resetMediaDisposition()
    if (props.file) {
      setFile(props.file)
    } else {
      setGif(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.file, resetMediaDisposition, setGif])

  const [isTestMessageModalOpen, setIsTestMessageModalOpen] = useState(false)
  const { showToastMessage } = useToastMessage()
  const history = useHistory()
  const { t } = useTranslation()
  const { COLORS } = useTheme() || {}
  const currentTheme = useCurrentTheme()
  const { settings } = useSettings()
  const { isLimitReached } = useCarrierUsage()
  const [editor] = useLexicalComposerContext()
  const hasLexicalEditorContext = useMemo(() => editor?.update && typeof editor.update === 'function', [editor])
  const { supportsScheduling } = useFilterSupportMatrix({ communicationChannel })
  const canSchedule = supportsScheduling(filters)
  const canInsertDynamicFields = dynamicFields && hasLexicalEditorContext
  const [isComposingAnother, setIsComposingAnother] = useState(false)
  const [isDiscardDialogVisible, setIsDiscardDialogVisible] = useState(false)
  const [isDynamicFieldListVisible, setIsDynamicFieldListVisible] = useState(false)
  const [isLimitDialogVisible, setIsLimitDialogVisible] = useState(isLimitReached)
  const [redirect, setRedirect] = useState<string | null>(null)
  const shouldPreventClose = isComposingAnother
  const { hasLinks, links } = useShortenableLinks(text, 200)
  const { giphySearchResults, setSearchTerm } = useGiphySearch()
  const { displayMessagesRemaining, hasLimitReached, hasWarningThresholdReached, monthlyRemaining } =
    useMessagesLimitsModal({
      enabled: !isSentReply,
      scheduledAt,
    })
  const composeDialogsRef = useRef<ComposeDialogsHandle>(null)
  const trimmedText = text.trim()
  const {
    placeholders: activePlaceholders,
    hasPlaceholderNodes,
    hasExceededLimit,
    hasReachedLimit,
    placeholderNodes,
  } = usePlaceholderNodes()
  const isScheduledAtInPast = useMemo(() => Boolean(scheduledAt && dayjs(scheduledAt).isBefore(dayjs())), [scheduledAt])

  const { data: availablePlaceholders = [], isLoading: isLoadingPlaceholders } = usePlaceholders({
    clientId,
    communicationChannel,
  })
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = useMessageBillingUsage({
    communicationChannel,
    hasMediaLink,
    isMediaAttached,
    linksToShorten: links,
    mediaDisposition,
    message: trimmedText,
    placeholders: availablePlaceholders,
    recipientCount,
    shouldShortenLinks,
    showDynamicCount: hasPlaceholderNodes,
  })
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }
  const hasBirthdayFilter = activeFilterTypes.birthday
  const shouldRenderGifPicker = communicationChannel === CommunicationChannel.SMS

  const { isCampaignPreviewEnabled, isCampaignTestEnabled } = useCampaignPreviewAndTestEnabled()

  useEffect(() => {
    setIsLimitDialogVisible(isLimitReached)
  }, [isLimitReached])

  useEffect(() => {
    setHasPlaceholders(hasPlaceholderNodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPlaceholderNodes])

  const hasCampaignCalendarFeature = useHasCapability(CAPABILITIES.FEATURE.CAMPAIGN_SCHEDULING.ALL)
  const [scheduledView] = useLocalStorageState<ScheduledTabView>(ScheduledLocalStorageKeys.VIEW_KEY, {
    defaultValue: hasCampaignCalendarFeature ? ScheduledTabView.MONTH : ScheduledTabView.LIST,
  })

  useEffect(() => {
    if (messageSuccess) {
      notify({
        type: scheduledAt ? 'scheduled' : 'sent',
        scheduledAt,
        isSuccess: true,
      })

      resetMediaDisposition()

      // Message success analytics
      if (hasBirthdayFilter) {
        analytics.track(analytics.events.SentBirthdayMessage({ isScheduled: Boolean(scheduledAt) }))
      }
      if (scheduledAt) {
        analytics.track(analytics.events.MessageScheduled({ isComposingAnother: shouldPreventClose }))
      } else {
        analytics.track(analytics.events.MessageSent({ isComposingAnother: shouldPreventClose }))
      }

      // Refresh the scheduled data when we create a new one
      if (scheduledAt) {
        queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.LIST, { clientId }])
        queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.RANGE, { clientId }])
        queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.RECURRING, { clientId }])
      } else {
        // refresh campaigns data
        queryClient.invalidateQueries([QUERY_CACHE.CAMPAIGN.LIST, { clientId }])
        queryClient.invalidateQueries([QUERY_CACHE.CAMPAIGN.RANGE, { clientId }])
      }

      // Handle any send action here
      if (onSuccessfulSend) {
        // If the function returns true, then don't
        // close the modal automatically
        const shouldSkipClose = onSuccessfulSend(Boolean(scheduledAt))
        if (shouldSkipClose) return
      }

      if (shouldRedirect && !shouldPreventClose) {
        if (scheduledAt) {
          // check the last scheduled tab view
          // if its month, route to the scheduled month view
          if (scheduledView === ScheduledTabView.MONTH) {
            const month = scheduledAt.month() + 1
            const year = scheduledAt.year()

            history.push(route(ROUTES.SCHEDULED.MONTH, { 'month?': month, 'year?': year }))
          } else {
            history.push(route(ROUTES.SCHEDULED.VIEW, { view: ScheduledTabView.LIST }))
          }
        } else {
          history.push(ROUTES.CAMPAIGNS.ROOT)
        }
      }

      if (!shouldPreventClose) {
        // Make sure this happens after the redirect has a chance to render
        setTimeout(() => onClose(), 0)
      }

      resetCompose()

      // Has to happen after reset since reset will close the scheduled section
      if (isComposingAnother && isCampaignDrafterRole) {
        toggleScheduled()
      }
    }
    // omitting scheduledAt to prevent toasts from triggering when that changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, messageSuccess, shouldPreventClose, notify, queryClient, shouldRedirect, resetCompose, scheduledView])

  useEffect(() => {
    return () => {
      resetCompose()
      setRedirect(null)
      resetMediaDisposition()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (messageError?.type === ErrorTypes.MESSAGES_LIMIT) {
      return
    } else if (messageError?.type === ErrorTypes.UPLOAD_FAILED) {
      showToastMessage({
        message: messageError?.error?.responseText || 'Failed to upload media.',
        success: false,
      })
    } else if (messageError?.type === ErrorTypes.CONTENT_CHECK_FAILED) {
      showToastMessage({
        message: t('compose.failedToSendCampaign'),
        success: false,
      })
    } else if (messageError) {
      notify({
        type: scheduledAt ? 'scheduled' : 'sent',
        scheduledAt,
        isSuccess: false,
      })
    }

    // omitting scheduledAt to prevent toasts from triggering when that changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageError, notify])

  const hasError = hasExceededLimit
  const dynamicFieldMessage = useMemo(() => {
    if (hasExceededLimit) {
      return t('compose.dynamicFieldLimit', { number: PLACEHOLDER_LIMIT })
    }

    return t('compose.dynamicFieldWarning')
  }, [hasExceededLimit, t])

  const isLinkOrPlaceholderAtMessageStart = useMemo(() => {
    // WhatsApp templates are not supposed to have placeholders or links in the
    // beginning of the message.
    return (
      links.some((link) => trimmedText.startsWith(link)) ||
      placeholderNodes.some((placeholder) => trimmedText.startsWith(placeholderNodeToText(placeholder)))
    )
  }, [links, placeholderNodes, trimmedText])

  // if user attaches a file that is not supported by MMS
  // and they choose to instead send the message as SMS
  // set file and temporarily override media disposition
  const handleContinue = () => {
    setMediaDisposition(MediaDisposition.LINK)
  }

  const handleEmojiSelect = (emoji: BaseEmoji, text: string) => {
    if (canInsertDynamicFields) {
      editor.update(() => {
        const selection = $getSelection()
        // If cursor is in the editor, insert emoji at cursor
        if ($isRangeSelection(selection)) {
          selection.insertNodes([$createTextNode(emoji.native)])
        }
      })
    }
    setText(text)
  }

  const handleClose = useCallback(() => {
    if (trimmedText.length) {
      setIsDiscardDialogVisible(true)

      return
    }

    resetMediaDisposition()
    setName(undefined)

    onClose()
  }, [trimmedText, onClose, setIsDiscardDialogVisible, resetMediaDisposition, setName])

  const handleCancelDiscardDialog = () => {
    setIsDiscardDialogVisible(false)
  }

  const handleClickSend = (bypass: CheckTypes[] = []) => {
    sendMessage({
      bypass: compact([
        ...bypass,
        // for Non-SMS campaigns, we ignore Quite Hours.
        communicationChannel !== CommunicationChannel.SMS && WarningTypes.QUIET_TIME,
        // … and we don’t warn about multiple links, or links in the middle of the message.
        communicationChannel !== CommunicationChannel.SMS && WarningTypes.LINK_PREVIEW,
      ]),
      estimatedRecipientCount: recipientCount,
      filters,
      individuals,
      communicationChannel,
      hasDropdownFilter,
      isRemessage,
      placeholders: activePlaceholders,
      scheduledAt,
      shouldShortenLinks,
      suggestionCard,
      text: processBodyText(text, gif),
      upload: file,
      ...(respondingToCampaign && {
        replyTo: {
          parent_sms_campaign_id: respondingToCampaign,
        },
      }),
    })
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && event.metaKey === true && !event.ctrlKey) {
      event.preventDefault()
      // Send a message on cmd + enter
    }
  }

  const onChangeCommunicationChannel = useCallback(
    (c: CommunicationChannel) => {
      // we’re resetting the filters when the communication channel changes, to
      // avoid having filters applied that might not be supported by the channel.
      setIncludedFilters(null)
      setExcludedFilters(null)
      setCommunicationChannel(c)
    },
    [setCommunicationChannel, setIncludedFilters, setExcludedFilters],
  )

  const onFileRejected = useCallback(
    (fileRejection: FileRejection) => {
      setFile(fileRejection.file)
    },
    [setFile],
  )

  const handleRemoveMedia = () => {
    setFile(undefined)
    resetMediaDisposition()
    // Focus on text after media removal if we're using a lexical editor
    if (editor && editor.focus && typeof editor.focus === 'function') {
      editor.focus()
    }
  }

  const handleDynamicFieldButtonClick = () => {
    if (!availablePlaceholders || availablePlaceholders.length < 1) return

    // if there is only one placeholder and it matches First Name, insert a placeholder node, otherwise show list
    if (
      availablePlaceholders.length === 1 &&
      availablePlaceholders.some((p) => p.key === firstNamePlaceholder.key && p.source === firstNamePlaceholder.source)
    ) {
      setIsDynamicFieldListVisible(false)
      editor.dispatchCommand(INSERT_PLACEHOLDER_COMMAND, {
        id: firstNamePlaceholder.key,
        name: firstNamePlaceholder.name,
        source: firstNamePlaceholder.source,
      })
    } else {
      setIsDynamicFieldListVisible((isDynamicFieldListVisible) => !isDynamicFieldListVisible)
    }
  }

  const handleClickMessages = () => {
    composeDialogsRef.current?.showMessageLimitsDialog()
    analytics.track(analytics.events.MessageLimitAlertTriggered('Info'))
  }

  const { md } = useBreakpoints()

  const { data: apiMedia } = useQuery<Media>(
    [QUERY_CACHE.API_MEDIA, { file, gif, mediaDisposition: mediaDisposition }],
    () => {
      if (gif) {
        return createMediaFromGiphy(gif, {
          disposition: mediaDisposition,
          width: 150,
        }).then(convertKeysToCamelCase)
      }

      return createMediaFromFile(file!, {
        disposition: mediaDisposition,
      }).then(convertKeysToCamelCase)
    },
    {
      select: (data) => {
        return {
          ...data,
          filename: 'Tap to expand',
        }
      },
      enabled: !!(file || gif),
    },
  )

  const phonePreviewBubbles = usePhonePreviewBubbles({
    text: replacePlaceholdersWithNames(trimmedText, availablePlaceholders),
    media: apiMedia,
    shouldShortenLinks,
  })

  const isSendDisabled =
    // disable if there are no filters
    !filters ||
    // disable if birthday filter is selected and no recipients unless there is a frequency
    (hasBirthdayFilter && !recipientCount && !frequency) ||
    (!canSendMessage && !isCampaignDrafterRole) ||
    (!trimmedText.length && !file && !gif) ||
    loading ||
    // We allow scheduling messages with recipient count = 0 when there is a scheduledAt date
    (!recipientCount && !scheduledAt) ||
    !!error ||
    hasExceededLimit ||
    (communicationChannel === CommunicationChannel.WHATS_APP && !trimmedText.length) ||
    (communicationChannel === CommunicationChannel.SMS && !hasSmsCommunicationChannel) ||
    (communicationChannel === CommunicationChannel.WHATS_APP && isLinkOrPlaceholderAtMessageStart) ||
    isEmptyCommunicationChannels ||
    // disable if scheduling to a date/time in the past
    isScheduledAtInPast

  if (redirect && shouldRedirect) {
    return <Redirect push to={redirect} />
  }

  if (isLimitDialogVisible) {
    return (
      <LimitDialog
        onClose={() => {
          onClose()
        }}
        onSubmit={() => {
          setIsLimitDialogVisible(false)
        }}
      />
    )
  }

  const isTestMessageDisabled =
    (!canSendMessage && !isCampaignDrafterRole) ||
    (!trimmedText.length && !file && !gif) ||
    hasExceededLimit ||
    (communicationChannel === CommunicationChannel.WHATS_APP && !trimmedText.length) ||
    (communicationChannel === CommunicationChannel.SMS && !hasSmsCommunicationChannel) ||
    (communicationChannel === CommunicationChannel.WHATS_APP && isLinkOrPlaceholderAtMessageStart) ||
    isEmptyCommunicationChannels

  const showExclusionField = filters && !readOnlyRecipients

  return (
    <>
      <Modal
        maxWidth={isCampaignPreviewEnabled ? 938 : 640}
        minHeight={0}
        onClose={handleClose}
        open={open}
        overflow="visible"
      >
        <Modal.Header>
          <Modal.Header.Left>
            <Layout marginLeft="24px" maxWidth="560px" width="100%">
              <EditableText
                aria-label={t('editCampaignName')}
                emptyValue={t('compose.untitledCampaign')}
                onSave={setName}
                placeholder={t('enterCampaignName')}
                saveOnClickOutside
                typographyProps={{ fontSize: '18px', fontWeight: 700 }}
              />
            </Layout>
          </Modal.Header.Left>
          <Modal.Header.Center />
          <Modal.Header.Right onClose={handleClose}>
            <StyledCloseIcon color={COLORS?.SUBTEXT} size={12} />
          </Modal.Header.Right>
        </Modal.Header>
        <StyledModalBody>
          <Layout display="flex" flexDirection={!md ? 'column' : 'row'}>
            <Layout display="flex" flex="1 1 0" flexDirection="column" padding={SPACING[5]}>
              <Layout display="flex" flex="1" flexDirection="column">
                <MemoizedCommunicationChannelSelect
                  compose
                  css={{ marginBottom: SPACING[4] }}
                  disabled={readOnlyRecipients}
                  onChange={onChangeCommunicationChannel}
                  value={communicationChannel}
                />
                <RecipientFieldContextProvider key="includes">
                  <StyledRecipientField readOnlyRecipients={readOnlyRecipients} />
                </RecipientFieldContextProvider>
                {showExclusionField && (
                  <RecipientFieldContextProvider key="excludes">
                    <ExclusionField />
                  </RecipientFieldContextProvider>
                )}
                <StyledMultimediaInput>
                  <MultimediaInput
                    accept={accept}
                    additionalToolbarItems={
                      canInsertDynamicFields
                        ? [
                            <InsertDynamicFieldButton
                              hasNoDynamicFields={availablePlaceholders.length < 1}
                              hasReachedLimit={hasReachedLimit}
                              isActive={isDynamicFieldListVisible}
                              key="insert-dynamic-field"
                              onClick={handleDynamicFieldButtonClick}
                            />,
                          ]
                        : []
                    }
                    autoFocus
                    emojiSkinTone={settings.skinTone}
                    emojiTheme={currentTheme.type}
                    gif={shouldRenderGifPicker}
                    gifImages={giphySearchResults}
                    gifMedia={gif}
                    hasError={hasError}
                    maxSize={maxSize}
                    media={file && { file, progress: fileUploadProgress }}
                    onChange={(e) => setText(e.target.value)}
                    onEmojiSelect={handleEmojiSelect}
                    onFileAccepted={setFile}
                    onFileRejected={onFileRejected}
                    onGifImageClick={setGif}
                    onGifSearchUpdate={setSearchTerm}
                    onKeyDown={handleKeyDown}
                    onRemoveGifMedia={() => setGif(undefined)}
                    onRemoveMedia={handleRemoveMedia}
                    placeholder={t('compose.placeholder')}
                    usageCounterProps={usageCounterProps}
                    value={text}
                  >
                    {canInsertDynamicFields
                      ? (props) => (
                          <ComposeEditor
                            {...props}
                            communicationChannel={communicationChannel}
                            enablePlaceholders
                            placeholders={availablePlaceholders}
                          />
                        )
                      : undefined}
                  </MultimediaInput>
                </StyledMultimediaInput>
                {communicationChannel === CommunicationChannel.SMS &&
                  tcrStatus !== STATUS.APPROVED &&
                  client?.phoneNumberType === PhoneNumberType.TEN_DLC && (
                    <Typography
                      color={COLORS?.ERRORS}
                      fontSize={FONT_SIZE[2]}
                      marginBottom={SPACING[2]}
                      marginTop={0}
                      variant="body2"
                    >
                      <WarningIcon color={COLORS.ERRORS} size={19} />{' '}
                      <Trans i18nKey="compose.smsCampaignsDisabled">
                        <StyledUnderlineLink href={TCR_FAQ} rel="noopener noreferrer" target="_blank" />
                      </Trans>
                    </Typography>
                  )}
                {communicationChannel === CommunicationChannel.WHATS_APP && isLinkOrPlaceholderAtMessageStart && (
                  <Typography
                    color={COLORS?.ERRORS}
                    fontSize={FONT_SIZE[2]}
                    marginBottom={SPACING[2]}
                    marginTop={0}
                    variant="body2"
                  >
                    <WarningIcon color={COLORS.ERRORS} size={19} /> {t('compose.whatsAppPlaceholderPositionError')}
                  </Typography>
                )}
                {canDisableLinkShortener && hasLinks && (
                  <LinkShorteningCheckbox onChange={setShouldShortenLinks} value={shouldShortenLinks} />
                )}
              </Layout>
              <Layout>
                <MediaDispositionSelect mediaDisposition={mediaDisposition} setMediaDisposition={setMediaDisposition} />
                {canInsertDynamicFields && availablePlaceholders.length > 0 && isDynamicFieldListVisible && (
                  <DynamicFieldList
                    hasReachedLimit={hasReachedLimit}
                    isLoading={isLoadingPlaceholders}
                    onInsert={() => setIsDynamicFieldListVisible(false)}
                    placeholders={availablePlaceholders}
                  />
                )}
                {canInsertDynamicFields && hasPlaceholderNodes && (
                  <Typography
                    color={hasError ? COLORS?.ERRORS : COLORS?.TEXT}
                    fontSize={FONT_SIZE[2]}
                    marginBottom={SPACING[2]}
                    marginTop={0}
                    variant="body2"
                  >
                    <InfoIcon size={19} /> {dynamicFieldMessage}
                  </Typography>
                )}
                {communicationChannel === CommunicationChannel.WHATS_APP && (
                  <Tooltip
                    content={
                      <Layout padding={SPACING[1]}>
                        <Trans i18nKey="compose.whatsappApprovalDelayTooltip">
                          <StyledTooltipLink
                            href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/#approval-process"
                            rel="noreferrer"
                            target="_blank"
                          >
                            {t('learnMore')}
                          </StyledTooltipLink>
                        </Trans>
                      </Layout>
                    }
                    interactive={true}
                    placement="bottom"
                  >
                    <span>
                      <Typography
                        color={hasError ? COLORS?.ERRORS : COLORS?.TEXT}
                        fontSize={FONT_SIZE[2]}
                        marginBottom={SPACING[2]}
                        marginTop={0}
                        variant="body2"
                      >
                        <WhatsAppIcon size={19} /> {t('compose.whatsappApprovalDelay')}
                      </Typography>
                    </span>
                  </Tooltip>
                )}
                {suggestionCard?.type === 'custom_community_cares_Compose' && <CommunityCaresInfo />}
                {shouldShowScheduled && scheduledAt && (
                  <Layout borderBottom={`1px solid ${COLORS.APP_BACKGROUND_LEVEL_1}`} paddingBottom={SPACING[3]}>
                    <Schedule
                      onChange={handleScheduledAtChange}
                      onFrequencyChange={setFrequency}
                      scheduledAt={scheduledAt}
                    />
                  </Layout>
                )}
                <StyledComposeButtonWrapper $centerContent={!shouldShowScheduled}>
                  {enableAiMessageGenerator && isAiMessageGeneratorCapabilityEnabled && (
                    <StyledAiMessageGeneratorButton />
                  )}
                  {!isCampaignDrafterRole && (
                    <>
                      {shouldShowScheduled ? (
                        <StyledScheduleButton
                          aria-label={t('compose.cancelScheduling')}
                          data-testid="compose-cancel-scheduling-button"
                          onClick={toggleScheduled}
                          variant={BUTTON_VARIANTS.OUTLINE}
                        >
                          <StyledCloseButton>
                            <FontAwesomeIcon color={COLORS?.SUBTEXT} icon={['far', 'times-circle']} />
                          </StyledCloseButton>
                          {t('compose.cancelScheduling')}
                        </StyledScheduleButton>
                      ) : (
                        <StyledScheduleButton
                          data-testid="compose-schedule-button"
                          disabled={Boolean(!canSchedule || shouldDisableScheduleMessages)}
                          onClick={() => {
                            toggleScheduled()
                            if (hasBirthdayFilter) {
                              analytics.track(analytics.events.SchedulingBirthdayMessage())
                            }
                          }}
                          variant={BUTTON_VARIANTS.OUTLINE}
                        >
                          {t('compose.schedule')}
                        </StyledScheduleButton>
                      )}
                    </>
                  )}

                  <SendButton
                    handleClickSend={() => handleClickSend()}
                    isSendDisabled={isSendDisabled}
                    loading={loading}
                    setIsComposingAnother={setIsComposingAnother}
                  />
                </StyledComposeButtonWrapper>
                {displayMessagesRemaining && (hasLimitReached || hasWarningThresholdReached) && (
                  <Layout paddingTop={SPACING[3]}>
                    <MessagesRemaining
                      hasLimitReached={hasLimitReached}
                      hasWarningThresholdReached={hasWarningThresholdReached}
                      monthlyRemaining={monthlyRemaining}
                      onClick={handleClickMessages}
                    />
                  </Layout>
                )}
                <ScheduledTimezoneWarning />
                {shouldShowScheduled && hasBirthdayFilter && <ScheduledBirthdayInfo />}
                {isScheduledAtInPast && <StyledInfo>{t('scheduled.mustBeInFuture')}</StyledInfo>}
              </Layout>
            </Layout>
            {isCampaignPreviewEnabled && md && (
              <Layout
                background={COLORS.WORKFLOW_EDITOR_PHONE_PREVIEW_BACKGROUND}
                borderBottomRightRadius="24px"
                borderLeft={`1px solid ${COLORS.BORDERS}`}
                display="flex"
                flex="240px 0"
                flexDirection="column"
                justifyContent="flex-start"
                padding={SPACING[5]}
              >
                <PhonePreview bubbles={phonePreviewBubbles} client={client} style={{ minWidth: 250, maxWidth: 250 }} />
                {isCampaignTestEnabled && (
                  <Layout display="flex" justifyContent="center" marginTop={SPACING[5]}>
                    <Button
                      disabled={isTestMessageDisabled}
                      onClick={() => {
                        setIsTestMessageModalOpen(true)
                      }}
                      style={{
                        backgroundColor: COLORS?.APP_BACKGROUND_LEVEL_3,
                      }}
                      variant={BUTTON_VARIANTS.OUTLINE}
                    >
                      {t('compose.sendATestMessage')}
                    </Button>
                  </Layout>
                )}
              </Layout>
            )}
          </Layout>
        </StyledModalBody>
      </Modal>

      <RecipientFieldContextProvider>
        <FilterProvider communicationChannel={communicationChannel}>
          <Modal
            minHeight={0}
            onClose={() => setIsTestMessageModalOpen(false)}
            open={isTestMessageModalOpen}
            overflow="visible"
            style={{
              marginTop: 64,
            }}
          >
            <Modal.Header>
              <Modal.Header.Left>{t('compose.selectTestRecipients')}</Modal.Header.Left>
              <Modal.Header.Right onClose={() => setIsTestMessageModalOpen(false)}>
                <StyledCloseIcon color={COLORS?.SUBTEXT} size={12} />
              </Modal.Header.Right>
            </Modal.Header>
            <StyledModalBody style={{ padding: SPACING[5] }}>
              <SelectTestRecipients
                mediaDisposition={mediaDisposition}
                onError={(e) => {
                  showToastMessage({
                    message: e?.details || t('compose.failedToSendMessage'),
                    success: false,
                  })
                }}
                onSuccess={() => {
                  setIsTestMessageModalOpen(false)
                  showToastMessage({
                    message: t('compose.testMessageSent'),
                    success: true,
                  })
                }}
              />
            </StyledModalBody>
          </Modal>
        </FilterProvider>
      </RecipientFieldContextProvider>

      <ComposeDialogs
        error={messageError}
        hasViolation={hasViolation}
        isSentReply={isSentReply}
        mediaDisposition={mediaDisposition}
        onSchedule={() => {
          if (scheduledAt) return
          toggleScheduled()
        }}
        onSend={handleClickSend}
        ref={composeDialogsRef}
        warning={warning}
      />
      {isDiscardDialogVisible && (
        <Dialog
          message={t('compose.areYouSureDiscard')}
          onCancel={handleCancelDiscardDialog}
          title={t('compose.discardMessage')}
        >
          <Dialog.Action onClick={handleCancelDiscardDialog}>{t('cancel')}</Dialog.Action>
          <Dialog.Action
            onClick={() => {
              setIsDiscardDialogVisible(false)
              onClose()
            }}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            {t('compose.discard')}
          </Dialog.Action>
        </Dialog>
      )}
      <FileRejectionDialog
        communicationChannel={communicationChannel}
        context={FileContext.COMPOSE}
        file={file}
        mediaDisposition={mediaDisposition}
        onClose={() => {
          setFile(undefined)
          resetMediaDisposition()
        }}
        onContinue={handleContinue}
      />
    </>
  )
}

export function LexicalComposerProvider({
  children,
}: {
  children: JSX.Element | string | (JSX.Element | string)[]
}): JSX.Element {
  const initialConfig = {
    namespace: 'ComposeEditor',
    nodes: [...Nodes],
    onError: handleError,
  }

  function handleError(error) {
    Sentry.captureException(error)
  }

  return <LexicalComposer initialConfig={initialConfig}>{children}</LexicalComposer>
}
