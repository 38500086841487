import {
  SelectorOperators,
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  fieldTypeFor,
  SUBSCRIPTION_DATA_FILTER_DATETIME_FORMAT,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { postCountByQuery } from 'api/fan-count'
import { getAgeAndGenderInsights, getCountryInsights, getMSAInsights, getStateInsights } from 'api/insights'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClient, useClientId } from 'hooks/useClient'
import dayjs from 'utils/dayjs'

// we're doing all of our time math in epoch time, so no need to bring in
// any date libraries or anything.
const HOUR = 1000 * 60 * 60
const SEVEN_DAYS = 24 * HOUR * 7

type UseDemographicInsightsOptions = Pick<UseQueryOptions, 'enabled'>

export const useDemographicInsights = (options: UseDemographicInsightsOptions = {}): any => {
  const { data: client } = useClient()
  const clientId = useClientId()
  const [currentTimestamp, setCurrentTimestamp] = useState(Date.now())

  // auto-refresh every hour, in case somebody puts this on a wall
  // monitor or whatever.
  useEffect(() => {
    const interval = setInterval(() => setCurrentTimestamp(Date.now()), HOUR)
    return () => clearInterval(interval)
  }, [])

  const ageAndGenderQuery = useQuery(
    [QUERY_CACHE.INSIGHTS.AGE_AND_GENDER, { clientId, currentTimestamp }],
    () => getAgeAndGenderInsights({ clientId }),
    { staleTime: STALE_TIME.TEN_MINUTES, ...options },
  )

  const stateQuery = useQuery(
    [QUERY_CACHE.INSIGHTS.STATES, { clientId, currentTimestamp }],
    () => getStateInsights({ clientId }),
    { staleTime: STALE_TIME.TEN_MINUTES, ...options },
  )

  const msaQuery = useQuery(
    [QUERY_CACHE.INSIGHTS.MSAS, { clientId, currentTimestamp }],
    () => getMSAInsights({ clientId }),
    { staleTime: STALE_TIME.TEN_MINUTES, ...options },
  )

  const countryQuery = useQuery(
    [QUERY_CACHE.INSIGHTS.COUNTRY, { clientId, currentTimestamp }],
    () => getCountryInsights({ clientId }),
    {
      staleTime: STALE_TIME.TEN_MINUTES,
      enabled: client?.communicationChannels.includes(CommunicationChannel.WHATS_APP),
      ...options,
    },
  )

  const sevenDayNewMembersQuery = useSevenDayNewMembers({
    clientId,
    mostRecentInsightsDateTime: ageAndGenderQuery?.data?.age_gender_insights?.[0]?.timestamp,
  })

  const demographicInsights = {
    ageAndGenderQuery,
    countryQuery,
    stateQuery,
    msaQuery,
    sevenDayNewMembersQuery,
  }

  return demographicInsights
}

const fetchSevenDayNewMembers = ({ clientId, mostRecentInsightsDateTime }) => {
  const membersSinceLastWeek = {
    operator: SelectorOperators.GREATER_THAN,
    operand: {
      field_key: BuiltInFields.JOINED,
      field_label: fieldLabelFor(BuiltInFields.JOINED),
      source: FieldSources.BUILT_IN,
      type: fieldTypeFor(BuiltInFields.JOINED),
      value: dayjs(new Date(mostRecentInsightsDateTime).getTime() - SEVEN_DAYS).format(
        SUBSCRIPTION_DATA_FILTER_DATETIME_FORMAT,
      ),
    },
  }

  const membersSinceLastRun = {
    operator: SelectorOperators.GREATER_THAN,
    operand: {
      field_key: BuiltInFields.JOINED,
      field_label: fieldLabelFor(BuiltInFields.JOINED),
      source: FieldSources.BUILT_IN,
      type: fieldTypeFor(BuiltInFields.JOINED),
      value: dayjs(mostRecentInsightsDateTime).format(SUBSCRIPTION_DATA_FILTER_DATETIME_FORMAT),
    },
  }

  return Promise.all([
    postCountByQuery({ clientId, filters: membersSinceLastWeek, traceId: 'seven-day-new-members' }),
    postCountByQuery({ clientId, filters: membersSinceLastRun, traceId: 'last-run-new-members' }),
  ]).then(([memberCountSinceLastWeek, memberCountSinceLastRun]) => memberCountSinceLastWeek - memberCountSinceLastRun)
}

const useSevenDayNewMembers = ({ clientId, mostRecentInsightsDateTime }) => {
  // Getting the seven day new member count between the timestamps of
  // $MOST_RECENT_SPARK_JOB_RUN and $MOST_RECENT_SPARK_JOB_RUN_MINUS_7_DAYS
  // requires two queries, since the count-by-query endpoint doesn't support
  // having both a greater_than and a less_than predicate on the inserted_at
  // filter.
  //
  // However, these two individual counts only make sense when combined, so
  // it is advantageous to fetch in parallel and treat them as a single chunk of
  // data with a single isError, isLoading, etc state. We do this via a call to
  // Promise.all():
  const sevenDayNewMembersQuery = useQuery(
    [QUERY_CACHE.SUBSCRIPTION_DATA.COUNT, { clientId, mostRecentInsightsDateTime }],
    () => fetchSevenDayNewMembers({ clientId, mostRecentInsightsDateTime }),
    { enabled: !!mostRecentInsightsDateTime },
  )

  return sevenDayNewMembersQuery
}
