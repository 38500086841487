import { CheckIcon2, SPACING, Typography, WhatsAppIcon } from '@community_dev/pixels'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { acceptLegalTerms } from 'api/legal-text'
import {
  WhatsAppIntegrationStatus,
  getWhatsAppIntegrationStatus,
  postRetryWhatsAppRegistration,
  postWhatsAppRegistration,
} from 'api/whatsapp'
import { BetaBadge } from 'components/BetaBadge'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClient } from 'hooks/useClient'
import useScript from 'hooks/useScript'
import Sentry from 'integrations/Sentry'
import { Setting } from 'layouts/SettingsLayout'
import analytics from 'utils/analytics'

declare global {
  interface Window {
    fbAsyncInit?: typeof fbAsyncInit
    FB?: typeof FB
  }
}

export enum FacebookSignUpStepId {
  BUSINESS_ACCOUNT_SELECTION = 'BUSINESS_ACCOUNT_SELECTION',
  WABA_PHONE_PROFILE_PICKER = 'WABA_PHONE_PROFILE_PICKER',
  WHATSAPP_BUSINESS_PROFILE_SETUP = 'WHATSAPP_BUSINESS_PROFILE_SETUP',
  PHONE_NUMBER_SETUP = 'PHONE_NUMBER_SETUP',
  PHONE_NUMBER_VERIFICATION = 'PHONE_NUMBER_VERIFICATION',
  PERMISSIONS = 'PERMISSIONS',
}

export type FacebookSessionInfo =
  | {
      data: {
        phone_number_id: string
        waba_id: string
      }
      type: 'WA_EMBEDDED_SIGNUP'
      event: 'FINISH_ONLY_WABA'
      version: '2'
    }
  | {
      data: {
        phone_number_id: string
        waba_id: string
      }
      type: 'WA_EMBEDDED_SIGNUP'
      event: 'FINISH'
      version: '2'
    }
  | {
      data: {
        current_step: FacebookSignUpStepId
      }
      type: 'WA_EMBEDDED_SIGNUP'
      event: 'CANCEL'
      version: '2'
    }

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.COLORS.LINKS};
`

const WHATSAPP_LEGAL_TERMS_URL = 'https://www.community.com/legal/whatsapp-beta-terms'
const WHATSAPP_LEGAL_TERMS_ID = 'whatsapp_signup'

enum WhatsAppDisplayStatus {
  authorized = 'authorized',
  pending = 'pending',
  denied = 'denied',
  notStarted = 'not_started',
  otpFailed = 'otp_failed',
}

function WhatsAppAction({ displayStatus }: { displayStatus: WhatsAppDisplayStatus }): JSX.Element {
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  if (displayStatus === WhatsAppDisplayStatus.authorized) {
    return (
      <>
        <CheckIcon2 color={COLORS.SUCCESS} /> {t('settings.integrations.whatsapp.authorized')}
      </>
    )
  }

  if (displayStatus === WhatsAppDisplayStatus.otpFailed) {
    return <>{t('settings.integrations.whatsapp.retry')}</>
  }

  if (displayStatus === WhatsAppDisplayStatus.pending) {
    return <>{t('settings.integrations.whatsapp.pending')}</>
  }

  return <>{t('settings.integrations.whatsapp.authorize')}</>
}

export function WhatsApp(): JSX.Element | null {
  const { data: client } = useClient()
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const [forcePendingState, setForcePendingState] = useState(false)

  const {
    data: whatsAppIntegrationStatus,
    refetch: fetchWhatsAppIntegrationStatus,
    isInitialLoading,
  } = useQuery(
    [QUERY_CACHE.SETTINGS.WHATSAPP_INTEGRATION_STATUS, { clientId: client!.id }],
    () => getWhatsAppIntegrationStatus(client!.id),
    {
      // we have to poll the status, because it can take minutes for an approval
      // to go through.
      refetchInterval: 10000,
      retry: false,
      onError(err: unknown) {
        Sentry.captureException(err)
      },
    },
  )

  const { mutateAsync: postRegistration, isLoading: isPostWhatsAppRegistrationLoading } = useMutation(
    postWhatsAppRegistration,
    {
      mutationKey: [QUERY_CACHE.SETTINGS.WHATSAPP_INTEGRATION_SUBMIT, { clientId: client!.id }],
      retry: 10,
      retryDelay: 10000,
    },
  )

  const { mutateAsync: postRetryRegistration, isLoading: isRetryWhatsAppRegistrationLoading } = useMutation(
    postRetryWhatsAppRegistration,
    {
      mutationKey: [QUERY_CACHE.SETTINGS.WHATSAPP_INTEGRATION_RETRY, { clientId: client!.id }],
    },
  )

  const displayStatus: WhatsAppDisplayStatus = useMemo(() => {
    // If either mutations are loading, show pending state
    if (isPostWhatsAppRegistrationLoading || isRetryWhatsAppRegistrationLoading) {
      return WhatsAppDisplayStatus.pending
    }

    // There is a brief period in which the facebook embedded signup has been completed,
    // but we may not have a status back from the infobip_gateway. In this case, we force
    // the pending state until we get a valid status back.
    if (whatsAppIntegrationStatus?.status === null && forcePendingState) {
      return WhatsAppDisplayStatus.pending
    }

    switch (whatsAppIntegrationStatus?.status) {
      case WhatsAppIntegrationStatus.FINISHED:
        return WhatsAppDisplayStatus.authorized
      case WhatsAppIntegrationStatus.WAITING_FOR_STATUS_UPDATE:
      case WhatsAppIntegrationStatus.IN_PROGRESS:
      case WhatsAppIntegrationStatus.NO_CERTIFICATE:
      case WhatsAppIntegrationStatus.SUBMITTED_FOR_REGISTRATION:
      case WhatsAppIntegrationStatus.OTP_REQUESTED:
        return WhatsAppDisplayStatus.pending
      case WhatsAppIntegrationStatus.PHONE_NUMBER_NOT_VERIFIED:
        return WhatsAppDisplayStatus.otpFailed
      case WhatsAppIntegrationStatus.REJECTED_BY_WHATSAPP:
      case WhatsAppIntegrationStatus.FAILED:
        return WhatsAppDisplayStatus.denied
      default:
        return WhatsAppDisplayStatus.notStarted
    }
  }, [
    isPostWhatsAppRegistrationLoading,
    isRetryWhatsAppRegistrationLoading,
    whatsAppIntegrationStatus?.status,
    forcePendingState,
  ])

  useEffect(() => {
    window.fbAsyncInit = () => {
      if (window.FB !== undefined) {
        window.FB.init({
          appId: import.meta.env.VITE_FACEBOOK_APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v16.0',
        })
      }
    }
  }, [])
  const jsReady = useScript('https://connect.facebook.net/en_US/sdk.js', 'anonymous')

  const openSignupPopup = useCallback(async () => {
    analytics.track(analytics.events.WhatsAppIntegrationStarted())

    // accept the legal terms
    try {
      await acceptLegalTerms({
        type: WHATSAPP_LEGAL_TERMS_ID,
        clientId: client!.id,
      })
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          termsType: WHATSAPP_LEGAL_TERMS_ID,
          termsUrl: WHATSAPP_LEGAL_TERMS_URL,
        },
      })
    }
    // Launch Facebook login
    if (window.FB !== undefined) {
      window.FB.login(
        function (response) {
          // note that this callback also fires and can include an access token
          // even if the flow was cancelled. This is why we’re using the
          // SessionInfoListener below to determine whether the flow was
          // finished.
          if (response.authResponse && response.authResponse.accessToken) {
            // logging the token for debugging purposes
            console.log({ token: response.authResponse.accessToken })
          } else {
            console.log('User cancelled login or did not fully authorize.')
          }
        },
        {
          scope: 'whatsapp_business_management,business_management',
          extras: {
            version: 2,
            sessionInfoVersion: 2,
            feature: 'whatsapp_embedded_signup',
            featureType: 'only_waba_sharing',
            setup: {
              solutionID: import.meta.env.VITE_FACEBOOK_SOLUTION_ID,
              business: {
                phone: {
                  code: 1,
                  number: client!.masterPhoneNumber.substring(2),
                },
              },
            },
          },
        },
      )
    }
  }, [client])

  useEffect(() => {
    const sessionInfoListener = async (event: MessageEvent<string>) => {
      if (import.meta.env.VITE_ENVIRONMENT === 'production' && event.origin !== 'https://www.facebook.com') {
        // In production, we only listen to events from facebook.com. When
        // testing with cypress, we need to allow other origins.
        return
      }

      let data: FacebookSessionInfo
      try {
        data = JSON.parse(event.data) as FacebookSessionInfo
      } catch (e) {
        return
      }

      try {
        // logging for debugging purposes.
        console.log(data, event)
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // if user finishes the Embedded SignUp flow
          if (data.event === 'FINISH_ONLY_WABA') {
            const { waba_id } = data.data

            analytics.track(
              analytics.events.WhatsAppIntegrationCompleted({
                waba_id,
              }),
            )

            setForcePendingState(true)

            await postRegistration({ clientId: client!.id, whatsAppBusinessId: waba_id })
            await fetchWhatsAppIntegrationStatus()
          }
        }
      } catch (e) {
        // Don’t parse info that’s not a JSON
        Sentry.captureException(e, {
          extra: { eventData: event.data },
        })
      }
    }

    window.addEventListener('message', sessionInfoListener)
    // teardown when the component is unmounted
    return () => window.removeEventListener('message', sessionInfoListener)
  }, [client, fetchWhatsAppIntegrationStatus, postRegistration, setForcePendingState])

  const onClickAction = useCallback(async () => {
    if (displayStatus === WhatsAppDisplayStatus.otpFailed) {
      await postRetryRegistration(client!.id)
      await fetchWhatsAppIntegrationStatus()
      return
    }

    openSignupPopup()
  }, [client, displayStatus, fetchWhatsAppIntegrationStatus, openSignupPopup, postRetryRegistration])

  if (isInitialLoading) {
    return null
  }

  return (
    <Setting
      action={<WhatsAppAction displayStatus={displayStatus} />}
      disabled={displayStatus === WhatsAppDisplayStatus.authorized || displayStatus === WhatsAppDisplayStatus.pending}
      icon={<WhatsAppIcon size={20} />}
      label={
        <>
          {t('settings.integrations.whatsapp.title')} <BetaBadge />
        </>
      }
      loading={!jsReady}
      onClickAction={onClickAction}
      text={
        <>
          {displayStatus === WhatsAppDisplayStatus.pending && (
            <Typography
              color={COLORS.SUBTEXT}
              fontWeight="bold"
              marginBottom={0}
              marginTop={SPACING[1]}
              variant="body2"
            >
              {t('settings.integrations.whatsapp.pendingExplainer')}
            </Typography>
          )}
          {displayStatus === WhatsAppDisplayStatus.otpFailed && (
            <Typography
              color={COLORS.SUBTEXT}
              fontWeight="bold"
              marginBottom={0}
              marginTop={SPACING[1]}
              variant="body2"
            >
              {t('settings.integrations.whatsapp.otpFailedExplainer')}
            </Typography>
          )}
          {displayStatus === WhatsAppDisplayStatus.authorized && (
            <Typography color={COLORS.SUBTEXT} marginTop={SPACING[0]} variant="body2">
              <Trans i18nKey="settings.integrations.whatsapp.authorizedExplainer">
                <StyledLink href={'https://www.community.com/client-terms'} rel="noopener noreferrer" target="blank" />
                <StyledLink href={'https://www.community.com/privacy'} rel="noopener noreferrer" target="blank" />
                <StyledLink href={WHATSAPP_LEGAL_TERMS_URL} rel="noopener noreferrer" target="blank" />
              </Trans>
            </Typography>
          )}
          {(displayStatus === WhatsAppDisplayStatus.notStarted || displayStatus === WhatsAppDisplayStatus.denied) && (
            <>
              {displayStatus === WhatsAppDisplayStatus.denied && (
                <Typography color={COLORS.SUBTEXT} marginBottom={0} marginTop={SPACING[1]} variant="body2">
                  <Trans i18nKey="settings.integrations.whatsapp.deniedExplainer">
                    <StyledLink href="mailto:yourfriends@community.com" />
                  </Trans>
                </Typography>
              )}
              <Typography color={COLORS.SUBTEXT} marginTop={SPACING[0]} variant="body2">
                <Trans i18nKey="settings.integrations.whatsapp.description">
                  <StyledLink
                    href={'https://www.community.com/client-terms'}
                    rel="noopener noreferrer"
                    target="blank"
                  />
                  <StyledLink href={'https://www.community.com/privacy'} rel="noopener noreferrer" target="blank" />
                  <StyledLink href={WHATSAPP_LEGAL_TERMS_URL} rel="noopener noreferrer" target="blank" />
                </Trans>
              </Typography>
            </>
          )}
        </>
      }
    />
  )
}
