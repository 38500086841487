import {
  BUTTON_VARIANTS,
  Button,
  Layout,
  Typography,
  Box,
  SPACING,
  LoadingIndicator,
  ChevronLeftIcon,
} from '@community_dev/pixels'
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { PRIVACY_POLICY_HREF, TERMS_OF_SERVICE_HREF } from 'constants/communityLinks'
import { ROUTES } from 'constants/routes'
import { useClientSecret } from 'hooks/billing/useClientSecret'
import { useStripeCustomTheme } from 'hooks/useStripeCustomTheme'
import { useToastMessage } from 'hooks/useToastMessage'

const StyledBoxContainer = styled(Box)`
  margin: 0;
  flex: 1 1 377px;
  > div {
    margin: 0;
    width: 100%;
    //Space around the box
    padding: ${SPACING[6]} ${SPACING[5]};
  }
  margin-bottom: ${SPACING[6]};
`
const StyledLink = styled.a`
  display: inline;
  color: ${({ theme }) => theme.COLORS.LINKS};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const StyledButton = styled(Button)`
  margin-top: ${SPACING[4]};
`

const StyledBack = styled(Link)`
  margin-right: ${SPACING[5]};
`

const StyledEditContainer = styled.div`
  display: flex;
`

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || '')

const CheckoutForm = () => {
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const { showToastMessage } = useToastMessage()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${import.meta.env.VITE_PUBLIC_URL}${ROUTES.SETTINGS.BILLING}`,
      },
    })

    if (error.type === 'card_error' || error.type === 'validation_error') {
      showToastMessage({ message: error.message || t('settings.billing.processingError'), success: false })
    } else {
      showToastMessage({ message: t('settings.billing.genericError'), success: false })
    }

    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Layout display="flex" flexWrap="wrap" gap={SPACING[5]}>
        <Layout flex="1 1 377px">
          <Typography marginBottom={SPACING[6]} marginTop="0" variant="h3">
            {t('settings.billing.editBillingInfo')}
          </Typography>
          <PaymentElement options={{ terms: { card: 'never' } }} />
        </Layout>
        <StyledBoxContainer>
          <Typography color={COLORS?.TEXT} component="span" textAlign="left" variant="caption2" whiteSpace="pre-line">
            <Trans i18nKey="settings.billing.editPaymentLegal">
              <StyledLink href={TERMS_OF_SERVICE_HREF} rel="noreferrer" target="_blank"></StyledLink>
              <StyledLink href={PRIVACY_POLICY_HREF} rel="noreferrer" target="_blank"></StyledLink>
            </Trans>
          </Typography>
          <StyledButton
            disabled={isLoading || !stripe || !elements}
            type="submit"
            variant={BUTTON_VARIANTS.ACTION}
            width="100%"
          >
            {isLoading ? t('settings.billing.processing') : t('billing.confirmPaymentDetails')}
          </StyledButton>
        </StyledBoxContainer>
      </Layout>
    </form>
  )
}

export const EditStripeBilling = (): JSX.Element | null => {
  const { data, isLoading, isError } = useClientSecret()
  const { showToastMessage } = useToastMessage()
  const history = useHistory()
  const { t } = useTranslation()
  const appearance = useStripeCustomTheme()
  if (isLoading) return <LoadingIndicator />

  if (isError) {
    showToastMessage({ message: t('settings.billing.genericError'), success: false })
    history.push(ROUTES.SETTINGS.BILLING)
    return null
  }

  return (
    <StyledEditContainer>
      <StyledBack aria-label="back" to={ROUTES.SETTINGS.BILLING}>
        <ChevronLeftIcon />
      </StyledBack>
      <Elements options={{ clientSecret: data?.clientSecret, appearance }} stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </StyledEditContainer>
  )
}
