import { usePrevious } from '@community_dev/hooks'
import { Tooltip } from '@community_dev/pixels'
import React from 'react'
import styled, { keyframes, useTheme } from 'styled-components'

import { useDataColorGradient } from 'screens/InsightsScreen/useDataColorGradient'

const ChartContainer = styled.div`
  position: relative;
  font-size: 14px;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-column-gap: 16px;
  grid-row-gap: 12px;
`

export const HorizontalBarChart = ({ bars, chartType }: any): JSX.Element => {
  const dataMin = Math.min(...bars.map((b) => b.value))
  const dataMax = Math.max(...bars.map((b) => b.value))
  const xScale = (value) => (value / dataMax) * 90 // don't ever fill the scale
  const colorGradient = useDataColorGradient(dataMin, dataMax)

  if (window.location.search.includes('mockRenderError=yes')) {
    throw new Error('YOLO')
  }
  return (
    <ChartContainer>
      {bars.map((b, i) => (
        <Bar
          chartType={chartType}
          color={colorGradient(b.value)}
          dataLabel={b.label}
          key={i}
          percentage={xScale(b.value)}
          tooltip={b.tooltip}
          valueLabel={b.valueLabel}
        />
      ))}
    </ChartContainer>
  )
}

// this uses a fixed width of 119px per design decision. While individually
// this can result in some odd spacing for charts containing short location
// strings, it allows for non-jarring transitions between datasets since the
// column widths remain constant between charts.
const DataLabel = styled.div`
  text-align: right;
  width: 119px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
`

const BarInner = styled.div`
  transition: width 800ms cubic-bezier(0.22, 1, 0.36, 1), color 800ms ease;
`

const CrossFadeTextContainer = styled.span`
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
`

const easeOutQuint = 'cubic-bezier(0.22, 1, 0.36, 1)'

const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const fadeOutAnimation = keyframes`
  0% { opacity: 1; }
  30% { opacity: 0; }
  100% { opacity: 0; }
`

type CrossFadeTextContentProps = {
  $alignRight?: boolean
  $animate?: boolean
  $visible?: boolean
}

const CrossFadeTextContent = styled.span<CrossFadeTextContentProps>`
  position: absolute;
  ${({ $alignRight }) => ($alignRight ? 'right: 0' : 'left: 0')};
  opacity: 0;
  animation-name: ${({ $visible }) => ($visible ? fadeInAnimation : fadeOutAnimation)};
  animation-duration: ${({ $animate, $visible }) => ($animate ? ($visible ? '1600ms' : '1200ms') : '0ms')};
  animation-fill-mode: forwards;
  animation-timing-function: ${easeOutQuint};
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

type CrossFadeTextProps = {
  alignRight?: boolean
  children?: React.ReactNode
  crossFadeKey?: string
  string?: string
}

const CrossFadeText = ({ string, crossFadeKey: _cfKey, children, alignRight }: CrossFadeTextProps) => {
  const crossFadeKey = _cfKey || string
  const displayedContent = string || children
  const prevKey = usePrevious(crossFadeKey)
  const prevDisplayed = usePrevious(displayedContent)
  const animateIn = prevKey && prevKey !== crossFadeKey
  return (
    <CrossFadeTextContainer>
      {prevKey !== crossFadeKey && (
        <CrossFadeTextContent $alignRight={alignRight} $animate $visible={false} key={prevKey}>
          {prevDisplayed}
        </CrossFadeTextContent>
      )}

      <CrossFadeTextContent $alignRight={alignRight} $animate={animateIn as boolean} $visible key={crossFadeKey}>
        {displayedContent}
      </CrossFadeTextContent>
    </CrossFadeTextContainer>
  )
}

const Bar = ({ dataLabel, percentage, valueLabel, color, chartType, tooltip }) => {
  const { COLORS } = useTheme()
  return (
    <>
      <DataLabel data-testid="data-label">
        <CrossFadeText alignRight string={dataLabel} />
      </DataLabel>
      <Tooltip content={tooltip}>
        <div
          style={{
            height: '20px',
            background: COLORS?.DATA_BACKGROUND,
            borderRadius: '4px',
            overflow: 'hidden',
          }}
        >
          <BarInner
            style={{
              background: color,
              width: percentage + '%',
              height: '100%',
            }}
          />
        </div>
      </Tooltip>
      <div style={{ width: '40px' }}>
        <CrossFadeText crossFadeKey={chartType}>{valueLabel}</CrossFadeText>
      </div>
    </>
  )
}
