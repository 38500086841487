export enum WebhookEventType {
  INBOUND_MESSAGE = 'inbound_message',
  OUTBOUND_MESSAGE = 'outbound_message',
  MEMBER_UPDATE = 'member_update',
}
export enum WebhookStatus {
  WEBHOOK_STATUS_ERRORS = 'WEBHOOK_STATUS_ERRORS',
  WEBHOOK_STATUS_OK = 'WEBHOOK_STATUS_OK',
}
export enum WebhookResponseStatus {
  SUCCESS = 'SUCCESS',
  ERROR_TYPE_CONNECTION_ERROR = 'ERROR_TYPE_CONNECTION_ERROR',
  ERROR_TYPE_REQUEST_ERROR = 'ERROR_TYPE_REQUEST_ERROR',
  ERROR_TYPE_STATUS_CODE = 'ERROR_TYPE_STATUS_CODE',
  ERROR_TYPE_PRIVATE_IP = 'ERROR_TYPE_PRIVATE_IP',
  ERROR_TYPE_OTHER = 'ERROR_TYPE_OTHER',
  ERROR_TYPE_REQUEST_TIMEOUT = 'ERROR_TYPE_REQUEST_TIMEOUT',
}
