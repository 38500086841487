/* eslint-disable no-plusplus */

interface ValidationResult {
  letter: boolean
  number: boolean
  symbol: boolean
  length: boolean
  space: boolean
  valid: boolean
}

export default function validatePassword(password: string): ValidationResult {
  const validations: ValidationResult = {
    letter: false,
    number: false,
    symbol: false,
    length: false,
    space: false,
    valid: false,
  }

  // password length 8+
  if (password.length >= 8) validations.length = true

  // password must not start or end with a space
  if (password[0] !== ' ' && password[password.length - 1] !== ' ') validations.space = true

  // password must have two types of characters (letters, numbers, symbols)
  let characterTypes = 0
  const letters = /[a-zA-Z]+/
  const numbers = /[0-9]+/
  // From OWASP - https://owasp.org/www-community/password-special-characters
  const symbols =
    // eslint-disable-next-line no-useless-escape
    /[\ \#\!\?\&\@\$\%\^\*\(\)\-\_\.\~\`\{\|\}\,\;\:\*\=\[\]\"\'\<\>\+\\\/]+/
  if (password.match(letters)) {
    validations.letter = true
    characterTypes++
  }
  if (password.match(numbers)) {
    validations.number = true
    characterTypes++
  }
  if (password.match(symbols)) {
    validations.symbol = true
    characterTypes++
  }

  // password is overall valid if all above are true
  if (characterTypes >= 3 && validations.length && validations.space) validations.valid = true

  return validations
}
