import { humanizeComplexFilter } from '@community_dev/filter-dsl/lib/humanize/humanizeComplexFilter'
import { digitsFilterToMemberDataFilter } from '@community_dev/filter-dsl/lib/transformers/digitsFilterToMemberDataFilter'
import { convertKeysToSnakeCase } from '@community_dev/requests'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Campaign } from 'api/campaign'
import { Community } from 'api/community'

type UseCampaignTextReturn = {
  sentTo: string
  messageText: string
  createdAt: string
  scheduledAt: string | null
}

export function useCampaignText(campaign: Campaign, communities: Community[]): UseCampaignTextReturn {
  const { t } = useTranslation()
  const { filters, recipientCount, text, createdAt, scheduledAt, communicationChannel, fanSubscriptionTags } = campaign

  const isUnresponsiveMessage = !!filters?.query?.campaignRecipients?.unresponsiveMembers

  const toText = useMemo(() => {
    const convertedFilters = convertKeysToSnakeCase(filters)
    const memberDataFilters = convertedFilters?.subscription_data
      ? convertedFilters.subscription_data
      : digitsFilterToMemberDataFilter(convertedFilters, fanSubscriptionTags)
    return humanizeComplexFilter(memberDataFilters, recipientCount, {
      communicationChannel,
      communities: communities?.reduce((acc, community) => ({ ...acc, [community.id]: { ...community } }), {}),
    })
  }, [filters, recipientCount, communities, communicationChannel, fanSubscriptionTags])

  const reply = false
  const type = reply && !isUnresponsiveMessage ? t('campaigns.repliedTo') : ''
  const sentTo = `${type} ${toText}`

  return {
    sentTo,
    messageText: text || '',
    createdAt,
    scheduledAt,
  }
}
