import React from 'react'
import styled from 'styled-components'

const StyledList = styled.ol`
  text-align: left;
  list-style: none;
  counter-reset: tips-counter;
  margin: 24px 36px 20px;
  padding: 0;

  li {
    position: relative;
    counter-increment: tips-counter;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.176px;
    margin-bottom: 12px;
    padding-left: 28px;

    &::before {
      position: absolute;
      left: 0;
      content: '0' counter(tips-counter);
      margin-right: 12px;
      color: ${({ theme }) => theme?.COLORS?.BORDERS};
      font-weight: 600;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

type TipsProps = {
  items: string[]
}

export function Tips({ items }: TipsProps): JSX.Element {
  return (
    <StyledList>
      {items.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </StyledList>
  )
}
