import {
  AmexCardIcon,
  CreditCardIcon,
  DinersClubCardIcon,
  DiscoverCardIcon,
  JCBCardIcon,
  MasterCardIcon,
  VisaCardIcon,
} from '@community_dev/pixels'

import {
  RECURLY_AMERICAN_EXPRESS,
  RECURLY_DINERS_CLUB,
  RECURLY_DISCOVER,
  RECURLY_JCB,
  RECURLY_MASTER_CARD,
  RECURLY_VISA,
  STRIPE_AMERICAN_EXPRESS,
  STRIPE_DINERS_CLUB,
  STRIPE_DISCOVER,
  STRIPE_JCB,
  STRIPE_MASTER_CARD,
  STRIPE_VISA,
} from './constants'

const cardIcons = {
  [RECURLY_VISA]: VisaCardIcon,
  [RECURLY_MASTER_CARD]: MasterCardIcon,
  [RECURLY_AMERICAN_EXPRESS]: AmexCardIcon,
  [RECURLY_DISCOVER]: DiscoverCardIcon,
  [RECURLY_JCB]: JCBCardIcon,
  [RECURLY_DINERS_CLUB]: DinersClubCardIcon,
  [STRIPE_VISA]: VisaCardIcon,
  [STRIPE_MASTER_CARD]: MasterCardIcon,
  [STRIPE_AMERICAN_EXPRESS]: AmexCardIcon,
  [STRIPE_DISCOVER]: DiscoverCardIcon,
  [STRIPE_JCB]: JCBCardIcon,
  [STRIPE_DINERS_CLUB]: DinersClubCardIcon,
  default: CreditCardIcon,
}

type PaymentIconProps = {
  cardType?: string
  width?: number
  height?: number
}

export const PaymentIcon = ({ cardType = '', width, height }: PaymentIconProps): JSX.Element => {
  return cardIcons[cardType] ? (
    cardIcons[cardType]({ width: width, height: height })
  ) : (
    <cardIcons.default height={height} width={width} />
  )
}
