import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { createContext, useContext, useState } from 'react'

import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { SidebarLinkProps } from 'containers/Sidebar/SidebarLink'
import { baseConfig } from 'utils/config'

type SidebarItems = Record<string, SidebarLinkProps>

type SidebarState = {
  collapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  items: Record<string, SidebarLinkProps>
}

const sidebarItems: SidebarItems = {
  home: {
    id: 'home',
    icon: 'HomeIcon',
    text: 'Home',
    to: ROUTES.HOME,
    exact: true,
  },
  messages: {
    id: 'message',
    icon: 'MessagesIcon',
    text: 'Messages',
    to: ROUTES.MESSAGES.INBOX,
  },
  campaigns: {
    id: 'campaigns',
    icon: 'BackwardsSentIcon',
    text: 'Campaigns',
    to: ROUTES.CAMPAIGNS.ROOT,
  },
  automations: {
    id: 'flows',
    icon: 'TransferIcon',
    text: 'Flows',
    to: ROUTES.AUTOMATIONS.FLOWS,
    requiresCapabilityEnabled: [CAPABILITIES.FEATURE.WORKFLOWS.ALL],
    requiresCommunicationChannel: [CommunicationChannel.SMS],
  },
  communities: {
    id: 'communities',
    icon: 'CommunitiesIcon',
    text: 'Communities',
    to: ROUTES.COMMUNITIES,
  },
  insights: {
    id: 'insights',
    icon: 'InsightsIcon',
    text: 'Insights',
    to: ROUTES.INSIGHTS.ROOT,
  },
  scheduled: {
    id: 'scheduled',
    icon: 'ScheduledIcon',
    text: 'Scheduled',
    to: ROUTES.SCHEDULED.ROOT,
  },
  growthTools: {
    id: 'growth-tools',
    icon: 'ResourcesIcon',
    text: 'Growth Tools',
    to: ROUTES.GROWTH_TOOLS,
  },
  addOns: {
    id: 'add-ons',
    icon: 'SparkleIcon',
    text: 'Add-Ons',
    to: ROUTES.ADD_ONS.ROOT,
    requiresCapabilityEnabled: [CAPABILITIES.FEATURE.ADD_ONS.ALL],
  },
  settings: {
    id: 'settings',
    icon: 'SettingsIcon',
    text: 'Settings',
    to: ROUTES.SETTINGS.ROOT,
  },
  app: {
    id: 'app',
    icon: 'AppStoreIcon',
    text: 'Get the app',
    to: baseConfig.appStoreUrl,
    onlyMobile: true,
  },
}

const SidebarContext = createContext<SidebarState | undefined>(undefined)

export const SidebarProvider = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  const [collapsed, setCollapsed] = useState(false)
  const [opened, setOpened] = useState(false)

  return (
    <SidebarContext.Provider value={{ collapsed, setCollapsed, opened, setOpened, items: sidebarItems }}>
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = (): SidebarState => {
  const sidebar = useContext(SidebarContext)
  if (sidebar === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider')
  }

  return sidebar
}
