import { ApiError } from '@community_dev/requests'
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { useClientId } from './useClient'

import { CreditBundles, getCreditBundles } from 'api/billingUsage'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'

export const useCreditBundlesQuery = <SelectData = CreditBundles>(
  options?: UseQueryOptions<CreditBundles, ApiError, SelectData>,
): UseQueryResult<SelectData, ApiError> => {
  const clientId = useClientId()
  return useQuery({
    queryKey: [QUERY_CACHE.BILLING.CREDIT_BUNDLES, { clientId }],
    queryFn: () => getCreditBundles(clientId),
    staleTime: STALE_TIME.FIVE_MINUTES,
    ...options,
  })
}
