import {
  SelectorOperators,
  BuiltInFields,
  FieldSources,
  FieldTypes,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ApiError } from '@community_dev/requests'
import { WebhookStatus } from '@community_dev/types/lib/api/v1/Webhook'
import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query'

import { postCountByQuery } from 'api/fan-count'
import { getClientEmbeds } from 'api/mgu'
import {
  Webhook,
  WebhookStatisticsResponse,
  WebhookUnsaved,
  WebhooksStatusResponse,
  createWebhook,
  deleteWebhook,
  getWebhookStatistics,
  getWebhooks,
  getWebhooksStatus,
  updateWebhook,
} from 'api/webhooks'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { combinedQueryState } from 'utils/query'

export function useMguQuery(clientId: string, community: any): any {
  const embeds = useQuery([QUERY_CACHE.MGU.EMBEDS, clientId], () => getClientEmbeds(clientId), {
    staleTime: STALE_TIME.ONE_MINUTE,
  })

  const communityCount = useQuery(
    [QUERY_CACHE.MGU.COUNT, { tag: community?.id, clientId }],
    () =>
      postCountByQuery({
        filters: {
          operator: SelectorOperators.EQUALS,
          operand: {
            field_key: BuiltInFields.TAG_ID,
            field_label: community.title,
            source: FieldSources.TAGS,
            type: FieldTypes.UUID,
            value: community.id,
          },
        },
        traceId: 'mgu-count-1',
        clientId,
      }),
    { enabled: !!community?.id },
  )

  return combinedQueryState({ embeds, communityCount })
}

export function useUpdateWebhook(
  clientId: string,
  options: UseMutationOptions<Webhook, ApiError, Webhook>,
): UseMutationResult<Webhook, ApiError, Webhook> {
  return useMutation({
    mutationFn: (webhook: Webhook) => {
      return updateWebhook(clientId, webhook)
    },
    ...options,
  })
}

export function useCreateWebhook(
  clientId: string,
  options?: UseMutationOptions<Webhook, ApiError, WebhookUnsaved>,
): UseMutationResult<Webhook, ApiError, WebhookUnsaved> {
  return useMutation({
    mutationFn: (webhook: WebhookUnsaved) => {
      return createWebhook(clientId, webhook)
    },
    ...options,
  })
}

export function useDeleteWebhook(
  clientId: string,
  options?: UseMutationOptions<unknown, ApiError, Webhook, unknown>,
): UseMutationResult<unknown, ApiError, Webhook, unknown> {
  return useMutation({
    mutationFn: (webhook: Webhook) => {
      return deleteWebhook(clientId, webhook)
    },
    ...options,
  })
}

export function useWebhookStatistics(
  clientId: string,
  webhookId: string | undefined,
  options?: UseQueryOptions<WebhookStatisticsResponse, ApiError>,
): UseQueryResult<WebhookStatisticsResponse, ApiError> {
  const placeholderData = { webhook: { counts: [], lastErrors: [], status: WebhookStatus.WEBHOOK_STATUS_OK } }
  return useQuery({
    queryKey: [QUERY_CACHE.WEBHOOKS.WEBHOOK_STATISTICS, clientId, webhookId],
    enabled: (options?.enabled === true || options?.enabled === undefined) && webhookId !== undefined,
    placeholderData,
    queryFn: () => {
      if (webhookId) {
        return getWebhookStatistics(clientId, webhookId)
      } else {
        // we guard against this case with the enabled prop, so this is just to
        // satisfy the type checker.
        return placeholderData
      }
    },
    ...options,
  })
}

export function useWebhooksStatus(
  clientId: string,
  options?: UseQueryOptions<WebhooksStatusResponse, ApiError>,
): UseQueryResult<WebhooksStatusResponse, ApiError> {
  return useQuery({
    queryKey: [QUERY_CACHE.WEBHOOKS.WEBHOOKS_STATUS, clientId],
    queryFn: () => {
      return getWebhooksStatus(clientId)
    },
    ...options,
  })
}

export function useWebhooks(
  clientId: string,
  options?: UseQueryOptions<Webhook[], ApiError>,
): UseQueryResult<Webhook[], ApiError> {
  return useQuery({
    queryKey: [QUERY_CACHE.WEBHOOKS.WEBHOOKS, clientId],
    queryFn: () => {
      return getWebhooks(clientId).then(({ data }) => data)
    },
    ...options,
  })
}
