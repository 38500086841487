import { BUTTON_VARIANTS, Button, SPACING } from '@community_dev/pixels'
import React from 'react'
import styled from 'styled-components'

import { MOBILE_BREAK } from 'constants/theme'

const VisualFormSectionStyle = styled.div`
  max-width: 424px;

  @media (max-width: ${MOBILE_BREAK}) {
    max-width: 100%;
  }
`

const StyledImage = styled.img`
  margin: 0;
  padding: 0;
  margin-bottom: ${SPACING[5]};
  max-width: 424px;

  @media (max-width: ${MOBILE_BREAK}) {
    max-width: 100%;
  }
`

export const StyledTitle = styled.h2`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  padding: 0;
  margin: 0;
  margin-bottom: ${SPACING[4]};
`

type StyledFooterProps = {
  $multiple?: boolean
}

export const StyledFooter = styled.div<StyledFooterProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ $multiple }) => ($multiple ? 'space-between' : 'flex-end')};
  margin-top: ${SPACING[5]};
`

type VisualFormSectionProps = {
  image: string
  title: string
  backButton?: string
  backButtonDisabled?: boolean
  backButtonClick?: any
  primaryButton: string
  primaryButtonClick: any
  primaryButtonDisabled?: boolean
  children: React.ReactChild
}

export const VisualFormSection = (props: VisualFormSectionProps): JSX.Element => {
  return (
    <VisualFormSectionStyle>
      <StyledImage src={props.image} />
      <StyledTitle>{props.title}</StyledTitle>

      <div>{props.children}</div>

      <StyledFooter $multiple={!!props.backButton}>
        {props.backButton && (
          <Button disabled={props.backButtonDisabled} onClick={props.backButtonClick} variant={BUTTON_VARIANTS.OUTLINE}>
            {props.backButton}
          </Button>
        )}
        <Button disabled={props.primaryButtonDisabled} onClick={props.primaryButtonClick}>
          {props.primaryButton}
        </Button>
      </StyledFooter>
    </VisualFormSectionStyle>
  )
}
