import { ArrowRightIcon, Typography } from '@community_dev/pixels'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { ROUTES } from 'constants/routes'
import { LocationChartBox } from 'screens/InsightsScreen/boxes/LocationChartBox'
import { InsightsHeader } from 'screens/InsightsScreen/presentation/InsightsHeader'

const Flip = styled.span`
  display: inline-block;
  vertical-align: top;
  transform: rotate(180deg);
  margin-bottom: 35px;
`

export const InsightsLocationView = ({ demographicInsights }: any): JSX.Element => {
  const { COLORS } = useTheme() || {}
  return (
    <>
      <InsightsHeader>
        <Link to={ROUTES.INSIGHTS.ROOT}>
          <Flip>
            <ArrowRightIcon color={COLORS?.TEXT} size={20} />
          </Flip>
        </Link>
        <Typography variant="h1">Top Locations</Typography>
      </InsightsHeader>
      <LocationChartBox demographicInsights={demographicInsights} footer={false} height="auto" limit={-1} />
    </>
  )
}
