import React from 'react'
import styled from 'styled-components'

const StyledEmpty = styled.div`
  margin-top: 16px;
  text-align: center;
  padding-bottom: 500px;

  div:first-child {
    font-size: larger;
  }
`

export default function EmptyResponse(): JSX.Element {
  return (
    <StyledEmpty>
      <div>No Responses Yet</div>
      <div>Responses will appear here</div>
    </StyledEmpty>
  )
}
