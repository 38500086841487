import {
  ArrowCircleIcon,
  BUTTON_VARIANTS,
  Button,
  ButtonProps,
  DIALOG_VARIANTS,
  Dialog,
  FONT_WEIGHT,
  PenIcon,
  SPACING,
  Tooltip,
  TrashIcon,
  Typography,
} from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { StyledTooltipContent } from './styled'
import { RoleList } from './TeamMemberRoleList'

import { deleteInvitation, sendInvitation } from 'api/invite'
import { deleteClientSeat } from 'api/team-seats'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERY_CACHE } from 'constants/query-cache'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { Invitation } from 'generators/invitations'
import { useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import { Setting } from 'layouts/SettingsLayout'

const StyledButton = styled(Button).attrs({ variant: BUTTON_VARIANTS.OUTLINE })<{ disabled?: boolean }>`
  min-width: 34px;
  width: 34px;
  height: 34px;
  line-height: 14px;
  padding: 6px 12px;
  margin-right: ${SPACING[1]};
  margin-left: ${SPACING[1]};
  visibility: ${({ disabled }) => (disabled ? 'hidden' : 'initial')};
`

function IconButton(props: ButtonProps & { label: string }): JSX.Element {
  return (
    <Tooltip content={<StyledTooltipContent>{props.label}</StyledTooltipContent>}>
      <StyledButton {...props} aria-label={props.label} />
    </Tooltip>
  )
}

export function TeamMemberRow({
  item,
  onClick,
}: {
  item: CamelCasedPropertiesDeep<Api.V1.Seat> | Invitation
  onClick?: React.EventHandler<React.MouseEvent>
}): JSX.Element {
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data: seat } = useSeat()
  const clientId = useClientId()
  const { showToastMessage } = useToastMessage()

  const seatHasSeatsManagement = useHasCapability(CAPABILITIES.CLIENT.SEATS.WRITE)
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)

  const isSeat = (item: any): item is CamelCasedPropertiesDeep<Api.V1.Seat> => 'seatMappingId' in item
  const hasProtectedRole = isSeat(item) && item.roles.some((role) => role.protected === true)
  const isSelf = isSeat(item) && item.id === seat?.id
  const canEditSeat = seatHasSeatsManagement && !isSelf

  const { mutateAsync: deleteSeat, isLoading: isDeletingSeat } = useMutation({
    mutationFn: (seat: CamelCasedPropertiesDeep<Api.V1.Seat>) => deleteClientSeat(clientId, seat.id),
    onError() {
      showToastMessage({
        message: t('settings.team.errorDeleteSeat'),
        success: false,
      })
    },
    onSuccess() {
      queryClient.invalidateQueries([QUERY_CACHE.SETTINGS.TEAM_SEATS])
    },
  })

  const { mutateAsync: resendInvitation, isLoading: isResendingInvitation } = useMutation({
    mutationFn: (invite: Invitation) => sendInvitation({ clientId, email: invite.email, role: invite.role.name }),
    onError() {
      showToastMessage({
        success: false,
        message: t('settings.team.errorSendInvite'),
      })
    },
    onSuccess(_, variables) {
      showToastMessage({
        message: t('settings.team.sendInviteSuccess', { email: variables.email }),
      })
    },
  })

  const { mutateAsync: revokeInvitation, isLoading: isDeletingInvitation } = useMutation({
    mutationFn: (invite: Invitation) => deleteInvitation({ clientId, invitationId: invite.id }),
    onError() {
      showToastMessage({
        message: t('settings.team.errorDeleteInvite'),
        success: false,
      })
    },
    onSuccess() {
      showToastMessage({
        message: t('settings.team.deleteInviteSuccess'),
      })
      queryClient.invalidateQueries([QUERY_CACHE.SETTINGS.TEAM_INVITES])
    },
  })

  const isDeleteDisabled = !canEditSeat || isSelf || isDeletingSeat || isDeletingInvitation || hasProtectedRole

  return (
    <>
      {isDeleteDialogVisible && (
        <Dialog
          message={isSeat(item) ? t('settings.team.deleteSeatExplainer') : t('settings.team.deleteInviteExplainer')}
          onCancel={() => setIsDeleteDialogVisible(false)}
          title={isSeat(item) ? t('settings.team.deleteMember') : t('settings.team.deleteInvite')}
        >
          <Dialog.Action onClick={() => setIsDeleteDialogVisible(false)}>{t('cancel')}</Dialog.Action>
          <Dialog.Action
            onClick={() => {
              if (isSeat(item)) {
                deleteSeat(item)
              } else {
                revokeInvitation(item)
              }
              setIsDeleteDialogVisible(false)
            }}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            {t('delete')}
          </Dialog.Action>
        </Dialog>
      )}
      <Setting
        action={
          <>
            <Typography
              color={COLORS.TEXT}
              display="inline-block"
              marginRight={SPACING[4]}
              marginTop={SPACING[2]}
              variant="body2"
            >
              {isSeat(item) ? <RoleList roles={item.roles} /> : t('settings.team.pendingInvite')}
            </Typography>
            <IconButton
              disabled={isDeleteDisabled}
              icon={<TrashIcon />}
              label={isSeat(item) ? t('settings.team.deleteMember') : t('settings.team.deleteInvite')}
              onClick={(e) => {
                e.stopPropagation()
                setIsDeleteDialogVisible(true)
              }}
            />
            {isSeat(item) && (
              <IconButton
                disabled={!seatHasSeatsManagement || (isSelf && !hasProtectedRole)}
                icon={<PenIcon />}
                label={t('settings.team.editMember')}
                onClick={onClick}
              />
            )}
            {!isSeat(item) && (
              <IconButton
                disabled={isResendingInvitation || !canEditSeat}
                icon={<ArrowCircleIcon size={11} />}
                label={t('settings.team.resendInvite')}
                onClick={() => resendInvitation(item)}
              />
            )}
            {isSeat(item) && !seatHasSeatsManagement && (
              <Typography display="inline-block" fontWeight={FONT_WEIGHT[6]} textAlign="right" variant="body2">
                View
              </Typography>
            )}
          </>
        }
        actionAs="div"
        label={
          isSeat(item) ? (
            `${item.firstName} ${item.lastName} ${isSelf ? '(me)' : ''}`
          ) : (
            <Typography color={COLORS.SUBTEXT} fontWeight={600}>
              Invitation Sent
            </Typography>
          )
        }
        onClick={onClick}
        text={
          <Typography color={COLORS.SUBTEXT} marginTop={0} variant="body2">
            {item.email}
          </Typography>
        }
      />
    </>
  )
}
