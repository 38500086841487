import { InfiniteScroll, ThinkingFlag } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { FanStateFlag } from '@community_dev/types/lib/api/Fan'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { StyledCenteredContainer, StyledCenteredCopy } from './Communities.style'

import InboxItem from 'components/InboxItem'
import { ConvoModal } from 'containers/ConvoModal'
import { useInbox } from 'contexts/InboxProvider'
import { useUpdateChat } from 'hooks/useUpdateChat'

const StyledInboxItemBody = styled.div`
  &&& {
    width: 100%;
    padding: 0 56px 0 0;
  }
`

type MessagesProps = {
  communicationChannel?: CommunicationChannel
}

export default function Messages({ communicationChannel }: MessagesProps): JSX.Element | null {
  const { COLORS } = useTheme() || {}
  const { mutateAsync: updateChat } = useUpdateChat()
  const { items, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInbox()
  const [viewingFanConvo, setViewingFanConvo] = useState<string | null>(null)

  const onItemClick = (item) => () => {
    setViewingFanConvo(item.fan.id)
    if (item.unread) {
      updateChat({
        fanId: item.fan.id,
        fanSubscriptionId: item.fan.fanSubscriptionId,
        chat: {
          has_new: false,
        },
      })
    }
  }

  if (!items || (items.length === 0 && !isLoading)) {
    return (
      <StyledCenteredContainer>
        <ThinkingFlag color={COLORS?.BORDERS} size={150} />
        <StyledCenteredCopy>This community has no messages</StyledCenteredCopy>
      </StyledCenteredContainer>
    )
  }

  const renderItems = () => {
    return items?.map((item) => {
      const { id, unread, incoming } = item
      const { fullName, graphicUrl, initials, stateFlags } = item.fan
      const { body, media, ts } = item.last

      const canReply = stateFlags?.includes(FanStateFlag.CAN_RECEIVE_DMS)

      return (
        <InboxItem
          {...{
            canReply,
            id,
            body,
            fullName,
            graphicUrl,
            incoming,
            initials,
            ts,
            unread,
            media,
            fan: item.fan,
            communicationChannel,
            key: id,
            onItemClick: onItemClick(item),
            multilineDensity: 3,
            renderBody: (body) => <StyledInboxItemBody>{body}</StyledInboxItemBody>,
          }}
        />
      )
    })
  }

  return (
    <>
      {viewingFanConvo && <ConvoModal fanId={viewingFanConvo} onClose={() => setViewingFanConvo(null)} />}
      <InfiniteScroll
        data-testid="community-messages-list"
        hasMore={isFetchingNextPage || hasNextPage}
        loadMore={fetchNextPage}
        loading={isLoading || isFetchingNextPage}
        threshold={100}
      >
        {renderItems()}
      </InfiniteScroll>
    </>
  )
}
