type TrackData = Record<string, any>

type AppcuesType = {
  page(): void
  track(event: string, data?: TrackData): void
  identify(id: string, user: TrackData): void
}

declare global {
  interface Window {
    Appcues: AppcuesType
  }
}

export const getAppcues = (fn: (aq: AppcuesType) => void): void => {
  if (!window.Appcues) return
  fn(window.Appcues)
}

export function page(): void {
  getAppcues((aq) => aq.page())
}

export function track({ event, properties }: { event: string; properties: TrackData }): void {
  getAppcues((aq) => aq.track(event, properties))
}

export function identify(id: string, user: TrackData): void {
  getAppcues((aq) => aq.identify(id, user))
}

export function init(): void {
  const appcuesId = 104591
  const script = document.createElement('script')
  script.setAttribute('src', `//fast.appcues.com/${appcuesId}.js`)
  document?.documentElement?.firstChild?.appendChild(script)
}

export const Appcues = {
  identify,
  init,
  page,
  track,
}
