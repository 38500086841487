import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { Timezone, getTimezones } from 'api/timezones'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

export function useTimezones():
  | UseQueryResult<Timezone[]> & {
      timezones?: Timezone[]
      communityLocalTimezone?: Timezone
      localTimezone: Timezone
    } {
  const clientId = useClientId()
  const localTimeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
  const result = useQuery([QUERY_CACHE.TIMEZONES, { localTimeZoneId }], () => getTimezones({ localTimeZoneId }), {
    staleTime: STALE_TIME.ONE_HOUR,
    enabled: !!clientId,
  })

  const localTimezone = result.data ? result.data.find((tz) => tz.local) : undefined

  return {
    ...result,
    timezones: result.data,
    communityLocalTimezone: localTimezone || {
      representativeTzid: localTimeZoneId,
    },
    localTimezone: { representativeTzid: localTimeZoneId },
  }
}
