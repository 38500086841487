import { Api } from '@community_dev/types'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { ResponseTags } from '@community_dev/types/lib/api/v2/Campaign'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { Fan } from 'api/fans'
import { ChainBubble } from 'components/ChainBubble'
import { VirtualList, VirtualListHandle } from 'components/VirtualList'
import { ConvoModal } from 'containers/ConvoModal'
import { canReceiveCampaigns } from 'hooks/useCanSendDms'
import dayjs from 'utils/dayjs'
import { CampaignInsight, normalizeMedia } from 'utils/normalize'

export const REPLIED_TO = 'replied-to'

type ItemProps = {
  items: CamelCasedPropertiesDeep<Api.V2.CampaignResponses>[]
  tapbacks: CampaignInsight[]
  onMemberSelect?: (fan: Fan) => void
}

type Bubbles = {
  timeStamp: string
  bubbles: JSX.Element[]
}

export const Items = ({ items, tapbacks, onMemberSelect }: ItemProps): JSX.Element => {
  const [viewingFanConvo, setViewingFanConvo] = useState<null | string>(null)
  const virtualRef = useRef<VirtualListHandle>(null)
  const { t } = useTranslation()

  const bubbles = items?.reduce<Bubbles>(
    (accumulator, item) => {
      if (!item) return accumulator
      const itemTimeStamp = dayjs(item.createdAt)
      const prevTimeStamp = accumulator?.timeStamp
      const shouldShowTime = !prevTimeStamp || itemTimeStamp.diff(prevTimeStamp, 'hours', true) < -1
      const {
        fanProfile: { displayName: fullName, communicationChannel, stateFlags },
      } = item
      const repliedTo = item.tags.includes(ResponseTags.REPLIED_TO)
      const bubbleProps = { id: item.id, media: normalizeMedia(item.media), ts: item.createdAt }

      const allowSelection = canReceiveCampaigns({ communicationChannel, stateFlags })

      const disabledAmbReason =
        !allowSelection && communicationChannel === CommunicationChannel.AMB
          ? t('convo.ambMessagingDisabled')
          : undefined

      return {
        timeStamp: item.createdAt,
        bubbles: [
          ...accumulator.bubbles,
          <ChainBubble
            allowSelection={allowSelection}
            body={item.text}
            bubbleProps={bubbleProps}
            disabledSelectionReason={disabledAmbReason}
            fan={item.fanProfile}
            fanSubscriptionId={item.fanSubscriptionId}
            incoming
            key={item.id}
            onTitleClick={onMemberSelect}
            openConvoModal={(fan: Fan) => setViewingFanConvo(fan.id)}
            repliedTo={repliedTo}
            showTime={shouldShowTime}
            showUser
            tapbacks={tapbacks}
            title={fullName}
            ts={item.createdAt}
          />,
        ],
      }
    },
    {
      timeStamp: '',
      bubbles: [],
    },
  )?.bubbles

  return (
    <>
      {viewingFanConvo && <ConvoModal fanId={viewingFanConvo} onClose={() => setViewingFanConvo(null)} />}
      <VirtualList ref={virtualRef} rows={bubbles} testId="items-list">
        {({ virtualRow }) => {
          const bubble = bubbles[virtualRow.index]
          return <>{bubble}</>
        }}
      </VirtualList>
    </>
  )
}
