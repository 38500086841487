import { Dayjs } from 'dayjs'

import { PT_TIME_ZONE } from 'constants/date'
import dayjs from 'utils/dayjs'

type AmPm = 'am' | 'pm'

type GenerateHourRangeArgs = {
  today: Dayjs
  timezone: string
}

type GenerateHourRangeReturn = {
  amPm: AmPm
  hour: number
}[]

export const generateHourRange = ({ today, timezone }: GenerateHourRangeArgs): GenerateHourRangeReturn => {
  return Array(24)
    .fill(null)
    .map((val, idx) => idx)
    .filter((hour) => {
      const hourOfDay = dayjs(today).hour(hour).tz(PT_TIME_ZONE).format('HH')

      const hourOfDayNumber = Number.parseInt(hourOfDay)

      return hourOfDayNumber >= 6 && hourOfDayNumber <= 19
    })
    .map((hour) => {
      const timestamp = dayjs.tz(today, timezone).hour(hour).minute(0)

      return {
        amPm: timestamp.format('h a').split(' ')[1] as AmPm,
        hour: Number(timestamp.format('h a').split(' ')[0]),
      }
    })
}

type GenerateDaysArgs = {
  today: Dayjs
}

type GenerateDaysReturn = Dayjs[][]

export const generateDays = ({ today }: GenerateDaysArgs): GenerateDaysReturn => {
  const daysOfWeek = Array(7)
    .fill(null)
    .map((_, i) => dayjs(today).day(i + 1))

  const days = daysOfWeek.reduce<Dayjs[][]>((acc, day) => {
    const hours = Array(24)
      .fill(null)
      .map((_, i) => dayjs(day).hour(i).minute(0).second(0))
    acc.push(hours)
    return acc
  }, [])

  return days
}
