import { FONT_SIZE } from '@community_dev/pixels'
import Prism from 'prismjs'
import 'prismjs/themes/prism-okaidia.min.css'
import { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

type CodeProps = {
  children: string
  language?: string
  wrap?: boolean
  className?: string
}

const StyledText = styled.div<{ $wrap?: boolean }>`
  font-size: ${FONT_SIZE[3]};

  code[class*='language-'],
  pre[class*='language-'] {
    ${({ $wrap }) =>
      $wrap &&
      css`
        white-space: normal !important;
        word-break: break-word !important;
      `}
  }
`

export const Code = ({ children, language = 'html', wrap = false, className }: CodeProps): JSX.Element => {
  const codeRef = useRef<HTMLElement | null>()

  useEffect(() => {
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current)
    }
  }, [children, language])

  return (
    <StyledText $wrap={wrap} className={className}>
      <pre className={'language-' + language}>
        <code ref={(ref) => (codeRef.current = ref)}>{children}</code>
      </pre>
    </StyledText>
  )
}
