import { FONT_SIZE, SPACING } from '@community_dev/pixels'
import React from 'react'
import styled from 'styled-components'

const StyledDot = styled.span<{ $color: string }>`
  color: ${({ $color }) => $color};
  font-size: ${FONT_SIZE[5]};
  padding-right: ${SPACING[1]};
`

const StyledText = styled.span`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  font-size: ${FONT_SIZE[3]};
`

type CommunityNameProps = {
  color: string
  children: string
}

export const CommunityName = ({ color, children }: CommunityNameProps): JSX.Element => {
  return (
    <StyledText>
      <StyledDot $color={color}>&bull;</StyledDot>
      {children}
    </StyledText>
  )
}
