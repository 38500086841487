import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useQuery } from '@tanstack/react-query'
import { Dayjs } from 'dayjs'
import last from 'lodash/last'
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'

import { SearchResults, postSearchIndexByQuery, SearchResult } from 'api/search'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { SEARCH_SORT } from 'constants/search'
import { useQueryParams } from 'hooks/useQueryParams'
import { i18n } from 'i18n'
import analytics from 'utils/analytics'
import dayjs from 'utils/dayjs'
import { combinedQueryState } from 'utils/query'
import { route } from 'utils/router'

const PAGE_SIZE = 25

function createEmptyDate(date: Dayjs, method: 'add' | 'subtract', number: number, interval: 'hours' | 'days') {
  return {
    messageTimestamp: date[method](number, interval).valueOf(),
    messagesCount: 0,
  }
}

type SearchParams = {
  term?: string
  sort?: string
  exact?: boolean
  page?: number
  start?: string | null
  end?: string | null
  communicationChannel?: CommunicationChannel
}

export type UseSearch = {
  allMembers: SearchResult[]
  changeParams: (params: Partial<SearchParams>) => void
  end?: string
  error: any
  exact: boolean
  communicationChannel: CommunicationChannel
  histogram?: any
  histogramIsLoading: boolean
  nextPage?: string
  page: number
  pageSize: number
  previousPage?: string
  search: string
  searchIsLoading: boolean
  searchResults?: SearchResults
  sort: string
  start?: string
}

export function useSearch(): UseSearch {
  const history = useHistory()
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const params = useQueryParams()
  const communicationChannel = params.communicationChannel || CommunicationChannel.SMS
  const search = params.term
  const exact = params.exact === 'true' || false
  const page = parseInt(params.page, 10) || 1
  const sort = params.sort || SEARCH_SORT.NEWEST
  const start = params.start || dayjs().subtract(90, 'days').toISOString()
  const end = params.end || dayjs().toISOString()

  useEffect(() => {
    if (
      !params.term ||
      !params.start ||
      !params.end ||
      !params.sort ||
      !params.exact ||
      !params.communicationChannel ||
      params.page
    ) {
      const url = route(
        ROUTES.SEARCH,
        {},
        {
          page,
          term: search,
          exact,
          sort,
          start,
          communicationChannel,
          end,
        },
      )

      history.replace(url)
    }
  }, [end, exact, history, page, params, search, sort, start, communicationChannel])

  const searchResults = useQuery(
    [QUERY_CACHE.SEARCH_RESULTS, { search, exact, page, sort, start, end, communicationChannel }],
    () =>
      postSearchIndexByQuery(
        {
          search_query: search,
          exact_match: exact,
          start_datetime: start,
          end_datetime: end,
          communication_channel: communicationChannel,
          sort_by: sort,
          with_timestamp_histogram: false,
        },
        page,
      ),
    {
      enabled: Boolean(search),
      staleTime: STALE_TIME.FIVE_SECONDS,
    },
  )

  useEffect(() => {
    if (!searchResults.isInitialLoading && searchResults.data) {
      const { term } = params
      const { totalResults } = searchResults.data.data
      analytics.track(
        analytics.events.FullTextSearchRun({
          termSearched: term,
          resultsFound: totalResults,
        }),
      )
    }
  }, [params, searchResults.data, searchResults.isInitialLoading])

  const histogram = useQuery(
    [QUERY_CACHE.SEARCH_HISTOGRAM, { search, exact, start, end, localTimeZone }],
    () =>
      postSearchIndexByQuery({
        search_query: search,
        exact_match: exact,
        start_datetime: start,
        end_datetime: end,
        timezone: localTimeZone,
        communication_channel: communicationChannel,
        with_timestamp_histogram: true,
      }).then(({ data }) => {
        if (!data.timestampHistogram) return data.timestampHistogram

        const histogram = data.timestampHistogram
        const histogramInterval = histogram.interval
        const interval = histogramInterval === '1h' ? 'hours' : 'days'
        const numberOfIntervals = histogramInterval === '1h' ? 24 : dayjs(end).diff(start, 'days')

        if (histogram.buckets.length >= numberOfIntervals) {
          return histogram
        }

        const lastBucket = dayjs(last(histogram.buckets)?.messageTimestamp)
        const firstBucket = dayjs(histogram.buckets[0]?.messageTimestamp)
        const diff = dayjs(end).diff(lastBucket, interval)
        const requiredEmptyBuckets = numberOfIntervals - histogram.buckets.length
        const numberToAdd = diff > requiredEmptyBuckets ? diff - requiredEmptyBuckets : diff

        for (let i = 0; i < numberToAdd; i++) {
          histogram.buckets.push(createEmptyDate(lastBucket, 'add', i + 1, interval))
        }

        const padStart = numberOfIntervals - histogram.buckets.length

        for (let i = 0; i < padStart; i++) {
          histogram.buckets.unshift(createEmptyDate(firstBucket, 'subtract', i + 1, interval))
        }

        return histogram
      }),
    {
      enabled: Boolean(search),
      staleTime: STALE_TIME.FIVE_SECONDS,
    },
  )

  const allMembers = useMemo(() => {
    return searchResults.data ? searchResults.data.data.results : []
  }, [searchResults])

  const queryState = combinedQueryState({
    searchResults,
    histogram,
  })

  let error: any = undefined
  if (!search) {
    error = new Error(i18n.t('search.noSearchTerm'))
  }
  if (queryState.isError) {
    error = searchResults.error || histogram.error
  }

  function changeParams(params: SearchParams) {
    // If the term changes, then let's reset the time range
    if (params.term) {
      params.start = dayjs().subtract(90, 'days').toISOString()
      params.end = dayjs().toISOString()
    }

    history.push(
      route(
        ROUTES.SEARCH,
        {},
        {
          term: search,
          exact,
          communicationChannel,
          sort,
          start,
          end,
          ...params,
        },
        true,
      ),
    )
  }

  let nextPage: string | undefined
  let previousPage: string | undefined
  if (searchResults.data?.paginationData.hasNextPage) {
    nextPage = route(
      ROUTES.SEARCH,
      {},
      {
        page: searchResults.data.paginationData.pageNumber + 1,
        term: search,
        communicationChannel,
        exact,
        sort,
        start,
        end,
      },
      true,
    )
  }

  if (page > 1) {
    previousPage = route(
      ROUTES.SEARCH,
      {},
      {
        page: page - 1,
        term: search,
        communicationChannel,
        exact,
        sort,
        start,
        end,
      },
      true,
    )
  }

  return {
    allMembers,
    changeParams,
    end,
    communicationChannel,
    error,
    exact,
    histogram: queryState.queries.histogram.data,
    histogramIsLoading: histogram.isInitialLoading,
    nextPage,
    page,
    pageSize: PAGE_SIZE,
    previousPage,
    search,
    searchIsLoading: searchResults.isInitialLoading,
    searchResults: queryState.queries.searchResults.data,
    sort,
    start,
  }
}
