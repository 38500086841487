import { Api } from '@community_dev/types'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type PostCreateMediaResponse = {
  media: Api.V2.Media
  upload_url: string
}

export function postCreateMedia({ clientId }: { clientId: string }): Promise<PostCreateMediaResponse> {
  return request(route(ENDPOINTS.MEDIA.CREATE, { clientId }), {
    camelCase: false,
    method: 'POST',
  })
}

export type GetMediaResponse = {
  media: Api.V2.Media
}

export function getMedia({ clientId, mediaId }: { clientId: string; mediaId: string }): Promise<GetMediaResponse> {
  return request(route(ENDPOINTS.MEDIA.GET, { clientId, mediaId }), {
    camelCase: false,
    method: 'GET',
  })
}
