import { SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

const StyledUsageCounterContainer = styled.div`
  color: ${({ theme }) => theme?.COLORS?.DIVIDERS};
  display: flex;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
`

const StyledCharacterLimit = styled.div`
  padding: 0 ${SPACING[1]};
`

type CharacterCounterProps = {
  count: number
  max?: number
}

export function CharacterCounter({ count = 0, max = 160 }: CharacterCounterProps): JSX.Element {
  return (
    <StyledUsageCounterContainer data-testid="character-counter">
      <StyledCharacterLimit data-testid="max-length">{`${count}/${max}`}</StyledCharacterLimit>
    </StyledUsageCounterContainer>
  )
}
