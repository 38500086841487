export const ROUTES = {
  ACTIVATE_ACCOUNT: '/activate/:token',
  ADD_ONS: {
    ROOT: '/add-ons',
    VIEW: '/add-ons/:addOn',
  },
  AI_MESSAGE_GENERATOR: {
    HASH: '#ai-message-generator',
  },
  AUTOMATIONS: {
    FLOW: '/automations/flows/:workflowId/edit',
    FLOWS: '/automations/flows',
    FLOWS_ADD_KEYWORD_RESPONSE: '/automations/flows/new/keyword-response',
    FLOWS_EDIT_KEYWORD_RESPONSE: '/automations/flows/:workflowId/edit/trigger/:triggerName/keywords',
    FLOWS_NEW: '/automations/flows/new',
    FLOWS_NEW_KEYWORD_RESPONSE: '/automations/flows/new/keyword-response',
    FLOW_ADD_STEP: '/automations/flows/:workflowId/add/:functionKey/:functionIndex',
    FLOW_ADD_STEP_ADD_TO_COMMUNITY: '/automations/flows/:workflowId/add/add-to-community',
    FLOW_ADD_STEP_SMS_MESSAGE: '/automations/flows/:workflowId/add/message',
    FLOW_EDIT: '/automations/flows/:workflowId/edit',
    FLOW_EDIT_ONBOARDING_MESSAGE: '/automations/flows/:workflowId/edit/onboarding/:messageType',
    FLOW_EDIT_STEP: '/automations/flows/:workflowId/edit/:functionKey/:functionIndex',
    FLOW_EDIT_TRIGGER: '/automations/flows/:workflowId/edit/trigger/:triggerName',
    FLOW_PERFORMANCE: '/automations/flows/:workflowId/performance',
    ROOT: '/automations',
  },
  CAMPAIGNS: {
    CLUSTER: '/campaigns/:campaignId/cluster/:clusterId/:selectedFilter',
    DETAILS: '/campaigns/:campaignId',
    ROOT: '/campaigns',
  },
  CAMPAIGN_DETAILS: '/campaigns/:campaignId',
  CHANGE_PASSWORD: '/reset-password/:token',
  CLIENT_SELECTION: '/client-selection',
  COMMUNITIES: '/communities',
  COMMUNITY: '/communities/:id',
  COMPOSE: {
    HASH: '#compose',
    HASH_APP_CUES: '#compose/?ac=ai_generator',
  },
  CONFIRM_PAYMENT_DETAILS: {
    ROOT: '/confirm-payment-details',
    SETUP_INTENT: '/confirm-payment-details/setup-intent',
  },
  DASHBOARD: '/',
  GROWTH_TOOLS: '/growth-tools',
  HOME: '/',
  IMPERSONATION: '/impersonate',
  INSIGHTS: {
    LOCATION_DETAIL: '/insights/geography',
    ROOT: '/insights',
    SHOPIFY: '/insights/shopify',
  },
  INVITE: '/invite',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MEMBER_DETAIL_ACTION_PANEL: {
    ADD_TO_COMMUNITY: '/member-detail-action-panel/add-to-community',
    ROOT: '/member-detail-action-panel',
    VIEW_ALL_CMD: '/member-detail-action-panel/view-all-cmd',
  },
  MESSAGES: {
    DIRECT_MESSAGE: '/messages/:fanId',
    FAN_INBOX: '/messages/inbox/:fanId',
    INBOX: '/messages/inbox',
    ROOT: '/messages',
  },
  MFA: '/mfa',
  ONBOARDING: {
    CONFIRMATION_MESSAGE: '/onboarding/confirmation-message',
    CONTACT_PHOTO: '/onboarding/contact-photo',
    CONTACT_US: {
      PAST_DUE_INVOICES: '/contact-us/past-due-invoices',
      ROOT: '/contact-us',
    },
    GET_THE_APP: '/gettheapp',
    NAME: '/onboarding/name',
    ROOT: '/onboarding',
    SUMMARY: '/onboarding/summary',
    VOICEMAIL: '/onboarding/voicemail',
    VOICEMAIL_RECORD: '/onboarding/voicemail/record',
    WELCOME_MESSAGE: '/onboarding/welcome-message',
  },
  PRICING: {
    CONTACT_US: '/upgrade/contact-us',
  },
  RECIPIENT_PANE: {
    ACTIVITY: '/recipient-pane/activity',
    AGE: '/recipient-pane/age',
    COMMUNITY: '/recipient-pane/community',
    CUSTOM_FIELD: '/recipient-pane/custom-fields/:fieldKey',
    CUSTOM_FIELDS: '/recipient-pane/custom-fields',
    GENDER: '/recipient-pane/gender',
    INDIVIDUAL: '/recipient-pane/individual',
    JOIN: '/recipient-pane/join',
    LOCATION: '/recipient-pane/location',
    RECENT_CAMPAIGNS: '/recipient-pane/recent-campaigns',
    ROOT: '/recipient-pane',
    TIMEZONE: '/recipient-pane/timezone',
    TOP: '/recipient-pane/top',
  },
  RESET_PASSWORD: '/reset-password',
  RESOURCES: '/resources',
  SALESFORCE: {
    CONNECT: '/salesforce/connect',
    MARKETING_CLOUD_OAUTH: '/salesforce/marketing-cloud/oauth',
    OAUTH: '/salesforce/oauth',
  },
  SCHEDULED: {
    LIST: '/scheduled/list/:scheduledCampaignId?',
    MONTH: '/scheduled/month/:month?/:year?',
    ROOT: '/scheduled',
    VIEW: '/scheduled/:view',
  },
  SEARCH: '/search',
  SETTINGS: {
    API_AUTH_TOKENS: '/settings/api-tokens',
    APPEARANCE: '/settings/appearance',
    BILLING: '/settings/billing',
    BILLING_EDIT: '/settings/billing/edit',
    BILLING_HISTORY: '/settings/billing/history',
    BILLING_USAGE: '/settings/billing/usage',
    CUSTOMIZE: {
      IMPORT: {
        DATA: '/settings/customize/upload/data',
        DETAILS: '/settings/customize/upload/:importId',
        NEW: '/settings/customize/upload/new',
        PREVIEW: '/settings/customize/upload/preview',
        ROOT: '/settings/customize/upload',
        SUMMARY: '/settings/customize/upload/summary',
      },
      IMPORTS: '/settings/customize/uploads',
      KEYWORD: '/settings/customize/keywords/:id',
      KEYWORDS: '/settings/customize/keywords',
      ROOT: '/settings/customize',
    },
    INTEGRATIONS: {
      CREATE_EMBED: '/settings/integrations/embeds',
      EDIT_EMBED: '/settings/integrations/embeds/:embedId',
      ROOT: '/settings/integrations',
      SETLIVE: '/settings/integrations/setlive',
      SHOPIFY: {
        CONNECT_STORE: '/settings/integrations/shopify/connect',
        EDIT_STORE: '/settings/integrations/shopify/edit',
      },
      WEBHOOKS: '/settings/integrations/webhooks',
      WEBHOOK_EDIT: '/settings/integrations/webhooks/:webhookId',
      ZAPIER: '/settings/integrations/zapier',
    },
    MESSAGE_LIMITS: '/settings/message-limits',
    ROOT: '/settings',
    SECURITY: {
      EDIT: '/settings/security/edit',
      ROOT: '/settings/security',
    },
    SIGNUP_MESSAGES: '/settings/signup-messages',
    TEAM: '/settings/team',
  },
  SHARE: '/share',
  TCR_FLOW: {
    ASK_EIN_FORM: '/tcr/ask-ein-form',
    BUSINESS_INFORMATION_FORM: '/tcr/business-information-form',
    CONTACT_INFORMATION_FORM: '/tcr/contact-information-form',
    REGISTRATION_DETAILS: '/tcr/registration-details',
    ROOT: '/tcr',
    THANK_YOU: '/tcr/thank-you',
  },
} as const

export const UNAUTHENTICATED_ROUTES = [ROUTES.INVITE, ROUTES.LOGIN]

export const SKIP_REDIRECTION = [ROUTES.ONBOARDING.GET_THE_APP]
