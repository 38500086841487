import { useQuery } from '@tanstack/react-query'
import noop from 'lodash/noop'
import { useCallback, useMemo, useState } from 'react'

import { formatBestHourData } from './formatBestHourData'
import { Cell, Heatmap } from './Heatmap'

import { getBestHourOfWeek } from 'api/insights'
import { ComposeMessage } from 'components/ComposeMessage'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { createBestHours } from 'generators/bestHour'
import { useClientId } from 'hooks/useClient'
import { InsightsBox } from 'screens/InsightsScreen/presentation/InsightsBox'
import { useDataColorScale } from 'screens/InsightsScreen/useDataColorScale'
import dayjs from 'utils/dayjs'

export const BestHourChartBox = ({ isLocked }: { isLocked: boolean }): JSX.Element | null => {
  const { colors } = useDataColorScale()
  const clientId = useClientId()
  const [isComposeModalOpen, setIsComposeModalOpen] = useState(false)
  const [selectedCell, setSelectedCell] = useState<Cell | null>(null)

  const bestHourQuery = useQuery(
    [QUERY_CACHE.INSIGHTS.BEST_HOUR_OF_WEEK, { clientId }],
    () => getBestHourOfWeek({ clientId }),
    {
      enabled: !isLocked,
      staleTime: STALE_TIME.FOREVER,
    },
  )

  const data = bestHourQuery.data?.data

  const mockData = useMemo(() => createBestHours(), [])

  const { timezone, heatmapData, hoursRange } = useMemo(() => {
    return formatBestHourData(isLocked ? mockData : data, {}, colors)
  }, [isLocked, mockData, data, colors])

  const handleClick = useCallback(
    (timestamp) => {
      setSelectedCell(timestamp)
      setIsComposeModalOpen(true)
    },
    [setIsComposeModalOpen],
  )

  const handleCloseComposeModal = useCallback(() => {
    setIsComposeModalOpen(false)
    setSelectedCell(null)
  }, [setIsComposeModalOpen])

  return (
    <>
      <InsightsBox.Managed
        data-testid="best-hour-box"
        isEmpty={false}
        locked={isLocked}
        noBlur={!isLocked}
        queries={!isLocked ? [bestHourQuery] : []}
        tabs={<InsightsBox.Tabs noop onChange={noop} tabs={[timezone]} />}
        title="Best Hour of Week"
      >
        <Heatmap data={heatmapData} hoursRange={hoursRange} onClick={handleClick} />
      </InsightsBox.Managed>
      {isComposeModalOpen && selectedCell && (
        <ComposeMessage
          initialComposeState={{
            scheduledAt: dayjs(selectedCell.timestamp),
          }}
          initialFilterState={{
            includedFilters: null,
            excludedFilters: null,
          }}
          onClose={handleCloseComposeModal}
        />
      )}
    </>
  )
}
