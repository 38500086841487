import flatMapDeep from 'lodash/flatMapDeep'
import isString from 'lodash/isString'

export const CAPABILITIES = Object.freeze({
  ADMIN: {
    ALL: {
      VIEW: 'admin:all:view',
    },
    BILLING: {
      READ: 'admin:billing:read',
      WRITE: 'admin:billing:write',
    },
    OUTBOUND_WEBHOOKS: {
      READ: 'admin:outbound_webhooks:read',
    },
  },
  CLIENT: {
    API_AUTH_TOKENS: {
      WRITE: 'client:api_auth_tokens:write',
    },
    BILLING: {
      READ: 'client:billing:read',
      WRITE: 'client:billing:write',
    },
    CUSTOM_MEMBER_DATA: {
      READ: 'client:custom_member_data:read',
      WRITE: 'client:custom_member_data:write',
    },
    DATA_DOWNLOAD: {
      VIEW: 'client:data_download:view',
    },
    MESSAGES: {
      READ: 'client:messages:read',
      WRITE: 'client:messages:write',
      WRITE_PENDING_REVIEW: 'client:messages:write_pending_review',
    },
    OUTBOUND_WEBHOOKS: {
      WRITE: 'client:outbound_webhooks:write',
    },
    SEATS: {
      READ: 'client:seats:read',
      WRITE: 'client:seats:write',
    },
    SETTINGS: {
      READ: 'client:settings:read',
      WRITE: 'client:settings:write',
    },
  },
  DEBUG: {
    FILTER_PROVIDER_DEBUGGER: 'debug:filter_provider_debugger',
    INCLUDE_EXCLUDE_DEBUGGER: 'debug:include_exclude_debugger',
    REACT_QUERY_DEVTOOLS: 'debug:react_query_devtools',
  },
  FEATURE: {
    ADD_ONS: {
      ALL: 'feature:add_ons:all',
    },
    AI_CAMPAIGN_SUMMARIES: {
      ALL: 'feature:ai_campaign_summaries:all',
    },
    AI_MESSAGE_GENERATOR: {
      ALL: 'feature:ai_message_generator:all',
    },
    AMB: {
      ALL: 'feature:amb:all',
    },
    API_AUTH_TOKENS: {
      ALL: 'feature:api_auth_tokens:all',
    },
    CAMPAIGN_PREVIEW: {
      ALL: 'feature:campaign_preview:all',
    },
    CAMPAIGN_SCHEDULING: {
      ALL: 'feature:campaign_scheduling:all',
    },
    CAMPAIGN_STOP_RATE: 'feature:campaign_stop_rate:read',
    CAMPAIGN_TEST: {
      ALL: 'feature:campaign_test:all',
    },
    CSV_SELF_IMPORT: {
      ALL: 'feature:csv_self_import:all',
    },
    CUSTOM_MEMBER_DATA: {
      ALL: 'feature:custom_member_data:all',
    },
    DATA_DOWNLOAD_STANDARD: 'feature:data_download:standard',
    FULL_TEXT_SEARCH: {
      ALL: 'feature:full_text_search:all',
      OVER_90_DAYS: 'feature:full_text_search_over_90_days:all',
    },
    INSIGHTS: {
      ALL: 'feature:insights:all',
      BEST_HOUR_OF_WEEK: 'feature:insights_best_hour_of_week:read',
    },
    LINK_CLICK_FILTERING: { ALL: 'feature:link_click_filtering:all' },
    LINK_SHORTENING: { ALL: 'feature:link_shortening:all' },
    LINK_TRACKING: { ALL: 'feature:link_tracking:all' },
    MESSAGE_CLUSTERING: {
      ALL: 'feature:message_clustering:all',
    },
    MMS_MESSAGING: {
      ALL: 'feature:mms_messaging:all',
    },
    OUTBOUND_WEBHOOKS: {
      ALL: 'feature:outbound_webhooks:all',
    },
    SALESFORCE_INTEGRATION: 'feature:salesforce_integration:connect_and_sync',
    SCHEDULE_CAMPAIGN_API: {
      ALL: 'feature:schedule_campaign_api:all',
    },
    SEATED_INTEGRATION: {
      ALL: 'feature:seated_integration:all',
    },
    SEGMENT_BILLING: {
      ALL: 'feature:segment_billing:all',
    },
    SET_LIVE_INTEGRATION: {
      ALL: 'feature:set_live_integration:all',
    },
    SHOPIFY_INTEGRATION: 'feature:shopify_integration_web:connect_store',
    WHATSAPP: {
      ALL: 'feature:whatsapp:all',
    },
    WORKFLOWS: {
      ABANDONED_CHECKOUT: 'feature:workflows:abandoned_checkout',
      ALL: 'feature:workflows:all',
    },
    ZAPIER_INTEGRATION: {
      EMBED: 'client:zapier_integration:embed',
    },
  },
  GLOBAL: {
    ADMIN: {
      ALL: 'global:admin:all',
    },
    ALL: {
      READ: 'global:all:read',
    },
  },
  RECENT_CAMPAIGNS: {
    ALL: 'feature:recent_campaigns:all',
  },
} as const)

// extracts leaf types from a nested record type
type ExtractValues<
  T extends Record<string, N | unknown>, // the nested record
  K = keyof T, // the Keys
  N = string, // the type we want to extract
> = K extends keyof T
  ? T[K] extends N
    ? T[K]
    : T[K] extends Record<string, unknown>
    ? ExtractValues<T[K]>
    : never
  : never

export type CapabilityName = ExtractValues<typeof CAPABILITIES>

// eslint-disable-next-line @typescript-eslint/ban-types
function flattenCapabilities<T extends object>(capabilities: T): string[] {
  try {
    return flatMapDeep(capabilities, (value: any) => {
      if (isString(value)) {
        return value
      }
      return flattenCapabilities(value)
    })
  } catch (e) {
    console.error(e)

    return []
  }
}

export const FLIPPABLE_CAPABILITIES = flattenCapabilities(CAPABILITIES)
