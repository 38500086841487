import { SPACING, Tooltip } from '@community_dev/pixels'
import styled from 'styled-components'

import { HeatmapDataStructure, HeatmapHoursRange } from './formatBestHourData'
import { HeatmapLegend } from './HeatmapLegend'

const StyledGrid = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-areas:
    'Heatmap'
    '.';
`

const StyledHeatmap = styled.div`
  grid-area: Heatmap;
`

const StyledHeatmapRow = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 28px repeat(14, 1fr);
  grid-gap: 1px;
  margin-bottom: 1px;
  text-align: center;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledHeatmapCell = styled.button`
  background-color: var(--heatmap-cell-color);
  border: 1px solid var(--heatmap-cell-color);
  min-width: auto;
  padding: 10px 0;
  display: block;
  border-radius: var(--border-radius);
  &:hover {
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  }
`

const StyledTooltip = styled(Tooltip)`
  border-radius: 4px;
`

export type Cell = {
  timestamp: string
}

type HeatmapCellProps = Cell & {
  day: string
  color: string
  hour: number
  amPm: string
  timezone: string
  coordinates: number[]
  timestamp: string
  onClick: (args: Cell) => void
}

function assignRoundEdge(coordinates) {
  const [x, y] = coordinates
  switch (true) {
    case x === 0 && y === 0:
      return `${SPACING[2]} 0 0 0`
    case x === 0 && y === 13:
      return `0 ${SPACING[2]} 0 0`
    case x === 6 && y === 0:
      return `0 0 0 ${SPACING[2]}`
    case x === 6 && y === 13:
      return `0 0 ${SPACING[2]} 0`
  }
}

const HeatmapCell = ({
  onClick,
  amPm,
  day,
  hour,
  color,
  coordinates,
  timezone,
  timestamp,
}: HeatmapCellProps): JSX.Element => {
  const style = {
    '--heatmap-cell-color': color,
    '--border-radius': assignRoundEdge(coordinates),
  } as React.CSSProperties
  return (
    <StyledTooltip content={<span>{`${day} ${hour}:00${amPm} ${timezone}`}</span>}>
      <StyledHeatmapCell
        data-testid={`${day} ${hour}:00${amPm}`}
        onClick={() => onClick({ timestamp })}
        style={style}
      />
    </StyledTooltip>
  )
}

type HeatmapProps = {
  data: HeatmapDataStructure
  onClick: (args: Cell) => void
  hoursRange: HeatmapHoursRange
}

function formatAmPmMarker(hour: number, amPm: string) {
  return `${hour}${amPm[0]}`
}

export const Heatmap = ({ onClick, data, hoursRange }: HeatmapProps): JSX.Element => {
  return (
    <>
      <StyledGrid>
        <StyledHeatmap>
          {data.map((day, dayIdx) => (
            <StyledHeatmapRow key={day.day}>
              <div key={day.day}>{day.day}</div>
              {day.hours.map((cell, cellIdx) => (
                <HeatmapCell
                  key={`${cell.hour}${cell.amPm}`}
                  {...cell}
                  coordinates={[dayIdx, cellIdx]}
                  day={day.day}
                  onClick={onClick}
                />
              ))}
            </StyledHeatmapRow>
          ))}
          <StyledHeatmapRow>
            <div />
            {hoursRange.map(({ hour, amPm }) => (
              <div key={`${hour}${amPm}`}>{hour === 12 ? formatAmPmMarker(hour, amPm) : hour}</div>
            ))}
          </StyledHeatmapRow>
        </StyledHeatmap>
      </StyledGrid>
      <br />
      <HeatmapLegend />
    </>
  )
}
