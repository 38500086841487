import { BuiltInFields, parseFilters } from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunitiesIcon, SPACING, SparkleIcon } from '@community_dev/pixels'
import { FanRegistrationCoreFieldName } from '@community_dev/types/lib/api/FanRegistration'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useQueryClient } from '@tanstack/react-query'
import mapValues from 'lodash/mapValues'
import { useMemo, useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MemoryRouter, Route, Switch } from 'react-router'
import styled from 'styled-components'

import {
  FilterItem,
  RecommendationsActivity,
  RecommendationsAge,
  RecommendationsCommunities,
  RecommendationsCustomFields,
  RecommendationsGenderIdentity,
  RecommendationsIndividuals,
  RecommendationsJoin,
  RecommendationsLocation,
  RecommendationsTop,
  RecommendationsRecentCampaigns,
} from './components'
import { RecommendationsTimezone } from './components/RecommendationsTimezone'
import { useFilterSupportMatrix } from './hooks/useFilterSupportMatrix'

import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { FilterPane } from 'containers/RecipientRecommendations/recommendation-constants'
import { useMostActiveMembers } from 'containers/RecipientRecommendations/useMostActiveMembers'
import { FilterSelectionType, useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useClientId } from 'hooks/useClient'
import { useFanRegistrationField } from 'hooks/useClientSettings'
import { useCountByQuery } from 'hooks/useCountByQuery'
import { useRecentCampaignFilters } from 'hooks/useCountByQuery/useRecentCampaignCount'
import { useCustomMemberDataFields } from 'hooks/useCustomDataFields'
import { useMemberDataPermissions } from 'hooks/useMemberDataPermissions'
import { formatLargeNumber } from 'utils/number'

const StyledSparkleIcon = styled(SparkleIcon)`
  margin-left: ${SPACING[1]};
  margin-right: ${SPACING[1]};
`

const StyledCommunitiesIcon = styled(CommunitiesIcon)``

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`

const StyledLeftPane = styled.nav`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  width: 184px;
  height: inherit;
  border-right: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  overflow-y: auto;
  flex-shrink: 0;
`

const StyledRightPane = styled.div`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  flex-grow: 1;
  overflow: auto;
`

const StyledDivider = styled.hr`
  height: 1px;
  border: none;
  background: ${({ theme }) => theme?.COLORS?.BORDERS};
  margin: ${SPACING[2]} ${SPACING[4]};
`

const StyledFilterHeader = styled.h2`
  padding: 0 ${SPACING[4]};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  padding-left: ${SPACING[4]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledList = styled.ul`
  padding: 0;
`

const ALL_PANES: Record<FilterPane, any> = {
  [FilterPane.TOP]: () => RecommendationsTop,
  [FilterPane.INDIVIDUAL]: () => RecommendationsIndividuals,
  [FilterPane.CUSTOM_FIELDS]: () => RecommendationsCustomFields,
  [FilterPane.COMMUNITY]: (type: FilterSelectionType) => () => <RecommendationsCommunities type={type} />,
  [FilterPane.LOCATION]: () => RecommendationsLocation,
  [FilterPane.TIMEZONE]: () => RecommendationsTimezone,
  [FilterPane.AGE]: () => RecommendationsAge,
  [FilterPane.GENDER]: () => RecommendationsGenderIdentity,
  [FilterPane.JOIN]: () => RecommendationsJoin,
  [FilterPane.ACTIVITY]: () => RecommendationsActivity,
  [FilterPane.RECENT_CAMPAIGNS]: () => RecommendationsRecentCampaigns,
}

enum PaneGroups {
  SEARCH = 'SEARCH',
  CUSTOM_FIELDS = 'CUSTOM_FIELDS',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  ENGAGEMENT = 'ENGAGEMENT',
}

const PANE_BY_GROUP = {
  [PaneGroups.SEARCH]: [FilterPane.INDIVIDUAL, FilterPane.COMMUNITY],
  [PaneGroups.CUSTOM_FIELDS]: [FilterPane.CUSTOM_FIELDS],
  [PaneGroups.DEMOGRAPHICS]: [FilterPane.LOCATION, FilterPane.AGE, FilterPane.GENDER],
  [PaneGroups.ENGAGEMENT]: [FilterPane.JOIN, FilterPane.ACTIVITY],
}

const FILTER_BY_PANE = {
  [FilterPane.INDIVIDUAL]: BuiltInFields.SUBSCRIPTION_ID,
  [FilterPane.COMMUNITY]: BuiltInFields.TAG_ID,
  [FilterPane.LOCATION]: BuiltInFields.LOCATION,
  [FilterPane.AGE]: BuiltInFields.AGE,
  [FilterPane.GENDER]: BuiltInFields.GENDER_IDENTITY,
  [FilterPane.JOIN]: BuiltInFields.JOINED,
  [FilterPane.TIMEZONE]: BuiltInFields.TIME_ZONE_GROUP,
  [FilterPane.ACTIVITY]: BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
}

const ROUTE_BY_PANE = {
  [FilterPane.TOP]: ROUTES.RECIPIENT_PANE.TOP,
  [FilterPane.CUSTOM_FIELDS]: ROUTES.RECIPIENT_PANE.CUSTOM_FIELDS,
  [FilterPane.COMMUNITY]: ROUTES.RECIPIENT_PANE.COMMUNITY,
  [FilterPane.INDIVIDUAL]: ROUTES.RECIPIENT_PANE.INDIVIDUAL,
  [FilterPane.LOCATION]: ROUTES.RECIPIENT_PANE.LOCATION,
  [FilterPane.TIMEZONE]: ROUTES.RECIPIENT_PANE.TIMEZONE,
  [FilterPane.AGE]: ROUTES.RECIPIENT_PANE.AGE,
  [FilterPane.GENDER]: ROUTES.RECIPIENT_PANE.GENDER,
  [FilterPane.JOIN]: ROUTES.RECIPIENT_PANE.JOIN,
  [FilterPane.ACTIVITY]: ROUTES.RECIPIENT_PANE.ACTIVITY,
  [FilterPane.RECENT_CAMPAIGNS]: ROUTES.RECIPIENT_PANE.RECENT_CAMPAIGNS,
}

export type RecipientPaneProps = {
  className?: string
  style?: React.CSSProperties
  initialPane?: FilterPane
  panes?: Record<FilterPane, any>
  recipientCount?: number
  topIcon?: IconProp
  topLabel?: string
  type?: FilterSelectionType
}

export type RecipientPaneHandle = {
  contains: (event: Event) => boolean
}

export const RecipientPane = forwardRef<RecipientPaneHandle, RecipientPaneProps>(
  (
    {
      className,
      style,
      initialPane = FilterPane.TOP,
      panes = ALL_PANES,
      recipientCount,
      topIcon,
      topLabel,
      type = 'includes',
    },
    ref,
  ): JSX.Element => {
    const { t } = useTranslation()
    const clientId = useClientId()
    const { activeSubtreeFilters, communicationChannel } = useFilters()
    const { isFilterEnabled, isFilterSupported, hasAnyRequiredFilterType } = useFilterSupportMatrix({
      communicationChannel,
    })
    const { data: { count: activeSubtreeRecipientCount = 0 } = {} } = useCountByQuery({
      filters: activeSubtreeFilters,
      communicationChannel,
      traceId: 'recipient-pane',
    })
    const { isMostActiveMembersEnabled } = useMostActiveMembers()
    const [currentPane, setCurrentPane] = useState(initialPane)
    const queryClient = useQueryClient()

    const { canFilter: canFilterMemberData } = useMemberDataPermissions()
    const { data: fields } = useCustomMemberDataFields({
      options: { enabled: Boolean(canFilterMemberData && !!clientId) },
      clientId,
    })

    const recentCampaignFilters = useRecentCampaignFilters()

    const shouldShowRecentCampaigns = useMemo(() => {
      return recentCampaignFilters.length > 0
    }, [recentCampaignFilters])

    const recipientPaneRef = useRef<HTMLDivElement>(null)
    const CurrentPane = useMemo(() => panes[currentPane]?.(type), [currentPane, panes, type])

    useImperativeHandle(ref, () => ({
      contains: (event: Event) => {
        if (!event.target) return false
        return recipientPaneRef?.current?.contains(event?.target as Element) || false
      },
    }))

    const paneGroups = useMemo(() => {
      return mapValues(PANE_BY_GROUP, (_, group: PaneGroups) => {
        const activePanesInGroup = PANE_BY_GROUP[group].filter(
          (pane) =>
            // the pane has been passed as a prop
            panes[pane] !== undefined &&
            // no filters are required for it or the required filters are supported for the channel
            (FILTER_BY_PANE[pane] === undefined || isFilterSupported(FILTER_BY_PANE[pane])),
        )
        return activePanesInGroup.length > 0
      })
    }, [panes, isFilterSupported])

    useEffect(() => {
      queryClient.invalidateQueries([QUERY_CACHE.CUSTOM_DATA_FIELDS])
    }, [])
    const { label: genderLabel } = useFanRegistrationField(FanRegistrationCoreFieldName.GENDER_NAME) || {}
    return (
      <MemoryRouter initialEntries={[ROUTES.RECIPIENT_PANE.ROOT]}>
        <StyledContainer className={className} data-testid="recipient-pane" style={style}>
          <StyledLeftPane aria-label="Recipient filters">
            <StyledList>
              {panes[FilterPane.TOP] && (
                <FilterItem
                  comIcon={topIcon ? undefined : StyledCommunitiesIcon}
                  icon={topIcon ? topIcon : undefined}
                  onClick={() => setCurrentPane(FilterPane.TOP)}
                  selected={currentPane === FilterPane.TOP}
                  title={
                    topLabel
                      ? topLabel
                      : `Recipients (${formatLargeNumber(recipientCount || activeSubtreeRecipientCount)})`
                  }
                  to={ROUTES.RECIPIENT_PANE.TOP}
                  type={type}
                />
              )}
              {panes[FilterPane.RECENT_CAMPAIGNS] && shouldShowRecentCampaigns && (
                <>
                  <FilterItem
                    icon="clock"
                    onClick={() => setCurrentPane(FilterPane.RECENT_CAMPAIGNS)}
                    selected={currentPane === FilterPane.RECENT_CAMPAIGNS}
                    title={t('compose.recent')}
                    to={ROUTES.RECIPIENT_PANE.RECENT_CAMPAIGNS}
                    type={type}
                  />
                  <StyledDivider />
                </>
              )}
              {paneGroups[PaneGroups.SEARCH] && (
                <div>
                  <StyledFilterHeader>Search</StyledFilterHeader>
                  {panes[FilterPane.INDIVIDUAL] && isFilterSupported(BuiltInFields.SUBSCRIPTION_ID) && (
                    <FilterItem
                      disabled={!isFilterEnabled(BuiltInFields.SUBSCRIPTION_ID, activeSubtreeFilters)}
                      icon="user"
                      onClick={() => setCurrentPane(FilterPane.INDIVIDUAL)}
                      selected={currentPane === FilterPane.INDIVIDUAL}
                      title="Individuals"
                      to={ROUTES.RECIPIENT_PANE.INDIVIDUAL}
                      type={type}
                    />
                  )}
                  {panes[FilterPane.COMMUNITY] && isFilterSupported(BuiltInFields.TAG_ID) && (
                    <FilterItem
                      comIcon={StyledCommunitiesIcon}
                      disabled={!isFilterEnabled(BuiltInFields.TAG_ID, activeSubtreeFilters)}
                      onClick={() => setCurrentPane(FilterPane.COMMUNITY)}
                      selected={currentPane === FilterPane.COMMUNITY}
                      title="Communities"
                      to={ROUTES.RECIPIENT_PANE.ROOT}
                      type={type}
                    />
                  )}
                </div>
              )}
              {paneGroups[PaneGroups.CUSTOM_FIELDS] && canFilterMemberData && (fields?.length || 0) > 0 && (
                <div>
                  <StyledFilterHeader>Custom</StyledFilterHeader>
                  <FilterItem
                    comIcon={StyledSparkleIcon}
                    disabled={!hasAnyRequiredFilterType(parseFilters(activeSubtreeFilters))}
                    onClick={() => setCurrentPane(FilterPane.CUSTOM_FIELDS)}
                    selected={currentPane === FilterPane.CUSTOM_FIELDS}
                    title="Custom Fields"
                    to={ROUTES.RECIPIENT_PANE.CUSTOM_FIELDS}
                    type={type}
                  />
                </div>
              )}
              {paneGroups[PaneGroups.DEMOGRAPHICS] && (
                <div>
                  <StyledFilterHeader>Demographics</StyledFilterHeader>
                  {panes[FilterPane.LOCATION] && isFilterSupported(BuiltInFields.LOCATION) && (
                    <FilterItem
                      disabled={!isFilterEnabled(BuiltInFields.LOCATION, activeSubtreeFilters)}
                      icon="map-marker-alt"
                      onClick={() => setCurrentPane(FilterPane.LOCATION)}
                      selected={currentPane === FilterPane.LOCATION}
                      title="Location"
                      to={ROUTES.RECIPIENT_PANE.LOCATION}
                      type={type}
                    />
                  )}
                  {panes[FilterPane.TIMEZONE] && isFilterSupported(BuiltInFields.TIME_ZONE_GROUP) && (
                    <FilterItem
                      disabled={!isFilterEnabled(BuiltInFields.TIME_ZONE_GROUP, activeSubtreeFilters)}
                      icon="clock"
                      onClick={() => setCurrentPane(FilterPane.TIMEZONE)}
                      selected={currentPane === FilterPane.TIMEZONE}
                      title="Time Zone"
                      to={ROUTES.RECIPIENT_PANE.TIMEZONE}
                      type={type}
                    />
                  )}
                  {panes[FilterPane.AGE] && isFilterSupported(BuiltInFields.AGE) && (
                    <FilterItem
                      disabled={!isFilterEnabled(BuiltInFields.AGE, activeSubtreeFilters)}
                      icon="user"
                      onClick={() => setCurrentPane(FilterPane.AGE)}
                      selected={currentPane === FilterPane.AGE}
                      title="Age"
                      to={ROUTES.RECIPIENT_PANE.AGE}
                      type={type}
                    />
                  )}
                  {panes[FilterPane.GENDER] && isFilterSupported(BuiltInFields.GENDER_IDENTITY) && (
                    <FilterItem
                      disabled={!isFilterEnabled(BuiltInFields.GENDER_IDENTITY, activeSubtreeFilters)}
                      icon="transgender-alt"
                      onClick={() => setCurrentPane(FilterPane.GENDER)}
                      selected={currentPane === FilterPane.GENDER}
                      title={genderLabel || 'Gender Identity'}
                      to={ROUTES.RECIPIENT_PANE.GENDER}
                      type={type}
                    />
                  )}
                </div>
              )}
              {paneGroups[PaneGroups.ENGAGEMENT] && (
                <div>
                  <StyledFilterHeader>Engagement</StyledFilterHeader>
                  {panes[FilterPane.JOIN] && isFilterSupported(BuiltInFields.JOINED) && (
                    <FilterItem
                      disabled={!isFilterEnabled(BuiltInFields.JOINED, activeSubtreeFilters)}
                      icon="calendar-alt"
                      onClick={() => setCurrentPane(FilterPane.JOIN)}
                      selected={currentPane === FilterPane.JOIN}
                      title="Join Date"
                      to={ROUTES.RECIPIENT_PANE.JOIN}
                      type={type}
                    />
                  )}
                  {panes[FilterPane.ACTIVITY] &&
                    isFilterSupported(BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT) &&
                    isMostActiveMembersEnabled && (
                      <FilterItem
                        data-testid="recommendations-activity-button"
                        disabled={!isFilterEnabled(BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT, activeSubtreeFilters)}
                        icon="user"
                        onClick={() => setCurrentPane(FilterPane.ACTIVITY)}
                        selected={currentPane === FilterPane.ACTIVITY}
                        title="Activity"
                        to={ROUTES.RECIPIENT_PANE.ACTIVITY}
                        type={type}
                      />
                    )}
                </div>
              )}
            </StyledList>
          </StyledLeftPane>
          <StyledRightPane data-testid="recommendation-pane">
            <Switch>
              {Object.keys(panes).map((pane) => (
                <Route component={panes[pane](type)} key={pane} path={ROUTE_BY_PANE[pane]} />
              ))}
              <Route component={CurrentPane} path="" />
            </Switch>
          </StyledRightPane>
        </StyledContainer>
      </MemoryRouter>
    )
  },
)
