import React from 'react'

type ZapierIconProps = {
  size?: number
}

export const ZapierIcon = ({ size = 24 }: ZapierIconProps): JSX.Element => {
  return (
    <svg height={size} version="1.1" viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 12.007812 0 C 12.683594 0 13.351562 0.0585938 14 0.167969 L 14 7.152344 L 18.953125 2.210938 C 19.5 2.601562 20.011719 3.03125 20.484375 3.503906 C 20.960938 3.976562 21.394531 4.488281 21.78125 5.035156 L 16.828125 9.976562 L 23.832031 9.976562 C 23.945312 10.632812 24 11.296875 24 11.960938 L 24 11.976562 C 24 12.65625 23.941406 13.320312 23.832031 13.964844 L 16.828125 13.964844 L 21.78125 18.902344 C 21.394531 19.449219 20.960938 19.960938 20.488281 20.433594 L 20.484375 20.433594 C 20.011719 20.90625 19.5 21.339844 18.953125 21.726562 L 14 16.785156 L 14 23.773438 C 13.34375 23.882812 12.675781 23.9375 12.007812 23.9375 L 11.992188 23.9375 C 11.324219 23.9375 10.660156 23.882812 10 23.773438 L 10 16.785156 L 5.046875 21.726562 C 3.953125 20.949219 2.996094 19.996094 2.21875 18.902344 L 7.171875 13.964844 L 0.167969 13.964844 C 0.0546875 13.308594 0 12.640625 0 11.972656 L 0 11.9375 C 0 11.761719 0.0117188 11.546875 0.0273438 11.328125 L 0.0351562 11.257812 C 0.0820312 10.632812 0.167969 9.976562 0.167969 9.976562 L 7.171875 9.976562 L 2.21875 5.035156 C 2.605469 4.492188 3.039062 3.980469 3.511719 3.507812 L 3.515625 3.503906 C 3.988281 3.03125 4.503906 2.601562 5.046875 2.210938 L 10 7.152344 L 10 0.167969 C 10.660156 0.0585938 11.324219 0 11.992188 0 Z M 12.007812 8.976562 L 11.996094 8.976562 C 11.101562 8.976562 10.25 9.140625 9.460938 9.4375 C 9.15625 10.246094 9 11.101562 9 11.964844 L 9 11.976562 C 9 12.839844 9.15625 13.695312 9.460938 14.5 C 10.269531 14.804688 11.128906 14.960938 11.996094 14.960938 L 12.007812 14.960938 C 12.898438 14.960938 13.75 14.796875 14.539062 14.5 C 14.84375 13.695312 15 12.839844 15 11.976562 L 15 11.964844 C 15 11.074219 14.835938 10.222656 14.539062 9.4375 C 13.730469 9.132812 12.871094 8.976562 12.007812 8.976562 Z M 12.007812 8.976562 "
        fill="#FF4A00"
      />
    </svg>
  )
}
