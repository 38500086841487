import { humanizeJoinFilterShortened } from '@community_dev/filter-dsl/lib/humanize/builtInFilters/humanizeJoinFilter'
import {
  BuiltInFields,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ApiError } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { FilterOptionsWithCounts } from './types'
import { CountByQueryFnReturn, useCountByQueries } from './useCountByQuery'

import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { FilterSelectionType } from 'contexts/FilterProvider/FilterProvider'
import dayjs from 'utils/dayjs'
const joinedFilters: MemberDataFilter[] = [
  {
    operator: SelectorOperators.GREATER_THAN,
    operand: {
      field_key: BuiltInFields.JOINED,
      field_label: 'Joined: Last 24 hours',
      source: FieldSources.BUILT_IN,
      type: FieldTypes.DATETIME,
      value: dayjs().subtract(1, 'day').toISOString(),
    },
  },
  {
    operator: SelectorOperators.GREATER_THAN,
    operand: {
      field_key: BuiltInFields.JOINED,
      field_label: 'Joined: Last 7 days',
      source: FieldSources.BUILT_IN,
      type: FieldTypes.DATETIME,
      value: dayjs().subtract(7, 'day').toISOString(),
    },
  },
  {
    operator: SelectorOperators.GREATER_THAN,
    operand: {
      field_key: BuiltInFields.JOINED,
      field_label: 'Joined: Last 30 days',
      source: FieldSources.BUILT_IN,
      type: FieldTypes.DATETIME,
      value: dayjs().subtract(30, 'day').toISOString(),
    },
  },
]

type UseJoinedCountArgs<SelectData = CountByQueryFnReturn> = {
  traceId?: string
  communicationChannel?: CommunicationChannel
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

export const useJoinedCounts = <SelectData = CountByQueryFnReturn>({
  traceId,
  communicationChannel,
  options,
}: UseJoinedCountArgs<SelectData> = {}): UseQueryResult<SelectData, ApiError>[] => {
  return useCountByQueries<SelectData>({
    filters: joinedFilters,
    traceId,
    communicationChannel,
    options,
  })
}

export const useCurrentFiltersWithJoinedCount = (type: FilterSelectionType = 'includes'): FilterOptionsWithCounts => {
  const { previewNextFilter, communicationChannel } = useFilters()

  const nextFilter = joinedFilters.map((filter) => previewNextFilter(filter, type)) as MemberDataFilter[]

  return {
    options: joinedFilters.map((filter) => ({
      filter,
      label: humanizeJoinFilterShortened(filter),
    })),
    counts: useCountByQueries({
      filters: nextFilter,
      communicationChannel,
      traceId: 'current-filters-with-joined-counts',
    }),
  }
}
