import { ErrorBoundary, LoadingIndicator } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ResponsiveWrapper } from './WorkflowScreen.style'

import { ErrorComponent } from 'components/ErrorComponent'
import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { WorkflowEditor } from 'components/WorkflowEditor/WorkflowEditor'
import { ROUTES } from 'constants/routes'
import { useToastMessage } from 'hooks/useToastMessage'
import Sentry from 'integrations/Sentry'

export function WorkflowScreen(): JSX.Element {
  const history = useHistory()
  const { showToastMessage } = useToastMessage()

  const { t } = useTranslation()

  const { isLoading, workflow, manifest, isError, error } = useWorkflowProvider()

  if (isError) {
    Sentry.captureException(error)

    showToastMessage({
      message: t('settings.unableToLoadData'),
      success: false,
    })

    history.push(ROUTES.AUTOMATIONS.FLOWS)
  }
  return (
    <ResponsiveWrapper>
      <ErrorBoundary ErrorComponent={ErrorComponent} onCatch={(e) => Sentry.captureException(e)}>
        {!isLoading && workflow !== undefined && manifest !== undefined ? <WorkflowEditor /> : <LoadingIndicator />}
      </ErrorBoundary>
    </ResponsiveWrapper>
  )
}
