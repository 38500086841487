import styled from 'styled-components'

export const StyledWrapper = styled.div<{ $fontSize?: string }>`
  cursor: pointer;
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  border-radius: 4px;
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};
  width: 100%;

  ${({ $fontSize }) =>
    $fontSize &&
    `
      font-size: ${$fontSize};
    `}
`

export const StyledChildContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;

  input {
    cursor: pointer;
    font-weight: 500;
    padding: 8px 10px 8px 10px;
    outline: none;
    border: none;
    background: transparent;
    line-height: normal;
    width: 100%;
    color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT};

    &::placeholder {
      color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT_PLACEHOLDER};
    }
  }
`

export const StyledIcon = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  flex-shrink: 0;
  color: ${({ $active, theme }) => ($active ? theme?.COLORS?.INPUT_FOCUS : theme?.COLORS?.INPUT_TEXT_PLACEHOLDER)};
  padding-left: 10px;
  transition: color 0.2s ease;

  + input {
    padding-left: 8px;
  }
`
