import { AddToCommunities } from '../AddToCommunities/AddToCommunities'

import { Community } from 'api/community'
import { Fan } from 'api/fans'
import {
  useAddFanToCommunity,
  useRemoveFanFromCommunity,
} from 'containers/InboxList/MemberDetailActionPanel/member-detail-queries'

type AddToCommunitiesByFanProps = {
  fan?: Fan
  filters?: any
}

export function AddToCommunitiesByFan({ fan }: AddToCommunitiesByFanProps): JSX.Element {
  const { removeFan } = useRemoveFanFromCommunity()
  const { addFan } = useAddFanToCommunity()

  const handleAdd = (community: Community): void => {
    if (!community || !fan) return
    addFan({ community, fan })
  }

  const handleRemove = (community: Community) => {
    if (!community || !fan) return
    removeFan({ community, fan })
  }

  function handleCreateCommunity(community: Community) {
    if (!fan) return
    addFan({ community, fan })
  }

  return (
    <AddToCommunities fan={fan} onAdd={handleAdd} onCommunityCreated={handleCreateCommunity} onRemove={handleRemove} />
  )
}
