import { WorkflowTrigger } from '@community_dev/workflow-manifest'
import { useParams } from 'react-router'

import { WorkflowSidebarEditTriggerKeyword } from '../WorkflowEditSidebarTriggerKeyword'
import { WorkflowSidebarEditTriggerShopify } from '../WorkflowEditSidebarTriggerShopify'

import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'

export function WorkflowSidebarEditTrigger(): JSX.Element | null {
  const { triggerName } = useParams<{ workflowId: string; triggerName: string }>()
  const { manifest } = useWorkflowProvider()

  switch (manifest.entrypoints[triggerName].type) {
    case WorkflowTrigger.ShopifyAbandonedCheckout:
      return <WorkflowSidebarEditTriggerShopify />
    case WorkflowTrigger.Keyword:
      return <WorkflowSidebarEditTriggerKeyword />
    default:
      return null
  }
}
