import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type CreditBundles = CamelCasedPropertiesDeep<Api.Billing.CreditBundles>

export type MonthlyUsage = CamelCasedPropertiesDeep<Api.Billing.MonthlyUsage>

export const getCreditBundles = (clientId: string): Promise<CreditBundles> => {
  return request(route(ENDPOINTS.BILLING.CREDIT_BUNDLES, { clientId }))
}

export const getMonthlyUsage = ({
  clientId,
  month = new Date().getMonth() + 1,
  year = new Date().getFullYear(),
}: {
  clientId: string
  month?: number
  year?: number
}): Promise<MonthlyUsage> => {
  return request(route(ENDPOINTS.BILLING.SEGMENT_SUBSCRIPTION_USAGE, { clientId }, { month, year }))
}

export const getDownloadUrl = (clientId: string, fileName: string): Promise<Api.Billing.UsageReportUrl> => {
  return request(route(ENDPOINTS.BILLING.DOWNLOAD_USAGE, { clientId, fileName }))
}
