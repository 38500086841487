import { TABLET_BREAK } from '@community_dev/pixels'
import { Route, Switch } from 'react-router'
import styled from 'styled-components'

import { WorkflowSidebarEditTrigger } from '../WorkflowEditSidebarTrigger'
import { WorkflowSidebarAddCommunity } from '../WorkflowSidebarAddCommunity'
import { WorkflowSidebarAddSms } from '../WorkflowSidebarAddSms'
import { WorkflowSidebarAddStep } from '../WorkflowSidebarAddStep'
import { WorkflowEditOnboardingMessage } from '../WorkflowSidebarEditOnboardingMessage'
import { WorkflowSidebarEditStep } from '../WorkflowSidebarEditStep'

import { ROUTES } from 'constants/routes'

export const WORKFLOW_EDIT_SIDEBAR_WIDTH = 340

const StyledWorkflowEditSidebar = styled.div`
  width: ${WORKFLOW_EDIT_SIDEBAR_WIDTH}px;
  overflow-y: auto;
  border-left: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  background: ${({ theme }) => theme?.COLORS?.WORKFLOW_EDITOR_SIDEBAR_BACKGROUND};
  z-index: 50;
  @media only screen and (max-width: ${TABLET_BREAK}) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
`

export function WorkflowSidebarEdit(): JSX.Element {
  return (
    <StyledWorkflowEditSidebar data-testid="workflow-edit-sidebar">
      <Switch>
        <Route component={WorkflowEditOnboardingMessage} exact path={ROUTES.AUTOMATIONS.FLOW_EDIT_ONBOARDING_MESSAGE} />
        <Route component={WorkflowSidebarAddSms} exact path={ROUTES.AUTOMATIONS.FLOW_ADD_STEP_SMS_MESSAGE} />
        <Route component={WorkflowSidebarAddCommunity} exact path={ROUTES.AUTOMATIONS.FLOW_ADD_STEP_ADD_TO_COMMUNITY} />
        <Route component={WorkflowSidebarEditTrigger} exact path={ROUTES.AUTOMATIONS.FLOW_EDIT_TRIGGER} />
        <Route component={WorkflowSidebarEditStep} exact path={ROUTES.AUTOMATIONS.FLOW_EDIT_STEP} />
        <Route component={WorkflowSidebarAddStep} exact path={ROUTES.AUTOMATIONS.FLOW_ADD_STEP} />
      </Switch>
    </StyledWorkflowEditSidebar>
  )
}
