import { Button, Modal, SPACING, BUTTON_VARIANTS, FONT_WEIGHT } from '@community_dev/pixels'
import { useMutation } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { postRetryOTPVerification } from 'api/tcr'
import { ROUTES } from 'constants/routes'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useAuth } from 'hooks/useAuth'
import { useClient, useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'

const StyledLogout = styled(Button).attrs({
  as: Link,
  variant: BUTTON_VARIANTS.LINK,
  to: ROUTES.LOGOUT,
})`
  color: ${({ theme }) => theme?.COLORS?.ERRORS};
  margin-right: ${SPACING[5]};
  font-weight: ${FONT_WEIGHT[6]};
  text-decoration: none;
`
const StyledBody = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${SPACING[5]};
  p {
    width: 100%;
  }
`

const StyledFooter = styled.div`
  text-align: center;
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  text-decoration: none;
  white-space: nowrap;
`

export const SubmittedTCRModal = (): JSX.Element => {
  const { data: client } = useClient()
  const clientId = useClientId()
  const { data: seat } = useSeat()
  const { logout } = useAuth()
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const { mutate: sendTextAgain } = useMutation(() => postRetryOTPVerification({ clientId, seatId: seat!.id }), {
    onSuccess: () => {
      showToastMessage({
        message: t('tcr.modals.submitted.retryOTPVerificationSuccess'),
      })
    },
    onError: () => {
      showToastMessage({
        message: t('tcr.modals.submitted.retryOTPVerificationError'),
        success: false,
      })
    },
  })

  const mailLink = useMemo(() => {
    return `mailto:yourfriends@community.com?subject=${encodeURIComponent(
      t('tcr.modals.submitted.emailSubject'),
    )}&body=${encodeURIComponent(
      t('tcr.modals.submitted.emailBody', { clientId, name: client?.fullName, email: client?.email }),
    )}`
  }, [t, clientId, client])

  return (
    <Modal maxHeight="auto" open>
      <Modal.Header>{t('tcr.modals.submitted.modalTitle')}</Modal.Header>
      <Modal.Body>
        <StyledBody>
          <Trans i18nKey="tcr.modals.submitted.body">
            <StyledLink href={mailLink} />
          </Trans>
        </StyledBody>
      </Modal.Body>
      <Modal.Footer>
        <StyledFooter>
          <StyledLogout onClick={logout}>{t('logout')}</StyledLogout>
          <Button onClick={sendTextAgain}>{t('tcr.modals.submitted.didntReceiveTextButton')}</Button>
        </StyledFooter>
      </Modal.Footer>
    </Modal>
  )
}
