import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query'

import { CampaignResponses, getCampaignResponses } from 'api/campaign'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

type UseResponseTagsQueryProps = {
  campaignId: string
  includeClusters: boolean
  tagsAll: string[]
  tagsNot: string[]
}

export const useResponseTagsQuery = ({
  campaignId,
  includeClusters,
  tagsAll,
  tagsNot,
}: UseResponseTagsQueryProps): UseInfiniteQueryResult<CampaignResponses> => {
  const clientId = useClientId()

  return useInfiniteQuery(
    [QUERY_CACHE.CAMPAIGN.RESPONSES, { campaignId, clientId, tagsAll, tagsNot }],
    ({ pageParam }) => {
      return getCampaignResponses({
        hasClusters: includeClusters,
        campaignId,
        clientId,
        endDate: pageParam,
        tagsAll,
        tagsNot,
      })
    },
    {
      enabled: !!(clientId && campaignId),
      getNextPageParam: (lastPage) => {
        return lastPage?.paginationData?.hasNextPage && lastPage?.paginationData?.nextEndDate
      },
    },
  )
}
