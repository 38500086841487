import { toStringWithoutSpecialChars } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createTextNode, TextNode } from 'lexical'
import { useEffect } from 'react'

type UseSpecialCharactersPluginProps = {
  communicationChannel: CommunicationChannel
}

/**
 * This simple plugin will replace special characters like emdash and smart quotes while SMS communication channel is active
 */
export function useSpecialCharactersPlugin({ communicationChannel }: UseSpecialCharactersPluginProps): null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerNodeTransform(TextNode, (textNode) => {
      const textContent = textNode.getTextContent()

      if (
        communicationChannel === CommunicationChannel.SMS &&
        (/[\u2014]/g.exec(textContent) ||
          /[\u2018\u2019]/g.exec(textContent) ||
          /[\u201C\u201D]/g.exec(textContent) ||
          /[\u00A0\u2000]/g.exec(textContent))
      ) {
        const transformedContent = toStringWithoutSpecialChars(textContent)
        textNode.replace($createTextNode(transformedContent))
        return
      }
    })
  }, [communicationChannel, editor])

  return null
}
