import { FONT_SIZE, FONT_WEIGHT, SPACING } from '@community_dev/pixels'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import SettingsSection from './SettingsSection'

import { BetaBadge } from 'components/BetaBadge'
import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { TABLET_BREAK } from 'constants/theme'
import { useHasCapability, useHasSomeCapabilities } from 'hooks/useUserCapability'
import analytics from 'utils/analytics'

const StyledRoot = styled.div`
  width: 100%;
`

const StyledTabs = styled.ul`
  font-weight: ${FONT_WEIGHT[7]};
  font-size: ${FONT_SIZE[3]};
  line-height: 18px;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  display: flex;
  list-style: none;
  margin: 0;
  padding: ${SPACING[2]} 0 ${SPACING[8]};

  a {
    padding-bottom: 8px;
    display: inline-block;
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
    text-decoration: none;

    &.active {
      border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
      color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
    }
  }

  li {
    position: relative;
    margin-right: ${SPACING[6]};

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${TABLET_BREAK}) {
    font-size: ${FONT_SIZE[2]};
    padding: ${SPACING[3]} 0 ${SPACING[5]};
    flex-wrap: wrap;

    li {
      margin-right: ${SPACING[5]};
      margin-bottom: ${SPACING[5]};
    }
  }
`

const StyledContent = styled.div`
  padding-bottom: 48px;

  ${SettingsSection} {
    &:not(:last-child) {
      margin: 0 0 ${SPACING[5]};
      border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
    }
  }
`

const StyledBadgeWrapper = styled.div`
  top: -28px;
  position: absolute;
`

type SettingsLayoutProps = {
  children: React.ReactChild
}

const SettingsLayout = (props: SettingsLayoutProps): JSX.Element => {
  const { t } = useTranslation()
  const isAutomationsEnabled = useHasCapability(CAPABILITIES.FEATURE.WORKFLOWS.ALL)
  const isNewMemberSeriesEnabled = useHasCapability(CAPABILITIES.FEATURE.WORKFLOWS.ALL)
  const isApiAuthTokensEnabled = useHasCapability(CAPABILITIES.FEATURE.API_AUTH_TOKENS.ALL)
  const isBillingEnabled = useHasSomeCapabilities(
    CAPABILITIES.CLIENT.BILLING.READ,
    CAPABILITIES.CLIENT.BILLING.WRITE,
    CAPABILITIES.ADMIN.BILLING.READ,
    CAPABILITIES.ADMIN.BILLING.WRITE,
  )

  return (
    <StyledRoot>
      <StyledTabs>
        <li>
          <NavLink exact to={ROUTES.SETTINGS.ROOT}>
            {t('settings.general.tab')}
          </NavLink>
        </li>
        <li>
          {isNewMemberSeriesEnabled && !isAutomationsEnabled && (
            <StyledBadgeWrapper>
              <BetaBadge />
            </StyledBadgeWrapper>
          )}
          <NavLink
            exact
            onClick={() => isNewMemberSeriesEnabled && analytics.track(analytics.events.SignUpMessagesTabClicked())}
            to={ROUTES.SETTINGS.SIGNUP_MESSAGES}
          >
            {t('settings.signupMessages')}
          </NavLink>
        </li>
        <li>
          <NavLink exact to={ROUTES.SETTINGS.TEAM}>
            {t('settings.team.tab')}
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.SETTINGS.CUSTOMIZE.ROOT}>{t('settings.customize.tab')}</NavLink>
        </li>
        {isBillingEnabled && (
          <li>
            <NavLink to={ROUTES.SETTINGS.BILLING}>{t('settings.billing.tab')}</NavLink>
          </li>
        )}
        <li>
          <NavLink exact to={ROUTES.SETTINGS.SECURITY.ROOT}>
            {t('settings.security')}
          </NavLink>
        </li>
        <li>
          <NavLink exact to={ROUTES.SETTINGS.INTEGRATIONS.ROOT}>
            {t('settings.integrations.tab')}
          </NavLink>
        </li>
        <li>
          <NavLink exact to={ROUTES.SETTINGS.APPEARANCE}>
            {t('settings.appearance.tab')}
          </NavLink>
        </li>
        {isApiAuthTokensEnabled && (
          <li>
            <NavLink exact to={ROUTES.SETTINGS.API_AUTH_TOKENS}>
              {t('settings.apiAuthTokens.tab')}
            </NavLink>
          </li>
        )}
      </StyledTabs>
      <StyledContent>{props.children}</StyledContent>
    </StyledRoot>
  )
}

export default SettingsLayout
