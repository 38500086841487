import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type Webhook = CamelCasedPropertiesDeep<Api.V1.Webhook>
export type WebhookUnsaved = Omit<CamelCasedPropertiesDeep<Api.V1.Webhook>, 'id'>
export type WebhooksStatusResponse = CamelCasedPropertiesDeep<Api.V1.WebhooksStatusResponse>
export type WebhookStatisticsResponse = CamelCasedPropertiesDeep<Api.V1.WebhookStatisticsResponse>

export function getWebhooksStatus(clientId: string): Promise<WebhooksStatusResponse> {
  return request(route(ENDPOINTS.WEBHOOKS.WEBHOOKS_STATUS, { clientId }))
}
export function getWebhookStatistics(clientId: string, webhookId: string): Promise<WebhookStatisticsResponse> {
  return request(route(ENDPOINTS.WEBHOOKS.WEBHOOK_STATISTICS, { clientId, webhookId }))
}
export function getWebhooks(clientId: string): Promise<{ data: Webhook[] }> {
  return request(route(ENDPOINTS.WEBHOOKS.WEBHOOKS, { clientId }))
}
export function updateWebhook(clientId: string, webhook: Webhook): Promise<Webhook> {
  return request(route(ENDPOINTS.WEBHOOKS.WEBHOOK, { clientId, webhookId: webhook.id }), {
    method: HTTP.PUT,
    body: webhook,
  })
}
export function deleteWebhook(clientId: string, webhook: Webhook): Promise<unknown> {
  return request(route(ENDPOINTS.WEBHOOKS.WEBHOOK, { clientId, webhookId: webhook.id }), {
    method: HTTP.DELETE,
  })
}
export function createWebhook(clientId: string, webhook: WebhookUnsaved): Promise<Webhook> {
  return request(route(ENDPOINTS.WEBHOOKS.WEBHOOKS, { clientId }), {
    method: HTTP.POST,
    body: webhook,
  })
}
