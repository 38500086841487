import {
  Layout,
  Typography,
  SPACING,
  BORDER_RADIUS,
  Image,
  ChevronRightIcon,
  FONT_WEIGHT,
  FONT_SIZE,
} from '@community_dev/pixels'
import { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import styled, { useTheme, css } from 'styled-components'

type ActionCardProps = {
  title: string
  type?: string
  description: string
  imageSrc: string | string[]
  routeTo: string
  className?: string
  showArrow?: boolean
  showOnHoverEffect?: boolean
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  // Height is required for the image to not appear stretched
  height: string
}

const StyledDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const StyledTitle = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const onHoverEffect = css`
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  &:hover {
    transform: translateZ(0) scale(1.0175);
    box-shadow: ${({ theme }) => theme.SHADOWS.CARD};

    @media (hover: none) {
      transform: translateZ(0) scale(1);
    }
  }
`
const StyledLink = styled(Link)<{ $showOnHoverEffect: boolean }>`
  text-decoration: none;
  border-radius: ${BORDER_RADIUS[1]};
  ${({ $showOnHoverEffect }) => $showOnHoverEffect && onHoverEffect};
`

export const ActionCard = ({
  title,
  type,
  description,
  imageSrc,
  className,
  routeTo,
  showArrow = true,
  showOnHoverEffect = true,
  onClick,
  height,
}: ActionCardProps): JSX.Element => {
  const { COLORS } = useTheme()

  return (
    <StyledLink $showOnHoverEffect={showOnHoverEffect} onClick={onClick} to={routeTo}>
      <Layout
        backgroundColor={COLORS.APP_BACKGROUND_LEVEL_3}
        borderColor={COLORS?.BORDERS}
        borderRadius={BORDER_RADIUS[1]}
        borderStyle="solid"
        borderWidth="1px"
        className={className}
        height={height}
        padding={SPACING[3]}
      >
        <Layout alignItems="flex-start" display="flex" height="100%">
          <Image height="100%" src={imageSrc} />
          <Layout display="flex" flexDirection="column" marginLeft={SPACING[3]}>
            {type && (
              <Typography component="div" fontWeight={FONT_WEIGHT[5]} textAlign="left" variant="caption1">
                {type}
              </Typography>
            )}
            <StyledTitle>
              <Typography marginBottom={type ? SPACING[1] : 0} marginTop="0" textAlign="left" variant="h5">
                {title}
              </Typography>
            </StyledTitle>
            <StyledDescription>
              <Typography
                color={COLORS.SUBTEXT}
                fontSize={FONT_SIZE[2]}
                lineHeight="18px"
                margin="0"
                textAlign="left"
                variant="body2"
              >
                {description}
              </Typography>
            </StyledDescription>
          </Layout>
          {showArrow && (
            <Layout alignItems="center" display="flex" height="100%" marginLeft="auto" marginRight="0">
              <ChevronRightIcon />
            </Layout>
          )}
        </Layout>
      </Layout>
    </StyledLink>
  )
}
