import { SPACING } from '@community_dev/pixels'
import React from 'react'
import Moment from 'react-moment/src/index'
import styled from 'styled-components'

import { Media } from 'api/campaign'
import { Bubble } from 'components/Bubble'
import { BUBBLE_VARIANTS } from 'components/Bubble/Bubble'
import { MessageSourceType } from 'components/Bubble/MessageSource'
import { LinkPreviewType } from 'components/links'
import { DESKTOP_BREAK } from 'constants/theme'

const StyledTimeStamp = styled(Moment)`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 12px;
  line-height: 136%;
  letter-spacing: 0.16px;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  padding: 8px;

  span.ts-day {
    font-weight: 500;
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  }
`

type StyledConvoBubbleRootProps = {
  $incoming?: boolean
  $last?: boolean
}

const StyledConvoBubbleRoot = styled.div<StyledConvoBubbleRootProps>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${({ $incoming }) => ($incoming ? 'flex-start' : 'flex-end')};
  margin-bottom: ${({ $last }) => ($last ? SPACING[4] : 0)};

  &:not(.incoming) + &.incoming,
  &.incoming + &:not(.incoming) {
    ${StyledTimeStamp} {
      padding-top: 8px;
    }
  }
`

type StyledConvoBubbleMainProps = {
  $incoming?: boolean
}

export const StyledConvoBubbleMain = styled.div<StyledConvoBubbleMainProps>`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 78%;
  align-items: ${({ $incoming }) => ($incoming ? 'flex-start' : 'flex-end')};
  justify-content: ${({ $incoming }) => ($incoming ? 'flex-start' : 'flex-end')};

  @media (min-width: ${DESKTOP_BREAK}) {
    max-width: 65%;
  }
`

const calendarOpts = {
  nextDay: '[Tomorrow] [|]h:mm A',
  nextWeek: 'dddd [|]h:mm A',
  lastDay: '[Yesterday] [|]h:mm A',
  lastWeek: 'dddd [|]h:mm A',
  sameDay: '[Today] [|]h:mm A',
  sameElse: 'ddd, MMM D, YYYY, [|]h:mm A',
  sameYear: 'ddd, MMM D, [|]h:mm A',
}

const momentFilter = (d) => {
  const parts = d.split('|')
  return (
    <>
      <span className="ts-day">{parts[0]}</span>
      <span className="ts-time">{parts[1]}</span>
    </>
  )
}
export interface ConvoBubbleProps {
  id: string
  body: string | null
  incoming: boolean
  last: boolean
  media: Media | null
  onBodyClick?: () => void
  showTime: boolean
  status: string
  tail: boolean
  trending?: boolean
  ts: string
  sourceType?: MessageSourceType
  sourceTypeId?: string
  linkPreviewType?: LinkPreviewType
  onImageLoad?: () => void
  mediaMaxWidth?: number
  mediaMaxHeight?: number
}

export function ConvoBubble(props: ConvoBubbleProps): JSX.Element {
  const { showTime, ts, incoming, last = false, trending = false, linkPreviewType = 'rich', ...rest } = props

  return (
    <StyledConvoBubbleRoot $incoming={incoming} $last={last} data-testid={`convo-bubble-${props.id}`}>
      {showTime && ts && (
        <StyledTimeStamp
          calendar={calendarOpts}
          className="ConvoBubble-ts"
          data-testid="time"
          element="div"
          filter={momentFilter}
        >
          {ts}
        </StyledTimeStamp>
      )}
      <StyledConvoBubbleMain $incoming={incoming}>
        <Bubble
          incoming={incoming}
          linkPreviewType={linkPreviewType}
          trending={trending}
          variant={BUBBLE_VARIANTS.CONVERSATION}
          {...rest}
        />
      </StyledConvoBubbleMain>
    </StyledConvoBubbleRoot>
  )
}

ConvoBubble.selector = StyledConvoBubbleRoot
