import { PostCheckMessageContentReturn } from 'api/message'

type ProcessBadWordsReturn = {
  [key: string]: any
}

export const processBadWords = (badWords: PostCheckMessageContentReturn): ProcessBadWordsReturn => {
  const formattedWords = Object.entries(badWords).reduce((wordsDictionary, [type, words]) => {
    const formatted = words.map((word: string) => word.toLowerCase())

    return { ...wordsDictionary, [type]: formatted }
  }, {})

  return formattedWords
}
