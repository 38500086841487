import { WorkflowType } from '@community_dev/workflow-manifest'
import { Route, Switch } from 'react-router-dom'

import { WorkflowSidebarEditFlow } from '../WorkflowSidebarEditFlow'
import { WorkflowSidebarPerformance } from '../WorkflowSidebarPerformance'

import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { ROUTES } from 'constants/routes'

export function WorkflowSidebar(): JSX.Element | null {
  const { workflow } = useWorkflowProvider()
  return (
    <Switch>
      <Route component={WorkflowSidebarPerformance} exact path={ROUTES.AUTOMATIONS.FLOW_PERFORMANCE} />
      {/* In Shopify Abandoned Cart workflows, we don’t display the Edit Flow tab contents,
          and instead go directly to the performance tab */}
      <Route
        component={
          workflow?.type === WorkflowType.shopify_abandoned_checkout
            ? WorkflowSidebarPerformance
            : WorkflowSidebarEditFlow
        }
      />
    </Switch>
  )
}
