import { FanSubscriptionTags, DigitsFilter } from '@community_dev/filter-dsl/lib/digits'
import { Placeholder } from '@community_dev/filter-dsl/lib/subscription-data'
import { convertKeysToCamelCase } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import isObject from 'lodash/isObject'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { normalizeFan } from './normalizers/normalizeFan'

import { Fan } from 'api/fans'

export { normalizeFan } from './normalizers/normalizeFan'

type FormatPhoneOptions = {
  intl?: boolean
  parens?: boolean
}

export const formatPhone = (val?: string, options: FormatPhoneOptions = {}): string => {
  if (!val) return ''
  const { intl = true, parens = true } = options
  const nums = `${val}`.replace(/\D/g, '')
  const found = nums.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (!found) return val // returns phone number value if not found as 10DLC
  const parts = [intl && found[1] ? '+1 ' : '']
  if (parens) {
    parts.push('(', found[2], ') ')
  } else {
    parts.push(found[2], '-')
  }
  parts.push(found[3], '-', found[4])
  return parts.join('')
}

export const formatMediaHref = (url: string): string => {
  const [clientId, mediaGroup, filename] = (url || '').split('/').slice(-3)
  const prefix = (mediaGroup || '')[0] || ''
  const domain = (url || '').split('/uploads')[0] || ''
  return [domain, prefix, clientId, filename].filter((part) => !!part).join('/')
}

const normalizeStatus = (status: string): string => {
  switch (status) {
    case 'processed':
      return 'success'
    case 'media_processing':
      return 'pending'
    case 'media_transcoding_failed':
      return 'error'
    default:
      return status
  }
}

export type InboxItemNormalized = {
  id: string
  incoming: boolean
  unread: boolean
  fan: Fan | Record<string, never>
  last: LastMessageNormalized
}

export const normalizeItem = (item: Api.Chat, fan: any = {}): InboxItemNormalized | Record<string, never> => {
  if (item.constructor !== Object) return {}
  if (!fan || !item.last_msg) return {}
  return {
    id: item.id || '',
    incoming: item.direction === 'inbound',
    unread: item.has_new === true,
    fan: normalizeFan({ ...fan, ...item.fan_profile }),
    last: normalizeLastMessage(item.last_msg),
  }
}

export const normalizeClient = (data: any): any => {
  if (!isObject(data)) return {}
  const {
    auto_drip_enabled,
    email,
    first_name,
    id,
    international_enabled,
    last_name,
    master_phone_number,
    unsupported_country_onboarding_enabled,
    url_slug,
    voice_onboarding_enabled,
    voicemail_url,
    onboarding_tasks,
    profile_image,
    profile_image_small,
  }: any = data
  const graphicUrl = profile_image && profile_image.url
  const graphicUrlSmall = profile_image_small && profile_image_small.url
  const names = [first_name, last_name].filter((x: any) => !!x).map((x) => x.trim())

  return {
    autoDripEnabled: auto_drip_enabled || false,
    email: email || '',
    firstName: names[0] || '',
    fullName: names.join(' '),
    graphicUrl: graphicUrl || '',
    graphicUrlSmall: graphicUrlSmall || '',
    id: id || '',
    internationalEnabled: international_enabled || false,
    lastName: names[1] || '',
    phone: formatPhone(master_phone_number),
    phoneNumber: master_phone_number || '',
    slug: url_slug || '',
    unsupportedCountryOnboardingEnabled: unsupported_country_onboarding_enabled || false,
    urlSlug: url_slug || '',
    voicemail: voicemail_url || '',
    voiceOnboardingEnabled: voice_onboarding_enabled || false,
    onboardingTasks: onboarding_tasks || [],
  }
}

export type NormalizedKeyword = {
  id: string
  word: string
}

export const normalizeKeywords = (data: any[] = []): NormalizedKeyword[] =>
  data.map(({ id, original_keyword, originalKeyword }) => ({
    id: id || '',
    word: original_keyword || originalKeyword,
  }))

export const normalizeMedia = (data: any): any => {
  if (!data) return data
  const {
    id,
    filename,
    height,
    width,
    url,
    mime_type,
    mimeType,
    thumbnail_filename,
    thumbnailFilename,
    thumbnail_url,
    thumbnailUrl,
    type,
    sensitive,
  } = data

  return {
    id: id || '',
    type: mime_type || mimeType || type || '',
    filename: filename || '',
    href: url,
    url: url || '',
    thumbnailUrl: thumbnail_url || thumbnailUrl || '',
    thumbnailFilename: thumbnail_filename || thumbnailFilename || '',
    height: height || 0,
    width: width || 0,
    sensitive,
  }
}

export type SentNormalized = {
  body: string
  fanSubscriptionTags: CamelCasedPropertiesDeep<FanSubscriptionTags>
  filters: CamelCasedPropertiesDeep<DigitsFilter>
  id: string
  media: CamelCasedPropertiesDeep<Api.V2.Media> | null
  parentItemId: string | null
  placeholders: CamelCasedPropertiesDeep<Placeholder[]>
  recipientCount: number
  replies: SentNormalized[]
  representativeMessage: CamelCasedPropertiesDeep<Api.V2.RepresentativeMessage> | null
  status: string
  tags: string[]
  ts: string
}

export const normalizeSent = (item: any, parentItemId: string | null = null): SentNormalized | undefined => {
  if (!item) return
  const {
    createdAt,
    fanSubscriptionTags,
    filters,
    id,
    media,
    parentSmsCampaignId,
    placeholders,
    recipientCount,
    representativeMessage,
    scheduledAt,
    status,
    tags,
    text,
  } = convertKeysToCamelCase(item)
  const replies = item.replies && item.replies.length > 0 ? item.replies.map((r) => normalizeSent(r, id)) : []

  return {
    body: text || '',
    fanSubscriptionTags: fanSubscriptionTags || { any: [], not: [] },
    filters: filters || {},
    id: id || '',
    media: normalizeMedia(media),
    parentItemId: parentItemId || parentSmsCampaignId,
    placeholders: placeholders || [],
    recipientCount: recipientCount || 0,
    replies,
    representativeMessage: representativeMessage || null,
    status: normalizeStatus(status),
    tags,
    ts: scheduledAt || createdAt || '',
  }
}

type LastMessageNormalized =
  | {
      body: string
      ts: string
      media?: any
    }
  | Record<string, never>

export const normalizeLastMessage = (data: Api.Chat['last_msg']): LastMessageNormalized => {
  if (!data || data.constructor !== Object) return {}
  const { text, created_at } = data
  return { body: text || '', ts: created_at || '' }
}

type Pagination = {
  end_date: string
  has_next_page: boolean
  next_end_date?: string
  page_number?: number
  page_size: number
}

export type PaginationNormalized = {
  latestTs?: string
  hasNext?: boolean
  nextTs?: string
  page?: number
  per?: number
}

export const normalizePagination = (data: Pagination): PaginationNormalized => {
  if (data.constructor !== Object) return {}
  const { has_next_page, next_end_date, page_number, page_size, end_date } = data
  return {
    hasNext: has_next_page,
    nextTs: next_end_date,
    page: page_number,
    per: page_size,
    latestTs: end_date,
  }
}

export const titleize = (sentence: string): string => {
  if (!sentence.split) return sentence
  const parts = sentence.split(' ').map((word) => {
    if (typeof word !== 'string') return word
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  })
  return parts.join(' ')
}

export const normalizeGiphy = (resp: any): any =>
  resp.data.map((gif) => {
    const smallImg = gif.images.fixed_height_small
    const { original } = gif.images
    return {
      preview: {
        src: smallImg.url,
        height: smallImg.height,
        width: smallImg.width,
      },
      original: {
        src: original.url,
        height: original.height,
        width: original.width,
        mp4: original.mp4,
      },
    }
  })

const sortByKey = (data, key) => data.sort((obj1, obj2) => obj2[key] - obj1[key])

export type CampaignInsight = {
  count: number
  name: string
}

export const normalizeCampaignInsights = ({
  responseMetadata,
}: {
  responseMetadata: CamelCasedPropertiesDeep<Api.V2.ResponseMetadata>
}): {
  responses: CampaignInsight[]
  tapbacks: CampaignInsight[]
} => {
  let responseCategories = {
    question: { name: 'Questions', count: 0 },
    'thank-you': { name: 'Thanks', count: 0 },
    loving: { name: 'Loving', count: 0 },
    happy: { name: 'Happy', count: 0 },
    yes: { name: 'Yes', count: 0 },
    no: { name: 'No', count: 0 },
  }

  let tapbackCategories = {
    'tapback-disliked': { name: 'Disliked', count: 0 },
    'tapback-laughed': { name: 'Laughed', count: 0 },
    'tapback-questioned': { name: 'Questioned', count: 0 },
    'tapback-emphasized': { name: 'Emphasized', count: 0 },
    'tapback-loved': { name: 'Loved', count: 0 },
    'tapback-liked': { name: 'Liked', count: 0 },
  }

  let responses: CampaignInsight[] = []
  let tapbacks: CampaignInsight[] = []
  const campaignInsights = responseMetadata?.categories || []

  campaignInsights.forEach((data) => {
    switch (data.type) {
      case 'public':
        if (responseCategories[data.name]) {
          responses = [
            ...responses,
            {
              name: responseCategories[data.name].name,
              count: data.count,
            },
          ]
          responseCategories = {
            ...responseCategories,
            [data.name]: {
              name: responseCategories[data.name].name,
              count: data.count,
            },
          }
        }
        break
      case 'tapback':
        if (tapbackCategories[data.name]) {
          tapbacks = [
            ...tapbacks,
            {
              name: tapbackCategories[data.name].name,
              count: data.count,
            },
          ]
          tapbackCategories = {
            ...tapbackCategories,
            [data.name]: {
              name: tapbackCategories[data.name].name,
              count: data.count,
            },
          }
        }
        break
      default:
        break
    }
  })

  let zeroResponsesCount = 0
  const TOTAL_RESPONSE_CATEGORIES = 4
  Object.values(responseCategories).forEach((cat) => {
    if (!cat.count) {
      responses.push({ ...cat })
      zeroResponsesCount += 1
    }
  })

  if (zeroResponsesCount === TOTAL_RESPONSE_CATEGORIES) {
    responses = []
  }

  Object.values(tapbackCategories).forEach((cat) => !cat.count && tapbacks.push({ ...cat }))

  return {
    responses: sortByKey(responses, 'count'),
    tapbacks: sortByKey(tapbacks, 'count'),
  }
}
