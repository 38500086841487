import { SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { Setting, StyledAction } from 'components/Settings'

export default styled(Setting)`
  position: relative;
  padding: ${SPACING[4]} 0;
  padding-left: ${({ isNested }) => (isNested ? '44px' : '0')};
  border-bottom: none;
  ${StyledAction} {
    color: ${({ theme, isActionDestructive }) => (isActionDestructive ? theme?.COLORS?.ERRORS : theme?.COLORS?.TEXT)};
    ${({ actionAs }) => actionAs === 'a' && 'cursor: pointer; text-decoration: none;'}
  }
`
