import {
  BuiltInFields,
  FanEnabledStatuses,
  fieldLabelFor,
  FieldSources,
  fieldTypeFor,
  FilterOperator,
  isRelationshipFilter,
  isRelationshipOperator,
  isSelectorFilter,
  isSelectorOperator,
  MemberDataFilter,
  RelationshipFilter,
  RelationshipOperators,
  SelectorFilter,
  SelectorOperators,
  SUBSCRIPTION_DATA_FILTER_DATE_FORMAT,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { BUTTON_VARIANTS, Button, Layout, SPACING, Select } from '@community_dev/pixels'
import { useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  convertFromBetweenValue,
  convertToBetweenValue,
  generateOptions,
  getOperandBetweenValue,
  getOperandValue,
  hasChangedFromBetween,
  hasChangedToBetween,
  TypeOperators,
  validateDate,
} from './RecommendationsCustomFields/fields/utils'

import dayjs from 'utils/dayjs'

const StyledCompoundField = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: ${SPACING[1]};
  margin-right: ${SPACING[2]};
  margin-bottom: ${SPACING[2]};
`

const StyledBetweenContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 0 ${SPACING[4]} ${SPACING[4]};

  > span {
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
    font-weight: 600;
  }
`

const StyledSubmitButton = styled(Button)`
  padding: 10px 2px;
  border-radius: 6px;
  margin: ${SPACING[2]} 0;
`

const StyledDateInput = styled.input`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 10px 8px 10px;
  outline: none;
  line-height: normal;
  color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT};
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  border-radius: 4px;
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};

  &::placeholder {
    color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT_PLACEHOLDER};
  }
`

type CustomJoinDateFormProps = {
  onAdd(filter: MemberDataFilter): void
}

const dateOperators: TypeOperators = {
  [SelectorOperators.GREATER_THAN]: 'greaterThan',
  [SelectorOperators.LESS_THAN]: 'lessThan',
  [SelectorOperators.EQUALS]: 'equals',
  [RelationshipOperators.AND]: 'and',
}

export function CustomJoinDateForm({ onAdd }: CustomJoinDateFormProps): JSX.Element {
  const { t } = useTranslation(undefined, { keyPrefix: 'customData' })

  const field = {
    name: fieldLabelFor(BuiltInFields.JOINED),
    // this only used to match the type for FieldDefinition
    fan_enabled_status: FanEnabledStatuses.IS_ENABLED_REQUIRED,
    key: BuiltInFields.JOINED,
    source: FieldSources.BUILT_IN,
    value_type: fieldTypeFor(BuiltInFields.JOINED),
    value_options: null,
  }

  const options = useMemo(() => {
    return generateOptions({ t, typeOperators: dateOperators, type: field.value_type })
  }, [t, field.value_type])

  const handleSubmit = (values) => {
    onAdd(values['join-date'])
  }

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, valid, pristine }) => {
        return (
          <StyledForm onSubmit={handleSubmit}>
            <Field name="join-date" validate={validateDate} validateOnBlur>
              {({ input }) => {
                const handleSelectChange = (option: { value: FilterOperator; label: string } | null): void => {
                  if (option) {
                    const { value: operator } = option
                    if (hasChangedToBetween({ nextOperator: operator, previousValue: input?.value })) {
                      input.onChange(convertToBetweenValue({ field, previousValue: input?.value }))
                      return
                    }

                    if (hasChangedFromBetween({ nextOperator: operator, previousValue: input?.value })) {
                      input.onChange(
                        convertFromBetweenValue({
                          field,
                          previousValue: input?.value,
                          operator: operator as SelectorOperators,
                        }),
                      )
                      return
                    }

                    input.onChange({
                      ...(input?.value || {}),
                      operator,
                    } as MemberDataFilter)
                  }
                }

                const handleSingleValueChange = (value): void => {
                  input.onChange({
                    operator: input.value.operator,
                    operand: {
                      field_key: field.key,
                      field_label: field.name,
                      source: field.source,
                      type: field.value_type,
                      value: dayjs(value).format(SUBSCRIPTION_DATA_FILTER_DATE_FORMAT).slice(0, 10) + 'T00:00:00.000Z',
                    },
                  } as MemberDataFilter)
                }

                const handleBetweenValueChange =
                  (idx: number) =>
                  (value): void => {
                    if (isRelationshipFilter(input?.value)) {
                      const operands = input?.value?.operands.map((filter, operandIdx) => {
                        if (operandIdx === idx && isSelectorFilter(filter)) {
                          return {
                            ...filter,
                            operand: {
                              field_key: field.key,
                              field_label: field.name,
                              source: field.source,
                              type: field.value_type,
                              value:
                                dayjs(value).format(SUBSCRIPTION_DATA_FILTER_DATE_FORMAT).slice(0, 10) +
                                'T00:00:00.000Z',
                            },
                          }
                        }

                        return filter
                      })

                      input.onChange({
                        ...input.value,
                        operands,
                      } as MemberDataFilter)
                    }
                  }

                const selectedOperator = options.find((operator) => operator.value === input?.value?.operator)

                return (
                  <div>
                    <StyledCompoundField>
                      <Select
                        inline
                        instanceId="datetime-"
                        onChange={handleSelectChange}
                        options={options}
                        value={selectedOperator}
                        width="130px"
                      />
                      {isSelectorOperator(selectedOperator?.value) && (
                        <StyledDateInput
                          data-testid="date-input-selector"
                          onChange={(e) => handleSingleValueChange(e.target.value)}
                          type="date"
                          value={input?.value ? getOperandValue(input?.value as SelectorFilter) : undefined}
                        />
                      )}
                    </StyledCompoundField>
                    {isRelationshipOperator(selectedOperator?.value) && (
                      <StyledBetweenContainer>
                        <StyledDateInput
                          data-testid="date-input-relationship-1"
                          onChange={(e) => handleBetweenValueChange(0)(e.target.value)}
                          type="date"
                          value={getOperandBetweenValue(0, input?.value as RelationshipFilter)}
                        />

                        <Layout padding={SPACING[1]}>and</Layout>
                        <StyledDateInput
                          data-testid="date-input-relationship-2"
                          onChange={(e) => handleBetweenValueChange(1)(e.target.value)}
                          type="date"
                          value={getOperandBetweenValue(1, input?.value as RelationshipFilter)}
                        />
                      </StyledBetweenContainer>
                    )}
                  </div>
                )
              }}
            </Field>
            <StyledSubmitButton disabled={!valid || pristine} type="submit" variant={BUTTON_VARIANTS.PRIMARY}>
              Apply
            </StyledSubmitButton>
          </StyledForm>
        )
      }}
    />
  )
}
