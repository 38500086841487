import { CloseIcon, Dropzone, FONT_SIZE, Modal } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useCallback } from 'react'
import { FileRejection } from 'react-dropzone'
import styled, { useTheme } from 'styled-components'

import { FileContext } from 'components/FileRejectionDialog'
import Convo from 'containers/Convo'
import { BoundsProvider } from 'contexts/BoundsProvider'
import { ConvoProvider } from 'contexts/ConvoProvider'
import { useUpload } from 'contexts/UploadProvider'
import { useFan } from 'hooks/useFan'
import { useMediaConfig } from 'hooks/useMediaConfig'

type ConvoModalProps = {
  fanId: string
  onClose: () => any
}

const StyledModalHeader = styled(Modal.Header)`
  background: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_2};
  font-size: ${FONT_SIZE[3]};
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`

export const ConvoModal = ({ fanId, onClose }: ConvoModalProps): JSX.Element => {
  const { setFile } = useUpload()
  const { COLORS } = useTheme()
  const { data: fan } = useFan(fanId)

  const fullName = fan?.fullName
  const communicationChannel = fan?.communicationChannel || CommunicationChannel.SMS
  const { accept, maxSize } = useMediaConfig({
    communicationChannel,
    context: FileContext.DM,
  })

  const onFileAccepted = (file) => {
    setFile(file)
  }

  const onFileRejected = useCallback(
    (fileRejection: FileRejection) => {
      setFile(fileRejection.file)
    },
    [setFile],
  )

  return (
    <BoundsProvider>
      <Modal margin="auto" maxHeight={680} maxWidth={720} minHeight="80vh" minWidth={720} onClose={onClose} open>
        <StyledModalHeader>
          <Modal.Header.Center>{fullName}</Modal.Header.Center>
          <Modal.Header.Right onClose={onClose}>
            <StyledCloseIcon color={COLORS?.SUBTEXT} size={12} />
          </Modal.Header.Right>
        </StyledModalHeader>
        <Modal.Body style={{ position: 'relative' }}>
          <Dropzone
            accept={accept}
            maxSize={maxSize}
            noClick
            onFileAccepted={onFileAccepted}
            onFileRejected={onFileRejected}
          >
            <ConvoProvider>
              <Convo fanId={fanId} />
            </ConvoProvider>
          </Dropzone>
        </Modal.Body>
      </Modal>
    </BoundsProvider>
  )
}
