import { Typography, FONT_SIZE, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { ActionCard } from 'components/ActionCard'
import { AddOnWithRouteTo } from 'hooks/useAddons'

type AddOnsSectionProps = {
  title: string
  cards: AddOnWithRouteTo[]
  className?: string
}

const StyledCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
`
export const AddOnsSection = ({ title, cards, className }: AddOnsSectionProps): JSX.Element => {
  return (
    <div className={className}>
      <Typography fontSize={FONT_SIZE[6]} margin="0" marginBottom={SPACING[4]} variant="h2">
        {title}
      </Typography>
      <StyledCardContainer>
        {cards.map((card) => (
          <ActionCard
            {...card}
            height="85px"
            // We dont want to show the type
            key={card.title}
            type={undefined}
          />
        ))}
      </StyledCardContainer>
    </div>
  )
}
