import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { useTheme } from 'styled-components'

import { StyledListCommunityIcon, StyledListIcon, iconColor } from './styled'

type ComposeNavigationIconProps = {
  activated?: boolean
  comIcon?: any
  disabled?: boolean
  faIcon?: FontAwesomeIconProps['icon']
  selected?: boolean
}

function CommunityIcon({ comIcon: ComIcon, disabled }) {
  const theme = useTheme()
  return (
    <StyledListCommunityIcon>
      <ComIcon
        color={iconColor({
          disabled,
          theme,
        })}
        size={20}
      />
    </StyledListCommunityIcon>
  )
}

function FaIcon({ faIcon, disabled }) {
  if (!faIcon) return null

  return (
    <StyledListIcon disabled={disabled}>
      <FontAwesomeIcon icon={faIcon} />
    </StyledListIcon>
  )
}
export function ComposeNavigationIcon({
  comIcon: ComIcon,
  faIcon,
  disabled = false,
}: ComposeNavigationIconProps): JSX.Element | null {
  return faIcon ? (
    <FaIcon disabled={disabled} faIcon={faIcon} />
  ) : (
    <CommunityIcon comIcon={ComIcon} disabled={disabled} />
  )
}
