import React from 'react'

import { IconProps } from './IconProps'

export const ChevronDownIcon = ({ size = 16, color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg fill="none" height={size} viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M10.842 5.8897C11.0874 5.64029 11.4895 5.64029 11.7349 5.8897C11.9747 6.13342 11.9747 6.52441 11.7349 6.76814L8.68576 9.86741C8.30903 10.2503 7.69162 10.2503 7.31489 9.86741L4.26577 6.76814C4.02599 6.52441 4.02599 6.13342 4.26577 5.8897C4.51115 5.64029 4.91328 5.64029 5.15866 5.8897L7.31489 8.0814C7.69162 8.46433 8.30903 8.46433 8.68576 8.0814L10.842 5.8897Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.842 5.8897C11.0874 5.64029 11.4895 5.64029 11.7349 5.8897C11.9747 6.13342 11.9747 6.52441 11.7349 6.76814L8.68576 9.86741C8.30903 10.2503 7.69162 10.2503 7.31489 9.86741L4.26577 6.76814C4.02599 6.52441 4.02599 6.13342 4.26577 5.8897C4.51115 5.64029 4.91328 5.64029 5.15866 5.8897L7.31489 8.0814C7.69162 8.46433 8.30903 8.46433 8.68576 8.0814L10.842 5.8897Z"
        fill="#333333"
        fillOpacity="0.04"
        fillRule="evenodd"
      />
    </svg>
  )
}
