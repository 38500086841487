import { SPACING, Layout, Typography, LoadingIndicator } from '@community_dev/pixels'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { TermedInvoiceBillingForm } from './TermedInvoiceBillingForm'

import { ROUTES } from 'constants/routes'
import { useClientSecret } from 'hooks/billing/useClientSecret'
import { useStripeCustomTheme } from 'hooks/useStripeCustomTheme'
import SimpleLayout from 'layouts/SimpleLayout'

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || '')

export const CollectBillingScreen = (): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const { data, isLoading, isError } = useClientSecret()
  const appearance = useStripeCustomTheme()
  if (isLoading) return <LoadingIndicator />

  if (isError) {
    return <Redirect to={ROUTES.ONBOARDING.CONTACT_US.ROOT} />
  }

  return (
    <SimpleLayout showContextSwitcher>
      <Layout alignSelf="stretch" background={COLORS?.APP_BACKGROUND_LEVEL_2} flex={1} justifyContent="center">
        <Layout
          alignItems="center"
          alignSelf="center"
          display="flex"
          flexDirection="column"
          marginTop={SPACING[8]}
          padding={`0 ${SPACING[5]}`}
          textAlign="center"
        >
          <Typography marginBottom={SPACING[8]} variant="h1">
            {t('billing.reviewAndConfirmPaymentDetails')}
          </Typography>
          <Elements options={{ clientSecret: data?.clientSecret, appearance }} stripe={stripePromise}>
            <TermedInvoiceBillingForm />
          </Elements>
        </Layout>
      </Layout>
    </SimpleLayout>
  )
}
