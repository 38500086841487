import { useMemo } from 'react'

import { Reactions } from './Reactions'

import { useCampaignDetails } from 'hooks/useCampaign'
import { normalizeCampaignInsights } from 'utils/normalize'

type CampaignReactionsProps = {
  campaignId: string
}

export function CampaignReactions({ campaignId }: CampaignReactionsProps): JSX.Element {
  const { data } = useCampaignDetails({ campaignId })
  const reactions = useMemo(() => {
    if (!data?.responseMetadata) return []
    return normalizeCampaignInsights({ responseMetadata: data.responseMetadata }).tapbacks
  }, [data])

  return <Reactions reactions={reactions} />
}
