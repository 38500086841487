import { useTranslation } from 'react-i18next'

import { useTimezones } from './useTimezones'

import dayjs from 'utils/dayjs'

export function UserTimezone(): JSX.Element | null {
  const { t } = useTranslation()
  const { communityLocalTimezone, localTimezone } = useTimezones()

  if (!communityLocalTimezone || !localTimezone) return null
  if (communityLocalTimezone.timeZoneGroup) {
    return <span>{t(`timezones.${communityLocalTimezone?.timeZoneGroup}`)}</span>
  }

  const abbr = dayjs().tz(localTimezone.representativeTzid).format('z')
  return <span>{/^(\+|-)/.test(abbr) ? `GMT ${abbr}` : abbr}</span>
}
