import { DigitsFilter } from '@community_dev/filter-dsl/lib/digits'
import { digitsFilterToMemberDataFilter } from '@community_dev/filter-dsl/lib/transformers/digitsFilterToMemberDataFilter'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'

import { postAssignByQuery } from 'api/community'
import { SearchResult } from 'api/search'
import { COMMUNITY_SORT_BY_MEMBER_COUNT } from 'constants/launch-darkly-flags'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { userHasFlag } from 'hooks/useLaunchDarkly'
import analytics from 'utils/analytics'

export const SEARCH_SELECTION_STATE = {
  ENTIRE_QUERY_SELECTED: 'ENTIRE_QUERY_SELECTED',
  EVERYONE_SELECTED: 'EVERYONE_SELECTED',
  NON_SELECTED: 'NON_SELECTED',
  PARTIALLY_SELECTED: 'PARTIALLY_SELECTED',
} as const

export type SearchSelectionProps = {
  communicationChannel: CommunicationChannel
  allMembers: SearchResult[]
  end: string
  exact: boolean
  search: string
  start: string
  page: number
  sort: string
}

export type UseSearchSelection = {
  assignMembersToCommunity(
    id: string,
    communicationChannel: CommunicationChannel,
    onSuccess?: (data: any) => void,
    onError?: (err: any) => void,
  ): void
  filters: DigitsFilter
  removeAllMembers: () => void
  removeMember(member: SearchResult): void
  selectAllMembers(): void
  selected: SearchResult[]
  selectionState: keyof typeof SEARCH_SELECTION_STATE
  selectMember(member: SearchResult): void
  selectMembers(members: SearchResult[]): void
}

function getState(
  allMembers: SearchResult[],
  selected: SearchResult[],
  selectAllSelected: boolean,
): keyof typeof SEARCH_SELECTION_STATE {
  if (selectAllSelected) return SEARCH_SELECTION_STATE.ENTIRE_QUERY_SELECTED
  if (selected.length === 0) return SEARCH_SELECTION_STATE.NON_SELECTED
  if (selected.length === allMembers.length) {
    return SEARCH_SELECTION_STATE.EVERYONE_SELECTED
  }
  return SEARCH_SELECTION_STATE.PARTIALLY_SELECTED
}

export function useSearchSelection({
  allMembers,
  end,
  exact,
  search,
  start,
  page,
  sort,
}: SearchSelectionProps): UseSearchSelection {
  const clientId = useClientId()
  const queryClient = useQueryClient()
  const isSortByMemberCountEnabled = userHasFlag(COMMUNITY_SORT_BY_MEMBER_COUNT)
  const [selected, setSelected] = useState<SearchResult[]>([])
  const [selectAllSelected, setSelectAllSelected] = useState(false)
  const filters: DigitsFilter = useMemo(() => {
    return {
      query: {
        full_text_search: {
          client_id: clientId,
          search_query: search,
          exact_match: exact,
          start_datetime: start,
          end_datetime: end,
          only_media: false,
          exclusive_fan_subscription_ids: selected.map((fan) => fan.subscriptionId),
          exclude_fan_subscription_ids: [],
          include_fan_subscription_ids: [],
        },
      },
    }
  }, [selected])
  const { mutateAsync: assignToCommunityAsync } = useMutation(postAssignByQuery)
  const selectionState = getState(allMembers, selected, selectAllSelected)

  useEffect(() => {
    // Reset all values when the search changes
    removeAllMembers()
    setSelectAllSelected(false)
  }, [end, start, search, exact, page, sort])

  useEffect(() => {
    analytics.track(
      analytics.events.FullTextSearchFiltersApplied({
        relatedSearchTerm: search,
        start,
        end,
        exact,
        sort,
      }),
    )
  }, [search, start, end, exact, sort])

  const selectMember = (member: SearchResult) => {
    setSelected([...selected, member])
  }

  const selectMembers = (members: SearchResult[]) => {
    setSelected(members)
  }

  const removeMember = (member: SearchResult) => {
    if (selectAllSelected) {
      setSelectAllSelected(false)
      setSelected(allMembers.filter((item) => item.subscriptionId !== member.subscriptionId))
      return
    }
    setSelected(selected.filter((item) => item.subscriptionId !== member.subscriptionId))
  }

  const removeAllMembers = () => {
    setSelectAllSelected(false)
    setSelected([])
  }

  const selectAllMembers = () => {
    setSelectAllSelected(true)
    setSelected([])
  }

  const assignMembersToCommunity = (
    id: string,
    communicationChannel: CommunicationChannel,
    onSuccess?: (data: any) => void,
    onError?: (err: any) => void,
  ) => {
    const memberDataFilters = digitsFilterToMemberDataFilter(filters)
    if (memberDataFilters !== null) {
      const convertedFilter = { subscription_data: memberDataFilters }
      assignToCommunityAsync({
        id,
        communicationChannel,
        filters: convertedFilter,
        clientId,
        fanSubscriptionTags: { any: [], not: [] },
      })
        .then((data) => {
          if (isSortByMemberCountEnabled) {
            queryClient.invalidateQueries([QUERY_CACHE.TAGS])
          }
          if (!onSuccess) return
          onSuccess(data)
        })
        .catch((err) => {
          if (!onError) return
          onError(err)
        })
    } else {
      onError?.(new Error('Could not convert FTS filter for assign by query'))
    }
  }

  return {
    assignMembersToCommunity,
    filters,
    removeAllMembers,
    removeMember,
    selectAllMembers,
    selected,
    selectionState,
    selectMember,
    selectMembers,
  }
}
