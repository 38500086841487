import { AudioPlayer } from '@community_dev/pixels'

type AudioProps = {
  src?: string
}

const Audio = ({ src = '' }: AudioProps): JSX.Element => {
  return <AudioPlayer src={src} />
}

export default Audio
