import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  findOne,
  MostActiveMembersFilter,
  MostActiveMembersMatch,
  SelectorOperators,
  serializeFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { InfoIconFilled, ListItem, SPACING, Tooltip } from '@community_dev/pixels'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'
import styled from 'styled-components'

import { RecommendationsLoadingIndicator } from '../RecommendationsLoadingIndicator'

import { useRecipientField } from './RecipientFieldContext'
import {
  StyledAddSuffix,
  StyledButton,
  StyledHeader,
  StyledHeading,
  StyledMeta,
  StyledPanel,
} from './styled-recipients'

import { MostActiveMembersOption, useMostActiveMembers } from 'containers/RecipientRecommendations/useMostActiveMembers'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import analytics from 'utils/analytics'

const StyledTooltipHeader = styled.p`
  font-weight: bold;
`

const StyledTooltipMessage = styled.div`
  padding-left: ${SPACING[2]};
`

function TooltipMessage() {
  return (
    <StyledTooltipMessage>
      <StyledTooltipHeader>How is activity calculated?</StyledTooltipHeader>
      <p>
        We are able to predict which Members are most likely to engage with your links and media. This calculation is
        based on how recently a Member joined your Community, link clicks, and media viewing.
      </p>
    </StyledTooltipMessage>
  )
}

export function RecommendationsActivity(): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { mostActiveMembersOptions, isLoading } = useMostActiveMembers()
  const { setIsOpen } = useRecipientField()
  const { addFilter, removeFilter, activeSubtree } = useFilters()

  const selectedNode = useMemo(() => {
    return findOne(activeSubtree, MostActiveMembersMatch)
  }, [activeSubtree])

  const selected = useMemo(() => {
    if (selectedNode) {
      return serializeFilters(selectedNode) as MostActiveMembersFilter
    }
  }, [selectedNode])

  const onAddFilter = (option: MostActiveMembersOption) => {
    addFilter({
      operator: SelectorOperators.LESS_THAN_OR_EQUALS,
      operand: {
        field_key: BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
        field_label: fieldLabelFor(BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT),
        source: FieldSources.BUILT_IN,
        type: FieldTypes.INTEGER,
        value: String(option.percentage),
      },
    })

    analytics.track(analytics.events.ActivityFilterSelected({ percentage: option.percentage }))

    setIsOpen(false)
  }

  return (
    <StyledPanel>
      <StyledMeta>
        <StyledHeader>
          <StyledHeading>Activity</StyledHeading>
          <Tooltip content={<TooltipMessage />} placement="bottom-end">
            <span>
              <InfoIconFilled size={20} />
            </span>
          </Tooltip>
        </StyledHeader>
      </StyledMeta>
      {isLoading ? (
        <RecommendationsLoadingIndicator />
      ) : (
        <div data-testid="recommendations-activity-panel">
          {selected ? (
            <ListItem
              as="div"
              label={`Top ${selected.operand.value}% Active`}
              subtext="Members"
              suffix={
                <StyledAddSuffix>
                  <StyledButton
                    $color={COLORS?.ERRORS}
                    onClick={() => {
                      removeFilter(selected)
                      setIsOpen(false)
                    }}
                  >
                    Remove
                  </StyledButton>
                </StyledAddSuffix>
              }
            />
          ) : (
            mostActiveMembersOptions?.map((option) => (
              <ListItem
                key={option.percentage}
                label={`Top ${option.percentage}% Active`}
                onClick={() => onAddFilter(option)}
                subtext="Members"
              />
            ))
          )}
        </div>
      )}
    </StyledPanel>
  )
}
