import { useTranslation } from 'react-i18next'

import { StyledContainer, StyledHeading, StyledSubheading } from './styled'

export function CampaignsError(): JSX.Element {
  const { t } = useTranslation()

  return (
    <StyledContainer>
      <StyledHeading>{t('failedToLoadData')}</StyledHeading>
      <StyledSubheading>{t('checkInternetConnection')}</StyledSubheading>
    </StyledContainer>
  )
}
