import { Dialog } from '@community_dev/pixels'
import React, { useEffect, useState } from 'react'

import { SET_DIALOG } from 'constants/dialog'

type AppDialogProps = {
  title?: React.ReactNode
  message?: React.ReactNode
  dismissText?: React.ReactNode
}

const AppDialog = (): JSX.Element | null => {
  const [dialog, setDialog] = useState<AppDialogProps>({})
  const { message, title, dismissText } = dialog
  useEffect(() => {
    const handler = (e: Event) => {
      setDialog((e as CustomEvent).detail)
    }
    window.addEventListener(SET_DIALOG, handler)
    return () => window.removeEventListener(SET_DIALOG, handler)
  }, [])
  if (!message && !title) return null
  return (
    <div>
      <Dialog message={message} title={title}>
        <Dialog.Action onClick={() => setDialog({})}>{dismissText || 'OK'}</Dialog.Action>
      </Dialog>
    </div>
  )
}

export default AppDialog
