import { PlanPaymentMethodRow } from '../../PlanPaymentMethodRow'

import { useSubscription } from 'hooks/billing/useSubscription'
import { useClient } from 'hooks/useClient'
import { SettingsSection } from 'layouts/SettingsLayout'

export const PaymentSection = (): JSX.Element | null => {
  const { data: client } = useClient()
  const { data, isError } = useSubscription()

  const isBillingRequired = client?.onboardingBillingState?.billingRequired

  // DO NOT show the Payment Section:
  // if subscription has no data or the request errors
  // if there is no billingProvider
  // if billingProvider is 'stripe' but billing is not required
  if (!data || isError || !data.billingProvider || (data.billingProvider === 'stripe' && !isBillingRequired)) {
    return null
  }

  return (
    <SettingsSection>
      <PlanPaymentMethodRow />
    </SettingsSection>
  )
}
