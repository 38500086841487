import { GenderLabels } from '@community_dev/filter-dsl/lib/humanize/builtInFilters/humanizeGenderFilter'
import { CheckBox, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { HighlightMatchedText } from '../HighlightMatchedText'

import { SearchResult as Result } from 'api/search'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import analytics from 'utils/analytics'
import dayjs from 'utils/dayjs'
import { NO_DATA_TEXT, formatLocation } from 'utils/normalizers/utils'

const StyledName = styled.p`
  margin: 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
`

const StyledDetails = styled.p`
  margin: 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledTextHighlight = styled.p`
  flex: 1;
  margin: 0;
  color: ${({ theme }) => theme?.COLORS?.HEADLINES};

  em {
    font-style: normal;
    display: inline-block;
    background-color: rgba(30, 144, 255, 0.16);
  }
`

const StyledMessage = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};

  p {
    margin: 0;
  }
`

const StyledCheckbox = styled.div`
  padding: ${SPACING[4]} ${SPACING[4]};
`

export const StyledResultItem = styled.button`
  flex-grow: 1;
  border: none;
  background: none;
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  padding: 0 ${SPACING[4]} ${SPACING[4]} 46px;
  cursor: pointer;
  text-align: left;
`

const StyledResultMember = styled.div`
  display: flex;
`

const StyledDate = styled.p`
  width: 140px;
  margin-left: ${SPACING[4]};
  text-align: right;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledResult = styled.div<{ $selected: boolean }>`
  ${({ $selected }) =>
    $selected
      ? css`
          background-color: ${({ theme }) => theme?.COLORS?.DEPRECATED_SELECTED};
        `
      : css`
          &:hover {
            ${StyledResultMember}, ${StyledResultItem} {
              background-color: ${({ theme }) => theme?.COLORS?.DEPRECATED_HOVER};
            }
          }
        `}
`

const StyledMember = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: left;
  width: 100%;
  padding-top: ${SPACING[4]};
`

type SearchResultProps = {
  result: Result & { communicationChannel: CommunicationChannel }
  selected: boolean
  onRemove(fan: Result): void
  onSelect(fan: Result): void
  onClick(): void
}

// mixpanel
const TEXT_SEARCH = 'text search'
const NAME_SEARCH = 'name search'
const BOTH = 'both'

const toMixpanelType = (result: Result) => {
  const hasNameHighlight = Boolean(result.nameHighlight[0])
  const hasTextHighlight = Boolean(result.textHighlight[0])

  if (hasNameHighlight && hasTextHighlight) {
    return BOTH
  }
  return hasNameHighlight ? NAME_SEARCH : TEXT_SEARCH
}

export function SearchResult({ result, selected, onRemove, onSelect, onClick }: SearchResultProps): JSX.Element {
  const { t } = useTranslation()
  const onClickWithTracking = useCallback(() => {
    analytics.track(analytics.events.FullTextSearchSearchResultOpened(toMixpanelType(result)))
    onClick()
  }, [result, onClick])

  // When doing a contact search, the textHighlight property could be empty
  // In that case, we just render the message text
  const message = result.textHighlight[0] || result.messageText

  const name = result.nameHighlight[0] || result.name

  return (
    <StyledResult $selected={selected} data-testid={`search-result-${result.subscriptionId}`}>
      <StyledResultMember>
        <StyledCheckbox>
          <CheckBox
            onChange={() => {
              const action = selected ? onRemove : onSelect
              action(result)
            }}
            selected={selected}
          />
        </StyledCheckbox>
        <StyledMember onClick={onClickWithTracking}>
          <StyledName aria-label={t('search.memberName')}>
            <StyledTextHighlight>
              <CommunicationChannelIcon
                communicationChannel={result.communicationChannel}
                css={{ marginRight: SPACING[1], marginTop: 2 }}
                size={18}
              />
              <HighlightMatchedText>{name}</HighlightMatchedText>
            </StyledTextHighlight>
          </StyledName>
          <StyledDetails aria-label={t('search.memberDetails')}>
            {GenderLabels[result.gender] || t(NO_DATA_TEXT.GENDER)} |{' '}
            {dayjs().diff(result.dateOfBirth, 'years') || t(NO_DATA_TEXT.AGE)} |{' '}
            {formatLocation(result.city, result.countryCode, result.stateCode)}
          </StyledDetails>
        </StyledMember>
      </StyledResultMember>
      {/* This on section is split because in the near future we will have nested results */}
      <StyledResultItem onClick={onClickWithTracking}>
        <StyledMessage>
          <StyledTextHighlight aria-label={t('search.message')}>
            <HighlightMatchedText>{message}</HighlightMatchedText>
          </StyledTextHighlight>
          <StyledDate aria-label={t('search.messageTime')}>{dayjs(result.messageTimestamp).fromNow()}</StyledDate>
        </StyledMessage>
      </StyledResultItem>
    </StyledResult>
  )
}
