import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { createRoot } from 'react-dom/client'

import { baseConfig } from './config'

import { Client } from 'api/client'
import QRCode, { getSvgString } from 'components/QRCode'
import Sentry from 'integrations/Sentry'

type GetFileNameProps = {
  clientName?: string
  fileName: string
  fileType?: 'png' | 'svg'
}

export const getQrCodeFileName = ({ clientName, fileName, fileType = 'png' }: GetFileNameProps): string => {
  return clientName ? `${clientName} - ${fileName}.${fileType}` : `${fileName}.${fileType}`
}

export const downloadCanvas = (id: string, fileName: string, clientName?: string): void => {
  const anchor = document.createElement('a')
  anchor.download = getQrCodeFileName({ clientName, fileName, fileType: 'png' })

  const canvas = document.getElementById(id)

  if (canvas) {
    anchor.href = (canvas as HTMLCanvasElement).toDataURL()
    anchor.click()
  }
}

export const downloadAsSvg = (url: string, fileName: string, clientName?: string): void => {
  getSvgString({ text: url, size: 1000 })
    .then((xml) => {
      const anchor = document.createElement('a')
      const blob = new Blob([xml], { type: 'image/svg+xml' })
      anchor.download = getQrCodeFileName({ clientName, fileName, fileType: 'svg' })
      anchor.href = URL.createObjectURL(blob)
      anchor.click()
    })
    .catch(Sentry.captureException)
}

export const downloadAsPng = (url: string, fileName: string, clientName?: string): void => {
  const container = document.createElement('div')
  const root = createRoot(container!)

  document.body.appendChild(container)

  root.render(
    <div
      style={{
        display: 'none',
        position: 'absolute',
        zIndex: -1,
      }}
    >
      <QRCode id="downloadable-canvas" size={1000} text={url} />
    </div>,
  )

  setTimeout(() => {
    downloadCanvas('downloadable-canvas', fileName, clientName)
    setTimeout(() => {
      root.unmount()
    }, 100)
  }, 100)
}

// RFC3986-compliant implementation that escapes !, ', (, ), and *
const encodeRFC3986URIComponent = (str) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`)
}

type GetShareUrlProps = {
  ambShareUrl?: string
  client: Client | undefined
  communicationChannel?: CommunicationChannel
  messageText?: string
}

export const getShareUrl = ({ communicationChannel, client, ambShareUrl, messageText }: GetShareUrlProps): string => {
  const encodedMessage = messageText && encodeRFC3986URIComponent(messageText)
  if (communicationChannel === CommunicationChannel.WHATS_APP) {
    return `https://wa.me/${client?.phoneNumber}${encodedMessage ? `?text=${encodedMessage}` : ''}`
  } else if (communicationChannel === CommunicationChannel.AMB && ambShareUrl) {
    return `${ambShareUrl}${encodedMessage ? `?body=${encodedMessage}` : ''}`
  } else {
    return `${baseConfig.swipeUpUrl}/${client?.urlSlug}${encodedMessage ? `?t=${encodedMessage}` : ''}`
  }
}
