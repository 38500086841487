import { convertKeysToCamelCase } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { MessageSourceType } from '@community_dev/types/lib/api/v2/MessageSourceType'
import { InfiniteData, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { v4 as uuidv4 } from 'uuid'

import { Media } from 'api/campaign'
import { ENDPOINTS } from 'constants/endpoints'
import { QUERY_CACHE } from 'constants/query-cache'
import { UploadFileResult, useUpload } from 'contexts/UploadProvider'
import { useClientId } from 'hooks/useClient'
import analytics from 'utils/analytics'
import { request } from 'utils/api'
import { createMediaFromFile } from 'utils/createMediaFromFile'
import { MediaDimensions, getMediaDimensions } from 'utils/getMediaDimensions'
import { route } from 'utils/router'

type UseSendDMReturn = UseMutationResult<
  UseSendDMMutationReturn,
  unknown,
  UseSendDMMutationOptions,
  { previousData: InfiniteData<CamelCasedPropertiesDeep<Api.V2.MessageHistory>> | undefined }
>

type UseSendDMMutationOptions = {
  message: SendMessageApiOptions
  file?: File
  fanId: string
  usedAiSuggestion: boolean
}

type UseSendDMMutationReturn = {
  sendDMResult: PostSendDMResponse
  uploadResult?: UploadFileResult
  mediaDimensions?: MediaDimensions
}

export type UseSendDMOptions = {
  fanId: string
  body: string
  media?: Media
  shorten_links: boolean
  media_disposition: MediaDisposition
}

type UseSendDMProps = {
  onComplete?(): void
}

export function useSendDM({ onComplete }: UseSendDMProps): UseSendDMReturn {
  const clientId = useClientId()
  const queryClient = useQueryClient()
  const { uploadFile } = useUpload()

  return useMutation({
    mutationFn: async (options: UseSendDMMutationOptions) => {
      const { message, file } = options

      let uploadResult: UploadFileResult | undefined = undefined
      let mediaDimensions: MediaDimensions | undefined = undefined

      if (file) {
        uploadResult = (await uploadFile({ file })) as UploadFileResult
        mediaDimensions = await getMediaDimensions(file)
      }

      const sendDMResult = await postSendDM({
        clientId,
        data: {
          fan_id: message.data.fan_id,
          shorten_links: message.data.shorten_links,
          text: message.data.text,
          media: uploadResult?.data ? convertKeysToCamelCase(uploadResult.data) : null,
          media_disposition: message.data.media_disposition,
        },
      })

      return { uploadResult, sendDMResult, mediaDimensions }
    },
    onMutate: async (variables) => {
      const queryKey = [QUERY_CACHE.MESSAGE_HISTORY.DMS, clientId, variables.fanId]

      analytics.track(analytics.events.DMSent({ fanId: variables.fanId, usedAiSuggestion: variables.usedAiSuggestion }))

      await queryClient.cancelQueries(queryKey)

      let media: Api.V2.Media | null = null

      if (variables.file) {
        media = await createMediaFromFile(variables.file)
      }

      const previousData =
        queryClient.getQueryData<InfiniteData<CamelCasedPropertiesDeep<Api.V2.MessageHistory>>>(queryKey)

      await queryClient.setQueryData<InfiniteData<CamelCasedPropertiesDeep<Api.V2.MessageHistory>>>(
        queryKey,
        (data) => {
          if (data) {
            const nextData = cloneDeep(data)

            const dm: CamelCasedPropertiesDeep<Api.V2.Message> = {
              createdAt: new Date().toISOString(),
              id: uuidv4(),
              inbound: false,
              media: convertKeysToCamelCase(media),
              sourceType: MessageSourceType.DM,
              status: 'success',
              text: variables.message.data.text,
            }

            nextData.pages[0].data.push(dm)

            return nextData
          }

          return undefined
        },
      )

      if (onComplete) {
        onComplete()
      }

      return { previousData }
    },
  })
}

type PostSendDMBody = {
  fan_id: string
  shorten_links: boolean
  text: string
  media: Media | null
  media_disposition?: MediaDisposition
}

// legacy issue, this endpoint returns an empty response
type PostSendDMResponse = void

export type SendMessageApiOptions = {
  clientId: string
  data: PostSendDMBody
}

export const postSendDM = ({ clientId, data }: SendMessageApiOptions): Promise<PostSendDMResponse> => {
  return request(route(ENDPOINTS.MESSAGING.DM, { id: clientId }), {
    camelCase: false,
    body: data,
    method: 'POST',
  })
}
