import { ArrowRightIcon, Layout, SPACING } from '@community_dev/pixels'
import { useQueryClient } from '@tanstack/react-query'
import { ReactNode, useCallback } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { QUERY_CACHE } from 'constants/query-cache'

const StyledHeader = styled.div`
  display: flex;
  margin-top: ${SPACING[3]};
  padding: 0 ${SPACING[1]};
  justify-content: space-between;
`

const StyledBackButton = styled.a`
  margin-right: ${SPACING[3]};
  margin-top: 2px;
  cursor: pointer;
  svg {
    transform: rotate(180deg);
  }
`
const StyledHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const StyledHeading = styled.h1`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  margin-top: 0;
`

type HeaderProps = {
  title: string
  action?: ReactNode
  subtext?: ReactNode
}

export const Header = ({ title, action, subtext }: HeaderProps): JSX.Element => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const shouldGoBack = useCallback(() => {
    queryClient.invalidateQueries([QUERY_CACHE.MGU.EMBEDS])
    history.goBack()
  }, [history, queryClient])

  return (
    <StyledHeader>
      <Layout display="flex">
        <StyledBackButton onClick={shouldGoBack}>
          <ArrowRightIcon size={20} />
        </StyledBackButton>
        <StyledHeadingWrapper>
          <StyledHeading>{title}</StyledHeading>
          {subtext}
        </StyledHeadingWrapper>
      </Layout>
      {action && <Layout>{action}</Layout>}
    </StyledHeader>
  )
}
