import { SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { LinkShorteningCheckbox } from 'components/LinkShorteningCheckbox'

export const StyledRoot = styled.div`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-top: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  padding: 9px ${SPACING[4]};
  min-width: 180px;
`

export const StyledMain = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

export const StyledIcons = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px 0 -2px;
  height: 34px;
`

type StyledIconProps = {
  $disabled?: boolean
}

export const StyledIcon = styled.button<StyledIconProps>`
  flex-shrink: 0;
  width: 26px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  fill: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  cursor: pointer;
  transition: color 0.3s ease;
  background: none;
  padding: 0;
  border: none;

  &:hover {
    color: ${({ theme }) => theme?.COLORS?.TEXT};
    fill: ${({ theme }) => theme?.COLORS?.TEXT};

    @media (hover: none) {
      color: ${({ theme }) => theme?.COLORS?.DEPRECATED_HOVER};
      fill: ${({ theme }) => theme?.COLORS?.DEPRECATED_HOVER};
    }
  }
`

export const StyledUploadPreview = styled.div`
  flex-shrink: 0;
  padding: 12px 12px 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledGifPicker = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0px;
  box-shadow: 0 0 6px 2px rgba(86, 86, 86, 0.46);
  border-radius: 3px;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`

export const StyledSend = styled.button<{ $disabled: boolean }>`
  flex-shrink: 0;
  height: 32px;
  padding: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  align-self: flex-end;
  border: none;
  background: none;
`

export const StyledField = styled.div`
  width: 100%;
  border-radius: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: ${SPACING[2]};
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};
`

export const StyledFieldMain = styled.div<{ $disabled: boolean }>`
  min-height: 32px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  background: ${({ theme, $disabled }) =>
    $disabled ? theme?.COLORS.APP_BACKGROUND_LEVEL_1 : theme?.COLORS?.INPUT_BACKGROUND};

  textarea {
    background: ${({ theme, $disabled }) =>
      $disabled ? theme?.COLORS.APP_BACKGROUND_LEVEL_1 : theme?.COLORS?.INPUT_BACKGROUND};
    padding: 7px 16px 1px;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`

export const StyledLinkShorteningCheckbox = styled(LinkShorteningCheckbox)`
  text-align: right;
  margin-right: ${SPACING[2]};
`

export const StyledUsageCounter = styled.div`
  padding-right: ${SPACING[1]};
  display: flex;
  align-items: center;
`
