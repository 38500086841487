import sortBy from 'lodash/sortBy'
import { transparentize } from 'polished'
import { useMemo } from 'react'
import { Cell, Pie as RePie, PieChart as RePieChart } from 'recharts'
import styled from 'styled-components'

import { Legend } from 'components/Charts/Legend'
import { useDataColorGradient } from 'screens/InsightsScreen/useDataColorGradient'

const Stack = styled.div`
  position: relative;
  height: 200px;
  width: 200px;
  & > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

const TotalContacts = styled.div`
  text-align: center;
  font-size: 12px;
  padding-top: 83px;
`

const ContactCount = styled.div`
  font-size: 16px;
  font-weight: 600;
`

export type PieSlice = {
  value: number
  label: string
  valueLabel: string | JSX.Element
  color?: string
}

export const PieChart = ({
  slices,
  total,
  showTotal = true,
}: {
  slices: PieSlice[]
  total: number
  showTotal: boolean
}): JSX.Element => {
  const _sortedSlices = sortBy(slices, (slice) => slice.value)

  const min = _sortedSlices[0].value
  const max = _sortedSlices[_sortedSlices.length - 1].value
  const colorGradient = useDataColorGradient(min, max)

  const sortedSlices = _sortedSlices.map((slice) => ({
    ...slice,
    color: slice.color || colorGradient(slice.value),
  }))

  return (
    <div style={{ height: '240px', display: 'flex', justifyContent: 'center' }}>
      <Stack>
        {showTotal && (
          <TotalContacts>
            <div>Total Contacts</div>
            <ContactCount>{total.toLocaleString('en-US', { notation: 'compact' }).toLowerCase()}</ContactCount>
          </TotalContacts>
        )}
        <Pie sortedSlices={sortedSlices} />
      </Stack>
      <Legend sections={sortedSlices} />
    </div>
  )
}

// Note that this is going to throw warnings off about
// componentWillRecieveProps due to a recharts issue:
// https://github.com/recharts/recharts/issues/1835
//
// and since Recharts has chosen to migrate to UNSAFE_* lifecycles
// instead of actually doing a refactor in their v2.0.x.beta releases,
// the best solution is actually just to replace Recharts with something
// better. UNSAFE_* still warns in strict mode.
//
// For now, though, the warnings can be safely ignored.
export function Pie({
  sortedSlices,
}: {
  sortedSlices: Array<PieSlice & { color: string; highlight?: boolean }>
}): JSX.Element {
  const hasHighlight = useMemo(() => sortedSlices.some((slice) => slice.highlight === true), [sortedSlices])
  return (
    <RePieChart height={200} width={200}>
      <RePie
        data={sortedSlices}
        dataKey="value"
        innerRadius={70}
        isAnimationActive={false}
        nameKey="dataLabel"
        outerRadius={100}
        paddingAngle={2}
        // cornerRadius={2} // the design calls for rounded corners on the pie chart,
        // however with recharts this causes pie slices that are less than 4px wide to
        // render incorrectly
        startAngle={-270}
      >
        {sortedSlices.map((slice, index) => (
          <Cell fill={hasHighlight && !slice.highlight ? transparentize(0.5, slice.color) : slice.color} key={index} />
        ))}
      </RePie>
      {/* recharts' piechart tooltips are completely broken, and tippy won't
        work here due to the special positioning logic needed to deal with arc
        segments. We'll probably need to replace this with a non-recharts
        chart if we want to have functional tooltips. */}
      {/*<Tooltip />*/}
    </RePieChart>
  )
}
