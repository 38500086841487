const validEmailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)

type Validator = {
  validator: (val: string) => boolean
  message: string
}

export const isRequired = {
  validator: (val: string): boolean => !val || val.length === 0,
  message: 'is required',
}

export const isEmail = {
  validator: (val: string): boolean => !validEmailRegex.test(val),
  message: 'is invalid',
}

export const minLength = (min: number): Validator => ({
  validator: (val: string): boolean => Boolean(val && val.length < min),
  message: `must be at least ${min} characters`,
})

const validate = ({ validator, message }: Validator, value: string) => (validator(value) ? message : undefined)

export const composeValidators =
  (...validators: Validator[]) =>
  (value: string): undefined | string =>
    validators.reduce(
      (error: string | undefined, validator: Validator) => error || validate(validator, value),
      undefined,
    )
