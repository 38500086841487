import { humanizeAgeFilterShortened } from '@community_dev/filter-dsl/lib/humanize/builtInFilters/humanizeAgeFilter'
import {
  AgeMatch,
  findOne,
  serializeFilters,
  AgeFilter,
  isSelectorFilter,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ListItem } from '@community_dev/pixels'
import { useMemo, useEffect, useRef } from 'react'
import { useTheme } from 'styled-components'

import { RecommendationsLoadingIndicator } from '../RecommendationsLoadingIndicator'

import { CustomAgeForm } from './CustomAgeForm'
import { FilterMemberCount } from './FilterMemberCount'
import { useRecipientField } from './RecipientFieldContext'
import {
  StyledAddSuffix,
  StyledButton,
  StyledHeader,
  StyledHeading,
  StyledMeta,
  StyledPanel,
  StyledSubHeading,
  StyledVirtualList,
} from './styled-recipients'

import { VirtualListHandle } from 'components/VirtualList'
import { FilterSelectionType, useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useCurrentFiltersWithAgeCounts } from 'hooks/useCountByQuery/useAgeCount'
import { pluralizeNumeral } from 'utils/general'

export function RecommendationsAge({ type = 'includes' }: { type: FilterSelectionType }): JSX.Element {
  const { COLORS } = useTheme() || {}
  const virtualRef = useRef<VirtualListHandle>(null)
  const { addFilter, removeFilter, filters, activeSubtree } = useFilters()
  const { setIsOpen } = useRecipientField()
  const { options, counts } = useCurrentFiltersWithAgeCounts(type)

  useEffect(() => {
    virtualRef.current?.measure()
  }, [options, filters])

  const selectedNode = useMemo(() => {
    return findOne(activeSubtree, AgeMatch)
  }, [activeSubtree])

  const selected = useMemo(() => {
    if (selectedNode) {
      return serializeFilters(selectedNode) as AgeFilter
    }
  }, [selectedNode])

  if (selected) {
    return (
      <StyledPanel>
        <StyledMeta>
          <StyledHeader>
            <StyledHeading>Age</StyledHeading>
          </StyledHeader>
        </StyledMeta>
        <ListItem
          as={'div'}
          data-testid={selected.operand?.value}
          label={humanizeAgeFilterShortened(selected)}
          suffix={
            <StyledAddSuffix>
              <StyledButton
                $color={COLORS?.ERRORS}
                onClick={() => {
                  removeFilter(selected)
                  setIsOpen(false)
                }}
              >
                Remove
              </StyledButton>
            </StyledAddSuffix>
          }
        />
      </StyledPanel>
    )
  }
  return (
    <StyledPanel>
      <StyledMeta>
        <StyledHeader>
          <StyledHeading>Age</StyledHeading>
        </StyledHeader>
      </StyledMeta>
      {options.length === 0 ? (
        <RecommendationsLoadingIndicator />
      ) : (
        <>
          {!selected && <StyledSubHeading>Suggested Ages</StyledSubHeading>}
          <div>
            <StyledVirtualList ref={virtualRef} rows={options} testId="age-list">
              {({ virtualRow }) => {
                const { label, filter } = options[virtualRow.index]
                if (!isSelectorFilter(filter)) {
                  return null
                }
                const { data: { count = 0 } = {}, isInitialLoading } = counts[virtualRow.index]
                return (
                  <ListItem
                    as={'button'}
                    data-testid={`age-${filter.operand?.value}`}
                    label={label}
                    onClick={() => {
                      addFilter(filter, type)
                      setIsOpen(false)
                    }}
                    subtext={
                      <FilterMemberCount
                        count={pluralizeNumeral(String(count), '0,0', 'Member', 'Members')}
                        isLoading={isInitialLoading}
                      />
                    }
                  />
                )
              }}
            </StyledVirtualList>
          </div>
          {!selected && (
            <>
              <StyledSubHeading $topPadding>Custom Age</StyledSubHeading>
              <CustomAgeForm
                onAdd={(filter) => {
                  addFilter(filter, type)
                  setIsOpen(false)
                }}
              />
            </>
          )}
        </>
      )}
    </StyledPanel>
  )
}
