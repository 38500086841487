import { ApiError, HTTP } from '@community_dev/requests'
import { SnakeCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

// this type could probably be simplified if we improve typings on the request
// function and on the ApiError class.
export type LoginError = ApiError & {
  errors: {
    detail?: string
    field?: string
    message?: string
  }[]
  status: number
  response: Response
}

export type MfaSetupProps = {
  authUrl: string
  token: string
  authSecret: string
}

export function isLoginError(err: Error | LoginError): err is LoginError {
  return err.name === 'ApiError'
}

export function responseHasSetupCodes(response: any): response is SnakeCasedPropertiesDeep<MfaSetupProps> {
  return (
    response !== undefined &&
    response.auth_url !== undefined &&
    response.token !== undefined &&
    response.auth_secret !== undefined
  )
}

export function putNewSeat({ token, data }: { token: string; data: any }): Promise<any> {
  return request(route(ENDPOINTS.INVITES.BY_TOKEN, { token }), {
    body: data,
    method: HTTP.PUT,
    noAuth: true,
  }).then(({ data }) => data)
}

export function putExistingSeat({
  seatId,
  token,
  body,
}: {
  seatId: string
  token: string
  body: { password: string }
}): Promise<{ id: string; token: string; mfa?: boolean }> {
  return request(route(ENDPOINTS.INVITES.ACCEPT, { token, seatId }), {
    body,
    method: HTTP.PUT,
    noAuth: true,
  }).then(({ data }) => data)
}

export function postLogin({ body }: { body: { email: string; password: string } }): Promise<Response> {
  return request(ENDPOINTS.LOGIN.LOGIN, {
    body,
    method: HTTP.POST,
    noAuth: true,
    rawResponse: true,
  })
}

export function deleteLogout(token: string): Promise<any> {
  return request(ENDPOINTS.LOGIN.LOGOUT, {
    method: HTTP.DELETE,
    skipAuthCheck: true,
    token,
    responseMethod: 'text',
  })
}

export function postForgotPassword({ email }: { email: string }): Promise<any> {
  return request(ENDPOINTS.LOGIN.FORGOT_PASSWORD, {
    method: HTTP.POST,
    noAuth: true,
    body: { email },
  })
}

type PutUpdatePasswordArgs = {
  token: string
  password: string
}

export function putChangePassword({ token, password }: PutUpdatePasswordArgs): Promise<any> {
  return request(route(ENDPOINTS.LOGIN.CHANGE_PASSWORD_WITH_TOKEN, { token }), {
    method: 'PUT',
    body: { password, password_confirmation: password },
    noAuth: true,
  })
}

export function getValidateToken({ token }: { token: string }): Promise<any> {
  return request(route(ENDPOINTS.LOGIN.VALIDATE_TOKEN, { token }), {
    noAuth: true,
  })
}

export type RefreshToken = {
  capabilities: string[]
  jwt: string
}

export function getRefreshToken(): Promise<RefreshToken> {
  return request(route(ENDPOINTS.LOGIN.REFRESH_TOKEN))
}
