import { CheckCircleIcon, Layout, SPACING, Typography } from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import sortBy from 'lodash/sortBy'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { StyledList } from './styled'

export function CapabilityList({ roles }: { roles: CamelCasedPropertiesDeep<Api.V1.Role>[] }): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { t, i18n } = useTranslation()
  const sortedRoles = useMemo(() => sortBy(roles, 'group'), [roles])
  // this can be removed once all roles have descriptions
  const getRoleSummary = useCallback(
    (name: string) => {
      const summaryKey = `settings.team.rolesSummaries.${name}`
      if (i18n.exists(summaryKey)) {
        return t(summaryKey)
      } else {
        return t('settings.team.rolesSummaries.fallback')
      }
    },
    [i18n, t],
  )
  return (
    <>
      {sortedRoles.map(({ capabilities, description, group, id, name }) => (
        <Layout key={id}>
          <Typography fontWeight={500} marginBottom={`-${SPACING[2]}`} variant="body2">
            <Layout as="span" display="inline-block" marginRight="9px" position="relative" top={SPACING[1]}>
              <CheckCircleIcon color={group === null ? COLORS.SUBTEXT : undefined} size={13} />
            </Layout>
            {description || getRoleSummary(name)}
          </Typography>
          <StyledList>
            {capabilities.map((c) => (
              <li key={c.id}>
                <Typography color={COLORS.SUBTEXT} margin={`${SPACING[1]} 0`} variant="body2">
                  {c.description}
                </Typography>
              </li>
            ))}
          </StyledList>
        </Layout>
      ))}
    </>
  )
}
