import { MemberDataFilter, RelationshipOperators } from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { Map } from 'immutable'
import noop from 'lodash/noop'
import T from 'prop-types'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import useMutationAssignByQuery from './use-mutation-assign-by-query'

import { DebugViewer } from 'components/DebugViewer'
import { CAPABILITIES } from 'constants/capabilities'
import { useIncludeExcludeContext } from 'contexts/IncludeExcludeProvider'
import { convertToBackendQuery } from 'contexts/IncludeExcludeProvider/IncludeExcludeAdapter'
import { Selection } from 'contexts/IncludeExcludeProvider/IncludeExcludeState'
import { useClientId } from 'hooks/useClient'
import { useCountByQuery } from 'hooks/useCountByQuery'
import { useHasCapability } from 'hooks/useUserCapability'

// utility to glom all of the FilterProvider concerns together into a {filters}
// body that is consumable by the backend and the Redux layers
const buildFilters = ({ includeExcludeState, threadId: campaign_id, clientId: client_id, allTags, notTags }) =>
  convertToBackendQuery(includeExcludeState, {
    campaign_id,
    client_id,
    tags: {
      all: [...allTags],
      not: [...notTags],
    },
  })

type CampaignResponseFilterStateContextValue = {
  resetState(): void
  // nice and good things:
  sentimentFilter: string | null
  setSentimentFilter: (string) => void
  tagFilters: Map<string, string>
  applyTagFilter: (tag: string, mode: string) => void
  unsetTagFilter: (tag: string) => void
  resetAllTagFilters: () => void
  communicationChannel: CommunicationChannel
  setCommunicationChannel: (communicationChannel: CommunicationChannel) => void
  // mildly questionable things that aren't really that bad but could
  // maybe use a rename:
  isAnySelected: boolean
  isLoadingFilterCount: boolean
  filterCount: number
  // somewhat unfortunate things that would be nice to get rid of:
  filters: MemberDataFilter | null
  allTags: Set<string>
  notTags: Set<string>
  updateCommunity: (...any) => void
  campaignFollowUpFilter: MemberDataFilter | null
  setCampaignFollowUpFilter: (campaignFollowUpFilter: MemberDataFilter | null) => void
}

export const CampaignResponseFilterStateContext = createContext<CampaignResponseFilterStateContextValue>({
  resetState: noop,
  sentimentFilter: null,
  setSentimentFilter: noop,
  tagFilters: Map(),
  applyTagFilter: noop,
  unsetTagFilter: noop,
  resetAllTagFilters: noop,
  communicationChannel: CommunicationChannel.SMS,
  setCommunicationChannel: noop,
  isAnySelected: false,
  isLoadingFilterCount: false,
  filterCount: 0,
  filters: null,
  allTags: new Set(),
  notTags: new Set(),
  updateCommunity: noop,
  campaignFollowUpFilter: null,
  setCampaignFollowUpFilter: noop,
})

CampaignResponseFilterStateContext.displayName = 'FilterStateContext'

export function useCampaignResponseFilterState(): CampaignResponseFilterStateContextValue {
  const context = useContext(CampaignResponseFilterStateContext)
  if (context === undefined) {
    throw new Error('useFilterState must be used within a FilterProvider')
  }

  return context as CampaignResponseFilterStateContextValue
}

export function CampaignResponseFilterProvider({ children }: { children?: React.ReactNode }): any {
  const [campaignFollowUpFilter, setCampaignFollowUpFilter] = useState<MemberDataFilter | null>(null)
  // tag-based filter state that is owned by the FilterProvider
  const [sentimentFilter, setSentimentFilterDirectly] = useState<string | null>(null)
  const [tagFilters, setTagFilters] = useState(Map<string, string>())

  const clientId = useClientId()

  const [communicationChannel, setCommunicationChannel] = useState<CommunicationChannel>(CommunicationChannel.SMS)

  // id-based include/excludes that are owned by the IncludeExcludeProvider
  const includeExcludeContext = useIncludeExcludeContext()

  const match = useRouteMatch<{ campaignId: string }>('/campaigns/:campaignId')
  const { campaignId: threadId } = match?.params || {}

  // computed variables
  const isAnySelected = includeExcludeContext.isRootSelected() !== Selection.NOT_SELECTED

  const allTags: any = useMemo(() => {
    const includedTags: any = tagFilters.filter((v) => v === 'all').keys()
    return sentimentFilter ? new Set([...includedTags, sentimentFilter]) : new Set(includedTags)
  }, [sentimentFilter, tagFilters])

  const notTags: any = useMemo(() => {
    const excludedTags = tagFilters.filter((v) => v === 'not').keys()
    return new Set(excludedTags)
  }, [tagFilters])

  // DOWNSTREAM API FOR MODIFYING FILTERS
  //
  const applyTagFilter = (tag, mode) => {
    setTagFilters(tagFilters.set(tag, mode))
    includeExcludeContext.resetState()
  }

  const unsetTagFilter = (tag) => {
    setTagFilters(tagFilters.delete(tag))
    includeExcludeContext.resetState()
  }

  const resetAllTagFilters = () => {
    setTagFilters(Map())
  }

  const setSentimentFilter = useCallback(
    (newFilter: string | null) => {
      resetAllTagFilters()
      includeExcludeContext.resetState()
      setSentimentFilterDirectly(newFilter)
    },
    [includeExcludeContext],
  )

  // STATE RESET LOGIC
  //
  // Because of
  //
  // 1. The global nature of FilterProvider necessitating that IncludeExcludeProvider
  //    be global as well
  //
  // and
  //
  // 2. The design of SentThread, which doesn't remount on location changes but
  //    rather sniffs changing thread IDs from the URL and attempts to keep
  //    all of the persistient local state in sync manually
  //
  // It is unfortunately necessary to reset the state of both the FilterProvider
  // and the includeExcludeContext manually whenever we detect that the threadId
  // currently active in redux has changed.
  //
  // this is done via this somewhat funky hook:

  const resetState = useCallback(() => {
    includeExcludeContext.resetState()
    resetAllTagFilters()
    setSentimentFilter(null)
    setCommunicationChannel(CommunicationChannel.SMS)
    setCampaignFollowUpFilter(null)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // reset the state automatically, if the thread id becomes empty.
  useEffect(() => {
    if (!threadId) {
      resetState()
    }
  }, [resetState, threadId])

  // FILTER CONSTRUCTION LOGIC
  //
  // This is where we build up the filters for the backend. It's important to
  // memoize this computation so that the resulting `filters` object has a
  // stable object reference unless it actually changes, so that it can be used
  // as a hook dependency without triggering unnecessary calls to count-by-query,
  // dispatch, etc.

  // It would be nice to get rid of the 'allTags' and 'notTags' data
  // format entirely and just pass the tagFilters arounnd, however that
  // represents a pretty big refactor of the downstream consumers of this
  // API.
  //
  // As a compromise, I've chosen to implement the allTags and notTags sets
  // as memoized, read-only representations of the tagFilters state. This
  // keeps the scope of the refactor manageble while still allowing us to
  // benefit from the implicitly-mutually-exclusive nature of the tagFilters
  // format representation of the { tags: { not: [], all: [] } } query filter.
  const filters = useMemo(() => {
    const isAnySelected = includeExcludeContext.isRootSelected() !== Selection.NOT_SELECTED

    // The checkboxes, clusters, default campaign
    const partialFilters = buildFilters({
      includeExcludeState: includeExcludeContext.includeExcludeState,
      threadId,
      clientId,
      allTags: allTags,
      notTags: notTags,
    })

    // NO FILTERS SELECTED
    // If there is no campaign follow up filter, and no checkboxes or clusters, return the partialFilters. That will be equivalent as to remessage all the campaign recipients
    if (!isAnySelected && !campaignFollowUpFilter) return partialFilters

    // JUST CAMPAIGN FOLLOW UP FILTER SELECTED
    // If campaign follow up filter is set and no checkboxes or clusters, return the campaign follow up filter
    if (campaignFollowUpFilter && !isAnySelected) return campaignFollowUpFilter

    // JUST CHECKBOXES OR CLUSTERS SELECTED
    // partialFIlters includes the checkbox/clusters selection state
    if (isAnySelected && !campaignFollowUpFilter) return partialFilters

    // BOTH CHECKBOXES, CLUSTERS AND CAMPAIGN FOLLOW UP FILTER SELECTED
    // Combine them via OR
    return {
      operator: RelationshipOperators.OR,
      operands: [partialFilters, campaignFollowUpFilter],
    } as MemberDataFilter
  }, [includeExcludeContext, threadId, clientId, allTags, notTags, campaignFollowUpFilter])

  // ACTUAL CALL TO GET COUNTS
  //
  // This is the one place in the file where we actually touch the network
  // directly via a call to counts
  // TODO - throttle calls to count (or debounce?)
  const countQuery = useCountByQuery({
    communicationChannel,
    traceId: 'campaign-responses',
    filters,
    options: { enabled: !!threadId },
  })

  // TODO FIXME
  // This *really* doesn't belong in FilterProvider, the only reason it's
  // here is so that it has access to the fan count for the given query.
  //
  // However, that info really ought to come from the backend SUCCESS
  // response, as the fanCount here isn't actually guaranteed to correspond
  // to the query that was active when adding users to a community - it can
  // easily change between the user initiating that action, and the backend
  // response coming back to the client.
  const { mutate: updateCommunity } = useMutationAssignByQuery({
    fanCount: countQuery.data?.count, // race condition!
  })

  const showDebugViewer = useHasCapability(CAPABILITIES.DEBUG.FILTER_PROVIDER_DEBUGGER)

  return (
    <CampaignResponseFilterStateContext.Provider
      value={{
        resetState,
        // nice and good things:
        sentimentFilter,
        setSentimentFilter,
        tagFilters,
        applyTagFilter,
        unsetTagFilter,
        resetAllTagFilters,
        communicationChannel,
        setCommunicationChannel,
        // mildly questionable things that aren't really that bad but could
        // maybe use a rename:
        isAnySelected,
        isLoadingFilterCount: countQuery.isInitialLoading,
        filterCount: countQuery.data?.count || 0,
        // somewhat unfortunate things that would be nice to get rid of:
        filters,
        allTags: allTags,
        notTags: notTags,
        updateCommunity,
        campaignFollowUpFilter,
        setCampaignFollowUpFilter,
      }}
    >
      {showDebugViewer && (
        <DebugViewer
          children={undefined}
          debugValue={{
            sentimentFilter,
            allTags: [...allTags],
            notTags: [...notTags],
            tagFilters: tagFilters.toJS(),
            campaignFollowUpFilter,
          }}
          title="CampaignResponseFilterStateContext"
        />
      )}
      {children}
    </CampaignResponseFilterStateContext.Provider>
  )
}

CampaignResponseFilterProvider.propTypes = {
  children: T.node,
}
