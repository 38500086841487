import { ColorGrid, DropdownArrowIcon, SPACING } from '@community_dev/pixels'
import { ChangeEvent, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { CommunityDot } from 'components/CommunityDot'

const StyledField = styled.button`
  border: none;
  background: none;
  padding: 0 0 0 ${SPACING[4]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  overflow: visible;
`

const StyledIcon = styled.div`
  margin-left: ${SPACING[2]};

  svg {
    vertical-align: middle;
  }
`

const StyledColorInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

type CommunityColorFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export function CommunityColorField({ value, onChange }: CommunityColorFieldProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const [open, setOpen] = useState(false)

  return (
    <StyledField aria-label="Color" onClick={() => setOpen(!open)} type="button">
      <ColorGrid
        align="left"
        color={value}
        offsetTop={10}
        onChange={(value) => onChange({ target: { value } } as ChangeEvent<HTMLInputElement>)}
      >
        <StyledColorInput>
          <CommunityDot color={value} />
          <StyledIcon>
            <DropdownArrowIcon color={COLORS?.BUTTON_PRIMARY} />
          </StyledIcon>
        </StyledColorInput>
      </ColorGrid>
    </StyledField>
  )
}
