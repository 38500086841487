import noop from 'lodash/noop'
import { ChangeEvent, useRef, useState } from 'react'

import { Main, Remaining, StyledElasticInput, Wrapper } from './MessageBox.style'

export type MessageBoxProps = {
  autoFocus?: boolean
  className?: string
  disabled?: boolean
  maxLength?: number
  maxRows?: number
  message: string
  minRows?: number
  placeholder?: string
  onChange: (message: string) => void
}

export const MessageBox = (props: MessageBoxProps): JSX.Element => {
  const {
    autoFocus = false,
    className,
    disabled = false,
    maxLength = 0,
    maxRows = 6,
    message = '',
    minRows = 3,
    placeholder,
    onChange = noop,
  } = props
  const [style, setStyle] = useState({ height: '26px' })
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const over = maxLength > 0 && maxLength - message.length < 0

  const handleHeightChange = (height: number): void => {
    setStyle({ height: `${height + 6}px` })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement> | any): void => onChange(e.target.value)

  return (
    <Wrapper className={className}>
      <Main style={style}>
        <StyledElasticInput
          autoFocus={autoFocus}
          disabled={disabled}
          maxRows={maxRows}
          minRows={minRows}
          onChange={handleChange}
          onHeightChange={handleHeightChange}
          placeholder={placeholder}
          ref={inputRef}
          spellCheck={false}
          value={message}
        />
      </Main>
      {maxLength > 0 && <Remaining over={over}>{`Remaining: ${maxLength - message.length}`}</Remaining>}
    </Wrapper>
  )
}

export default MessageBox
