import { Typography } from '@community_dev/pixels'

import { HeadingContent } from 'api/addOns'

export const AddOnHeading = ({ value }: HeadingContent): JSX.Element => {
  return (
    <Typography margin={0} variant="h2">
      {value}
    </Typography>
  )
}
