import {
  AgeMatchSingle,
  BirthdayMatch,
  findAll,
  findOne,
  isAgeFilter,
  isBetweenFilter,
  isSelectorFilter,
  MemberDataFilter,
  parseFilters,
  SelectorOperators,
  serializeFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import moment from 'moment-timezone'

import { PT_TIME_ZONE } from 'constants/date'

export const checkIsAgeUnderLimit = (filter: MemberDataFilter | null, limit: number): boolean => {
  if (!filter) return false

  if (isBetweenFilter(filter) && isAgeFilter(filter.operands[0]) && isAgeFilter(filter.operands[1])) {
    const lowerBound = Number(filter.operands[0].operand.value)
    const upperBound = Number(filter.operands[1].operand.value)
    if (lowerBound < limit || upperBound < limit) {
      return true
    }
  }

  return Array.from(findAll(parseFilters(filter), AgeMatchSingle))
    .map(serializeFilters)
    .filter(isSelectorFilter)
    .filter(isAgeFilter)
    .every((ageFilter) => {
      const value = Number(ageFilter.operand.value)
      switch (ageFilter.operator) {
        case SelectorOperators.GREATER_THAN_OR_EQUALS:
        case SelectorOperators.EQUALS:
          return Number(value) < limit
        case SelectorOperators.GREATER_THAN:
          return Number(value) + 1 < limit
        case SelectorOperators.LESS_THAN:
        case SelectorOperators.LESS_THAN_OR_EQUALS:
        default:
          return true
      }
    })
}

export const checkIsBirthdayFilterMissing = (filters: MemberDataFilter | null, message: string): boolean => {
  const birthdayFilter = findOne(parseFilters(filters), BirthdayMatch)
  const lowercaseMessage = message.toLowerCase()

  if (lowercaseMessage.includes('bday') || lowercaseMessage.includes('birthday')) {
    return !birthdayFilter
  }
  return false
}

export function checkIsQuietTime(scheduledAt?: Date): boolean {
  // Using moment since it passes the test for 'is quiet time before 8 AM PST'
  // Issue with dayjs is that it returns the 8 hour for '01 July 2050 7:59 PST'
  // Seems like it could be an issue with how daylight savings is handled by dayjs
  const hourOfDay = Number(
    moment(scheduledAt || Date.now())
      .tz(PT_TIME_ZONE)
      .format('HH'),
  )

  return hourOfDay < 8 || hourOfDay >= 17
}
