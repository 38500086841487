import {
  BuiltInFields,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ApiError } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useQueryClient, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useCallback } from 'react'

import { FilterOptionsWithCounts } from './types'
import {
  CountByQueryFnReturn,
  toCountByQueryKey,
  useCountByQueries,
  useMultiChannelCountByQuery,
} from './useCountByQuery'
import { useCountByQuery } from './useCountByQuery'

import { STALE_TIME } from 'constants/query-cache'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { FilterSelectionType } from 'contexts/FilterProvider/FilterProvider'
import { useClient } from 'hooks/useClient'

export const toCommunityFilter = (communityId?: string, title?: string): MemberDataFilter => {
  return {
    operator: SelectorOperators.EQUALS,
    operand: {
      field_key: BuiltInFields.TAG_ID,
      field_label: title || '',
      source: FieldSources.TAGS,
      type: FieldTypes.UUID,
      value: communityId || '',
    },
  }
}

type UseCommunityCountArgs<SelectData = CountByQueryFnReturn> = {
  communityId: string
  title: string
  traceId?: string
  communicationChannel?: CommunicationChannel
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}
export const useCommunityCount = <SelectData = CountByQueryFnReturn>({
  communityId,
  title,
  traceId,
  communicationChannel,
  options,
}: UseCommunityCountArgs<SelectData>): UseQueryResult<SelectData, ApiError> => {
  return useCountByQuery<SelectData>({
    filters: toCommunityFilter(communityId, title),
    traceId,
    communicationChannel,
    options,
  })
}

export const useCurrentFiltersWithCommunityCount = ({
  communityId,
  title,
  type = 'includes',
}: {
  communityId: string
  title: string
  type?: FilterSelectionType
}): FilterOptionsWithCounts => {
  const { previewNextFilter, communicationChannel } = useFilters()

  const nextFilter = previewNextFilter(toCommunityFilter(communityId, title), type) as MemberDataFilter

  return {
    options: [
      {
        filter: nextFilter,
        label: title,
      },
    ],
    counts: useCountByQueries({
      filters: [nextFilter],
      communicationChannel,
      traceId: `current-filters-with-community-count-${communityId}`,
    }),
  }
}

type MemberCountChannelWithTotal = {
  [key in CommunicationChannel | 'unknown']?: number
} & {
  total: number
  isLoading: boolean
}

export const useAllCommunityCount = ({
  communityId,
  title,
  traceId,
  enabled = true,
}: {
  communityId?: string
  title?: string
  traceId?: string
  enabled?: boolean
}): MemberCountChannelWithTotal => {
  const { data: client } = useClient()
  const { communicationChannels = [] } = client || {}

  const allChannelsCount = useMultiChannelCountByQuery({
    filters: toCommunityFilter(communityId, title),
    communicationChannels: communicationChannels,
    traceId,
    options: {
      staleTime: STALE_TIME.FIVE_MINUTES,
      enabled: Boolean(communityId?.length && title?.length && enabled),
    },
  })

  const totalCount = Object.values(allChannelsCount).reduce((acc, value) => acc + (value.data?.count ?? 0), 0)
  const isLoading = Object.values(allChannelsCount).some(({ isInitialLoading }) => isInitialLoading)

  return {
    total: totalCount,
    isLoading,
    ...Object.entries(allChannelsCount).reduce((acc, [key, value]) => {
      return { ...acc, [key as CommunicationChannel]: value.data?.count ?? 0 }
    }, {} as Record<CommunicationChannel, number>),
  }
}

export const useInvalidateCommunityCount = (): ((communityId: string, title: string) => void) => {
  const { data: client } = useClient()
  const { communicationChannels: clientCommunicationChannels } = client || {}
  const queryClient = useQueryClient()

  return useCallback(
    (communityId, title) => {
      clientCommunicationChannels?.forEach((channel) => {
        queryClient.invalidateQueries({
          queryKey: toCountByQueryKey(toCommunityFilter(communityId, title), channel, client?.id || ''),
        })
      })
    },
    [clientCommunicationChannels, queryClient, client],
  )
}
