import { HTTP } from '@community_dev/requests'
import identity from 'lodash/identity'

import { ONBOARDING_TASK_COMPLETE } from '../screens/Onboarding/constants'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export function putClientName({ body, id }: { body: any; id: string }): Promise<any> {
  return request(ENDPOINTS.SETTINGS.NAME.replace(':id', id), {
    method: HTTP.PUT,
    body,
  })
}

export function putClientFanOnboardingMessage({ id, text }: { id: string; text: string }): Promise<any> {
  return request(route(ENDPOINTS.SETTINGS.MESSAGE, { id }), {
    method: HTTP.PUT,
    body: { text },
  })
}

export function putClientVoicemail({ body }: { body: any }): Promise<any> {
  return request(ENDPOINTS.SETTINGS.VOICEMAIL, {
    method: HTTP.PUT,
    body,
    defaultHeaders: false,
    serialize: identity,
  })
}

export function putClientTask({
  id,
  status = ONBOARDING_TASK_COMPLETE,
}: {
  id: string
  status?: string
}): Promise<any> {
  return request(route(ENDPOINTS.CLIENTS.TASK, { id }), {
    method: HTTP.PUT,
    body: { status },
    responseMethod: 'text',
  })
}
