import { BUTTON_VARIANTS, Button, FONT_SIZE, SPACING, Typography } from '@community_dev/pixels'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { PaymentIcon } from './PaymentIcon'

import { ROUTES } from 'constants/routes'
import { useSubscription } from 'hooks/billing/useSubscription'
import { Setting } from 'layouts/SettingsLayout'

const StyledContainer = styled.div`
  display: flex;
  justify-content: row;
  align-items: center;
`

const StyledCreditCardDetails = styled.div`
  justify-content: column;
  padding: 0 ${SPACING[2]};
  align-items: flex-start;
`

const StyledMaskedNumbers = styled.div`
  font-size: ${FONT_SIZE[7]};
  padding-bottom: ${SPACING[4]};
  margin-right: ${SPACING[1]};
`

const StyledCreditCardNumber = styled.div`
  display: flex;
  justify-content: row;
  align-items: center;
  margin-bottom: -${SPACING[2]};
`

const StyledExpirationDate = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const CardDetails = (): JSX.Element | null => {
  const { data } = useSubscription()
  const { cardType, expMonth, expYear, lastFour } = data?.billingInfo || {}

  const hasBilling = Boolean(cardType && expMonth && expYear && lastFour)

  if (!hasBilling) return null

  return (
    <StyledContainer>
      <PaymentIcon cardType={cardType} height={34} width={44} />
      <StyledCreditCardDetails>
        <StyledCreditCardNumber>
          <StyledMaskedNumbers>&hellip;</StyledMaskedNumbers>
          {lastFour}
        </StyledCreditCardNumber>
        <StyledExpirationDate>{`exp ${expMonth}/${expYear}`}</StyledExpirationDate>
      </StyledCreditCardDetails>
    </StyledContainer>
  )
}

export function PlanPaymentMethodRow(): JSX.Element {
  const history = useHistory()
  const edit = useCallback(() => {
    history.push(ROUTES.SETTINGS.BILLING_EDIT)
  }, [history])

  return (
    <>
      <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
        Payment Method
      </Typography>
      <Setting className="Setting-billing" label={<CardDetails />}>
        <Button onClick={edit} variant={BUTTON_VARIANTS.OUTLINE} width="60px">
          Edit
        </Button>
      </Setting>
    </>
  )
}
