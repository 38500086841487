import { HTTP } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export enum WhatsAppIntegrationStatus {
  SUBMITTED_FOR_REGISTRATION = 'submitted_for_registration',
  OTP_REQUESTED = 'otp_requested',
  WAITING_FOR_STATUS_UPDATE = 'waiting_for_status_update',
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished',
  NO_CERTIFICATE = 'no_certificate',
  REJECTED_BY_WHATSAPP = 'rejected_by_whatsapp',
  PHONE_NUMBER_NOT_VERIFIED = 'phone_number_not_verified',
  FAILED = 'failed',
}

export type WhatsAppIntegrationStatusResponse = {
  status: WhatsAppIntegrationStatus
}

export function getWhatsAppIntegrationStatus(clientId: string): Promise<WhatsAppIntegrationStatusResponse> {
  return request(route(ENDPOINTS.WHATSAPP.INTEGRATION_STATUS, { clientId }))
}

export function postWhatsAppRegistration({
  clientId,
  whatsAppBusinessId,
}: {
  clientId: string
  whatsAppBusinessId: string
}): Promise<any> {
  return request(route(ENDPOINTS.WHATSAPP.REGISTER_INTEGRATION, { clientId }), {
    method: HTTP.POST,
    body: {
      client_id: clientId,
      whatsapp_business_id: whatsAppBusinessId,
    },
  })
}

export function postRetryWhatsAppRegistration(clientId: string): Promise<any> {
  return request(route(ENDPOINTS.WHATSAPP.REGISTRY_INTEGRATION_RETRY, { clientId }), {
    method: HTTP.POST,
  })
}
