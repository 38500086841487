import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import merge from 'deepmerge'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback } from 'react'
import { PartialDeep } from 'type-fest'

import { QUERY_CACHE } from 'constants/query-cache'
import { useInbox } from 'contexts/InboxProvider'
import { useClientId } from 'hooks/useClient'
import { InboxItemPaged } from 'hooks/useInboxMessages'
import { isInboxItemNormalized } from 'layouts/MessagesLayout/DirectMessageLayout'
import { InboxItemNormalized } from 'utils/normalize'

type UpdateInboxFn = (fanId: string, inboxItem: PartialDeep<InboxItemNormalized>) => void

const overwriteMerge = <T>(_: T, sourceArray: T): T => sourceArray

// Update inbox query cache optimistically
//
export function useUpdateInbox(): UpdateInboxFn {
  const queryClient = useQueryClient()
  const clientId = useClientId()

  const { filters, searchTerm, searchTab } = useInbox()

  const queryKey = [QUERY_CACHE.CLIENT_DASHBOARD_CHATS, filters, searchTerm, searchTab, clientId]

  return useCallback((fanId: string, inboxItem: PartialDeep<InboxItemNormalized>) => {
    queryClient.setQueryData<InfiniteData<InboxItemPaged>>(queryKey, (data) => {
      if (data) {
        const nextData = cloneDeep(data)
        return {
          ...nextData,
          pages: nextData?.pages.map((page) => {
            return {
              ...page,
              messages: page.messages.filter(isInboxItemNormalized).map((message) => {
                if (message.fan.id === fanId) {
                  return merge(message, inboxItem, { arrayMerge: overwriteMerge }) as InboxItemNormalized
                }

                return message
              }),
            }
          }),
        }
      }

      return undefined
    })
  }, [])
}
