import { FONT_SIZE, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

type LegendProps = {
  sections: Section[]
  className?: string
}

type Section = {
  color: string
  label: string
  valueLabel: string | JSX.Element
}

type BallProps = {
  $color: string
}

const StyledBall = styled.div<BallProps>`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background: ${({ $color }) => $color};
`

const StyledValueLabel = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  margin-left: ${SPACING[2]};
  font-size: ${FONT_SIZE[4]};
`

const StyledLabel = styled.div`
  margin-left: ${SPACING[4]};
  font-size: 12px;
  line-height: 15px;
`

const StyledDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const StyledLegend = styled.div`
  margin-left: 40px;
`

const StyledSection = styled.div`
  margin-bottom: 16px;
`

export const Legend = ({ sections, className }: LegendProps): JSX.Element => (
  <StyledLegend className={className}>
    {sections.map((section) => (
      <StyledSection key={section.label}>
        <StyledLabel data-testid={`legend-label-${section.label}`}>{section.label}</StyledLabel>
        <StyledDetails>
          <StyledBall $color={section.color} data-testid={`legend-ball-${section.label}`} />
          <StyledValueLabel data-testid={`legend-value-label-${section.label}`}>{section.valueLabel}</StyledValueLabel>
        </StyledDetails>
      </StyledSection>
    ))}
  </StyledLegend>
)
