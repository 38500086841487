import { Layout, Typography, FONT_SIZE, SPACING, TABLET_BREAK } from '@community_dev/pixels'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AddOnsSection } from './AddOnsSection'

import { MeasuredContainer } from 'components/MeasuredContainer'
import { useAddons } from 'hooks/useAddons'
import { SidebarLayout } from 'layouts/SidebarLayout'

const MAX_CENTER_COLUMN_WIDTH = TABLET_BREAK

const StyledAddOnSection = styled(AddOnsSection)`
  margin-bottom: ${SPACING[7]};
  width: 100%;
`

const StyledMeasuredContainer = styled(MeasuredContainer)`
  width: 100%;
  flex-direction: column;
  align-items: center;
`

const BREAK_POINT_WIDTH = parseInt(TABLET_BREAK, 10)

export const AddOnsScreen = (): JSX.Element => {
  const { allAddOnsBySection = {} } = useAddons()
  const { t } = useTranslation()
  const [width, setWidth] = useState<number>()
  const isNarrowView = width && width < BREAK_POINT_WIDTH

  return (
    <SidebarLayout>
      <StyledMeasuredContainer onResize={({ width }) => setWidth(width)}>
        <Layout
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="100%"
          maxWidth={MAX_CENTER_COLUMN_WIDTH}
          padding={isNarrowView ? SPACING[3] : undefined}
          width="100%"
        >
          <Typography
            fontSize={FONT_SIZE[8]}
            margin="0"
            paddingBottom={isNarrowView ? SPACING[3] : SPACING[8]}
            paddingTop={isNarrowView ? SPACING[3] : SPACING[9]}
            variant="h1"
            width="100%"
          >
            {t('addOns.getTheMostOutOfCommunity')}
          </Typography>

          <Layout alignItems="flex-start" display="flex" flexDirection="column" width="100%">
            {Object.keys(allAddOnsBySection).map((key) => (
              <StyledAddOnSection cards={allAddOnsBySection[key]} key={key} title={key} />
            ))}
          </Layout>
        </Layout>
      </StyledMeasuredContainer>
    </SidebarLayout>
  )
}
