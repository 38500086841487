import { Filter, ListItem, PlusCircleIcon, SPACING, SearchBar } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled, { useTheme } from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'

import { CommunitiesListItem } from './CommunitiesListItem'

import { Community } from 'api/community'
import { CommunityDot } from 'components/CommunityDot'
import CreateCommunityModal from 'components/CreateCommunityModal'
import { LazyComponent } from 'components/LazyComponent'
import { CAPABILITIES } from 'constants/capabilities'
import { COMMUNITY_SORT_BY_MEMBER_COUNT } from 'constants/launch-darkly-flags'
import { useClient } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useAllMemberCount } from 'hooks/useCountByQuery/useEveryoneCount'
import { useInitialAction } from 'hooks/useInitialAction'
import { userHasFlag } from 'hooks/useLaunchDarkly'
import { useHasCapability } from 'hooks/useUserCapability'
import analytics from 'utils/analytics'
import { formatLargeNumber } from 'utils/number'

const StyledCommunities = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const StyledHeader = styled.header`
  padding: ${SPACING[4]};
  flex-shrink: 0;
`

const StyledSearch = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${SPACING[2]};
`

const StyledAdd = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  padding-top: 4px;
  flex-shrink: 0;
  flex-grow: 0;
`

const StyledTitle = styled.h1`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledList = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
`

const StyledAddRow = styled.button`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  border: none;
  border-top: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  cursor: pointer;
  padding: ${SPACING[3]} ${SPACING[4]};
  flex-grow: 0;
  flex-shrink: 0;
  text-align: left;
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
`

const StyledNoResults = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.16px;
  line-height: 16px;
  padding: 24px 0;
  text-align: center;
`

enum SortField {
  COUNT = 'count',
  DATE = 'date',
  NAME = 'name',
}

enum SortDirection {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

type CommunitiesListProps = {
  onItemClick: (...args: any[]) => any
  title: string
}

const CommunitiesList = (props: CommunitiesListProps): JSX.Element => {
  const { COLORS } = useTheme() || {}

  const [isCreateCommunityModalOpen, setIsCreateCommunityModalOpen] = useState(false)
  const { id: activeCommunityId } = useParams<{ id: string }>()
  const [searchTerm, setSearchTerm] = useState('')
  const { data: client } = useClient()
  const isSortByMemberCountEnabled = userHasFlag(COMMUNITY_SORT_BY_MEMBER_COUNT)
  const [sortField, setSortField] = useLocalStorageState<SortField>('communitiesList.sortField', {
    defaultValue: SortField.DATE,
  })
  const [sortDirection, setSortDirection] = useLocalStorageState<SortDirection>('communitiesList.sortDirection', {
    defaultValue: SortDirection.DESCENDING,
  })

  const hasAmbEnabled =
    useHasCapability(CAPABILITIES.FEATURE.AMB.ALL) && client?.communicationChannels.includes(CommunicationChannel.AMB)

  const hasFullTextSearch = useHasCapability(CAPABILITIES.FEATURE.FULL_TEXT_SEARCH.ALL)

  const isAddCommunityInitiallyOpen = useInitialAction('new')
  const { t } = useTranslation()

  const { data: rawCommunities } = useCommunities()

  const communities: Community[] = useMemo(() => {
    if (!rawCommunities) return []
    const sortCommunities = (communities: Community[], sortField: SortField, sortDirection: SortDirection) => {
      const up = sortDirection === 'ascending' ? 1 : -1
      const down = -up

      return communities.sort((a, b) => {
        switch (sortField) {
          case 'name':
            if (a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase()) return down
            return a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() ? up : 0
          case 'date':
            if (a.insertedAt < b.insertedAt) return down
            return a.insertedAt > b.insertedAt ? up : 0
          case 'count':
            if (a?.fanCount !== undefined && b?.fanCount !== undefined && a.fanCount < b.fanCount) return down
            return a?.fanCount !== undefined && b?.fanCount !== undefined && a.fanCount > b.fanCount ? up : 0
          default:
            return 0
        }
      })
    }

    const searchCommunities = (communities, searchTerm) => {
      return communities.filter((obj) => obj.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
    }

    let customCommunitiesList = rawCommunities.custom
    let protectedCommunitiesList = rawCommunities.protected

    if (searchTerm?.length) {
      customCommunitiesList = searchCommunities(rawCommunities.custom, searchTerm)

      protectedCommunitiesList = searchCommunities(rawCommunities.protected, searchTerm)
    }

    customCommunitiesList = sortCommunities(customCommunitiesList, sortField, sortDirection)

    protectedCommunitiesList = sortCommunities(protectedCommunitiesList, sortField, sortDirection)

    const allCommunities: any[] = [...rawCommunities.default, ...protectedCommunitiesList, ...customCommunitiesList]

    return hasAmbEnabled
      ? allCommunities
      : allCommunities.filter((c) => !c.communicationChannels?.includes(CommunicationChannel.AMB))
  }, [hasAmbEnabled, rawCommunities, searchTerm, sortField, sortDirection])

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const onSort = (sortField) => () => {
    setSortField(sortField)
  }

  const addCommunity = () => {
    setIsCreateCommunityModalOpen(true)
    analytics.track(analytics.events.CommunityCreateClicked())
    setSearchTerm('')
  }

  useEffect(() => {
    if (isAddCommunityInitiallyOpen) {
      addCommunity()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shouldRenderAdd = communities.length > 0

  const { total: activeFansCount = 0 } = useAllMemberCount()
  const activeFansFormatted = formatLargeNumber(activeFansCount)

  const allContactsCount = t('communities.activeMembers', {
    count: activeFansCount,
    total: activeFansFormatted,
  })

  const sortOptions = useMemo(() => {
    let options = [
      { value: SortField.NAME, label: t('communities.name') },
      { value: SortField.DATE, label: t('communities.dateCreated') },
    ]
    if (isSortByMemberCountEnabled) {
      options = options.concat({
        value: SortField.COUNT,
        label: t('communities.memberCount'),
      })
    }
    return options
  }, [t, isSortByMemberCountEnabled])
  const activeFilter = sortOptions.find((option) => option.value === sortField) || sortOptions[0]

  return (
    <StyledCommunities data-testid="communities-list">
      <StyledHeader>
        <StyledTitle>
          {props.title}{' '}
          <StyledAdd aria-label={t('communities.addCommunity')} onClick={addCommunity}>
            <PlusCircleIcon size={32} />
          </StyledAdd>
        </StyledTitle>
        <StyledSearch>
          <SearchBar onChange={onSearchChange} placeholder="Search Communities" value={searchTerm} />
        </StyledSearch>
        <div>
          <Filter activeFilter={activeFilter.label} align="left" icon={false} label={t('communities.sortBy')}>
            {sortOptions.map((option) => (
              <Filter.Option onClick={onSort(option.value)} selected={sortField === option.value}>
                {option.label}
              </Filter.Option>
            ))}
            <Filter.Sort direction={sortDirection} onClick={setSortDirection} />
          </Filter>
        </div>
      </StyledHeader>
      <StyledList data-testid="communities-list-scroll" role="listbox">
        <div>
          {!searchTerm && (
            <ListItem
              label={t('communities.allMembers')}
              onClick={() => props.onItemClick({ id: null })}
              prefix={<CommunityDot color={COLORS?.SUBTEXT} />}
              selected={!activeCommunityId}
              subtext={allContactsCount}
            />
          )}
          {searchTerm && !communities.length && (
            <StyledNoResults>
              No results for &quot;
              {searchTerm}
              &quot;
            </StyledNoResults>
          )}
          <div style={{ height: communities.length * 59 }}>
            {communities.map((community) => {
              return (
                <LazyComponent
                  key={community.id}
                  options={{
                    threshold: 0,
                    triggerOnce: true,
                  }}
                  style={{
                    height: 59,
                  }}
                >
                  <CommunitiesListItem
                    active={activeCommunityId === community.id}
                    community={community}
                    key={community.id}
                    onItemClick={props.onItemClick}
                  />
                </LazyComponent>
              )
            })}
          </div>
        </div>
      </StyledList>
      {shouldRenderAdd && (
        <StyledAddRow data-testid="communities-create-community" onClick={addCommunity}>
          + {t('communities.createCommunity')}
        </StyledAddRow>
      )}
      {isCreateCommunityModalOpen && (
        <CreateCommunityModal
          allowRetroactiveCommunity={hasFullTextSearch}
          defaultTitle={searchTerm}
          onClose={() => setIsCreateCommunityModalOpen(false)}
        />
      )}
    </StyledCommunities>
  )
}

export default CommunitiesList
