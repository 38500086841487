import { Toast as ToastComponent } from '@community_dev/pixels'
import React from 'react'

import { StyledToastContainer } from './Toast.styled'

type ToastProps = {
  message?: JSX.Element | string | null
  onDismiss?: (...args: any[]) => any
  onView?: (...args: any[]) => any
  icon?: boolean
  success?: boolean
  maxWidth?: string | number
  style?: React.CSSProperties
  className?: string
}

class Toast extends React.PureComponent<ToastProps> {
  render(): JSX.Element {
    const { message, onDismiss, onView, icon, success, maxWidth, style, className } = this.props

    return (
      <StyledToastContainer
        className={className}
        data-testid={`toast-message-${success ? 'success' : 'failure'}`}
        style={style}
      >
        <ToastComponent
          icon={icon}
          maxWidth={maxWidth ? maxWidth.toString() : undefined}
          message={message}
          onDismiss={onDismiss}
          onView={onView}
          success={success}
        />
      </StyledToastContainer>
    )
  }
}

export default Toast
