type ImageDimension = {
  width: number
  height: number
}

export function calculateScaledDimensions(
  originalDimensions: ImageDimension,
  maxDimensions: ImageDimension,
): ImageDimension {
  const aspectRatio = originalDimensions.width / originalDimensions.height

  let scaledWidth = maxDimensions.width
  let scaledHeight = maxDimensions.height

  if (aspectRatio > 1) {
    scaledHeight = maxDimensions.width / aspectRatio
    if (scaledHeight > maxDimensions.height) {
      scaledHeight = maxDimensions.height
      scaledWidth = scaledHeight * aspectRatio
    }
  } else {
    scaledWidth = maxDimensions.height * aspectRatio
    if (scaledWidth > maxDimensions.width) {
      scaledWidth = maxDimensions.width
      scaledHeight = scaledWidth / aspectRatio
    }
  }

  return {
    width: Math.ceil(scaledWidth),
    height: Math.ceil(scaledHeight),
  }
}
