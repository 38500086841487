import { BUTTON_VARIANTS, Button, FONT_WEIGHT, Box, Layout, Typography, SPACING, Dialog } from '@community_dev/pixels'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { PRIVACY_POLICY_HREF, TERMS_OF_SERVICE_HREF } from 'constants/communityLinks'
import { ROUTES } from 'constants/routes'
import { usePlanColor } from 'hooks/billing/usePlanColor'
import { useSubscription } from 'hooks/billing/useSubscription'

const StyledBoxContainer = styled(Box)`
  text-align: left;
  margin: 0;
  flex: 1 1 377px;
  > div {
    margin: 0;
    width: 100%;
    //Space around the box
    padding: ${SPACING[6]} ${SPACING[5]};
  }
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  text-decoration: none;
`

const Divider = styled.div`
  width: 100%;
  border-bottom: 0.5px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  margin: 0 0 ${SPACING[5]} 0;
`

const StyledButton = styled(Button)`
  margin-top: ${SPACING[5]};
`

export const TermedInvoiceBillingForm = (): JSX.Element => {
  const stripe = useStripe()
  const elements = useElements()
  const { COLORS } = useTheme() || {}
  const { color } = usePlanColor()
  const [error, setError] = useState('')
  const { t } = useTranslation()
  const { data } = useSubscription()
  const [isLoading, setIsLoading] = useState(false)
  const planName = data?.subscription?.planName
  const planDescription = data?.subscription?.description

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${import.meta.env.VITE_PUBLIC_URL}${
          ROUTES.CONFIRM_PAYMENT_DETAILS.SETUP_INTENT
        }`,
      },
    })

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setError(error.message || t('settings.billing.processingError'))
    } else {
      setError(t('settings.billing.genericError'))
    }

    setIsLoading(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Layout display="flex" flexWrap="wrap" gap={SPACING[5]} marginBottom={SPACING[3]}>
          <StyledBoxContainer>
            <Typography margin={`0 0 ${SPACING[5]} 0`} variant="h3">
              {t('billing.enterBillingInfo')}
            </Typography>
            <PaymentElement options={{ terms: { card: 'never' } }} />
          </StyledBoxContainer>
          <StyledBoxContainer>
            <Typography margin={`0 0 ${SPACING[5]} 0`} variant="h3">
              {t('billing.summary')}
            </Typography>
            <Layout margin={`0 0 ${SPACING[1]} 0`}>
              <Typography color={color || COLORS?.TEXT} component="span" variant="h5">
                {`${planName} `}
              </Typography>
              <Typography color={COLORS?.TEXT} component="span" fontWeight={FONT_WEIGHT[5]} variant="h5">
                {t('settings.billing.termedInvoice.plan')}
              </Typography>
            </Layout>
            <Typography color={COLORS?.SUBTEXT} margin={`0 0 ${SPACING[5]} 0`} variant="body2">
              {planDescription}
            </Typography>
            <Divider />
            <Typography color={COLORS?.TEXT} component="span" textAlign="left" variant="caption2" whiteSpace="pre-line">
              <Trans i18nKey="settings.billing.editPaymentLegal">
                <StyledLink href={TERMS_OF_SERVICE_HREF} rel="noreferrer" target="_blank"></StyledLink>
                <StyledLink href={PRIVACY_POLICY_HREF} rel="noreferrer" target="_blank"></StyledLink>
              </Trans>
            </Typography>
            <StyledButton
              disabled={isLoading || !stripe || !elements}
              type="submit"
              variant={BUTTON_VARIANTS.ACTION}
              width="100%"
            >
              {isLoading ? t('settings.billing.processing') : t('billing.confirmPaymentDetails')}
            </StyledButton>
          </StyledBoxContainer>
        </Layout>
      </form>

      {error && (
        <Dialog message={error}>
          <Dialog.Action onClick={() => setError('')}>{t('billing.ok')}</Dialog.Action>
        </Dialog>
      )}
    </>
  )
}
