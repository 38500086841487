import { SelectedFilters } from './SelectedFilters'
import { SuggestionsFilters } from './SuggestionsFilter'

import { useFilters } from 'contexts/FilterProvider/FilterProvider'

export const RecommendationsTop = (): JSX.Element => {
  const { activeSubtreeFilters } = useFilters()

  return activeSubtreeFilters ? <SelectedFilters /> : <SuggestionsFilters />
}
