import {
  BuiltInFields,
  FieldSources,
  FieldTypes,
  FullTextSearchField,
  MemberDataFilter,
  SelectorOperators,
  fieldLabelFor,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { useKeywordResponders } from './keywordResponders/useKeywordResponders'
import { useToastMessage } from './useToastMessage'

import { Community, postAssignByQuery } from 'api/community'
import { addCommunityKeyword } from 'api/keyword-communities'
import { addKeywordResponder, deleteKeywordResponder, updateKeywordResponder } from 'api/keywordResponders'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import dayjs from 'utils/dayjs'
import { NormalizedKeyword } from 'utils/normalize'

export type UseCommunityKeywordsHook = {
  removeKeywordResponder(props: { keyword: Omit<NormalizedKeyword, 'id'> }): Promise<unknown>
  upsertKeywordResponder(props: { keyword: Omit<NormalizedKeyword, 'id'>; autoResponderText: string }): Promise<unknown>
  formatKeyword(keyword: string): string
  addKeywordToCommunity(props: {
    keyword: Omit<NormalizedKeyword, 'id'>
    community: Community
    includeFansRetroactively: boolean
  }): Promise<unknown>
}

export function useCommunityKeywords(): UseCommunityKeywordsHook {
  const clientId = useClientId()
  const queryClient = useQueryClient()
  const { data: keywordMessages = { data: [] } } = useKeywordResponders()
  const { showToastMessage } = useToastMessage()

  /* keywords in keyword responders are lowercased and trimmed on the
     server, so we need to recreate this formatting when comparing them. */
  const formatKeyword = useCallback((keyword: string) => {
    return keyword.trim().toLocaleLowerCase()
  }, [])

  const { mutateAsync: removeKeywordResponder } = useMutation({
    mutationFn({ keyword }: { keyword: Omit<NormalizedKeyword, 'id'> }) {
      const existingResponder = keywordMessages.data.find(
        (km) => formatKeyword(km.keyword) === formatKeyword(keyword.word),
      )
      if (existingResponder) {
        return deleteKeywordResponder({ id: existingResponder.id })
      } else {
        return new Promise((resolve) => resolve(null))
      }
    },
    onSuccess(update) {
      if (update !== null) {
        queryClient.invalidateQueries([QUERY_CACHE.SETTINGS.KEYWORD_MESSAGES])
      }
    },
    onError() {
      showToastMessage({
        message: `Could not remove Keyword Responders`,
        success: false,
      })
    },
  })

  const { mutateAsync: upsertKeywordResponder } = useMutation({
    mutationFn: ({
      keyword,
      autoResponderText,
    }: {
      keyword: Omit<NormalizedKeyword, 'id'>
      autoResponderText: string
    }) => {
      const existingResponder = keywordMessages.data.find(
        (km) => formatKeyword(km.keyword) === formatKeyword(keyword.word),
      )
      if (existingResponder) {
        return updateKeywordResponder({
          id: existingResponder.id,
          keyword: existingResponder.keyword,
          text: autoResponderText,
        })
      } else {
        return addKeywordResponder({
          keyword: keyword.word,
          text: autoResponderText,
        })
      }
    },
    onSuccess() {
      queryClient.invalidateQueries([QUERY_CACHE.SETTINGS.KEYWORD_MESSAGES])
    },
    onError() {
      showToastMessage({
        message: `Could not update Keyword Responders`,
        success: false,
      })
    },
  })

  const { mutateAsync: addKeywordToCommunity } = useMutation({
    mutationFn: async ({
      keyword,
      community,
      includeFansRetroactively,
    }: {
      keyword: Omit<NormalizedKeyword, 'id'>
      community: Community
      includeFansRetroactively: boolean
    }) => {
      await addCommunityKeyword({
        communityId: community?.id,
        keyword: keyword.word,
      })
      if (includeFansRetroactively) {
        const memberDataFilter: MemberDataFilter = {
          operator: SelectorOperators.QUERY,
          operand: {
            field_key: BuiltInFields.FULL_TEXT_SEARCH,
            field_label: fieldLabelFor(BuiltInFields.FULL_TEXT_SEARCH),
            source: FieldSources.BUILT_IN,
            type: FieldTypes.FULL_TEXT_SEARCH,
            value: {
              client_id: clientId,
              search_fields: [FullTextSearchField.MESSAGE_TEXT],
              search_query: keyword.word,
              exact_match: true,
              end_datetime: dayjs().toISOString(),
              start_datetime: dayjs().subtract(1, 'year').toISOString(),
            },
          },
        }

        const body: {
          id: string
          clientId: string
          filters: {
            subscription_data: MemberDataFilter
          }
          fanSubscriptionTags: {
            any: []
            not: []
          }
        } = {
          id: community.id,
          clientId,
          fanSubscriptionTags: {
            any: [],
            not: [],
          },
          filters: { subscription_data: memberDataFilter },
        }
        await postAssignByQuery({
          ...body,
          communicationChannel: CommunicationChannel.SMS,
        })
        await postAssignByQuery({
          ...body,
          communicationChannel: CommunicationChannel.WHATS_APP,
        })
      }
    },
  })
  return { removeKeywordResponder, upsertKeywordResponder, addKeywordToCommunity, formatKeyword }
}
