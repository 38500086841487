import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { OnboardingConfirmationMessage, useOnboardingConfirmation } from './useOnboardingConfirmationMessage'
import { useOnboardingWelcomeMessage, OnboardingWelcomeMessage } from './useOnboardingWelcomeMessage'
import { UpdateMessage, useUpdateOnboardingMessage } from './useUpdateOnboardingMessage'

export type OnboardingReplyYMessage = CamelCasedPropertiesDeep<Api.OnboardingReplyYMessage>

export type OnboardingMessagesResult = {
  isProcessing: boolean
  welcomeMessage: OnboardingWelcomeMessage
  updateMessage: (o: UpdateMessage) => void
  confirmationMessage: OnboardingConfirmationMessage
  replyYLegalMessage?: OnboardingReplyYMessage
}

export const useOnboardingMessages = (withTransformations = true): OnboardingMessagesResult => {
  const { updateMessage, isProcessing } = useUpdateOnboardingMessage()

  const welcomeMessage = useOnboardingWelcomeMessage(withTransformations)

  const confirmationMessage = useOnboardingConfirmation(withTransformations)

  return {
    isProcessing,
    welcomeMessage,
    confirmationMessage,
    updateMessage,
  }
}
