import { humanizeMemberDataFilterShortened } from '@community_dev/filter-dsl/lib/humanize/humanizeMemberDataFilters'
import { jsonLogicToMemberDataFilter } from '@community_dev/filter-dsl/lib/transformers/jsonLogicToMemberDataFilter'
import {
  WorkflowConnectorDroppableItem,
  WorkflowConnectorDroppableProps,
  FlexibleCanvas,
  WorkflowConnectorDroppable,
  WorkflowAction as WorkflowActionCard,
  WorkflowEntrypoint,
  WorkflowEditorCardTypes,
  WORKFLOW_CARDS,
  WorkflowActionIconWrapper,
  WorkflowCardMoreWrapper,
  WorkflowActionWrapper,
  midpoint,
  humanizeDuration,
  createPath,
  calculateNodeConnectionPoints,
  WorkflowLogic,
  WorkflowCardConfig,
} from '@community_dev/flexible-canvas'
import {
  CloseIcon,
  Dialog,
  DIALOG_VARIANTS,
  Ellipsis,
  Layout,
  Menu,
  Modal,
  MODAL_VARIANTS,
  Typography,
  LightningIcon,
  SPACING,
  Radio,
  FONT_SIZE,
  CheckBox,
  UploadIndicator,
  AudioPlayer,
  Tooltip,
  WarningIcon,
} from '@community_dev/pixels'
import { MediaProcessingStatus } from '@community_dev/types/lib/api/v2/Media'
import {
  Action,
  addAction,
  removeStep,
  WorkflowAction,
  Manifest,
  isNodeDataAction,
  WorkflowTrigger,
  isSendMessageAction,
  ManifestFunctionLogic,
  addLogic,
  Tree,
  TreeData,
  WorkflowType,
  isLogic,
  getLogicIndex,
  KeywordEntrypoint,
  updateKeywords,
  removeBranch,
  ManifestFunctionCondition,
  isAddToCommunityAction,
} from '@community_dev/workflow-manifest'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DropTargetMonitor } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { matchPath, useHistory, useParams, useRouteMatch } from 'react-router'
import styled, { useTheme } from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'

import { WorkflowSidebar } from './components/WorkflowSidebar/WorkflowSidebar'
import { WorkflowSidebarEdit } from './components/WorkflowSidebarEdit'
import { FilterModalMode, traverseLogic } from './components/WorkflowSidebarEditLogic'
import { WorkflowStatusbar } from './components/WorkflowStatusbar'
import { WorkflowToolbar } from './components/WorkflowToolbar'
import { useWorkflowProvider } from './context/WorkflowProvider'
import { useCreateManifest } from './hooks/useCreateManifest'
import { useRemoteWorkflow } from './hooks/useRemoteWorkflow'
import { getMediaForAction, NODE_SPACING_Y, useWorkflowTree } from './hooks/useWorkflowTree'
import IconCommunityLogo from './svg/community-logo.svg?react'
import IconConfirmationMessage from './svg/confirmation-message.svg?react'
import IconExitFlow from './svg/exit-flow.svg?react'
import IconMessageBubbleEllipsis from './svg/message-bubble-ellipsis.svg?react'
import IconMore from './svg/more.svg?react'
import IconWelcomeWave from './svg/welcome-wave.svg?react'
import { formatPlaceholders } from './utils/formatPlaceholders'
import {
  StyledContainer,
  RelativeWrapper,
  StyledWorkflowSidebarContainer,
  StyledModalHeading,
  WorkflowMoreButton,
  StyledWorkflowCard,
  WorkflowElseBubble,
  StyledRadioWrapper,
  StyledRadioLabel,
  WorkflowWrapper,
} from './WorkflowEditor.style'

import { createWorkflow } from 'api/workflows'
import { CommunityDot } from 'components/CommunityDot'
import { ROUTES } from 'constants/routes'
import { WORKFLOW_ID_UNSAVED } from 'constants/workflow'
import { useWorkflowAnalytics, formatSent, getMessageTextsWithIndex } from 'containers/Settings/useWorkflowAnalytics'
import useBreakpoints from 'hooks/useBreakpoints'
import { useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { OnboardingConfirmationMessage, OnboardingWelcomeMessage } from 'hooks/useOnboardingMessages'
import { useToastMessage } from 'hooks/useToastMessage'
import { useWorkflowKeywords } from 'hooks/useWorkflowKeywords'
import Sentry from 'integrations/Sentry'
import { CONFIRMATION_MESSAGE_KEY, WELCOME_MESSAGE_KEY } from 'screens/Onboarding/constants'
import analytics from 'utils/analytics'
import { route } from 'utils/router'

export type NodeDataInfo = {
  id: string
  type: 'info'
  text: string
}

export type NodeDataWelcomeMessage = OnboardingWelcomeMessage & {
  type: 'welcome-message'
}

export type NodeDataSignupConfirmation = OnboardingConfirmationMessage & {
  type: 'signup-confirmation'
}

export type ExtraNodeTypes = NodeDataInfo | NodeDataWelcomeMessage | NodeDataSignupConfirmation

const DURATION_ONE_DAY = 'P1D'

const StyledUploadIndicator = styled(UploadIndicator)`
  width: 86px;
  height: 50px;
`

const StyledTooltipTarget = styled.span`
  display: flex;
  align-items: center;
  padding: 0 ${SPACING[1]};
`

const StyledTooltipContent = styled.span`
  display: inline-block;
  min-width: 300px;
  padding: ${SPACING[2]};
`

function getMessageIndex(functionKey: string, functionIndex: number, manifest: Manifest): number {
  return getMessageTextsWithIndex(manifest).findIndex((message) => {
    return message.function === functionKey && message.index === functionIndex
  })
}

/**
 * Return the human friendly path number for a node, to display in the UI
 *
 * @param node
 * @returns {number}
 */
function getPathIndex(node: Tree<string, TreeData>): number | undefined {
  if (node.parent?.data?.type === 'condition' || node.parent?.data?.type === 'else') {
    return getPathIndex(node.parent)
  } else if (node.parent?.data?.type === 'logic') {
    const childIndex = node.parent.children.indexOf(node)

    return childIndex + 1
  }
}

export function WorkflowEditor(): JSX.Element {
  const { t } = useTranslation()
  const { md } = useBreakpoints()
  const { COLORS } = useTheme()
  const history = useHistory()
  const clientId = useClientId()
  const { showToastMessage } = useToastMessage()
  const { workflow, manifest, dispatch, history: workflowHistory, mediaUpload } = useWorkflowProvider()
  const { data: remoteWorkflow, refetch } = useRemoteWorkflow()

  const isWorkflowLogicStepEnabled = workflow?.type === WorkflowType.signup

  const [functionKeyToAdd, setFunctionKeyToAdd] = useState<string>()
  const [functionIndexToAdd, setFunctionIndexToAdd] = useState<number>()
  const [logicIndexToAdd, setLogicIndexToAdd] = useState<number>()

  const [isSaving, setIsSaving] = useState(false)
  const [isAddToWorkflowModalOpen, setIsAddToWorkflowModalOpen] = useState(false)
  const [isDeleteLogicDialogOpen, setIsDeleteLogicDialogOpen] = useState(false)
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false)
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false)

  const [functionKeyToDelete, setFunctionKeyToDelete] = useState<string>()
  const [functionIndexToDelete, setFunctionIndexToDelete] = useState<number>()

  const [logicIndexToKeep, setLogicIndexToKeep] = useState<number>()

  const [shouldShowAnalyticsWarning, setShouldShowAnalyticsWarning] = useLocalStorageState<boolean>(
    'showWorkflowAnalyticsResetWarning',
    {
      defaultValue: true,
    },
  )
  const { getKeywordsTextPreview, getManifestKeywords, updateManifestKeywordList } = useWorkflowKeywords()
  const { data: communities } = useCommunities()

  useEffect(() => {
    // if this component is called without the workflow type being set, we
    // redirect to the new workflow screen.
    if (workflow !== undefined && workflow.type === undefined) {
      history.push(ROUTES.AUTOMATIONS.FLOWS_NEW)
    }
  }, [history, workflow])

  useEffect(() => {
    if (!isAddToWorkflowModalOpen) {
      setFunctionKeyToAdd(undefined)
      setLogicIndexToAdd(undefined)
      setFunctionIndexToAdd(undefined)
    }
  }, [isAddToWorkflowModalOpen])

  useEffect(() => {
    if (!isDeleteLogicDialogOpen) {
      setFunctionKeyToDelete(undefined)
      setFunctionIndexToDelete(undefined)
      setLogicIndexToKeep(undefined)
    }
  }, [isDeleteLogicDialogOpen])

  const {
    workflowId,
    functionIndex: paramFunctionIndex,
    functionKey: paramFunctionKey,
    messageType: paramOnboardingMessageType,
  } = useParams<{ workflowId: string; functionIndex: string; functionKey: string; messageType?: string }>()

  const isWorkflowChanged = useMemo(() => {
    return workflowId === WORKFLOW_ID_UNSAVED || !isEqual(manifest, remoteWorkflow?.last_manifest?.body)
  }, [manifest, workflow?.last_manifest?.body, workflowId])

  const onCancel = () => {
    if (isWorkflowChanged) {
      setIsDiscardDialogOpen(true)
    } else {
      history.push(ROUTES.AUTOMATIONS.FLOWS)
    }
  }

  const onSave = () => {
    // if there are analytics available that can be reset, and the user hasn’t
    // told us to stop showing the reset-analytics warning, display it.
    if (!noAnalyticsAvailable && shouldShowAnalyticsWarning) {
      setIsSaveDialogOpen(true)
    } else {
      saveChanges()
    }
  }

  const deleteStepAndRedirect = (functionKey: string, functionIndex: number, logicIndexToKeep?: number) => {
    const nextManifest = removeStep(manifest, functionKey, functionIndex, logicIndexToKeep)

    dispatch({
      type: 'update',
      state: {
        manifest: nextManifest,
      },
    })

    // if the node we want to delete is before the one that’s selected
    if (paramFunctionIndex && paramFunctionKey === functionKey && Number(paramFunctionIndex) > Number(functionIndex)) {
      // if we’re in the middle of adding a step, re-select it.
      if (matchPath(history.location.pathname, { path: ROUTES.AUTOMATIONS.FLOW_ADD_STEP })) {
        history.replace(
          route(ROUTES.AUTOMATIONS.FLOW_ADD_STEP, {
            workflowId,
            functionKey: paramFunctionKey,
            functionIndex: Number(paramFunctionIndex) - 1,
          }),
        )
        // if we’re in the middle of editing a step, re-select it.
      } else if (matchPath(history.location.pathname, { path: ROUTES.AUTOMATIONS.FLOW_EDIT_STEP })) {
        history.replace(
          route(ROUTES.AUTOMATIONS.FLOW_EDIT_STEP, {
            workflowId,
            functionKey: paramFunctionKey,
            functionIndex: Number(paramFunctionIndex) - 1,
          }),
        )
      }
      // if we’re deleting the step we’re currently adding or editing.
    } else if (paramFunctionIndex && paramFunctionKey === functionKey && Number(paramFunctionIndex) === functionIndex) {
      // de-select any step, go to the default view.
      history.replace(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
    } else {
      // the step we want to delete is in a different function or
      // comes after the one that’s selected => we do nothing.
    }
  }

  const saveChanges = async () => {
    setIsSaving(true)
    setIsSaveDialogOpen(false)
    try {
      const previousKeywords = getManifestKeywords(remoteWorkflow?.last_manifest?.body)
      const nextKeywords = getManifestKeywords(manifest)
      const nextManifest = updateKeywords(manifest, nextKeywords)
      // if it’s a new workflow
      if (workflowId === WORKFLOW_ID_UNSAVED) {
        const savedWorkflow = await createWorkflow({ clientId, name: workflow.name, type: workflow.type })
        await createManifestMutation({
          clientId,
          workflowId: savedWorkflow.id,
          manifest: nextManifest,
        })
        history.replace(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId: savedWorkflow.id }))
      } else {
        await createManifestMutation({
          clientId,
          workflowId,
          manifest: nextManifest,
        })
      }

      await updateManifestKeywordList(previousKeywords, nextKeywords)

      analytics.track(analytics.events.Workflows.Published({ type: workflow?.type }))

      showToastMessage({
        message: t('automations.flowSaved'),
        success: true,
        position: 'bottom-right',
      })

      if (workflowId !== WORKFLOW_ID_UNSAVED) {
        // if we’re editing an existing workflow, we need to refetch the
        // workflow to get the new manifest.
        await refetch()
      }
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          workflowId,
          manifest,
        },
      })
      showToastMessage({
        message: t('automations.publishError'),
        success: false,
        position: 'bottom-right',
      })
    }
    setIsSaving(false)
  }

  // warn before unload if there are unsaved changes.
  useEffect(() => {
    const confirmationMessage = t('automations.exitConfirmationMessage')
    function warnBeforeUnload(e: BeforeUnloadEvent) {
      if (isWorkflowChanged) {
        e.preventDefault()
        // for Firefox and old IE
        ;(e || window.event).returnValue = confirmationMessage
        // Modern Browsers
        return confirmationMessage
      }
    }

    if (!window.Cypress) {
      window.addEventListener('beforeunload', warnBeforeUnload)
      return () => window.removeEventListener('beforeunload', warnBeforeUnload)
    }
  }, [t, isWorkflowChanged])

  const { data: workflowAnalytics, isEmpty: noAnalyticsAvailable } = useWorkflowAnalytics(clientId, workflow)

  const getKeywordsFromEntrypoint = useCallback((trigger: KeywordEntrypoint) => {
    try {
      return trigger.params.keyword_matched.keywords
    } catch (e) {
      Sentry.captureException(e)
      return []
    }
  }, [])

  const { mutateAsync: createManifestMutation } = useCreateManifest({
    onError: (error, createManifestArgs) => {
      showToastMessage({
        message: (error as Error).message,
        success: false,
        position: 'bottom-right',
      })

      Sentry.captureException(error, {
        extra: {
          workflowId,
          manifest: createManifestArgs.manifest,
        },
      })
    },
  })

  const { nodes } = useWorkflowTree()

  const isEditingStep = useRouteMatch([
    ROUTES.AUTOMATIONS.FLOW_EDIT_STEP,
    ROUTES.AUTOMATIONS.FLOW_ADD_STEP,
    ROUTES.AUTOMATIONS.FLOW_ADD_STEP_SMS_MESSAGE,
    ROUTES.AUTOMATIONS.FLOW_ADD_STEP_ADD_TO_COMMUNITY,
  ])

  const addToWorkflow = (
    itemType: WorkflowEditorCardTypes,
    functionKey: string,
    functionIndex: number,
    logicIndex?: number,
  ) => {
    switch (itemType) {
      case WorkflowEditorCardTypes.SEND_MESSAGE: {
        history.push({
          pathname: route(ROUTES.AUTOMATIONS.FLOW_ADD_STEP_SMS_MESSAGE, {
            workflowId,
          }),
          state: {
            functionKey,
            functionIndex,
            logicIndex,
          },
        })

        break
      }
      case WorkflowEditorCardTypes.SLEEP: {
        const delayAction: Action = {
          type: WorkflowAction.Sleep,
          params: {
            duration: DURATION_ONE_DAY,
          },
        }

        const {
          manifest: nextManifest,
          newFunctionKey,
          newFunctionIndex,
        } = addAction(manifest, delayAction, functionKey, functionIndex, logicIndex)

        dispatch({
          type: 'update',
          state: {
            manifest: nextManifest,
          },
        })

        history.push(
          route(ROUTES.AUTOMATIONS.FLOW_ADD_STEP, {
            workflowId,
            functionKey: newFunctionKey,
            functionIndex: newFunctionIndex,
          }),
        )

        break
      }
      case WorkflowEditorCardTypes.LOGIC: {
        const logic: ManifestFunctionLogic = {
          if: [null, null, null],
        }

        const {
          manifest: nextManifest,
          newFunctionKey,
          newFunctionIndex,
        } = addLogic(manifest, logic, functionKey, functionIndex, logicIndex)

        dispatch({
          type: 'update',
          state: {
            manifest: nextManifest,
          },
        })

        history.push(
          route(ROUTES.AUTOMATIONS.FLOW_ADD_STEP, {
            workflowId,
            functionKey: newFunctionKey,
            functionIndex: newFunctionIndex,
          }),
        )

        break
      }
      case WorkflowEditorCardTypes.ADD_TO_COMMUNITY: {
        history.push({
          pathname: route(ROUTES.AUTOMATIONS.FLOW_ADD_STEP_ADD_TO_COMMUNITY, {
            workflowId,
          }),
          state: {
            functionKey,
            functionIndex,
            logicIndex,
          },
        })

        break
      }
      case WorkflowEditorCardTypes.WAIT:
      case WorkflowEditorCardTypes.REMOVE_FROM_COMMUNITY:
        break
    }
  }

  const onDrop = (
    item: WorkflowConnectorDroppableItem,
    monitor: DropTargetMonitor,
    props: WorkflowConnectorDroppableProps,
  ) => {
    const { functionKey, functionIndex, logicIndex } = props

    addToWorkflow(item.type, functionKey, functionIndex, logicIndex)
  }

  return (
    <StyledContainer>
      <WorkflowToolbar
        isChanged={isWorkflowChanged}
        isSaving={isSaving}
        onCancel={onCancel}
        onSave={onSave}
        workflowHistory={workflowHistory}
      />
      {isDiscardDialogOpen && (
        <Dialog
          message={t('automations.discardConfirmationMessage')}
          onCancel={() => setIsDiscardDialogOpen(false)}
          title={t('automations.discardUnsavedChanges')}
        >
          <Dialog.Action onClick={() => setIsDiscardDialogOpen(false)}>{t('cancel')}</Dialog.Action>
          <Dialog.Action onClick={() => history.push(ROUTES.AUTOMATIONS.FLOWS)} variant={DIALOG_VARIANTS.DESTRUCTIVE}>
            {t('discard')}
          </Dialog.Action>
        </Dialog>
      )}
      {isSaveDialogOpen && (
        <Dialog
          message={
            <>
              <Layout marginBottom={SPACING[4]}>{t('automations.savingChangesWillResetAnalytics')}</Layout>
              <label>
                <CheckBox
                  onChange={(value) => setShouldShowAnalyticsWarning(!value)}
                  selected={!shouldShowAnalyticsWarning}
                />{' '}
                {t('automations.doNotShowThisMessageAgain')}
              </label>
            </>
          }
          onCancel={() => setIsSaveDialogOpen(false)}
          title={t('automations.analyticsWillReset')}
        >
          <Dialog.Action onClick={() => setIsSaveDialogOpen(false)}>Cancel</Dialog.Action>
          <Dialog.Action onClick={saveChanges} variant={DIALOG_VARIANTS.EMPHASIZED}>
            {t('save')}
          </Dialog.Action>
        </Dialog>
      )}
      <WorkflowWrapper>
        {md && (
          <StyledWorkflowSidebarContainer data-testid="workflow-sidebar">
            <WorkflowSidebar />
          </StyledWorkflowSidebarContainer>
        )}
        <Layout display="flex" flex="1 0 280px" flexDirection="column">
          <WorkflowStatusbar />
          <FlexibleCanvas>
            {/**
             * Draw all flow connectors that have a parent and child node.
             *
             * This does not include connectors at the end of a branch, which are considered placeholder nodes.
             */}
            {nodes.map((node) => {
              if (node.children.length) {
                return node.children.map((child) => {
                  if (
                    ['info', 'welcome-message', 'signup-confirmation', 'condition', 'else'].indexOf(
                      child.data?.type || '',
                    ) > -1
                  ) {
                    return null
                  }

                  if (workflow?.type === WorkflowType.shopify_abandoned_checkout) {
                    return null
                  }

                  const functionKey = child.data.functionKey
                  const functionIndex = child.data.functionIndex
                  const logicIndex = child.data.logicIndex as number

                  const [[x1, y1], [x2, y2]] = calculateNodeConnectionPoints(node, child)
                  const [mx, my] = midpoint([x1, y1], [x2, y2])

                  return (
                    <RelativeWrapper
                      data-connector-key={child.key}
                      key={child.key}
                      style={{
                        top: my,
                        left: mx,
                        width: node.data.width / 2,
                        height: NODE_SPACING_Y,
                        zIndex: 10,
                      }}
                    >
                      <WorkflowConnectorDroppable
                        accept={[
                          WorkflowEditorCardTypes.SEND_MESSAGE,
                          WorkflowEditorCardTypes.SLEEP,
                          WorkflowEditorCardTypes.LOGIC,
                          WorkflowEditorCardTypes.WAIT,
                          WorkflowEditorCardTypes.ADD_TO_COMMUNITY,
                          WorkflowEditorCardTypes.REMOVE_FROM_COMMUNITY,
                        ]}
                        buttonProps={{
                          'aria-label': t('automations.addToWorkflow'),
                        }}
                        functionIndex={functionIndex}
                        functionKey={functionKey}
                        last={false}
                        logicIndex={logicIndex}
                        onClick={() => {
                          setFunctionKeyToAdd(functionKey)
                          setLogicIndexToAdd(logicIndex)
                          setFunctionIndexToAdd(functionIndex)
                          setIsAddToWorkflowModalOpen(true)
                        }}
                        onDrop={onDrop}
                        style={{
                          width: 100,
                          height: 40,
                        }}
                      />
                    </RelativeWrapper>
                  )
                })
              }

              return null
            })}

            {/**
             * Draw all steps within the flow (trigger, send message, sleep, etc)
             */}
            {nodes.map((node) => {
              const functionKey = node.data.functionKey
              const functionIndex = node.data.functionIndex

              let highlight = false

              // if the currently editing function matches this node
              if (
                node.data.functionIndex === Number(paramFunctionIndex) &&
                node.data.functionKey === paramFunctionKey
              ) {
                highlight = true
                // else if the node is a condition and the parent matches the editing node
              } else if (
                node.data.type === 'condition' &&
                node.parent?.data.functionIndex === Number(paramFunctionIndex) &&
                node.parent?.data.functionKey === paramFunctionKey
              ) {
                highlight = true
              }
              switch (node.data.type) {
                case 'signup-confirmation':
                case 'welcome-message': {
                  const label =
                    node.data.label === WELCOME_MESSAGE_KEY
                      ? t('automations.editWelcomeMessage')
                      : t('automations.editConfirmationMessage')

                  const onEditClick = () => {
                    history.push(
                      route(ROUTES.AUTOMATIONS.FLOW_EDIT_ONBOARDING_MESSAGE, {
                        workflowId,
                        messageType: node.data.type,
                      }),
                    )
                  }

                  return (
                    <div
                      data-node-key={node.key}
                      key={node.key}
                      style={{
                        top: node.data.y,
                        left: node.data.x,
                        position: 'absolute',
                      }}
                    >
                      <WorkflowActionWrapper
                        aria-label={label}
                        highlight={
                          (node.data.label === WELCOME_MESSAGE_KEY &&
                            paramOnboardingMessageType === 'welcome-message') ||
                          (node.data.label === CONFIRMATION_MESSAGE_KEY &&
                            paramOnboardingMessageType === 'signup-confirmation')
                        }
                        onClick={onEditClick}
                        role="button"
                        style={{
                          cursor: 'pointer',
                          width: node.data.width,
                          height: node.data.height,
                        }}
                      >
                        <Layout alignItems="center" display="flex">
                          <WorkflowActionIconWrapper>
                            {node.data.label === WELCOME_MESSAGE_KEY && <IconWelcomeWave />}
                            {node.data.label === CONFIRMATION_MESSAGE_KEY && <IconConfirmationMessage />}
                          </WorkflowActionIconWrapper>
                          <Layout>
                            <Typography component="div" fontSize="14px" fontWeight="500" variant="body1">
                              {node.data.label === WELCOME_MESSAGE_KEY && t('automations.welcomeMessage')}
                              {node.data.label === CONFIRMATION_MESSAGE_KEY && t('automations.confirmationMessage')}
                            </Typography>
                          </Layout>
                        </Layout>

                        <Layout marginTop="6px">
                          <Typography component="div" fontSize="12px" lineHeight="20px" variant="body2">
                            <Ellipsis lines={3}>
                              {node.data.text}
                              {typeof node.data.tcpa === 'string' && node.data.tcpa}
                              {typeof node.data.legalMessage === 'string' && node.data.legalMessage}
                            </Ellipsis>
                          </Typography>
                        </Layout>
                        <WorkflowCardMoreWrapper>
                          <Menu
                            trigger={
                              <WorkflowMoreButton aria-label={t('automations.workflowActionUpdates')}>
                                <IconMore />
                              </WorkflowMoreButton>
                            }
                            width={160}
                          >
                            <Menu.Item onClick={onEditClick}>{t('edit')}</Menu.Item>
                          </Menu>
                        </WorkflowCardMoreWrapper>
                      </WorkflowActionWrapper>
                    </div>
                  )
                }
                case 'info': {
                  return (
                    <div
                      data-node-key={node.key}
                      key={node.key}
                      style={{
                        top: node.data.y,
                        left: node.data.x,
                        width: node.data.width,
                        height: node.data.height,
                        position: 'absolute',
                      }}
                    >
                      <Layout
                        alignItems="center"
                        background={COLORS.WORKFLOW_CARD_INFO_BACKGROUND}
                        border={`1px solid ${COLORS.WORKFLOW_CARD_DEFAULT_BORDER}`}
                        borderRadius="8px"
                        color={COLORS.TEXT}
                        display="flex"
                        fontSize="12px"
                        height="100%"
                        justifyContent="flex-start"
                        padding="9px 15px"
                        width="100%"
                      >
                        <node.data.IconComponent style={{ marginRight: 8 }} />
                        {node.data.text}
                      </Layout>
                    </div>
                  )
                }
                case 'trigger': {
                  const highlight =
                    matchPath(history.location.pathname, { path: ROUTES.AUTOMATIONS.FLOW_EDIT_TRIGGER }) !== null
                  if (node.data.trigger.type === WorkflowTrigger.ShopifyAbandonedCheckout) {
                    const { icon: Icon, iconColor, backgroundColor, borderColor } = WORKFLOW_CARDS.SLEEP
                    return (
                      <div
                        data-node-key={node.key}
                        key={node.key}
                        style={{
                          top: node.data.y,
                          left: node.data.x,
                          position: 'absolute',
                        }}
                      >
                        <WorkflowEntrypoint
                          Icon={IconCommunityLogo}
                          highlight={highlight}
                          onClick={() =>
                            history.push(
                              route(ROUTES.AUTOMATIONS.FLOW_EDIT_TRIGGER, { workflowId, triggerName: node.key }),
                            )
                          }
                          style={{
                            width: node.data.width,
                            height: node.data.height,
                          }}
                          subtitle={t('automations.userHasNotCompletedCheckout')}
                          title={t('automations.triggerAbandonedCheckout')}
                        >
                          <Layout alignItems="center" display="flex" marginTop={SPACING[3]}>
                            <WorkflowActionIconWrapper
                              backgroundColor={backgroundColor}
                              borderColor={borderColor}
                              iconColor={iconColor}
                            >
                              <Icon style={{ transform: 'scale(0.8)' }} />
                            </WorkflowActionIconWrapper>
                            <Layout>
                              <Typography component="div" fontSize="12px" lineHeight="17px" variant="body2">
                                {t('automations.wait')}{' '}
                                {node.data.trigger.params !== undefined &&
                                  humanizeDuration(
                                    node.data.trigger.params.shopify_checkout_updated.abandoned_threshold,
                                  )}
                              </Typography>
                            </Layout>
                          </Layout>
                        </WorkflowEntrypoint>
                      </div>
                    )
                  } else if (node.data.trigger.type === WorkflowTrigger.Keyword) {
                    return (
                      <div
                        data-node-key={node.key}
                        key={node.key}
                        style={{
                          top: node.data.y,
                          left: node.data.x,
                          position: 'absolute',
                        }}
                      >
                        <WorkflowEntrypoint
                          Icon={IconMessageBubbleEllipsis}
                          highlight={highlight}
                          onClick={() =>
                            history.push(
                              route(ROUTES.AUTOMATIONS.FLOW_EDIT_TRIGGER, { workflowId, triggerName: node.key }),
                            )
                          }
                          style={{
                            width: node.data.width,
                            height: node.data.height,
                          }}
                          title={t('automations.triggerKeywordMatched')}
                        >
                          <Typography
                            color={COLORS.SUBTEXT}
                            display="block"
                            fontSize={FONT_SIZE[2]}
                            fontWeight="bold"
                            marginTop={SPACING[2]}
                          >
                            <Ellipsis>
                              {t('automations.keywords')}
                              {': '}
                              <span style={{ color: COLORS.TEXT }}>
                                {getKeywordsTextPreview(getKeywordsFromEntrypoint(node.data.trigger))}
                              </span>
                            </Ellipsis>
                          </Typography>
                        </WorkflowEntrypoint>
                      </div>
                    )
                  } else {
                    return (
                      <div
                        data-node-key={node.key}
                        key={node.key}
                        style={{
                          top: node.data.y,
                          left: node.data.x,
                          position: 'absolute',
                        }}
                      >
                        <WorkflowEntrypoint
                          Icon={IconCommunityLogo}
                          highlight={highlight}
                          style={{
                            width: node.data.width,
                            height: node.data.height,
                          }}
                          title={t('automations.triggerNewMemberText')}
                        />
                      </div>
                    )
                  }
                }
                case 'action': {
                  const sentMessages =
                    workflowAnalytics.functions[node.data.functionKey]?.[node.data.functionIndex]?.totalsent
                  const tagId = (isAddToCommunityAction(node.data.action) && node.data.action.params?.tag_id) || null
                  const matchingCommunity = communities?.custom?.find((community) => community.id === tagId)
                  let label = t('automations.editDelayStep')

                  if (node.data.action.type === WorkflowAction.SendMessage) {
                    label = t('automations.editMessageAction')
                  } else if (
                    node.data.action.type === WorkflowAction.AddToCommunity ||
                    node.data.action.type === WorkflowAction.RemoveFromCommunity
                  ) {
                    label = t('automations.editCommunityAction')
                  }

                  const onEditClick = () => {
                    history.push(
                      route(ROUTES.AUTOMATIONS.FLOW_EDIT_STEP, {
                        workflowId,
                        functionKey,
                        functionIndex,
                      }),
                    )
                  }

                  const { mediaObjects } = getMediaForAction(node, mediaUpload)

                  return (
                    <div
                      data-node-key={node.key}
                      key={node.key}
                      style={{
                        top: node.data.y,
                        left: node.data.x,
                        position: 'absolute',
                      }}
                    >
                      <WorkflowActionCard
                        action={formatPlaceholders(node.data.action)}
                        appendTitle={
                          node.data.action.type === WorkflowAction.SendMessage &&
                          `#${getMessageIndex(functionKey, functionIndex, manifest) + 1}`
                        }
                        aria-label={label}
                        highlight={highlight}
                        maxLines={2}
                        onClick={onEditClick}
                        renderMore={() => {
                          return (
                            <Layout display="flex">
                              {isNodeDataAction(node.data) &&
                                isAddToCommunityAction(node.data.action) &&
                                !matchingCommunity && (
                                  <Tooltip
                                    content={
                                      <StyledTooltipContent data-testid="info-tooltip">
                                        {t('automations.deletedCommunityWarning')}
                                      </StyledTooltipContent>
                                    }
                                    interactive
                                    placement="bottom"
                                  >
                                    <StyledTooltipTarget data-testid="info-icon">
                                      <WarningIcon color={COLORS.ERRORS} size={20} />
                                    </StyledTooltipTarget>
                                  </Tooltip>
                                )}
                              <Menu
                                trigger={
                                  <WorkflowMoreButton aria-label={t('automations.workflowActionOptions')}>
                                    <IconMore />
                                  </WorkflowMoreButton>
                                }
                                width={160}
                              >
                                <Menu.Item onClick={onEditClick}>{t('edit')}</Menu.Item>
                                {/*
                                  The "delete" option is hidden, if we’re dealing with a Shopify Abandoned Checkout Workflow,
                                  it’s the first action, and it contains the Shopify checkout URL, since that node should
                                  always be present.
                                */}
                                {isNodeDataAction(node.data) &&
                                isSendMessageAction(node.data.action) &&
                                node.data.functionIndex === 0 &&
                                node.data.action.params.include_checkout_url === true ? null : (
                                  <Menu.Item
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      deleteStepAndRedirect(node.data.functionKey, node.data.functionIndex)
                                    }}
                                  >
                                    {t('delete')}
                                  </Menu.Item>
                                )}
                              </Menu>
                            </Layout>
                          )
                        }}
                        role="button"
                        style={{
                          cursor: 'pointer',
                          width: node.data.width,
                          height: node.data.height,
                        }}
                      >
                        {mediaObjects?.map((media) => {
                          if (
                            media.status === MediaProcessingStatus.PROCESSING ||
                            media.status === MediaProcessingStatus.UPLOAD_URL_ISSUED
                          ) {
                            return (
                              <Layout height="50px" marginTop="5px" position="relative" width="86px">
                                <StyledUploadIndicator />
                              </Layout>
                            )
                          }

                          if (media.status === MediaProcessingStatus.ERRORED) {
                            return (
                              <Layout
                                alignItems="center"
                                display="flex"
                                flexDirection="column"
                                height="50px"
                                marginTop="5px"
                                position="relative"
                              >
                                <Typography fontSize="12px">{t('automations.mediaFileUploadError')}</Typography>
                                <Typography fontSize="12px" fontWeight="600">
                                  {t('automations.retry')}
                                </Typography>
                              </Layout>
                            )
                          }

                          if (media.mime_type?.startsWith('audio') && media.url) {
                            return (
                              <Layout height="50px" marginTop="5px" position="relative">
                                <AudioPlayer src={media.url} />
                              </Layout>
                            )
                          }

                          if ((media.thumbnail_url || media.url) && media.mime_type) {
                            return (
                              <Layout height="50px" marginTop="5px" position="relative">
                                <a href={media.thumbnail_url || (media.url as string)} rel="noreferrer" target="_blank">
                                  <img
                                    alt={media.filename || ''}
                                    src={media.thumbnail_url || (media.url as string)}
                                    style={{
                                      width: 'auto',
                                      height: 50,
                                      borderRadius: 4,
                                    }}
                                  />
                                </a>
                              </Layout>
                            )
                          }
                        })}
                        {isSendMessageAction(node.data.action) && (
                          <Layout alignItems="center" display="flex" marginLeft="-6px" marginTop="auto">
                            <LightningIcon size={20} />
                            <Typography
                              component="div"
                              fontSize="12px"
                              lineHeight="17px"
                              marginLeft={SPACING[1]}
                              variant="body2"
                            >
                              {t('automations.sent')} {formatSent(sentMessages)}
                            </Typography>
                          </Layout>
                        )}
                        {isAddToCommunityAction(node.data.action) && (
                          <Layout alignItems="center" display="flex" marginTop={SPACING[3]}>
                            <CommunityDot
                              color={matchingCommunity?.color || COLORS.COMMUNITY_COLOR_DEFAULT}
                              size={20}
                            />
                            <Typography
                              component="div"
                              fontSize="12px"
                              fontWeight="600"
                              lineHeight="17px"
                              marginLeft={SPACING[2]}
                              maxWidth="calc(100% - 40px)"
                              variant="body2"
                            >
                              <Ellipsis>{matchingCommunity?.title || t('deletedCommunity')}</Ellipsis>
                            </Typography>
                          </Layout>
                        )}
                      </WorkflowActionCard>
                    </div>
                  )
                }
                case 'logic': {
                  const onEditClick = () => {
                    history.push(
                      route(ROUTES.AUTOMATIONS.FLOW_EDIT_STEP, {
                        workflowId,
                        functionKey,
                        functionIndex,
                      }),
                    )
                  }

                  const onDeleteClick = (e) => {
                    e.stopPropagation()
                    setFunctionKeyToDelete(node.data.functionKey)
                    setFunctionIndexToDelete(node.data.functionIndex)
                    setIsDeleteLogicDialogOpen(true)
                  }

                  return (
                    <div
                      data-node-key={node.key}
                      key={node.key}
                      style={{
                        top: node.data.y,
                        left: node.data.x,
                        position: 'absolute',
                      }}
                    >
                      <WorkflowLogic
                        aria-label={t('automations.editLogic')}
                        highlight={highlight}
                        onClick={onEditClick}
                        renderMore={() => {
                          return (
                            <Menu
                              trigger={
                                <WorkflowMoreButton aria-label={t('automations.workflowLogicOptions')}>
                                  <IconMore />
                                </WorkflowMoreButton>
                              }
                              width={160}
                            >
                              <Menu.Item onClick={onEditClick}>{t('edit')}</Menu.Item>
                              <Menu.Item onClick={onDeleteClick}>{t('delete')}</Menu.Item>
                            </Menu>
                          )
                        }}
                        role="button"
                        style={{
                          cursor: 'pointer',
                          width: node.data.width,
                          height: node.data.height,
                        }}
                      />
                    </div>
                  )
                }
                case 'condition': {
                  const memberDataFilters =
                    node.data.condition === null
                      ? null
                      : jsonLogicToMemberDataFilter(node.data.condition as ManifestFunctionCondition)

                  const pathIndex = getPathIndex(node as Tree<string, TreeData>)
                  const logicIndex = getLogicIndex(node as Tree<string, TreeData>) as number

                  const onEditClick = () => {
                    history.push(
                      route(ROUTES.AUTOMATIONS.FLOW_EDIT_STEP, {
                        workflowId,
                        functionKey,
                        functionIndex,
                      }),
                      {
                        initFilterModalOpen: true,
                        initFilterModalMode: FilterModalMode.EditPath,
                        initFilterModalConditionIndex: logicIndex - 1,
                        initFilters: memberDataFilters,
                      },
                    )
                  }

                  const onDeleteClick = (e: React.MouseEvent) => {
                    e.stopPropagation()
                    const fn = manifest.functions[functionKey][functionIndex]
                    // if the "if" array has length 3, that means there’s only one condition left
                    if ('if' in fn && fn.if.length === 3) {
                      // so we want to remove the entire "if" node and keep the else branch
                      const elseBranchIndex = Array.from(traverseLogic(fn)).findIndex(({ type }) => type === 'else')
                      deleteStepAndRedirect(functionKey, functionIndex, elseBranchIndex)
                      // otherwise, we just remove the branch.
                    } else {
                      const nextManifest = removeBranch(manifest, functionKey, functionIndex, logicIndex - 1)
                      dispatch({
                        type: 'update',
                        state: {
                          manifest: nextManifest,
                        },
                      })
                    }
                  }

                  return (
                    <div
                      data-node-key={node.key}
                      key={node.key}
                      style={{
                        top: node.data.y,
                        left: node.data.x,
                        width: node.data.width,
                        height: node.data.height,
                        position: 'absolute',
                      }}
                    >
                      <Layout
                        alignItems="center"
                        aria-label={t('automations.editFiltersForThisBranch')}
                        background={
                          highlight
                            ? COLORS.WORKFLOW_CARD_HIGHLIGHT_BACKGROUND
                            : COLORS.WORKFLOW_CARD_DEFAULT_BACKGROUND
                        }
                        border={`1px solid ${
                          highlight ? COLORS.WORKFLOW_CARD_HIGHLIGHT_BORDER : COLORS.WORKFLOW_CARD_DEFAULT_BORDER
                        }`}
                        borderRadius="8px"
                        color={COLORS.TEXT}
                        cursor="pointer"
                        display="flex"
                        fontSize="12px"
                        height="100%"
                        justifyContent="flex-start"
                        onClick={onEditClick}
                        padding="9px 15px"
                        role="button"
                        width="100%"
                      >
                        {memberDataFilters ? (
                          <Layout fontSize="12px" fontWeight="600" width="100%">
                            <Ellipsis>
                              {t('automations.path')}&nbsp;{pathIndex}:&nbsp;
                              {humanizeMemberDataFilterShortened(memberDataFilters)}
                            </Ellipsis>
                          </Layout>
                        ) : (
                          <Typography color={COLORS?.SUBTEXT} fontSize="12px">
                            {t('automations.noFiltersSetForThisBranch')}
                          </Typography>
                        )}
                        <WorkflowCardMoreWrapper>
                          <Menu
                            trigger={
                              <WorkflowMoreButton aria-label={t('automations.workflowBranchOptions')}>
                                <IconMore />
                              </WorkflowMoreButton>
                            }
                            width={160}
                          >
                            <Menu.Item onClick={onEditClick}>{t('edit')}</Menu.Item>
                            <Menu.Item onClick={onDeleteClick}>{t('delete')}</Menu.Item>
                          </Menu>
                        </WorkflowCardMoreWrapper>
                      </Layout>
                    </div>
                  )
                }
                case 'else': {
                  return (
                    <div
                      data-node-key={node.key}
                      key={node.key}
                      style={{
                        top: node.data.y,
                        left: node.data.x,
                        width: node.data.width,
                        height: node.data.height,
                        position: 'absolute',
                      }}
                    >
                      <Layout alignItems="center" display="flex" justifyContent="center">
                        <WorkflowElseBubble>{t('automations.else')}</WorkflowElseBubble>
                      </Layout>
                    </div>
                  )
                }
                case 'placeholder': {
                  return (
                    <div
                      data-connector-key={node.key}
                      key={node.key}
                      style={{
                        top: node.data.y,
                        left: node.data.x,
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        width: node.data.width,
                      }}
                    >
                      <Layout alignItems="center" display="flex" flexDirection="column" justifyContent="center">
                        <IconExitFlow />
                        <Typography component="div" fontSize="13px" fontWeight="600" lineHeight="1">
                          {t('automations.exitFlow')}
                        </Typography>
                      </Layout>
                    </div>
                  )
                }
              }

              return null
            })}

            <svg
              style={{
                width: 9999,
                height: 9999,
                overflow: 'visible',
              }}
            >
              {/**
               * Draw all placeholder nodes at the end of a branch
               */}
              {nodes.map((node, i) => {
                if (node.children.length) {
                  return node.children.map((child, ic) => {
                    const [[x1, y1], [x2, y2]] = calculateNodeConnectionPoints(node, child)

                    return (
                      <path
                        d={createPath([x1, y1], [x2, y2])}
                        data-from={node.key}
                        data-to={child.key}
                        fill="none"
                        key={`line:${i}:${ic}`}
                        stroke={COLORS.WORKFLOW_EDITOR_LINE_COLOR}
                        strokeWidth="1"
                      />
                    )
                  })
                }

                return null
              })}
            </svg>
          </FlexibleCanvas>
        </Layout>
        {isEditingStep && <WorkflowSidebarEdit />}
      </WorkflowWrapper>
      <Modal
        maxWidth={600}
        minHeight={100}
        onClose={() => setIsAddToWorkflowModalOpen(false)}
        open={isAddToWorkflowModalOpen}
        variant={MODAL_VARIANTS.ROUNDED}
      >
        <Modal.Header>
          <Modal.Header.Center>{t('automations.addToWorkflow')}</Modal.Header.Center>
          <Modal.Header.Right onClose={() => setIsAddToWorkflowModalOpen(false)}>
            <CloseIcon color="#666" style={{ width: 12, height: 12 }} />
          </Modal.Header.Right>
        </Modal.Header>
        <Modal.Body>
          <Layout padding="24px">
            <StyledModalHeading>{t('automations.steps')}</StyledModalHeading>

            {[
              WORKFLOW_CARDS.SLEEP,
              isWorkflowLogicStepEnabled && WORKFLOW_CARDS.LOGIC,
              WORKFLOW_CARDS.SEND_MESSAGE,
              WORKFLOW_CARDS.ADD_TO_COMMUNITY,
            ]
              .filter((card): card is WorkflowCardConfig => Boolean(card))
              .map((card, i) => {
                return (
                  <StyledWorkflowCard
                    backgroundColor={card.backgroundColor}
                    borderColor={card.borderColor}
                    icon={card.icon}
                    iconColor={card.iconColor}
                    key={i}
                    onClick={() => {
                      addToWorkflow(
                        card.type,
                        functionKeyToAdd as string,
                        functionIndexToAdd as number,
                        logicIndexToAdd,
                      )
                      setIsAddToWorkflowModalOpen(false)
                    }}
                    subtitle={card.subtitle}
                    title={card.title}
                  />
                )
              })}
          </Layout>
        </Modal.Body>
      </Modal>

      {isDeleteLogicDialogOpen && (
        <Dialog
          maxWidth={600}
          message={
            <Layout textAlign="left">
              <Typography display="block" fontSize="14px" marginBottom="20px">
                {t('automations.selectFlowToFollow')}
              </Typography>

              {functionKeyToDelete &&
                typeof functionIndexToDelete === 'number' &&
                manifest.functions[functionKeyToDelete][functionIndexToDelete] &&
                isLogic(manifest.functions[functionKeyToDelete][functionIndexToDelete]) &&
                Array.from(
                  traverseLogic(
                    manifest.functions[functionKeyToDelete][functionIndexToDelete] as ManifestFunctionLogic,
                  ),
                )
                  .filter(({ type }) => type === 'condition' || type === 'else')
                  .map(({ value, type, index }, i) => {
                    const memberDataFilters =
                      value === null || typeof value === 'string' ? null : jsonLogicToMemberDataFilter(value)

                    if (type === 'else') {
                      const pathName = t('automations.keepElsePath')

                      return (
                        <StyledRadioWrapper>
                          <Radio
                            checked={logicIndexToKeep === index}
                            id={pathName}
                            name="entryTimes"
                            onChange={() => setLogicIndexToKeep(index)}
                            style={{ marginRight: 12 }}
                            value={pathName}
                          />
                          <StyledRadioLabel htmlFor={pathName}>
                            <strong>{pathName}</strong>
                          </StyledRadioLabel>
                        </StyledRadioWrapper>
                      )
                    }

                    if (type === 'condition') {
                      const pathName = t('automations.keepPath', { index: i + 1 })

                      return (
                        <StyledRadioWrapper>
                          <Radio
                            checked={logicIndexToKeep === index + 1}
                            id={pathName}
                            name="entryTimes"
                            onChange={() => setLogicIndexToKeep(index + 1)}
                            style={{ marginRight: 12 }}
                            value={pathName}
                          />
                          <StyledRadioLabel htmlFor={pathName}>
                            <strong>{pathName}</strong>:{' '}
                            {(memberDataFilters && humanizeMemberDataFilterShortened(memberDataFilters)) ||
                              '(no condition)'}
                          </StyledRadioLabel>
                        </StyledRadioWrapper>
                      )
                    }

                    return null
                  })}

              <StyledRadioWrapper>
                <Radio
                  checked={logicIndexToKeep === -1}
                  id={t('automations.deleteAllPaths')}
                  name="entryTimes"
                  onChange={() => setLogicIndexToKeep(-1)}
                  style={{ marginRight: 12 }}
                  value={t('automations.deleteAllPaths')}
                />
                <StyledRadioLabel htmlFor={t('automations.deleteAllPaths')}>
                  <strong>{t('automations.deleteAllPaths')}</strong>
                </StyledRadioLabel>
              </StyledRadioWrapper>
            </Layout>
          }
          onCancel={() => setIsDeleteLogicDialogOpen(false)}
          title={t('automations.deleteSplitConfirmation')}
        >
          <Dialog.Action onClick={() => setIsDeleteLogicDialogOpen(false)} variant={DIALOG_VARIANTS.EMPHASIZED}>
            {t('cancel')}
          </Dialog.Action>
          <Dialog.Action
            disabled={typeof logicIndexToKeep === 'undefined'}
            onClick={(e) => {
              e.stopPropagation()
              deleteStepAndRedirect(
                functionKeyToDelete as string,
                functionIndexToDelete as number,
                logicIndexToKeep === -1 ? undefined : logicIndexToKeep,
              )
              setIsDeleteLogicDialogOpen(false)
            }}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            {t('delete')}
          </Dialog.Action>
        </Dialog>
      )}
    </StyledContainer>
  )
}
