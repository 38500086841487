import { BUTTON_VARIANTS, Button, SPACING, Toast } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledForm = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;

  > ${Toast} {
    max-width: 100%;
    width: auto;
  }
`

export const StyledModalBody = styled.div`
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 24px 40px;

  border-radius: 0px 0px 3px 3px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0;

  & img {
    object-fit: contain;
    align-self: flex-start;
  }
`

export const MessageTips = styled.ol`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  padding: 0;
`

export const MessageHint = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  padding: 0;
`

type StyledContentProps = {
  $submitting?: boolean
}

export const StyledContent = styled.div<StyledContentProps>`
  opacity: ${({ $submitting }) => ($submitting ? 0.6 : 1)};

  > ${Toast} {
    max-width: 100%;
    width: auto;
    margin: ${SPACING[4]} 40px;
  }
`

export const StyledParagraph = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

export const StyledInstructionTitle = styled.h5`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};

  display: flex;
  align-items: flex-end;
  letter-spacing: -0.096px;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

export const StyledCheckboxLabel = styled.label`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  margin-left: ${SPACING[1]};
  display: flex;
  align-items: center;
  margin-top: ${SPACING[5]};

  & > input {
    margin-right: ${SPACING[2]};
  }
`

export const StyledListIcon = styled.ul`
  padding: 0;
  li {
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
    list-style-type: none;
    display: flex;
    color: ${({ theme }) => theme?.COLORS?.TEXT};
    margin-bottom: ${SPACING[4]};
    & img {
      object-fit: contain;
      align-self: flex-start;
      margin-right: ${SPACING[2]};
    }
  }
`

export const StyledButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.OUTLINE,
})`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  padding: 12px;

  & a {
    color: ${({ theme }) => theme?.COLORS?.LINKS};
    text-decoration: none;
  }
`

export const StyledMultimedia = styled.div`
  width: 100%;
`
