import { ChartIcon, FeatureBanner, SPACING } from '@community_dev/pixels'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AgeChartBox } from '../boxes/AgeChartBox'
import { BestHourChartBox } from '../boxes/BestHourChartBox'
import { GenderChartBox } from '../boxes/GenderChartBox'
import { LocationChartBox } from '../boxes/LocationChartBox'
import { InsightsMasthead } from '../InsightsMasthead'

import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { useTrackBilling } from 'hooks/billing/useTrackBilling'
import { useHasCapability } from 'hooks/useUserCapability'
import events from 'utils/analytics/events'

// Define a grid that matches the design and collapses to a single column at
// 1124px. 1124px was chosen because it is the width at which the barchart
// labels begin to collapse to two lines.
const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${SPACING[5]};
  grid-row-gap: ${SPACING[5]};
  margin-top: ${SPACING[5]};
  @media screen and (max-width: 1124px) {
    grid-template-columns: 1fr;
  }
`

const StyledBanner = styled(FeatureBanner)`
  margin-bottom: ${SPACING[5]};
`

const StyledLinkWrapper = styled.a`
  text-decoration: inherit;
  color: inherit;
`

export type InsightsRootViewProps = {
  enabled?: boolean
  demographicInsights: any
}

export const General = ({ demographicInsights, enabled = true }: InsightsRootViewProps): JSX.Element | null => {
  const { t } = useTranslation()
  const track = useTrackBilling()
  const Wrapper = enabled ? React.Fragment : StyledLinkWrapper

  const userHasBestHourCapability = useHasCapability(CAPABILITIES.FEATURE.INSIGHTS.BEST_HOUR_OF_WEEK)

  const isBestHourLocked = !userHasBestHourCapability
  const BestHourLinkWrapper = isBestHourLocked ? StyledLinkWrapper : React.Fragment

  const bestHourLinkProps = !isBestHourLocked
    ? {}
    : {
        href: ROUTES.PRICING.CONTACT_US,
        onClick: () => track(events.FeatureHoldPrompt({ featureName: 'insights' })),
      }

  const linkProps = enabled
    ? {}
    : {
        href: ROUTES.PRICING.CONTACT_US,
        onClick: () => track(events.FeatureHoldPrompt({ featureName: 'insights' })),
      }

  return (
    <>
      {!enabled && (
        <ChartGrid>
          <StyledBanner
            headline={t('generalInsights.learnMoreAudience')}
            icon={<ChartIcon />}
            name={t('generalInsights.memberInsights')}
            {...linkProps}
          />
        </ChartGrid>
      )}
      <Wrapper {...linkProps}>
        <InsightsMasthead locked={!enabled} />
      </Wrapper>
      <ChartGrid>
        <Wrapper key="location-chart-box" {...linkProps}>
          <LocationChartBox demographicInsights={demographicInsights} locked={!enabled} />
        </Wrapper>
        <Wrapper key="age-chart-box" {...linkProps}>
          <AgeChartBox demographicInsights={demographicInsights} locked={!enabled} />
        </Wrapper>
        <Wrapper key="gender-chart-box" {...linkProps}>
          <GenderChartBox demographicInsights={demographicInsights} locked={!enabled} />
        </Wrapper>
        <BestHourLinkWrapper key="best-hour-chart-box" {...bestHourLinkProps}>
          <BestHourChartBox isLocked={isBestHourLocked} />
        </BestHourLinkWrapper>
      </ChartGrid>
    </>
  )
}
