import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { StyledNotifBody, StyledNotifIcon, StyledNotifMain, StyledNotifRoot } from './Notif.style'

const iconMap = {
  danger: 'exclamation-circle',
  warn: 'exclamation-triangle',
  info: 'info-circle',
  success: 'check-circle',
}

type NotifProps = {
  body?: React.ReactNode
  className?: string
  level?: 'success' | 'info' | 'warn' | 'danger'
}

const Notif = (props: NotifProps): JSX.Element => {
  const { level, body } = props
  const icon = iconMap[level as string]

  return (
    <StyledNotifRoot>
      <StyledNotifMain>
        {icon && (
          <StyledNotifIcon>
            <FontAwesomeIcon icon={icon} />
          </StyledNotifIcon>
        )}
        <StyledNotifBody>{body}</StyledNotifBody>
      </StyledNotifMain>
    </StyledNotifRoot>
  )
}

export default Notif
