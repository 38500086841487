import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { forwardRef } from 'react'
import { useTheme } from 'styled-components'
import styled from 'styled-components'

type StyledCommunityDotProps = {
  $color: string
  $size: number
}

const StyledCommunityDot = styled.div<StyledCommunityDotProps>`
  position: relative;
  background: ${({ $color }) => $color};
  border: none;
  padding: 0;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ $size }) => $size / 2}px;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;
`

const StyledBadge = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`

type CommunityDotProps = {
  color: string
  icon?: string
  size?: number
  badge?: React.ReactNode
  onClick?: (...args: any[]) => any
}

export const CommunityDot = forwardRef<HTMLDivElement, CommunityDotProps>(
  ({ color, icon, badge, size = 32, onClick, ...rest }, ref): JSX.Element => {
    const { COLORS } = useTheme() || {}
    return (
      <StyledCommunityDot $color={color} $size={size} onClick={onClick} ref={ref} role="presentation" {...rest}>
        {icon && (
          <FontAwesomeIcon
            color={COLORS?.APP_BACKGROUND_LEVEL_3}
            icon="star"
            style={{ fontSize: size * 0.55, position: 'relative', top: '-1px' }}
          />
        )}
        {badge && <StyledBadge>{badge}</StyledBadge>}
      </StyledCommunityDot>
    )
  },
)
