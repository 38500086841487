import ContentLoader from 'react-content-loader'
import styled, { useTheme } from 'styled-components'

const StyledWrapper = styled.div`
  position: relative;
  height: 29px;
  overflow: visible;

  > * {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export function SearchDatePickerLoadingIndicator(): JSX.Element {
  const { COLORS } = useTheme() || {}
  return (
    <StyledWrapper>
      <svg fill="none" height="32" viewBox="0 0 258 32" width="258" xmlns="http://www.w3.org/2000/svg">
        <rect height="31" rx="15.5" stroke={COLORS?.BORDERS} width="257" x="0.5" y="0.5" />
        <rect fill={COLORS?.APP_BACKGROUND_LEVEL_3} height="31" rx="15.5" width="257" x="0.5" y="0.5" />
      </svg>
      <ContentLoader
        backgroundColor={COLORS?.APP_BACKGROUND_LEVEL_2}
        foregroundColor={COLORS?.APP_BACKGROUND_LEVEL_1}
        height={32}
        speed={2}
        viewBox="0 0 257 32"
        width={257}
      >
        <rect height="16" rx="4" width="16" x="12" y="8" />
        <rect height="16" rx="4" width="36" x="44" y="8" />
        <rect height="16" rx="4" width="30" x="96" y="8" />
        <rect height="16" rx="4" width="24" x="142" y="8" />
        <rect height="16" rx="4" width="24" x="182" y="8" />
        <rect height="16" rx="4" width="24" x="222" y="8" />
      </ContentLoader>
    </StyledWrapper>
  )
}
