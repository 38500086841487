import {
  BuiltInFields,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorOperators,
  fieldLabelFor,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { RecipientsActionPanel } from '../../RecipientsActionPanel'
import { SelectionActionPanel } from '../../SelectionActionPanel'

import { Fan } from 'api/fans'
import { AddToCommunityActionPanel } from 'components/ActionPanel'
import { MemberDetailActionPanelRouter } from 'containers/InboxList/MemberDetailActionPanel/MemberDetailActionPanelRouter'
import { useCampaignResponseFilterState } from 'contexts/CampaignResponseFilterProvider'
import { useIncludeExcludeContext } from 'contexts/IncludeExcludeProvider'
import { Selection } from 'contexts/IncludeExcludeProvider/IncludeExcludeState'
import { useCurrentCampaignDetails } from 'hooks/useCampaign'

type SentActionPanelProps = {
  onClickDeselect?(): void
  selectedDetailMember?: Fan
  setSelectedDetailMember?(member: Fan | undefined): void
  communicationChannel?: CommunicationChannel
}

export function SentActionPanel({
  onClickDeselect,
  selectedDetailMember,
  setSelectedDetailMember,
  communicationChannel,
}: SentActionPanelProps): JSX.Element | null {
  const [isAddToCommunityOpen, setIsAddToCommunityOpen] = useState<boolean>(false)
  const location = useLocation()
  const { campaignId: pathCampaignId, id }: any = useParams()
  const campaignId = pathCampaignId || id
  const {
    filterCount: selectionCount,
    isLoadingFilterCount: isLoadingSelectionCount,
    filters,
    campaignFollowUpFilter,
  } = useCampaignResponseFilterState()
  const { data: campaignDetails } = useCurrentCampaignDetails()
  const { recipientCount = 0 } = campaignDetails || {}
  const { isRootSelected } = useIncludeExcludeContext()
  const selectAllCheckboxState = isRootSelected()

  useEffect(() => {
    setIsAddToCommunityOpen(false)
  }, [location])

  function handleToggleAddToCommunity() {
    setIsAddToCommunityOpen((prev) => !prev)
  }

  const hasAnySelection = selectAllCheckboxState !== Selection.NOT_SELECTED || campaignFollowUpFilter

  // any cluster or individual recipient selection is not supported
  const addToCommunityFilters =
    campaignId && !hasAnySelection
      ? {
          operand: {
            type: FieldTypes.UUID,
            value: campaignId,
            source: FieldSources.BUILT_IN,
            field_key: BuiltInFields.CAMPAIGN_RECIPIENTS,
            field_label: fieldLabelFor(BuiltInFields.CAMPAIGN_RECIPIENTS),
          },
          operator: SelectorOperators.QUERY,
        }
      : filters

  const onSelectedDetailMemberClose = () => setSelectedDetailMember?.(undefined)

  if (selectedDetailMember) {
    return <MemberDetailActionPanelRouter fan={selectedDetailMember} onBack={onSelectedDetailMemberClose} />
  }

  return isAddToCommunityOpen ? (
    <AddToCommunityActionPanel
      communicationChannel={communicationChannel}
      filters={addToCommunityFilters as MemberDataFilter}
      onBack={handleToggleAddToCommunity}
      recipientCount={hasAnySelection ? selectionCount : recipientCount}
    />
  ) : hasAnySelection ? (
    <SelectionActionPanel
      communicationChannel={communicationChannel}
      onClickAddToCommunity={handleToggleAddToCommunity}
      onClickDeselect={onClickDeselect}
      selectionCount={selectionCount}
      selectionCountIsLoading={isLoadingSelectionCount}
    />
  ) : campaignId ? (
    <RecipientsActionPanel campaignId={campaignId} onClickAddToCommunity={handleToggleAddToCommunity} />
  ) : null
}
