import { darken } from 'polished'
import ContentLoader from 'react-content-loader'
import { useTheme } from 'styled-components'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  position: relative;
  height: 44px;
`

const StyledBar = styled.div`
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  height: 44px;
  display: flex;
  justify-content: space-between;
`

export function SearchActionBarLoader(): JSX.Element {
  const { COLORS } = useTheme() || {}
  return (
    <StyledWrapper>
      <StyledBar>
        <ContentLoader
          backgroundColor={COLORS?.APP_BACKGROUND_LEVEL_1}
          foregroundColor={darken(0.05, COLORS?.APP_BACKGROUND_LEVEL_1)}
          height={44}
          speed={2}
          viewBox="0 0 340 44"
          width={340}
        >
          <rect fill={COLORS?.APP_BACKGROUND_LEVEL_1} height="16" rx="4" width="16" x="14" y="14" />
          <rect fill={COLORS?.APP_BACKGROUND_LEVEL_1} height="16" rx="4" width="82" x="42" y="14" />
          <path d="M137.062 13.5369H135.73V30.2812H137.062V13.5369Z" fill={COLORS?.DIVIDERS} />
          <rect fill="white" height="31" rx="15.5" width="83" x="151.5" y="6.5" />
          <rect fill={COLORS?.APP_BACKGROUND_LEVEL_1} height="16" rx="4" width="16" x="163" y="14" />
          <rect fill={COLORS?.APP_BACKGROUND_LEVEL_1} height="16" rx="4" width="36" x="187" y="14" />
          <rect height="31" rx="15.5" stroke="#DADADA" width="83" x="151.5" y="6.5" />
          <rect fill="white" height="31" rx="15.5" width="83" x="247.5" y="6.5" />
          <rect fill={COLORS?.APP_BACKGROUND_LEVEL_1} height="16" rx="4" width="16" x="259" y="14" />
          <rect fill={COLORS?.APP_BACKGROUND_LEVEL_1} height="16" rx="4" width="36" x="283" y="14" />
          <rect height="31" rx="15.5" stroke="#DADADA" width="83" x="247.5" y="6.5" />
        </ContentLoader>

        <ContentLoader
          backgroundColor={COLORS?.APP_BACKGROUND_LEVEL_1}
          foregroundColor={darken(0.05, COLORS?.APP_BACKGROUND_LEVEL_1)}
          height={44}
          speed={2}
          viewBox="0 0 340 44"
          width={340}
        >
          <rect fill={COLORS?.APP_BACKGROUND_LEVEL_1} height="16" rx="4" width="82" x="194" y="14" />
          <rect fill={COLORS?.APP_BACKGROUND_LEVEL_1} height="16" rx="4" width="16" x="284" y="14" />
          <rect fill={COLORS?.APP_BACKGROUND_LEVEL_1} height="16" rx="4" width="16" x="308" y="14" />
        </ContentLoader>
      </StyledBar>
    </StyledWrapper>
  )
}
