import { ApiError } from '@community_dev/requests'
import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import differenceBy from 'lodash/differenceBy'
import numeral from 'numeral'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useCommunityKeywords } from './useCommunityKeywords'
import { useInvalidateCommunityCount } from './useCountByQuery/useCommunityCount'
import { userHasFlag } from './useLaunchDarkly'
import { useToastMessage } from './useToastMessage'

import {
  Communities,
  Community,
  CommunityById,
  CreateCommunityProps,
  UpdateCommunityProps,
  getAllCommunities,
  getCommunities,
  getCommunity,
  postCreateCommunity,
  putUpdateCommunity,
} from 'api/community'
import { Fan } from 'api/fans'
import { removeCommunityKeyword } from 'api/keyword-communities'
import { COMMUNITY_SORT_BY_MEMBER_COUNT } from 'constants/launch-darkly-flags'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useAddFansToCommunity } from 'containers/InboxList/MemberDetailActionPanel/member-detail-queries'
import { useClientId } from 'hooks/useClient'
import analytics from 'utils/analytics'
import { NormalizedKeyword, normalizeKeywords } from 'utils/normalize'

export function useCommunity<SelectData = CommunityById>(
  id?: string,
  options: UseQueryOptions<CommunityById, ApiError, SelectData> = {},
): UseQueryResult<SelectData, ApiError> {
  const clientId = useClientId()
  return useQuery({
    queryKey: [QUERY_CACHE.TAGS, id],
    queryFn: () => getCommunity({ clientId, id: id || '' }).then(({ data }) => data),
    enabled: Boolean(id),
    ...options,
  })
}

export type CreateCommunity = CreateCommunityProps & {
  fans?: Fan[]
  keywords?: Omit<NormalizedKeyword, 'id'>[]
  autoResponderText?: string
  includeFansRetroactively?: boolean
}

export function useCreateCommunity(
  options: UseMutationOptions<Community, ApiError, CreateCommunity> = {},
): UseMutationResult<Community, ApiError, CreateCommunity> {
  const queryClient = useQueryClient()
  const { showToastMessage } = useToastMessage()
  const { t } = useTranslation()
  const { addFans } = useAddFansToCommunity()
  const { addKeywordToCommunity, upsertKeywordResponder } = useCommunityKeywords()
  const clientId = useClientId()
  return useMutation({
    mutationFn: async ({
      fans,
      includeFansRetroactively,
      keywords,
      autoResponderText,
      ...updates
    }: CreateCommunity) => {
      const community = await postCreateCommunity(updates)
      if (fans && fans.length > 0) {
        await addFans({ community, fans })
      }
      if (keywords && keywords.length > 0) {
        await Promise.all(
          keywords.map((keyword) =>
            addKeywordToCommunity({
              community,
              includeFansRetroactively: Boolean(includeFansRetroactively),
              keyword,
            }),
          ),
        )
        if (autoResponderText) {
          await Promise.all(keywords.map((keyword) => upsertKeywordResponder({ keyword, autoResponderText })))
        }
      }

      return community
    },
    onSuccess: (community, { includeFansRetroactively }) => {
      //Sets the new community in the cache. This allows us to route to it after creating it
      queryClient.setQueryData([QUERY_CACHE.TAGS, { clientId }], (old: { all: Community[] } | undefined) => ({
        ...old,
        all: (old?.all || []).concat(community),
      }))
      //Invalidates the cache so the new community is fetched in the sidebar
      queryClient.invalidateQueries([QUERY_CACHE.TAGS, { clientId }])

      showToastMessage({
        message: t('communities.success.createdCommunity', { title: community.title }),
        success: true,
      })
      analytics.track(analytics.events.CommunityCreated(community, includeFansRetroactively))
    },
    onError: (err, { title }) => {
      showToastMessage({
        message: t('communities.error.createdCommunity', { title }),
        success: false,
      })
    },
    ...options,
  })
}

export type UpdateCommunity = UpdateCommunityProps & {
  fans?: Fan[]
  oldKeywords?: { id: string; originalKeyword: string }[]
  newKeywords?: Omit<NormalizedKeyword, 'id'>[]
  unsetAutoResponder?: boolean
  autoResponderText?: string
  includeFansRetroactively?: boolean
  redirectOnCreate?: boolean
}

export function useUpdateCommunity(
  options: UseMutationOptions<Community, ApiError, UpdateCommunity> = {},
): UseMutationResult<Community, ApiError, UpdateCommunity> {
  const queryClient = useQueryClient()
  const clientId = useClientId()
  const { showToastMessage } = useToastMessage()
  const { t } = useTranslation()
  const { addFans } = useAddFansToCommunity()
  const { data: communities } = useCommunities()
  const { addKeywordToCommunity, upsertKeywordResponder, removeKeywordResponder } = useCommunityKeywords()
  const invalidateCommunityMemberCount = useInvalidateCommunityCount()
  return useMutation({
    mutationFn: async ({
      fans,
      oldKeywords,
      newKeywords,
      autoResponderText,
      unsetAutoResponder,
      includeFansRetroactively,
      ...updates
    }: UpdateCommunity) => {
      const keywordsAdded = differenceBy(newKeywords, normalizeKeywords(oldKeywords), 'word')
      const keywordsRemoved = differenceBy(normalizeKeywords(oldKeywords), newKeywords || [], 'word')
      let community = communities?.all?.find((c) => c.id === updates.id) as Community

      if (community?.type === 'custom' && (updates.color !== community.color || updates.title !== community.title)) {
        community = await putUpdateCommunity(updates)
        analytics.track(analytics.events.CommunityUpdated(community))
      }
      if (fans && fans.length > 0) {
        await addFans({ community, fans })
        analytics.track(analytics.events.CommunityMembersAdded(community, fans.length))
      }
      if (keywordsAdded && keywordsAdded.length > 0) {
        await Promise.all(
          keywordsAdded.map((keyword) =>
            addKeywordToCommunity({ keyword, community, includeFansRetroactively: Boolean(includeFansRetroactively) }),
          ),
        )
      }
      if (newKeywords) {
        if (autoResponderText && !unsetAutoResponder) {
          await Promise.all(newKeywords.map((keyword) => upsertKeywordResponder({ keyword, autoResponderText })))
        }
        if (unsetAutoResponder) {
          await Promise.all(newKeywords.map((keyword) => removeKeywordResponder({ keyword })))
        }
      }
      if (keywordsRemoved && keywordsRemoved.length > 0) {
        await Promise.all(
          keywordsRemoved.map(({ id }) =>
            removeCommunityKeyword({
              communityId: community.id,
              keywordId: id,
            }),
          ),
        )
      }
      return community
    },
    onMutate({ id, ...updates }) {
      queryClient.cancelQueries([QUERY_CACHE.TAGS, id])
      const previousValues: Partial<Community> | undefined = queryClient.getQueryData([QUERY_CACHE.TAGS, id])
      queryClient.setQueryData([QUERY_CACHE.TAGS, id], (old: Partial<Community> | undefined) => ({
        ...old,
        ...updates,
      }))
      return () => queryClient.setQueryData([QUERY_CACHE.TAGS, id], previousValues)
    },
    onSuccess: (data, community) => {
      const { id, fans, title } = community
      queryClient.invalidateQueries([QUERY_CACHE.TAGS, id])
      queryClient.invalidateQueries([QUERY_CACHE.TAGS, id, clientId])
      queryClient.invalidateQueries([QUERY_CACHE.TAGS])
      queryClient.invalidateQueries([QUERY_CACHE.AUDIENCE.INDEX_BY_QUERY, { tags: [id] }])
      invalidateCommunityMemberCount(id, title)

      showToastMessage({
        message:
          fans && fans?.length > 0
            ? t('communities.success.fansAdded', { addedCount: numeral(fans.length).format('0,0'), title: data.title })
            : t('communities.success.updatedCommunity', { title: data.title }),
        success: true,
      })
    },
    onError: (err, { title }, rollback: any) => {
      rollback()
      showToastMessage({
        message: t('communities.error.updatedCommunity', { title }),
        success: false,
      })
    },
    ...options,
  })
}

export function useCommunities<SelectData = Communities>(
  options: UseQueryOptions<Communities, ApiError, SelectData> = {},
): UseQueryResult<SelectData, ApiError> {
  const clientId = useClientId()
  const isSortByMemberCountEnabled = userHasFlag(COMMUNITY_SORT_BY_MEMBER_COUNT)
  return useQuery({
    queryKey: [QUERY_CACHE.TAGS, { clientId }],
    queryFn: () => (isSortByMemberCountEnabled ? getAllCommunities({ clientId }) : getCommunities({ clientId })),
    enabled: !!clientId,
    staleTime: STALE_TIME.ONE_MINUTE,
    ...options,
  })
}

export function useCommunityLookup(): Record<string, Community> {
  const { data: communities = { all: [] } } = useCommunities()

  const communityLookup = useMemo(
    () => Object.fromEntries(communities.all.map((community) => [community.id, community])),
    [communities.all],
  )

  return communityLookup
}
