import {
  BORDER_RADIUS,
  Button,
  FONT_SIZE,
  FONT_WEIGHT,
  Layout,
  SPACING,
  TABLET_BREAK,
  Tabs,
} from '@community_dev/pixels'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const maxContentWidth = 896
const minMargin = 32
const maxLeftMargin = 160

export const StyledResponsiveWrapper = styled.div`
  max-width: ${maxContentWidth + 2 * minMargin}px;
  padding: 60px ${minMargin}px ${minMargin}px;
  margin: auto;
  position: relative;
  height: 100%;
`

export const StyledPinLeftWrapper = styled.div`
  max-width: ${maxContentWidth + 2 * maxLeftMargin}px;
  position: relative;
  height: 100%;
`

export const StyledScrollContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`

export const StyledGridLayout = styled.div`
  width: 100%;
  display: grid;
  grid-gap: ${SPACING[5]};

  @media (min-width: ${TABLET_BREAK}) {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - ${SPACING[5]}), 1fr));
  }
`

export const StyledTabs = styled(Tabs)`
  button {
    font-weight: ${FONT_WEIGHT[6]};
    font-size: ${FONT_SIZE[3]};
  }
`

export const StyledColumn = styled(Layout)`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  margin-bottom: ${SPACING[5]};
`

export const StyledSection = styled.section`
  padding-bottom: ${SPACING[6]};

  @media (min-width: ${TABLET_BREAK}) {
    :last-of-type {
      padding-bottom: ${SPACING[9]};
    }
  }
`
export const CardCss = css`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: ${BORDER_RADIUS[2]};
  padding: ${SPACING[3]} ${SPACING[5]} ${SPACING[5]};
  margin-bottom: ${SPACING[5]};
  height: 100%;

  @media (min-width: ${TABLET_BREAK}) {
    margin: 0;
  }
`

export const StyledCard = styled.div`
  ${CardCss}
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  text-decoration: none;
  text-transform: capitalize;

  :hover {
    text-decoration: underline;
  }
`

export const StyledRouterLink = styled(Link)`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  text-decoration: none;
  text-transform: capitalize;
  width: fit-content;

  :hover {
    text-decoration: underline;
  }
`

export const StyledHelpList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    margin-bottom: ${SPACING[4]};

    svg {
      margin-right: ${SPACING[4]};
    }
  }

  @media (min-width: ${TABLET_BREAK}) {
    display: flex;
    justify-content: space-between;
  }
`

export const StyledHelpLink = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme?.COLORS?.HEADLINES};
  text-decoration: none;
  text-transform: capitalize;

  :hover {
    text-decoration: underline;
  }
`

export const StyledInput = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: ${BORDER_RADIUS[2]};
  margin-top: ${SPACING[4]};
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};

  @media (min-width: ${TABLET_BREAK}) {
    margin: 0;
  }
`

export const StyledImage = styled.img`
  width: 134px;
  max-width: 100%;
`

export const StyledNumberButton = styled(Button)`
  flex-shrink: 0;
  padding-left: ${SPACING[5]};
  padding-right: ${SPACING[5]};

  @media (min-width: ${TABLET_BREAK}) {
    margin-left: ${SPACING[5]};
  }
`

export const StyledActionButton = styled(Button)`
  font-size: 14px;
  color: ${({ theme }) => theme?.COLORS?.TEXT};

  &:not(:first-of-type) {
    margin-top: 6px;
  }
`

export const StyledNumberContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (min-width: ${TABLET_BREAK}) {
    flex-wrap: nowrap;
  }
`

export const StyledDivider = styled.hr`
  border-style: initial;
  border-top: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  margin: ${SPACING[4]} 0;
`
