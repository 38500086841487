import { Badge, SPACING } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

const StyledBadge = styled(Badge)`
  vertical-align: middle;
  font-size: 10px;
  letter-spacing: 0.16px;
  padding: ${SPACING[1]} ${SPACING[2]};
`

export const BetaBadge = (): JSX.Element => {
  const { t } = useTranslation()
  const { COLORS } = useTheme()

  return <StyledBadge color={COLORS.TEXT}>{t('beta')}</StyledBadge>
}
