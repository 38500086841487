import { TextInput, Toast, composeValidators, required, url } from '@community_dev/pixels'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Field, Form } from 'react-final-form'

import privateAppDetailsImage from '../../assets/private-app-details.png'
import { StyledContent, StyledForm, StyledParagraph } from '../styled'

import { postShopifyStoreDetails } from 'api/shopify'
import { QUERY_CACHE } from 'constants/query-cache'
import { VisualFormSection } from 'containers/Settings/components/Integrations/VisualFormSection'
import analytics from 'utils/analytics'
import { parseUrl } from 'utils/link'

type Detail = {
  detail: string
}

type Error = {
  errors: Detail[]
}

type Props = {
  onPrimaryButtonClick: any
  onBackButtonClick: any
  clientId: string
  setEnabled: any
  client: any
}

// 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
const VALID_SHOPIFY_ADMIN_SECRET = new RegExp(/^shpat_[-A-Za-z0-9+=]{1,50}|=[^=]|={3,}$/)
const shopifyAdminSecret = (value) => (VALID_SHOPIFY_ADMIN_SECRET.test(value) ? undefined : 'is invalid')

export const EnterPrivateDetails = (props: Props): JSX.Element => {
  const queryClient = useQueryClient()
  const { mutateAsync: submitStoreDetails } = useMutation(postShopifyStoreDetails)

  const [error, setError] = useState<any>()

  async function handleConnectStore(values) {
    const { hostname } = parseUrl(values.store_url) || {}

    return submitStoreDetails({
      clientId: props.clientId,
      body: {
        store_name: hostname,
        api_key: values.api_key,
        api_password: values.admin_api_access_token,
        webhook_signing_secret: values.api_secret_key,
        first_name: props.client.firstName,
        last_name: props.client.lastName,
      },
    })
      .then(() => {
        props.setEnabled(true)
        queryClient.invalidateQueries([QUERY_CACHE.TAGS])
        queryClient.invalidateQueries([QUERY_CACHE.SHOPIFY.STORE_DETAILS, { clientId: props.clientId }])
        queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
        props.onPrimaryButtonClick()
      })
      .catch((error: Error) => {
        setError({
          message: 'Could Not Be Created',
          subtext: error.errors[0].detail,
        })
      })
  }
  return (
    <Form
      onSubmit={handleConnectStore}
      render={({ handleSubmit, valid, submitting }) => (
        <VisualFormSection
          backButton="Back"
          backButtonClick={props.onBackButtonClick}
          image={privateAppDetailsImage}
          primaryButton={submitting ? 'Connecting...' : 'Continue'}
          primaryButtonClick={handleSubmit}
          primaryButtonDisabled={!valid || submitting}
          title="Enter Custom App Details"
        >
          <div>
            <StyledParagraph>
              Find your <b>Store URL, API Key, API Secret Key</b> and <b>Admin API access token</b> in the settings of
              your new ‘custom app’ (found in Shopify).
              <br />
              <br /> Need help? See instructions:{' '}
              <a
                href="https://incommunity.zendesk.com/hc/en-us/articles/1500002146762"
                onClick={() => analytics.track(analytics.events.ShopifyLearnMore())}
                rel="noreferrer noopener"
                target="_blank"
              >
                Connect Shopify Store.
              </a>
            </StyledParagraph>
            <form>
              <StyledContent $submitting={submitting}>
                {error && <Toast icon message={error.message} subtext={error.subtext} success={false} />}
                <StyledForm>
                  <Field name="store_url" validate={composeValidators(required, url)} validateOnBlur>
                    {({ input, meta }) => (
                      <TextInput
                        autoFocus
                        error={(!meta.active && meta.touched && meta.modified && meta.error) || ''}
                        label="Store URL"
                        type="text"
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name="api_key" validate={composeValidators(required)} validateOnBlur>
                    {({ input, meta }) => (
                      <TextInput
                        error={(!meta.active && meta.touched && meta.modified && meta.error) || ''}
                        label="API Key"
                        type="text"
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name="api_secret_key" validate={required} validateOnBlur>
                    {({ input, meta }) => (
                      <TextInput
                        error={(!meta.active && meta.touched && meta.modified && meta.error) || ''}
                        label="API Secret Key"
                        type="text"
                        {...input}
                      />
                    )}
                  </Field>

                  <Field
                    name="admin_api_access_token"
                    validate={composeValidators(required, shopifyAdminSecret)}
                    validateOnBlur
                  >
                    {({ input, meta }) => (
                      <TextInput
                        error={(!meta.active && meta.touched && meta.modified && meta.error) || ''}
                        label="Admin API access token"
                        type="text"
                        {...input}
                      />
                    )}
                  </Field>
                </StyledForm>
              </StyledContent>
            </form>
          </div>
        </VisualFormSection>
      )}
    />
  )
}
