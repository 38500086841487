import { useMessageBillingUsage } from '@community_dev/hooks'
import { DIALOG_VARIANTS, Dialog, Modal } from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import { useMutation } from '@tanstack/react-query'
import differenceBy from 'lodash/differenceBy'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'
import { CamelCasedPropertiesDeep } from 'type-fest'

import KeywordsEditor from './KeywordsEditor'
import { BannerActiveText, BannerActiveTextWrap, Link, Paragraph, StyledCloseIcon } from './KeywordsModal.style'

import { validateCommunityKeyword } from 'api/keyword-communities'
import { MultimediaInput } from 'components/MultimediaInput'
import { AUTO_RESPONDER_MAX_LENGTH, MAX_KEYWORD_RESPONDERS, RESTRICTED_KEYWORDS } from 'constants/keywords'
import { ROUTES } from 'constants/routes'
import { useSettings } from 'contexts/SettingsProvider'
import { useKeywordResponders } from 'hooks/keywordResponders/useKeywordResponders'
import { useCommunityKeywords } from 'hooks/useCommunityKeywords'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import analytics from 'utils/analytics'
import { getError } from 'utils/errors'
import { NormalizedKeyword } from 'utils/normalize'

export type KeywordsCreateModalProps = {
  keywords: Omit<NormalizedKeyword, 'id'>[]
  onClose: () => void
  onSave?: ({
    keywords,
    keywordResponder,
  }: {
    keywords: Omit<NormalizedKeyword, 'id'>[]
    keywordResponder?: string
  }) => void
}

export const KeywordsCreateModal = ({
  keywords: initialKeywords = [],
  onClose,
  onSave,
}: KeywordsCreateModalProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const history = useHistory()
  const { formatKeyword } = useCommunityKeywords()
  const [showExitDialog, setShowExitDialog] = useState(false)
  const [exitDialogNext, setExitDialogNext] = useState<() => any | undefined>()
  const [keywords, setKeywords] = useState(initialKeywords)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const currentTheme = useCurrentTheme()
  const { settings } = useSettings()

  const { mutateAsync: validateKeyword } = useMutation(validateCommunityKeyword)

  const { data: keywordMessages = { data: [] } } = useKeywordResponders()

  const keywordsWithResponders = useMemo(
    () =>
      keywords
        .map((keyword) => ({
          ...keyword,
          response: keywordMessages.data.find(
            (keywordMessage) => formatKeyword(keywordMessage.keyword) === formatKeyword(keyword.word),
          ),
        }))
        .filter(
          (
            keyword,
          ): keyword is {
            response: CamelCasedPropertiesDeep<Api.KeywordResponder.Keyword>
            word: string
          } => keyword.response !== undefined,
        ),
    [formatKeyword, keywordMessages.data, keywords],
  )

  const areAllKeywordResponsesTheSame = useMemo(
    () =>
      keywordsWithResponders.every(
        (keywordResponse) =>
          keywordResponse.response?.text &&
          keywordResponse.response?.text === keywordsWithResponders[0]?.response?.text,
      ),
    [keywordsWithResponders],
  )

  const initialKeywordResponder = areAllKeywordResponsesTheSame ? keywordsWithResponders[0]?.response?.text || '' : ''

  const [keywordResponder, setKeywordResponder] = useState<string>(initialKeywordResponder)

  const { links } = useShortenableLinks(keywordResponder)
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = useMessageBillingUsage({
    linksToShorten: links,
    message: keywordResponder,
    charMax: AUTO_RESPONDER_MAX_LENGTH,
  })
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }

  const [showKeywordResponseField, setShowKeywordResponder] = useState<boolean>(
    areAllKeywordResponsesTheSame && keywordsWithResponders.length > 0,
  )

  const hasChanges = useMemo(() => {
    return (
      initialKeywordResponder !== keywordResponder ||
      initialKeywords.length !== keywords.length ||
      differenceBy(initialKeywords, keywords, 'word').length > 0
    )
  }, [initialKeywordResponder, initialKeywords, keywordResponder, keywords])

  const keywordResponseLimitReached = useMemo(() => {
    if (
      (keywordMessages.data.length || 0) >= MAX_KEYWORD_RESPONDERS ||
      keywords.length + (keywordMessages.data.length || 0) > MAX_KEYWORD_RESPONDERS
    ) {
      analytics.track(analytics.events.Community.MaxKeywordResponseReached())
      return true
    }

    return false
  }, [keywords, keywordMessages])

  const handleClose = useCallback(() => {
    const next = onClose
    if (hasChanges) {
      setExitDialogNext(() => next)
      setShowExitDialog(true)
    } else {
      next()
    }
  }, [hasChanges, onClose])

  const validateAndAddCommunityKeyword = async (keyword) => {
    const trimmed = String(keyword).trim()

    if (keyword && RESTRICTED_KEYWORDS.find((rkw) => rkw === trimmed.toLowerCase())) {
      setErrorMessage(t(`keywordsModal.restrictedKeywordError`, { keyword: trimmed }))
    } else if (keywords.length > 14) {
      setErrorMessage(t(`keywordsModal.tooManyError`))
    } else if (keywords.find((k) => k.word?.toLowerCase() === keyword?.toLowerCase())) {
      setErrorMessage(t(`keywordsModal.duplicateError`))
    } else {
      try {
        await validateKeyword({ keyword })
        setKeywords([{ word: keyword }, ...keywords])
      } catch (e) {
        const error = getError(e)
        setErrorMessage(t(error.message || error.detail))
      }
    }
  }

  return (
    <>
      {showExitDialog && (
        <Dialog message={t('yourChangesWillNotBeSaved')} title={t('areYouSureYouWantToLeave')}>
          <Dialog.Action
            onClick={() => {
              setShowExitDialog(false)
              setExitDialogNext(undefined)
            }}
          >
            {t('cancel')}
          </Dialog.Action>
          <Dialog.Action
            onClick={() => {
              setShowExitDialog(false)
              typeof exitDialogNext === 'function' && exitDialogNext()
              setExitDialogNext(undefined)
            }}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            {t('leave')}
          </Dialog.Action>
        </Dialog>
      )}
      <Modal
        css={`
          > div {
            max-width: 720px;
          }
        `}
        onClose={handleClose}
        open
      >
        <Modal.Header>
          <Modal.Header.Center>
            <div>{t(`keywordsModal.title`)}</div>
          </Modal.Header.Center>
          <Modal.Header.Right onClose={handleClose}>
            <StyledCloseIcon color={COLORS?.SUBTEXT} size={12} />
          </Modal.Header.Right>
        </Modal.Header>
        <Modal.Body>
          <KeywordsEditor
            autoFocus
            capacity={15}
            error={errorMessage}
            keywords={keywords}
            onAdd={validateAndAddCommunityKeyword}
            onChange={() => setErrorMessage('')}
            onClose={() => {
              onSave?.({
                keywords,
                keywordResponder: !keywordResponseLimitReached ? keywordResponder : undefined,
              })
              onClose()
            }}
            onDelete={(keyword) => setKeywords(keywords.filter((k) => k.word !== keyword.word))}
          >
            {keywords.length > 0 && !keywordResponseLimitReached && (
              <>
                {!areAllKeywordResponsesTheSame && keywordsWithResponders.length > 0 && (
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      const next = () => {
                        history.push(ROUTES.SETTINGS.CUSTOMIZE.ROOT)
                        analytics.track(analytics.events.Community.EditDifferentKeywordResponses())
                      }
                      if (hasChanges) {
                        setExitDialogNext(next)
                      } else {
                        next()
                      }
                    }}
                    style={{
                      marginTop: 12,
                      display: 'inline-block',
                    }}
                  >
                    {t('keywordsModal.editKeywordsInSettings')}
                  </Link>
                )}
                {!showKeywordResponseField && areAllKeywordResponsesTheSame && (
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      setShowKeywordResponder(true)
                      analytics.track(analytics.events.Community.AddKeywordResponse())
                    }}
                    style={{
                      textAlign: 'right',
                      marginTop: 20,
                      display: 'block',
                    }}
                  >
                    {t('keywordsModal.addAutomatedResponse')}
                  </Link>
                )}
                {showKeywordResponseField && (
                  <>
                    <BannerActiveTextWrap>
                      <BannerActiveText>{t('automatedKeywordResponse')}</BannerActiveText>

                      <StyledCloseIcon
                        aria-label={t('keywordsModal.cancelAutomatedResponse')}
                        onClick={() => {
                          setShowKeywordResponder(false)
                          setKeywordResponder('')
                        }}
                        role="button"
                      />
                    </BannerActiveTextWrap>

                    <Paragraph>{t('keywordsModal.automatedResponseInfoBubble')}</Paragraph>

                    <MultimediaInput
                      emojiSkinTone={settings.skinTone}
                      emojiTheme={currentTheme.type}
                      gallery={false}
                      gif={false}
                      onChange={(e) => setKeywordResponder(e.target.value)}
                      onEmojiSelect={(_, text) => setKeywordResponder(text)}
                      placeholder={t('automatedKeywordResponsePlaceholder')}
                      usageCounterProps={usageCounterProps}
                      value={keywordResponder}
                    />
                  </>
                )}
              </>
            )}
          </KeywordsEditor>
        </Modal.Body>
      </Modal>
    </>
  )
}
