import { SPACING } from '@community_dev/pixels'
import React from 'react'
import styled from 'styled-components'

import { UnitSwitcher } from 'screens/InsightsScreen/UnitSwitcher'

const TitleGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 ${SPACING[5]} 0;

  h1 {
    margin: 0 0;
  }
`

export const InsightsHeader = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  return (
    <TitleGrid>
      <div>{children}</div>
      <div>
        <UnitSwitcher />
      </div>
    </TitleGrid>
  )
}
