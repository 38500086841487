import { SPACING } from '@community_dev/pixels'
import isUndefined from 'lodash/isUndefined'
import styled from 'styled-components'

import {
  DESKTOP_BREAK,
  DESKTOP_LARGE_WITH_SIDEBAR_BREAK,
  DESKTOP_XL_WITH_SIDEBAR_BREAK,
  FLOATING_CONTAINER_LEFT_SPACING,
  FLOATING_CONTAINER_WIDTH,
} from 'constants/theme'

const StyledWrapper = styled.div`
  margin: 0 ${SPACING[4]} 0;

  @media (min-width: ${DESKTOP_XL_WITH_SIDEBAR_BREAK}) {
    margin-left: ${FLOATING_CONTAINER_LEFT_SPACING};
    max-width: ${FLOATING_CONTAINER_WIDTH};
  }

  @media (min-width: ${DESKTOP_LARGE_WITH_SIDEBAR_BREAK}) and (max-width: ${DESKTOP_XL_WITH_SIDEBAR_BREAK}) {
    margin: 0 auto;
    max-width: ${FLOATING_CONTAINER_WIDTH};
  }
`

const StyledFloatingContainer = styled.main<{ $top?: number }>`
  padding-top: ${SPACING[5]};
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
  padding-bottom: ${SPACING[4]};

  @media (min-width: ${DESKTOP_BREAK}) {
    padding-top: ${({ $top }) => (isUndefined($top) ? SPACING[5] : `${$top}px`)};
  }
`

export const StyledContainer = styled.div<{ $hasContainer: boolean }>`
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  border-radius: ${SPACING[4]};
  background-color: ${({ theme, $hasContainer }) =>
    $hasContainer ? theme?.COLORS?.APP_BACKGROUND_LEVEL_3 : 'initial'};
`

export function SidebarLayoutFloatingContainer({
  children,
  className,
  container = true,
  header,
  marginTop,
}: {
  children: React.ReactNode
  className?: string
  container?: boolean
  header?: React.ReactNode
  marginTop?: number
}): JSX.Element {
  return (
    <StyledFloatingContainer $top={marginTop} className={className}>
      <StyledWrapper>
        {header}
        <StyledContainer $hasContainer={container}>{children}</StyledContainer>
      </StyledWrapper>
    </StyledFloatingContainer>
  )
}
