const normalizeNewYork = (t) => {
  if (t === 'New York State') {
    return 'New York'
  }
  return t
}

export interface PlaceSuggestion {
  name: string
  type: string
  city: string
  state: string
  country: string
}

type Suggestion = {
  types: string[]
  description: string
  terms: {
    value: string
  }[]
}

export const transformPlaces = (suggestion: Suggestion): PlaceSuggestion => {
  const { types } = suggestion || {}
  let { description, terms } = suggestion || {}

  // Reformat New York State to New York for API.
  description = normalizeNewYork(description)
  terms = terms.map((t) => ({
    ...t,
    value: normalizeNewYork(t.value),
  }))

  const isCity = terms.length === 3 || types[0] === 'locality'
  const country = isCity && terms.length === 3 ? terms[2].value : terms[1].value
  const type = isCity ? 'city' : 'state'
  let name = description

  let state = ''
  if (terms.length === 3) {
    if (terms[2].value === 'USA') {
      name = `${terms[0].value}, ${terms[1].value}`
    }
    state = terms[1].value
  } else if (terms.length === 4) {
    if (terms[3].value === 'USA') {
      name = `${terms[0].value}, ${terms[1].value}, ${terms[2].value}`
    }
    state = terms[2].value
  } else {
    if (terms[1].value === 'USA') {
      name = terms[0].value
    }
    state = terms[0].value
  }

  return {
    name,
    type,
    city: isCity ? terms[0].value : '',
    state,
    country,
  }
}
