import { SPACING } from '@community_dev/pixels'
import { FanRegistrationCoreFieldName } from '@community_dev/types/lib/api/FanRegistration'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BarChart } from 'components/Charts'
import { useFanRegistrationField } from 'hooks/useClientSettings/useFanRegistrationField'
import { InsightsBox } from 'screens/InsightsScreen/presentation/InsightsBox'
import { UnitDisplay, formatUnits } from 'screens/InsightsScreen/UnitDisplay'
import { useUnitsPreference } from 'screens/InsightsScreen/use-units-preference'
import { formatNumeral } from 'utils/general'

// In the context of this chart (and the other charts), what exactly does
// "No age data yet" mean from a data standpoint.
//
// Some scenarios that come to mind where a "No data" message is appropriate:
//
// - the endpoint returns an empty response of `{age_gender_insights: []}`, indicating
//   that no spark jobs have run yet for this leader.
// - the endpoint returns a response where all age buckets have a count of 0
// - the endpoint returns a response where the male, female, or non-binary
//   bucket has a count of 0 and the user has selected that bucket.
//
// Another question - do we want to display a "no data" state if there is
// *technically* enough data to render a chart, however, it would look stupid?
// i.e. a Leader with 2 members in their age bar chart?

const computeBars = (data, selected, unitsPreference, visibleTabs) => {
  const _ageBuckets = data?.age_gender_insights[0].ages
  // munge the data into the display format, removing the
  // no-answer category and combining the 65-99 and 100-120 buckets
  const { 'no-answer': _, '65-99': sixtyFive, '100-120': oneHundred, ...others } = _ageBuckets
  const ageBuckets = {
    ...others,
    '65+': {
      total: sixtyFive.total + oneHundred.total,
      male: sixtyFive.male + oneHundred.male,
      female: sixtyFive.female + oneHundred.female,
      'non-binary': sixtyFive['non-binary'] + oneHundred['non-binary'],
      'no-answer': sixtyFive['no-answer'] + oneHundred['no-answer'],
    },
  }

  const totalFans =
    selected === 'total'
      ? data.age_gender_insights[0]?.total_reported_age
      : Object.keys(ageBuckets).reduce((sum, bucket) => sum + ageBuckets[bucket][selected], 0)
  const bars = Object.keys(ageBuckets).map((bucket) => ({
    label: bucket,
    value: ageBuckets[bucket][selected],
    valueLabel: <UnitDisplay.Animated count={ageBuckets[bucket][selected]} height="22px" total={totalFans} />,
    tooltip: `Ages ${bucket}, ${visibleTabs[selected]} Members (${formatUnits({
      count: ageBuckets[bucket][selected],
      total: totalFans,
      unitsPreference,
    })})`,
  }))

  return bars
}

export const AgeChartBox = ({
  demographicInsights,
  locked,
}: {
  demographicInsights: any
  locked?: boolean
}): JSX.Element => {
  const [selected, setSelected] = useState('total')
  const { unitsPreference } = useUnitsPreference()
  const { t } = useTranslation()

  const queries = [demographicInsights.ageAndGenderQuery]

  const { options } = useFanRegistrationField(FanRegistrationCoreFieldName.GENDER_NAME) || {}

  // These labels match the text for the gender options presented on the
  // ContactForm that users fill out when opting into a leader's community
  const visibleTabs = useMemo(() => {
    if (!options) return { total: 'All' }
    return options?.reduce(
      (tabs, option) => {
        if (option === 'Prefer not to answer') {
          return tabs
        }

        return { ...tabs, [option.toLowerCase()]: option }
      },
      { total: 'All' },
    )
  }, [options])

  const tabs = <InsightsBox.Tabs onChange={setSelected} selected={selected} tabs={visibleTabs} />

  const data = demographicInsights?.ageAndGenderQuery?.data
  const hasData = data?.age_gender_insights?.length > 0
  const selectedTotal = selected === 'total'
  const fanTotal = selectedTotal
    ? data?.age_gender_insights?.[0]?.total_reported_age
    : data?.age_gender_insights?.[0]?.total_fans

  const fanTotalText = formatNumeral(fanTotal)
  const infoType = selectedTotal ? 'age' : 'age and gender identity'
  const infoText = t('generalInsights.infoText', { total: fanTotalText, type: infoType })

  return (
    <InsightsBox.Managed
      infoText={infoText}
      isEmpty={!hasData}
      locked={locked}
      queries={queries}
      tabs={tabs}
      title="Age"
    >
      {() => (
        <div style={{ marginTop: SPACING[8] }}>
          <BarChart
            bars={computeBars(demographicInsights.ageAndGenderQuery?.data, selected, unitsPreference, visibleTabs)}
            height={216}
          />
        </div>
      )}
    </InsightsBox.Managed>
  )
}
