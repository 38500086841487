import { humanizeAgeFilterShortened } from '@community_dev/filter-dsl/lib/humanize/builtInFilters/humanizeAgeFilter'
import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorOperators,
  AgeFilter,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ApiError } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { FilterOptionsWithCounts } from './types'
import { CountByQueryFnReturn, useCountByQuery, useCountByQueries } from './useCountByQuery'

import { FilterSelectionType, useFilters } from 'contexts/FilterProvider/FilterProvider'

const ageFilters: AgeFilter[] = [
  {
    operator: SelectorOperators.GREATER_THAN_OR_EQUALS,
    operand: {
      field_key: BuiltInFields.AGE,
      field_label: fieldLabelFor(BuiltInFields.AGE),
      source: FieldSources.BUILT_IN,
      type: FieldTypes.INTEGER,
      value: '18',
    },
  },
  {
    operator: SelectorOperators.GREATER_THAN_OR_EQUALS,
    operand: {
      field_key: BuiltInFields.AGE,
      field_label: fieldLabelFor(BuiltInFields.AGE),
      source: FieldSources.BUILT_IN,
      type: FieldTypes.INTEGER,
      value: '21',
    },
  },
]

const toAgeFilter = (age: number, operator: SelectorOperators) => {
  return {
    operator,
    operand: {
      field_key: BuiltInFields.AGE,
      field_label: fieldLabelFor(BuiltInFields.AGE),
      source: FieldSources.BUILT_IN,
      type: FieldTypes.INTEGER,
      value: age.toString(),
    },
  }
}

type UseAgeCountArgs<SelectData = CountByQueryFnReturn> = {
  traceId?: string
  communicationChannel?: CommunicationChannel
  age: number
  operator?:
    | SelectorOperators.GREATER_THAN_OR_EQUALS
    | SelectorOperators.LESS_THAN_OR_EQUALS
    | SelectorOperators.EQUALS
    | SelectorOperators.LESS_THAN
    | SelectorOperators.GREATER_THAN
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

export const useAgeCount = <SelectData = CountByQueryFnReturn>({
  traceId,
  communicationChannel,
  age,
  operator = SelectorOperators.GREATER_THAN_OR_EQUALS,
  options,
}: UseAgeCountArgs<SelectData>): UseQueryResult<SelectData, ApiError> => {
  return useCountByQuery<SelectData>({
    filters: toAgeFilter(age, operator),
    traceId,
    communicationChannel,
    options,
  })
}

type UseAgeCountsArgs<SelectData = CountByQueryFnReturn> = {
  traceId?: string
  communicationChannel?: CommunicationChannel
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

export const useAgeCounts = <SelectData = CountByQueryFnReturn>({
  traceId,
  communicationChannel,
  options,
}: UseAgeCountsArgs<SelectData> = {}): UseQueryResult<SelectData, ApiError>[] => {
  return useCountByQueries<SelectData>({
    filters: ageFilters,
    traceId: traceId || 'age-counts',
    communicationChannel,
    options,
  })
}

export const useCurrentFiltersWithAgeCounts = (type: FilterSelectionType = 'includes'): FilterOptionsWithCounts => {
  const { previewNextFilter, communicationChannel } = useFilters()

  const nextFilters = ageFilters
    .map((ageFilter) => previewNextFilter(ageFilter, type))
    .filter(Boolean) as MemberDataFilter[]

  return {
    options: ageFilters.map((ageFilter) => ({
      label: humanizeAgeFilterShortened(ageFilter),
      filter: ageFilter,
    })),
    counts: useCountByQueries({
      filters: nextFilters,
      traceId: 'current-filters-with-age-counts',
      communicationChannel,
    }) as UseQueryResult<CountByQueryFnReturn, ApiError>[],
  }
}
