import { Modal } from '@community_dev/pixels'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import KeywordsEditor from './KeywordsEditor'
import { StyledCloseIcon, StyledModalBody, StyledModalHeader } from './KeywordsModal.style'

import { RestrictedKeywords } from 'api/restrictedKeywords'
import { MAX_RESTRICTED_KEYWORDS } from 'constants/keywords'
import { useAddRestrictedKeyword } from 'hooks/restrictedKeywords/useAddRestrictedKeyword'
import { useDeleteRestrictedKeyword } from 'hooks/restrictedKeywords/useDeleteRestrictedKeyword'
import { useRestrictedKeywords } from 'hooks/restrictedKeywords/useRestrictedKeywords'
import { useToastMessage } from 'hooks/useToastMessage'
import { NormalizedKeyword } from 'utils/normalize'

const toKeywordShape = (restrictedKeywords: RestrictedKeywords): NormalizedKeyword[] => {
  return restrictedKeywords?.data.map((keyword) => ({
    id: keyword.id,
    word: keyword.originalKeyword,
  }))
}

export type RestrictedKeywordsModalProps = {
  onClose: () => any
}

const RestrictedKeywordsModal = ({ onClose }: RestrictedKeywordsModalProps): JSX.Element => {
  const { t } = useTranslation()
  const { COLORS } = useTheme()
  const [error, setError] = React.useState('')
  const { data: restrictedKeywords } = useRestrictedKeywords({ select: toKeywordShape })
  const { showToastMessage } = useToastMessage()

  const { mutate: addRestrictedKeyword } = useAddRestrictedKeyword({
    onSuccess: () => showToastMessage({ message: t('keywordsModal.offensiveContent.restrictedKeywordAdded') }),
    onError: ({ errors }) => {
      if (errors?.length) {
        setError(errors[0].message)
        return
      }
      setError(t('keywordsModal.offensiveContent.restrictedKeywordAddedError`'))
    },
  })

  const { mutate: deleteRestrictedKeyword } = useDeleteRestrictedKeyword({
    onSuccess: () => showToastMessage({ message: t('keywordsModal.offensiveContent.restrictedKeywordDeleted') }),
    onError: () =>
      showToastMessage({ message: t('keywordsModal.offensiveContent.restrictedKeywordDeletedError'), success: false }),
  })

  const validateAndAddBlacklistKeyword = (keyword) => {
    if (keyword === '') return
    addRestrictedKeyword(keyword)
  }

  return (
    <Modal maxWidth={720} minHeight={600} onClose={onClose} open>
      <StyledModalHeader>
        <Modal.Header.Center>{t(`keywordsModal.offensiveContent.title`)}</Modal.Header.Center>
        <Modal.Header.Right onClose={onClose}>
          <StyledCloseIcon color={COLORS.SUBTEXT} size={12} />
        </Modal.Header.Right>
      </StyledModalHeader>
      <StyledModalBody>
        <KeywordsEditor
          activeWordCount={t('keywordsModal.offensiveContent.activeWordCount', {
            count: restrictedKeywords?.length ?? 0,
          })}
          autoFocus
          capacity={MAX_RESTRICTED_KEYWORDS}
          emptyState={t('keywordsModal.offensiveContent.emptyState')}
          error={error}
          infoBubble={t('keywordsModal.offensiveContent.infoBubble')}
          inputPlaceholder={t('keywordsModal.offensiveContent.inputPlaceholder')}
          keywords={restrictedKeywords || []}
          onAdd={validateAndAddBlacklistKeyword}
          onChange={() => setError('')}
          onClose={onClose}
          onDelete={({ id }) => id && deleteRestrictedKeyword(id)}
        />
      </StyledModalBody>
    </Modal>
  )
}

export default RestrictedKeywordsModal
