import React from 'react'

import PasswordForgotForm from 'containers/PasswordForgotForm'
import BoxFormLayout from 'layouts/BoxFormLayout'

class PasswordForgotScreen extends React.PureComponent {
  render(): JSX.Element {
    return (
      <BoxFormLayout>
        <PasswordForgotForm />
      </BoxFormLayout>
    )
  }
}

export default PasswordForgotScreen
