import { useMemo } from 'react'

type UseQueryParams = {
  [key: string]: any
}

export function useQueryParams(): UseQueryParams {
  const search = window.location.search
  return useMemo(
    () =>
      Object.fromEntries(
        Array.from(new URLSearchParams(search).entries()).map(([key, value]) => [key, decodeURIComponent(value)]),
      ),
    [search],
  )
}
