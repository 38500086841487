import { LOCATION_TYPE } from '@community_dev/filter-dsl/lib/digits'
import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  findOne,
  isLocationCityFilter,
  isLocationCountryFilter,
  isLocationRadiusFilter,
  isLocationStateFilter,
  isSelectorFilter,
  oneOf,
  SelectorFilter,
  SelectorOperators,
  serializeFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { useGooglePlaces } from '@community_dev/hooks'
import { countryCodeMap } from '@community_dev/location-data'
import { BORDER_RADIUS, ListItem, SearchBar } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import uniqWith from 'lodash/uniqWith'
import upperFirst from 'lodash/upperFirst'
import React, { ChangeEvent, useRef, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { FilterMemberCount } from '../FilterMemberCount'
import { LocationRadiusMap } from '../LocationRadiusMap'
import { useRecipientField } from '../RecipientFieldContext'
import {
  StyledAddSuffix,
  StyledButton,
  StyledFilters,
  StyledHeader,
  StyledHeading,
  StyledLoadingIndicator,
  StyledMeta,
  StyledNoResults,
  StyledPanel,
  StyledResults,
  StyledVirtualList,
} from '../styled-recipients'

import { LocationSuggestion, suggestCountries, suggestProvinces, suggestStates } from './suggestLocations'

import { VirtualListHandle } from 'components/VirtualList'
import { FilterSelectionType, useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useCurrentFiltersWithLocationCounts } from 'hooks/useCountByQuery/useLocationCount'
import { useToastMessage } from 'hooks/useToastMessage'
import { pluralizeNumeral } from 'utils/general'
import { loadGoogleMapsLoader } from 'utils/google-maps-loader'
import LOCALITY_MAP, { COUNTRY_LOOKUP, LocalityMap } from 'utils/localityMap'
import { PlaceSuggestion, transformPlaces } from 'utils/places'

const StyledRadiusMap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
  right: 0px;
  top: 0;
  left: 0px;
  bottom: 0px;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  border-bottom-left-radius: ${BORDER_RADIUS[1]};
  border-bottom-right-radius: ${BORDER_RADIUS[1]};
`

const StyledRadiusMapContainer = styled.div`
  width: 100%;
  height: calc(100% - 50px);
`

const StyledRadiusMapHeader = styled.div`
  padding: 14px;
  height: 50px;
  text-align: center;
  position: relative;
`

const StyledRadiusMapHeaderLeftButton = styled.div`
  position: absolute;
  top: 16px;
  left: 25px;
  width: 20px;
  color: ${({ theme }) => theme?.COLORS?.DIVIDERS};
  font-size: 19px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  }
`
const StyledRadiusMapHeaderContent = styled.div`
  font-weight: 900;
  font-size: 18px;
  letter-spacing: 0.24px;
`

const StyledListItem = styled.div<{ $hasAction: boolean }>`
  opacity: ${({ $hasAction }) => ($hasAction ? 1 : '0.4')};
`

function getLocality(location: LocationSuggestion | PlaceSuggestion): LocalityMap | 'city' | 'country' | 'unknown' {
  switch (location.type) {
    case LOCATION_TYPE.STATE:
      return LOCALITY_MAP[COUNTRY_LOOKUP[location.country.toUpperCase()]]
    case LOCATION_TYPE.COUNTRY_CODE:
      return 'country'
    case LOCATION_TYPE.CITY:
      return 'city'
    default:
      return 'unknown'
  }
}

function getLabel(location: PlaceSuggestion | LocationSuggestion) {
  if (location.type === LOCATION_TYPE.COUNTRY_CODE && 'code' in location && typeof location.code === 'string') {
    return countryCodeMap[location.code]
  }

  return location.name
}

export function getSubtext(count: any, locality: string): string {
  if (isUndefined(count)) return locality
  return `${locality} ${pluralizeNumeral(count, '0,0', 'Member', 'Members')}`
}

export function RecommendationsLocation({ type = 'includes' }: { type: FilterSelectionType }): JSX.Element {
  const { COLORS } = useTheme() || {}
  const virtualRef = useRef<VirtualListHandle>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const searchTermRef = useRef('')
  searchTermRef.current = searchTerm
  const [selected, setSelected] = useState<PlaceSuggestion | LocationSuggestion>()
  const [initialized, setInitialized] = useState(false)
  const {
    error,
    results,
    sessionToken,
    loading: isLoading,
  } = useGooglePlaces(searchTerm, {
    getPlacePredictions: {
      componentRestrictions: {
        country: ['us', 'ca', 'pr'],
      },
    },
  })
  const { activeSubtree, addFilter, removeFilter, communicationChannel } = useFilters()
  const { options, counts } = useCurrentFiltersWithLocationCounts(type)

  const { setIsOpen } = useRecipientField()
  const { showToastMessage } = useToastMessage()
  const { t } = useTranslation()

  const searchResults = useMemo(() => {
    if (!results) return []
    const stateSuggestions = [...suggestStates(searchTermRef.current), ...suggestProvinces(searchTermRef.current)]
    const countrySuggestions = suggestCountries(
      searchTermRef.current,
      // The SMS communication channel only supports US and Canada
      communicationChannel === CommunicationChannel.SMS ? ['US', 'CA'] : undefined,
    )

    return uniqWith(
      [
        ...countrySuggestions,
        ...stateSuggestions,
        ...results.map(transformPlaces).filter((s: any) => s.state !== 'Washington, D.C.'),
      ],
      isEqual,
    ) as (LocationSuggestion | PlaceSuggestion)[]
  }, [results, communicationChannel])

  useEffect(() => {
    loadGoogleMapsLoader().then(() => {
      setInitialized(true)
    })
  }, [])

  useEffect(() => {
    virtualRef.current?.measure()
  }, [searchResults])

  useEffect(() => {
    if (!error) return
    showToastMessage({ message: 'Unable to find location', success: false })
  }, [error, showToastMessage])

  const selectedLocation = useMemo(() => {
    const filterNode = findOne(activeSubtree, {
      operand: {
        field_key: oneOf(
          BuiltInFields.LOCATION,
          BuiltInFields.STATE_CODE,
          BuiltInFields.COUNTRY_CODE,
          BuiltInFields.CITY,
        ),
      },
    })

    if (filterNode) {
      const filter = serializeFilters(filterNode) as SelectorFilter

      if (filter) {
        if (isLocationRadiusFilter(filter)) {
          return {
            name: `${filter.operand.value.city}, ${filter.operand.value.state}`,
            type: filter.operand.type,
            filter,
          }
        }

        if (isLocationStateFilter(filter)) {
          return {
            name: filter.operand.value,
            type: filter.operand.type,
            country: 'USA',
            filter,
          }
        }

        if (isLocationCountryFilter(filter)) {
          return {
            name: countryCodeMap[filter.operand.value],
            type: filter.operand.type,
            country: filter.operand.value,
            filter,
          }
        }

        if (isLocationCityFilter(filter)) {
          return {
            name: filter.operand.value,
            type: filter.operand.type,
            filter,
          }
        }
      }
    }

    return null
  }, [activeSubtree])

  function onSearchChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value)
  }

  function handleSelect(value) {
    addFilter(
      {
        operator: SelectorOperators.RADIUS,
        operand: {
          field_key: BuiltInFields.LOCATION,
          field_label: fieldLabelFor(BuiltInFields.LOCATION),
          source: FieldSources.BUILT_IN,
          type: FieldTypes.LOCATION,
          value,
        },
      },
      type,
    )

    setIsOpen(false)
  }

  return (
    <React.Fragment>
      <StyledPanel>
        <StyledMeta>
          <StyledHeader>
            <StyledHeading>Location</StyledHeading>
          </StyledHeader>
          {!selectedLocation ? (
            <StyledFilters>
              <SearchBar
                disabled={!initialized}
                onChange={onSearchChange}
                placeholder="Type the name of a country, city or state"
                value={searchTerm}
              />
            </StyledFilters>
          ) : null}
        </StyledMeta>

        {isLoading && searchTerm ? (
          <StyledLoadingIndicator />
        ) : selectedLocation ? (
          <StyledListItem $hasAction={true}>
            <ListItem
              as="div"
              label={selectedLocation.name}
              suffix={
                <StyledAddSuffix>
                  <StyledButton
                    $color={COLORS?.ERRORS}
                    onClick={() => {
                      removeFilter(selectedLocation.filter)
                    }}
                  >
                    Remove
                  </StyledButton>
                </StyledAddSuffix>
              }
            />
          </StyledListItem>
        ) : searchResults?.length > 0 ? (
          <StyledResults>
            <StyledVirtualList ref={virtualRef} rows={searchResults} testId="location-list">
              {({ virtualRow }) => {
                const location = searchResults[virtualRow.index]
                const { name } = location
                const label = getLabel(location)
                const localityType = getLocality(location)
                const locality = localityType === 'unknown' ? location.type : t(`location.${localityType}`)

                return (
                  <StyledListItem $hasAction={true}>
                    <ListItem
                      as="button"
                      data-testid={`location-${name}`}
                      label={label}
                      onClick={() => {
                        if (location.type === LOCATION_TYPE.STATE && 'abbrev' in location && location.abbrev) {
                          const filter = {
                            operator: SelectorOperators.EQUALS,
                            operand: {
                              field_key: BuiltInFields.STATE_CODE,
                              field_label: fieldLabelFor(BuiltInFields.STATE_CODE),
                              source: FieldSources.BUILT_IN,
                              type: FieldTypes.STRING,
                              value: location.abbrev,
                            },
                          }

                          addFilter(filter, type)
                          setIsOpen(false)
                          return
                        }

                        if (location.type === LOCATION_TYPE.COUNTRY_CODE && 'code' in location && location.code) {
                          const filter = {
                            operator: SelectorOperators.EQUALS,
                            operand: {
                              field_key: BuiltInFields.COUNTRY_CODE,
                              field_label: fieldLabelFor(BuiltInFields.COUNTRY_CODE),
                              source: FieldSources.BUILT_IN,
                              type: FieldTypes.STRING,
                              value: location.code,
                            },
                          }

                          addFilter(filter, type)
                          setIsOpen(false)
                          return
                        }

                        setSelected(location)
                      }}
                      subtext={locality}
                    />
                  </StyledListItem>
                )
              }}
            </StyledVirtualList>
          </StyledResults>
        ) : searchTerm ? (
          <StyledNoResults>No results for "{searchTerm}"</StyledNoResults>
        ) : options?.length > 0 ? (
          <StyledResults>
            <StyledVirtualList ref={virtualRef} rows={options} testId="location-list">
              {({ virtualRow }) => {
                const { data: { count = 0 } = {}, isInitialLoading } = counts[virtualRow.index]
                const { label, filter } = options[virtualRow.index]

                const subtext = getSubtext(count, 'city')

                if (!isSelectorFilter(filter) || !isLocationRadiusFilter(filter)) return null

                return (
                  <StyledListItem $hasAction={true}>
                    <ListItem
                      as="button"
                      data-testid={`location-${label}`}
                      label={label}
                      onClick={() => {
                        setSelected({
                          name: label,
                          city: filter.operand.value.city,
                          type: LOCATION_TYPE.CITY,
                          state: filter.operand.value.state,
                          country: 'USA',
                        })
                      }}
                      subtext={<FilterMemberCount count={upperFirst(subtext)} isLoading={isInitialLoading} />}
                    />
                  </StyledListItem>
                )
              }}
            </StyledVirtualList>
          </StyledResults>
        ) : null}
      </StyledPanel>
      {selected && (
        <StyledRadiusMap>
          <StyledRadiusMapContainer>
            <StyledRadiusMapHeader>
              <StyledRadiusMapHeaderLeftButton
                onClick={() => {
                  setSelected(undefined)
                }}
              >
                <FontAwesomeIcon icon="chevron-left" />
              </StyledRadiusMapHeaderLeftButton>
              <StyledRadiusMapHeaderContent>Set Radius</StyledRadiusMapHeaderContent>
            </StyledRadiusMapHeader>

            <LocationRadiusMap
              initialLocation={selected}
              onBack={() => {
                setSelected(undefined)
              }}
              onSelect={handleSelect}
              sessionToken={sessionToken}
            />
          </StyledRadiusMapContainer>
        </StyledRadiusMap>
      )}
    </React.Fragment>
  )
}
