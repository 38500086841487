import { FieldSources, Placeholder } from '@community_dev/filter-dsl/lib/subscription-data'

export const firstNamePlaceholder: Placeholder = {
  key: 'first_name',
  max_length: 100,
  name: 'First Name',
  source: FieldSources.BUILT_IN,
  value_type: 'string',
}

export const shopifyCheckoutPlaceholders: Placeholder[] = [
  {
    key: 'first_name',
    max_length: 100,
    name: 'First Name',
    source: FieldSources.SHOPIFY_CHECKOUT,
    value_type: 'string',
  },
  {
    key: 'last_name',
    max_length: 100,
    name: 'Last Name',
    source: FieldSources.SHOPIFY_CHECKOUT,
    value_type: 'string',
  },
]
