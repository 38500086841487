import { useMutation } from '@tanstack/react-query'
import numeral from 'numeral'
import { toast } from 'react-hot-toast'

import { postAssignByQuery } from 'api/community'
import analytics from 'utils/analytics'

export default function useMutationAssignByQuery({ fanCount }: any): any {
  return useMutation(postAssignByQuery, {
    onSuccess(_, community) {
      analytics.track(analytics.events.CommunityMembersAdded(community, fanCount))

      toast.success(
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ id: any... Remove this comment to see the full error message
        `${numeral(fanCount).format('0,0')} added to ${community.title}`,
      )
    },
    onError(_, community) {
      toast.error(
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ id: any... Remove this comment to see the full error message
        `${community.title} failed to be updated`,
      )
    },
  })
}
