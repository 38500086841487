import { ApiError } from '@community_dev/requests'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { Invoices, getInvoices } from 'api/invoices'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { useHasSomeCapabilities } from 'hooks/useUserCapability'

export function useInvoices<SelectData = Invoices>(
  options: UseQueryOptions<Invoices, ApiError, SelectData> = {},
): UseQueryResult<SelectData, ApiError> {
  const clientId = useClientId()

  // We only want to show invoices if they have the segment billing capability
  const hasSegmentBilling = useHasSomeCapabilities(CAPABILITIES.FEATURE.SEGMENT_BILLING.ALL)

  const invoicesEnabled =
    useHasSomeCapabilities(
      CAPABILITIES.CLIENT.BILLING.READ,
      CAPABILITIES.CLIENT.BILLING.WRITE,
      CAPABILITIES.ADMIN.BILLING.READ,
      CAPABILITIES.ADMIN.BILLING.WRITE,
    ) && hasSegmentBilling

  return useQuery({
    queryKey: [QUERY_CACHE.INVOICES, { clientId }],
    queryFn: () => getInvoices({ clientId }),
    enabled: invoicesEnabled,
    retryOnMount: false,
    ...options,
  })
}
