import { WorkflowEditorCardTypes, WORKFLOW_CARDS } from '@community_dev/flexible-canvas'
import { useMessageBillingUsage } from '@community_dev/hooks'
import {
  Layout,
  CheckBox,
  Typography,
  SPACING,
  ChatBubbleDirectionValues,
  BaseEmoji,
  ChatBubble,
} from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { Manifest, SendMessageAction, updateAction, WorkflowMedia } from '@community_dev/workflow-manifest'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import IconFlowPerformance from 'components/WorkflowEditor/svg/flow-performance.svg?react'
import { $createTextNode, $getSelection, $isRangeSelection } from 'lexical'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { formatMediaForFlow } from '../WorkflowSidebarAddSms'

import { StyledLink, StyledLabel } from './WorkflowSidebarEditSms.style'

import { ComposeEditor, usePlaceholderNodes } from 'components/ComposeEditor'
import { Nodes } from 'components/ComposeEditor/nodes'
import { DynamicFieldList, InsertDynamicFieldButton } from 'components/ComposeMessage/components'
import { FileRejectionDialog } from 'components/FileRejectionDialog'
import { MiniLinkPreview } from 'components/links/MiniLinkPreview'
import { MultimediaInput } from 'components/MultimediaInput'
import { WorkflowSidebarHeader } from 'components/WorkflowEditor/components/WorkflowSidebarHeader/WorkflowSidebarHeader'
import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { useManifestAction } from 'components/WorkflowEditor/hooks/useManifestAction'
import { useMediaUpload } from 'components/WorkflowEditor/hooks/useMediaUpload'
import { shopifyCheckoutPlaceholders } from 'constants/placeholder'
import { ROUTES } from 'constants/routes'
import {
  formatClicks,
  formatCtr,
  formatSent,
  getMessageTextsWithIndex,
  useWorkflowAnalytics,
} from 'containers/Settings/useWorkflowAnalytics'
import { BoundsProvider } from 'contexts/BoundsProvider'
import { useSettings } from 'contexts/SettingsProvider'
import { useUpload } from 'contexts/UploadProvider'
import { useClientId } from 'hooks/useClient'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useMediaConfig, FileContext } from 'hooks/useMediaConfig'
import { useShopifyStoreDetails } from 'hooks/useShopify'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import { useToastMessage } from 'hooks/useToastMessage'
import Sentry from 'integrations/Sentry'
import { DoneButton, CancelLink } from 'screens/WorkflowScreen/WorkflowScreen.style'
import analytics from 'utils/analytics'
import { route } from 'utils/router'

function getMessageIndex(functionKey: string, functionIndex: string, manifest: Manifest): number {
  return getMessageTextsWithIndex(manifest).findIndex((message) => {
    return message.function === functionKey && message.index.toString() === functionIndex
  })
}

const WorkflowAction = WORKFLOW_CARDS[WorkflowEditorCardTypes.SEND_MESSAGE]

function WorkflowEditSMSMessage(): JSX.Element {
  const { t } = useTranslation()
  const clientId = useClientId()
  const history = useHistory()
  const currentTheme = useCurrentTheme()
  const { COLORS } = useTheme() || {}
  const { settings } = useSettings()
  const [editor] = useLexicalComposerContext()
  const hasLexicalEditorContext = useMemo(() => editor?.update && typeof editor.update === 'function', [editor])

  const { data: shopifyDetails } = useShopifyStoreDetails(clientId)

  const { showToastMessage } = useToastMessage()

  const { workflowId, functionKey, functionIndex } =
    useParams<{ workflowId: string; functionKey: string; functionIndex: string }>()
  const { workflow, manifest, dispatch } = useWorkflowProvider()
  const { data: workflowAnalytics } = useWorkflowAnalytics(clientId, workflow)
  const { action } = useManifestAction<SendMessageAction>()

  const isShopifyCheckoutMessage = action?.params?.include_checkout_url === true
  const [messageText, setMessageText] = useState(action?.params.text || '')
  const [ignoreQuietHours, setIgnoreQuietHours] = useState(action?.params.ignore_quiet_hours || false)

  // ensure any media or uploads stored in the context are cleared when we close the sidebar
  useEffect(() => {
    return () => {
      setFile(undefined)
    }
  }, [])

  const [existingMedia, setExistingMedia] = useState<{ media: WorkflowMedia; file?: File } | undefined>()

  // is there currently media on this step
  const manifestHasMedia = action?.params?.media?.[0]?.id

  // the initial state for existing media, so we can determine if media was removed when editing the step
  useEffect(() => {
    if (action?.params?.media?.[0]?.id) {
      setExistingMedia({
        media: action.params.media[0],
        file: undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { file, setFile } = useUpload()

  const communicationChannel = CommunicationChannel.SMS
  const { accept, maxSize, mediaDisposition, setMediaDisposition, resetMediaDisposition } = useMediaConfig({
    communicationChannel,
    context: FileContext.COMPOSE,
  })

  const isMms =
    (file && mediaDisposition === MediaDisposition.ATTACHMENT) ||
    existingMedia?.media?.disposition === MediaDisposition.ATTACHMENT

  const canInsertDynamicFields = hasLexicalEditorContext && isShopifyCheckoutMessage
  const { hasReachedLimit, placeholders } = usePlaceholderNodes()
  const { links } = useShortenableLinks(messageText)
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = useMessageBillingUsage({
    communicationChannel,
    isMediaAttached: file !== undefined || existingMedia !== undefined,
    isMms,
    linksToShorten: links,
    mediaDisposition,
    message: messageText,
    placeholders: canInsertDynamicFields && placeholders.length ? placeholders : [],
    showDynamicCount: canInsertDynamicFields && placeholders.length > 0,
  })
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }

  const onFileAccepted = (file: File) => {
    setFile(file)
    analytics.track(analytics.events.Workflows.AttachedMedia({ type: workflow.type }))
  }

  const onFileRejected = useCallback(
    (fileRejection: FileRejection) => {
      setFile(fileRejection.file)
    },
    [setFile],
  )

  // if user attaches a file that is not supported by MMS
  // and they choose to instead send the message as SMS
  // set file and temporarily override media disposition
  const handleContinue = () => {
    setMediaDisposition(MediaDisposition.LINK)
  }

  const { mediaUploadDispatch } = useWorkflowProvider()

  const { uploadMedia, isLoading: mediaUploadInProgress } = useMediaUpload()

  const [isDynamicFieldListVisible, setIsDynamicFieldListVisible] = useState(false)

  // update text when switching steps
  useEffect(() => {
    setMessageText(action?.params.text || '')
  }, [action?.params.text])

  // update media when switching steps
  useEffect(() => {
    if (!action?.params.media) {
      setExistingMedia(undefined)
    }
    if (action?.params?.media?.[0]?.id) {
      setExistingMedia({
        media: action.params.media[0],
        file: undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action?.params.media])

  // update quiet hours when switching steps
  useEffect(() => {
    if (action?.params.ignore_quiet_hours) {
      setIgnoreQuietHours(action.params.ignore_quiet_hours)
    }
  }, [action?.params.ignore_quiet_hours])

  const hasChanges = useMemo(() => {
    return (
      messageText !== (action?.params.text || '') ||
      ignoreQuietHours !== (action?.params.ignore_quiet_hours || false) ||
      file ||
      (!existingMedia && manifestHasMedia)
    )
  }, [action, messageText, ignoreQuietHours, existingMedia, file, manifestHasMedia])

  const subtitle = `${t('automations.createCustomMessage')} ${
    isShopifyCheckoutMessage ? t('automations.thisMessageWillIncludeLink') : ''
  }`

  function handleEmojiSelect(emoji: BaseEmoji, text: string) {
    if (canInsertDynamicFields) {
      editor.update(() => {
        const selection = $getSelection()
        // If cursor is in the editor, insert emoji at cursor
        if ($isRangeSelection(selection)) {
          selection.insertNodes([$createTextNode(emoji.native)])
        }
      })
    }
    setMessageText(text)
  }

  return (
    <BoundsProvider>
      <Layout display="flex" flexDirection="column" height="100%" width="100%">
        <Layout marginTop={SPACING[2]} padding={SPACING[4]}>
          <WorkflowSidebarHeader
            workflowAction={{
              ...WorkflowAction,
              title: `${WorkflowAction.title} #${getMessageIndex(functionKey, functionIndex, manifest) + 1}`,
              subtitle,
            }}
          />
        </Layout>
        <Layout flex="1" padding={SPACING[4]}>
          {isShopifyCheckoutMessage && (
            <ChatBubble
              backgroundColor={COLORS.WORKFLOW_EDITOR_SIDEBAR_BACKGROUND}
              color={COLORS.CHAT_BUBBLE_BACKGROUND_RECEIVED}
              direction={ChatBubbleDirectionValues.LEFT}
              style={{ marginBottom: SPACING[6] }}
              tail={true}
            >
              <MiniLinkPreview
                title={t('automations.checkout')}
                url={shopifyDetails ? `https://${shopifyDetails.name}.myshopify.com` : 'https://yourshop.myshopify.com'}
              />
            </ChatBubble>
          )}
          <MultimediaInput
            accept={accept}
            additionalToolbarItems={[
              canInsertDynamicFields && (
                <InsertDynamicFieldButton
                  hasReachedLimit={false}
                  isActive={isDynamicFieldListVisible}
                  onClick={() => setIsDynamicFieldListVisible(!isDynamicFieldListVisible)}
                />
              ),
            ]}
            emojiSkinTone={settings.skinTone}
            emojiTheme={currentTheme.type}
            gallery={true}
            gif={false}
            maxSize={maxSize}
            media={{
              file: file || existingMedia?.file,
              remoteUrl: (!file && existingMedia?.media?.url) || undefined,
              width: 100,
            }}
            onChange={(e) => setMessageText(e.target.value)}
            onEmojiSelect={handleEmojiSelect}
            onFileAccepted={onFileAccepted}
            onFileRejected={onFileRejected}
            onRemoveMedia={() => {
              setFile(undefined)
              setExistingMedia(undefined)
              resetMediaDisposition()
            }}
            usageCounterProps={usageCounterProps}
            value={messageText}
          >
            {canInsertDynamicFields
              ? (props) => (
                  <ComposeEditor
                    {...props}
                    communicationChannel={CommunicationChannel.SMS}
                    enablePlaceholders={isShopifyCheckoutMessage}
                    placeholders={shopifyCheckoutPlaceholders}
                  />
                )
              : undefined}
          </MultimediaInput>

          <FileRejectionDialog
            communicationChannel={communicationChannel}
            context={FileContext.COMPOSE}
            file={file}
            mediaDisposition={mediaDisposition}
            onClose={() => {
              setFile(undefined)
              resetMediaDisposition()
            }}
            onContinue={handleContinue}
          />

          {canInsertDynamicFields && shopifyCheckoutPlaceholders.length > 0 && isDynamicFieldListVisible && (
            <DynamicFieldList
              hasReachedLimit={hasReachedLimit}
              onInsert={() => setIsDynamicFieldListVisible(false)}
              placeholders={shopifyCheckoutPlaceholders}
            />
          )}
          <Layout alignItems="flex-start" display="flex" marginTop="12px">
            <CheckBox
              id="ignore-quiet-hours-checkbox"
              onChange={(checked) => setIgnoreQuietHours(!checked)}
              selected={!ignoreQuietHours}
              style={{
                marginRight: 6,
                marginTop: 4,
              }}
            />
            <Layout lineHeight="1.2">
              <StyledLabel htmlFor="ignore-quiet-hours-checkbox" style={{ marginRight: 6 }}>
                {t('automations.doNotSendDuringQuietHours')}
              </StyledLabel>{' '}
              <StyledLink
                href="https://incommunity.zendesk.com/hc/en-us/articles/6029475630227-Quiet-Hours-Learn-More"
                rel="noreferrer"
                target="_blank"
              >
                {t('learnMore')}
              </StyledLink>
            </Layout>
          </Layout>
          <Layout borderTop={`1px solid ${COLORS.BORDERS}`} marginTop={SPACING[6]}>
            <Layout display="flex" marginBottom={SPACING[4]} marginTop={SPACING[5]}>
              <IconFlowPerformance />
              <Typography component="div" fontSize="18px" fontWeight="bold" marginLeft={SPACING[3]} variant="body1">
                {t('automations.performance')}
              </Typography>
            </Layout>
            <Layout display="flex" flexGrow="1" justifyContent="space-between">
              <Layout>
                <Typography lineHeight="33px" variant="overline">
                  {t('automations.sent')}
                </Typography>
                <Typography display="block">
                  {formatSent(workflowAnalytics.functions[functionKey]?.[functionIndex]?.totalsent)}
                </Typography>
              </Layout>
              <Layout>
                <Typography lineHeight="33px" variant="overline">
                  {t('automations.clicks')}
                </Typography>
                <Typography display="block">
                  {formatClicks(action, workflowAnalytics.functions[functionKey]?.[functionIndex]?.linkhits)}
                </Typography>
              </Layout>
              <Layout>
                <Typography lineHeight="33px" variant="overline">
                  {t('automations.ctr')}
                </Typography>
                <Typography display="block">
                  {formatCtr(workflowAnalytics.functions[functionKey]?.[functionIndex]?.ctr)}
                </Typography>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
        <Layout
          alignItems="center"
          borderTop={`1px solid ${COLORS?.BORDERS}`}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding="45px 16px"
        >
          <DoneButton
            disabled={mediaUploadInProgress || !hasChanges || (!messageText && !file && !existingMedia) || error}
            onClick={async () => {
              if (!action) {
                return
              }

              let media: Api.V2.Media | undefined

              if (file) {
                media = await uploadMedia(file)

                analytics.track(analytics.events.Workflows.SavedMedia({ type: workflow.type }))

                mediaUploadDispatch({
                  type: 'update',
                  media: media as Api.V2.Media,
                  file,
                })
              }

              const nextAction = cloneDeep(action)
              set(nextAction, 'params.text', messageText || null)
              set(nextAction, 'params.ignore_quiet_hours', ignoreQuietHours)

              if (media) {
                set(nextAction, 'params.media', [formatMediaForFlow(media, mediaDisposition)])
              } else if (existingMedia?.media) {
                set(nextAction, 'params.media', [existingMedia.media])
              } else {
                set(nextAction, 'params.media', undefined)
              }

              const nextManifest = updateAction(manifest, functionKey, Number(functionIndex), nextAction)

              try {
                dispatch({
                  type: 'update',
                  state: {
                    manifest: nextManifest,
                  },
                })

                history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
              } catch (e) {
                showToastMessage({
                  message: (e as Error).message,
                  success: false,
                })
              }
            }}
            type="button"
          >
            {t('done')}
          </DoneButton>
          <CancelLink style={{ marginTop: SPACING[5] }} to={route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId })}>
            {t('cancel')}
          </CancelLink>
        </Layout>
      </Layout>
    </BoundsProvider>
  )
}

export function WorkflowSidebarEditSms(): JSX.Element {
  return (
    <LexicalComposer
      initialConfig={{
        namespace: 'ComposeEditor',
        onError: (error) => Sentry.captureException(error),
        nodes: [...Nodes],
      }}
    >
      <WorkflowEditSMSMessage />
    </LexicalComposer>
  )
}
