import { SPACING, Toast } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledForm = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${SPACING[5]} 40px 0;

  > ${Toast} {
    max-width: 100%;
    width: auto;
  }
`

type StyledContentProps = {
  $submitting?: boolean
}

export const StyledContent = styled.div<StyledContentProps>`
  opacity: ${({ $submitting }) => ($submitting ? 0.6 : 1)};

  > ${Toast} {
    max-width: 100%;
    width: auto;
    margin: ${SPACING[4]} 40px;
  }
`

type StyledFooterProps = {
  $multiple?: boolean
}

export const StyledFooter = styled.div<StyledFooterProps>`
  width: 100%;
  margin: 0 24px ${SPACING[4]};
  display: flex;
  justify-content: ${({ $multiple }) => ($multiple ? 'space-between' : 'flex-end')};
`
