import {
  FieldSources,
  FanEnabledStatuses,
  FieldTypes,
  FieldDefinition,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ListItem, SearchBar } from '@community_dev/pixels'
import sortBy from 'lodash/sortBy'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory, useParams } from 'react-router'

import {
  StyledFilters,
  StyledHeader,
  StyledHeading,
  StyledMeta,
  StyledPanel,
  StyledVirtualList,
} from '../styled-recipients'

import { FieldPane } from './FieldPane'

import { RecommendationsLoadingIndicator } from 'components/ComposeMessage/RecommendationsLoadingIndicator'
import { VirtualListHandle } from 'components/VirtualList'
import { ROUTES } from 'constants/routes'
import { FilterSelectionType } from 'contexts/FilterProvider/FilterProvider'
import { useClientId } from 'hooks/useClient'
import { useCustomMemberDataFields } from 'hooks/useCustomDataFields'
import analytics from 'utils/analytics'
import { route } from 'utils/router'

export const displayableSources: FieldSources[] = [FieldSources.CLIENT, FieldSources.FAN]

function matchTypeToTranslationType(field: FieldDefinition): FieldTypes | 'dropdown' {
  return field.value_type === 'string' && !!field.value_options ? 'dropdown' : field.value_type
}

type ActiveFieldPanelProps = {
  fields: FieldDefinition[]
  type: FilterSelectionType
}

const ActiveFieldPanel = ({ fields, type }: ActiveFieldPanelProps): JSX.Element | null => {
  const history = useHistory()
  const { fieldKey }: { fieldKey: string } = useParams()

  const activeField = useMemo(() => {
    return fields.find((field) => field.key === fieldKey)
  }, [fieldKey, fields])

  if (activeField) {
    return (
      <StyledPanel>
        <FieldPane
          field={activeField}
          onBackClick={() => history.push(ROUTES.RECIPIENT_PANE.CUSTOM_FIELDS)}
          type={type}
        />
      </StyledPanel>
    )
  }

  return null
}

type PanelProps = {
  fields: FieldDefinition[]
  isLoading: boolean
}

// sort active fields ahead of inactive fields
function sortFields(field: FieldDefinition) {
  const activeFieldStatuses = [
    FanEnabledStatuses.IS_ENABLED_REQUIRED,
    FanEnabledStatuses.IS_ENABLED_OPTIONAL,
    null, // null is the value for fields that aren't from the fan source
  ] as FanEnabledStatuses[]

  if (activeFieldStatuses.includes(field.fan_enabled_status)) {
    // `sortBy` sorts ascendingly so active fields have to return `0`
    return 0
  }

  return 1
}

function sortNames(field: FieldDefinition): string {
  return field.name.toLowerCase()
}

const Panel = ({ fields, isLoading }: PanelProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('')
  const { t } = useTranslation(undefined, { keyPrefix: 'customData' })
  const history = useHistory()
  const virtualRef = useRef<VirtualListHandle>(null)

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const filteredFields = useMemo(() => {
    const selectableFields = fields.filter(
      (field) =>
        field.name.toLowerCase().includes(searchTerm.toLowerCase()) && displayableSources.includes(field.source),
    ) as FieldDefinition[]

    return sortBy(selectableFields, [sortFields, sortNames])
  }, [fields, searchTerm])

  return (
    <StyledPanel>
      <StyledMeta>
        <StyledHeader>
          <StyledHeading>Custom Fields</StyledHeading>
        </StyledHeader>
        <StyledFilters>
          <SearchBar onChange={onSearchChange} placeholder="Search" value={searchTerm} />
        </StyledFilters>
      </StyledMeta>
      {isLoading && <RecommendationsLoadingIndicator />}
      {!isLoading && (
        <StyledVirtualList ref={virtualRef} rows={filteredFields}>
          {({ virtualRow }) => {
            const field = filteredFields[virtualRow.index]
            const translationType = matchTypeToTranslationType(field)
            const subtext = t(`${translationType}Type`)

            return (
              <ListItem
                as="button"
                data-testid={`field-${field.key}`}
                label={field.name}
                onClick={() => {
                  const fieldRoute = route(ROUTES.RECIPIENT_PANE.CUSTOM_FIELD, { fieldKey: field.key })
                  history.push(fieldRoute)
                  analytics.track(analytics.events.CustomFieldSelected(field.value_type))
                }}
                subtext={subtext}
              />
            )
          }}
        </StyledVirtualList>
      )}
    </StyledPanel>
  )
}

export const RecommendationsCustomFields = ({ type = 'includes' }: { type: FilterSelectionType }): JSX.Element => {
  const clientId = useClientId()

  const { isLoading, data } = useCustomMemberDataFields({
    clientId,
  })

  const fields = useMemo(() => sortBy(data || [], ['name']), [data])

  useEffect(() => {
    analytics.track(analytics.events.CustomFieldsSelected())
  }, [])

  return (
    <Switch>
      <Route path={ROUTES.RECIPIENT_PANE.CUSTOM_FIELD}>
        <ActiveFieldPanel fields={fields} type={type} />
      </Route>
      <Route exact path={ROUTES.RECIPIENT_PANE.CUSTOM_FIELDS}>
        <Panel fields={fields} isLoading={isLoading} />
      </Route>
    </Switch>
  )
}
