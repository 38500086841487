import { humanizeComplexFilter } from '@community_dev/filter-dsl/lib/humanize/humanizeComplexFilter'
import { digitsFilterToMemberDataFilter } from '@community_dev/filter-dsl/lib/transformers/digitsFilterToMemberDataFilter'
import { convertKeysToCamelCase, convertKeysToSnakeCase } from '@community_dev/requests'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router'

import { useCommunities } from './useCommunities'

import { CampaignDetails, campaignDetailsQuery } from 'api/campaign'
import { Community } from 'api/community'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import Sentry from 'integrations/Sentry'
import { createMediaFromFile } from 'utils/createMediaFromFile'
import { mapFanSubscriptionTags } from 'utils/mapFanSubscriptionTags'
import media from 'utils/media'

const mediaCache = media.cache

type UseCampaignDetailsProps<T> = {
  campaignId?: string
  enabled?: boolean
  select?: (campaign: CampaignDetails) => T
}

// We add non image (videos/audio) to the cache in the useSendMessage hook
// The other media types are processed in sync
// Once the cache is no longer needed we need to remove it
export const addPendingMedia = async (campaignDetails: CampaignDetails): Promise<CampaignDetails> => {
  // Check to see if its text only
  if (campaignDetails.media === null) return campaignDetails

  // Check the cache and see if there is anything in it
  const cacheLength = await mediaCache.length()
  if (cacheLength === 0) return campaignDetails

  // Check if the campaign already has processed media
  if (campaignDetails?.media?.url) {
    // if it does, remove the pending media file from the cache
    await mediaCache.removeItem(campaignDetails.id)
    return campaignDetails
  }

  // Get the pending media file from the cache
  const pendingMediaFileFromCache = await mediaCache.getItem(campaignDetails.id)
  if (!pendingMediaFileFromCache) return campaignDetails

  // Create a media obj from the file
  const pendingMedia = await createMediaFromFile(pendingMediaFileFromCache)

  // Add the pending media to the campaign
  return { ...campaignDetails, media: convertKeysToCamelCase(pendingMedia) }
}

export const useCampaignDetails = <T = CampaignDetails>({
  campaignId,
  enabled = true,
  select,
}: UseCampaignDetailsProps<T>): UseQueryResult<T> => {
  const clientId = useClientId()
  return useQuery(
    [QUERY_CACHE.CAMPAIGN.DETAILS, { clientId, campaignId }],
    async () => {
      const campaignDetails = await campaignDetailsQuery({ clientId, campaignId })

      try {
        const dataWithPendingMedia = await addPendingMedia(campaignDetails)
        return dataWithPendingMedia
      } catch (e) {
        Sentry.captureException(e)
        return campaignDetails
      }
    },
    {
      select,
      enabled: enabled && !!clientId && !!campaignId,
      staleTime: STALE_TIME.ONE_MINUTE,
    },
  )
}

type UseCurrentCampaignDetailsProps<T> = Pick<UseCampaignDetailsProps<T>, 'enabled' | 'select'>
export const useCurrentCampaignDetails = <T = CampaignDetails>({
  enabled,
  select,
}: UseCurrentCampaignDetailsProps<T> = {}): UseQueryResult<T> => {
  const { campaignId }: { campaignId: string } = useParams()
  return useCampaignDetails({ campaignId, enabled, select })
}

export const useCurrentParentCampaignDetails = (): UseQueryResult<CampaignDetails> => {
  const { data: parentId } = useCurrentCampaignDetails({ select: (campaign) => campaign.parentSmsCampaignId })
  return useCampaignDetails({ campaignId: parentId ?? '', enabled: !!parentId })
}

export const useCampaignFilterSummary = ({ campaignId }: { campaignId?: string }): string => {
  const { data: campaignDetails } = useCampaignDetails({ campaignId })
  const { data: communities } = useCommunities()

  return useMemo(() => {
    if (!campaignDetails || !communities) return ''
    return humanizeComplexFilter(
      digitsFilterToMemberDataFilter(
        convertKeysToSnakeCase(campaignDetails.filters),
        campaignDetails.fanSubscriptionTags,
      ),
      campaignDetails.recipientCount,
      {
        communities: communities?.all.reduce((acc, community) => ({ ...acc, [community.id]: community }), {}),
        communicationChannel: campaignDetails.communicationChannel,
      },
    )
  }, [campaignDetails, communities])
}

export const useCurrentCampaignFilterSummary = (): string => {
  const match = useRouteMatch<{ campaignId: string }>('/campaigns/:campaignId')
  const { campaignId } = match?.params || {}
  return useCampaignFilterSummary({ campaignId })
}

type UseCampaignProps = {
  campaignId: string
  options: {
    communities: any[]
  }
  enabled?: boolean
}

export type UseCampaignResult = Omit<CampaignDetails, 'filters' | 'fanSubscriptionTags'> & {
  fanSubscriptionTags: {
    any: Partial<Community>[]
    not: Partial<Community>[]
  }
  level: number
  setLevel: Dispatch<SetStateAction<number>>
}

export const useCampaign = ({ campaignId, enabled = true, options }: UseCampaignProps): UseCampaignResult => {
  const [level, setLevel] = useState(0)
  const result = useCampaignDetails({ campaignId, enabled })

  const fanSubscriptionTags = useMemo(() => {
    if (!result.data) return { any: [], not: [] }

    const tags = result.data.fanSubscriptionTags

    return {
      any: mapFanSubscriptionTags(tags?.any, options?.communities),
      not: mapFanSubscriptionTags(tags?.not, options?.communities),
    }
  }, [options.communities, result.data])

  if (!result.data) {
    return {} as UseCampaignResult
  }

  return {
    ...result.data,
    level,
    setLevel,
    fanSubscriptionTags,
  }
}
