import { WorkflowCardConfig } from '@community_dev/flexible-canvas'
import { Filter, Layout, ListItem, LoadingIndicator, PlusCircleIcon, SearchBar, SPACING } from '@community_dev/pixels'
import escapeStringRegexp from 'escape-string-regexp'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Community } from 'api/community'
import { CommunityDot } from 'components/CommunityDot'
import { CreateCommunity } from 'components/CreateCommunity'
import { VirtualList, VirtualListHandle } from 'components/VirtualList'
import { WorkflowSidebarHeader } from 'components/WorkflowEditor/components/WorkflowSidebarHeader/WorkflowSidebarHeader'
import { ROUTES } from 'constants/routes'
import { BoundsProvider } from 'contexts/BoundsProvider'
import { useCommunities } from 'hooks/useCommunities'
import { useToastMessage } from 'hooks/useToastMessage'
import { CancelLink, DoneButton } from 'screens/WorkflowScreen/WorkflowScreen.style'
import { route } from 'utils/router'

const StyledNoResults = styled.p`
  text-align: center;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  margin: 0;
`

const StyledVirtualList = styled(VirtualList)`
  margin: 0;
  overflow: auto;

  li > div {
    padding: 0 ${SPACING[4]};
  }
`

const StyledAdd = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`

type WorkflowCommunitySelectProps = {
  tagId: string | null
  workflowCardConfig: WorkflowCardConfig
  onSubmit: () => void
  setTagId: Dispatch<SetStateAction<string | null>>
}

export function WorkflowCommunitySelect({
  tagId,
  workflowCardConfig,
  onSubmit,
  setTagId,
}: WorkflowCommunitySelectProps): JSX.Element | null {
  const { t } = useTranslation()
  const { COLORS } = useTheme()
  const { showToastMessage } = useToastMessage()
  const { workflowId } = useParams<{ workflowId: string }>()
  const virtualRef = useRef<VirtualListHandle>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortField, setSortField] = useState<'name' | 'date'>('name')
  const [ascending, setAscending] = useState(true)
  const { isLoading, data: communities } = useCommunities()

  const up = ascending ? 1 : -1
  const down = -up

  function onSearchChange(event) {
    setSearchTerm(event.target.value)
  }

  function onCreateCommunity(community: Community) {
    showToastMessage({
      message: `${community?.title || 'Community'} has been created`,
    })
  }

  const filteredItems = communities?.custom
    ?.filter((community) =>
      searchTerm ? new RegExp(escapeStringRegexp(searchTerm), 'ig').test(community.title) : true,
    )
    .sort((a, b) => {
      switch (sortField) {
        case 'name':
          if (a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase()) return down
          return a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() ? up : 0
        case 'date':
          if (a.insertedAt < b.insertedAt) return down
          return a.insertedAt > b.insertedAt ? up : 0
        default:
          return 0
      }
    })

  useEffect(() => {
    virtualRef.current?.measure()
  }, [communities?.custom, filteredItems])

  return (
    <BoundsProvider>
      <Layout display="flex" flexDirection="column" height="100%" width="100%">
        <Layout marginTop={SPACING[2]} padding={SPACING[4]}>
          <WorkflowSidebarHeader
            cta={
              <CreateCommunity onCreate={onCreateCommunity}>
                {({ toggleOpen }) => (
                  <StyledAdd aria-label={t('communities.addCommunity')} onClick={toggleOpen}>
                    <PlusCircleIcon color={COLORS?.BUTTON_PRIMARY_TEXT} fill={COLORS?.BUTTON_PRIMARY} size={32} />
                  </StyledAdd>
                )}
              </CreateCommunity>
            }
            workflowAction={workflowCardConfig}
          />
        </Layout>
        <Layout flex="1" overflow="auto">
          <Layout marginBottom={SPACING[2]} padding={`${SPACING[4]} ${SPACING[4]} 0`}>
            <SearchBar onChange={onSearchChange} placeholder={t('communities.searchCommunities')} value={searchTerm} />
            <Filter
              activeFilter={sortField === 'date' ? t('communities.dateCreated') : t('communities.name')}
              icon={false}
              label={t('communities.sortBy')}
              width="300px"
            >
              <Filter.Option onClick={() => setSortField('name')} selected={sortField === 'name'}>
                {t('communities.name')}
              </Filter.Option>
              <Filter.Option onClick={() => setSortField('date')} selected={sortField === 'date'}>
                {t('communities.dateCreated')}
              </Filter.Option>
              <Filter.Sort
                direction={ascending ? 'ascending' : 'descending'}
                onClick={() => setAscending(!ascending)}
              />
            </Filter>
          </Layout>
          {isLoading || !filteredItems ? (
            <LoadingIndicator />
          ) : filteredItems.length ? (
            <StyledVirtualList ref={virtualRef} rows={filteredItems} testId="communities-list">
              {({ virtualRow }) => {
                const community = filteredItems[virtualRow.index]
                const { id, color, title, updateInProgress } = community
                const subtext = updateInProgress ? t('communities.addingMembers') : ''

                return (
                  <ListItem
                    as="div"
                    data-testid={`community-item-${id}`}
                    label={title}
                    onClick={() => setTagId(id)}
                    prefix={<CommunityDot color={color} />}
                    selected={tagId === id}
                    subtext={subtext}
                  />
                )
              }}
            </StyledVirtualList>
          ) : (
            <StyledNoResults>{t('noResultsFor', { searchTerm })}</StyledNoResults>
          )}
        </Layout>

        <Layout
          alignItems="center"
          borderTop={`1px solid ${COLORS?.BORDERS}`}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding="45px 16px"
        >
          <DoneButton disabled={!tagId} onClick={onSubmit} type="button">
            {t('done')}
          </DoneButton>
          <CancelLink style={{ marginTop: SPACING[5] }} to={route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId })}>
            {t('cancel')}
          </CancelLink>
        </Layout>
      </Layout>
    </BoundsProvider>
  )
}
