export const RECURLY_VISA = 'Visa'
export const RECURLY_MASTER_CARD = 'MasterCard'
export const RECURLY_AMERICAN_EXPRESS = 'American Express'
export const RECURLY_DISCOVER = 'Discover'
export const RECURLY_JCB = 'JCB'
export const RECURLY_DINERS_CLUB = 'Diners Club'
export const STRIPE_VISA = 'visa'
export const STRIPE_MASTER_CARD = 'mastercard'
export const STRIPE_AMERICAN_EXPRESS = 'amex'
export const STRIPE_DISCOVER = 'discover'
export const STRIPE_DINERS_CLUB = 'diners'
export const STRIPE_JCB = 'jcb'
