import { Button, EditableText, Ellipsis, Layout, ReplyIcon, SPACING } from '@community_dev/pixels'
import { Pill } from '@community_dev/pixels'
import { CampaignStatus } from '@community_dev/types/lib/api/v2/Campaign'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { useCampaignText } from '../hooks/useCampaignText'
import { MediaPreview } from '../MediaPreview/MediaPreview'

import { Campaign } from 'api/campaign'
import { patchMessageQuery } from 'api/message'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { replacePlaceholdersWithNames } from 'components/ComposeEditor/util/replacePlaceholders'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useToastMessage } from 'hooks/useToastMessage'
import Sentry from 'integrations/Sentry'
import analytics from 'utils/analytics'
import { screens } from 'utils/analytics/events'

const StyledReplyButton = styled(Button)`
  border: none;
  border-radius: 20px;
  margin-top: ${SPACING[2]};
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
  color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
  &:hover {
    background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
  }
  &:active {
    color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
  }
`

const StyledReply = styled.div`
  padding: ${SPACING[2]};
  margin-right: ${SPACING[3]};
  background-color: ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: 50%;
  max-height: 32px;
  align-self: center;
`

const StyledCell = styled.div<{ $level: number }>`
  margin-left: ${({ $level }) => $level * 8 + 'px'};

  &:not(:first-of-type) {
    margin-top: ${SPACING[2]};
  }
`

const StyledLabel = styled.div`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  text-align: left;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  margin-top: 0;
  margin-bottom: ${SPACING[1]};
  max-width: 100%;
`

const StyledHeading = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  margin: 0;
`

export type CampaignCellDetailsProps = {
  campaign: Campaign
}

export function CampaignCellDetails(props: CampaignCellDetailsProps): JSX.Element {
  const { campaign } = props
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { COLORS } = useTheme()
  const { showToastMessage } = useToastMessage()
  const clientId = useClientId()

  const { data: communities } = useCommunities()
  const { sentTo, messageText } = useCampaignText(campaign, communities?.all || [])

  const isReply = campaign?.parentSmsCampaignId !== null

  const { mutate: patchMessage } = useMutation(patchMessageQuery, {
    onSuccess: () => {
      analytics.track(
        analytics.events.EditCampaignName({
          page: screens.Campaigns,
        }),
      )
      queryClient.invalidateQueries([QUERY_CACHE.CAMPAIGN.LIST])
      queryClient.invalidateQueries([QUERY_CACHE.CAMPAIGN.DETAILS])
      showToastMessage({
        message: t('updatedCampaignName'),
      })
    },
    onError: (error) => {
      showToastMessage({
        message: t('failedToUpdateCampaignName'),
        success: false,
      })

      Sentry.captureException(error)
    },
  })

  return (
    <Layout width="100%">
      <Layout display="flex" width="100%">
        {isReply && (
          <StyledReply>
            <ReplyIcon color={COLORS?.BUTTON_PRIMARY} size={16} />
          </StyledReply>
        )}
        <Layout width="100%">
          <StyledHeading id={`${campaign.id}-title`}>
            <Layout alignItems="center" display="inline-flex" maxWidth="calc(100% - 20px)">
              <EditableText
                aria-label={t('editCampaignName')}
                defaultValue={campaign.name || undefined}
                emptyValue={sentTo}
                onSave={(name) => {
                  if (name.trim() !== campaign.name) {
                    patchMessage({
                      body: {
                        name: name.trim(),
                      },
                      campaignId: campaign.id,
                      clientId,
                    })
                  }
                }}
                placeholder={t('enterCampaignName')}
                showOnHoverEffect={false}
                titleComponent="div"
              />
              <Layout display="flex" flexShrink={0} marginLeft={`${SPACING[2]}`}>
                <CommunicationChannelIcon
                  communicationChannel={campaign.communicationChannel}
                  isAvatar={false}
                  size={16}
                />
              </Layout>
              {campaign?.testCampaign && (
                <Layout marginLeft={`${SPACING[2]}`}>
                  <Pill
                    style={{
                      color: COLORS.BADGE_INFO_TEXT,
                      backgroundColor: COLORS.BADGE_INFO_BACKGROUND,
                      padding: '5px 12px',
                    }}
                  >
                    Test
                  </Pill>
                </Layout>
              )}
            </Layout>
          </StyledHeading>
          <StyledLabel>
            <Ellipsis lines={1}>{replacePlaceholdersWithNames(messageText, campaign?.placeholders || [])}</Ellipsis>
          </StyledLabel>
        </Layout>
      </Layout>
      <MediaPreview
        linkCount={campaign?.clientUrls?.length || 0}
        links={campaign?.linkInfo}
        media={campaign?.media}
        processing={
          // when the status of the campaign is template_pending, and the
          // preview url of the media item is null, we say that the media is
          // still transcoding, and display it as a separate status.
          campaign?.status === CampaignStatus.TEMPLATE_PENDING || campaign.status === CampaignStatus.MEDIA_PROCESSING
        }
      />
    </Layout>
  )
}

export type CampaignCellProps = {
  campaign: Campaign
  repliesVisible?: boolean
  onToggleReplies?: () => void
}

export function CampaignCell(props: CampaignCellProps): JSX.Element {
  const { t } = useTranslation()
  const { campaign, repliesVisible, onToggleReplies } = props

  const isReply = campaign?.parentSmsCampaignId !== null
  const hasReplies = Boolean(campaign?.replies?.length)

  return (
    <StyledCell $level={campaign.nestLevel}>
      <CampaignCellDetails campaign={campaign} />

      {hasReplies && !isReply && (
        <StyledReplyButton
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            typeof onToggleReplies == 'function' && onToggleReplies()
          }}
        >
          {repliesVisible ? t('campaigns.collapseReplies') : t('campaigns.showReplies')}
        </StyledReplyButton>
      )}
    </StyledCell>
  )
}
