import { AudioPlayer, BUTTON_VARIANTS, Button, Typography } from '@community_dev/pixels'
import React from 'react'
import styled from 'styled-components'

import { ROUTES } from 'constants/routes'
import { StyledAction, StyledBack, StyledTextButton } from 'screens/Onboarding/styled'

const StyledAudioPlayer = styled.div`
  display: flex;
  justify-content: center;
  margin: 80px 0;
`

type PreviewProps = {
  confirm?: boolean
  processing?: boolean
  voicemail?: string
  onEdit: (...args: any[]) => any
  onConfirm?: (...args: any[]) => any
}

export function Preview({
  confirm = false,
  processing = false,
  voicemail,
  onEdit,
  onConfirm,
}: PreviewProps): JSX.Element {
  return (
    <>
      <Typography variant="h1">Your voicemail</Typography>
      <StyledAudioPlayer>
        <AudioPlayer src={voicemail as string} />
      </StyledAudioPlayer>
      {confirm ? (
        <>
          <StyledAction>
            <Button disabled={processing} onClick={onConfirm} variant={BUTTON_VARIANTS.ACTION}>
              {processing ? 'Saving' : 'Confirm'}
            </Button>
          </StyledAction>
          <StyledTextButton disabled={processing} onClick={onEdit}>
            Edit
          </StyledTextButton>
        </>
      ) : (
        <>
          <StyledAction>
            <Button disabled={processing} onClick={onEdit} variant={BUTTON_VARIANTS.ACTION}>
              Edit
            </Button>
          </StyledAction>
          <StyledBack to={ROUTES.ONBOARDING.SUMMARY}>Back</StyledBack>
        </>
      )}
    </>
  )
}
