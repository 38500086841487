import { AudioPlayer, Box, Image, Toast, Typography } from '@community_dev/pixels'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { Description } from '../components/Description'
import { Examples } from '../components/Examples'
import { ONBOARDING_TASKS, ONBOARDING_TASK_COMPLETE } from '../constants'
import { useCompleteClientTask, useUpdateVoicemail } from '../queries'

import { Preview } from './components/Preview'
import { RecordVoicemail } from './components/RecordVoicemail'

import { ROUTES } from 'constants/routes'
import { useClient } from 'hooks/useClient'
import Sentry from 'integrations/Sentry'
import texts from 'texts.json'
import { generalConfig } from 'utils/config'
import { getFileFromUrl } from 'utils/file'

const StyledImage = styled(Image)`
  margin-bottom: 4px;
`

const StyledSlide = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 15px 0 36px;
  text-align: left;
`

enum State {
  INITIAL = 'initial',
  EDIT = 'edit',
  PREVIEW = 'preview',
  CONFIRM = 'confirm',
}

const PREVIEW_STATES = [State.PREVIEW, State.CONFIRM]

const TIPS = ['Ask them to text you instead', 'Let them know you don’t receive voicemails']

export function Voicemail(): JSX.Element {
  const history = useHistory()
  const { data: client } = useClient()
  const task = client?.onboardingTasks?.tasks[ONBOARDING_TASKS.VOICEMAIL]
  const voicemailUrl = client?.voicemailUrl
  const [state, setState] = useState<State>(State.INITIAL)
  const [url, setUrl] = useState<string>()
  const [error, setError] = useState<string>()
  const { mutate: updateTask } = useCompleteClientTask({
    onError(e) {
      setError(texts.errors.generic)
      Sentry.captureException(e)
    },
    onSuccess() {
      history.push(ROUTES.ONBOARDING.SUMMARY)
    },
  })
  const { mutate: submit, isLoading: isProcessing } = useUpdateVoicemail({
    onError(e) {
      setError(texts.errors.generic)
      Sentry.captureException(e)
    },
    onSuccess() {
      updateTask({ id: task!.id })
    },
  })

  async function confirm() {
    if (!url) return
    try {
      const voicemail = await getFileFromUrl(url, 'voicemail', {
        type: 'audio/mpeg',
      })
      const body = new FormData()
      setError(undefined)
      body.append('voicemail', voicemail)
      await submit({ body })
    } catch (e: any) {
      setError(e.message)
      Sentry.captureException(e)
    }
  }

  useEffect(() => {
    if (!task) return
    setState(task.status === ONBOARDING_TASK_COMPLETE ? State.PREVIEW : State.INITIAL)
  }, [task])

  useEffect(() => {
    if (!voicemailUrl) return
    setUrl(voicemailUrl)
  }, [voicemailUrl])

  function handleOnUrl(fileUrl) {
    setUrl(fileUrl)
    setState(State.CONFIRM)
  }

  return (
    <>
      <Box>
        {error && (
          <Box.ToastArea>
            <Toast icon message={error} success={false} />
          </Box.ToastArea>
        )}
        <Box.Content>
          {state === State.INITIAL && (
            <Description
              actionLabel="Set custom voicemail"
              animation="animation-voicemail.gif"
              color="#f4f9fc"
              onAction={() => setState(State.EDIT)}
            >
              <Typography variant="h1">Voicemail</Typography>
              <p>Upload a voicemail for people who call your number and encourage them to text.</p>
            </Description>
          )}
          {state === State.EDIT && (
            <RecordVoicemail
              onCancel={
                url ? () => setState(voicemailUrl && voicemailUrl === url ? State.PREVIEW : State.CONFIRM) : undefined
              }
              onUrl={handleOnUrl}
            />
          )}
          {state === State.PREVIEW && <Preview onEdit={() => setState(State.EDIT)} voicemail={url} />}
          {state === State.CONFIRM && (
            <Preview
              confirm
              onConfirm={confirm}
              onEdit={() => setState(State.EDIT)}
              processing={isProcessing}
              voicemail={url}
            />
          )}
        </Box.Content>
      </Box>
      {!PREVIEW_STATES.includes(state) && (
        <Examples
          arrowVerticalOffset={26}
          examples={{
            edSheeran: (
              <StyledSlide>
                <AudioPlayer
                  label="Ed Sheeran"
                  prefix={
                    <StyledImage
                      alt="Ed Sheeran"
                      src={[
                        `${generalConfig.staticUrl}/avatars/ed-sheeran%403x.png 3x`,
                        `${generalConfig.staticUrl}/avatars/ed-sheeran%402x.png 2x`,
                        `${generalConfig.staticUrl}/avatars/ed-sheeran.png 1x`,
                      ]}
                    />
                  }
                  src={`${generalConfig.staticUrl}/voicemail/ed-sheeran.mp3`}
                />
              </StyledSlide>
            ),
            laDodgers: (
              <StyledSlide>
                <AudioPlayer
                  label="Los Angeles Dodgers"
                  prefix={
                    <StyledImage
                      alt="Los Angeles Dodgers"
                      src={[
                        `${generalConfig.staticUrl}/avatars/la-dodgers%403x.png 3x`,
                        `${generalConfig.staticUrl}/avatars/la-dodgers%402x.png 2x`,
                        `${generalConfig.staticUrl}/avatars/la-dodgers.png 1x`,
                      ]}
                    />
                  }
                  src={`${generalConfig.staticUrl}/voicemail/la-dodgers.mp3`}
                />
              </StyledSlide>
            ),
            xGames: (
              <StyledSlide>
                <AudioPlayer
                  label="X Games"
                  prefix={
                    <StyledImage
                      alt="X Games"
                      src={[
                        `${generalConfig.staticUrl}/avatars/x-games%403x.png 3x`,
                        `${generalConfig.staticUrl}/avatars/x-games%402x.png 2x`,
                        `${generalConfig.staticUrl}/avatars/x-games.png 1x`,
                      ]}
                    />
                  }
                  src={`${generalConfig.staticUrl}/voicemail/x-games.mp3`}
                />
              </StyledSlide>
            ),
          }}
          tips={TIPS}
        />
      )}
    </>
  )
}
