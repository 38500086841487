export enum CampaignStatus {
  TEMPLATE_PENDING = 'template_pending',
  TEMPLATE_REJECTED = 'template_rejected',
  TEMPLATE_FAILED = 'template_failed',
  CANCELED = 'canceled',
  FAILED = 'failed',
  INITIALIZED = 'initialized',
  PROCESSED = 'processed',
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  MEDIA_PROCESSING = 'media_processing',
  MEDIA_TRANSCODING_FAILED = 'media_transcoding_failed',
  DELETED = 'deleted',
  SUBMITTED_FOR_APPROVAL = 'submitted_for_approval',
}

export enum ResponseTags {
  QUESTION = 'question',
  THANK_YOU = 'thank-you',
  LOVING = 'loving',
  HAPPY = 'happy',
  YES = 'yes',
  NO = 'no',
  TAPBACK = 'tapback',
  REPLIED_TO = 'replied-to',
}
