import { SPACING } from '@community_dev/pixels'
import { FanRegistrationCoreFieldName } from '@community_dev/types/lib/api/FanRegistration'
import { GenderOptions } from '@community_dev/types/lib/api/FanRegistration'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFanRegistrationField } from 'hooks/useClientSettings'
import { PieChart } from 'screens/InsightsScreen/charts/PieChart'
import { InsightsBox } from 'screens/InsightsScreen/presentation/InsightsBox'
import { UnitDisplay } from 'screens/InsightsScreen/UnitDisplay'
import { formatNumeral } from 'utils/general'

const computeSlices = (data, visibleOptions) => {
  const ageBuckets = data.age_gender_insights[0].ages
  const totalFans = data.age_gender_insights[0].total_reported_gender
  const getSum = (gender) => Object.keys(ageBuckets).reduce((sum, bucket) => sum + ageBuckets[bucket][gender], 0)

  // We only want to show the pie for the visible options
  const visibleSlices = GenderOptions.filter((option) => visibleOptions.includes(option.label))

  const visibleSlicesWithData = visibleSlices.map((slice) => ({
    ...slice,
    value: getSum(slice.value),
  }))

  const slices = visibleSlicesWithData.map((s) => ({
    label: s.label,
    value: s.value,
    percent: s.value / totalFans,
    valueLabel: (
      <UnitDisplay.Animated
        count={s.value}
        height="22px"
        percentOptions={{ maximumFractionDigits: 0 }}
        total={totalFans}
      />
    ),
  }))

  return slices
}

export type GenderChartBoxProps = {
  demographicInsights: any
  locked?: boolean
}

export const GenderChartBox = ({ demographicInsights, locked }: GenderChartBoxProps): JSX.Element => {
  const { t } = useTranslation()
  const queries = [demographicInsights.ageAndGenderQuery]
  const { label: genderLabel, options = [] } = useFanRegistrationField(FanRegistrationCoreFieldName.GENDER_NAME) || {}
  const data = demographicInsights.ageAndGenderQuery?.data

  const hasData = data?.age_gender_insights?.length > 0 && data?.age_gender_insights?.[0].total_reported_gender > 0

  const slices = useMemo(() => {
    return hasData ? computeSlices(data, options) : []
  }, [data, hasData, options])

  const totalFans = data?.age_gender_insights?.[0]?.total_reported_gender
  const totalFansText = formatNumeral(totalFans)
  const infoText = t('generalInsights.infoText', { total: totalFansText, type: 'gender identity' })

  return (
    <InsightsBox.Managed
      infoText={infoText}
      isEmpty={!hasData}
      locked={locked}
      queries={queries}
      title={genderLabel || 'Gender Identity'}
    >
      {() => (
        <div style={{ marginTop: SPACING[5] }}>
          <PieChart
            // size={200}
            showTotal={false}
            slices={slices}
            total={data.age_gender_insights[0].total_reported_gender}
          />
        </div>
      )}
    </InsightsBox.Managed>
  )
}
