import styled from 'styled-components'

export const StyledInboxList = styled.div<{ $hideMobile: boolean }>`
  border-right: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;

  ${({ $hideMobile }) =>
    $hideMobile &&
    `
      display: none;
      visibility: hidden;
      @media (min-width: 720px) {
        display: flex;
        visibility: visible;
      }
    `}
`
