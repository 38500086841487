import { HTTP } from '@community_dev/requests'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type SupportedAppVersions = {
  data: {
    blacklisted_versions: string[]
    minimum_version: string
  }
}

export function getSupportedAppVersions(): Promise<CamelCasedPropertiesDeep<SupportedAppVersions>> {
  return request(route(ENDPOINTS.METADATA.APP_VERSIONS), {
    method: HTTP.GET,
    noAuth: true,
  })
}
