import { SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import styled from 'styled-components'

import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { MemberAvatar } from 'components/MemberAvatar'
import { TABLET_BREAK } from 'constants/theme'

const StyledFanGraphic = styled.button`
  display: none;
  border: none;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  margin-bottom: 5px;
  flex-shrink: 0;
  height: 100%;

  @media (min-width: ${TABLET_BREAK}) {
    display: block;
  }
`

const StyledInfo = styled.div`
  width: 100%;
  min-width: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${TABLET_BREAK}) {
    align-items: flex-start;
    padding: 0 8px;
  }
`

const StyledName = styled.button`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledOverview = styled.button`
  display: none;
  max-width: 100%;
  padding: 0;
  margin-top: 1px;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  background: none;

  @media (min-width: ${TABLET_BREAK}) {
    display: block;
  }
`

const StyledFan = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`

type MemberDetailsProps = {
  fan: {
    fullName: string
    graphicUrl?: string
    overview: string
    communicationChannel?: CommunicationChannel
  }
  onClick?: () => void
}

export function MemberDetails({ fan, onClick }: MemberDetailsProps): JSX.Element {
  const { fullName, graphicUrl, overview } = fan

  return (
    <StyledFan>
      <StyledFanGraphic onClick={onClick}>
        <MemberAvatar
          badge={
            <CommunicationChannelIcon
              communicationChannel={fan.communicationChannel}
              filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))"
              size={19}
              style={{ marginTop: SPACING[2] }}
            />
          }
          fan={fan}
          graphicUrl={graphicUrl}
        />
      </StyledFanGraphic>
      <StyledInfo>
        <StyledName data-private="redact" onClick={onClick}>
          {fullName}
        </StyledName>
        <StyledOverview data-private="redact" onClick={onClick}>
          {overview}
        </StyledOverview>
      </StyledInfo>
    </StyledFan>
  )
}
