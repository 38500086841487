import { Map, Record, Set } from 'immutable'
const last = (arr) => arr[arr.length - 1]

export enum Selection {
  // SELECTED     - this node is selected (either explicitly, or implicitly
  //                because a parent is selected or the mode is includeAll), and
  //                no children are excluded
  SELECTED = 'selected',
  // PARTIAL      - this node is selected, and one or more children are excluded
  PARTIAL = 'partial',
  // NOT_SELECTED - this node is not selected (either explicitly via excludes, or
  //                implicitly because a parent is excluded or the mode is
  //                excludeAll), and no children are included
  NOT_SELECTED = 'not-selected',
}

export type SelectionState = `${Selection}`

export type KeyPath = string[]

// top-level selection
export enum Modes {
  MODE_INCLUDE = 'includeAll',
  MODE_EXCLUDE = 'excludeAll',
}

export type RootMode = `${Modes}`

export type ResponseType = 'cluster' | 'fanSubscription'

type IncludeExcludeStateStructure = {
  // includeAll - all items are included by default
  // excludeAll - no items are included by default
  mode: `${Modes}`
  // this is a key -> keyPath map of items that should be included
  includes: Map<string, KeyPath>
  // this is a key -> keyPath map of items that should be excluded
  excludes: Map<string, KeyPath>
  // this is a key -> [childKey, childKey, childKey] map that is used to
  // enumerate sibling nodes during select/deselect operations
  childMap: Map<string, Set<string[]>>
  // this is a key => string map of types that is used to provide type
  // hinting in situations where the IncludeExcludeState is representing
  // a heterogenous collection of elements (i.e. both clusters and fan subs)
  typeMap: Map<string, ResponseType>
  // when true, attempting to toggle a key without providing type hints
  // will result in an error being thrown
  requireTypes: boolean
  // This is a map of ids with details on replied and cluster id's
  subscriptionIdMap: Map<string, { clusterId: string; repliedId: string; repliedTo?: boolean }>
}

export const IncludeExcludeState = Record<IncludeExcludeStateStructure>({
  // by default, we set the `excludeAll` mode
  mode: Modes.MODE_EXCLUDE,
  includes: Map(),
  excludes: Map(),
  childMap: Map(),
  typeMap: Map(),
  requireTypes: true,
  subscriptionIdMap: Map(),
})

export type IncludeExcludeRecord = ReturnType<typeof IncludeExcludeState>

// Get the selection state for the provided keyPath.
export const isSelected = (state: IncludeExcludeRecord, keyPath?: KeyPath): SelectionState => {
  if (!keyPath) {
    return Selection.NOT_SELECTED
  }

  // if any child include/exclude rules exist for the node, it is considered
  // partially selected. eg. if a child is excluded but parent is selected
  if (_hasChildrenRules(state, keyPath)) return Selection.PARTIAL

  // otherwise, if the node is included via either an explicit "includes"
  // rule or a parent "includes" rule, it is considered fully selected
  if (_isKeyPathIncluded(state, keyPath)) return Selection.SELECTED

  // if none of the above two cases apply, the node is considered deselected
  return Selection.NOT_SELECTED
}

// same as above, but for the virtual "root node" which is represented by the
// "mode"
export const isRootSelected = (state: IncludeExcludeRecord): SelectionState => {
  const { includes, excludes, mode } = state
  const hasAnyRules = includes.size > 0 || excludes.size > 0

  // NOTE: why does the root have "modes" if the return is a Selection anyways?
  if (hasAnyRules) return Selection.PARTIAL
  if (mode === Modes.MODE_INCLUDE) return Selection.SELECTED

  return Selection.NOT_SELECTED
}

// toggle an item, either selecting or deselecting it based on its current
// selection state
export const toggle = (state: IncludeExcludeRecord, keyPath: KeyPath, type?: string): IncludeExcludeRecord => {
  // guard against missing type hints
  if (state.requireTypes && !type) {
    throw Error('this IncludeExcludeState requires a type hint but none was provided')
  }
  // record the type hint if provided
  const withTypeHint = type ? state.setIn(['typeMap', last(keyPath)], type) : state

  switch (isSelected(withTypeHint, keyPath)) {
    case Selection.SELECTED:
    case Selection.PARTIAL:
      return deselect(withTypeHint, keyPath)
    case Selection.NOT_SELECTED:
      return select(withTypeHint, keyPath)
    default:
      console.warn('Invalid IncludeExcludeState detected')
      return withTypeHint
  }
}

// select an item and all of its children
export const select = (state: IncludeExcludeRecord, keyPath: KeyPath): IncludeExcludeRecord => {
  const parent = keyPath.slice(0, -1)
  const parentIncluded = _isKeyPathIncluded(state, parent)
  const key = last(keyPath)

  // If the parent is deselected but all sibling nodes are fully included, select
  // the parent.
  //
  // NOTE: so this is an optimization:
  // (deselected parent) -> [selected child, selected child, selected child]
  // becomes just: (selected parent)
  const hasParent = parent && parent.length > 0
  if (hasParent && !_isKeyPathIncluded(state, parent) && _allSiblingsIncluded(state, keyPath)) {
    return select(state, parent)
  }
  // If we're not in that special case, proceed as usual:

  // first off, no matter what we're going to clear the child rules, and make
  // sure that this node is not excluded, so do that up front:
  const pruned = _pruneChildrenRules(state, keyPath).deleteIn(['excludes', key])
  // there are now two possibilities:
  // (1) if we're deselected because of an ancestor exclusion rule, then we need
  //     to explicitly include this node
  if (!parentIncluded) {
    return pruned.setIn(['includes', key], parent)
  }
  // (2) if we're deselected because is specific node is explicitly excluded,
  //     then we're all set
  return pruned
}

// deselect an item and all of its children
export const deselect = (state: IncludeExcludeRecord, keyPath: KeyPath): IncludeExcludeRecord => {
  const parent = keyPath.slice(0, -1)
  const parentIncluded = _isKeyPathIncluded(state, parent)
  const key = last(keyPath)

  // If the parent is selected and all the sibling nodes are excluded
  // deselect the parent.
  const hasParent = parent && parent.length > 0
  if (hasParent && _isKeyPathIncluded(state, parent) && _allSiblingsExcluded(state, keyPath)) {
    return deselect(state, parent)
  }
  // If we're not in that special case, proceed as usual:

  // first off, no matter what we're going to clear the child rules, and make
  // sure that this node is not included, so do that now:
  const pruned = _pruneChildrenRules(state, keyPath).deleteIn(['includes', key])
  // there are now two possibilities:
  // (1) if we're selected because of an ancestor include rule, then we need
  //     to explicitly exclude the node
  if (parentIncluded) {
    return pruned.setIn(['excludes', key], parent)
  }
  // (2) if we're selected because the node is explicitly included, then
  //     we simply need to remove the node from the inclusion array
  return pruned
}

// toggle the virtual root node, following the same semantics as toggling
// a normal node
export const toggleRoot = (state: IncludeExcludeRecord): IncludeExcludeRecord => {
  switch (isRootSelected(state)) {
    // if anything is selected in the whole tree,
    // return a fully-deselected state
    case Selection.SELECTED:
    case Selection.PARTIAL:
      return state.merge({
        mode: Modes.MODE_EXCLUDE,
        includes: Map(),
        excludes: Map(),
        typeMap: Map(),
      })
    // otherwise, return a fully-selected state
    case Selection.NOT_SELECTED:
      return state.merge({
        mode: Modes.MODE_INCLUDE,
        includes: Map(),
        excludes: Map(),
        typeMap: Map(),
      })
    default:
      console.warn('Invalid IncludeExcludeState detected')
      return state
  }
}

export const recordChildMapping = (
  state: IncludeExcludeRecord,
  parentId: string,
  childIds: string[],
): IncludeExcludeRecord => {
  return state.mergeDeep(Map({ childMap: Map({ [parentId]: Set(childIds) }) }))
}

export const addFanSubscriptionId = (
  state: IncludeExcludeRecord,
  fanSubscriptionId: string,
  repliedTo: boolean,
  clusterId: string,
): IncludeExcludeRecord => {
  return state.mergeDeep(
    Map({
      subscriptionIdMap: Map({
        [fanSubscriptionId]: {
          fanSubscriptionId,
          clusterId,
          repliedTo,
        },
      }),
    }),
  )
}

// ALL THE STUFF DOWN HERE IS "private" in the sense that it exposes
// fine-grained functionality that requires a strong understanding of how this
// code works in order to use correctly. Most consumers of the
// IncludeExcludeState should stick to the "public" functions exposed above,
// which provide a much more user-friendly interface than the functions
// down here.

// remove all child include/exclude rules for sub-nodes of the given keyPath
const _pruneChildrenRules = (state: IncludeExcludeRecord, keyPath: KeyPath): IncludeExcludeRecord => {
  const { includes, excludes } = state
  const key = last(keyPath)
  const isChild = (ancestors) => ancestors.includes(key)
  return state.merge({
    includes: includes.filter((v) => !isChild(v)),
    excludes: excludes.filter((v) => !isChild(v)),
  })
}

// check if a given keyPath has any include/exclude rules for its children,
// in the most efficient way possible
const _hasChildrenRules = (state: IncludeExcludeRecord, keyPath: KeyPath): boolean => {
  const { includes, excludes } = state
  const key = last(keyPath)
  const isChild = (ancestors) => ancestors.includes(key)
  return includes.some(isChild) || excludes.some(isChild)
}

// check if a given keyPath is "included", returning a simple boolean.
// this operation differs from the .isSelected operation because it doesn't
// consider the state of a node's children, only the ancestors
//
// This is a performance-critical code path, since it's used for determining
// selection state of every checkbox.
//
// As implemented here, it is O(n) where n represents the length of the
// keyPath. Since the maximum nesting depth of the clustering feature is
// 1, this is therefore essentially O(1) for clustering
//
// *technically* Immutable.Map().has() is O(log32(n)) and not O(1), but for our
// dataset size this is effectively always going to be O(1).
export const _isKeyPathIncluded = (state: IncludeExcludeRecord, keyPath: KeyPath): boolean => {
  const { includes, excludes } = state
  const rootNodeIncluded = state.mode === Modes.MODE_INCLUDE
  // to determine if a path is selected, traverse the node's key path from
  // least-specific (root node) to most-specific (the node itself). The
  // most specific value wins.
  return keyPath.reduce((selected, key) => {
    if (excludes.has(key)) {
      return false
    }
    if (includes.has(key)) {
      return true
    }
    return selected
  }, rootNodeIncluded)
}

// for a given keyPath, get a list of sibling keyPaths using our internal
// understanding of the node tree. This is useful for bubbling full-selection
// and full-deselection to the parent.
export const _getSiblings = (state: IncludeExcludeRecord, keyPath: KeyPath): Set<string> => {
  const key = last(keyPath)
  const parent = keyPath[keyPath.length - 2]
  if (!parent) return Set()
  return state.childMap.get(parent, Set()).delete(key) as Set<string>
}

// Note - these two _allSiblingsIncluded/_allSiblingsExcluded functions
// are the only abstractions in this file that don't support arbitrary levels
// of nesting. Because the clustering is limited to a single level of nesting,
// that means that we can safely assume that sibling nodes *do not* have their
// own children nodes, and therefore we can check for sibling
// inclusion/exclusion via a shallow subset query on the includes/excludes map.
//
// If we wanted this file to support arbitrary levels of nesting, all we'd need
// to do is modify these functions to check for inclusion taking into account
// the sibling subtree rules with something like this:
//
//  siblings.every(
//    sibling => IncludExcludeState.isSelected(state, siblingKeypath) === SELECTED
//  )
//
// However, due to the extra time complexity of the solution above and the fact
// that we do not need to support arbitrary nesting for the clustering feature,
// it makes more sense to use the shallow check for now.
export const _allSiblingsIncluded = (state: IncludeExcludeRecord, keyPath: KeyPath): boolean => {
  const siblings = _getSiblings(state, keyPath)
  if (siblings.size === 0) {
    return false
  }
  return siblings.isSubset(state.includes.keySeq())
}

export const _allSiblingsExcluded = (state: IncludeExcludeRecord, keyPath: KeyPath): boolean => {
  const siblings = _getSiblings(state, keyPath)
  if (siblings.size === 0) {
    return false
  }
  return siblings.isSubset(state.excludes.keySeq())
}
