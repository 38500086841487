import { Button } from '@community_dev/pixels'
import { transparentize } from 'polished'
import styled from 'styled-components'

export const AddPathButton = styled(Button)`
  background: none;
  border: 0;
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  -webkit-appearance: none;
  min-width: 0px;
  padding-right: 5px;

  &:hover {
    background: ${({ theme }) => transparentize(0.8, theme?.COLORS?.BUTTON_DEFAULT_HOVER)};
    border: 0;
  }
`
