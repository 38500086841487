import useMediaQuery from './useMediaQuery'

import { MOBILE_BREAK, TABLET_BREAK, DESKTOP_BREAK, DESKTOP_XL_WITH_SIDEBAR_BREAK } from 'constants/theme'

export type UseBreakpointsValue = {
  sm: boolean
  md: boolean
  lg: boolean
  xl: boolean
  smDown: boolean
  mdDown: boolean
  lgDown: boolean
  xlDown: boolean
}

export default function useBreakpoints(): UseBreakpointsValue {
  const sm = useMediaQuery(`(min-width: ${MOBILE_BREAK})`)
  const md = useMediaQuery(`(min-width: ${TABLET_BREAK})`)
  const lg = useMediaQuery(`(min-width: ${DESKTOP_BREAK})`)
  const xl = useMediaQuery(`(min-width: ${DESKTOP_XL_WITH_SIDEBAR_BREAK})`)

  const smDown = useMediaQuery(`(max-width: ${MOBILE_BREAK})`)
  const mdDown = useMediaQuery(`(max-width: ${TABLET_BREAK})`)
  const lgDown = useMediaQuery(`(max-width: ${DESKTOP_BREAK})`)
  const xlDown = useMediaQuery(`(max-width: ${DESKTOP_XL_WITH_SIDEBAR_BREAK})`)

  return {
    sm,
    md,
    lg,
    xl,
    smDown,
    mdDown,
    lgDown,
    xlDown,
  }
}
