import { useMessageBillingUsage } from '@community_dev/hooks'
import { ConversationIcon, Info, Toast, composeValidators, required } from '@community_dev/pixels'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Field, Form, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import sampleMessageImage from '../../assets/sample-message.png'
import { MessageHint, MessageTips, StyledContent, StyledForm, StyledMultimedia, StyledParagraph } from '../styled'

import { Client } from 'api/client'
import { putClientFanOnboardingMessage } from 'api/onboarding'
import { MultimediaInput } from 'components/MultimediaInput'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERY_CACHE } from 'constants/query-cache'
import { VisualFormSection } from 'containers/Settings/components/Integrations/VisualFormSection'
import { useSettings } from 'contexts/SettingsProvider'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import analytics from 'utils/analytics'

type Props = {
  onPrimaryButtonClick: any
  client: Client
}

const MessageField = ({ name, validators }: { name: string; validators: any[] }): JSX.Element => {
  const currentTheme = useCurrentTheme()
  const { settings } = useSettings()
  const { getFieldState } = useForm()
  const message = getFieldState(name)?.value
  const { links } = useShortenableLinks(message)
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = useMessageBillingUsage({
    linksToShorten: links,
    message,
  })
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }

  return (
    <Field name={name} validate={composeValidators(...validators)} validateOnBlur>
      {({ input }) => (
        <StyledMultimedia>
          <MultimediaInput
            emojiSkinTone={settings.skinTone}
            emojiTheme={currentTheme.type}
            gallery={false}
            gif={false}
            onChange={input.onChange}
            onEmojiSelect={(_, text) => {
              input.onChange({ target: { value: text } })
            }}
            usageCounterProps={usageCounterProps}
            value={input.value}
          />
          <MessageHint>{'You can edit this later, in Settings > Integrations'}</MessageHint>
        </StyledMultimedia>
      )}
    </Field>
  )
}

export const CustomizeWelcomeMessage = (props: Props): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const queryClient = useQueryClient()
  const isAbandonedCheckoutEnabled = useHasCapability(CAPABILITIES.FEATURE.WORKFLOWS.ABANDONED_CHECKOUT)

  const shopifyOrderCompleteMessage = props.client.onboardingMessages.find((i) => i.label === 'shopify_onboarding')
  const shopifyCheckoutMessage = props.client.onboardingMessages.find((i) => i.label === 'import_welcome_message')

  const [error, setError] = useState<{ message: string; subtext: string }>()

  const { mutateAsync: updateOnboardingMessage } = useMutation(putClientFanOnboardingMessage)

  const INITIAL_ORDER_COMPLETE_MESSAGE = `Hey it’s ${props.client.firstName}, this is an autotext to say thank you for your order. Everything else will be from me! Make sure you click the link and add yourself to my phone, so I can respond to you.`
  const INITIAL_CHECKOUT_MESSAGE = `Hey, it’s ${props.client.fullName}.`
  const INITIAL_CHECKOUT_MESSAGE_SUFFIX = t('settings.integrations.shopify.replyYSuffix')

  async function handleCustomizeMessage(body: { text: string }) {
    try {
      if (shopifyOrderCompleteMessage) {
        await updateOnboardingMessage({ id: shopifyOrderCompleteMessage.id, text: body.text })
        analytics.track(analytics.events.ShopifyStoreConnected())
      }
      if (shopifyCheckoutMessage && isAbandonedCheckoutEnabled) {
        await updateOnboardingMessage({
          id: shopifyCheckoutMessage.id,
          text: `${INITIAL_CHECKOUT_MESSAGE} ${INITIAL_CHECKOUT_MESSAGE_SUFFIX}`,
        })
      }
      queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
      showToastMessage({
        message: 'Shopify Store Connected.',
        success: true,
      })
      props.onPrimaryButtonClick()
    } catch (e) {
      setError({
        message: 'Could Not Be Created',
        subtext: 'We’re having temporary problems. Please try again in a minute or two.',
      })
    }
  }

  return (
    <Form<{ text: string }>
      initialValues={{
        text: INITIAL_ORDER_COMPLETE_MESSAGE,
      }}
      onSubmit={handleCustomizeMessage}
      render={({ handleSubmit, valid, submitting }) => (
        <VisualFormSection
          image={sampleMessageImage}
          primaryButton={submitting ? 'Saving...' : 'Save'}
          primaryButtonClick={handleSubmit}
          primaryButtonDisabled={!valid || submitting}
          title="Shopify Welcome Message"
        >
          <div>
            <StyledParagraph>
              Enter a custom welcome message for your Shopify Customers. New Members will receive this message after
              completing your checkout.
            </StyledParagraph>
            <StyledContent $submitting={submitting}>
              {' '}
              {error && <Toast icon message={error.message} subtext={error.subtext} success={false} />}
              <StyledForm>
                <MessageField name="text" validators={[required]} />
              </StyledForm>
            </StyledContent>
            <Info
              heading="Your Welcome Message should include:"
              icon={<ConversationIcon color={COLORS?.SUBTEXT} size={18} />}
            >
              <MessageTips>
                <li>Your name (iPhones will show ‘Maybe: {`${props.client.firstName}`}’)</li>
                <li>Let them know this is an automated message, but everything else will be from you</li>
                <li>Ask people to click the link</li>
              </MessageTips>
            </Info>
          </div>
        </VisualFormSection>
      )}
    />
  )
}
