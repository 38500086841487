import {
  ChatBubbleDirectionValues,
  ChatBubbleTypeValues,
  ChatBubble,
  InvisibleCharacters,
  SPACING,
  linkify,
} from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import emojiRegex from 'emoji-regex'
import mime from 'mime-types'
import { useTranslation } from 'react-i18next'
import styled, { css, useTheme } from 'styled-components'

import { MessageSource, MessageSourceType } from './MessageSource'

import { LinkPreviewType, MiniLinkPreview, RichLinkPreview } from 'components/links'
import MediaPreview from 'components/MediaPreview'
import { useBounds } from 'contexts/BoundsProvider'
import { generalConfig } from 'utils/config'
import { CampaignInsight } from 'utils/normalize'
import parseStringForRichLinks, { ParsedStringForRichLinks } from 'utils/parseStringForRichLinks'
import tapbackToIconMap from 'utils/tapbackToIconMap'

export const BUBBLE_VARIANTS = Object.freeze({
  CHAIN: 'chain',
  CONVERSATION: 'conversation',
})

function bubbleType(emojis) {
  switch (emojis) {
    case 'short':
      return ChatBubbleTypeValues.EMOJI_ONLY
    case 'long':
      return ChatBubbleTypeValues.EMOJI_LONG
    default:
      return ChatBubbleTypeValues.REGULAR
  }
}

const tapbackPhrases = ['Liked', 'Loved', 'Laughed at', 'Questioned', 'Emphasized']

const isMessageResponseTapback = (message) =>
  tapbackPhrases.reduce((accum, phrase) => (accum ? accum : message?.startsWith(phrase)), false)

const StyledClusterArrow = styled(FontAwesomeIcon)`
  margin: 0 0 0 6px;
  color: ${({ theme }) => theme?.COLORS?.DIVIDERS};
  transform: scaleX(-1);
`

type StyledBubbleProps = {
  $hasError?: boolean
}

const StyledMessageSource = styled(MessageSource)`
  padding: 0 ${SPACING[3]} 0 ${SPACING[3]};
`

const StyledBubble = styled.div<StyledBubbleProps>`
  width: 100%;
  position: relative;
  backface-visibility: hidden;

  ${({ $hasError }) =>
    $hasError &&
    css`
      right: ${SPACING[5]};
    `}
`

type StyledMainProps = {
  $incoming?: boolean
}

const StyledMain = styled.div<StyledMainProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${({ $incoming }) => ($incoming ? 'flex-start' : 'flex-end')};
  margin-bottom: ${SPACING[1]};
`

type StyledMessageProps = {
  $isCluster?: boolean
  $stacked2x?: boolean
  $stacked3x?: boolean
}

const StyledMessage = styled.div<StyledMessageProps>`
  position: relative;
  display: inline-flex;
  align-items: center;

  ${({ $isCluster, theme }) =>
    $isCluster &&
    css`
      > div > div {
        box-shadow: -2px -2px ${theme?.COLORS?.CHAT_BACKGROUND},
          -4px -4px ${theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_RECEIVED}, -6px -6px ${theme?.COLORS?.CHAT_BACKGROUND},
          -8px -8px ${theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_RECEIVED};
      }
    `}

  ${({ $stacked2x, $isCluster, theme }) =>
    $stacked2x &&
    !$isCluster &&
    css`
      box-shadow:
        /* The top layer stroke */ -2px -2px 1px ${theme?.COLORS?.CHAT_BACKGROUND},
        /* The second layer */ -5px -5px 1px ${theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_RECEIVED},
        /* The second layer stroke */ -7px -7px 1px ${theme?.COLORS?.CHAT_BACKGROUND};
      border-radius: 15px;
    `}

  ${({ $stacked3x, $isCluster, theme }) =>
    $stacked3x &&
    !$isCluster &&
    css`
      box-shadow:
        /* The top layer stroke */ -2px -2px 1px ${theme?.COLORS?.CHAT_BACKGROUND},
        /* The second layer */ -5px -5px 1px ${theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_RECEIVED},
        /* The second layer stroke */ -7px -7px 1px ${theme?.COLORS?.CHAT_BACKGROUND},
        /* The third layer */ -10px -9px 1px ${theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_RECEIVED},
        /* The third layer has no stroke */ 0 -20px 1px -9px rgba(0, 0, 0, 0);
      border-radius: 15px;
    `}
`

const StyledTapback = styled.div`
  position: absolute;
  top: -11px;
  left: -18px;
  cursor: pointer;
`

type StyledErrorProps = {
  $variant: string
}

const StyledError = styled.div<StyledErrorProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border: none;
  background: none;
  padding: 0;

  svg {
    color: ${({ theme }) => theme?.COLORS?.ERRORS};
    font-size: 22px;
    position: absolute;
    top: 8px;
    right: -28px;
  }

  aside {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.49px;
    color: ${({ theme }) => theme?.COLORS?.ERRORS};
    margin: 5px 0 12px;

    ${({ $variant }) =>
      $variant === BUBBLE_VARIANTS.CHAIN &&
      css`
        &::after {
          content: ' Click to resend.';
        }
      `}
  }
`

const StyledMediaPreview = styled(MediaPreview)<{
  $height?: number
  $error?: boolean
}>`
  background-image: ${({ $error }) => ($error ? `url('${generalConfig.staticUrl}/media_placeholder.png')` : '')};
`

type StyledMediaProps = {
  $stacked2x?: boolean
  $stacked3x?: boolean
}

export const StyledMedia = styled.div<StyledMediaProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin: 5px;

  ${({ $stacked2x }) =>
    $stacked2x &&
    css`
      margin-bottom: 15px;
      .Image-root {
        box-shadow:
          /* The top layer stroke */ -2px -2px 1px ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3},
          /* The second layer */ -5px -5px 1px ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1},
          /* The second layer stroke */ -7px -7px 1px ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3},
          /* The third layer */ -10px -9px 1px ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1},
          /* The third layer has no stroke */ 0 -20px 1px -9px rgba(0, 0, 0, 0);
        border-radius: 15px;
      }
    `}

  ${({ $stacked3x }) =>
    $stacked3x &&
    css`
      margin-bottom: 15px;
      .Image-root {
        box-shadow:
          /* The top layer stroke */ -2px -2px 1px ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3},
          /* The second layer */ -5px -5px 1px ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1},
          /* The second layer stroke */ -7px -7px 1px ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3},
          /* The third layer */ -10px -9px 1px ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1},
          /* The third layer has no stroke */ 0 -20px 1px -9px rgba(0, 0, 0, 0);
        border-radius: 15px;
      }
    `}
`

const checkEmojis = (bodyText) => {
  if (!bodyText || bodyText.length > 50) return
  let matched
  let emojis = ''
  const re = emojiRegex()
  while ((matched = re.exec(bodyText)) !== null) emojis += matched[0]
  if (emojis !== bodyText) return
  if (emojis.length > 6) return 'long'
  return 'short'
}

const formatContent = (body) => {
  const content: any = {}
  content.body = typeof body === 'string' ? body.trim() : body
  content.isEmojis = checkEmojis(content.body)
  if (!content.isEmojis) content.body = formatLinks(content.body)
  return content
}

const formatLinks = (bodyText) => {
  if (!bodyText || !linkify.pretest(bodyText)) return bodyText
  const links = linkify.match(bodyText)
  if (!links) return bodyText
  const parts: any[] = []
  let lastIdx = 0
  links.forEach((link, idx) => {
    const linkProps = { key: idx, href: link.url, target: '_blank' }
    parts.push(bodyText.substring(lastIdx, link.index))
    parts.push(
      <a {...linkProps} aria-hidden="true" rel="noopener noreferrer">
        {link.text}
      </a>,
    )
    lastIdx = link.lastIndex
  })
  parts.push(bodyText.substring(lastIdx))
  return parts
}

function renderBody(body) {
  return Array.isArray(body) ? (
    body.map((text) => {
      if (typeof text === 'string') {
        return <InvisibleCharacters>{text}</InvisibleCharacters>
      }
      return text
    })
  ) : (
    <InvisibleCharacters>{body}</InvisibleCharacters>
  )
}

type TapbackProps = {
  tapbacks: CampaignInsight[]
  onClick?: (...args: any[]) => any
}

const Tapback = (props: TapbackProps): JSX.Element => {
  const { tapbacks, onClick } = props
  const { COLORS } = useTheme() || {}
  const Icon = tapbackToIconMap[tapbacks[0].name.toLowerCase()]

  return (
    <StyledTapback>
      <Icon
        innerFill={tapbacks[0].name === 'Loved' ? '#FA5FAF' : COLORS?.SUBTEXT}
        onClick={onClick}
        outerFill={COLORS?.DEPRECATED_RECEIVED}
        shape={tapbacks[0].count === 1 ? 'singleBubble' : 'multipleBubbles'}
        size={32}
      />
    </StyledTapback>
  )
}

type BubbleProps = {
  body: string | null
  incoming?: boolean
  media: any | null
  onBodyClick?: (...args: any[]) => any
  openDetailsPane?: (...args: any[]) => any
  redacted?: boolean
  stacked?: number
  status?: string
  tapbacks?: CampaignInsight[]
  viewDetailsPane?: (...args: any[]) => any
  variant?: any // TODO: PropTypes.oneOf(Object.values(BUBBLE_VARIANTS))
  tail?: boolean
  trending?: boolean
  isCluster?: boolean
  showDrilldownArrow?: boolean
  className?: string
  linkPreviewType?: LinkPreviewType
  sourceType?: MessageSourceType
  sourceTypeId?: string
  onImageLoad?: () => void
  mediaMaxWidth?: number
  mediaMaxHeight?: number
}

export const Bubble = (props: BubbleProps): JSX.Element => {
  const {
    body,
    media,
    status,
    incoming,
    onBodyClick,
    redacted,
    tapbacks,
    stacked,
    variant,
    tail,
    trending,
    isCluster,
    showDrilldownArrow,
    className,
    linkPreviewType,
    onImageLoad,
    mediaMaxWidth,
    mediaMaxHeight,
  } = props

  const theme = useTheme()
  const { t } = useTranslation()
  const { bounds } = useBounds()

  // If the message starts with or ends with a URL use preview and remove link from text
  let linkPreview: any = null
  let replacedBody = ''
  let links: ParsedStringForRichLinks | undefined = undefined

  if (!incoming && !isMessageResponseTapback(body)) {
    links = parseStringForRichLinks(body || '')
  }

  if (links?.startsWith || links?.endsWith) {
    const { url } = links
    replacedBody = links.body

    if (linkPreviewType === 'rich') {
      linkPreview = <RichLinkPreview incoming={incoming} onImageLoad={onImageLoad} url={url} />
    } else if (linkPreviewType === 'mini') {
      linkPreview = (
        <ChatBubble
          color={incoming ? theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_RECEIVED : theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_SENT}
          direction={incoming ? ChatBubbleDirectionValues.LEFT : ChatBubbleDirectionValues.RIGHT}
          tail={links.endsWith || !replacedBody?.trim()}
          trending={trending}
        >
          <MiniLinkPreview url={url} />
        </ChatBubble>
      )
    }
  }

  const content = formatContent(links?.startsWith || links?.endsWith ? replacedBody : body)
  let redactedProp = {}
  if (redacted) {
    redactedProp = { 'data-private': 'redact' }
  }

  const renderMediaPreview = () => {
    if (!media || !media.url) return <MediaPreview inbound={incoming} />

    const { width, height, href, type, mimeType, url, sensitive, thumbnailUrl } = media

    return (
      <StyledMediaPreview
        $error={status === 'error'}
        $height={height}
        href={href}
        inbound={incoming}
        mediaProps={{
          src: href || url,
          height: height,
          width: width,
          poster: thumbnailUrl,
          mimetype: type || mimeType || mime.lookup(url) || 'application/octet-stream',
          sensitive: sensitive,
          maxWidth: mediaMaxWidth || Math.min(bounds ? Math.ceil(bounds?.width * 0.6) : 500, 500),
          maxHeight: mediaMaxHeight || Math.min(bounds ? Math.ceil(bounds?.width * 0.5) : 500, 500),
        }}
        onImageClick={onBodyClick}
        type={(type || mimeType || mime.lookup(url) || 'application/octet-stream').split('/')[0]}
      />
    )
  }

  if (status === 'error') {
    return (
      <StyledBubble $hasError className={className}>
        <StyledMain $incoming={incoming}>
          <StyledError $variant={variant} onClick={onBodyClick}>
            <StyledMedia>
              {renderMediaPreview()}
              <aside id="message">{t('convo.mediaUploadFailed')}</aside>
            </StyledMedia>
            {content.body && (
              <ChatBubble
                color={
                  incoming ? theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_RECEIVED : theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_SENT
                }
                direction={incoming ? ChatBubbleDirectionValues.LEFT : ChatBubbleDirectionValues.RIGHT}
                onClick={onBodyClick}
                tail={tail}
                trending={trending}
                type={bubbleType(content.isEmojis)}
                {...redactedProp}
              >
                {renderBody(content.body)}
              </ChatBubble>
            )}
            {content.body && <aside>{t('convo.notDelivered')}</aside>}
            <FontAwesomeIcon icon="exclamation-circle" />
          </StyledError>
        </StyledMain>
      </StyledBubble>
    )
  }

  return (
    <StyledBubble className={className}>
      <StyledMain $incoming={incoming}>
        {(status === 'pending' || (media && media.url)) && (
          <StyledMedia $stacked2x={stacked === 2} $stacked3x={stacked === 3} {...redactedProp}>
            {renderMediaPreview()}
          </StyledMedia>
        )}
        {links?.startsWith && linkPreview}
        {content.body && (
          <>
            <StyledMessage $isCluster={isCluster} $stacked2x={stacked === 2} $stacked3x={stacked === 3}>
              <ChatBubble
                color={
                  incoming ? theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_RECEIVED : theme?.COLORS?.CHAT_BUBBLE_BACKGROUND_SENT
                }
                direction={incoming ? ChatBubbleDirectionValues.LEFT : ChatBubbleDirectionValues.RIGHT}
                tail={isCluster || links?.endsWith ? false : tail}
                trending={trending}
                type={bubbleType(content.isEmojis)}
                {...redactedProp}
              >
                {renderBody(content.body)}
              </ChatBubble>
              {/* putting the icon _after_ the chat bubble ensures that it renders on top of the bubble instead of below it. */}
              {tapbacks && !!tapbacks[0]?.count && !incoming && (
                <Tapback onClick={props.viewDetailsPane} tapbacks={tapbacks} />
              )}
              {isCluster && showDrilldownArrow && <StyledClusterArrow icon="chevron-left" onClick={onBodyClick} />}
            </StyledMessage>
          </>
        )}
        <StyledMessageSource sourceType={props.sourceType} sourceTypeId={props.sourceTypeId} />
        {links?.endsWith && linkPreview}
      </StyledMain>
    </StyledBubble>
  )
}

Bubble.defaultProps = {
  linkPreviewType: 'rich',
}
