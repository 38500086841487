import { Api } from '@community_dev/types'

import { getMediaDimensions } from './getMediaDimensions'

import { Giphy } from 'api/giphy'

export async function createMediaFromFile(file: File, overrides: Partial<Api.V2.Media> = {}): Promise<Api.V2.Media> {
  const mediaDimensions = await getMediaDimensions(file)
  const fileUrl = URL.createObjectURL(file)

  const media: Api.V2.Media = {
    disposition: null,
    filename: file.name,
    height: mediaDimensions.height,
    id: '',
    link_click_info: null,
    mime_type: file.type,
    short_url: null,
    thumbnail_filename: null,
    thumbnail_url: null,
    url: fileUrl,
    width: mediaDimensions.width,
    ...overrides,
  }

  return media
}

export async function createMediaFromGiphy(gif: Giphy, overrides: Partial<Api.V2.Media> = {}): Promise<Api.V2.Media> {
  const media: Api.V2.Media = {
    disposition: null,
    filename: null,
    height: null,
    id: '',
    link_click_info: null,
    mime_type: 'image/gif',
    short_url: null,
    thumbnail_filename: null,
    thumbnail_url: null,
    url: gif.preview.src,
    width: null,
    ...overrides,
  }

  return media
}
