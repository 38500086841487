import { Image } from '@community_dev/pixels'
import React from 'react'

import { SlideBody, SlideContainer } from '../../styled'

type SlideWithImageProps = {
  src: string | any[]
  alt?: string
  body: string
}

export function SlideWithImage({ src, alt, body }: SlideWithImageProps): JSX.Element {
  return (
    <SlideContainer>
      <Image alt={alt} src={src} width="100%" />
      <SlideBody>{body}</SlideBody>
    </SlideContainer>
  )
}
