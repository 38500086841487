import noop from 'lodash/noop'
import React, { MouseEvent } from 'react'

import { StyledContainer, StyledIcon, StyledWrapper, StyledChildContainer } from './TextInput.style'

type TextInputProps = {
  active?: boolean
  focus?: boolean
  fontSize?: string
  rootStyle?: React.CSSProperties
  icon?: React.ReactNode
  onClick?: React.MouseEventHandler
  placeholder?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  children?: React.ReactNode
  value?: string
}

class TextInput extends React.PureComponent<TextInputProps & React.HTMLProps<HTMLInputElement>> {
  static defaultProps = {
    onChange: noop,
  }

  private input

  constructor(props: TextInputProps) {
    super(props)
    this.input = React.createRef()
  }

  componentDidMount(): void {
    if (this.props.focus) {
      this.input.current.focus()
    }
  }

  isFocused = (): void => {
    const inputNode = this.input.current
    return inputNode && inputNode === document.activeElement
  }

  onClick = (e: MouseEvent): void => {
    if (this.props.onClick) this.props.onClick(e)
    const inputNode = this.input.current
    if (inputNode) inputNode.focus()
  }

  render(): JSX.Element {
    const { active, icon, onClick, rootStyle, placeholder, onChange, focus, fontSize, children, ...rest } = this.props
    const isActive = Boolean(active || this.isFocused())
    return (
      <StyledWrapper $fontSize={fontSize} style={rootStyle}>
        <StyledContainer onClick={this.onClick}>
          {icon && <StyledIcon $active={isActive}>{icon}</StyledIcon>}
          <input {...rest} onChange={onChange} placeholder={placeholder} ref={this.input} />
          {children && <StyledChildContainer onClick={(e) => e.stopPropagation()}>{children}</StyledChildContainer>}
        </StyledContainer>
      </StyledWrapper>
    )
  }
}

export default TextInput
