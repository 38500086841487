import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { useMemberDataPermissions } from './useMemberDataPermissions'

import { CmdForSubscriber, getCustomMemberDataForSubscriber } from 'api/customMemberData'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

type UseCustomMemberDataArgs = {
  options?: UseQueryOptions<CmdForSubscriber>
  fanSubscriptionId: string
}

export function useCustomMemberData({
  fanSubscriptionId,
  options = {},
}: UseCustomMemberDataArgs): UseQueryResult<CmdForSubscriber> {
  const clientId = useClientId()
  const { canReadMemberData } = useMemberDataPermissions()

  return useQuery<CmdForSubscriber>(
    [QUERY_CACHE.CUSTOM_DATA_FOR_SUBSCRIBER, { clientId, fanSubscriptionId, canReadMemberData }],
    () => getCustomMemberDataForSubscriber({ clientId, fanSubscriptionId }),
    {
      enabled: canReadMemberData && Boolean(clientId) && Boolean(fanSubscriptionId),
      staleTime: STALE_TIME.ONE_HOUR,
      ...options,
    },
  )
}
