import { TYPOGRAPHY } from '@community_dev/pixels'
import styled from 'styled-components'

import { TextContent } from 'api/addOns'

const StyledTextContainer = styled.div`
  ${TYPOGRAPHY.VARIANT.BODY1}

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.COLORS.LINKS};
  }
`

export const AddOnText = ({ value }: TextContent): JSX.Element => {
  // Using dangerouslySetInnerHTML since the value can contain anchor tags
  return <StyledTextContainer dangerouslySetInnerHTML={{ __html: value }} />
}
