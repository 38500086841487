import { BORDER_RADIUS, SPACING, Select, composeValidators, required } from '@community_dev/pixels'
import { useMemo } from 'react'
import { Field, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export enum DelaySelectMode {
  Delayed = 'Delayed',
  Immediately = 'Immediately',
}

export enum DelaySelectOption {
  seconds = 'seconds',
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
}

export const FIELD_MODE = 'mode'
export const FIELD_UNIT = 'unit'
export const FIELD_DELAY = 'delay'

export const MAX_DELAY_SECONDS = 604800
export const MAX_DELAY_MINUTES = 10080
export const MAX_DELAY_HOURS = 168
export const MAX_DELAY_DAYS = 7

const StyledContainer = styled.div``

const StyledFlex = styled.div`
  display: flex;
`

const StyledSelect = styled(Select)`
  margin-right: ${SPACING[2]};
`
const StyledInput = styled.input<{ $invalid?: boolean }>`
  display: inline-block;
  height: 42px;
  min-width: ${SPACING[9]};
  padding: ${SPACING[1]} ${SPACING[2]};
  border: 1px solid ${({ theme, $invalid }) => ($invalid ? theme?.COLORS?.ERRORS : theme?.COLORS?.BORDERS)};
  border-radius: ${BORDER_RADIUS[1]};

  &:hover,
  &:focus {
    border-color: ${({ theme, $invalid }) => ($invalid ? theme?.COLORS?.ERRORS : theme?.COLORS?.DIVIDERS)};
  }
`
export const withinLimits =
  (min: number, max: number) =>
  (value: number): string | undefined => {
    if (value > max) return `must be less than or equal to ${max}`
    if (value < min) return `must be greater than or equal to ${min}`
    return undefined
  }

type SelectOption = {
  value: string
  label: string
}

export type DelaySelectValues = {
  delay: number
  mode: SelectOption
  unit: SelectOption
}

export type DelaySelectProps = {
  allowImmediate?: boolean
  allowedUnits?: `${DelaySelectOption}`[]
  initialDelay?: number
  initialMode?: `${DelaySelectMode}`
  initialUnitValue?: `${DelaySelectOption}`
}

const defaultAllowedUnits = [
  DelaySelectOption.seconds,
  DelaySelectOption.minutes,
  DelaySelectOption.hours,
  DelaySelectOption.days,
]

export const DelaySelect = ({
  allowImmediate = true,
  initialDelay = 1,
  allowedUnits = defaultAllowedUnits,
  initialMode = DelaySelectMode.Delayed,
  initialUnitValue = DelaySelectOption.days,
}: DelaySelectProps): JSX.Element => {
  const { t } = useTranslation()
  const { getFieldState } = useForm()
  const type: SelectOption = getFieldState(FIELD_MODE)?.value
  const unit: SelectOption = getFieldState(FIELD_UNIT)?.value
  // useMemo allows us to use translation here but prevent the
  // component from getting stuck in a render loop
  const modeOptions = useMemo(
    () => [
      { value: DelaySelectMode.Immediately, label: t('settings.immediately') },
      { value: DelaySelectMode.Delayed, label: t('settings.delayed') },
    ],
    [t],
  )
  const unitOptions = useMemo(
    () =>
      [
        { value: DelaySelectOption.seconds, label: t('settings.seconds') },
        { value: DelaySelectOption.minutes, label: t('settings.minutes') },
        { value: DelaySelectOption.hours, label: t('settings.hours') },
        { value: DelaySelectOption.days, label: t('settings.days') },
      ].filter((option) => allowedUnits.includes(option.value)),
    [t, allowedUnits],
  )
  const initialModeOption = useMemo(
    () => modeOptions.find((options) => options.value === initialMode) || modeOptions[1],
    [initialMode, modeOptions],
  )
  const initialUnitOption = useMemo(
    () => unitOptions.find((options) => options.value === initialUnitValue) || unitOptions[1],
    [initialUnitValue, unitOptions],
  )
  const maxDelay = useMemo(() => {
    if (unit?.value === DelaySelectOption.seconds) {
      return MAX_DELAY_SECONDS
    }

    if (unit?.value === DelaySelectOption.minutes) {
      return MAX_DELAY_MINUTES
    }

    if (unit?.value === DelaySelectOption.days) {
      return MAX_DELAY_DAYS
    }

    return MAX_DELAY_HOURS
  }, [unit])

  return (
    <StyledContainer>
      <StyledFlex>
        {allowImmediate && (
          <Field initialValue={initialModeOption} name={FIELD_MODE} validate={required} validateOnBlur>
            {({ input }) => (
              <StyledSelect
                aria-label="Select delay unit"
                id={FIELD_MODE}
                menuPortalTarget={document.body}
                options={modeOptions}
                {...input}
              />
            )}
          </Field>
        )}
        {(type?.value === DelaySelectMode.Delayed || allowImmediate === false) && (
          <>
            <Field initialValue={initialUnitOption} name={FIELD_UNIT} validate={required} validateOnBlur>
              {({ input }) => (
                <StyledSelect
                  aria-label="Select delay unit"
                  id={FIELD_UNIT}
                  menuPortalTarget={document.body}
                  options={unitOptions}
                  {...input}
                />
              )}
            </Field>
            <Field
              initialValue={initialDelay}
              key={FIELD_DELAY}
              name={FIELD_DELAY}
              // without this key the validator will not update when this value changes
              // https://final-form.org/docs/react-final-form/types/FieldProps#validate
              validate={composeValidators(required, withinLimits(1, maxDelay))}
              validateOnBlur
            >
              {({ input, meta }) => (
                <StyledInput
                  $invalid={meta.invalid}
                  aria-label="Select delay value"
                  id={FIELD_DELAY}
                  max={maxDelay}
                  min={1}
                  type="number"
                  {...input}
                />
              )}
            </Field>
          </>
        )}
      </StyledFlex>
    </StyledContainer>
  )
}
