import { PixelsTheme, ThemeProvider as PixelsThemeProvider } from '@community_dev/pixels'
import ABYSS from '@community_dev/pixels/dist/js/themes/presets/abyss'
import BLACKOUT from '@community_dev/pixels/dist/js/themes/presets/blackout'
import DARK from '@community_dev/pixels/dist/js/themes/presets/dark'
import KIMBIE from '@community_dev/pixels/dist/js/themes/presets/kimbie'
import LIGHT from '@community_dev/pixels/dist/js/themes/presets/light'
import MIAMI from '@community_dev/pixels/dist/js/themes/presets/miami'
import QUIET from '@community_dev/pixels/dist/js/themes/presets/quiet'
import WHITEOUT from '@community_dev/pixels/dist/js/themes/presets/whiteout'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import { useContext, useEffect, useReducer } from 'react'
import React from 'react'
import { PartialDeep } from 'type-fest'

import useColorScheme from 'hooks/useColorScheme'
import useCurrentTheme from 'hooks/useCurrentTheme'

const isLocal = ['local'].includes(import.meta.env.VITE_ENVIRONMENT as string)
const isDevelopment = ['local', 'development'].includes(import.meta.env.VITE_ENVIRONMENT as string)

const THEMES = {
  LIGHT,
  DARK,
  BLACKOUT,
  WHITEOUT,
  ABYSS,
  KIMBIE,
  MIAMI,
  QUIET,
}

export type Theme = {
  active: boolean
  color: string
  key: keyof typeof themes
  label: string
  theme: PartialDeep<PixelsTheme>
  type: 'light' | 'dark'
}

export const themes: Record<string, Theme> = {
  light: {
    active: true,
    color: '#eee',
    key: 'light',
    label: 'Light',
    theme: THEMES.LIGHT,
    type: 'light',
  },
  dark: {
    active: true,
    color: '#444',
    key: 'dark',
    label: 'Dark',
    theme: THEMES.DARK,
    type: 'dark',
  },
  abyss: {
    active: isDevelopment,
    color: 'rgba(71, 143, 229, 1)',
    key: 'abyss',
    label: 'Abyss',
    theme: THEMES.ABYSS,
    type: 'dark',
  },
  kimbie: {
    active: isDevelopment,
    color: 'rgba(227, 25, 77, 1)',
    key: 'kimbie',
    label: 'Kimbie',
    theme: THEMES.KIMBIE,
    type: 'dark',
  },
  quiet: {
    active: isDevelopment,
    color: 'rgba(112, 86, 151)',
    key: 'quiet',
    label: 'Quiet',
    theme: THEMES.QUIET,
    type: 'light',
  },
  miami: {
    active: isDevelopment,
    color: '#ff8ba8',
    key: 'miami',
    label: 'Miami',
    theme: THEMES.MIAMI,
    type: 'dark',
  },
  blackout: {
    active: isLocal,
    color: '#000',
    key: 'blackout',
    label: 'Blackout',
    theme: THEMES.BLACKOUT,
    type: 'dark',
  },
  whiteout: {
    active: isLocal,
    color: '#fff',
    key: 'whiteout',
    label: 'Whiteout',
    theme: THEMES.WHITEOUT,
    type: 'light',
  },
}

type ThemeUpdateKeyAction = {
  type: 'UPDATE_KEY'
  params: {
    key: string
    value: string
  }
}

type ThemeUpdateAction = {
  type: 'UPDATE'
  params: {
    theme: PartialDeep<PixelsTheme>
  }
}

type ThemeAction = ThemeUpdateKeyAction | ThemeUpdateAction

const themeReducer = (state: PartialDeep<PixelsTheme>, action: ThemeAction) => {
  switch (action.type) {
    case 'UPDATE_KEY':
      return set(cloneDeep(state), action.params.key, action.params.value)
    case 'UPDATE':
      return action.params.theme
    default:
      return state
  }
}

type ThemeProviderProps = {
  children: JSX.Element
}

export const ThemeContext = React.createContext<{
  theme: PartialDeep<PixelsTheme>
  dispatchTheme: React.Dispatch<ThemeAction>
}>({
  theme: {} as PartialDeep<PixelsTheme>,
  dispatchTheme: (state) => state,
})

ThemeContext.displayName = 'ThemeContext'

export const ThemeProvider = (props: ThemeProviderProps): JSX.Element => {
  const { children } = props
  const currentTheme = useCurrentTheme()
  const colorScheme = useColorScheme()
  const [theme, dispatch] = useReducer(themeReducer, themes[currentTheme.key].theme || THEMES.LIGHT)

  // update theme when system settings change
  useEffect(() => {
    dispatch({
      type: 'UPDATE',
      params: {
        theme: themes[currentTheme.key].theme,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorScheme])

  return (
    <PixelsThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ dispatchTheme: dispatch, theme }}>{children}</ThemeContext.Provider>
    </PixelsThemeProvider>
  )
}

export function useThemeDispatch(): {
  theme: PartialDeep<PixelsTheme>
  dispatchTheme: React.Dispatch<ThemeAction>
} {
  const { theme, dispatchTheme } = useContext(ThemeContext)

  return { theme, dispatchTheme }
}
