import { Layout, Typography, SPACING, Tooltip, InfoIcon } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { WorkflowType } from '@community_dev/workflow-manifest'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import styled from 'styled-components'

import { WorkflowAnalyticsTable } from '../WorkflowAnalyticsTable/WorkflowAnalyticsTable'
import { StyledHeading } from '../WorkflowSidebarEditFlow/WorkflowSidebarEditFlow.style'
import { WorkflowSidebarNavigation } from '../WorkflowSidebarNavigation'

import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { ROUTES } from 'constants/routes'
import {
  formatClicks,
  formatCtr,
  formatSent,
  getMessageTextsWithIndex,
  useWorkflowAnalytics,
} from 'containers/Settings/useWorkflowAnalytics'
import { useClientId } from 'hooks/useClient'

const StyledLink = styled(Link)`
  text-decoration: none;
`

export function WorkflowSidebarPerformance(): JSX.Element {
  const { workflow, manifest } = useWorkflowProvider()
  const { t } = useTranslation()
  const clientId = useClientId()
  const { COLORS } = useTheme()
  const { data: analytics } = useWorkflowAnalytics(clientId, workflow)
  return (
    <>
      <WorkflowSidebarNavigation />
      <Layout flex="1" height="100%" overflow="auto" padding="0px 16px 16px">
        <StyledHeading display="flex">
          <Layout textTransform="capitalize">{t('messages')}</Layout>
          <Tooltip
            content={<Layout padding={SPACING[2]}>{t('settings.signUpSeriesAnalytics.performanceTooltip')}</Layout>}
            placement="top"
          >
            <Layout display="inline-block" marginLeft={SPACING[1]} marginTop="2px">
              <InfoIcon size={20} />
            </Layout>
          </Tooltip>
        </StyledHeading>
        <WorkflowAnalyticsTable
          analytics={{
            clicks: analytics.totalClicks,
            sent: analytics.totalSent,
            ctr: analytics.ctr,
          }}
          labelCtr={t('settings.avgCtr')}
          labelSent={t('settings.totalSent')}
        />
        <Layout borderBottom={`1px solid ${COLORS.BORDERS}`} flex="none" padding={`${SPACING[4]} 0 ${SPACING[4]} 0`}>
          {workflow?.type === WorkflowType.signup && (
            <Typography display="inline-block" fontStyle="oblique" marginRight={SPACING[2]} variant="caption1">
              {t('automations.excludingWelcomeAndConfirmation')}
            </Typography>
          )}
        </Layout>
        {workflow &&
          manifest &&
          getMessageTextsWithIndex(manifest).map((sendMessageAction, index) => {
            const messageAnalytics = analytics.functions[sendMessageAction.function]?.[sendMessageAction.index]
            return (
              <Layout key={`${sendMessageAction.function}-${sendMessageAction.index}`}>
                <StyledLink
                  to={route(ROUTES.AUTOMATIONS.FLOW_EDIT_STEP, {
                    workflowId: workflow?.id,
                    functionKey: sendMessageAction.function,
                    functionIndex: sendMessageAction.index,
                  })}
                >
                  <Typography color={COLORS.LINKS} marginBottom={SPACING[1]} variant="h4">
                    {t('automations.smsIndex', { index: index + 1 })}
                  </Typography>
                </StyledLink>
                <WorkflowAnalyticsTable
                  analytics={{
                    clicks: formatClicks(sendMessageAction.action, messageAnalytics?.linkhits),
                    sent: formatSent(messageAnalytics?.totalsent),
                    ctr: formatCtr(messageAnalytics?.ctr),
                  }}
                />
              </Layout>
            )
          })}
      </Layout>
    </>
  )
}
