import groupBy from 'lodash/fp/groupBy'
import kebabCase from 'lodash/fp/kebabCase'
import { useMemo } from 'react'

import { AddOnsContentType } from './constants'
import { useHasCapabilitiesAndFeatureFlagsFn } from './useHasCapabilitiesAndFeatureFlags'

import { AddOn } from 'api/addOns'
import { ROUTES } from 'constants/routes'
import { addOns } from 'hooks/useAddons/constants'
import { generalConfig } from 'utils/config'
import { route } from 'utils/router'

export type AddOnWithRouteTo = AddOn & {
  routeTo: string
}

type AddOnsBySection = Record<string, AddOnWithRouteTo[]>
type AddOns = {
  allAddOns?: AddOnWithRouteTo[]
  featuredAddOns?: AddOnWithRouteTo[]
  allAddOnsBySection?: AddOnsBySection
  selectedAddOn?: AddOnWithRouteTo
}

const toUrlsWithStaticUrl = (urls: string[]): string[] => urls.map((url) => `${generalConfig.staticUrl}${url}`)

const byFeatureRank = (a: AddOnWithRouteTo, b: AddOnWithRouteTo) => {
  const rankA = a?.featureRank || Number.MAX_SAFE_INTEGER
  const rankB = b?.featureRank || Number.MAX_SAFE_INTEGER

  return rankA - rankB
}

// The addOnTitle will be what we route to
// ex: /add-ons/list-imports - addOnTitle = list-imports
// we generate the route by doing kebabCase on addOn.title
export const useAddons = (addOnTitle?: string): AddOns => {
  const hasCapabilitiesAndFeatureFlagsFn = useHasCapabilitiesAndFeatureFlagsFn()

  const allAddOns = useMemo(() => {
    return addOns
      .map((addOn: AddOn) => {
        const content = addOn?.details?.content || []
        const contentWithUrls = content.map((item) => {
          if (item.type === AddOnsContentType.image) {
            return { ...item, imageSrc: toUrlsWithStaticUrl(item.imageSrc || []) }
          }
          return item
        })
        return {
          ...addOn,
          imageSrc: toUrlsWithStaticUrl(addOn.imageSrc || []),
          details: { ...addOn.details, content: contentWithUrls },
          routeTo: route(ROUTES.ADD_ONS.VIEW, { addOn: kebabCase(addOn.title) }),
        }
      })
      .filter(({ visible }) => hasCapabilitiesAndFeatureFlagsFn(visible)) as AddOnWithRouteTo[]
  }, [hasCapabilitiesAndFeatureFlagsFn])

  const featuredAddOns = useMemo(() => {
    return allAddOns && allAddOns.slice().sort(byFeatureRank).slice(0, 4)
  }, [allAddOns])

  const allAddOnsBySection = useMemo(() => {
    return allAddOns && (groupBy((addOn) => addOn.type, allAddOns) as AddOnsBySection)
  }, [allAddOns])

  const selectedAddOn = useMemo(() => {
    return allAddOns && allAddOns.find((addOn) => kebabCase(addOn.title) === addOnTitle)
  }, [allAddOns, addOnTitle])

  return { allAddOns, featuredAddOns, allAddOnsBySection, selectedAddOn }
}
