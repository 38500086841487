import { Api } from '@community_dev/types'
import { RoleGroup } from '@community_dev/types/lib/api/v1/Roles'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CamelCasedPropertiesDeep } from 'type-fest'

// This Component will not be needed anymore once all roles have groups and display names.
export function RoleList({ roles }: { roles: CamelCasedPropertiesDeep<Api.V1.Role[]> }): JSX.Element {
  const { t, i18n } = useTranslation()
  const primaryRole = useMemo(() => roles.find((role) => role.group === RoleGroup.PRIMARY), [roles])
  return (
    <>
      {primaryRole?.displayName ||
        roles
          .map((role) => i18n.exists(`settings.team.roles.${role.name}`) && t(`settings.team.roles.${role.name}`))
          .filter((r) => r !== false)
          .join(', ')}
    </>
  )
}
