import { Info } from '@community_dev/pixels'
import React from 'react'
import { Trans } from 'react-i18next'
import { useTheme } from 'styled-components'

import { MAX_HEADLINE_LENGTH } from '../../constants/embed'
import { DiscountIcon } from '../../Icons/DiscountIcon'

import { ColorInput } from './ColorInput'
import { CombinedInput } from './CombinedInput'
import { FormSection } from './FormSection'

import { Embed } from 'api/mgu'

type ApperanceFormProps = {
  embedConfig: Embed['jsonConfig']
  onChange(embed: Partial<Embed['jsonConfig']>): any
}

export const AppearanceForm = ({ embedConfig, onChange }: ApperanceFormProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  return (
    <>
      <FormSection name="Background Color">
        <ColorInput
          label="Hex"
          name="backgroundColor"
          onChange={(v) => onChange({ backgroundColor: v })}
          value={embedConfig.backgroundColor}
        />
      </FormSection>
      <FormSection name="Text">
        <CombinedInput
          colorValue={embedConfig.headlineTextColor}
          error={!embedConfig.headline ? 'Enter headline text' : ''}
          label="Headline"
          name="headline"
          onColorChange={(v) => onChange({ headlineTextColor: v })}
          onTextChange={(v) => onChange({ headline: v.slice(0, MAX_HEADLINE_LENGTH) })}
          textValue={embedConfig.headline}
        />
        <CombinedInput
          colorValue={embedConfig.subtextTextColor}
          label="Subtext"
          name="subtext"
          onColorChange={(v) => onChange({ subtextTextColor: v })}
          onTextChange={(v) => onChange({ subtext: v })}
          textValue={embedConfig.subtext}
        />

        <Info heading="Tip: Have an online store?" icon={<DiscountIcon color={COLORS?.SUBTEXT} />}>
          <Trans i18nKey="settings.integrations.signupUnit.appearanceForm.tip" />
        </Info>
      </FormSection>
      <FormSection name="Button Text Color">
        <ColorInput
          label="Hex"
          name="buttonTextColor"
          onChange={(v) => onChange({ buttonTextColor: v })}
          value={embedConfig.buttonTextColor}
        />
      </FormSection>
      <FormSection name="Button Color">
        <ColorInput
          label="Hex"
          name="buttonBackgroundColor"
          onChange={(v) => onChange({ buttonBackgroundColor: v })}
          value={embedConfig.buttonBackgroundColor}
        />
      </FormSection>
    </>
  )
}
