export const ONBOARDING_TASKS = Object.freeze({
  WELCOME_MESSAGE: 'Welcome Message',
  CONTACT_PHOTO: 'Contact Photo',
  CONFIRMATION_MESSAGE: 'Confirmation Message',
  VOICEMAIL: 'Voicemail',
  UPDATE_NAME: 'Update Name',
})

export const WELCOME_MESSAGE_KEY = 'intro_with_fan_registration_url'

export const CONFIRMATION_MESSAGE_KEY = 'subscription_confirmation'

export const ONBOARDING_TASK_COMPLETE = 'complete'
