import { Block, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { CustomMemberDataDefinitionList } from '../../CustomMemberDataDefinitionList'
import { ActionPanel } from '../ActionPanel'

import { Fan } from 'api/fans'
import { PANELS } from 'constants/panels'
import { useCustomMemberData } from 'hooks/useCustomMemberData'

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${SPACING[1]} ${SPACING[4]} ${SPACING[4]};
`

const StyledHeading = styled.h3`
  margin: 0;
`

const StyledActionPanel = styled(ActionPanel)`
  padding-bottom: 0;
`

const StyledBlock = styled(Block)`
  border-radius: 0;
  padding-top: 0;
  margin-bottom: 0 !important;

  dl {
    margin-top: 0;
  }

  dt {
    min-width: 120px;
    max-width: 120px;
    width: 120px;
  }

  dd {
    width: 100%;
  }
`

type ViewAllCMDActionPanelProps = {
  fan?: Fan
  onBack: () => void
}

export function ViewAllCMDActionPanel({ fan, onBack }: ViewAllCMDActionPanelProps): JSX.Element | null {
  const { data: customMemberData = { fields: [] }, isInitialLoading } = useCustomMemberData({
    fanSubscriptionId: fan?.fanSubscriptionId || '',
  })

  if (isInitialLoading || !customMemberData || customMemberData?.fields?.length === 0) {
    return null
  }

  return (
    <StyledActionPanel flush name={PANELS.VIEW_ALL_CMD} onBack={onBack}>
      <StyledHeader>
        <StyledHeading>
          Custom Member Data{customMemberData.fields.length > 0 ? ` (${customMemberData.fields.length})` : ''}
        </StyledHeading>
      </StyledHeader>
      <StyledBlock flush>
        <CustomMemberDataDefinitionList fields={customMemberData.fields} />
      </StyledBlock>
    </StyledActionPanel>
  )
}
