import { SPACING } from '@community_dev/pixels'
import styled, { css } from 'styled-components'

import { EditSignUpConfirmationMessage, EditWelcomeMessage } from 'components/EditOnboardingMessage'
import { StyledIcon } from 'components/Settings'

export const editMessageStyles = css`
  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  }

  button:hover {
    text-decoration: none;
  }
`
export const StyledEditSignUpConfirmationMessage = styled(EditSignUpConfirmationMessage)`
  ${editMessageStyles}
`
export const StyledEditWelcomeMessage = styled(EditWelcomeMessage)`
  ${editMessageStyles}
`
export const StyledLine = styled.div`
  position: absolute;
  top: 72px;
  bottom: ${SPACING[9]};
  left: ${SPACING[4]};
  border-left: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
`
export const SignupMessagesWrapper = styled.div`
  ${StyledIcon} {
    padding: 0;
    margin-right: 12px;
  }
`
