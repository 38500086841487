import { FieldSources, FieldTypes } from '@community_dev/filter-dsl/lib/subscription-data'
import { HTTP } from '@community_dev/requests'
import { CamelCase, CamelCasedProperties, CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import type { Paginated } from 'hooks/usePagination'
import { request } from 'utils/api'
import { route } from 'utils/router'

export enum ImportStatuses {
  AWAITING_UPLOAD = 'awaiting_upload',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  COMPLETED_WITH_ERRORS = 'completed_with_errors',
  FAILED = 'failed',
  INITIALIZED = 'initialized',
  PROCESSING = 'processing',
}

export type ImportStatus = CamelCase<`${ImportStatuses}`>

export type Import = {
  id: string
  insertedAt: string
  name: string
  status: `${ImportStatuses}`
  updatedAt: string
}

export type Field = {
  key: string
  name: string
  value_type: FieldTypes | null
  source: FieldSources.CLIENT
}

export type ImportField = CamelCasedProperties<Field>

export enum ImportErrors {
  FILE_UPLOAD_FAILED = 'file_upload_failed',
  INVALID_FIELD_VALUE = 'invalid_field_value',
  INVALID_FILE_FORMAT = 'invalid_file_format',
  INVALID_PHONE_NUMBER = 'invalid_phone_number',
  MAX_FIELDS_EXCEEDED = 'max_fields_exceeded',
  MAX_ROWS_EXCEEDED = 'max_rows_exceeded',
  MISSING_SUBSCRIPTION = 'missing_subscription',
  PHONE_NUMBER_HEADER_MISSING = 'phone_number_header_missing',
  UNKNOWN_FIELD = 'unknown_field',
}

export type ImportError = `${ImportErrors}`

type GetImportHistoryArgs = {
  clientId: string
  pageParam?: number
  pageSize?: number
}

type ImportHistoryResponse = Paginated<Import[]>

export function getImportHistory({
  clientId,
  pageParam = 1,
  pageSize = 50,
}: GetImportHistoryArgs): Promise<ImportHistoryResponse> {
  const requestRoute = route(
    ENDPOINTS.IMPORT.HISTORY,
    {
      clientId,
    },
    {
      page_number: pageParam,
      page_size: pageSize,
    },
  )

  return request(requestRoute, { method: HTTP.GET })
}

type GetImportArgs = {
  clientId: string
  importId: string
}

export type ApiImportDetailsResponse = {
  data: {
    batches_processed: number
    client_id: string
    error_csv_file_url: string | null
    error_details: ImportError[]
    estimated_filesize: number
    fields: Field[]
    filesize: number | null
    id: string
    inserted_at: string
    name: string
    rows_accepted: Record<string, number>
    rows_failed: number
    rows_total: number
    rows_without_subscriptions: number
    status: `${ImportStatuses}`
    total_batches: number
    total_rows_accepted: number
    updated_at: string
  }
}

export type ImportDetailsResponse = CamelCasedPropertiesDeep<ApiImportDetailsResponse>

export type ImportDetails = ImportDetailsResponse['data']

export function getImport({ clientId, importId }: GetImportArgs): Promise<ImportDetailsResponse> {
  const requestRoute = route(ENDPOINTS.IMPORT.DETAILS, {
    clientId,
    importId,
  })

  return request(requestRoute, { method: HTTP.GET })
}

type PostImportBody = {
  data: {
    name: string
    client_id: string
    estimated_filesize: number
    fields: Field[]
  }
}

type PostImportArgs = {
  clientId: string
  body: PostImportBody
}

export type PostImportResponse = CamelCasedPropertiesDeep<{
  id: string
  status: string
  original_file_url: string
  estimated_filesize: number
  created_at: string
  updated_at: string
}>

export function postImport({ clientId, body }: PostImportArgs): Promise<PostImportResponse> {
  return request(route(ENDPOINTS.IMPORT.IMPORT, { clientId }), {
    method: HTTP.POST,
    body,
  })
}

type UploadFileArgs = {
  file: File
  endpoint: string // pre-signed S3 URL
}

export function uploadFile({ file, endpoint }: UploadFileArgs): Promise<any> {
  return fetch(endpoint, {
    headers: {
      'Content-Type': file.type,
    },
    method: HTTP.PUT,
    body: file,
  })
}
