import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'

export type Context = {
  name: string
  isBillingActive: boolean
  clientId: string
  clientImage: string
  currentContext: boolean
  firstName?: string
  lastName?: string
  onboardingBillingState: CamelCasedPropertiesDeep<Api.ClientOnboardingBillingState>
  phoneNumber: string
  roles: CamelCasedPropertiesDeep<Api.V1.Role>[]
}

export function getContexts(token?: string): Promise<Context[]> {
  return request(ENDPOINTS.CONTEXTS.LIST, {
    token,
  }).then(({ data }) => {
    return data.map((context) => {
      const firstName = context.firstName || ''
      const lastName = context.lastName || ''
      const { billingRequired, billingProvided } = context.onboardingBillingState

      return {
        ...context,
        name: `${firstName} ${lastName}`.trim(),
        isBillingActive: billingRequired ? billingProvided : true,
      }
    })
  })
}

export function putSelectContext({ body, token }: { body: any; token?: string }): Promise<any> {
  return request(ENDPOINTS.CONTEXTS.SET, {
    body,
    token,
    method: HTTP.PUT,
    headers: { 'cache-control': 'no-cache' },
  })
}
