import { ReactNode, createContext, useContext, useState } from 'react'

export type RecipientFieldContextValue = {
  isOpen: boolean
  setIsOpen(value: boolean | ((prev: boolean) => boolean)): void
}

export const RecipientFieldContext = createContext<RecipientFieldContextValue | null>(null)

RecipientFieldContext.displayName = 'RecipientFieldContext'

type ComposeProviderProps = {
  children: ReactNode
}

export function RecipientFieldContextProvider({ children }: ComposeProviderProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <RecipientFieldContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </RecipientFieldContext.Provider>
  )
}

export function useRecipientField(): RecipientFieldContextValue {
  const context = useContext(RecipientFieldContext)!

  if (typeof context === 'undefined') {
    throw new Error('useCount must be used within a RecipientFieldContext')
  }

  return context
}
