import { NotifArgs } from 'components/form/PasswordForm/PasswordForm'

const notifs: Record<string, NotifArgs> = {
  activateError: {
    level: 'danger',
    body: 'Unable to activate account',
  },
  inviteExpired: {
    level: 'danger',
    body: 'Your invitation has expired',
  },
  loginError: {
    level: 'danger',
    body: 'Invalid email or password',
  },
  passwordChanged: {
    level: 'success',
    body: 'Your password has been changed',
  },
  passwordUnchanged: {
    level: 'danger',
    body: 'Unable to update password',
  },
  passwordEmailSent: {
    level: 'success',
    body: 'Email sent! Check your inbox',
  },
  passwordSpace: {
    level: 'danger',
    body: "Can't begin or end with a space",
  },
}

export default notifs
