import { GenderLabels } from '@community_dev/filter-dsl/lib/humanize/builtInFilters/humanizeGenderFilter'
import { countryCodeMap } from '@community_dev/location-data'

import { i18n } from 'i18n'

export const formatGender = (gender: string | null): string => {
  if (gender) return GenderLabels[gender.toLowerCase()] || ''
  if (typeof gender === 'undefined') return ''

  return i18n.t(NO_DATA_TEXT.GENDER)
}

export const formatAge = (age?: number | null): string => {
  if (age && age > 0) return `${age} Years Old`
  if (typeof age === 'undefined') return ''
  return i18n.t(NO_DATA_TEXT.AGE)
}

export const isBlank = (val: any): boolean => {
  if (!val) return true
  if (typeof val === 'string') {
    const v = val.trim().toLowerCase()
    if (v === 'blank') return true
  }
  return false
}

export const formatLocation = (city?: string, countryCode?: string, stateCode?: string, country?: string): string => {
  if (!city) {
    return i18n.t(NO_DATA_TEXT.LOCATION)
  } else {
    if (countryCode === 'US' || countryCode === 'CA') {
      if (stateCode) {
        return `${city}, ${stateCode}`
      } else {
        return city
      }
    } else {
      // for locations outside of north america, we display the country
      // instead of the state.
      if (country) {
        return `${city}, ${country}`
      } else if (countryCode) {
        // if we don’t have a country name, but a country code, we try to look it up.
        // Otherwise we fall back to the country code.
        return `${city}, ${countryCodeMap[countryCode] || countryCode}`
      } else {
        return city
      }
    }
  }
}

export const NO_DATA_TEXT = Object.freeze({
  AGE: 'noDataText.age',
  GENDER: 'noDataText.gender',
  LOCATION: 'noDataText.location',
} as const)
