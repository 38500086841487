import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type UrlMetadata = CamelCasedPropertiesDeep<Api.V1.UrlMetadata>

export function getUrlMetadata({ clientId, url }: { clientId: string; url: string }): Promise<UrlMetadata> {
  return request(
    route(ENDPOINTS.URL_METADATA, {
      clientId,
      url: encodeURIComponent(url),
    }),
    {
      ignoreReportingErrorCodes: [404],
    },
  ).then((data) => data?.data)
}
