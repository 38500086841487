export enum ConversionGoalType {
  Conversion = 'conversion',
  BrandEngagement = 'brand_engagement',
  FirstPartyData = 'first_party_data',
}

export enum ConversionGoalSuccessMetric {
  LinkClick = 'link_click',
  ProductSale = 'product_sale',
  TicketSale = 'ticket_sale',
  Donation = 'donation',
  WebsiteVisit = 'website_visit',
  LeadCapture = 'lead_capture',
  SongPresave = 'song_presave',
  FormComplete = 'form_complete',
  Other = 'other',
}
