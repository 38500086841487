import { Layout } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { useEffect } from 'react'
import { Redirect, Route, Switch, useParams } from 'react-router'
import useLocalStorageState from 'use-local-storage-state'

import { ScheduledLocalStorageKeys, ScheduledTabView } from './constants'
import { ScheduledHeader } from './ScheduledHeader'
import { ScheduledList } from './screens/List/ScheduledList'
import { ScheduledMonth } from './screens/Month/ScheduledMonth'

import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { useHasCapability } from 'hooks/useUserCapability'
import { SidebarLayout } from 'layouts/SidebarLayout'

export const Scheduled = (): JSX.Element => {
  const { view } = useParams<{ view: string }>()
  const [, setScheduledView] = useLocalStorageState<ScheduledTabView>(ScheduledLocalStorageKeys.VIEW_KEY)

  useEffect(() => {
    if (view === ScheduledTabView.MONTH || view === ScheduledTabView.LIST) {
      setScheduledView(view)
    }
  }, [view, setScheduledView])

  const hasCampaignCalendarFeature = useHasCapability(CAPABILITIES.FEATURE.CAMPAIGN_SCHEDULING.ALL)
  return (
    <SidebarLayout>
      <Layout display="flex" flexDirection="column" width="100%">
        <ScheduledHeader />
        <Switch>
          <Route component={ScheduledList} path={ROUTES.SCHEDULED.LIST} />
          {hasCampaignCalendarFeature && <Route component={ScheduledMonth} path={ROUTES.SCHEDULED.MONTH} />}

          {/* Redirect to the list view if the feature is not enabled */}
          {!hasCampaignCalendarFeature && (
            <Redirect
              from={ROUTES.SCHEDULED.MONTH}
              to={route(ROUTES.SCHEDULED.VIEW, { view: ScheduledTabView.LIST })}
            />
          )}

          {/* Redirect to the Month view if the view is not found since its the default view */}
          {/* If they don't have the feature, it will redirect to List by the Redirect above */}
          <Redirect to={route(ROUTES.SCHEDULED.VIEW, { view: ScheduledTabView.MONTH })} />
        </Switch>
      </Layout>
    </SidebarLayout>
  )
}
