import { useMemo } from 'react'

import { Communities, Community } from 'api/community'
import { COMMUNITY_TYPES } from 'constants/communities'
import { useCommunities } from 'hooks/useCommunities'

function findFavoriteCommunity(communities) {
  return communities?.find(
    (community) => community.type === COMMUNITY_TYPES.DEFAULT && community.title.toLocaleLowerCase() === 'favorites',
  )
}

type UseFavoriteCommunity = {
  favoriteCommunity: Community
  communities?: Communities
}

export function useFavoriteCommunity(): UseFavoriteCommunity {
  const { data: communities } = useCommunities()
  const favoriteCommunity = useMemo(() => findFavoriteCommunity(communities?.default), [communities])

  return { communities, favoriteCommunity }
}
