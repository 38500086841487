import { Button } from '@community_dev/pixels'
import styled from 'styled-components'

import { Setting } from 'layouts/SettingsLayout'

export const StyledSetting = styled(Setting)`
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

export const StyledPlanName = styled.span`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

export const StyledText = styled.div`
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

//TODO: this should not be a button
export const StyledActive = styled(Button)`
  pointer-events: none;
  cursor: default;
`
