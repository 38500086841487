import {
  Typography,
  Button,
  BUTTON_VARIANTS,
  Layout,
  Menu,
  COLORS,
  Dialog,
  DIALOG_VARIANTS,
  LoadingIndicator,
} from '@community_dev/pixels'
import { ApiError } from '@community_dev/requests'
import { WorkflowType } from '@community_dev/workflow-manifest'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import ZeroMessageIllustration from './svg/illustration.svg?react'
import IconMore from './svg/more.svg?react'
import IconPlus from './svg/plus.svg?react'
import {
  ResponsiveWrapper,
  StyledHeader,
  StyledTable,
  StyledTBody,
  StyledTHead,
  TableHorizontalScrollWrapper,
  StyledWorkflowLink,
  StyledWorkflowRowMenu,
  StyledEllipsis,
} from './WorkflowsScreen.style'
import { WorkflowStatus, WorkflowStatusTypes } from './WorkflowStatus'

import { deleteWorkflow, listWorkflows, Workflow } from 'api/workflows'
import { useScheduleWorkflow } from 'components/WorkflowEditor/hooks/useScheduleWorkflow'
import { shouldAllowScheduling } from 'components/WorkflowEditor/utils/shouldAllowScheduling'
import { toWorkflowStatus } from 'components/WorkflowEditor/utils/toWorkflowStatus'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { useWorkflowAnalytics } from 'containers/Settings/useWorkflowAnalytics'
import { useClientId } from 'hooks/useClient'
import { useWorkflowKeywords } from 'hooks/useWorkflowKeywords'
import Sentry from 'integrations/Sentry'
import { SidebarLayout } from 'layouts/SidebarLayout'
import analytics from 'utils/analytics'
import dayjs from 'utils/dayjs'
import { route } from 'utils/router'

export function WorkflowsScreen(): JSX.Element {
  const history = useHistory()
  const clientId = useClientId()
  const { t } = useTranslation()

  const { data: workflows, isLoading: isLoadingListWorkflows } = useQuery(
    [QUERY_CACHE.WORKFLOW_CONFIG.WORKFLOWS, { clientId }],
    async () => listWorkflows({ clientId, getDetails: true }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        Sentry.captureException(error)
        toast.error(t('settings.unableToLoadData'))
      },
    },
  )

  return (
    <SidebarLayout>
      <ResponsiveWrapper>
        <StyledHeader>
          <Layout alignItems="center" display="flex" justifyContent="space-between">
            <Typography fontSize="40px" marginBottom="24px" variant="h1">
              {t('automations.flows')}
            </Typography>

            <Button
              data-testid="create-workflow-button"
              icon={<IconPlus />}
              onClick={() => history.push(ROUTES.AUTOMATIONS.FLOWS_NEW)}
              variant={BUTTON_VARIANTS.SOLID_PILL}
            >
              {t('automations.createNewFlow')}
            </Button>
          </Layout>
        </StyledHeader>

        <TableHorizontalScrollWrapper>
          <StyledTable>
            <StyledTHead>
              <tr>
                <th style={{ maxWidth: 400, minWidth: 250 }}>{t('automations.name')}</th>
                <th style={{ width: '12%', minWidth: 120 }}>{t('automations.messagesSent')}</th>
                <th style={{ width: '12%', minWidth: 120 }}>{t('automations.ctr')}</th>
                <th style={{ width: '12%', minWidth: 120 }}>{t('automations.status')}</th>
                <th style={{ width: 50 }}></th>
              </tr>
            </StyledTHead>
            <StyledTBody>
              {isLoadingListWorkflows ? (
                <tr>
                  <td colSpan={8}>
                    <LoadingIndicator size={20} />
                  </td>
                </tr>
              ) : (
                <>
                  {(workflows || []).length > 0 ? (
                    (workflows || []).map((data) => {
                      return <WorkflowRow key={data.id} workflow={data} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={8} style={{ borderBottom: 0 }}>
                        <Layout display="block" margin="32px auto" maxWidth="310px" textAlign="center">
                          <ZeroMessageIllustration />
                          <Typography component="h2" variant="h3">
                            {t('automations.noFlowsCreated')}
                          </Typography>
                          <Typography color={COLORS.SUBTEXT} variant="body2">
                            {t('automations.noFlowsCreatedBody')}
                          </Typography>
                          <Button
                            onClick={() => history.push(ROUTES.AUTOMATIONS.FLOWS_NEW)}
                            variant={BUTTON_VARIANTS.ACTION}
                          >
                            {t('automations.createNewFlow')}
                          </Button>
                        </Layout>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </StyledTBody>
          </StyledTable>
        </TableHorizontalScrollWrapper>
      </ResponsiveWrapper>
    </SidebarLayout>
  )
}

export function WorkflowRow(props: { workflow: Workflow }): JSX.Element {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const { workflow } = props
  const history = useHistory()
  const clientId = useClientId()
  const { deleteKeywordsOfWorkflow } = useWorkflowKeywords()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { data: analyticsData } = useWorkflowAnalytics(clientId, workflow)

  const workflowStatus = toWorkflowStatus(workflow)
  const isWorkflowActive = workflowStatus === WorkflowStatusTypes.active
  const hasShouldAllowScheduling = shouldAllowScheduling(workflow)

  const { onPause, onResume } = useScheduleWorkflow({
    workflow,
    onSuccess: () => queryClient.invalidateQueries([QUERY_CACHE.WORKFLOW_CONFIG.WORKFLOWS]),
  })
  return (
    <>
      <tr key={workflow.id}>
        <td>
          <StyledEllipsis>
            <StyledWorkflowLink
              onClick={() => analytics.track(analytics.events.Workflows.Edited({ type: workflow.type }))}
              to={route(ROUTES.AUTOMATIONS.FLOW, { workflowId: workflow.id })}
            >
              {workflow.name}
            </StyledWorkflowLink>
            <br />
            <Typography component="div" fontSize="14px" fontWeight="normal" variant="caption1">
              {t('automations.lastEdit', { date: dayjs(workflow.updated_at).format('MMM DD, YYYY') })}
            </Typography>
          </StyledEllipsis>
        </td>
        <td>{analyticsData.totalSent}</td>
        <td>{analyticsData.ctr}</td>
        <td>
          <WorkflowStatus status={workflowStatus} />
        </td>
        <td>
          <StyledWorkflowRowMenu trigger={<IconMore aria-label="workflow options" role="button" />}>
            <Menu.Item
              onClick={() => {
                history.push(route(ROUTES.AUTOMATIONS.FLOW, { workflowId: workflow.id }))

                analytics.track(analytics.events.Workflows.Edited({ type: workflow.type }))
              }}
            >
              Edit
            </Menu.Item>
            {hasShouldAllowScheduling && (
              <Menu.Item onClick={isWorkflowActive ? onPause : onResume}>
                {isWorkflowActive ? t('automations.scheduled.pause') : t('automations.scheduled.resume')}
              </Menu.Item>
            )}
            <Menu.Item
              onClick={() => {
                setShowDeleteDialog(true)
              }}
            >
              Delete
            </Menu.Item>
          </StyledWorkflowRowMenu>
        </td>
      </tr>
      {showDeleteDialog && (
        <Dialog
          message={
            <>
              <Typography variant="body1">
                The following action will delete the workflow <strong>{workflow.name}</strong>.
              </Typography>
              <Typography variant="body1">This action can not be undone.</Typography>
            </>
          }
          onCancel={() => setShowDeleteDialog(false)}
          title="Are you sure?"
        >
          <Dialog.Action onClick={() => setShowDeleteDialog(false)} variant={DIALOG_VARIANTS.EMPHASIZED}>
            Cancel
          </Dialog.Action>
          <Dialog.Action
            onClick={async () => {
              setShowDeleteDialog(false)
              try {
                if (workflow.type === WorkflowType.keyword) {
                  await deleteKeywordsOfWorkflow(workflow.id)
                }
                await deleteWorkflow({ clientId, workflowId: workflow.id })
                queryClient.invalidateQueries([QUERY_CACHE.WORKFLOW_CONFIG.WORKFLOWS])

                analytics.track(analytics.events.Workflows.Deleted({ type: workflow.type }))
              } catch (e) {
                toast.error((e as ApiError).message)
              }
            }}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            Delete
          </Dialog.Action>
        </Dialog>
      )}
    </>
  )
}
