import { HTTP } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type ShopifyStoreDetails = {
  name: string
}

export type ShopifyCustomerDetails = {
  lastOrderDatetime: string
  totalSpent: { amount: number; currency: string }
  url: string
}

export function getShopifyStoreDetails(clientId: string): Promise<ShopifyStoreDetails> {
  return request(route(ENDPOINTS.SHOPIFY.STORE_DETAILS, { clientId }), { ignoreReportingErrorCodes: [404] })
}

export function getShopifyCustomerDetails(
  clientId: string,
  fanSubscriptionId: string,
): Promise<ShopifyCustomerDetails> {
  return request(
    route(ENDPOINTS.SHOPIFY.CUSTOMER_DETAILS, {
      clientId,
      fanSubscriptionId,
    }),
    {
      ignoreReportingErrorCodes: [404],
    },
  )
}

export function postShopifyStoreDetails({ clientId, body }: { clientId: string; body: any }): Promise<any> {
  return request(route(ENDPOINTS.SHOPIFY.STORE_DETAILS, { clientId }), {
    method: HTTP.POST,
    body,
  })
}

export function deleteShopifyStoreDetails({ clientId }: { clientId: string }): Promise<any> {
  return request(route(ENDPOINTS.SHOPIFY.STORE_DETAILS, { clientId }), {
    method: HTTP.DELETE,
  })
}

export type ShopifyInsights = {
  totalRevenue: {
    amount: string
    currency: string
  } | null
  members: {
    total: number
  }
}

export function getShopifyInsights(clientId: string): Promise<ShopifyInsights> {
  return request(
    route(ENDPOINTS.SHOPIFY.INSIGHTS, {
      clientId,
    }),
  )
}
