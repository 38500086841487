import { DigitsFilter } from '@community_dev/filter-dsl/lib/digits'
import {
  FieldTypes,
  MemberDataFilter,
  Placeholder,
  RelationshipOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ConversionGoalSuccessMetric, ConversionGoalType } from '@community_dev/types/lib/api/v1/AiInterface'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { WorkflowType } from '@community_dev/workflow-manifest'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { FileContext } from 'components/FileRejectionDialog'
import { AnalyticsEventSuggested, AnalyticsEventSuggestMode } from 'contexts/AIMessageGeneratorProvider'

export const screens = {
  AllContacts: 'All Contacts',
  CampaignDetails: 'Campaign Details',
  CampaignResponses: 'Campaign Responses',
  Campaigns: 'Campaigns',
  Community: 'Community',
  CommunitySpecificInsights: 'Community Specific Insights',
  Compose: 'Compose',
  ConnectShopifyStore: 'Connect Shopify Store',
  CustomizeShopifyWelcomeMessage: 'Customize Shopify Welcome Message',
  EditShopifyStore: 'Edit Shopify Store',
  GrowthTools: 'Growth Tools',
  Home: 'Home',
  Import: {
    Details: 'Import - Details',
    History: 'Import - History',
    Summary: 'Import - Summary',
  },
  Inbox: 'Inbox',
  Insights: 'Insights',
  Scheduled: 'Scheduled',
  Sent: 'Sent',
  Settings: {
    Billing: 'Settings - Billing',
    Customize: 'Settings - Customize',
  },
  ShopifyInsights: 'Shopify Insights',
  TCR: {
    ApplicationApproved: 'TCR - Application approved',
    ApplicationNotApproved: 'TCR - Application not approved',
    AskIfEIN: 'TCR - Ask if EIN',
    CCRegistrationDetails: 'TCR - CC Registration details',
    ContactInfo: 'TCR - Contact Info',
    EINInfo: 'TCR - EIN info',
    FirstPage: 'TCR - First Page',
    FreeRegistrationDetails: 'TCR - Free Registration details',
    InvoiceRegistrationDetails: 'TCR - Invoice Registration details',
    NotEINConfirmation: 'TCR - Not EIN Confirmation',
    YesEINConfirmation: 'TCR - Yes EIN Confirmation',
  },
}

type GoalSelected = {
  Goal: ConversionGoalType
}

type FullAIMessageGeneratorButtonPressedProperties = {
  Goal: ConversionGoalType
  Tone?: string
  Keywords?: string
  'Additional Details'?: string
  'Success Metric'?: ConversionGoalSuccessMetric
  Description?: string
  Link?: string
}

type GeneratedCampaignSelected = {
  Text: string
}

type CampaignSentFromSuggestedCampaign = {
  'Campaign ID': string
  Suggested: AnalyticsEventSuggested
  'Suggest Mode': AnalyticsEventSuggestMode
  Text: string
}

type MmsProperties = {
  context: FileContext
}

const events = {
  ActivityFilterSelected: 'Activity Filter Selected',
  AddOnCtaClicked: 'Add-on CTA clicked',
  AddOnDetailsViewed: 'Add-on details viewed',
  AdditionalMessageClicked: 'Additional Message Clicked',
  BillingProvided: 'BillingProvided',
  BlastSent: 'Blast Sent',
  Calendar: {
    CampaignCalendarAiMessageScheduled: 'Campaign Calendar AI Message Scheduled',
    CampaignCalendarMessageScheduled: 'Campaign Calendar Message Scheduled',
  },
  CampaignDuplicateCreated: 'Campaign Duplicate Created',
  Community: {
    AddKeywordResponse: 'Community - Add Keyword Response',
    EditDifferentKeywordResponses: 'Community - Edit Different Keyword Responses',
    MaxKeywordResponseReached: 'Community - Max Keyword Response Reached',
    SaveKeywordResponse: 'Community - Save Keyword Response',
  },
  CommunityCreateClicked: 'Community Create Clicked',
  CommunityCreated: 'Community Created',
  CommunityDeleted: 'Community Deleted',
  CommunityMembersAdded: 'Community Members Added',
  CommunityUpdated: 'Community Updated',
  ComposeMessageTextCleared: 'Compose Message Text Cleared',
  ComposeModalOpened: 'Compose Modal Opened',
  ContentWarningGuidelinesViewed: 'Content Filter Guidelines Viewed',
  ContentWarningMessageEdited: 'Content Filter Message Edited',
  ContentWarningOverridden: 'Content Filter Overridden',
  ContentWarningPolicyViewed: 'Content Filter Policy Viewed',
  ContentWarningTriggered: 'Content Filter Triggered',
  ContextExited: 'Context Exited',
  CreateCampaignName: 'Create Campaign Name',
  CustomFieldSelected: 'Custom Field Selected',
  CustomFieldsSelected: 'Custom Fields Selected',
  DMSent: 'DM Sent',
  DowngradeAttempt: 'Downgrade attempt',
  EditCampaignName: 'Edit Campaign Name',
  ExclusionOptionsOpened: 'Exclusion Options Opened',
  FeatureHoldPrompt: 'Feature hold prompt',
  FilterAdded: 'Filter Added',
  FilterRemoved: 'Filter Removed',
  FollowUp: {
    MembersThatClicked: 'Follow up on Members that clicked a link',
    MembersThatDidNotClick: 'Follow up on Members that did not click a link',
    MembersThatDidNotRespond: 'Follow up on Members that did not respond',
    MembersThatResponded: 'Follow up on Members that responded',
    ToAll: 'Follow up on all Members',
  },
  FullTextSearchFiltersApplied: 'Full Text Search - Filters Applied',
  FullTextSearchMembersAddedToCommunity: 'Full Text Search - Members Added to Community',
  FullTextSearchMembersRespondedTo: 'Full Text Search - Members Responded To',
  FullTextSearchRun: 'Full Text Search - Run',
  FullTextSearchSearchDetailsViewed: 'Full Text Search - Search Details Viewed',
  FullTextSearchSearchResultOpened: 'Full Text Search - Search Result Opened',
  GPT: {
    AICampaignSummaryAutoGenerate: 'GPT: AI Campaign Summary Auto Generate',
    AICampaignSummaryButtonPressed: 'GPT: AI Campaign Summary Button Pressed',
    AIMessageGeneratorButtonPressed: 'GPT: AI Message Generator Button Pressed',
    AIMessageOnDmEditButtonPressed: 'GPT: AI Message on DM Edit Button Pressed',
    AIMessageOnDmPressed: 'GPT: AI Message on DM Pressed',
    AIMessageOnDmSendNowButtonPressed: 'GPT: AI Message on DM Send Now Button Pressed',
    CampaignSentFromSuggestedCampaign: 'GPT: Campaign Sent from Suggested Campaign',
    FullAIMessageGeneratorButtonPressed: 'GPT: Full AI Message Generator Button Pressed',
    GenerateCampaignsButtonPressed: 'GPT: Generate Campaigns Button Pressed',
    GeneratedCampaignSelected: 'GPT: Generated Campaign Selected',
    GoalSelected: 'GPT: Goal Selected',
    QuickSuggestButtonPressed: 'GPT: Quick Suggest Button Pressed',
    ReGenerateCampaignsButtonPressed: 'GPT: Re-Generate Campaigns Button Pressed',
    ToneSuggestionsButtonPressed: 'GPT: Tone Suggestions Button Pressed',
  },
  GrowthTools: {
    ActivationTextCustomized: 'Activation Text Customized',
    EmbedLinkClicked: 'Sign Up Unit Link Clicked',
    InsightsLinkClicked: 'Insights Link Clicked',
    PhoneNumberCopied: 'Growth Tools - Phone Number Copied',
    QrCodeDownloaded: 'QR Code Downloaded',
    ShopifyLinkClicked: 'Shopify Link Clicked',
    UrlCopied: 'URL Copied',
  },
  Import: {
    BeginImport: 'CMD self import - Begin import',
    ImportInProgress: 'CMD self import - Import in progress',
    UploadAttempt: 'CMD self import - CSV import attempt',
    UploadError: 'CMD self import - Error during import attempt',
    UploadSuccess: 'CMD self import - CSV import file attached',
  },
  InboxFilter: 'Inbox Filter',
  InboxTruncationUpdated: 'Inbox Truncation Updated',
  KeywordAdded: 'Keyword Added',
  KeywordDeleted: 'Keyword Deleted',
  KeywordUpdated: 'Keyword Updated',
  LinkWarningBypassed: 'Link Warning Bypassed',
  LinkWarningDisplayed: 'Link Warning Displayed',
  LoginFailure: 'Login Failure',
  LoginRedirect: 'Login Redirect',
  LoginSuccess: 'Login Success',
  MGUEmbedCompleted: 'MGU - Embed Completed',
  MMS: {
    ChooseAnotherAttachment: 'MMS: Tapped choose another attachment button',
    FileRejectionSize: 'MMS: Attachment size alert displayed',
    FileRejectionType: 'MMS: Attachment type not supported alert displayed',
    SendAsSms: 'MMS: Tapped send attachment as link button',
    SettingAttachment: 'MMS: Changed media delivery setting to: attachment',
    SettingLink: 'MMS: Changed media delivery setting to: link',
  },
  MarkedUnread: 'Marked Unread',
  MemberBlocked: 'Member Blocked',
  MemberReported: 'Member Reported',
  MemberUnsubscribed: 'Member Unsubscribed',
  MessageDetail: 'Message Detail',
  MessageLimitAlertTriggered: 'Message Limits - Alert Triggered',
  MessageLimitWarningDisplayed: 'Message Limit Warning Displayed',
  MessageLimitsContactUsClicked: 'Message Limits - Contact Us Clicked',
  MessageLimitsPrimaryCTAClicked: 'Message Limits - Primary CTA Clicked',
  MessageLimitsSecondaryCTAClicked: 'Message Limits - Secondary CTA Clicked',
  MessageScheduled: 'Message Scheduled',
  MessageSent: 'Message Sent',
  MessageSourceClicked: 'Message Source Clicked',
  MixedSelectionDialogClosed: 'Mixed Selection Dialog Closed',
  MixedSelectionDialogDisplayed: 'Mixed Selection Dialog Displayed',
  MixedSelectionOptionChosen: 'Mixed Selection Option Chosen',
  MoreMessages: 'Messages Loaded',
  PhoneNumberCopied: 'Phone Number Copied',
  PressANDFilter: 'Press AND Filter',
  PressORFilter: 'Press OR Filter',
  ProfilePictureAdded: 'Profile Picture Added',
  RecurringBirthdayCampaignScheduled: 'Recurring Birthday Campaign Scheduled',
  ReviewUpgradePageLoad: 'Review Upgrade Page Load',
  SchedulingBirthdayMessage: 'Clicked Schedule while Birthday filter active',
  Search: 'Search',
  SeeAllAddOns: 'See All Add-ons',
  SensitiveContentViewed: 'Sensitive Content Viewed',
  SentBirthdayMessage: 'Birthday message sent or scheduled',
  SeriesMessageAdded: 'Series Message Added',
  SeriesMessageDeleted: 'Series Message Deleted',
  SeriesMessageUpdated: 'Series Message Updated',
  ShopifyConnectStore: 'Shopify Connect Store',
  ShopifyLearnMore: 'Shopify Learn More',
  ShopifyRemoveStoreAlert: 'Shopify Remove Store Alert',
  ShopifyStoreConnected: 'Shopify Store Connected',
  ShopifyStoreRemoved: 'Shopify Store Removed',
  SignUpMessagesTabClicked: 'Sign Up Messages Tab Clicked',
  SuggestionCardClicked: 'Suggestion Card Tap',
  TCR: {
    ApplicationApproved: 'TCR - Application approved',
    ApplicationApprovedGetMoreSegments: 'TCR - More segments requested from approval modal',
    ApplicationNotApproved: 'TCR - Application not approved (general)',
    AskIfEINBack: 'TCR - Ask if EIN back arrow',
    AskIfEINCSEmail: 'TCR - Ask if EIN CS email',
    CCRegistrationAgreed: 'TCR - CC Registration agreed',
    CCRegistrationBack: 'TCR - CC Registration back arrow',
    ClosedComposeWarning: 'TCR carrier limit - closed compose warning',
    ComposeAnyway: 'TCR carrier limit - compose anyway',
    ContactInfoBack: 'TCR - Contact info back arrow',
    ContactInfoContinued: 'TCR - Contact info continued',
    ContactUs: 'TCR carrier limit - contact us',
    EINInfoBack: 'TCR - EIN info back arrow',
    EINInfoContinued: 'TCR - EIN info continued',
    ExistingLeaderProvidedOTPInfo: 'TCR - Existing Leader provided OTP info',
    FirstPageContinued: 'TCR - First page continued',
    FirstPageSkipped: 'TCR - First page skipped',
    FreeRegistrationAgreed: 'TCR - Free Registration agreed',
    FreeRegistrationBack: 'TCR - Free Registration back arrow',
    GetMoreSegments: 'TCR - More segments requested from settings',
    InvoiceRegistrationAgreed: 'TCR - Invoice Registration agreed',
    InvoiceRegistrationBack: 'TCR - Invoice Registration back arrow',
    NewLeaderProvidedOTPInfo: 'TCR - New Leader provided OTP info',
    OTPRegistrationAgreed: 'TCR - OTP Registration agreed',
    YesEINConfirmation: 'TCR - Yes EIN confirmation',
    YesEINContinued: 'TCR - Yes EIN and continued',
  },
  TapResourcesInMemberGrowth: 'Tap resources in member growth',
  TermsAccepted: 'Terms Accepted',
  ToggleChartType: 'Toggle chart type',
  ToggleMemberGrowthChart: 'Toggle member growth chart',
  ToggleTotalMembersChart: 'Toggle total members chart',
  TrendingResponsesClicked: 'Trending Responses Clicked',
  TrendingResponsesCouldNotDisplay: 'Trending Responses Could Not Display',
  TrendingResponsesDisplayed: 'Trending Responses Displayed',
  UpgradeCTAClicks: 'Upgrade CTA clicks',
  UploadFailure: 'Upload Failure',
  VoicemailUploaded: 'Voicemail Uploaded',
  WelcomeMessageSaved: 'Welcome Message Saved',
  WhatsAppIntegrationCompleted: 'WhatsApp Integration Completed',
  WhatsAppIntegrationStarted: 'WhatsApp Integration Started',
  Workflows: {
    AddToCommunity: 'Workflow - Add to Community',
    AttachedMedia: 'Workflow - Attached Media',
    Created: 'Workflow - Created',
    Deleted: 'Workflow - Deleted',
    Edited: 'Workflow - Edited',
    Published: 'Workflow - Published',
    SavedMedia: 'Workflow - Saved Media',
  },
}

export type AnalyticsEvent<T = any> = { event: string; properties?: T }

const defaultEvents = {
  ActivityFilterSelected: ({ percentage }: any): AnalyticsEvent => ({
    event: events.ActivityFilterSelected,
    properties: { Percentage: percentage },
  }),
  AddOnCtaClicked: (text: string): AnalyticsEvent => ({
    event: events.AddOnCtaClicked,
    properties: { 'Button Text': text },
  }),
  AddOnDetailsViewed: (title: string): AnalyticsEvent => ({
    event: events.AddOnDetailsViewed,
    properties: { title },
  }),
  AdditionalMessageClicked: (): AnalyticsEvent => ({
    event: events.AdditionalMessageClicked,
  }),
  BillingProvided: (hasCoupon = false): AnalyticsEvent => ({
    event: events.BillingProvided,
    properties: { hasCoupon: hasCoupon },
  }),
  BlastSent: ({
    excludeTags,
    filters,
    giphySent,
    hasDropdownFilter,
    includeTags,
    campaignId,
    mediaDisposition,
    parentSmsCampaignId = undefined,
    placeholders = [],
    remessage = false,
    remessageUnresponsiveMembers = false,
    suggestionCardTitle,
    suggestionCardType,
    suggestionCardUsed,
    aiInterfaceRequestId,
    suggested,
    suggestMode,
    includedFiltersLogic,
    excludedFiltersLogic,
    testCampaign = false,
  }: {
    excludeTags: any
    filters: DigitsFilter
    giphySent: boolean
    includeTags: any
    hasDropdownFilter?: boolean
    campaignId?: string
    mediaDisposition: MediaDisposition
    parentSmsCampaignId?: string
    placeholders?: Placeholder[]
    remessage: boolean
    remessageUnresponsiveMembers?: boolean
    suggestionCardTitle: string
    suggestionCardType: string
    suggestionCardUsed: any
    aiInterfaceRequestId?: string
    suggested?: 'full' | 'partial'
    suggestMode?: 'full' | 'quick'
    includedFiltersLogic?: 'AND' | 'OR' | 'Both AND and OR' | null
    excludedFiltersLogic?: 'AND' | 'OR' | 'Both AND and OR' | null
    testCampaign?: boolean
  }): AnalyticsEvent => {
    return {
      event: events.BlastSent,
      properties: {
        'AI Interface Request ID': aiInterfaceRequestId,
        'Campaign ID': campaignId,
        'Excluded Communities': excludeTags,
        'Excluded Filters Logic': excludedFiltersLogic,
        Filters: filters,
        'Giphy Sent': giphySent,
        'Included Filters Logic': includedFiltersLogic,
        'Media Disposition': mediaDisposition,
        'Message Type': 'Blast',
        'Parent Campaign ID': parentSmsCampaignId,
        Placeholders: placeholders,
        'Suggest Mode': suggestMode,
        Suggested: suggested,
        'Suggestion Card Title': suggestionCardTitle,
        'Suggestion Card Type': suggestionCardType,
        'Suggestion Card Used': suggestionCardUsed,
        Tags: includeTags,
        'Test Campaign': testCampaign,
        hasDropdownFilter,
        remessage,
        remessageUnresponsiveMembers,
      },
    }
  },
  BlockFan: ({
    fanId,
    fanSubscriptionId,
    lastMessageBody,
    lastMessageId,
    lastMessageTimestamp,
    lastMessageHasMedia,
    reason,
  }: {
    fanId: string
    fanSubscriptionId: string
    lastMessageBody?: string
    lastMessageId?: string
    lastMessageTimestamp?: string
    lastMessageHasMedia?: boolean
    reason: string
  }): AnalyticsEvent => ({
    event: events.MemberBlocked,
    properties: {
      'Fan ID': fanId,
      'Fan Subscription ID': fanSubscriptionId,
      'Last Message': lastMessageBody,
      'Last Message Has Media': lastMessageHasMedia,
      'Last Message ID': lastMessageId,
      'Last Message Timestamp': lastMessageTimestamp,
      'Leader Reason': reason,
    },
  }),
  Calendar: {
    CampaignCalendarAiMessageScheduled: (): AnalyticsEvent => ({
      event: events.Calendar.CampaignCalendarAiMessageScheduled,
    }),
    CampaignCalendarMessageScheduled: (): AnalyticsEvent => ({
      event: events.Calendar.CampaignCalendarMessageScheduled,
    }),
  },
  CampaignDuplicateCreated: (): AnalyticsEvent => ({
    event: events.CampaignDuplicateCreated,
  }),
  Community: {
    AddKeywordResponse: (): AnalyticsEvent => ({
      event: events.Community.AddKeywordResponse,
    }),
    EditDifferentKeywordResponses: (): AnalyticsEvent => ({
      event: events.Community.EditDifferentKeywordResponses,
    }),
    MaxKeywordResponseReached: (): AnalyticsEvent => ({
      event: events.Community.MaxKeywordResponseReached,
    }),
    SaveKeywordResponse: (): AnalyticsEvent => ({
      event: events.Community.SaveKeywordResponse,
    }),
  },
  CommunityCreateClicked: (): AnalyticsEvent => ({
    event: events.CommunityCreateClicked,
  }),
  CommunityCreated: (community, includeFansRetroactively?: boolean): AnalyticsEvent => ({
    event: events.CommunityCreated,
    properties: {
      'Community ID': community.id,
      'Community Title': community.title,
      'Retroactive Keywords': includeFansRetroactively === true,
    },
  }),
  CommunityDeleted: (community): AnalyticsEvent => ({
    event: events.CommunityDeleted,
    properties: {
      'Community ID': community.id,
      'Community Title': community.title,
    },
  }),
  CommunityMembersAdded: (community, Count): AnalyticsEvent => ({
    event: events.CommunityMembersAdded,
    properties: {
      'Community ID': community.id,
      'Community Title': community.title,
      Count,
    },
  }),
  CommunityUpdated: (community): AnalyticsEvent => ({
    event: events.CommunityUpdated,
    properties: {
      'Community ID': community.id,
      'Community Title': community.title,
    },
  }),
  ComposeMessageTextCleared: (): AnalyticsEvent => ({
    event: events.ComposeMessageTextCleared,
  }),
  ComposeModalOpened: (): AnalyticsEvent => ({
    event: events.ComposeModalOpened,
  }),
  ContentWarningGuidelinesViewed: ({
    content,
    page,
    triggers,
  }: {
    content?: string
    page: string
    triggers?: string | string[]
  }): AnalyticsEvent => ({
    event: events.ContentWarningGuidelinesViewed,
    properties: {
      'Message Content': content,
      Page: page,
      'Triggering Words': triggers,
    },
  }),
  ContentWarningMessageEdited: ({
    content,
    page,
    triggers,
  }: {
    content?: string
    page: string
    triggers?: string | string[]
  }): AnalyticsEvent => ({
    event: events.ContentWarningMessageEdited,
    properties: {
      'Message Content': content,
      Page: page,
      'Triggering Words': triggers,
    },
  }),
  ContentWarningOverridden: ({
    content,
    page,
    triggers,
  }: {
    content?: string
    page: string
    triggers?: string | string[]
  }): AnalyticsEvent => ({
    event: events.ContentWarningOverridden,
    properties: {
      'Message Content': content,
      Page: page,
      'Triggering Words': triggers,
    },
  }),
  ContentWarningPolicyViewed: ({
    content,
    page,
    triggers,
  }: {
    content?: string
    page: string
    triggers?: string | string[]
  }): AnalyticsEvent => ({
    event: events.ContentWarningPolicyViewed,
    properties: {
      'Message Content': content,
      Page: page,
      'Triggering Words': triggers,
    },
  }),
  ContentWarningTriggered: ({
    content,
    page,
    triggers,
  }: {
    content?: string
    page: string
    triggers?: string | string[]
  }): AnalyticsEvent => ({
    event: events.ContentWarningTriggered,
    properties: {
      'Message Content': content,
      Page: page,
      'Triggering Words': triggers,
    },
  }),
  ContextExited: (seatId: string, name: string, email: string, role = undefined): AnalyticsEvent => ({
    // These properties are in the context of the seat, not the client (leader)
    event: events.ContextExited,
    properties: {
      'Seat Email': email,
      'Seat ID': seatId,
      'Seat Name': name,
      'Seat Role': role,
    },
  }),
  CreateCampaignName: (): AnalyticsEvent => ({
    event: events.CreateCampaignName,
  }),
  CustomFieldSelected: (type: FieldTypes): AnalyticsEvent => ({
    event: events.CustomFieldsSelected,
    properties: {
      'Field Type': type,
    },
  }),
  CustomFieldsSelected: (): AnalyticsEvent => ({
    event: events.CustomFieldsSelected,
  }),
  DMSent: ({ fanId, usedAiSuggestion = false }: { fanId: string; usedAiSuggestion?: boolean }): AnalyticsEvent => ({
    event: events.DMSent,
    properties: {
      'Fan ID': fanId,
      'Message Type': 'DM',
      'Used AI Suggestion': usedAiSuggestion,
    },
  }),
  DowngradeAttempt: (): AnalyticsEvent => ({
    event: events.DowngradeAttempt,
  }),
  EditCampaignName: ({ page }: { page: string }): AnalyticsEvent => ({
    event: events.EditCampaignName,
    properties: {
      Page: page,
    },
  }),
  ExclusionOptionsOpened: (): AnalyticsEvent => ({
    event: events.ExclusionOptionsOpened,
  }),
  FeatureHoldPrompt: ({
    featureName,
  }: {
    featureName: 'link tracking' | 'insights' | 'clustering' | 'scheduling'
  }): AnalyticsEvent => ({
    event: events.FeatureHoldPrompt,
    properties: { Feature: featureName },
  }),
  FilterAdded: ({
    type,
    operator,
    excludedFilter,
  }: {
    type: string
    operator?: RelationshipOperators | null
    excludedFilter?: boolean
  }): AnalyticsEvent => ({
    event: events.FilterAdded,
    properties: { 'Excluded Filter': excludedFilter, Operator: operator?.toUpperCase(), Type: type },
  }),
  FilterRemoved: ({
    type,
    operator,
    excludedFilter,
  }: {
    type: string
    operator?: RelationshipOperators | null
    excludedFilter?: boolean
  }): AnalyticsEvent => ({
    event: events.FilterRemoved,
    properties: { 'Excluded Filter': excludedFilter, Operator: operator?.toUpperCase(), Type: type },
  }),
  FollowUp: {
    MembersThatClicked: ({ parentSmsCampaignId }: { parentSmsCampaignId: string }): AnalyticsEvent => ({
      event: events.FollowUp.MembersThatClicked,
      properties: { 'Parent Campaign ID': parentSmsCampaignId },
    }),
    MembersThatDidNotClick: ({ parentSmsCampaignId }: { parentSmsCampaignId: string }): AnalyticsEvent => ({
      event: events.FollowUp.MembersThatDidNotClick,
      properties: { 'Parent Campaign ID': parentSmsCampaignId },
    }),
    MembersThatDidNotRespond: ({ parentSmsCampaignId }: { parentSmsCampaignId: string }): AnalyticsEvent => ({
      event: events.FollowUp.MembersThatDidNotRespond,
      properties: { 'Parent Campaign ID': parentSmsCampaignId },
    }),
    MembersThatResponded: ({ parentSmsCampaignId }: { parentSmsCampaignId: string }): AnalyticsEvent => ({
      event: events.FollowUp.MembersThatResponded,
      properties: { 'Parent Campaign ID': parentSmsCampaignId },
    }),
    ToAll: ({ parentSmsCampaignId }: { parentSmsCampaignId: string }): AnalyticsEvent => ({
      event: events.FollowUp.ToAll,
      properties: { 'Parent Campaign ID': parentSmsCampaignId },
    }),
  },
  FullTextSearchFiltersApplied: ({
    relatedSearchTerm,
    start,
    end,
    exact,
    sort,
  }: {
    relatedSearchTerm: string
    start: string
    end: string
    exact: boolean
    sort: string
  }): AnalyticsEvent => ({
    event: events.FullTextSearchFiltersApplied,
    properties: {
      End: end,
      Exact: exact,
      'Related Search Term': relatedSearchTerm,
      Sort: sort,
      Start: start,
    },
  }),
  FullTextSearchMembersAddedToCommunity: ({
    numberOfMembers,
    relatedSearchTerm,
    communityAddedTo,
  }: {
    numberOfMembers: number
    relatedSearchTerm: string
    communityAddedTo: string
  }): AnalyticsEvent => ({
    event: events.FullTextSearchMembersAddedToCommunity,
    properties: {
      'Community Added To': communityAddedTo,
      'Number of Members': numberOfMembers,
      'Related Search Term': relatedSearchTerm,
    },
  }),
  FullTextSearchMembersRespondedTo: ({
    numberOfMembers,
    relatedSearchTerm,
  }: {
    numberOfMembers: number
    relatedSearchTerm: string
  }): AnalyticsEvent => ({
    event: events.FullTextSearchMembersRespondedTo,
    properties: {
      'Number of Members': numberOfMembers,
      'Related Search Term': relatedSearchTerm,
    },
  }),
  FullTextSearchRun: ({ termSearched, resultsFound }: { termSearched: string; resultsFound: any }): AnalyticsEvent => ({
    event: events.FullTextSearchRun,
    properties: {
      'Results Found': resultsFound,
      'Term Searched': termSearched,
    },
  }),
  FullTextSearchSearchDetailsViewed: (): AnalyticsEvent => ({
    event: events.FullTextSearchSearchDetailsViewed,
  }),
  FullTextSearchSearchResultOpened: (type: string): AnalyticsEvent => ({
    event: events.FullTextSearchSearchResultOpened,
    properties: { type },
  }),
  GPT: {
    AICampaignSummaryAutoGenerate: (): AnalyticsEvent => ({
      event: events.GPT.AICampaignSummaryAutoGenerate,
      properties: {},
    }),
    AICampaignSummaryButtonPressed: (): AnalyticsEvent => ({
      event: events.GPT.AICampaignSummaryButtonPressed,
      properties: {},
    }),
    AIMessageGeneratorButtonPressed: (): AnalyticsEvent => ({
      event: events.GPT.AIMessageGeneratorButtonPressed,
      properties: {},
    }),
    AIMessageOnDmEditButtonPressed: (): AnalyticsEvent => ({
      event: events.GPT.AIMessageOnDmEditButtonPressed,
      properties: {},
    }),
    AIMessageOnDmPressed: (): AnalyticsEvent => ({
      event: events.GPT.AIMessageOnDmPressed,
      properties: {},
    }),
    AIMessageOnDmSendNowButtonPressed: (): AnalyticsEvent => ({
      event: events.GPT.AIMessageOnDmSendNowButtonPressed,
      properties: {},
    }),
    CampaignSentFromSuggestedCampaign: (
      properties: CamelCasedPropertiesDeep<CampaignSentFromSuggestedCampaign>,
    ): AnalyticsEvent<CampaignSentFromSuggestedCampaign> => ({
      event: events.GPT.CampaignSentFromSuggestedCampaign,
      properties: {
        'Campaign ID': properties.campaignID,
        'Suggest Mode': properties.suggestMode,
        Suggested: properties.suggested,
        Text: properties.text,
      },
    }),
    FullAIMessageGeneratorButtonPressed: (): AnalyticsEvent => ({
      event: events.GPT.FullAIMessageGeneratorButtonPressed,
      properties: {},
    }),
    GenerateCampaignsButtonPressed: (
      properties: CamelCasedPropertiesDeep<FullAIMessageGeneratorButtonPressedProperties>,
    ): AnalyticsEvent<FullAIMessageGeneratorButtonPressedProperties> => ({
      event: events.GPT.GenerateCampaignsButtonPressed,
      properties: {
        'Additional Details': properties.additionalDetails,
        Description: properties.description,
        Goal: properties.goal,
        Keywords: properties.keywords,
        Link: properties.link,
        'Success Metric': properties.successMetric,
        Tone: properties.tone,
      },
    }),
    GeneratedCampaignSelected: (
      properties: CamelCasedPropertiesDeep<GeneratedCampaignSelected>,
    ): AnalyticsEvent<GeneratedCampaignSelected> => ({
      event: events.GPT.GeneratedCampaignSelected,
      properties: {
        Text: properties.text,
      },
    }),
    GoalSelected: (properties: CamelCasedPropertiesDeep<GoalSelected>): AnalyticsEvent<GoalSelected> => ({
      event: events.GPT.GoalSelected,
      properties: {
        Goal: properties.goal,
      },
    }),
    QuickSuggestButtonPressed: (): AnalyticsEvent => ({
      event: events.GPT.QuickSuggestButtonPressed,
      properties: {},
    }),
    ReGenerateCampaignsButtonPressed: (
      properties: CamelCasedPropertiesDeep<FullAIMessageGeneratorButtonPressedProperties>,
    ): AnalyticsEvent<FullAIMessageGeneratorButtonPressedProperties> => ({
      event: events.GPT.ReGenerateCampaignsButtonPressed,
      properties: {
        'Additional Details': properties.additionalDetails,
        Description: properties.description,
        Goal: properties.goal,
        Keywords: properties.keywords,
        Link: properties.link,
        'Success Metric': properties.successMetric,
        Tone: properties.tone,
      },
    }),
    ToneSuggestionsButtonPressed: (): AnalyticsEvent => ({
      event: events.GPT.ToneSuggestionsButtonPressed,
      properties: {},
    }),
  },
  GrowthTools: {
    ActivationTextCustomized: ({ text, url }: { text: string; url: string }): AnalyticsEvent => ({
      event: events.GrowthTools.ActivationTextCustomized,
      properties: {
        Text: text,
        Url: url,
      },
    }),
    EmbedLinkClicked: (): AnalyticsEvent => ({
      event: events.GrowthTools.EmbedLinkClicked,
    }),
    InsightsLinkClicked: (): AnalyticsEvent => ({
      event: events.GrowthTools.InsightsLinkClicked,
    }),
    PhoneNumberCopied: (): AnalyticsEvent => ({
      event: events.GrowthTools.PhoneNumberCopied,
    }),
    QrCodeDownloaded: ({ url }: { url: string }): AnalyticsEvent => ({
      event: events.GrowthTools.QrCodeDownloaded,
      properties: {
        Url: url,
      },
    }),
    ShopifyLinkClicked: (): AnalyticsEvent => ({
      event: events.GrowthTools.ShopifyLinkClicked,
    }),
    UrlCopied: ({ url }: { url: string }): AnalyticsEvent => ({
      event: events.GrowthTools.UrlCopied,
      properties: {
        Url: url,
      },
    }),
  },
  Import: {
    BeginImport: (page?: string): AnalyticsEvent => ({
      event: events.Import.BeginImport,
      properties: {
        Page: page,
      },
    }),
    ImportInProgress: (): AnalyticsEvent => ({
      event: events.Import.ImportInProgress,
    }),
    UploadAttempt: (): AnalyticsEvent => ({
      event: events.Import.UploadAttempt,
    }),
    UploadError: (errors: string[]): AnalyticsEvent => ({
      event: events.Import.UploadError,
      properties: {
        Errors: errors,
      },
    }),
    UploadSuccess: (): AnalyticsEvent => ({
      event: events.Import.UploadSuccess,
    }),
  },
  InboxMessageDetail: (fanId: string): AnalyticsEvent => ({
    event: events.MessageDetail,
    properties: { 'Fan ID': fanId, Page: screens.Inbox },
  }),
  InboxSearch: (term: string): AnalyticsEvent => ({
    event: events.Search,
    properties: { Page: screens.Inbox, 'Search Term': term },
  }),
  InboxTruncationUpdated: (density: string): AnalyticsEvent => ({
    event: events.InboxTruncationUpdated,
    properties: { Density: density },
  }),
  KeywordAdded: (Keyword): AnalyticsEvent => ({
    event: events.KeywordAdded,
    properties: { Keyword },
  }),
  KeywordDeleted: (Keyword): AnalyticsEvent => ({
    event: events.KeywordDeleted,
    properties: { Keyword },
  }),
  KeywordUpdated: (Keyword): AnalyticsEvent => ({
    event: events.KeywordUpdated,
    properties: { Keyword },
  }),
  LinkWarningBypassed: (): AnalyticsEvent => ({
    event: events.LinkWarningBypassed,
  }),
  LinkWarningDisplayed: (): AnalyticsEvent => ({
    event: events.LinkWarningDisplayed,
  }),
  LoginFailure: (): AnalyticsEvent => ({ event: events.LoginFailure }),
  LoginRedirect: (): AnalyticsEvent => ({
    event: events.LoginRedirect,
  }),
  LoginSuccess: (): AnalyticsEvent => ({ event: events.LoginSuccess }),
  MGUEmbedCompleted: (): AnalyticsEvent => ({
    event: events.MGUEmbedCompleted,
  }),
  MMS: {
    ChooseAnotherAttachment: (properties: MmsProperties): AnalyticsEvent<MmsProperties> => ({
      event: events.MMS.ChooseAnotherAttachment,
      properties,
    }),
    FileRejectionSize: (properties: MmsProperties): AnalyticsEvent<MmsProperties> => ({
      event: events.MMS.FileRejectionSize,
      properties,
    }),
    FileRejectionType: (properties: MmsProperties): AnalyticsEvent<MmsProperties> => ({
      event: events.MMS.FileRejectionType,
      properties,
    }),
    SendAsSms: (properties: MmsProperties): AnalyticsEvent<MmsProperties> => ({
      event: events.MMS.SendAsSms,
      properties,
    }),
    SettingAttachment: (): AnalyticsEvent => ({ event: events.MMS.SettingAttachment }),
    SettingLink: (): AnalyticsEvent => ({ event: events.MMS.SettingLink }),
  },
  MarkedUnread: (): AnalyticsEvent => ({
    event: events.MarkedUnread,
  }),
  MessageLimitAlertTriggered: (location): AnalyticsEvent => ({
    event: events.MessageLimitAlertTriggered,
    properties: {
      Location: location,
    },
  }),
  MessageLimitWarningDisplayed: (): AnalyticsEvent => ({
    event: events.MessageLimitWarningDisplayed,
  }),
  MessageLimitsContactUsClicked: (): AnalyticsEvent => ({
    event: events.MessageLimitsContactUsClicked,
  }),
  MessageLimitsPrimaryCTAClicked: (): AnalyticsEvent => ({
    event: events.MessageLimitsPrimaryCTAClicked,
  }),
  MessageLimitsSecondaryCTAClicked: (): AnalyticsEvent => ({
    event: events.MessageLimitsSecondaryCTAClicked,
  }),
  MessageScheduled: ({ isComposingAnother }: { isComposingAnother: boolean }): AnalyticsEvent => ({
    event: events.MessageScheduled,
    properties: { ComposingAnother: isComposingAnother },
  }),
  MessageSent: ({ isComposingAnother }: { isComposingAnother: boolean }): AnalyticsEvent => ({
    event: events.MessageSent,
    properties: { ComposingAnother: isComposingAnother },
  }),
  MessageSourceClicked: (sourceType?: string): AnalyticsEvent => ({
    event: events.MessageSourceClicked,
    properties: {
      'Source Type': sourceType,
    },
  }),
  MixedSelectionDialogClosed: (): AnalyticsEvent => ({
    event: events.MixedSelectionDialogClosed,
  }),
  MixedSelectionDialogDisplayed: (): AnalyticsEvent => ({
    event: events.MixedSelectionDialogDisplayed,
  }),
  MixedSelectionOptionChosen: ({ option }: { option: 'Only Unreplied' | 'All' }): AnalyticsEvent => ({
    event: events.MixedSelectionOptionChosen,
    properties: {
      Option: option,
    },
  }),
  MoreInboxMessages: (page: number, rows: number): AnalyticsEvent => ({
    event: events.MoreMessages,
    properties: { Page: screens.Inbox, 'Page Number': page, 'Row Count': rows },
  }),
  MoreSentMessages: (page: number, rows: number): AnalyticsEvent => ({
    event: events.MoreMessages,
    properties: { Page: screens.Sent, 'Page Number': page, 'Row Count': rows },
  }),
  PhoneNumberCopied: (): AnalyticsEvent => ({
    event: events.PhoneNumberCopied,
  }),
  PressANDFilter: (): AnalyticsEvent => ({
    event: events.PressANDFilter,
  }),
  PressORFilter: (): AnalyticsEvent => ({
    event: events.PressORFilter,
  }),
  ProfilePictureAdded: (): AnalyticsEvent => ({
    event: events.ProfilePictureAdded,
  }),
  RecurringBirthdayCampaignScheduled: ({ frequency }: { frequency: 'daily' | 'weekly' }): AnalyticsEvent => ({
    event: events.RecurringBirthdayCampaignScheduled,
    properties: {
      Frequency: frequency,
    },
  }),
  ReportFan: ({
    fanId,
    fanSubscriptionId,
    lastMessageBody,
    lastMessageId,
    lastMessageTimestamp,
    lastMessageHasMedia,
    reason,
  }: {
    fanId: string
    fanSubscriptionId: string
    lastMessageBody?: string
    lastMessageId?: string
    lastMessageTimestamp?: string
    lastMessageHasMedia?: boolean
    reason: string
  }): AnalyticsEvent => ({
    event: events.MemberReported,
    properties: {
      'Fan ID': fanId,
      'Fan Subscription ID': fanSubscriptionId,
      'Last Message': lastMessageBody,
      'Last Message Has Media': lastMessageHasMedia,
      'Last Message ID': lastMessageId,
      'Last Message Timestamp': lastMessageTimestamp,
      'Leader Reason': reason,
    },
  }),
  ReviewUpgradePageLoad: (): AnalyticsEvent => ({
    event: events.ReviewUpgradePageLoad,
  }),
  SchedulingBirthdayMessage: (): AnalyticsEvent => ({
    event: events.SchedulingBirthdayMessage,
  }),
  SeeAllAddOns: (): AnalyticsEvent => ({
    event: events.SeeAllAddOns,
  }),
  SensitiveContentViewed: (): AnalyticsEvent => ({
    event: events.SensitiveContentViewed,
  }),
  SentBirthdayMessage: ({ isScheduled }: { isScheduled: boolean }): AnalyticsEvent => ({
    event: events.SentBirthdayMessage,
    properties: {
      Scheduled: isScheduled,
    },
  }),
  SentMessageDetail: (campaignId: string): AnalyticsEvent => ({
    event: events.MessageDetail,
    properties: { 'Campaign ID': campaignId, Page: screens.Sent },
  }),
  SentSearch: (term: string): AnalyticsEvent => ({
    event: events.Search,
    properties: { Page: screens.Sent, 'Search Term': term },
  }),
  SeriesMessageAdded: ({ delay, message }: { delay: string; message: string }): AnalyticsEvent => ({
    event: events.SeriesMessageAdded,
    properties: { Delay: delay, Message: message },
  }),
  SeriesMessageDeleted: ({ message }: { message: string }): AnalyticsEvent => ({
    event: events.SeriesMessageAdded,
    properties: { Message: message },
  }),
  SeriesMessageUpdated: ({ delay, message }: { delay: string; message: string }): AnalyticsEvent => ({
    event: events.SeriesMessageUpdated,
    properties: { Delay: delay, Message: message },
  }),
  ShopifyConnectStore: (): AnalyticsEvent => ({
    event: events.ShopifyConnectStore,
  }),
  ShopifyLearnMore: (): AnalyticsEvent => ({ event: events.ShopifyLearnMore }),
  ShopifyRemoveStoreAlert: (): AnalyticsEvent => ({
    event: events.ShopifyRemoveStoreAlert,
  }),
  ShopifyStoreConnected: (): AnalyticsEvent => ({
    event: events.ShopifyStoreConnected,
  }),
  ShopifyStoreRemoved: (): AnalyticsEvent => ({
    event: events.ShopifyStoreRemoved,
  }),
  SignUpMessagesTabClicked: (): AnalyticsEvent => ({
    event: events.SignUpMessagesTabClicked,
  }),
  SuggestionCardClicked: (
    filters: MemberDataFilter | Record<string, never>,
    title: string,
    type: string,
    url?: string,
  ): { event: string; properties?: any } => ({
    event: events.SuggestionCardClicked,
    properties: {
      Filters: filters,
      'Suggestion Card Title': title,
      'Suggestion Card Type': type,
      'Url Clicked': url,
    },
  }),
  TCR: {
    ApplicationApproved: (): AnalyticsEvent => ({
      event: events.TCR.ApplicationApproved,
      properties: {
        Page: screens.TCR.ApplicationApproved,
      },
    }),
    ApplicationApprovedGetMoreSegments: (): AnalyticsEvent => ({
      event: events.TCR.ApplicationApprovedGetMoreSegments,
      properties: {
        Page: screens.TCR.ApplicationApproved,
      },
    }),
    ApplicationNotApproved: (): AnalyticsEvent => ({
      event: events.TCR.ApplicationNotApproved,
      properties: {
        Page: screens.TCR.ApplicationNotApproved,
      },
    }),
    AskIfEINBack: (): AnalyticsEvent => ({
      event: events.TCR.AskIfEINBack,
      properties: { Page: screens.TCR.AskIfEIN },
    }),
    AskIfEINCSEmail: (): AnalyticsEvent => ({
      event: events.TCR.AskIfEINCSEmail,
      properties: { Page: screens.TCR.AskIfEIN },
    }),
    CCRegistrationAgreed: (): AnalyticsEvent => ({
      event: events.TCR.CCRegistrationAgreed,
      properties: { Page: screens.TCR.CCRegistrationDetails },
    }),
    CCRegistrationBack: (): AnalyticsEvent => ({
      event: events.TCR.CCRegistrationBack,
      properties: { Page: screens.TCR.CCRegistrationDetails },
    }),
    ClosedComposeWarning: (): AnalyticsEvent => ({
      event: events.TCR.ClosedComposeWarning,
    }),
    ComposeAnyway: (): AnalyticsEvent => ({
      event: events.TCR.ComposeAnyway,
    }),
    ContactInfoBack: (): AnalyticsEvent => ({
      event: events.TCR.ContactInfoBack,
      properties: { Page: screens.TCR.ContactInfo },
    }),
    ContactInfoContinued: (): AnalyticsEvent => ({
      event: events.TCR.ContactInfoContinued,
      properties: { Page: screens.TCR.ContactInfo },
    }),
    ContactUs: (): AnalyticsEvent => ({
      event: events.TCR.ContactUs,
    }),
    EINInfoBack: (): AnalyticsEvent => ({
      event: events.TCR.EINInfoBack,
      properties: { Page: screens.TCR.EINInfo },
    }),
    EINInfoContinued: (): AnalyticsEvent => ({
      event: events.TCR.EINInfoContinued,
      properties: { Page: screens.TCR.EINInfo },
    }),
    ExistingLeaderProvidedOTPInfo: (): AnalyticsEvent => ({
      event: events.TCR.ExistingLeaderProvidedOTPInfo,
    }),
    FirstPageContinued: (): AnalyticsEvent => ({
      event: events.TCR.FirstPageContinued,
      properties: { Page: screens.TCR.FirstPage },
    }),
    FirstPageSkipped: (): AnalyticsEvent => ({
      event: events.TCR.FirstPageSkipped,
      properties: { Page: screens.TCR.FirstPage },
    }),
    FreeRegistrationAgreed: (): AnalyticsEvent => ({
      event: events.TCR.FreeRegistrationAgreed,
      properties: { Page: screens.TCR.FreeRegistrationDetails },
    }),
    FreeRegistrationBack: (): AnalyticsEvent => ({
      event: events.TCR.FreeRegistrationBack,
      properties: { Page: screens.TCR.FreeRegistrationDetails },
    }),
    GetMoreSegments: (): AnalyticsEvent => ({
      event: events.TCR.GetMoreSegments,
      properties: {
        Page: screens.Settings.Billing,
      },
    }),
    InvoiceRegistrationAgreed: (): AnalyticsEvent => ({
      event: events.TCR.InvoiceRegistrationAgreed,
      properties: { Page: screens.TCR.InvoiceRegistrationDetails },
    }),
    InvoiceRegistrationBack: (): AnalyticsEvent => ({
      event: events.TCR.InvoiceRegistrationBack,
      properties: { Page: screens.TCR.InvoiceRegistrationDetails },
    }),
    NewLeaderProvidedOTPInfo: (): AnalyticsEvent => ({
      event: events.TCR.NewLeaderProvidedOTPInfo,
    }),
    OTPRegistrationAgreed: (): AnalyticsEvent => ({
      event: events.TCR.OTPRegistrationAgreed,
    }),
    YesEINConfirmation: (): AnalyticsEvent => ({
      event: events.TCR.YesEINConfirmation,
      properties: { Page: screens.TCR.YesEINConfirmation },
    }),
    YesEINContinued: (): AnalyticsEvent => ({
      event: events.TCR.YesEINContinued,
      properties: { Page: screens.TCR.AskIfEIN },
    }),
  },
  TapResourcesInMemberGrowth: (): { event: string } => ({
    event: events.TapResourcesInMemberGrowth,
  }),
  TermsAccepted: (): AnalyticsEvent => ({ event: events.TermsAccepted }),
  ToggleChartType: (chart): AnalyticsEvent => ({
    event: events.ToggleChartType,
    properties: {
      chart,
    },
  }),
  ToggleMemberGrowthChart: (range): AnalyticsEvent => ({
    event: events.ToggleMemberGrowthChart,
    properties: {
      range,
    },
  }),
  ToggleTotalMembersChart: (range): AnalyticsEvent => ({
    event: events.ToggleTotalMembersChart,
    properties: {
      range,
    },
  }),
  TrendingResponsesClicked: (): AnalyticsEvent => ({
    event: events.TrendingResponsesClicked,
  }),
  TrendingResponsesCouldNotDisplay: (): AnalyticsEvent => ({
    event: events.TrendingResponsesCouldNotDisplay,
  }),
  TrendingResponsesDisplayed: (): AnalyticsEvent => ({
    event: events.TrendingResponsesDisplayed,
  }),
  UnsubscribeFan: (fanId): AnalyticsEvent => ({
    event: events.MemberUnsubscribed,
    properties: { 'Fan ID': fanId },
  }),
  UpgradeCTAClicks: (): AnalyticsEvent => ({
    event: events.UpgradeCTAClicks,
  }),
  UploadFailure: (error): AnalyticsEvent => ({
    event: events.UploadFailure,
    properties: { ...error },
  }),
  VoicemailUploaded: (): AnalyticsEvent => ({
    event: events.VoicemailUploaded,
  }),
  WelcomeMessageSaved: (): AnalyticsEvent => ({
    event: events.WelcomeMessageSaved,
  }),
  WhatsAppIntegrationCompleted: ({ waba_id }: { waba_id: string }): AnalyticsEvent => ({
    event: events.WhatsAppIntegrationCompleted,
    properties: {
      'WABA ID': waba_id,
    },
  }),
  WhatsAppIntegrationStarted: (): AnalyticsEvent => ({
    event: events.WhatsAppIntegrationStarted,
  }),
  Workflows: {
    AddToCommunity: ({ type }: { type: WorkflowType }): AnalyticsEvent => ({
      event: events.Workflows.AddToCommunity,
      properties: {
        'Flow Type': workflowTypeMap[type],
      },
    }),
    AttachedMedia: ({ type }: { type: WorkflowType }): AnalyticsEvent => ({
      event: events.Workflows.AttachedMedia,
      properties: {
        'Flow Type': workflowTypeMap[type],
      },
    }),
    Created: ({ type }: { type: WorkflowType }): AnalyticsEvent => ({
      event: events.Workflows.Created,
      properties: {
        'Flow Type': workflowTypeMap[type],
      },
    }),
    Deleted: ({ type }: { type: WorkflowType }): AnalyticsEvent => ({
      event: events.Workflows.Deleted,
      properties: {
        'Flow Type': workflowTypeMap[type],
      },
    }),
    Edited: ({ type }: { type: WorkflowType }): AnalyticsEvent => ({
      event: events.Workflows.Edited,
      properties: {
        'Flow Type': workflowTypeMap[type],
      },
    }),
    Published: ({ type }: { type: WorkflowType }): AnalyticsEvent => ({
      event: events.Workflows.Published,
      properties: {
        'Flow Type': workflowTypeMap[type],
      },
    }),
    SavedMedia: ({ type }: { type: WorkflowType }): AnalyticsEvent => ({
      event: events.Workflows.SavedMedia,
      properties: {
        'Flow Type': workflowTypeMap[type],
      },
    }),
  },
}

const workflowTypeMap: Record<WorkflowType, string> = {
  [WorkflowType.signup]: 'Signup series',
  [WorkflowType.shopify_abandoned_checkout]: 'Abandoned checkout',
  [WorkflowType.keyword]: 'Keyword responder',
}

export default defaultEvents
