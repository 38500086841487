import { CommunityLogo, SPACING } from '@community_dev/pixels'
import isFunction from 'lodash/isFunction'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ROUTES } from 'constants/routes'
import SwitchAccountsDropdown from 'containers/Sidebar/SwitchAccountsDropdown'
import { useClient } from 'hooks/useClient'
import { onboardingRedirect } from 'utils/onboarding'

const StyledSimpleLayout = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
`

const StyledMain = styled.div`
  width: 100%;
  height: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const StyledHeader = styled.div`
  z-index: 2;
  width: 100%;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 12px ${SPACING[4]};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  @media (min-width: 720px) {
    padding: 18px 36px;
  }
`

const StyledLogo = styled.div`
  display: flex;
  color: ${({ theme }) => theme?.COLORS?.LOGO};
  opacity: 0.9;
`

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  min-width: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const StyledLogoLink = styled(Link)`
  position: relative;
  top: 2px;
  color: ${({ theme }) => theme?.COLORS?.LOGO};
`

type SimpleLayoutProps = {
  showContextSwitcher?: boolean
  children?: React.ReactNode | ((props: any) => React.ReactNode)
}

export function SimpleLayout({ children, showContextSwitcher }: SimpleLayoutProps): JSX.Element {
  const { t } = useTranslation()
  const contentRef = useRef<HTMLDivElement>(null)
  const { data: client } = useClient()

  const isLogoLink = !onboardingRedirect(client?.onboardingTasks || {})

  return (
    <StyledSimpleLayout>
      <StyledMain>
        <StyledHeader>
          <StyledLogo>
            {isLogoLink ? (
              <StyledLogoLink aria-label={t('simpleLayout.navLogoLabel')} to={ROUTES.HOME}>
                <CommunityLogo height="22px" />
              </StyledLogoLink>
            ) : (
              <StyledLogoLink as="span">
                <CommunityLogo height="22px" />
              </StyledLogoLink>
            )}
          </StyledLogo>
          {showContextSwitcher && (
            <SwitchAccountsDropdown allowSingleContext popoverProps={{ align: 'right' }} size={22} />
          )}
        </StyledHeader>
        <StyledContent ref={contentRef}>{isFunction(children) ? children({ contentRef }) : children}</StyledContent>
      </StyledMain>
    </StyledSimpleLayout>
  )
}

export default SimpleLayout
