import { ClientIcon } from '@community_dev/pixels'

import { StyledClientAvatarIcon, StyledClientAvatarMain, StyledClientAvatarRoot } from './ClientAvatar.style'

type ClientAvatarProps = {
  graphicUrl?: string
  imgProps?: any
  size?: number | string
  fontSize?: string
  name?: string
  onClick?: (...args: any[]) => any
  style?: any
}

const ClientAvatar = (props: ClientAvatarProps): JSX.Element => {
  const { size, graphicUrl, imgProps, name, ...rest } = props

  if (size) {
    const sz = `${parseInt(size.toString(), 10) || 20}px`
    rest.style = { width: sz, height: sz, ...rest.style }
  }

  return (
    <StyledClientAvatarRoot {...rest}>
      <StyledClientAvatarMain>
        <StyledClientAvatarIcon>
          <ClientIcon size={size} />
        </StyledClientAvatarIcon>
        {graphicUrl && <img alt={name} src={graphicUrl} {...imgProps} />}
      </StyledClientAvatarMain>
    </StyledClientAvatarRoot>
  )
}

export default ClientAvatar
