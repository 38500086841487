export const DATE_FORMAT = 'dddd, MMMM Do YYYY'
export const SHORT_DATE_FORMAT = 'MMMM Do YYYY'
export const BILLING_DATE_FORMAT = 'ddd, MMM D, YYYY'

export const PT_TIME_ZONE = 'America/Los_Angeles'

export const DATE_INPUT_FORMAT = 'MM/DD/YYYY'
export const TIME_INPUT_FORMAT = 'h:mm A'
export const DATE_TIME_INPUT_FORMAT = 'YYYY-MM-DDTHH:mm'

export const DATE_FILTER_FORMAT = 'YYYY-MM-DD'

export const DATE_AND_TIME = 'MM/DD/YYYY [at] h:mm A'
export const DAY_FORMAT = 'dddd'
