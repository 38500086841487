import { Api } from '@community_dev/types'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { AI_INTERFACE, FULL_SUGGEST } from '../../AiMessageGeneratorButton/AiMessageGeneratorButton'

import { postSuggestFull } from 'api/ai-interface'
import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { useAiMessageGenerator } from 'contexts/AIMessageGeneratorProvider'
import { useClient, useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import { formatFullName } from 'utils/formatters'

export function useSubmitFullAiMessageGenerator(): () => Promise<void> {
  const { t } = useTranslation()
  const { data: client } = useClient()
  const clientId = useClientId()
  const { showToastMessage } = useToastMessage()
  const { scheduledAt } = useCompose()

  const { tone, keywords, additionalDetails, goal, link, setSuggestions, setRequestId } = useAiMessageGenerator()

  const mutationPostSuggestFull = useMutation(postSuggestFull, { mutationKey: [AI_INTERFACE, FULL_SUGGEST], retry: 3 })

  const onSubmit = useCallback(() => {
    return mutationPostSuggestFull
      .mutateAsync({
        clientId,
        body: {
          client_name: formatFullName(client),
          max_length: 300,
          message_date: scheduledAt ? scheduledAt.toISOString() : new Date().toISOString(),
          count: 4,
          tone: tone,
          keywords: keywords,
          additional_details: additionalDetails,
          goal: goal as Api.V1.RequestGoal,
          link: link,
        },
      })
      .then((response) => {
        setRequestId(response.data.requestId)
        setSuggestions(response.data.suggestions)
      })
      .catch(() => {
        showToastMessage({
          message: t('aiMessageGenerator.fullSuggestError'),
          success: false,
        })
      })
  }, [
    t,
    mutationPostSuggestFull,
    clientId,
    client,
    tone,
    keywords,
    additionalDetails,
    goal,
    link,
    setRequestId,
    setSuggestions,
    showToastMessage,
  ])

  return onSubmit
}
