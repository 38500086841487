import queryString from 'query-string'
import { useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { ROUTES } from 'constants/routes'
import { AuthStates } from 'contexts/AuthProvider'
import { useAuth } from 'hooks/useAuth'
import { GetTheAppScreen } from 'screens/GetTheAppScreen'
import { InviteScreen } from 'screens/InviteScreen'
import LoginScreen from 'screens/LoginScreen'
import { MfaAuthCodeScreen } from 'screens/MfaAuthCodeScreen'
import { PasswordChangeScreen } from 'screens/PasswordChangeScreen'
import PasswordForgotScreen from 'screens/PasswordForgotScreen'
import { SalesforceConnectScreen } from 'screens/SalesforceConnectScreen'

export const UnauthenticatedApp = (): JSX.Element => {
  const { logout, state } = useAuth()
  const match = useRouteMatch(ROUTES.LOGOUT)

  useEffect(() => {
    if (match) logout()
  }, [match, logout])

  const nextPath =
    queryString.parse(window.location.search).next ||
    encodeURIComponent(window.location.href.replace(`${window.location.origin}${import.meta.env.VITE_PUBLIC_URL}`, ''))

  if (state === AuthStates.MFA_CHALLENGE) return <MfaAuthCodeScreen />

  return (
    <Switch>
      <Route component={InviteScreen} exact path={ROUTES.INVITE} />
      <Route component={LoginScreen} exact path={ROUTES.LOGIN} />
      <Route component={PasswordForgotScreen} exact path={ROUTES.RESET_PASSWORD} />
      <Route component={SalesforceConnectScreen} exact path={ROUTES.SALESFORCE.CONNECT} />
      <Route component={PasswordChangeScreen} exact path={ROUTES.CHANGE_PASSWORD} />
      <Route component={GetTheAppScreen} exact path="/gettheapp" />
      <Redirect
        to={{
          pathname: ROUTES.LOGIN,
          search: match || !nextPath || nextPath === '%2F' ? undefined : `?next=${nextPath}`,
        }}
      />
    </Switch>
  )
}
