import {
  MemberDataFilter,
  withoutNotOperator,
  toIncludedAndExcludedMemberDataFilters,
  expandByDemorgansLaw,
  reduceSingleOperandAndOrFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { useTranslation } from 'react-i18next'

import { StyledPanel, StyledMeta, StyledHeader, StyledHeading } from './styled-recipients'

import { useRecipientField } from 'components/ComposeMessage/components/RecipientFieldContext'
import { MemberDataFilterSection } from 'components/ComposeMessage/components/RecommendationsTop/SuggestionsFilter'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useRecentCampaignsCount } from 'hooks/useCountByQuery/useRecentCampaignCount'

export function RecommendationsRecentCampaigns(): JSX.Element {
  const { setIncludedFilters, setExcludedFilters, communicationChannel } = useFilters()
  const recentCampaigns = useRecentCampaignsCount({ traceId: 'recent-campaign-filters', communicationChannel })

  const { t } = useTranslation()
  const { setIsOpen } = useRecipientField()

  return (
    <StyledPanel>
      <StyledMeta>
        <StyledHeader>
          <StyledHeading>{t('compose.recentCampaigns')}</StyledHeading>
        </StyledHeader>
      </StyledMeta>
      <MemberDataFilterSection
        filters={recentCampaigns}
        onClick={(filter: MemberDataFilter) => {
          const { includedFilters, excludedFilters } = toIncludedAndExcludedMemberDataFilters(filter)

          if (includedFilters) {
            // Remove redundant filters
            const reducedIncludedFilters = reduceSingleOperandAndOrFilters(includedFilters)
            setIncludedFilters(reducedIncludedFilters)
          } else {
            setIncludedFilters(null)
          }

          if (excludedFilters) {
            // Remove redundant filters
            const reducedExcludedFilters = reduceSingleOperandAndOrFilters(excludedFilters)
            // Expand by Demorgan's Law
            // This is needed because previous filter syntax was AND(NOT(A), NOT(B)) EX: NOT_EQUALS community filter
            // This is the same as NOT(OR(A, B))
            const expandedExcludedFilters = expandByDemorgansLaw(reducedExcludedFilters)
            // We remove the not operator because setExcludedFilters expects a filter without the not operator
            const excludedFiltersWithoutNotOperator = withoutNotOperator(expandedExcludedFilters)
            setExcludedFilters(excludedFiltersWithoutNotOperator)
          } else {
            setExcludedFilters(null)
          }

          setIsOpen(false)
        }}
        testId="suggested-recent-campaigns"
      />
    </StyledPanel>
  )
}
