import base64 from 'base64-arraybuffer'
import { useEffect, useState } from 'react'

import { exposeLocalCapabilityOverrides } from './expose-local-capability-overrides'

// this exposes the local capability overrides if (and only if) the extension
// is installed
exposeLocalCapabilityOverrides()

const publicKeyJWK = {
  alg: 'RS256',
  e: 'AQAB',
  ext: true,
  key_ops: ['verify'],
  kty: 'RSA',
  n: 's4rpr99HTtvmMGH6KReWm7mDvo6iTrQ4v56ZljkLt-pM6LGhR7UPNiNgDwZxVIJ3F9rkFtTNyyzJB5c0SbjLj9-rD2P0Wm76KbW4i7iLZ5H7KOfJvXxKXykpkjuV-Vmqrpa72dJGqAkD464cQyv8ktqV8TH3ULs-AWDhIfvgmZyRa0inGdxkOMwCLKcC_EKs8flmL1VTl3mn6hozJ1oM1-BRrLBhk1JNAboap-7foG-KzMqwlf_ev8HtMLGFUP4DyXF_ZhpBIczanVrhksGP4fkP-_NEi4Am9tr8z13n9SW-wTEmgLjTWh8rEau8WzyLf_lC8Q8uScnDu7_DV2uYQw',
}

const importKey = (key, keyUsages) =>
  crypto.subtle.importKey(
    'jwk',
    key,
    {
      name: 'RSASSA-PKCS1-v1_5',
      hash: 'SHA-256',
    },
    false,
    keyUsages,
  )

const verifyString = async (string, signature) => {
  const encoded = new window.TextEncoder().encode(string)
  const signatureValue = base64.decode(signature)

  const publicKey = await importKey(publicKeyJWK, ['verify'])

  return crypto.subtle.verify('RSASSA-PKCS1-v1_5', publicKey, signatureValue, encoded)
}

const readLocalCapabilityOverrides = async (): Promise<Record<string, boolean>> => {
  try {
    const string = localStorage.getItem('community-flipper-overrides')
    if (!string) {
      // no overrides present, bail with default value (empty overrides)
      return {}
    }
    // try to grab the json and signature
    const [json, sig] = string.split('--sig--')
    if (await verifyString(json, sig)) {
      // only return overrides if the json passes signature check
      return JSON.parse(json)
    } else {
      // otherwise let the user know what happened and bail with the default
      console.warn('malformed localStorage value')
      return {}
    }
  } catch (e) {
    // if anything goes wrong here, the show must go on: warn and bail with
    // the default
    console.warn('error reading localStorage value:', e)
    return {}
  }
}

export const useLocalCapabilityOverrides = (): Record<string, boolean> => {
  const [localCapabilityOverrides, setLocalCapabilityOverrides] = useState({})

  useEffect(() => {
    readLocalCapabilityOverrides().then((overrides) => setLocalCapabilityOverrides(overrides))
  }, [])
  return localCapabilityOverrides
}
