import { Avatar, FONT_SIZE, MembersIcon, SPACING } from '@community_dev/pixels'
import { ReactNode } from 'react'
import styled, { useTheme } from 'styled-components'

import { Fan } from 'api/fans'

const StyledAvatarContainer = styled.div`
  position: relative;
  display: inline-block;
`

const StyledAvatarBadge = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`

const StyledEmptyAvatar = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${SPACING[8]};
  height: ${SPACING[8]};
  background: linear-gradient(180deg, #a4a9b6 0%, #858a94 100%);
  border-radius: 50%;
  color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  font-family: Arial Rounded MT Bold, Inter;
  font-size: ${FONT_SIZE[5]};
  letter-spacing: 0.48px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`

type MemberAvatarProps = {
  fan: Partial<Fan>
  graphicUrl?: string
  badge?: ReactNode
}

export const MemberAvatar = ({ fan = {}, graphicUrl, badge }: MemberAvatarProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  return (
    <StyledAvatarContainer>
      {fan?.avatar === null ? (
        <StyledEmptyAvatar>
          <MembersIcon color={COLORS?.APP_BACKGROUND_LEVEL_3} />
        </StyledEmptyAvatar>
      ) : (
        <Avatar name={fan.fullName || fan.displayName || ''} src={graphicUrl} />
      )}
      {badge && <StyledAvatarBadge>{badge}</StyledAvatarBadge>}
    </StyledAvatarContainer>
  )
}
