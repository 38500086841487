import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: default;
  user-select: none;
  display: flex;
  align-items: center;
  border: 0;
  appearance: none;
  text-align: left;
  cursor: ${({ onClick }) => (typeof onClick === 'function' ? 'pointer' : undefined)};
`

export const HeaderIconWrapper = styled.div<{
  iconColor?: string
  borderColor?: string
  backgroundColor?: string
}>`
  width: 40px;
  height: 40px;
  color: ${({ iconColor }) => iconColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ borderColor }) => borderColor && `1px solid ${borderColor}`};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`
