import { Layout, SPACING, Tooltip, ToolbarItem, Typography } from '@community_dev/pixels'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { PLACEHOLDER_LIMIT } from 'components/ComposeEditor'

const StyledToolbarItem = styled(ToolbarItem)<{ $isActive: boolean }>`
  padding: 0;
  margin: 0 5px;
  border-bottom: ${({ $isActive, theme }) =>
    $isActive ? `1px solid ${theme?.COLORS?.TEXT}` : '1px solid transparent'};

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme?.COLORS?.TEXT};
      fill: ${({ theme }) => theme?.COLORS?.TEXT};
    `}
`

type InsertDynamicFieldButtonProps = {
  hasNoDynamicFields?: boolean
  hasReachedLimit?: boolean
  hasUnsupportedFilter?: boolean
  isActive?: boolean
  onClick?(): void
}

export function InsertDynamicFieldButton({
  hasNoDynamicFields = false,
  hasReachedLimit = false,
  hasUnsupportedFilter = false,
  isActive = false,
  onClick,
}: InsertDynamicFieldButtonProps): JSX.Element {
  const { t } = useTranslation()
  const disabled = hasNoDynamicFields || hasReachedLimit || hasUnsupportedFilter
  const tooltipContent = useMemo(() => {
    if (hasNoDynamicFields) {
      return t('compose.noDynamicFields')
    } else if (hasReachedLimit) {
      return t('compose.dynamicFieldLimit', { number: PLACEHOLDER_LIMIT })
    } else if (hasUnsupportedFilter) {
      return t('compose.filterNotSupported')
    }
    return ''
  }, [hasNoDynamicFields, hasReachedLimit, hasUnsupportedFilter, t])

  function handleClick() {
    if (onClick && !disabled) {
      onClick()
    }
  }

  return (
    <Tooltip
      content={<Layout padding={SPACING[1]}>{tooltipContent}</Layout>}
      disabled={!tooltipContent}
      placement="bottom"
    >
      <Layout display="inline-flex">
        <StyledToolbarItem
          $isActive={isActive && !disabled}
          aria-label={t('compose.insertDynamicField')}
          disabled={disabled}
          onClick={handleClick}
          type="button"
        >
          <Typography component="span" fontWeight={600} variant="body2">{`{`}</Typography>
          <Typography
            component="span"
            fontWeight={600}
            transform={isActive && !disabled ? 'rotate(45deg)' : 'initial'}
            transformOrigin="50% 55%"
            variant="body2"
          >{`+`}</Typography>
          <Typography component="span" fontWeight={600} variant="body2">{`}`}</Typography>
        </StyledToolbarItem>
      </Layout>
    </Tooltip>
  )
}
