import { Block } from '@community_dev/pixels'
import { useState } from 'react'
import { Bar, BarChart, Cell, LabelList, XAxis, YAxis } from 'recharts'
import styled, { useTheme } from 'styled-components'

import { CampaignInsight } from 'utils/normalize'

type ResponseProps = {
  responses?: CampaignInsight[]
}

const StyledGraph = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
`

export function Responses({ responses }: ResponseProps): JSX.Element | null {
  const { COLORS } = useTheme() || {}
  const [hover, setHover] = useState(false)

  if (!responses?.length) return null

  return (
    <Block id="responses-block" title="Sentiment">
      <StyledGraph>
        <BarChart
          data={responses}
          height={200}
          layout="vertical"
          margin={{ top: 5, right: 36, bottom: -20, left: 16 }}
          onMouseOut={() => setHover(false)}
          onMouseOver={() => setHover(true)}
          width={244}
        >
          <YAxis axisLine={false} dataKey="name" tickLine={false} type="category" />
          <XAxis axisLine={false} dataKey="count" tick={false} type="number" />
          <Bar dataKey="count" fill={COLORS?.LINKS} maxBarSize={32}>
            {responses &&
              responses.map((entry, idx) => {
                const color = idx < 2 && entry.count > 0 ? COLORS?.LINKS : COLORS?.DEPRECATED_RECEIVED
                return <Cell fill={color} key={entry.name} />
              })}
            {hover && <LabelList dataKey="count" position="right" />}
          </Bar>
        </BarChart>
      </StyledGraph>
    </Block>
  )
}
