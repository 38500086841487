import React, { ReactNode, createContext, useContext, useState } from 'react'

interface BoundsContextType {
  bounds: DOMRect | null
  setBounds: React.Dispatch<React.SetStateAction<DOMRect | null>>
}

const BoundsContext = createContext<BoundsContextType | undefined>(undefined)

const BoundsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [bounds, setBounds] = useState<DOMRect | null>(null)

  return <BoundsContext.Provider value={{ bounds, setBounds }}>{children}</BoundsContext.Provider>
}

function useBounds(): BoundsContextType {
  const context = useContext(BoundsContext)
  if (!context) {
    throw new Error('useBounds must be used within a BoundsProvider')
  }
  return context
}

export { BoundsProvider, useBounds }
