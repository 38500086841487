import { SPACING } from '@community_dev/pixels'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { ComposeNavigationContent } from './ComposeNavigationContent'
import { ComposeNavigationHeader } from './ComposeNavigationHeader'
import { ComposeNavigationIcon } from './ComposeNavigationIcon'
import { ComposeNavigationItem } from './ComposeNavigationItem'

const StyledList = styled.ul`
  width: 100%;
  padding: ${SPACING[5]} 0 0 0;
  margin: 0;
  list-style: none;
`

type ComposeNavigationProps = {
  children?: ReactNode
  title?: string
}

export function ComposeNavigation({ title, children }: ComposeNavigationProps): JSX.Element {
  return (
    <nav aria-label={title}>
      <StyledList>{children}</StyledList>
    </nav>
  )
}

ComposeNavigation.Content = ComposeNavigationContent
ComposeNavigation.Header = ComposeNavigationHeader
ComposeNavigation.Icon = ComposeNavigationIcon
ComposeNavigation.Item = ComposeNavigationItem
