import { Loader } from 'google-maps/lib/esm'
import once from 'lodash/once'

import { baseConfig } from 'utils/config'

export const loadGoogleMapsLoader = once(async () => {
  const loader = new Loader(baseConfig.placesApiKey, {
    version: '3.52',
    libraries: ['places'],
  })

  return loader.load()
})
