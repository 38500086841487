import { SPACING, SearchBar } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledRecommendationHeader = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.OVERLINE};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  margin: ${SPACING[4]} ${SPACING[4]} ${SPACING[2]};
  display: flex;
  justify-content: space-between;
`

export const StyledPaneMain = styled.main`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  header {
    padding: ${SPACING[4]};

    h3 {
      margin-bottom: 0;
    }
  }
`

export const StyledSearchBar = styled(SearchBar)`
  margin-top: ${SPACING[4]};
`

export const StyledPaneContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`

export const StyledPaneEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  text-align: center;
  user-select: none;
`
