import { ApiError } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { Fan, getFan, postFanIndexByQuery } from 'api/fans'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'

export function useFan(fanId?: string, { enabled, ...options }: UseQueryOptions<Fan> = {}): UseQueryResult<Fan> {
  return useQuery({
    queryKey: [QUERY_CACHE.FAN, fanId],
    queryFn: () => getFan(fanId),
    enabled: Boolean(fanId) && enabled,
    staleTime: STALE_TIME.FIVE_MINUTES,
    ...options,
  })
}

export function useFans(
  query = '',
  communicationChannel: CommunicationChannel = CommunicationChannel.SMS,
  queryConfig: UseQueryOptions<Fan[], ApiError> = {},
): UseQueryResult<Fan[], ApiError> {
  return useQuery({
    queryKey: [QUERY_CACHE.FANS.INDEX_BY_QUERY, query, communicationChannel],
    queryFn: () => postFanIndexByQuery(query, communicationChannel),
    staleTime: STALE_TIME.FIVE_MINUTES,
    ...queryConfig,
  })
}
