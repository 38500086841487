import { AxisBottom, AxisLeft } from '@visx/axis'
import { localPoint } from '@visx/event'
import { Group } from '@visx/group'
import { scaleLinear, scaleTime } from '@visx/scale'
import { Bar, Line, LinePath } from '@visx/shape'
import { Tooltip, withTooltip } from '@visx/tooltip'
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip'
import { bisector, extent, max, min } from 'd3-array'
import { transparentize } from 'polished'
import React, { useCallback, useMemo, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { LineChartLoadingIndicator } from '.'

import { MeasuredContainer } from 'components/MeasuredContainer'
import dayjs from 'utils/dayjs'
import { formatLargeNumber } from 'utils/number'

const TOOLTIP_WIDTH = 130
const HALF_TOOLTIP_WIDTH = TOOLTIP_WIDTH / 2
const VERTICAL_MARGIN = 20
const NOW_INDICATOR_WIDTH = 3
const StyledTooltip = styled(Tooltip)<{ $left: number }>`
  ${({ $left }) => css`
    left: ${$left}px;
  `}
  width: ${TOOLTIP_WIDTH}px;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme?.COLORS?.TOOLTIP_BACKGROUND} !important;
  color: ${({ theme }) => theme?.COLORS?.TOOLTIP_TEXT} !important;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION2};
`
const StyledTooltipLine = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledTooltipKey = styled.span`
  color: ${({ theme }) => theme?.COLORS?.TOOLTIP_LABEL};
`

type TooltipData = LineChartData

export type LineChartData = {
  x: Date | number | string
  y: number
  index?: any
  tooltipLeft?: number
}

// accessors
const getX = (d: LineChartData) => new Date(d?.x)
const getY = (d: LineChartData) => d.y
const bisectDate = bisector(getX).center

const toShortenedNumber = (number) =>
  new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(number)

const defaultTooltipDateFormat = (date: Date) => dayjs(date).format('M/DD/YYYY')

export type LineChartProps = {
  margin?: { top: number; right: number; bottom: number; left: number }
  data?: LineChartData[]
  bottomNumTicks: number
  isLoading?: boolean
  formatXAxis: (d: any) => any
  formatTooltipValue?: (d: Date) => string
}

export function LineChart({
  margin = { top: 10, right: 30, bottom: 0, left: 40 },
  showTooltip,
  hideTooltip,
  tooltipData,
  tooltipLeft = 0,
  tooltipTop = 0,
  data = [],
  bottomNumTicks,
  isLoading = false,
  formatXAxis,
  formatTooltipValue = defaultTooltipDateFormat,
}: LineChartProps & WithTooltipProvidedProps<TooltipData>): JSX.Element | null {
  const { COLORS } = useTheme() || {}
  const [width, setWidth] = useState(600)
  const [height, setHeight] = useState(202)

  // bounds
  const xMax = width - margin.left - margin.right
  const yMax = height - VERTICAL_MARGIN - margin.top

  // scales
  const xScale = useMemo(() => {
    return (
      data &&
      scaleTime({
        range: [0, xMax],
        domain: extent(data, getX),
      })
    )
  }, [data, xMax])

  const yScale = useMemo(() => {
    // we want the y axis to be more centered
    // we do this by adding 10% to the min and max
    // we dont allow the y axis to be negative
    const minData = min(data, getY)
    // min - 10%
    const minPlus = minData - minData * 0.1
    // min bounded to not be able to be less than 0
    const minBounded = minPlus > 0 ? minPlus : 0
    // max + 10%
    const maxPlus = max(data, getY) * 1.1
    return (
      data &&
      scaleLinear({
        range: [yMax, 0],
        domain: [minBounded, maxPlus],
        nice: true,
      })
    )
  }, [yMax, data])
  // tooltip handler
  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      const { x } = localPoint(event) || { x: 0 }
      const xWithMargins = x - margin.left
      const x0 = xScale.invert(xWithMargins).valueOf()
      const index = bisectDate(data, x0, 1)
      const d0 = data[index - 1]
      const d1 = data[index]
      let d = d0

      if (d1 && getX(d1)) {
        d = x0 - getX(d0).valueOf() > getX(d1).valueOf() - x0 ? d1 : d0
      }

      const left = xScale(getX(d))

      // This is so the tooltip does not clip at either end
      let tooltipSidePadding = 0
      if (left < HALF_TOOLTIP_WIDTH) {
        tooltipSidePadding = HALF_TOOLTIP_WIDTH
      }

      showTooltip({
        tooltipData: {
          x: getX(d),
          y: getY(d),
          index,
          // actual tooltip
          tooltipLeft: left + tooltipSidePadding,
        },
        // dot from the tooltip
        tooltipLeft: left + margin.left,
        tooltipTop: yScale(getY(d)) + margin.top,
      })
    },
    [xScale, yScale, data, showTooltip, margin.left, margin.top],
  )

  if (isLoading) {
    return <LineChartLoadingIndicator />
  }

  if (width < 10 || !data.length) return null

  const nowX = xScale(getX(data[data.length - 1]))
  const nowY = yScale(getY(data[data.length - 1]))

  return (
    <MeasuredContainer
      onResize={({ width, height }) => {
        setWidth(width)
        setHeight(height)
      }}
    >
      <svg height={height} width={width}>
        <Group left={margin.left} top={margin.top}>
          <Group transform={`translate(${nowX}, ${nowY})`}>
            <circle
              fill={transparentize(0.9, COLORS?.DATA_TO)}
              height={NOW_INDICATOR_WIDTH * 4}
              r={NOW_INDICATOR_WIDTH * 4}
              width={NOW_INDICATOR_WIDTH * 4}
            />
            <circle
              fill={transparentize(0.8, COLORS?.DATA_TO)}
              height={NOW_INDICATOR_WIDTH * 2}
              r={NOW_INDICATOR_WIDTH * 2}
              width={NOW_INDICATOR_WIDTH * 2}
            />
            <circle
              fill={transparentize(0.5, COLORS?.DATA_TO)}
              height={NOW_INDICATOR_WIDTH}
              r={3}
              width={NOW_INDICATOR_WIDTH}
            />
          </Group>
          <LinePath<LineChartData>
            data={data}
            stroke={COLORS?.DATA_TO}
            strokeWidth={2}
            x={(d) => xScale(getX(d)) ?? 0}
            y={(d) => yScale(getY(d)) ?? 0}
          />
          <AxisLeft
            hideAxisLine
            hideTicks
            numTicks={3}
            scale={yScale}
            stroke={COLORS?.LINKS}
            tickFormat={toShortenedNumber}
            tickLabelProps={() => ({
              fill: COLORS?.TEXT,
              fontSize: 11,
              textAnchor: 'end',
              dy: '0.33em',
            })}
            tickStroke={COLORS?.LINKS}
          />
          <AxisBottom
            hideTicks
            numTicks={bottomNumTicks}
            scale={xScale}
            stroke={COLORS?.BORDERS}
            tickFormat={formatXAxis}
            tickLabelProps={() => ({
              fill: COLORS?.TEXT,
              fontSize: 11,
              textAnchor: 'middle',
            })}
            tickStroke={COLORS?.BORDERS}
            top={yMax}
          />
        </Group>
        <svg width={'100%'} x={0} y={0}>
          <text
            fill={COLORS?.TEXT}
            fontSize={11}
            style={{
              transform: 'translateX(10px)',
            }}
            textAnchor="middle"
            x={xMax + margin.right}
            y={yMax + margin.top + VERTICAL_MARGIN}
          >
            Now
          </text>
        </svg>
        {tooltipData && (
          <>
            <Line
              from={{ x: tooltipLeft, y: margin.top + VERTICAL_MARGIN }}
              pointerEvents="none"
              stroke={COLORS?.TOOLTIP_BACKGROUND}
              strokeWidth={2}
              to={{ x: tooltipLeft, y: yMax + margin.top }}
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop + 1}
              fill="black"
              fillOpacity={0.1}
              pointerEvents="none"
              r={4}
              stroke="black"
              strokeOpacity={0.1}
              strokeWidth={2}
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              fill={transparentize(0.5, COLORS?.DATA_TO)}
              pointerEvents="none"
              r={4}
              stroke="white"
              strokeWidth={2}
            />
          </>
        )}
        <Bar
          fill="transparent"
          height={yMax}
          onMouseLeave={hideTooltip}
          onMouseMove={handleTooltip}
          onTouchMove={handleTooltip}
          onTouchStart={handleTooltip}
          rx={14}
          width={xMax}
          x={margin.left}
          y={margin.top}
        />
      </svg>
      {tooltipData && (
        <StyledTooltip $left={tooltipData?.tooltipLeft || 0 - NOW_INDICATOR_WIDTH}>
          <StyledTooltipLine>
            <StyledTooltipKey>Total:</StyledTooltipKey> <span>{formatLargeNumber(getY(tooltipData))}</span>
          </StyledTooltipLine>
          <StyledTooltipLine>
            <StyledTooltipKey>Date:</StyledTooltipKey> <span>{formatTooltipValue(getX(tooltipData))}</span>
          </StyledTooltipLine>
        </StyledTooltip>
      )}
    </MeasuredContainer>
  )
}

export default withTooltip<LineChartProps, TooltipData>(LineChart)
