import { humanizeMemberDataFilterShortened } from '@community_dev/filter-dsl/lib/humanize/humanizeMemberDataFilters'
import {
  AgeMatch,
  findAll,
  GenderMatch,
  isMemberDataFilter,
  LocationMatch,
  MemberDataFilter,
  oneOf,
  parseFilters,
  removeNodeByMatch,
  serializeFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { jsonLogicToMemberDataFilter } from '@community_dev/filter-dsl/lib/transformers/jsonLogicToMemberDataFilter'
import { memberDataFilterToJsonLogic } from '@community_dev/filter-dsl/lib/transformers/memberDataFilterToJsonLogic'
import { WorkflowEditorCardTypes, WORKFLOW_CARDS } from '@community_dev/flexible-canvas'
import {
  Button,
  BUTTON_VARIANTS,
  CloseIcon,
  Layout,
  Modal,
  MODAL_VARIANTS,
  Pill,
  SPACING,
  Typography,
} from '@community_dev/pixels'
import {
  removeStep,
  ManifestFunctionLogic,
  ManifestFunctionReference,
  isLogic,
  ManifestFunctionCondition,
  removeBranch,
  updateLogicCondition,
  addBranch,
} from '@community_dev/workflow-manifest'
import cloneDeep from 'lodash/cloneDeep'
import { useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { useManifestPlanItem } from '../../hooks/useManifestPlanItem'
import IconCircleAdd from '../../svg/circle-add.svg?react'
import IconEdit from '../../svg/edit.svg?react'
import IconTrash from '../../svg/trash.svg?react'
import { WorkflowSidebarHeader } from '../WorkflowSidebarHeader/WorkflowSidebarHeader'

import { AddPathButton } from './WorkflowSidebarEditLogic.style'

import {
  RecommendationsAge,
  RecommendationsGenderIdentity,
  RecommendationsLocation,
} from 'components/ComposeMessage/components'
import { RecipientFieldContextProvider } from 'components/ComposeMessage/components/RecipientFieldContext'
import { RecipientPane, RecipientPaneHandle } from 'components/ComposeMessage/RecipientPane'
import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { logicIndexToPathIndex } from 'components/WorkflowEditor/utils/logicIndexToPathIndex'
import { ROUTES } from 'constants/routes'
import { FilterPane } from 'containers/RecipientRecommendations/recommendation-constants'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useToastMessage } from 'hooks/useToastMessage'
import Sentry from 'integrations/Sentry'
import { CancelLink, DoneButton } from 'screens/WorkflowScreen/WorkflowScreen.style'
import { route } from 'utils/router'

const WorkflowAction = WORKFLOW_CARDS[WorkflowEditorCardTypes.LOGIC]

export type WorkflowSidebarEditLogicProps = {
  mode: 'add' | 'edit'
}

/**
 * This utility loops over a JsonLogic if array, which follows
 * the [...[condition, reference,], reference] pattern, and adds
 * additional data to make it easier to work with within the
 * component in this file.
 *
 * It stores the type: either condition, reference, or else,
 * as well as the data and the index.
 *
 * @param {ManifestFunctionLogic} logic
 */
export function* traverseLogic(logic: ManifestFunctionLogic): Generator<{
  value: ManifestFunctionCondition | ManifestFunctionReference | null
  type: 'condition' | 'reference' | 'else'
  index: number
}> {
  for (const [i, statement] of Object.entries(logic.if)) {
    const index = Number(i)

    // all even indices are string references to outcome functions
    if ((index + 1) % 2 === 0) {
      yield {
        value: statement,
        type: 'reference',
        index: Number(i),
      }
      // if it is the last  item int he array, it is the "else" condition
    } else if ((index + 1) % 2 === 1 && index === logic.if.length - 1) {
      yield {
        value: statement,
        type: 'else',
        index: Number(i),
      }
      // all other odd indices are "if" logic conditions
    } else if ((index + 1) % 2 === 1) {
      yield {
        value: statement,
        type: 'condition',
        index: Number(i),
      }
    }
  }
}

export enum FilterModalMode {
  AddPath = 'AddPath',
  EditPath = 'EditPath',
}

export type LocationState = {
  initFilterModalOpen?: boolean
  initFilterModalMode?: FilterModalMode
  initFilterModalConditionIndex?: number
  initFilters?: MemberDataFilter
}

const PANES: Record<string, any> = {
  [FilterPane.AGE]: () => RecommendationsAge,
  [FilterPane.GENDER]: () => RecommendationsGenderIdentity,
  [FilterPane.LOCATION]: () => RecommendationsLocation,
}

export function PrivateWorkflowSidebarEditLogic(props: WorkflowSidebarEditLogicProps): JSX.Element | null {
  const { t } = useTranslation()
  const history = useHistory<LocationState>()
  const { COLORS } = useTheme() || {}
  const { showToastMessage } = useToastMessage()
  const { workflowId, functionKey, functionIndex } =
    useParams<{ workflowId: string; functionKey: string; functionIndex: string }>()

  const { state = {} } = history.location
  const {
    initFilterModalOpen = false,
    initFilterModalMode = undefined,
    initFilterModalConditionIndex = 0,
    initFilters = null,
  } = state

  const { manifest, isLoading, dispatch } = useWorkflowProvider()

  const { planItem: manifestFunctionLogic } = useManifestPlanItem<ManifestFunctionLogic>()

  const [filterModalOpen, setFilterModalOpen] = useState(initFilterModalOpen)
  const [filterModalMode, setFilterModalMode] = useState<FilterModalMode | undefined>(initFilterModalMode)
  const [filterModalConditionIndex, setFilterModalConditionIndex] = useState(initFilterModalConditionIndex)

  const { filters, setIncludedFilters, setActiveSubtree } = useFilters({
    initialState: {
      includedFilters: initFilters,
      excludedFilters: null,
    },
  })

  const recipientPaneRef = useRef<RecipientPaneHandle>(null)

  const onSubmit = async () => {
    try {
      dispatch({
        type: 'update',
        state: {
          manifest,
        },
      })

      history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          workflowId,
          manifest,
        },
      })

      showToastMessage({
        message: t('automations.errorSavingFlow'),
        success: false,
      })
    }
  }

  const onFilterModalSave = async (mode: FilterModalMode, filters: void | ManifestFunctionCondition) => {
    if (!manifestFunctionLogic) {
      return null
    }

    if (!filters) {
      return null
    }

    if (mode === FilterModalMode.AddPath) {
      const nextManifest = addBranch(
        manifest,
        functionKey,
        Number(functionIndex),
        manifestFunctionLogic.if.length - 1,
        filters,
      )

      try {
        dispatch({
          type: 'update',
          state: {
            manifest: nextManifest,
          },
        })
      } catch (e) {
        Sentry.captureException(e)
      }
    } else if (mode === FilterModalMode.EditPath) {
      const nextManifest = updateLogicCondition(
        manifest,
        functionKey,
        Number(functionIndex),
        filterModalConditionIndex,
        filters,
      )

      try {
        dispatch({
          type: 'update',
          state: {
            manifest: nextManifest,
          },
        })
      } catch (e) {
        Sentry.captureException(e)
      }
    }

    setFilterModalMode(undefined)
  }

  const closeFilterModal = () => {
    if (initFilterModalOpen) {
      history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
    } else {
      setFilterModalOpen(false)
      setIncludedFilters(null)
      setFilterModalConditionIndex(0)
    }
  }

  const deletePath = (index: number) => {
    if (isLogic(manifestFunctionLogic)) {
      const nextManifest = removeBranch(manifest, functionKey, Number(functionIndex), index)

      try {
        dispatch({
          type: 'update',
          state: {
            manifest: nextManifest,
          },
        })
      } catch (e) {
        Sentry.captureException(e)
      }
    }
  }

  if (isLoading) {
    return null
  }

  return (
    <Layout display="flex" flexDirection="column" height="100%" width="100%">
      <Layout flex="1" marginTop="8px" padding="16px">
        <WorkflowSidebarHeader workflowAction={WorkflowAction} />

        <Layout marginTop="24px">
          <Form
            onSubmit={onSubmit}
            render={(formRenderProps) => {
              const { handleSubmit } = formRenderProps

              return (
                <form id="logicForm" onSubmit={handleSubmit}>
                  {isLogic(manifestFunctionLogic) &&
                    Array.from(traverseLogic(manifestFunctionLogic))
                      .filter(({ type }) => type === 'condition')
                      .map(({ value, index }, i) => {
                        const memberDataFilters = value
                          ? jsonLogicToMemberDataFilter(value as ManifestFunctionCondition)
                          : null

                        const filtersAst = parseFilters(memberDataFilters)

                        const mdfArray = Array.from(findAll(filtersAst, oneOf(LocationMatch, AgeMatch, GenderMatch)))
                          .map(serializeFilters)
                          .filter(isMemberDataFilter)

                        return (
                          <Layout
                            border={`1px solid ${COLORS.BORDERS}`}
                            borderRadius="8px"
                            data-testid={`Path ${i + 1}`}
                            key={i}
                            marginBottom="25px"
                            padding="8px 12px"
                          >
                            <Layout
                              alignItems="center"
                              display="flex"
                              flexDirection="row"
                              marginBottom="8px"
                              width="100%"
                            >
                              <Typography component="div" flex="1" fontSize="14px" fontWeight="bold" margin="0">
                                {t('automations.path')} {i + 1}
                              </Typography>
                              <Layout alignItems="center" display="flex" justifyContent="center">
                                {i > 0 && (
                                  <AddPathButton
                                    onClick={() => {
                                      deletePath(index)
                                    }}
                                    style={{ marginRight: 10 }}
                                  >
                                    <IconTrash />
                                    {t('delete')}
                                  </AddPathButton>
                                )}
                                <AddPathButton
                                  onClick={() => {
                                    const filters = memberDataFilters || null
                                    setIncludedFilters(filters)
                                    setActiveSubtree(parseFilters(filters))
                                    setFilterModalMode(FilterModalMode.EditPath)
                                    setFilterModalOpen(true)
                                    setFilterModalConditionIndex(index)
                                  }}
                                >
                                  <IconEdit />
                                  {t('edit')}
                                </AddPathButton>
                              </Layout>
                            </Layout>

                            {Array.isArray(mdfArray) && (
                              <>
                                {mdfArray.length > 0 ? (
                                  mdfArray.map((memberDataFilter) => {
                                    const filterString = humanizeMemberDataFilterShortened(memberDataFilter)
                                    return (
                                      <Layout marginBottom="5px">
                                        <Pill
                                          key={filterString}
                                          onRemove={async () => {
                                            const fn: ManifestFunctionLogic = {
                                              if: [...manifestFunctionLogic.if],
                                            }
                                            const nextManifest = cloneDeep(manifest)
                                            const nextFilters = serializeFilters(
                                              removeNodeByMatch(filtersAst, memberDataFilter),
                                            )

                                            const jsonLogicFilters = nextFilters
                                              ? memberDataFilterToJsonLogic(nextFilters)
                                              : null

                                            fn.if[index] = jsonLogicFilters || null

                                            if (nextManifest.functions?.[functionKey]?.[functionIndex]) {
                                              nextManifest.functions[functionKey][Number(functionIndex)] = fn
                                              try {
                                                dispatch({
                                                  type: 'update',
                                                  state: {
                                                    manifest: nextManifest,
                                                  },
                                                })
                                              } catch (e) {
                                                Sentry.captureException(e)
                                              }
                                            }
                                          }}
                                          removable
                                          style={{ marginRight: 10 }}
                                        >
                                          {filterString}
                                        </Pill>
                                      </Layout>
                                    )
                                  })
                                ) : (
                                  <Layout margin="12px 0 24px" textAlign="center">
                                    <Typography color={COLORS.SUBTEXT} component="span" variant="body2">
                                      {t('automations.addYourFilters')}
                                    </Typography>
                                  </Layout>
                                )}
                              </>
                            )}
                          </Layout>
                        )
                      })}

                  {isLogic(manifestFunctionLogic) && manifestFunctionLogic.if[0] !== null && (
                    <AddPathButton
                      onClick={async () => {
                        setFilterModalMode(FilterModalMode.AddPath)
                        setFilterModalOpen(true)
                      }}
                      style={{ marginTop: 20 }}
                    >
                      <IconCircleAdd />
                      {t('automations.addPath')}
                    </AddPathButton>
                  )}
                </form>
              )
            }}
          />
        </Layout>
      </Layout>
      <Layout
        alignItems="center"
        borderTop={`1px solid ${COLORS?.BORDERS}`}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding="45px 16px"
      >
        <DoneButton form="logicForm" type="submit">
          {t('done')}
        </DoneButton>

        <CancelLink
          onClick={async () => {
            // if we cancel while adding a logic step
            if (props.mode === 'add') {
              // we want to keep the else branch
              const elseBranchIndex = Array.from(
                traverseLogic(manifest.functions[functionKey][functionIndex] as ManifestFunctionLogic),
              ).findIndex(({ type }) => type === 'else')
              const nextManifest = removeStep(manifest, functionKey, Number(functionIndex), elseBranchIndex)

              try {
                dispatch({
                  type: 'update',
                  state: {
                    manifest: nextManifest,
                  },
                })
              } catch (e) {
                Sentry.captureException(e)
              }
            }
          }}
          style={{ marginTop: SPACING[5] }}
          to={route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId })}
        >
          {t('cancel')}
        </CancelLink>
      </Layout>

      <Modal
        maxHeight="none"
        maxWidth={700}
        onClose={closeFilterModal}
        open={filterModalOpen}
        variant={MODAL_VARIANTS.ROUNDED}
      >
        <Modal.Header style={{ height: 50 }}>
          <Modal.Header.Center>
            {t('automations.pathIndexFiltersAre', {
              index: logicIndexToPathIndex(filterModalConditionIndex, manifestFunctionLogic?.if || [null, null, null]),
            })}
          </Modal.Header.Center>
          <Modal.Header.Right onClose={closeFilterModal}>
            <CloseIcon />
          </Modal.Header.Right>
        </Modal.Header>
        <Modal.Body>
          <RecipientPane
            initialPane={FilterPane.AGE}
            panes={PANES}
            ref={recipientPaneRef}
            style={{ height: 500, paddingBottom: 32 }}
          />
        </Modal.Body>
        <Modal.Footer style={{ borderTop: `1px solid ${COLORS.BORDERS}` }}>
          <Layout display="flex" justifyContent="space-between" width="100%">
            <Button onClick={closeFilterModal} variant={BUTTON_VARIANTS.OUTLINE}>
              {t('cancel')}
            </Button>
            <Button
              onClick={async () => {
                setFilterModalOpen(false)

                if (filters) {
                  const jsonLogicFilters = memberDataFilterToJsonLogic(filters)
                  if (filterModalMode && jsonLogicFilters) {
                    onFilterModalSave(filterModalMode, jsonLogicFilters)
                  }
                }

                setIncludedFilters(null)
              }}
            >
              {t('save')}
            </Button>
          </Layout>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export function WorkflowSidebarEditLogic(props: WorkflowSidebarEditLogicProps): JSX.Element {
  return (
    <RecipientFieldContextProvider>
      <PrivateWorkflowSidebarEditLogic {...props} />
    </RecipientFieldContextProvider>
  )
}
