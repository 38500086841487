import React, { useCallback, useEffect } from 'react'

import { TrendingConvoGrid } from './TrendingConvoGrid'
import {
  TrendingResponsesBox,
  TrendingResponsesBoxContent,
  TrendingResponsesBoxFooterLink,
} from './TrendingResponsesBox'
import { useTrendingResponse } from './useTrendingResponse'

import { ROUTES } from 'constants/routes'
import { BoundsProvider } from 'contexts/BoundsProvider'
import analytics from 'utils/analytics'
import { route } from 'utils/router'

type TrendingResponsesProps = {
  className?: string
}

const useTrendingResponsesRenderTracking = (trendingQuery) => {
  useEffect(() => {
    if (trendingQuery.isSuccess && trendingQuery.data === null) {
      analytics.track(analytics.events.TrendingResponsesCouldNotDisplay())
    }
    if (trendingQuery.isSuccess && trendingQuery.data !== null) {
      analytics.track(analytics.events.TrendingResponsesDisplayed())
    }
  }, [trendingQuery.isSuccess, trendingQuery.data])
}

export const TrendingResponses = ({ ...props }: TrendingResponsesProps): JSX.Element | null => {
  const trendingQuery = useTrendingResponse()

  useTrendingResponsesRenderTracking(trendingQuery)

  const trackClick = useCallback(() => {
    analytics.track(analytics.events.TrendingResponsesClicked())
  }, [])

  if (!trendingQuery || !trendingQuery.data) {
    return null
  }

  return (
    <div {...props}>
      <h2>Trending Responses</h2>
      <TrendingResponsesBox
        onClick={trackClick}
        to={route(ROUTES.CAMPAIGN_DETAILS, { campaignId: trendingQuery.data.sentCampaign.id })}
      >
        <TrendingResponsesBoxContent>
          <BoundsProvider>
            <TrendingConvoGrid cluster={trendingQuery.data.cluster} sentCampaign={trendingQuery.data.sentCampaign} />
          </BoundsProvider>
        </TrendingResponsesBoxContent>
        <TrendingResponsesBoxFooterLink>View all responses</TrendingResponsesBoxFooterLink>
      </TrendingResponsesBox>
    </div>
  )
}
