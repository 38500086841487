import { Layout, Image } from '@community_dev/pixels'
import { useState } from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

import { ImageContent } from 'api/addOns'
import { MeasuredContainer } from 'components/MeasuredContainer'

const ImageLoader = ({ width }: { width: number }): JSX.Element => {
  return (
    <ContentLoader height={200} title="Image Loading" viewBox={`0 0 ${width} 200`} width={width}>
      <rect height="100%" rx="3" ry="3" width="100%" x="0" y="0" />
    </ContentLoader>
  )
}

const StyledMeasureContainer = styled(MeasuredContainer)`
  flex-direction: column;
`

export const AddOnImage = ({ imageSrc }: ImageContent): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [width, setWidth] = useState(0)
  return (
    <Layout width="100%">
      <StyledMeasureContainer onResize={({ width }) => setWidth(width)}>
        <Image onLoad={() => setIsLoaded(true)} src={imageSrc} width="100%" />
        {isLoaded ? null : <ImageLoader width={width} />}
      </StyledMeasureContainer>
    </Layout>
  )
}
