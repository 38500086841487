import { useActionPanel } from 'components/ActionPanel/ActionPanelContext'
import InboxChat from 'containers/InboxChat'
import { DirectMessageLayout } from 'layouts/MessagesLayout'

function InboxScreen(): JSX.Element {
  const { isOpen, openActionPanel } = useActionPanel()

  return (
    <DirectMessageLayout>
      <InboxChat detailsPaneOpen={isOpen} openDetailsPane={openActionPanel} />
    </DirectMessageLayout>
  )
}

export default InboxScreen
