import { SPACING, Typography } from '@community_dev/pixels'
import styled, { DefaultTheme, useTheme } from 'styled-components'

import { BILLING_DATE_FORMAT } from 'constants/date'
import { LEGACY_PLAN_COLORS } from 'constants/plans'
import { useSubscription } from 'hooks/billing/useSubscription'
import { Setting } from 'layouts/SettingsLayout'
import dayjs from 'utils/dayjs'

const toPlanColor = (theme: DefaultTheme, id?: string): string => {
  if (!id) return theme?.COLORS?.BUTTON_PRIMARY
  return LEGACY_PLAN_COLORS[id] || theme?.COLORS?.BUTTON_PRIMARY
}

const StyledPlanName = styled.div<{ $color: string }>`
  display: flex;
  flex-flow: row nowrap;
  p {
    color: ${({ $color }) => $color};
    margin-top: 0;
  }
`

const StyledPlanMemberLimit = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledPlanState = styled.div<{ $color: string }>`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  padding: ${SPACING[2]} ${SPACING[4]};
  background-color: ${({ $color }) => $color};
  border-radius: ${SPACING[1]};
  color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  margin-bottom: ${SPACING[5]};
  text-transform: capitalize;
  max-width: 68px;
`

const StyledPlanRenewal = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
`

const StyledDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
`

const planDetailsLabels = (planColor, displayPrice, contacts, name) => {
  return (
    <>
      <StyledPlanName $color={planColor}>
        <div>Community</div>&nbsp;<p>{name}</p>
      </StyledPlanName>
      <div>{`$${displayPrice} / month`}</div>
      <StyledPlanMemberLimit>{`Up to ${contacts} Members`}</StyledPlanMemberLimit>
    </>
  )
}

export const toNextCylceText = (currentPeriodEndsAt: string, canceledAt?: string | null): string => {
  const billingCycleEndDate = dayjs(currentPeriodEndsAt).format(BILLING_DATE_FORMAT)

  if (canceledAt) {
    return `Expires ${billingCycleEndDate}`
  }

  return `Renews ${billingCycleEndDate}`
}

export function PlanDetailsRow(): JSX.Element {
  const { data } = useSubscription()

  const nextBillingCycleSubtotal = data?.subscription?.nextBillingCycleSubtotal
  const planName = data?.subscription?.planName
  const planId = data?.subscription.planId
  const planContactLimit = data?.subscription?.planContactLimit
  const currentPeriodEndsAt = data?.subscription?.currentPeriodEndsAt
  const canceledAt = data?.subscription?.canceledAt

  const theme = useTheme()
  const displayPrice = nextBillingCycleSubtotal

  const planColor = toPlanColor(theme, planId)
  const nextCycleText = currentPeriodEndsAt && toNextCylceText(currentPeriodEndsAt, canceledAt)

  return (
    <>
      <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
        Your Plan
      </Typography>
      <Setting
        className="Setting-billing"
        label={planDetailsLabels(planColor, displayPrice, planContactLimit, planName)}
      >
        <StyledDetailsContainer>
          <StyledPlanState $color={planColor}>{data?.state}</StyledPlanState>
          <StyledPlanRenewal>
            <div>{nextCycleText}</div>
            To cancel, email yourfriends@community.com
          </StyledPlanRenewal>
        </StyledDetailsContainer>
      </Setting>
    </>
  )
}
