import { useDebouncedValue } from '@community_dev/hooks'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { Giphy, getGiphy } from 'api/giphy'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'

const SEARCH_DELAY = 750

type GiphySearch = {
  giphySearchResults: Giphy[] | undefined
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
}

export function useGiphySearch(): GiphySearch {
  const [rawQuery, setSearchTerm] = useState('')
  const query = useDebouncedValue(rawQuery, SEARCH_DELAY, true)
  const { data: giphySearchResults } = useQuery([QUERY_CACHE.GIPHY.QUERY, { query }], () => getGiphy({ query }), {
    staleTime: STALE_TIME.TEN_MINUTES,
    keepPreviousData: true,
  })

  return { giphySearchResults, setSearchTerm }
}
