import { CloseIcon, Modal } from '@community_dev/pixels'
import styled, { useTheme } from 'styled-components'

import RecipientList from 'containers/RecipientList'
import { useCurrentCampaignFilterSummary } from 'hooks/useCampaign'

const StyledToField = styled.div`
  display: flex;
  padding: 16px 16px;
  font-weight: 800;
  border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
`

const StyledToLabel = styled.div`
  flex-grow: 0;
  padding-right: 10px;
`

const StyledRecipients = styled.div`
  flex-grow: 1;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledModalContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: auto;
  flex-direction: column;
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`

type RecipientModalProps = {
  onClose: () => any
}

const RecipientModal = ({ onClose }: RecipientModalProps): JSX.Element => {
  const filterSummary = useCurrentCampaignFilterSummary()
  const { COLORS } = useTheme()
  return (
    <Modal onClose={onClose} open>
      <Modal.Header>
        <Modal.Header.Center>View All Recipients</Modal.Header.Center>
        <Modal.Header.Right onClose={onClose}>
          <StyledCloseIcon color={COLORS.SUBTEXT} size={12} />
        </Modal.Header.Right>
      </Modal.Header>
      <Modal.Body>
        <StyledModalContent>
          <StyledToField>
            <StyledToLabel>To:</StyledToLabel>
            <StyledRecipients>{filterSummary}</StyledRecipients>
          </StyledToField>
          <RecipientList />
        </StyledModalContent>
      </Modal.Body>
    </Modal>
  )
}

export default RecipientModal
