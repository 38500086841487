import { FONT_SIZE, Layout, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

type WorkflowAnalyticsTableProps = {
  labelCtr?: string
  labelSent?: string
  labelClicks?: string
  analytics: {
    clicks: string
    sent: string
    ctr: string
  }
}

const StyledTerm = styled.h5`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.OVERLINE};
  margin: 0 0 ${SPACING[1]} 0;
  font-size: ${FONT_SIZE[2]};
`
const StyledText = styled.div`
  font-size: ${FONT_SIZE[3]};
`

export function WorkflowAnalyticsTable({
  labelCtr = 'ctr',
  labelSent = 'sent',
  labelClicks = 'clicks',
  analytics,
}: WorkflowAnalyticsTableProps): JSX.Element {
  return (
    <Layout display="flex" flexGrow="1" justifyContent="space-between">
      <Layout minWidth="85px">
        <StyledText data-testid="series-performance-summary-total-sent">{analytics.sent || 0}</StyledText>
        <StyledTerm>{labelSent}</StyledTerm>
      </Layout>
      <Layout>
        <StyledText data-testid="series-performance-summary-link-clicks">{analytics.clicks || '--'}</StyledText>
        <StyledTerm>{labelClicks}</StyledTerm>
      </Layout>
      <Layout minWidth="55px">
        <StyledText data-testid="series-performance-summary-ctr">{analytics.ctr}</StyledText>
        <StyledTerm>{labelCtr}</StyledTerm>
      </Layout>
    </Layout>
  )
}
