import { RefObject, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { ROUTES } from 'constants/routes'

const WHITELIST_DO_NOT_AUTO_CLOSE_ON_ROUTES: string[] = [ROUTES.CAMPAIGN_DETAILS]

export type ActionPanelContextValue = {
  openActionPanel(): void
  closeActionPanel: (isAnimated?: boolean) => void
  isOpen: boolean
  portalTarget: RefObject<HTMLDivElement>
  animatePanelToggle?: boolean
}

export const ActionPanelContext = createContext<ActionPanelContextValue | null>(null)

ActionPanelContext.displayName = 'ActionPanelContext'

export const ActionPanelContextProvider = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAnimated, setIsAnimated] = useState(true)
  const portalTarget = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()
  const match = useRouteMatch([
    ROUTES.CAMPAIGNS.DETAILS,
    ROUTES.MESSAGES.INBOX,
    ROUTES.COMMUNITY,
    ROUTES.COMMUNITIES,
    ROUTES.INSIGHTS.ROOT,
    ROUTES.SCHEDULED.ROOT,
    ROUTES.GROWTH_TOOLS,
    ROUTES.SETTINGS.ROOT,
    ROUTES.DASHBOARD,
  ])
  const prevMatchPath = useRef<string>(match?.path || '')

  const closeActionPanel = useCallback((isAnimated = true) => {
    setIsAnimated(isAnimated)
    setIsOpen(false)
  }, [])

  useEffect(() => {
    const matchedPath = match?.path || ''
    const isWhitelisted = WHITELIST_DO_NOT_AUTO_CLOSE_ON_ROUTES.includes(matchedPath)

    if (!matchedPath) return

    if (!isWhitelisted && matchedPath !== prevMatchPath.current) {
      closeActionPanel(false)
    }
    prevMatchPath.current = matchedPath
  }, [closeActionPanel, match, pathname, prevMatchPath])

  function openActionPanel() {
    setIsAnimated(true)
    setIsOpen(true)
  }

  return (
    <ActionPanelContext.Provider
      value={{
        portalTarget,
        isOpen,
        closeActionPanel,
        openActionPanel,
        animatePanelToggle: isAnimated,
      }}
    >
      {children}
    </ActionPanelContext.Provider>
  )
}

export function useActionPanel(): ActionPanelContextValue {
  const context = useContext(ActionPanelContext)

  if (context === undefined) {
    throw new Error('useActionPanel must be used within a ActionPanelContextProvider')
  }

  return context as ActionPanelContextValue
}
