import { useMessageBillingUsage } from '@community_dev/hooks'
import { SPACING, Typography } from '@community_dev/pixels'
import noop from 'lodash/noop'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'

import MessageBox from 'components/MessageBox'
import { MultimediaInput } from 'components/MultimediaInput'
import { EditButtons } from 'components/Settings'
import { AUTO_RESPONDER_MAX_LENGTH, RESTRICTED_KEYWORDS } from 'constants/keywords'
import { ROUTES } from 'constants/routes'
import { EditContainer } from 'containers/Settings'
import { useSettings } from 'contexts/SettingsProvider'
import { useAddKeywordResponder } from 'hooks/keywordResponders/useAddKeywordResponder'
import { useKeywordResponders } from 'hooks/keywordResponders/useKeywordResponders'
import { useUpdateKeywordResponder } from 'hooks/keywordResponders/useUpdateKeywordResponder'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useShortenableLinks } from 'hooks/useShortenableLinks'

export function EditKeyword(): JSX.Element {
  const { data: keywords } = useKeywordResponders()
  const { mutate: addKeyword, isLoading: isAddKeywordLoading } = useAddKeywordResponder()
  const { mutate: updateKeyword, isLoading: isUpdateKeywordLoading } = useUpdateKeywordResponder()
  const isSubmitting = isAddKeywordLoading || isUpdateKeywordLoading
  const { id: keywordId } = useParams() as { id?: string }
  const currentTheme = useCurrentTheme()
  const { settings } = useSettings()
  const [keyword, setKeyword] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const { t } = useTranslation()
  const history = useHistory()
  const { links } = useShortenableLinks(message)
  const {
    charCount,
    error: messageError,
    segmentCount,
    segmentMessage,
    tooltipContent,
  } = useMessageBillingUsage({
    message,
    linksToShorten: links,
    charMax: AUTO_RESPONDER_MAX_LENGTH,
  })
  const usageCounterProps = {
    charCount,
    error: messageError,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }

  const selectedKeyword = keywords?.data?.find(({ id }) => id === keywordId)

  useEffect(() => {
    if (selectedKeyword) {
      setKeyword(selectedKeyword.keyword)
      setMessage(selectedKeyword.text)
    }
  }, [selectedKeyword])

  const onKeywordChange = (keyword: string) => {
    setKeyword(keyword)

    const trimmed = String(keyword).trim()

    if (keyword && RESTRICTED_KEYWORDS.find((rkw) => rkw === trimmed.toLowerCase())) {
      setError(t(`keywordsModal.restrictedKeywordError`, { keyword: trimmed }))
    } else {
      setError('')
    }
  }

  const onCloseClick = () => {
    history.push(ROUTES.SETTINGS.CUSTOMIZE.ROOT)
  }

  const isEditing = !!selectedKeyword

  const onSaveClick = () => {
    if (isEditing) {
      updateKeyword({ id: selectedKeyword.id, keyword, text: message })
    } else {
      addKeyword({ keyword, text: message })
    }
    onCloseClick()
  }

  return (
    <EditContainer className="EditContainer-keyword" onClose={onCloseClick} title="Keyword Responders">
      <Typography marginBottom={SPACING[3]} marginTop={0} variant="h4">
        {isEditing ? 'Your keyword' : 'Add your keyword'}
      </Typography>
      <MessageBox
        autoFocus={!isEditing}
        disabled={isEditing}
        maxRows={1}
        message={keyword}
        minRows={1}
        onChange={isEditing ? noop : onKeywordChange}
      />
      {error && <div className="EditContainer-error">{error}</div>}
      <Typography marginBottom={SPACING[3]} marginTop={SPACING[3]} variant="h4">
        {isEditing ? 'Edit response' : 'Add your response'}
      </Typography>

      <MultimediaInput
        autoFocus={isEditing}
        emojiSkinTone={settings.skinTone}
        emojiTheme={currentTheme.type}
        gallery={false}
        gif={false}
        onChange={(e) => setMessage(e.target.value)}
        onEmojiSelect={(_, text) => setMessage(text)}
        placeholder={t('automatedKeywordResponsePlaceholder')}
        usageCounterProps={usageCounterProps}
        value={message}
      />
      <EditButtons
        disabled={Boolean(error) || !keyword || !message || Boolean(messageError)}
        loading={isSubmitting}
        onCancel={onCloseClick}
        onSave={onSaveClick}
      />
      <Typography marginBottom={0} marginTop={SPACING[3]} variant="h4">
        What is this?
      </Typography>
      <Typography variant="body1">When someone texts the keyword, they will receive the response.</Typography>
    </EditContainer>
  )
}
