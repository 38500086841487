import { ActionButton } from '@community_dev/pixels'
import { useParams } from 'react-router'
import { useTheme } from 'styled-components'

import { useFavoriteCommunity } from '../useFavoriteCommunity'

import { useCampaignResponseFilterState } from 'contexts/CampaignResponseFilterProvider'
import { useCampaign } from 'hooks/useCampaign'
import { useClientId } from 'hooks/useClient'
import { useCommunity } from 'hooks/useCommunity'

export function FavoriteFilterActionButton({ disabled }: { disabled?: boolean }): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { filterCount, filters }: any = useCampaignResponseFilterState()
  const clientId = useClientId()
  const { isLoading, assignMembersToCommunity } = useCommunity({ clientId })
  const { communities, favoriteCommunity: community } = useFavoriteCommunity()
  const { id: threadId }: any = useParams()
  const { fanSubscriptionTags } = useCampaign({
    campaignId: threadId,
    options: {
      communities: [...(communities?.custom || []), ...(communities?.default || [])],
    },
  })

  function handleClickFavorite() {
    if (!community) return

    assignMembersToCommunity({
      recipientCount: filterCount,
      tagId: community.id,
      filters: filters,
      fanSubscriptionTags,
      community,
    })
  }

  return (
    <ActionButton
      color={COLORS?.BUTTON_PRIMARY}
      disabled={disabled}
      icon="star"
      id="detail-pane-favorite"
      label="favorite"
      onClick={handleClickFavorite}
      processing={isLoading}
    />
  )
}
