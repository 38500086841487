import { WorkflowEditorCardTypes, WORKFLOW_CARDS } from '@community_dev/flexible-canvas'
import {
  BaseEmoji,
  Button,
  BUTTON_VARIANTS,
  EmojiPicker,
  ExclamationCircle,
  FONT_SIZE,
  Layout,
  SmileIcon,
  SPACING,
  Tooltip,
  Typography,
} from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { updateTrigger, WorkflowTrigger } from '@community_dev/workflow-manifest'
import IconMessageBubbleEllipsis from 'components/WorkflowEditor/svg/message-bubble-ellipsis.svg?react'
import { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { useTheme } from 'styled-components'

import { WorkflowKeywords } from '../WorkflowKeywords/WorkflowKeywords'

import { validateKeyword as validateKeywordRemote } from 'api/keyword'
import TextInput from 'components/input/TextInput'
import { WorkflowSidebarHeader } from 'components/WorkflowEditor/components/WorkflowSidebarHeader/WorkflowSidebarHeader'
import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { ROUTES } from 'constants/routes'
import { WORKFLOW_ID_UNSAVED } from 'constants/workflow'
import { useSettings } from 'contexts/SettingsProvider'
import { useClientId } from 'hooks/useClient'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useToastMessage } from 'hooks/useToastMessage'
import { isKeywordEntrypoint, useWorkflowKeywords } from 'hooks/useWorkflowKeywords'
import Sentry from 'integrations/Sentry'
import { StyledIcon, StyledInfo } from 'screens/WorkflowKeywordResponseScreen/WorkflowKeywordResponseScreen.style'
import { DoneButton, CancelLink } from 'screens/WorkflowScreen/WorkflowScreen.style'

export function WorkflowSidebarEditTriggerKeyword(): JSX.Element | null {
  const history = useHistory()
  const { settings } = useSettings()
  const currentTheme = useCurrentTheme()
  const clientId = useClientId()
  const { t } = useTranslation()
  const { COLORS } = useTheme() || {}
  const { getManifestKeywords, keywords } = useWorkflowKeywords()
  const { availableKeywordsCount, maxKeywordsAllowed } = keywords || {}
  const { showToastMessage } = useToastMessage()
  const { triggerName, workflowId } = useParams<{ triggerName: string; workflowId: string }>()
  const isUnsavedWorkflow = workflowId === WORKFLOW_ID_UNSAVED
  const { manifest, workflow, isLoading: isWorkflowLoading, dispatch } = useWorkflowProvider()
  const entrypoint = manifest.entrypoints[triggerName]

  const manifestKeywords = useMemo(() => {
    if (triggerName && isKeywordEntrypoint(entrypoint)) {
      return entrypoint.params.keyword_matched.keywords
    } else {
      return []
    }
  }, [entrypoint, triggerName])

  // a copy of the manifest keywords
  const [localKeywords, setLocalKeywords] = useState<string[]>(manifestKeywords)

  // if the workflow is unsaved, we need to count all the local keywords
  // since the manifest keywords would not be available till the workflow is saved
  const newLocalKeywords = isUnsavedWorkflow ? localKeywords.length : localKeywords.length - manifestKeywords.length

  const [value, setValue] = useState('')
  const [isValidating, setIsValidating] = useState(false)
  const [error, setError] = useState('')
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false)

  const validateKeyword = useCallback(
    async (value: string) => {
      const isLocalKeyword = localKeywords.some((keyword) => keyword === value)
      const isManifestKeyword = manifestKeywords.some((keyword) => keyword === value)
      const isRemoteManifestKeyword = getManifestKeywords(workflow.last_manifest?.body).some(
        (keyword) => keyword === value,
      )

      // if the user is adding a new keyword, we need to check if they've reached the max
      if (newLocalKeywords + 1 > (availableKeywordsCount || 0)) {
        throw new Error(t('automations.keyword.maxKeywordsReached', { maxKeywordsAllowed: maxKeywordsAllowed }))
      }
      // if it’s a local duplicate, we error right away.
      if (isLocalKeyword) {
        throw new Error(t('automations.keyword.localDuplicateError', { keyword: value }))
      } else if (!isManifestKeyword && !isRemoteManifestKeyword) {
        // if it wasn’t part of the manifest before, we need to validate it
        // against the server.
        const response = await validateKeywordRemote(value, clientId)
        const normalizedKeyword = response?.data?.normalizedText
        if (!normalizedKeyword) {
          throw new Error(t('unknownServerError'))
        }
        const isNormalizedDuplicate = localKeywords.some((keyword) => keyword === normalizedKeyword)
        if (isNormalizedDuplicate) {
          throw new Error(t('automations.keyword.localDuplicateError', { keyword: normalizedKeyword }))
        }
        return normalizedKeyword
      } else {
        return value
      }
    },
    [
      localKeywords,
      manifestKeywords,
      getManifestKeywords,
      workflow.last_manifest?.body,
      newLocalKeywords,
      availableKeywordsCount,
      t,
      maxKeywordsAllowed,
      clientId,
    ],
  )

  const removeLocalKeyword = useCallback((keyword: string) => {
    setLocalKeywords((keywords) => keywords.filter((localKeyword) => localKeyword !== keyword))
  }, [])

  const addLocalKeyword = useCallback(
    async (value: string) => {
      setIsValidating(true)
      setError('')
      try {
        const keyword = await validateKeyword(value)
        setLocalKeywords([...localKeywords, keyword])
      } catch (e: any) {
        // server errors
        if (e.errors?.[0]?.message) {
          setError(e.errors[0].message)
          // local errors
        } else if (typeof e.message === 'string') {
          setError(e.message)
          // generic error
        } else {
          setError(t('unknownServerError'))
        }
      }
      setValue('')
      setIsValidating(false)
    },
    [validateKeyword, localKeywords, t],
  )

  const onValueKeyUp = useCallback(
    async (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.stopPropagation()
        e.preventDefault()
        addLocalKeyword(value)
      }
    },
    [value, addLocalKeyword],
  )

  if (!isKeywordEntrypoint(entrypoint) || isWorkflowLoading) {
    return null
  }

  const WorkflowAction = WORKFLOW_CARDS[WorkflowEditorCardTypes.SLEEP]

  const onSubmit = async () => {
    const nextManifest = updateTrigger(manifest, triggerName, {
      type: WorkflowTrigger.Keyword,
      params: {
        keyword_matched: {
          keywords: localKeywords,
        },
      },
    })

    try {
      dispatch({
        type: 'update',
        state: {
          manifest: nextManifest,
        },
      })
      history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId: workflow.id }))
    } catch (e) {
      Sentry.captureException(e)

      showToastMessage({
        message: (e as Error).message,
        success: false,
      })
    }
  }

  return (
    <Layout display="flex" flex="1" flexDirection="column" height="100%">
      <Layout marginTop={SPACING[2]} padding={SPACING[4]}>
        <WorkflowSidebarHeader
          workflowAction={{
            ...WorkflowAction,
            backgroundColor: COLORS.BUTTON_PRIMARY,
            title: t('automations.keyword.triggerTitle'),
            icon: IconMessageBubbleEllipsis,
            subtitle: t('automations.keyword.triggerSubHeadline'),
          }}
        />
      </Layout>
      <Layout flex="1" padding={`0 ${SPACING[4]}`}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form id="keywordTriggerForm" onSubmit={handleSubmit}>
              <Layout display="flex">
                <TextInput
                  autoFocus={true}
                  maxLength={50}
                  onChange={(e) => setValue(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                  onKeyUp={onValueKeyUp}
                  placeholder={t('automations.keyword.inputPlaceholder')}
                  rootStyle={{ padding: SPACING[1], fontSize: FONT_SIZE[3] }}
                  value={value}
                >
                  <StyledIcon onClick={() => setIsEmojiPickerOpen(true)} type="button">
                    <SmileIcon size={24} />
                    {isEmojiPickerOpen && (
                      <EmojiPicker
                        onOuterClick={() => setIsEmojiPickerOpen(false)}
                        onSelect={(emoji: BaseEmoji) => {
                          setValue(`${value}${emoji.native}`)
                        }}
                        rootStyle={{ marginTop: SPACING[4], marginLeft: '-270px', position: 'fixed', zIndex: 1 }}
                        skin={settings.skinTone}
                        theme={currentTheme.type}
                      />
                    )}
                  </StyledIcon>
                </TextInput>
                <Button
                  disabled={value.trim() === '' || isValidating}
                  onClick={() => addLocalKeyword(value)}
                  style={{ marginLeft: SPACING[2] }}
                  variant={BUTTON_VARIANTS.PRIMARY}
                >
                  {t('add')}
                </Button>
              </Layout>
              {error && <StyledInfo icon={<ExclamationCircle color={COLORS.ERRORS} size={20} />}>{error}</StyledInfo>}
              {localKeywords.length > 0 && (
                <Layout padding={SPACING[3]}>
                  <Typography component="div" flex="1" fontSize="14px" fontWeight="bold" marginBottom={SPACING[3]}>
                    {t('automations.keywords')}
                  </Typography>
                  <Layout>
                    <WorkflowKeywords keywords={localKeywords} onRemoveKeyword={removeLocalKeyword} removable />
                  </Layout>
                </Layout>
              )}
            </form>
          )}
        />
      </Layout>
      <Layout
        alignItems="center"
        borderTop={`1px solid ${COLORS?.BORDERS}`}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding="45px 16px"
      >
        <Tooltip content={localKeywords.length === 0 ? t('automations.keyword.noKeywordsWarning') : undefined}>
          <div>
            <DoneButton disabled={localKeywords.length === 0} form="keywordTriggerForm" type="submit">
              {t('done')}
            </DoneButton>
          </div>
        </Tooltip>
        <CancelLink
          style={{ marginTop: SPACING[5] }}
          to={route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId: workflow.id })}
        >
          {t('cancel')}
        </CancelLink>
      </Layout>
    </Layout>
  )
}
