import { Select } from '@community_dev/pixels'
import React from 'react'

import { UnitsPreference, UNITS_PERCENT, UNITS_SCALAR, useUnitsPreference } from '../use-units-preference'

export const UnitSwitcher = (): JSX.Element => {
  const options = {
    [UNITS_PERCENT]: { value: UNITS_PERCENT, label: 'Percentage (%)' },
    [UNITS_SCALAR]: { value: UNITS_SCALAR, label: 'Number' },
  }

  const { unitsPreference, setUnitsPreference } = useUnitsPreference()

  const onChange = (option: { value: string; label: string } | null) => {
    if (option) setUnitsPreference(option.value as UnitsPreference)
  }

  return <Select onChange={onChange} options={Object.values(options)} value={options[unitsPreference]} width="170px" />
}
