import { useCallback } from 'react'

import { ScheduledState } from 'components/ComposeMessage/ComposeContext'
import { useToastMessage } from 'hooks/useToastMessage'

type NotifyArgs = {
  type: 'sent' | 'scheduled'
  isSuccess: boolean
  scheduledAt?: ScheduledState
}

type UseComposeNotifyReturn = {
  (notifyArgs: NotifyArgs): void
}

export const useComposeNotify = (): UseComposeNotifyReturn => {
  const { showToastMessage } = useToastMessage()

  const notify = useCallback(
    ({ type, scheduledAt, isSuccess = true }: NotifyArgs): void => {
      if (type === 'sent') {
        if (isSuccess) {
          showToastMessage({
            message: 'Message sent.',
          })
        } else {
          showToastMessage({
            message: 'Message failed to send.',
            success: false,
          })
        }

        return
      }

      if (type === 'scheduled') {
        if (isSuccess) {
          showToastMessage({
            message: `Message scheduled [${scheduledAt?.format('LL')}, ${scheduledAt?.format('LT')}]`,
            position: 'bottom-right',
          })
        } else {
          showToastMessage({
            message: 'Message failed to schedule.',
            success: false,
          })
        }
      }
    },
    [showToastMessage],
  )

  return notify
}
