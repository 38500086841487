import { FONT_SIZE, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { Code } from '../../Code'

import { CopyButton } from './CopyButton'

import { Embed } from 'api/mgu'

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${SPACING[1]};
  padding-bottom: ${SPACING[2]};
  padding-left: ${SPACING[1]};
  min-width: 100px;
  margin-bottom: 14px;
`

const StyledInstallStep = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledStepIndicator = styled.div`
  padding-right: ${SPACING[2]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledSubtext = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  padding-top: ${SPACING[2]};
`

const StyledInstructions = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-size: ${FONT_SIZE[3]};
  padding-top: ${SPACING[2]};
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  & > * {
    overflow: hidden;
  }
`

type InlineInstructionsProps = {
  embed: Embed
}

export const InlineInstallationModal = ({ embed }: InlineInstructionsProps): JSX.Element => {
  const tag = '<div id="community-embed"></div>'
  const script = `<script async data-community-embed-id="${embed?.id}" src="${
    import.meta.env.VITE_MGU_EMBED_URL
  }"></script>`

  return (
    <>
      <StyledInstructions>
        <div>
          <StyledInstallStep>
            <StyledStepIndicator>1.</StyledStepIndicator>
            Copy/Paste this Code Snippet into your website's code where you want the form to appear.
          </StyledInstallStep>
          <StyledSubtext>&lt;div&gt; id</StyledSubtext>
          <Code language="html">{tag}</Code>
        </div>
        <StyledButtonRow>
          <CopyButton content={tag} short />
        </StyledButtonRow>
      </StyledInstructions>
      <StyledInstructions>
        <div>
          <StyledInstallStep>
            <StyledStepIndicator>2.</StyledStepIndicator>
            Copy/Paste this Code Snippet into your website’s code before the {'</body>'} tag.
          </StyledInstallStep>
          <StyledSubtext>Code Snippet</StyledSubtext>
          <Code language="html">{script}</Code>
        </div>
        <StyledButtonRow>
          <CopyButton content={script} short />
        </StyledButtonRow>
      </StyledInstructions>
    </>
  )
}
