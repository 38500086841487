import React from 'react'

import { IconProps } from './IconProps'

export const DiscountIcon = ({ size = 18, color = 'currentColor' }: IconProps): JSX.Element => (
  <svg fill="none" height={size} viewBox="0 0 18 17" width={size} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.37109 15.8916C9.47168 16.9922 10.6885 16.999 11.8027 15.8916L16.2188 11.4688C17.333 10.3613 17.3193 9.14453 16.2256 8.04395L9.45117 1.27637C8.87695 0.702148 8.52832 0.592773 7.65332 0.592773H5.0625C4.25586 0.592773 3.93457 0.78418 3.30566 1.40625L1.75391 2.95801C1.13184 3.58691 0.94043 3.9082 0.94043 4.71484V7.30566C0.94043 8.18066 1.0293 8.54297 1.61035 9.12402L8.37109 15.8916ZM6.36816 6.72461C5.7666 6.72461 5.30176 6.25977 5.30176 5.6582C5.30176 5.06348 5.7666 4.5918 6.36816 4.5918C6.97656 4.5918 7.43457 5.06348 7.43457 5.6582C7.43457 6.25977 6.97656 6.72461 6.36816 6.72461Z"
      fill={color}
    />
  </svg>
)
