import { BUTTON_VARIANTS, Button, Image, SPACING } from '@community_dev/pixels'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { GrowYourCommunityData } from '../../GrowYourCommunityCard/growYourCommunityData'
import { SlideContainer } from '../../styled'

const HeroImage = styled(Image)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  flex: 0 0 auto;
`

const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const BlockHeaderContainer = styled.div`
  display: flex;
  width: 100%;
`

const BlockImage = styled(Image)<{ $isLastImage: boolean }>`
  width: 100%;
  margin-bottom: ${({ $isLastImage }) => ($isLastImage ? '0px' : '15px')};
`

const IconImage = styled(Image)`
  width: ${SPACING[5]};
  height: ${SPACING[5]};
  margin-right: 12px;
`

const BlockHeaderTitle = styled.h3`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  margin: 0;
`

const StyledText = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  margin-top: 12px;
  margin-bottom: ${SPACING[5]};
`

const HorizontalLine = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  margin-top: 62px;
  margin-bottom: 32px;
`

const StyledButton = styled(Button)`
  flex: 0 0 auto;
`

type BlockProps = {
  icon?: {
    url?: string
    alt?: string
  }
  images?: {
    url?: string
    alt?: string
  }[]
  text?: string
  title?: string
  isLastBlock?: boolean
}
const Block = ({ icon, images, text, title, isLastBlock }: BlockProps) => {
  return (
    <StyledBlock>
      <BlockHeaderContainer>
        {icon && icon.url && <IconImage src={icon?.url} />}
        <BlockHeaderTitle>{title}</BlockHeaderTitle>
      </BlockHeaderContainer>
      <StyledText>{text}</StyledText>
      {images?.map(
        ({ url, alt }, index) =>
          url && <BlockImage $isLastImage={images.length - 1 === index} alt={alt} key={url} src={url} />,
      )}
      {!isLastBlock && <HorizontalLine />}
    </StyledBlock>
  )
}

Block.defaultProps = {
  images: [],
}

type BlocksListProps = {
  list?: {
    title?: string
  }[]
}

const BlocksList = ({ list }: BlocksListProps) => {
  return (
    <StyledList>
      {list?.map((block, index) => (
        <Block isLastBlock={list?.length - 1 === index} key={block.title} {...block} />
      ))}
    </StyledList>
  )
}

BlocksList.defaultProps = {
  list: [],
}

const useScrollToTopOnLeaderChange = (leader) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }), [leader])
  return ref
}

type SlideWithLeaderProps = {
  leader?: GrowYourCommunityData
  onClick: (...args: any[]) => void
}

export function SlideWithLeader({ leader, onClick }: SlideWithLeaderProps): JSX.Element {
  const { heroImage: { url = '', alt = '' } = {}, blocks } = leader || {}
  const ref = useScrollToTopOnLeaderChange(leader)
  return (
    <SlideContainer ref={ref}>
      <HeroImage alt={alt ? alt : undefined} src={url} />
      <BlocksList list={blocks} />
      <StyledButton onClick={onClick} variant={BUTTON_VARIANTS.ACTION} width="calc(100% - 48px)">
        Done
      </StyledButton>
    </SlideContainer>
  )
}
