import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { ReactElement } from 'react'

import { STALE_TIME } from 'constants/query-cache'

export function DataWrapper<T>({
  children,
  queryFn,
  params,
  enabled = true,
}: {
  children: (query: UseQueryResult<T>) => ReactElement
  queryFn: (...any) => Promise<T>
  params?: any
  enabled?: boolean
}): JSX.Element {
  const queryKey = queryFn['QueryKey'] || queryFn.name
  const query = useQuery([queryKey, params], () => queryFn(params), {
    staleTime: STALE_TIME.FIVE_MINUTES,
    enabled,
  })

  return children(query)
}
