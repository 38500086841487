import React from 'react'

type ZapierIconProps = {
  size?: number
}

export const AiMessageGeneratorIcon = ({ size = 24 }: ZapierIconProps): JSX.Element => {
  return (
    <svg fill="none" height={size} viewBox="0 0 59 59" width={size} xmlns="http://www.w3.org/2000/svg">
      <mask
        height="57"
        id="mask0_918_14113"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="57"
        x="1"
        y="1"
      >
        <circle cx="29.4219" cy="29.4219" fill="#C4C4C4" r="28.4219" />
      </mask>
      <g mask="url(#mask0_918_14113)">
        <g filter="url(#filter0_b_918_14113)">
          <circle cx="29.4219" cy="29.4219" fill="#DEFCFF" fill-opacity="0.18" r="28.4219" />
        </g>
        <g filter="url(#filter1_f_918_14113)">
          <circle cx="29.4219" cy="29.4219" r="28.2531" stroke="black" stroke-width="0.337514" />
        </g>
        <g filter="url(#filter2_f_918_14113)">
          <circle cx="29.4219" cy="29.4219" r="27.4093" stroke="url(#paint0_linear_918_14113)" stroke-width="2.02509" />
        </g>
        <g filter="url(#filter3_f_918_14113)">
          <path
            d="M56.3802 29.5379C55.7216 39.9485 49.0869 47.4171 45.8519 49.8502C39.1458 55.7388 31.232 45.9758 34.1683 42.0285C37.1046 38.0811 39.013 24.9355 35.4827 16.8593C31.9524 8.783 38.681 4.79053 45.988 6.93694C53.295 9.08335 57.2035 16.5248 56.3802 29.5379Z"
            fill="url(#paint1_linear_918_14113)"
          />
        </g>
        <g filter="url(#filter4_f_918_14113)">
          <path
            d="M23.5176 6.16416C31.3628 3.89076 38.6239 6.80018 41.2739 8.53906C47.3898 11.9197 42.2934 20.3378 38.6068 19.2204C34.9202 18.1029 24.7286 20.1802 19.7134 24.9215C14.6982 29.6628 9.97349 25.7631 9.61871 19.8104C9.26394 13.8577 13.7112 9.00592 23.5176 6.16416Z"
            fill="url(#paint2_linear_918_14113)"
          />
        </g>
        <g filter="url(#filter5_f_918_14113)" style={{ mixBlendMode: 'lighten' }}>
          <path
            d="M40.8922 46.7797C34.5511 51.8643 25.7479 52.0025 22.6143 52.1817C26.2159 53.1398 35.2364 51.1278 38.6481 49.7237C42.0598 48.3196 49.3813 42.972 51.9378 35.8133C54.4943 28.6546 51.779 21.0734 50.4311 18.5907C51.2298 20.062 50.4369 27.643 49.9263 28.5085L49.8745 28.5963C49.3724 29.4477 48.7376 30.5243 47.4901 32.3658C46.1995 34.2711 47.192 36.6138 46.8811 37.8471C46.5702 39.0804 46.6297 42.1791 40.8922 46.7797Z"
            fill="url(#paint3_linear_918_14113)"
          />
        </g>
        <g filter="url(#filter6_f_918_14113)">
          <circle cx="29.4219" cy="29.4219" r="27.6625" stroke="url(#paint4_linear_918_14113)" stroke-width="1.51881" />
        </g>
        <g filter="url(#filter7_f_918_14113)">
          <path
            d="M14.0942 44.0402C7.35885 34.8345 9.72934 30.3368 12.47 28.4081C14.5002 26.9794 18.9905 33.2441 23.2901 37.5437C27.5897 41.8433 43.8356 35.8181 43.8356 41.5025C43.8356 51.2743 22.4325 55.4368 14.0942 44.0402Z"
            fill="url(#paint5_linear_918_14113)"
          />
        </g>
        <g filter="url(#filter8_f_918_14113)">
          <path
            d="M34.5609 18.9132C33.0072 17.5537 33.2948 15.1909 33.9421 14.1795C34.3824 13.3303 37.2312 12.8443 38.5663 14.1795C39.9015 15.5146 41.2366 15.8787 41.2366 18.9132C41.2366 21.9476 36.5029 20.6124 34.5609 18.9132Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter9_f_918_14113)">
          <path
            d="M35.2015 16.5413C33.326 14.9004 33.79 12.4686 34.5715 11.2476C35.1029 10.2226 38.1363 9.56861 40.0365 10.8273C41.7442 11.9584 43.2598 12.8785 43.2598 16.5413C43.2598 20.2042 37.5457 18.5925 35.2015 16.5413Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter10_f_918_14113)">
          <path
            d="M18.1822 43.0266C16.4496 41.5034 16.3272 38.856 17.0491 37.7227C17.5401 36.7713 21.418 36.9524 22.9069 38.4484C24.3958 39.9443 25.1599 39.6267 25.1599 43.0266C25.1599 46.4265 20.3479 44.9306 18.1822 43.0266Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter11_f_918_14113)">
          <path
            d="M48.1732 22.1555C47.6711 21.5797 47.964 20.8126 48.1732 20.3842C48.3155 20.0245 49.3737 19.5849 49.8051 20.1505C50.2366 20.716 50.4554 21.0999 50.2603 21.8156C49.9223 23.0556 48.8008 22.8752 48.1732 22.1555Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter12_f_918_14113)">
          <path
            d="M10.5878 18.3273C13.6333 12.3748 19.8165 8.69482 22.5275 7.59891C26.8534 7.59891 17.1077 10.4174 12.5879 18.7666C6.73495 29.5782 10.5878 42.3796 8.68435 38.7458C6.78093 35.112 6.78093 25.7679 10.5878 18.3273Z"
            fill="url(#paint6_linear_918_14113)"
          />
        </g>
      </g>
      <defs>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="57.5188"
          id="filter0_b_918_14113"
          width="57.5188"
          x="0.662486"
          y="0.662486"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.168757" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_918_14113" />
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_918_14113" mode="normal" result="shape" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="60.2189"
          id="filter1_f_918_14113"
          width="60.2189"
          x="-0.687572"
          y="-0.687572"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="0.843786" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="62.5815"
          id="filter2_f_918_14113"
          width="62.5815"
          x="-1.86887"
          y="-1.86887"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="1.43444" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="63.5559"
          id="filter3_f_918_14113"
          width="41.176"
          x="24.4257"
          y="-2.75031"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="4.55644" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="39.9315"
          id="filter4_f_918_14113"
          width="52.7468"
          x="0.486232"
          y="-3.7826"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="4.55644" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="40.2533"
          id="filter5_f_918_14113"
          width="36.7931"
          x="19.4079"
          y="15.3843"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="1.60319" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="72.0319"
          id="filter6_f_918_14113"
          width="72.0319"
          x="-6.59407"
          y="-6.59407"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="3.79704" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="39.5787"
          id="filter7_f_918_14113"
          width="50.988"
          x="1.28528"
          y="19.7628"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="4.21893" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="12.097"
          id="filter8_f_918_14113"
          width="12.5406"
          x="31.0588"
          y="10.9879"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="1.1813" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="10.6143"
          id="filter9_f_918_14113"
          width="11.3957"
          x="32.8766"
          y="9.12338"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="0.506272" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="12.6427"
          id="filter10_f_918_14113"
          width="13.2516"
          x="14.2707"
          y="34.7735"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="1.1813" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="7.5674"
          id="filter11_f_918_14113"
          width="7.16124"
          x="45.5334"
          y="17.5169"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="1.1813" />
        </filter>
        <filter
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="36.5022"
          id="filter12_f_918_14113"
          width="20.9005"
          x="5.07148"
          y="5.23631"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_918_14113" stdDeviation="1.1813" />
        </filter>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_918_14113"
          x1="29.4219"
          x2="29.4219"
          y1="1"
          y2="57.8438"
        >
          <stop stop-color="#4AD5DF" />
          <stop offset="0.21875" stop-color="#FFE382" />
          <stop offset="0.473958" stop-color="#FFC38C" />
          <stop offset="0.729167" stop-color="#977EDE" />
          <stop offset="1" stop-color="#91E1AA" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_918_14113"
          x1="56.0695"
          x2="30.7636"
          y1="32.7075"
          y2="26.0012"
        >
          <stop stop-color="#EAADFF" />
          <stop offset="1" stop-color="#FFB763" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_918_14113"
          x1="25.9353"
          x2="27.6999"
          y1="5.55322"
          y2="25.9761"
        >
          <stop stop-color="#ADFFEB" />
          <stop offset="1" stop-color="#FFB763" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_918_14113"
          x1="47.3684"
          x2="32.4746"
          y1="40.4511"
          y2="30.6647"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint4_linear_918_14113"
          x1="29.4219"
          x2="29.4219"
          y1="1"
          y2="57.8438"
        >
          <stop stop-color="#54F4FF" />
          <stop offset="0.21875" stop-color="#FFDC5F" />
          <stop offset="0.473958" stop-color="#FF6745" />
          <stop offset="0.729167" stop-color="#9069FF" />
          <stop offset="1" stop-color="#77FFA2" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint5_linear_918_14113"
          x1="13.6881"
          x2="24.9553"
          y1="45.6643"
          y2="25.0584"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint6_linear_918_14113"
          x1="10.7352"
          x2="19.3001"
          y1="17.9323"
          y2="24.94"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
