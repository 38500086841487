import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  SelectorOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { useMessageBillingUsage } from '@community_dev/hooks'
import {
  DIALOG_VARIANTS,
  Dialog,
  Dropzone,
  ElasticInput,
  EmojiPicker,
  GalleryIcon,
  GifIcon,
  ImageGallery,
  Media,
  MessageDisabledIcon,
  SPACING,
  SendIcon,
  SmileIcon,
  Tooltip,
  UsageCounter,
  toStringWithoutSpecialChars,
} from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import uniq from 'lodash/uniq'
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import {
  StyledRoot,
  StyledMain,
  StyledIcons,
  StyledIcon,
  StyledField,
  StyledUploadPreview,
  StyledGifPicker,
  StyledFieldMain,
  StyledSend,
  StyledUsageCounter,
  StyledLinkShorteningCheckbox,
} from './ReplyForm.style'

import { Giphy } from 'api/giphy'
import { useGiphySearch } from 'components/ComposeMessage/hooks/useGiphySearch'
import { FileContext, FileRejectionDialog } from 'components/FileRejectionDialog'
import { LimitDialog } from 'components/LimitDialog'
import { CAPABILITIES } from 'constants/capabilities'
import { useConvo } from 'contexts/ConvoProvider'
import { useSettings } from 'contexts/SettingsProvider'
import { useUpload } from 'contexts/UploadProvider'
import { useCarrierUsage } from 'hooks/useCarrierUsage'
import { useClientId } from 'hooks/useClient'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useFan } from 'hooks/useFan'
import { useMediaConfig } from 'hooks/useMediaConfig'
import { useSendDM } from 'hooks/useSendDM'
import { useSendMediaDM } from 'hooks/useSendMediaDM'
import { useCheckMessageContent, WarningTypes } from 'hooks/useSendMessage/useCheckMessageContent'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import Sentry from 'integrations/Sentry'
import analytics from 'utils/analytics'
import { screens } from 'utils/analytics/events'

export const INPUT_MAX_LENGTH = 400

const initialSelection = { start: 0, end: 0 }

type ReplyFormProps = {
  scrollConvoBottom: (...args: any[]) => any
  fanId: string
  disabled?: boolean
  disabledReason?: ReactNode
  enableAttachments?: boolean
  showUsage?: boolean
  communicationChannel?: CommunicationChannel
}

const DEFAULT_BYPASS = [WarningTypes.BIRTHDAY_FILTER, WarningTypes.QUIET_TIME, WarningTypes.LINK_PREVIEW]

const ReplyForm = React.forwardRef<HTMLDivElement, ReplyFormProps>(
  (
    {
      scrollConvoBottom,
      fanId,
      showUsage = true,
      enableAttachments = true,
      disabledReason,
      disabled = false,
      communicationChannel = CommunicationChannel.SMS,
    }: ReplyFormProps,
    ref,
  ) => {
    const clientId = useClientId()
    const canDisableLinkShortener = useHasCapability(CAPABILITIES.FEATURE.LINK_SHORTENING.ALL)

    const { COLORS } = useTheme()
    const dropzone = React.createRef<any>()
    const gifPicker = React.createRef<any>()

    const { giphySearchResults, setSearchTerm } = useGiphySearch()
    const [gifMedia, setGifMedia] = useState<Giphy>()

    const { file, setFile, uploading, resetUpload } = useUpload()

    const { data: fan } = useFan(fanId)

    const { showToastMessage } = useToastMessage()
    const currentTheme = useCurrentTheme()
    const { settings } = useSettings()
    const { isLimitReached } = useCarrierUsage()
    const { t } = useTranslation()

    const { replyBody, replyInput, setReplyBody, usedAiSuggestion, setUsedAiSuggestion } = useConvo()
    const trimmedBody = replyBody.trim()

    const [emojiActive, setEmojiActive] = useState<boolean>(false)
    const [gifActive, setGifActive] = useState<boolean>(false)
    const [selection, setSelection] = useState(initialSelection)
    const { hasLinks } = useShortenableLinks(trimmedBody, 200)
    const [shouldShortenLinks, setShouldShortenLinks] = useState(true)
    const [isLimitDialogOpen, setIsLimitDialogOpen] = useState(false)
    const [isBadWordsDialogVisible, setIsBadWordsDialogVisible] = useState(false)
    const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false)
    const { accept, maxSize, mediaDisposition, resetMediaDisposition, setMediaDisposition } = useMediaConfig({
      communicationChannel,
      context: FileContext.DM,
    })
    const { hasViolation, warning, checkMessage } = useCheckMessageContent({
      clientId,
      mediaDisposition,
    })
    const badWords = useMemo(
      () =>
        uniq([
          ...(warning?.badWords?.general || []),
          ...(warning?.badWords?.alcohol || []),
          ...(warning?.badWords?.tobacco || []),
          ...(warning?.badWords?.violations || []),
        ]).reduce((words, word, index, array) => {
          const isLastWord = array.length - 1 === index
          const nextWord = `"${word}"${isLastWord ? '' : ', '}`

          return `${words}${nextWord}`
        }, ''),

      [warning],
    )

    const handleCancelBadWordsDialog = () => {
      setIsBadWordsDialogVisible(false)
      focusInput()
      analytics.track(
        analytics.events.ContentWarningMessageEdited({
          content: trimmedBody,
          page: screens.Inbox,
          triggers: badWords,
        }),
      )
    }

    const handleCancelConfirmationDialog = () => {
      setIsConfirmationDialogVisible(false)
      focusInput()
    }

    const handleClickSendAnywayBadWords = () => {
      setIsBadWordsDialogVisible(false)
      setIsConfirmationDialogVisible(true)
      analytics.track(
        analytics.events.ContentWarningOverridden({
          content: trimmedBody,
          page: screens.Inbox,
          triggers: badWords,
        }),
      )
    }

    const handleClickIUnderstandSendBadWords = () => {
      setIsConfirmationDialogVisible(false)
      onSendMessage({
        bypass: [
          WarningTypes.BAD_WORDS,
          WarningTypes.BIRTHDAY_FILTER,
          WarningTypes.QUIET_TIME,
          WarningTypes.LINK_PREVIEW,
        ],
      })
    }

    useEffect(() => {
      const warningType = warning?.type

      if (warningType === WarningTypes.BAD_WORDS) {
        analytics.track(
          analytics.events.ContentWarningTriggered({
            content: trimmedBody,
            page: screens.Inbox,
            triggers: badWords,
          }),
        )
        return setIsBadWordsDialogVisible(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warning])

    useEffect(() => {
      if (!gifMedia?.original && !file?.size) {
        resetMediaDisposition()
      }
    }, [file, gifMedia, communicationChannel, resetMediaDisposition])

    const hasMediaLink = Boolean(gifMedia?.original)
    const isMediaAttached = Boolean(file)
    const firstName = (fan?.fullName || '').split(' ')[0]
    const toName = fan?.fullName ? ` to ${firstName}` : ''
    const shouldRenderGifPicker = communicationChannel === CommunicationChannel.SMS
    const shouldTransformSpecialChars = communicationChannel === CommunicationChannel.SMS

    const resetForm = () => {
      resetMediaDisposition()
      resetUpload()
      setEmojiActive(false)
      setGifActive(false)
      setGifMedia(undefined)
      setReplyBody('')
      setSelection(initialSelection)
      setUsedAiSuggestion(false)
      focusInput()
      scrollConvoBottom(true)
    }

    const { mutateAsync: sendDM, isLoading: isSendingDM } = useSendDM({
      onComplete: resetForm,
    })
    const { mutateAsync: sendMediaDM, isLoading: isSendingMediaDM } = useSendMediaDM({
      onComplete: resetForm,
    })

    useEffect(() => {
      focusInput()

      return () => {
        resetForm()
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onMouseUp = useCallback(
      (e) => {
        if (!gifPicker || !gifPicker.current) return
        if (!gifPicker.current.root.current.contains(e.target) && gifActive) {
          setGifActive(false)
        }
      },
      [gifActive, setGifActive, gifPicker],
    )

    useEffect(() => {
      window.addEventListener('mouseup', onMouseUp)

      return () => {
        window.removeEventListener('mouseup', onMouseUp)
      }
    }, [onMouseUp]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      resetForm()
    }, [fanId]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSendMessage = () => {
      if (isLimitReached) {
        setIsLimitDialogOpen(true)
      } else {
        onSendMessage({ bypass: DEFAULT_BYPASS })
      }
    }

    const handleContinue = () => {
      setMediaDisposition(MediaDisposition.LINK)
    }

    const onChangeInput = (e) => {
      setReplyBody(shouldTransformSpecialChars ? toStringWithoutSpecialChars(e.target.value) : e.target.value)
    }

    const onEmojiOpen = () => {
      const inputNode = replyInput?.current
      setEmojiActive(true)
      setSelection({
        start: inputNode?.selectionStart || initialSelection.start,
        end: inputNode?.selectionEnd || initialSelection.end,
      })
    }

    const onEmojiClose = () => {
      setEmojiActive(false)
    }

    const onEmojiSelect = (emoji) => {
      const { start, end } = selection
      let newBody = replyBody.substring(0, start) + emoji.native
      newBody += replyBody.substring(end)
      const pos = start + emoji.native.length
      const newSelection = { start: pos, end: pos }

      setReplyBody(newBody)
      setSelection(newSelection)
    }

    const onGifOpen = () => {
      setGifActive(true)
    }

    const onFocus = () => {
      if (scrollConvoBottom) {
        scrollConvoBottom()
      }
    }

    const checkValidMessage = () => {
      if (!fanId) return false
      if (trimmedBody || isMediaAttached || hasMediaLink) return true
      return false
    }

    const focusInput = () => {
      if (document.activeElement === replyInput?.current) return
      if (window.orientation !== undefined) return
      if (document.activeElement?.id === 'search') return
      if (gifActive) return
      replyInput?.current?.focus()
    }

    const onSendMessage = async ({ bypass = DEFAULT_BYPASS }: { bypass?: string[] }) => {
      if (!checkValidMessage()) return
      const { error, valid } = await checkMessage(
        {
          text: trimmedBody,
          filters: {
            operator: SelectorOperators.EQUALS,
            operand: {
              field_key: BuiltInFields.AGE,
              field_label: fieldLabelFor(BuiltInFields.AGE),
              source: FieldSources.BUILT_IN,
              type: FieldTypes.INTEGER,
              value: typeof fan?.age === 'number' ? String(fan.age) : '13',
            },
          },
        },
        {
          bypass,
        },
      )
      if (error) {
        Sentry.captureException(error)

        showToastMessage({
          message: t('compose.failedToSendMessage'),
          success: false,
        })

        return
      }

      if (!valid) return

      if (!file || file?.type.includes('image')) {
        let _body = trimmedBody
        if (gifMedia?.original?.mp4) {
          _body = `${gifMedia.original.mp4} ${_body}`
        }

        try {
          await sendDM({
            usedAiSuggestion,
            file,
            fanId,
            message: {
              clientId,
              data: {
                fan_id: fanId,
                text: _body,
                shorten_links: shouldShortenLinks,
                media_disposition: mediaDisposition,
                media: null,
              },
            },
          })
        } catch (e) {
          Sentry.captureException(e)

          showToastMessage({
            message: t('compose.failedToSendMessage'),
            success: false,
          })
        }
      } else if (file) {
        const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
        const data = {
          message_body: trimmedBody,
          media_file_extension: extension,
          shorten_links: shouldShortenLinks,
          media_disposition: mediaDisposition,
        }

        try {
          await sendMediaDM({
            usedAiSuggestion,
            message: {
              fanId,
              clientId,
              data,
            },
            file,
            fanId,
          })
        } catch (e) {
          Sentry.captureException(e)

          showToastMessage({
            message: 'Failed to send message.',
            success: false,
          })
        }
      }
    }

    const onRemove = () => {
      resetMediaDisposition()
      setFile(undefined)
    }

    const onFileAccepted = (file: File) => {
      setGifMedia(undefined)
      setFile(file)
    }

    const onFileRejected = useCallback(
      (fileRejection: FileRejection) => {
        setFile(fileRejection.file)
      },
      [setFile],
    )

    const onGifImageClick = (gif) => {
      setFile(undefined)
      setGifActive(false)
      setGifMedia(gif)
      setTimeout(() => scrollConvoBottom(), 0)
    }

    const onGifSearchUpdate = (val) => {
      setSearchTerm(val)
    }

    const onRemoveGifMedia = () => {
      setGifMedia(undefined)
    }

    const { links } = useShortenableLinks(trimmedBody)
    const messageBillingUsage = useMessageBillingUsage({
      communicationChannel,
      hasMediaLink,
      linksToShorten: links,
      isMediaAttached,
      mediaDisposition,
      message: trimmedBody,
      shouldShortenLinks,
    })

    const onKeyDown = (e) => {
      if (e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault()
        if (messageBillingUsage.error) return
        handleSendMessage()
        return false
      }
    }

    const sendEnabled = checkValidMessage() && !messageBillingUsage.error

    return (
      <StyledRoot data-testid="reply-form" ref={ref}>
        <StyledMain>
          <StyledIcons>
            <StyledIcon $disabled={!enableAttachments || disabled}>
              <Dropzone
                accept={accept}
                disabled={!enableAttachments || disabled}
                maxSize={maxSize}
                noDrag
                onFileAccepted={onFileAccepted}
                onFileRejected={onFileRejected}
                ref={dropzone}
              >
                <GalleryIcon size={24} />
              </Dropzone>
            </StyledIcon>
            <StyledIcon $disabled={disabled} onClick={disabled ? undefined : onEmojiOpen}>
              <SmileIcon size={24} />
            </StyledIcon>
            {shouldRenderGifPicker && (
              <StyledIcon
                $disabled={!enableAttachments || disabled}
                data-testid="reply-gif-picker"
                onClick={disabled ? undefined : onGifOpen}
              >
                <GifIcon size={24} />
              </StyledIcon>
            )}
          </StyledIcons>
          <StyledField>
            {file && (
              <StyledUploadPreview>
                <Media
                  file={file}
                  onRemove={onRemove}
                  removable
                  uploading={uploading || isSendingDM || isSendingMediaDM}
                />
              </StyledUploadPreview>
            )}
            {gifMedia && gifMedia.preview && (
              <StyledUploadPreview>
                <Media
                  onRemove={onRemoveGifMedia}
                  remoteUrl={gifMedia.preview.src}
                  removable
                  type="image/gif"
                  width={Number(gifMedia.preview.width)}
                />
              </StyledUploadPreview>
            )}
            {emojiActive && (
              <EmojiPicker
                onOuterClick={onEmojiClose}
                onSelect={onEmojiSelect}
                rootStyle={{ top: '-310px', left: '0' }}
                skin={settings.skinTone}
                theme={currentTheme.type}
              />
            )}
            {gifActive && (
              <StyledGifPicker>
                <ImageGallery
                  images={giphySearchResults}
                  onImageClick={onGifImageClick}
                  onSearchUpdate={onGifSearchUpdate}
                  ref={gifPicker}
                />
              </StyledGifPicker>
            )}
            <Tooltip content={disabledReason} disabled={!disabled || !disabledReason}>
              <StyledFieldMain $disabled={disabled} data-private="redact">
                <ElasticInput
                  className="ReplyForm-input"
                  data-testid="reply-form-input"
                  disabled={disabled}
                  maxRows={5}
                  minRows={1}
                  onChange={onChangeInput}
                  onFocus={onFocus}
                  onKeyDown={onKeyDown}
                  placeholder={`Send a message${toName}`}
                  ref={replyInput}
                  value={replyBody}
                />
                {!disabled && showUsage && (
                  <StyledUsageCounter>
                    <UsageCounter
                      charCount={messageBillingUsage.charCount}
                      error={messageBillingUsage.error}
                      segmentCount={messageBillingUsage.segmentCount}
                      segmentMessage={messageBillingUsage.segmentMessage}
                      tooltipContent={messageBillingUsage.tooltipContent}
                    />
                  </StyledUsageCounter>
                )}
                {disabled && (
                  <StyledIcon $disabled style={{ marginRight: SPACING[2] }}>
                    <MessageDisabledIcon size={20} />
                  </StyledIcon>
                )}

                {!disabled && (
                  <StyledSend
                    $disabled={!sendEnabled}
                    aria-disabled={!sendEnabled}
                    aria-label="send direct message"
                    data-testid={`reply-form-send-button`}
                    disabled={!sendEnabled}
                    onClick={sendEnabled ? handleSendMessage : undefined}
                  >
                    <SendIcon
                      color={COLORS?.APP_BACKGROUND_LEVEL_3}
                      fill={sendEnabled ? COLORS?.LINKS : COLORS?.BUTTON_DISABLED}
                      size={26}
                    />
                  </StyledSend>
                )}
              </StyledFieldMain>
            </Tooltip>
          </StyledField>
        </StyledMain>
        <FileRejectionDialog
          communicationChannel={communicationChannel}
          context={FileContext.DM}
          file={file}
          mediaDisposition={mediaDisposition}
          onClose={() => {
            setFile(undefined)
            resetMediaDisposition()
          }}
          onContinue={handleContinue}
        />
        {canDisableLinkShortener && hasLinks && (
          <StyledLinkShorteningCheckbox onChange={setShouldShortenLinks} value={shouldShortenLinks} />
        )}
        {isLimitDialogOpen && (
          <LimitDialog
            onClose={() => setIsLimitDialogOpen(false)}
            onSubmit={() => {
              setIsLimitDialogOpen(false)
              onSendMessage({ bypass: DEFAULT_BYPASS })
            }}
            submitText={t('tcr.carrierLimit.sendAnyway')}
          />
        )}
        {isBadWordsDialogVisible && (
          <Dialog
            message={
              <Trans
                i18nKey={hasViolation ? 'compose.violationDialogDescription' : 'compose.badWordsDialogDescription'}
                values={{ badWords }}
              >
                <a
                  href="https://www.community.com/legal/aup"
                  onClick={() => {
                    analytics.track(
                      analytics.events.ContentWarningPolicyViewed({
                        content: trimmedBody,
                        page: screens.Inbox,
                        triggers: badWords,
                      }),
                    )
                  }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  &nbsp;
                </a>
                <a
                  href="https://r.community.com/messaging-guidelines"
                  onClick={() => {
                    analytics.track(
                      analytics.events.ContentWarningGuidelinesViewed({
                        content: trimmedBody,
                        page: screens.Inbox,
                        triggers: badWords,
                      }),
                    )
                  }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  &nbsp;
                </a>
              </Trans>
            }
            onCancel={handleCancelBadWordsDialog}
            title={t('compose.badWordsDialogTitle')}
          >
            {!hasViolation && (
              <Dialog.Action onClick={handleClickSendAnywayBadWords}>{t('compose.sendAnyway')}</Dialog.Action>
            )}
            <Dialog.Action onClick={handleCancelBadWordsDialog} variant={DIALOG_VARIANTS.EMPHASIZED}>
              {t('compose.editMessage')}
            </Dialog.Action>
          </Dialog>
        )}
        {isConfirmationDialogVisible && (
          <Dialog
            message={<Trans i18nKey="compose.confirmationDialogDescription" />}
            onCancel={handleCancelConfirmationDialog}
            title={t('compose.confirmationDialogTitle')}
          >
            <Dialog.Action onClick={handleClickIUnderstandSendBadWords}>{t('compose.iUnderstandSend')}</Dialog.Action>
            <Dialog.Action onClick={handleCancelConfirmationDialog} variant={DIALOG_VARIANTS.EMPHASIZED}>
              {t('compose.editMessage')}
            </Dialog.Action>
          </Dialog>
        )}
      </StyledRoot>
    )
  },
)

export default ReplyForm
