import { DAY_KEY, MONTH_KEY, WEEK_KEY } from '../constants'

import { LineChartData } from 'components/LineChart'
import dayjs from 'utils/dayjs'

const formatDateWeekMonth = (d: Date): string => {
  // if its the same day as today dont display because we display 'now'
  if (dayjs().isSame(d, 'day')) {
    return ''
  }

  return dayjs(d).format('M/DD')
}

const formatDateDay = (d: Date): string => {
  // if its the same day as today dont display because we display 'now'
  if (dayjs().isSame(d, 'hour')) {
    return ''
  }

  // if the hour is 12 add pm / am
  if (dayjs().hour(0).isSame(d, 'hour') || dayjs().hour(12).isSame(d, 'hour')) {
    return dayjs(d).format('h a')
  }
  return dayjs(d).format('h')
}

export const formatDateFns = {
  [DAY_KEY]: formatDateDay,
  [WEEK_KEY]: formatDateWeekMonth,
  [MONTH_KEY]: formatDateWeekMonth,
}

type Data = {
  timestamp: string
  count: number
}

const withLatestDataForDay = (data: Data[], activeSubscriptions: number): LineChartData[] => {
  // to get the time to align more on the date we set the hour to 0
  const now = dayjs().startOf('hour').valueOf()
  return [
    ...data
      .filter(({ timestamp }) => {
        //filter any hour thats after today
        return dayjs(timestamp).isBefore(dayjs(now), 'hour')
      })
      .map(({ timestamp, count }) => ({
        x: dayjs(timestamp).startOf('hour').valueOf(),
        y: count,
      })),
    // add the latest data point for now
    {
      x: now,
      y: activeSubscriptions,
    },
  ]
}

const withLatestDataForWeek = (data: Data[], activeSubscriptions: number): LineChartData[] => {
  // to get the time to align more on the date we set the hour and minute to 0
  const now = dayjs().startOf('day').valueOf()
  return [
    ...data
      //filter any day thats after today
      .filter(({ timestamp }) => {
        const roundedTime = dayjs(timestamp.split('T')[0])
        return roundedTime.isBefore(dayjs(now), 'day')
      })
      .map(({ timestamp, count }) => ({
        x: dayjs(timestamp.split('T')[0]).valueOf(),
        y: count,
      })),
    // add the latest data point for now
    {
      x: now,
      y: activeSubscriptions,
    },
  ]
}

export const withLatestDataFns = {
  [DAY_KEY]: withLatestDataForDay,
  [WEEK_KEY]: withLatestDataForWeek,
  [MONTH_KEY]: withLatestDataForWeek,
}

const formatTooltipDayDate = (d?: Date): string => {
  if (!d) return ''
  return dayjs(d).format('h a')
}

const formatTooltipDate = (d?: Date): string => {
  if (!d) return ''
  return dayjs(d).format('M/DD/YYYY')
}

export const tooltipFormatFns = {
  [DAY_KEY]: formatTooltipDayDate,
  [WEEK_KEY]: formatTooltipDate,
  [MONTH_KEY]: formatTooltipDate,
}
