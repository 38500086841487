import React from 'react'

type WebFormIconProps = {
  size?: number
}

export const WebFormIcon = ({ size = 24 }: WebFormIconProps): JSX.Element => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.93311 11.8047C4.11768 11.7842 4.30225 11.7705 4.47998 11.7705C4.66455 11.7705 4.84912 11.7842 5.03369 11.8047V9.22754H18.9517V16.1934C18.9517 16.9043 18.5688 17.2666 17.8921 17.2666H8.73877C8.6499 17.6563 8.49951 18.0254 8.30127 18.3672H17.9058C19.3413 18.3672 20.0522 17.6563 20.0522 16.248V7.90137C20.0522 6.49316 19.3413 5.78223 17.9058 5.78223H6.07959C4.65088 5.78223 3.93311 6.48633 3.93311 7.90137V11.8047ZM5.4917 7.50488C5.4917 7.18359 5.76514 6.89648 6.1001 6.89648C6.43506 6.89648 6.71533 7.18359 6.71533 7.50488C6.71533 7.82617 6.43506 8.11328 6.1001 8.11328C5.76514 8.11328 5.4917 7.82617 5.4917 7.50488ZM7.45361 7.50488C7.45361 7.18359 7.72705 6.89648 8.06201 6.89648C8.39697 6.89648 8.67725 7.18359 8.67725 7.50488C8.67725 7.82617 8.39697 8.11328 8.06201 8.11328C7.72705 8.11328 7.45361 7.82617 7.45361 7.50488ZM9.41553 7.50488C9.41553 7.18359 9.68896 6.89648 10.0239 6.89648C10.3589 6.89648 10.6323 7.18359 10.6323 7.50488C10.6323 7.82617 10.3589 8.11328 10.0239 8.11328C9.68896 8.11328 9.41553 7.82617 9.41553 7.50488ZM4.48682 19.6592C6.3667 19.6592 7.95947 18.0869 7.95947 16.1865C7.95947 14.2861 6.39404 12.7139 4.48682 12.7139C2.57959 12.7139 1.01416 14.2861 1.01416 16.1865C1.01416 18.1006 2.57959 19.6592 4.48682 19.6592ZM4.48682 18.4424C4.24756 18.4424 4.04248 18.2783 4.04248 18.0254V16.6035H2.72998C2.50439 16.6035 2.31299 16.4121 2.31299 16.1865C2.31299 15.9609 2.50439 15.7695 2.72998 15.7695H4.04248V14.3545C4.04248 14.0947 4.24756 13.9307 4.48682 13.9307C4.72607 13.9307 4.93115 14.0947 4.93115 14.3545V15.7695H6.24365C6.46924 15.7695 6.66064 15.9609 6.66064 16.1865C6.66064 16.4121 6.46924 16.6035 6.24365 16.6035H4.93115V18.0254C4.93115 18.2783 4.72607 18.4424 4.48682 18.4424Z"
        fill="#888888"
      />
    </svg>
  )
}
