import { DigitsFilterQuerySelectAll } from '@community_dev/filter-dsl/lib/digits'
import { MemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { digitsFilterToMemberDataFilter } from '@community_dev/filter-dsl/lib/transformers/digitsFilterToMemberDataFilter'
import { Seq } from 'immutable'

import { IncludeExcludeRecord, Modes } from './IncludeExcludeState'
// IncludeExcludeAdapter - used to convert an IncludeExcludeState
// to various query formats used by other internal state representations and the
// backend

export const convertToBackendQuery = (
  includeExcludeState: IncludeExcludeRecord,
  otherKeys: Pick<DigitsFilterQuerySelectAll, 'campaign_id' | 'client_id' | 'tags'>,
): MemberDataFilter => {
  const { includes, excludes, typeMap, mode } = includeExcludeState

  // First, sort the include/exclude rules into clusters and fanSubs so that
  // we can handle the different semantics for each operation
  const excludedFanSubIds = excludes.keySeq().filter((k) => typeMap.get(k) === 'fanSubscription')

  const includedFanSubIds = includes.keySeq().filter((k) => typeMap.get(k) === 'fanSubscription')

  const excludedClusterIds = excludes.keySeq().filter((k) => typeMap.get(k) !== 'fanSubscription')

  const includedClusterIds = includes.keySeq().filter((k) => typeMap.get(k) !== 'fanSubscription')

  // Next, handle the special case where the select all behavior is not active,
  // and the selection consists only of fan_subscription_ids (no clusters).
  //
  // In this specific situation and no others, the exclusive_fan_subscription_ids
  // array is used to both include the specified fan_subscription_ids, and to
  // signal to the backend that all fan subscriptions should be  excluded from
  // the response set by default. In all other cases, the standard
  // include_fan_subscription_ids array is used for including a list of
  // fan_subscription ids.
  const shouldUseExclusiveKeyForIncludingFanSubs = mode === Modes.MODE_EXCLUDE && includedClusterIds.count() === 0

  const exclusiveFanSubscriptionIds = shouldUseExclusiveKeyForIncludingFanSubs ? includedFanSubIds : Seq()

  const includeFanSubscriptionIds = !shouldUseExclusiveKeyForIncludingFanSubs ? includedFanSubIds : Seq()

  // build up the query
  return digitsFilterToMemberDataFilter({
    query: {
      select_all_count_by_campaign_id_and_tags: {
        ...otherKeys,
        cluster_ids: {
          any: includedClusterIds.toJS() as string[],
          not: excludedClusterIds.toJS() as string[],
        },
        // the next two keys, exclusive_fan_subscription_ids and include_fan_subscription_ids,
        // are both used to INCLUDE fans in the selection, but we have to switch
        // between them based on whether or not unspecified fan subs should be
        // selected by default or not (see notes above)
        exclusive_fan_subscription_ids: exclusiveFanSubscriptionIds.toJS() as string[],
        include_fan_subscription_ids: includeFanSubscriptionIds.toJS() as string[],
        exclude_fan_subscription_ids: excludedFanSubIds.toJS() as string[],
      },
    },
  }) as MemberDataFilter
}
