import { useHistory, useLocation, useParams } from 'react-router'
import styled, { css } from 'styled-components'

import { BoundsProvider } from '../../contexts/BoundsProvider'

import { InboxChatEmptyState } from './InboxChatEmptyState'
import { InboxChatHeader } from './InboxChatHeader'

import { ROUTES } from 'constants/routes'
import { TABLET_BREAK } from 'constants/theme'
import Convo from 'containers/Convo'
import { ConvoProvider } from 'contexts/ConvoProvider'
import { useInbox } from 'contexts/InboxProvider'
import { useFan } from 'hooks/useFan'

type StyledInboxChatRootProps = {
  $hideMobile?: boolean
}

const StyledInboxChatRoot = styled.div<StyledInboxChatRootProps>`
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: auto;
  background: ${({ theme }) => theme?.COLORS?.CHAT_BACKGROUND};

  ${({ $hideMobile }) =>
    $hideMobile &&
    css`
      display: none;
      visibility: hidden;

      @media (min-width: ${TABLET_BREAK}) {
        display: block;
        visibility: visible;
      }
    `};
`

const StyledMain = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`

const StyledConvo = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 70px;
`

type InboxChatProps = {
  detailsPaneOpen: boolean
  openDetailsPane: (...args: any[]) => void
}

export default function InboxChat(props: InboxChatProps): JSX.Element {
  const { detailsPaneOpen, openDetailsPane } = props
  const { empty, searchTerm } = useInbox()
  const history = useHistory()
  const { pathname } = useLocation()
  const hideMobile = RegExp('^/messages/inbox(/)?$').test(pathname)

  const { fanId } = useParams<{ fanId: string }>()
  const { data: fan } = useFan(fanId)

  const goBack = () => {
    history.push(ROUTES.MESSAGES.INBOX)
    const videos: any[] = [].slice.call(document.getElementsByTagName('video'))
    if (videos.length) videos.forEach((video) => video.pause())
  }

  return (
    <BoundsProvider>
      <StyledInboxChatRoot $hideMobile={!!hideMobile}>
        {empty ? (
          <InboxChatEmptyState search={searchTerm} />
        ) : (
          <StyledMain>
            {fan && (
              <InboxChatHeader
                detailsPaneOpen={detailsPaneOpen}
                fan={fan}
                goBack={goBack}
                openDetailsPane={openDetailsPane}
              />
            )}
            <StyledConvo>
              <ConvoProvider>
                <Convo fanId={fanId} />
              </ConvoProvider>
            </StyledConvo>
          </StyledMain>
        )}
      </StyledInboxChatRoot>
    </BoundsProvider>
  )
}
