import { FONT_SIZE, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { StyledLink } from '../../../../../styled'
import { Code } from '../../Code'

import { CopyButton } from './CopyButton'

import { Embed } from 'api/mgu'
import { MGU_POPUP_INSTRUCTIONS_LINK } from 'screens/SettingsScreen/components/EmbedSettings/constants/links'

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${SPACING[1]};
`

const StyledSubtext = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  padding-top: ${SPACING[2]};
`

const StyledInstructions = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-size: ${FONT_SIZE[3]};
`

const StyledInstructionsLink = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
`

type PopUpInstallationProps = {
  embed: Embed
}

export const PopUpInstallation = ({ embed }: PopUpInstallationProps): JSX.Element => {
  const script = `<script async data-community-embed-id="${embed?.id}" src="${
    import.meta.env.VITE_MGU_EMBED_URL
  }"></script>`

  return (
    <>
      <StyledInstructions>
        Copy/Paste this Code Snippet into your website’s code before the {'</body>'} tag.
        <StyledSubtext>Code Snippet</StyledSubtext>
        <Code language="html">{script}</Code>
      </StyledInstructions>
      <StyledButtonRow>
        <StyledInstructionsLink>
          <StyledLink href={MGU_POPUP_INSTRUCTIONS_LINK} rel="noopener noreferrer" target="_blank">
            Detailed Instructions
          </StyledLink>
        </StyledInstructionsLink>
        <CopyButton content={script} />
      </StyledButtonRow>
    </>
  )
}
