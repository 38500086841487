import {
  Button,
  EnterKeyIcon,
  ElasticInput,
  LookingUpFlag,
  Layout,
  SPACING,
  BUTTON_VARIANTS,
  Menu,
  WhatsAppIcon,
  SmsIcon,
  AppleIcon,
  Tooltip,
} from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import copy from 'copy-to-clipboard'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import {
  ActiveKeywords,
  BannerActiveText,
  BannerCard,
  ButtonAdd,
  Empty,
  EmptyStateText,
  Footer,
  InputArea,
  InputAreaKeyword,
  InputError,
  InputLabel,
  InputSubText,
  InputWrapper,
  ListItem,
  ListItemName,
  Main,
  StyledCard,
} from './KeywordsModal.style'

import { AddedButton } from 'components/Communities'
import { CAPABILITIES } from 'constants/capabilities'
import { useClient } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import { useCapabilityChecks } from 'hooks/useUserCapability'
import { NormalizedKeyword } from 'utils/normalize'
import { downloadAsPng, downloadAsSvg, getShareUrl } from 'utils/shareCommunity'

const StyledButton = styled(Button)`
  border-radius: 12px;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  user-select: none;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  padding: 0 ${SPACING[2]};
  min-width: 72px;
  min-height: 24px;
`

type KeywordsEditorProps = {
  keywords: Partial<NormalizedKeyword>[]
  capacity?: number
  autoFocus?: boolean
  error?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onAdd: (keyword: string) => void
  onDelete: (item: Partial<NormalizedKeyword>) => void
  onClose: () => void
  inputPlaceholder?: string
  infoBubble?: string
  activeWordCount?: string
  emptyState?: string
  doneButtonDisabled?: boolean
  doneButtonText?: string
  children?: React.ReactNode
}

const KeywordsEditor: React.FC<KeywordsEditorProps> = (props) => {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()

  const {
    capacity = 15,
    keywords,
    autoFocus,
    error,
    onChange,
    onAdd,
    onDelete,
    onClose,
    inputPlaceholder = t('keywordsModal.inputPlaceholder'),
    infoBubble = t(`keywordsModal.infoBubble`),
    activeWordCount = t(`keywordsModal.activeWordCount`, { count: props.keywords.length }),
    emptyState = t('keywordsModal.emptyState'),
    doneButtonDisabled,
    doneButtonText = t('done'),
    children,
  } = props

  const [keyword, setKeyword] = useState<string>('')

  const inputRef = useRef<HTMLTextAreaElement | null>(null)
  const { data: client } = useClient()
  const { userHasCapability } = useCapabilityChecks()

  const submitOnEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        e.preventDefault()
        onAdd(keyword)
      }
    },
    [keyword, onAdd],
  )

  const channelConfig: Record<CommunicationChannel, { capabilities?: string[]; tabIcon: JSX.Element; name: string }> =
    useMemo(
      () => ({
        [CommunicationChannel.WHATS_APP]: {
          capabilities: [CAPABILITIES.FEATURE.WHATSAPP.ALL],
          tabIcon: <WhatsAppIcon size={18} style={{ marginRight: '3px' }} />,
          name: 'WhatsApp',
        },
        [CommunicationChannel.AMB]: {
          capabilities: [CAPABILITIES.FEATURE.AMB.ALL],
          tabIcon: <AppleIcon size={17} />,
          name: 'Apple',
        },
        [CommunicationChannel.SMS]: {
          tabIcon: <SmsIcon size={17} style={{ marginRight: '4px' }} />,
          name: 'SMS',
        },
      }),
      [],
    )

  const communicationChannelsSorted = useMemo(
    () =>
      (Object.keys(channelConfig) as CommunicationChannel[]).filter((channel) => {
        return (
          channel !== CommunicationChannel.AMB && // AMB is not supported
          client?.communicationChannels.includes(channel) &&
          (channelConfig[channel].capabilities === undefined ||
            channelConfig[channel].capabilities?.every(userHasCapability))
        )
      }),
    [channelConfig, client?.communicationChannels, userHasCapability],
  )

  useEffect(() => {
    if (keywords.find((i) => i.word === keyword)) {
      setKeyword('')
    }
  }, [keywords]) // eslint-disable-line react-hooks/exhaustive-deps

  const inputDisabled = useMemo(() => {
    if (keywords.length === capacity) {
      return true
    }

    return false
  }, [keywords?.length, capacity])

  const addDisabled = useMemo(() => {
    if (error) {
      return true
    }

    if (!keyword.length) {
      return true
    }

    return false
  }, [error, keyword])

  const onKeywordChange = (e) => {
    typeof onChange === 'function' && onChange(e)

    const word = e.target.value

    setKeyword(word)
  }

  const handleDownload = (keyword: string, fileType: 'png' | 'svg', channel: CommunicationChannel) => {
    const url = getShareUrl({ communicationChannel: channel, messageText: keyword, client })
    const fileName = `${channelConfig[channel].name} - ${keyword} QR Code`

    if (fileType === 'png') {
      downloadAsPng(url, fileName, client?.fullName)
    } else {
      downloadAsSvg(url, fileName, client?.fullName)
    }
  }

  const handleCopy = (keyword: string, channel: CommunicationChannel) => {
    const url = getShareUrl({ communicationChannel: channel, messageText: keyword, client })
    copy(url)
    showToastMessage({
      message: t('copiedToClipboard'),
    })
  }

  return (
    <Main>
      <Layout flex="1" padding={`0 ${SPACING[7]} ${SPACING[7]}`}>
        <InputArea>
          <InputAreaKeyword>
            <InputWrapper>
              <ElasticInput
                autoFocus={autoFocus}
                disabled={inputDisabled}
                maxLength={50}
                maxRows={1}
                minRows={1}
                onChange={onKeywordChange}
                onKeyDown={submitOnEnter}
                placeholder={inputDisabled ? t('keywordsModal.limitReached') : inputPlaceholder}
                ref={inputRef}
                value={keyword}
              />
            </InputWrapper>
            <InputSubText>
              <InputLabelMessage disabled={inputDisabled} error={error} />
            </InputSubText>
          </InputAreaKeyword>
          <ButtonAdd
            color={COLORS?.DEPRECATED_SENT}
            data-testid="add-keyword-button"
            disabled={addDisabled}
            onClick={(e) => {
              e.preventDefault()
              onAdd(keyword)
            }}
            title="Add"
          >
            Add
          </ButtonAdd>
        </InputArea>
        <ActiveKeywords>
          <BannerCard>
            <StyledCard text={infoBubble} />
          </BannerCard>
          <BannerActiveText>{activeWordCount}</BannerActiveText>

          {keywords.length ? (
            <>
              {keywords.map((item) => (
                <ListItem key={item.word}>
                  <ListItemName>{item.word}</ListItemName>
                  <div className="ListItem-remove">
                    <Tooltip content={t('keywordsModal.copy.tooltip', { keyword: item.word })} placement="top">
                      <span>
                        {communicationChannelsSorted.length === 1 ? (
                          <StyledButton
                            onClick={() => handleCopy(item.word!, communicationChannelsSorted[0])}
                            variant={BUTTON_VARIANTS.PILL}
                          >
                            {t('keywordsModal.copy.button')}
                          </StyledButton>
                        ) : (
                          <Menu
                            style={{ left: 0 }}
                            trigger={
                              <StyledButton variant={BUTTON_VARIANTS.PILL}>
                                {t('keywordsModal.copy.button')}
                              </StyledButton>
                            }
                            width={200}
                          >
                            {communicationChannelsSorted.map((channel) => {
                              const { tabIcon, name } = channelConfig[channel]
                              return (
                                <Menu.Item onClick={() => handleCopy(item.word!, channel)}>
                                  {tabIcon} {t(`keywordsModal.copy.${name.toLowerCase()}`)}
                                </Menu.Item>
                              )
                            })}
                          </Menu>
                        )}
                      </span>
                    </Tooltip>{' '}
                    <Tooltip content={t('keywordsModal.qrCode.tooltip', { keyword: item.word })} placement="top">
                      <span>
                        <Menu
                          style={{ left: 0 }}
                          trigger={
                            <StyledButton variant={BUTTON_VARIANTS.PILL}>
                              {t('keywordsModal.qrCode.button')}
                            </StyledButton>
                          }
                          width={180}
                        >
                          {communicationChannelsSorted.map((channel, i) => {
                            const { tabIcon, name } = channelConfig[channel]
                            return (
                              <>
                                <Menu.Item divider={i !== 0} onClick={() => handleDownload(item.word!, 'png', channel)}>
                                  {tabIcon} {name} {t('png')}
                                </Menu.Item>
                                <Menu.Item onClick={() => handleDownload(item.word!, 'svg', channel)}>
                                  {tabIcon} {name} {t('svg')}
                                </Menu.Item>
                              </>
                            )
                          })}
                        </Menu>
                      </span>
                    </Tooltip>{' '}
                    <AddedButton added label="Remove" onRemove={() => onDelete(item)} />
                  </div>
                </ListItem>
              ))}
              {children}
            </>
          ) : (
            <Empty>
              <div style={{ marginTop: 30 }}>
                <LookingUpFlag height={150} width={200} />
                <EmptyStateText>{emptyState}</EmptyStateText>
              </div>
            </Empty>
          )}
        </ActiveKeywords>
      </Layout>
      <Footer
        css={`
          position: sticky;
          bottom: 0;
        `}
      >
        <Button disabled={doneButtonDisabled} onClick={() => onClose()} title="Done" type="button">
          {doneButtonText}
        </Button>
      </Footer>
    </Main>
  )
}

type InputLabelMessageProps = {
  error?: string
  disabled?: boolean
}

const InputLabelMessage: React.FC<InputLabelMessageProps> = (props) => {
  const { t } = useTranslation()
  const { disabled, error } = props

  if (error?.length) {
    return <InputError>{error}</InputError>
  }

  if (disabled) {
    return <InputLabel>{t('keywordsModal.pleaseRemoveKeywords')}</InputLabel>
  }

  return (
    <>
      <EnterKeyIcon />
      <InputLabel style={{ marginLeft: 5 }}>{t('keywordsModal.pressEnterToAdd')}</InputLabel>
    </>
  )
}

export default KeywordsEditor
