const toRad = (deg) => (deg * Math.PI) / 180

function haversine(neLat: number, swLat: number, neLng: number, swLng: number): number {
  const lat2 = swLat + (neLat - swLat) / 2
  const lon2 = swLng
  const lat1 = lat2
  const lon1 = neLng

  const R = 6371 // km
  const x1 = lat2 - lat1
  const dLat = toRad(x1)
  const x2 = lon2 - lon1
  const dLon = toRad(x2)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return (d * 1.60934) / 5
}

export default haversine
