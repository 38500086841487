import { useEffect } from 'react'
import styled from 'styled-components'

import { useActionPanel } from 'components/ActionPanel/ActionPanelContext'
import IndexChain from 'containers/Chain/IndexChain'
import { SidebarLayout } from 'layouts/SidebarLayout'

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: ${({ theme }) => theme?.COLORS?.CHAT_BACKGROUND};
`

const CampaignDetails = (): JSX.Element => {
  const { openActionPanel } = useActionPanel()

  useEffect(() => {
    openActionPanel && openActionPanel()
  }, [])

  return (
    <SidebarLayout>
      <StyledContainer>
        <IndexChain />
      </StyledContainer>
    </SidebarLayout>
  )
}

export default CampaignDetails
