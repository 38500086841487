import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export function getCampaignRecipients({
  pageParam,
  campaignId,
}: {
  pageParam: number
  campaignId: string
}): Promise<CamelCasedPropertiesDeep<Api.V2.Pagination<Api.Fan>>> {
  return request<CamelCasedPropertiesDeep<Api.V2.Pagination<Api.Fan>>>(
    route(
      ENDPOINTS.CAMPAIGN.RECIPIENTS,
      { campaignId },
      {
        page_number: pageParam,
        page_size: 50,
      },
    ),
    { method: HTTP.GET },
  )
}
