export type PlanColorValue = '#FF9305' | '#FF5437' | '#E637CE'

export const PLAN_COLOR: Record<string, PlanColorValue> = Object.freeze({
  essentials: '#FF5437',
  premium: '#E637CE',
  starter: '#FF9305',
})

export const LEGACY_PREMIUM_PLAN_ID = 'd1d3a739-492c-42cd-99fa-44d240bb2798'
export const LEGACY_ESSENTIALS_PLAN_ID = '455b474f-77bb-4a7c-b62f-3b293c228b19'
export const LEGACY_STARTER_PLAN_ID = 'b80e8274-8b7c-4b2e-bb00-b161650e22a8'

export const LEGACY_PLAN_COLORS = Object.freeze({
  [LEGACY_ESSENTIALS_PLAN_ID]: PLAN_COLOR.essentials,
  [LEGACY_PREMIUM_PLAN_ID]: PLAN_COLOR.premium,
  [LEGACY_STARTER_PLAN_ID]: PLAN_COLOR.starter,
})

export const PLAN_TYPES = {
  COMMUNITY_PLUS: 'community_plus',
  CREDIT_CARD: 'credit_card',
  MARKETING_PARTNERSHIP: 'marketing_partnership',
  TERMED_INVOICE: 'termed_invoice',
  UNLIMITED_FREE_TRIAL: 'unlimited_free_trial',
}

export enum ENTERPRISE_BILLING_PLAN_CODES {
  EARLY_STAGE = 'usage-early-stage',
  SMALL_BUSINESS = 'usage-small-business',
  MEDIUM_BUSINESS = 'usage-medium-business',
}
