import { useCallback, useEffect, useRef } from 'react'

import { useSeat } from 'contexts/bootstrap/useSeat'
import { useSelectedClientContext } from 'hooks/useClient'

const dataExportDomainArr = window.location.origin.split('.')
dataExportDomainArr[0] = 'data-export'
const dataExportDomain =
  import.meta.env.VITE_DATA_EXPORT_WEBAPP_URL || `${window.location.protocol}//${dataExportDomainArr.join('.')}`

export const useGoToDataExport = (): (() => void) => {
  const dataExportWindowRef = useRef<Window | null>()
  const context = useSelectedClientContext()
  const { data: seat } = useSeat()
  useEffect(() => {
    dataExportWindowRef.current?.postMessage(
      {
        action: 'setContext',
        payload: {
          ...context,
          email: seat?.email,
          origin: window.location.origin,
        },
      },
      '*',
    )
    const handleMessage = (event) => {
      if (dataExportWindowRef.current !== event.source) return
      const { data } = event
      switch (data.action) {
        case 'ready':
          dataExportWindowRef.current?.postMessage(
            {
              action: 'setContext',
              payload: {
                ...context,
                email: seat?.email,
                origin: window.location.origin,
              },
            },
            '*',
          )
          break
        default:
      }
    }
    window.addEventListener('message', handleMessage, false)
    return () => window.removeEventListener('message', handleMessage, false)
  }, [context, seat?.email])

  return useCallback(() => {
    dataExportWindowRef.current = window.open(dataExportDomain)
  }, [])
}
