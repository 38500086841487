import { Info, SPACING } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledInfo = styled(Info)`
  margin: 0 0 ${SPACING[3]} 0;
  & > :first-child > :first-child {
    display: flex;
    align-items: center;
    svg path {
      fill: ${({ theme }) => theme?.COLORS?.DIVIDERS};
    }
  }
`

export function CommunityCaresInfo(): JSX.Element {
  const { t } = useTranslation()

  return <StyledInfo>{t('communityCares.composeInfo')}</StyledInfo>
}
