import { useEffect } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { useActionPanel } from 'components/ActionPanel/ActionPanelContext'
import ClusterChain from 'containers/Chain/ClusterChain'
import { useCampaignDetails } from 'hooks/useCampaign'
import { SidebarLayout } from 'layouts/SidebarLayout'

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: ${({ theme }) => theme?.COLORS?.CHAT_BACKGROUND};
`

const ClusterDetails = (): JSX.Element => {
  const { openActionPanel } = useActionPanel()
  const { campaignId }: any = useParams()

  const { data } = useCampaignDetails({
    campaignId,
  })

  const { data: parentCampaignData } = useCampaignDetails({
    campaignId: data?.parentSmsCampaignId || '',
    enabled: Boolean(data?.parentSmsCampaignId),
  })

  useEffect(() => {
    if (data?.parentSmsCampaignId && !parentCampaignData) {
      return
    }
  }, [data, parentCampaignData])

  useEffect(() => {
    openActionPanel && openActionPanel()
  }, [])

  return (
    <SidebarLayout>
      <StyledContainer>
        <ClusterChain />
      </StyledContainer>
    </SidebarLayout>
  )
}

export default ClusterDetails
