import { GenderLabels } from '@community_dev/filter-dsl/lib/humanize/builtInFilters/humanizeGenderFilter'
import {
  BuiltInFields,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorOperators,
  fieldLabelFor,
  findSubscriberIncludesIds,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { useDebouncedValue } from '@community_dev/hooks'
import { Layout, ListItem, MessageDisabledIcon, SPACING, SearchBar, Tooltip } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useQuery } from '@tanstack/react-query'
import uniqBy from 'lodash/uniqBy'
import { ChangeEvent, useRef, useState, useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { useRecipientField } from './RecipientFieldContext'
import {
  StyledAddSuffix,
  StyledButton,
  StyledFilters,
  StyledHeader,
  StyledHeading,
  StyledLoadingIndicator,
  StyledMeta,
  StyledNoResults,
  StyledPanel,
  StyledResults,
  StyledSubHeading,
  StyledVirtualList,
} from './styled-recipients'
import { useRecentMembers } from './useRecentMembers'

import { Fan, postFanIndexByQuery } from 'api/fans'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { MemberAvatar } from 'components/MemberAvatar'
import { VirtualListHandle } from 'components/VirtualList'
import { QUERY_CACHE } from 'constants/query-cache'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { canReceiveCampaigns } from 'hooks/useCanSendDms'

const StyledIconContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 60px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
`

export function RecommendationsIndividuals(): JSX.Element {
  const { COLORS } = useTheme() || {}
  const virtualRef = useRef<VirtualListHandle>(null)
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedQuery = useDebouncedValue(searchTerm, 700, true)
  const { individuals, addFilter, removeFilter, addIndividual, removeIndividual, activeSubtree, communicationChannel } =
    useFilters()
  const { isInitialLoading, data } = useQuery(
    [QUERY_CACHE.FANS.INDEX_BY_QUERY, { query: debouncedQuery, communicationChannel }],
    () => postFanIndexByQuery(debouncedQuery, communicationChannel),
    { enabled: Boolean(debouncedQuery.length) },
  )

  const { members } = useRecentMembers({ communicationChannel })

  const { setIsOpen } = useRecipientField()

  const addedIndividuals: string[] = useMemo(() => {
    return findSubscriberIncludesIds(activeSubtree)
  }, [individuals, activeSubtree])

  const selectIndividual = (id: string): void => {
    const filter: MemberDataFilter = {
      operator: SelectorOperators.EQUALS,
      operand: {
        field_key: BuiltInFields.SUBSCRIPTION_ID,
        field_label: fieldLabelFor(BuiltInFields.SUBSCRIPTION_ID),
        source: FieldSources.BUILT_IN,
        type: FieldTypes.UUID,
        value: id,
      },
    }

    addFilter(filter)
  }

  const deselectIndividual = (id: string): void => {
    const filter: MemberDataFilter = {
      operator: SelectorOperators.EQUALS,
      operand: {
        field_key: BuiltInFields.SUBSCRIPTION_ID,
        field_label: fieldLabelFor(BuiltInFields.SUBSCRIPTION_ID),
        source: FieldSources.BUILT_IN,
        type: FieldTypes.UUID,
        value: id,
      },
    }

    removeFilter(filter)
  }

  useEffect(() => {
    virtualRef.current?.measure()
  }, [individuals])

  function onSearchChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value)
  }

  const isDisabled = useCallback(
    (fan: Fan) => !canReceiveCampaigns({ communicationChannel, stateFlags: fan.stateFlags }),
    [communicationChannel],
  )

  const items = useMemo(() => {
    const applicableIndividuals = uniqBy(individuals, 'id').filter(
      (individuals) => individuals.communicationChannel === communicationChannel,
    )
    if (searchTerm) {
      return data
    } else if (applicableIndividuals.length > 0) {
      return applicableIndividuals
    } else {
      return members
    }
  }, [communicationChannel, data, individuals, members, searchTerm])
  const isSearchLoading = (isInitialLoading || !items) && searchTerm
  const hasRecentSuggestions = !searchTerm && !!items?.length
  const showIndividualSearch = addedIndividuals?.length < 1

  return (
    <StyledPanel>
      <StyledMeta>
        <StyledHeader>
          <StyledHeading>Individuals {addedIndividuals.length ? `(${addedIndividuals.length})` : ''}</StyledHeading>
        </StyledHeader>
        {showIndividualSearch && (
          <StyledFilters>
            <SearchBar onChange={onSearchChange} placeholder="Search Members" value={searchTerm} />
          </StyledFilters>
        )}
      </StyledMeta>
      {hasRecentSuggestions && showIndividualSearch && (
        <StyledSubHeading $topPadding>Recent Suggestions</StyledSubHeading>
      )}
      {isSearchLoading ? (
        <StyledLoadingIndicator />
      ) : items?.length ? (
        <StyledResults>
          <StyledVirtualList ref={virtualRef} rows={items} testId="member-list">
            {({ virtualRow }) => {
              const fan = items[virtualRow.index]
              const { fanSubscriptionId } = fan
              const added = addedIndividuals.includes(fanSubscriptionId)
              const name = `${fan.firstName} ${fan.lastName}`

              if (!added && !showIndividualSearch) {
                return null
              }

              return (
                <Layout position="relative">
                  <ListItem
                    as={added ? 'div' : 'button'}
                    data-testid={`member-${fanSubscriptionId}`}
                    disabled={isDisabled(fan)}
                    label={name}
                    onClick={
                      added || isDisabled(fan)
                        ? undefined
                        : () => {
                            addIndividual(fan as Fan)
                            selectIndividual(fanSubscriptionId)
                            setIsOpen(false)
                          }
                    }
                    prefix={
                      <MemberAvatar
                        badge={
                          <CommunicationChannelIcon
                            communicationChannel={communicationChannel}
                            filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))"
                            size={19}
                            style={{ marginTop: SPACING[2] }}
                          />
                        }
                        fan={fan}
                        graphicUrl={fan.graphicUrl}
                      />
                    }
                    subtext={
                      fan.stateCode
                        ? [GenderLabels[fan.gender], fan.age, `${fan.city}, ${fan.stateCode}`]
                            .filter(Boolean)
                            .join(' | ')
                        : undefined
                    }
                    suffix={
                      added ? (
                        <StyledAddSuffix>
                          <StyledButton
                            $color={COLORS?.ERRORS}
                            onClick={() => {
                              removeIndividual(fanSubscriptionId)
                              deselectIndividual(fanSubscriptionId)
                              setIsOpen(false)
                            }}
                          >
                            Remove
                          </StyledButton>
                        </StyledAddSuffix>
                      ) : undefined
                    }
                  />
                  {communicationChannel === CommunicationChannel.AMB && isDisabled(fan) ? (
                    <Tooltip content={t('convo.ambMessagingDisabled')}>
                      <StyledIconContainer aria-label="Messaging Disabled" role="img">
                        <MessageDisabledIcon size={16} />
                      </StyledIconContainer>
                    </Tooltip>
                  ) : undefined}
                </Layout>
              )
            }}
          </StyledVirtualList>
        </StyledResults>
      ) : searchTerm ? (
        <StyledNoResults>No results for "{searchTerm}"</StyledNoResults>
      ) : null}
    </StyledPanel>
  )
}
