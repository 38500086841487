import styled from 'styled-components'

export const StyledClientAvatarRoot = styled.div`
  user-select: none;
`

export const StyledClientAvatarMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  img {
    position: absolute;
    height: 100%;
    outline: none !important;
    margin: auto;
    object-fit: fill;
    transform: scale(1.02);
    transform-origin: center;
    &:not([src]) {
      content: '';
    }
  }
`

export const StyledClientAvatarIcon = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    transform: scale(0.99);
    transform-origin: center;
  }
`
