import { SmsIcon, WhatsAppIcon, AppleIcon } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { MemoizedSelectWithIcon, SelectWithIconOption, SelectWithIconProps } from '../SelectWithIcon'

import { CAPABILITIES } from 'constants/capabilities'
import { useClient } from 'hooks/useClient'
import { useHasCapability } from 'hooks/useUserCapability'

export type CommunicationChannelSelectProps = Omit<SelectWithIconProps<CommunicationChannel>, 'options'> & {
  compose?: boolean
}

export function CommunicationChannelSelect({
  value,
  compose,
  ...props
}: CommunicationChannelSelectProps): JSX.Element | null {
  const theme = useTheme()
  const { data: client } = useClient()

  const hasFeatureWhatsApp = useHasCapability(CAPABILITIES.FEATURE.WHATSAPP.ALL)
  const hasFeatureAMB = useHasCapability(CAPABILITIES.FEATURE.AMB.ALL)

  const clientCommunicationChannels = useMemo(() => {
    return client?.communicationChannels || []
  }, [client])
  const { COLORS } = theme
  const options = useMemo(
    () =>
      [
        {
          value: CommunicationChannel.SMS,
          icon: <SmsIcon color={COLORS.HEADLINES} size={18} />,
          label: 'SMS',
        },
        clientCommunicationChannels.includes(CommunicationChannel.WHATS_APP) &&
          (!compose || hasFeatureWhatsApp) && {
            value: CommunicationChannel.WHATS_APP,
            icon: <WhatsAppIcon size={18} />,
            label: 'WhatsApp',
          },
        clientCommunicationChannels.includes(CommunicationChannel.AMB) &&
          hasFeatureAMB && {
            value: CommunicationChannel.AMB,
            icon: <AppleIcon size={18} />,
            label: 'Apple Messages',
          },
      ].filter(Boolean) as SelectWithIconOption<CommunicationChannel>[],
    [COLORS.HEADLINES, clientCommunicationChannels, compose, hasFeatureAMB, hasFeatureWhatsApp],
  )

  if (options.length < 2) {
    return null
  }
  return (
    <MemoizedSelectWithIcon<CommunicationChannel>
      {...props}
      menuName="communication-channel"
      options={options}
      value={value}
    />
  )
}

export const MemoizedCommunicationChannelSelect = React.memo(CommunicationChannelSelect)
