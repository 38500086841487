import { AUDIO_EXTENSIONS, VIDEO_EXTENSIONS } from 'constants/media'

export function parseUrl(url: string): URL | null {
  const protocolCheck = /^([a-zA-Z][a-zA-Z0-9+\-.]*):\/\//

  if (!protocolCheck.test(url)) {
    return parseUrl('https://' + url)
  }

  try {
    return new URL(url)
  } catch (e) {
    return null
  }
}

export function getHost(urlString?: string | null): string {
  if (!urlString) return ''

  const url = new URL(urlString)

  return url?.hostname
}

export function getExtension(urlString?: string): string {
  if (!urlString) return ''
  let url: URL

  try {
    url = new URL(urlString)
  } catch (e) {
    return ''
  }

  const extension = url?.pathname?.split?.('.').pop() ?? ''

  return extension.trim()
}

export function isAudio(urlString = ''): boolean {
  return AUDIO_EXTENSIONS.includes(getExtension(urlString))
}

export function isVideo(urlString = ''): boolean {
  return VIDEO_EXTENSIONS.includes(getExtension(urlString))
}
