import {
  BuiltInFields,
  FieldSources,
  getFieldSource,
  getFieldKey,
  isMemberDataFilter,
  MemberDataFilter,
  FieldTypes,
  fieldLabelFor,
  SelectorOperators,
  isSelectorFilter,
  isEveryoneFilter,
  MostActiveMembersFilter,
  SelectorFilter,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { InfoIconFilled, ListItem, SPACING, Tooltip } from '@community_dev/pixels'
import { useMemo } from 'react'
import styled from 'styled-components'

import { useRecipientField } from '../RecipientFieldContext'

import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { useMostActiveMembers } from 'containers/RecipientRecommendations/useMostActiveMembers'
import analytics from 'utils/analytics'
import { pluralizeNumeral } from 'utils/general'

export type RecentFiltersFilter<F = SelectorFilter> = { filter: F } & { count: number }

export type RecentFilters = {
  suggestions?: RecentFiltersFilter<MemberDataFilter>[]
}

type ActivityProps = {
  filters?: RecentFilters['suggestions']
  onSelect: (filter: MemberDataFilter) => void
  heading: JSX.Element
}

const StyledTooltipHeader = styled.p`
  font-weight: bold;
`

const StyledTooltipMessage = styled.div`
  padding-left: ${SPACING[2]};
`

const StyledRecommendationHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledTooltipTarget = styled.span`
  margin: ${SPACING[5]} ${SPACING[4]} ${SPACING[4]};
`

const StyledPaneMain = styled.main`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  header {
    h3 {
      margin-bottom: 0;
    }
  }
`

function TooltipMessage() {
  return (
    <StyledTooltipMessage>
      <StyledTooltipHeader>How is activity calculated?</StyledTooltipHeader>
      <p>
        We are able to predict which Members are most likely to engage with your links and media. This calculation is
        based on how recently a Member joined your Community, link clicks, and media viewing.
      </p>
    </StyledTooltipMessage>
  )
}

export const Activity = ({ onSelect, filters, heading }: ActivityProps): JSX.Element | null => {
  const { hasPlaceholders } = useCompose()
  const { mostActiveMembersOptions = [], isMostActiveMembersEnabled, isLoading } = useMostActiveMembers()
  const { setIsOpen } = useRecipientField()
  const filterWithCounts: { count: number; filter: MemberDataFilter }[] = useMemo(() => {
    if (!isMostActiveMembersEnabled) return []
    return [
      ...(filters || []).filter(({ filter }) => {
        return (
          isMemberDataFilter(filter) &&
          getFieldKey(filter) === BuiltInFields.EVERYONE &&
          getFieldSource(filter) === FieldSources.BUILT_IN
        )
      }),
    ]
  }, [filters, mostActiveMembersOptions, isMostActiveMembersEnabled])

  if (!isMostActiveMembersEnabled) return null

  function handleMostActiveMembersSelection(percentage: string) {
    const filter: MostActiveMembersFilter = {
      operand: {
        type: FieldTypes.INTEGER,
        value: percentage,
        source: FieldSources.BUILT_IN,
        field_key: BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
        field_label: fieldLabelFor(BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT),
      },
      operator: SelectorOperators.LESS_THAN_OR_EQUALS,
    }

    onSelect(filter)
    analytics.track(analytics.events.ActivityFilterSelected({ percentage }))
  }

  return (
    <StyledPaneMain>
      <StyledRecommendationHeader>
        {heading}
        <Tooltip content={<TooltipMessage />} placement="bottom-end">
          <StyledTooltipTarget>
            <InfoIconFilled size={20} />
          </StyledTooltipTarget>
        </Tooltip>
      </StyledRecommendationHeader>
      {!isLoading && (
        <div data-testid="activity-recommendations">
          {filterWithCounts?.map((option, i) => {
            if (isSelectorFilter(option.filter)) {
              let label = ''
              if (isEveryoneFilter(option.filter)) {
                label = 'Everyone'
              } else {
                label = `Top ${option.filter.operand.value}% Active`
              }

              return (
                <ListItem
                  key={i}
                  label={label}
                  onClick={() => {
                    onSelect(option.filter)
                    setIsOpen(false)
                  }}
                  subtext={pluralizeNumeral(option.count.toString(), '0,0', 'Member', 'Members')}
                />
              )
            }
          })}

          {!hasPlaceholders &&
            mostActiveMembersOptions.map((option) => {
              return (
                <ListItem
                  key={option.percentage}
                  label={`Top ${option.percentage}% Active`}
                  onClick={() => {
                    handleMostActiveMembersSelection(String(option.percentage))
                    setIsOpen(false)
                  }}
                  subtext="Members"
                />
              )
            })}
        </div>
      )}
    </StyledPaneMain>
  )
}
