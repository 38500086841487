import { useSubscription } from './useSubscription'

import { useClientContexts, useSelectedClientContext } from 'hooks/useClient'

export const useShouldCollectBilling = (): { shouldCollectBilling: boolean; isLoading: boolean } => {
  const { isLoading: isContextLoading } = useClientContexts()
  const context = useSelectedClientContext()
  const { data: subscription, isSuccess, isInitialLoading: isSubscriptionLoading } = useSubscription()

  const hasSubscription = subscription && isSuccess

  const isLoading = isContextLoading || isSubscriptionLoading

  // We only want to collect billing in one scenario
  // 1. The user's isBillingActive is false and the user has a subscription and the billingProvider is stripe
  if (context && !context.isBillingActive && hasSubscription && subscription?.billingProvider === 'stripe')
    return { shouldCollectBilling: true, isLoading }

  return { shouldCollectBilling: false, isLoading }
}
