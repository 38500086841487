import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendar from 'dayjs/plugin/calendar'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

// importing dayjs directly does not load the plugins
// and it breaks typing

// timezone plugins
dayjs.extend(utc)
dayjs.extend(timezone)

// adds support for timezone abbreviations
dayjs.extend(advancedFormat)

dayjs.extend(calendar)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(minMax)
dayjs.extend(isSameOrBefore)
dayjs.extend(isoWeek)

export default dayjs
