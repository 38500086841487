export const RESTRICTED_KEYWORDS = [
  'stop',
  'remove',
  'cancel',
  'optout',
  'opt out',
  'unsubscribe',
  'end',
  'quit',
  'start',
  'go',
  'restart',
  'join',
  'unstop',
  'help',
]

export const AUTO_RESPONDER_MAX_LENGTH = 255
export const MAX_KEYWORD_RESPONDERS = 49
export const MAX_RESTRICTED_KEYWORDS = 100
