import { SPACING } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'

import { ActionPanelContext } from './ActionPanelContext'

const StyledClose = styled.button`
  margin-left: auto;
  height: 18px;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;
  right: ${SPACING[4]};
  top: ${SPACING[4]};
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  }
`

type StyledNavProps = {
  $hasBorder: boolean
  $isOpen: boolean
}

const StyledNav = styled.div<StyledNavProps>`
  display: flex;
  /* So it doesn't jump while animating */
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 ${SPACING[4]};
  padding: ${SPACING[4]} ${SPACING[4]};
  ${({ $hasBorder }) =>
    $hasBorder &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
    `};
`

const StyledBack = styled.button`
  display: flex;
  align-items: center;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};

  &:hover {
    color: ${({ theme }) => theme?.COLORS?.TEXT};

    svg {
      stroke: ${({ theme }) => theme?.COLORS?.TEXT};
    }
  }
`

const StyledLabel = styled.span`
  margin-left: ${SPACING[2]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

type StyledActionPanelProps = {
  $isOpen: boolean
}

const StyledActionPanel = styled.div<StyledActionPanelProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: ${SPACING[4]};
  overflow: ${({ $isOpen }) => ($isOpen ? 'auto' : 'hidden')};
`

type StyledContentProps = {
  $flush: boolean
}

const StyledContent = styled.div<StyledContentProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ $flush }) => ($flush ? 0 : `0 ${SPACING[4]}`)};
  width: 100%;

  > div,
  > button {
    margin-bottom: ${SPACING[4]};
  }
`

const StyledScrollableContent = styled.div`
  overflow: auto;
  flex: 1;
  display: flex;
`

type ActionPanelProps = {
  children?: React.ReactNode
  className?: string
  flush?: boolean
  name: string
  onBack?(): void
  onClose?(): void
}

const usePanelState = (onClose?: () => void) => {
  const context = useContext(ActionPanelContext)

  // this check is necessary for TypeScript to interpret the value as non-null
  if (!context) {
    throw Error('usePanelState can only be used within ActionPanelProvider')
  }

  const { closeActionPanel, isOpen, openActionPanel, portalTarget } = context

  function handleCloseActionPanel() {
    closeActionPanel()
    if (onClose) onClose()
  }

  return {
    closeActionPanel: handleCloseActionPanel,
    isOpen,
    openActionPanel,
    portalTarget,
  }
}

export const ActionPanel = ({
  children,
  className,
  flush = false,
  onBack,
  name,
  onClose,
}: ActionPanelProps): JSX.Element | null => {
  const { isOpen, portalTarget, closeActionPanel } = usePanelState(() => {
    if (onBack) onBack()
    if (onClose) onClose()
  })

  if (!portalTarget.current) return null

  return ReactDOM.createPortal(
    <StyledActionPanel $isOpen={isOpen} aria-label={`panel-${name}`} className={className}>
      <StyledNav $hasBorder={!!onBack} $isOpen={isOpen}>
        {onBack && (
          <StyledBack aria-label="Back" onClick={onBack}>
            <FontAwesomeIcon icon="chevron-left" />
            <StyledLabel>Back</StyledLabel>
          </StyledBack>
        )}
        <StyledClose aria-label="Close" onClick={closeActionPanel}>
          <FontAwesomeIcon icon="times" />
        </StyledClose>
      </StyledNav>
      <StyledScrollableContent data-testid="scrollable-content">
        <StyledContent $flush={flush}>{children}</StyledContent>
      </StyledScrollableContent>
    </StyledActionPanel>,
    portalTarget.current,
  )
}
