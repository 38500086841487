import { Api } from '@community_dev/types'
import { useMutation } from '@tanstack/react-query'
import { useState, useCallback } from 'react'

import { postCreateMedia } from 'api/media'
import { useUpload } from 'contexts/UploadProvider'
import { useClientId } from 'hooks/useClient'

type UseMediaUploadReturn = {
  uploadMedia: (file: File) => Promise<Api.V2.Media>
  isLoading: boolean
}

export function useMediaUpload(): UseMediaUploadReturn {
  const clientId = useClientId()
  const { uploadFile } = useUpload()
  const [loading, setLoading] = useState(false)

  const { mutateAsync: postCreateMediaAsync } = useMutation(postCreateMedia)

  const uploadMedia = useCallback(async (file: File) => {
    setLoading(true)

    try {
      const createMediaResult = await postCreateMediaAsync({ clientId })
      const uploadUrl = createMediaResult.upload_url
      await uploadFile({ url: uploadUrl, file })

      setLoading(false)

      return createMediaResult.media
    } catch (e) {
      setLoading(false)
      throw e
    }
  }, [])

  return {
    isLoading: loading,
    uploadMedia,
  }
}
