import { SPACING, FONT_SIZE, FONT_WEIGHT } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledRecipientItemRoot = styled.div`
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  width: 100%;
  padding: ${SPACING[2]} ${SPACING[3]};
`

export const StyledRecipientItemGraphic = styled.div`
  flex-shrink: 0;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledRecipientItemMain = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledRecipientItemDetail = styled.div`
  width: 100%;
  padding: ${SPACING[1]} ${SPACING[2]};
  overflow: hidden;
`

export const StyledRecipientItemName = styled.div`
  font-weight: 700;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

export const StyledRecipientItemOverview = styled.div`
  font-size: ${FONT_SIZE[2]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  font-weight: ${FONT_WEIGHT[5]};
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledRecipientItemAction = styled.div`
  flex-shrink: 0;
`

export const StyledRecipientItemPreviewRoot = styled(StyledRecipientItemRoot)`
  width: auto;
  padding-top: 6px;

  &:first-child {
    padding-left: ${SPACING[4]};
  }
  &:last-child {
    padding-right: ${SPACING[4]};
  }
`

export const StyledRecipientItemPreviewMain = styled(StyledRecipientItemMain)`
  position: relative;
  padding: ${SPACING[1]};
  justify-content: center;
  flex-direction: column;
  width: 52px;
`

export const StyledRecipientItemPreviewDetail = styled(StyledRecipientItemDetail)`
  width: 100%;
  padding: ${SPACING[1]} ${SPACING[0]};
`

export const StyledRecipientItemPreviewName = styled(StyledRecipientItemName)`
  width: auto;
  font-size: ${FONT_SIZE[1]};
  font-weight: ${FONT_WEIGHT[5]};
  text-align: center;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

export const StyledRecipientItemPreviewGraphic = styled(StyledRecipientItemGraphic)`
  width: 46px;
  height: 46px;
`

export const StyledRecipientItemPreviewSeeAll = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: ${({ theme }) => theme?.COLORS?.TEXT};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`
