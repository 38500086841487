import { BUTTON_VARIANTS, Box, Button, SPACING, TextInput, Toast, required, Typography } from '@community_dev/pixels'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Redirect, useHistory } from 'react-router'
import styled from 'styled-components'

import { ONBOARDING_TASKS, ONBOARDING_TASK_COMPLETE } from '../constants'
import { useCompleteClientTask, useUpdateName } from '../queries'

import { ROUTES } from 'constants/routes'
import { useClient } from 'hooks/useClient'
import Sentry from 'integrations/Sentry'
import texts from 'texts.json'

const StyledContent = styled.div`
  width: 100%;
  max-width: 332px;
  margin: 0 auto 22px;
`

const StyledDescription = styled.p`
  margin: ${SPACING[4]} 0;
`

const StyledForm = styled.form`
  input:invalid,
  input:required {
    box-shadow: none;
  }
`

const StyledFields = styled.div`
  text-align: left;
`

export function Name(): JSX.Element {
  const history = useHistory()
  const { data: client, isLoading } = useClient()
  const task = client?.onboardingTasks?.tasks[ONBOARDING_TASKS.UPDATE_NAME]
  const [error, setError] = useState<string>()
  const { mutate: updateTask } = useCompleteClientTask({
    onError(e) {
      setError(texts.errors.generic)
      Sentry.captureException(e)
    },
    onSuccess() {
      history.push(ROUTES.ONBOARDING.SUMMARY)
    },
  })
  const { mutateAsync: submit } = useUpdateName({
    onError(e) {
      setError(texts.errors.generic)
      Sentry.captureException(e)
    },
    onSuccess() {
      updateTask({ id: task!.id })
    },
  })

  function handleSubmit(body) {
    setError(undefined)
    submit({ body, id: client!.id })
  }

  if (!isLoading && (!task || task.status === ONBOARDING_TASK_COMPLETE)) {
    return <Redirect to={ROUTES.ONBOARDING.SUMMARY} />
  }

  return (
    <Box>
      {error && (
        <Box.ToastArea>
          <Toast icon message={error} success={false} />
        </Box.ToastArea>
      )}
      <Box.Content>
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit, valid, submitting }) => (
            <>
              <StyledForm onSubmit={handleSubmit}>
                <StyledContent>
                  <Typography variant="h1">
                    What is the name you'd like displayed on your Community contact card?
                  </Typography>
                  <StyledDescription>Enter the name you want saved in your audience’s contact book.</StyledDescription>
                  <StyledFields>
                    <Field name="first_name" validate={required} validateOnBlur>
                      {({ input, meta }) => (
                        <TextInput
                          error={(!meta.active && meta.touched && meta.modified && meta.error) || ''}
                          label="Name"
                          placeholder="e.g. Joe's Coffee Shop"
                          required
                          type="text"
                          {...input}
                          autoFocus
                        />
                      )}
                    </Field>
                  </StyledFields>
                </StyledContent>
                <Button disabled={!valid || submitting} type="submit" variant={BUTTON_VARIANTS.ACTION} width="100%">
                  {submitting ? 'Saving' : 'Next'}
                </Button>
              </StyledForm>
            </>
          )}
        />
      </Box.Content>
    </Box>
  )
}
