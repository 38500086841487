import { FILTER_PREDICATES } from '@community_dev/filter-dsl/lib/digits'
import { Api } from '@community_dev/types'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { CampaignStatus } from '@community_dev/types/lib/api/v2/Campaign'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { getCampaignList } from 'api/campaign'
import { getMatchingClusters } from 'api/cluster'
import { useClientId } from 'hooks/useClient'

export const useTrendingResponse = (): UseQueryResult<any, any> => {
  const clientId = useClientId()

  const getMostRecentSentCampaignNormal = () =>
    getCampaignList({ clientId, pageParam: 1, pageSize: 1 }).then((r) => r.data?.[0])

  const getMostRecentSentCampaignPatched = () => Promise.resolve(SENT_CAMPAIGN_THAT_WILL_TRIGGER_UI_TO_SHOW)
  const getUnrepliedClustersForCampaign = (campaignId) =>
    getMatchingClusters({
      clientId,
      campaignId,
      tagsAll: '',
      tagsNot: 'replied-to',
    }).then((r) => r.data)

  // You must be on the account "Community Demo Test Account" in dev for this override to work.
  const getMostRecentSentCampaign = window.location.search.includes('forceTrending=true')
    ? getMostRecentSentCampaignPatched
    : getMostRecentSentCampaignNormal

  // part of the project envelope for this "experiment" was that we didn't want
  // to add any new backend endpoints. Therefore, in order to get the info
  // I need, we have to do hit a couple of endpoints in sequence to fetch the
  // most recent campaign, and then grab the clusters for the most recent
  // campaign.
  const getTrending = async () => {
    const mostRecentSentCampaign = await getMostRecentSentCampaign()
    if (!mostRecentSentCampaign) {
      return null
    }
    let sentCampaign = mostRecentSentCampaign
    if (
      sentCampaign?.text &&
      sentCampaign?.placeholders &&
      sentCampaign.placeholders.length > 0 &&
      sentCampaign.placeholders.some((p) => sentCampaign.text?.includes(`{${p.key}:${p.source}}`))
    ) {
      const matchingPlaceholders = sentCampaign.placeholders.filter((p) =>
        sentCampaign.text?.includes(`{${p.key}:${p.source}}`),
      )
      if (matchingPlaceholders.length > 0) {
        sentCampaign = {
          ...sentCampaign,
          text: matchingPlaceholders.reduce(
            (t, ph) => t.replace(`{${ph.key}:${ph.source}}`, `{${ph.name}}`),
            sentCampaign.text,
          ),
        }
      }
    }
    const unrepliedClusters = await getUnrepliedClustersForCampaign(sentCampaign.id)
    if (!(unrepliedClusters.length > 0)) {
      return null
    }
    // unrepliedClusters is returned with the largest cluster first, thus
    // the [0] element will always be either our trending cluster length > 0
    return { sentCampaign, cluster: unrepliedClusters[0] }
  }
  const trendingQuery = useQuery<any, any>(['experiments-trending-responses'], getTrending, {
    cacheTime: 0,
    staleTime: 0,
  })

  return trendingQuery
}

// You must be on the account "Community Demo Test Account" in dev for this to work.
const SENT_CAMPAIGN_THAT_WILL_TRIGGER_UI_TO_SHOW: CamelCasedPropertiesDeep<Api.V2.Campaign> = {
  clientUrls: ['https://www.community.com'],
  communicationChannel: CommunicationChannel.SMS,
  contentReview: null,
  createdAt: '2021-04-01T16:09:00Z',
  fanSubscriptionTags: {
    any: [],
    not: [],
  },
  filters: {
    insertedAt: {
      predicate: FILTER_PREDICATES.LESS_THAN,
      value: '2021-04-01T16:09:00.470702Z',
    },
    receivedMessage: {
      predicate: FILTER_PREDICATES.EQUALS,
      value: 'false',
    },
  },
  id: '3ab3a086-a9df-4b2d-bfd4-1138fa584a12',
  linkInfo: [],
  media: null,
  name: null,
  nestLevel: 0,
  parentCampaign: null,
  parentSmsCampaignId: null,
  placeholders: [],
  recipientCount: 99,
  recurrence: null,
  replies: [],
  representativeMessage: null,
  responseInfo: {
    responseCount: 0,
    responseRate: 0,
  },
  responseMetadata: {
    categories: [],
    defaultCategory: {
      count: 0,
      displayName: '',
      id: 0,
      name: '',
      type: '',
    },
    responseDataViewable: false,
    supportsFollowUps: false,
  },
  scheduledAt: null,
  sendMechanism: 'hermes',
  status: CampaignStatus.PROCESSED,
  stopInfo: {
    stopCount: 0,
    stopRate: 0,
  },
  tags: [],
  text: 'https://www.community.com hey! how’s it going? hope you all are staying safe and healthy.\n\n',
  testCampaign: false,
  threadId: '',
}
