import { BUTTON_VARIANTS, Button, CommunityFlagIcon, SPACING } from '@community_dev/pixels'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { useSelectedClientContext, useSwitchClient } from 'hooks/useClient'
import SimpleLayout from 'layouts/SimpleLayout'
import { baseConfig } from 'utils/config'

const StyledContainer = styled.div`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  flex: 1;
  align-self: stretch;
  justify-content: center;
  padding: 0 ${SPACING[3]};
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledHeader = styled.h1`
  margin-top: ${SPACING[2]};
  margin-bottom: ${SPACING[4]};
`

const StyledBody = styled.div`
  margin-bottom: ${SPACING[8]};
`

const StyledIcon = styled.a`
  display: inline-block;
  background: radial-gradient(
    135% 135% at 135% 135%,
    #f6f9fc 0%,
    ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3} 100%
  );
  position: relative;
  margin: ${SPACING[4]};
  margin-top: ${SPACING[9]};
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.DEPRECATED_RECEIVED}`};
  box-shadow: 0px 4px 12px rgba(65, 75, 88, 0.24);
  border-radius: 30px;

  svg {
    vertical-align: top;
  }
`

const StyledDashboardUrl = styled(Link)`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  color: ${({ theme }) => theme?.COLORS?.DEPRECATED_SENT};
  text-decoration: none;
  margin-top: ${SPACING[5]};
`

export const GetTheAppScreen = (): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const switchClient = useSwitchClient()
  const context = useSelectedClientContext()
  const hasApp = false

  // We want to do a full redirect when the page has come from the payment form
  // This way it will get the updated context and unlock the billing redirect
  return (
    <SimpleLayout>
      <StyledContainer>
        <StyledContent>
          <StyledIcon>
            <CommunityFlagIcon color={COLORS?.TEXT} size={104} />
          </StyledIcon>
          <StyledHeader>Welcome to Community</StyledHeader>
          <StyledBody>
            Download the mobile app on the Apple App Store, or head to the Web App to get started.
          </StyledBody>
          <Button forwardedAs="a" href={baseConfig.appStoreUrl} variant={BUTTON_VARIANTS.ACTION}>
            {hasApp ? 'Open the app' : 'Get the iOS app'}
          </Button>
          <StyledDashboardUrl onClick={() => context?.clientId && switchClient(context.clientId)} to="/">
            Don’t have an iPhone? Go to our Web App &#8594;
          </StyledDashboardUrl>
        </StyledContent>
      </StyledContainer>
    </SimpleLayout>
  )
}
