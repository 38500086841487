export type SentimentFilter = {
  descriptor: string
  title: string
  value: string | null
}

export const SENTIMENT_FILTERS = [
  {
    descriptor: 'all messages',
    title: 'All',
    value: null,
  },
  {
    descriptor: 'messages containing questions',
    title: 'Questions',
    value: 'question',
  },
  {
    descriptor: 'messages containing thank you',
    title: 'Thanks',
    value: 'thank-you',
  },
  {
    descriptor: 'loving messages',
    title: 'Loving',
    value: 'loving',
  },
  {
    descriptor: 'happy messages',
    title: 'Happy',
    value: 'happy',
  },
  {
    descriptor: 'messages with an affirmative response',
    title: 'Yes',
    value: 'yes',
  },
  {
    descriptor: 'messages with an adverse response',
    title: 'No',
    value: 'no',
  },
] as const
