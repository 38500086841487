import semver from 'semver'

export function isAppVersionSupported(
  metadata: {
    minimumVersion: string
    blacklistedVersions: any[]
  },
  currentVersion = import.meta.env.VITE_APP_VERSION,
): boolean {
  try {
    const { minimumVersion = '0.0.1', blacklistedVersions = [] } = metadata
    const blacklist = blacklistedVersions.map((v) => semver.coerce(v))
    const minimum = semver.coerce(minimumVersion)
    const current = semver.coerce(currentVersion)
    if (blacklist.some((blacklisted) => semver.eq(blacklisted || '', current || ''))) {
      return false
    } else if (semver.lt(currentVersion, minimum || '0.0.1')) {
      return false
    } else {
      return true
    }
  } catch (ex) {
    return true
  }
}
