import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query'

import {
  AddCommunityFanProps,
  AddCommunityFansProps,
  RemoveCommunityFanProps,
  addCommunityFan,
  addCommunityFans,
  deleteCommunityFan,
} from 'api/community'
import { COMMUNITY_SORT_BY_MEMBER_COUNT } from 'constants/launch-darkly-flags'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { useInvalidateCommunityCount } from 'hooks/useCountByQuery/useCommunityCount'
import { userHasFlag } from 'hooks/useLaunchDarkly'
import { useToastMessage } from 'hooks/useToastMessage'

export function useRemoveFanFromCommunity(): {
  removeFan: UseMutateAsyncFunction<{ message: string; job_id: string }, any, Omit<RemoveCommunityFanProps, 'clientId'>>
  isLoading: boolean
} {
  const clientId = useClientId()
  const queryClient = useQueryClient()
  const { showToastMessage } = useToastMessage()

  const { mutateAsync: removeFan, isLoading } = useMutation(
    (removeFanProps: Omit<RemoveCommunityFanProps, 'clientId'>) => deleteCommunityFan({ clientId, ...removeFanProps }),
    {
      onError(_, { community, fan }) {
        showToastMessage({
          message: `Could not remove ${fan?.firstName} from ${community.title}`,
          success: false,
        })
      },
      onSuccess(_, { community, fan }) {
        showToastMessage({
          message: `${fan?.firstName} has been removed from ${community.title}`,
        })
        queryClient.invalidateQueries([QUERY_CACHE.FAN, fan?.id])
      },
    },
  )
  return { removeFan, isLoading }
}

export function useAddFanToCommunity(): {
  addFan: UseMutateAsyncFunction<string, any, AddCommunityFanProps>
  isLoading: boolean
} {
  const queryClient = useQueryClient()
  const { showToastMessage } = useToastMessage()

  const { mutateAsync: addFan, isLoading } = useMutation(addCommunityFan, {
    onError(_, { fan, community }) {
      showToastMessage({
        success: false,
        message: `Could not add ${fan?.firstName} from ${community.title}`,
      })
    },
    onSuccess(_, { fan, community }) {
      showToastMessage({
        message: `${fan?.firstName} has been added to ${community.title}`,
      })
      queryClient.invalidateQueries([QUERY_CACHE.FAN, fan?.id])
      // Invalidates the /communities/:id page
      // When we have the Members selected from dropdown (Not the Messages)
      queryClient.invalidateQueries([QUERY_CACHE.AUDIENCE.INDEX_BY_QUERY, { tags: [community.id] }])
    },
  })

  return { addFan, isLoading }
}

export function useAddFansToCommunity(): {
  addFans: UseMutateAsyncFunction<string, any, AddCommunityFansProps>
  isLoading: boolean
} {
  const queryClient = useQueryClient()
  const { showToastMessage } = useToastMessage()
  const isSortByMemberCountEnabled = userHasFlag(COMMUNITY_SORT_BY_MEMBER_COUNT)
  const invalidateCommunityMemberCount = useInvalidateCommunityCount()
  const { mutateAsync: addFans, isLoading } = useMutation(addCommunityFans, {
    onError(_, { fans, community }) {
      showToastMessage({
        success: false,
        message: `Could not add ${fans.length} fans to ${community.title}`,
      })
    },
    onSuccess(_, { fans, community }) {
      fans.forEach((fan) => queryClient.invalidateQueries([QUERY_CACHE.FAN, fan?.id]))
      queryClient.invalidateQueries([QUERY_CACHE.TAGS, community?.id])
      if (isSortByMemberCountEnabled) {
        queryClient.invalidateQueries([QUERY_CACHE.TAGS])
      } else {
        invalidateCommunityMemberCount(community.id, community.title)
      }
      queryClient.invalidateQueries([QUERY_CACHE.AUDIENCE.INDEX_BY_QUERY, { tags: [community.id] }])
    },
  })

  return { addFans, isLoading }
}
