import { ChevronRightIcon, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import sortBy from 'lodash/sortBy'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { ROUTES } from 'constants/routes'
import { useClient } from 'hooks/useClient'
import { HorizontalBarChart } from 'screens/InsightsScreen/charts/HorizontalBarChart'
import { InsightsBox } from 'screens/InsightsScreen/presentation/InsightsBox'
import { UnitDisplay, formatUnits } from 'screens/InsightsScreen/UnitDisplay'
import { useUnitsPreference } from 'screens/InsightsScreen/use-units-preference'
import { formatNumeral } from 'utils/general'

const TABS = {
  msa: 'Cities',
  state: 'States',
}

const INTERNATIONAL_TABS = {
  country: 'Countries',
  msa: 'US/CA Cities',
  state: 'US States',
}

const computeBars = (data, selected, limit, unitsPreference) => {
  const filteredData = data[selected === 'country' ? 'countries' : `${selected}s`].filter(
    (loc) => loc.state !== 'other-international',
  )

  const totalFans = data.total_fans

  const sorted = sortBy(filteredData, (s) => -s.count)

  const topBars = limit > 0 ? sorted.slice(0, 6) : sorted

  const bars = topBars.map((s) => ({
    label: s.display_name,
    tooltip: `${s.display_name} (${formatUnits({
      count: s.count,
      total: totalFans,
      unitsPreference,
    })})`,
    value: (s.count / Math.max(totalFans, 1)) * 100,
    valueLabel: <UnitDisplay.Animated count={s.count} height="22px" total={totalFans} />,
  }))

  return bars
}

const Footer = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  text-decoration: none;
  &:hover,
  &:visited,
  &:active {
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  }
  transition: background 100ms ease;
  &:hover {
    background: rgba(30, 144, 255, 0.08);
  }
  &:active {
    background: rgba(30, 144, 255, 0.12);
  }
  &:before {
    content: '';
    display: block;
    height: 1px;
    width: calc(100% - 32px);
    position: absolute;
    top: 0;
    background: ${({ theme }) => theme?.COLORS?.BORDERS};
    z-index: 1;
  }
`

type LocationChartBoxProps = {
  demographicInsights: any
  limit?: number
  footer?: boolean
  height?: string
  locked?: boolean
}

export const LocationChartBox = ({
  demographicInsights,
  limit = 6,
  footer = true,
  height,
  locked,
}: LocationChartBoxProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const [selected, setSelected] = useState('msa')
  const { unitsPreference } = useUnitsPreference()
  const { t } = useTranslation()
  const { data: client } = useClient()

  const queries = [demographicInsights.msaQuery, demographicInsights.stateQuery]

  const tabs = (
    <InsightsBox.Tabs
      onChange={setSelected}
      selected={selected}
      tabs={client?.communicationChannels.includes(CommunicationChannel.WHATS_APP) ? INTERNATIONAL_TABS : TABS}
    />
  )

  const data = demographicInsights?.[`${selected}Query`]?.data?.[`${selected}_insights`]?.[0]

  const hasData = data?.total_fans > 0
  const hasSpill = data?.[selected === 'country' ? 'countries' : `${selected}s`]?.length > limit
  const totalFansText = formatNumeral(data?.total_fans)

  const infoText = t('generalInsights.infoText', { total: totalFansText, type: 'location' })

  const bars = useMemo(() => {
    return data && computeBars(data, selected, limit, unitsPreference)
  }, [data, selected, limit, unitsPreference])

  return (
    <InsightsBox.Managed
      height={height}
      infoText={infoText}
      isEmpty={!hasData}
      locked={locked}
      queries={queries}
      tabs={tabs}
      title="Top Locations"
    >
      {() => (
        <>
          <div style={{ marginTop: SPACING[5] }}>
            <HorizontalBarChart bars={bars} chartType={selected} />
          </div>

          {hasSpill && footer && !locked && (
            <Footer to={ROUTES.INSIGHTS.LOCATION_DETAIL}>
              <span>More locations</span>
              <ChevronRightIcon color={COLORS?.SUBTEXT} size={20} />
            </Footer>
          )}
        </>
      )}
    </InsightsBox.Managed>
  )
}
