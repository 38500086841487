import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const createOrFetchContainer = () => {
  if (document.getElementById('debug-viewer-container')) {
    return document.getElementById('debug-viewer-container')
  }
  const elem = document.createElement('div')
  elem.id = 'debug-viewer-container'
  elem.style.position = 'absolute'
  elem.style.right = '48px'
  elem.style.bottom = '0px'
  elem.style.display = 'flex'
  elem.style.alignItems = 'flex-end'
  document.body.appendChild(elem)
  return elem
}

type DebugModalProps = {
  children: React.ReactNode
}

export const DebugModal = ({ children }: DebugModalProps): JSX.Element | null => {
  const [portalTarget, setPortalTarget] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const elem = createOrFetchContainer()
    if (elem) setPortalTarget(elem)

    return () => {
      setPortalTarget(null)
    }
  }, [])

  return portalTarget && ReactDOM.createPortal(children, portalTarget)
}

const DebugBox = styled.div<{ $expanded: boolean }>`
  ${({ $expanded }) => ($expanded ? 'width: 420px;' : 'width: 250px;')}
  background: #222;
  color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4), 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  margin-right: 8px;
`

const Heading = styled.div`
  background: hsl(0, 0%, 40%);
  border-bottom: 1px solid #101010;
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
`

const Content = styled.pre`
  height: 400px;
  overflow-y: auto;
  margin: 0 0;
  padding: 15px;
  font-size: 12px;
`

const Minimize = styled.div<{ $expanded: boolean }>`
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background: hsl(0, 0%, 50%);
  }
  &:before {
    content: ' ';
    display: block;
    height: 12px;
    width: 12px;
    margin: 4px;
    ${({ $expanded }) => ($expanded ? 'border-bottom: 2px solid #FFF;' : 'border-top: 2px solid #FFF;')}
  }
`

type DebugViewerProps = {
  title: string
  debugValue: any
  children?: React.ReactNode
}

export const DebugViewer = ({ title, debugValue, children }: DebugViewerProps): JSX.Element => {
  const [expanded, setExpanded] = useState(localStorage.getItem(`debug-${title}`) === 'true')

  const toggleExpanded = () => setExpanded(!expanded)

  useEffect(() => {
    localStorage.setItem(`debug-${title}`, expanded.toString())
  }, [title, expanded])

  return (
    <DebugModal>
      <DebugBox $expanded={expanded} onClick={(e) => e.stopPropagation()}>
        <Heading onClick={toggleExpanded}>
          {title} <Minimize $expanded={expanded} />
        </Heading>
        {expanded &&
          (children || (
            <Content>
              <code>{JSON.stringify(debugValue, null, '  ')}</code>
            </Content>
          ))}
      </DebugBox>
    </DebugModal>
  )
}
