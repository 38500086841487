import { BUTTON_VARIANTS, Button, SPACING, TextInput, composeValidators, email, required } from '@community_dev/pixels'
import { ApiError } from '@community_dev/requests'
import { useMutation } from '@tanstack/react-query'
import isUndefined from 'lodash/isUndefined'
import pickBy from 'lodash/pickBy'
import React, { ReactNode, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'

import { putNewSeat } from 'api/login'
import { Notif } from 'components/base'
import { BoxForm } from 'components/form'
import PasswordVerification from 'components/input/PasswordInput/PasswordVerification'
import { INVITATION_DELIVERY } from 'constants/invites'
import { ROUTES } from 'constants/routes'
import { MOBILE_BREAK, TABLET_BREAK } from 'constants/theme'
import { AuthActionTypes } from 'contexts/AuthProvider'
import { useAuth } from 'hooks/useAuth'
import { isAppleMobile } from 'utils/detectors'
import { humanizeInviteError } from 'utils/errors'
import notifs from 'utils/notifs'
import { getQueryParams } from 'utils/router'
import validatePassword from 'utils/validatePassword'

const password = (value = '') => {
  const { valid }: any = validatePassword(value)
  if (value.trim() !== value) return notifs.passwordSpace
  return valid ? undefined : 'Invalid password'
}

const StyledContainer = styled.div`
  width: 100%;
  align-items: center;

  @media (max-width: ${MOBILE_BREAK}) {
    margin-top: -${SPACING[5]};
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const StyledTitle = styled.h1`
  text-align: center;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H1};
  width: 100%;

  @media (max-width: ${TABLET_BREAK}) {
    text-align: left;
    max-width: 464px;
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  }

  @media (max-width: ${MOBILE_BREAK}) {
    max-width: 350px;
  }
`

const StyledCopy = styled.h4`
  text-align: center;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};

  @media (max-width: ${TABLET_BREAK}) {
    text-align: left;
    max-width: 464px;
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  }

  @media (max-width: ${MOBILE_BREAK}) {
    max-width: 350px;
  }
`

const StyledLegalText = styled.div`
  width: 100%;
  text-align: center;
  padding: ${SPACING[4]} ${SPACING[5]} ${SPACING[4]} 0;

  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION2};

  a {
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  }

  @media (max-width: ${MOBILE_BREAK}) {
    padding: 0;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

type NewInvitationFormProps = {
  error?: ReactNode
  firstName?: string
  lastName?: string
  notif?: any
  leaderImage?: string
  leaderName?: string
  subtitleText?: string
  token: string
  email?: string
  isInviteTokenValid: boolean
}

export function NewInvitationForm({
  error,
  leaderName,
  isInviteTokenValid,
  notif: propNotif,
  token,
  email: seatEmail,
}: NewInvitationFormProps): JSX.Element {
  const { t } = useTranslation()
  const { login, transition } = useAuth()
  const history = useHistory()
  const [notif, setNotif] = useState(propNotif)
  const { search } = useLocation()
  const [submitError, setSubmitError] = useState<ApiError>()
  const [processing, setProcessing] = useState(false)
  const { delivery } = getQueryParams(search)
  const { mutate: submit } = useMutation(putNewSeat, {
    async onError(err: ApiError, { data: submittedValues }) {
      setProcessing(false)
      if (err.status === 409) {
        login(seatEmail || submittedValues.email, submittedValues.password)
      } else {
        setSubmitError(err)
      }
    },
    onSuccess({ token, id }) {
      setProcessing(false)
      transition({
        type: AuthActionTypes.LOGIN_SUCCESS,
        payload: { token, clientId: id },
      })
      if (isAppleMobile(navigator.platform)) {
        history.replace(ROUTES.ONBOARDING.GET_THE_APP)
      }
    },
  })

  function handleSubmit(values) {
    const { firstName, lastName, password, email } = values

    const data = pickBy(
      { first_name: firstName, last_name: lastName, password, email, delivery },
      (i) => !isUndefined(i),
    )

    setProcessing(true)
    submit({ data, token })
  }

  function onChangePassword(obj) {
    let notif = {}
    const { password } = obj
    const validations: any = validatePassword(password)
    if (password.length && !validations.space) notif = notifs.passwordSpace
    setNotif(notif)
  }

  return (
    <>
      {notif?.body && <Notif {...notif} />}
      <StyledContainer>
        {isInviteTokenValid && (
          <>
            <StyledTitle>{t('invitationForm.title', { accountName: leaderName })}</StyledTitle>
            <StyledCopy>{t('invitationForm.copy', { accountName: leaderName })}</StyledCopy>
          </>
        )}
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit, valid, pristine }) => (
            <BoxForm error={error || (submitError && humanizeInviteError(submitError))} onSubmit={handleSubmit}>
              <Field name="firstName" validate={required} validateOnBlur>
                {({ input, meta }) => (
                  <TextInput
                    autoComplete="given-name"
                    autoFocus={isInviteTokenValid}
                    disabled={!isInviteTokenValid}
                    error={(!meta.active && meta.touched && meta.modified && meta.error) || ''}
                    label={t('invitationForm.labels.firstName')}
                    type="text"
                    {...input}
                  />
                )}
              </Field>

              <Field name="lastName" validate={required} validateOnBlur>
                {({ input, meta }) => (
                  <TextInput
                    autoComplete="family-name"
                    disabled={!isInviteTokenValid}
                    error={(!meta.active && meta.touched && meta.modified && meta.error) || ''}
                    label={t('invitationForm.labels.lastName')}
                    type="text"
                    {...input}
                  />
                )}
              </Field>

              {delivery === INVITATION_DELIVERY.SMS && (
                <Field name="email" validate={composeValidators(required, email)} validateOnBlur>
                  {({ input, meta }) => (
                    <TextInput
                      autoComplete="email"
                      disabled={!isInviteTokenValid}
                      error={(!meta.active && meta.touched && meta.modified && meta.error) || ''}
                      label={t('invitationForm.labels.email')}
                      type="email"
                      {...input}
                    />
                  )}
                </Field>
              )}

              <Field name="password" validate={password} validateOnBlur>
                {({ input, meta }) => (
                  <PasswordVerification
                    disabled={!isInviteTokenValid}
                    id="password"
                    labelText={t('invitationForm.labels.createPassword')}
                    {...input}
                    error={(!meta.active && meta.touched && meta.modified && meta.error) || ''}
                    onChange={({ password, validations }) => {
                      onChangePassword({ password, validations })
                      input.onChange({ target: { value: password } })
                    }}
                  />
                )}
              </Field>

              <StyledLegalText>
                {'By clicking "Sign up", you agree to our  '}
                <a href="https://www.community.com/client-terms" rel="noopener noreferrer" target="_blank">
                  Terms of Use
                </a>
                <span> and </span>
                <a href="https://www.community.com/privacy" rel="noopener noreferrer" target="_blank">
                  Privacy Policy
                </a>
              </StyledLegalText>
              <StyledButtonContainer>
                <Button
                  disabled={!valid || processing || pristine || !isInviteTokenValid}
                  type="submit"
                  variant={BUTTON_VARIANTS.ACTION}
                  width="100%"
                >
                  {t('invitationForm.labels.signUp')}
                </Button>
              </StyledButtonContainer>
            </BoxForm>
          )}
        />
      </StyledContainer>
    </>
  )
}

NewInvitationForm.defaultProps = {
  firstName: '',
  lastName: '',
  notif: {},
  leaderImage: '',
  leaderName: '',
  subtitleText: '',
}
