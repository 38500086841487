import Communities from 'containers/Communities'
import { SidebarLayout } from 'layouts/SidebarLayout'

function CommunitiesScreen(): JSX.Element {
  return (
    <SidebarLayout>
      <Communities />
    </SidebarLayout>
  )
}

export default CommunitiesScreen
