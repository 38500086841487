import {
  CloseIcon,
  FONT_SIZE,
  Layout,
  Modal,
  SPACING,
  Select,
  TextInput,
  TextInputVariant,
  Typography,
} from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledWebhookItem = styled(Layout)`
  align-items: center;
  display: flex;
  padding: ${SPACING[5]} 0;
  gap: ${SPACING[4]};
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.BORDERS};
  :last-child {
    border-bottom: 0;
  }
`

export const StyledCaption = styled(Typography).attrs({ variant: 'caption1', component: 'p' })`
  margin: 0;
  text-align: left;
  display: block;
`

export const StyledModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.COLORS.BORDERS};
  margin: 0;
`

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`

export const StyledError = styled(Typography).attrs({ variant: 'caption2' })`
  color: ${({ theme }) => theme.COLORS.ERRORS};
  display: block;
  text-align: left;
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme?.COLORS.LINKS};
`

export const StyledSelect = styled(Select)<{ width?: string; marginLeft?: string }>`
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  margin-right: ${SPACING[2]};
  margin-bottom: ${SPACING[3]};
  width: 100%;
  .control {
    border-radius: 4px;
    border-color: ${({ theme }) => theme.COLORS.BORDERS};
  }
  .value-container {
    padding: ${SPACING[2]};
    gap: ${SPACING[1]};
  }
`
export const StyledTextInput = styled(TextInput).attrs({ variant: TextInputVariant.OUTLINED })`
  font-size: ${FONT_SIZE[3]};
  padding: 11px;
  width: inherit;
  margin-bottom: -${SPACING[1]};
`
