import { SPACING, ComposeCircleIcon, Layout } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { ScheduledTabView } from './constants'

import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { useClient } from 'hooks/useClient'
import { useHasCapability } from 'hooks/useUserCapability'

const StyledNavLink = styled(NavLink)`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  text-decoration: none;
  margin-right: ${SPACING[5]};
  padding-bottom: ${SPACING[2]};
  border-bottom: 2px solid transparent;

  &.active {
    color: ${({ theme }) => theme?.COLORS?.TEXT};
    border-bottom: 2px solid ${({ theme }) => theme?.COLORS?.TEXT};
    font-weight: 500;
  }
`

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${SPACING[5]} ${SPACING[4]} 0;
  border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`
const StyledHeading = styled.h1`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
`

const StyledCompose = styled.button`
  align-self: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  margin-left: ${SPACING[3]};

  &:disabled {
    cursor: auto;
    svg {
      path:first-child {
        fill: ${({ theme }) => theme?.COLORS?.BUTTON_DISABLED};
      }
    }
  }
`

export const ScheduledHeader = (): JSX.Element => {
  const { data: client } = useClient()
  // We disable the ability to open compose if the client has no communication channels
  const isOpenComposeDisabled = !client?.communicationChannels?.length

  const { setComposeModalOpen, scheduledAt, toggleScheduled } = useCompose()
  const { t } = useTranslation()

  const hasCampaignCalendarFeature = useHasCapability(CAPABILITIES.FEATURE.CAMPAIGN_SCHEDULING.ALL)
  function openComposeModal() {
    setComposeModalOpen(true)

    if (!scheduledAt) {
      toggleScheduled()
    }
  }

  return (
    <StyledHeader>
      <Layout display="flex">
        <StyledHeading>{t('scheduled.title')}</StyledHeading>
        <StyledCompose
          aria-label={t('compose.composeMessage')}
          disabled={isOpenComposeDisabled}
          onClick={openComposeModal}
        >
          <ComposeCircleIcon size={32} />
        </StyledCompose>
      </Layout>
      {hasCampaignCalendarFeature && (
        <Layout display="flex">
          <StyledNavLink to={route(ROUTES.SCHEDULED.VIEW, { view: ScheduledTabView.MONTH })}>
            {t('scheduled.month')}
          </StyledNavLink>
          <StyledNavLink to={route(ROUTES.SCHEDULED.VIEW, { view: ScheduledTabView.LIST })}>
            {t('scheduled.list')}
          </StyledNavLink>
        </Layout>
      )}
    </StyledHeader>
  )
}
