import { ModalGuidedTour, OverlayMessage } from '@community_dev/pixels'
import { useState } from 'react'

import { CopyFooter } from '../components/CopyFooter'
import { SlideWithImage } from '../components/SlideWithImage'

import { useClient } from 'hooks/useClient'
import { baseConfig, generalConfig } from 'utils/config'

const SLIDES = {
  SHARE_YOUR_NUMBER: {
    HEADING: 'Share your community URL',
    BODY: `Make it easier for people to text you by sharing your Community URL or QR code. It will take them to a new text message that's already directed straight\u00A0to\u00A0you.`,
    SRC: `${generalConfig.staticUrl}/product-education/community-url.gif`,
    ALT: 'Share your community URL or QR code',
  },
  GET_CREATIVE: {
    HEADING: 'Get creative',
    BODY: 'Add your Community URL as a link anywhere on your website so your audience can click and text your Community Number.',
    SRC: [
      `${generalConfig.staticUrl}/product-education/lewis-howes-tap-to-text-button%403x.png 3x`,
      `${generalConfig.staticUrl}/product-education/lewis-howes-tap-to-text-button%402x.png 2x`,
      `${generalConfig.staticUrl}/product-education/lewis-howes-tap-to-text-button.png 1x`,
    ],
    ALT: 'Lewis Howes tap to text button on website',
  },
  SWIPE_UP_LINK: {
    HEADING: 'Swipe up link',
    BODY: 'Use your Community URL as your tap-to-text link on social media so your audience can text you right then and there.',
    SRC: [
      `${generalConfig.staticUrl}/product-education/instagram-tap-link-chance%403x.png 3x`,
      `${generalConfig.staticUrl}/product-education/instagram-tap-link-chance%402x.png 2x`,
      `${generalConfig.staticUrl}/product-education/instagram-tap-link-chance.png 1x`,
    ],
    ALT: 'Chance the Rapper Instagram swipe up link',
  },
  COPY_PASTE: {
    HEADING: 'Include in your bio',
    BODY: 'Share your Community URL in your online bios and content so your audience can text and add your Community Number as a contact.',
    SRC: [
      `${generalConfig.staticUrl}/product-education/731-woodworks-youtube-description-box%403x.png 3x`,
      `${generalConfig.staticUrl}/product-education/731-woodworks-youtube-description-box%402x.png 2x`,
      `${generalConfig.staticUrl}/product-education/731-woodworks-youtube-description-box.png 1x`,
    ],
    ALT: '731 Woodworks Youtube number promotion in description box',
    COPY_BUTTON_LABEL: 'Copy my Community URL',
    COPY_OVERLAY_LABEL: 'URL copied',
  },
}

type ShareYourNumberModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const ShareYourCommunityModal = ({ isOpen, onClose }: ShareYourNumberModalProps): JSX.Element => {
  const { data: client } = useClient()
  const { urlSlug } = client || { urlSlug: '' }
  const [isDisplayed, setIsDisplayed] = useState(false)

  return (
    <ModalGuidedTour adaptiveHeight isOpen={isOpen} onClose={onClose}>
      <ModalGuidedTour.Slide heading={SLIDES.SHARE_YOUR_NUMBER.HEADING}>
        <SlideWithImage
          alt={SLIDES.SHARE_YOUR_NUMBER.ALT}
          body={SLIDES.SHARE_YOUR_NUMBER.BODY}
          src={SLIDES.SHARE_YOUR_NUMBER.SRC}
        />
      </ModalGuidedTour.Slide>
      <ModalGuidedTour.Slide heading={SLIDES.GET_CREATIVE.HEADING}>
        <SlideWithImage body={SLIDES.GET_CREATIVE.BODY} src={SLIDES.GET_CREATIVE.SRC} />
      </ModalGuidedTour.Slide>
      <ModalGuidedTour.Slide heading={SLIDES.SWIPE_UP_LINK.HEADING}>
        <SlideWithImage body={SLIDES.SWIPE_UP_LINK.BODY} src={SLIDES.SWIPE_UP_LINK.SRC} />
      </ModalGuidedTour.Slide>
      <ModalGuidedTour.Slide
        dots={false}
        footer={({ onDone }) => (
          <CopyFooter
            copyButtonLabel={SLIDES.COPY_PASTE.COPY_BUTTON_LABEL}
            onCopy={() => setIsDisplayed(true)}
            onDone={onDone}
            value={`${baseConfig.swipeUpUrl}/${urlSlug}`}
          />
        )}
        heading={SLIDES.COPY_PASTE.HEADING}
      >
        <OverlayMessage
          content={SLIDES.COPY_PASTE.COPY_OVERLAY_LABEL}
          fixed={false}
          height={'116px'}
          isDisplayed={isDisplayed}
          onDismissed={() => setIsDisplayed(false)}
          width={'193px'}
        >
          <SlideWithImage body={SLIDES.COPY_PASTE.BODY} src={SLIDES.COPY_PASTE.SRC} />
        </OverlayMessage>
      </ModalGuidedTour.Slide>
    </ModalGuidedTour>
  )
}
