import { request } from 'utils/api'
import { baseConfig } from 'utils/config'
import { normalizeGiphy } from 'utils/normalize'

type GetGiphyProps = {
  query?: string
}

export type Giphy = {
  original?: {
    height: string
    mp4: string
    src: string
    width: string
  }
  preview: {
    height: string
    src: string
    width: string
  }
}

export function getGiphy({ query }: GetGiphyProps): Promise<Giphy[]> {
  const endpoint = query ? 'search' : 'trending'
  let url = `/v1/gifs/${endpoint}?api_key=${baseConfig.giphyApiToken}`
  if (query) {
    url = `${url}&q=${query}`
  }

  return request(url, {
    baseUrl: 'https://api.giphy.com',
    camelCase: false,
  }).then(normalizeGiphy)
}
