import { DIALOG_VARIANTS, Dialog, FONT_SIZE, FONT_WEIGHT, SPACING, Typography } from '@community_dev/pixels'
import { PhoneNumberType } from '@community_dev/types/lib/api/PhoneNumberType'
import { useQuery } from '@tanstack/react-query'
import * as filestack from 'filestack-js'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { SettingsMms } from './components/customize/SettingsMms'

import { getImportHistory } from 'api/imports'
import { CAPABILITIES } from 'constants/capabilities'
import { ENDPOINTS } from 'constants/endpoints'
import { MAX_KEYWORD_RESPONDERS } from 'constants/keywords'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import RestrictedKeywordsModal from 'containers/KeywordsModal/RestrictedKeywordsModal'
import { UtmOutboundLinksModal } from 'containers/UtmOutboundLinksModal'
import { useDeleteKeywordResponder } from 'hooks/keywordResponders/useDeleteKeywordResponder'
import { useKeywordResponders } from 'hooks/keywordResponders/useKeywordResponders'
import { useClient, useClientId } from 'hooks/useClient'
import { useClientSettings } from 'hooks/useClientSettings/useClientSettings'
import { useMemberDataPermissions } from 'hooks/useMemberDataPermissions'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import Sentry from 'integrations/Sentry'
import { Setting, SettingsSection } from 'layouts/SettingsLayout'
import { useUpdateVoicemail } from 'screens/Onboarding/queries'
import analytics from 'utils/analytics'
import { screens } from 'utils/analytics/events'
import { baseConfig } from 'utils/config'

const StyledVoiceMailLink = styled.a`
  font-weight: ${FONT_WEIGHT[6]};
  font-size: ${FONT_SIZE[3]};
  line-height: 18px;
  text-decoration: none;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  white-space: nowrap;
`

const StyledEdit = styled.button`
  font-weight: ${FONT_WEIGHT[6]};
  font-size: ${FONT_SIZE[3]};
  line-height: 18px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const Customize = (): JSX.Element => {
  const { t } = useTranslation()
  const { data: client } = useClient()
  const clientId = useClientId()
  const history = useHistory()
  const [message, setMessage] = useState('')
  const [showDialog, setShowDialog] = useState(false)
  const [isUtmModalOpen, setIsUtmModalOpen] = useState(false)
  const pickerRef = useRef<filestack.PickerInstance>()
  const [isRestrictedKeywordsModalOpen, setIsRestrictedKeywordsModalOpen] = useState(false)
  const { showToastMessage } = useToastMessage()
  const hasClientSettingsWriteCapability = useHasCapability(CAPABILITIES.CLIENT.SETTINGS.WRITE)

  const { data: keywords } = useKeywordResponders()
  const { mutate: deleteKeyword } = useDeleteKeywordResponder()

  const { canReadHistory, canUpload } = useMemberDataPermissions()
  const { data: clientSettings, isLoading: isClientSettingsLoading } = useClientSettings({})

  const { data: importHistory } = useQuery(
    [QUERY_CACHE.IMPORT.HISTORY, { clientId }],
    () => getImportHistory({ clientId }),
    {
      staleTime: STALE_TIME.FIVE_SECONDS,
      enabled: canReadHistory,
    },
  )
  const showCustomMemberDataSettings =
    useHasCapability(CAPABILITIES.FEATURE.CSV_SELF_IMPORT.ALL) &&
    ((canReadHistory && !!importHistory?.data.length) || canUpload)

  const { mutate: updateVoicemail, isLoading: updateVoicemailLoading } = useUpdateVoicemail({
    onSuccess() {
      analytics.track(analytics.events.VoicemailUploaded())

      showToastMessage({
        message: 'Voicemail saved',
        success: true,
      })
    },
    onError(e) {
      Sentry.captureException(e)

      showToastMessage({
        message: 'Voicemail failed to save',
        success: false,
      })
    },
  })

  useEffect(() => {
    const instance = filestack.init(baseConfig.filestackApiKey)

    const onFileUploadFinished = async (res) => {
      try {
        const response = await fetch(res.url)
        const blob = await response.blob()
        const body = new FormData()
        body.append('voicemail', blob)
        await updateVoicemail({ body })
      } catch (e) {
        Sentry.captureException(e)
      }
    }

    const options = {
      maxFiles: 1,
      accept: ['audio/*'],
      fromSources: ['local_file_system'],
      uploadInBackground: false,
      maxSize: 50 * 1024 * 1024,
      onFileUploadFinished,
    }

    pickerRef.current = instance.picker(options)
  }, [updateVoicemail])

  const addKeyword = () => {
    history.push(ENDPOINTS.SETTINGS.KEYWORDS)
  }

  const editKeyword = (id) => {
    history.push(`${ENDPOINTS.SETTINGS.KEYWORDS}/${id}`)
  }

  const setDialogState = (showDialog, message) => () => {
    setShowDialog(showDialog)
    setMessage(message)
  }

  const openPicker = () => {
    pickerRef.current?.open()
  }

  const renderDialog = () => {
    if (!showDialog) return
    return (
      <Dialog message={message}>
        <Dialog.Action onClick={setDialogState(false, undefined)} variant={DIALOG_VARIANTS.EMPHASIZED}>
          {t('gotIt')}
        </Dialog.Action>
      </Dialog>
    )
  }

  const currentNumberOfKeywords = keywords?.data?.length || 0
  return (
    <>
      {isRestrictedKeywordsModalOpen && (
        <RestrictedKeywordsModal onClose={() => setIsRestrictedKeywordsModalOpen(false)} />
      )}
      {client?.phoneNumberType !== PhoneNumberType.SHORT_CODE && (
        <SettingsSection>
          <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
            {t('settings.customize.voicemail')}
          </Typography>
          <Setting
            action={t('update')}
            label={t('settings.customize.uploadFileMp3')}
            loading={updateVoicemailLoading}
            onClickAction={openPicker}
          >
            <StyledVoiceMailLink href={client?.voicemailUrl}>{t('settings.customize.tapToListen')}</StyledVoiceMailLink>
          </Setting>
        </SettingsSection>
      )}
      <SettingsMms />
      {showCustomMemberDataSettings && (
        <SettingsSection>
          <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
            {t('settings.customize.cmd.title')}
          </Typography>
          {canUpload && (
            <Setting
              action={t('settings.customize.cmd.importYourData.action')}
              label={t('settings.customize.cmd.importYourData.label')}
              onClickAction={() => {
                analytics.track(analytics.events.Import.BeginImport(screens.Settings.Customize))
                history.push(ROUTES.SETTINGS.CUSTOMIZE.IMPORT.NEW)
              }}
              text={t('settings.customize.cmd.importYourData.text')}
            />
          )}
          {!!importHistory?.data.length && canReadHistory && (
            <Setting
              action={t('settings.customize.cmd.importHistory.action')}
              label={t('settings.customize.cmd.importHistory.label')}
              onClickAction={() => history.push(ROUTES.SETTINGS.CUSTOMIZE.IMPORTS)}
              text={t('settings.customize.cmd.importHistory.text')}
            />
          )}
        </SettingsSection>
      )}

      <SettingsSection>
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          {t('settings.customize.filterOffensiveContent')}
        </Typography>
        <Setting
          action={t('settings.customize.manage')}
          label={t('settings.customize.filterOffensiveContent')}
          onClickAction={() => setIsRestrictedKeywordsModalOpen(true)}
          text={t('settings.customize.chooseWordsOrPhrases')}
        />
      </SettingsSection>

      <SettingsSection>
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          {t('settings.customize.utm.addUtmToOutboundUrls.title')}
        </Typography>
        <Setting
          action={t('settings.change')}
          disabled={!hasClientSettingsWriteCapability}
          label={
            clientSettings?.addUtmToOutboundUrls &&
            t(`settings.customize.utm.addUtmToOutboundUrls.${clientSettings.addUtmToOutboundUrls}`)
          }
          loading={isClientSettingsLoading}
          onClickAction={() => setIsUtmModalOpen(true)}
          text={t('settings.customize.utm.addUtmToOutboundUrls.text')}
        />
        <UtmOutboundLinksModal isOpen={isUtmModalOpen} onClose={() => setIsUtmModalOpen(false)} />
      </SettingsSection>

      <SettingsSection>
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          {t('settings.customize.keywordResponders')}
        </Typography>
        <Setting
          action={t('settings.customize.addKeyword')}
          disabled={currentNumberOfKeywords >= MAX_KEYWORD_RESPONDERS}
          onClickAction={addKeyword}
        />
        {keywords?.data?.map((keyword) => (
          <Setting
            action={t('delete')}
            isActionDestructive={true}
            key={keyword.id}
            label={keyword.keyword}
            onClickAction={() => deleteKeyword({ id: keyword.id })}
            text={keyword.text}
          >
            <StyledEdit onClick={() => editKeyword(keyword.id)}>{t('edit')}</StyledEdit>
          </Setting>
        ))}
      </SettingsSection>

      {renderDialog()}
    </>
  )
}

export default Customize
