import { Button, Layout, Tooltip } from '@community_dev/pixels'

import { LinkAction } from 'api/addOns'
import { useHasCapabilitiesAndFeatureFlags } from 'hooks/useAddons/useHasCapabilitiesAndFeatureFlags'
import { useLinkWithParams } from 'hooks/useAddons/useLinkWithParams'
import analytics from 'utils/analytics'

type LinkButtonProps = LinkAction & {
  className?: string
}

export const LinkButton = ({ text, url, params, disabled = {}, className }: LinkButtonProps): JSX.Element => {
  const urlWithParams = useLinkWithParams({ url, params })

  const isDisabled = !useHasCapabilitiesAndFeatureFlags({
    capabilities: disabled.capabilities,
    featureFlags: disabled.featureFlags,
  })

  if (isDisabled) {
    return (
      <Tooltip content={disabled?.tooltip}>
        <Layout className={className}>
          <Button disabled>{text}</Button>
        </Layout>
      </Tooltip>
    )
  }

  return (
    <a
      className={className}
      href={urlWithParams}
      onClick={() => analytics.track(analytics.events.AddOnCtaClicked(text))}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Button>{text}</Button>
    </a>
  )
}
