import {
  findOne,
  isSelectorFilter,
  serializeFilters,
  TimeZoneFilter,
  TimeZoneGroupMatch,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { COLORS, ListItem, SPACING } from '@community_dev/pixels'
import kebabCase from 'lodash/kebabCase'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { RecommendationsLoadingIndicator } from '../RecommendationsLoadingIndicator'

import { FilterMemberCount } from './FilterMemberCount'
import { useRecipientField } from './RecipientFieldContext'
import {
  StyledAddSuffix,
  StyledButton,
  StyledHeader,
  StyledHeading,
  StyledMeta,
  StyledPanel,
  StyledSubHeading,
} from './styled-recipients'
import { useTimezones } from './useTimezones'

import { FilterSelectionType, useFilters } from 'contexts/FilterProvider/FilterProvider'
import { FilterOptionsWithCounts } from 'hooks/useCountByQuery/types'
import { useCurrentFiltersWithTimezoneCounts } from 'hooks/useCountByQuery/useTimezoneCount'
import { formatNumeral } from 'utils/general'
const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
  margin-bottom: ${SPACING[4]};
`

const StyledListItem = styled.div<{ $hasAction: boolean }>`
  opacity: ${({ $hasAction }) => ($hasAction ? 1 : '0.4')};
`

type TimezoneSectionProps = {
  heading?: string
  timezones: FilterOptionsWithCounts
  type: FilterSelectionType
}

function TimezoneSection({ heading, timezones, type }: TimezoneSectionProps): JSX.Element | null {
  const { t } = useTranslation()
  const { addFilter } = useFilters()
  const { setIsOpen } = useRecipientField()
  const { communityLocalTimezone } = useTimezones()

  if (!communityLocalTimezone) return null

  const id = kebabCase(heading)

  return (
    <>
      {heading && <StyledSubHeading id={id}>{heading}</StyledSubHeading>}
      <StyledList aria-labelledby={id}>
        {timezones.options.map((timezone, index) => {
          const { filter, label } = timezone
          const { data: { count = 0 } = {}, isInitialLoading } = timezones.counts[index]

          const hasAction = Boolean(count)

          if (!isSelectorFilter(filter)) return null

          const timeZoneGroup = filter.operand.value
          const countLabel = `${formatNumeral(count, '0,0')} ${t('units.members', { count })}`

          return (
            <StyledListItem $hasAction={hasAction} key={`timezone-${timeZoneGroup}`}>
              <ListItem
                as={'button'}
                data-testid={`timezone-${timeZoneGroup}`}
                label={label}
                onClick={() => {
                  addFilter(filter, type)
                  setIsOpen(false)
                }}
                subtext={<FilterMemberCount count={countLabel} isLoading={isInitialLoading} />}
              />
            </StyledListItem>
          )
        })}
      </StyledList>
    </>
  )
}

export function RecommendationsTimezone({ type = 'includes' }: { type: FilterSelectionType }): JSX.Element {
  const { t } = useTranslation()
  const { setIsOpen } = useRecipientField()
  const localTimezones = useCurrentFiltersWithTimezoneCounts('local', type)
  const popularTimezones = useCurrentFiltersWithTimezoneCounts('popular', type)
  const otherTimezones = useCurrentFiltersWithTimezoneCounts('other', type)

  const allOptions = [...localTimezones.options, ...popularTimezones.options, ...otherTimezones.options]

  const { activeSubtree, removeFilter } = useFilters()
  const { isInitialLoading: timezonesLoading } = useTimezones()

  const selected = useMemo(() => {
    const filterNode = findOne(activeSubtree, TimeZoneGroupMatch)
    if (filterNode) {
      return serializeFilters(filterNode) as TimeZoneFilter
    }
    return null
  }, [activeSubtree])

  const selectedIndex = allOptions.findIndex(({ filter }) => {
    if (isSelectorFilter(filter)) {
      return filter.operand.value === selected?.operand.value
    }
    return false
  })
  const selectedOption = allOptions[selectedIndex]

  return (
    <StyledPanel>
      <StyledMeta>
        <StyledHeader>
          <StyledHeading>{t('timezone')}</StyledHeading>
        </StyledHeader>
      </StyledMeta>
      {timezonesLoading ? (
        <RecommendationsLoadingIndicator />
      ) : selected && selectedOption ? (
        <div data-testid="timezones-list">
          <StyledList>
            <StyledListItem $hasAction={true}>
              <ListItem
                as={'div'}
                data-testid={`timezone-${selected.operand.value}`}
                label={selectedOption.label}
                suffix={
                  <StyledAddSuffix>
                    <StyledButton
                      $color={COLORS?.ERRORS}
                      onClick={() => {
                        removeFilter(selected)
                        setIsOpen(false)
                      }}
                    >
                      Remove
                    </StyledButton>
                  </StyledAddSuffix>
                }
              />
            </StyledListItem>
          </StyledList>
        </div>
      ) : (
        <div data-testid="timezones-list">
          {localTimezones?.options.length ? (
            <TimezoneSection heading={t('timezoneFilter.localHeading')} timezones={localTimezones} type={type} />
          ) : null}
          {popularTimezones?.options.length ? (
            <TimezoneSection heading={t('timezoneFilter.popularHeading')} timezones={popularTimezones} type={type} />
          ) : null}
          {otherTimezones?.options.length ? (
            <TimezoneSection heading={t('timezoneFilter.otherHeading')} timezones={otherTimezones} type={type} />
          ) : null}
        </div>
      )}
    </StyledPanel>
  )
}
