import React, { useContext, useEffect, useMemo, useState } from 'react'

const STORAGE_KEY = 'insights-units-preference'

export const UNITS_PERCENT = 'UNITS_PERCENT'
export const UNITS_SCALAR = 'UNITS_SCALAR'
const UNITS_DEFAULT = UNITS_PERCENT

export type UnitsPreference = typeof UNITS_PERCENT | typeof UNITS_SCALAR

type UnitsPreferenceContextValue = {
  unitsPreference: UnitsPreference
  setUnitsPreference: React.Dispatch<React.SetStateAction<UnitsPreference>>
}

export const UnitsPreferenceContext = React.createContext<UnitsPreferenceContextValue | null>(null)

UnitsPreferenceContext.displayName = 'UnitsPreferenceContext'

const readPersistedState = () => {
  try {
    const persisted = localStorage.getItem(STORAGE_KEY)
    if (persisted !== UNITS_PERCENT && persisted !== UNITS_SCALAR) {
      return UNITS_DEFAULT
    }
    return persisted
  } catch (_) {
    return UNITS_DEFAULT
  }
}

export const UnitsPreferenceProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [unitsPreference, setUnitsPreference] = useState<UnitsPreference>(readPersistedState())

  useEffect(() => {
    window.localStorage.setItem(STORAGE_KEY, unitsPreference)
  }, [unitsPreference])

  const value = useMemo(() => ({ unitsPreference, setUnitsPreference }), [unitsPreference, setUnitsPreference])

  return <UnitsPreferenceContext.Provider value={value}>{children}</UnitsPreferenceContext.Provider>
}

export const useUnitsPreference = (): UnitsPreferenceContextValue => {
  const context = useContext(UnitsPreferenceContext)

  if (!context) {
    throw new Error('useUnitsPreference must be used within a UnitsPreferenceContext')
  }

  return context
}
