import { linkify } from '@community_dev/pixels'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { Match } from 'linkify-it'

import { Media } from 'api/campaign'
import { utf8ToBase62 } from 'utils/encode'

export const generateShortId = (guid: string): string => {
  return `https://m.community.com/${utf8ToBase62(guid).slice(0, 10)}`
}

// Define the type PhonePreviewConfig which can be an object containing either text, link, or media
export type PhonePreviewConfig = PhonePeviewConfigText | PhonePeviewConfigLink | PhonePeviewConfigMedia

export type PhonePeviewConfigText = {
  text: string
}

export type PhonePeviewConfigLink = {
  shortened: string
  link: string
}

export type PhonePeviewConfigMedia = {
  media: Media
}

// Function to remove a substring from a string at a specified index with a given length
export function removeSubstrings(text: string, ...indexLengthPairs: [number, number][]): string {
  // Sort the index-length pairs by index to handle removals correctly
  const sortedPairs = indexLengthPairs.sort((a, b) => a[0] - b[0])
  let modifiedText = text

  // Adjust the indexes from the start to handle index shifting correctly
  let offset = 0
  for (const [index, length] of sortedPairs) {
    const adjustedIndex = index - offset
    modifiedText = modifiedText.slice(0, adjustedIndex) + modifiedText.slice(adjustedIndex + length)
    offset += length // Increase offset by length of removed substring
  }

  return modifiedText
}

// Function to compose the input text and optional media into an array of PhonePreviewConfig objects
export function composeInputToPhonePreview(text: string, media?: Media | null): PhonePreviewConfig[] {
  // If both text and media are not provided, return an empty array
  if (!text && !media) {
    return []
  }

  // If only media is provided, return an array with a single media object
  if (!text && media) {
    return [
      {
        media: media,
      },
    ]
  }

  // Parse the text to find links using the linkify
  let _parsed = linkify.match(text) || []
  let _input = text.trim()

  // If no links are found and media is provided, return media and text objects
  if (_parsed.length === 0 && media) {
    return [
      {
        media,
      },
      {
        text: _input,
      },
    ]
  }

  // If links are found and media disposition is LINK, handle accordingly
  if (_parsed.length > 0 && media?.disposition === MediaDisposition.LINK) {
    const remaining = removeSubstrings(_input, ..._parsed.map((l: Match) => [l.index, l.raw.length])).trim()

    // If no remaining text after removing the link, return media and link objects
    if (!remaining) {
      return [
        {
          media: media,
        },
        ..._parsed.map((link) => {
          return {
            shortened: generateShortId(link.raw),
            link: link.raw,
          }
        }),
      ]
    }

    // Otherwise, return text object with media URL and input text
    return [
      {
        text: `${media.url} ${_input}`,
      },
    ]
  }

  // Handle cases where the link is at the beginning of the text
  if (_parsed.length === 1 && _parsed[0].index === 0) {
    const remaining = removeSubstrings(_input, [_parsed[0].index, _parsed[0].raw.length])

    return [
      media?.disposition === MediaDisposition.ATTACHMENT && {
        media: media,
      },
      { shortened: generateShortId(_parsed[0].raw), link: _parsed[0].raw },
      remaining.trim() && {
        text: remaining.trim(),
      },
    ].filter(Boolean) as PhonePreviewConfig[]
  }

  // Handle cases where the link is at the end of the text
  if (_parsed.length === 1 && _parsed[0].lastIndex === _input.length) {
    const remaining = removeSubstrings(_input, [_parsed[0].index, _parsed[0].raw.length])

    return [
      media?.disposition === MediaDisposition.ATTACHMENT && {
        media: media,
      },
      remaining.trim() && {
        text: remaining.trim(),
      },
      { shortened: generateShortId(_parsed[0].raw), link: _parsed[0].raw },
    ].filter(Boolean) as PhonePreviewConfig[]
  }

  // Handle cases where there are multiple links in the text
  while (_parsed.length) {
    const link = _parsed[0]
    const remaining = removeSubstrings(_input, [link.index, link.raw.length])
    _input = remaining.trim()
    _parsed = linkify.match(_input) || []
  }

  _parsed = linkify.match(text) || []

  // If no remaining input text but multiple links are found, return an array of link objects
  if (!_input && _parsed.length > 1) {
    return [
      media?.disposition === MediaDisposition.ATTACHMENT && {
        media: media,
      },
      ..._parsed.map((link) => {
        return {
          shortened: generateShortId(link.raw),
          link: link.raw,
        }
      }),
    ].filter(Boolean)
  }

  // Return an array with a single text object
  return [
    media?.disposition === MediaDisposition.ATTACHMENT && {
      media: media,
    },
    {
      text: text,
    },
  ].filter(Boolean) as PhonePreviewConfig[]
}
