import styled from 'styled-components'

export const StyledLabel = styled.label`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  font-size: 12px;
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  font-size: 12px;
`
