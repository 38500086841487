import { loadGoogleMapsLoader } from 'utils/google-maps-loader'

const getLocationGeometryApi = ({ service, location, address }) =>
  new Promise((resolve) => service.geocode({ location, address }, resolve))

type LatLng = {
  lat: number
  lng: number
}

export async function postCoordinates({ address }: { address: string }): Promise<LatLng> {
  await loadGoogleMapsLoader()
  const service = new google.maps.Geocoder()
  const geometryResp: any = await getLocationGeometryApi({
    service,
    address,
    location: null,
  })
  const lat = geometryResp[0].geometry.location.lat()
  const lng = geometryResp[0].geometry.location.lng()

  return { lat, lng }
}

export async function postAddresses({
  lat,
  lng,
}: LatLng): Promise<{ name: string; city: string; state: string; country: string }> {
  await loadGoogleMapsLoader()
  const latLng = { lat, lng }
  const service = new google.maps.Geocoder()
  const resp: any = await getLocationGeometryApi({
    service,
    address: null,
    location: latLng,
  })

  // Find the city / state
  let city = ''
  let state = ''
  let country = ''

  resp[0].address_components.forEach((component) => {
    if (component.types.includes('administrative_area_level_1')) {
      state = component.short_name
    }
    if (component.types.includes('locality')) {
      city = component.long_name
    }
    if (component.types.includes('administrative_area_level_2') && city === '') {
      city = component.long_name
    }
    if (component.types.includes('country')) {
      country = component.long_name
    }
  })

  return {
    name: `${city}, ${state}`,
    city,
    country,
    state,
  }
}
