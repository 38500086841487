import { FieldSources, Placeholder } from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { Fields, getCustomMemberDataFields, getPlaceholders } from 'api/customMemberData'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'

type UseCustomDataFieldsArgs = {
  options?: UseQueryOptions<Fields>
  clientId: string
}

export function useCustomMemberDataFields({ options = {}, clientId }: UseCustomDataFieldsArgs): UseQueryResult<Fields> {
  return useQuery<Fields>(
    [QUERY_CACHE.CUSTOM_DATA_FIELDS, { clientId }],
    () => getCustomMemberDataFields({ clientId }),
    {
      enabled: !!clientId,
      staleTime: STALE_TIME.ONE_HOUR,
      ...options,
    },
  )
}

type UsePlaceholdersArgs = {
  options?: UseQueryOptions<Placeholder[]>
  clientId: string
  communicationChannel: CommunicationChannel
}

export function usePlaceholders({
  options = {},
  clientId,
  communicationChannel,
}: UsePlaceholdersArgs): UseQueryResult<Placeholder[]> {
  return useQuery<Placeholder[]>([QUERY_CACHE.PLACEHOLDERS, { clientId }], () => getPlaceholders({ clientId }), {
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME.ONE_MINUTE,
    select(data) {
      if (communicationChannel === CommunicationChannel.SMS) {
        return data
      } else {
        // communication channels other than SMS have fewer sources available.
        return data.filter(
          (placeholder) => placeholder.source === FieldSources.BUILT_IN || placeholder.source === FieldSources.FAN,
        )
      }
    },
    ...options,
  })
}
