import { Button, Modal, SPACING } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useTCRContext } from 'contexts/TCRProvider'
import analytics from 'utils/analytics'

const StyledTitle = styled.h1`
  text-align: center;
`

const StyledBody = styled.div`
  padding: 0 ${SPACING[9]};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 500px) {
    padding: 0 ${SPACING[3]};
  }

  li {
    text-align: left;
    margin-top: ${SPACING[4]};
  }
`

const StyledFooter = styled.div`
  text-align: center;
`

export const RejectionTCRModal = (): JSX.Element => {
  const { t } = useTranslation(undefined, { keyPrefix: 'tcr.modals.rejection' })
  const { setSeen, showSkip } = useTCRContext()

  // Should not be able to close the rejection modal if showSkip is false
  const markAsSeen = () => {
    if (showSkip) {
      analytics.track(analytics.events.TCR.ApplicationNotApproved())
      setSeen()
    }
  }

  return (
    <div>
      <Modal maxHeight="auto" onClose={markAsSeen} open>
        <Modal.Header>{t('modalTitle')}</Modal.Header>
        <Modal.Body>
          <StyledBody>
            <StyledTitle>{t('title')}</StyledTitle>
            <ul>
              <li>{t('bullet1')}</li>
              <li>
                <b>{t('bullet2')}</b>
              </li>
            </ul>
          </StyledBody>
        </Modal.Body>
        {showSkip && (
          <Modal.Footer>
            <StyledFooter>
              <Button onClick={markAsSeen}>Done</Button>
            </StyledFooter>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  )
}
