import { CommunityFlagIcon, Layout, SPACING, Typography } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import {
  StyledEditSignUpConfirmationMessage,
  StyledEditWelcomeMessage,
  StyledLine,
  SignupMessagesWrapper,
} from './SignupMessages.style'

import { MessageBubble } from 'components/MessageBubble'
import { StatusIndicator } from 'components/StatusIndicator'
import { useOnboardingMessages } from 'hooks/useOnboardingMessages'
import { Setting } from 'layouts/SettingsLayout'
import { SettingsSection } from 'layouts/SettingsLayout'

export function SignupMessages(): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const { welcomeMessage, confirmationMessage, updateMessage } = useOnboardingMessages()

  return (
    <SignupMessagesWrapper>
      <SettingsSection>
        <Layout position="relative">
          <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
            {t('settings.signupMessages')}
          </Typography>
          <StyledLine />
          <Setting
            icon={
              <MessageBubble>
                <CommunityFlagIcon color={COLORS?.APP_BACKGROUND_LEVEL_3} />
              </MessageBubble>
            }
            label={t('settings.welcomeMessage')}
            text={welcomeMessage?.text}
          >
            <StyledEditWelcomeMessage
              charMax={welcomeMessage?.maxLength}
              defaultValue={welcomeMessage?.text}
              legalLanguage={welcomeMessage?.legalMessage}
              messageBubbleContents={<CommunityFlagIcon color={COLORS?.APP_BACKGROUND_LEVEL_3} />}
              onSave={(values) => updateMessage({ id: welcomeMessage?.id, text: values.message })}
              segmentMax={welcomeMessage?.maxSegmentCount}
            />
          </Setting>
          <StatusIndicator status={t('settings.registrationCompleted')} />
          <Setting
            icon={<MessageBubble>👋</MessageBubble>}
            label={t('settings.signupConfirmationMessage')}
            text={confirmationMessage?.text}
          >
            <StyledEditSignUpConfirmationMessage
              charMax={confirmationMessage?.maxLength}
              defaultValue={confirmationMessage?.text}
              messageBubbleContents="👋"
              onSave={(values) => updateMessage({ id: confirmationMessage?.id, text: values.message })}
              segmentMax={confirmationMessage?.maxSegmentCount}
              tcpaMessage={confirmationMessage?.tcpa}
            />
          </Setting>
        </Layout>
      </SettingsSection>
    </SignupMessagesWrapper>
  )
}
