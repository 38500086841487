import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type PostTCRProps = {
  clientId: string
  hasBusinessIdentification: boolean | null
  form: Api.V2.TCR.PostStandardRegistrationBody | Record<string, any>
  seatId: string
}

export type PostTCRReturn = Promise<any>

export const postTCR = ({ clientId, hasBusinessIdentification, form, seatId }: PostTCRProps): PostTCRReturn => {
  const endpoint = hasBusinessIdentification ? ENDPOINTS.TCR.STANDARD_REGISTRATION : ENDPOINTS.TCR.STARTER_REGISTRATION

  return request(route(endpoint, { clientId, seatId }), {
    method: HTTP.POST,
    body: form,
  })
}

export type GetTCRRegistrationProps = {
  clientId: string
  seatId: string
}

type TCRBody = {
  statusSeen?: boolean
  mobileNumber?: string
  businessName?: string
}

export type PatchTCR = {
  clientId: string
  seatId: string
  body: TCRBody
}

export type CarrierLimit = {
  value: number | string
  period: string
}

export type CarrierLimits = {
  carrier: string
  limit: CarrierLimit | null
}

export const PAY_BY = Object.freeze({
  CREDIT_CARD: 'credit_card',
  INVOICE: 'invoice',
  FREE: 'free',
})

type PayBy = typeof PAY_BY[keyof typeof PAY_BY]

export const BRAND_TYPE = Object.freeze({
  STANDARD: 'standard',
  STARTER: 'starter',
})

type BrandType = typeof BRAND_TYPE[keyof typeof BRAND_TYPE]

export type TRCRegistrationData = {
  id: string
  status: string
  payBy: PayBy | null
  campaignCreationEnabled: boolean
  presentStatusInfo: boolean
  brandType: BrandType | null
  carrierLimits: Array<CarrierLimits> | null
}

export type TRCRegistration = {
  data: TRCRegistrationData
}

export const getTRCRegistration = ({ clientId, seatId }: GetTCRRegistrationProps): Promise<TRCRegistration> => {
  return request(route(ENDPOINTS.TCR.REGISTRATION, { clientId, seatId }))
}

export const patchTCR = ({ clientId, seatId, body }: PatchTCR): Promise<TRCRegistration> => {
  return request(route(ENDPOINTS.TCR.REGISTRATION, { clientId, seatId }), {
    method: HTTP.PATCH,
    body,
  })
}

export const postRetryOTPVerification = ({ clientId, seatId }: { clientId: string; seatId: string }): Promise<any> => {
  return request(route(ENDPOINTS.TCR.RETRY_OTP_VERIFICATION, { clientId, seatId }), {
    method: HTTP.POST,
  })
}

type GetCarrierUsageProps = {
  clientId: string
}

type CarrierUsageData = {
  carrier: string
  percentUsed: number
  quotaResetsAt: string
}

export type CarrierUsageReturn = {
  data: CarrierUsageData[]
}

export const getCarrierUsage = ({ clientId }: GetCarrierUsageProps): Promise<CarrierUsageReturn> => {
  return request(route(ENDPOINTS.TCR.CARRIER_USAGE, { clientId }))
}
