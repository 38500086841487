import { Dropdown, Info, SPACING } from '@community_dev/pixels'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { FormSection } from '../../FormSection'

import { PathRuleSet } from './PathRuleSet'

import { Embed, EmbedPathRuleSelectType } from 'api/mgu'
import { PlaceIcon } from 'screens/SettingsScreen/components/EmbedSettings/Icons'

const DELAY_OPTIONS = [0, 3, 5, 10, 15, 20, 30, 45, 60, 90].map((n) => ({
  value: n.toString(), // Dropdown requires values to be strings, these are parsed to ints on change
  label: n ? `${n} seconds` : 'no delay',
}))

const StyledPadding = styled.div`
  padding: ${SPACING[2]} 0;
`

type PopupBehaviorsProps = {
  config: Embed['jsonConfig']
  onChange(config: Partial<Embed['jsonConfig']>)
}

export const PopupBehaviors = ({ config, onChange }: PopupBehaviorsProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation(undefined, { keyPrefix: 'settings.integrations.signupUnit.behaviorForm' })
  const exclusionRules = config.pathRules.filter((r) => r.selectType === 'excluded')

  const inclusionRules = config.pathRules.filter((r) => r.selectType === 'included')

  return (
    <>
      <FormSection name="Your Website">
        <StyledPadding>
          <PathRuleSet
            label="only show on certain URLs"
            onChange={(rules) => onChange({ pathRules: [...exclusionRules, ...rules] })}
            rules={inclusionRules}
            selectType={EmbedPathRuleSelectType.included}
          />
        </StyledPadding>
        <StyledPadding>
          <PathRuleSet
            label="don't show on certain URLs"
            onChange={(rules) => onChange({ pathRules: [...inclusionRules, ...rules] })}
            rules={exclusionRules}
            selectType={EmbedPathRuleSelectType.excluded}
          />
        </StyledPadding>
        <StyledPadding>
          <Info icon={<PlaceIcon color={COLORS?.SUBTEXT} />}>{t('note')}</Info>
        </StyledPadding>
      </FormSection>
      <FormSection name="Launch Delay">
        <Dropdown
          onChange={(v) => onChange({ launchDelayInSeconds: parseInt(v.value) })}
          options={DELAY_OPTIONS}
          value={DELAY_OPTIONS.find((o) => o.value === config.launchDelayInSeconds.toString())}
        />
      </FormSection>
    </>
  )
}
