import { SPACING, FONT_SIZE, FONT_WEIGHT, TakeoverModal, Button, Pill } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledCreateCommunityModal = styled(TakeoverModal)`
  align-items: baseline;
  padding-top: ${SPACING[10]};
  align-items: flex-start !important;
`

export const StyledModalHeader = styled.h1`
  margin: 0;
`

export const StyledModalSubHeader = styled.div`
  margin-top: ${SPACING[2]};
  font-size: ${FONT_SIZE[4]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  line-height: 148%;
`

export const StyledBoxInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const StyledLabel = styled.label`
  display: block;
  margin-top: 25px;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  line-height: 136%;
  font-size: ${FONT_SIZE[3]};
  user-select: none;
  cursor: pointer;
  input {
    position: relative;
    top: 1px;
    margin-right: 7px;
  }
`

export const StyledInputLabel = styled.div`
  display: block;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  line-height: 136%;
  font-size: ${FONT_SIZE[2]};
  user-select: none;
`

export const StyledInputTitle = styled.label`
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  font-size: ${FONT_SIZE[2]};
  line-height: 136%;
  margin: ${SPACING[3]} 0 ${SPACING[1]};
`

export const StyledNameInput = styled.input`
  margin-top: ${SPACING[1]};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: 4px;
  width: 100%;
  padding: ${SPACING[3]} ${SPACING[4]};
  font-size: ${FONT_SIZE[4]};
  font-weight: ${FONT_WEIGHT[5]};
  outline: none;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  height: ${SPACING[8]};

  &::placeholder {
    color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT_PLACEHOLDER};
  }
`
export const StyledColorInput = styled.div`
  margin-top: ${SPACING[1]};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};
  border-radius: 4px;
  width: 88px;
  height: 100%;
  padding: ${SPACING[2]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND_HOVER};
  }
`

export const StyledCommunityNameContainer = styled.div`
  display: flex;
  padding: ${SPACING[7]} 0 0;
  position: relative;

  & ${StyledBoxInputContainer}:nth-child(1) {
    flex-grow: 1;
  }

  & ${StyledBoxInputContainer}:not(:last-of-type) {
    margin-right: ${SPACING[2]};
  }
`
export const StyledAddTextButton = styled.button`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  font-weight: bold;
  font-size: ${FONT_SIZE[2]};
  line-height: 136%;
  margin: ${SPACING[3]} 0 ${SPACING[1]};
  text-decoration: none;
  background: none;
  border: 0;
  padding: 0;
  appearance: none;

  &:hover {
    cursor: pointer;
  }
`

export const StyledPillContainer = styled.div`
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: 4px;
  min-height: 48px;
  padding: ${SPACING[2]} ${SPACING[3]} ${SPACING[3]} ${SPACING[4]};
  display: flex;
  max-height: 80px;
  overflow-y: scroll;
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};
  &:hover {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.SHADOWS.CARD};
  }

  & > .Pill {
    height: 25px;
    align-self: flex-end;
  }

  & .Pill {
    margin: ${SPACING[1]} ${SPACING[1]} 0 0;
    cursor: pointer;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${SPACING[7]};
`

export const StyledButton = styled(Button)`
  width: 100px;
`

export const StyledPills = styled.div`
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${Pill} {
    margin-right: 4px;
    margin-top: 4px;
  }
`
