import { SPACING, Typography } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'

import { AiMessageGenerator } from './components/Integrations/AiMessageGenerator'
import { Amb } from './components/Integrations/Amb'
import { Embeds } from './components/Integrations/Embeds'
import { Salesforce } from './components/Integrations/Salesforce'
import { Seated } from './components/Integrations/Seated'
import { SetLive } from './components/Integrations/SetLive'
import { Shopify } from './components/Integrations/Shopify'
import { Webhooks } from './components/Integrations/Webhooks'
import { WhatsApp } from './components/Integrations/WhatsApp'
import { Zapier } from './components/Integrations/Zapier'

import { CAPABILITIES } from 'constants/capabilities'
import { useClient } from 'hooks/useClient'
import { useHasCapability, useHasEveryCapability } from 'hooks/useUserCapability'
import { SettingsSection } from 'layouts/SettingsLayout'

export function Integrations(): JSX.Element {
  const { data: client } = useClient()
  const hasShopify = useHasCapability(CAPABILITIES.FEATURE.SHOPIFY_INTEGRATION)
  const hasSalesforce = useHasCapability(CAPABILITIES.FEATURE.SALESFORCE_INTEGRATION)
  const hasWhatsappSignUpEmbed = useHasCapability(CAPABILITIES.FEATURE.WHATSAPP.ALL)
  const hasAmb = useHasCapability(CAPABILITIES.FEATURE.AMB.ALL)
  const hasOutboundWebhooks = useHasEveryCapability(
    CAPABILITIES.FEATURE.OUTBOUND_WEBHOOKS.ALL,
    CAPABILITIES.CLIENT.OUTBOUND_WEBHOOKS.WRITE,
  )
  const hasOutboundWebhookAdmin = useHasEveryCapability(
    CAPABILITIES.ADMIN.OUTBOUND_WEBHOOKS.READ,
    CAPABILITIES.FEATURE.OUTBOUND_WEBHOOKS.ALL,
  )
  const hasAnyCommunicationChannel = client && client.communicationChannels.length > 0
  const hasSetLiveIntegration = useHasCapability(CAPABILITIES.FEATURE.SET_LIVE_INTEGRATION.ALL)
  const hasSeatedIntegration = useHasCapability(CAPABILITIES.FEATURE.SEATED_INTEGRATION.ALL)
  const { t } = useTranslation()

  if (!hasAnyCommunicationChannel && !hasWhatsappSignUpEmbed && !hasAmb) {
    return (
      <SettingsSection data-testid="settings-integrations">
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          {t('settings.integrations.noIntegrationsAvailable')}
        </Typography>
      </SettingsSection>
    )
  } else {
    return (
      <SettingsSection data-testid="settings-integrations">
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          {t('settings.integrations.tab')}
        </Typography>
        {hasAnyCommunicationChannel && (
          <SettingsSection>
            <Embeds />
          </SettingsSection>
        )}
        {hasAnyCommunicationChannel && hasShopify && (
          <SettingsSection>
            <Shopify />
          </SettingsSection>
        )}
        {hasAnyCommunicationChannel && hasSalesforce && (
          <SettingsSection>
            <Salesforce />
          </SettingsSection>
        )}
        {hasWhatsappSignUpEmbed && (
          <SettingsSection>
            <WhatsApp />
          </SettingsSection>
        )}
        {hasAnyCommunicationChannel && (hasOutboundWebhooks || hasOutboundWebhookAdmin) && (
          <SettingsSection>
            <Webhooks />
          </SettingsSection>
        )}
        {hasAmb && (
          <SettingsSection>
            <Amb />
          </SettingsSection>
        )}
        {hasAnyCommunicationChannel && (
          <SettingsSection>
            <Zapier />
          </SettingsSection>
        )}
        {hasAnyCommunicationChannel && (
          <SettingsSection>
            <AiMessageGenerator />
          </SettingsSection>
        )}
        {hasAnyCommunicationChannel && hasSetLiveIntegration && (
          <SettingsSection>
            <SetLive />
          </SettingsSection>
        )}
        {hasAnyCommunicationChannel && hasSeatedIntegration && (
          <SettingsSection>
            <Seated />
          </SettingsSection>
        )}
      </SettingsSection>
    )
  }
}
