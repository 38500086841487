import { FONT_SIZE, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { StyledLink } from '../../../../../styled'
import { Code } from '../../Code'

import { CopyButton } from './CopyButton'

import { Embed } from 'api/mgu'
import { MGU_INLINE_INSTRUCTIONS_LINK } from 'screens/SettingsScreen/components/EmbedSettings/constants/links'

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${SPACING[1]};
  padding-bottom: ${SPACING[2]};
`

const StyledInstallStep = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledStepIndicator = styled.div`
  padding-right: ${SPACING[2]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledSubtext = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  padding-top: ${SPACING[2]};
`

const StyledInstructions = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-size: ${FONT_SIZE[3]};
  padding-top: ${SPACING[2]};
`

const StyledInstructionsLink = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
`

type InlineInstructionsProps = {
  embed: Embed
}

export const InlineInstallation = ({ embed }: InlineInstructionsProps): JSX.Element => {
  const tag = '<div id="community-embed"></div>'
  const script = `<script async data-community-embed-id="${embed?.id}" src="${
    import.meta.env.VITE_MGU_EMBED_URL
  }"></script>`

  return (
    <>
      <StyledInstructionsLink>
        <StyledLink href={MGU_INLINE_INSTRUCTIONS_LINK} rel="noopener noreferrer" target="_blank">
          Detailed Instructions
        </StyledLink>
      </StyledInstructionsLink>
      <StyledInstructions>
        <StyledInstallStep>
          <StyledStepIndicator>1.</StyledStepIndicator>
          Copy/Paste this Code Snippet into your website's code where you want the form to appear.
        </StyledInstallStep>
        <StyledSubtext>&lt;div&gt; id</StyledSubtext>
        <Code language="html">{tag}</Code>
      </StyledInstructions>
      <StyledButtonRow>
        <CopyButton content={tag} />
      </StyledButtonRow>
      <StyledInstructions>
        <StyledInstallStep>
          <StyledStepIndicator>2.</StyledStepIndicator>
          Copy/Paste this Code Snippet into your website’s code before the {'</body>'} tag.
        </StyledInstallStep>
        <StyledSubtext>Code Snippet</StyledSubtext>
        <Code language="html">{script}</Code>
      </StyledInstructions>
      <StyledButtonRow>
        <CopyButton content={script} />
      </StyledButtonRow>
    </>
  )
}
