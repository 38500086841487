import { BORDER_RADIUS, Button, Card, CloseIcon, FONT_SIZE, Modal, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
`

export const StyledModalHeader = styled(Modal.Header)`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
`

export const InputArea = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  margin-top: 20px;
`

export const InputAreaKeyword = styled.div`
  width: 100%;
  margin-right: ${SPACING[5]};
`

export const InputWrapper = styled.div`
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};
  border-radius: ${BORDER_RADIUS[1]};
`

export const InputSubText = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${SPACING[1]};
`

export const InputLabel = styled.div`
  font-weight: 500;
  font-size: ${FONT_SIZE[2]};
  line-height: 136%;
  letter-spacing: 0.16px;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

export const InputError = styled(InputLabel)`
  color: ${({ theme }) => theme?.COLORS?.ERRORS};
`

export const Footer = styled.div`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  border-top: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  display: flex;
  padding: ${SPACING[4]};
  justify-content: flex-end;
  justify-self: flex-end;
  bottom: 0;
  align-items: center;
`

export const ButtonAdd = styled(Button)`
  height: 42px;
`

export const Empty = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const EmptyStateText = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: ${FONT_SIZE[5]};
  line-height: 132%;
  text-align: center;
  letter-spacing: 0.24px;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

export const Paragraph = styled.p`
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

export const ActiveKeywords = styled.div`
  overflow: visible;
  flex: 1;
  margin-bottom: ${SPACING[5]};
`

export const ListItem = styled.div`
  padding: ${SPACING[3]} 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  align-items: center;
`

export const ListItemName = styled.div`
  letter-spacing: 0.08px;
  font-size: ${FONT_SIZE[3]};
  line-height: 140%;
  font-weight: 700;
`

export const BannerActiveTextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`

export const BannerActiveText = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  font-weight: 800;
  font-size: ${FONT_SIZE[2]};
  line-height: 100%;
  letter-spacing: 0.8px;
`

export const BannerCard = styled.div`
  margin: ${SPACING[5]} 0 ${SPACING[6]};
`

export const StyledCard = styled(Card)`
  max-width: 100%;
  width: 100%;
  padding: ${SPACING[5]} ${SPACING[4]};

  div:nth-of-type(1) {
    padding-right: ${SPACING[4]};
  }

  div:nth-of-type(2) {
    font-size: 13px;
  }
`

export const Link = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  font-size: ${FONT_SIZE[2]};
  line-height: 136%;
  margin: ${SPACING[3]} 0 ${SPACING[1]};
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`

export const StyledCloseIcon = styled(CloseIcon)`
  width: 10px;
  height: 10px;
  cursor: pointer;
`
