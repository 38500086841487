import { SummaryCards } from '@community_dev/pixels'
import noop from 'lodash/noop'
import React, { JSXElementConstructor } from 'react'
import styled, { useTheme } from 'styled-components'

const StyledCardContent = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme?.COLORS?.TEXT};

  p,
  h4 {
    margin: 0;
  }

  h4 {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.32px;
    word-break: break-word;
  }

  p {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;

    strong {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.32px;
    }
  }
`

const StyledCardBody = styled.div`
  flex: 1;
`

type ProductEducationCardProps = {
  header?: string
  subHeader?: string
  body?: string
  Icon?: JSXElementConstructor<any>
  onClick?: () => void
}

export function ProductEducationCard(props: ProductEducationCardProps): JSX.Element {
  const { header, subHeader, body, Icon, onClick } = props
  const { COLORS } = useTheme() || {}

  return (
    <SummaryCards.SimpleCard onAction={onClick}>
      <StyledCardContent>
        <StyledCardBody>
          <h4>
            {/* @ts-expect-error: No constructor */}
            <Icon color={COLORS?.BUTTON_PRIMARY} size={32} /> {header}
          </h4>
          <p>
            <strong>{subHeader}</strong>
          </p>
          <p>{body}</p>
        </StyledCardBody>
      </StyledCardContent>
    </SummaryCards.SimpleCard>
  )
}

ProductEducationCard.defaultProps = {
  Icon: () => null,
  onClose: noop,
}
