import { MemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { HTTP } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'

import { ENDPOINTS } from 'constants/endpoints'
import { ApiResponse } from 'types/api'
import { request, RequestOptions } from 'utils/api'
import { wrapSingleFilter } from 'utils/memberDataFilters/memberDataFiltersUtils'
import { route } from 'utils/router'

type SdsCountResponse = {
  count: number
}

export type PostCountByQueryArgs = {
  filters: MemberDataFilter
  clientId: string
  communicationChannel?: CommunicationChannel
  traceId?: string
  options?: RequestOptions<unknown>
}

// note that body and clientId are required, however traceId is an
// *optional* argument that isn't used by the backend in any way. It's here
// as a quick way to start to identify the code paths that are triggering
// counts most often so that we can begin to make a reduction in
// counts usage within our app.
//
// It can also be used to more easily isolate specific invocations of
// counts within Cypress tests in situations where identifying these
// queries via request body introspection would be fragile.
export function postCountByQuery({
  filters,
  clientId,
  communicationChannel = CommunicationChannel.SMS,
  traceId = 'unknown',
  options,
}: PostCountByQueryArgs): Promise<number> {
  return request<ApiResponse<SdsCountResponse>>(
    route(ENDPOINTS.SUBSCRIPTION_DATA.COUNT, { clientId }, { traceId, communication_channel: communicationChannel }),
    {
      ...options,
      body: {
        filters: {
          subscription_data: wrapSingleFilter(filters),
        },
      },
      method: HTTP.POST,
      camelCase: false,
    },
  ).then(({ data }) => data?.count || 0)
}
