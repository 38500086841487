import { useMemo } from 'react'

import { Responses } from './Responses'

import { useCampaignDetails } from 'hooks/useCampaign'
import { normalizeCampaignInsights } from 'utils/normalize'

type CampaignResponseProps = {
  campaignId: string
}

export function CampaignResponses({ campaignId }: CampaignResponseProps): JSX.Element | null {
  const { data } = useCampaignDetails({ campaignId })
  const responses = useMemo(() => {
    if (!data?.responseMetadata) return []
    return normalizeCampaignInsights({
      responseMetadata: data.responseMetadata,
    }).responses
  }, [data])

  if (!responses?.length) return null

  return <Responses responses={responses} />
}
