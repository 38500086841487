import { useEffect, useState } from 'react'

const useStylesheetLink = (url: string): boolean => {
  const [loaded, setLoaded] = useState<null | boolean>(null)

  useEffect(() => {
    if (loaded !== null) {
      const tag = document.createElement('link')

      tag.rel = 'stylesheet'
      tag.href = url
      if (!loaded) {
        if (!('onload' in tag)) {
          // browser doesn't support onload... just set it as loaded.
          setLoaded(true)
        } else {
          tag.onload = () => setLoaded(true)
        }
      }

      document.body.appendChild(tag)

      return () => {
        document.body.removeChild(tag)
      }
    }
  }, [url, loaded])

  useEffect(() => {
    setLoaded(Boolean(document.querySelector(`link[href="${url}"]`)))
  }, [url])

  return Boolean(loaded)
}

export default useStylesheetLink
