export type MediaDimensions = { width: number; height: number }

export function getMediaDimensions(file: File): Promise<MediaDimensions> {
  return new Promise((resolve) => {
    // Create blob url from file
    const url = URL.createObjectURL(file)

    let media: HTMLImageElement | HTMLVideoElement

    const isVideo = file.type.startsWith('video')
    const isImage = file.type.startsWith('image')

    if (isImage) {
      media = new Image()
    } else if (isVideo) {
      media = document.createElement('video')
    } else {
      resolve({ width: 0, height: 0 })
      return
    }

    // Set event listeners
    const onMediaLoaded = () => {
      // Clean blob url
      URL.revokeObjectURL(url)

      // Resolve promise with width and height
      if (isImage) {
        resolve({ width: media.width, height: media.height })
      } else {
        resolve({ width: (media as HTMLVideoElement).videoWidth, height: (media as HTMLVideoElement).videoHeight })
      }
    }

    const onError = () => {
      // Clean blob url
      URL.revokeObjectURL(url)

      // Reject promise
      resolve({ width: 0, height: 0 })
    }

    media.addEventListener(isImage ? 'load' : 'loadedmetadata', onMediaLoaded)
    media.addEventListener('error', onError)

    // Start loading media
    media.src = url
  })
}
