import { Layout, Typography, Button, BUTTON_VARIANTS } from '@community_dev/pixels'
import { useTheme } from 'styled-components'

import { ROUTES } from 'constants/routes'

export function ErrorComponent(): JSX.Element {
  const { COLORS } = useTheme()

  return (
    <Layout
      alignItems="center"
      backgroundColor={COLORS?.APP_BACKGROUND_LEVEL_1}
      color={COLORS?.TEXT}
      display="flex"
      height="100vh"
      justifyContent="center"
      padding="16px"
      width="100vw"
    >
      <Layout textAlign="center">
        <Typography marginBottom="8px" variant="h1">
          Something went wrong.
        </Typography>
        <Typography component="div" margin="1em 0">
          Our team has been notified of the issue.
        </Typography>
        <Typography component="div" margin="1em 0 2em 0">
          Need help? Reach out to us at{' '}
          <a href="mailto:help@community.com" style={{ color: COLORS?.LINKS }}>
            help@community.com
          </a>
        </Typography>
        <a href={`${import.meta.env.VITE_PUBLIC_URL}${ROUTES.HOME}`}>
          <Button forwardAs="a" variant={BUTTON_VARIANTS.ACTION}>
            Take me home
          </Button>
        </a>
      </Layout>
    </Layout>
  )
}
