import {
  SelectorOperators,
  BuiltInFields,
  FieldSources,
  FieldTypes,
  fieldLabelFor,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { getMostActiveMembersAvailability, getMostActiveMembersFilters } from 'api/mostActiveMembers'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useClientId } from 'hooks/useClient'
import { useCountByQuery } from 'hooks/useCountByQuery/useCountByQuery'
import { useEveryoneCount } from 'hooks/useCountByQuery/useEveryoneCount'

const COUNT_STALE_TIME = STALE_TIME.TEN_MINUTES
const OPTIONS_STALE_TIME = STALE_TIME.FOREVER

export type MostActiveMembersOption = {
  percentage: number
  minimumMemberCount: number
}

type MostActiveMembersReturn = {
  mostActiveMembersOptions: MostActiveMembersOption[]
  totalMemberCount: number
  isMostActiveMembersEnabled: boolean
  isLoading: boolean
  isSuccess: boolean
}

export function isOption(option: any): option is MostActiveMembersOption {
  return Boolean(option.percentage)
}

export const useMostActiveMembers = (): MostActiveMembersReturn => {
  const clientId = useClientId()
  const { communicationChannel } = useFilters()
  const { data, isLoading, isSuccess } = useQuery(
    [QUERY_CACHE.MOST_ACTIVE_MEMBERS.FILTERS, { clientId }],
    getMostActiveMembersFilters,
    {
      staleTime: OPTIONS_STALE_TIME,
    },
  )

  const { data: mostActiveMembersAvailability, isLoading: isMostActiveMembersAvailabilityLoading } = useQuery(
    [QUERY_CACHE.SUBSCRIPTION_DATA.MOST_ACTIVE_MEMBERS_AVAILABILITY, { clientId }],
    () => getMostActiveMembersAvailability({ clientId }),
  )

  const isMostActiveMembersAvailable =
    Boolean(mostActiveMembersAvailability?.isAvailable) && communicationChannel !== CommunicationChannel.WHATS_APP

  const { data: { count: totalMemberCount = 0 } = {} } = useEveryoneCount({
    options: {
      enabled: isMostActiveMembersAvailable,
      staleTime: COUNT_STALE_TIME,
    },
    communicationChannel,
  })

  const twentyFiveQuery = useMemo(
    () => ({
      operator: SelectorOperators.LESS_THAN_OR_EQUALS,
      operand: {
        field_key: BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT,
        field_label: fieldLabelFor(BuiltInFields.MOST_ACTIVE_MEMBERS_PERCENT),
        source: FieldSources.BUILT_IN,
        type: FieldTypes.INTEGER,
        value: '25',
      },
    }),
    [],
  )

  const { isInitialLoading: isTwentyFiveLoading, data: { count: twentyFiveCount } = {} } = useCountByQuery({
    filters: twentyFiveQuery,
    communicationChannel,
    traceId: 'twenty-five-percent-count',
    options: {
      enabled: isMostActiveMembersAvailable,
      staleTime: COUNT_STALE_TIME,
    },
  })
  const mostActiveMembersOptions =
    isLoading || isTwentyFiveLoading || isMostActiveMembersAvailable === false
      ? []
      : data?.filter((option) => option.minimumMemberCount === null || option.minimumMemberCount <= totalMemberCount)

  return {
    isMostActiveMembersEnabled: isMostActiveMembersAvailable && (twentyFiveCount || 0) >= 5,
    isLoading: isLoading || isTwentyFiveLoading || isMostActiveMembersAvailabilityLoading,
    isSuccess,
    mostActiveMembersOptions,
    totalMemberCount: totalMemberCount,
  }
}
