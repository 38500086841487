import { useQuery } from '@tanstack/react-query'

import { getMessageLimits } from 'api/message-limits'
import { MessagesLimits } from 'constants/messages'
import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

type MessagesLimitsProps = {
  overrides?: any
  enabled?: boolean
}

type MessagesLimitsReturn = {
  monthlyThreshold: number
  monthlyRemaining: number | null
  monthlyUsed: number
  percentageUsed: number
  hasLimitEnabled: boolean
  hasWarningThresholdReached: boolean
  hasLimitReached: boolean
  isLoading: boolean
}

export function useMessagesLimits({ overrides = {}, enabled = true }: MessagesLimitsProps): MessagesLimitsReturn {
  const clientId = useClientId()
  const result = useQuery([QUERY_CACHE.MESSAGE_LIMITS, { clientId }], () => getMessageLimits({ clientId }), {
    enabled,
  })

  const data = { ...(result?.data || {}), ...overrides }

  const monthlyThreshold = data.campaignMessagesMonthlyThreshold
  const monthlyRemaining = data.campaignMessagesMonthlyRemaining
  const monthlyUsed = data.campaignMessagesMonthlyUsed
  const hasWarningThresholdReached = data.campaignMessagesWarningThreshold < monthlyUsed
  const hasLimitReached = data.campaignMessagesMonthlyRemaining < MessagesLimits.THROTTLED_LIMIT
  const percentageUsed = Math.ceil((monthlyUsed / monthlyThreshold) * 100)
  const hasLimitEnabled = typeof monthlyThreshold === 'undefined' ? false : monthlyThreshold > 0

  return {
    monthlyThreshold,
    monthlyRemaining,
    monthlyUsed,
    percentageUsed,
    hasLimitEnabled,
    hasWarningThresholdReached,
    hasLimitReached,
    isLoading: result?.isInitialLoading,
  }
}
