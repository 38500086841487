import { Typography, Layout, BUTTON_VARIANTS, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import copy from 'copy-to-clipboard'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BestPractices } from './components/BestPractices/BestPractices'
import { ShareCommunityUrl } from './components/ShareCommunityUrl'
import {
  StyledNumberButton,
  StyledCard,
  StyledGridLayout,
  StyledHelpLink,
  StyledHelpList,
  StyledImage,
  StyledNumberContainer,
  StyledPinLeftWrapper,
  StyledResponsiveWrapper,
  StyledRouterLink,
  StyledScrollContainer,
  StyledSection,
} from './GrowthTools.style'
import IconCommunityNumber from './svg/community-number.svg?react'
import IconCopy from './svg/copy.svg?react'
import IconHelpCaseStudies from './svg/help-case-studies.svg?react'
import IconHelpCommunityBlog from './svg/help-community-blog.svg?react'
import IconHelpContactUs from './svg/help-contact-us.svg?react'
import IconHelpFaq from './svg/help-faq.svg?react'
import IconHelpMessagingGuidelines from './svg/help-messaging-guidelines.svg?react'
import IconWebsite from './svg/website.svg?react'

import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { ShopifyIcon } from 'containers/Settings/components/Integrations/components/Icons/ShopifyIcon'
import { useMguQuery } from 'containers/Settings/components/Integrations/queries'
import useBreakpoints from 'hooks/useBreakpoints'
import { useClient, useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useShopify } from 'hooks/useShopify'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import MguExample from 'images/mgu-example.png'
import { SidebarLayout } from 'layouts/SidebarLayout'
import { InsightsMasthead } from 'screens/InsightsScreen/InsightsMasthead'
import analytics from 'utils/analytics'
import { route } from 'utils/router'

function GrowthTools(): JSX.Element {
  const { data: client } = useClient()
  const clientId = useClientId()
  const { md } = useBreakpoints()
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const isInsightsEnabled = useHasCapability(CAPABILITIES.FEATURE.INSIGHTS.ALL)
  const isShopifyEnabled = useHasCapability(CAPABILITIES.FEATURE.SHOPIFY_INTEGRATION)
  const { data: webCommunity } = useCommunities({
    select: (communities) => {
      return communities.all.find((c) => c.title === 'Web Visitors')
    },
  })
  const { data: shopifyCommunity } = useCommunities({
    select: (communities) => {
      return communities.protected.find((community) => community.title === 'Shopify')
    },
  })
  const { shopifyExists } = useShopify(clientId, shopifyCommunity)
  const { embeds } = useMguQuery(clientId, webCommunity)
  const hasCommunicationChannels = client && client.communicationChannels?.length > 0
  const hasSmsCommunicationChannel = client?.communicationChannels?.includes(CommunicationChannel.SMS)
  const hasWhatsAppCommunicationChannel = client?.communicationChannels?.includes(CommunicationChannel.WHATS_APP)
  const shouldDisplayShareNumberCard = hasSmsCommunicationChannel || hasWhatsAppCommunicationChannel
  const embedUrl = useMemo(() => {
    if (embeds) {
      const url = embeds[0]?.id ? ROUTES.SETTINGS.INTEGRATIONS.EDIT_EMBED : ROUTES.SETTINGS.INTEGRATIONS.CREATE_EMBED
      return route(url, {
        embedId: embeds[0]?.id,
      })
    }
  }, [embeds])

  return (
    <SidebarLayout greyBg>
      <StyledScrollContainer>
        <StyledPinLeftWrapper>
          <StyledResponsiveWrapper>
            <StyledSection style={{ paddingBottom: shouldDisplayShareNumberCard ? SPACING[6] : SPACING[1] }}>
              <Typography marginTop="0" variant="h1">
                {t('growthTools.tab')}
              </Typography>
              {shouldDisplayShareNumberCard && (
                <StyledCard>
                  <Layout alignItems="flex-start" display="flex" marginBottom={SPACING[5]}>
                    <IconCommunityNumber
                      style={{ flexShrink: 0, height: '76px', marginTop: SPACING[5], marginRight: SPACING[4] }}
                    />
                    <Layout>
                      <Typography
                        component="h3"
                        fontSize="21px"
                        marginBottom="0"
                        textTransform="capitalize"
                        variant="h2"
                      >
                        {t('growthTools.shareMyNumber')}
                      </Typography>
                      <StyledNumberContainer>
                        <Typography marginBottom={md ? 0 : '1em'} marginTop={SPACING[2]} variant="body2">
                          {t('growthTools.shareMyNumberSubtext')}
                        </Typography>
                        <StyledNumberButton
                          aria-label={t('growthTools.copyCommunityNumberToClipboard')}
                          onClick={() => {
                            copy(client?.phone || '')
                            showToastMessage({ message: t('copiedToClipboard') })
                            analytics.track(analytics.events.GrowthTools.PhoneNumberCopied())
                          }}
                          variant={BUTTON_VARIANTS.SOLID_PILL}
                        >
                          {client?.phone}
                          <IconCopy style={{ height: '20px', marginLeft: SPACING[3] }} />
                        </StyledNumberButton>
                      </StyledNumberContainer>
                    </Layout>
                  </Layout>
                  {hasCommunicationChannels && (
                    <ShareCommunityUrl communicationChannels={client.communicationChannels} />
                  )}
                </StyledCard>
              )}
            </StyledSection>

            {hasCommunicationChannels ? <BestPractices /> : null}
            {hasSmsCommunicationChannel && (
              <StyledSection>
                <Typography fontSize="24px" textTransform="capitalize" variant="h2">
                  {t('growthTools.growthIntegrations')}
                </Typography>
                <StyledGridLayout>
                  <StyledCard>
                    <Layout alignItems="center" display="flex" flexDirection="row">
                      <Layout display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                        <Layout>
                          <Layout alignItems="center" display="flex" height={SPACING[9]}>
                            <IconWebsite style={{ marginRight: SPACING[4] }} />
                            <Typography
                              component="h3"
                              fontSize="21px"
                              margin="0"
                              textTransform="capitalize"
                              variant="h2"
                            >
                              {t('growthTools.onMyWebsite')}
                            </Typography>
                          </Layout>
                          <Typography marginTop="0" variant="body2">
                            {t('growthTools.onMyWebsiteSubtext')}
                          </Typography>
                        </Layout>
                        {embedUrl && (
                          <StyledRouterLink
                            aria-label={t('growthTools.signUpUnitIntegration')}
                            onClick={() => analytics.track(analytics.events.GrowthTools.EmbedLinkClicked())}
                            to={embedUrl}
                          >
                            {embeds?.length > 0 ? t('edit') : t('getStarted')}
                          </StyledRouterLink>
                        )}
                      </Layout>
                      <StyledImage alt={t('growthTools.mguAltText')} src={MguExample} />
                    </Layout>
                  </StyledCard>
                  {isShopifyEnabled && (
                    <StyledCard>
                      <Layout display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                        <Layout>
                          <Layout alignItems="center" display="flex" height={SPACING[9]}>
                            <ShopifyIcon size={60} />
                            <Typography
                              component="h3"
                              fontSize="21px"
                              margin="0"
                              textTransform="capitalize"
                              variant="h2"
                            >
                              {t('growthTools.connectMyStore')}
                            </Typography>
                          </Layout>
                          <Typography marginTop="0" variant="body2">
                            {t('growthTools.connectMyStoreSubtext')}
                          </Typography>
                        </Layout>
                        <StyledRouterLink
                          aria-label={t('growthTools.shopifyIntegration')}
                          onClick={() => analytics.track(analytics.events.GrowthTools.ShopifyLinkClicked())}
                          to={ROUTES.SETTINGS.INTEGRATIONS.SHOPIFY.CONNECT_STORE}
                        >
                          {shopifyExists ? t('edit') : t('getStarted')}
                        </StyledRouterLink>
                      </Layout>
                    </StyledCard>
                  )}
                </StyledGridLayout>
              </StyledSection>
            )}

            {hasSmsCommunicationChannel && isInsightsEnabled && (
              <StyledSection>
                <Layout alignItems="center" display="flex" justifyContent="space-between">
                  <Typography fontSize="24px" textTransform="capitalize" variant="h2">
                    {t('growthTools.memberGrowth')}
                  </Typography>
                  <StyledRouterLink
                    onClick={() => analytics.track(analytics.events.GrowthTools.InsightsLinkClicked())}
                    to={ROUTES.INSIGHTS.ROOT}
                  >
                    {t('growthTools.seeMoreInsights')}
                  </StyledRouterLink>
                </Layout>
                <InsightsMasthead locked={false} />
              </StyledSection>
            )}

            <StyledSection>
              <Typography fontSize="24px" textTransform="capitalize" variant="h2">
                {t('growthTools.needHelp')}
              </Typography>
              <StyledHelpList>
                <li>
                  <IconHelpFaq />
                  <StyledHelpLink href="https://incommunity.zendesk.com/hc/en-us" target="_blank">
                    {t('growthTools.leaderResources')}
                  </StyledHelpLink>
                </li>
                <li>
                  <IconHelpMessagingGuidelines />
                  <StyledHelpLink href="https://r.community.com/messaging-guidelines" target="_blank">
                    {t('growthTools.messagingGuidelines')}
                  </StyledHelpLink>
                </li>
                <li>
                  <IconHelpCommunityBlog />
                  <StyledHelpLink href="https://www.community.com/blog" target="_blank">
                    {t('growthTools.blog')}
                  </StyledHelpLink>
                </li>
                <li>
                  <IconHelpCaseStudies />
                  <StyledHelpLink href="https://www.community.com/case-studies" target="_blank">
                    {t('growthTools.caseStudies')}
                  </StyledHelpLink>
                </li>
                <li>
                  <IconHelpContactUs />
                  <StyledHelpLink href="mailto:yourfriends@community.com">{t('growthTools.contactUs')}</StyledHelpLink>
                </li>
              </StyledHelpList>
            </StyledSection>
          </StyledResponsiveWrapper>
        </StyledPinLeftWrapper>
      </StyledScrollContainer>
    </SidebarLayout>
  )
}

export default GrowthTools
