import { FONT_SIZE, FONT_WEIGHT, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { ZapierIcon } from './components/Icons/ZapierIcon'

import { BetaBadge } from 'components/BetaBadge'
import { useAddons } from 'hooks/useAddons'
import { Setting } from 'layouts/SettingsLayout'
import { DynamicButton } from 'screens/DetailedAddOnScreen/DynamicButton'

const StyledSetting = styled(Setting)`
  button {
    padding: 0;
    border: none;
    background: none;
    color: ${({ theme }) => theme?.COLORS.TEXT};
    font-weight: ${FONT_WEIGHT[6]};
    font-size: ${FONT_SIZE[3]};
    line-height: 18px;
    margin-left: ${SPACING[4]};
    white-space: nowrap;
    :hover {
      background: none;
    }
  }
  div:first-child {
    align-self: center;
  }
`

export function Zapier(): JSX.Element {
  const { allAddOns } = useAddons()
  const zapierAddOn = allAddOns?.find((addOn) => addOn.title === 'Zapier')

  return (
    <StyledSetting
      action={zapierAddOn ? <DynamicButton {...zapierAddOn?.details.action} /> : null}
      actionAs="div"
      icon={<ZapierIcon size={17} />}
      label={
        <>
          Zapier <BetaBadge />
        </>
      }
      loading={!zapierAddOn}
    />
  )
}
