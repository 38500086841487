import { WorkflowCardConfig, WORKFLOW_CARDS } from '@community_dev/flexible-canvas'
import { Info, Layout } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { WorkflowType } from '@community_dev/workflow-manifest'
import { useTranslation } from 'react-i18next'

import { WorkflowSidebarNavigation } from '../WorkflowSidebarNavigation'

import { Wrapper, StyledHeading, StyledCardDraggable } from './WorkflowSidebarEditFlow.style'

import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { useClient } from 'hooks/useClient'

export function WorkflowSidebarEditFlow(): JSX.Element | null {
  const { workflow } = useWorkflowProvider()
  const { data: client } = useClient()
  const isWorkflowLogicStepEnabled = workflow?.type === WorkflowType.signup
  const { t } = useTranslation()
  const hasAmb = client?.communicationChannels.includes(CommunicationChannel.AMB)
  const hasWhatsApp = client?.communicationChannels.includes(CommunicationChannel.WHATS_APP)
  return (
    <>
      <WorkflowSidebarNavigation />
      <Layout
        display="flex"
        flex="1"
        flexDirection="column"
        flexWrap="initial"
        height="100%"
        justifyContent="space-between"
        overflow="auto"
        padding="0px 16px 16px"
      >
        <Wrapper>
          <StyledHeading>{t('automations.steps')}</StyledHeading>
          {[
            WORKFLOW_CARDS.SLEEP,
            isWorkflowLogicStepEnabled && WORKFLOW_CARDS.LOGIC,
            WORKFLOW_CARDS.SEND_MESSAGE,
            WORKFLOW_CARDS.ADD_TO_COMMUNITY,
          ]
            .filter((card): card is WorkflowCardConfig => Boolean(card))
            .map((card, i) => {
              return (
                <StyledCardDraggable
                  backgroundColor={card.backgroundColor}
                  borderColor={card.borderColor}
                  icon={card.icon}
                  iconColor={card.iconColor}
                  key={`${card.type}-${i}`}
                  subtitle={card.subtitle}
                  title={card.title}
                  type={card.type}
                />
              )
            })}
        </Wrapper>
        {(hasAmb || hasWhatsApp) && (
          <Info css={{ textAlign: 'left' }}>{t('automations.warningOtherCommunicationChannels')}</Info>
        )}
      </Layout>
    </>
  )
}
