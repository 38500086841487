import React from 'react'

import { IconProps } from './IconProps'

export const CogIcon = ({ color = 'currentColor', size = 24 }: IconProps): JSX.Element => (
  <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.964 13.1068L19.3103 13.8824C19.4641 13.971 19.5354 14.1542 19.4828 14.3234C19.1331 15.4473 18.536 16.4626 17.7551 17.3059C17.6966 17.3691 17.6184 17.4105 17.5333 17.4234C17.4481 17.4363 17.3611 17.42 17.2865 17.377L15.9412 16.6015C15.3752 17.0855 14.7245 17.4611 14.0218 17.7092V19.26C14.0218 19.346 13.9925 19.4295 13.9386 19.4966C13.8848 19.5638 13.8096 19.6106 13.7256 19.6295C12.6213 19.877 11.4352 19.8898 10.2752 19.6297C10.1018 19.5908 9.97817 19.4375 9.97817 19.2601V17.7092C9.27546 17.4611 8.62473 17.0855 8.05872 16.6015L6.71344 17.377C6.63885 17.42 6.55183 17.4363 6.46668 17.4234C6.38154 17.4105 6.30332 17.3691 6.24488 17.3059C5.46401 16.4626 4.86691 15.4473 4.51719 14.3234C4.46453 14.1542 4.53589 13.971 4.68965 13.8824L6.03597 13.1068C5.90074 12.3751 5.90074 11.6249 6.03597 10.8932L4.68968 10.1176C4.53593 10.029 4.46456 9.84584 4.51722 9.67661C4.86694 8.55274 5.46401 7.5374 6.24491 6.6941C6.30335 6.63096 6.38157 6.58953 6.46671 6.57661C6.55186 6.5637 6.63888 6.58007 6.71347 6.62303L8.05875 7.39856C8.62477 6.9145 9.2755 6.53895 9.9782 6.29084V4.73999C9.9782 4.65398 10.0075 4.57053 10.0614 4.50337C10.1152 4.43622 10.1904 4.38935 10.2744 4.3705C11.3787 4.12297 12.5648 4.11017 13.7248 4.37028C13.8982 4.40916 14.0218 4.5625 14.0218 4.73993V6.29081C14.7245 6.53891 15.3753 6.91445 15.9413 7.39853L17.2866 6.623C17.3612 6.58003 17.4482 6.56367 17.5333 6.57658C17.6185 6.5895 17.6967 6.63093 17.7551 6.69407C18.536 7.53737 19.1331 8.55271 19.4828 9.67658C19.5355 9.84578 19.4641 10.0289 19.3104 10.1176L17.964 10.8932C18.0993 11.6249 18.0993 12.3751 17.964 13.1068ZM14.5273 12C14.5273 10.609 13.3935 9.47742 12 9.47742C10.6065 9.47742 9.47271 10.609 9.47271 12C9.47271 13.3909 10.6065 14.5226 12 14.5226C13.3935 14.5226 14.5273 13.3909 14.5273 12Z"
      fill={color}
    />
  </svg>
)
