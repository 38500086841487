import { Placeholder } from '@community_dev/filter-dsl/lib/subscription-data'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
  TextNode,
} from 'lexical'
import { useEffect } from 'react'

import { $createPlaceholderNode, PlaceholderNode, PlaceholderPayload } from '../nodes/PlaceholderNode'

export type InsertPlaceholderPayload = Readonly<PlaceholderPayload>

export const INSERT_PLACEHOLDER_COMMAND: LexicalCommand<PlaceholderPayload> = createCommand()

type UsePlaceholderPluginProps = {
  enabled?: boolean
  placeholders: Placeholder[]
}

/**
 * This plugin adds support for inserting a dynamic field placeholder at the current selection
 *
 * @example
 * const [editor] = useLexicalComposerContext()
 * editor.dispatchCommand(INSERT_PLACEHOLDER_COMMAND, {
 *   id: 'first_name',
 *   name: 'First Name',
 *   source: 'built_in'
 * })
 */
export function usePlaceholderPlugin({ enabled = true, placeholders }: UsePlaceholderPluginProps): null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([PlaceholderNode])) {
      throw new Error('usePlaceholderPlugin: PlaceholderNode not registered on editor')
    }
  }, [editor])

  useEffect(() => {
    if (!enabled) return

    return editor.registerNodeTransform(TextNode, (textNode) => {
      const textContent = textNode.getTextContent()
      // Regex to match text that is wrapped in curly braces
      const match = /\{([^}]+)\}/i.exec(textContent)

      if (!match) return

      const placeholderMatch = placeholders.find(
        (p) => p.name.toLowerCase() === match[1].toLowerCase() || `${p.key}:${p.source}` === match[1],
      )

      if (!placeholderMatch) return

      const placeholderNode = $createPlaceholderNode(
        placeholderMatch.key,
        placeholderMatch.name,
        placeholderMatch.source,
      )

      // If full text content matches, we can replace existing textNode
      // otherwise we need to split up the text node based on match index
      if (match[0] === textContent) {
        textNode.replace(placeholderNode)
        return
      }

      const startIndex = match.index || 0
      const endIndex = startIndex + match[0].length

      if (startIndex === 0) {
        const [currentNode] = textNode.splitText(endIndex)
        currentNode.replace(placeholderNode)
      } else {
        const [, currentNode] = textNode.splitText(startIndex, endIndex)
        currentNode.replace(placeholderNode)
      }
    })
  }, [editor, enabled])

  useEffect(() => {
    if (!enabled) return

    const insertPlaceholder = ({ id, name, source }) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          const placeholder = $createPlaceholderNode(id, name, source)
          // Insert node at cursor, replacing any highlighted text
          selection.insertNodes([placeholder])
        }
      })

      // Returning true indicates that command is handled and no further propagation is required
      return true
    }

    // Registering custom command that will be handled by this plugin
    return editor.registerCommand(INSERT_PLACEHOLDER_COMMAND, insertPlaceholder, COMMAND_PRIORITY_EDITOR)
  }, [editor, enabled])

  return null
}
