import { BUTTON_VARIANTS, Button, SPACING, TextInput, Box } from '@community_dev/pixels'
import { useMutation } from '@tanstack/react-query'
import { ReactNode, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { putExistingSeat } from 'api/login'
import { BoxForm } from 'components/form'
import { ROUTES } from 'constants/routes'
import { MOBILE_BREAK, TABLET_BREAK } from 'constants/theme'
import { AuthActionTypes } from 'contexts/AuthProvider'
import { useAuth } from 'hooks/useAuth'
import { isAppleMobile } from 'utils/detectors'
import { humanizeInviteError } from 'utils/errors'

const StyledHelperText = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  width: 100%;
  text-align: start;
  padding-bottom: ${SPACING[5]};
  margin-top: -${SPACING[2]};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledTitle = styled.h1`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H1};
  width: 100%;
  margin: ${SPACING[4]} 0;
  text-align: center;

  @media (max-width: ${TABLET_BREAK}) {
    text-align: left;
    max-width: 464px;
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  }

  @media (max-width: ${MOBILE_BREAK}) {
    max-width: 350px;
  }
`

const StyledSubtitle = styled.div`
  text-align: center;
  margin-bottom: ${SPACING[5]};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};

  @media (max-width: ${TABLET_BREAK}) {
    text-align: left;
    max-width: 464px;
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  }

  @media (max-width: ${MOBILE_BREAK}) {
    max-width: 350px;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledLegalText = styled.div`
  width: 100%;
  text-align: center;
  padding: ${SPACING[5]} 0 0;

  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION2};

  a {
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  }

  @media (max-width: ${MOBILE_BREAK}) {
    padding: 0;
  }
`

type ExistingInvitationFormProps = {
  error?: ReactNode
  buttonText?: string
  titleText?: string
  subtitleText?: string
  seatId: string
  token: string
  email: string
}

export function ExistingInvitationForm({
  error,
  buttonText = 'Login',
  titleText = 'Accept Invitation',
  seatId,
  subtitleText = '',
  token,
  email: seatEmail,
}: ExistingInvitationFormProps): JSX.Element {
  const { login, transition } = useAuth()
  const history = useHistory()
  const [submitError, setSubmitError] = useState<{ status: number }>()
  const [processing, setProcessing] = useState(false)
  const { mutate: submit } = useMutation(putExistingSeat, {
    onError(err: any, { body: submittedValues }) {
      if (err.status === 409) {
        login(seatEmail, submittedValues.password)
      } else {
        setSubmitError(err)
      }
      setProcessing(false)
    },
    onSuccess({ token, id: clientId, mfa }) {
      setProcessing(false)
      if (mfa) {
        transition({
          type: AuthActionTypes.MFA_REQUIRED,
          payload: { mfaToken: token, clientId },
        })
      } else {
        transition({
          type: AuthActionTypes.LOGIN_SUCCESS_SKIP_SELECTION,
          payload: { token, clientId },
        })
        if (isAppleMobile(navigator.platform)) {
          history.replace(ROUTES.ONBOARDING.GET_THE_APP)
        } else {
          history.replace(ROUTES.HOME)
        }
      }
    },
  })

  function handleSubmit(body: { password: string }) {
    setSubmitError(undefined)
    setProcessing(true)
    submit({ seatId, body, token })
  }

  return (
    <>
      <StyledTitle>You've been invited as a Team Member</StyledTitle>
      <StyledSubtitle>Enter your Community password to accept your invite.</StyledSubtitle>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit, valid, pristine }) => (
          <BoxForm
            error={error || (submitError && humanizeInviteError(submitError))}
            onSubmit={handleSubmit}
            subtitle={subtitleText}
            title={titleText}
          >
            <Field name="password">
              {({ input, meta }) => (
                <TextInput
                  autoFocus
                  error={(!meta.active && meta.touched && meta.modified && meta.error) || ''}
                  label="Password"
                  type="password"
                  {...input}
                />
              )}
            </Field>

            <StyledHelperText>Enter your existing Community password</StyledHelperText>

            <StyledLegalText>
              By clicking "{buttonText}", you agree to our{' '}
              <a href="https://www.community.com/client-terms" rel="noopener noreferrer" target="_blank">
                Terms of Use
              </a>{' '}
              and{' '}
              <a href="https://www.community.com/privacy" rel="noopener noreferrer" target="_blank">
                Privacy Policy
              </a>
            </StyledLegalText>

            <StyledButtonContainer>
              <Button
                disabled={!valid || processing || pristine}
                type="submit"
                variant={BUTTON_VARIANTS.ACTION}
                width="100%"
              >
                {buttonText}
              </Button>
            </StyledButtonContainer>
          </BoxForm>
        )}
      />
      <Box.Footer>
        <Button forwardedAs={Link} to={ROUTES.RESET_PASSWORD} variant={BUTTON_VARIANTS.LINK}>
          Forgot password?
        </Button>
      </Box.Footer>
    </>
  )
}
