import { WorkflowType } from '@community_dev/workflow-manifest'

type WorkflowNameOptions = {
  keywords: string[]
}

function makeKeywordWorkflowName(keywords: string[] = []): string {
  const ellipsis = ' …'
  const keywordNameMaxLength = 128
  return [...keywords].reduce((acc, keyword, index, arr) => {
    const delimiter = index === 0 ? ' – ' : ', '
    const nextAcc = `${acc}${delimiter}${keyword}`
    if (nextAcc.length <= keywordNameMaxLength - ellipsis.length) {
      return nextAcc
    } else {
      // equivalent to a "break" statement, as we don’t want to continue
      // adding keywords after this.
      arr.splice(1)
      return `${acc}${ellipsis}`
    }
  }, 'Keyword')
}

export function workflowName(type?: WorkflowType, options?: WorkflowNameOptions): string {
  switch (type) {
    case WorkflowType.signup:
      return 'Signup Series'
    case WorkflowType.shopify_abandoned_checkout:
      return 'Shopify Checkout Abandoned'
    case WorkflowType.keyword:
      return makeKeywordWorkflowName(options?.keywords)
    default:
      return 'New Workflow'
  }
}
