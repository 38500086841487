import { Info, Layout, SPACING, Tooltip, Typography } from '@community_dev/pixels'
import IconWave from '@community_dev/pixels/dist/svg/illustrations/wave.svg?react'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import {
  makeShopifyAbandonedCheckoutTemplate,
  makeSignupSeriesTemplate,
  WorkflowType,
} from '@community_dev/workflow-manifest'
import formatISO from 'date-fns/formatISO'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'

import IconAbandonedCheckout from './svg/abandoned-checkout.svg?react'
import IconClose from './svg/close.svg?react'
import IconKeywordResponse from './svg/keyword-response.svg?react'
import { WorkflowCreateCard, WorkflowCreateCardProps } from './WorkflowCreateCard'
import { ResponsiveWrapper, StyledCloseButton } from './WorkflowsNewScreen.style'

import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { useShopifyAbandonedCheckout } from 'components/WorkflowEditor/hooks/useShopifyAbandonedCheckout'
import { useWelcomeSeries } from 'components/WorkflowEditor/hooks/useWelcomeSeries'
import { workflowName } from 'components/WorkflowEditor/utils/workflowName'
import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { WORKFLOW_ID_UNSAVED } from 'constants/workflow'
import { useClient, useClientId } from 'hooks/useClient'
import { useShopifyStoreDetails } from 'hooks/useShopify'
import { useHasCapability } from 'hooks/useUserCapability'
import analytics from 'utils/analytics'
import { route } from 'utils/router'

type WorkflowCreateCardPropsWithExtra = WorkflowCreateCardProps & {
  enabled: boolean
  deactivated?: boolean
  deactivatedTooltip?: string
}

export function WorkflowsNewScreen(): JSX.Element {
  const { t } = useTranslation()
  const { data: client } = useClient()
  const clientId = useClientId()
  const theme = useTheme()
  const { dispatch } = useWorkflowProvider()
  const isShopifyEnabled = useHasCapability(CAPABILITIES.FEATURE.SHOPIFY_INTEGRATION)
  const hasAmb = client?.communicationChannels.includes(CommunicationChannel.AMB)
  const hasWhatsApp = client?.communicationChannels.includes(CommunicationChannel.WHATS_APP)
  const isAbandonedCheckoutEnabled =
    // the feature flag is present
    useHasCapability(CAPABILITIES.FEATURE.WORKFLOWS.ABANDONED_CHECKOUT) &&
    // and, the minimum fan age is not set, or it’s lte 13
    (!client?.minimumFanAge || client?.minimumFanAge <= 13)

  const { shopifyExists } = useShopifyStoreDetails(clientId)
  const welcomeSeriesWorkflow = useWelcomeSeries()
  const abandonedCheckoutWorkflow = useShopifyAbandonedCheckout()

  const history = useHistory()

  const WORKFLOW_CREATE_CARDS: WorkflowCreateCardPropsWithExtra[] = [
    {
      title: t('automations.welcomeSeries'),
      description: t('automations.createSignUpFlowDescription'),
      enabled: true,
      deactivated: welcomeSeriesWorkflow !== undefined,
      deactivatedTooltip: 'You can only create one welcome series at a time',
      color: welcomeSeriesWorkflow === undefined ? '#D0F5D5' : theme.COLORS.APP_BACKGROUND_LEVEL_1,
      icon: <IconWave width="74px" />,
      overline: 'Template',
      onClick: async () => {
        const manifest = makeSignupSeriesTemplate()
        dispatch({
          clearHistory: true,
          state: {
            workflow: {
              id: WORKFLOW_ID_UNSAVED,
              name: workflowName(WorkflowType.signup),
              created_at: formatISO(new Date()),
              type: WorkflowType.signup,
              active_end_at: null,
              active_start_at: null,
              updated_at: formatISO(new Date()),
            },
            manifest: manifest,
          },
          type: 'update',
        })
        history.push(route(ROUTES.AUTOMATIONS.FLOW, { workflowId: WORKFLOW_ID_UNSAVED }))
        analytics.track(analytics.events.Workflows.Created({ type: WorkflowType.signup }))
      },
      'aria-label': 'Create a welcome series',
    },
    {
      title: t('automations.keywordResponse'),
      description: t('automations.createKeywordFlowDescription'),
      enabled: true,
      color: '#1e90ff',
      icon: <IconKeywordResponse />,
      overline: 'Template',
      'aria-label': 'Create a keyword response',
      onClick: async () => {
        history.push(ROUTES.AUTOMATIONS.FLOWS_ADD_KEYWORD_RESPONSE)
        analytics.track(analytics.events.Workflows.Created({ type: WorkflowType.keyword }))
      },
    },
    {
      enabled: isShopifyEnabled && isAbandonedCheckoutEnabled && shopifyExists,
      deactivated: abandonedCheckoutWorkflow !== undefined,
      deactivatedTooltip: 'You can only create one Shopify Abandoned Checkout series at a time',
      color: abandonedCheckoutWorkflow === undefined ? '#D0F5D5' : theme.COLORS.APP_BACKGROUND_LEVEL_1,
      icon: <IconAbandonedCheckout />,
      overline: 'Template',
      title: t('automations.shopifyAbandonedCheckout'),
      description: t('automations.createShopifyFlowDescription'),
      onClick: async () => {
        const manifest = makeShopifyAbandonedCheckoutTemplate()
        dispatch({
          clearHistory: true,
          state: {
            workflow: {
              id: WORKFLOW_ID_UNSAVED,
              name: workflowName(WorkflowType.shopify_abandoned_checkout),
              created_at: formatISO(new Date()),
              type: WorkflowType.shopify_abandoned_checkout,
              updated_at: formatISO(new Date()),
              active_end_at: null,
              active_start_at: null,
            },
            manifest: manifest,
          },
          type: 'update',
        })
        history.push(route(ROUTES.AUTOMATIONS.FLOW, { workflowId: WORKFLOW_ID_UNSAVED }))
        analytics.track(analytics.events.Workflows.Created({ type: WorkflowType.shopify_abandoned_checkout }))
      },
      'aria-label': 'Create an abandoned checkout flow',
    },
  ]

  return (
    <ResponsiveWrapper>
      <Layout margin="64px auto 0" maxWidth="600px" textAlign="center">
        <Typography variant="h1">{t('automations.newFlowHeadline')}</Typography>
        {(hasAmb || hasWhatsApp) && (
          <Info css={{ marginBottom: SPACING[6], marginTop: SPACING[3], textAlign: 'left' }}>
            {t('automations.warningOtherCommunicationChannels')}
          </Info>
        )}
        <Layout margin="0 auto" maxWidth="360px">
          {WORKFLOW_CREATE_CARDS.filter((c) => c.enabled).map((workflowCardProps, i) => {
            const tooltipContent = workflowCardProps.deactivated ? workflowCardProps.deactivatedTooltip : undefined
            return (
              <Tooltip content={tooltipContent} disabled={!workflowCardProps.deactivated} key={i} placement="bottom">
                <span>
                  <WorkflowCreateCard
                    disabled={workflowCardProps.deactivated}
                    key={i}
                    {...workflowCardProps}
                    style={{ marginBottom: 24 }}
                  />
                </span>
              </Tooltip>
            )
          })}
        </Layout>
      </Layout>
      <StyledCloseButton
        aria-label="Close"
        onClick={() => {
          history.push(ROUTES.AUTOMATIONS.FLOWS)
        }}
      >
        <IconClose />
      </StyledCloseButton>
    </ResponsiveWrapper>
  )
}
