import { BORDER_RADIUS, ChevronRightIcon, SPACING } from '@community_dev/pixels'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'

export type ExpandableListProps = {
  Icon: React.ElementType
  title: string
  list: string[]
  defaultOpen: boolean
  className?: string
}

type StyledIsOpenProps = {
  $isOpen: boolean
}
const StyledListContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  border-radius: ${BORDER_RADIUS[1]};
  overflow: hidden;
`
const StyledListHeader = styled.button<StyledIsOpenProps>`
  cursor: pointer;
  height: 48px;
  padding: 0 ${SPACING[4]};
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  border: ${({ $isOpen }) => ($isOpen ? 'unset' : 'none')};
  border-bottom: ${({ $isOpen }) => ($isOpen ? `solid 1px ${({ theme }) => theme?.COLORS?.BORDERS}` : 'none')};
`
const StyledListHeaderTitle = styled.h4`
  margin: 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
`

const StyledList = styled.ol`
  padding: 0 ${SPACING[4]} 0 ${SPACING[5]};
  list-style: none;
  counter-reset: li;
`

const StyledListItem = styled.li`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  margin-bottom: ${SPACING[4]};
  counter-increment: li;
  list-style-position: inside;
  &:before {
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
    content: counter(li) '. ';
  }
`
const StyledIconContainer = styled.div`
  margin-right: ${SPACING[2]};
`
const StyledArrowIconContainer = styled.div<StyledIsOpenProps>`
  margin-left: auto;
  transform: ${({ $isOpen }) => ($isOpen ? `rotate(90deg)` : `rotate(0deg)`)};
`
export const ExpandableList = ({ Icon, title, list, defaultOpen, className }: ExpandableListProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const [isOpen, setIsOpen] = useState(defaultOpen)

  return (
    <StyledListContainer className={className}>
      <StyledListHeader $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} type="button">
        <StyledIconContainer>
          <Icon />
        </StyledIconContainer>
        <StyledListHeaderTitle>{title}</StyledListHeaderTitle>
        <StyledArrowIconContainer $isOpen={isOpen}>
          <ChevronRightIcon color={COLORS?.SUBTEXT} />
        </StyledArrowIconContainer>
      </StyledListHeader>
      {isOpen && (
        <StyledList>
          {list.map((item, index) => (
            <StyledListItem key={`${item}-${index}`}>{item}</StyledListItem>
          ))}
        </StyledList>
      )}
    </StyledListContainer>
  )
}
