import { DIALOG_VARIANTS, Dialog } from '@community_dev/pixels'
import { useEffect, useState } from 'react'

import { Prompt } from './EditMessage'

type OnSavePromptsProps = {
  isOpen: boolean
  textValue: string
  prompts?: Prompt[]
  onSave: () => void
  onCancel: () => void
}

// Once the prompts are open filter out the prompts that should not show
const toFilteredPrompts = (prompts, textValue, isOpen) => {
  if (!isOpen) return prompts
  return prompts.filter(({ shouldPromptWhen = () => true }: Prompt) => shouldPromptWhen(textValue))
}
export const OnSavePrompts = ({
  isOpen,
  prompts = [],
  textValue,
  onCancel,
  onSave,
}: OnSavePromptsProps): JSX.Element => {
  const filteredPrompts = toFilteredPrompts(prompts, textValue, isOpen)
  const [currentPromptIndex, setCurrentPromptIndex] = useState(0)
  const currentPrompt = filteredPrompts[currentPromptIndex]
  const isLastPrompt = filteredPrompts.length - 1 === currentPromptIndex

  // if it opens or close reset index
  useEffect(() => setCurrentPromptIndex(0), [isOpen, setCurrentPromptIndex])

  const onNextPrompt = () => {
    if (isLastPrompt) {
      onSave()
      setCurrentPromptIndex(0)
      return
    }
    setCurrentPromptIndex(currentPromptIndex + 1)
  }

  const shouldCancel = () => {
    setCurrentPromptIndex(0)
    onCancel()
  }

  return (
    <>
      {isOpen && currentPrompt ? (
        <Dialog message={currentPrompt.message} title={currentPrompt.title}>
          <Dialog.Action onClick={shouldCancel}>{currentPrompt.cancelLabel}</Dialog.Action>
          <Dialog.Action onClick={onNextPrompt} variant={DIALOG_VARIANTS.EMPHASIZED}>
            {currentPrompt.saveLabel}
          </Dialog.Action>
        </Dialog>
      ) : null}
    </>
  )
}
