import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  isRelationshipOperator,
  isSelectorOperator,
  MemberDataFilter,
  RelationshipOperators,
  SelectorOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { BUTTON_VARIANTS, Button, SPACING, Select, required } from '@community_dev/pixels'
import { ChangeEvent } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  padding: 0 ${SPACING[2]};

  > * {
    margin: 0 6px;
  }

  > span {
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
    font-weight: 600;
  }
`

const StyledSubmitButton = styled(Button)`
  padding: 10px 2px;
  border-radius: 6px;
`

const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  margin: 0;
  padding: 10px;
  border-radius: 6px;
  width: 60px;
`

type Option = { value: SelectorOperators | RelationshipOperators; label: string }

const options: Option[] = [
  { value: RelationshipOperators.AND, label: 'between' },
  { value: SelectorOperators.LESS_THAN, label: 'under' },
  { value: SelectorOperators.GREATER_THAN_OR_EQUALS, label: 'over' },
  { value: SelectorOperators.EQUALS, label: 'exactly' },
]

function preventNonNumberInput(event: ChangeEvent<HTMLInputElement>) {
  event.currentTarget.value = event.currentTarget.value.replace(/\D/g, '')
}

type CustomAgeFormProps = {
  onAdd(filter: MemberDataFilter): void
}

export function CustomAgeForm({ onAdd }: CustomAgeFormProps): JSX.Element {
  function handleSubmit({
    operator,
    startValue,
    endValue,
  }: {
    operator: {
      value: RelationshipOperators | SelectorOperators
      label: string
    }
    startValue: string
    endValue: string
  }) {
    if (isRelationshipOperator(operator.value)) {
      onAdd({
        operator: operator.value,
        operands: [
          {
            operator: SelectorOperators.GREATER_THAN_OR_EQUALS,
            operand: {
              field_key: BuiltInFields.AGE,
              field_label: fieldLabelFor(BuiltInFields.AGE),
              source: FieldSources.BUILT_IN,
              type: FieldTypes.INTEGER,
              value: startValue,
            },
          },
          {
            operator: SelectorOperators.LESS_THAN_OR_EQUALS,
            operand: {
              field_key: BuiltInFields.AGE,
              field_label: fieldLabelFor(BuiltInFields.AGE),
              source: FieldSources.BUILT_IN,
              type: FieldTypes.INTEGER,
              value: endValue,
            },
          },
        ],
      })

      return
    }

    if (isSelectorOperator(operator.value)) {
      onAdd({
        operator: operator.value,
        operand: {
          field_key: BuiltInFields.AGE,
          field_label: fieldLabelFor(BuiltInFields.AGE),
          source: FieldSources.BUILT_IN,
          type: FieldTypes.INTEGER,
          value: startValue,
        },
      })
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, valid, pristine, values, form }) => (
        <StyledForm
          onSubmit={(event) => {
            handleSubmit(event)
            form.reset()
          }}
        >
          <Field name="operator" validate={required} validateOnBlur>
            {({ input }) => (
              <Select
                inline
                menuPlacement="auto"
                menuPortalTarget={document.body}
                options={options}
                width="130px"
                {...input}
              />
            )}
          </Field>
          {values.operator && (
            <>
              <Field name="startValue" validate={required} validateOnBlur>
                {({ input }) => (
                  <StyledInput
                    id="value"
                    type="text"
                    {...input}
                    aria-label={values.operator.value === RelationshipOperators.AND ? 'Start value' : 'Value'}
                    onInput={preventNonNumberInput}
                  />
                )}
              </Field>
              {values.operator.value === RelationshipOperators.AND && (
                <>
                  <span>and</span>
                  <Field name="endValue" validate={required} validateOnBlur>
                    {({ input }) => (
                      <StyledInput
                        id="end"
                        type="text"
                        {...input}
                        aria-label="End value"
                        onInput={preventNonNumberInput}
                      />
                    )}
                  </Field>
                </>
              )}
            </>
          )}
          <StyledSubmitButton disabled={!valid || pristine} type="submit" variant={BUTTON_VARIANTS.PRIMARY}>
            Apply
          </StyledSubmitButton>
        </StyledForm>
      )}
    />
  )
}
