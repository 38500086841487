import { Button, SpeechBubble } from '@community_dev/pixels'
import React from 'react'
import { Link } from 'react-router-dom'

import EmptyBlock from 'components/EmptyBlock'
import { ROUTES } from 'constants/routes'

type InboxChatEmptyStateProps = {
  search?: string
}

export function InboxChatEmptyState({ search }: InboxChatEmptyStateProps): JSX.Element {
  const noSearchResults = !!search

  return (
    <EmptyBlock
      button={
        !noSearchResults && (
          <Link to={ROUTES.GROWTH_TOOLS}>
            <Button>Promote Your Number</Button>
          </Link>
        )
      }
      glyph={<SpeechBubble />}
      subtitle={noSearchResults ? `'${search}' did not match any fan names` : 'Your Direct Messages will appear here'}
      title={noSearchResults ? 'No Results Found' : 'No Direct Messages'}
    />
  )
}
