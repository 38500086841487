import 'what-input'

import { DndProvider, FlexibleCanvasProvider } from '@community_dev/flexible-canvas'
import { ErrorBoundary, GlobalStyle } from '@community_dev/pixels'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createBrowserHistory } from 'history'
import { SetupWorker } from 'msw'
import { createRoot } from 'react-dom/client'
import { Router } from 'react-router-dom'

import { initialize } from './i18n'

import { ErrorComponent } from 'components/ErrorComponent'
import App from 'containers/App'
import { AuthProvider } from 'contexts/AuthProvider'
import { LaunchDarklyProvider } from 'contexts/LaunchDarklyProvider/LaunchDarklyProvider'
import { SettingsProvider } from 'contexts/SettingsProvider'
import { ThemeProvider } from 'contexts/ThemeProvider'
import Sentry from 'integrations/Sentry'
import { Appcues } from 'utils/appcues'

import 'utils/moment-config'
import 'integrations/Sentry'
/* eslint-disable no-param-reassign */

initialize()

declare global {
  interface Window {
    Cypress: unknown
    msw: SetupWorker
    zESettings: {
      webWidget: {
        launcher?: {
          label?: {
            [key: string]: string
          }
        }
        contactForm?: {
          fields?: {
            id?: string | number
            prefill?: { [key: string]: string }
            readOnly?: true
          }[]
        }
        color?: {
          launcher?: string
          launcherText?: string
        }
      }
    }
    zE(command: string, property: string, parameters?: any): void
  }
}

// Setup history
const history = createBrowserHistory({
  basename: import.meta.env.VITE_PUBLIC_URL,
})

// We dont want to use MSW when we are running cypress tests as the result wont be predictable
if ((import.meta.env.DEV && !window.Cypress) || import.meta.env.VITE_INCLUDE_MOCKS === 'true') {
  import('./mocks/browser')
    .then(({ worker }) => {
      worker.start({
        serviceWorker: {
          url: '/mockServiceWorker.js',
        },
        onUnhandledRequest: 'bypass',
      })

      window.msw = worker

      worker.printHandlers()
    })
    .catch(Sentry.captureException)
}

// APPCUES should only be loaded in production
if (import.meta.env.VITE_APPCUES_ENABLED === 'true') {
  Appcues.init()
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, error: any) => count < 2 && ![418, 404].includes(error.status),
    },
  },
})

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <QueryClientProvider client={queryClient}>
    <DndProvider>
      <Router history={history}>
        <SettingsProvider>
          <ThemeProvider>
            <ErrorBoundary ErrorComponent={ErrorComponent} onCatch={(e) => Sentry.captureException(e)}>
              <FlexibleCanvasProvider>
                <AuthProvider>
                  <LaunchDarklyProvider>
                    <GlobalStyle />
                    <App />
                  </LaunchDarklyProvider>
                </AuthProvider>
              </FlexibleCanvasProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </SettingsProvider>
      </Router>
    </DndProvider>
  </QueryClientProvider>,
)
