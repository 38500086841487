import { Button, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: ${SPACING[5]} 0 ${SPACING[4]};
  position: relative;
`

export const StyledBackButton = styled(Button)`
  position: absolute;
  left: -10px;
`

type StyledInputProps = {
  $borderColor: string
  $background: string
}

export const StyledInput = styled.div<StyledInputProps>`
  border-radius: 8px;
  border: ${({ $borderColor }) => `1px solid ${$borderColor}`};
  background: ${({ $background }) => $background};
  margin-left: ${SPACING[1]};
  height: 40px;
  overflow: hidden;
  textarea {
    padding: 10px 16px 1px;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
    overflow: hidden;
  }
`

export const StyledFields = styled.div`
  flex-direction: column;
  display: flex;
  height: 100%;
  padding: 0 ${SPACING[4]};
`

export const StyledFieldEditor = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${SPACING[4]};
`

export const StyledCompoundField = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: ${SPACING[1]};
`

export const StyledBetweenContainer = styled.div`
  display: flex;
  margin-top: ${SPACING[2]};
  margin-left: ${SPACING[3]};
  align-items: center;
`
