import {
  BaseEmoji,
  Button,
  BUTTON_VARIANTS,
  EmojiPicker,
  ExclamationCircle,
  InfoIcon,
  Layout,
  SPACING,
  Tooltip,
  Typography,
} from '@community_dev/pixels'
import { SmileIcon } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { makeKeywordResponseTemplate, WorkflowType } from '@community_dev/workflow-manifest'
import formatISO from 'date-fns/formatISO'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'

import { ResponsiveWrapper, StyledIcon, StyledInfo } from './WorkflowKeywordResponseScreen.style'

import { validateKeyword as validateKeywordRemote } from 'api/keyword'
import { TextInput } from 'components/input'
import { WorkflowKeywords } from 'components/WorkflowEditor/components/WorkflowKeywords'
import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { workflowName } from 'components/WorkflowEditor/utils/workflowName'
import { ROUTES } from 'constants/routes'
import { WORKFLOW_ID_UNSAVED } from 'constants/workflow'
import { useSettings } from 'contexts/SettingsProvider'
import { useClientId } from 'hooks/useClient'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useWorkflowKeywords } from 'hooks/useWorkflowKeywords'
import { CancelLink, DoneButton } from 'screens/WorkflowScreen/WorkflowScreen.style'

export function WorkflowKeywordResponseScreen(): JSX.Element {
  const { settings } = useSettings()
  const currentTheme = useCurrentTheme()
  const { t } = useTranslation()
  const { COLORS } = useTheme()
  const clientId = useClientId()
  const [value, setValue] = useState('')
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const { keywords } = useWorkflowKeywords()
  const { availableKeywordsCount, maxKeywordsAllowed } = keywords || {}
  const [localKeywords, setLocalKeywords] = useState<string[]>([])

  const { dispatch } = useWorkflowProvider()

  const validateKeyword = useCallback(
    async (value: string) => {
      const isLocalDuplicate = localKeywords.some((keyword) => keyword === value)
      // if it’s a local duplicate, we error right away.
      if (isLocalDuplicate) {
        throw new Error(t('automations.keyword.localDuplicateError', { keyword: value }))
      } else {
        // otherwise, we ask the server for validation
        const response = await validateKeywordRemote(value, clientId)
        const normalizedKeyword = response?.data?.normalizedText
        if (!normalizedKeyword) {
          throw new Error(t('unknownServerError'))
        }
        const isNormalizedDuplicate = localKeywords.some((keyword) => keyword === normalizedKeyword)
        if (isNormalizedDuplicate) {
          throw new Error(t('automations.keyword.localDuplicateError', { keyword: normalizedKeyword }))
        }
        return normalizedKeyword
      }
    },
    [clientId, localKeywords, t],
  )

  const addKeyword = useCallback(
    async (value: string) => {
      setIsLoading(true)
      setError('')
      try {
        const keyword = await validateKeyword(value)
        if (localKeywords.length < (availableKeywordsCount || 0)) {
          setLocalKeywords([...localKeywords, keyword])
        } else {
          setError(t('automations.keyword.maxKeywordsReached', { maxKeywordsAllowed }))
        }
      } catch (e: any) {
        // server errors
        if (e.errors?.[0]?.message) {
          setError(e.errors[0].message)
          // local errors
        } else if (typeof e.message === 'string') {
          setError(e.message)
          // generic error
        } else {
          setError(t('unknownServerError'))
        }
      }
      setValue('')
      setIsLoading(false)
    },
    [validateKeyword, localKeywords, t, availableKeywordsCount, maxKeywordsAllowed],
  )

  const onValueKeyUp = useCallback(
    async (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        addKeyword(value)
      }
    },
    [value, addKeyword],
  )

  const onEmojiSelect = (emoji: BaseEmoji) => {
    setValue(`${value}${emoji.native}`)
  }

  const onEmojiOpen = () => {
    setIsEmojiPickerOpen(true)
  }

  const removeLocalKeyword = useCallback((keyword: string) => {
    setLocalKeywords((keywords) => keywords.filter((kw) => kw !== keyword))
  }, [])

  const createKeywordWorkflow = useCallback(
    async (keywords: string[]) => {
      const manifest = makeKeywordResponseTemplate(keywords)
      dispatch({
        clearHistory: true,
        state: {
          manifest,
          workflow: {
            created_at: formatISO(new Date()),
            id: WORKFLOW_ID_UNSAVED,
            updated_at: formatISO(new Date()),
            name: workflowName(WorkflowType.keyword, { keywords }),
            active_end_at: null,
            active_start_at: null,
            type: WorkflowType.keyword,
          },
        },
        type: 'update',
      })
      history.push(route(ROUTES.AUTOMATIONS.FLOW, { workflowId: WORKFLOW_ID_UNSAVED }))
    },
    [dispatch, history],
  )

  return (
    <ResponsiveWrapper style={{ height: '100%' }}>
      <Layout display="flex" flexDirection="column" height="100%">
        <Typography marginTop={SPACING[10]} textAlign="center" variant="h1">
          {t('automations.keyword.newKeywordFlowHeadline')}
        </Typography>
        <Layout flex="1" margin="0 auto" padding={`${SPACING[6]} ${SPACING[6]} 0 ${SPACING[6]}`} width="520px">
          <Typography variant="h4">
            {t('automations.keyword.membersThatSendTheFollowing')}
            <Tooltip content={t('automations.keyword.keywordMatchExplainer')}>
              <Layout display="inline-block" marginLeft={SPACING[1]} marginTop="2px">
                <InfoIcon size={20} />
              </Layout>
            </Tooltip>
          </Typography>
          <Layout display="flex">
            <TextInput
              autoFocus={true}
              maxLength={50}
              onChange={(e) => setValue(e.target.value)}
              onKeyUp={onValueKeyUp}
              placeholder={t('automations.keyword.inputPlaceholderLong')}
              rootStyle={{ padding: SPACING[1] }}
              value={value}
            >
              <StyledIcon onClick={onEmojiOpen}>
                <SmileIcon size={24} />
                {isEmojiPickerOpen && (
                  <EmojiPicker
                    onOuterClick={() => setIsEmojiPickerOpen(false)}
                    onSelect={onEmojiSelect}
                    rootStyle={{ top: SPACING[4] }}
                    skin={settings.skinTone}
                    theme={currentTheme.type}
                  />
                )}
              </StyledIcon>
            </TextInput>
            <Button
              disabled={value.trim() === '' || isLoading}
              onClick={() => addKeyword(value)}
              style={{ marginLeft: SPACING[4], minWidth: 120 }}
              variant={BUTTON_VARIANTS.PRIMARY}
            >
              Add Keyword
            </Button>
          </Layout>
          {error && <StyledInfo icon={<ExclamationCircle color={COLORS.ERRORS} size={20} />}>{error}</StyledInfo>}
          <Layout padding={`${SPACING[4]} 0`}>
            <WorkflowKeywords keywords={localKeywords} onRemoveKeyword={removeLocalKeyword} removable />
          </Layout>
        </Layout>
        <Layout
          alignItems="center"
          borderTop={`1px solid ${COLORS.BORDERS}`}
          display="flex"
          height="100px"
          justifyContent="space-between"
          padding={`0 ${SPACING[6]}`}
        >
          <CancelLink to={ROUTES.AUTOMATIONS.FLOWS}>{t('cancel')}</CancelLink>
          <DoneButton
            disabled={isLoading || localKeywords.length === 0}
            onClick={() => createKeywordWorkflow(localKeywords)}
          >
            {t('continue')}
          </DoneButton>
        </Layout>
      </Layout>
    </ResponsiveWrapper>
  )
}
