import { Button, Layout, Tooltip } from '@community_dev/pixels'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { FormstackAction, FormstackField, PopulatedField, requestFeature } from 'api/addOns'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { FormstackActionNames } from 'hooks/useAddons/constants'
import { useHasCapabilitiesAndFeatureFlags } from 'hooks/useAddons/useHasCapabilitiesAndFeatureFlags'
import { useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import analytics from 'utils/analytics'

const useFormstackFields = (fields: FormstackField[]): PopulatedField[] => {
  const { data: seat } = useSeat()
  const clientId = useClientId()
  const { email } = seat || {}
  return fields.map((field) => {
    if (field.fieldName === FormstackActionNames.clientId) {
      return { ...field, fieldValue: clientId }
    }
    if (field.fieldName === FormstackActionNames.seatEmail) {
      return { ...field, fieldValue: email }
    }
    return field
  }) as PopulatedField[]
}

type FormstackApiButtonProps = FormstackAction & {
  className?: string
}
export const FormstackApiButton = ({
  text,
  formId,
  fields,
  disabled = {},
  className,
}: FormstackApiButtonProps): JSX.Element => {
  const { t } = useTranslation()
  const populatedFields = useFormstackFields(fields)
  const { showToastMessage } = useToastMessage()
  const clientId = useClientId()
  const { mutate, isSuccess, isLoading } = useMutation(requestFeature, {
    onError: () => {
      showToastMessage({ message: 'Unable to request feature', success: false })
    },
    onSuccess: () => {
      analytics.track(analytics.events.AddOnCtaClicked(text))
      showToastMessage({ message: 'Request Submitted. We’ll be in touch within 1 business day.', success: true })
    },
  })

  const isDisabled = !useHasCapabilitiesAndFeatureFlags({
    capabilities: disabled.capabilities,
    featureFlags: disabled.featureFlags,
  })

  if (isDisabled) {
    return (
      <Tooltip content={disabled?.tooltip}>
        <Layout className={className}>
          <Button disabled>{text}</Button>
        </Layout>
      </Tooltip>
    )
  }

  return (
    <Button
      className={className}
      disabled={isLoading || isSuccess}
      onClick={() => mutate({ clientId, fields: populatedFields, formId })}
    >
      {isSuccess ? t('addOns.requestSubmitted') : text}
    </Button>
  )
}
