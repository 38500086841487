import {
  Badge,
  Button,
  FONT_WEIGHT,
  Layout,
  LoadingIndicator,
  SPACING,
  Switch,
  Tooltip,
  Typography,
} from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { WebhookEventType, WebhookStatus } from '@community_dev/types/lib/api/v1/Webhook'
import { useQueryClient } from '@tanstack/react-query'
import IconWebhooks from 'containers/Settings/components/Integrations/components/Icons/icon-webhooks.svg?react'
import startCase from 'lodash/startCase'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router'
import { useTheme } from 'styled-components'

import { StyledLink } from '../styled'

import { generateSignature } from './util'
import { WebhookModal } from './WebhookModal'
import { StyledWebhookItem } from './webhooks.style'

import { Webhook, WebhookUnsaved } from 'api/webhooks'
import { StyledAction } from 'components/Settings'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import {
  useCreateWebhook,
  useDeleteWebhook,
  useUpdateWebhook,
  useWebhooks,
  useWebhooksStatus,
} from 'containers/Settings/components/Integrations/queries'
import { useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import { Header } from 'screens/SettingsScreen/components/Header'

type WebhookItemProps = {
  data: Webhook
  status?: WebhookStatus
  onActionClick: (data: Webhook) => any
}

const WebhookItem = ({ data, onActionClick, status }: WebhookItemProps): JSX.Element => {
  const { t } = useTranslation()
  const { COLORS } = useTheme()
  const queryClient = useQueryClient()
  const { showToastMessage } = useToastMessage()
  const clientId = useClientId()
  const { isLoading: isUpdating, mutateAsync: toggleWebhook } = useUpdateWebhook(clientId, {
    onSuccess: ({ enabled }) => {
      queryClient.refetchQueries([QUERY_CACHE.WEBHOOKS.WEBHOOKS, clientId])
      showToastMessage({
        success: true,
        message: enabled
          ? t('settings.integrations.webhooks.webhookEnabled')
          : t('settings.integrations.webhooks.webhookDisabled'),
      })
    },
    onError: () => {
      showToastMessage({ success: false, message: t('settings.integrations.webhooks.notifyUpdateError') })
    },
  })
  return (
    <StyledWebhookItem as="li">
      <Layout>
        <Tooltip
          content={
            data.enabled
              ? `${t('settings.integrations.webhooks.webhookEnabled')}. ${t(
                  'settings.integrations.webhooks.clickToDisable',
                )}`
              : `${t('settings.integrations.webhooks.webhookDisabled')}. ${t(
                  'settings.integrations.webhooks.clickToEnable',
                )}`
          }
        >
          <span>
            <Switch
              aria-label={t('settings.integrations.webhooks.enableOrDisable')}
              color={data.enabled ? COLORS.SUCCESS : COLORS.BORDERS}
              disabled={isUpdating}
              on={data.enabled}
              onChange={(event) => toggleWebhook({ ...data, enabled: event.target.checked })}
            />
          </span>
        </Tooltip>
      </Layout>
      <Layout flex="1">
        <Layout display="flex" justifyContent="space-between" marginBottom={SPACING[1]}>
          <Typography fontWeight={FONT_WEIGHT[6]} margin={0} variant="body1">
            {data.name}
            {status && status !== WebhookStatus.WEBHOOK_STATUS_OK && (
              <Tooltip content={t('settings.integrations.webhooks.errorsTooltip')}>
                <Layout display="inline-block">
                  <Badge
                    background={COLORS.ERRORS}
                    borderColor={COLORS.ERRORS_HOVER}
                    color={COLORS.BUTTON_PRIMARY_TEXT}
                    display="inline-block"
                    marginLeft={SPACING[2]}
                  >
                    {t('errors')}
                  </Badge>
                </Layout>
              </Tooltip>
            )}
          </Typography>
          <StyledAction onClick={() => onActionClick(data)}>{t('edit')}</StyledAction>
        </Layout>
        <Layout display="flex" justifyContent="space-between">
          <Typography component="div" variant="caption1">
            POST {data.url}
          </Typography>
          <Typography component="div" variant="caption1">
            {data.types.map(startCase).join(', ')}
          </Typography>
        </Layout>
      </Layout>
    </StyledWebhookItem>
  )
}

export const WebhooksSettings = (): JSX.Element => {
  const clientId = useClientId()
  const [isCreating, setIsCreating] = useState(false)
  const {
    params: { webhookId },
  } = useRouteMatch<{ webhookId?: string }>()
  const location = useHistory()
  const { showToastMessage } = useToastMessage()
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data: webhooks = [], isLoading: isLoadingWebhooks } = useWebhooks(clientId)
  const { data: webhooksStatus = { webhooks: [] } } = useWebhooksStatus(clientId)
  const statusById = useMemo(
    () =>
      webhooksStatus.webhooks.reduce<{ [key: string]: WebhookStatus }>(
        (acc, webhook) => ({ ...acc, [webhook.webhookId]: webhook.status }),
        {},
      ),
    [webhooksStatus],
  )
  const makeDefaultWebhook = useCallback(
    (): WebhookUnsaved => ({
      enabled: true,
      name: '',
      signatureSecret: generateSignature(),
      types: [WebhookEventType.INBOUND_MESSAGE],
      url: '',
    }),
    [],
  )
  const editingWebhook: Webhook | WebhookUnsaved | null = useMemo(() => {
    if (isCreating) {
      return makeDefaultWebhook()
    } else if (webhookId) {
      return webhooks.find((webhook) => webhook.id === webhookId) || null
    } else {
      return null
    }
  }, [isCreating, makeDefaultWebhook, webhookId, webhooks])

  // if the webhook doesn’t exist, redirect to the overview route
  useEffect(() => {
    if (webhooks.length && webhookId && editingWebhook === null) {
      showToastMessage({
        message: t('settings.integrations.webhooks.webhookNotFound'),
        success: false,
      })
      location.replace(ROUTES.SETTINGS.INTEGRATIONS.WEBHOOKS)
    }
  }, [editingWebhook, location, showToastMessage, t, webhookId, webhooks.length])
  const { mutateAsync: updateWebhook } = useUpdateWebhook(clientId, {
    onSuccess: () => {
      queryClient.refetchQueries([QUERY_CACHE.WEBHOOKS.WEBHOOKS, clientId])
      showToastMessage({
        success: true,
        message: t('settings.integrations.webhooks.webhookUpdated'),
      })
    },
    onError: () => {
      showToastMessage({ success: false, message: t('settings.integrations.webhooks.notifyUpdateError') })
    },
  })
  const { mutateAsync: deleteWebhook } = useDeleteWebhook(clientId, {
    onSuccess: () => {
      queryClient.refetchQueries([QUERY_CACHE.WEBHOOKS.WEBHOOKS, clientId])
      showToastMessage({
        success: true,
        message: t('settings.integrations.webhooks.webhookDeleted'),
      })
    },
    onError: () => {
      showToastMessage({ success: false, message: t('settings.integrations.webhooks.notifyUpdateError') })
    },
  })
  const { mutateAsync: createWebhook } = useCreateWebhook(clientId, {
    onSuccess: () => {
      queryClient.refetchQueries([QUERY_CACHE.WEBHOOKS.WEBHOOKS, clientId])
      showToastMessage({
        success: true,
        message: t('settings.integrations.webhooks.webhookCreated'),
      })
    },
    onError: () => {
      showToastMessage({ success: false, message: t('settings.integrations.webhooks.notifyCreateError') })
    },
  })
  const onSubmit = (webhook: Webhook | WebhookUnsaved): Promise<Webhook> => {
    if ('id' in webhook) {
      return updateWebhook(webhook)
    } else {
      return createWebhook(webhook)
    }
  }
  return (
    <Layout display="flex" flexDirection="column" height="100%">
      {editingWebhook && (
        <WebhookModal
          onClose={() => {
            setIsCreating(false)
            location.push(ROUTES.SETTINGS.INTEGRATIONS.WEBHOOKS)
          }}
          onDelete={(webhook) => deleteWebhook(webhook)}
          onSubmit={onSubmit}
          webhook={editingWebhook}
        />
      )}
      <Header
        action={
          <Layout whiteSpace="nowrap">
            <Button onClick={() => setIsCreating(true)}>{t('settings.integrations.webhooks.createWebhook')}</Button>
          </Layout>
        }
        subtext={
          <Typography margin="0" paddingRight={SPACING[3]} variant="body2">
            <Trans i18nKey="settings.integrations.webhooks.settingsDescription">
              <StyledLink href="https://developer.community.com/reference/webhooks-introduction" target="_blank" />
              <StyledLink href="mailto:yourfriends@community.com" />
            </Trans>
          </Typography>
        }
        title={t('settings.integrations.webhooks.settingsTitle')}
      />
      {isLoadingWebhooks && (
        <Layout flex="1">
          <LoadingIndicator />
        </Layout>
      )}
      {webhooks.length === 0 && !isLoadingWebhooks && (
        <Layout alignItems="center" display="flex" flex="1" justifyContent="center">
          <Typography color={COLORS.SUBTEXT} textAlign="center">
            <IconWebhooks css={{ marginRight: SPACING[2] }} />
            <Trans i18nKey="settings.integrations.webhooks.noWebhooksConfigured">
              <StyledLink onClick={() => setIsCreating(true)} />
            </Trans>
          </Typography>
        </Layout>
      )}
      {webhooks.length > 0 && !isLoadingWebhooks && (
        <Layout as="ul" flex="1" marginTop={SPACING[6]}>
          {webhooks?.map((webhook) => (
            <WebhookItem
              data={webhook}
              key={webhook.id}
              onActionClick={() =>
                location.push(route(ROUTES.SETTINGS.INTEGRATIONS.WEBHOOK_EDIT, { webhookId: webhook.id }))
              }
              status={statusById[webhook.id]}
            />
          ))}
        </Layout>
      )}
      <Layout>
        <Typography component="div" display="block" variant="caption1">
          {isLoadingWebhooks
            ? t('loading')
            : t('settings.integrations.webhooks.status', {
                total: webhooks.length,
                active: webhooks.reduce((m, w) => (w.enabled ? m + 1 : m), 0),
              })}
        </Typography>
      </Layout>
    </Layout>
  )
}
