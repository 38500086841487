import { FONT_SIZE, Info, InfoIconFilled, SPACING } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { StyledLink } from '../../../../styled'
import { FormSection } from '../FormSection'

import { InstallationInstructions } from './components/InstallationInstructions'
import { PopupBehaviors } from './components/PopupBehaviors'

import { Embed } from 'api/mgu'

const StyledRadios = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledPadding = styled.div`
  padding: ${SPACING[2]};
`

const StyledRadioLabel = styled.label`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  cursor: pointer;
  padding: ${SPACING[2]} 0;

  input {
    margin-right: ${SPACING[2]};
  }
`

const StyledSwitchBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const StyledSwitchLink = styled(StyledLink)`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  font-size: ${FONT_SIZE[3]};
`

export type BehaviorFormProps = {
  embed: Embed
  isCreate: boolean
  onChange(config: Partial<Embed['jsonConfig']>): any
  togglePreviewMode(): void
}

export const BehaviorForm = ({ embed, isCreate, onChange, togglePreviewMode }: BehaviorFormProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const config = embed.jsonConfig
  const { t } = useTranslation(undefined, { keyPrefix: 'settings.integrations.signupUnit.behaviorForm' })

  return (
    <div>
      <FormSection name="Display Mode">
        <StyledRadios>
          <StyledRadioLabel onClick={() => onChange({ displayType: 'inline' })}>
            <input checked={config.displayType === 'inline'} type="radio" />
            Inline
          </StyledRadioLabel>
          <StyledRadioLabel onClick={() => onChange({ displayType: 'popup' })}>
            <input checked={config.displayType !== 'inline'} type="radio" />
            {t('popup')}
          </StyledRadioLabel>
        </StyledRadios>
      </FormSection>
      {config.displayType !== 'inline' ? <PopupBehaviors config={config} onChange={onChange} /> : <StyledPadding />}
      <Info icon={<InfoIconFilled color={COLORS?.SUBTEXT} size={20} />}>
        <StyledSwitchBox>
          <div>Tip: Check on all devices.</div>
          <StyledSwitchLink onClick={togglePreviewMode}>Switch</StyledSwitchLink>
        </StyledSwitchBox>
      </Info>
      {!isCreate && <InstallationInstructions embed={embed} />}
    </div>
  )
}
