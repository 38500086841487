import { Workflow } from 'api/workflows'
import { WORKFLOW_ID_UNSAVED } from 'constants/workflow'
import { WorkflowStatusType, WorkflowStatusTypes } from 'screens/WorkflowsScreen/WorkflowStatus'
import dayjs from 'utils/dayjs'

export const toWorkflowStatus = (workflow: Workflow): WorkflowStatusType => {
  const { active_end_at, id } = workflow

  // A workflow is paused if it has never been saved
  if (id === WORKFLOW_ID_UNSAVED) return WorkflowStatusTypes.paused

  if (active_end_at) {
    const today = dayjs()
    const activeEndAt = dayjs(active_end_at)
    const isActive = today.isBefore(activeEndAt)
    return isActive ? WorkflowStatusTypes.active : WorkflowStatusTypes.paused
  }

  return WorkflowStatusTypes.active
}
