import { UseQueryResult } from '@tanstack/react-query'

type QueryResults<E> = { [key: string]: UseQueryResult<any, E> }

type CombinedQueryState<Q, E> = {
  isInitialLoading: boolean
  isLoading: boolean
  errors: E[]
  isError: boolean
  queries: Q
}

export function combinedQueryState<Q extends QueryResults<E>, E = unknown>(queries: Q): CombinedQueryState<Q, E> {
  return Object.keys(queries).reduce<CombinedQueryState<Q, E>>(
    function reduceQueries(acc, key) {
      const query = queries[key]
      acc.isInitialLoading = acc.isInitialLoading || query.isInitialLoading
      acc.isLoading = acc.isLoading || query.isLoading
      acc.isError = acc.isError || query.isError
      acc[key] = query.data
      if (query.error) {
        acc.errors.push(query.error)
      }

      return acc
    },
    { isInitialLoading: false, isLoading: false, errors: [], isError: false, queries },
  )
}
