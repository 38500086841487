import { InfoIcon, Select, SPACING, Tooltip, Typography } from '@community_dev/pixels'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ClientSettings, patchClientSettings } from 'api/client'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERY_CACHE } from 'constants/query-cache'
import { useAddons } from 'hooks/useAddons'
import { useClientId } from 'hooks/useClient'
import { useClientSettings } from 'hooks/useClientSettings'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import { Setting, SettingsSection } from 'layouts/SettingsLayout'
import { DynamicButton } from 'screens/DetailedAddOnScreen/DynamicButton'
import analytics from 'utils/analytics'

type MediaDispositionOption = {
  label: string
  value: MediaDisposition
}

const StyledDynamicButton = styled(DynamicButton)`
  button {
    border: none;
    font-size: 14px;
    &[aria-disabled='true'] {
      background-color: unset;
    }

    &[aria-disabled='false'] {
      color: ${({ theme }) => theme?.COLORS?.TEXT};
      background-color: unset;
    }
  }
`

export const SettingsMms = (): JSX.Element => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const clientId = useClientId()
  const { showToastMessage } = useToastMessage()
  const hasClientSettingsWriteCapability = useHasCapability(CAPABILITIES.CLIENT.SETTINGS.WRITE)
  const hasMMSCapability = useHasCapability(CAPABILITIES.FEATURE.MMS_MESSAGING.ALL)
  const { selectedAddOn: mmsAddon } = useAddons('send-media-using-mms')

  const {
    data: clientSettings,
    isLoading: isClientSettingsLoading,
    isError: hasClientSettingsError,
    refetch,
  } = useClientSettings({})
  const mediaDispositionOptions = useMemo(
    () => [
      { value: MediaDisposition.LINK, label: t('settings.customize.media.linkOption') },
      { value: MediaDisposition.ATTACHMENT, label: t('settings.customize.media.attachmentOption') },
    ],
    [t],
  )

  const [mediaDispositionOption, setMediaDispositionOption] = useState<MediaDispositionOption>(
    mediaDispositionOptions[0],
  )

  const { mutate: updateClientSettings } = useMutation(patchClientSettings, {
    onMutate: (newPreference) => {
      queryClient.cancelQueries([QUERY_CACHE.SETTINGS.CLIENT_SETTINGS, clientId])

      const previousPreferences: Partial<ClientSettings> | undefined = queryClient.getQueryData([
        QUERY_CACHE.SETTINGS.CLIENT_SETTINGS,
        clientId,
      ])

      queryClient.setQueryData(
        [QUERY_CACHE.SETTINGS.CLIENT_SETTINGS, clientId],
        (old: Partial<ClientSettings> | undefined) => ({
          ...old,
          ...newPreference?.body,
        }),
      )

      return () => queryClient.setQueryData([QUERY_CACHE.SETTINGS.CLIENT_SETTINGS, clientId], previousPreferences)
    },
    onSuccess: () => {
      showToastMessage({
        message: t('settings.changesSaved'),
        success: true,
      })
    },
    onError: (err, newPreference, rollback: any) => {
      rollback()
      showToastMessage({
        message: t('settings.changesCouldNotBeSaved'),
        success: false,
      })
    },
    onSettled: () => {
      refetch()
    },
  })

  useEffect(() => {
    if (clientSettings?.defaultMediaDisposition) {
      setMediaDispositionOption(
        mediaDispositionOptions.find((o) => o.value === clientSettings.defaultMediaDisposition) ||
          mediaDispositionOptions[0],
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSettings?.defaultMediaDisposition])

  if (!hasMMSCapability) {
    return (
      <SettingsSection>
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          {t('settings.customize.media.title')}
        </Typography>
        <Setting
          label={t('settings.customize.media.upgradeToMms')}
          loading={isClientSettingsLoading}
          text={t('settings.customize.media.mmsUpgradeDescription')}
        >
          {mmsAddon && <StyledDynamicButton {...mmsAddon?.details.action} />}
        </Setting>
      </SettingsSection>
    )
  }

  return (
    <SettingsSection>
      <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
        {t('settings.customize.media.title')}
      </Typography>
      <Setting
        label={t('settings.customize.media.defaultMediaType')}
        loading={isClientSettingsLoading}
        text={
          mediaDispositionOption.value === mediaDispositionOptions[1].value ? (
            <>
              <span>{t('settings.customize.media.attachmentDescription')}</span>
              <Tooltip content={t('settings.customize.media.attachmentTooltip')} placement="bottom-end">
                <span>
                  <InfoIcon size={18} />
                </span>
              </Tooltip>
            </>
          ) : (
            <>
              <span>{t('settings.customize.media.linkDescription')}</span>
              <Tooltip content={t('settings.customize.media.linkTooltip')} placement="bottom-end">
                <span>
                  <InfoIcon size={18} />
                </span>
              </Tooltip>
            </>
          )
        }
      >
        <Select
          id="media-disposition"
          isDisabled={!hasClientSettingsWriteCapability || hasClientSettingsError}
          onChange={(value) => {
            if (value?.value) {
              if (value.value === MediaDisposition.ATTACHMENT) {
                analytics.track(analytics.events.MMS.SettingAttachment())
              } else {
                analytics.track(analytics.events.MMS.SettingLink())
              }

              updateClientSettings({
                clientId,
                body: { defaultMediaDisposition: value.value },
              })
            }
          }}
          options={mediaDispositionOptions}
          value={mediaDispositionOption}
        />
      </Setting>
    </SettingsSection>
  )
}
