import { SelectorFilter, SelectorOperators, FieldDefinition } from '@community_dev/filter-dsl/lib/subscription-data'
import { TextInput, required, SPACING } from '@community_dev/pixels'
import { ChangeEvent } from 'react'
import { Field } from 'react-final-form'
import styled, { useTheme } from 'styled-components'

import { StyledInput } from '../FieldPane.style'

const validateText = (value: SelectorFilter | null): void | string => {
  return required(value?.operand?.value)
}

const StyledTextInput = styled(TextInput)`
  padding: ${SPACING[2]} ${SPACING[3]};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

type PlainTextFieldProps = {
  field: FieldDefinition
}

export function PlainTextField({ field }: PlainTextFieldProps): JSX.Element {
  const { COLORS } = useTheme() || {}

  const handleChange =
    (onChange: (value: any) => void) =>
    (event: ChangeEvent<HTMLTextAreaElement>): void => {
      const value = event.target.value

      onChange({
        operator: SelectorOperators.EQUALS,
        operand: {
          field_key: field.key,
          field_label: field.name,
          source: field.source,
          type: field.value_type,
          value: value,
        },
      } as SelectorFilter)
    }

  return (
    <Field name={`custom-field-${field.key}`} validate={validateText}>
      {({ input }) => (
        <StyledInput $background={COLORS.INPUT_BACKGROUND} $borderColor={COLORS.BORDERS}>
          <StyledTextInput
            data-testid={`${field.key} value`}
            onChange={handleChange(input.onChange)}
            placeholder="Enter Text"
            showLine={false}
            value={input?.value?.operand?.value || ''}
          />
        </StyledInput>
      )}
    </Field>
  )
}
