import { SPACING } from '@community_dev/pixels'
import styled, { useTheme } from 'styled-components'

import { InfoAccordion } from '../../InfoAccordion'

import { InlineInstallation } from './InlineInstallation'
import { PopUpInstallation } from './PopupInstallation'

import { Embed } from 'api/mgu'
import { CogIcon } from 'screens/SettingsScreen/components/EmbedSettings/Icons'

const StyledInfoAccordion = styled(InfoAccordion)`
  margin-top: ${SPACING[4]};
`

type InstallationInstructionsProps = {
  embed: Embed
}

export const InstallationInstructions = ({ embed }: InstallationInstructionsProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  return (
    <StyledInfoAccordion heading="Installation Instructions" icon={<CogIcon color={COLORS?.SUBTEXT} />}>
      {embed.jsonConfig.displayType === 'inline' ? (
        <InlineInstallation embed={embed} />
      ) : (
        <PopUpInstallation embed={embed} />
      )}
    </StyledInfoAccordion>
  )
}
