import { humanizeComplexFilter } from '@community_dev/filter-dsl/lib/humanize/humanizeComplexFilter'
import { humanizeMemberDataFilterShortened } from '@community_dev/filter-dsl/lib/humanize/humanizeMemberDataFilters'
import {
  getFieldKey,
  getRoot,
  MemberDataFilter,
  removeNode,
  serializeFilters,
  FilterAst,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { useContainer, useRefRect, useWindowSize } from '@community_dev/hooks'
import {
  Pill,
  BORDER_RADIUS,
  Layout,
  InfoIcon,
  Tooltip,
  StyledFilterPill,
  PillRemoveButton,
} from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import { Modal as ModalBase } from '@restart/ui'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { useFilterSupportMatrix } from '../hooks/useFilterSupportMatrix'

import { useRecipientField } from './RecipientFieldContext'
import {
  StyledAddRecipientButton,
  StyledAndOrTooltipContent,
  StyledAndOrTooltipLabel,
  StyledAndOrTooltipTarget,
  StyledFilterPills,
  StyledLabel,
  StyledRecipientField,
  StyledToField,
} from './styled'
import PlusCircleIcon from './svg/circle-plus.svg?react'

import { useUpdatingCommunities } from 'components/ActionPanel/AddToCommunities/useUpdatingCommunities'
import { RecipientPane, RecipientPaneHandle } from 'components/ComposeMessage/RecipientPane'
import { FilterPane } from 'containers/RecipientRecommendations/recommendation-constants'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useCommunityLookup } from 'hooks/useCommunities'
import { useCountByQuery } from 'hooks/useCountByQuery'
import analytics from 'utils/analytics'
import { pluralizeText } from 'utils/general'
import { formatLargeNumber } from 'utils/number'

const StyledWrapper = styled.div`
  position: relative;
`

const StyledRecipientSummary = styled.span<{ $empty?: boolean }>`
  color: ${({ theme, $empty }) => ($empty ? theme.COLORS.SUBTEXT : theme.COLORS.TEXT)};
`

type StyledModalBaseProps = {
  $top?: React.CSSProperties['top']
  $height?: React.CSSProperties['height']
}

const StyledModalBase = styled(ModalBase)<StyledModalBaseProps>`
  position: absolute;
  box-shadow: ${({ theme }) => theme.SHADOWS.CARD};
  top: ${({ $top }) => $top};
  height: ${({ $height }) => $height};
  width: 100%;
  left: 0;
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-bottom-left-radius: ${BORDER_RADIUS[1]};
  border-bottom-right-radius: ${BORDER_RADIUS[1]};
  z-index: 2;
  background: transparent;
  overflow: hidden;
`

const StyledBackdrop = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
`

const TooltipMessage = (): JSX.Element => (
  <StyledAndOrTooltipContent>
    <Trans i18nKey="compose.andOrTooltip">
      <p>
        <strong></strong>
      </p>
    </Trans>
  </StyledAndOrTooltipContent>
)

type FilterPillProps = {
  filter: MemberDataFilter
}

export function GenericFilterPill(props: FilterPillProps): JSX.Element | null {
  const { filter } = props
  const { removeFilter, includedFiltersAst, individuals } = useFilters()
  const { setIsOpen } = useRecipientField()

  const individualLookup = useMemo(() => {
    return Object.fromEntries(
      individuals.map((individual) => [individual.fanSubscriptionId, individual as CamelCasedPropertiesDeep<Api.Fan>]),
    )
  }, [individuals])

  if (filter === null) return null

  return (
    <Pill
      key={getFieldKey(filter)}
      onRemove={() => {
        removeFilter(filter, includedFiltersAst, 'includes')
        setIsOpen(false)
      }}
      removable
    >
      {humanizeMemberDataFilterShortened(filter, { individuals: individualLookup })}
    </Pill>
  )
}

export function RecipientField({
  className,
  readOnlyRecipients = false,
  containerId = 'recipient-pane-container',
  panes,
  initialPane,
}: {
  className?: string
  readOnlyRecipients?: boolean
  containerId?: string
  panes?: Record<FilterPane, any>
  initialPane?: FilterPane
}): JSX.Element {
  const { t } = useTranslation()
  const { COLORS } = useTheme() || {}
  const { isOpen, setIsOpen } = useRecipientField()
  const {
    communicationChannel,
    filters,
    includedFilters,
    includedFiltersAst,
    setIncludedFilters,
    setActiveSubtree,
    individuals,
  } = useFilters()

  const { isInitialLoading: isCountLoading, data: { count: recipientCount = 0 } = {} } = useCountByQuery({
    communicationChannel,
    filters,
    traceId: 'recipient-count',
  })

  const hasAtLeastOneFilter = !!includedFilters
  const wrapperRef = useRef<HTMLDivElement>(null)
  const recipientPaneRef = useRef<RecipientPaneHandle>(null)
  const mouseDownRef = useRef()
  const memberText = pluralizeText(recipientCount.toString(), 'Member', 'Members')

  const { communities = { all: [] } } = useUpdatingCommunities()
  const { siblingsHaveAnyRequiredFilterType } = useFilterSupportMatrix({ communicationChannel })
  const summary = useMemo(() => {
    return humanizeComplexFilter(filters, recipientCount, {
      communicationChannel,
      communities: communities?.all.reduce((acc, community) => ({ ...acc, [community.id]: community }), {}),
    })
  }, [communicationChannel, filters, recipientCount, communities])

  const openRecipientPane = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const closeRecipientPane = useCallback(() => {
    setIsOpen(false)
    setActiveSubtree(null)
  }, [setIsOpen, setActiveSubtree])

  const toggleRecipientPane = useCallback(() => {
    if (isOpen) {
      closeRecipientPane()
    } else {
      openRecipientPane()
    }
  }, [isOpen, closeRecipientPane, openRecipientPane])

  useEffect(() => {
    if (!isOpen) return
    function handleClickRecipientField(event) {
      event.stopPropagation()
      if (recipientPaneRef.current?.contains(event) || wrapperRef.current?.contains(event.target)) {
        return
      }

      toggleRecipientPane()
    }

    function onMouseDown(event) {
      mouseDownRef.current = event.target
    }

    function onMouseUp(event) {
      const downElement = mouseDownRef.current
      mouseDownRef.current = undefined
      if (downElement !== recipientPaneRef.current) return
      handleClickRecipientField(event)
    }

    document.body.addEventListener('mousedown', onMouseDown)
    document.body.addEventListener('mouseup', onMouseUp)

    return () => {
      document.body.removeEventListener('mousedown', onMouseDown)
      document.body.removeEventListener('mouseup', onMouseUp)
    }
  }, [isOpen, toggleRecipientPane])

  const recipientSummary = readOnlyRecipients ? summary : `${formatLargeNumber(recipientCount)} ${memberText}`
  const communityLookup = useCommunityLookup()

  const individualLookup = useMemo(
    () =>
      Object.fromEntries(
        individuals.map((individual) => [
          individual.fanSubscriptionId,
          individual as CamelCasedPropertiesDeep<Api.Fan>,
        ]),
      ),
    [individuals],
  )

  const [, windowHeight] = useWindowSize()
  const { y: fieldTop = 0, height: fieldHeight = 0 } = useRefRect(wrapperRef, [filters])
  const modalHeight = Math.max(Math.min(windowHeight - fieldTop - fieldHeight - 16, 600), 300)

  const container = useContainer(containerId)

  const defaultOnClickEnabled = !readOnlyRecipients && !includedFilters

  return (
    <StyledWrapper ref={wrapperRef}>
      <StyledRecipientField
        className={className}
        data-testid="recipient-field"
        onClick={
          defaultOnClickEnabled
            ? () => {
                toggleRecipientPane()
                setActiveSubtree(includedFiltersAst)
              }
            : undefined
        }
      >
        <StyledLabel>
          <StyledToField key={recipientSummary}>
            {isCountLoading ? (
              'Loading...'
            ) : (
              <>
                To: <StyledRecipientSummary $empty={recipientCount === 0}>{recipientSummary}</StyledRecipientSummary>
              </>
            )}
          </StyledToField>
          {defaultOnClickEnabled && (
            <StyledAddRecipientButton aria-label={t('compose.addFilter')}>
              <PlusCircleIcon />
            </StyledAddRecipientButton>
          )}
        </StyledLabel>

        {!readOnlyRecipients && hasAtLeastOneFilter && (
          <>
            <StyledFilterPills
              PillComponent={(props) => {
                return (
                  <StyledFilterPill
                    {...props}
                    RemoveButtonComponent={(buttonProps) => {
                      return (
                        <Tooltip
                          content={t('compose.requiredFilterWarning')}
                          disabled={
                            props.node !== undefined &&
                            (!props.node?.parent || siblingsHaveAnyRequiredFilterType(props.node))
                          }
                        >
                          <Layout display="inline-flex" left="5px" position="relative" right="-2px">
                            <PillRemoveButton style={{ left: 0 }} {...buttonProps} />
                          </Layout>
                        </Tooltip>
                      )
                    }}
                  />
                )
              }}
              communityLookup={communityLookup}
              data-testid="recipient-field-pills"
              filters={includedFilters}
              individualLookup={individualLookup}
              onAddAndClick={(_, subtree) => {
                toggleRecipientPane()
                setActiveSubtree(subtree)

                analytics.track(analytics.events.PressANDFilter())
              }}
              onAddOrClick={(_, subtree) => {
                toggleRecipientPane()
                setActiveSubtree(subtree)

                analytics.track(analytics.events.PressORFilter())
              }}
              onPillClick={(_, subtree) => {
                toggleRecipientPane()
                setActiveSubtree(subtree)
              }}
              onRemove={(_, node) => {
                let newSubtree: FilterAst | null = null

                if (siblingsHaveAnyRequiredFilterType(node)) {
                  // if the node has sibling nodes that are required filters,
                  // we can safely remove the single node
                  newSubtree = removeNode(node)
                } else if (node.parent) {
                  // if the node that is to be removed represents a required
                  // filter, we have to remove the entire group.
                  newSubtree = removeNode(node.parent)
                } else {
                  newSubtree = removeNode(node)
                }
                const root = getRoot(newSubtree)
                setIncludedFilters(serializeFilters(root))
              }}
            />

            <StyledAndOrTooltipLabel>
              {t('compose.andOrTooltipLabel')}&nbsp;
              <Tooltip content={<TooltipMessage />} interactive placement="bottom">
                <StyledAndOrTooltipTarget>
                  <InfoIcon color={COLORS.TOOLTIP_LABEL} size={20} />
                </StyledAndOrTooltipTarget>
              </Tooltip>
            </StyledAndOrTooltipLabel>
          </>
        )}
      </StyledRecipientField>
      <StyledModalBase
        $height={modalHeight}
        $top="-19px"
        container={container}
        onHide={() => closeRecipientPane()}
        renderBackdrop={() => (
          <StyledBackdrop data-testid={`${containerId}-backdrop`} onClick={() => closeRecipientPane()} />
        )}
        role="menu"
        show={isOpen}
      >
        <RecipientPane initialPane={initialPane} panes={panes} ref={recipientPaneRef} style={{ height: modalHeight }} />
      </StyledModalBase>
      <Layout id={containerId} position="relative" />
    </StyledWrapper>
  )
}
