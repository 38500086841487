import linkify from 'linkify-it'

export const checkContentForLinkAtBeginning = (text: string): boolean => {
  return (linkify().match(text) || []).some(({ index }) => index === 0)
}

export const checkContentForLinkAtEnd = (text: string): boolean => {
  return (linkify().match(text) || []).some(({ lastIndex }) => lastIndex === text.length)
}

export const checkContentForLink = (text: string): boolean => {
  return !!(linkify().match(text) || []).length
}

export const checkContentForMultipleLinks = (text: string): boolean => {
  return (linkify().match(text) || []).length > 1
}
