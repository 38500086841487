import { fileValidator } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { useCallback } from 'react'
import { FileError } from 'react-dropzone'

import { FileContext, MaxSize, getMediaConfig } from './useMediaConfig'

type MediaValidation = {
  /** If the file is not allowed, this is error code that explains why
  is undefined if no file is provided or if the file is allowed */
  error?: FileError | null
  /** Given the current media disposition and communication channel, this is the max size allowed for the file type
  is only return if the file is too large */
  maxSize?: MaxSize
  /** If the file is not allowed with media disposition ATTACHMENT, but can be allowed if the media disposition is changed to LINK
  is undefined if no file is provided or if the file is allowed */
  canUseLink?: boolean
}

type useMediaValidationProps = {
  communicationChannel: CommunicationChannel
  mediaDisposition: MediaDisposition
  context?: FileContext
}

export function useMediaValidation({
  communicationChannel,
  mediaDisposition,
  context,
}: useMediaValidationProps): (file: File) => MediaValidation {
  return useCallback(
    (file: File): MediaValidation => {
      const { accept, maxSize, unsupportedFileTypes } = getMediaConfig({
        communicationChannel,
        mediaDisposition,
        context,
      })
      if (file?.size) {
        const fileError = fileValidator({ accept, file, maxSize, unsupportedFileTypes })
        if (!fileError) {
          return {}
        }
        // The file is not allowed under the current media disposition and communication channel
        // To see if we can use a link, we need to know if the file is allowed with link disposition
        const {
          accept: linkAccept,
          maxSize: linkMaxSize,
          unsupportedFileTypes: linkUnsupportedFileTypes,
        } = getMediaConfig({
          communicationChannel,
          mediaDisposition: MediaDisposition.LINK,
          context,
        })
        const linkFileError = fileValidator({
          accept: linkAccept,
          file,
          maxSize: linkMaxSize,
          unsupportedFileTypes: linkUnsupportedFileTypes,
        })
        return {
          error: fileError,
          maxSize: fileError.maxSize,
          canUseLink: !linkFileError,
        }
      }

      return {}
    },
    [mediaDisposition, communicationChannel, context],
  )
}
