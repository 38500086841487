import { ComposeCircleIcon, Dropzone, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import React, { useCallback, useEffect } from 'react'
import { FileRejection } from 'react-dropzone'
import styled from 'styled-components'

import { FullTextSearch } from './FullTextSearch'

import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { FileContext } from 'components/FileRejectionDialog'
import { MessageSearch } from 'components/MessageSearch'
import { CAPABILITIES } from 'constants/capabilities'
import { FILTER_STATE } from 'constants/filter-states'
import { PANE_WIDTH, TABLET_BREAK } from 'constants/theme'
import InboxList from 'containers/InboxList'
import Navbar from 'containers/Navbar'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useInbox } from 'contexts/InboxProvider'
import { useUpload } from 'contexts/UploadProvider'
import { useClient } from 'hooks/useClient'
import { useMediaConfig } from 'hooks/useMediaConfig'
import { useHasCapability } from 'hooks/useUserCapability'
import { SidebarLayout } from 'layouts/SidebarLayout'
import { InboxItemNormalized } from 'utils/normalize'

type StyledMessageHeaderProps = {
  $searching?: boolean
}

const StyledMessageHeader = styled.div<StyledMessageHeaderProps>`
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-right: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  width: 100%;
`

const StyledTabSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 ${SPACING[4]};
`

const StyledCompose = styled.button`
  align-self: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;

  &:disabled {
    cursor: auto;
    svg {
      path:first-child {
        fill: ${({ theme }) => theme?.COLORS?.BUTTON_DISABLED};
      }
    }
  }
`

const StyledMessageGroup = styled.div`
  padding: 32px ${SPACING[4]} 0;
`

const StyledMessageSidebar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: ${TABLET_BREAK}) {
    width: ${PANE_WIDTH};
  }
`

const StyledMain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  touch-action: none;

  > div {
    pointer-events: auto;
    touch-action: auto;
  }

  @media (min-width: ${TABLET_BREAK}) {
    pointer-events: auto;
    touch-action: auto;
    left: 337px;
  }
`

const StyledDropzone = styled(Dropzone)`
  width: 100%;
  height: 100%;
  position: relative;
`

const StyledHeading = styled.h1`
  margin-bottom: ${SPACING[4]};
`

type MessagesLayoutProps = {
  children?: React.ReactNode
}

export function isInboxItemNormalized(item: InboxItemNormalized | Record<string, never>): item is InboxItemNormalized {
  return Object.keys(item).length > 0
}

export const DirectMessageLayout = ({ children }: MessagesLayoutProps): JSX.Element => {
  const { setFile } = useUpload()
  const { data: client } = useClient()
  // We disable the ability to open compose if the client has no communication channels
  const isOpenComposeDisabled = !client?.communicationChannels?.length
  const isFullTextSearchEnabled = useHasCapability(CAPABILITIES.FEATURE.FULL_TEXT_SEARCH.ALL)

  const {
    filters,
    setFilters,
    searchTerm,
    setSearchTerm,
    searchTab,
    setSearchTab,
    isFetching,
    isFetchingNextPage,
    isInitialLoading,
  } = useInbox()

  const { setComposeModalOpen } = useCompose()
  const { setCommunicationChannel } = useFilters()

  const communicationChannel =
    (filters.find((filter) => filter.field === 'search[communication_channel]')?.key as CommunicationChannel) ||
    CommunicationChannel.SMS
  const { accept, maxSize } = useMediaConfig({
    communicationChannel,
    context: FileContext.DM,
  })

  const openCompose = useCallback(() => {
    setCommunicationChannel(communicationChannel)
    setComposeModalOpen(true)
  }, [communicationChannel, setCommunicationChannel, setComposeModalOpen])

  useEffect(() => {
    if (client?.communicationChannels?.length && !client?.communicationChannels.includes(communicationChannel)) {
      setFilters(FILTER_STATE.INITIAL)
    }
  }, [client?.communicationChannels, setFilters, communicationChannel])

  const onFileAccepted = (file: File) => {
    setFile(file)
  }

  const onFileRejected = useCallback(
    (fileRejection: FileRejection) => {
      setFile(fileRejection.file)
    },
    [setFile],
  )

  useEffect(() => {
    setSearchTerm('')
  }, [])

  return (
    <SidebarLayout hideNavbar>
      <StyledDropzone
        accept={accept}
        maxSize={maxSize}
        noClick
        onFileAccepted={onFileAccepted}
        onFileRejected={onFileRejected}
      >
        <StyledMessageSidebar data-testid="message-sidebar">
          <Navbar />
          <StyledMessageHeader $searching={!!searchTerm}>
            <StyledMessageGroup>
              <StyledTabSection>
                <StyledHeading>Messages</StyledHeading>
                <StyledCompose aria-label="Compose Message" disabled={isOpenComposeDisabled} onClick={openCompose}>
                  <ComposeCircleIcon size={32} />
                </StyledCompose>
              </StyledTabSection>
              {isFullTextSearchEnabled ? (
                <FullTextSearch filters={filters} onFilterChange={setFilters} />
              ) : (
                <MessageSearch
                  activeTab={searchTab}
                  defaultSearch={searchTerm}
                  filters={filters}
                  isLoading={isFetching || isFetchingNextPage}
                  onFilterChange={setFilters}
                  onSearchTermChange={setSearchTerm}
                  setSearchTab={setSearchTab}
                />
              )}
            </StyledMessageGroup>
          </StyledMessageHeader>
          <InboxList isSearching={Boolean(searchTerm)} loading={isInitialLoading} moreLoading={isFetchingNextPage} />
        </StyledMessageSidebar>
        <StyledMain>{children}</StyledMain>
      </StyledDropzone>
    </SidebarLayout>
  )
}
