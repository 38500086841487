import { SearchBar } from '@community_dev/pixels'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function Input({
  value = '',
  selectProps,
  onChange,
  placeholder,
  ...props
}: {
  value: string
  selectProps: any
  onChange(Event): void
  placeholder?: string
}): JSX.Element {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState(value)
  const selectedValue = selectProps?.value?.value

  useEffect(() => {
    if (!selectedValue) return
    setInputValue(selectedValue)
  }, [selectedValue])

  function handleChangeInput(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target?.value

    if (value) {
      setInputValue(value)
      onChange(event)
    } else {
      setInputValue('')
    }
  }

  return (
    <SearchBar
      {...props}
      onChange={handleChangeInput}
      placeholder={placeholder || t('search.placeholder')}
      value={inputValue || ''}
    />
  )
}
