import { SPACING, FONT_WEIGHT, FONT_SIZE } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledTable = styled.table<{ $maxWidth?: string }>`
  border-collapse: collapse;
  width: 100%;
  margin: ${SPACING[3]} 0 ${SPACING[4]} 0;
  max-width: ${({ $maxWidth }) => $maxWidth};
`

export const StyledHeaderRow = styled.tr`
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
`

export const StyledHeaderCell = styled.th<{ $align?: 'left' | 'right' }>`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  padding: ${SPACING[4]} ${SPACING[6]} ${SPACING[4]} ${SPACING[4]};
  line-height: ${SPACING[4]};
  font-weight: ${FONT_WEIGHT[7]};
  font-size: ${FONT_SIZE[2]};
  text-align: ${({ $align = 'left' }) => $align};
  white-space: nowrap;
`

export const StyledRow = styled.tr`
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
`

export const StyledSecondaryRow = styled.tr`
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
`

export const StyledCell = styled.td<{ $align?: 'left' | 'right'; $bolded?: boolean }>`
  padding: ${SPACING[4]} ${SPACING[6]} ${SPACING[4]} ${SPACING[4]};
  text-align: ${({ $align = 'left' }) => $align};
  font-weight: ${({ $bolded = false }) => ($bolded ? FONT_WEIGHT[6] : FONT_WEIGHT[4])};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  font-size: ${FONT_SIZE[3]};
`
