import moment from 'moment'
import Moment from 'react-moment/src/index'

// Set relative time rounding thresholds
moment.relativeTimeThreshold('s', 60)
moment.relativeTimeThreshold('m', 60)
moment.relativeTimeThreshold('h', 24)
moment.relativeTimeThreshold('d', 31)
moment.relativeTimeThreshold('M', 12)

// Override default moment calender format
moment.calendarFormat = (myMoment, now) => {
  const diff = myMoment.diff(now, 'days', true)
  if (diff >= -6) {
    if (diff < -1) return 'lastWeek'
    if (diff < 0) return 'lastDay'
    if (diff < 1) return 'sameDay'
    if (diff < 2) return 'nextDay'
    if (diff < 7) return 'nextWeek'
  }
  if (myMoment.year() === now.year()) return 'sameYear'
  return 'sameElse'
}

// Initial the global pooled timer with a refresh interval of 52s
Moment.startPooledTimer(52000)

const formatKeys = ['lastDay', 'lastWeek', 'nextDay', 'nextWeek', 'sameDay', 'sameElse', 'sameYear']

export const buildCalenderOpts = (defaultFormat = 'MM/DD/YY', opts = {}): any => {
  const format = {}
  formatKeys.forEach((key) => {
    format[key] = defaultFormat
  })
  return Object.assign(format, opts)
}

export const messageCalendarOpts = {
  sameDay: '[Today] [|]h:mm A',
  nextDay: '[Tomorrow] [|]h:mm A',
  nextWeek: 'dddd [|]h:mm A',
  nextFornight: '[Next] dddd [|]h:mm A',
  lastDay: '[Yesterday] [|]h:mm A',
  lastWeek: 'dddd [|]h:mm A',
  lastFornight: '[Last] dddd [|]h:mm A',
  sameYear: 'ddd, MMM D, [|]h:mm A',
  sameElse: 'ddd, MMM D, YYYY, [|]h:mm A',
}
