import { humanizeComplexFilter } from '@community_dev/filter-dsl/lib/humanize/humanizeComplexFilter'
import { BirthdayMatch, findOne, parseFilters } from '@community_dev/filter-dsl/lib/subscription-data'
import { digitsFilterToMemberDataFilter } from '@community_dev/filter-dsl/lib/transformers/digitsFilterToMemberDataFilter'
import {
  ListItem,
  LoadingIndicator,
  Ellipsis,
  SPACING,
  EditableText,
  Layout,
  Badge,
  Typography,
  InfoIcon,
  Tooltip,
} from '@community_dev/pixels'
import { convertKeysToSnakeCase, route } from '@community_dev/requests'
import { CampaignStatus } from '@community_dev/types/lib/api/v2/Campaign'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { useScheduledCampaigns } from '../../useScheduledCampaigns'

import IconRefresh from './refresh.svg?react'
import { ScheduledDetail } from './ScheduledDetail'
import { ScheduledEmpty } from './ScheduledEmpty'
import { SchedulePreview } from './SchedulePreview'

import { Communities } from 'api/community'
import { patchMessageQuery } from 'api/message'
import { ScheduledCampaign } from 'api/scheduled'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { UserTimezone } from 'components/ComposeMessage/components/UserTimezone'
import { useTimezones } from 'components/ComposeMessage/components/useTimezones'
import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { renderBodyContent } from 'components/InboxItem/formatters'
import { VirtualList, VirtualListHandle } from 'components/VirtualList'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import Sentry from 'integrations/Sentry'
import analytics from 'utils/analytics'
import { screens } from 'utils/analytics/events'
import dayjs from 'utils/dayjs'
import { buildCalenderOpts } from 'utils/moment-config'
import { titleize } from 'utils/normalize'
import { parseRecurrenceRule, RecurrenceDay, RecurrenceFrequency, RecurrenceRule } from 'utils/recurrence'

const StyledLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 340px;
  border-right: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
`

const StyledRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  text-align: right;
  position: relative;
  height: 100%;
`

const StyledVirtualList = styled(VirtualList)`
  margin: 0;
`

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
`

const StyledCommunicationChannelIcon = styled(CommunicationChannelIcon)`
  flex: none;
  margin-left: ${SPACING[2]};
  vertical-align: middle;
`

const StyledSubtext = styled.div`
  font-size: 14px;
  font-weight: 500;
`

const StyledTime = styled.div`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  margin: ${SPACING[2]} 0 ${SPACING[2]};
`

const StyledFrequency = styled.div`
  display: flex;
  align-items: center;
  margin: ${SPACING[2]} 0 ${SPACING[2]};
`

const StyledIconRefresh = styled(IconRefresh)`
  margin-right: ${SPACING[2]};
`

const StyledEmpty = styled.div`
  width: 100%;
  position: relative;
  padding: ${SPACING[4]};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
`

const StyledThumbnail = styled.div`
  display: inline-block;
  padding: ${SPACING[4]} ${SPACING[4]} ${SPACING[2]} 0;
`

const StyledImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
`

const StyledBadge = styled(Badge)`
  padding: ${SPACING[1]} ${SPACING[3]};
  border: none;
`

const calender = buildCalenderOpts('[Send on ]MM/DD/YY h:mm A', {
  sameDay: '[Send today at] h:mm A',
  nextDay: '[Send tomorrow at] h:mm A',
  nextWeek: '[Send on ] MM/DD/YY h:mm A',
})

const isRecurringBirthday = (campaign: ScheduledCampaign): boolean => {
  const filters = digitsFilterToMemberDataFilter(convertKeysToSnakeCase(campaign.filters), campaign.fanSubscriptionTags)
  const isRecurring = Boolean(campaign.recurrence)
  const isBirthday = Boolean(findOne(parseFilters(filters), BirthdayMatch))
  return isRecurring && isBirthday
}

export const toScheduledCampaignLabel = (campaign: ScheduledCampaign, communities?: Communities): string => {
  if (isRecurringBirthday(campaign)) {
    return 'Members with Birthdays'
  }

  return humanizeComplexFilter(
    digitsFilterToMemberDataFilter(convertKeysToSnakeCase(campaign.filters), campaign.fanSubscriptionTags),
    campaign.recipientCount,
    {
      communicationChannel: campaign.communicationChannel,
      communities: communities?.all.reduce((acc, community) => ({ ...acc, [community.id]: community }), {}),
      showRecipientCount: false,
    },
  )
}

export function ScheduledList(): JSX.Element {
  const clientId = useClientId()
  const hasCampaignScheduling = useHasCapability(CAPABILITIES.FEATURE.CAMPAIGN_SCHEDULING.ALL)

  const virtualRef = useRef<VirtualListHandle>(null)
  const { t } = useTranslation()
  const history = useHistory()
  const { scheduledCampaignId } = useParams<{ scheduledCampaignId: string }>()
  const { isLoading: timezoneLoading, communityLocalTimezone } = useTimezones()
  const { isLoading: communitiesLoading, data: communities } = useCommunities()
  const { isLoading: scheduledLoading, campaigns, hasNextPage, fetchNextPage } = useScheduledCampaigns()
  const { setComposeModalOpen, scheduledAt, toggleScheduled } = useCompose()
  const { showToastMessage } = useToastMessage()
  const queryClient = useQueryClient()
  const { COLORS } = useTheme()

  const { mutate: patchMessage } = useMutation(patchMessageQuery, {
    onSuccess: () => {
      analytics.track(
        analytics.events.EditCampaignName({
          page: screens.Scheduled,
        }),
      )
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.LIST, { clientId }])
      showToastMessage({
        message: t('updatedCampaignName'),
      })
    },
    onError: (error) => {
      showToastMessage({
        message: t('failedToUpdateCampaignName'),
        success: false,
      })

      Sentry.captureException(error)
    },
  })

  function openComposeModal() {
    setComposeModalOpen(true)

    if (!scheduledAt) {
      toggleScheduled()
    }
  }

  useEffect(() => {
    // Only do this once
    if (scheduledLoading || !campaigns?.length) return
    // Check if the id exists
    const scheduled = campaigns.find((scheduled) => scheduled.id === scheduledCampaignId) || campaigns[0]

    history.replace(route(ROUTES.SCHEDULED.LIST, { scheduledCampaignId: scheduled.id }))
  }, [scheduledLoading, campaigns, history, scheduledCampaignId])

  function handleClickScheduled(id: string) {
    history.push(route(ROUTES.SCHEDULED.LIST, { scheduledCampaignId: id }))
  }

  const isLoading = communitiesLoading || scheduledLoading || timezoneLoading

  return (
    <Layout display="flex" flex="1" overflow="hidden">
      <StyledLeft>
        {isLoading ? (
          <LoadingIndicator />
        ) : campaigns?.length ? (
          <StyledVirtualList
            hasMore={hasNextPage}
            loadMore={fetchNextPage}
            ref={virtualRef}
            rows={campaigns || []}
            testId="scheduled-list"
          >
            {({ virtualRow }) => {
              const scheduled = campaigns?.[virtualRow.index]
              const needsApproval = scheduled?.status === CampaignStatus.SUBMITTED_FOR_APPROVAL
              const templatePending = scheduled?.status === CampaignStatus.TEMPLATE_PENDING

              if (!scheduled) return null

              const nlpLanguage = toScheduledCampaignLabel(scheduled, communities)
              const recurrenceParams = parseRecurrenceRule(scheduled?.recurrence?.frequency as RecurrenceRule)

              return (
                <ListItem
                  data-testid="scheduled-item"
                  id={scheduled.id}
                  key={scheduled.id}
                  label={
                    <StyledLabel>
                      <Layout maxWidth="300px">
                        <EditableText
                          aria-label={t('editCampaignName')}
                          defaultValue={scheduled.name || undefined}
                          emptyValue={nlpLanguage}
                          minInputWidth="232px"
                          onSave={(name) => {
                            if (name.trim() !== scheduled.name) {
                              patchMessage({
                                body: {
                                  name: name.trim(),
                                },
                                campaignId: scheduled.id,
                                clientId,
                              })
                            }
                          }}
                          placeholder={t('enterCampaignName')}
                          showOnHoverEffect={false}
                        />
                      </Layout>
                      <StyledCommunicationChannelIcon
                        communicationChannel={scheduled.communicationChannel}
                        isAvatar={false}
                        size={16}
                      />
                    </StyledLabel>
                  }
                  onClick={(e) => {
                    if (!e.isDefaultPrevented()) {
                      handleClickScheduled(scheduled.id)
                    }
                  }}
                  selected={scheduledCampaignId === scheduled.id}
                  subtext={
                    <StyledSubtext>
                      <div data-private="redact">
                        <Ellipsis aria-label={t('scheduled.scheduledCampaignContent')} lines={3}>
                          {renderBodyContent({
                            body: scheduled.text,
                            incoming: false,
                            media: scheduled.media,
                          })}
                        </Ellipsis>
                        <StyledTime>
                          <span data-testid="time">
                            {communityLocalTimezone
                              ? dayjs(scheduled.scheduledAt)
                                  .tz(communityLocalTimezone?.representativeTzid)
                                  .calendar(null, calender)
                              : dayjs(scheduled.scheduledAt).calendar(null, calender)}
                          </span>
                          {', '}
                          <UserTimezone />
                        </StyledTime>
                      </div>
                      {recurrenceParams?.frequency && (
                        <StyledFrequency>
                          <StyledIconRefresh />
                          {recurrenceParams?.frequency === RecurrenceFrequency.WEEKLY && recurrenceParams.day
                            ? t('compose.repeatsWeeklyOn', {
                                day: titleize(
                                  Object.keys(RecurrenceDay)[
                                    Object.values(RecurrenceDay).indexOf(recurrenceParams.day)
                                  ],
                                ),
                              })
                            : t('compose.repeatsDaily')}
                        </StyledFrequency>
                      )}
                      {needsApproval ? (
                        <Layout alignItems="center" display="flex" justifyContent="space-between">
                          <StyledBadge background={COLORS?.BADGE_WARNING_BACKGROUND}>
                            <Typography
                              color={COLORS?.BUTTON_DEFAULT_TEXT}
                              fontSize="12px"
                              fontWeight={600}
                              margin={0}
                              variant={'h6'}
                            >
                              {t('scheduled.needsApproval')}
                            </Typography>
                          </StyledBadge>
                          <Tooltip content={t('scheduled.needsApprovalTooltip')}>
                            <Layout>
                              <InfoIcon />
                            </Layout>
                          </Tooltip>
                        </Layout>
                      ) : null}
                      {templatePending ? (
                        <Layout alignItems="center" display="flex" justifyContent="space-between">
                          <StyledBadge background={COLORS?.BADGE_PENDING_BACKGROUND}>
                            <Typography
                              color={COLORS?.BUTTON_DEFAULT_TEXT}
                              fontSize="12px"
                              fontWeight={600}
                              margin={0}
                              variant={'h6'}
                            >
                              {t('campaigns.templatePendingReview')}
                            </Typography>
                          </StyledBadge>
                        </Layout>
                      ) : null}
                    </StyledSubtext>
                  }
                  suffix={
                    scheduled.media?.thumbnailUrl ? (
                      <StyledThumbnail>
                        <StyledImage alt="" src={scheduled.media.thumbnailUrl} />
                      </StyledThumbnail>
                    ) : undefined
                  }
                />
              )
            }}
          </StyledVirtualList>
        ) : (
          <StyledEmpty>{t('scheduled.empty.text')}</StyledEmpty>
        )}
      </StyledLeft>
      <StyledRight data-testid="scheduled-details">
        {hasCampaignScheduling ? (
          campaigns?.length || isLoading ? (
            <ScheduledDetail />
          ) : (
            <ScheduledEmpty onClick={openComposeModal} />
          )
        ) : (
          <SchedulePreview />
        )}
      </StyledRight>
    </Layout>
  )
}
