import padEnd from 'lodash/padEnd'
import React from 'react'

import { CombinedInput, CombinedInputProps } from './CombinedInput'

type OmittedInputKeys = 'textValue' | 'colorValue' | 'onTextChange' | 'onColorChange'

type ColorInputProps = Omit<CombinedInputProps, OmittedInputKeys> & {
  value: string
  onChange(value: string): any
}

export const ColorInput = ({ value, onChange, ...rest }: ColorInputProps): JSX.Element => {
  const handleTextChange = (text: string) => {
    const color = '#' + (text || value).replace(/[^a-f0-9]/gi, '').slice(0, 6)
    onChange(color)
  }

  const handleBlur = () => {
    if (value.length === 4) {
      const [r, g, b] = (value as string).slice(1).split('')
      handleTextChange(`#${r}${r}${g}${g}${b}${b}`)
    } else if (value.length !== 7) {
      handleTextChange(padEnd(value, 7, '0'))
    }
  }

  return (
    <CombinedInput
      colorValue={value}
      onBlur={handleBlur}
      onColorChange={onChange}
      onTextChange={handleTextChange}
      textValue={value}
      {...rest}
    />
  )
}
