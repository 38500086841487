import { MemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { ActionButton } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useTheme } from 'styled-components'

import { useFavoriteCommunity } from 'components/ActionPanel/useFavoriteCommunity'
import { useClientId } from 'hooks/useClient'
import { useCommunity } from 'hooks/useCommunity'

type SelectionFavoriteActionButtonProps = {
  communicationChannel: CommunicationChannel
  disabled: boolean
  filters: MemberDataFilter
  recipientCount: number
}

export function SelectionFavoriteActionButton({
  communicationChannel = CommunicationChannel.SMS,
  disabled,
  filters,
  recipientCount,
}: SelectionFavoriteActionButtonProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const clientId = useClientId()
  const { isLoading, assignMembersToCommunity } = useCommunity({ clientId })
  const { favoriteCommunity: community } = useFavoriteCommunity()

  function handleClickFavorite() {
    if (!community) return

    assignMembersToCommunity({
      communicationChannel,
      community,
      filters,
      recipientCount,
      tagId: community.id,
    })
  }

  return (
    <ActionButton
      color={COLORS?.BUTTON_PRIMARY}
      disabled={disabled}
      icon="star"
      id="detail-pane-favorite"
      label="favorite"
      onClick={handleClickFavorite}
      processing={isLoading}
    />
  )
}
