import { AiBubble, Ellipsis, Layout } from '@community_dev/pixels'
import styled from 'styled-components'

import { CalendarMonthEvent } from './useCalendarMonthEvents'

import CommunicationChannelIcon from 'components/CommunicationChannelIcon'

const StyledCommunicationChannelIcon = styled(CommunicationChannelIcon)`
  flex-shrink: 0;
  margin-right: 4px;
`

type EventComponentProps = {
  continuesAfter: boolean
  continuesPrior: boolean
  event: CalendarMonthEvent
  isAllDay: boolean
  localizer: any
  slotEnd: Date
  slotStart: Date
  title: string
}

const EventIcon = ({ event }: { event: CalendarMonthEvent }): JSX.Element | null => {
  const { allDay, scheduledCampaign, sentCampaign, recurringCampaign } = event
  const communicationChannel =
    scheduledCampaign?.communicationChannel ||
    sentCampaign?.communicationChannel ||
    recurringCampaign?.communicationChannel

  if (allDay) {
    return (
      <AiBubble isPlaying={false} style={{ width: '16px', minWidth: '16px', height: '16px', marginRight: '4px' }} />
    )
  }

  if (communicationChannel) {
    return <StyledCommunicationChannelIcon communicationChannel={communicationChannel} isAvatar={false} size={16} />
  }

  return null
}

export const MonthEvent = (props: EventComponentProps): JSX.Element => {
  const { title, event } = props
  const campaignId =
    event.scheduledCampaign?.id ||
    event.sentCampaign?.id ||
    event.recurringCampaign?.id ||
    event.submittedForApprovalCampaign?.id

  const dataTestId = `${campaignId}-${event.start}`
  return (
    <Layout alignItems="center" data-testid={dataTestId} display="flex">
      <EventIcon event={event} />
      <Ellipsis>{title}</Ellipsis>
    </Layout>
  )
}
