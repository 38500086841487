import { Api } from '@community_dev/types'
import { useMemo } from 'react'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { useOnboardingMessage } from './useOnboardingMessage'

import { CONFIRMATION_MESSAGE_KEY } from 'screens/Onboarding/constants'

export type OnboardingConfirmationMessage = CamelCasedPropertiesDeep<Api.OnboardingSubscriptionConfirmationMessage>

const withSpace = (text: string) => (text ? ` ${text}` : text)

export const useOnboardingConfirmation = (withTransformations = true): OnboardingConfirmationMessage => {
  const confirmationMessage = useOnboardingMessage(CONFIRMATION_MESSAGE_KEY)

  const newConfirmationMessage = useMemo(() => {
    if (withTransformations && 'tcpa' in confirmationMessage) {
      return {
        ...confirmationMessage,
        tcpa: withSpace(confirmationMessage?.tcpa || ''),
      }
    }

    return confirmationMessage
  }, [withTransformations, confirmationMessage]) as OnboardingConfirmationMessage

  return newConfirmationMessage
}
