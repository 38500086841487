import { Placeholder } from '@community_dev/filter-dsl/lib/subscription-data'
import { BORDER_RADIUS } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin'
import {
  $createTextNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  CLEAR_EDITOR_COMMAND,
  EditorState,
  LineBreakNode,
} from 'lexical'
import { useEffect } from 'react'
import styled from 'styled-components'

import { useAutoFocusPlugin, usePlaceholderPlugin, useSpecialCharactersPlugin } from './hooks'
import { useReplaceTextPlugin } from './hooks/useReplaceTextPlugin'

import { useCompose } from 'components/ComposeMessage/ComposeContext'

export const StyledRoot = styled.div`
  position: relative;

  p {
    margin: 0;
  }
`

const StyledContentEditable = styled(ContentEditable)`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};
  border-radius: ${BORDER_RADIUS[2]};
  color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT};
  width: 100%;
  min-height: 85px;
  padding: 10px 12px;
  outline: none;
  border: none;
`

const StyledPlaceholder = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT_PLACEHOLDER};
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 10px;
  left: 12px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
`

type ComposeEditorProps = {
  enablePlaceholders?: boolean
  placeholder?: string
  placeholders: Placeholder[]
  value?: string
  communicationChannel: CommunicationChannel
  onChange(event: any): void
}

/**
 * This component must be rendered within the context of LexicalComposer
 */
export const ComposeEditor = (props: ComposeEditorProps): JSX.Element => {
  const { enablePlaceholders = false, placeholders, placeholder = 'Type your message...', onChange, value } = props
  const { maxConsecutiveLineBreaks } = useCompose()
  const [editor] = useLexicalComposerContext()

  useAutoFocusPlugin()
  usePlaceholderPlugin({ enabled: enablePlaceholders && placeholders.length > 0, placeholders })
  useSpecialCharactersPlugin({ communicationChannel: props.communicationChannel })
  useReplaceTextPlugin()

  // to set the initial state of the compose editor using the value prop.
  useEffect(() => {
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        selection.insertNodes([$createTextNode(value)])
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // listener to limit the amount of consecutive line-breaks.
  useEffect(() => {
    // #registerNodeTransform returns the teardown function
    return editor.registerNodeTransform(LineBreakNode, (node) => {
      const previousSiblings = node.getPreviousSiblings()
      const hasReachedMax =
        maxConsecutiveLineBreaks !== undefined &&
        previousSiblings.length >= maxConsecutiveLineBreaks &&
        // check whether the last n nodes are all line breaks
        previousSiblings.slice(maxConsecutiveLineBreaks * -1).every((node) => node.getType() === 'linebreak')
      if (hasReachedMax) {
        node.remove()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxConsecutiveLineBreaks])

  function handleChange(editorState: EditorState) {
    editorState.read(() => {
      const root = $getRoot()
      const value = root.getTextContent() || ''

      if (onChange) {
        onChange({ target: { value } })
      }
    })
  }

  return (
    <StyledRoot>
      <PlainTextPlugin
        contentEditable={<StyledContentEditable />}
        placeholder={<StyledPlaceholder>{placeholder}</StyledPlaceholder>}
      />
      <OnChangePlugin onChange={handleChange} />
      <HistoryPlugin />
      <ClearEditorPlugin />
    </StyledRoot>
  )
}
