import { SPACING, SortSelect } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useEffect, useMemo, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import Measure from 'react-measure'
import { Redirect } from 'react-router'
import styled, { useTheme } from 'styled-components'

import FailedToLoad from './components/FailedToLoad.svg?react'
import { SearchActionBar } from './components/SearchActionBar'
import { SearchBarChart } from './components/SearchBarChart'
import { SearchDatePickerLoadingIndicator } from './components/SearchDatePickerLoadingIndicator'
import { SearchDayPicker } from './components/SearchDayPicker'
import { SearchHeader } from './components/SearchHeader'
import { SearchResult, StyledResultItem } from './components/SearchResult'
import { SearchResultsLoadingIndicator } from './components/SearchResultsLoadingIndicator'
import { SearchProvider, useSearchState } from './SearchProvider'
import { SEARCH_SELECTION_STATE } from './useSearchSelection'

import { ROUTES } from 'constants/routes'
import { DESKTOP_BREAK } from 'constants/theme'
import { i18n } from 'i18n'
import { SidebarLayout } from 'layouts/SidebarLayout'
import { StyledContainer } from 'layouts/SidebarLayout/SidebarLayoutFloatingContainer'
import analytics from 'utils/analytics'
import { formatNumeral, pluralizeNumeral } from 'utils/general'
import { route } from 'utils/router'

const StyledSearchScreen = styled.div`
  width: 100%;
`

const SearchResults = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li:last-child {
    ${StyledResultItem} {
      border: none;
      margin-bottom: 0;
    }
  }
`

const StyledLayout = styled.div`
  > header {
    flex: 0 0 auto;
  }

  @media (min-width: ${DESKTOP_BREAK}) {
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
  }
`

const StyledHeader = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  z-index: 3;
`

const StyledBody = styled.div`
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};

  @media (min-width: ${DESKTOP_BREAK}) {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: scroll;
  }
`

const StyledSortWrapper = styled.div`
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
  width: 100%;
  margin-bottom: ${SPACING[4]};
  position: sticky;
  top: 0;
  z-index: 2;
`

const StyledDatePicker = styled.div<{ $top: number }>`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
  padding: ${SPACING[4]};
  position: sticky;
  top: ${({ $top }) => $top}px;
  z-index: 1;
  margin-left: -1px;
  margin-right: -1px;
  width: calc(100% + 2px);

  &::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
    border-top-left-radius: ${SPACING[4]};
    border-top-right-radius: ${SPACING[4]};
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
    border-bottom: none;
  }
`

const StyledEmptyResults = styled.div`
  text-align: center;
  padding: ${SPACING[10]} 0 450px;
`

const StyledCounts = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  margin: 0 ${SPACING[4]};
`

const StyledSentBy = styled.span`
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledFloatingContainer = styled(SidebarLayout.FloatingContainer)`
  ${StyledContainer} {
    border-top: none;
  }
`

function getSortOptions() {
  return [
    { value: 'relevance', label: i18n.t('search.sort.relevance') },
    { value: 'oldest_first', label: i18n.t('search.sort.oldest_first') },
    { value: 'newest_first', label: i18n.t('search.sort.newest_first') },
  ]
}

export function Search(): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const [redirect, setRedirect] = useState<string | null>(null)
  const [sortHeight, setSortHeight] = useState(0)
  const [datePickerHeight, setDatePickerHeight] = useState(0)
  const [sortOptions] = useState(getSortOptions)
  const {
    changeParams,
    error,
    communicationChannel,
    removeMember,
    searchIsLoading,
    searchResults,
    selected,
    selectionState,
    selectMember,
    sort,
  } = useSearchState()
  const hasErrorOrNoResults = error || searchResults?.data.totalResults === 0

  useEffect(() => {
    analytics.track(analytics.events.FullTextSearchSearchDetailsViewed())
  }, [])

  const selectedFanSubscriptionIds = useMemo(() => selected.map((member) => member.subscriptionId), [selected])

  if (redirect) return <Redirect push to={redirect} />

  return (
    <StyledSearchScreen>
      <StyledLayout>
        <StyledHeader>
          <SearchHeader />
        </StyledHeader>
        <StyledBody>
          <StyledFloatingContainer
            header={
              <Measure
                bounds
                onResize={({ bounds }) => {
                  if (bounds) {
                    setSortHeight(bounds.height)
                  }
                }}
              >
                {({ measureRef }) => (
                  <StyledSortWrapper ref={measureRef}>
                    {searchIsLoading ? (
                      <ContentLoader
                        backgroundColor={COLORS?.BORDERS}
                        foregroundColor={COLORS?.APP_BACKGROUND_LEVEL_1}
                        height={26}
                        speed={2}
                        viewBox="0 0 142 26"
                        width={142}
                      >
                        <rect fill={COLORS?.APP_BACKGROUND_LEVEL_1} height="20" rx="4" width="142" y="4" />
                      </ContentLoader>
                    ) : (
                      <SortSelect
                        onChange={(option) => {
                          if (option) changeParams({ sort: option.value })
                        }}
                        options={sortOptions}
                        value={sortOptions.find((item) => item.value === sort)}
                      />
                    )}
                  </StyledSortWrapper>
                )}
              </Measure>
            }
          >
            <Measure
              bounds
              onResize={({ bounds }) => {
                if (bounds) {
                  setDatePickerHeight(bounds.height)
                }
              }}
            >
              {({ measureRef }) => (
                <StyledDatePicker $top={sortHeight} ref={measureRef}>
                  {searchIsLoading ? <SearchDatePickerLoadingIndicator /> : <SearchDayPicker />}
                </StyledDatePicker>
              )}
            </Measure>
            {hasErrorOrNoResults ? (
              <>
                {error && (
                  <StyledEmptyResults>
                    <FailedToLoad />
                    <h3>{t('failedToLoadData')}</h3>
                    <p>{t('checkInternetConnection')}</p>
                  </StyledEmptyResults>
                )}
                {searchResults?.data.totalResults === 0 && (
                  <StyledEmptyResults>
                    <h3>{t('search.noResults')}</h3>
                    <p>{t('search.searchAgain')}</p>
                  </StyledEmptyResults>
                )}
              </>
            ) : (
              <>
                {searchIsLoading ? (
                  <ContentLoader
                    backgroundColor={COLORS?.APP_BACKGROUND_LEVEL_2}
                    foregroundColor={COLORS?.APP_BACKGROUND_LEVEL_1}
                    height={16}
                    speed={2}
                    viewBox="0 0 305 16"
                    width={305}
                  >
                    <rect height="16" rx="4" width="305" x="16" />
                  </ContentLoader>
                ) : (searchResults?.data.totalResults || 0) > 0 ? (
                  <StyledCounts>
                    <StyledSentBy>
                      {pluralizeNumeral(
                        searchResults?.data.totalResults.toString() || '',
                        '0,0',
                        t('message'),
                        t('messages'),
                      )}{' '}
                      sent by
                    </StyledSentBy>{' '}
                    {formatNumeral(searchResults?.data.totalSubscriptions.toString() || '')}{' '}
                    {communicationChannel === CommunicationChannel.WHATS_APP ? 'WhatsApp ' : ''}
                    {t('units.members', { count: searchResults?.data.totalSubscriptions || 0 })}
                  </StyledCounts>
                ) : (
                  <StyledCounts>
                    <StyledSentBy>
                      {pluralizeNumeral(
                        searchResults?.data.totalResults.toString() || '',
                        '0,0',
                        t('message'),
                        t('messages'),
                      )}
                    </StyledSentBy>
                  </StyledCounts>
                )}
                <SearchBarChart />
                <SearchActionBar top={datePickerHeight + sortHeight} />
                {searchIsLoading ? (
                  <SearchResultsLoadingIndicator />
                ) : (
                  <SearchResults>
                    {searchResults?.data.results.map((result) => (
                      <li key={result.subscriptionId}>
                        <SearchResult
                          onClick={() =>
                            setRedirect(
                              route(ROUTES.MESSAGES.DIRECT_MESSAGE, {
                                fanId: result.fanId,
                              }),
                            )
                          }
                          onRemove={removeMember}
                          onSelect={selectMember}
                          result={{ ...result, communicationChannel }}
                          selected={
                            selectionState === SEARCH_SELECTION_STATE.ENTIRE_QUERY_SELECTED ||
                            selectedFanSubscriptionIds.includes(result.subscriptionId)
                          }
                        />
                      </li>
                    ))}
                  </SearchResults>
                )}
              </>
            )}
          </StyledFloatingContainer>
        </StyledBody>
      </StyledLayout>
    </StyledSearchScreen>
  )
}

export function SearchScreen(): JSX.Element {
  return (
    <SidebarLayout>
      <SearchProvider>
        <Search />
      </SearchProvider>
    </SidebarLayout>
  )
}
