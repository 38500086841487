import { ArrowRightIcon, Button, BUTTON_VARIANTS, SPACING } from '@community_dev/pixels'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import useCurrentTheme from 'hooks/useCurrentTheme'
import useScript from 'hooks/useScript'
import useStylesheetLink from 'hooks/useStylesheetLink'

const StyledHeader = styled.div`
  padding: 0 ${SPACING[1]} ${SPACING[4]};
`

const StyledNav = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

const StyledContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const StyledBackButton = styled.a`
  cursor: pointer;
  vertical-align: text-top;
  svg {
    transform: rotate(180deg);
  }
`
export const Zapier = (): JSX.Element | null => {
  const currentTheme = useCurrentTheme()
  const history = useHistory()
  const jsReady = useScript('https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js')
  const cssReady = useStylesheetLink(
    'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css',
  )

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledNav>
          <div>
            <StyledBackButton onClick={() => history.goBack()}>
              <ArrowRightIcon size={20} />
            </StyledBackButton>{' '}
            Zapier
          </div>
          <Button
            forwardedAs="a"
            href="https://help.community.com/hc/en-us/articles/6883360847123"
            variant={BUTTON_VARIANTS.PRIMARY}
          >
            Need help?
          </Button>
        </StyledNav>
      </StyledHeader>
      {jsReady && cssReady && (
        <zapier-app-directory
          app="community"
          create-without-template="show"
          introcopy="hide"
          link-target="new-tab"
          theme={currentTheme.type}
          use-this-zap="show"
        />
      )}
    </StyledContainer>
  )
}
