import { Pill } from '@community_dev/pixels'
import { useMemo } from 'react'
import styled from 'styled-components'

export enum WorkflowStatusTypes {
  draft = 'draft',
  active = 'active',
  paused = 'paused',
}

export type WorkflowStatusType = `${WorkflowStatusTypes}`

export type WorkflowStatusProps = {
  status: WorkflowStatusType
}

function capitalize(s: string) {
  return s[0].toUpperCase() + s.slice(1)
}

const StyledPill = styled(Pill)`
  color: #000000;
`

export function WorkflowStatus(props: WorkflowStatusProps): JSX.Element {
  const { status } = props

  const backgroundColor = useMemo(() => {
    if (status === WorkflowStatusTypes.active) return '#D2F2B3'
    if (status === WorkflowStatusTypes.draft) return '#FDF0C1'
    if (status === WorkflowStatusTypes.paused) return '#C8C8C8'
    return '#C8C8C8'
  }, [status])

  return <StyledPill color={backgroundColor}>{capitalize(status)}</StyledPill>
}
