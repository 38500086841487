import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { ComposeMessage } from 'components/ComposeMessage'
import { AiMessageGeneratorModal } from 'components/ComposeMessage/components/AiMessageGeneratorModal'
import { useCompose } from 'components/ComposeMessage/ComposeContext'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { useAiMessageGenerator } from 'contexts/AIMessageGeneratorProvider'
import { useClientId } from 'hooks/useClient'
import { useHashChangeListener } from 'hooks/useHashChangeListener'

export default function Compose(): JSX.Element {
  useHashChangeListener()

  const history = useHistory()
  const queryClient = useQueryClient()
  const clientId = useClientId()
  const { composeModalOpen, reset: resetCompose, setComposeModalOpen } = useCompose()
  const {
    capabilityEnabled: aiMessageGeneratorCapabilityEnabled,
    aiMessageGeneratorPopoverOpen,
    setAiMessageGeneratorPopoverOpen,
    aiMessageGeneratorOpen,
    setAiMessageGeneratorOpen,
    reset: resetAiMessageGenerator,
  } = useAiMessageGenerator()

  useEffect(() => {
    if (
      (history.location.hash === ROUTES.COMPOSE.HASH || history.location.hash === ROUTES.COMPOSE.HASH_APP_CUES) &&
      clientId
    ) {
      setComposeModalOpen(true)
      setAiMessageGeneratorOpen(false)
    }
  }, [history.location.hash, clientId, setAiMessageGeneratorOpen, setComposeModalOpen])

  useEffect(() => {
    if (history.location.hash === ROUTES.AI_MESSAGE_GENERATOR.HASH) {
      setAiMessageGeneratorOpen(true)
      setComposeModalOpen(false)
    }
  }, [history.location.hash, setAiMessageGeneratorOpen, setComposeModalOpen])

  useEffect(() => {
    if (composeModalOpen) {
      setAiMessageGeneratorOpen(false)
    }
  }, [composeModalOpen, setAiMessageGeneratorOpen, setComposeModalOpen])

  useEffect(() => {
    if (aiMessageGeneratorOpen) {
      setComposeModalOpen(false)
    }
  }, [aiMessageGeneratorOpen, setComposeModalOpen])

  return (
    <>
      {composeModalOpen && (
        <ComposeMessage
          enableAiMessageGenerator
          onClose={() => {
            if (aiMessageGeneratorPopoverOpen) {
              setAiMessageGeneratorPopoverOpen(false)
            } else {
              setComposeModalOpen(false)

              if (
                history.location.hash === ROUTES.COMPOSE.HASH ||
                history.location.hash === ROUTES.COMPOSE.HASH_APP_CUES
              ) {
                history.location.hash = ''
                history.push(history.location)
              }
            }
          }}
          onSuccessfulSend={(scheduled) => {
            if (scheduled) {
              queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED, { clientId }])
            }

            resetCompose()
            resetAiMessageGenerator()
          }}
        />
      )}

      {aiMessageGeneratorCapabilityEnabled && aiMessageGeneratorOpen && (
        <AiMessageGeneratorModal
          onClose={() => {
            setAiMessageGeneratorOpen(false)
            setAiMessageGeneratorPopoverOpen(false)
            setComposeModalOpen(true)
          }}
          open
        />
      )}
    </>
  )
}
