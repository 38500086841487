import { CommunityFlagIcon } from '@community_dev/pixels'
import React, { useState } from 'react'

import { ProductEducationCard } from '../components/ProductEducationCard'

import { ShareYourNumberModal } from './ShareYourNumberModal'

import { useClient } from 'hooks/useClient'

export function ShareYourNumberCard(): JSX.Element {
  const { data: client } = useClient()
  const { phone } = client || { phone: '' }
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <ProductEducationCard
        Icon={CommunityFlagIcon}
        body="Share it and start growing your Community"
        header={phone}
        onClick={() => setIsOpen(true)}
        subHeader="Your number is ready!"
      />
      {isOpen ? <ShareYourNumberModal isOpen={isOpen} onClose={() => setIsOpen(false)} /> : null}
    </>
  )
}
