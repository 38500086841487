import pick from 'lodash/fp/pick'
import { useEffect } from 'react'

import { useSubscription } from './billing/useSubscription'
import { useClient } from './useClient'
import { useAllMemberCount } from './useCountByQuery/useEveryoneCount'

import { useSeat } from 'contexts/bootstrap/useSeat'
import analytics from 'utils/analytics'
import { Appcues } from 'utils/appcues'

const pickUserValues = pick(['id', 'firstName', 'fullName', 'phoneNumber', 'email'])

export function useIdentify(): void {
  const { data: seat } = useSeat()
  const { data: client } = useClient()
  const { data } = useSubscription()
  const { total = 0, isLoading } = useAllMemberCount()
  const activeMemberCount = isLoading ? undefined : total
  const planName = data?.subscription.planName
  const planType = data?.subscription.planType

  useEffect(() => {
    if (!seat || !client) return
    const user = pickUserValues(client)
    analytics.identify(client, seat, planName, planType)
    Appcues.identify(seat.id, { ...user, planName, planType, activeMemberCount })
  }, [seat, client, planName, planType, activeMemberCount])
}
