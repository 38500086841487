import { MessageBillingUsage } from '@community_dev/hooks'
import {
  BUTTON_VARIANTS,
  Button,
  FONT_SIZE,
  FONT_WEIGHT,
  Image,
  Modal,
  SPACING,
  BORDER_RADIUS,
  TCPAMessage,
} from '@community_dev/pixels'
import React, {
  Dispatch,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { OnSavePrompts } from './OnSavePrompts'

import { MessageBubble } from 'components/MessageBubble'
import { MultimediaInput } from 'components/MultimediaInput'
import { useSettings } from 'contexts/SettingsProvider'
import useCurrentTheme from 'hooks/useCurrentTheme'

export type Prompt = {
  cancelLabel: string
  message: string
  saveLabel: string
  title: string
  shouldPromptWhen?: (currentValue: string) => boolean
}

export type EditMessageValues = { message: string }

export type EditMessageIsSaveDisabledProps = {
  currentValue: string
  defaultValue: string
  messageBillingUsage: MessageBillingUsage
  pristine: boolean
  submitting: boolean
  valid: boolean
}

type EditMessageProps = {
  alt?: string
  // this allows us to pass in another button to replace 'Edit' as the modal trigger
  children?: ReactElement<{ onClick(): void }, string | JSXElementConstructor<{ onClick(): void }>>
  className?: string
  copy: string
  defaultValue: string
  header: string
  hint?: ReactNode
  message: string
  messageBillingUsage: MessageBillingUsage
  messageBubbleContents?: ReactNode
  prompts?: Prompt[]
  src?: string | string[]
  tcpaMessage?: string
  isSaveDisabled?: (props: EditMessageIsSaveDisabledProps) => boolean
  onSave: (values: EditMessageValues) => void
  setMessage: Dispatch<SetStateAction<string>>
}

const StyledButton = styled(Button)`
  font-weight: ${FONT_WEIGHT[6]};
  font-size: ${FONT_SIZE[3]};
  line-height: 18px;
  color: ${({ theme }) => theme?.COLORS?.LINKS};
`
const StyledBody = styled(Modal.Body)`
  padding: ${SPACING[5]} ${SPACING[5]} 0 ${SPACING[5]};
`
const StyledBodyTopSection = styled.div`
  display: flex;
  margin-bottom: ${SPACING[5]};
`
const StyledBodyHeader = styled.h1`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  margin: 0;
`
const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
`
const StyledBodyCopy = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  margin-bottom: 0;
`
const StyledImage = styled(Image)`
  min-width: 195px;
  max-width: 250px;
  border-radius: ${BORDER_RADIUS[1]};
`
const StyledModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const StyledBubbleContainer = styled.div`
  margin-right: ${SPACING[2]};
`

const StyledHintContainer = styled.div`
  margin-bottom: ${SPACING[5]};
`

const StyledSegmentError = styled.div`
  color: ${({ theme }) => theme?.COLORS?.ERRORS};
  font-size: 11px;
`

export const EditMessage = ({
  alt,
  children,
  className,
  copy,
  defaultValue,
  header,
  hint,
  message,
  messageBillingUsage,
  messageBubbleContents,
  prompts,
  src,
  tcpaMessage,
  isSaveDisabled = () => false,
  onSave,
  setMessage,
}: EditMessageProps): JSX.Element => {
  const { t } = useTranslation()
  const currentTheme = useCurrentTheme()
  const { settings } = useSettings()
  const [isOpen, setIsOpen] = useState(false)
  const [isOnConfirm, setIsOnConfirm] = useState(false)
  const shouldSave = useCallback(
    (values) => {
      onSave({
        ...values,
        // we append message text here since it isn't included in the form values
        // we also trim any whitespace from either end of the message string
        message: message.trim(),
      })
      setIsOpen(false)
      setIsOnConfirm(false)
    },
    [message, onSave],
  )
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = messageBillingUsage
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }

  const handleSave = (values) => {
    if (!prompts || !prompts.length) return shouldSave(values)
    setIsOnConfirm(true)
  }

  return (
    <Form
      onSubmit={handleSave}
      render={(formRenderProps) => {
        const { handleSubmit, valid, submitting, values, pristine, form } = formRenderProps
        const handleClose = () => {
          setIsOpen(false)
          setIsOnConfirm(false)
          setMessage(defaultValue)
          form.reset()
        }

        return (
          <div className={className}>
            {children ? (
              React.Children.map(children, (child) =>
                React.cloneElement(child, {
                  onClick: () => {
                    child.props.onClick()
                    setIsOpen(true)
                    setIsOnConfirm(false)
                    setMessage(defaultValue)
                  },
                }),
              )
            ) : (
              <StyledButton
                onClick={() => {
                  setIsOpen(true)
                  setIsOnConfirm(false)
                  setMessage(defaultValue)
                }}
                variant={BUTTON_VARIANTS.LINK}
              >
                {t('edit')}
              </StyledButton>
            )}
            <Modal maxHeight={840} onClose={handleClose} open={isOpen} shouldDismissOnBodyClick={false}>
              <form onSubmit={handleSubmit}>
                <Modal.Header />
                <Modal.Close onClick={handleClose} />
                <StyledBody>
                  <StyledBodyTopSection>
                    <div>
                      <StyledFlexContainer>
                        {messageBubbleContents && (
                          <StyledBubbleContainer>
                            <MessageBubble>{messageBubbleContents}</MessageBubble>
                          </StyledBubbleContainer>
                        )}
                        <StyledBodyHeader>{header}</StyledBodyHeader>
                      </StyledFlexContainer>
                      <StyledBodyCopy>{copy}</StyledBodyCopy>
                    </div>
                    {src && <StyledImage alt={alt} src={src} />}
                  </StyledBodyTopSection>
                  {hint && <StyledHintContainer>{hint}</StyledHintContainer>}
                  {tcpaMessage ? (
                    <TCPAMessage
                      defaultValue={message}
                      emojiSkinTone={settings.skinTone}
                      emojiTheme={currentTheme.type}
                      onChange={(currentValue) => setMessage(currentValue)}
                      requiredSuffix={tcpaMessage}
                      usageCounterProps={usageCounterProps}
                    />
                  ) : (
                    <MultimediaInput
                      emojiSkinTone={settings.skinTone}
                      emojiTheme={currentTheme.type}
                      gallery={false}
                      gif={false}
                      onChange={(e) => setMessage(e.target.value)}
                      onEmojiSelect={(_, value) => setMessage(value)}
                      usageCounterProps={usageCounterProps}
                      value={message}
                    />
                  )}
                  {messageBillingUsage.error &&
                    messageBillingUsage.error.segment &&
                    messageBillingUsage.segmentsAllowed && (
                      <StyledSegmentError>
                        Your message is too long. Please shorten it to {messageBillingUsage.segmentsAllowed} segment
                        {messageBillingUsage.segmentsAllowed > 1 && 's'}.
                      </StyledSegmentError>
                    )}
                </StyledBody>
                <Modal.Footer>
                  <StyledModalFooter>
                    <Button onClick={handleClose} variant={BUTTON_VARIANTS.OUTLINE}>
                      {t('cancel')}
                    </Button>
                    <Button
                      disabled={isSaveDisabled({
                        defaultValue,
                        currentValue: message,
                        valid,
                        submitting,
                        pristine,
                        messageBillingUsage,
                      })}
                      type="submit"
                    >
                      {t('saveChanges')}
                    </Button>
                  </StyledModalFooter>
                </Modal.Footer>
              </form>
            </Modal>
            <OnSavePrompts
              isOpen={isOnConfirm}
              onCancel={() => setIsOnConfirm(false)}
              onSave={() => shouldSave({ ...values })}
              prompts={prompts}
              textValue={message}
            />
          </div>
        )
      }}
    ></Form>
  )
}
