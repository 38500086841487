import { AppleIconCircled, CheckIcon2, SPACING, Typography } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { Trans, useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { BetaBadge } from 'components/BetaBadge'
import { useClient } from 'hooks/useClient'
import { Setting } from 'layouts/SettingsLayout'

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.COLORS.LINKS};
`

const AMB_LEGAL_TERMS_URL = 'https://www.community.com/legal/amb-beta-terms'

export function Amb(): JSX.Element {
  const { data: client } = useClient()
  const { COLORS } = useTheme()
  const { t } = useTranslation()
  const isAmbConnected = client?.communicationChannels.includes(CommunicationChannel.AMB)
  return (
    <Setting
      action={
        isAmbConnected ? (
          <>
            <CheckIcon2 color={COLORS.SUCCESS} /> {t('settings.integrations.amb.authorized')}
          </>
        ) : (
          t('settings.integrations.amb.inactive')
        )
      }
      disabled={!isAmbConnected}
      icon={<AppleIconCircled size={35} style={{ marginTop: '-6px' }} />}
      label={
        <>
          {t('settings.integrations.amb.title')} <BetaBadge />
        </>
      }
      text={
        isAmbConnected ? (
          <Typography color={COLORS.SUBTEXT} marginTop={SPACING[0]} variant="body2">
            <Trans i18nKey="settings.integrations.amb.authorizedExplainer">
              <StyledLink href={'https://www.community.com/client-terms'} rel="noopener noreferrer" target="blank" />
              <StyledLink href={'https://www.community.com/privacy'} rel="noopener noreferrer" target="blank" />
              <StyledLink href={AMB_LEGAL_TERMS_URL} rel="noopener noreferrer" target="blank" />
            </Trans>
          </Typography>
        ) : (
          <Typography color={COLORS.SUBTEXT} marginTop={SPACING[0]} variant="body2">
            <Trans i18nKey="settings.integrations.amb.inactiveExplainer">
              <StyledLink href={'https://www.community.com/client-terms'} rel="noopener noreferrer" target="blank" />
              <StyledLink href={'https://www.community.com/privacy'} rel="noopener noreferrer" target="blank" />
              <StyledLink href={AMB_LEGAL_TERMS_URL} rel="noopener noreferrer" target="blank" />
              <StyledLink href="mailto:yourfriends@community.com" rel="noopener noreferrer" target="blank" />
            </Trans>
          </Typography>
        )
      }
    />
  )
}
