import { Button } from '@community_dev/pixels'
import React from 'react'
import styled, { css, useTheme } from 'styled-components'

const StyledButtonsSection = styled.div`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  position: sticky;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 14px 12px;
`

const buttonStyle = css`
  margin: 0 4px;
  padding: 7px 16px;
`

const StyledDoneButton = styled(Button)`
  ${buttonStyle};
`

const StyledClearButton = styled(Button)`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  ${buttonStyle};
`

type FilterPaneProps = {
  children: React.ReactNode
  onClearFilters: () => any
  onClose?: () => any
  closeDropdown?: () => any
}

export const FilterPane = ({ onClearFilters, onClose, closeDropdown, children }: FilterPaneProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const handleClose = () => {
    if (closeDropdown) {
      closeDropdown()
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      {children}
      <StyledButtonsSection>
        <StyledClearButton color={COLORS?.APP_BACKGROUND_LEVEL_3} onClick={() => onClearFilters()} width="auto">
          Clear All
        </StyledClearButton>
        <StyledDoneButton color={COLORS?.BUTTON_PRIMARY} onClick={() => handleClose()} width="auto">
          Done
        </StyledDoneButton>
      </StyledButtonsSection>
    </>
  )
}
