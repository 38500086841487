import { SPACING, Typography } from '@community_dev/pixels'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { DisplayLinks, DisplayMedia } from './'

import { Campaign } from 'api/campaign'

const StyledMediaPreview = styled.div`
  text-align: left;
  display: flex;
  padding-top: ${SPACING[3]};
`

type CampaignItemMediaPreviewProps = {
  links: Campaign['linkInfo']
  linkCount: number
  media: Campaign['media']
  processing: boolean
}

export function MediaPreview({
  processing,
  links,
  linkCount,
  media,
}: CampaignItemMediaPreviewProps): JSX.Element | null {
  const { t } = useTranslation()
  const { COLORS } = useTheme()
  const hasLinkUrl = useMemo(() => Boolean((links || [])?.find((info) => info?.url)), [links])

  if (!media && !hasLinkUrl) {
    return null
  }

  if (processing) {
    return (
      <>
        <Typography color={COLORS.SUBTEXT} textAlign="left" variant="body2">
          {t('campaigns.processingMedia')}
        </Typography>
        <StyledMediaPreview>
          {Boolean(media?.url) && <DisplayMedia media={media} />}
          {links && hasLinkUrl && <DisplayLinks linkCount={linkCount} links={links} />}
        </StyledMediaPreview>
      </>
    )
  }

  return (
    <StyledMediaPreview>
      {Boolean(media?.id) && <DisplayMedia media={media} />}
      {links && hasLinkUrl && <DisplayLinks linkCount={linkCount} links={links} />}
    </StyledMediaPreview>
  )
}
