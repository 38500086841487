import {
  Button,
  BUTTON_VARIANTS,
  COLORS,
  composeValidators,
  CopyIcon,
  Ellipsis,
  Info,
  InfoIcon,
  Layout,
  Modal,
  required as ruleRequired,
  SPACING,
  TextInput,
  Typography,
} from '@community_dev/pixels'
import { useMutation, useQuery } from '@tanstack/react-query'
import copy from 'copy-to-clipboard'
import { useCallback } from 'react'
import { Field, Form } from 'react-final-form'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ApiAuthToken, postApiAuthToken, putApiAuthToken } from 'api/api-auth-tokens'
import { acceptTcpaLiability, getTcpaLanguage } from 'api/legal-text'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERY_CACHE } from 'constants/query-cache'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import { useHasCapability } from 'hooks/useUserCapability'
import Sentry from 'integrations/Sentry'

export type ApiAuthTokensEditModalProps = {
  onClose?: () => unknown
  /** Called when a token was created or updated */
  onSuccess?: () => unknown
  /** If present, the modal is used for editing. Otherwise it creates a new Token. */
  token?: ApiAuthToken
  /** A list of all existing tokens. Used check token name uniqueness */
  tokens?: ApiAuthToken[]
}

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.COLORS.LINKS};
`

const StyledEllipsis = styled(Ellipsis)`
  max-width: 200px;
`

const StyledModalBody = styled(Modal.Body)`
  padding: ${SPACING[5]} ${SPACING[8]} ${SPACING[1]} ${SPACING[8]};
`

const StyledWell = styled(Layout)`
  margin-top: ${SPACING[4]};
  border: ${({ theme }) => `1px solid ${theme.COLORS.BORDERS}`};
  border-radius: 4px;
  text-align: center;
  padding: ${SPACING[4]};
`

export function ApiAuthTokensEditModal({
  onClose = () => null,
  token: existingToken,
  tokens = [],
  onSuccess = () => null,
}: ApiAuthTokensEditModalProps): JSX.Element {
  const { data: seat } = useSeat()
  const clientId = useClientId()
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const hasApiTokenWrite = useHasCapability(CAPABILITIES.CLIENT.API_AUTH_TOKENS.WRITE)

  const { mutateAsync: createToken, data: createdToken } = useMutation(postApiAuthToken, {
    onSuccess() {
      onSuccess()
    },
    onError(e) {
      Sentry.captureException(e)
      showToastMessage({
        message: 'Could not create Token',
        success: false,
      })
    },
  })

  const { data: tcpaLanguage } = useQuery([QUERY_CACHE.TCPA_LANGUAGE], getTcpaLanguage)

  const { mutateAsync: updateToken } = useMutation(putApiAuthToken, {
    onSuccess() {
      onSuccess()
    },
    onError(e) {
      Sentry.captureException(e)
      showToastMessage({
        message: 'Could not update Token',
        success: false,
      })
    },
  })
  const ruleNotEmpty = (tokenName?: string) => {
    return tokenName?.trim() === '' ? 'cannot be empty' : undefined
  }
  const ruleUniqueTokenName = useCallback(
    (tokenName?: string) => {
      return tokens.some((token) => token.name.trim() === tokenName?.trim()) && tokenName !== existingToken?.name
        ? 'is already used.'
        : undefined
    },
    [tokens, existingToken],
  )

  const onSubmit = async ({ name }: { name: string }) => {
    if (existingToken) {
      await updateToken({ clientId, id: existingToken.id, name, seatId: seat!.id })
      showToastMessage({ message: 'Token updated' })
      onClose()
    } else {
      if (tcpaLanguage?.data.version) {
        await acceptTcpaLiability({
          languageVersion: tcpaLanguage?.data.version,
          clientId,
        })
      }
      createToken({ clientId, name, seatId: seat!.id })
    }
  }

  if (createdToken) {
    return (
      <Modal onClose={onClose} open={true}>
        <Modal.Header>
          Access Token “<StyledEllipsis>{createdToken.name}</StyledEllipsis>” Created
        </Modal.Header>
        <StyledModalBody>
          <Info icon={<InfoIcon />}>
            <b>{t('settings.apiAuthTokens.copyTokenNow')}</b>
          </Info>
          <StyledWell>
            <Typography display="block" marginBottom={SPACING[4]} whiteSpace="break-spaces" wordBreak="break-all">
              {createdToken.token}
            </Typography>
            <Button
              onClick={() => {
                copy(createdToken.token)
                showToastMessage({
                  message: t('copiedToClipboard'),
                  success: true,
                })
              }}
              width="100%"
            >
              <CopyIcon color={COLORS.BUTTON_PRIMARY_TEXT} size={17} /> {t('copyToClipboard')}
            </Button>
          </StyledWell>
        </StyledModalBody>
        <Modal.Footer>
          <Button onClick={onClose} variant={BUTTON_VARIANTS.OUTLINE}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  } else {
    return (
      <Modal onClose={onClose} open={true}>
        <Modal.Header>
          {existingToken ? t('settings.apiAuthTokens.updateToken') : t('settings.apiAuthTokens.createToken')}
        </Modal.Header>
        <Form<{ name: string }>
          initialValues={{ name: existingToken?.name }}
          onSubmit={onSubmit}
          render={({ handleSubmit, valid, submitting, values }) => (
            <>
              <StyledModalBody>
                <Typography marginBottom={SPACING[5]} variant="body2">
                  <Trans i18nKey="settings.apiAuthTokens.createExplainer">
                    <Button
                      color={COLORS?.LINKS}
                      forwardedAs="a"
                      href="https://developer.community.com/reference/webhook-api-introduction"
                      target="_blank"
                      variant={BUTTON_VARIANTS.LINK}
                    />
                  </Trans>
                </Typography>
                <form id="authTokenForm" onSubmit={handleSubmit}>
                  <Field name="name" validate={composeValidators(ruleRequired, ruleNotEmpty, ruleUniqueTokenName)}>
                    {({ input, meta }) => (
                      <TextInput
                        {...input}
                        autoFocus
                        error={meta.touched && meta.modified && meta.error}
                        label={t('settings.apiAuthTokens.tokenName')}
                        subtext={t('settings.apiAuthTokens.tokenNameHint')}
                      />
                    )}
                  </Field>
                  <Layout>
                    <Typography display="block" fontSize="13px" textAlign="left" variant="caption1">
                      {t('expiration')}
                    </Typography>
                    <Typography display="block" marginTop={SPACING[1]} textAlign="left" variant="caption1">
                      {t('settings.apiAuthTokens.tokenAutoExpire')}
                    </Typography>
                  </Layout>
                </form>
                <Typography component="p" marginTop={SPACING[5]} variant="caption2">
                  {tcpaLanguage?.data.language}
                </Typography>
                <Typography component="p" variant="caption2">
                  <Trans i18nKey="settings.apiAuthTokens.legalMoreInfo">
                    <StyledLink href="https://www.community.com/legal/privacy-policy" target="_blank" />
                    <StyledLink href="https://www.community.com/legal/customer-terms" target="_blank" />
                  </Trans>
                </Typography>
              </StyledModalBody>
              <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant={BUTTON_VARIANTS.OUTLINE}>
                  {t('cancel')}
                </Button>
                <Button
                  disabled={
                    !hasApiTokenWrite ||
                    !valid ||
                    submitting ||
                    (existingToken !== undefined && values.name === existingToken.name)
                  }
                  form="authTokenForm"
                  type="submit"
                >
                  {existingToken === undefined ? t('continue') : t('saveChanges')}
                </Button>
              </Modal.Footer>
            </>
          )}
        />
      </Modal>
    )
  }
}
