import { HTTP, route } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'

type TcpaLanguageResponse = {
  data: {
    language: string
    version: number
  }
}

export function acceptLegalTerms({ clientId, type }: { clientId: string; type: string }): Promise<any> {
  return request(route(ENDPOINTS.LEGAL_TERMS.ACCEPT, { clientId }), {
    method: HTTP.POST,
    body: {
      type,
    },
  })
}

export function getTcpaLanguage(): Promise<TcpaLanguageResponse> {
  return request(ENDPOINTS.TCPA.LEGAL_LIABILITY)
}

export function acceptTcpaLiability({
  languageVersion,
  clientId,
}: {
  languageVersion: number
  clientId: string
}): Promise<unknown> {
  return request(route(ENDPOINTS.TCPA.TCPA_ACCEPT, { clientId }), {
    method: HTTP.POST,
    body: {
      tcpaLiabilityLanguageVersion: languageVersion,
    },
  })
}
