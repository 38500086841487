import { FONT_SIZE, SPACING } from '@community_dev/pixels'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Route } from 'react-router-dom'
import styled from 'styled-components'

import { ROUTES } from 'constants/routes'
import { useClientId } from 'hooks/useClient'
import { useShopifyStoreDetails } from 'hooks/useShopify'

const StyledTabs = styled.ul`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 0 ${SPACING[5]};

  a {
    padding-bottom: 8px;
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
    text-decoration: none;

    &.active {
      border-bottom: 2px solid ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
      color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
    }
  }

  li {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
`

const StyledTitle = styled.h1`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H1};
  margin: 0 0 ${SPACING[1]} 0;
`

const StyledSubhead = styled.div`
  font-size: ${FONT_SIZE[3]};
`

const TitleGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 ${SPACING[5]} 0;
`

const StyledActionsContainer = styled.div`
  height: 40px;
  *:not(:last-child) {
    margin-right: ${SPACING[3]};
  }
`

const getReferenceDates = () => {
  const MIDNIGHT = new Date()
  MIDNIGHT.setHours(0, 0, 0, 0)
  const MIDNIGHT_YESTERDAY = new Date()
  MIDNIGHT_YESTERDAY.setHours(0, 0, 0, 0)
  MIDNIGHT_YESTERDAY.setDate(MIDNIGHT_YESTERDAY.getDate() - 1)
  const LAST_WEEK = new Date()
  LAST_WEEK.setHours(0, 0, 0, 0)
  LAST_WEEK.setDate(LAST_WEEK.getDate() - 6)
  return { MIDNIGHT, MIDNIGHT_YESTERDAY, LAST_WEEK }
}

const getUpdatedString = (updatedAtTimeStamp: string | undefined): string | null => {
  if (!updatedAtTimeStamp) return null
  const updated = new Date(updatedAtTimeStamp)
  if (isNaN(updated.getTime())) {
    // invalid date, return null
    return null
  }
  const { MIDNIGHT, MIDNIGHT_YESTERDAY, LAST_WEEK } = getReferenceDates()
  const day = (() => {
    switch (true) {
      case updated > MIDNIGHT:
        return 'today'
      case updated > MIDNIGHT_YESTERDAY:
        return 'yesterday'
      case updated > LAST_WEEK:
        return updated.toLocaleString('en-US', { weekday: 'long' })
      default:
        return updated.toLocaleString('en-US', {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        })
    }
  })()
  const time = updated.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  })
  return `${day} at ${time}`
}

type InsightsLayoutProps = {
  enabled?: boolean
  updatedAtTimeStamp?: string
  children: React.ReactNode
  actions?: React.ReactNode
}

export const InsightsLayout = ({
  updatedAtTimeStamp,
  enabled = true,
  children,
  actions,
}: InsightsLayoutProps): JSX.Element => {
  const { t } = useTranslation()
  const updatedAt = getUpdatedString(updatedAtTimeStamp)
  const clientId = useClientId()
  const { shopifyExists } = useShopifyStoreDetails(clientId)

  return (
    <>
      <TitleGrid>
        <div>
          <StyledTitle>{t('insights')}</StyledTitle>
          <StyledSubhead>
            <Route exact path={ROUTES.INSIGHTS.ROOT}>
              {updatedAt && enabled ? t('updatedAt', { date: updatedAt }) : null}
            </Route>
            &nbsp;
          </StyledSubhead>
        </div>
        <StyledActionsContainer>{actions}</StyledActionsContainer>
      </TitleGrid>
      {shopifyExists && (
        <StyledTabs>
          <li>
            <NavLink exact to={ROUTES.INSIGHTS.ROOT}>
              {t('general')}
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.INSIGHTS.SHOPIFY}>{t('shopify')}</NavLink>
          </li>
        </StyledTabs>
      )}
      {children}
    </>
  )
}
