import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { UrlMetadata, getUrlMetadata } from 'api/urlMetadata'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'

type UseUrlMetadataProps = {
  url: string
}

type UseUrlMetadataReturn = UseQueryResult<UrlMetadata>

export function useUrlMetadata({ url }: UseUrlMetadataProps): UseUrlMetadataReturn {
  const clientId = useClientId()

  return useQuery([QUERY_CACHE.URL_METADATA, { clientId, url }], () => getUrlMetadata({ url, clientId: clientId! }), {
    retry: false,
    enabled: !!clientId && !!url,
    staleTime: STALE_TIME.FOREVER,
  })
}
