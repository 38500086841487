import { generalConfig } from 'utils/config'

export const ENDPOINTS = {
  ADD_ONS: {
    DATA: `${generalConfig.staticUrl}/add-ons/data.json`,
    REQUEST_FEATURE: '/client-dashboard/v3/:clientId/add-ons/feature-request',
  },
  AI_INTERFACE: {
    FULL: '/ai-interface/v1/clients/:clientId/suggest-campaigns/full',
    QUICK: '/ai-interface/v1/clients/:clientId/suggest-campaigns/quick',
    SUGGEST_DMS: '/ai-interface/v1/clients/:clientId/suggest-dms',
  },
  AMB: {
    SIGN_UP_URL: '/amb_handler/v1/clients/:clientId/sign_up_url',
  },
  API_AUTH_TOKENS: {
    TOKEN: '/auth/v1/:clientId/auth-tokens/:id',
    TOKENS: '/auth/v1/:clientId/auth-tokens',
  },
  ASSIGN_BY_QUERY: '/client-dashboard/v2/:clientId/tags/:tagId/fans/assign-by-query',
  BILLING: {
    CLIENT: '/billing/v1/clients/:clientId',
    CREDIT_BUNDLES: '/billing/v3/clients/:clientId/credits',
    DOWNLOAD_USAGE: '/download/v3/files/:clientId/:fileName/multi-channel-usage?noredirect=true',
    INFO: '/billing/v1/clients/:clientId/billing_info',
    INVOICES: '/billing/v3/clients/:clientId/invoices',
    SEGMENT_COUNT_CURRENT_MONTH: '/accountant/v1/clients/:clientId/segment-usage/monthly-total',
    SEGMENT_SUBSCRIPTION_USAGE: '/billing/v3/clients/:clientId/usage',
    SETUP_INTENT: '/billing/v1/clients/:clientId/setup_intent',
    SUBSCRIPTION: '/billing/v2/clients/:clientId/subscription',
    TRACK: '/billing/v1/clients/:clientId/track',
  },
  CAMPAIGN: {
    DETAILS: '/client-dashboard/v2/:clientId/messaging/sms-campaigns/:campaignId',
    RECIPIENTS: `/client-dashboard/messaging/sms-campaigns/:campaignId/recipients`,
    RESPONSES: '/client-dashboard/v2/:clientId/messaging/sms-campaigns/:campaignId/responses',
  },
  CAMPAIGNS: {
    DEFAULT: '/client-dashboard/v3/:clientId/messaging/sms-campaigns',
    LIST: '/client-dashboard/v2/:clientId/messaging/sms-campaigns',
    RECURRING: '/client-dashboard/v3/:clientId/messaging/recurring-campaigns',
    SCHEDULED: '/client-dashboard/v3/:clientId/messaging/scheduled-sms-campaigns',
    SCHEDULED_APPROVAL_STATUS:
      '/client-dashboard/v3/:clientId/messaging/scheduled-sms-campaigns/:campaignId/:approvalStatus',
  },
  CAMPAIGN_SUMMARY: '/campaign-summaries/v1/clients/:clientId/campaigns/:campaignId/campaign-summary',
  CHATS: '/client-dashboard/messaging/chats',
  CLIENT: '/client-dashboard/clients',
  CLIENTS: {
    ONBOARDING_TASKS: '/clients/onboarding-tasks',
    STATUS: 'clients/onboarding-tasks/status',
    TASK: '/clients/onboarding-tasks/:id',
  },
  CLUSTER: {
    DETAIL_INDEX:
      '/client-dashboard/v2/:clientId/messaging/sms-campaigns/:campaignId/response-clusters/:clusterId/responses',
    INDEX: '/client-dashboard/v2/:clientId/messaging/sms-campaigns/:campaignId/response-clusters',
  },
  COMPOSE_MESSAGE: '/client-dashboard/v3/:clientId/messaging/sms-campaigns',
  COMPOSE_MESSAGE_MEDIA: '/client-dashboard/v3/:clientId/messaging/message-campaigns',
  COMPOSE_SCHEDULE_MESSAGE: '/client-dashboard/v3/:clientId/messaging/scheduled-sms-campaigns',
  CONTENT_WARNING_CHECK: '/cullkin/v1/content-warning-check',
  CONTEXTS: {
    LIST: '/client-dashboard/context',
    SET: '/auth/v1/context',
  },
  CURRENT_SEAT: '/client-dashboard/v1/seats',
  DOWNLOAD: '/download/v1/files/:clientId/:fileName?noredirect=true',
  FANS: {
    BLOCK: '/client-dashboard/fans/:fanId/block',
    FAN: '/client-dashboard/fans/:fanId',
    INDEX_BY_QUERY: '/client-dashboard/fans/index-by-query',
    OPT_OUT: '/client-dashboard/fans/:fanId/opt-out',
    UNSUBSCRIBE: 'client-dashboard/fans/:fanId/opt-out',
  },
  FAST_SUMMARIES: { CLIENT: '/fast_summaries/v1/clients/:clientId/compare' },
  IMPORT: {
    DETAILS: '/subscription-data/v1/clients/:clientId/imports/:importId',
    HISTORY: '/subscription-data/v1/clients/:clientId/imports',
    IMPORT: '/subscription-data/v1/clients/:clientId/imports',
  },
  INSIGHTS: {
    AGE_AND_GENDER: '/metrics/v1/insights/clients/:clientId/age_gender',
    BEST_HOUR_OF_WEEK: '/metrics/v1/insights/clients/:clientId/best-hour',
    GEO: {
      COUNTRY: '/metrics/v1/insights/clients/:clientId/country',
      MSA: '/metrics/v1/insights/clients/:clientId/msa',
      STATE: '/metrics/v1/insights/clients/:clientId/state',
    },
    GROWTH: '/metrics/v1/insights/clients/:clientId/growth/:timeRange',
    NEW_MEMBERS: '/metrics/v1/insights/clients/:clientId/subscribes/:timeRange',
  },
  INTEGRATIONS: {
    SALESFORCE: {
      INSTANCES: '/integrations/salesforce/v1/instances/:clientId',
      MARKETING_CLOUD_OAUTH: '/integrations/salesforce/v1/marketing_cloud/:clientId/oauth',
      OAUTH: '/integrations/salesforce/v1/:clientId/oauth',
    },
  },
  INVITES: {
    ACCEPT: '/seats/:seatId/invitations/:token',
    BY_TOKEN: '/seats/invitations/:token',
    INVITE: '/clients/v1/:clientId/invitations/:inviteId',
    INVITES: '/clients/v1/:clientId/invitations',
  },
  KEYWORD: {
    CHECK_KEYWORD: '/apollo/v1/:clientId/keywords/normalized?original_text=:keyword',
    KEYWORD: '/apollo/v1/:clientId/keywords/:id',
    KEYWORDS: '/apollo/v1/:clientId/keywords',
  },
  LEGAL_TERMS: {
    ACCEPT: '/clients/v1/:clientId/accept-terms',
  },
  LINK_HITS: {
    CAMPAIGN: '/metrics/v1/link-hits/clients/:clientId/campaigns/:campaignId',
    TIME_VIEW: '/metrics/v1/link-hits/time-view/clients/:clientId/campaigns/:campaignId',
  },
  LOGIN: {
    CHANGE_PASSWORD: '/client-dashboard/v1/seats/reset-password',
    CHANGE_PASSWORD_WITH_TOKEN: '/seats/reset-password/:token',
    FORGOT_PASSWORD: '/seats/reset-password',
    LOGIN: '/auth/v1/login',
    LOGOUT: '/auth/v1/logout',
    MFA: '/auth/v1/login/callback/mfa',
    REFRESH_TOKEN: '/auth/v1/refresh?force_recreate=true',
    VALIDATE_TOKEN: '/seats/reset-password/:token',
  },
  MEDIA: {
    CREATE: '/media/v1/clients/:clientId/media',
    GET: '/media/v1/clients/:clientId/media/:mediaId',
  },
  MESSAGE_LIMITS: '/message-limits/v1/clients/:clientId',
  MESSAGE_URLS: '/client-dashboard/v3/:clientId/messaging/message-urls',
  MESSAGING: {
    CHAT: '/client-dashboard/v2/:clientId/messaging/chats/:fanSubscriptionId',
    DM: '/client-dashboard/v2/:id/messaging/dm',
    HISTORY: '/client-dashboard/v2/:clientId/fans/:fanId/message-history',
    MEDIA: '/client-dashboard/v2/:clientId/messaging/fans/:fanId/messages',
    MESSAGE_CAMPAIGNS: '/client-dashboard/v3/:clientId/messaging/message-campaigns/:campaignId',
  },
  METADATA: {
    APP_VERSIONS: '/metadata/web',
  },
  MGU: {
    EMBED: '/member-growth-units/v1/clients/:clientId/embeds/:embedId',
    EMBEDS: '/member-growth-units/v1/clients/:clientId/embeds',
    TRACK: '/member-growth-units/v1/clients/:clientId/embeds/:embedId/track',
  },
  PREFERENCES: '/preferences/v1/seats',
  ROLES: {
    ROLES: '/capabilities/v1/roles',
    SEAT_ROLE: '/capabilities/v2/clients/:clientId/seat-mappings/:seatMappingId/roles',
  },
  SEARCH: {
    MESSAGES: '/full-text-search/:clientId/messages/index-by-query',
  },
  SEATS: {
    SEAT: '/clients/v1/:clientId/seats/:seatId',
    SEATS: '/clients/v1/:clientId/seats',
  },
  SETTINGS: {
    CLIENT_SETTINGS: '/client-dashboard/v3/:clientId/client_settings',
    CONTACT_PHOTO: '/client-dashboard/settings/profile-image',
    KEYWORDS: '/settings/customize/keywords',
    KEYWORD_MESSAGES: '/client-dashboard/settings/keyword-messages',
    KEYWORD_MESSAGES_UPDATE: '/client-dashboard/settings/keyword-messages/:keywordId',
    MESSAGE: '/client-dashboard/settings/fan-onboarding-messages/:id',
    MESSAGES: '/client-dashboard/settings/fan-onboarding-messages',
    NAME: '/client-dashboard/v2/:id/settings/name',
    RESTRICTED_KEYWORDS: '/client-dashboard/settings/blacklist-keywords',
    RESTRICTED_KEYWORD_DELETE: '/client-dashboard/settings/blacklist-keywords/:id',
    SEAT_PREFERENCES: '/preferences/v1/seats/:seatId',
    VOICEMAIL: '/client-dashboard/settings/voicemail',
  },
  SHOPIFY: {
    CUSTOMER_DETAILS: '/shopify_integration/v1/:clientId/customers?fan_subscription_id=:fanSubscriptionId',
    INSIGHTS: '/shopify_integration/v1/:clientId/insights',
    STORE_DETAILS: '/shopify_integration/v1/:clientId/store_details',
  },
  SUBSCRIPTION_DATA: {
    COUNT: '/subscription-data/v1/clients/:clientId/subscriptions/count',
    FIELDS: '/subscription-data/v1/clients/:clientId/fields',
    FOR_SUBSCRIBER: '/subscription-data/v1/clients/:clientId/subscriptions/:fanSubscriptionId',
    MOST_ACTIVE_MEMBERS_AVAILABILITY: '/subscription-data/v1/clients/:clientId/most_active_members/availability',
    PLACEHOLDERS: '/subscription-data/v1/clients/:clientId/placeholders',
  },
  TAG: {
    CHECK_KEYWORD: '/client-dashboard/v1/tags/check-keyword',
    KEYWORD: '/client-dashboard/v1/tags/:tagId/keywords/:keywordId',
    KEYWORDS: '/client-dashboard/v1/tags/:tagId/keywords',
    LIST: '/client-dashboard/v3/:clientId/tags',
    POST_FAN: '/client-dashboard/v1/tags/:tagId/fans',
    TAG: '/clients/v1/:clientId/tags/:tagId',
  },
  TAGS: '/clients/v1/:clientId/tags',
  TCPA: {
    LEGAL_LIABILITY: '/clients/tcpa-liability-acceptance-language',
    TCPA_ACCEPT: '/clients/v1/:clientId/accept-tcpa-liability',
  },
  TCR: {
    CARRIER_USAGE: '/twilio_onboarding/v1/clients/:clientId/carrier_usage',
    REGISTRATION: '/twilio_onboarding/v2/clients/:clientId/seats/:seatId/registration',
    RETRY_OTP_VERIFICATION: '/twilio_onboarding/v1/clients/:clientId/seats/:seatId/registration/sms_otp',
    STANDARD_REGISTRATION: '/twilio_onboarding/v1/clients/:clientId/seats/:seatId/standard_registration',
    STARTER_REGISTRATION: '/twilio_onboarding/v1/clients/:clientId/seats/:seatId/starter_registration',
  },
  TIMEZONES: '/time-zones/v1/time-zone-groups',
  UNASSIGN_BY_QUERY: '/client-dashboard/v2/:clientId/tags/:tagId/fans/unassign-by-query',
  URL_METADATA: '/url_metadata/v1/clients/:clientId/preview?url=:url',
  WEBHOOKS: {
    WEBHOOK: '/outbound_webhooks_handler/v1/clients/:clientId/webhooks/:webhookId',
    WEBHOOKS: '/outbound_webhooks_handler/v1/clients/:clientId/webhooks',
    WEBHOOKS_STATUS: '/metrics/v1/clients/:clientId/webhooks',
    WEBHOOK_STATISTICS: '/metrics/v1/clients/:clientId/webhooks/:webhookId',
  },
  WHATSAPP: {
    INTEGRATION_STATUS: '/infobip_gateway/v2/clients/:clientId/registration',
    REGISTER_INTEGRATION: '/infobip_gateway/v2/clients/:clientId/registration',
    REGISTRY_INTEGRATION_RETRY: '/infobip_gateway/v2/clients/:clientId/registration/retry-sender-verification',
  },
  WORKFLOW_CONFIG: {
    MANIFESTS: '/workflow-config/v1/clients/:clientId/workflows/:id/manifests',
    METRICS: '/metrics/v1/clients/:clientId/workflows/:workflowId',
    WORKFLOW: '/workflow-config/v1/clients/:clientId/workflows/:id',
    WORKFLOWS: '/workflow-config/v1/clients/:clientId/workflows',
  },
} as const
