import { humanizeBuiltInFilterShortened } from '@community_dev/filter-dsl/lib/humanize/humanizeBuiltInFilters'
import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorFilter,
  SelectorOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ApiError } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { FilterOptionsWithCounts } from './types'
import { useCountByQueries, useCountByQuery, useMultiChannelCountByQuery } from './useCountByQuery'
import { CountByQueryFnReturn } from './useCountByQuery'

import { STALE_TIME } from 'constants/query-cache'
import { FilterSelectionType } from 'contexts/FilterProvider/FilterProvider'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useClient } from 'hooks/useClient'

export const everyoneFilter: SelectorFilter = {
  operator: SelectorOperators.EQUALS,
  operand: {
    field_key: BuiltInFields.EVERYONE,
    field_label: fieldLabelFor(BuiltInFields.EVERYONE),
    source: FieldSources.BUILT_IN,
    value: '*',
    type: FieldTypes.STRING,
  },
}

type UseEveryoneCountArgs<SelectData = CountByQueryFnReturn> = {
  traceId?: string
  communicationChannel?: CommunicationChannel
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

export const useEveryoneCount = <SelectData = CountByQueryFnReturn>({
  traceId,
  communicationChannel,
  options,
}: UseEveryoneCountArgs<SelectData> = {}): UseQueryResult<SelectData, ApiError> => {
  return useCountByQuery<SelectData>({
    filters: everyoneFilter,
    traceId: traceId || 'everyone-count',
    communicationChannel,
    options,
  })
}

export const useCurrentFiltersWithEveryoneCount = (type: FilterSelectionType = 'includes'): FilterOptionsWithCounts => {
  const { previewNextFilter, communicationChannel } = useFilters()

  const nextFilter = previewNextFilter(everyoneFilter, type) as MemberDataFilter

  return {
    options: [
      {
        filter: everyoneFilter,
        label: humanizeBuiltInFilterShortened(everyoneFilter),
      },
    ],
    counts: useCountByQueries({
      filters: [nextFilter],
      communicationChannel,
      traceId: 'current-filters-with-everyone-count',
    }),
  }
}

type MemberCountChannelWithTotal = {
  [key in CommunicationChannel | 'unknown']?: number
} & {
  total: number
  isLoading: boolean
}

export const useAllMemberCount = (): MemberCountChannelWithTotal => {
  const { data: client } = useClient()
  const { communicationChannels = [] } = client || {}

  const allChannelsCount = useMultiChannelCountByQuery({
    filters: everyoneFilter,
    communicationChannels: communicationChannels,
    traceId: 'all-member-count',
    options: {
      staleTime: STALE_TIME.FIVE_MINUTES,
    },
  })

  const totalCount = Object.values(allChannelsCount).reduce((acc, value) => acc + (value.data?.count ?? 0), 0)
  const isLoading = Object.values(allChannelsCount).some(({ isInitialLoading }) => isInitialLoading)

  return {
    total: totalCount,
    isLoading,
    ...Object.entries(allChannelsCount).reduce((acc, [key, value]) => {
      return { ...acc, [key as CommunicationChannel]: value.data?.count ?? 0 }
    }, {} as Record<CommunicationChannel, number>),
  }
}
