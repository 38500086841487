import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createParagraphNode, $createTextNode } from 'lexical'
import { $getRoot, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from 'lexical'
import { useEffect } from 'react'

export type ReplaceTextPayload = Readonly<{ text: string }>
export const REPLACE_TEXT_COMMAND: LexicalCommand<ReplaceTextPayload> = createCommand()

/**
 * This simple plugin add a command `REPLACE_TEXT_COMMAND` to overwrite the editor text
 */
export function useReplaceTextPlugin(): null {
  const [editor] = useLexicalComposerContext()

  const replaceText = ({ text }) => {
    editor.update(() => {
      const root = $getRoot()

      root.select()
      root.clear()

      const paragraphNode = $createParagraphNode()
      const textNode = $createTextNode(text)

      paragraphNode.append(textNode)

      root.append(paragraphNode)

      root.selectEnd()
    })

    return true
  }

  useEffect(() => {
    return editor.registerCommand(REPLACE_TEXT_COMMAND, replaceText, COMMAND_PRIORITY_EDITOR)
  }, [editor])

  return null
}
