import { FONT_WEIGHT } from '@community_dev/pixels'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ImportStatuses } from 'api/imports'
import { getImport, getImportHistory } from 'api/imports'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { useClientId } from 'hooks/useClient'
import { useMemberDataPermissions } from 'hooks/useMemberDataPermissions'
import { useToastMessage } from 'hooks/useToastMessage'
import { route } from 'utils/router'

const StyledLink = styled(Link)`
  font-weight: ${FONT_WEIGHT[6]};
  color: ${({ theme }) => theme?.COLORS?.LINKS};
`

type ImportPollingContextValue = {
  resetPolling(): void
}

export const ImportPollingContext = createContext<ImportPollingContextValue | null>(null)

ImportPollingContext.displayName = 'ImportPollingContext'

export function useImportPollingContext(): ImportPollingContextValue {
  const context = useContext(ImportPollingContext)

  if (!context) {
    throw new Error('useImportPollingContext must be used within a ImportPollingProvider')
  }

  return context
}

type ImportPollingProviderProps = {
  children?: ReactNode
  enabled?: boolean
}

export function ImportPollingProvider({ children, enabled = true }: ImportPollingProviderProps): JSX.Element {
  const [isPollingEnabled, setIsPollingEnabled] = useState(true)

  const clientId = useClientId()
  const { canPollHistory } = useMemberDataPermissions()
  const queryClient = useQueryClient()
  const history = useHistory()
  const { showToastMessage } = useToastMessage()
  const location = useLocation()

  const pageSize = 10
  const pageNumber = 1
  const importHistoryResult = useQuery(
    [QUERY_CACHE.IMPORT.HISTORY, { pageNumber, pageSize, clientId }],
    () => getImportHistory({ clientId, pageSize, pageParam: pageNumber }),
    {
      enabled: !!(clientId && canPollHistory && enabled),
    },
  )
  const processingImport = importHistoryResult.data?.data?.find((importData) => {
    return importData.status === ImportStatuses.AWAITING_UPLOAD || importData.status === ImportStatuses.PROCESSING
  })
  const importId = processingImport?.id || ''

  const shouldFetch = !!(clientId && canPollHistory && enabled && isPollingEnabled && importId)
  const importResult = useQuery(
    [QUERY_CACHE.IMPORT.IMPORT, { clientId, importId }],
    () => getImport({ clientId, importId }),
    {
      enabled: shouldFetch,
      refetchInterval: shouldFetch && isPollingEnabled ? STALE_TIME.FIVE_SECONDS : undefined,
    },
  )

  useEffect(() => {
    const status = importResult?.data?.data?.status

    if (!importResult.isSuccess || status === ImportStatuses.AWAITING_UPLOAD || status === ImportStatuses.PROCESSING) {
      return
    }

    setIsPollingEnabled(false)

    // skip toast notification on summary page and redirect to details
    if (location.pathname === ROUTES.SETTINGS.CUSTOMIZE.IMPORT.SUMMARY && importId) {
      history.replace(route(ROUTES.SETTINGS.CUSTOMIZE.IMPORT.DETAILS, { importId }))
      return
    }

    if (status === ImportStatuses.COMPLETED || status === ImportStatuses.COMPLETED_WITH_ERRORS) {
      showToastMessage({
        maxWidth: '375px',
        message: (
          <Trans i18nKey="settings.import.successNotificationMessage">
            <StyledLink to={route(ROUTES.SETTINGS.CUSTOMIZE.IMPORT.DETAILS, { importId })}></StyledLink>
          </Trans>
        ),
      })
    }

    if (status === ImportStatuses.FAILED) {
      showToastMessage({
        maxWidth: '375px',
        success: false,
        message: (
          <Trans i18nKey="settings.import.failedNotificationMessage">
            <StyledLink to={route(ROUTES.SETTINGS.CUSTOMIZE.IMPORT.DETAILS, { importId })}></StyledLink>
          </Trans>
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importResult.data, importResult.isSuccess, importId])

  const resetPolling = useCallback(() => {
    setIsPollingEnabled(true)
    queryClient.invalidateQueries([QUERY_CACHE.IMPORT.IMPORT])
    queryClient.invalidateQueries([QUERY_CACHE.IMPORT.HISTORY])
    queryClient.invalidateQueries([QUERY_CACHE.CUSTOM_DATA_FIELDS])
  }, [queryClient])

  return <ImportPollingContext.Provider value={{ resetPolling }}>{children}</ImportPollingContext.Provider>
}
