import { MessageBillingUsage, useMessageBillingUsage } from '@community_dev/hooks'
import { BORDER_RADIUS, Button, DIALOG_VARIANTS, Dialog, IconTabs, SPACING } from '@community_dev/pixels'
import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { TAB_IDS } from '../constants/tabs'
import { AppearanceIcon, BehaviorIcon, ConfirmationIcon } from '../Icons'

import { AppearanceForm } from './components/AppearanceForm'
import { BehaviorForm } from './components/BehaviorForm'
import { ConfirmationForm } from './components/ConfirmationForm'
import { CreateActions } from './components/CreateActions'
import { InstructionModal } from './components/InstructionModal'
import { Preview } from './components/Preview'
import { SaveActions } from './components/SaveActions'

import { Embed, EmbedStatus, postTrackEmbed } from 'api/mgu'
import { useClientId } from 'hooks/useClient'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import events from 'utils/analytics/events'

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  max-height: 873px;
  border-radius: ${BORDER_RADIUS[1]};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  margin-bottom: ${SPACING[8]};
`

const StyledFormPane = styled.div`
  position: relative;
  display: flex;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  width: 375px;

  > div {
    width: 375px;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    [role='tabpanel'] {
      flex: 1;
      overflow: scroll;
    }

    [role='tablist'] {
      justify-content: space-between;
      padding: ${SPACING[2]} ${SPACING[4]};

      li {
        max-width: 105px;
        width: 105px;
      }
    }
  }
`

const StyledCreateFormActions = styled.div`
  border-top: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  backdrop-filter: blur(56px);
  padding: ${SPACING[4]};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`

const StyledFormActions = styled.div`
  border-top: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  backdrop-filter: blur(56px);
  padding: ${SPACING[4]};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  button:not(:first-child) {
    margin-left: ${SPACING[2]};
  }
`

const StyledForm = styled.div`
  overflow: scroll;
  height: 100%;
  max-height: 100%;
  padding: ${SPACING[1]} ${SPACING[4]};
  padding-bottom: 75px;
`

const StyledPreviewPane = styled.div`
  flex: 1;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  position: relative;
`

const StyledPreviewInteractionBlocker = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`

export type PopUpDesignerProps = {
  embed: Embed
  isCreate?: boolean
  isSaving?: boolean
  previewViewMode: string
  onChange(embed: Embed)
  onSave(embed: Embed)
  togglePreviewMode()
}

type TabValue = typeof TAB_IDS[keyof typeof TAB_IDS]

const toIsSaveValid = (embed: Embed, error: MessageBillingUsage['error']): boolean => {
  // if there is no headline, you cant save
  if (!embed.jsonConfig.headline) return false

  // if additional confirmation is selected but empty, you cant save
  if (embed.subscriptionConfirmationMessage !== null && embed.subscriptionConfirmationMessage === '') return false

  // if additional confirmation has too many characters / segments, you cant save
  if (error) return false

  // if links are empty, you cant save
  if (!embed.jsonConfig.pathRules.every((r) => Boolean(r.path.length))) return false

  return true
}

export const PopUpDesigner = ({
  embed,
  isCreate,
  isSaving,
  previewViewMode,
  onChange,
  onSave,
  togglePreviewMode,
}: PopUpDesignerProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const [activeTab, setActiveTab] = useState<TabValue>(TAB_IDS.APPEARANCE)
  const [showUnpublishDialog, setShowUnpublishDialog] = useState(false)
  const [showInstructionsModal, setShowInstrucionsModal] = useState(false)
  const clientId = useClientId()
  const { mutate: trackEmbedCompleted } = useMutation(postTrackEmbed)

  const message = embed?.subscriptionConfirmationMessage ?? ''
  const { links } = useShortenableLinks(message)
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = useMessageBillingUsage({
    linksToShorten: links,
    message,
  })
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }

  const handleConfigChange = (jsonConfig: Partial<Embed['jsonConfig']>) => {
    const updatedEmbed = {
      ...embed,
      jsonConfig: { ...embed.jsonConfig, ...jsonConfig },
    }
    onChange(updatedEmbed)
  }

  const handlePublish = () => {
    onSave({ ...embed, status: EmbedStatus.published })
  }

  const handleUnpublish = () => {
    onSave({ ...embed, status: EmbedStatus.draft })
    setShowUnpublishDialog(false)
  }

  const handleSave = () => {
    onSave(embed)
  }

  const handleBehaviorSaveOnCreation = () => {
    onSave(embed)
    embed.id &&
      clientId &&
      trackEmbedCompleted({
        embedId: embed.id,
        clientId,
        event: events.MGUEmbedCompleted(),
      })
    setShowInstrucionsModal(true)
  }

  const isValid = toIsSaveValid(embed, error)

  const saveActions = (
    <SaveActions
      isSaving={Boolean(isSaving)}
      isValid={isValid}
      onPublish={handlePublish}
      onSave={handleSave}
      onUnpublish={() => setShowUnpublishDialog(true)}
      status={embed.status}
    />
  )

  return (
    <StyledContainer>
      <StyledFormPane>
        <IconTabs activeTab={activeTab} onTabSelect={(tab) => setActiveTab(tab as TabValue)}>
          <IconTabs.Tab
            icon={<AppearanceIcon color={COLORS?.BUTTON_PRIMARY} />}
            id={TAB_IDS.APPEARANCE}
            label="Appearance"
          >
            <StyledForm>
              <AppearanceForm embedConfig={embed.jsonConfig} onChange={handleConfigChange} />
              {isCreate ? (
                <StyledCreateFormActions>
                  <Button disabled={!isValid} onClick={() => setActiveTab(TAB_IDS.CONFIRMATION)}>
                    Continue
                  </Button>
                </StyledCreateFormActions>
              ) : (
                <StyledFormActions>{saveActions}</StyledFormActions>
              )}
            </StyledForm>
          </IconTabs.Tab>
          <IconTabs.Tab
            icon={<ConfirmationIcon color={COLORS?.BUTTON_PRIMARY} />}
            id={TAB_IDS.CONFIRMATION}
            label="Confirmation"
          >
            <StyledForm>
              <ConfirmationForm
                additionalMessage={embed.subscriptionConfirmationMessage}
                onChange={(v) =>
                  onChange({
                    ...embed,
                    subscriptionConfirmationMessage: v,
                  })
                }
                usageCounterProps={usageCounterProps}
              />
              {isCreate ? (
                <StyledCreateFormActions>
                  <Button disabled={!isValid} onClick={() => setActiveTab(TAB_IDS.BEHAVIOR)}>
                    Continue
                  </Button>
                </StyledCreateFormActions>
              ) : (
                <StyledFormActions>{saveActions}</StyledFormActions>
              )}
            </StyledForm>
          </IconTabs.Tab>
          <IconTabs.Tab icon={<BehaviorIcon color={COLORS?.BUTTON_PRIMARY} />} id={TAB_IDS.BEHAVIOR} label="Behavior">
            <StyledForm>
              <BehaviorForm
                embed={embed}
                isCreate={Boolean(isCreate)}
                onChange={handleConfigChange}
                togglePreviewMode={togglePreviewMode}
              />
              {isCreate ? (
                <StyledCreateFormActions>
                  <CreateActions
                    isSaving={Boolean(isSaving)}
                    isValid={isValid}
                    onBack={() => setActiveTab(TAB_IDS.CONFIRMATION)}
                    onSave={handleBehaviorSaveOnCreation}
                  />
                </StyledCreateFormActions>
              ) : (
                <StyledFormActions>{saveActions}</StyledFormActions>
              )}
            </StyledForm>
          </IconTabs.Tab>
        </IconTabs>
      </StyledFormPane>
      <StyledPreviewPane>
        <Preview config={embed.jsonConfig} mode={previewViewMode} />
        <StyledPreviewInteractionBlocker />
      </StyledPreviewPane>
      <InstructionModal embed={embed} isOpen={showInstructionsModal} onClose={() => setShowInstrucionsModal(false)} />
      {showUnpublishDialog && (
        <Dialog
          message="Unpublished Sign Up Forms won’t appear until Published again"
          onCancel={() => setShowUnpublishDialog(false)}
          title="Are you sure?"
        >
          <Dialog.Action onClick={() => setShowUnpublishDialog(false)}>Cancel</Dialog.Action>
          <Dialog.Action onClick={handleUnpublish} variant={DIALOG_VARIANTS.EMPHASIZED}>
            Unpublish
          </Dialog.Action>
        </Dialog>
      )}
    </StyledContainer>
  )
}
