import { humanizeBuiltInFilterShortened } from '@community_dev/filter-dsl/lib/humanize/humanizeBuiltInFilters'
import {
  BuiltInFields,
  FieldSources,
  FieldTypes,
  SUBSCRIPTION_DATA_FILTER_DATE_FORMAT,
  SelectorFilter,
  SelectorOperators,
  fieldLabelFor,
  MemberDataFilter,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ApiError } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { FilterOptionsWithCounts } from './types'
import { CountByQueryFnReturn, useCountByQueries } from './useCountByQuery'
import { useCountByQuery } from './useCountByQuery'

import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { FilterSelectionType } from 'contexts/FilterProvider/FilterProvider'
import dayjs from 'utils/dayjs'

const toBirthdayFilter = (ts: string): SelectorFilter => ({
  operator: SelectorOperators.EQUALS,
  operand: {
    field_key: BuiltInFields.BIRTHDAY,
    field_label: fieldLabelFor(BuiltInFields.BIRTHDAY),
    source: FieldSources.BUILT_IN,
    value: dayjs(ts).format(SUBSCRIPTION_DATA_FILTER_DATE_FORMAT),
    type: FieldTypes.DATE,
  },
})

type UseBirthdayCountArgs<SelectData = CountByQueryFnReturn> = {
  traceId?: string
  communicationChannel?: CommunicationChannel
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
  ts?: string
}

export const useBirthdayCount = <SelectData = CountByQueryFnReturn>({
  ts = dayjs().format(SUBSCRIPTION_DATA_FILTER_DATE_FORMAT),
  traceId,
  communicationChannel,
  options,
}: UseBirthdayCountArgs<SelectData> = {}): UseQueryResult<SelectData, ApiError> => {
  return useCountByQuery<SelectData>({
    filters: toBirthdayFilter(ts),
    traceId: traceId || 'birthday-count',
    communicationChannel,
    options,
  })
}

export const useCurrentFiltersWithBirthdayCount = (type: FilterSelectionType = 'includes'): FilterOptionsWithCounts => {
  const { previewNextFilter, communicationChannel } = useFilters()

  const nextFilter = previewNextFilter(
    toBirthdayFilter(dayjs().format(SUBSCRIPTION_DATA_FILTER_DATE_FORMAT)),
    type,
  ) as MemberDataFilter

  return {
    options: [
      {
        filter: nextFilter,
        label: humanizeBuiltInFilterShortened(nextFilter),
      },
    ],
    counts: useCountByQueries({
      filters: [nextFilter],
      communicationChannel,
      traceId: 'current-filters-with-birthday-count',
    }),
  }
}
