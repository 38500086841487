import { useDebouncedValue } from '@community_dev/hooks'
import { BORDER_RADIUS, SPACING, Tags } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { Filter } from './Filter'

import { MemoizedCommunicationChannelSelect } from 'components/ComposeMessage/components/CommunicationChannelSelect'
import { FILTER_STATE } from 'constants/filter-states'
import { SearchTabType } from 'constants/messages'
import { useCommunities } from 'hooks/useCommunities'

const StyledMessageSearchWrapper = styled.div`
  padding: 16px 0 18px 0;
`

const StyledInput = styled.input`
  flex-grow: 1;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  width: 100%;
  border-radius: ${BORDER_RADIUS[2]};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  padding: ${SPACING[1]} ${SPACING[1]} ${SPACING[1]} 32px;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  height: 33px;
  margin-bottom: 1px;
  &::placeholder {
    color: ${({ theme }) => theme?.COLORS?.INPUT_TEXT_PLACEHOLDER};
  }
`

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`

const StyledInputWrapper = styled.div`
  width: 100%;
`

const StyledMessageSearch = styled.div`
  display: flex;
  align-items: center;
`

const accessoryStyles = css`
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
`

const StyledSearchIcon = styled.div`
  ${accessoryStyles};
  left: 13px;
`

const StyledSearchSuffix = styled.div`
  ${accessoryStyles};
  right: 13px;
`

const StyledClear = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
`

const StyledTags = styled.div`
  padding-top: 12px;
`

const SEARCH_DELAY = 750

type MessageSearchProps = {
  defaultSearch?: string
  activeTab?: string
  filters: any[]
  onFilterChange: (...args: any[]) => any
  onSearchTermChange: (...args: any[]) => any
  isLoading?: boolean
  setSearchTab: (...args: any[]) => any
}

export const MessageSearch = ({
  defaultSearch = '',
  activeTab,
  filters,
  isLoading,
  onSearchTermChange,
  setSearchTab,
  onFilterChange,
}: MessageSearchProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const [search, setSearch] = useState(() => defaultSearch)
  const debouncedSearch = useDebouncedValue(search, SEARCH_DELAY, true)

  const performSearch = useCallback(
    (searchTerm) => {
      onSearchTermChange(searchTerm)
    },
    [onSearchTermChange, debouncedSearch, activeTab],
  )

  useEffect(() => {
    performSearch(debouncedSearch)
  }, [debouncedSearch, performSearch])

  const communicationChannel = useMemo(() => {
    return (
      (filters?.find((filter) => filter.field === 'search[communication_channel]')?.value as CommunicationChannel) ||
      CommunicationChannel.SMS
    )
  }, [filters])

  const { data: communitiesWithCommunicationChannel } = useCommunities({
    select: ({ all }) => {
      return all.filter((community) => community.communicationChannels?.includes(communicationChannel))
    },
  })
  const handleOnChange = (event) => {
    setSearch(event.target.value)
  }

  const clearSearch = () => {
    if (search === '') return
    setSearch('')
    setSearchTab(SearchTabType.LATEST_MESSAGE)
  }

  const onClearFilter = (filterId) => {
    const filterToRemove: any = filterTagList.find((filter: any) => filter.id === filterId)
    const newFilters = filters.filter((filter: any) => filter.key !== filterToRemove.key)
    onFilterChange(!newFilters.length ? FILTER_STATE.INITIAL : newFilters)
  }

  const filterTagList = filters
    ? filters
        .map((filter) => {
          return filter.field !== 'search[communication_channel]'
            ? {
                id: filter.key,
                title: filter.label,
                color: filter.color,
                icon: filter.icon && <FontAwesomeIcon icon={filter.icon} style={{ color: filter.color, width: 10 }} />,
                key: filter.key,
              }
            : undefined
        })
        .filter((e): e is Exclude<typeof e, undefined> => e !== undefined)
    : []

  return (
    <StyledMessageSearchWrapper>
      <StyledMessageSearch>
        <StyledWrapper>
          <StyledSearchIcon>
            <FontAwesomeIcon color={COLORS?.DIVIDERS} icon="search" style={{ width: 14 }} />
          </StyledSearchIcon>
          <StyledInputWrapper>
            <StyledInput
              aria-label="Search"
              className="MessageSearch-input"
              id="search"
              onChange={handleOnChange}
              placeholder="Search"
              spellCheck={false}
              type="text"
              value={search}
            />
          </StyledInputWrapper>
          <StyledSearchSuffix>
            {search &&
              (isLoading ? (
                <FontAwesomeIcon color={COLORS?.BORDERS} icon="spinner" spin />
              ) : (
                <StyledClear aria-label="Clear search" onClick={clearSearch}>
                  <FontAwesomeIcon color={COLORS?.BORDERS} icon="times-circle" />
                </StyledClear>
              ))}
          </StyledSearchSuffix>
        </StyledWrapper>
        {!search && (
          <>
            <Filter communities={communitiesWithCommunicationChannel} filters={filters} setFilter={onFilterChange} />
            <MemoizedCommunicationChannelSelect
              compact
              css={{
                marginBottom: '1px',
                marginLeft: SPACING[1],
              }}
              data-testid="full-text-search-communication-channel-select"
              inline
              onChange={(value) => {
                if (value !== filters?.find((filter) => filter.field === 'search[communication_channel]')?.value) {
                  onFilterChange([{ field: 'search[communication_channel]', key: value, value }])
                }
              }}
              value={
                (filters?.find((filter) => filter.field === 'search[communication_channel]')
                  ?.value as CommunicationChannel) || CommunicationChannel.SMS
              }
              width={60}
            />
          </>
        )}
      </StyledMessageSearch>
      {filterTagList.length > 0 && (
        <StyledTags data-testid="message-filters">
          <Tags onRemove={(id) => onClearFilter(id)} tags={filterTagList} />
        </StyledTags>
      )}
    </StyledMessageSearchWrapper>
  )
}
