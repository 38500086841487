import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { matchPath } from 'react-router'

// If you're thinking of using this, consider `getQueryParams`
// It is a lot more resilient.
export const pathParams = (pathname: string, opts: any): any => {
  const matched = matchPath(pathname, opts)
  if (!matched || !matched.params) return {}
  return matched.params
}

export function getQueryParams(search: string): any {
  const params = new URLSearchParams(search || window.location.search)
  return Object.fromEntries(params.entries())
}

export const matchesRoutes = (paths: string[], pathname = window.location.pathname): boolean =>
  paths.reduce(
    (matched: any, path) => matched || matchPath(pathname, { path: `${import.meta.env.VITE_PUBLIC_URL}${path}` }),
    false,
  )

export function route(path: string, params = {}, queryParams = {}, encode = false): string {
  // `encode = false` is not a good idea and a dangerous default — we should fix this
  // I'm adding a temporary flag for this, but will refactor this to find where
  // we need the `[]` and handle that correctly. — Deshi

  // note that we are _not_ sanitizing query params here due to issues with the
  // handling of nested params with queryString. (tags[all] and tags[not])
  // worth discussing with backend - is nested query params a pattern we want
  // to continue using, or could we get by with flattened query params
  // (i.e. tags[all] -> tags_all and tags[not] -> tags_not)
  //
  // if yes, we should address this tech debt by writing our own (unit tested!)
  // version of queryString.stringify that handles nested params appropriately
  // while sanitizing/escaping values by default.
  const query = isEmpty(queryParams) ? '' : `?${queryString.stringify(queryParams, { encode })}`
  return (
    Object.keys(params)
      .reduce((acc, token) => acc.replace(token.replace(/^:?/, ':'), params[token]), path)
      .replace(/\/+/g, '/')
      .replace(/\/$/, '') + query
  )
}
