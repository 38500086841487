import { CloseIcon, Layout, Modal, SPACING } from '@community_dev/pixels'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'

import { CreateCommunityForm } from './CreateCommunityForm'

import { Community } from 'api/community'

const StyledContent = styled.div`
  width: 600px;
  position: relative;

  h2 {
    margin-bottom: 0;
  }

  p {
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
    margin: 4px 0 ${SPACING[5]} 0;
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  }
`

const StyledClose = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  flex-direction: column;
  cursor: pointer;
  user-select: none;

  & > span {
    margin-top: 10px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    font-size: 12px;
  }
`

type CreateCommunityProps = {
  children: (props: { toggleOpen: () => void }) => ReactNode
  onCreate?: (community: Community) => void
}

export function CreateCommunity({ children, onCreate }: CreateCommunityProps): JSX.Element {
  const [open, setOpen] = useState(false)
  function toggleOpen() {
    setOpen((prev) => !prev)
  }

  return (
    <>
      <Modal
        borderRadius="0px"
        margin="0px"
        maxHeight="100vh"
        maxWidth="100vw"
        minHeight="100vh"
        onClose={toggleOpen}
        open={open}
      >
        <Layout
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          padding="100px 24px 24px"
          width="100%"
        >
          <StyledContent>
            <Layout display="flex" justifyContent="flex-end">
              <StyledClose onClick={toggleOpen}>
                <CloseIcon size={12} />
                <span>ESC</span>
              </StyledClose>
            </Layout>
            <CreateCommunityForm onCreate={onCreate} onSubmit={toggleOpen} />
          </StyledContent>
        </Layout>
      </Modal>
      {children({ toggleOpen })}
    </>
  )
}
