import { AccountSetupSummary } from './AccountSetupSummary'
import { ConfirmationMessage } from './ConfirmationMessage'
import { ContactPhoto } from './ContactPhoto'
import { Name } from './Name'
import { Voicemail } from './Voicemail'
import { WelcomeMessage } from './WelcomeMessage'

import { withSimpleLayout } from 'layouts/SimpleLayout/withSimpleLayout'

export const VoicemailScreen = withSimpleLayout(Voicemail, true)
export const AccountSetupSummaryScreen = withSimpleLayout(AccountSetupSummary, true)
export const NameScreen = withSimpleLayout(Name, true)
export const WelcomeScreen = withSimpleLayout(WelcomeMessage, true)
export const ConfirmationMessageScreen = withSimpleLayout(ConfirmationMessage, true)
export const ContactPhotoScreen = withSimpleLayout(ContactPhoto, true)
