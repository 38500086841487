import { BUTTON_VARIANTS, Button, Pill } from '@community_dev/pixels'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { InvoicesTableLoader } from './InvoicesTableLoader'

import { Invoice, Invoices } from 'api/invoices'
import {
  StyledCell,
  StyledHeaderCell,
  StyledHeaderRow,
  StyledRow,
  StyledTable,
} from 'components/SettingsTable/SettingsTable.style'
import { CAPABILITIES } from 'constants/capabilities'
import { useHasCapability } from 'hooks/useUserCapability'
import dayjs from 'utils/dayjs'

const StyledStatus = styled(Pill)<{ $overdue?: boolean; $status: Invoice['status'] }>`
  ${({ $status, $overdue }) => {
    if ($overdue) {
      return `background-color: #FFE7AD`
    }
    if ($status === 'open') {
      return `background-color: #CAEEFD`
    } else if ($status === 'paid') {
      return `background-color: #CBF6BC`
    }
  }}
`

const StyledWideCell = styled(StyledCell)`
  &:first-child {
    width: 50%;
  }
`

type InvoicesTableProps = {
  isLoading: boolean
  invoicesData?: Invoices['invoices']
}

function formatCurrency(cents) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100)
}

export const InvoicesTable = ({ isLoading, invoicesData = [] }: InvoicesTableProps): JSX.Element | null => {
  const { t } = useTranslation()
  const hasClientBillingWrite = useHasCapability(CAPABILITIES.CLIENT.BILLING.WRITE)
  const hasAdminBillingWrite = useHasCapability(CAPABILITIES.ADMIN.BILLING.WRITE)

  const hasBillingWrite = hasClientBillingWrite || hasAdminBillingWrite

  const getStatusText = useCallback(
    (invoice: Invoice) => {
      if (invoice.isPastDue) {
        return t('settings.billing.pastDue')
      }

      if (invoice.status === 'open') {
        return t('settings.billing.open')
      } else if (invoice.status === 'paid') {
        return t('settings.billing.paid')
      }

      return ''
    },
    [t],
  )

  if (isLoading) {
    return <InvoicesTableLoader />
  }

  return (
    <StyledTable data-testid="invoices-table">
      <thead>
        <StyledHeaderRow>
          <StyledHeaderCell>{t('settings.billing.dueDate')}</StyledHeaderCell>
          <StyledHeaderCell $align="right">Balance Due</StyledHeaderCell>
          <StyledHeaderCell $align="right">Status</StyledHeaderCell>
          {hasBillingWrite && <StyledHeaderCell $align="right"></StyledHeaderCell>}
        </StyledHeaderRow>
      </thead>
      <tbody>
        {Boolean(invoicesData.length > 0) &&
          invoicesData.map((invoice) => {
            const statusText = getStatusText(invoice)
            return (
              <StyledRow key={invoice.id}>
                <StyledWideCell>{dayjs(invoice.dueDate).format('LL')}</StyledWideCell>
                <StyledCell $align="right">{formatCurrency(invoice.amountDue)}</StyledCell>
                <StyledCell $align="right">
                  <StyledStatus $overdue={invoice.isPastDue} $status={invoice.status}>
                    {statusText}
                  </StyledStatus>
                </StyledCell>
                {hasBillingWrite && (
                  <StyledCell $align="right">
                    <Button
                      $status={invoice.status}
                      forwardedAs="a"
                      href={invoice.hostedInvoiceUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      variant={BUTTON_VARIANTS.OUTLINE}
                    >
                      {invoice.status === 'paid' ? t('settings.billing.view') : t('settings.billing.payNow')}
                    </Button>
                  </StyledCell>
                )}
              </StyledRow>
            )
          })}
      </tbody>
    </StyledTable>
  )
}
