import {
  BUTTON_VARIANTS,
  Button,
  CloseIcon,
  Image,
  MODAL_VARIANTS,
  LoadingIndicator,
  Modal,
} from '@community_dev/pixels'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import styled, { useTheme } from 'styled-components'

import { getSeatPreferences, patchSeatInboxDensity } from 'api/seat'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { useSeat } from 'contexts/bootstrap/useSeat'
import analytics from 'utils/analytics'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding: 40px;
  text-align: center;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledImage = styled(Image)`
  max-width: 336px;
  margin: 0 0 24px;
  width: 336px;
  height: 398px;
`
const StyledFieldset = styled.fieldset`
  margin: 0 0 24px;
  padding: 0;
  border: none;
`
const StyledRadioContent = styled.div`
  display: inline-block;
`
const StyledRadioLabel = styled.label`
  &:first-child {
    margin: 0 30px 0 0;
  }
`
const StyledInputRadio = styled.input`
  opacity: 0;
  &:checked {
    + ${StyledRadioLabel}::before {
      border: 1px solid ${({ theme }) => theme?.COLORS?.LINKS};
    }
    + ${StyledRadioLabel}::after {
      background: ${({ theme }) => theme?.COLORS?.LINKS};
    }
  }
  + ${StyledRadioLabel} {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 0 0 0 24px;
    &::before {
      content: '';
      position: absolute;
      left: -22px;
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
      background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
      top: 3px;
    }
    &::after {
      content: '';
      position: absolute;
      left: -20px;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      top: 5px;
    }
  }
`

type InboxDensityDisplayModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const InboxDensityDisplayModal = ({ isOpen, onClose }: InboxDensityDisplayModalProps): JSX.Element => {
  const { COLORS } = useTheme()
  const { data: seat } = useSeat()
  const queryClient = useQueryClient()
  const [value, setValue] = useState('multiline')
  const { data, isLoading } = useQuery(
    [QUERY_CACHE.PREFERENCES, { seatId: seat?.id }],
    () => getSeatPreferences({ seatId: seat!.id }),
    {
      enabled: !!seat?.id,
      staleTime: STALE_TIME.FOREVER,
    },
  )
  const { mutate: updateDensity, isLoading: processing } = useMutation(patchSeatInboxDensity, {
    onMutate: (newPreference) => {
      queryClient.cancelQueries([QUERY_CACHE.PREFERENCES])

      const previousPreferences = queryClient.getQueryData([QUERY_CACHE.PREFERENCES])

      queryClient.setQueryData([QUERY_CACHE.PREFERENCES], (old: any) => ({
        ...old,
        ...newPreference,
      }))

      return () => queryClient.setQueryData([QUERY_CACHE.PREFERENCES], previousPreferences)
    },
    onSuccess: (data) => {
      const density = data?.inboxDisplayDensity

      if (density) {
        analytics.track(analytics.events.InboxTruncationUpdated(density))
      }

      onClose()
      toast.success('Inbox Density Display saved.')
    },
    onError: (err, newPreference, rollback: any) => {
      rollback()
      toast.error('Failed to save Inbox Density Display. Please try again.')
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_CACHE.PREFERENCES])
    },
  })
  const isDensityMultiline = value === 'multiline'
  const isCompact = value === 'compact'

  useEffect(() => {
    if (!data) return
    setValue(data.inboxDisplayDensity)
  }, [data])

  const handleSubmit = () => {
    updateDensity({
      seatId: seat!.id,
      body: { inbox_display_density: value },
    })
  }

  function handleCancel() {
    onClose()
  }

  const handleChangeRadio = (evt) => {
    setValue(evt.target.value)
  }

  if (isLoading) return <LoadingIndicator />

  return (
    <Modal maxHeight={700} maxWidth={452} onClose={handleCancel} open={isOpen} variant={MODAL_VARIANTS.ROUNDED}>
      <Modal.Header>
        <Modal.Header.Center>
          <div>Choose a View</div>
        </Modal.Header.Center>
        <Modal.Header.Right onClose={handleCancel}>
          <CloseIcon color={COLORS.SUBTEXT} size={12} />
        </Modal.Header.Right>
      </Modal.Header>
      <Modal.Body>
        <StyledContainer>
          {isDensityMultiline && (
            <StyledImage
              alt="displays a larger preview of messages"
              src={`${import.meta.env.VITE_WEB_ASSETS_URL}/inbox-truncation-multi-line.png`}
            />
          )}
          {isCompact && (
            <StyledImage
              alt="displays a compact preview of messages"
              src={`${import.meta.env.VITE_WEB_ASSETS_URL}/inbox-truncation-collapsed.png`}
            />
          )}
          <StyledFieldset>
            <StyledRadioContent>
              <StyledInputRadio
                checked={isDensityMultiline}
                id="multiline"
                name="inbox-truncation"
                onChange={handleChangeRadio}
                type="radio"
                value="multiline"
              />
              <StyledRadioLabel htmlFor="multiline">Multi-line</StyledRadioLabel>
            </StyledRadioContent>
            <StyledRadioContent>
              <StyledInputRadio
                checked={isCompact}
                id="compact"
                name="inbox-truncation"
                onChange={handleChangeRadio}
                type="radio"
                value="compact"
              />
              <StyledRadioLabel htmlFor="compact">Collapsed</StyledRadioLabel>
            </StyledRadioContent>
          </StyledFieldset>
          <Button disabled={isLoading || processing} onClick={handleSubmit} variant={BUTTON_VARIANTS.ACTION}>
            {processing ? 'Saving' : 'Save'}
          </Button>
        </StyledContainer>
      </Modal.Body>
    </Modal>
  )
}
