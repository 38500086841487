import { LinkPreview } from '@community_dev/pixels'
import noop from 'lodash/noop'
import styled from 'styled-components'

import { useUrlMetadata } from './useUrlMetadata'

type StyledLinkPreviewProps = {
  $incoming?: boolean
}

const StyledLinkPreview = styled.div<StyledLinkPreviewProps>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${({ $incoming }) => ($incoming ? 'flex-start' : 'flex-end')};
  margin: 5px 0px;

  a[class^='StyledLinkPreview'],
  div[class*=' StyledLinkPreview'] {
    margin: 0;
  }
`

type RichLinkPreviewProps = {
  className?: string
  style?: React.CSSProperties
  incoming?: boolean
  linkData?: any
  useLoading?: boolean
  onImageLoad?: (...args: any[]) => any
  url: string
}

export const RichLinkPreview = ({
  className,
  style,
  incoming,
  useLoading = true,
  onImageLoad = noop,
  url = '',
}: RichLinkPreviewProps): JSX.Element => {
  const { data, isInitialLoading } = useUrlMetadata({
    url,
  })

  return (
    <StyledLinkPreview $incoming={incoming} className={className} data-testid="rich-link-preview" style={style}>
      <LinkPreview loading={useLoading && isInitialLoading} onImageLoad={onImageLoad} url={url} {...data} />
    </StyledLinkPreview>
  )
}
