import { linkify } from '@community_dev/pixels'

export type ParsedStringForRichLinks = {
  body: string
  endsWith: boolean
  startsWith: boolean
  url: string
}

function parseStringForRichLinks(str: string): ParsedStringForRichLinks {
  const links = linkify.match(str)

  const startsWith = Boolean(links && links[0].index === 0 && links[0].schema !== 'mailto:')
  const endsWith = Boolean(!startsWith && links && links[0].lastIndex === str.length && links[0].schema !== 'mailto:')
  const matchedText = (links && links[0].raw) || ''
  const matchedURL = (links && links[0].url) || ''
  let body = str

  if (startsWith) {
    body = body.replace(matchedText, '').trim()
  } else if (endsWith) {
    body = body.replace(matchedText, '').trim()
  }

  return {
    body,
    endsWith,
    startsWith,
    url: matchedURL,
  }
}

export default parseStringForRichLinks
