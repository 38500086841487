import { Api } from '@community_dev/types'
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useEffect } from 'react'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { getSeat } from 'api/seat'
import { QUERY_CACHE } from 'constants/query-cache'
import { AuthStates } from 'contexts/AuthProvider'
import { useAuth } from 'hooks/useAuth'
import Sentry from 'integrations/Sentry'
import { formatFullName } from 'utils/formatters'

export type Seat = CamelCasedPropertiesDeep<Api.V1.Seat>

export function useSeat(queryOptions?: UseQueryOptions): UseQueryResult<Seat> {
  const { token, state } = useAuth()
  const seat = useQuery({
    queryKey: [QUERY_CACHE.SEAT],
    queryFn: () => getSeat(),
    enabled: state !== AuthStates.UNAUTHENTICATED && !!token,
    ...queryOptions,
  } as UseQueryOptions<Seat>)

  useEffect(() => {
    if (seat?.data) {
      Sentry.setUser({
        email: seat.data.email,
        id: seat.data.id,
        username: formatFullName(seat.data),
        ip_address: '{{auto}}',
      })
    } else {
      Sentry.setUser(null)
    }
  }, [seat?.data])

  return seat
}
