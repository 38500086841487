import { useMemo } from 'react'

import { useDataColorGradient } from './useDataColorGradient'

export type ColorScale = string[]

type DataColorScale = {
  colors: ColorScale
  colorsReversed: ColorScale
}
export const useDataColorScale = (size = 6): DataColorScale => {
  const colorFn = useDataColorGradient(0, size)

  const colors = useMemo(() => {
    const colors = Array.from({ length: size }, (_, i) => colorFn(i))
    const colorsReversed = colors.slice().reverse()
    return { colors, colorsReversed }
  }, [colorFn, size])

  return colors
}
