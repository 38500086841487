export type Provider = React.JSXElementConstructor<React.PropsWithChildren>

export function composeProviders<P extends JSX.IntrinsicAttributes & { children?: React.ReactNode }>(
  WrappedComponent: React.ComponentType<P>,
  ...providers: Provider[]
): React.ComponentType<P> {
  const WithProviders = providers.reduceRight((AccumulatedComponents, CurrentProvider) => {
    return (props) => (
      <CurrentProvider>
        <AccumulatedComponents>{props.children}</AccumulatedComponents>
      </CurrentProvider>
    )
  })

  return (props: P) => {
    return (
      <WithProviders>
        <WrappedComponent {...props} />
      </WithProviders>
    )
  }
}
