import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { baseConfig } from 'utils/config'
import { route } from 'utils/router'

export function getMostActiveMembersFilters(): Promise<any> {
  return request(baseConfig.mostActiveMembersOptionsGetApi, {
    baseUrl: '',
  }).then(({ data }) => data)
}

export function getMostActiveMembersAvailability({
  clientId,
}: {
  clientId: string
}): Promise<{ isAvailable: boolean }> {
  return request<{ isAvailable: boolean }>(
    route(ENDPOINTS.SUBSCRIPTION_DATA.MOST_ACTIVE_MEMBERS_AVAILABILITY, { clientId }),
  ).catch(() => ({ isAvailable: false }))
}
