import { Api } from '@community_dev/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'

import { deleteRestrictedKeyword } from 'api/restrictedKeywords'
import { QUERY_CACHE } from 'constants/query-cache'

type DeleteRestrictedKeyword = {
  onSuccess?: (data: string, variables: string, context: unknown) => void
  onError?: (error: Api.RestrictedKeyword.DeleteKeywordError, variables: string, context: unknown) => void
}

export const useDeleteRestrictedKeyword = ({ onError, onSuccess }: DeleteRestrictedKeyword = {}): UseMutationResult<
  string,
  Api.RestrictedKeyword.DeleteKeywordError,
  string
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteRestrictedKeyword,
    onError: (error, variables, context) => {
      queryClient.invalidateQueries([QUERY_CACHE.SETTINGS.RESTRICTED_KEYWORDS])
      onError?.(error, variables, context)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([QUERY_CACHE.SETTINGS.RESTRICTED_KEYWORDS])
      onSuccess?.(data, variables, context)
    },
  })
}
