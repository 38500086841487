import { BUTTON_VARIANTS, Button, ChatBubble, InvisibleCharacters } from '@community_dev/pixels'
import React from 'react'

import { StyledAction, StyledMessagePreview, StyledTextButton } from 'screens/Onboarding/styled'

type ConfirmProps = {
  children: React.ReactNode
  message: string
  processing?: boolean
  onConfirm: (...args: any[]) => any
  onEdit: (...args: any[]) => any
  tcpaMessage?: string
}

export function Confirm({
  children,
  message,
  processing = false,
  onConfirm,
  onEdit,
  tcpaMessage,
}: ConfirmProps): JSX.Element {
  return (
    <>
      {children}
      <StyledMessagePreview>
        <ChatBubble>
          <InvisibleCharacters>{tcpaMessage ? `${message}${tcpaMessage}` : message}</InvisibleCharacters>
        </ChatBubble>
      </StyledMessagePreview>
      <StyledAction>
        <Button disabled={processing} onClick={onConfirm} variant={BUTTON_VARIANTS.ACTION}>
          {processing ? 'Saving' : 'Confirm'}
        </Button>
      </StyledAction>
      <StyledTextButton onClick={onEdit}>Edit</StyledTextButton>
    </>
  )
}
