import { MemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { UseCampaignResult } from './useCampaign'
import { useInvalidateCommunityCount } from './useCountByQuery/useCommunityCount'
import { userHasFlag } from './useLaunchDarkly'

import { assignByQuery } from 'api/assignByQuery'
import { COMMUNITY_SORT_BY_MEMBER_COUNT } from 'constants/launch-darkly-flags'
import { QUERY_CACHE } from 'constants/query-cache'
import { useToastMessage } from 'hooks/useToastMessage'

type AssignMembersToCommunity = ({
  community,
  communicationChannel,
  tagId,
  fanSubscriptionTags,
  filters,
  recipientCount,
}: {
  communicationChannel?: CommunicationChannel
  community: any
  fanSubscriptionTags?: UseCampaignResult['fanSubscriptionTags']
  filters: MemberDataFilter
  recipientCount: number
  tagId: string
}) => void

type UseCommunityProps = {
  assignMembersToCommunity: AssignMembersToCommunity
  isLoading: boolean
  status: string
}

export const useCommunity = ({ clientId }: { clientId: string }): UseCommunityProps => {
  const queryClient = useQueryClient()
  const invalidateCommunityMemberCount = useInvalidateCommunityCount()
  const { showToastMessage } = useToastMessage()
  const isSortByMemberCountEnabled = userHasFlag(COMMUNITY_SORT_BY_MEMBER_COUNT)
  const {
    isLoading,
    status,
    mutate: assignByQueryMutation,
  } = useMutation(assignByQuery, {
    onSuccess(_, { community, recipientCount }) {
      const message = recipientCount === 1 ? 'Member has' : 'Members have'
      showToastMessage({
        message: `${new Intl.NumberFormat().format(recipientCount)} ${message} been added${
          community?.title ? ` to ${community?.title}` : ''
        }`,
      })
      if (community?.id && community?.title) {
        if (isSortByMemberCountEnabled) {
          queryClient.invalidateQueries([QUERY_CACHE.TAGS])
        } else {
          invalidateCommunityMemberCount(community.id, community.title)
        }
      }
    },
  })

  const assignMembersToCommunity: AssignMembersToCommunity = useCallback(
    ({ community, tagId, filters, recipientCount, communicationChannel = CommunicationChannel.SMS }) => {
      assignByQueryMutation({
        community,
        recipientCount,
        clientId,
        tagId,
        communicationChannel,
        body: {
          filters: {
            subscription_data: filters,
          },
        },
      })
    },
    [assignByQueryMutation, clientId],
  )

  return {
    assignMembersToCommunity,
    status,
    isLoading,
  }
}
