import { Ellipsis, SPACING, Skeleton, Tooltip } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import copy from 'copy-to-clipboard'
import { hideVisually } from 'polished'
import { toast } from 'react-hot-toast'
import styled, { useTheme } from 'styled-components'

import SwitchAccountsDropdown from '../../SwitchAccountsDropdown'

import { DESKTOP_BREAK, SIDEBAR_EASING, SIDEBAR_TRANSITION_SPEED } from 'constants/theme'
import { useClient } from 'hooks/useClient'
import { useAllMemberCount } from 'hooks/useCountByQuery/useEveryoneCount'
import { formatLargeNumber } from 'utils/number'

const StyledClose = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;

  @media (min-width: ${DESKTOP_BREAK}) {
    display: none;
    visibility: hidden;
  }
`

const StyledCloseIcon = styled.div`
  padding: 2px 5px;
  font-size: 18px;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  transition: color 0.1s ease-in;

  &:hover {
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  }
`

type StyledAccountInfoProps = {
  $collapsed?: boolean
}

const StyledAccountInfo = styled.div<StyledAccountInfoProps>`
  width: 100%;
  height: ${({ $collapsed }) => ($collapsed ? '0px' : '95px')};
  opacity: ${({ $collapsed }) => ($collapsed ? '0' : '1')};
  transition: height ${SIDEBAR_TRANSITION_SPEED} ${SIDEBAR_EASING},
    opacity ${SIDEBAR_TRANSITION_SPEED} ${SIDEBAR_EASING};
  overflow: hidden;
  tab-index: ${({ $collapsed }) => ($collapsed ? '-1' : '0')};
`

const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme?.COLORS?.BORDERS};
  margin: ${SPACING[4]} 0;
`

const StyledContactInfo = styled.div`
  width: calc(100% - 32px);
  margin-top: ${SPACING[2]};
`

const StyledName = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
`

const StyledDetail = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  white-space: nowrap;

  input {
    ${hideVisually()}
  }
`

type StyledHeaderProps = {
  $collapsed?: boolean
}

const StyledHeader = styled.header<StyledHeaderProps>`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  transition: padding ${SIDEBAR_TRANSITION_SPEED} ${SIDEBAR_EASING};
  padding: ${({ $collapsed }) => ($collapsed ? `${SPACING[5]} 10px ${SPACING[4]}` : `${SPACING[5]} ${SPACING[4]}`)};
`

const StyledCopyPhone = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

// Substitute the last 4 digits of the phone number with X's
const toHiddenPhoneNumber = (phone: string) => {
  return phone.slice(0, -4) + 'XXXX'
}

type SidebarHeaderProps = {
  collapsed: boolean
  fullName: string
  phone: string
  onClose: (...args: any[]) => any
}

export function SidebarHeader({ collapsed, fullName, phone = '', onClose }: SidebarHeaderProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { data: client } = useClient()
  const { total: totalSubscribers = 0, isLoading: isInitialLoading } = useAllMemberCount()

  const hasCommunicationChannel = client?.communicationChannels?.length && client?.communicationChannels?.length > 0

  function copyToClipboard() {
    copy(phone)
    toast.success('Copied to clipboard')
  }

  return (
    <StyledHeader $collapsed={collapsed}>
      <StyledClose aria-label="Close menu" onClick={onClose}>
        <StyledCloseIcon>
          <FontAwesomeIcon color={COLORS?.TEXT} icon="times" />
        </StyledCloseIcon>
      </StyledClose>
      <SwitchAccountsDropdown />
      <StyledAccountInfo $collapsed={collapsed}>
        <StyledContactInfo>
          <StyledName>
            <Ellipsis>{fullName}</Ellipsis>
          </StyledName>
          <Skeleton height="14px" loading={isInitialLoading} margin="4px 0 0" width="120px">
            <StyledDetail>Members ({formatLargeNumber(totalSubscribers)})</StyledDetail>
          </Skeleton>
        </StyledContactInfo>
        <StyledDivider />
        {hasCommunicationChannel ? (
          <StyledDetail onClick={copyToClipboard}>
            <Tooltip content="Copy">
              <StyledCopyPhone aria-label="Copy phone number">{phone}</StyledCopyPhone>
            </Tooltip>
          </StyledDetail>
        ) : (
          <StyledDetail>{toHiddenPhoneNumber(phone)}</StyledDetail>
        )}
      </StyledAccountInfo>
    </StyledHeader>
  )
}
