import { generalConfig } from 'utils/config'

type Image = {
  url: string | string[]
  alt?: string | null
}

type Blocks = {
  icon: Image
  images: Image[]
  title: string
  text?: string
}

export type GrowYourCommunityData = {
  blocks: Blocks[]
  category: string
  title: string
  summary?: string
  heroImage: Image
  name: string
  number: string
  featured?: boolean
}

const announcementIcon = `${generalConfig.staticUrl}/product-education/grow-your-community/announcement-icon.png`
const tapToTextIcon = `${generalConfig.staticUrl}/product-education/grow-your-community/tap-to-text-icon.png`
const exclusiveAccessIcon = `${generalConfig.staticUrl}/product-education/grow-your-community/exclusive-access-icon.png`

export const growYourCommunityData: GrowYourCommunityData[] = [
  {
    name: 'Los Angeles Clippers',
    number: '+1 (213) 577-1833',
    title: 'Los Angeles Clippers',
    category: 'Sports',
    heroImage: {
      url: [
        `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/hero%403x.png 3x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/hero%402x.png 2x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/hero.png 1x`,
      ],
    },
    blocks: [
      {
        icon: {
          url: announcementIcon,
        },
        title: 'Announcement',
        text: 'The Clippers promoted their Community Number on Instagram using their custom URL.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/announcement%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/announcement%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/announcement.jpeg 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: tapToTextIcon,
        },
        title: 'Tap-to-text',
        text: 'Add your Community Number to your Instagram profile.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/tap-to-text%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/tap-to-text%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/tap-to-text.jpeg 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: exclusiveAccessIcon,
        },
        title: 'Exclusive Access',
        text: 'Offer exclusive tickets and merch to your Community Members. ',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/exclusive-access%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/exclusive-access%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/clippers/exclusive-access.jpeg 1x`,
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'X-Games',
    number: '+1 (772) 494-2637',
    title: 'X-Games',
    category: 'Sports',
    heroImage: {
      url: [
        `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/hero%403x.png 3x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/hero%402x.png 2x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/hero.png 1x`,
      ],
    },
    blocks: [
      {
        icon: {
          url: announcementIcon,
        },
        title: 'Announcement',
        text: 'X Games launched their new Community Number on an X post.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/announcement%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/announcement%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/announcement.jpeg 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: tapToTextIcon,
        },
        title: 'Tap-to-text',
        text: 'X Games added their custom URL to promote their new Community Number.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/tap-to-text%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/tap-to-text%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/tap-to-text.jpeg 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: exclusiveAccessIcon,
        },
        title: 'Exclusive Access',
        text: 'X Games promoted a sweepstakes on an Instagram Story using their custom URL.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/exclusive-access%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/exclusive-access%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/x-games/exclusive-access.jpeg 1x`,
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'New York Giants',
    number: '+1 (201) 636-1569',
    title: 'New York Giants',
    category: 'Sports',
    heroImage: {
      url: [
        `${generalConfig.staticUrl}/product-education/grow-your-community/ny-giants/hero%403x.png 3x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/ny-giants/hero%402x.png 2x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/ny-giants/hero.png 1x`,
      ],
    },
    blocks: [
      {
        icon: {
          url: announcementIcon,
        },
        title: 'Announcement',
        text: 'The NY Giants promoted their Community number through television and in-venue custom QR codes for easy text in abilities.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/ny-giants/announcement%403x.png 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/ny-giants/announcement%402x.png 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/ny-giants/announcement.png 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: exclusiveAccessIcon,
        },
        title: 'Exclusive Access',
        text: 'The NY Giants used Twitter to promote their Community number, highlighting the exclusive content, ticket offers, and other Member perks available by texting in',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/ny-giants/tap-to-text%403x.png 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/ny-giants/tap-to-text%402x.png 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/ny-giants/tap-to-text.png 1x`,
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Sony Pictures - Insidious: The Red Door',
    number: '+1 (310) 634-1918',
    title: 'Sony Pictures - Insidious: The Red Door',
    category: 'Entertainment',
    heroImage: {
      url: [
        `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/hero%403x.png 3x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/hero%402x.png 2x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/hero.png 1x`,
      ],
    },
    blocks: [
      {
        icon: {
          url: announcementIcon,
        },
        title: 'Announcement',
        text: 'Insidious promoted their Community Number on X to get new Members to sign up.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/announcement%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/announcement%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/announcement.jpeg 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: tapToTextIcon,
        },
        title: 'Tap-to-text',
        text: 'Insidious added their custom URL to promote their new Community Number.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/tap-to-text%403x.png 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/tap-to-text%402x.png 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/tap-to-text.png 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: exclusiveAccessIcon,
        },
        title: 'Exclusive Access',
        text: 'Insidious encouraged movie fans to text in for a Q&A with the movie cast.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/exclusive-access%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/exclusive-access%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/insidious-movie/exclusive-access.jpeg 1x`,
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Los Angeles Dodgers',
    number: '+1 (323) 375-2583',
    title: 'Los Angeles Dodgers',
    category: 'Sports',
    heroImage: {
      url: [
        `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/hero%403x.png 3x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/hero%402x.png 2x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/hero.png 1x`,
      ],
    },
    blocks: [
      {
        icon: {
          url: announcementIcon,
        },
        title: 'Announcement',
        text: 'The Dodgers launched their number on social media with exclusive offers.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/announcement%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/announcement%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/announcement.jpeg 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: tapToTextIcon,
        },
        title: 'Tap-to-text',
        text: 'The Dodgers added their custom URL to promote their new Community Number.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/tap-to-text%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/tap-to-text%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/tap-to-text.jpeg 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: exclusiveAccessIcon,
        },
        title: 'Exclusive Access',
        text: 'The Dodgers offered exclusive content for Community Members on their Instagram using their custom URL.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/exclusive-access%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/exclusive-access%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/la-dodgers/exclusive-access.jpeg 1x`,
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Transformers',
    number: '+1 (718) 808-8200',
    title: 'Transformers',
    category: 'Entertainment',
    heroImage: {
      url: [
        `${generalConfig.staticUrl}/product-education/grow-your-community/transformers/hero%403x.png 3x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/transformers/hero%402x.png 2x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/transformers/hero.png 1x`,
      ],
    },
    blocks: [
      {
        icon: {
          url: announcementIcon,
        },
        title: 'Announcement',
        text: 'Posting on X, The Transformers movie announced their new Community Number to get new movie fans signed up.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/transformers/announcement%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/transformers/announcement%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/transformers/announcement.jpeg 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: tapToTextIcon,
        },
        title: 'Tap-to-text',
        text: 'Transformers added their custom URL to promote their new Community Number.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/transformers/tap-to-text%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/transformers/tap-to-text%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/transformers/tap-to-text.jpeg 1x`,
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'John Wick',
    number: '+1 (310) 564-8005',
    title: 'John Wick',
    category: 'Entertainment',
    heroImage: {
      url: [
        `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/hero%403x.png 3x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/hero%402x.png 2x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/hero.png 1x`,
      ],
    },
    blocks: [
      {
        icon: {
          url: announcementIcon,
        },
        title: 'Announcement',
        text: 'John Wick promoted their number with Keanue Reeves encouraging fans to text in.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/announcement%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/announcement%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/announcement.jpeg 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: tapToTextIcon,
        },
        title: 'Tap-to-text',
        text: 'John Wick added their custom URL to promote their new Community Number.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/tap-to-text%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/tap-to-text%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/tap-to-text.jpeg 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: exclusiveAccessIcon,
        },
        title: 'Exclusive Access',
        text: 'John Wick offered exclusive early screenings to fans who texted in with a special keyword.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/exclusive-access%403x.jpeg 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/exclusive-access%402x.jpeg 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/john-wick/exclusive-access.jpeg 1x`,
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Spider-Man - Across the Spider-Verse',
    number: '+1 (718) 808-8342',
    title: 'Spider-Man - Across the Spider-Verse',
    category: 'Entertainment',
    heroImage: {
      url: [
        `${generalConfig.staticUrl}/product-education/grow-your-community/spiderman/hero%403x.png 3x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/spiderman/hero%402x.png 2x`,
        `${generalConfig.staticUrl}/product-education/grow-your-community/spiderman/hero.png 1x`,
      ],
    },
    blocks: [
      {
        icon: {
          url: announcementIcon,
        },
        title: 'Announcement',
        text: 'The new Spider-Man movie, Across the Spider-Verse, promoted their Community number, featuring the official soundtrack artist, on billboards throughout Coachella valley as well as social re-posts for further exposure.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/spiderman/announcement%403x.png 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/spiderman/announcement%402x.png 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/spiderman/announcement.png 1x`,
            ],
          },
        ],
      },
      {
        icon: {
          url: exclusiveAccessIcon,
        },
        title: 'Exclusive Access',
        text: 'Spider-Man, Across the Spider-Verse, utilized the exposure in their trailer drop by including their Community number prompting people to join the Spider Society for exclusive access.',
        images: [
          {
            url: [
              `${generalConfig.staticUrl}/product-education/grow-your-community/spiderman/tap-to-text%403x.png 3x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/spiderman/tap-to-text%402x.png 2x`,
              `${generalConfig.staticUrl}/product-education/grow-your-community/spiderman/tap-to-text.png 1x`,
            ],
          },
        ],
      },
    ],
  },
]
