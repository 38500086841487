import React from 'react'

import { IconProps } from './IconProps'

export const CloseIconFilled = ({ size = 25, color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 25" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M12 20.5C16.4183 20.5 20 16.9183 20 12.5C20 8.08172 16.4183 4.5 12 4.5C7.58172 4.5 4 8.08172 4 12.5C4 16.9183 7.58172 20.5 12 20.5ZM8.71486 10.2523C8.42838 9.9658 8.42838 9.50133 8.71486 9.21486C9.00133 8.92838 9.4658 8.92838 9.75227 9.21486L12 11.4626L14.2477 9.21486C14.5342 8.92838 14.9987 8.92838 15.2851 9.21486C15.5716 9.50133 15.5716 9.9658 15.2851 10.2523L13.0374 12.5L15.2851 14.7477C15.5716 15.0342 15.5716 15.4987 15.2851 15.7851C14.9987 16.0716 14.5342 16.0716 14.2477 15.7851L12 13.5374L9.75227 15.7851C9.4658 16.0716 9.00133 16.0716 8.71486 15.7851C8.42838 15.4987 8.42838 15.0342 8.71486 14.7477L10.9626 12.5L8.71486 10.2523Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}
