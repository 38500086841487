import { HTTP } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'

import { Community } from './community'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

type AssignByQueryProps = {
  body: any
  clientId: string
  tagId: string
  community?: Community
  recipientCount: number
  communicationChannel: CommunicationChannel
}

export function assignByQuery({ body, clientId, tagId, communicationChannel }: AssignByQueryProps): Promise<any> {
  return request(
    route(ENDPOINTS.ASSIGN_BY_QUERY, { clientId, tagId }, { communication_channel: communicationChannel }),
    {
      body,
      method: HTTP.POST,
    },
  ).then(({ data }) => data)
}
