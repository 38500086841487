import { useState, useEffect } from 'react'

export function mediaLoaded(container: HTMLElement): Promise<void> {
  return new Promise((resolve, reject) => {
    const images = container.getElementsByTagName('img')
    const videos = container.getElementsByTagName('video')
    const totalElements = images.length + videos.length
    let loadedCount = 0

    const checkAllLoaded = () => {
      loadedCount += 1
      if (loadedCount === totalElements) {
        resolve()
      }
    }

    if (totalElements === 0) {
      resolve()
    }

    for (const img of Array.from(images)) {
      if (img.complete) {
        checkAllLoaded()
      } else {
        img.addEventListener('load', checkAllLoaded)
        img.addEventListener('error', () => {
          reject(new Error('One or more images failed to load'))
        })
      }
    }

    for (const vid of Array.from(videos)) {
      if (vid.readyState >= 2) {
        // 2 = HAVE_CURRENT_DATA
        checkAllLoaded()
      } else {
        vid.addEventListener('loadeddata', checkAllLoaded)
        vid.addEventListener('error', () => {
          reject(new Error('One or more videos failed to load'))
        })
      }
    }
  })
}

export function useMediaLoaded(containerRef: React.MutableRefObject<HTMLDivElement | null>, deps: any[] = []): boolean {
  const [allImagesLoaded, setAllImagesLoaded] = useState(false)

  useEffect(() => {
    setAllImagesLoaded(false)
  }, deps)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    mediaLoaded(container)
      .then(() => setAllImagesLoaded(true))
      .catch((err) => {
        console.error(err)
        setAllImagesLoaded(false)
      })
  }, [containerRef, allImagesLoaded])

  return allImagesLoaded
}
