import { Tabs } from '@community_dev/pixels'
import { useEffect, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useTheme } from 'styled-components'

import { StyledInboxList } from './InboxList.style'
import { MemberDetailActionPanelRouter } from './MemberDetailActionPanel/MemberDetailActionPanelRouter'
import { SearchTabs } from './SearchTabs'

import { SearchTabType } from 'constants/messages'
import { useInbox } from 'contexts/InboxProvider'
import useBreakpoints from 'hooks/useBreakpoints'
import { useCommunities } from 'hooks/useCommunities'
import { useFan } from 'hooks/useFan'
import { useUpdateChat } from 'hooks/useUpdateChat'

type InboxListProps = {
  loading: boolean
  moreLoading: boolean
  isSearching: boolean
}

export default function InboxList(props: InboxListProps): JSX.Element {
  const { loading, moreLoading, isSearching } = props

  const { COLORS } = useTheme() || {}

  const history = useHistory()
  const { pathname } = useLocation()

  const { fanId } = useParams<{ fanId?: string }>()
  const { data: fan } = useFan(fanId)

  const { data } = useCommunities()
  const { custom: communities } = data || {}

  const { items = [], hasNextPage, searchTab, setSearchTab, fetchNextPage } = useInbox()

  const selectedItem = useRef()
  const scroller = useRef<HTMLDivElement | null>(null)

  const hideMobile = !RegExp('^/messages/inbox(/)?$').test(pathname)
  const labelActiveMap = {
    'Latest Message': SearchTabType.LATEST_MESSAGE,
    'Contact Name': SearchTabType.CONTACT_NAME,
  }

  const { mutateAsync: updateChat } = useUpdateChat()
  const { md } = useBreakpoints()

  useEffect(() => {
    if (items.length > 0 && md) {
      const fanItem = items.find((item) => item.fan.id === fanId)

      if (fanItem) {
        autoSelectItem(fanItem)
      } else {
        autoSelectItem(items[0])
      }
    }
  }, [items, fanId])

  const loadMore = () => {
    const { loading, moreLoading } = props
    if (!loading && !moreLoading && hasNextPage) {
      fetchNextPage()
    }
  }

  const autoSelectItem = (item) => {
    const { fan } = item

    history.push(`/messages/inbox/${fan.id}`)
  }

  const onItemClick = (fan, unread) => {
    if (fan?.id) {
      history.push(`/messages/inbox/${fan.id}`)

      if (unread) {
        updateChat({ fanSubscriptionId: fan.fanSubscriptionId, fanId: fan.id, chat: { has_new: false } })
      }
    }
  }

  return (
    <StyledInboxList $hideMobile={hideMobile}>
      <MemberDetailActionPanelRouter communities={communities} fan={fan} />
      <Tabs active={searchTab} color={COLORS?.APP_BACKGROUND_LEVEL_2} onTabSelect={setSearchTab}>
        {Object.keys(labelActiveMap).map((label) => {
          if (!isSearching && labelActiveMap[label] !== searchTab && !!items) return null
          return (
            <SearchTabs
              fanId={fan?.id}
              hasNextPage={hasNextPage}
              items={items}
              key={label}
              label={label}
              loadMore={loadMore}
              loading={loading}
              moreLoading={moreLoading}
              name={labelActiveMap[label]}
              onItemClick={onItemClick}
              ref={selectedItem}
              scroller={scroller}
              showLoading={loading}
            />
          )
        })}
      </Tabs>
    </StyledInboxList>
  )
}
