import { Avatar, SPACING } from '@community_dev/pixels'
import noop from 'lodash/noop'
import React from 'react'
import styled, { css } from 'styled-components'

import { Fan } from 'api/fans'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { MemberAvatar } from 'components/MemberAvatar'

type StyledRecommendationItemProps = {
  $disabled?: boolean
  $active?: boolean
  $removable?: boolean
}

const StyledRecommendationItem = styled.div<StyledRecommendationItemProps>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: background 0.3s ease-in-out;
  padding: ${SPACING[2]} ${SPACING[4]};
  overflow: visible;
  cursor: pointer;

  ${Avatar} {
    margin-right: ${SPACING[2]};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.4;
      cursor: default;
    `}

  &:after {
    content: '';
    height: 1px;
    bottom: 0;
    left: ${SPACING[4]};
    right: ${SPACING[4]};
    position: absolute;
    background: ${({ theme }) => theme?.COLORS?.BORDERS};
  }

  &:hover {
    background: ${({ theme }) => theme?.COLORS?.DEPRECATED_HOVER};
  }

  ${({ $active }) =>
    $active &&
    css`
      background: ${({ theme }) => theme?.COLORS?.DEPRECATED_SELECTED};
    `}

  ${({ $removable }) =>
    $removable &&
    css`
      cursor: auto;
      &:hover {
        background: initial;
      }
    `}
`

const StyledSecondaryCounter = styled.div`
  display: inline-block;
  position: relative;

  @keyframes fade-slide-right {
    0% {
      opacity: 0;
      left: -10px;
    }
    100% {
      opacity: 1;
      left: 0px;
    }
  }

  &.fade-slide-right {
    animation-name: fade-slide-right;
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
  }
`

const StyledSecondary = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledContent = styled.div`
  display: flex;
  align-items: center;
`

const StyledPrimary = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
`

const StyledRemove = styled.div`
  background: none;
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: 12px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 100%;
  padding: 6px 14px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme?.COLORS?.ERRORS};
    border-color: ${({ theme }) => theme?.COLORS?.ERRORS};
    color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }
`

type RecommendationRowProps = {
  active?: boolean
  counter?: string | null
  disabled?: boolean
  initials?: string
  fan?: Fan
  onClear?: (...args: any[]) => any
  onClick?: (...args: any[]) => any
  primary?: React.ReactNode
  removable?: boolean
  secondary?: string
}

class RecommendationRow extends React.PureComponent<RecommendationRowProps> {
  static defaultProps = {
    active: false,
    counter: '',
    disabled: false,
    onClear: noop,
    onClick: noop,
    primary: '',
    removable: false,
    secondary: '',
  }

  state = {
    loadingClass: '',
  }

  componentDidUpdate(prevProps: RecommendationRowProps): void {
    if (this.props.counter !== prevProps.counter) {
      this.setState({ loadingClass: 'fade-slide-right' })
    }
  }

  render(): JSX.Element {
    const { fan, active, primary, removable, initials, secondary, disabled, counter } = this.props

    return (
      <StyledRecommendationItem
        $active={active}
        $disabled={disabled}
        $removable={removable}
        data-testid="recommendation-item"
        onClick={!disabled ? this.props.onClick : undefined}
      >
        <StyledContent>
          {fan ? (
            <MemberAvatar
              badge={
                <CommunicationChannelIcon
                  communicationChannel={fan.communicationChannel}
                  filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))"
                  size={19}
                  style={{ marginTop: SPACING[2] }}
                />
              }
              fan={fan}
              graphicUrl={fan.graphicUrl}
            />
          ) : (
            initials && <Avatar name={initials} />
          )}
          <div>
            <StyledPrimary>{primary}</StyledPrimary>
            {secondary && (
              <StyledSecondary>
                {secondary}{' '}
                <StyledSecondaryCounter className={this.state.loadingClass}>{counter}</StyledSecondaryCounter>
              </StyledSecondary>
            )}
          </div>
        </StyledContent>
        {removable && <StyledRemove onClick={this.props.onClear}>Remove</StyledRemove>}
      </StyledRecommendationItem>
    )
  }
}

export default RecommendationRow
