import { Layout, SPACING, Typography } from '@community_dev/pixels'
import { useMemo } from 'react'
import styled from 'styled-components'

import imagePlaceholder from './image-placeholder.svg'

import { parseUrl } from 'utils/link'

const StyledMiniLinkPreview = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  padding: ${SPACING[1]};
  img {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 10px;
  }
`

type MiniLinkPreviewProps = {
  url?: string
  title?: string
}

export const MiniLinkPreview = (props: MiniLinkPreviewProps): JSX.Element | null => {
  const { url } = props

  const parsedUrl = useMemo(() => parseUrl(url || ''), [url])

  if (parsedUrl) {
    return (
      <StyledMiniLinkPreview>
        <Layout maxWidth="100%" overflow="hidden">
          {props.title && (
            <Typography display="block" fontSize={'12px'} fontWeight={600} lineHeight="initial">
              {props.title}
            </Typography>
          )}
          <Typography
            data-testid="mini-link-preview-host"
            display="block"
            fontSize="12px"
            lineHeight="initial"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            width="100%"
          >
            {parsedUrl.host}
          </Typography>
        </Layout>
        <img alt="" height={props.title ? 'auto' : '26'} src={imagePlaceholder} width={props.title ? 'auto' : '26'} />
      </StyledMiniLinkPreview>
    )
  }

  return null
}
