import { ReplyIcon, SPACING, Tooltip } from '@community_dev/pixels'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Measure from 'react-measure'
import { useParams, useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { NAME } from './constants'

import { Fan } from 'api/fans'
import { Bubble } from 'components/Bubble'
import { IncludeExcludeCheckbox } from 'components/IncludeExcludeCheckbox'
import { FullTime } from 'components/time'
import { ROUTES } from 'constants/routes'
import { DESKTOP_BREAK, TABLET_BREAK } from 'constants/theme'
import { useBounds } from 'contexts/BoundsProvider'
import { useCampaignResponseFilterState } from 'contexts/CampaignResponseFilterProvider'
import { CampaignInsight } from 'utils/normalize'

const StyledName = styled.div`
  padding: 16px 0 4px 54px;
  width: 100%;
  max-width: 85%;
  display: flex;
  justify-content: flex-start;

  @media (min-width: ${DESKTOP_BREAK}) {
    max-width: 77%;
  }
`

const StyledMain = styled.label`
  position: relative;
  width: 100%;
  max-width: 88%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media (min-width: ${DESKTOP_BREAK}) {
    max-width: 80%;
  }

  input[type='checkbox'] {
    margin: 0 ${SPACING[2]} 0 ${SPACING[4]};
    cursor: pointer;
  }

  .Bubble-root .Bubble-main {
    align-items: flex-start;
    padding: 1px 10px 1px 4px;

    .Bubble-media {
      align-items: flex-start;
    }

    .Bubble-error {
      align-items: flex-start;
      svg {
        right: 0;
        left: 275px;
      }
    }
  }
`

type StyledRootProps = {
  $outgoing?: boolean
}

const StyledRoot = styled.div<StyledRootProps>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  &:last-child {
    padding-bottom: 6px;
  }

  ${({ $outgoing }) =>
    $outgoing &&
    css`
      ${StyledName} {
        align-self: flex-end;
        justify-content: flex-end;
        padding: 12px 60px 4px 86px;

        @media (min-width: ${TABLET_BREAK}) {
          padding: 6px 60px 4px 86px;
        }
      }

      ${StyledMain} {
        align-self: flex-end;
        flex-direction: row-reverse;
        .Bubble-main {
          align-items: flex-end !important;
        }
      }

      .MediaPreview-root {
        justify-content: flex-end;
      }

      .Media {
        margin-right: 16px;
      }
    `}
`

const StyledCheckboxSpace = styled.div`
  width: ${SPACING[8]};
`

const StyledIncludeExcludeCheckbox = styled(IncludeExcludeCheckbox)`
  grid-column-start: 1;
  grid-row-start: 3;
  place-self: center;
  margin: 0 16px 0 0;
`

const StyledTime = styled.div`
  width: 100%;
  text-align: center;
  padding: 16px 0 0 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION2};
  font-weight: 600;
  margin-bottom: 8px;
`
const StyledFullTime = styled(FullTime)`
  padding: 0;
`

type StyledTitleProps = {
  $isCluster?: boolean
  $incoming?: boolean
}

const StyledTitle = styled.button<StyledTitleProps>`
  align-items: center;
  margin: 0 12px ${({ $isCluster }) => ($isCluster ? '10px' : '3px')} 22px;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  border: none;
  padding: 0;
  background: none;

  &:hover {
    ${({ $isCluster, $incoming }) =>
      !$isCluster &&
      $incoming &&
      css`
        color: ${({ theme }) => theme?.COLORS?.LINKS};
      `}
  }

  ${({ $incoming }) =>
    !$incoming &&
    css`
      text-align: right;
    `}
`

type StyledLabelProps = {
  $incoming?: boolean
}

const StyledLabel = styled.label<StyledLabelProps>`
  display: flex;
  width: 100%;
  ${({ $incoming }) =>
    !$incoming &&
    css`
      margin: 0 0 0 auto;
    `}
`

type StyledBubbleContainerProps = {
  $repliedTo?: boolean
  $repliedToSome?: boolean
}

const StyledBubbleContainer = styled.div<StyledBubbleContainerProps>`
  width: 100%;

  ${({ $repliedTo, $repliedToSome }) =>
    $repliedTo &&
    css`
      opacity: ${$repliedToSome ? '1' : '0.8'};
    `}
`

const StyledRepliedToText = styled.span`
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  opacity: 0.8;
`

type ChainBubbleProps = {
  allowSelection?: boolean
  body?: string
  bubbleProps?: any
  clusterId?: string
  fan?: any
  fanSubscriptionId?: string
  incoming?: boolean
  isCluster?: boolean
  onTitleClick?: (fan: Fan) => void
  openConvoModal?: (...args: any[]) => any
  preTitle?: React.ReactNode
  repliedTo?: boolean
  repliedToSome?: boolean
  selectItem?: (...args: any[]) => any
  showTime?: boolean
  showUser?: boolean
  tapbacks?: CampaignInsight[]
  title?: string
  ts?: string
  viewDetailsPane?: (...args: any[]) => any
  disabledSelectionReason?: string
}

export function ChainBubble({
  allowSelection = true,
  disabledSelectionReason,
  body,
  bubbleProps = {},
  clusterId: clusterIdFromIndex,
  fan,
  fanSubscriptionId,
  incoming,
  isCluster,
  onTitleClick,
  openConvoModal,
  preTitle = null,
  repliedTo = false,
  repliedToSome,
  showTime,
  showUser,
  tapbacks,
  title,
  ts,
  viewDetailsPane,
}: ChainBubbleProps): JSX.Element {
  const { t } = useTranslation()
  const disabledReason = disabledSelectionReason || t('clusterSelectionDisabled')
  const isMessagesClusterRoute = useRouteMatch(ROUTES.CAMPAIGNS.CLUSTER)
  const params: any = useParams()
  const { setBounds } = useBounds()
  const clusterId = isMessagesClusterRoute ? params.clusterId : clusterIdFromIndex

  const handleClickBody = () => {
    if (!openConvoModal) return
    openConvoModal(fan)
  }

  const handleUserNameClick = () => {
    if (onTitleClick) onTitleClick(fan)
  }

  const fanId = fan?.id
  const name =
    (fanSubscriptionId && `${NAME.FAN_SUBSCRIPTION_ID}${fanSubscriptionId}`) ||
    (fanId && `${NAME.FAN_ID}${fanId}`) ||
    (clusterId && `${NAME.CLUSTER_ID}${clusterId}`) ||
    'you'

  // because of the way that the `filters` syntax works with tag-based filtering,
  // is is not possible to add an inclusion rule for an item that is being
  // filtered out by a tags[not] rule. Therefore, we need to:
  //
  // (1) Hide the checkboxes for anything that is being filtered out by a tags[not]
  //     rule.
  // (2) Ensure that the IncludeExcludeState is reset when the user applies a
  //     tag-based filter to the screen.
  const { notTags, allTags } = useCampaignResponseFilterState()
  // at the moment, we only support filtering by replied/unreplied, so we can
  // use the repliedTo prop as a proxy for the node tags.
  const excludedByGlobalFilters = (repliedTo && notTags.has('replied-to')) || (!repliedTo && allTags.has('replied-to'))

  const showChildCheckbox = incoming && !excludedByGlobalFilters

  const keyPath = [clusterId, fanSubscriptionId].filter((v) => v)

  const itemType = fanSubscriptionId ? 'fanSubscription' : 'cluster'

  return (
    <Measure onResize={(bounds) => setBounds(bounds.entry)}>
      {({ measureRef }) => (
        <StyledRoot $outgoing={!incoming} ref={measureRef}>
          {showTime && (
            <StyledTime>
              <StyledFullTime ts={ts} />
            </StyledTime>
          )}

          <StyledMain htmlFor={`checkbox-${name}`}>
            <StyledCheckboxSpace>
              {showChildCheckbox && (
                <Tooltip content={disabledReason} disabled={allowSelection} maxWidth={345}>
                  <div>
                    <StyledIncludeExcludeCheckbox
                      aria-labelledby={`checkbox-${name}-label`}
                      disabled={!allowSelection}
                      itemType={itemType}
                      key={`checkbox-${name}`}
                      keyPath={keyPath}
                      name={name}
                      repliedTo={repliedTo}
                      type="checkbox"
                    />
                  </div>
                </Tooltip>
              )}
            </StyledCheckboxSpace>
            <StyledLabel $incoming={incoming} id={`checkbox-${name}-label`}>
              <StyledBubbleContainer
                $repliedTo={repliedTo}
                $repliedToSome={repliedToSome}
                data-testid="chain-bubble-container"
              >
                <div>
                  {showUser && title && (
                    <StyledTitle
                      $incoming={incoming}
                      $isCluster={isCluster}
                      onClick={isCluster ? handleClickBody : handleUserNameClick}
                    >
                      {repliedTo ? (
                        <StyledRepliedToText>
                          {preTitle}
                          <ReplyIcon /> Replied to {title}
                        </StyledRepliedToText>
                      ) : (
                        title
                      )}
                    </StyledTitle>
                  )}
                  <Bubble
                    body={body}
                    incoming={incoming}
                    onBodyClick={openConvoModal ? handleClickBody : undefined}
                    ts={ts}
                    {...bubbleProps}
                    isCluster={isCluster}
                    redacted={incoming}
                    tapbacks={tapbacks}
                    viewDetailsPane={viewDetailsPane}
                  />
                </div>
              </StyledBubbleContainer>
            </StyledLabel>
          </StyledMain>
        </StyledRoot>
      )}
    </Measure>
  )
}

ChainBubble.propTypes = {
  body: PropTypes.string,
  isCluster: PropTypes.bool,
  bubbleProps: PropTypes.object,
  clusterId: PropTypes.string,
  fan: PropTypes.object,
  fanSubscriptionId: PropTypes.string,
  title: PropTypes.string,
  incoming: PropTypes.bool,
  initials: PropTypes.string,
  openConvoModal: PropTypes.func,
  repliedTo: PropTypes.bool,
  select: PropTypes.func,
  selectItem: PropTypes.func,
  showTime: PropTypes.bool,
  showUser: PropTypes.bool,
  tapbacks: PropTypes.array,
  ts: PropTypes.string,
  viewDetailsPane: PropTypes.func,
}
