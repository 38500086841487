import {
  Button,
  FONT_SIZE,
  FilterPills,
  SPACING,
  StyledFilterPill,
  StyledFilterPillsSubGroupWrapper,
} from '@community_dev/pixels'
import styled from 'styled-components'

import { PANE_WIDTH } from 'constants/theme'

export const StyledRoot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`

export const StyledLeftColumn = styled.div`
  width: ${PANE_WIDTH};
  flex-grow: 0;
  flex-shrink: 0;
  border-right: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  position: relative;
`

type StyledRightColumnProps = {
  $backgroundColor?: string
}

export const StyledRightColumn = styled.div<StyledRightColumnProps>`
  position: absolute;
  left: ${PANE_WIDTH};
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor ? $backgroundColor : theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`

export const StyledRightHeader = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`

export const StyledRightContent = styled.div`
  flex-grow: 1;
  position: relative;

  .InfiniteScroll-root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 34px ${SPACING[4]};
`

export const FiltersAppliedTitle = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0;
`

export const StyledFilterTitle = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100% - 160px);
`

export const StyledTitle = styled.h2`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 ${SPACING[2]};
`

export const StyledFilterApplied = styled.div`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  letter-spacing: 0.08px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
`

export const StyledNLP = styled.button`
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  background: none;
  border: none;
  padding: 0;
  &:active {
    color: inherit;
  }
`

export const StyledSentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;

  > *:not(:last-child) {
    margin-right: ${SPACING[3]};
  }
`

export const StyledStar = styled.div`
  font-size: 32px;
`

export const StyledEditPencil = styled.button`
  padding: 0;
  border: none;
  background: none;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const StyledSwitcherHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${SPACING[4]};

  .select-with-icon-control {
    border-radius: 20px;
    height: 34px;
    min-height: 34px;
    min-width: 275px;
  }
`

export const StyledSearch = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: ${SPACING[2]};

  ${Button} {
    flex-shrink: 0;
    margin-left: ${SPACING[2]};
  }
`

export const StyledFiltersContainer = styled.div`
  height: 40px;
  display: flex;
`

export const StyledFiltersControls = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 14px;
`

export const StyledFiltersSelect = styled.label`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  input {
    margin-right: 10px;
    font-size: 12px;
    cursor: pointer;
  }

  span {
    font-weight: 900;
    font-size: 14px;
    letter-spacing: 0.08px;
    cursor: pointer;
  }
`

export const StyledFiltersFilter = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  white-space: nowrap;
`

export const StyledFiltersFilterButton = styled.button`
  background: none;
  border: none;
  padding: 0 0 2px;
  cursor: pointer;
  font-weight: 900;
  font-size: ${FONT_SIZE[3]};
  line-height: 140%;
  letter-spacing: 0.08px;
  margin-left: ${SPACING[2]};
  color: ${({ theme }) => theme?.COLORS?.TEXT};

  svg {
    margin-left: 3px;
  }
`

export const StyledActionButton = styled.button`
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: 50%;
  user-select: none;

  &:hover {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  }
`

export const StyledCenteredContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 76px;

  svg {
    margin-bottom: 30px;
  }
`

export const StyledCenteredHeading = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
  letter-spacing: 0.08px;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

export const StyledCenteredCopy = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  text-align: center;
  user-select: none;
  margin-bottom: ${SPACING[2]};
`

export const StyledFilterPills = styled(FilterPills)`
  ${StyledFilterPill} {
    background: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_1};
  }

  ${StyledFilterPillsSubGroupWrapper} {
    background: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_3};
  }
`
