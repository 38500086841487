import { Layout, Skeleton } from '@community_dev/pixels'

export const InvoicesTableLoader = (): JSX.Element => {
  return (
    <Layout marginBottom="20px">
      <Skeleton height="60px" loading marginBottom="8px" width="100%" />
      <Skeleton height="60px" loading marginBottom="8px" width="100%" />
      <Skeleton height="60px" loading marginBottom="8px" width="100%" />
      <Skeleton height="60px" loading marginBottom="8px" width="100%" />
    </Layout>
  )
}
