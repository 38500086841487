import { VALID_URL_PATTERN } from '@community_dev/pixels'
import {
  isSendMessageAction,
  isNodeDataAction,
  manifestToTree,
  ManifestFunctions,
} from '@community_dev/workflow-manifest'
import { Manifest, SendMessageAction } from '@community_dev/workflow-manifest'
import { useQuery } from '@tanstack/react-query'
import forEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import transform from 'lodash/transform'
import { breadthFirstTraversal } from 'm-ary-tree'

import { Workflow, WorkflowMetics, getWorkflowAnalytics } from 'api/workflows'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'

export type WorkflowAnalyticsProcessed = {
  totalSent: string
  totalClicks: string
  ctr: string
  functions: {
    [fn: string]: {
      [step: number]: Partial<{
        totalsent: number
        linkhits: number
        ctr: number
      }>
    }
  }
}

type MessageWithIndex = {
  action: SendMessageAction
  function: string
  index: number
}

function makeEmptyAnalytics(): WorkflowAnalyticsProcessed {
  return {
    totalSent: '0',
    totalClicks: '--',
    ctr: '--',
    functions: {},
  }
}

// checks if a message contains a link
export function containsLink(action: SendMessageAction): boolean {
  // the message either directly contains a link in the text, or is set to have a checkout url prepended.
  return VALID_URL_PATTERN.test(action.params.text || '') || action.params.include_checkout_url === true
}

// get messages (send actions) with their original index, in the correct order
export function getMessageTextsWithIndex(manifest: Manifest): MessageWithIndex[] {
  const tree = manifestToTree(manifest)
  return Array.from(breadthFirstTraversal(tree))
    .map((node) => {
      if (isNodeDataAction(node.data) && isSendMessageAction(node.data.action)) {
        return {
          action: node.data.action,
          function: node.data.functionKey,
          index: node.data.functionIndex,
        }
      } else {
        return undefined
      }
    })
    .filter((m): m is MessageWithIndex => m !== undefined)
}

/** Formats CTR to a percentage string */
export function formatCtr(ctr?: number | null): string {
  if (ctr) {
    return `${Math.min(ctr * 100, 100).toFixed(0)}%`
  } else {
    return '--'
  }
}
/** Formats clicks to a string */
export function formatClicks(action?: SendMessageAction, clicks?: number): string {
  return action !== undefined && containsLink(action) ? clicks?.toLocaleString() || '--' : '--'
}

/** Formats sends to a string */
export function formatSent(sent?: number): string {
  return sent?.toLocaleString() || '0'
}

// transforms manifest functions object by mapping each action to a 'stepId' by combining its parent key and index
function transformFunctions(input: ManifestFunctions): Record<string, string> {
  return transform(
    input,
    (result, actions, key) => {
      forEach(actions, (item, index) => {
        if ('action' in item) {
          result[item.action] = `${String(key)}:${index}`
        }
      })
    },
    {},
  )
}

export function formatAnalytics(metrics: WorkflowMetics, manifest: Manifest): WorkflowAnalyticsProcessed {
  const functionsByActionId = transformFunctions(manifest.functions)
  const functionGroups = mapValues(
    groupBy(metrics.actions, (action) => functionsByActionId[action.actionId]?.split(':')[0]),
    (actions) => {
      return actions.reduce((acc, action) => {
        acc[functionsByActionId[action.actionId]?.split(':')[1]] = {
          totalsent: action.messagesSentTotal,
          linkhits: action.linkHitsTotal || undefined,
          ctr: action.clickThroughRate || undefined,
        }
        return acc
      }, {} as { [step: string]: Partial<{ totalsent: number; linkhits: number; ctr: number }> })
    },
  )
  return {
    totalSent: metrics.workflow.messagesSentTotal.toString(),
    totalClicks: metrics.workflow.linkHitsTotal ? metrics.workflow.linkHitsTotal.toString() : '--',
    ctr: formatCtr(metrics.workflow.clickThroughRateAvg),
    functions: functionGroups,
  }
}

export function useWorkflowAnalytics(
  clientId: string,
  workflow?: Workflow,
): { isLoading: boolean; data: WorkflowAnalyticsProcessed; isEmpty: () => boolean } {
  const lastManifestBody = workflow?.last_manifest?.body
  const { data, isInitialLoading: isLoading } = useQuery(
    [QUERY_CACHE.WORKFLOW_ANALYTICS, { clientId, workflowId: workflow?.id }],
    () => {
      // the "enabled" flag below guards against these cases, so we do this
      // check mainly to satisfy the type checker.
      if (workflow?.id !== undefined && lastManifestBody !== undefined) {
        return getWorkflowAnalytics({ clientId, workflowId: workflow.id })
      }
    },
    {
      // don’t load this if there’s no manifest version id or if workflow id is ''
      enabled: workflow !== undefined && lastManifestBody !== undefined && Boolean(workflow.id),
      staleTime: STALE_TIME.FIVE_MINUTES,
      // map the data to a more usable format
      select: (analyticsResponse) => {
        if (analyticsResponse !== undefined && lastManifestBody !== undefined) {
          return formatAnalytics(analyticsResponse.data, lastManifestBody)
        }
      },
    },
  )

  function isEmpty(): boolean {
    return (Number(data?.totalSent) || 0) > 0
  }

  return { data: data || makeEmptyAnalytics(), isLoading, isEmpty }
}
