export enum FanActivationMode {
  // Frost registration
  WEB = 'web',
  // Reply Y
  TEXT = 'text',
  // Imported member
  PREOPT = 'preopt',
}

export enum FanRegistrationState {
  NEW = 'new',
  BLOCKED = 'blocked',
  LIVE = 'live',
  OPTED_OUT = 'opted_out',
  PENDING_NEW = 'pending_new',
  UNSUPPORTED_COUNTRY = 'unsupported_country',
  UNREACHABLE = 'unreachable',
  REMINDED = 'reminded',
  LIVE_WHATSAPP = 'live_whatsapp',
  LIVE_AMB = 'live_amb',
  CUSTOMER_SUPPORT_AMB = 'customer_support_amb',
}

export enum FanRegistrationProviderId {
  TWILIO = 'twilio',
  INFOBIP = 'infobip',
  INFOBIP_AMB = 'infobip_amb',
}

export enum FanRegistrationFieldType {
  TEXT = 'text',
  DATE = 'date',
  EMAIL = 'email',
  SELECT = 'select',
  BOOL = 'boolean',
  INTEGER = 'integer',
  DECIMAL2 = 'decimal2',
  DECIMAL4 = 'decimal4',
}

export enum FanRegistrationCoreFieldName {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  CITY_NAME = 'location',
  EMAIL_NAME = 'email',
  GENDER_NAME = 'gender',
  BIRTHDAY_NAME = 'dob',
}

export const GenderOptions = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Non-binary',
    value: 'non-binary',
  },
  {
    label: 'Prefer not to answer',
    value: 'no-answer',
  },
] as const
