import { useFlexibleCanvas } from '@community_dev/flexible-canvas'
import { Api } from '@community_dev/types'
import { MediaProcessingStatus } from '@community_dev/types/lib/api/v2/Media'
import {
  manifestToTree,
  WorkflowAction,
  TreeData,
  NodeData,
  shortGuid,
  WorkflowType,
  isSendMessageAction,
  isNodeDataAction,
  WorkflowMedia,
} from '@community_dev/workflow-manifest'
import { Tree, preOrderTraversal, breadthFirstTraversal } from 'm-ary-tree'
import {
  calculateCoordinates,
  TreeDataPositioned,
} from 'm-ary-tree/dist/positioning-algorithms/Ploeg/calculateCoordinates'
import { useMemo } from 'react'

import { useWorkflowProvider } from '../context/WorkflowProvider'
import IconCompleteRegistrationCheck from '../svg/complete-registration-check.svg?react'
import IconContactCard from '../svg/contact-card.svg?react'
import IconSignupLinkChain from '../svg/signup-link-chain.svg?react'

import {
  OnboardingConfirmationMessage,
  OnboardingWelcomeMessage,
  useOnboardingMessages,
} from 'hooks/useOnboardingMessages'

export type NodeDataInfo = {
  id: string
  type: 'info'
  text: string
  IconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export type NodeDataWelcomeMessage = OnboardingWelcomeMessage & {
  type: 'welcome-message'
}

export type NodeDataSignupConfirmation = OnboardingConfirmationMessage & {
  type: 'signup-confirmation'
}

export type ExtraNodeTypes = NodeDataInfo | NodeDataWelcomeMessage | NodeDataSignupConfirmation

export type WorkflowTree = Tree<string, TreeData<ExtraNodeTypes | NodeData> & TreeDataPositioned>

export type WorkflowTreeResult = {
  tree: WorkflowTree
  nodes: WorkflowTree[]
}

export const NODE_SPACING_X = 100
export const NODE_SPACING_Y = 50

function isMedia(media: Api.V2.Media | WorkflowMedia | undefined): media is Api.V2.Media {
  return !!media
}

export function getMediaForAction(
  node: WorkflowTree,
  mediaUploadState: Map<string, { media: Api.V2.Media; file: File }>,
): {
  mediaObjects?: Api.V2.Media[]
  mediaProcessing?: boolean
  mediaErrored?: boolean
} {
  let mediaObjects: Api.V2.Media[] | undefined

  if (isNodeDataAction(node.data) && isSendMessageAction(node.data.action) && node.data.action.params.media) {
    mediaObjects = node.data.action.params.media
      .map((media) => {
        return mediaUploadState.get(media.id)?.media || media
      })
      .filter(isMedia)
  }

  const mediaProcessing =
    mediaObjects && mediaObjects.some((media) => media?.status === MediaProcessingStatus.PROCESSING)
  const mediaErrored = mediaObjects && mediaObjects.some((media) => media?.status === MediaProcessingStatus.ERRORED)

  return {
    mediaObjects,
    mediaProcessing,
    mediaErrored,
  }
}

export function useWorkflowTree(): WorkflowTreeResult {
  const { workflow, manifest, mediaUpload } = useWorkflowProvider()
  const { welcomeMessage, confirmationMessage } = useOnboardingMessages()
  const { canvasWidth } = useFlexibleCanvas()

  return useMemo(() => {
    const additionalNodes: ExtraNodeTypes[] = []

    if (workflow?.type === WorkflowType.signup) {
      if (welcomeMessage) {
        additionalNodes.push({
          type: 'welcome-message',
          ...welcomeMessage,
        })
      }

      additionalNodes.push({
        id: shortGuid(),
        type: 'info',
        text: 'Signup Link Sent',
        IconComponent: IconSignupLinkChain,
      })

      additionalNodes.push({
        id: shortGuid(),
        type: 'info',
        text: 'Member Completes Registration',
        IconComponent: IconCompleteRegistrationCheck,
      })

      if (confirmationMessage) {
        additionalNodes.push({
          type: 'signup-confirmation',
          ...confirmationMessage,
        })
      }

      additionalNodes.push({
        id: shortGuid(),
        type: 'info',
        text: 'Contact Card Sent',
        IconComponent: IconContactCard,
      })
    }

    const tree = manifestToTree<ExtraNodeTypes>(manifest, additionalNodes) as Tree<
      string,
      TreeData<ExtraNodeTypes | NodeData> & TreeDataPositioned
    >

    for (const node of Array.from(breadthFirstTraversal(tree))) {
      switch (node.data.type) {
        case 'info': {
          node.data.width = 279
          node.data.height = 50
          node.data.spacingY = 9
          break
        }
        case 'welcome-message':
        case 'signup-confirmation': {
          node.data.width = 279
          node.data.height = 137
          node.data.spacingY = 24
          break
        }
        case 'action': {
          switch (node.data.action.type) {
            case WorkflowAction.AddToCommunity:
            case WorkflowAction.RemoveFromCommunity:
              node.data.width = 279
              node.data.height = 86
              node.data.spacingY = 56
              break
            case WorkflowAction.SendMessage: {
              const media = getMediaForAction(node, mediaUpload)
              const { mediaObjects, mediaProcessing, mediaErrored } = media

              if (mediaObjects) {
                if (mediaProcessing && mediaErrored) {
                  node.data.width = 279
                  node.data.height = 137
                  node.data.spacingY = 56
                  break
                }

                node.data.width = 279
                node.data.height = 170
                node.data.spacingY = 56
                break
              }

              node.data.width = 279
              node.data.height = 137
              node.data.spacingY = 56
              break
            }
            case WorkflowAction.Sleep:
            default:
              node.data.width = 279
              node.data.height = 50
              node.data.spacingY = 56
              break
          }
          break
        }
        case 'trigger': {
          switch (workflow?.type) {
            case WorkflowType.keyword:
              node.data.width = 279
              node.data.height = 80
              node.data.spacingBottom = 0
              break
            case WorkflowType.shopify_abandoned_checkout:
              node.data.width = 279
              node.data.height = 120
              node.data.spacingBottom = 0
              break
            case WorkflowType.signup:
            default:
              node.data.width = 279
              node.data.height = 60
              node.data.spacingY = 24
          }
          break
        }
        case 'logic': {
          node.data.width = 279
          node.data.height = 50
          node.data.spacingTop = 56
          node.data.spacingBottom = 53
          break
        }
        case 'else': {
          node.data.width = 279
          node.data.height = 34
          node.data.spacingTop = 64
          node.data.spacingBottom = 64
          break
        }
        case 'condition': {
          node.data.width = 279
          node.data.height = 50
          node.data.spacingTop = 56
          node.data.spacingBottom = 56
          break
        }
        case 'placeholder':
        default: {
          node.data.width = 279
          node.data.height = 137
          node.data.spacingY = 74
        }
      }
    }

    calculateCoordinates(tree, {
      nodeSpacingX: NODE_SPACING_X,
      nodeSpacingY: NODE_SPACING_Y,
    })

    // The following positions the workflow on the canvas in a place that is visually
    // appealing for the user.
    //
    // First, find the root node's distance from the x,y coordinates we would like
    // it to be at.
    //
    const { x: rootX, y: rootY, width: rootWidth } = tree.data
    const targetX = canvasWidth / 2 - rootWidth / 2
    const targetY = 100
    const diffY = targetY - rootY
    const diffX = targetX - rootX

    // Second, adjust all nodes based on the root notes x,y diff value.
    //
    for (const node of Array.from(preOrderTraversal(tree))) {
      node.data.y += diffY
      node.data.x += diffX
    }

    const nodes = Array.from(breadthFirstTraversal(tree))

    return { tree, nodes }
  }, [manifest, workflow, canvasWidth, confirmationMessage, welcomeMessage])
}
