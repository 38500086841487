import { ArrowRightIcon, Button, Dropzone, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useCallback } from 'react'
import { FileRejection } from 'react-dropzone'
import { useHistory, useParams } from 'react-router'
import styled, { css, useTheme } from 'styled-components'

import { useActionPanel } from 'components/ActionPanel/ActionPanelContext'
import { MemberDetails } from 'components/MemberDetails'
import { MOBILE_HEADER_HEIGHT, TABLET_BREAK } from 'constants/theme'
import Convo from 'containers/Convo'
import { InboxChatEmptyState } from 'containers/InboxChat/InboxChatEmptyState'
import { MemberDetailActionPanelRouter } from 'containers/InboxList/MemberDetailActionPanel/MemberDetailActionPanelRouter'
import { BoundsProvider } from 'contexts/BoundsProvider'
import { ConvoProvider } from 'contexts/ConvoProvider'
import { useInbox } from 'contexts/InboxProvider'
import { useUpload } from 'contexts/UploadProvider'
import { useCommunities } from 'hooks/useCommunities'
import { useFan } from 'hooks/useFan'
import { FileContext, useMediaConfig } from 'hooks/useMediaConfig'
import { SidebarLayout } from 'layouts/SidebarLayout'

const StyledInboxChatRoot = styled.div<{ $hideMobile?: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: auto;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};

  ${({ $hideMobile }) =>
    $hideMobile &&
    css`
      display: none;

      @media (min-width: ${TABLET_BREAK}) {
        display: block;
      }
    `};
`

const StyledMain = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`

const StyledConvo = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const StyledDropzone = styled(Dropzone)`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`

const StyledHeader = styled.header`
  flex-shrink: 0;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
`

const StyledHeaderMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MOBILE_HEADER_HEIGHT};
  padding: 0 32px;

  @media (min-width: ${TABLET_BREAK}) {
    padding: ${SPACING[2]} ${SPACING[4]};
    height: auto;
  }
`

const StyledBackButton = styled.button`
  background: none;
  border: none;
  margin-right: ${SPACING[2]};
  transform: rotate(180deg);
  cursor: pointer;
`

const StyledDetailsButton = styled(Button)`
  display: none;

  @media (min-width: ${TABLET_BREAK}) {
    display: initial;
  }
`

export function DirectMessage(): JSX.Element {
  const { COLORS } = useTheme() || {}

  const { fanId } = useParams<{ fanId: string }>()
  const { data: fan } = useFan(fanId)

  const { data: communities } = useCommunities()
  const { openActionPanel, closeActionPanel } = useActionPanel()
  const history = useHistory()
  const shouldGoBack = useCallback(() => {
    closeActionPanel()
    history.goBack()
  }, [history, closeActionPanel])

  const { setFile } = useUpload()
  const { filters } = useInbox()

  const communicationChannel =
    (filters.find((filter) => filter.field === 'search[communication_channel]')?.key as CommunicationChannel) ||
    CommunicationChannel.SMS
  const { accept, maxSize } = useMediaConfig({
    communicationChannel,
    context: FileContext.DM,
  })

  const onFileAccepted = (file: File) => {
    setFile(file)
  }

  const onFileRejected = useCallback(
    (fileRejection: FileRejection) => {
      setFile(fileRejection.file)
    },
    [setFile],
  )

  return (
    <BoundsProvider>
      <SidebarLayout>
        <StyledInboxChatRoot $hideMobile={false}>
          {!fan ? (
            <InboxChatEmptyState />
          ) : (
            <StyledMain>
              <StyledHeader>
                <StyledHeaderMain>
                  {history.length && (
                    <StyledBackButton aria-label="back" onClick={shouldGoBack}>
                      <ArrowRightIcon color={COLORS?.BUTTON_PRIMARY} size={20} />
                    </StyledBackButton>
                  )}
                  <MemberDetails fan={fan} onClick={openActionPanel} />
                  <StyledDetailsButton onClick={openActionPanel}>Details</StyledDetailsButton>
                </StyledHeaderMain>
              </StyledHeader>
              <StyledConvo>
                <StyledDropzone
                  accept={accept}
                  maxSize={maxSize}
                  noClick
                  onFileAccepted={onFileAccepted}
                  onFileRejected={onFileRejected}
                >
                  <ConvoProvider>
                    <Convo fanId={fanId} />
                  </ConvoProvider>
                </StyledDropzone>
              </StyledConvo>
            </StyledMain>
          )}
        </StyledInboxChatRoot>
        <MemberDetailActionPanelRouter communities={communities?.custom} fan={fan} />
      </SidebarLayout>
    </BoundsProvider>
  )
}
