import { Api } from '@community_dev/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'

import { PostRestrictedKeywordResponse, addRestrictedKeyword } from 'api/restrictedKeywords'
import { QUERY_CACHE } from 'constants/query-cache'

type AddRestrictedKeyword = {
  onSuccess?: (data: PostRestrictedKeywordResponse, variables: string, context: unknown) => void
  onError?: (error: Api.RestrictedKeyword.PostKeywordError, variables: string, context: unknown) => void
}

export const useAddRestrictedKeyword = ({ onSuccess, onError }: AddRestrictedKeyword = {}): UseMutationResult<
  PostRestrictedKeywordResponse,
  Api.RestrictedKeyword.PostKeywordError,
  string
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (keyword) => {
      return addRestrictedKeyword(keyword)
    },
    onError,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([QUERY_CACHE.SETTINGS.RESTRICTED_KEYWORDS])
      onSuccess?.(data, variables, context)
    },
  })
}
