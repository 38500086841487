import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type Invoice = CamelCasedPropertiesDeep<Api.Billing.Invoice>
export type Invoices = CamelCasedPropertiesDeep<Api.Billing.Invoices>

export function getInvoices({ clientId }: { clientId: string }): Promise<Invoices> {
  return request(
    route(ENDPOINTS.BILLING.INVOICES, {
      clientId,
    }),
    {
      ignoreReportingErrorCodes: [404],
    },
  )
}
