import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

type PostSuggestQuick = {
  clientId: string
  body?: Partial<Api.V1.SuggestRequestQuick>
}

export function postSuggestQuick({
  clientId,
  body = {},
}: PostSuggestQuick): Promise<CamelCasedPropertiesDeep<Api.V1.SuggestResponse>> {
  return request<CamelCasedPropertiesDeep<Api.V1.SuggestResponse>>(route(ENDPOINTS.AI_INTERFACE.QUICK, { clientId }), {
    body,
    method: HTTP.POST,
  })
}

type PostSuggestFull = {
  clientId: string
  body?: Partial<Api.V1.SuggestRequestFull>
}

export function postSuggestFull({
  clientId,
  body = {},
}: PostSuggestFull): Promise<CamelCasedPropertiesDeep<Api.V1.SuggestResponse>> {
  return request<CamelCasedPropertiesDeep<Api.V1.SuggestResponse>>(route(ENDPOINTS.AI_INTERFACE.FULL, { clientId }), {
    body,
    method: HTTP.POST,
  })
}

type PostSuggestDms = {
  clientId: string
  body?: Partial<Api.V1.SuggestDmsRequest>
}

export function postSuggestDms({
  clientId,
  body = {},
}: PostSuggestDms): Promise<CamelCasedPropertiesDeep<Api.V1.SuggestDmsResponse>> {
  return request<CamelCasedPropertiesDeep<Api.V1.SuggestDmsResponse>>(
    route(ENDPOINTS.AI_INTERFACE.SUGGEST_DMS, { clientId }),
    {
      body,
      method: HTTP.POST,
    },
  )
}
