import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { AddKeywordResponderResponse, AddKeywordResponderBody, addKeywordResponder } from 'api/keywordResponders'
import { QUERY_CACHE } from 'constants/query-cache'
import { useToastMessage } from 'hooks/useToastMessage'

export const useAddKeywordResponder = (): UseMutationResult<
  AddKeywordResponderResponse,
  unknown,
  AddKeywordResponderBody
> => {
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: addKeywordResponder,
    onError: () => {
      showToastMessage({
        message: t('settings.customize.addKeywordSuccess'),
        success: false,
      })
    },
    onSuccess: () => {
      showToastMessage({
        message: t('settings.customize.addKeywordSuccess'),
      })
      queryClient.invalidateQueries([QUERY_CACHE.SETTINGS.KEYWORD_MESSAGES])
    },
  })
}
