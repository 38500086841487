import { SPACING } from '@community_dev/pixels'
import React, { useState } from 'react'
import styled from 'styled-components'

import { SETTINGS_LAYOUT } from './constants'

import { MOBILE_BREAK } from 'constants/theme'
import { Header } from 'screens/SettingsScreen/components/Header'

const StyledRoot = styled.div`
  height: 100vh;
  width: 100%;
  max-width: ${SETTINGS_LAYOUT.MAX_WIDTH};
  margin: auto;
  padding: 32px;
`

const Tab = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${MOBILE_BREAK}) {
    flex-direction: column;
  }
`

const StyledTabs = styled.ul`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
  display: flex;
  list-style: none;
  margin: 0;
  flex-direction: column;
  padding: ${SPACING[4]} 0 ${SPACING[5]};
  padding-left: ${SPACING[4]};

  p {
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
    text-decoration: none;
    padding: 0;
    margin: 0;

    &.active {
      color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
    }
  }

  li {
    margin-right: ${SPACING[9]};
    list-style-type: disc;
    padding: 0;
    margin-bottom: ${SPACING[5]};

    &.active {
      color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};

      & p {
        color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${MOBILE_BREAK}) {
    flex-direction: row;
    overflow-x: scroll;
    padding-left: ${SPACING[1]};

    & li {
      list-style: none;
      margin-bottom: 0;
      margin-right: ${SPACING[5]};
      white-space: nowrap;
    }
  }
`

const StyledContent = styled.div`
  margin-left: ${SPACING[2]};

  @media (max-width: ${MOBILE_BREAK}) {
    margin-left: 0;
  }
`

interface ChildrenProps {
  selectTab: (tab: number) => void
}

type HorizontalTabLayoutProps = {
  children: (args: ChildrenProps) => React.ReactChild
  tabs: string[]
  initialTab: number
}

const HorizontalTabLayout = (props: HorizontalTabLayoutProps): JSX.Element => {
  const [currentTab, setCurrentTab] = useState(props.initialTab || 0)
  const selectTab = (toIndex: number) => {
    setCurrentTab(toIndex)
  }

  const childrenProps: any = props.children({ selectTab: selectTab })
  return (
    <StyledRoot>
      <Header title="Connect Shopify" />
      <Tab>
        <StyledTabs className="shopify-links">
          {props.tabs.map((tab: string, index: number) => (
            <li className={index === currentTab ? 'active' : 'inactive'} key={tab}>
              <p>{tab}</p>
            </li>
          ))}
        </StyledTabs>
        <StyledContent>{childrenProps.props.children[currentTab]}</StyledContent>
      </Tab>
    </StyledRoot>
  )
}

export default HorizontalTabLayout
