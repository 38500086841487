import React from 'react'
import { IntersectionOptions, useInView } from 'react-intersection-observer'

interface LazyComponentProps {
  style: React.CSSProperties
  options: IntersectionOptions
  children: React.ReactNode
}

export const LazyComponent = (props: LazyComponentProps): JSX.Element => {
  const { ref, inView } = useInView(props.options)

  return (
    <div ref={ref} style={props.style}>
      {inView ? props.children : null}
    </div>
  )
}
