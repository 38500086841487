import { CheckCircleIcon, SPACING } from '@community_dev/pixels'
import React from 'react'
import styled, { useTheme } from 'styled-components'

const StyledValidation = styled.div`
  display: flex;
  align-items: center;
`

type StyledTextProps = {
  $isValid: boolean
}

const StyledText = styled.span<StyledTextProps>`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme, $isValid }) => ($isValid ? theme?.COLORS?.TEXT : theme?.COLORS?.SUBTEXT)};
  margin-left: ${SPACING[1]};
`

type ValidationRequirementProps = {
  text: string
  isValid: boolean
}

export const ValidationRequirement = ({ text, isValid }: ValidationRequirementProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  return (
    <StyledValidation>
      <CheckCircleIcon color={isValid ? COLORS?.SUCCESS : COLORS?.SUBTEXT} size={14} />
      <StyledText $isValid={isValid}>{text}</StyledText>
    </StyledValidation>
  )
}
