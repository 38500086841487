import styled from 'styled-components'

export const StyledNotifRoot = styled.div`
  width: 100%;
  position: relative;
`

type StyledNotifMainProps = {
  $level?: 'success' | 'info' | 'warn' | 'danger'
}

const toLevelColors = (theme) => ({
  success: theme?.COLORS?.SUCCESS,
  info: theme?.COLORS?.INFO,
  warn: theme?.COLORS?.WARNINGS,
  danger: theme?.COLORS?.ERRORS,
})

export const StyledNotifMain = styled.div<StyledNotifMainProps>`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border: 1px solid ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${({ theme, $level }) => $level && toLevelColors(theme)[$level]};
  border-left: ${({ theme, $level }) => $level && toLevelColors(theme)[$level]};
`

export const StyledNotifBody = styled.div`
  width: 100%;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  font-weight: 500;
  opacity: 0.9;
  padding-top: 1px;
`

export const StyledNotifIcon = styled.div`
  flex-shrink: 0;
  padding-right: 10px;
  font-size: 16px;
`
