import { Api } from '@community_dev/types'
import { ClusterRepliedState } from '@community_dev/types/lib/api/v2/Clusters'
import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'

import { ClusterResponse, getClusterThread } from 'api/cluster'
import { QUERY_CACHE } from 'constants/query-cache'
import { FILTERS } from 'containers/Chain/constants'
import { useClientId } from 'hooks/useClient'

type UseClusterQueryProps = {
  campaignId: string
  clusterId: string
  filtersSelected: {
    [FILTERS.REPLIED]: boolean
    [FILTERS.UNREPLIED]: boolean
  }
}

export const useClusterQuery = ({
  campaignId,
  clusterId,
  filtersSelected,
}: UseClusterQueryProps): UseInfiniteQueryResult<Api.V2.Pagination<ClusterResponse>, unknown> => {
  const clientId = useClientId()

  return useInfiniteQuery(
    [
      QUERY_CACHE.CLUSTER.DETAILS,
      {
        clientId,
        campaignId,
        clusterId,
        [FILTERS.REPLIED]: filtersSelected[FILTERS.REPLIED],
        [FILTERS.UNREPLIED]: filtersSelected[FILTERS.UNREPLIED],
      },
    ],
    ({ pageParam }) => getClusterThread({ clientId, campaignId, clusterId, filtersSelected }, pageParam),
    {
      enabled: !!(clientId && campaignId && clusterId),
      getNextPageParam: (lastPage) =>
        lastPage?.pagination_data?.has_next_page && (lastPage?.pagination_data?.page_number || 0) + 1,
    },
  )
}

export const useCurrentClusterQuery = (): UseInfiniteQueryResult<Api.V2.Pagination<ClusterResponse>, unknown> => {
  const { campaignId, clusterId, selectedFilter }: any = useParams()
  const filtersSelected = {
    [FILTERS.REPLIED]: selectedFilter === ClusterRepliedState.REPLIED,
    [FILTERS.UNREPLIED]: selectedFilter === ClusterRepliedState.UNREPLIED,
  }

  return useClusterQuery({ campaignId, clusterId, filtersSelected })
}
