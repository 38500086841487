import { SPACING } from '@community_dev/pixels'
import React from 'react'
import styled from 'styled-components'

import { Notif } from 'components/base'
import { MOBILE_BREAK, TABLET_BREAK } from 'constants/theme'

const StyledContainer = styled.div`
  width: 100%;
  max-width: 456px;
  margin: 0 ${SPACING[9]};

  @media (max-width: ${TABLET_BREAK}) {
    margin: 0 0;
  }
  @media (max-width: ${MOBILE_BREAK}) {
    max-width: 350px;
  }
`

const StyedMain = styled.div`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  border-radius: 8px;
  background-color: #ffffff;
  padding: 8px 12px 14px;
  padding-bottom: -100px;

  @media (min-width: ${TABLET_BREAK}) {
    padding: 24px;
  }
`

const StyledForm = styled.form`
  width: 100%;
  padding: 0 ${SPACING[5]};
  margin: ${SPACING[4]} 0;

  button[type='submit'] {
    margin-top: ${SPACING[2]};
    height: 50px;
    width: 100%;
    max-width: 336px;
    font-size: 14px;
    line-height: 14px;

    @media (max-width: ${MOBILE_BREAK}) {
      margin: ${SPACING[4]} 0 0 0;
    }
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    letter-spacing: 0.16px;
    color: #666666;
  }
`
const StyledFormTitle = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  width: 100%;
  padding: 0 0 ${SPACING[5]};
  color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
  text-align: center;
  user-select: none;

  @media (max-width: ${MOBILE_BREAK}) {
    margin-top: ${SPACING[2]};
  }
`

const StyledSubtitle = styled.div`
  margin-top: -${SPACING[4]};
  padding-bottom: ${SPACING[4]};
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.08px;
  color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
`

const StyledNotifContainer = styled.div`
  margin: ${SPACING[4]} 0;
`

type BoxFormProps = {
  children?: React.ReactNode
  title?: string
  subtitle?: string
  header?: React.ReactNode
  error?: React.ReactNode
  disabled?: boolean
  onSubmit: (...args: any[]) => void
}

function BoxForm({ children, header, title, subtitle, error, ...rest }: BoxFormProps): JSX.Element {
  return (
    <StyledContainer>
      {error && (
        <>
          <StyledNotifContainer>
            <Notif body={error} level="danger" />
          </StyledNotifContainer>
        </>
      )}
      {header && <div>{header}</div>}
      <StyedMain>
        {title && <StyledFormTitle>{title}</StyledFormTitle>}
        {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
        <StyledForm {...rest}>{children}</StyledForm>
      </StyedMain>
    </StyledContainer>
  )
}

export default BoxForm
