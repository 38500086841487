import events, { screens } from './events'
import ga from './ga'
import mixpanel from './mixpanel'

import { Appcues } from 'utils/appcues'

const analytics = {
  events,
  identify: (user: any, seat: any, planName: string | undefined, planType: string | undefined): void => {
    const { id } = user
    ga.identify(id)
    mixpanel.identify(user, seat, planName, planType)
  },
  init: ({
    clientId,
    gaTrackingId,
    mixpanelToken,
  }: {
    clientId: string
    gaTrackingId: string
    mixpanelToken: string
  }): void => {
    if (gaTrackingId) ga.init(gaTrackingId)
    if (mixpanelToken) mixpanel.init(mixpanelToken, clientId)
  },
  pageView: (pathname: string): void => {
    ga.pageView(pathname)
    Appcues.page()
  },
  reset: (): void => {
    mixpanel.reset()
  },
  screens,
  track: ({ event, properties }: { event: any; properties?: any }): void => {
    ga.track(event)
    mixpanel.track({ event, properties })
    Appcues.track({ event, properties })
  },
}

export default analytics
