import { StyledLoginScreenRoot } from './LoginScreen.style'

import { LoginForm } from 'containers/LoginForm'
import { MfaSetupForm } from 'containers/MfaSetupForm'
import { AuthStates } from 'contexts/AuthProvider'
import { useAuth } from 'hooks/useAuth'
import BoxFormLayout from 'layouts/BoxFormLayout'

const LoginScreen = (): JSX.Element => {
  const { state } = useAuth()
  return (
    <StyledLoginScreenRoot>
      {state === AuthStates.MFA_SETUP ? (
        <BoxFormLayout maxWidth="700px">
          <MfaSetupForm />
        </BoxFormLayout>
      ) : (
        <BoxFormLayout>
          <LoginForm />
        </BoxFormLayout>
      )}
    </StyledLoginScreenRoot>
  )
}

export default LoginScreen
