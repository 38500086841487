import { useCallback } from 'react'

import { useCapabilities } from 'contexts/bootstrap/useCapabilities'
import { useLocalCapabilityOverrides } from 'contexts/use-local-capability-overrides'

export type UseCapabilityChecks = {
  userHasCapability: (capability) => boolean
  userHasEveryCapability: (...capability: string[]) => boolean
  userHasSomeCapabilities: (...capability: string[]) => boolean
}

export const useCapabilityChecks = (): UseCapabilityChecks => {
  const localCapabilityOverrides = useLocalCapabilityOverrides()

  const capabilities = useCapabilities()

  const userHasCapability = useCallback(
    (capability: string) => {
      return localCapabilityOverrides[capability] !== undefined
        ? localCapabilityOverrides[capability]
        : capabilities?.includes(capability)
    },
    [localCapabilityOverrides, capabilities],
  )

  const userHasEveryCapability = useCallback(
    (...capability: string[]) => {
      return capability.every((capability) => {
        if (localCapabilityOverrides[capability] !== undefined) {
          return localCapabilityOverrides[capability]
        }

        return capabilities.includes(capability)
      })
    },
    [localCapabilityOverrides, capabilities],
  )

  const userHasSomeCapabilities = useCallback(
    (...capability: string[]) => {
      return capability.some((capability) => {
        if (localCapabilityOverrides[capability] !== undefined) {
          return localCapabilityOverrides[capability]
        }

        return capabilities.includes(capability)
      })
    },
    [localCapabilityOverrides, capabilities],
  )

  return {
    userHasCapability,
    userHasEveryCapability,
    userHasSomeCapabilities,
  }
}

export const useHasCapability = (capability: string): boolean => {
  const { userHasCapability } = useCapabilityChecks()

  return userHasCapability(capability)
}

export const useHasEveryCapability = (...capability: string[]): boolean => {
  const { userHasEveryCapability } = useCapabilityChecks()

  return userHasEveryCapability(...capability)
}

export const useHasSomeCapabilities = (...capability: string[]): boolean => {
  const { userHasSomeCapabilities: useUserHasSomeCapabilities } = useCapabilityChecks()

  return useUserHasSomeCapabilities(...capability)
}
