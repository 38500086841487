import { Block } from '@community_dev/pixels'

import { MetricsChart } from '../MetricsChart'
import { useMetrics } from '../useMetrics'

export function MediaViews({ totalRecipients }: { totalRecipients: number }): JSX.Element | null {
  const { hasMediaMetrics, mediaMetrics, isLoading } = useMetrics(totalRecipients)

  return hasMediaMetrics ? (
    <Block title="Media Clicks">
      {mediaMetrics?.map((metric) => (
        <MetricsChart isLoading={isLoading} metric={metric} totalRecipients={totalRecipients} />
      ))}
    </Block>
  ) : null
}
