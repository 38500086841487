import { SPACING } from '@community_dev/pixels'
import React from 'react'
import styled from 'styled-components'

type FormSectionProps = {
  name: string
  children: React.ReactNode
}

const StyledContainer = styled.div`
  padding-bottom: ${SPACING[2]};
`

const StyledSectionName = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  margin: ${SPACING[4]} 0;
`
export const FormSection = (props: FormSectionProps): JSX.Element => {
  const { children, name } = props

  return (
    <StyledContainer>
      <StyledSectionName>{name}</StyledSectionName>
      {children}
    </StyledContainer>
  )
}
