import { BUTTON_VARIANTS, Button, ChatBubble, InvisibleCharacters } from '@community_dev/pixels'
import React from 'react'

import { ROUTES } from 'constants/routes'
import { StyledAction, StyledBack, StyledMessagePreview } from 'screens/Onboarding/styled'

type PreviewProps = {
  children: React.ReactNode
  message: string
  onEdit: (...args: any[]) => any
}

export function Preview({ children, message, onEdit }: PreviewProps): JSX.Element {
  return (
    <>
      {children}
      <StyledMessagePreview>
        <ChatBubble>
          <InvisibleCharacters>{message}</InvisibleCharacters>
        </ChatBubble>
      </StyledMessagePreview>
      <StyledAction>
        <Button onClick={onEdit} variant={BUTTON_VARIANTS.ACTION}>
          Edit
        </Button>
      </StyledAction>
      <StyledBack to={ROUTES.ONBOARDING.SUMMARY}>Back</StyledBack>
    </>
  )
}
