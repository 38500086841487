import React from 'react'

type SeatedIconProps = {
  size?: number
}

export const SeatedIcon = ({ size = 24 }: SeatedIconProps): JSX.Element => {
  return (
    <svg
      enable-background="new 0 0 139 139"
      height={size}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 139 139"
      width={size}
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="m69.5 139c-38.4 0-69.5-31.1-69.5-69.5 0-38.4 31.1-69.5 69.5-69.5 38.4 0 69.5 31.1 69.5 69.5 0 38.4-31.1 69.5-69.5 69.5z"
        fill="#000000"
      />
      <path
        d="m35.3 38c-1.8 1.1-4 3.4-4.8 5-0.8 1.6-1.5 4.2-1.5 5.8 0 1.5 0.9 4.2 2 6 1.2 1.9 4 4.2 7.3 5.7 2.8 1.4 9.9 3.4 15.7 4.5 5.8 1.1 14.3 2.8 19 3.7 4.7 1 11.2 3 14.5 4.6 3.3 1.5 7.3 4.1 9 5.7l3 2.9c7.4-22 9.5-30.1 9.5-32.1 0-2.1-0.7-5.2-1.5-6.8-0.8-1.6-3-3.9-4.7-5-3.1-1.9-5.1-2-33.8-2-28.7 0-30.7 0.1-33.8 2zm-0.2 32.5c0 0.3 2.3 7.8 5.2 16.8 2.9 9.1 6.2 17.4 7.5 18.9 1.3 1.6 4 3.3 6 3.9 2.2 0.6 9.6 0.9 17.5 0.7 12-0.3 14-0.6 16.5-2.4 1.5-1.2 3.7-3.6 4.8-5.5 1.1-2.1 1.9-5.3 2-8.3 0-4.4-0.3-5.1-4.1-8.5-2.4-2.2-6.7-4.5-10.7-5.8-3.7-1.3-13.5-3.4-21.7-4.8-8.3-1.4-16.7-3.2-18.8-4-2-0.9-3.8-1.5-4-1.5-0.1 0-0.2 0.2-0.2 0.5z"
        fill="#FFFFFF"
      />
    </svg>
  )
}
