/* eslint-disable max-len */

export const baseConfig = {
  altAppUrl: import.meta.env.VITE_ALT_APP_URL,
  apiGwUrl: import.meta.env.VITE_API_GW_URL,
  apiUrl: import.meta.env.VITE_API_URL,
  appStoreUrl: import.meta.env.VITE_APP_STORE_URL || 'https://apps.apple.com/app/id1494783989',
  cookieSecret: import.meta.env.VITE_COOKIE_SECRET || '',
  deployEnv: import.meta.env.VITE_DEPLOY_ENV || 'dev',
  filestackApiKey: import.meta.env.VITE_FILESTACK_API_KEY || '',
  giphyApiToken: import.meta.env.VITE_GIPHY_API_TOKEN || '',
  mapboxApiToken: import.meta.env.VITE_MAPBOX_API_TOKEN || '',
  mostActiveMembersOptionsGetApi: import.meta.env.VITE_MOST_ACTIVE_MEMBERS_OPTIONS_GET_API || '',
  nodeEnv: import.meta.env.NODE_ENV,
  placesApiKey: import.meta.env.VITE_PLACES_API_KEY,
  socketUrl: import.meta.env.VITE_SOCKET_URL,
  swipeUpUrl: import.meta.env.VITE_SWIPE_URL || 'https://my.dev.community.com',
}

export const generalConfig = {
  loadingTimeout: 1500,
  shareNumberUrl: import.meta.env.VITE_SHARE_NUMBER_URL,
  // frontend-managed web assets
  staticUrl: import.meta.env.VITE_WEB_ASSETS_URL || 'https://assets.community.com',
}

export const mediaConfig = {
  clientMediaRegex: /https:\/\/[A-Za-z0-9_-]+\.[A-Za-z0-9_-]+\/(g|i|v)\/(\w{10})(?:$|\s+)/,
  mediaBucketUrl: import.meta.env.VITE_MEDIA_BUCKET_URL,
  sharedImageUrl: import.meta.env.VITE_SHARED_IMAGE_URL,
  sharedVideoUrl: import.meta.env.VITE_SHARED_VIDEO_URL,
  placeholderImage: `${generalConfig.staticUrl}/media_placeholder.png`,
}

export const analyticsConfig = {
  gaTrackingId: !window.Cypress ? import.meta.env.VITE_GA_TRACKING_ID : undefined,
  mixpanelToken: !window.Cypress ? import.meta.env.VITE_MIXPANEL_TOKEN : undefined,
}
