import { ChevronRightIcon, Modal } from '@community_dev/pixels'
import { useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { EditShopify } from './components/EditShopify'
import { ShopifyIcon } from './components/Icons/ShopifyIcon'

import { BetaBadge } from 'components/BetaBadge'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useInitialAction } from 'hooks/useInitialAction'
import { useShopify } from 'hooks/useShopify'
import { Setting } from 'layouts/SettingsLayout'
import analytics from 'utils/analytics'

type StyledCommunityProps = {
  $color: string
}

const StyledCommunity = styled.button<StyledCommunityProps>`
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: ${({ $color }) => $color};
    position: relative;
    top: -1px;
    margin: 0 5px 0 2px;
  }
`

const StyledChevron = styled.div`
  display: inline-block;
  position: relative;
  top: 2px;
  left: -4px;
`

enum STATES {
  INITIAL = 'initial',
  EDIT = 'edit',
}

export function Shopify(): JSX.Element {
  const queryClient = useQueryClient()
  const history = useHistory()
  const clientId = useClientId()
  const { data: shopifyCommunity } = useCommunities({
    select: (communities) => {
      return communities.protected.find((community) => community.title === 'Shopify')
    },
  })
  const [state, setState] = useState<STATES>(STATES.INITIAL)
  const [displayNewShopify, setDisplayNewShopify] = useState(useInitialAction('shopify'))

  const {
    isInitialLoading,
    communityCount,
    shopify,
    client,
    refetchShopify,
    shopifyExists: exists,
  } = useShopify(clientId, shopifyCommunity)

  function handleAction() {
    if (!exists) {
      analytics.track(analytics.events.ShopifyConnectStore())
      history.push(ROUTES.SETTINGS.INTEGRATIONS.SHOPIFY.CONNECT_STORE)
    } else {
      setState(STATES.EDIT)
    }
  }

  useEffect(() => {
    if (displayNewShopify && !isInitialLoading) {
      setDisplayNewShopify(false)
      handleAction()
    }
  }, [isInitialLoading, displayNewShopify])

  const pluralizedMembers = communityCount === 1 ? 'Member' : 'Members'

  return (
    <>
      <Setting
        action={exists ? 'Edit' : 'Connect Store'}
        icon={<ShopifyIcon />}
        label={
          <>
            Shopify <BetaBadge />
          </>
        }
        loading={isInitialLoading}
        onClick={handleAction}
        onClickAction={handleAction}
        text={
          exists &&
          shopifyCommunity && (
            <div>
              {`${communityCount} ${pluralizedMembers} added to`}{' '}
              <StyledCommunity
                $color={shopifyCommunity?.color}
                onClick={() => {
                  history.push(`${ROUTES.COMMUNITIES}/${shopifyCommunity.id}`)
                }}
              >
                Shopify{' '}
                <StyledChevron>
                  <ChevronRightIcon size={16} />
                </StyledChevron>
              </StyledCommunity>
            </div>
          )
        }
      />
      {state === STATES.EDIT && client && shopifyCommunity && communityCount !== undefined && (
        <Modal maxHeight="100%" open>
          <EditShopify
            client={client}
            community={shopifyCommunity}
            count={communityCount}
            onCancel={() => setState(STATES.INITIAL)}
            onComplete={() => {
              setState(STATES.INITIAL)
              queryClient.invalidateQueries([QUERY_CACHE.SHOPIFY.STORE_DETAILS, { clientId }])
              queryClient.invalidateQueries([QUERY_CACHE.CLIENT])
              refetchShopify()
            }}
            shopify={shopify}
          />
        </Modal>
      )}
    </>
  )
}
