import { Api } from '@community_dev/types'
import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'

import { ENDPOINTS } from 'constants/endpoints'
import { FILTERS } from 'containers/Chain/constants'
import { request } from 'utils/api'
import { route } from 'utils/router'

type GetClusterIndexProps = {
  clientId: string
  campaignId: string
  tagsAll: string
  tagsNot: string
}

export type Cluster = Api.V2.Cluster
export type ClusterResponse = Api.V2.ClusterResponse

export function getMatchingClusters({
  clientId,
  campaignId,
  tagsAll,
  tagsNot,
}: GetClusterIndexProps): Promise<{ data: Cluster[] }> {
  return request(
    route(
      ENDPOINTS.CLUSTER.INDEX,
      { clientId, campaignId },
      // we don't want to include ?tags[all] or ?tags[none] unless we're actively passing tags,
      // hence the pickBy
      pickBy({ 'tags[all]': tagsAll, 'tags[not]': tagsNot }, identity),
    ),
    { camelCase: false },
  ).then((parsedData: any) => ({
    ...parsedData,
    data: parsedData.data.filter((d) => d.has_messages),
  }))
}

export function getClusterIndex({ clientId, campaignId, tagsAll, tagsNot }: GetClusterIndexProps): Promise<any> {
  const injectRepliedToAll = (parsedData, repliedClusterIds = {}, forceAll?: boolean) => ({
    ...parsedData,
    data: parsedData.data.map((d) => ({
      ...d,
      replied_to_all: forceAll || repliedClusterIds[d.cluster_id],
    })),
  })

  // note: the previous version of this code was grabbing replied cluster IDs
  // via the tags[all]=replied-to option, however this was flawed because
  // the has_messages flag was being set to true for clusters containing ANY
  // messages that were replied, whereas we want to set the replied_to_all
  // flag for clusters where ALL sub-messages are replied. To accomplish this,
  // we have to grab all of the cluster IDs that have ANY unreplied sub messages.
  // The inverse of this set will then  correspond to the cluster IDs where ALL sub
  // messages have a reply.
  const getRepliedClusterIds = request(
    route(ENDPOINTS.CLUSTER.INDEX, { clientId, campaignId }, { 'tags[not]': 'replied-to' }),
    { camelCase: false },
  ).then((parsedData: any) => {
    // filter by has_messages to only get clusters where all sub-messages have
    // been replied to, then convert to an id map for efficient access
    return parsedData.data
      .filter((d) => !d.has_messages)
      .reduce((ids, d) => {
        ids[d.cluster_id] = true
        return ids
      }, {})
  })

  return Promise.all([
    getMatchingClusters({
      clientId,
      campaignId,
      tagsAll,
      tagsNot,
    }),
    getRepliedClusterIds,
  ]).then(([matchingClusters, repliedClusterIds]) => injectRepliedToAll(matchingClusters, repliedClusterIds))
}

type GetCusterThreadProps = {
  clientId: string
  campaignId: string
  clusterId: string
  filtersSelected: any
  pageSize?: number
}

export function getClusterThread(
  { clientId, campaignId, clusterId, filtersSelected, pageSize = 20 }: GetCusterThreadProps,
  cursor = 1,
): Promise<Api.V2.Pagination<ClusterResponse>> {
  return request<Api.V2.Pagination<ClusterResponse>>(
    route(
      ENDPOINTS.CLUSTER.DETAIL_INDEX,
      {
        clientId,
        campaignId,
        clusterId,
      },
      {
        ...(filtersSelected[FILTERS.UNREPLIED] && {
          'tags[not]': 'auto-response,replied-to',
        }),
        ...(filtersSelected[FILTERS.REPLIED] && {
          'tags[not]': 'auto-response',
          'tags[all]': 'replied-to',
        }),
        has_cluster: false,
        page_number: cursor,
        page_size: pageSize,
      },
    ),
    { camelCase: false },
  )
}
