import { SPACING } from '@community_dev/pixels'
import ContentLoader from 'react-content-loader'
import styled, { useTheme } from 'styled-components'

const StyledResult = styled.li`
  overflow: hidden;
`

const StyledResults = styled.ul`
  list-style: none;
  padding: ${SPACING[4]} 0 ${SPACING[5]};
  margin: 0;
`

function SearchResultLoadingIndicator(): JSX.Element {
  const { COLORS } = useTheme() || {}
  return (
    <ContentLoader
      backgroundColor={COLORS?.APP_BACKGROUND_LEVEL_2}
      foregroundColor={COLORS?.APP_BACKGROUND_LEVEL_1}
      height={96}
      speed={2}
      viewBox="0 0 896 96"
      width={896}
    >
      <rect height="16" rx="4" width="16" x="16" y="22" />
      <rect height="16" rx="4" width="80" x="44" y="8" />
      <rect height="16" rx="4" width="128" x="44" y="30" />
      <rect height="1" width="852" x="44" y="95" />
      <rect height="16" rx="4" width="104" x="44" y="66" />
    </ContentLoader>
  )
}

type SearchResultLoadingIndicatorProps = {
  numberOfResults?: number
}

export function SearchResultsLoadingIndicator({
  numberOfResults = 10,
}: SearchResultLoadingIndicatorProps): JSX.Element {
  return (
    <StyledResults>
      {Array.from({ length: numberOfResults }).map((_, index) => (
        <StyledResult key={index}>
          <SearchResultLoadingIndicator />
        </StyledResult>
      ))}
    </StyledResults>
  )
}
