import { AudioPlayer, SPACING } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Campaign } from 'api/campaign'
import { getMediaAttachmentType } from 'utils/media'
import { normalizeMedia } from 'utils/normalize'

const StyledImage = styled.img`
  height: 72px;
  border-radius: ${SPACING[2]};
  box-shadow: ${({ theme }) => theme.SHADOWS.CARD};
`

const StyledMedia = styled.div`
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  margin-right: ${SPACING[2]};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

const StyledVideo = styled.video`
  height: 72px;
  border-radius: ${SPACING[2]};
  box-shadow: ${({ theme }) => theme.SHADOWS.CARD};
`

type DisplayMediaProps = {
  media: Campaign['media']
}

export function DisplayMedia({ media }: DisplayMediaProps): JSX.Element {
  const { t } = useTranslation()
  const normalizedMedia = normalizeMedia(media)
  const mediaType = getMediaAttachmentType(normalizedMedia)
  switch (mediaType.toLowerCase()) {
    case 'image':
      return (
        <StyledMedia>
          <StyledImage alt={t('campaigns.imagePreview')} src={normalizedMedia.href} />
        </StyledMedia>
      )
    case 'audio':
      return (
        <StyledMedia
          onClick={(e) => {
            // If we don't stop propagation the click will take us to the campaign details page
            // This enables us to play the audio in the Campaign list page
            e.stopPropagation()
          }}
        >
          <AudioPlayer src={normalizedMedia.href} />
        </StyledMedia>
      )
    case 'video':
      return (
        <StyledMedia>
          <StyledVideo src={normalizedMedia.href} title={t('campaigns.videoPreview')} />
        </StyledMedia>
      )
    default:
      return <StyledMedia>{mediaType ? `1 ${mediaType}` : null}</StyledMedia>
  }
}
