import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorFilter,
  SelectorOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ApiError } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { FilterOptionsWithCounts } from './types'
import { CountByQueryFnReturn, useCountByQueries } from './useCountByQuery'

import { useTimezones } from 'components/ComposeMessage/components/useTimezones'
import { FilterSelectionType } from 'contexts/FilterProvider/FilterProvider'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import dayjs from 'utils/dayjs'
import { formatNumeral } from 'utils/general'

const toTimezoneFilter = (timezone: string): SelectorFilter => {
  return {
    operator: SelectorOperators.EQUALS,
    operand: {
      field_key: BuiltInFields.TIME_ZONE_GROUP,
      field_label: fieldLabelFor(BuiltInFields.TIME_ZONE_GROUP),
      source: FieldSources.BUILT_IN,
      type: FieldTypes.STRING,
      value: timezone,
    },
  }
}

type UseTimezoneCountsArgs<SelectData = CountByQueryFnReturn> = {
  traceId?: string
  communicationChannel?: CommunicationChannel
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

export const useTimezoneCounts = <SelectData = CountByQueryFnReturn>({
  traceId,
  communicationChannel,
  options,
}: UseTimezoneCountsArgs<SelectData> = {}): UseQueryResult<SelectData, ApiError>[] => {
  const { data = [] } = useTimezones()

  return useCountByQueries<SelectData>({
    filters: data.map((timezone) => toTimezoneFilter(timezone.timeZoneGroup || '')),
    traceId,
    communicationChannel,
    options: { enabled: Boolean(data), ...options },
  })
}

export const useCurrentFiltersWithTimezoneCounts = (
  timezoneType: 'local' | 'popular' | 'other',
  type: FilterSelectionType = 'includes',
): FilterOptionsWithCounts => {
  const { t } = useTranslation()
  const { previewNextFilter, communicationChannel } = useFilters()
  const { data = [], communityLocalTimezone } = useTimezones()

  const timezones = data.filter((timezone) => {
    if (timezoneType === 'local') {
      return timezone.local
    }

    if (timezoneType === 'popular') {
      return timezone.popular
    }

    return !timezone.local && !timezone.popular
  })

  const timezoneFilters = timezones.map((timezone) => toTimezoneFilter(timezone.timeZoneGroup || ''))

  const nextFilters = timezoneFilters.map((timezoneFilter) =>
    previewNextFilter(timezoneFilter, type),
  ) as MemberDataFilter[]

  return {
    options: timezones.map((timezone, index) => {
      const { representativeTzid } = timezone

      const offset =
        (dayjs().tz(representativeTzid).utcOffset() -
          dayjs().tz(communityLocalTimezone?.representativeTzid).utcOffset()) /
        60
      const sign = offset > 0 ? '+' : ''
      const difference = offset
        ? ` (${sign}${formatNumeral(offset, '0,0[.]0')} ${t('units.hours', {
            count: offset,
          })})`
        : ''

      return {
        label: `${t(`timezones.${timezone.timeZoneGroup}`)}${difference}`,
        filter: timezoneFilters[index],
      }
    }),
    counts: useCountByQueries({ filters: nextFilters, communicationChannel }),
  }
}
