import { WorkflowCard } from '@community_dev/flexible-canvas'
import { TABLET_BREAK, Typography } from '@community_dev/pixels'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transform: translate(0);
  flex-direction: column;
  display: flex;
`

export const StyledWorkflowSidebarContainer = styled.div`
  flex: 1 0 250px;
  max-width: 325px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-right: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  background: ${({ theme }) => theme?.COLORS?.WORKFLOW_EDITOR_SIDEBAR_BACKGROUND};
  z-index: 50;
  position: relative;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${TABLET_BREAK}) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
`

export const WorkflowWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  height: calc(100vh - 56px);
`

export const StyledModalHeading = styled(Typography).attrs({
  variant: 'h3',
  component: 'h2',
})`
  margin: 24px 0 8px;
  font-size: 16px;

  &:first-of-type {
    margin-top: 8px;
  }
`

export const StyledWorkflowCard = styled(WorkflowCard)`
  border: 1px solid ${({ theme }) => theme.COLORS.BORDERS};
  border-radius: 8px;
  margin-bottom: 11px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const RelativeWrapper = styled.div`
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WorkflowMoreButton = styled.button`
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  color: ${({ theme }) => theme.COLORS.TEXT};

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

export const WorkflowElseBubble = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 10px 7px 10px;
  width: 52px;
  height: 34px;
  background: ${({ theme }) => theme?.COLORS?.WORKFLOW_CARD_INFO_BACKGROUND};
  border: 1px solid ${({ theme }) => theme?.COLORS?.WORKFLOW_CARD_DEFAULT_BORDER};
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
`

export const StyledRadioLabel = styled.label`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`

export const StyledRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
`
