import {
  Avatar,
  BUTTON_VARIANTS,
  Box,
  Button,
  ChevronRightIcon,
  LoadingIndicator,
  SPACING,
  Typography,
} from '@community_dev/pixels'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router'
import styled, { css, useTheme } from 'styled-components'

import { ROUTES } from 'constants/routes'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useAuth } from 'hooks/useAuth'
import { useClientContexts, useSelectClient } from 'hooks/useClient'
import SimpleLayout from 'layouts/SimpleLayout'
import { formatPhone } from 'utils/normalize'

type StyledButtonProps = {
  $active?: boolean
}

const StyledButton = styled.button<StyledButtonProps>`
  width: 100%;
  border: none;
  background: none;
  padding: ${SPACING[4]} 0;
  text-align: left;
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  ${({ $active }) =>
    !$active &&
    css`
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    `}
`

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 -40px;

  li {
    padding: 0 40px;

    &:hover {
      background: ${({ theme }) => theme?.COLORS?.DEPRECATED_HOVER};
    }

    &:last-child ${StyledButton} {
      border: none;
    }
  }

  ${Avatar} {
    margin-right: ${SPACING[2]};
  }

  p {
    margin: 0;
  }
`

const StyledDetails = styled.div`
  flex-grow: 1;
`

const StyledName = styled.p`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
`

const StyledPhone = styled.p`
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};

  span {
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
    font-weight: 600;
    color: ${({ theme }) => theme?.COLORS?.LINKS};
  }
`

const StyledEmail = styled.p`
  margin-top: 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledLogout = styled.div`
  margin-top: ${SPACING[4]};
`

export function ClientSelection(): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const [clientSelected, setClientSelected] = React.useState<boolean>(false)
  const selectClientContext = useSelectClient()
  const { data: seat } = useSeat()
  const { data: clientContexts, isLoading } = useClientContexts()
  const { logout } = useAuth()

  useEffect(() => {
    if (!clientSelected && !isLoading && clientContexts?.length === 1) {
      setClientSelected(true)
      selectClientContext(clientContexts[0].clientId)
    }
  }, [clientSelected, clientContexts, isLoading, selectClientContext])

  if (isLoading || clientContexts?.length === 1 || clientSelected) {
    return <LoadingIndicator />
  }

  if (!clientContexts) {
    return <Redirect to={ROUTES.LOGOUT} />
  }

  return (
    <SimpleLayout>
      <Box>
        <Box.Content>
          <Typography variant="h1">Select Account</Typography>
          <StyledEmail>{seat?.email}</StyledEmail>
          <StyledList>
            {clientContexts.map((context) => (
              <li key={context.clientId}>
                <StyledButton
                  $active={context.isBillingActive}
                  onClick={() => {
                    setClientSelected(true)
                    selectClientContext(context.clientId)
                  }}
                  type="button"
                >
                  <Avatar name={context.name} src={context.clientImage} />
                  <StyledDetails>
                    <StyledName>{context.name}</StyledName>
                    {context.isBillingActive ? (
                      <StyledPhone>{formatPhone(context.phoneNumber)}</StyledPhone>
                    ) : (
                      <StyledPhone>
                        Inactive <span> {t('contactUs.contactUsButton')}</span>
                      </StyledPhone>
                    )}
                  </StyledDetails>
                  <ChevronRightIcon color={COLORS?.SUBTEXT} />
                </StyledButton>
              </li>
            ))}
          </StyledList>
        </Box.Content>
        <Box.Footer>
          <StyledLogout>
            <Button onClick={logout} variant={BUTTON_VARIANTS.OUTLINE}>
              Logout
            </Button>
          </StyledLogout>
        </Box.Footer>
      </Box>
    </SimpleLayout>
  )
}
