import styled from 'styled-components'

const StyledBody = styled.div`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_1};
  flex: 1;
  display: flex;
  justify-content: center;
`

export const MockBody = (): JSX.Element => (
  <StyledBody>
    <svg fill="none" viewBox="0 0 518 148" width="100%" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#EEEEEF" height="160" transform="translate(0 -12)" width="100%" />
      <rect fill="#C8C8C8" height="48" rx="24" width="48" x="40" y="20" />
      <rect fill="#C8C8C8" height="4" rx="2" width="64" x="40" y="80" />
      <rect fill="#C8C8C8" height="4" rx="2" width="40" x="40" y="96" />
      <rect fill="#C8C8C8" height="4" rx="2" width="40" x="40" y="112" />
      <rect fill="#C8C8C8" height="48" rx="24" width="48" x="196.667" y="20" />
      <rect fill="#C8C8C8" height="4" rx="2" width="40" x="196.667" y="80" />
      <rect fill="#C8C8C8" height="4" rx="2" width="64" x="196.667" y="96" />
      <rect fill="#C8C8C8" height="4" rx="2" width="40" x="196.667" y="112" />
      <rect fill="#C8C8C8" height="48" rx="24" width="48" x="353.333" y="20" />
      <rect fill="#C8C8C8" height="4" rx="2" width="40" x="353.333" y="80" />
      <rect fill="#C8C8C8" height="4" rx="2" width="40" x="353.333" y="96" />
      <rect fill="#C8C8C8" height="4" rx="2" width="64" x="353.333" y="112" />
    </svg>
  </StyledBody>
)
