import { SPACING } from '@community_dev/pixels'
import stubTrue from 'lodash/stubTrue'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Measure from 'react-measure'
import styled from 'styled-components'

import { useSearchState } from '../SearchProvider'

import { SearchChartLoadingIndicator } from './SearchChartLoadingIndicator'

import { Bucket } from 'api/search'
import { BarChart } from 'components/Charts'
import { DATE_INPUT_FORMAT } from 'constants/date'
import dayjs from 'utils/dayjs'

const StyledSearchBarChart = styled.div`
  padding-bottom: ${SPACING[4]};
`

const StyledBarChart = styled(BarChart)`
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 21px;
    width: 100%;
    border-bottom: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  }

  .bar-column {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const StyledBarLabel = styled.div`
  display: inline-block;
  width: 2px;
  text-align: center;
  overflow: visible;
  position: relative;
  height: ${SPACING[4]};

  div {
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
    color: ${({ theme }) => theme?.COLORS?.TEXT};
    position: absolute;
    transform: translate(-50%);
    white-space: nowrap;
  }
`

const StyledTooltip = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
  background-color: ${({ theme }) => theme?.COLORS?.TOOLTIP_BACKGROUND};

  span {
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  }
`

const BAR_CHART_MARGIN = parseInt(SPACING[4], 10)
const DAY_FORMAT = 'MMM D'
const HOUR_FORMAT = 'h|a'

function formatDate(date: string | number, format: string = DAY_FORMAT) {
  const value = dayjs(date).format(format)
  if (format === DAY_FORMAT) return value
  const parts = value.split('|')

  return Number(parts[0]) === 12 && parts[1] === 'pm' ? '12p' : parts[0]
}

export function SearchBarChart(): JSX.Element {
  const { t } = useTranslation()
  const { histogram, histogramIsLoading } = useSearchState()
  const [graphWidth, setGraphWidth] = useState(0)

  const { buckets, columnWidth, format, shouldDrawLabel } = useMemo(() => {
    if (!histogram) {
      return { buckets: [], shouldDrawLabel: stubTrue, columnWidth: 0 }
    }

    const buckets = histogram.buckets || []
    const length = buckets.length || 1
    const columnWidth = Math.floor((graphWidth - length) / length)
    const format = histogram.interval === '1h' ? HOUR_FORMAT : DAY_FORMAT

    // Get the max number of labels that look nice on the screen
    const maxLabels = Math.floor(graphWidth / 80)
    // if columns is <= maxLabels, draw every label
    // otherwise calculate the number of labels to draw
    const interval = Math.ceil(buckets.length / maxLabels)

    const shouldDrawLabel =
      length <= maxLabels || histogram.interval === '1h'
        ? stubTrue
        : (index) => index % interval === (Math.ceil(interval / 2) || 1)

    return { buckets, columnWidth, format, shouldDrawLabel }
  }, [histogram, graphWidth])

  if (histogramIsLoading) return <SearchChartLoadingIndicator />

  return (
    <StyledSearchBarChart>
      <Measure
        bounds
        onResize={({ bounds }) => {
          if (bounds) {
            setGraphWidth(bounds.width - BAR_CHART_MARGIN - BAR_CHART_MARGIN)
          }
        }}
      >
        {({ measureRef }) => (
          <StyledBarChart
            barBorderRadius={2}
            bars={
              histogram
                ? buckets.map((item: Bucket, index) => ({
                    label: shouldDrawLabel(index) ? (
                      <StyledBarLabel data-testid="bar-label">
                        <div>{formatDate(item.messageTimestamp, format)}</div>
                      </StyledBarLabel>
                    ) : undefined,
                    value: item.messagesCount,
                    tooltip: (
                      <StyledTooltip>
                        {item.messagesCount}
                        <span>{t('search.messagesAt')}</span>
                        {dayjs(item.messageTimestamp).format(histogram.interval === '1h' ? 'ha' : DATE_INPUT_FORMAT)}
                      </StyledTooltip>
                    ),
                    key: item.messageTimestamp.toString(),
                    color: 'rgba(30, 144, 255, 0.65)',
                  }))
                : []
            }
            columnGap={1}
            columnWidth={`${columnWidth}px`}
            height={200}
            ref={measureRef}
          />
        )}
      </Measure>
    </StyledSearchBarChart>
  )
}
