import { Manifest, ManifestFunction } from '@community_dev/workflow-manifest'
import { useParams } from 'react-router'

import { useWorkflowProvider } from '../context/WorkflowProvider'

export type UseWorkflowResult<T> = {
  manifest: Manifest
  functionKey: string
  functionIndex: number
  planItem?: T
}

/**
 * This hook pulls the functionIndex, functionKey, and manifest from the URL path
 * and then attempts to retrieve the plan step
 */
export function useManifestPlanItem<T extends ManifestFunction>(): UseWorkflowResult<T> {
  const { manifest } = useWorkflowProvider()
  const { functionKey, functionIndex } = useParams<{ functionKey: string; functionIndex: string }>()

  let planItem: T | undefined = undefined

  planItem = manifest?.functions?.[functionKey]?.[functionIndex] as T

  return {
    manifest,
    functionKey,
    functionIndex: Number(functionIndex),
    planItem,
  }
}
