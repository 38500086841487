import { useState } from 'react'
import Moment from 'react-moment/src/index'
import styled from 'styled-components'

import { Campaign, Media } from 'api/campaign'
import { ConvoBubble } from 'components/ConvoBubble'
import { DebugViewer } from 'components/DebugViewer'
import { useClient } from 'hooks/useClient'
import dayjs from 'utils/dayjs'

type TrendingConvoProps = {
  className?: string
  sentCampaign: Campaign
  cluster: any
}

const BUBBLE_OVERLAP = '24px'

const OutgoingColumn = styled.div`
  margin-left: -${BUBBLE_OVERLAP};
  text-align: right;
  min-width: 0;
  margin-left: auto;
  max-width: 400px;
  width: 100%;
`

const IncomingColumn = styled.div`
  text-align: left;
  min-width: 0;
  max-width: 400px;
  width: 100%;
`

const StyledColumnLabel = styled.div<{ $margin?: string }>`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  margin: ${({ $margin }) => $margin};
`

const StyledConvoBubble = styled(ConvoBubble)`
  div[data-testid='chat-bubble']:after {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }
`

const truncateTextWithEllipsis = (text, maxLen) =>
  text.length <= maxLen ? text : text.slice(0, maxLen - 3).trim() + '...'

// TODO
// - implement text truncation such that we never line break to more than
//   two lines
// - implement link parsing and "minilink" style link display
export const TrendingConvoGrid = ({ className, sentCampaign, cluster }: TrendingConvoProps): JSX.Element => {
  const [textOut, setTextOut] = useState('')
  const [textIncoming, setTextIncoming] = useState('')
  const [truncateLength, setTruncateLength] = useState(80)
  const showDebugInterface = window.location.search.includes('trendingDebugger=true')

  if (sentCampaign?.media?.height) {
    sentCampaign.media.height = null
  }

  return (
    <div className={className} style={{ textAlign: 'center' }}>
      <OutgoingColumn>
        <DisplayThisWithAvatarOnRight>
          <StyledConvoBubble
            body={textOut || sentCampaign.text || ''}
            id={sentCampaign.id}
            incoming={false}
            key={sentCampaign.id}
            last={true}
            linkPreviewType="mini"
            media={sentCampaign.media as unknown as Media}
            mediaMaxHeight={200}
            mediaMaxWidth={200}
            showTime={false}
            status={sentCampaign.status}
            tail={true}
            ts={dayjs(sentCampaign.scheduledAt).toString()}
          />
        </DisplayThisWithAvatarOnRight>
        <StyledColumnLabel $margin="0 0 5px 0">
          <Moment fromNow>{sentCampaign.createdAt}</Moment>
        </StyledColumnLabel>
      </OutgoingColumn>
      <IncomingColumn>
        <StyledColumnLabel $margin="0 0 8px 18px">{cluster.response_count} responses</StyledColumnLabel>
        <StyledConvoBubble
          body={truncateTextWithEllipsis(textIncoming || cluster.representative_text, truncateLength)}
          id={sentCampaign.id}
          incoming={true}
          key={sentCampaign.id}
          last={true}
          media={null}
          showTime={false}
          status={sentCampaign.status}
          tail={false}
          trending={true}
          ts={dayjs(sentCampaign.scheduledAt).toString()}
        />
      </IncomingColumn>

      {showDebugInterface && (
        <DebugInterface
          cluster={cluster}
          sentCampaign={sentCampaign}
          setTextIncoming={setTextIncoming}
          setTextOut={setTextOut}
          setTruncateLength={setTruncateLength}
          textIncoming={textIncoming}
          textOut={textOut}
          truncateLength={truncateLength}
        />
      )}
    </div>
  )
}

const StyledAvatarColContainer = styled.div`
  max-width: 100%;
  position: relative;
`

const StyledContentCol = styled.div`
  margin-right: 32px;
`

const StyledAvatarCol = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    border-radius: 50%;
    margin: 0;
    display: block;
  }
`

const DisplayThisWithAvatarOnRight = ({ children }) => {
  const { data: client } = useClient()
  const avatarImgSrc = client?.graphicUrlSmall

  return (
    <StyledAvatarColContainer>
      <StyledContentCol>{children}</StyledContentCol>
      <StyledAvatarCol>
        <img alt="user profile" height="32" src={avatarImgSrc} width="32" />
      </StyledAvatarCol>
    </StyledAvatarColContainer>
  )
}

const DebugInterface = ({
  cluster,
  sentCampaign,
  textOut,
  textIncoming,
  setTextIncoming,
  setTextOut,
  truncateLength,
  setTruncateLength,
}) => (
  <DebugViewer debugValue={undefined} title="Message overrides">
    <label>truncate chars</label>
    <div>
      <input onChange={(e) => setTruncateLength(+e.target.value)} type="number" value={truncateLength} />
    </div>
    <label>outgoing message</label>
    <div>
      <input
        onChange={(e) => setTextOut(e.target.value)}
        style={{ width: '100%' }}
        type="text"
        value={textOut || sentCampaign.text}
      />
    </div>
    <label>incoming message</label>
    <div>
      <input
        onChange={(e) => setTextIncoming(e.target.value)}
        style={{ width: '100%' }}
        type="text"
        value={textIncoming || cluster.representative_text}
      />
    </div>
  </DebugViewer>
)
