import { CommunityFlagIcon } from '@community_dev/pixels'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BestPracticeCard } from './BestPracticeCard'

import { useClient } from 'hooks/useClient'
import { ShareYourNumberModal } from 'screens/HomeScreen/screens/ProductEducation/ShareYourNumberCard/ShareYourNumberModal'

export const ShareYourNumberCard = (): JSX.Element => {
  const { data: client } = useClient()
  const { phone } = client || { phone: '' }
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation(undefined, { keyPrefix: 'growthTools.bestPractices' })

  return (
    <>
      <BestPracticeCard
        Icon={CommunityFlagIcon}
        body={t('shareYourNumber.body')}
        buttonText={t('buttonText')}
        dataTestId="share-your-number-card"
        header={phone}
        onClick={() => setIsOpen(true)}
        subHeader={t('shareYourNumber.subHeader')}
      />
      {isOpen ? <ShareYourNumberModal isOpen={isOpen} onClose={() => setIsOpen(false)} /> : null}
    </>
  )
}
