import { BUTTON_VARIANTS, Button, SPACING } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import {
  StyledRecipientItemAction,
  StyledRecipientItemDetail,
  StyledRecipientItemGraphic,
  StyledRecipientItemMain,
  StyledRecipientItemName,
  StyledRecipientItemOverview,
  StyledRecipientItemPreviewDetail,
  StyledRecipientItemPreviewGraphic,
  StyledRecipientItemPreviewMain,
  StyledRecipientItemPreviewName,
  StyledRecipientItemPreviewRoot,
  StyledRecipientItemPreviewSeeAll,
  StyledRecipientItemRoot,
} from './RecipientItem.style'

import { Fan } from 'api/fans'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { MemberAvatar } from 'components/MemberAvatar'

type RecipientItemProps = {
  preview?: boolean
  fan?: any
  seeAll?: boolean
  onSelectItem: (fan?: Fan) => any
}

class RecipientItem extends React.PureComponent<RecipientItemProps> {
  onClick = (): void => {
    const { fan, onSelectItem } = this.props
    if (fan && fan.id && onSelectItem) onSelectItem(fan)
  }

  onSeeAll = (): void => {
    this.props.onSelectItem()
  }

  renderSeeAll(): JSX.Element {
    return (
      <StyledRecipientItemPreviewRoot onClick={this.onSeeAll}>
        <StyledRecipientItemPreviewMain>
          <StyledRecipientItemPreviewGraphic>
            <StyledRecipientItemPreviewSeeAll>
              <FontAwesomeIcon icon="list-ul" />
            </StyledRecipientItemPreviewSeeAll>
          </StyledRecipientItemPreviewGraphic>
          <StyledRecipientItemPreviewDetail>
            <StyledRecipientItemPreviewName>See All</StyledRecipientItemPreviewName>
          </StyledRecipientItemPreviewDetail>
        </StyledRecipientItemPreviewMain>
      </StyledRecipientItemPreviewRoot>
    )
  }

  renderPreview(): JSX.Element {
    const { firstName, graphicUrl } = this.props.fan
    return (
      <StyledRecipientItemPreviewRoot onClick={this.onClick}>
        <StyledRecipientItemPreviewMain>
          <StyledRecipientItemPreviewGraphic>
            <MemberAvatar fan={this.props.fan} graphicUrl={graphicUrl} />
          </StyledRecipientItemPreviewGraphic>
          <StyledRecipientItemPreviewDetail>
            <StyledRecipientItemPreviewName data-private="redact">{firstName}</StyledRecipientItemPreviewName>
          </StyledRecipientItemPreviewDetail>
        </StyledRecipientItemPreviewMain>
      </StyledRecipientItemPreviewRoot>
    )
  }

  render(): JSX.Element {
    const { preview, fan, seeAll } = this.props
    if (seeAll) return this.renderSeeAll()
    if (preview) return this.renderPreview()
    const { fullName, graphicUrl, overview, communicationChannel } = fan

    return (
      <StyledRecipientItemRoot onClick={this.onClick}>
        <StyledRecipientItemMain>
          <StyledRecipientItemGraphic>
            <MemberAvatar
              badge={
                <CommunicationChannelIcon
                  communicationChannel={communicationChannel}
                  filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))"
                  size={19}
                  style={{ marginTop: SPACING[2] }}
                />
              }
              fan={fan}
              graphicUrl={graphicUrl}
            />
          </StyledRecipientItemGraphic>
          <StyledRecipientItemDetail>
            <StyledRecipientItemName data-private="redact">{fullName}</StyledRecipientItemName>
            <StyledRecipientItemOverview data-private="redact">{overview}</StyledRecipientItemOverview>
          </StyledRecipientItemDetail>
          <StyledRecipientItemAction>
            <Button variant={BUTTON_VARIANTS.OUTLINE}>View Conversation</Button>
          </StyledRecipientItemAction>
        </StyledRecipientItemMain>
      </StyledRecipientItemRoot>
    )
  }
}

export default RecipientItem
