import { WorkflowEditorCardTypes, WORKFLOW_CARDS } from '@community_dev/flexible-canvas'
import { useMessageBillingUsage } from '@community_dev/hooks'
import { CheckBox, Layout, SPACING } from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import {
  Action,
  addAction,
  WorkflowAction,
  WorkflowMedia,
  WorkflowTrigger,
  WorkflowEntrypoint as WorkflowEntrypointEnum,
} from '@community_dev/workflow-manifest'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { StyledLink, StyledLabel } from './WorkflowSidebarAddSms.style'

import { FileContext, FileRejectionDialog } from 'components/FileRejectionDialog'
import { MultimediaInput } from 'components/MultimediaInput'
import { WorkflowSidebarHeader } from 'components/WorkflowEditor/components/WorkflowSidebarHeader/WorkflowSidebarHeader'
import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { useMediaUpload } from 'components/WorkflowEditor/hooks/useMediaUpload'
import { ROUTES } from 'constants/routes'
import { BoundsProvider } from 'contexts/BoundsProvider'
import { useSettings } from 'contexts/SettingsProvider'
import { useUpload } from 'contexts/UploadProvider'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { useMediaConfig } from 'hooks/useMediaConfig'
import { useShortenableLinks } from 'hooks/useShortenableLinks'
import { useToastMessage } from 'hooks/useToastMessage'
import { CancelLink, DoneButton } from 'screens/WorkflowScreen/WorkflowScreen.style'
import { route } from 'utils/router'

const WorkflowCardConfig = WORKFLOW_CARDS[WorkflowEditorCardTypes.SEND_MESSAGE]

export type LocationState = {
  functionKey: string
  functionIndex: number
  logicIndex: number
}

export function formatMediaForFlow(media: Api.V2.Media | WorkflowMedia, disposition?: MediaDisposition): WorkflowMedia {
  return {
    disposition: disposition || media.disposition,
    byte_size: media.byte_size,
    filename: media.filename,
    height: media.height,
    id: media.id,
    mime_type: media.mime_type,
    thumbnail_filename: media.thumbnail_filename,
    thumbnail_url: media.thumbnail_url,
    url: media.url,
    width: media.width,
  }
}

export function WorkflowSidebarAddSms(): JSX.Element | null {
  const { t } = useTranslation()
  const history = useHistory<LocationState>()
  const currentTheme = useCurrentTheme()
  const { settings } = useSettings()
  const { COLORS } = useTheme()
  const { state } = history.location
  const { file, setFile } = useUpload()
  const communicationChannel = CommunicationChannel.SMS
  const { mediaUploadDispatch } = useWorkflowProvider()

  const { uploadMedia, isLoading: mediaUploadInProgress } = useMediaUpload()

  // ensure any media or uploads stored in the context are cleared when we close the sidebar
  useEffect(() => {
    return () => {
      setFile(undefined)
    }
  }, [])

  const { showToastMessage } = useToastMessage()

  const { workflowId } = useParams<{ workflowId: string }>()
  const { manifest, dispatch } = useWorkflowProvider()

  const isKeywordWorkflow = useMemo(
    () => manifest.entrypoints?.[WorkflowEntrypointEnum.keyword]?.type === (WorkflowTrigger.Keyword as string),
    [manifest],
  )

  const [messageText, setMessageText] = useState('')
  const [ignoreQuietHours, setIgnoreQuietHours] = useState(isKeywordWorkflow === true)

  const { accept, maxSize, mediaDisposition, setMediaDisposition, resetMediaDisposition } = useMediaConfig({
    communicationChannel,
    context: FileContext.COMPOSE,
  })

  const { links } = useShortenableLinks(messageText)
  const messageBillingUsage = useMessageBillingUsage({
    communicationChannel,
    isMediaAttached: file !== undefined,
    linksToShorten: links,
    mediaDisposition,
    message: messageText,
  })
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = messageBillingUsage
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }

  const onFileAccepted = (file: File) => {
    setFile(file)
  }

  const onFileRejected = useCallback(
    (fileRejection: FileRejection) => {
      setFile(fileRejection.file)
    },
    [setFile],
  )

  // if user attaches a file that is not supported by MMS
  // and they choose to instead send the message as SMS
  // set file and temporarily override media disposition
  const handleContinue = () => {
    setMediaDisposition(MediaDisposition.LINK)
  }

  if (state === undefined || typeof state.functionIndex !== 'number') {
    history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
    return null
  }

  return (
    <BoundsProvider>
      <Layout display="flex" flexDirection="column" height="100%" width="100%">
        <Layout marginTop={SPACING[2]} padding={SPACING[4]}>
          <WorkflowSidebarHeader
            workflowAction={{
              ...WorkflowCardConfig,
              subtitle: t('automations.createCustomMessage'),
            }}
          />
        </Layout>
        <Layout flex="1" padding={SPACING[4]}>
          <MultimediaInput
            accept={accept}
            emojiSkinTone={settings.skinTone}
            emojiTheme={currentTheme.type}
            gallery={true}
            gif={false}
            maxSize={maxSize}
            media={{ file, width: 100 }}
            onChange={(e) => setMessageText(e.target.value)}
            onEmojiSelect={(_, value) => setMessageText(value)}
            onFileAccepted={onFileAccepted}
            onFileRejected={onFileRejected}
            onRemoveMedia={() => {
              setFile(undefined)
              resetMediaDisposition()
            }}
            usageCounterProps={usageCounterProps}
            value={messageText}
          />

          <FileRejectionDialog
            communicationChannel={communicationChannel}
            context={FileContext.COMPOSE}
            file={file}
            mediaDisposition={mediaDisposition}
            onClose={() => {
              setFile(undefined)
              resetMediaDisposition()
            }}
            onContinue={handleContinue}
          />

          <Layout alignItems="flex-start" display="flex" marginTop="12px">
            <CheckBox
              id="ignore-quiet-hours-checkbox"
              onChange={(checked) => setIgnoreQuietHours(!checked)}
              selected={!ignoreQuietHours}
              style={{
                marginRight: 6,
                marginTop: 4,
              }}
            />
            <Layout lineHeight="1.2">
              <StyledLabel htmlFor="ignore-quiet-hours-checkbox" style={{ marginRight: 6 }}>
                {t('automations.doNotSendDuringQuietHours')}
              </StyledLabel>{' '}
              <StyledLink
                href="https://incommunity.zendesk.com/hc/en-us/articles/6029475630227-Quiet-Hours-Learn-More"
                rel="noreferrer"
                target="_blank"
              >
                {t('learnMore')}
              </StyledLink>
            </Layout>
          </Layout>
        </Layout>

        <Layout
          alignItems="center"
          borderTop={`1px solid ${COLORS?.BORDERS}`}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding="45px 16px"
        >
          <DoneButton
            disabled={mediaUploadInProgress || (!file && (!messageText || messageBillingUsage.error))}
            onClick={async () => {
              let media: Api.V2.Media | undefined

              if (file) {
                media = await uploadMedia(file)

                mediaUploadDispatch({
                  type: 'update',
                  media: media,
                  file,
                })
              }

              const sendMessageAction: Action = {
                type: WorkflowAction.SendMessage,
                params: {
                  text: messageText || null,
                  ignore_quiet_hours: ignoreQuietHours,
                },
              }

              if (media) {
                sendMessageAction.params.media = media ? [formatMediaForFlow(media, mediaDisposition)] : null
              }

              const { manifest: nextManifest } = addAction(
                manifest,
                sendMessageAction,
                state.functionKey,
                state.functionIndex,
                state.logicIndex,
              )

              try {
                dispatch({
                  type: 'update',
                  state: {
                    manifest: nextManifest,
                  },
                })

                history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
              } catch (e) {
                showToastMessage({
                  message: (e as Error).message,
                  success: false,
                })
              }
            }}
            type="button"
          >
            {t('done')}
          </DoneButton>
          <CancelLink style={{ marginTop: SPACING[5] }} to={route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId })}>
            {t('cancel')}
          </CancelLink>
        </Layout>
      </Layout>
    </BoundsProvider>
  )
}
