import React from 'react'
import { MemoryRouter, Route, Switch, Redirect } from 'react-router'

import { MemberDetailActionPanel } from './MemberDetailActionPanel'

import { Community } from 'api/community'
import { Fan } from 'api/fans'
import { AddToCommunityActionPanel } from 'components/ActionPanel/AddToCommunityActionPanel'
import { ViewAllCMDActionPanel } from 'components/ActionPanel/ViewAllCMDActionPanel/ViewAllCMDActionPanel'
import { ROUTES } from 'constants/routes'

type MemberDetailActionPanelProps = {
  communities?: Community[]
  fan?: Fan
  onBack?: () => void
}

export const MemberDetailActionPanelRouter = React.memo(
  ({ communities = [], fan, onBack }: MemberDetailActionPanelProps): JSX.Element | null => {
    return (
      <MemoryRouter>
        <Switch>
          <Route exact path={ROUTES.MEMBER_DETAIL_ACTION_PANEL.ROOT}>
            <MemberDetailActionPanel communities={communities} fanId={fan?.id} onBack={onBack} />
          </Route>
          <Route
            component={({ history }) => (
              <AddToCommunityActionPanel
                fan={fan}
                onBack={() => history.push(ROUTES.MEMBER_DETAIL_ACTION_PANEL.ROOT)}
              />
            )}
            exact
            path={ROUTES.MEMBER_DETAIL_ACTION_PANEL.ADD_TO_COMMUNITY}
          />
          <Route
            component={({ history }) => (
              <ViewAllCMDActionPanel fan={fan} onBack={() => history.push(ROUTES.MEMBER_DETAIL_ACTION_PANEL.ROOT)} />
            )}
            exact
            path={ROUTES.MEMBER_DETAIL_ACTION_PANEL.VIEW_ALL_CMD}
          />
          <Redirect to={ROUTES.MEMBER_DETAIL_ACTION_PANEL.ROOT} />
        </Switch>
      </MemoryRouter>
    )
  },
)
