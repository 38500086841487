import { SPACING, Typography } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'

import IconWebhooks from './components/Icons/icon-webhooks.svg?react'
import { useWebhooks } from './queries'

import { BetaBadge } from 'components/BetaBadge'
import { ROUTES } from 'constants/routes'
import { useClientId } from 'hooks/useClient'
import { Setting } from 'layouts/SettingsLayout'

export function Webhooks(): JSX.Element {
  const clientId = useClientId()
  const { data: webhooks = [], isInitialLoading } = useWebhooks(clientId)
  const { t } = useTranslation()
  const history = useHistory()
  const { COLORS } = useTheme()
  return (
    <Setting
      action={'Configure'}
      icon={<IconWebhooks />}
      label={
        <>
          Webhooks <BetaBadge />
        </>
      }
      loading={isInitialLoading}
      onClickAction={() => history.push(ROUTES.SETTINGS.INTEGRATIONS.WEBHOOKS)}
      text={
        <>
          <Typography color={COLORS.SUBTEXT} marginTop={SPACING[0]} variant="body2">
            {t('settings.integrations.webhooks.description')}{' '}
            {webhooks.length > 0 &&
              `(${t('settings.integrations.webhooks.status', {
                total: webhooks.length,
                active: webhooks.reduce((m, w) => (w.enabled ? m + 1 : m), 0),
              })})`}
          </Typography>
        </>
      }
    />
  )
}
