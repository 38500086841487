import { DigitsFilter } from '@community_dev/filter-dsl/lib/digits'
import {
  BuiltInFields,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorOperators,
  fieldLabelFor,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { Campaign } from './campaign'
import { Fan } from './fans'

import { ENDPOINTS } from 'constants/endpoints'
import { ApiResponse } from 'types/api'
import { request } from 'utils/api'
import { route } from 'utils/router'

const getSorters = (sorted) =>
  sorted.reduce((acc, cur) => {
    let field = cur.id
    let descending = cur.desc
    if (field === 'messagesTo') field = 'messages_to'
    if (field === 'messagesFrom') field = 'messages_from'
    if (['messages_to', 'messages_from'].includes(field)) descending = !descending
    const direction = descending ? 'descending' : 'ascending'
    acc[field] = { direction }
    return acc
  }, {})

export const postIndexByQuery = ({
  page = 1,
  filters = {},
  communicationChannel = CommunicationChannel.SMS,
  sorters = [],
  tags = [],
}: {
  page?: number
  filters?: DigitsFilter
  communicationChannel?: CommunicationChannel
  sorters?: { id: string; desc: boolean }[]
  tags?: string[]
}): Promise<CamelCasedPropertiesDeep<Api.V2.Pagination<Api.Fan>>> => {
  const endpoint = route(
    ENDPOINTS.FANS.INDEX_BY_QUERY,
    {},
    { communication_channel: communicationChannel, page_number: page, page_size: 100 },
  )
  const body = { filters, sorters: getSorters(sorters), tags }

  return request(endpoint, {
    body,
    method: 'POST',
  })
}

export const postAssignByQuery = ({
  id,
  clientId,
  filters,
  fanSubscriptionTags: fan_subscription_tags,
  communicationChannel,
}: {
  id: string
  clientId: string
  filters: DigitsFilter | MemberDataFilter
  fanSubscriptionTags: Campaign['fanSubscriptionTags'] | []
  communicationChannel: CommunicationChannel
}): Promise<{
  message: string
  job_id: string
}> => {
  return request(
    route(ENDPOINTS.ASSIGN_BY_QUERY, { tagId: id, clientId }, { communication_channel: communicationChannel }),
    {
      body: { filters, fan_subscription_tags },
      method: HTTP.POST,
    },
  )
}

export const postUnassignByQuery = ({
  id,
  clientId,
  filters,
  fanSubscriptionTags: fan_subscription_tags,
  communicationChannel,
}: {
  id: string
  clientId: string
  filters: DigitsFilter | MemberDataFilter
  fanSubscriptionTags: Campaign['fanSubscriptionTags'] | []
  communicationChannel: CommunicationChannel
}): Promise<{
  message: string
  job_id: string
}> => {
  return request(
    route(ENDPOINTS.UNASSIGN_BY_QUERY, { tagId: id, clientId }, { communication_channel: communicationChannel }),
    {
      body: { filters, fan_subscription_tags },
      method: HTTP.POST,
    },
  )
}

export type Community = CamelCasedPropertiesDeep<Api.Community>
export type CommunityById = CamelCasedPropertiesDeep<Api.CommunityById>

export enum ProtectedCommunityNames {
  SHOPIFY = 'Shopify',
  WEB_VISITORS = 'Web Visitors',
}

export type Communities = {
  default: Community[]
  custom: Community[]
  protected: Community[]
  protectedAmb: Community[]
  all: Community[]
}

export type GetCommunitiesArgs = {
  clientId: string
}

export const getCommunities = ({ clientId }: GetCommunitiesArgs): Promise<Communities> => {
  return request(route(ENDPOINTS.TAGS, { clientId })).then(({ data }) => {
    // protectedAmb is a special case, it's still a protected community but it's not a marked as "protected" in the backend
    data.protectedAmb = data.protectedAmb?.map((community) => ({ ...community, type: 'protected' })) || []
    data.protected = [...data.protected, ...data.protectedAmb]

    const all = [...data.default, ...data.protected, ...data.custom]

    return { ...data, all }
  })
}

export const getAllCommunities = ({ clientId }: GetCommunitiesArgs): Promise<Communities> => {
  return request(route(ENDPOINTS.TAG.LIST, { clientId })).then(({ data }) => {
    // protectedAmb is a special case, it's still a protected community but it's not a marked as "protected" in the backend
    data.protectedAmb = data.protectedAmb?.map((community) => ({ ...community, type: 'protected' })) || []
    data.protected = [...data.protected, ...data.protectedAmb]

    const all = [...data.default, ...data.protected, ...data.custom]

    return { ...data, all }
  })
}

export type GetCommunityArgs = {
  clientId: string
  id: string
}

export function getCommunity({ clientId, id }: GetCommunityArgs): Promise<ApiResponse<CommunityById>> {
  return request(route(ENDPOINTS.TAG.TAG, { clientId, tagId: id }))
}

export type CreateCommunityProps = {
  clientId: string
  title: string
  description?: string
  color: string
}

export function postCreateCommunity({ clientId, ...values }: CreateCommunityProps): Promise<Community> {
  return request(route(ENDPOINTS.TAGS, { clientId }), {
    body: values,
    method: HTTP.POST,
  }).then(({ data }) => data)
}

export type UpdateCommunityProps = {
  clientId: string
  id: string
  title: string
  description?: string
  color: string
}

export function putUpdateCommunity({ clientId, id, ...values }: UpdateCommunityProps): Promise<Community> {
  return request(route(ENDPOINTS.TAG.TAG, { clientId, tagId: id }), {
    body: values,
    method: HTTP.PUT,
  }).then(({ data }) => data)
}

export type AddCommunityFansProps = {
  community: Community
  fans: Fan[]
}

export function addCommunityFans({ community, fans }: AddCommunityFansProps): Promise<any> {
  return request(route(ENDPOINTS.TAG.POST_FAN, { tagId: community.id }), {
    method: HTTP.POST,
    body: { fan_ids: fans.map((fan) => fan.id) },
  })
}

export type AddCommunityFanProps = {
  community: Community
  fan: Fan
}

export function addCommunityFan({ community, fan }: AddCommunityFanProps): Promise<any> {
  return addCommunityFans({ community, fans: [fan] })
}

export type RemoveCommunityFanProps = {
  clientId: string
  fan: Fan
  community: Community
}

export function deleteCommunityFan({
  clientId,
  fan,
  community,
}: RemoveCommunityFanProps): Promise<{ message: string; job_id: string }> {
  return postUnassignByQuery({
    id: community.id,
    clientId,
    filters: {
      subscription_data: {
        operator: SelectorOperators.EQUALS,
        operand: {
          field_key: BuiltInFields.SUBSCRIPTION_ID,
          field_label: fieldLabelFor(BuiltInFields.SUBSCRIPTION_ID),
          source: FieldSources.BUILT_IN,
          type: FieldTypes.UUID,
          value: fan.fanSubscriptionId,
        },
      },
    },
    fanSubscriptionTags: {
      any: [],
      not: [],
    },
    communicationChannel: fan.communicationChannel,
  })
}

export type DeleteCommunityProps = {
  clientId: string
  id: string
}

export function deleteCommunity({ clientId, id }: DeleteCommunityProps): Promise<string[]> {
  return request(route(ENDPOINTS.TAG.TAG, { clientId, tagId: id }), {
    method: HTTP.DELETE,
    responseMethod: 'text',
  })
}
