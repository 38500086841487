import { SPACING } from '@community_dev/pixels'
import first from 'lodash/fp/first'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { MOCKED_ACTIVE_SUBSCRIPTIONS, TICK_NUM_MEMBER_GROWTH } from '../constants'
import { TimeRange } from '../InsightsMasthead'
import { LoadingHeader } from '../LoadingHeader'

import { NewMemberDiff } from './NewMembersDiff'
import { formatDateFns, tooltipFormatFns, withLatestDataFns } from './utils'

import { MemberCount, getMemberGrowthOverTime } from 'api/insights'
import { DataWrapper } from 'components/DataWrapper'
import LineChart from 'components/LineChart/LineChart'
import { MeasuredContainer } from 'components/MeasuredContainer'
import { QUERY_CACHE } from 'constants/query-cache'
import { ErrorMessage } from 'screens/InsightsScreen/presentation/InsightsBox'

const isDataWrapperEnabled = (isMocked: boolean, locked: boolean): boolean => {
  // we allow request since we are mocking the response
  if (isMocked) return true
  // we dont allow when locked because we will just get a 403
  if (locked) return false
  // if we have the capability we make the request
  return true
}

const showMockedMembersWhen = (isMocked: boolean, locked: boolean): boolean => {
  // we only show the mocked members when we are mocking the response and have the capability
  // this prevents showing the mock total members when they dont have the capability
  if (isMocked && !locked) return true
  return false
}

const showLineChartWhen = (activeSubscription: number): boolean => {
  // if there are no members, we dont show the graph
  return activeSubscription > 0
}

const ContactCount = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  margin: 0;
`

const StyledMeasuredContainer = styled(MeasuredContainer)`
  flex-direction: column;
`

const StyledErrorMessage = styled(ErrorMessage)`
  margin: ${SPACING[6]} 0;
`

type TotalMemberGrowthChartProps = {
  clientId: string
  isMocked: boolean
  isInsightsLoading: boolean
  timeRange: TimeRange
  timezone: string
  locked: boolean
  activeSubscriptions: number
  onResizeHeaderHeight: (height: number) => void
}

export const TotalMemberGrowthChart = ({
  clientId,
  isMocked,
  isInsightsLoading,
  timeRange,
  timezone,
  locked,
  activeSubscriptions,
  onResizeHeaderHeight,
}: TotalMemberGrowthChartProps): JSX.Element => {
  const { t } = useTranslation()
  async function queryGrowth({ timeRange }) {
    const data = await getMemberGrowthOverTime({
      clientId,
      timeRange,
      timezone,
      mocked: isMocked,
    })

    return data.data
  }

  queryGrowth.QueryKey = QUERY_CACHE.INSIGHTS.GROWTH_OVER_TIME

  return (
    <DataWrapper<MemberCount[]>
      enabled={isDataWrapperEnabled(isMocked, locked)}
      params={{ timeRange }}
      queryFn={queryGrowth}
    >
      {({ data, isInitialLoading, isError }) => {
        const currentSubscriptions = isMocked ? MOCKED_ACTIVE_SUBSCRIPTIONS : activeSubscriptions
        const combinedData = data ? withLatestDataFns[timeRange](data, currentSubscriptions) : data
        const firstItem = first(data)
        const delta = firstItem ? currentSubscriptions - firstItem.count : 0

        if (isError) {
          return <StyledErrorMessage msg={t('failedToLoadData')} />
        }

        return (
          <>
            {isInsightsLoading || isInitialLoading ? (
              <LoadingHeader />
            ) : (
              <StyledMeasuredContainer
                onResize={({ height }) => {
                  onResizeHeaderHeight(height)
                }}
              >
                <ContactCount>
                  {showMockedMembersWhen(isMocked, locked)
                    ? MOCKED_ACTIVE_SUBSCRIPTIONS.toLocaleString('en-US')
                    : activeSubscriptions.toLocaleString('en-US')}
                </ContactCount>
                <NewMemberDiff
                  currentSubscriptions={currentSubscriptions}
                  delta={delta}
                  locked={locked}
                  timeRange={timeRange}
                />
              </StyledMeasuredContainer>
            )}

            {showLineChartWhen(currentSubscriptions) && (
              <LineChart
                bottomNumTicks={TICK_NUM_MEMBER_GROWTH[timeRange]}
                data={combinedData}
                formatTooltipValue={tooltipFormatFns[timeRange]}
                formatXAxis={formatDateFns[timeRange]}
                isLoading={isInitialLoading}
              />
            )}
          </>
        )
      }}
    </DataWrapper>
  )
}
