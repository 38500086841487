import { Block, LockIcon, SPACING, Tooltip } from '@community_dev/pixels'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { LabelList, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import styled, { useTheme } from 'styled-components'

import { LinkPreview } from '../LinkPreview'

import { PLAN_COLOR } from 'constants/plans'
import { ROUTES } from 'constants/routes'
import { useTrackBilling } from 'hooks/billing/useTrackBilling'
import events from 'utils/analytics/events'

const toMetricLabelStyle = (COLORS) => ({
  fill: COLORS?.SUBTEXT,
  fontSize: 12,
})

const data = [
  { totalCount: 100, count: 4, tick: '0m' },
  { totalCount: 100, count: 32, tick: '1m' },
  { totalCount: 100, count: 41, tick: '2m' },
  { totalCount: 100, count: 47, tick: '3m' },
  { totalCount: 100, count: 50, tick: '4m' },
  { totalCount: 100, count: 53, tick: '5m' },
  { totalCount: 100, count: 55, tick: '6m' },
  { totalCount: 100, count: 56, tick: '7m' },
  { totalCount: 100, count: 56, tick: '8m' },
  { totalCount: 100, count: 57, tick: '9m' },
  { totalCount: 100, count: 57, tick: '10m' },
]

const StyledContainer = styled.div`
  position: relative;
  // Blur the whole chart as a fallback for browsers that don't support backdrop-filter
  filter: blur(6px);
  @supports (backdrop-filter: blur(1px)) {
    filter: none;
  }
`

const StyledBlur = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  // These values come from recharts, we're only blurring the line.
  bottom: 32px;
  left: 36px;
  backdrop-filter: blur(6px);
`

const StyledTooltipHeader = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
`

const StyledContent = styled.div`
  padding: ${SPACING[2]};
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const StyledPlanName = styled.span`
  color: ${PLAN_COLOR['essentials']};
`

const TooltipContent = () => (
  <StyledContent>
    <StyledTooltipHeader>✨ Unlock Link &amp; Media Tracking</StyledTooltipHeader>
    <p>Upgrade to track campaign performance and audience engagement</p>
  </StyledContent>
)

export const TeaserChart = (): JSX.Element | null => {
  const { COLORS } = useTheme() || {}
  const track = useTrackBilling()
  const metricLabelStyle = useMemo(() => toMetricLabelStyle(COLORS), [COLORS])

  return (
    <Tooltip content={<TooltipContent />} placement="top-start">
      <StyledLink
        onClick={() => track(events.FeatureHoldPrompt({ featureName: 'link tracking' }))}
        to={ROUTES.PRICING.CONTACT_US}
      >
        <Block
          title={
            <>
              Link Tracking <LockIcon />
            </>
          }
        >
          <LinkPreview
            metric={{
              title: (
                <>
                  Available on <StyledPlanName>Essentials</StyledPlanName> plan
                </>
              ),
              subtitle: 'Measure link & media engagement',
            }}
          />

          <StyledContainer>
            <ResponsiveContainer height={200} width="100%">
              <LineChart data={data} margin={{ top: 16, right: 16, left: -16, bottom: 0 }}>
                <XAxis dataKey="tick" interval={1} stroke={COLORS?.BORDERS} tick={metricLabelStyle} />
                <YAxis
                  axisLine={false}
                  domain={[0, 'dataMax']}
                  tick={metricLabelStyle}
                  tickLine={false}
                  ticks={[0, 25, 50, 75, 100]}
                  unit="%"
                />
                <Line
                  dataKey={({ count, lastCount }) => count ?? lastCount}
                  isAnimationActive={false}
                  stroke={COLORS?.LINKS}
                  strokeDasharray="2 2"
                  strokeWidth={1}
                  type="monotone"
                >
                  <LabelList
                    dataKey="tick"
                    formatter={(label) => (label === 'now' ? 'now' : null)}
                    offset={7}
                    position="top"
                  />
                </Line>
                <Line
                  dataKey="count"
                  dot={false}
                  isAnimationActive={false}
                  stroke={COLORS?.LINKS}
                  strokeWidth={2}
                  type="monotone"
                />
              </LineChart>
            </ResponsiveContainer>
            <StyledBlur />
          </StyledContainer>
        </Block>
      </StyledLink>
    </Tooltip>
  )
}
