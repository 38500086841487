import { useEffect, useRef } from 'react'

import { useIncludeExcludeContext } from 'contexts/IncludeExcludeProvider'
import { Selection } from 'contexts/IncludeExcludeProvider/IncludeExcludeState'

// <Checkbox />
//
// A very thin wrapper around the native <input type="checkbox" />
// element that allows for the native `indeterminate` attribute to be set
// declaratively via the indeterminate prop, mirroring the behavior of the
// `checked` attribute. It does nothing else to alter the behavior or API
// of the standard <input type="checkbox" /> element.
export const Checkbox = ({ indeterminate, ...props }: any): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!inputRef.current) return
    inputRef.current.indeterminate = !!indeterminate
  }, [inputRef, indeterminate])

  return <input ref={inputRef} type="checkbox" {...props} />
}

type IncludeExcludeCheckboxProps = {
  keyPath: any[]
  itemType: string
  type: string
  name: string
  repliedTo: boolean
  disabled?: boolean
}

// <IncludeExcludeCheckbox />
//
// This is a companion component to the IncludeExcludeProvider
// and IncludeExcludeState APIs. It must be rendered as a child of an
// IncludeExcludeProvider and passed a keyPath array, and optional itemType,
// as props. It will render a checkbox that calls the toggle function given by
// the IncludeExcludeProvider with the appropriate arguments when toggled, and
// drives its UI state directly based on the selection state reported by the
// IncludeExcludeProvider for the given keyPath.
//
// Keypath examples:
// Item with no parents: [fanSubId],
// Item with one parent: [clusterId, fanSubId]
//
// Note - due to the way that compose include/exclude filters are currently
// implemented, this component MUST be provided with an itemType when used
// in the context of selecting or excluding messages and fan subscriptions
// for a message send. This will be enforced by the IncludeExcludeProvider
// whenever the IncludeExcludeState.requireType field is set to `true`.
// An error message will be thrown if you forget to provide an itemType when
// required.
export const IncludeExcludeCheckbox = ({
  keyPath,
  itemType,
  repliedTo,
  disabled,
  ...props
}: IncludeExcludeCheckboxProps): JSX.Element => {
  const { isSelected, toggleSelected } = useIncludeExcludeContext()
  const selectionState = isSelected(keyPath)

  // For now, deciding that we don't need a disabled checked checkbox
  // This is for apple messaging, we could be in state that the user selects all but some might not have the can_receive_campaigns state flag
  // The server will account for this, so we don't want to show the selected state
  const checked = selectionState === Selection.SELECTED && !disabled

  return (
    <Checkbox
      {...props}
      checked={checked}
      disabled={disabled}
      indeterminate={selectionState === Selection.PARTIAL}
      onChange={() => toggleSelected(keyPath, itemType, repliedTo)}
    />
  )
}
