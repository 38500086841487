import {
  Button,
  CommunitiesIcon,
  ConversationIcon,
  FONT_SIZE,
  MembersIcon,
  MessagesIcon,
  ProgressBar,
  SPACING,
  Typography,
} from '@community_dev/pixels'
import clamp from 'lodash/clamp'
import isNumber from 'lodash/isNumber'
import React from 'react'
import styled from 'styled-components'

import { MessagesLimits as MessagesLimitsConstants } from 'constants/messages'
import { useMessagesLimits } from 'hooks/useMessagesLimits'
import analytics from 'utils/analytics'
import { pluralizeNumeral } from 'utils/general'

const SUPPORT_EMAIL = 'yourfriends@community.com'
const SUPPORT_SUBJECT = 'Questions about my Monthly Message Limit'
const SUPPORT_URL = 'https://incommunity.zendesk.com/hc/en-us/articles/1500002586481-Monthly-Message-Limits'
const MAIL_LINK = `mailto:${SUPPORT_EMAIL}?subject=${SUPPORT_SUBJECT}`

const StyledRoot = styled.div`
  margin-left: 160px;
  padding-bottom: ${SPACING[5]};
`

const StyledContent = styled.div`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  max-width: 455px;
  width: 100%;
  flex-direction: column;
  display: flex;
  padding: 0px 16px;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
`

const StyledIcon = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme?.COLORS?.DEPRECATED_SELECTED};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  margin-right: ${SPACING[4]};
`

const StyledVerticalAlign = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const StyledHeading = styled.h2`
  margin-bottom: 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  font-size: ${FONT_SIZE[5]};
`

const StyledBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${SPACING[4]};
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const StyledContactButton = styled(Button)`
  margin-top: ${SPACING[4]};
  margin-bottom: ${SPACING[1]};
  border-radius: 25px;
  width: 305px;
  padding-top: ${SPACING[4]};
  padding-bottom: ${SPACING[4]};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
`

const StyledLimitBlockList = styled.div`
  margin-top: ${SPACING[4]};
`

const StyledProgressBox = styled.div`
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  padding: ${SPACING[5]} ${SPACING[4]};
  border-raidus: 12px;
`

const icons = {
  messages: <MessagesIcon size={30} />,
  members: <MembersIcon size={30} />,
  communities: <CommunitiesIcon size={30} />,
  conversation: <ConversationIcon size={20} />,
}

const Icon = ({ type }) => {
  const iconSVG = icons[type]

  return (
    <StyledIcon>
      <StyledVerticalAlign>{iconSVG}</StyledVerticalAlign>
    </StyledIcon>
  )
}

const LimitBlock = ({ icon, children }) => {
  return (
    <StyledBlock>
      <Icon type={icon} />
      <div>{children}</div>
    </StyledBlock>
  )
}

const MessagesProgressBar = ({ percentageUsed, monthlyUsed, monthlyRemaining }) => {
  const messagesSent = pluralizeNumeral(monthlyUsed, '0,0', 'sent', 'sent')
  const messagesRemaining = pluralizeNumeral(monthlyRemaining, '0,0', 'left', 'left')

  const progressBarPercent = clamp(percentageUsed, 0, 100)

  return (
    <StyledProgressBox>
      <ProgressBar leftLabel={messagesSent} percentFilled={progressBarPercent} rightLabel={messagesRemaining} />
    </StyledProgressBox>
  )
}

export const MessagesLimits = (): JSX.Element => {
  const { monthlyRemaining, monthlyUsed, percentageUsed, hasLimitReached } = useMessagesLimits({})

  const handleContactButton = () => {
    analytics.track(analytics.events.MessageLimitsContactUsClicked())
  }

  return (
    <StyledRoot>
      <StyledContent>
        <Typography variant="h1">Monthly Message Limit</Typography>
        <MessagesProgressBar
          monthlyRemaining={monthlyRemaining}
          monthlyUsed={monthlyUsed}
          percentageUsed={percentageUsed}
        />

        {hasLimitReached ? (
          <StyledHeading>Message up to {MessagesLimitsConstants.THROTTLED_LIMIT_TEXT} recipients</StyledHeading>
        ) : (
          <StyledHeading>
            {isNumber(monthlyRemaining) && pluralizeNumeral(monthlyRemaining.toString(), '0,0', 'message', 'messages')}{' '}
            left this month
          </StyledHeading>
        )}
        <p>
          Your Monthly Message Limit will reset on the first of the month.{' '}
          <StyledLink href={SUPPORT_URL} target="_blank">
            Learn More
          </StyledLink>
        </p>
        <StyledHeading>When my limit is reached, what can I still do?</StyledHeading>
        <StyledLimitBlockList>
          <LimitBlock icon="messages">
            Send messages up to {MessagesLimitsConstants.THROTTLED_LIMIT_TEXT} Members
          </LimitBlock>
          <LimitBlock icon="members">Respond to direct messages</LimitBlock>
          <LimitBlock icon="conversation">Respond to clusters</LimitBlock>
        </StyledLimitBlockList>
        <StyledHeading>Why did I reach my limit?</StyledHeading>
        <p>
          Only messages to more than {MessagesLimitsConstants.THROTTLED_LIMIT_TEXT} recipients will count against the
          limit. Messages to {MessagesLimitsConstants.THROTTLED_LIMIT_TEXT} recipients or less, direct messages, and
          cluster replies of any size are always allowed.
        </p>
        <StyledHeading>Get help</StyledHeading>
        <p>
          Contact your partnership manager or email us at{' '}
          <StyledLink href={MAIL_LINK}>yourfriends@community.com</StyledLink> so we can help you increase your limit and
          remain connected with your Community Members.
        </p>
        <StyledContactButton forwardedAs="a" href={MAIL_LINK} onClick={handleContactButton}>
          Contact us
        </StyledContactButton>
      </StyledContent>
    </StyledRoot>
  )
}
