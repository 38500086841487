import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from 'languages/en.json'

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'en'
    resources: {
      en: typeof en
    }
  }
}

function initialize(): any {
  return i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: en,
        },
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    })
}

export { i18n, initialize }
