import { MemberDataFilter } from '@community_dev/filter-dsl/lib/subscription-data'
import { SPACING, SuggestionCard as SuggestionCardComponent } from '@community_dev/pixels'
import { useState } from 'react'
import styled from 'styled-components'

import { SuggestionCard } from 'api/message'
import { ComposeMessage } from 'components/ComposeMessage'
import { SuggestionCardTemplate, SuggestionCardTemplateTypes } from 'utils/suggestion-helpers'

const StyledSuggestions = styled.ul`
  padding: 0;
  list-style: none;

  li {
    margin-bottom: ${SPACING[2]};
  }
`

type SuggestionCardsProps = {
  items: SuggestionCardTemplate[]
}

export default function SuggestionCards({ items }: SuggestionCardsProps): JSX.Element {
  const [filters, setFilters] = useState<MemberDataFilter | null>(null)

  const [isComposeModalOpen, setIsComposeModalOpen] = useState(false)
  const [composeMessage, setComposeMessage] = useState('')
  const [card, setCard] = useState<SuggestionCard | undefined>(undefined)

  return (
    <>
      <StyledSuggestions data-testid="suggestion-cards">
        {items.map(({ icon, title, subtitle, onClick, type, composeMessage }) => (
          <li key={`${title}${subtitle}`}>
            <SuggestionCardComponent
              ellipsis={true}
              icon={icon}
              onClick={() => {
                onClick(setFilters)
                if (
                  composeMessage ||
                  type === SuggestionCardTemplateTypes.COMPOSE ||
                  type === SuggestionCardTemplateTypes.BIRTHDAY
                ) {
                  setComposeMessage(composeMessage || '')
                  setIsComposeModalOpen(true)
                }
                setCard({ title: title || '', type })
              }}
              subtitle={subtitle}
              title={title}
            />
          </li>
        ))}
      </StyledSuggestions>
      {isComposeModalOpen && (
        <ComposeMessage
          initialComposeState={{
            text: composeMessage,
          }}
          initialFilterState={{
            includedFilters: filters,
            excludedFilters: null,
          }}
          onClose={() => {
            setIsComposeModalOpen(false)
            setComposeMessage('')
          }}
          suggestionCard={card}
        />
      )}
    </>
  )
}
