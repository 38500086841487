import { ChevronRightIcon, SPACING } from '@community_dev/pixels'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

type TrendingResponsesBoxProps = {
  className?: string
  children?: React.ReactNode
  to: string
  onClick?: () => void
}

const StyledOuterLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: inherit;
  border-radius: 16px;
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  position: relative;
  transition: box-shadow 200ms ease-out, transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  &:hover {
    transform: translateZ(0) scale(1.0175);
    box-shadow: ${({ theme }) => theme.SHADOWS.CARD};

    @media (hover: none) {
      transform: translateZ(0) scale(1);
    }
  }
`

export const TrendingResponsesBoxContent = styled.div`
  margin: ${SPACING[5]} ${SPACING[5]} ${SPACING[4]} ${SPACING[5]};
`

// note - design calls for #e7e7e7, which I can't find anywhere in pixels.
const StyledTrendingResponsesBoxFooterDivThatLooksLikeLink = styled.div`
  cursor: pointer;
  display: block;
  padding-left: ${SPACING[4]};
  font-weight: 600;
  font-size: 15px;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  text-decoration: none;
  border-radius: 0 0 16px 16px;
  transition: background 100ms ease;
  &:before {
    content: '';
    display: block;
    height: 1px;
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
    margin-left: auto;
  }
  &:hover {
    background: rgba(51, 51, 51, 0.04);
  }
`

const StyledTrendingResponsesBoxFooterInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 25px;
  height: 50px;
`

// this is {props: any} because react-router-dom doesn't support typescript, and
// I want this thing to, for all intents and purposes, behave just like a <Link>
// with fancy styling.
export const TrendingResponsesBoxFooterLink = ({ children }: any): JSX.Element => {
  const { COLORS } = useTheme() || {}
  return (
    <StyledTrendingResponsesBoxFooterDivThatLooksLikeLink>
      <StyledTrendingResponsesBoxFooterInner>
        <div>{children}</div>
        <ChevronRightIcon color={COLORS?.SUBTEXT} />
      </StyledTrendingResponsesBoxFooterInner>
    </StyledTrendingResponsesBoxFooterDivThatLooksLikeLink>
  )
}

// The Trending Responses box is similar to the Insights box, but differs in:
// - padding
// - box shadow
// - border alignment
// - hover styling
//
// that's why I'm making another FooBox component rather than re-using a box from
// from Insights or Pixels.
export const TrendingResponsesBox = ({ children, ...props }: TrendingResponsesBoxProps): JSX.Element => {
  return <StyledOuterLink {...props}>{children}</StyledOuterLink>
}
