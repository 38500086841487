import { Layout, ChevronLeftIcon, SPACING, FONT_SIZE } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { InvoicesTable } from '../EnterpriseBilling/EnterpriseBillingSummary/InvoicesDetails/InvoicesTable'

import { ROUTES } from 'constants/routes'
import { useInvoices } from 'hooks/useInvoices'

const StyledHeader = styled.h3`
  margin: 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  font-size: ${FONT_SIZE[6]};
`

export const BillingHistory = (): JSX.Element => {
  const { t } = useTranslation()
  const { data: invoicesData, isInitialLoading } = useInvoices()

  return (
    <Layout display="flex" flexDirection="column">
      <Layout display="flex" marginBottom={SPACING[5]}>
        <Link aria-label="back" to={ROUTES.SETTINGS.BILLING}>
          <ChevronLeftIcon />
        </Link>
        <Layout display="flex" flexDirection="column" flexGrow={1} marginLeft={SPACING[5]}>
          <StyledHeader>{t('settings.billing.billingHistory')}</StyledHeader>
        </Layout>
      </Layout>
      <Layout>
        <InvoicesTable invoicesData={invoicesData?.invoices} isLoading={isInitialLoading} />
      </Layout>
    </Layout>
  )
}
