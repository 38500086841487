import { MessageDisabledIcon, SPACING, Tooltip } from '@community_dev/pixels'
import { ActionButton } from '@community_dev/pixels'
import { useQuery } from '@tanstack/react-query'
import { ReactNode } from 'react'
import styled, { useTheme } from 'styled-components'

import { Fan, getFan } from 'api/fans'
import { FavoriteFanActionButton } from 'components/ActionPanel'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'

const StyledContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(1fr);
  gap: ${SPACING[4]};
`

const StyledIcon = styled.div`
  position: relative;
  top: -2px;
  margin-bottom: -4px;
`

const ActionButtonContainer = styled.div`
  display: grid;
`

type MemberActionPanelProps = {
  fan?: Fan
  disableCommunities?: boolean
  disableMessage?: boolean
  disableMessageReason?: ReactNode
  onClickMessage?: () => void
  onClickAddToCommunity?: () => void
}

export function MemberDetailsActions({
  fan,
  onClickMessage,
  onClickAddToCommunity,
  disableCommunities = false,
  disableMessage = false,
  disableMessageReason = null,
}: MemberActionPanelProps): JSX.Element {
  const { COLORS } = useTheme()
  const { data } = useQuery([QUERY_CACHE.FAN, fan?.id], () => getFan(fan?.id), {
    enabled: Boolean(fan?.id),
    staleTime: STALE_TIME.FIVE_MINUTES,
  })

  return (
    <StyledContent data-testid="member-details-actions">
      {onClickMessage && (
        <Tooltip content={disableMessageReason} disabled={!disableMessage || !disableMessageReason}>
          <ActionButtonContainer>
            <ActionButton
              disabled={disableMessage}
              icon={
                disableMessage ? (
                  <StyledIcon>
                    <MessageDisabledIcon color={COLORS.INPUT_TEXT_PLACEHOLDER} size={20} />
                  </StyledIcon>
                ) : (
                  'comment'
                )
              }
              id="detail-pane-message"
              label="message"
              onClick={onClickMessage}
            />
          </ActionButtonContainer>
        </Tooltip>
      )}
      {onClickAddToCommunity && (
        <ActionButton
          disabled={disableCommunities}
          icon="plus"
          id="detail-pane-add-to"
          label="add to"
          onClick={onClickAddToCommunity}
        />
      )}
      <FavoriteFanActionButton disabled={disableCommunities} fan={data} />
    </StyledContent>
  )
}
