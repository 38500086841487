import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledVideo = styled.div`
  height: 180px;
  padding: 0 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${({ color }) => color};
  margin-bottom: 40px;
`

export const StyledAction = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) =>
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'gutter' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.gutter === 'slim' ? '16px' : '26px'};
  width: 100%;

  button,
  a {
    padding-top: 13px;
    padding-bottom: 13px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.176px;
  }

  a {
    text-decoration: none;
  }
`

const backStyles = css`
  display: inline-block;
  margin-top: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.176px;
  text-decoration: none;
  color: ${({ theme }) => theme?.COLORS?.BUTTON_PRIMARY};
`

export const StyledBack = styled(Link)`
  ${backStyles};
`

export const StyledTextButton = styled.button`
  ${backStyles};
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`

export const StyledBox = styled.div`
  width: 100%;
  margin-bottom: 36px;

  > div {
    margin-top: 24px;
  }
`

export const StyledMessagePreview = styled.div`
  display: inline-block;
  max-width: 264px;
  margin: 27px auto 25px;
`

export const StyledMultimedia = styled.div`
  textarea {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.176px;
  }
`
