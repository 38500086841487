import { useMutation, UseMutationResult } from '@tanstack/react-query'

import { patchWorkflow, PatchWorkflowArgs, Workflow } from 'api/workflows'

export type UsePatchWorkflowOptimisticUpdateContext = {
  previousWorkflow: Workflow
  nextWorkflow: Workflow
}

export type UsePatchWorkflowProps = {
  onSuccess?: (
    data: unknown,
    variables: PatchWorkflowArgs,
    context: UsePatchWorkflowOptimisticUpdateContext | undefined,
  ) => void
  onError?: (
    error: unknown,
    variables: PatchWorkflowArgs,
    context: UsePatchWorkflowOptimisticUpdateContext | undefined,
  ) => void
}

export type UsePatchWorkflowResult = UseMutationResult<
  unknown,
  unknown,
  PatchWorkflowArgs,
  { previousWorkflow: Workflow; nextWorkflow: Workflow }
>

export function usePatchWorkflow({ onSuccess, onError }: UsePatchWorkflowProps = {}): UsePatchWorkflowResult {
  return useMutation(patchWorkflow, {
    onSuccess,
    onError,
  })
}
