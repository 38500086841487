import { Filter } from '@community_dev/pixels'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CHART_OPTIONS, MONTH_KEY, NEW_MEMBER_GROWTH_KEY, TABS, TIME_RANGE, TOTAL_MEMBER_GROWTH_KEY } from './constants'
import { TotalMemberGrowthChart } from './TotalMemberGrowthChart'
import { TotalNewMembersChart } from './TotalNewMembersChart'

import { useClientId } from 'hooks/useClient'
import { useAllMemberCount } from 'hooks/useCountByQuery/useEveryoneCount'
import { InsightsBox } from 'screens/InsightsScreen/presentation/InsightsBox'
import analytics from 'utils/analytics'

const StyledFilter = styled(Filter)<{ $locked: boolean }>`
  > div {
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
    font-weight: 600;
    color: ${({ theme }) => theme?.COLORS?.TEXT};
    padding-left: 0;
    pointer-events: ${({ $locked }) => ($locked ? 'none' : 'auto')};
  }
`

const toIsMocked = (locked: boolean, activeSubscriptions: number, shouldMockMemberGrowthData: boolean): boolean => {
  if (locked && activeSubscriptions > 0) return true
  if (shouldMockMemberGrowthData) return true
  return false
}

type InsightsMastheadProps = {
  locked: boolean
}

export type TimeRange = typeof TIME_RANGE[keyof typeof TIME_RANGE]
export type GraphRange = typeof CHART_OPTIONS[keyof typeof CHART_OPTIONS]

const mockedClientIds = [
  'de7f5827-b421-4404-9843-b1b56b956602', // Ted Williams
  '5d321fe9-30cf-4885-82ae-6b0c489008f1', // Bot Tester
]

export const InsightsMasthead = ({ locked }: InsightsMastheadProps): JSX.Element => {
  const [selected, setSelected] = useState<TimeRange>(MONTH_KEY)
  const clientId = useClientId()
  const { total: activeSubscriptions, isLoading } = useAllMemberCount()
  const [childHeight, setChildHeight] = useState(0)
  const { t } = useTranslation()
  const [activeChart, setActiveChart] = useState<GraphRange>(NEW_MEMBER_GROWTH_KEY)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const shouldMockMemberGrowthData = useMemo(() => mockedClientIds.includes(clientId), [clientId])

  const isMocked = toIsMocked(locked, activeSubscriptions, shouldMockMemberGrowthData)

  const trackAndToggleMemberGrowth = useCallback(
    (selectedKey) => {
      analytics.track(analytics.events.ToggleMemberGrowthChart(selectedKey))
      setSelected(selectedKey)
    },
    [setSelected],
  )

  const trackAndToggleTotalMembersChart = useCallback(
    (selectedKey) => {
      analytics.track(analytics.events.ToggleTotalMembersChart(selectedKey))
      setSelected(selectedKey)
    },
    [setSelected],
  )

  const trackAndToggleChartType = useCallback(
    (selectedKey) => {
      analytics.track(analytics.events.ToggleChartType(selectedKey))
      setActiveChart(selectedKey)
    },
    [setActiveChart],
  )

  const tabs = (
    <InsightsBox.Tabs
      onChange={activeChart === TOTAL_MEMBER_GROWTH_KEY ? trackAndToggleMemberGrowth : trackAndToggleTotalMembersChart}
      selected={selected}
      tabs={TABS}
    />
  )

  const title = (
    <StyledFilter activeFilter={t(`insightMasthead.${activeChart}`)} icon={false} label={null}>
      <Filter.Option
        onClick={() => trackAndToggleChartType(NEW_MEMBER_GROWTH_KEY)}
        selected={activeChart === NEW_MEMBER_GROWTH_KEY}
      >
        {t(`insightMasthead.${NEW_MEMBER_GROWTH_KEY}`)}
      </Filter.Option>
      <Filter.Option
        onClick={() => trackAndToggleChartType(TOTAL_MEMBER_GROWTH_KEY)}
        selected={activeChart === TOTAL_MEMBER_GROWTH_KEY}
      >
        {t(`insightMasthead.${TOTAL_MEMBER_GROWTH_KEY}`)}
      </Filter.Option>
    </StyledFilter>
  )

  return (
    <InsightsBox.Managed
      headerHeight={`${childHeight + 20}px`}
      height="auto"
      // adding the margins
      locked={locked}
      queries={[]}
      tabs={tabs}
      title={title}
    >
      {activeChart === TOTAL_MEMBER_GROWTH_KEY ? (
        <TotalMemberGrowthChart
          activeSubscriptions={activeSubscriptions}
          clientId={clientId}
          isInsightsLoading={isLoading}
          isMocked={isMocked}
          locked={locked}
          onResizeHeaderHeight={setChildHeight}
          timeRange={selected}
          timezone={timezone}
        />
      ) : (
        <TotalNewMembersChart
          activeSubscriptions={activeSubscriptions}
          isMocked={isMocked}
          locked={locked}
          onResizeHeaderHeight={setChildHeight}
          timeRange={selected}
          timezone={timezone}
        />
      )}
    </InsightsBox.Managed>
  )
}
