import { digitsFilterToMemberDataFilter } from '@community_dev/filter-dsl/lib/transformers/digitsFilterToMemberDataFilter'
import {
  BORDER_RADIUS,
  BUTTON_VARIANTS,
  Button,
  CHECKBOX_STATES,
  CheckBox,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  MODAL_VARIANTS,
  MessagesIcon,
  Modal,
  PlusCircleIcon,
  SPACING,
} from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components'

import { useSearchState } from '../SearchProvider'
import { SEARCH_SELECTION_STATE } from '../useSearchSelection'

import { SearchActionBarLoader } from './SearchActionBarLoader'

import { Fan } from 'api/fans'
import { AddToCommunities } from 'components/ActionPanel/AddToCommunities'
import { ComposeMessage } from 'components/ComposeMessage'
import { CreateCommunityForm } from 'components/CreateCommunity/CreateCommunityForm'
import { useClient } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'
import analytics from 'utils/analytics'
import { formatNumeral, pluralizeNumeral } from 'utils/general'

const StyledButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.OUTLINE,
})`
  border-radius: 30px;
  display: flex;
  align-items: center;
`

const StyledPageButton = styled(Link)`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  border-radius: ${BORDER_RADIUS[1]};

  &:hover {
    background-color: ${({ theme }) => theme?.COLORS?.DEPRECATED_HOVER};
  }
`

const StyledSearchActionBar = styled.div<{ $isStuck: boolean; $top: number }>`
  position: sticky;
  top: ${({ $top }) => $top}px;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  padding: 6px ${SPACING[4]};

  ${({ $isStuck }) =>
    $isStuck &&
    css`
      box-shadow: 0 6px 8px -2px rgba(0, 0, 0, 0.1);
    `}
`

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const StyledSelectionSummary = styled.div`
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    margin-right: 12px;
  }

  > ${StyledButton}, label {
    margin-right: 12px;
  }
`

const StyledRight = styled.div`
  display: flex;
  flex-grow: 0;
  align-items: center;

  > p {
    margin: 0 12px 0 0;
  }
`

const StyledSelectionButton = styled.button`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  border: none;
  padding: 0;
  background: none;
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  cursor: pointer;
`

const StyledSelection = styled.p`
  margin: ${SPACING[2]} 0 0;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledStickyIndicator = styled.div`
  height: 0;
`

const StyledIconsWrapper = styled.span`
  display: inline-block;
  width: ${SPACING[4]};
  height: ${SPACING[4]};
  overflow: hidden;
  margin-right: ${SPACING[1]};

  svg {
    position: relative;
    left: -5px;
    top: -5px;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`

const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: ${SPACING[8]} ${SPACING[5]} ${SPACING[4]};
  overflow: auto;
`

const StyledModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
`

function getState(selectionState: keyof typeof SEARCH_SELECTION_STATE) {
  switch (selectionState) {
    case SEARCH_SELECTION_STATE.ENTIRE_QUERY_SELECTED:
    case SEARCH_SELECTION_STATE.EVERYONE_SELECTED:
      return CHECKBOX_STATES.CHECKED
    case SEARCH_SELECTION_STATE.PARTIALLY_SELECTED:
      return CHECKBOX_STATES.INDETERMINATE
    default:
      return CHECKBOX_STATES.UNCHECKED
  }
}

type SearchActionBarProps = {
  top?: number
}

export function SearchActionBar({ top = 0 }: SearchActionBarProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const [addedCommunities, setAddedCommunities] = useState({})
  const [isStuck, setIsStuck] = useState(false)
  const [isComposeModalOpen, setIsComposeModalOpen] = useState(false)
  const stickyRef = useRef<HTMLDivElement>(null)
  const [isCreateCommunityVisible, setIsCreateCommunityVisible] = useState(false)
  const [isAddToModalVisible, setIsAddToModalVisible] = useState(false)
  const {
    filters,
    assignMembersToCommunity,
    nextPage,
    page,
    pageSize,
    communicationChannel,
    previousPage,
    removeAllMembers,
    search,
    searchIsLoading,
    totalResults,
    searchResults,
    selectAllMembers,
    selected,
    selectionState,
    selectedNumberOfMembers,
  } = useSearchState()

  const { data: client } = useClient()
  const canAddResultToCommunity =
    communicationChannel === CommunicationChannel.SMS || communicationChannel === CommunicationChannel.WHATS_APP
  const hasClientCommunicationChannel = client?.communicationChannels?.some(
    (channel) => channel === communicationChannel,
  )
  const { showToastMessage } = useToastMessage()
  const ctaCommunityCopy = isCreateCommunityVisible ? t('cancel') : t('newCommunity')
  const membersCopy = pluralizeNumeral(`${selectedNumberOfMembers}`, '0,0', t('member'), t('members'))
  const selectedCopy =
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    t('addToCommunity_other', {
      count: membersCopy,
    })

  useEffect(() => {
    if (!stickyRef.current) return
    const observer = new IntersectionObserver(function (entries) {
      if (entries[0].isIntersecting) {
        setIsStuck(false)
      } else {
        setIsStuck(true)
      }
    })

    observer.observe(stickyRef.current)

    return () => {
      if (!stickyRef.current) return
      observer.unobserve(stickyRef.current)
    }
  }, [])

  const state = getState(selectionState)

  function handleChange() {
    switch (state) {
      case CHECKBOX_STATES.INDETERMINATE:
      case CHECKBOX_STATES.UNCHECKED:
        selectAllMembers()
        break
      case CHECKBOX_STATES.CHECKED:
        removeAllMembers()
        break
    }
  }

  function handleCloseAddTo() {
    setIsAddToModalVisible(false)
    setIsCreateCommunityVisible(false)
    setAddedCommunities({})
  }

  function handleClickAddTo() {
    setIsAddToModalVisible(true)
  }

  function handleToggleCreateCommunity() {
    setIsCreateCommunityVisible(!isCreateCommunityVisible)
  }

  function handleAddSuccess({ id, title }) {
    setAddedCommunities({
      ...addedCommunities,
      [id]: true,
    })

    analytics.track(
      analytics.events.FullTextSearchMembersAddedToCommunity({
        numberOfMembers: selectedNumberOfMembers,
        relatedSearchTerm: search,
        communityAddedTo: title,
      }),
    )

    showToastMessage({
      message: t('toasts.addToCommunitySuccess', {
        title,
        members: membersCopy,
      }),
    })
  }

  function handleAddError(title) {
    showToastMessage({
      message: t('toasts.addToCommunityError', { title }),
      success: false,
    })
  }

  function handleAdd({ id, title }) {
    assignMembersToCommunity(
      id,
      communicationChannel,
      () => handleAddSuccess({ id, title }),
      () => handleAddError(title),
    )
  }

  return (
    <>
      <StyledStickyIndicator ref={stickyRef} />
      <StyledSearchActionBar $isStuck={isStuck} $top={top}>
        {searchIsLoading ? (
          <SearchActionBarLoader />
        ) : (
          <>
            <StyledRow>
              <StyledSelectionSummary>
                <label>
                  <CheckBox onChange={handleChange} selected={state} /> {t('selectAll')}
                </label>
                {selectionState !== SEARCH_SELECTION_STATE.NON_SELECTED && (
                  <>
                    <StyledButton disabled={!hasClientCommunicationChannel} onClick={() => setIsComposeModalOpen(true)}>
                      <StyledIconsWrapper>
                        <MessagesIcon color="currentColor" size={26} />
                      </StyledIconsWrapper>
                      {t('Message')}
                    </StyledButton>
                    {canAddResultToCommunity && (
                      <StyledButton onClick={handleClickAddTo}>
                        <StyledIconsWrapper>
                          <PlusCircleIcon color="currentColor" fill="transparent" size={26} />
                        </StyledIconsWrapper>
                        {t('addTo')}
                      </StyledButton>
                    )}
                  </>
                )}
              </StyledSelectionSummary>
              <StyledRight>
                {totalResults < pageSize ? (
                  <p aria-label="Pagination summary">1-{totalResults}</p>
                ) : (
                  <p aria-label="Pagination summary">
                    {(page - 1) * (searchResults?.paginationData.pageSize || 0) + 1}-
                    {page * (searchResults?.paginationData.pageSize || 0)} of {formatNumeral(totalResults || 0)}
                  </p>
                )}

                {previousPage ? (
                  <StyledPageButton aria-label={t('previousPage')} to={previousPage}>
                    <ChevronLeftIcon />
                  </StyledPageButton>
                ) : null}
                {nextPage ? (
                  <StyledPageButton aria-label={t('nextPage')} to={nextPage}>
                    <ChevronRightIcon />
                  </StyledPageButton>
                ) : null}
              </StyledRight>
            </StyledRow>
          </>
        )}
        {selectionState !== SEARCH_SELECTION_STATE.NON_SELECTED ? (
          <StyledRow>
            <StyledSelectionSummary>
              {(() => {
                switch (selectionState) {
                  case SEARCH_SELECTION_STATE.PARTIALLY_SELECTED:
                    return (
                      <StyledSelection>
                        {formatNumeral(selected.length)} {t('search.selected')}{' '}
                        <StyledSelectionButton onClick={removeAllMembers}>
                          {t('search.clearSelection')}
                        </StyledSelectionButton>
                      </StyledSelection>
                    )
                    break
                  case SEARCH_SELECTION_STATE.EVERYONE_SELECTED:
                    return (
                      <StyledSelection>
                        {t('search.allSelected', {
                          number: formatNumeral(searchResults?.data.results.length || 0),
                        })}{' '}
                        <StyledSelectionButton onClick={selectAllMembers}>
                          {t('search.selectAllPages', {
                            members: formatNumeral(totalResults || 0),
                          })}
                        </StyledSelectionButton>
                      </StyledSelection>
                    )
                    break
                  default:
                    return (
                      <StyledSelection>
                        {t('search.entireQuerySelected', {
                          number: formatNumeral(totalResults || 0),
                        })}{' '}
                        <StyledSelectionButton onClick={removeAllMembers}>
                          {t('search.clearSelection')}
                        </StyledSelectionButton>
                      </StyledSelection>
                    )
                }
              })()}
            </StyledSelectionSummary>
          </StyledRow>
        ) : null}
        {isComposeModalOpen && (
          <ComposeMessage
            dynamicFields={false}
            initialFilterState={{
              individuals: selected.map(
                (searchResult) => ({ id: searchResult.fanId, fanSubscriptionId: searchResult.subscriptionId } as Fan),
              ),
              includedFilters: digitsFilterToMemberDataFilter(filters),
              excludedFilters: null,
              communicationChannel,
            }}
            onClose={() => setIsComposeModalOpen(false)}
            onSuccessfulSend={() => {
              analytics.track(
                analytics.events.FullTextSearchMembersRespondedTo({
                  numberOfMembers: selectedNumberOfMembers,
                  relatedSearchTerm: search,
                }),
              )
            }}
            readOnlyRecipients
            shouldDisableScheduleMessages
            shouldRedirect={false}
          />
        )}
      </StyledSearchActionBar>
      {isAddToModalVisible && (
        <Modal open variant={MODAL_VARIANTS.ROUNDED}>
          <Modal.Header>
            <Modal.Header.Center>{selectedCopy}</Modal.Header.Center>
            <Modal.Header.Right onClose={handleCloseAddTo}>
              <StyledCloseIcon color={COLORS?.SUBTEXT} size={12} />
            </Modal.Header.Right>
          </Modal.Header>
          <StyledModalBody>
            {isCreateCommunityVisible ? (
              <CreateCommunityForm onSubmit={handleToggleCreateCommunity} />
            ) : (
              <AddToCommunities addedCommunities={addedCommunities} onAdd={handleAdd} showHeader={false} />
            )}
          </StyledModalBody>
          <StyledModalFooter>
            <Button
              aria-label={ctaCommunityCopy}
              onClick={handleToggleCreateCommunity}
              variant={BUTTON_VARIANTS.OUTLINE}
            >
              {ctaCommunityCopy}
            </Button>
            <Button onClick={handleCloseAddTo}>{t('done')}</Button>
          </StyledModalFooter>
        </Modal>
      )}
    </>
  )
}
