import { Button, Layout, Popover, SPACING } from '@community_dev/pixels'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ScheduleTime } from 'components/ScheduleTime'
import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { useRemoteWorkflow } from 'components/WorkflowEditor/hooks/useRemoteWorkflow'
import { useScheduleWorkflow } from 'components/WorkflowEditor/hooks/useScheduleWorkflow'
import { toWorkflowStatus } from 'components/WorkflowEditor/utils/toWorkflowStatus'
import { WorkflowStatusTypes } from 'screens/WorkflowsScreen/WorkflowStatus'
import dayjs from 'utils/dayjs'

const StyledDivider = styled.div`
  top: 5px;
  left: 0;
  bottom: 5px;
  width: 1px;
  position: absolute;
  border-left: 1px solid currentColor;
  pointer-events: none;
`

const StyledArrow = styled.div`
  border: solid currentColor;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin-bottom: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  pointer-events: none;
`

const StyledPauseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: ${SPACING[4]};
`

const StyledPauseButtonLeft = styled(Button)`
  min-width: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
  margin: 0 0 0 auto;
`
const StyledSchedulePauseButtonRight = styled(Button)`
  position: relative;
  padding: 7px 12px;
  min-width: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin: 0 0 0 auto;
`
const ScheduledTimeDivider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  width: 100%;
  margin: ${SPACING[3]} 0;
`

export const PauseResumeScheduleButton = (): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const onClose = () => setIsOpen(false)
  const { workflow } = useWorkflowProvider()
  const [currentActiveEndAt, setCurrentActiveEndAt] = useState(workflow.active_end_at || dayjs().toISOString())
  const { refetch } = useRemoteWorkflow()
  const workflowStatus = toWorkflowStatus(workflow)
  const { t } = useTranslation()

  const { onPause, onResume, onSchedulePause, onCancelSchedule } = useScheduleWorkflow({
    workflow,
    onSuccess: () => {
      refetch()
      onClose()
    },
  })

  const isScheduledToPause = useMemo(() => {
    if (workflow.active_end_at) return dayjs(workflow.active_end_at).isAfter(dayjs())
  }, [workflow.active_end_at])

  if (workflowStatus === WorkflowStatusTypes.paused) {
    return (
      <StyledPauseButtonWrapper>
        <Button onClick={onResume}>{t('automations.scheduled.resume')}</Button>
      </StyledPauseButtonWrapper>
    )
  }
  if (isScheduledToPause) {
    return (
      <StyledPauseButtonWrapper>
        <StyledPauseButtonLeft onClick={onCancelSchedule}>
          {t('automations.scheduled.cancelSchedule')}
        </StyledPauseButtonLeft>

        <StyledSchedulePauseButtonRight aria-label="Schedule flow" onClick={() => setIsOpen(!isOpen)} ref={buttonRef}>
          <StyledDivider />
          <StyledArrow />
        </StyledSchedulePauseButtonRight>
        <Popover
          align="right"
          isOpen={isOpen}
          onClose={onClose}
          overflow="visible"
          showBorder
          targetRef={buttonRef}
          width="auto"
        >
          <Layout padding={SPACING[3]}>
            <ScheduleTime
              defaultValue={currentActiveEndAt}
              label={t('automations.scheduled.endAt')}
              onChange={(date) => setCurrentActiveEndAt(date)}
            />
            <ScheduledTimeDivider />
            <Layout display="flex" justifyContent="flex-end">
              <Button onClick={() => onSchedulePause(currentActiveEndAt)}>{t('update')}</Button>
            </Layout>
          </Layout>
        </Popover>
      </StyledPauseButtonWrapper>
    )
  }

  return (
    <StyledPauseButtonWrapper>
      <StyledPauseButtonLeft onClick={onPause}>{t('automations.scheduled.pause')}</StyledPauseButtonLeft>

      <StyledSchedulePauseButtonRight aria-label="Schedule flow" onClick={() => setIsOpen(!isOpen)} ref={buttonRef}>
        <StyledDivider />
        <StyledArrow />
      </StyledSchedulePauseButtonRight>
      <Popover
        align="right"
        isOpen={isOpen}
        onClose={onClose}
        overflow="visible"
        showBorder
        targetRef={buttonRef}
        width="auto"
      >
        <Layout padding={SPACING[3]}>
          <ScheduleTime
            defaultValue={currentActiveEndAt}
            label={t('automations.scheduled.endAt')}
            onChange={(date) => setCurrentActiveEndAt(date)}
          />
          <ScheduledTimeDivider />
          <Layout display="flex" justifyContent="flex-end">
            <Button onClick={() => onSchedulePause(currentActiveEndAt)}>{t('confirm')}</Button>
          </Layout>
        </Layout>
      </Popover>
    </StyledPauseButtonWrapper>
  )
}
