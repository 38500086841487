import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorOperators,
  LocationRadiusFilter,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ApiError } from '@community_dev/requests/lib/request'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { FilterOptionsWithCounts } from './types'
import { CountByQueryFnReturn } from './useCountByQuery'
import { useCountByQueries } from './useCountByQuery'

import { FilterSelectionType } from 'contexts/FilterProvider/FilterProvider'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'

const locationFilters: LocationRadiusFilter[] = [
  {
    operator: SelectorOperators.RADIUS,
    operand: {
      field_key: BuiltInFields.LOCATION,
      field_label: fieldLabelFor(BuiltInFields.LOCATION),
      source: FieldSources.BUILT_IN,
      type: FieldTypes.LOCATION,
      value: {
        city: 'Los Angeles',
        state: 'CA',
        lat: 34.0549076,
        lng: -118.242643,
        meters: 55360,
      },
    },
  },
  {
    operator: SelectorOperators.RADIUS,
    operand: {
      field_key: BuiltInFields.LOCATION,
      field_label: fieldLabelFor(BuiltInFields.LOCATION),
      source: FieldSources.BUILT_IN,
      type: FieldTypes.LOCATION,
      value: {
        city: 'New York',
        state: 'NY',
        lat: 40.7127753,
        lng: -74.0059728,
        meters: 55360,
      },
    },
  },
]

type UseLocationCountsArgs<SelectData = CountByQueryFnReturn> = {
  traceId?: string
  communicationChannel?: CommunicationChannel
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

export const useLocationCounts = <SelectData = CountByQueryFnReturn>({
  traceId,
  communicationChannel,
  options,
}: UseLocationCountsArgs<SelectData> = {}): UseQueryResult<SelectData, ApiError>[] => {
  return useCountByQueries<SelectData>({
    filters: locationFilters,
    traceId,
    communicationChannel,
    options,
  })
}

export const useCurrentFiltersWithLocationCounts = (
  type: FilterSelectionType = 'includes',
): FilterOptionsWithCounts => {
  const { previewNextFilter, communicationChannel } = useFilters()

  const nextFilters = locationFilters
    .map((locationFilter) => previewNextFilter(locationFilter, type))
    .filter(Boolean) as MemberDataFilter[]

  return {
    options: locationFilters.map((locationFilter) => ({
      label: `${locationFilter.operand.value.city}, ${locationFilter.operand.value.state}`,
      filter: locationFilter,
    })),
    counts: useCountByQueries({
      filters: nextFilters,
      traceId: 'current-filters-with-location-count',
      communicationChannel,
    }) as UseQueryResult<CountByQueryFnReturn, ApiError>[],
  }
}
