import {
  BuiltInFields,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorOperators,
  findCommunityIncludesIds,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { ListItem, SPACING, SearchBar } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import escapeStringRegexp from 'escape-string-regexp'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { FilterMemberCount } from './FilterMemberCount'
import { useRecipientField } from './RecipientFieldContext'
import {
  StyledAddSuffix,
  StyledButton,
  StyledFilters,
  StyledHeader,
  StyledHeading,
  StyledLoadingIndicator,
  StyledMeta,
  StyledNoResults,
  StyledVirtualList,
} from './styled-recipients'

import { Community } from 'api/community'
import { useUpdatingCommunities } from 'components/ActionPanel/AddToCommunities/useUpdatingCommunities'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { CommunityDot } from 'components/CommunityDot'
import { VirtualListHandle } from 'components/VirtualList'
import { FilterSelectionType, useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useCurrentFiltersWithCommunityCount } from 'hooks/useCountByQuery/useCommunityCount'
import { pluralizeNumeral } from 'utils/general'

const StyledRecommendationsCommunities = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`

export type RecommendationsCommunitiesProps = {
  type: FilterSelectionType
}

const CommunityListItem = ({
  communicationChannel,
  community,
  added,
  addCommunity,
  removeCommunity,
  setIsOpen,
  type,
}: {
  communicationChannel: CommunicationChannel
  community: Community
  added: boolean
  addCommunity: (community: Community) => void
  removeCommunity: (community: Community) => void
  setIsOpen: (isOpen: boolean) => void
  type: FilterSelectionType
}): JSX.Element => {
  const { id, color, title } = community
  const { COLORS } = useTheme() || {}
  const { counts } = useCurrentFiltersWithCommunityCount({
    communityId: id,
    title,
    type,
  })
  const { data: { count = 0 } = {}, isInitialLoading } = counts[0] || {}
  return (
    <ListItem
      as={added ? 'div' : 'button'}
      data-testid={`community-item-${id}`}
      label={title}
      onClick={
        added
          ? undefined
          : () => {
              addCommunity(community)
              setIsOpen(false)
            }
      }
      prefix={
        <CommunityDot
          badge={
            communicationChannel === CommunicationChannel.AMB &&
            community.communicationChannels?.includes(CommunicationChannel.AMB) && (
              <CommunicationChannelIcon
                communicationChannel={CommunicationChannel.AMB}
                filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))"
                size={19}
                style={{ marginTop: SPACING[2] }}
              />
            )
          }
          color={color}
          icon={community.title === 'Favorites' ? 'star' : undefined}
        />
      }
      subtext={
        <FilterMemberCount
          count={pluralizeNumeral(String(count), '0,0', 'Member', 'Members')}
          isLoading={isInitialLoading}
        />
      }
      suffix={
        added ? (
          <StyledAddSuffix>
            <StyledButton
              $color={COLORS?.ERRORS}
              onClick={() => {
                removeCommunity(community)
                setIsOpen(false)
              }}
            >
              Remove
            </StyledButton>
          </StyledAddSuffix>
        ) : undefined
      }
    />
  )
}

export function RecommendationsCommunities({ type = 'includes' }: RecommendationsCommunitiesProps): JSX.Element {
  const virtualRef = useRef<VirtualListHandle>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const { isLoading, communities } = useUpdatingCommunities()
  const { activeSubtree, addFilter, communicationChannel, removeFilter } = useFilters()
  const { setIsOpen } = useRecipientField()

  const addedCommunitiesList = useMemo(() => {
    if (activeSubtree) {
      if (type === 'includes') {
        return findCommunityIncludesIds(activeSubtree)
      }

      if (type === 'excludes') {
        return findCommunityIncludesIds(activeSubtree)
      }
    }

    return []
  }, [activeSubtree, type])

  const filteredItems = useMemo(() => {
    return communities?.all
      .filter((community) =>
        searchTerm ? new RegExp(escapeStringRegexp(searchTerm), 'ig').test(community.title) : true,
      )
      .filter(
        (community) =>
          community.communicationChannels === undefined ||
          community.communicationChannels?.includes(communicationChannel),
      )
  }, [communicationChannel, communities?.all, searchTerm])

  const addCommunity = (community: Community): void => {
    const filter: MemberDataFilter = {
      operator: SelectorOperators.EQUALS,
      operand: {
        field_key: BuiltInFields.TAG_ID,
        field_label: community.title,
        source: FieldSources.TAGS,
        type: FieldTypes.UUID,
        value: community.id,
      },
    }

    addFilter(filter, type)
  }

  const removeCommunity = (community: Community): void => {
    const filter: MemberDataFilter = {
      operator: SelectorOperators.EQUALS,
      operand: {
        field_key: BuiltInFields.TAG_ID,
        field_label: community.title,
        source: FieldSources.TAGS,
        type: FieldTypes.UUID,
        value: community.id,
      },
    }

    removeFilter(filter, null, type)
  }

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    virtualRef.current?.measure()
  }, [communities, filteredItems])

  return (
    <StyledRecommendationsCommunities>
      <StyledMeta>
        <StyledHeader>
          <StyledHeading>Communities</StyledHeading>
        </StyledHeader>
        <StyledFilters>
          <SearchBar onChange={onSearchChange} placeholder="Search Communities" value={searchTerm} />
        </StyledFilters>
      </StyledMeta>
      {isLoading || !filteredItems ? (
        <StyledLoadingIndicator />
      ) : filteredItems.length ? (
        <StyledVirtualList ref={virtualRef} rows={filteredItems} testId="communities-list">
          {({ virtualRow }) => {
            const community = filteredItems[virtualRow.index]
            const { id } = community
            const added = addedCommunitiesList?.includes(id)

            return (
              <CommunityListItem
                addCommunity={addCommunity}
                added={added}
                communicationChannel={communicationChannel}
                community={community}
                removeCommunity={removeCommunity}
                setIsOpen={setIsOpen}
                type={type}
              />
            )
          }}
        </StyledVirtualList>
      ) : (
        <StyledNoResults>No results for "{searchTerm}"</StyledNoResults>
      )}
    </StyledRecommendationsCommunities>
  )
}
