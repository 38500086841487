import { Layout } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { NavLink } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { StyledMenuItem } from './SidebarLink.style'

import { filterSidebarLinks, SIDEBAR_ICONS } from 'containers/Sidebar/Sidebar'
import { useCapabilities } from 'contexts/bootstrap/useCapabilities'
import { useLaunchDarklyContext } from 'contexts/LaunchDarklyProvider'
import { useClient } from 'hooks/useClient'

export type SidebarIconKey = keyof typeof SIDEBAR_ICONS

export type SidebarLinkBaseProps = {
  id: string
  collapsed?: boolean
  icon?: SidebarIconKey
  depth?: number
  text: string
  onlyDesktop?: boolean
  onlyMobile?: boolean
  requiresFlagEnabled?: string[]
  requiresFlagDisabled?: string[]
  requiresCapabilityEnabled?: string[]
  requiresCapabilityDisabled?: string[]
  requiresCommunicationChannel?: boolean | CommunicationChannel[]
}

export type SidebarLinkDirectProps = SidebarLinkBaseProps & {
  exact?: boolean
  to: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export type SidebarLinkWithChildrenProps = SidebarLinkBaseProps & {
  children: Record<string, SidebarLinkDirectProps>
  open?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export type SidebarLinkProps = SidebarLinkDirectProps | SidebarLinkWithChildrenProps

export const SidebarLink = (props: SidebarLinkProps): JSX.Element | null => {
  const { COLORS } = useTheme() || {}
  const launchDarklyClient = useLaunchDarklyContext()

  const { data: client } = useClient()
  const capabilities = useCapabilities()
  const allFlags = launchDarklyClient?.allFlags() || {}

  const { id, icon, text, depth = 0, collapsed } = props

  const Icon = icon ? SIDEBAR_ICONS[icon] : null

  const sidebarLinkBody = (
    <Layout alignItems="center" as="span" display="flex">
      <Layout alignItems="center" as="span" display="flex" height="32px" justifyContent="center" width="32px">
        {Icon && <Icon color={COLORS?.MENU_TEXT_PRIMARY} />}
      </Layout>
      <Layout as="span">{collapsed ? '' : text}</Layout>
    </Layout>
  )

  if ('to' in props) {
    const { to, exact, collapsed, onlyDesktop, onlyMobile, onClick } = props

    if (props.to[0] !== '/') {
      return (
        <StyledMenuItem $collapsed={collapsed} $depth={depth} $onlyDesktop={onlyDesktop} $onlyMobile={onlyMobile}>
          <a href={props.to} id={id} onClick={onClick}>
            {sidebarLinkBody}
          </a>
        </StyledMenuItem>
      )
    } else {
      return (
        <StyledMenuItem $collapsed={collapsed} $depth={depth} $onlyDesktop={onlyDesktop} $onlyMobile={onlyMobile}>
          <NavLink exact={exact} id={id} onClick={onClick} to={to}>
            {sidebarLinkBody}
          </NavLink>
        </StyledMenuItem>
      )
    }
  }

  if ('children' in props) {
    const { open, text, children, collapsed, onlyDesktop, onlyMobile, onClick } = props

    return (
      <>
        <StyledMenuItem $collapsed={collapsed} $depth={depth} $onlyDesktop={onlyDesktop} $onlyMobile={onlyMobile}>
          <button
            aria-label={`Open ${text} menu`}
            id={id}
            onClick={(e) => {
              typeof onClick === 'function' && onClick(e)
            }}
          >
            {sidebarLinkBody}
          </button>
        </StyledMenuItem>

        {open && client && (
          <>
            {filterSidebarLinks(children, allFlags, capabilities, client.communicationChannels).map((child) => {
              const id = child.text.replace(' ', '-').toLowerCase()

              return <SidebarLink collapsed={collapsed} depth={depth + 1} key={id} {...child} />
            })}
          </>
        )}
      </>
    )
  }

  return null
}
