import styled from 'styled-components'

const StyledFooter = styled.div`
  background: #333333;
  flex: 1;
  display: flex;
  justify-content: center;
`

export const MockFooter = (): JSX.Element => (
  <StyledFooter>
    <svg fill="none" viewBox="0 0 518 188" width="100%" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#333333" height="188" width="518" />
      <rect fill="#888888" height="8" rx="2" width="64" x="40" y="32" />
      <rect fill="#888888" height="4" rx="2" width="85" x="40" y="56" />
      <rect fill="#888888" height="4" rx="2" width="53" x="40" y="76" />
      <rect fill="#888888" height="4" rx="2" width="53" x="40" y="96" />
      <rect fill="#888888" height="4" rx="2" width="85" x="40" y="116" />
      <rect fill="#888888" height="8" rx="2" width="56" x="196.667" y="32" />
      <rect fill="#888888" height="4" rx="2" width="60" x="196.667" y="56" />
      <rect fill="#888888" height="4" rx="2" width="97" x="196.667" y="76" />
      <rect fill="#888888" height="4" rx="2" width="48" x="196.667" y="96" />
      <rect fill="#888888" height="8" rx="2" width="68" x="353.333" y="32" />
      <rect fill="#888888" height="4" rx="2" width="91" x="353.333" y="56" />
      <rect fill="#888888" height="4" rx="2" width="91" x="353.333" y="76" />
      <rect fill="#888888" height="4" rx="2" width="46" x="353.333" y="96" />
      <rect fill="#888888" height="4" rx="2" width="57" x="353.333" y="116" />
    </svg>
  </StyledFooter>
)
