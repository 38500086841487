import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { PhoneNumberType } from '@community_dev/types/lib/api/PhoneNumberType'
import { STATUS } from '@community_dev/types/lib/api/v2/TCR'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import styled from 'styled-components'

import { useCarrierUsage } from './useCarrierUsage'
import { useClient, useClientId } from './useClient'
import { useInvoices } from './useInvoices'
import { useHasCapability } from './useUserCapability'

import { Client } from 'api/client'
import { AppBannerProps, BannerTypes, StyledLink, StyledRouterLink } from 'components/AppBanner'
import { CAPABILITIES } from 'constants/capabilities'
import { TCR_FAQ } from 'constants/communityLinks'
import { ROUTES } from 'constants/routes'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useTCRContext } from 'contexts/TCRProvider'
import analytics from 'utils/analytics'
import dayjs from 'utils/dayjs'

type AppBannerHook = [true, AppBannerProps] | [false, null]

const StyledUnderlineLink = styled.a`
  color: unset;
  text-decoration: underline;
`

const useInvoiceBanner = (): AppBannerHook => {
  const { t } = useTranslation()
  const { data: invoicesData } = useInvoices()
  const hasClientBillingWrite = useHasCapability(CAPABILITIES.CLIENT.BILLING.WRITE)
  const hasAdminBillingWrite = useHasCapability(CAPABILITIES.ADMIN.BILLING.WRITE)

  const hasBillingWrite = hasClientBillingWrite || hasAdminBillingWrite

  const { invoices = [] } = invoicesData || {}

  const hasOverdueBalance = invoices.find((invoice) => invoice.isPastDue)

  const isBillingRoute = useRouteMatch([ROUTES.SETTINGS.BILLING, ROUTES.SETTINGS.BILLING_HISTORY])

  if (hasOverdueBalance) {
    const appBannerProps = {
      type: BannerTypes.WARNING,
      title: t('banners.invoiceOverdue.title'),
      body: t('banners.invoiceOverdue.body'),
      cta: !isBillingRoute && (
        <StyledLink as={StyledRouterLink} to={ROUTES.SETTINGS.BILLING}>
          {hasBillingWrite ? t('banners.invoiceOverdue.payNow') : t('banners.invoiceOverdue.view')}
        </StyledLink>
      ),
    }

    return [true, appBannerProps]
  }

  return [false, null]
}

const useCarrierLimitWarningBanner = (): AppBannerHook => {
  const { t } = useTranslation()
  const { isLimitReached, quotaResetsAt } = useCarrierUsage()
  const shouldHide = useRouteMatch(ROUTES.INSIGHTS.ROOT)
  const { data: seat } = useSeat()

  const { data: client } = useClient()
  const clientId = useClientId()
  const show = shouldHide || !isLimitReached ? false : true

  const timezone = useMemo(() => dayjs.tz.guess(), [])
  const resetTime = useMemo(
    () => quotaResetsAt && dayjs(quotaResetsAt).tz(timezone).format('h:mma z'),
    [quotaResetsAt, timezone],
  )

  if (!show) {
    return [false, null]
  }

  const subject = t('settings.billing.messageLimits.mailSubject')
  const body = t('settings.billing.messageLimits.mailBody', {
    clientId: clientId,
    clientName: client?.fullName,
    seatName: `${seat?.firstName} ${seat?.lastName}`,
    seatEmail: seat?.email,
  })
  const mailLink = `mailto:yourfriends@community.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    body,
  )}`

  const appBannerProps = {
    type: BannerTypes.WARNING,
    title: t('tcr.carrierLimit.warningTitle'),
    body: <Trans i18nKey="tcr.carrierLimit.warningDescription">{resetTime}</Trans>,
    cta: (
      <StyledLink href={mailLink} onClick={() => analytics.track(analytics.events.TCR.ContactUs())}>
        {t('tcr.carrierLimit.contactUs')}
      </StyledLink>
    ),
  }

  return [show, appBannerProps]
}

const shouldShowMessageDisabledBanner = (isSuccess: boolean, status: STATUS, client?: Client) => {
  // if tcr request is not successful we do not show
  if (!isSuccess) return false
  // if client has short code number and sms communication channel we do not show
  if (
    client?.phoneNumberType === PhoneNumberType.SHORT_CODE &&
    client?.communicationChannels?.includes(CommunicationChannel.SMS)
  )
    return false
  // if client has 10dlc number and tcr status is approved we do not show
  if (client?.phoneNumberType === PhoneNumberType.TEN_DLC && status === STATUS.APPROVED) return false
  // we show the tcr banner in every other case
  return true
}

const useOutboundMessagesDisabled = (): AppBannerHook => {
  const { data: seat } = useSeat()
  const { data: client } = useClient()
  const clientId = useClientId()
  const { t } = useTranslation()
  const { isSuccess, status } = useTCRContext()
  const show = shouldShowMessageDisabledBanner(isSuccess, status, client)

  if (!show) {
    return [false, null]
  }

  const subject = t('tcr.outboundMessagesDisabled.email.subject')
  const body = t('tcr.outboundMessagesDisabled.email.body', {
    clientId: clientId,
    clientName: client?.fullName,
    seatName: `${seat?.firstName} ${seat?.lastName}`,
    seatEmail: seat?.email,
  })
  const mailLink = `mailto:yourfriends@community.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    body,
  )}`

  const appBannerProps = {
    type: BannerTypes.WARNING,
    title: t('tcr.outboundMessagesDisabled.hardBlockTitle'),
    body:
      client?.phoneNumberType === PhoneNumberType.SHORT_CODE ? (
        t('tcr.outboundMessagesDisabled.hardBlockDescriptionShortCode')
      ) : (
        <Trans i18nKey={'tcr.outboundMessagesDisabled.hardBlockDescription'}>
          <StyledUnderlineLink href={TCR_FAQ} rel="noopener noreferrer" target="_blank" />
          <StyledUnderlineLink href={TCR_FAQ} rel="noopener noreferrer" target="_blank" />
        </Trans>
      ),
    cta: <StyledLink href={mailLink}>{t('tcr.carrierLimit.contactUs')}</StyledLink>,
  }

  return [show, appBannerProps]
}

export const useAppBanners = (): AppBannerProps[] => {
  const [showOutboundMessagesDisabled, outboundMessagesDisabledProps] = useOutboundMessagesDisabled()
  const [showCarrierLimitWarning, carrierLimitWarningProps] = useCarrierLimitWarningBanner()
  const [showInvoiceBanner, invoiceBannerProps] = useInvoiceBanner()

  const banners = useMemo(() => {
    const ret: AppBannerProps[] = []
    if (showInvoiceBanner) {
      ret.push(invoiceBannerProps)
    }

    if (showOutboundMessagesDisabled) {
      ret.push(outboundMessagesDisabledProps)
    } else if (showCarrierLimitWarning) {
      ret.push(carrierLimitWarningProps)
    }

    return ret
  }, [
    showOutboundMessagesDisabled,
    showCarrierLimitWarning,
    showInvoiceBanner,
    outboundMessagesDisabledProps,
    carrierLimitWarningProps,
    invoiceBannerProps,
  ])

  return banners
}
