import { FilterPills } from '@community_dev/pixels'
import { SPACING } from '@community_dev/pixels'
import styled, { css } from 'styled-components'

export const StyledFilterPills = styled(FilterPills)`
  margin: 0 -${SPACING[1]} ${SPACING[1]};
`

export const StyledRecipientField = styled.div<{
  onClick?: React.MouseEventHandler<HTMLDivElement>
}>`
  position: relative;
  padding: ${SPACING[3]} ${SPACING[3]};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: 4px;
  background: ${({ theme }) => theme?.COLORS?.INPUT_BACKGROUND};
  ${({ onClick = false }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
  margin-bottom: ${SPACING[4]};
`

export const StyledLabel = styled.div`
  display: flex;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  align-items: center;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  min-height: 24px;
`

export const StyledToField = styled.div`
  @keyframes pulse-in {
    0% {
      opacity: 0;
      left: -10px;
      color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
    }
    100% {
      opacity: 1;
      left: 0px;
      color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
    }
  }
  position: relative;
  animation-name: pulse-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
`

export const StyledAddRecipientButton = styled.button`
  margin: 0 0 0 auto;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme?.COLORS?.BUTTON_DEFAULT};
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme?.COLORS?.BUTTON_DEFAULT_HOVER};
  }

  #circle-plus-icon,
  #circle-minus-icon {
    color: ${({ theme }) => theme?.COLORS?.BUTTON_DEFAULT_TEXT};
  }
`

export const StyledPills = styled.div`
  margin: 0 -${SPACING[1]};

  > div {
    margin: 0 2px ${SPACING[1]};
  }
`

export const StyledAndOrTooltipLabel = styled.div`
  color: ${({ theme }) => theme?.COLORS.TOOLTIP_LABEL};
  display: flex;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledAndOrTooltipTarget = styled.span`
  display: flex;
  align-items: center;
  padding: 0 ${SPACING[1]};
`

export const StyledAndOrTooltipContent = styled.span`
  display: inline-block;
  min-width: 20rem;
  padding: ${SPACING[2]};

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`
