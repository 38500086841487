import { LinkIcon } from '@community_dev/pixels'
import React, { useState } from 'react'

import { ProductEducationCard } from '../components/ProductEducationCard'

import { ShareYourCommunityModal } from './ShareYourCommunityModal'

import { useClient } from 'hooks/useClient'
import { baseConfig } from 'utils/config'

const withoutProtocol = (url: string): string => url.replace(/(^\w+:|^)\/\//, '')

export function ShareYourCommunityCard(): JSX.Element {
  const { data: client } = useClient()
  const { urlSlug } = client || { urlSlug: '' }
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <ProductEducationCard
        Icon={LinkIcon}
        body="Let your audience text you with one click"
        header={`${withoutProtocol(baseConfig.swipeUpUrl)}/${urlSlug}`}
        onClick={() => setIsOpen(true)}
        subHeader="Use your Community URL"
      />
      {isOpen ? <ShareYourCommunityModal isOpen={isOpen} onClose={() => setIsOpen(false)} /> : null}
    </>
  )
}
