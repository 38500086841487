import { Button, EditableText, Layout, SPACING, Skeleton } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled, { css, useTheme } from 'styled-components'

import { patchMessageQuery } from 'api/message'
import { useActionPanel } from 'components/ActionPanel/ActionPanelContext'
import { CampaignMenu, DuplicateClickProps } from 'components/CampaignMenu'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { ComposeMessage } from 'components/ComposeMessage'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { useCurrentCampaignDetails, useCurrentCampaignFilterSummary } from 'hooks/useCampaign'
import { useClientId } from 'hooks/useClient'
import { useGoBack } from 'hooks/useGoBack'
import { useToastMessage } from 'hooks/useToastMessage'
import Sentry from 'integrations/Sentry'
import analytics from 'utils/analytics'
import { screens } from 'utils/analytics/events'

const StyledRecipients = styled.header`
  display: flex;
  padding: 12px ${SPACING[4]};
  width: 100%;
  position: sticky;
  z-index: 1;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  justify-content: space-between;
  margin-bottom: ${SPACING[5]};
  align-items: center;
  top: 0;
`

const StyledBackButton = styled.button`
  flex-shrink: none;
  border: none;
  background: none;
  padding: 0 ${SPACING[2]} 0 0;
  &:hover {
    cursor: pointer;
  }
`

const StyledButtons = styled.div`
  display: flex;
  align-items: center;

  & > button {
    margin-left: ${SPACING[2]};
  }
`

type StyledSummaryProps = {
  $hasFilters?: boolean
  $showDetailsButton?: boolean
}

const StyledSummary = styled.div<StyledSummaryProps>`
  width: 100%;
  max-width: ${({ $showDetailsButton }) => ($showDetailsButton ? 'calc(100% - 112px)' : 'calc(100% - 24px)')};
  display: inline-flex;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};

  ${({ $hasFilters }) =>
    $hasFilters &&
    css`
      margin-bottom: 6px;
    `}
`

export function Recipients(): JSX.Element {
  const clientId = useClientId()
  const [duplicateCampaign, setDuplicateCampaign] = useState<DuplicateClickProps | undefined>(undefined)
  const { isOpen, openActionPanel } = useActionPanel()
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const { showToastMessage } = useToastMessage()
  const queryClient = useQueryClient()
  const { campaignId }: { campaignId: string } = useParams()
  const { mutate: patchMessage } = useMutation(patchMessageQuery, {
    onSuccess: () => {
      analytics.track(
        analytics.events.EditCampaignName({
          page: screens.CampaignDetails,
        }),
      )
      queryClient.invalidateQueries([QUERY_CACHE.CAMPAIGN.LIST])
      queryClient.invalidateQueries([QUERY_CACHE.CAMPAIGN.DETAILS])
      queryClient.invalidateQueries([QUERY_CACHE.CAMPAIGN.RANGE])
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.RANGE])
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.RECURRING])
      showToastMessage({
        message: t('updatedCampaignName'),
      })
    },
    onError: (error) => {
      showToastMessage({
        message: t('failedToUpdateCampaignName'),
        success: false,
      })

      Sentry.captureException(error)
    },
  })

  const summary = useCurrentCampaignFilterSummary()

  const { data: { communicationChannel, name: campaignName, tags } = {}, isLoading } = useCurrentCampaignDetails()
  const showDetailsButton = !isOpen && !isLoading

  function openDetails() {
    openActionPanel && openActionPanel()
  }
  const goBackFn = useGoBack(ROUTES.CAMPAIGNS.ROOT)
  function goBack() {
    goBackFn()

    const videos = [].slice.call(document.getElementsByTagName('video'))
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'pause' does not exist on type 'never'.
    if (videos.length) videos.forEach((video) => video.pause())
  }

  return (
    <StyledRecipients data-testid="chain-recipients-header">
      <StyledSummary $hasFilters={Boolean(tags?.length)} $showDetailsButton={showDetailsButton}>
        <StyledBackButton aria-label="Back" onClick={goBack}>
          <FontAwesomeIcon color={COLORS?.TEXT} icon={faArrowLeft} />
        </StyledBackButton>
        <Skeleton height="16px" loading={isLoading} marginBottom="12px" marginTop="12px" width="50%">
          <Layout
            alignItems="center"
            display="inline-flex"
            maxWidth={communicationChannel !== CommunicationChannel.SMS ? 'calc(100% - 52px)' : 'calc(100% - 28px)'}
          >
            <EditableText
              aria-label={t('editCampaignName')}
              defaultValue={campaignName || undefined}
              emptyValue={`To: ${summary}`}
              onSave={(name) => {
                if (name.trim() !== campaignName) {
                  patchMessage({
                    body: {
                      name: name.trim(),
                    },
                    campaignId,
                    clientId,
                  })
                }
              }}
              placeholder={t('enterCampaignName')}
              showOnHoverEffect={false}
              typographyProps={{ fontSize: '16px', fontWeight: 600 }}
            />
            <Layout display="flex" flexShrink={0} marginLeft={`${SPACING[2]}`}>
              <CommunicationChannelIcon communicationChannel={communicationChannel} isAvatar={false} size={16} />
            </Layout>
          </Layout>
        </Skeleton>
      </StyledSummary>
      <StyledButtons>
        <CampaignMenu campaignId={campaignId} onDuplicateClick={setDuplicateCampaign} />
        {showDetailsButton && <Button onClick={openDetails}>Details</Button>}
      </StyledButtons>
      {duplicateCampaign && (
        <ComposeMessage
          file={duplicateCampaign.file}
          initialComposeState={duplicateCampaign.initialComposeState}
          initialFilterState={duplicateCampaign.initialFilterState}
          mediaDisposition={duplicateCampaign.mediaDisposition || undefined}
          onClose={() => setDuplicateCampaign(undefined)}
        />
      )}
    </StyledRecipients>
  )
}
