import { SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

export const ResponsiveWrapper = styled.div`
  padding: ${SPACING[5]} ${SPACING[5]} ${SPACING[9]};
  width: 100%;
  height: 100%;
  margin-right: auto;
  position: relative;
  overflow: auto;
`

export const StyledCloseButton = styled.button`
  position: absolute;
  width: 54px;
  height: 54px;
  right: 24px;
  top: 24px;
  border: 0;
  background: none;
  cursor: pointer;
  background: ${({ theme }) => theme.COLORS.WORKFLOW_EDITOR_CLOSE_BUTTON_BACKGROUND};
  color: ${({ theme }) => theme.COLORS.WORKFLOW_EDITOR_CLOSE_BUTTON_COLOR};
  border-radius: 50%;
  z-index: 1;
`
