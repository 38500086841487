import {
  BUTTON_VARIANTS,
  Button,
  CopyIcon,
  DropdownIndicator,
  Layout,
  Modal,
  Pill,
  SPACING,
  Switch,
  Option,
  Tooltip,
  Dialog,
  DIALOG_VARIANTS,
  Badge,
  Typography,
} from '@community_dev/pixels'
import { WebhookEventType, WebhookStatus } from '@community_dev/types/lib/api/v1/Webhook'
import copy from 'copy-to-clipboard'
import { useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import {
  StyledCaption,
  StyledCloseIcon,
  StyledError,
  StyledLink,
  StyledModalFooter,
  StyledSelect,
  StyledTextInput,
} from './webhooks.style'
import { WebhookStatistics } from './WebhookStatistics'

import { Webhook, WebhookUnsaved } from 'api/webhooks'
import { useWebhookStatistics } from 'containers/Settings/components/Integrations/queries'
import { useClientId } from 'hooks/useClient'
import { useToastMessage } from 'hooks/useToastMessage'

type WebhookOption = {
  label: string
  value: WebhookEventType
}

export type WebhookModalProps = {
  webhook: Webhook | WebhookUnsaved
  onClose: () => any
  onSubmit: (webhook: Webhook | WebhookUnsaved) => any
  onDelete: (webhook: Webhook) => any
}

const COL_WIDTH = '70px'

export function WebhookModal({ webhook, onClose, onSubmit, onDelete }: WebhookModalProps): JSX.Element {
  const { t } = useTranslation()
  const { COLORS } = useTheme()
  const clientId = useClientId()
  const { showToastMessage } = useToastMessage()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const isExistingWebhook = useCallback((webhook: Webhook | WebhookUnsaved): webhook is Webhook => 'id' in webhook, [])
  const {
    data: stats,
    refetch: refetchStats,
    isRefetching: isRefetchingStats,
    isLoading: isLoadingStats,
  } = useWebhookStatistics(clientId, isExistingWebhook(webhook) ? webhook.id : undefined, {
    enabled: isExistingWebhook(webhook),
    onError() {
      showToastMessage({ message: 'Could not fetch Webhook Statistics', success: false })
    },
  })
  const validateName = useCallback(
    (name = ''): string | undefined => {
      return name.trim() === '' ? t('settings.integrations.webhooks.errors.required') : undefined
    },
    [t],
  )
  const validateWebhookUrl = useCallback(
    (url: string): string | undefined => {
      if (!url) {
        return t('settings.integrations.webhooks.errors.required')
      } else if (!url.startsWith('https://')) {
        return t('settings.integrations.webhooks.errors.https')
      } else {
        // check if it’s a valid url
        try {
          new URL(url)
          return undefined
        } catch (e) {
          return t('settings.integrations.webhooks.errors.invalid')
        }
      }
    },
    [t],
  )
  const validateEventType = useCallback(
    (value: WebhookEventType[]) => {
      return value.length === 0 ? t('settings.integrations.webhooks.errors.required') : undefined
    },
    [t],
  )
  const webhookTypeOptions = [
    {
      value: WebhookEventType.INBOUND_MESSAGE,
      label: t('settings.integrations.webhooks.typeNames.inboundMessage'),
      description: t('settings.integrations.webhooks.typeDescriptions.inboundMessage'),
    },
    {
      value: WebhookEventType.OUTBOUND_MESSAGE,
      label: t('settings.integrations.webhooks.typeNames.outboundMessage'),
      description: t('settings.integrations.webhooks.typeDescriptions.outboundMessage'),
    },
    {
      value: WebhookEventType.MEMBER_UPDATE,
      label: t('settings.integrations.webhooks.typeNames.memberUpdate'),
      description: t('settings.integrations.webhooks.typeDescriptions.memberUpdate'),
    },
  ]
  return (
    <Modal onClose={onClose} open overflow="visible">
      {showDeleteDialog && isExistingWebhook(webhook) && (
        <Dialog
          message={t('settings.integrations.webhooks.confirmDeleteMessage')}
          onCancel={() => setShowDeleteDialog(false)}
          title={t('settings.integrations.webhooks.confirmDeleteTitle')}
        >
          <Dialog.Action onClick={() => setShowDeleteDialog(false)}>{t('cancel')}</Dialog.Action>
          <Dialog.Action
            onClick={async () => {
              await onDelete(webhook)
              onClose()
            }}
            variant={DIALOG_VARIANTS.DESTRUCTIVE}
          >
            {t('delete')}
          </Dialog.Action>
        </Dialog>
      )}
      <Form initialValues={webhook} onSubmit={onSubmit}>
        {({ invalid, values, submitting, handleSubmit }) => {
          return (
            <>
              <Modal.Header>
                <Modal.Header.Center>
                  {isExistingWebhook(webhook) ? (
                    <>
                      {webhook.name}
                      {stats?.webhook.status === WebhookStatus.WEBHOOK_STATUS_ERRORS && (
                        <Badge
                          background={COLORS.ERRORS}
                          borderColor={COLORS.ERRORS}
                          color={COLORS.BUTTON_PRIMARY_TEXT}
                          marginLeft={SPACING[2]}
                        >
                          {t('errors')}
                        </Badge>
                      )}
                    </>
                  ) : (
                    t('settings.integrations.webhooks.modal.titleCreate')
                  )}
                </Modal.Header.Center>
                <Modal.Header.Right onClose={onClose}>
                  <StyledCloseIcon color={COLORS.SUBTEXT} size={12} />
                </Modal.Header.Right>
              </Modal.Header>
              <Modal.Body style={{ padding: SPACING[6] }}>
                <Layout display="flex">
                  <Field initialValue={values.enabled} name="enabled" type="checkbox">
                    {({ input }) => (
                      <Layout width={COL_WIDTH}>
                        <StyledCaption>{t('enabled')}</StyledCaption>
                        <Tooltip
                          content={
                            input.checked
                              ? `${t('settings.integrations.webhooks.webhookEnabled')}. ${t(
                                  'settings.integrations.webhooks.clickToDisable',
                                )}`
                              : `${t('settings.integrations.webhooks.webhookDisabled')}. ${t(
                                  'settings.integrations.webhooks.clickToEnable',
                                )}`
                          }
                        >
                          <span style={{ display: 'inline-block' }}>
                            <Switch
                              aria-label={t('settings.integrations.webhooks.enableOrDisable')}
                              color={input.checked ? COLORS.SUCCESS : COLORS.BORDERS}
                              on={input.checked || false}
                              onChange={input.onChange}
                              style={{ marginTop: SPACING[3] }}
                            />
                          </span>
                        </Tooltip>
                      </Layout>
                    )}
                  </Field>
                  <Field name="name" validate={validateName}>
                    {({ input, meta }) => (
                      <Layout flex="1">
                        <StyledCaption>
                          <label htmlFor="name-input">{t('settings.integrations.webhooks.name')}</label> *
                        </StyledCaption>
                        <StyledTextInput
                          error={meta.modified && meta.error}
                          id="name-input"
                          onChange={input.onChange}
                          placeholder={t('settings.integrations.webhooks.namePlaceholder')}
                          value={input.value}
                        />
                      </Layout>
                    )}
                  </Field>
                </Layout>
                <Field name="url" validate={validateWebhookUrl}>
                  {({ input, meta }) => (
                    <Layout display="flex">
                      <Layout width={COL_WIDTH}>
                        <StyledCaption>{t('settings.integrations.webhooks.method')}</StyledCaption>
                        <Pill style={{ marginTop: SPACING[1], color: COLORS.SUBTEXT }}>POST</Pill>
                      </Layout>
                      <Layout flex="1">
                        <StyledCaption>
                          <label htmlFor="endpoint-input">{t('settings.integrations.webhooks.endpoint')}</label> *
                        </StyledCaption>
                        <StyledTextInput
                          error={meta.modified && meta.error}
                          id="endpoint-input"
                          onChange={input.onChange}
                          placeholder={t('settings.integrations.webhooks.endpointPlaceholder')}
                          value={input.value}
                        />
                      </Layout>
                    </Layout>
                  )}
                </Field>
                <Field name="signatureSecret">
                  {({ input }) => (
                    <Layout>
                      <StyledCaption htmlFor="secret-input">{t('settings.integrations.webhooks.secret')}</StyledCaption>
                      <Layout display="flex" gap={SPACING[2]}>
                        <StyledTextInput disabled id="secret-input" onChange={input.onChange} value={input.value} />
                        <Button
                          onClick={() => {
                            copy(input.value)
                            showToastMessage({ message: t('copiedToClipboard') })
                          }}
                          style={{ height: '42px', whiteSpace: 'nowrap', paddingRight: SPACING[6] }}
                          variant={BUTTON_VARIANTS.OUTLINE}
                        >
                          <CopyIcon /> {t('copy')}
                        </Button>
                      </Layout>
                      <StyledCaption>
                        <Trans i18nKey="settings.integrations.webhooks.secretDescription">
                          <StyledLink
                            href="https://developer.community.com/reference/webhooks-introduction"
                            target="_blank"
                          />
                        </Trans>
                      </StyledCaption>
                    </Layout>
                  )}
                </Field>
                <Field<WebhookEventType[]> name="types" validate={validateEventType}>
                  {({ input, meta }) => (
                    <Layout marginTop={SPACING[3]}>
                      <StyledCaption>
                        <label htmlFor="types-input">{t('settings.integrations.webhooks.eventTypes')}</label> *
                      </StyledCaption>
                      <StyledSelect
                        classNames={{
                          control: () => 'control',
                          valueContainer: () => 'value-container',
                        }}
                        closeMenuOnSelect={false}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator,
                          Option: (props) => (
                            <Option {...props}>
                              <Layout display="flex" flexDirection="column">
                                {props.children}
                                <Typography component="div" variant="caption1">
                                  {(props.data as any).description}
                                </Typography>
                              </Layout>
                            </Option>
                          ),
                          MultiValue: ({ children, removeProps }) => (
                            <Pill onRemove={removeProps.onClick} removable>
                              {children}
                            </Pill>
                          ),
                        }}
                        hideSelectedOptions={false}
                        inline={false}
                        inputId="types-input"
                        isMulti
                        onChange={(options) =>
                          input.onChange({
                            target: { value: (options as WebhookOption[]).map((option) => option.value) },
                          })
                        }
                        options={webhookTypeOptions}
                        value={input.value.map((value) => webhookTypeOptions.find((type) => type.value === value))}
                      />
                      {meta.modified && meta.error && (
                        <StyledError aria-live="polite">{meta.modified && meta.error}</StyledError>
                      )}
                      <StyledCaption>
                        <Trans i18nKey="settings.integrations.webhooks.eventTypesDescription">
                          <StyledLink
                            href="https://developer.community.com/reference/webhooks-introduction#payloads"
                            target="_bank"
                          >
                            developer.community.com
                          </StyledLink>
                        </Trans>
                      </StyledCaption>
                    </Layout>
                  )}
                </Field>
                {stats && isExistingWebhook(webhook) && (
                  <WebhookStatistics
                    isLoading={isLoadingStats || isRefetchingStats}
                    onRefresh={refetchStats}
                    value={stats}
                  />
                )}
              </Modal.Body>
              <StyledModalFooter $sticky={true}>
                <Layout display="flex" gap={SPACING[2]}>
                  <Button onClick={onClose} variant={BUTTON_VARIANTS.OUTLINE}>
                    {t('cancel')}
                  </Button>
                  {isExistingWebhook(webhook) && (
                    <Button
                      color={COLORS.ERRORS}
                      disabled={submitting}
                      onClick={() => setShowDeleteDialog(true)}
                      variant={BUTTON_VARIANTS.OUTLINE}
                    >
                      {t('settings.integrations.webhooks.deleteWebhook')}
                    </Button>
                  )}
                </Layout>
                <Button
                  disabled={invalid || submitting}
                  onClick={async () => {
                    await handleSubmit()
                    onClose()
                  }}
                  variant={BUTTON_VARIANTS.PRIMARY}
                >
                  {t('save')}
                </Button>
              </StyledModalFooter>
            </>
          )
        }}
      </Form>
    </Modal>
  )
}
