import { BUTTON_VARIANTS, Button, FONT_SIZE, SPACING } from '@community_dev/pixels'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { EmbedStatus } from 'api/mgu'
import { StyledOutlineButton } from 'screens/SettingsScreen/components/styled'

const StyledStatus = styled.div<{ $color: string }>`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  flex: 1;
  color: ${({ theme }) => theme?.COLORS?.TEXT};

  span {
    font-size: ${FONT_SIZE[5]};
    color: ${({ $color }) => $color};
    padding-right: ${SPACING[1]};
  }
`

type SaveActionsProps = {
  isSaving: boolean
  isValid: boolean
  status: EmbedStatus
  onSave(): any
  onPublish(): any
  onUnpublish(): any
}

export const SaveActions = (props: SaveActionsProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { isSaving, isValid, onSave, onPublish, onUnpublish, status } = props
  const isPublished = status === EmbedStatus.published
  const disabled = isSaving || !isValid
  return (
    <>
      <StyledStatus $color={isPublished ? COLORS?.SUCCESS : COLORS?.BORDERS}>
        <span>&bull;</span>
        {isPublished ? 'Published' : 'Draft'}
      </StyledStatus>
      <StyledOutlineButton
        disabled={disabled}
        onClick={isPublished ? onUnpublish : onPublish}
        variant={BUTTON_VARIANTS.OUTLINE}
      >
        {isPublished ? 'Unpublish' : 'Publish'}
      </StyledOutlineButton>
      <Button disabled={disabled} onClick={onSave}>
        Save Changes
      </Button>
    </>
  )
}
