import { SPACING, Typography } from '@community_dev/pixels'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { MessageLimitRow } from '../../MessageLimitRow'

import { InvoicesDetails } from './InvoicesDetails/InvoicesDetails'
import { SegmentBasedSubscription } from './SegmentBasedSubscription'
import { StyledActive, StyledPlanName, StyledSetting, StyledText } from './styled'
import { UsageBasedActivitySection } from './UsageBasedActivitySection'

import { useIsSegmentBasedSubscription } from 'hooks/billing/useIsSegmentBasedSubscription'
import { useIsTermedInvoice } from 'hooks/billing/useIsTermedInvoice'
import { useClient } from 'hooks/useClient'
import { SettingsSection } from 'layouts/SettingsLayout'

export const EnterpriseBillingSummary = (): JSX.Element => {
  const isTermedInvoice = useIsTermedInvoice()
  const { data: client } = useClient()
  const { t } = useTranslation()
  const planName = isTermedInvoice ? 'Termed Invoice' : 'Custom Plan'
  const { isSegmentBasedSubscription } = useIsSegmentBasedSubscription()

  if (isSegmentBasedSubscription) {
    return <SegmentBasedSubscription />
  }

  return (
    <>
      <InvoicesDetails />
      <SettingsSection>
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          {t('settings.billing.termedInvoice.yourPlan')}
        </Typography>
        <StyledSetting
          label={
            <>
              Community <StyledPlanName>{planName}</StyledPlanName>
            </>
          }
          text={<StyledText>{t('settings.billing.termedInvoice.contactPartnership')}</StyledText>}
        >
          <StyledActive onClick={() => void 0}>{t('settings.billing.termedInvoice.active')}</StyledActive>
        </StyledSetting>
      </SettingsSection>
      {client?.phoneNumberType === 'ten_dlc' && <UsageBasedActivitySection />}
      {client?.phoneNumberType === 'ten_dlc' && <MessageLimitRow />}
    </>
  )
}
