import { useMutation, useQuery } from '@tanstack/react-query'
import { jwtDecode } from 'jwt-decode'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router'
import useLocalStorageState from 'use-local-storage-state'

import { putSelectContext } from 'api/contexts'
import { RefreshToken, getRefreshToken } from 'api/login'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { AUTH_TOKENS, CLIENT_ID } from 'constants/web-storage'
import { QUERY_TOKEN } from 'contexts/bootstrap/constants'

export type AuthState = {
  state: AuthStates
  authUrl?: string
  authSecret?: string
  error?: string
  mfaToken?: string
}

export type AuthProviderValue =
  | AuthState & {
      capabilities?: RefreshToken['capabilities']
      token?: string
      transition: (action: AuthAction) => void
      isImpersonating: boolean
      clientId?: string
      setClientId: (value?: string) => void
    }

export enum AuthErrors {
  GENERIC = 'login.error.generic',
  ACTIVATION_EXPIRED = 'login.error.activationExpired',
  EMAIL_USED = 'login.error.emailUsed',
  TOO_MANY_ATTEMPTS = 'login.error.tooManyAttempts',
  INVALID_EMAIL_OR_PASSWORD = 'login.error.invalidEmailOrPassword',
  MFE_REQUIRED = 'login.error.mfaSetupEmail',
  MFA_GENERIC = 'login.error.mfaGeneric',
  MFA_LOCKED_OUT = 'login.error.mfaLockedOut',
  MFA_WRONG = `login.error.mfaWrong`,
}

export enum AuthStates {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  MFA_CHALLENGE = 'MFA_CHALLENGE',
  MFA_SETUP = 'MFA_SETUP',
  CONTEXT_SELECTION = 'CONTEXT_SELECTION',
  AUTHENTICATED = 'AUTHENTICATED',
}
export enum AuthActionTypes {
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_SUCCESS_SKIP_SELECTION = 'LOGIN_SUCCESS_SKIP_SELECTION',
  MFA_REQUIRED = 'MFA_REQUIRED',
  MFA_SETUP_REQUIRED = 'MFA_SETUP_REQUIRED',
  MFA_SUCCESS = 'MFA_SUCCESS',
  MFA_FAILED = 'MFA_FAILED',
  LOGOUT = 'LOGOUT',
  SELECT_CLIENT = 'SELECT_CLIENT',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
}

const transitions = {
  [AuthStates.UNAUTHENTICATED]: {
    [AuthActionTypes.LOGIN_SUCCESS]: AuthStates.CONTEXT_SELECTION,
    [AuthActionTypes.LOGIN_SUCCESS_SKIP_SELECTION]: AuthStates.AUTHENTICATED,
    [AuthActionTypes.MFA_REQUIRED]: AuthStates.MFA_CHALLENGE,
    [AuthActionTypes.MFA_SETUP_REQUIRED]: AuthStates.MFA_SETUP,
    [AuthActionTypes.LOGIN_FAILED]: AuthStates.UNAUTHENTICATED,
    [AuthActionTypes.LOGOUT]: AuthStates.UNAUTHENTICATED,
  },
  [AuthStates.MFA_CHALLENGE]: {
    [AuthActionTypes.MFA_SUCCESS]: AuthStates.CONTEXT_SELECTION,
    [AuthActionTypes.MFA_FAILED]: AuthStates.MFA_CHALLENGE,
    [AuthActionTypes.LOGOUT]: AuthStates.UNAUTHENTICATED,
  },
  [AuthStates.MFA_SETUP]: {
    [AuthActionTypes.MFA_SUCCESS]: AuthStates.CONTEXT_SELECTION,
    [AuthActionTypes.MFA_FAILED]: AuthStates.MFA_SETUP,
    [AuthActionTypes.LOGOUT]: AuthStates.UNAUTHENTICATED,
  },
  [AuthStates.CONTEXT_SELECTION]: {
    [AuthActionTypes.REFRESH_TOKEN]: AuthStates.CONTEXT_SELECTION,
    [AuthActionTypes.LOGOUT]: AuthStates.UNAUTHENTICATED,
    [AuthActionTypes.SELECT_CLIENT]: AuthStates.AUTHENTICATED,
  },
  [AuthStates.AUTHENTICATED]: {
    [AuthActionTypes.LOGIN_SUCCESS]: AuthStates.AUTHENTICATED,
    [AuthActionTypes.REFRESH_TOKEN]: AuthStates.AUTHENTICATED,
    [AuthActionTypes.LOGOUT]: AuthStates.UNAUTHENTICATED,
    [AuthActionTypes.SELECT_CLIENT]: AuthStates.AUTHENTICATED,
    [AuthActionTypes.MFA_SETUP_REQUIRED]: AuthStates.MFA_SETUP,
    [AuthActionTypes.MFA_REQUIRED]: AuthStates.MFA_CHALLENGE,
  },
}

type AuthAction =
  | {
      type: AuthActionTypes.LOGIN_FAILED
      payload: { error?: string }
    }
  | {
      type: AuthActionTypes.LOGIN_SUCCESS
      payload: { token: string; clientId?: string }
    }
  | {
      type: AuthActionTypes.LOGIN_SUCCESS_SKIP_SELECTION
      payload: { token: string; clientId: string }
    }
  | {
      type: AuthActionTypes.MFA_REQUIRED
      payload: { mfaToken: string; clientId?: string; error?: string }
    }
  | {
      type: AuthActionTypes.MFA_SETUP_REQUIRED
      payload: { mfaToken: string; authUrl: string; authSecret: string; clientId?: string; error?: string }
    }
  | {
      type: AuthActionTypes.MFA_SUCCESS
      payload: { token: string; clientId?: string }
    }
  | {
      type: AuthActionTypes.MFA_FAILED
      payload: { error?: string }
    }
  | {
      type: AuthActionTypes.LOGOUT
    }
  | {
      type: AuthActionTypes.SELECT_CLIENT
      payload: { token: string; clientId: string }
    }
  | {
      type: AuthActionTypes.REFRESH_TOKEN
      payload: { token: string; clientId?: string }
    }

type AuthProviderProps = {
  children?: React.ReactNode
}

export class AuthTransitionError extends Error {
  constructor(state: AuthStates, action: AuthActionTypes) {
    super(`Invalid transition. Cannot transition from state '${state}' with given action '${action}'`)
  }
}

export const AuthContext = createContext<AuthProviderValue | undefined>(undefined)

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const isInvitePath = useRouteMatch([ROUTES.INVITE])
  const isClientSelection = useRouteMatch([ROUTES.CLIENT_SELECTION])
  const isLogoutPath = useRouteMatch([ROUTES.LOGOUT])
  // Impersonation token comes from the query params and are stored in session storage
  // We don't assume it is impersonation token, we just check if it is there and use it
  const sessionToken = useMemo(() => {
    if (isLogoutPath) return undefined
    const params = new URLSearchParams(window.location.search)
    // the `token` query param also used for invites... and we want to ignore those.
    let token = !isInvitePath && params.get(QUERY_TOKEN)
    try {
      if (token) {
        // validate the token. This will throw if the token is not a valid jwt
        jwtDecode<{ imp: boolean }>(token)
        sessionStorage.setItem(AUTH_TOKENS, token)
      }
    } catch (e) {
      token = null
    }
    return token || sessionStorage.getItem(AUTH_TOKENS) || undefined
  }, [isInvitePath, isLogoutPath])
  const isImpersonating = useMemo(() => {
    if (sessionToken) {
      try {
        const { imp } = jwtDecode<{ imp: boolean }>(sessionToken)
        return imp
      } catch (e) {
        return false
      }
    }
    return false
  }, [sessionToken])
  const [localStorageClientId, setLocalStorageClientId, { removeItem: clearLocalStorageClientId }] =
    useLocalStorageState<string | null>(CLIENT_ID, {
      defaultValue: undefined,
      storageSync: false,
    })

  // We determine which clientId to use and store it in session storage for the duration of the session.
  // This value is only used on initial load, and is not used for subsequent requests.
  // The client ID from session storage is used for subsequent requests and is the source of truth.
  const [initialClientId] = useState(() => {
    // for impersonation we use the clientId from the query params
    const params = new URLSearchParams(window.location.search)
    const id = params.get(CLIENT_ID) || sessionStorage.getItem(CLIENT_ID) || localStorageClientId
    if (id) {
      sessionStorage.setItem(CLIENT_ID, id)
      setLocalStorageClientId(id)
    }
    return id || undefined
  })

  const clientId = sessionStorage.getItem(CLIENT_ID) || initialClientId

  const { mutateAsync: selectClient } = useMutation({
    mutationFn: putSelectContext,
    mutationKey: [QUERY_CACHE.CLIENT],
  })
  const [tokens, setTokens, { removeItem: clearLocalStorageTokens }] = useLocalStorageState<Record<string, string>>(
    AUTH_TOKENS,
    {
      defaultValue: {},
    },
  )
  const token = !isLogoutPath && clientId ? tokens[clientId] || undefined : undefined
  const clearTokens = useCallback(() => {
    clearLocalStorageTokens()
    sessionStorage.removeItem(AUTH_TOKENS)
  }, [clearLocalStorageTokens])

  const [auth, setAuth] = useState((): AuthState => {
    if ((sessionToken || token) && !isInvitePath) {
      return {
        state: isClientSelection ? AuthStates.CONTEXT_SELECTION : AuthStates.AUTHENTICATED,
      }
    }
    return {
      state: AuthStates.UNAUTHENTICATED,
    }
  })

  const transition = useCallback(
    (action: AuthAction) => {
      const nextState = transitions[auth.state][action.type]
      if (nextState === undefined) {
        throw new AuthTransitionError(auth.state, action.type)
      }
      // if we are logging out or transitioning to unauthenticated state, we clear the tokens
      if (action.type === AuthActionTypes.LOGOUT || nextState === AuthStates.UNAUTHENTICATED) {
        clearTokens()
      } else if (
        action.type !== AuthActionTypes.LOGIN_FAILED &&
        action.type !== AuthActionTypes.MFA_FAILED &&
        action.type !== AuthActionTypes.MFA_SETUP_REQUIRED &&
        action.type !== AuthActionTypes.MFA_REQUIRED
      ) {
        if (action.payload.token && (action.payload?.clientId || clientId)) {
          if (!isImpersonating) {
            setTokens((tokens) => ({ ...tokens, [action.payload?.clientId || clientId!]: action.payload.token }))
          } else {
            sessionStorage.setItem(AUTH_TOKENS, action.payload.token)
          }
        }
        if (action.payload?.clientId) {
          setLocalStorageClientId(action.payload.clientId)
          sessionStorage.setItem(CLIENT_ID, action.payload.clientId)
        }
      }
      if (action.type === AuthActionTypes.MFA_FAILED) {
        setAuth({
          state: nextState,
          mfaToken: auth.mfaToken,
          authUrl: auth.authUrl,
          authSecret: auth.authSecret,
          ...(action.payload || {}),
        })
      } else if (action.type !== AuthActionTypes.LOGOUT) {
        setAuth({
          state: nextState,
          ...(action.payload || {}),
        })
      } else {
        setAuth({
          state: nextState,
        })
      }
    },
    [auth.state, clearTokens, clientId, isImpersonating, setLocalStorageClientId, setTokens],
  )

  useEffect(() => {
    if (isLogoutPath) {
      clearTokens()
    }
  }, [clearTokens, isLogoutPath])

  useEffect(() => {
    if (auth.state !== AuthStates.UNAUTHENTICATED && ((!sessionToken && !token) || isLogoutPath)) {
      transition({ type: AuthActionTypes.LOGOUT })
    }
    // We only want to run this effect when the token changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const { data: { capabilities } = {} } = useQuery({
    queryKey: [QUERY_CACHE.TOKEN, clientId],
    queryFn: getRefreshToken,
    refetchOnWindowFocus: true,
    enabled: Boolean((sessionToken || token) && auth.state === AuthStates.AUTHENTICATED),
    onSuccess({ jwt }) {
      if (auth.state === AuthStates.AUTHENTICATED) {
        transition({ type: AuthActionTypes.REFRESH_TOKEN, payload: { token: jwt } })
      }
    },
  })

  const setClientId = useCallback(
    async (value?: string) => {
      if (!value) {
        sessionStorage.removeItem(CLIENT_ID)
        clearLocalStorageClientId()
      } else {
        const resp = await selectClient({
          body: { context: value },
        })
        transition({
          type: AuthActionTypes.SELECT_CLIENT,
          payload: { token: resp.data.token || resp.jwt, clientId: value },
        })
      }
    },
    [clearLocalStorageClientId, selectClient, transition],
  )

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        token: sessionToken || token,
        transition,
        isImpersonating,
        clientId,
        setClientId,
        capabilities,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
