import {
  BaseEmoji,
  Button,
  BUTTON_VARIANTS,
  EmojiPicker,
  PillVariant,
  Select,
  SPACING,
  Typography,
} from '@community_dev/pixels'
import { faMoon } from '@fortawesome/free-solid-svg-icons/faMoon'
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun'
import { useQuery } from '@tanstack/react-query'
import copy from 'copy-to-clipboard'
import { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { DefaultTheme } from 'styled-components'

import {
  StyledBox,
  StyledBoxContent,
  StyledBoxHeader,
  StyledBoxText,
  StyledBoxTitle,
  StyledColor,
  StyledColorContainer,
  StyledColorTextWrapper,
  StyledColorWrapper,
  StyledThemes,
  StyledIcon,
  StyledPill,
  StyledEmojis,
  StyledEmoji,
  StyledEmojiWrapper,
  StyledRadio,
  StyledLabel,
} from './Appearance.style'

import { QUERY_CACHE } from 'constants/query-cache'
import { InboxDensityDisplayModal } from 'containers/InboxDensityDisplayModal'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useSettings } from 'contexts/SettingsProvider'
import { themes, useThemeDispatch } from 'contexts/ThemeProvider'
import useColorScheme from 'hooks/useColorScheme'
import useCurrentTheme from 'hooks/useCurrentTheme'
import { Setting, SettingsSection } from 'layouts/SettingsLayout'
import { request } from 'utils/api'

type SelectOption = {
  label: string
  value: string
}

const determineDensityCopy = (density) => {
  if (density === 'multiline') return 'Multi-line'
  if (density === 'compact') return 'Collapsed'
}

const Appearance = (): JSX.Element => {
  const { t } = useTranslation()
  const { data: seat } = useSeat()
  const colorScheme = useColorScheme()
  const currentTheme = useCurrentTheme()
  const themeModeOptions = useMemo(
    () => [
      { value: 'light', label: t('settings.appearance.singleTheme') },
      { value: 'auto', label: t('settings.appearance.syncWithSystem') },
    ],
    [t],
  )
  const { dispatchTheme } = useThemeDispatch()
  const { settings, dispatchSettings } = useSettings()
  const [themeMode, setThemeMode] = useState<SelectOption>(
    themeModeOptions.find((o) => o.value === settings.colorMode) || themeModeOptions[0],
  )
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false)
  const [isDensityModalOpen, setIsDensityModalOpen] = useState(false)
  const [skinTone, setSkinTone] = useState(settings.skinTone || 1)
  const isFirstRender = useRef(true)

  const { data }: any = useQuery([QUERY_CACHE.PREFERENCES], () =>
    request(`/preferences/v1/seats/${seat?.id}`, { camelCase: false }),
  )

  const handleThemeModeChange = (option: SelectOption | null) => {
    if (option) {
      setThemeMode(option)
      dispatchSettings({
        type: 'UPDATE_KEY',
        params: {
          key: 'colorMode',
          value: option.value,
        },
      })
    }
  }

  const handleSkinToneClick = (newSkinTone: number) => {
    setSkinTone(newSkinTone)
    dispatchSettings({
      type: 'UPDATE_KEY',
      params: {
        key: 'skinTone',
        value: newSkinTone,
      },
    })
  }

  useEffect(() => {
    // prevent from running on mount
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    dispatchTheme({
      type: 'UPDATE',
      params: {
        theme: currentTheme.theme,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTheme.theme])

  return (
    <>
      <SettingsSection>
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          {t('settings.appearance.tab')}
        </Typography>
        <Setting
          label={t('settings.appearance.themeMode')}
          text={
            themeMode.value === themeModeOptions[0].value
              ? t('settings.appearance.singleThemeText')
              : t('settings.appearance.syncWithSystemText')
          }
        >
          <Select id="theme-mode" onChange={handleThemeModeChange} options={themeModeOptions} value={themeMode} />
        </Setting>
        {themeMode.value === themeModeOptions[0].value ? (
          <StyledThemes>
            {Object.entries(themes).map(
              ([, { active, color, key, label, theme, type }]) =>
                active && (
                  <StyledColorContainer key={key}>
                    <StyledColorWrapper
                      $active={key === currentTheme.key}
                      aria-label={t('settings.appearance.changeThemeTo', { label })}
                      onClick={() => {
                        dispatchTheme({
                          type: 'UPDATE',
                          params: {
                            theme,
                          },
                        })
                        dispatchSettings({
                          type: 'UPDATE_KEY',
                          params: {
                            key: 'colorMode',
                            value: type,
                          },
                        })
                        dispatchSettings({
                          type: 'UPDATE_KEY',
                          params: {
                            key: type === 'dark' ? 'darkTheme' : 'lightTheme',
                            value: key,
                          },
                        })
                      }}
                    >
                      <StyledColor $color={color} />
                    </StyledColorWrapper>
                    <StyledColorTextWrapper>
                      <strong>{label}</strong>
                    </StyledColorTextWrapper>
                  </StyledColorContainer>
                ),
            )}
          </StyledThemes>
        ) : (
          <StyledThemes>
            <StyledBox>
              <StyledBoxHeader>
                <StyledBoxTitle>
                  <StyledIcon icon={faSun} />
                  {t('settings.appearance.dayTheme')}
                </StyledBoxTitle>
                {colorScheme === 'light' && (
                  <StyledPill variant={PillVariant.OUTLINED}>{t('settings.appearance.active')}</StyledPill>
                )}
              </StyledBoxHeader>
              <StyledBoxContent>
                <StyledBoxText>{t('settings.appearance.dayThemeDescription')}</StyledBoxText>
                <StyledThemes>
                  {Object.entries(themes).map(
                    ([, { active, color, key, label, theme }]) =>
                      active && (
                        <StyledColorContainer key={key}>
                          <StyledColorWrapper
                            $active={key === settings.lightTheme}
                            aria-label={t('settings.appearance.changeThemeTo', { label })}
                            onClick={() => {
                              if (colorScheme === 'light') {
                                dispatchTheme({
                                  type: 'UPDATE',
                                  params: {
                                    theme: theme as DefaultTheme,
                                  },
                                })
                              }
                              dispatchSettings({
                                type: 'UPDATE_KEY',
                                params: {
                                  key: 'lightTheme',
                                  value: key,
                                },
                              })
                            }}
                          >
                            <StyledColor $color={color} />
                          </StyledColorWrapper>
                          <StyledColorTextWrapper>
                            <strong>{label}</strong>
                          </StyledColorTextWrapper>
                        </StyledColorContainer>
                      ),
                  )}
                </StyledThemes>
              </StyledBoxContent>
            </StyledBox>
            <StyledBox>
              <StyledBoxHeader>
                <StyledBoxTitle>
                  <StyledIcon icon={faMoon} />
                  {t('settings.appearance.nightTheme')}
                </StyledBoxTitle>
                {colorScheme === 'dark' && (
                  <StyledPill variant={PillVariant.OUTLINED}>{t('settings.appearance.active')}</StyledPill>
                )}
              </StyledBoxHeader>
              <StyledBoxContent>
                <StyledBoxText>{t('settings.appearance.nightThemeDescription')}</StyledBoxText>
                <StyledThemes>
                  {Object.entries(themes).map(
                    ([, { active, color, key, label, theme }]) =>
                      active && (
                        <StyledColorContainer key={key}>
                          <StyledColorWrapper
                            $active={key === settings.darkTheme}
                            aria-label={t('settings.appearance.changeThemeTo', { label })}
                            onClick={() => {
                              if (colorScheme === 'dark') {
                                dispatchTheme({
                                  type: 'UPDATE',
                                  params: {
                                    theme: theme as DefaultTheme,
                                  },
                                })
                              }
                              dispatchSettings({
                                type: 'UPDATE_KEY',
                                params: {
                                  key: 'darkTheme',
                                  value: key,
                                },
                              })
                            }}
                          >
                            <StyledColor $color={color} />
                          </StyledColorWrapper>
                          <StyledColorTextWrapper>
                            <strong>{label}</strong>
                          </StyledColorTextWrapper>
                        </StyledColorContainer>
                      ),
                  )}
                </StyledThemes>
              </StyledBoxContent>
            </StyledBox>
          </StyledThemes>
        )}
        <Setting label={t('settings.appearance.emojiSkinTone')} text={t('settings.appearance.preferredSkinTone')}>
          {isEmojiPickerOpen ? (
            <Button onClick={() => setIsEmojiPickerOpen(false)} variant={BUTTON_VARIANTS.OUTLINE}>
              {t('settings.appearance.closeEmojiPicker')}
            </Button>
          ) : (
            <Button onClick={() => setIsEmojiPickerOpen(true)} variant={BUTTON_VARIANTS.OUTLINE}>
              {t('settings.appearance.openEmojiPicker')}
            </Button>
          )}
          {isEmojiPickerOpen && (
            <EmojiPicker
              onSelect={(emoji: BaseEmoji) => {
                copy(emoji.native)
                setIsEmojiPickerOpen(false)
                toast.success(t('settings.appearance.copiedEmoji', { emoji: emoji.native }))
              }}
              rootStyle={{ top: '0', left: '-480px' }}
              showPreview={true}
              skin={skinTone}
              theme={currentTheme.type}
            />
          )}
        </Setting>

        <StyledEmojis>
          <StyledEmojiWrapper>
            <StyledLabel htmlFor="neutral">
              <StyledRadio
                checked={skinTone === 1}
                id="neutral"
                name="tone"
                onChange={() => handleSkinToneClick(1)}
                type="radio"
              />
              <StyledEmoji aria-label="Neutral">👋</StyledEmoji>
            </StyledLabel>
          </StyledEmojiWrapper>
          <StyledEmojiWrapper>
            <StyledLabel htmlFor="light">
              <StyledRadio
                checked={skinTone === 2}
                id="light"
                name="tone"
                onChange={() => handleSkinToneClick(2)}
                type="radio"
              />
              <StyledEmoji aria-label="Light Skin Tone">👋🏻</StyledEmoji>
            </StyledLabel>
          </StyledEmojiWrapper>
          <StyledEmojiWrapper>
            <StyledLabel htmlFor="medium-light">
              <StyledRadio
                checked={skinTone === 3}
                id="medium-light"
                name="tone"
                onChange={() => handleSkinToneClick(3)}
                type="radio"
              />
              <StyledEmoji aria-label="Medium-Light Skin Tone">👋🏼</StyledEmoji>
            </StyledLabel>
          </StyledEmojiWrapper>
          <StyledEmojiWrapper>
            <StyledLabel htmlFor="medium">
              <StyledRadio
                checked={skinTone === 4}
                id="medium"
                name="tone"
                onChange={() => handleSkinToneClick(4)}
                type="radio"
              />
              <StyledEmoji aria-label="Medium Skin Tone">👋🏽</StyledEmoji>
            </StyledLabel>
          </StyledEmojiWrapper>
          <StyledEmojiWrapper>
            <StyledLabel htmlFor="medium-dark">
              <StyledRadio
                checked={skinTone === 5}
                id="medium-dark"
                name="tone"
                onChange={() => handleSkinToneClick(5)}
                type="radio"
              />
              <StyledEmoji aria-label="Medium-Dark Skin Tone">👋🏾</StyledEmoji>
            </StyledLabel>
          </StyledEmojiWrapper>
          <StyledEmojiWrapper>
            <StyledLabel htmlFor="dark">
              <StyledRadio
                checked={skinTone === 6}
                id="dark"
                name="tone"
                onChange={() => handleSkinToneClick(6)}
                type="radio"
              />
              <StyledEmoji aria-label="Dark Skin Tone">👋🏿</StyledEmoji>
            </StyledLabel>
          </StyledEmojiWrapper>
        </StyledEmojis>
      </SettingsSection>
      <SettingsSection>
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          {t('settings.appearance.inboxDensityDisplay')}
        </Typography>
        <Setting
          action={t('settings.change')}
          label={determineDensityCopy(data?.data?.inbox_display_density)}
          onClickAction={() => setIsDensityModalOpen(true)}
          text={t('settings.appearance.controlLinesPreviewed')}
        />
        <InboxDensityDisplayModal isOpen={isDensityModalOpen} onClose={() => setIsDensityModalOpen(false)} />
      </SettingsSection>
    </>
  )
}

export default Appearance
