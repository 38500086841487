import { Info, SPACING } from '@community_dev/pixels'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ROUTES } from 'constants/routes'
import analytics from 'utils/analytics'

const StyledInfo = styled(Info)`
  margin: ${SPACING[4]} 0;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  text-decoration: none;
`

const trackResources = () => {
  analytics.track(analytics.events.TapResourcesInMemberGrowth())
}

export const LearnHowToGrow = (): JSX.Element => {
  return (
    <StyledInfo>
      <Trans i18nKey="insightMasthead.learnToGrow">
        <StyledLink onClick={trackResources} to={ROUTES.GROWTH_TOOLS} />
      </Trans>
    </StyledInfo>
  )
}
