import { SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import noop from 'lodash/noop'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { BlockFan } from './BlockFan'
import { ReportFan } from './ReportFan'
import { UnsubscribeFan } from './UnsubscribeFan'

import { Community } from 'api/community'
import { ActionPanel } from 'components/ActionPanel'
import { CustomMemberData } from 'components/ActionPanel/CustomMemberData'
import { MemberCommunities } from 'components/ActionPanel/MemberCommunities'
import { MemberDetails } from 'components/ActionPanel/MemberDetails'
import { MemberShopify } from 'components/ActionPanel/MemberShopify'
import CommunicationChannelIcon from 'components/CommunicationChannelIcon'
import { MemberAvatar } from 'components/MemberAvatar'
import { CAPABILITIES } from 'constants/capabilities'
import { PANELS } from 'constants/panels'
import { ROUTES } from 'constants/routes'
import { ConvoModal } from 'containers/ConvoModal'
import { MemberDetailsActions } from 'containers/InboxChat'
import { useCanSendDms } from 'hooks/useCanSendDms'
import { useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useFan } from 'hooks/useFan'
import { useMemberDataPermissions } from 'hooks/useMemberDataPermissions'
import { useMessageHistory } from 'hooks/useMessageHistory'
import { useShopify } from 'hooks/useShopify'
import { useHasCapability } from 'hooks/useUserCapability'

const StyledHeader = styled.header`
  text-align: center;

  h3 {
    margin-top: ${SPACING[1]};
  }
`

type MemberDetailActionPanelProps = {
  communicationChannel?: CommunicationChannel
  communities?: Community[]
  fanId?: string
  onBack?: () => void
  onClose?: () => void
}

export const MemberDetailActionPanel = ({
  communities = [],
  fanId = '',
  onBack,
  onClose = noop,
}: MemberDetailActionPanelProps): JSX.Element | null => {
  const history = useHistory()
  const clientId = useClientId()
  const { canReadMemberData } = useMemberDataPermissions()
  const [isConvoModalOpen, setIsConvoModalOpen] = useState(false)
  const { t } = useTranslation()

  const { data: fan, isInitialLoading: isLoading, isError } = useFan(fanId)

  const { data: shopifyCommunity } = useCommunities({
    select: (communities) => {
      return communities.protected.find((community) => community.title === 'Shopify')
    },
  })

  const { messageHistory } = useMessageHistory({ fanId })

  const lastMessage = useMemo(() => [...messageHistory].reverse().find((message) => message.inbound), [messageHistory])

  const lastMessageId = lastMessage?.id
  const lastMessageBody = lastMessage?.text
  const lastMessageTimestamp = lastMessage?.createdAt
  const lastMessageHasMedia = Boolean(lastMessage?.media)

  const isWhatsAppConversation = fan?.communicationChannel === CommunicationChannel.WHATS_APP
  const isAmbConversation = fan?.communicationChannel === CommunicationChannel.AMB
  const isSMSConversation = fan?.communicationChannel === CommunicationChannel.SMS
  const isCmdOnFanDetailEnabled =
    useHasCapability(CAPABILITIES.FEATURE.CUSTOM_MEMBER_DATA.ALL) &&
    canReadMemberData &&
    (isSMSConversation || isWhatsAppConversation)

  const canReceiveReply = useCanSendDms({
    communicationChannel: fan?.communicationChannel,
    stateFlags: fan?.stateFlags,
    conversationWindowEndAt: fan?.conversationWindowEndAt,
  })

  const canEditCommunities = !(fan?.communicationChannel === CommunicationChannel.AMB)

  const {
    errors: shopifyErrors,
    customerDetails,
    shopifyExists,
  } = useShopify(clientId, shopifyCommunity, fan?.fanSubscriptionId)

  function handleToggleAddToCommunity() {
    history.push(ROUTES.MEMBER_DETAIL_ACTION_PANEL.ADD_TO_COMMUNITY)
  }

  const fanName = fan?.fullName || fan?.displayName || ''
  const fanSubscriptionId = fan?.fanSubscriptionId || ''

  if (isError) {
    return (
      <ActionPanel name={PANELS.MEMBER_DETAIL} onBack={onBack} onClose={onClose}>
        <p>{t('memberActions.couldNotLoadData')}</p>
      </ActionPanel>
    )
  }

  if (fan) {
    return (
      <ActionPanel name={PANELS.MEMBER_DETAIL} onBack={onBack} onClose={onClose}>
        {isConvoModalOpen && <ConvoModal fanId={fan.id} onClose={() => setIsConvoModalOpen(false)} />}
        <StyledHeader>
          <MemberAvatar
            badge={
              <CommunicationChannelIcon
                communicationChannel={fan.communicationChannel}
                filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))"
                size={19}
                style={{ marginTop: SPACING[2] }}
              />
            }
            fan={fan}
            graphicUrl={fan.graphicUrl}
          />
          <h3>{fanName ? fanName : '\u00A0'}</h3>
        </StyledHeader>
        <MemberDetailsActions
          disableCommunities={!canEditCommunities}
          disableMessage={!canReceiveReply}
          disableMessageReason={
            isWhatsAppConversation
              ? t('convo.whatsAppMessagingDisabled')
              : isAmbConversation
              ? t('convo.ambMessagingDisabled')
              : undefined
          }
          fan={fan}
          onClickAddToCommunity={handleToggleAddToCommunity}
          onClickMessage={() => setIsConvoModalOpen(true)}
        />
        <MemberDetails fan={fan} isLoading={isLoading} />
        <MemberCommunities
          communities={communities}
          fan={fan}
          isLoading={isLoading}
          onAddToCommunity={handleToggleAddToCommunity}
        />
        {isCmdOnFanDetailEnabled && (
          <CustomMemberData
            fan={fan}
            onViewMoreClick={() => history.push(ROUTES.MEMBER_DETAIL_ACTION_PANEL.VIEW_ALL_CMD)}
          />
        )}

        {isSMSConversation && shopifyExists && (
          <MemberShopify customerDetails={customerDetails} errors={shopifyErrors} isLoading={isLoading} />
        )}

        <UnsubscribeFan fanId={fanId} fanName={fanName} />

        <ReportFan
          fanId={fanId}
          fanName={fanName}
          fanSubscriptionId={fanSubscriptionId}
          lastMessageBody={lastMessageBody ?? undefined}
          lastMessageHasMedia={lastMessageHasMedia}
          lastMessageId={lastMessageId}
          lastMessageTimestamp={lastMessageTimestamp}
        />

        <BlockFan
          fanId={fanId}
          fanName={fanName}
          fanSubscriptionId={fanSubscriptionId}
          lastMessageBody={lastMessageBody ?? undefined}
          lastMessageHasMedia={lastMessageHasMedia}
          lastMessageId={lastMessageId}
          lastMessageTimestamp={lastMessageTimestamp}
        />
      </ActionPanel>
    )
  }

  return null
}
