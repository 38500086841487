import { ENDPOINTS } from 'constants/endpoints'
import en from 'languages/en.json'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type Timezone = {
  timeZoneGroup?: keyof typeof en.timezones
  representativeTzid: string
  popular?: boolean
  local?: boolean
}

type PostTimezonesProps = {
  localTimeZoneId: string
}

export function getTimezones({ localTimeZoneId }: PostTimezonesProps): Promise<Timezone[]> {
  return request(
    route(
      ENDPOINTS.TIMEZONES,
      {},
      {
        local_time_zone_id: encodeURIComponent(localTimeZoneId),
      },
    ),
  ).then(({ data }) => data)
}
