import { Dialog, DIALOG_VARIANTS } from '@community_dev/pixels'
import { Trans, useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import styled from 'styled-components'

import { ROUTES } from 'constants/routes'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useClient, useClientId } from 'hooks/useClient'
import analytics from 'utils/analytics'

const StyledLink = styled.a`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  text-decoration: none;
  white-space: nowrap;

  :hover {
    text-decoration: underline;
  }
`

type LimitDialogProps = {
  submitText?: string
  onClose?(): void
  onSubmit?(): void
}

export const LimitDialog = ({ submitText, onClose, onSubmit }: LimitDialogProps): JSX.Element => {
  const { t } = useTranslation()
  const { data: client } = useClient()
  const clientId = useClientId()
  const { data: seat } = useSeat()
  const isScheduledScreen = useRouteMatch(ROUTES.SCHEDULED.ROOT)

  const subject = t('settings.billing.messageLimits.mailSubject')
  const body = t('settings.billing.messageLimits.mailBody', {
    clientId: clientId,
    clientName: client?.fullName,
    seatName: `${seat?.firstName} ${seat?.lastName}`,
    seatEmail: seat?.email,
  })
  const mailLink = `mailto:yourfriends@community.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    body,
  )}`

  const handleClose = () => {
    analytics.track(analytics.events.TCR.ClosedComposeWarning())
    if (onClose) {
      onClose()
    }
  }

  const handleSubmit = () => {
    analytics.track(analytics.events.TCR.ComposeAnyway())
    if (onSubmit) {
      onSubmit()
    }
  }

  return (
    <Dialog
      maxWidth={310}
      message={
        <>
          {t(
            isScheduledScreen
              ? 'tcr.carrierLimit.limitDialogDescriptionScheduled'
              : 'tcr.carrierLimit.limitDialogDescription',
          )}{' '}
          <Trans i18nKey="tcr.carrierLimit.contactUsToIncrease">
            <StyledLink href={mailLink} onClick={() => analytics.track(analytics.events.TCR.ContactUs())}>
              {t('tcr.carrierLimit.contactUs')}
            </StyledLink>
          </Trans>
        </>
      }
      onCancel={handleClose}
      title={t('tcr.carrierLimit.limitDialogTitle')}
    >
      <Dialog.Action onClick={handleClose} variant={DIALOG_VARIANTS.EMPHASIZED}>
        {t('tcr.carrierLimit.cancelMessage')}
      </Dialog.Action>
      <Dialog.Action onClick={handleSubmit} variant={DIALOG_VARIANTS.DESTRUCTIVE}>
        {submitText || t('tcr.carrierLimit.composeAnyway')}
      </Dialog.Action>
    </Dialog>
  )
}
