import { Box, Image, Toast, Typography } from '@community_dev/pixels'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { Confirm } from '../components/Confirm'
import { Description } from '../components/Description'
import { Examples } from '../components/Examples'
import { Preview } from '../components/Preview'
import { ONBOARDING_TASKS, ONBOARDING_TASK_COMPLETE, WELCOME_MESSAGE_KEY } from '../constants'
import { useCompleteClientTask, useUpdateFanMessage } from '../queries'

import { EditMessage } from './components/EditMessage'

import { ROUTES } from 'constants/routes'
import { useClient } from 'hooks/useClient'
import { useIsReplyYOn } from 'hooks/useIsReplyYOn'
import { useOnboardingMessage, useOnboardingMessages } from 'hooks/useOnboardingMessages'
import Sentry from 'integrations/Sentry'
import texts from 'texts.json'
import { generalConfig } from 'utils/config'

const WELCOME_MESSAGE_ASSETS = {
  newYorkYankees: (
    <Image
      alt="In the first text you receive from the New York Yankees, they make it known Community is their new way of communicating."
      src={[
        `${generalConfig.staticUrl}/welcome-messages/welcome-message-new-york-yankees%403x.png 3x`,
        `${generalConfig.staticUrl}/welcome-messages/welcome-message-new-york-yankees%402x.png 2x`,
        `${generalConfig.staticUrl}/welcome-messages/welcome-message-new-york-yankees.png 1x`,
      ]}
    />
  ),
  bravo: (
    <Image
      alt="Bravo uses their welcome message to notify fans exactly what you will be getting by texting their Community number: Major updates, exclusive content and so much more."
      src={[
        `${generalConfig.staticUrl}/welcome-messages/welcome-message-bravo403x.png 3x`,
        `${generalConfig.staticUrl}/welcome-messages/welcome-message-bravo%402x.png 2x`,
        `${generalConfig.staticUrl}/welcome-messages/welcome-message-bravo.png 1x`,
      ]}
    />
  ),
  lukeCombs: (
    <Image
      alt="Luke Combs lets his audience know just what he plans on texting out in the future as well as the steps to complete to stay connected with him."
      src={[
        `${generalConfig.staticUrl}/welcome-messages/welcome-message-luke-combs%403x.png 3x`,
        `${generalConfig.staticUrl}/welcome-messages/welcome-message-luke-combs%402x.png 2x`,
        `${generalConfig.staticUrl}/welcome-messages/welcome-message-luke-combs.png 1x`,
      ]}
    />
  ),
}

const REPLY_Y_WELCOME_MESSAGE_ASSETS = {
  modernDesigns: (
    <Image
      alt="Modern Designs Example"
      src={[
        `${generalConfig.staticUrl}/welcome-messages/reply-y-image-mockup-welcome-message-modern-designs%403x.png 3x`,
        `${generalConfig.staticUrl}/welcome-messages/reply-y-image-mockup-welcome-message-modern-designs%402x.png 2x`,
        `${generalConfig.staticUrl}/welcome-messages/reply-y-image-mockup-welcome-message-modern-designs.png 1x`,
      ]}
    />
  ),
}

enum State {
  INITIAL = 'initial',
  EDIT = 'edit',
  PREVIEW = 'preview',
  CONFIRM = 'confirm',
}

const PREVIEW_STATES = [State.PREVIEW, State.CONFIRM]

export function WelcomeMessage(): JSX.Element {
  const history = useHistory()
  const message = useOnboardingMessage(WELCOME_MESSAGE_KEY)
  const { data: client } = useClient()
  const task = client?.onboardingTasks?.tasks[ONBOARDING_TASKS.WELCOME_MESSAGE]
  const [state, setState] = useState<State>(State.INITIAL)
  const [text, setText] = useState<string>('')
  const [error, setError] = useState<string>()
  const { t } = useTranslation()
  const isReplyYOn = useIsReplyYOn()
  const {
    welcomeMessage: { maxSegmentCount, maxLength, legalMessage },
  } = useOnboardingMessages()
  const { mutate: updateTask } = useCompleteClientTask({
    onError(e) {
      setError(texts.errors.generic)
      Sentry.captureException(e)
    },
    onSuccess() {
      history.push(ROUTES.ONBOARDING.SUMMARY)
    },
  })
  const { mutate: submit, isLoading: isProcessing } = useUpdateFanMessage({
    onError(e) {
      setError(texts.errors.generic)
      Sentry.captureException(e)
    },
    onSuccess() {
      updateTask({ id: task!.id })
    },
  })

  useEffect(() => {
    if (!task) return
    setState(task.status === ONBOARDING_TASK_COMPLETE ? State.PREVIEW : State.INITIAL)
  }, [task])

  useEffect(() => {
    if (!message) return
    setText(message.text)
  }, [message])

  const tips = isReplyYOn
    ? [t('onboarding.welcomeMessage.listTip1'), t('onboarding.welcomeMessage.listTip2')]
    : [
        t('onboarding.welcomeMessage.listTip1'),
        t('onboarding.welcomeMessage.listTip2'),
        t('onboarding.welcomeMessage.listTip3'),
      ]

  function handleSave(value) {
    setText(value)
    setState(State.CONFIRM)
  }

  function handleConfirm() {
    setError(undefined)
    if (message?.id) {
      submit({ id: message.id, text })
    }
  }

  return (
    <>
      <Box>
        {error && (
          <Box.ToastArea>
            <Toast icon message={error} success={false} />
          </Box.ToastArea>
        )}
        <Box.Content>
          {state === State.INITIAL && (
            <Description
              actionLabel="Set welcome message"
              alt={t('onboarding.welcomeMessage.welcomeMessageGifAltText')}
              animation="animation-welcome-message.gif"
              onAction={() => setState(State.EDIT)}
            >
              <Typography variant="h1">Welcome Message</Typography>
              <p>{t('onboarding.welcomeMessage.initialBody')}</p>
            </Description>
          )}
          {state === State.EDIT && (
            <EditMessage
              charMax={maxLength}
              complete={task?.status === ONBOARDING_TASK_COMPLETE}
              defaultValue={text}
              legalMessage={legalMessage}
              onCancel={() => setState(State.PREVIEW)}
              onSave={handleSave}
              segmentMax={maxSegmentCount}
            />
          )}
          {state === State.PREVIEW && (
            <Preview
              message={text}
              onEdit={() => {
                setError(undefined)
                setState(State.EDIT)
              }}
            >
              <Typography variant="h1">Your welcome message</Typography>
              <p>This is the first message people receive when they text you.</p>
            </Preview>
          )}
          {state === State.CONFIRM && (
            <Confirm
              message={text}
              onConfirm={handleConfirm}
              onEdit={() => {
                setError(undefined)
                setState(State.EDIT)
              }}
              processing={isProcessing}
              tcpaMessage={legalMessage}
            >
              <Typography variant="h1">Your welcome message</Typography>
              <p>This is the first message people receive when they text you.</p>
            </Confirm>
          )}
        </Box.Content>
      </Box>
      {!PREVIEW_STATES.includes(state) && (
        <Examples examples={isReplyYOn ? REPLY_Y_WELCOME_MESSAGE_ASSETS : WELCOME_MESSAGE_ASSETS} tips={tips} />
      )}
    </>
  )
}
