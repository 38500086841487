import { SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const SlideBody = styled.span`
  padding: ${SPACING[5]};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  text-align: center;
  margin-top: 0px;
  overflow: auto;
`
