import {
  BORDER_RADIUS,
  BUTTON_VARIANTS,
  Button,
  ChevronRightIcon,
  ComposeIcon,
  FONT_SIZE,
  Info,
  InfoIcon,
  SPACING,
} from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { ProtectedCommunityNames } from 'api/community'
import { ROUTES } from 'constants/routes'
import { useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useShopify, useShopifyInsights } from 'hooks/useShopify'
import { InsightsBox } from 'screens/InsightsScreen/presentation/InsightsBox'
import { route } from 'utils/router'

const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${SPACING[5]};
  grid-row-gap: ${SPACING[5]};
  margin-top: ${SPACING[5]};
  @media screen and (max-width: 1124px) {
    grid-template-columns: 1fr;
  }
`

const StyledButton = styled(Button)`
  margin-top: ${SPACING[2]};
  font-size: ${FONT_SIZE[3]};
`

const StyledLabel = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`
const StyledValue = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  margin-bottom: ${SPACING[4]};
`
const StyledDataInfo = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  display: flex;
  align-items: center;
  svg {
    margin: 0 ${SPACING[1]} 0 0;
  }
`

const StyledShopifyCommunityLink = styled(Link)`
  padding: ${SPACING[4]};
  border-radius: ${BORDER_RADIUS[1]};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  cursor: pointer;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  text-decoration: none;
`

type StyledCommunityColorProps = {
  $color: string
}

const StyledCommunityColor = styled.div<StyledCommunityColorProps>`
  width: ${SPACING[2]};
  height: ${SPACING[2]};
  background: ${({ $color }) => $color};
  border-radius: 50%;
  display: inline-block;
`

const numberFormatter = new Intl.NumberFormat('en-US')
const currencyFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  maximumFractionDigits: 1,
})

const loadingView = (
  <ContentLoader height={57} viewBox="0 0 266 57">
    <rect height="20" width="128" y="6" />
    <rect height="20" width="266" y="37" />
  </ContentLoader>
)

type ShopifyInsightsProps = {
  hideCommunityLink?: boolean
}

const formatCurrency = (amount: string) => {
  // to avoid floating point issues, we will manipulate the string.
  const [dollars] = amount.split('.')
  if (dollars.length < 4) {
    return amount
  }
  return currencyFormatter.format(parseFloat(amount)).toLowerCase()
}

export const Shopify = ({ hideCommunityLink, ...props }: ShopifyInsightsProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const clientId = useClientId()
  const { data: shopifyCommunity } = useCommunities({
    select: (communities) => communities.all.find((community) => community.title === ProtectedCommunityNames.SHOPIFY),
  })

  const insights = useShopifyInsights(clientId)
  const { communityCount = 0 } = useShopify(clientId, shopifyCommunity)
  const queries = [insights]

  return (
    <ChartGrid {...props}>
      <div>
        <InsightsBox.Managed
          emptyView={
            <>
              <Info heading={t('shopifyInsights.noDataAvailable')} icon={<FontAwesomeIcon icon="exclamation-circle" />}>
                {t('shopifyInsights.howToAttributeLinks')}
                <div>
                  <StyledButton
                    color={COLORS?.LINKS}
                    forwardedAs="a"
                    href="https://incommunity.zendesk.com/hc/en-us/articles/4404791762195-Shopify-revenue-from-sent-messages"
                    target="_blank"
                    variant={BUTTON_VARIANTS.LINK}
                  >
                    {t('learnMore')}
                  </StyledButton>
                </div>
              </Info>

              <StyledDataInfo>
                <InfoIcon /> {t('shopifyInsights.dataUpdateDelay')}
              </StyledDataInfo>
            </>
          }
          height="auto"
          isEmpty={insights.data?.totalRevenue?.amount === undefined}
          loadingView={loadingView}
          queries={queries}
          title={t('shopifyInsights.revenue')}
        >
          {() => {
            const { amount, currency } = insights.data?.totalRevenue || {}
            return (
              <>
                <StyledLabel>{t('shopifyInsights.fromSentMessages')}</StyledLabel>
                <StyledValue>
                  ${formatCurrency(amount || '0')}
                  {currency !== 'USD' && ` ${currency}`}
                </StyledValue>
                <Info icon={<ComposeIcon />}>
                  {t('shopifyInsights.sendMembersALink')}
                  <div>
                    <StyledButton
                      color={COLORS?.LINKS}
                      forwardedAs="a"
                      href="https://incommunity.zendesk.com/hc/en-us/articles/4404791762195-Shopify-revenue-from-sent-messages"
                      target="_blank"
                      variant={BUTTON_VARIANTS.LINK}
                    >
                      {t('learnMore')}
                    </StyledButton>
                  </div>
                </Info>
                <StyledDataInfo>
                  <InfoIcon /> {t('shopifyInsights.dataUpdateDelay')}
                </StyledDataInfo>
              </>
            )
          }}
        </InsightsBox.Managed>
      </div>

      <div>
        <InsightsBox.Managed height="auto" loadingView={loadingView} queries={queries} title={t('members')}>
          {() => {
            return (
              <>
                <StyledLabel>{t('shopifyInsights.joinedViaShopify')}</StyledLabel>
                <StyledValue>{numberFormatter.format(communityCount)}</StyledValue>
                {!hideCommunityLink && shopifyCommunity?.id && (
                  <StyledShopifyCommunityLink to={route(ROUTES.COMMUNITY, { id: shopifyCommunity.id })}>
                    <div>
                      <StyledLabel>{t('community')}</StyledLabel>
                      <StyledCommunityColor $color={shopifyCommunity.color} /> {shopifyCommunity.title}
                    </div>
                    <ChevronRightIcon color={COLORS?.DIVIDERS} />
                  </StyledShopifyCommunityLink>
                )}
              </>
            )
          }}
        </InsightsBox.Managed>
      </div>
    </ChartGrid>
  )
}
