import { Gender } from '@community_dev/filter-dsl/lib/digits'
import { GenderLabels } from '@community_dev/filter-dsl/lib/humanize/builtInFilters/humanizeGenderFilter'
import {
  BuiltInFields,
  fieldLabelFor,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  SelectorOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { GenderFilter } from '@community_dev/filter-dsl/src/subscription-data'
import { ApiError } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { FanRegistrationCoreFieldName, GenderOptions } from '@community_dev/types/lib/api/FanRegistration'
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { FilterOptionsWithCounts } from './types'
import { CountByQueryFnReturn, useCountByQueries, useCountByQuery } from './useCountByQuery'

import { FilterSelectionType, useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useFanRegistrationField } from 'hooks/useClientSettings'

const toGenderFilter = (gender: Gender): GenderFilter => {
  return {
    operator: SelectorOperators.EQUALS,
    operand: {
      field_key: BuiltInFields.GENDER_IDENTITY,
      field_label: fieldLabelFor(BuiltInFields.GENDER_IDENTITY),
      source: FieldSources.BUILT_IN,
      type: FieldTypes.STRING,
      value: gender,
    },
  }
}

const toGenderFilters = (visibleGenderOptions: string[]): GenderFilter[] => {
  return (
    GenderOptions
      // Filter out the "no-answer" option
      // Not sure why we filter this out but doing it to match previous behavior
      .filter((option) => option.value !== 'no-answer')
      // Filter out the options that are not in the visibleGenderOptions
      .filter((option) => visibleGenderOptions.includes(option.label))
      .map((option) => toGenderFilter(option.value as Gender))
  )
}

type UseGenderCountArgs<SelectData = CountByQueryFnReturn> = {
  traceId?: string
  communicationChannel?: CommunicationChannel
  gender: Gender
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

export const useGenderCount = <SelectData = CountByQueryFnReturn>({
  traceId,
  communicationChannel,
  gender,
  options,
}: UseGenderCountArgs<SelectData>): UseQueryResult<SelectData, ApiError> => {
  return useCountByQuery<SelectData>({
    filters: toGenderFilter(gender),
    traceId,
    communicationChannel,
    options,
  })
}

type UseGenderCountsArgs<SelectData = CountByQueryFnReturn> = {
  traceId?: string
  communicationChannel?: CommunicationChannel
  options?: UseQueryOptions<CountByQueryFnReturn, ApiError, SelectData>
}

export const useGenderCounts = <SelectData = CountByQueryFnReturn>({
  traceId,
  communicationChannel,
  options,
}: UseGenderCountsArgs<SelectData> = {}): UseQueryResult<SelectData, ApiError>[] => {
  const { options: visibleGenderOptions = [] } = useFanRegistrationField(FanRegistrationCoreFieldName.GENDER_NAME) || {}

  return useCountByQueries<SelectData>({
    filters: toGenderFilters(visibleGenderOptions),
    traceId,
    communicationChannel,
    options,
  })
}

export const useCurrentFiltersWithGenderCounts = (type: FilterSelectionType = 'includes'): FilterOptionsWithCounts => {
  const { options: visibleGenderOptions = [] } = useFanRegistrationField(FanRegistrationCoreFieldName.GENDER_NAME) || {}

  const { previewNextFilter, communicationChannel } = useFilters()

  const genderFilters = toGenderFilters(visibleGenderOptions).filter(Boolean) as GenderFilter[]

  const nextFilters = genderFilters
    .map((genderFilter) => previewNextFilter(genderFilter, type))
    .filter(Boolean) as MemberDataFilter[]

  return {
    options: genderFilters.map((option, index) => ({
      label: GenderLabels[option.operand.value],
      filter: genderFilters[index],
    })),
    counts: useCountByQueries({
      filters: nextFilters,
      traceId: 'current-filters-with-gender-counts',
      communicationChannel,
    }) as UseQueryResult<CountByQueryFnReturn, ApiError>[],
  }
}
