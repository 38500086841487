import styled from 'styled-components'

export const StyledLabel = styled.label`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  line-height: 1;
  font-size: 12px;
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  line-height: 1;
  font-size: 12px;
  white-space: nowrap;
`
