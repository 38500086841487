import { SPACING } from '@community_dev/pixels'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

const WIDTH = 410

const StyledLoader = styled.div`
  box-shadow: ${({ theme }) => theme.SHADOWS.CARD};
  border-collapse: collapse;
  max-width: ${WIDTH}px;
  width: 100%;
  margin: ${SPACING[3]} 0;
`

export const CarrierLimitsTableLoader = (): JSX.Element => {
  return (
    <StyledLoader>
      <ContentLoader
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        height={80}
        speed={2}
        viewBox={`0 0 ${WIDTH} 80`}
        width={WIDTH}
      >
        <rect
          height={80 - parseInt(SPACING[3]) * 2}
          rx="0"
          ry="0"
          width={WIDTH - parseInt(SPACING[3]) * 2}
          x={SPACING[3]}
          y={SPACING[3]}
        />
      </ContentLoader>
    </StyledLoader>
  )
}
