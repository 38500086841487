import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import { QUERY_CACHE } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { useQueryParams } from 'hooks/useQueryParams'

export const useRedirectFromSetupIntent = ({
  onError,
  onSuccess,
}: {
  onError?: () => void
  onSuccess?: () => void
} = {}): { isSuccess: boolean; isError: boolean } => {
  const queryClient = useQueryClient()
  const clientId = useClientId()
  const params = useQueryParams()
  // Stripe will append this to the redirect url
  const { redirect_status, setup_intent, setup_intent_client_secret } = params

  // Its a success if redirect_status is succeeded, we have a setup_intent and client_secret
  const isSuccess = redirect_status === 'succeeded' && setup_intent && setup_intent_client_secret ? true : false

  // Its an error if redirect_status does not !== succeded and we have a setup_intent and client_secret
  // Majority of errors will probably happen before the redirect
  const isError = redirect_status !== 'succeeded' && setup_intent && setup_intent_client_secret ? true : false

  useEffect(() => {
    // If success, post to our server
    if (isSuccess) {
      onSuccess && onSuccess()
    }
    // Any other redirect status we will treat as a failure
    else if (isError) {
      // Invalidate cache to be able to add card again
      queryClient.invalidateQueries([QUERY_CACHE.BILLING.SETUP_INTENT, { clientId }])
      onError && onError()
    }
  }, [clientId, queryClient, onError, onSuccess, isSuccess, isError])

  return { isSuccess, isError }
}
