import { useGooglePlaces } from '@community_dev/hooks'
import { MapPin, SearchBar } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation, useQuery } from '@tanstack/react-query'
import compact from 'lodash/compact'
import { ChangeEvent, useEffect, useState, useRef, useMemo } from 'react'
import ReactMapGL, { InteractiveMapProps, Marker, NavigationControl, ViewState } from 'react-map-gl'
import styled, { css } from 'styled-components'

import { postAddresses, postCoordinates } from 'api/google'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import RecommendationRow from 'containers/RecipientRecommendations/RecommendationRow'
import { useToastMessage } from 'hooks/useToastMessage'
import { baseConfig } from 'utils/config'
import haversine from 'utils/haversine'
import LOCALITY_MAP from 'utils/localityMap'
import { PlaceSuggestion, transformPlaces } from 'utils/places'

const MAX_DISTANCE_METERS = 160934
const MILE_IN_METERS = 1609

const StyledMapContainer = styled.div`
  width: 100%;
  height: 100%;
`
const StyledMapOverlay = styled.div`
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 55%;
    left: 50%;
    width: 425px;
    height: 425px;
    transform: translate(-50%, -50%);
    border: 6px solid ${({ theme }) => theme?.COLORS?.LINKS};
    border-radius: 50%;
    box-shadow: 0 0 0 1000em rgba(0, 0, 0, 0.7);
    opacity: 0.93;
  }
`
const StyledMapSearchbar = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 550px;
  height: 50px;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-radius: 3px;
  cursor: default;
  z-index: 9998;
  box-sizing: initial;
`

type StyledMapSearchbarPlateProps = {
  $isVisible?: boolean
}

const StyledMapSearchbarPlate = styled.div<StyledMapSearchbarPlateProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  visibility: hidden;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  opacity: 0;
  transition: opacity 0.5s;
  border-radius: 3px;
  ${({ $isVisible }) =>
    $isVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`
const StyledMapSearchbarSearch = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 0 10px;
  color: ${({ theme }) => theme?.COLORS?.DEPRECATED_SENT};
  z-index: 9999;
`
const StyledMapSearchbarInfo = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  transition: width 0.6s ease-in;
  overflow: hidden;
`
const StyledMapSearchbarDivider = styled.div`
  border-left: 2px solid ${({ theme }) => theme?.COLORS?.DIVIDERS};
  height: 40px;
  margin: 6px 10px 0 0;
`
const StyledMapSearchbarStats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  padding-right: 12px;
`

type StyledMapSearchbarApplyButtonProps = {
  $isLoading?: boolean
}

const StyledMapSearchbarApplyButton = styled.button<StyledMapSearchbarApplyButtonProps>`
  background: ${({ theme }) => {
    if (theme.BASE === 'DARK') {
      return theme?.COLORS?.BUTTON_PRIMARY
    }
    return theme?.COLORS?.LINKS
  }};
  color: ${({ theme }) => {
    if (theme.BASE === 'DARK') {
      return theme?.COLORS?.BUTTON_PRIMARY_TEXT
    }
    return theme?.COLORS?.APP_BACKGROUND_LEVEL_3
  }};
  font-size: 12px;
  font-weight: 800;
  line-height: 100%;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 0 3px 3px 0;
  width: 40px;
  transition: background 0.2s;
  border: none;

  ${({ $isLoading, theme }) =>
    $isLoading &&
    css`
      background: ${theme?.COLORS?.BUTTON_DISABLED};
      color: ${theme?.COLORS?.BUTTON_DISABLED_TEXT};
    `}
`
const StyledMapSearchbarStatsRadius = styled.div`
  font-size: 16px;
  font-weight: 800;
  line-height: 18px;
`
const StyledMapSearchbarResults = styled.div`
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  width: 550px;
  max-height: 350px;
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
`
const StyledNavigationControl = styled(NavigationControl)`
  position: absolute;
  bottom: 50px;
  right: 10px;
`

type Location = {
  city: string
  country: string
  name: string
  state: string
  type: string
}

type LocationRadiusMapProps = {
  initialLocation?: Location
  sessionToken?: string | google.maps.places.AutocompleteSessionToken
  onBack(): void
  onSelect(value: any): void
}

export function LocationRadiusMap({
  initialLocation,
  sessionToken,
  onBack,
  onSelect,
}: LocationRadiusMapProps): JSX.Element {
  const { showToastMessage } = useToastMessage()
  const [isSearching, setIsSearching] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [distance, setDistance] = useState(0)
  const [address, setAddress] = useState<Location | undefined>(() => initialLocation)
  const [suggestions, setSuggestions] = useState<PlaceSuggestion[]>([])
  const [preventMapPan, setPreventMapPan] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)
  const mapContainerRef = useRef<any>(null)
  const mapOverlayRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const searchTimerRef = useRef<any>(null)
  const calculatedDistance = Math.min(Math.round(distance / MILE_IN_METERS), 100)
  const { isInitialLoading: isLoadingCoordinates, data } = useQuery(
    [QUERY_CACHE.GOOGLE.COORDINATES, { address }],
    () => postCoordinates({ address: compact([address?.city, address?.state, address?.country]).join(', ') }),
    {
      staleTime: STALE_TIME.FOREVER,
      refetchOnWindowFocus: false,
      enabled: Boolean(address?.city || address?.state || address?.country),
      onError() {
        showToastMessage({ message: 'Unable to find location', success: false })
      },
    },
  )

  const [viewport, setViewport] = useState<Partial<InteractiveMapProps>>({
    longitude: data?.lng,
    latitude: data?.lat,
    width: '100%',
    height: '100%',
    zoom: 8.192602682818082, // aprox 50 miles
  })

  const { mutate: searchAddress, isLoading: isLoadingAddress } = useMutation(postAddresses, {
    onSuccess(data: any) {
      setAddress(data as Location)
      setSearchTerm(data.name)
    },
    onError() {
      showToastMessage({ message: 'Unable to find location', success: false })
    },
  })
  const {
    error,
    results,
    loading: isLoadingPlaces,
  } = useGooglePlaces(isSearching ? searchTerm : undefined, {
    sessionToken,
    getPlacePredictions: {
      componentRestrictions: {
        country: ['us', 'ca', 'pr'],
      },
    },
  })

  const isLoading = isLoadingAddress || isLoadingCoordinates || isLoadingPlaces

  useEffect(() => {
    if (!data) return

    setViewport((prev) => ({
      ...prev,
      latitude: data.lat,
      longitude: data.lng,
    }))
  }, [data])

  useEffect(() => {
    setSearchTerm(address?.name || '')
  }, [setSearchTerm, address])

  useEffect(() => {
    if (!isSearching) {
      return
    } else {
      setSuggestions((results || []).map(transformPlaces))
    }
  }, [results, setSuggestions, isSearching])

  useEffect(() => {
    if (!error) return
    showToastMessage({ message: 'Unable to find location', success: false })
  }, [error, showToastMessage])

  const filteredSuggestions = useMemo(() => {
    return suggestions && isSearching
      ? suggestions.filter(({ state, type }) => type !== 'state' && state !== 'Washington, D.C.')
      : []
  }, [suggestions, isSearching])

  function handleKeyUpEvent(evt) {
    evt.preventDefault()

    if (filteredSuggestions?.length > 0) {
      if (evt.keyCode === 38) {
        // Up Arrow
        inputRef.current?.blur()
        const newActiveIndex = Math.max(0, activeIndex - 1)
        setActiveIndex(newActiveIndex)
      } else if (evt.keyCode === 40) {
        // Down Arrow
        inputRef.current?.blur()
        const newActiveIndex = Math.min(suggestions.length - 1, activeIndex + 1)
        setActiveIndex(newActiveIndex)
      } else if (evt.keyCode === 13) {
        // Enter Key
        if (activeIndex >= 0) {
          setActiveIndex(-1)
          handleRecommendationClick(suggestions[activeIndex])
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUpEvent)

    return () => {
      document.removeEventListener('keyup', handleKeyUpEvent)
    }
  }, [])

  function handleViewStateChange({ viewState }: { viewState: ViewState }): void {
    if (preventMapPan) {
      return
    }

    let updatedDistance = distance
    if (mapContainerRef.current) {
      const map = mapContainerRef.current?.getMap()
      const bounds = map.getBounds()
      const boundsDistance = haversine(bounds._ne.lat, bounds._sw.lat, bounds._ne.lng, bounds._sw.lng)
      updatedDistance = Math.min(boundsDistance * MILE_IN_METERS, MAX_DISTANCE_METERS)
    }

    setViewport(viewState)
    setDistance(updatedDistance)
    setIsSearching(false)
  }

  function handleInteractionStateChange(state: { isDragging: boolean; isPanning: boolean; isZooming: boolean }) {
    if (state.isDragging || state.isPanning || state.isZooming || !viewport.latitude || !viewport.longitude) {
      return
    }
    if (searchTimerRef.current) clearTimeout(searchTimerRef.current)
    searchTimerRef.current = setTimeout(() => {
      searchAddress({
        lat: viewport.latitude as number,
        lng: viewport.longitude as number,
      })
    }, 700)
  }

  function handleMouseDown(): void {
    setPreventMapPan(true)
  }

  function handleMouseUp(): void {
    setPreventMapPan(false)
  }

  function handleSearch(evt: ChangeEvent<HTMLInputElement>): void {
    setIsSearching(true)
    setSearchTerm(evt.target.value)
    setActiveIndex(-1)
  }

  function handleRecommendationClick(suggestion) {
    setIsSearching(false)
    setAddress(suggestion)
    setSearchTerm(`${suggestion.city}, ${suggestion.state}`)
    setDistance(80450)
  }

  return (
    <StyledMapContainer ref={mapOverlayRef}>
      <ReactMapGL
        {...viewport}
        dragRotate={false}
        mapStyle="mapbox://styles/alldev/cjyja62lq1an51cqfo5cq2srw"
        mapboxApiAccessToken={baseConfig.mapboxApiToken}
        maxPitch={0}
        maxZoom={12}
        minZoom={6.5}
        onInteractionStateChange={handleInteractionStateChange}
        onViewStateChange={handleViewStateChange}
        ref={mapContainerRef}
      >
        <StyledMapOverlay />
        <StyledMapSearchbar onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
          <StyledMapSearchbarPlate $isVisible={isSearching} />
          <StyledMapSearchbarSearch>
            <SearchBar onChange={handleSearch} placeholder="Search" ref={inputRef} value={searchTerm} />
            {isSearching ? (
              <StyledMapSearchbarResults>
                {filteredSuggestions.map((suggestion, idx) => {
                  const secondary =
                    suggestion.type === 'state' ? LOCALITY_MAP[suggestion.country.toUpperCase()] : suggestion.type
                  return (
                    <RecommendationRow
                      active={activeIndex === idx}
                      key={suggestion.name}
                      onClick={() => handleRecommendationClick(suggestion)}
                      primary={suggestion.name}
                      secondary={secondary}
                    />
                  )
                })}
              </StyledMapSearchbarResults>
            ) : null}
          </StyledMapSearchbarSearch>
          <StyledMapSearchbarInfo>
            <StyledMapSearchbarDivider />
            <StyledMapSearchbarStats>
              <StyledMapSearchbarStatsRadius>{`${calculatedDistance} mile radius`}</StyledMapSearchbarStatsRadius>
            </StyledMapSearchbarStats>
            <StyledMapSearchbarApplyButton
              $isLoading={isLoading}
              onClick={() => {
                onSelect({
                  city: address?.city,
                  state: address?.state,
                  lat: viewport.latitude,
                  lng: viewport.longitude,
                  meters: Math.round(distance),
                })
                onBack()
              }}
            >
              {isLoading ? <FontAwesomeIcon className="fa-spin" color="inherit" icon="spinner" /> : 'APPLY'}
            </StyledMapSearchbarApplyButton>
          </StyledMapSearchbarInfo>
        </StyledMapSearchbar>
        {viewport.latitude && viewport.longitude ? (
          <Marker
            draggable={false}
            latitude={viewport.latitude}
            longitude={viewport.longitude}
            offsetLeft={-10}
            offsetTop={-10}
          >
            <MapPin />
          </Marker>
        ) : null}
        <StyledNavigationControl showCompass={false} />
      </ReactMapGL>
    </StyledMapContainer>
  )
}
