import { BORDER_RADIUS, FONT_SIZE, Pill, SPACING } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const StyledThemes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${SPACING[3]};
`

export const StyledBox = styled.div`
  flex: 1;
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: ${BORDER_RADIUS[1]};

  &:not(:last-of-type) {
    margin-right: ${SPACING[3]};
  }
`

export const StyledBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  padding: ${SPACING[3]};
`

export const StyledBoxTitle = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
  margin: 0;
`

export const StyledBoxText = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  margin: 0 0 ${SPACING[4]};
`

export const StyledBoxContent = styled.div`
  padding: ${SPACING[3]};
`

export const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: ${SPACING[1]};
`

export const StyledPill = styled(Pill)`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  border-color: ${({ theme }) => theme?.COLORS?.LINKS};
  margin-left: ${SPACING[2]};
  padding: ${SPACING[0]} ${SPACING[2]};
`

export const StyledColorContainer = styled.button`
  font-size: ${FONT_SIZE[2]};
  display: flex;
  margin-bottom: ${SPACING[2]};
  align-items: center;
  background: none;
  padding: 0;
  border: none;

  &:not(:last-of-type) {
    margin-right: ${SPACING[5]};
  }
`

export const StyledColorWrapper = styled.div<{ $active?: boolean }>`
  width: ${SPACING[6]};
  height: ${SPACING[6]};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  transition: padding 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &:hover,
  &:focus {
    padding: 2px;
  }
  ${({ $active, theme }) =>
    $active &&
    `
      padding: 2px;
      outline: 2px solid ${theme?.COLORS?.LINKS};
    `}
`

export const StyledColor = styled.div<{ $color: string }>`
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`

export const StyledColorTextWrapper = styled.div`
  margin-left: ${SPACING[2]};
  text-align: left;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

export const StyledEmojis = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${SPACING[4]};
`

export const StyledEmojiWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledLabel = styled.label`
  cursor: pointer;
  display: block;
  margin-right: ${SPACING[3]};
`

export const StyledRadio = styled.input`
  cursor: pointer;
  display: inline-block;
  margin-right: ${SPACING[1]};
`

export const StyledEmoji = styled.span`
  font-size: 20px;
  display: inline-block;
  width: ${SPACING[5]};
  height: ${SPACING[5]};
  line-height: ${SPACING[5]};
  word-break: keep-all;
`
