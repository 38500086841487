import { Community } from 'api/community'

export function mapFanSubscriptionTags(tags: string[], communities: Community[] = []): Partial<Community>[] {
  if (!tags) return []

  return tags.map((tag) => {
    const community = communities.find((comm) => comm.id === tag) || {}

    return {
      ...community,
      id: tag,
    }
  })
}
