import { BUTTON_VARIANTS, Button } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Illustration from './empty-scheduled.svg?react'

const StyledContainer = styled.div`
  text-align: center;
  max-width: 340px;
  margin: 64px auto 0;
`

type ScheduledEmptyProps = {
  onClick: () => void
}

export function ScheduledEmpty({ onClick }: ScheduledEmptyProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <StyledContainer>
      <Illustration />
      <h3>{t('scheduled.empty.heading')}</h3>
      <p>{t('scheduled.empty.content')}</p>
      <Button onClick={onClick} variant={BUTTON_VARIANTS.ACTION}>
        {t('sendNewMessage')}
      </Button>
    </StyledContainer>
  )
}
