import { CheckBox, colors, InfoIcon, SPACING, Tooltip } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledLabel = styled.label`
  display: block;
  margin: ${SPACING[3]} 0 ${SPACING[2]} ${SPACING[1]};
  color: ${colors.gray4};
  line-height: 136%;
  font-size: 12px;
  user-select: none;
  cursor: pointer;
  input {
    position: relative;
    top: 1px;
    margin-right: 7px;
  }
`

type LinkShorteningCheckboxProps = {
  value: boolean
  onChange(value: boolean): any
}

export function LinkShorteningCheckbox({ value, onChange }: LinkShorteningCheckboxProps): JSX.Element {
  const { t } = useTranslation()
  return (
    <StyledLabel>
      <CheckBox key={`link-shortening-checkbox-${value}`} onChange={(value) => onChange(value)} selected={value} />
      {t('compose.linkShortenerCheckbox')}{' '}
      <Tooltip content={<div style={{ padding: SPACING[2] }}>{t('compose.linkShortenerTooltip')}</div>}>
        <span>
          <InfoIcon size={19} />
        </span>
      </Tooltip>
    </StyledLabel>
  )
}
