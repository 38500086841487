import { ApiError } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { useClientId } from './useClient'

import { PaginatedCampaigns, getCampaignList } from 'api/campaign'
import { QUERY_CACHE, STALE_TIME } from 'constants/query-cache'
import { addPendingMedia } from 'hooks/useCampaign'
import Sentry from 'integrations/Sentry'
import { useCurrentMonthDateRange } from 'screens/Scheduled/screens/Month/useCurrentMonthDateRange'

type ScheduledCampaignsQueryProps<SelectData = PaginatedCampaigns> = {
  start: Date
  end: Date
  searchTerm?: string
  communicationChannels?: CommunicationChannel[]
  options?: UseQueryOptions<PaginatedCampaigns, ApiError, SelectData>
}

export const useCampaignsQuery = <SelectData = PaginatedCampaigns>(
  props: ScheduledCampaignsQueryProps<SelectData>,
): UseQueryResult<SelectData, ApiError> => {
  const { searchTerm, communicationChannels, start, end, options } = props
  const clientId = useClientId()
  const isoStart = start.toISOString()
  const isoEnd = end.toISOString()
  return useQuery({
    queryKey: [
      QUERY_CACHE.CAMPAIGN.RANGE,
      { clientId, searchTerm, communicationChannels, start: isoStart, end: isoEnd },
    ],
    queryFn: async (): Promise<PaginatedCampaigns> => {
      const campaignList = await getCampaignList({
        clientId,
        searchTerm,
        communicationChannels,
        startDatetime: isoStart,
        endDatetime: isoEnd,
      })

      try {
        const dataWithPendingMedia = await Promise.all(campaignList.data.map(addPendingMedia))
        return { ...campaignList, data: dataWithPendingMedia }
      } catch (error) {
        Sentry.captureException(error)
        return campaignList
      }
    },
    enabled: !!clientId,
    staleTime: STALE_TIME.ONE_MINUTE,
    ...options,
  })
}

export const useCurrentMonthCampaignsQuery = <SelectData = PaginatedCampaigns>(
  options?: UseQueryOptions<PaginatedCampaigns, ApiError, SelectData>,
): UseQueryResult<SelectData, ApiError> => {
  const { start, end } = useCurrentMonthDateRange()
  return useCampaignsQuery<SelectData>({ start, end, options })
}
