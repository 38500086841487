import { LoadingIndicator, Toast } from '@community_dev/pixels'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import { useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { Redirect } from 'react-router'
import styled from 'styled-components'

import { BillingForm } from 'components/BillingForm'
import { QUERY_CACHE } from 'constants/query-cache'
import { ROUTES } from 'constants/routes'
import { EditContainer } from 'containers/Settings'
import { useSubscription } from 'hooks/billing/useSubscription'
import { useClientId } from 'hooks/useClient'
import texts from 'texts.json'

const StyledMain = styled.div`
  form {
    padding-top: 32px;
    width: 100%;
    max-width: 456px;
    margin: 0 auto;
  }
`

const StyledIndicator = styled(LoadingIndicator)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const StyledError = styled.div`
  margin: 16px 0 0;

  > div {
    max-width: 456px;
    margin: 0 auto;
  }
`

export function EditBilling(): JSX.Element {
  const clientId = useClientId()
  const [errorMessage, setErrorMessage] = useState()
  const [redirect, setRedirect] = useState<string>()
  const { isInitialLoading, isError, error: queryError } = useSubscription()
  const queryClient = useQueryClient()

  function handleSuccess() {
    toast.success(texts.payment.success)

    queryClient.invalidateQueries([QUERY_CACHE.BILLING.SUBSCRIPTION, { clientId }])
    setRedirect(ROUTES.SETTINGS.BILLING)
  }
  const error = isError && queryError.status !== 404

  if (redirect) return <Redirect to={redirect} />

  return (
    <EditContainer title="Edit Billing">
      <StyledMain>
        {(error || errorMessage) && (
          <StyledError>
            <Toast icon message={errorMessage || texts.errors.generic} success={false} />
          </StyledError>
        )}
        {isInitialLoading && <StyledIndicator />}
        {!isInitialLoading && (
          <RecurlyProvider publicKey={import.meta.env.VITE_RECURLY_KEY}>
            <Elements>
              <BillingForm onError={setErrorMessage} onSuccess={handleSuccess} />
            </Elements>
          </RecurlyProvider>
        )}
      </StyledMain>
    </EditContainer>
  )
}
