import { useInterval } from '@community_dev/hooks'
import { DIALOG_VARIANTS, Dialog, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Measure from 'react-measure'
import styled, { css } from 'styled-components'

import { ConvoDMSuggestions } from './ConvoDMSuggestions'

import { ConvoBubble } from 'components/ConvoBubble'
import { MOBILE_HEADER_HEIGHT } from 'constants/theme'
import ReplyForm from 'containers/ReplyForm'
import { useBounds } from 'contexts/BoundsProvider'
import { useConvo } from 'contexts/ConvoProvider'
import { useCanSendDms } from 'hooks/useCanSendDms'
import { useDeleteMessage } from 'hooks/useDeleteMessage'
import { useFan } from 'hooks/useFan'
import { mediaLoaded } from 'hooks/useMediaLoaded'
import { useMessageHistory } from 'hooks/useMessageHistory'
import { useUpdateInbox } from 'hooks/useUpdateInbox'
import Sentry from 'integrations/Sentry'
import dayjs from 'utils/dayjs'

const StyledConvo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
`

type StyledMainProps = {
  $isFetchingNextPage?: boolean
  $loaded: boolean
}

const StyledMain = styled.div<StyledMainProps>`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: auto;
  pointer-events: auto;
  padding: ${MOBILE_HEADER_HEIGHT} ${SPACING[4]} 0 ${SPACING[4]};
  opacity: 0;

  ${({ $loaded }) =>
    $loaded &&
    css`
      opacity: 1;
    `}

  ${({ $isFetchingNextPage }) =>
    $isFetchingNextPage &&
    css`
      -webkit-overflow-scrolling: auto;
    `}
`

const StyledReply = styled.div`
  position: relative;
  width: 100%;
  flex-shrink: 0;
`

type ConvoProps = {
  fanId: string
}

export default function Convo({ fanId }: ConvoProps): JSX.Element {
  const { t } = useTranslation()
  const { setBounds } = useBounds()
  const { setReplyBody, setUsedAiSuggestion } = useConvo()

  const [loaded, setLoaded] = useState(false)

  const [scrollActive, setScrollActive] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false)

  const scrollingTimeout = useRef<NodeJS.Timeout>()

  const [deleteMessageId, setDeleteMessageId] = useState('')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const scrollerElement = useRef<HTMLDivElement | null>(null)
  const dropzoneElement = useRef<HTMLDivElement | null>(null)

  const updateInbox = useUpdateInbox()
  const { data: fan } = useFan(fanId, {
    // fan data is polled
    refetchInterval: 5000,
    onSuccess(data) {
      // update the state flags and conversation window end for the active fan
      // on the inbox too.
      updateInbox(data.fanId, {
        fan: {
          stateFlags: data.stateFlags,
          conversationWindowEndAt: data.conversationWindowEndAt,
        },
      })
    },
  })

  const deleteMessage = useDeleteMessage({ fanId })
  const { messageHistory, isInitialLoading, fetchNextPage, isFetchingNextPage, isLoading, refetch } = useMessageHistory(
    { fanId },
  )

  // this is the polling implementation to ensure we always have the most recent messages
  useInterval(() => {
    if (fanId) {
      refetch({ refetchPage: (_, index) => index === 0 }).then(() => !hasScrolled && scrollToBottom())
    }
  }, 2000)

  useEffect(() => {
    setHasScrolled(false)
    setLoaded(false)
    setReplyBody('')
    setUsedAiSuggestion(false)
  }, [fanId, setReplyBody, setUsedAiSuggestion])

  useEffect(() => {
    if (!isLoading && scrollerElement.current) {
      mediaLoaded(scrollerElement.current)
        .then(() => !hasScrolled && scrollToBottom())
        .then(() => setTimeout(() => setLoaded(true), 0))
        // Links can not exist resulting in a 404 triggering this catch
        // In order for the convo to not be blank we still set loaded to true
        .catch(() => setTimeout(() => setLoaded(true), 0))
    }
  }, [fanId, isLoading, hasScrolled])

  const isWhatsAppConversation = fan?.communicationChannel === CommunicationChannel.WHATS_APP
  const isAmbConversation = fan?.communicationChannel === CommunicationChannel.AMB

  const disabledReason = isWhatsAppConversation
    ? t('convo.whatsAppMessagingDisabled')
    : isAmbConversation
    ? t('convo.ambMessagingDisabled')
    : undefined

  const canReceiveReply = useCanSendDms({
    communicationChannel: fan?.communicationChannel,
    stateFlags: fan?.stateFlags,
    conversationWindowEndAt: fan?.conversationWindowEndAt,
  })

  const onWheel = () => {
    setScrollActive(true)
    setHasScrolled(true)

    if (scrollingTimeout.current) {
      clearTimeout(scrollingTimeout.current)
    }
    scrollingTimeout.current = setTimeout(() => setScrollActive(false), 500)

    if (scrollerElement?.current?.scrollTop === 0 && !isFetchingNextPage) {
      fetchNextPage().then(() => {
        if (scrollerElement.current) {
          scrollerElement.current.scrollTop = 1
        }
      })
    }
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollerElement.current && !scrollActive && !hasScrolled) {
        scrollerElement.current.scrollTop = scrollerElement.current.scrollHeight
      }
    }, 0)
  }

  const confirmDelete = (deleteMessageId) => {
    setShowDeleteDialog(true)
    setDeleteMessageId(deleteMessageId)
  }

  const renderDeleteDialog = () => {
    if (!showDeleteDialog) return

    const onCancelClick = () => {
      setShowDeleteDialog(false)
    }

    const onDeleteClick = () => {
      setShowDeleteDialog(false)
      deleteMessage.mutateAsync({ id: deleteMessageId }).catch(Sentry.captureException)
    }

    return (
      <Dialog title="Delete unsent message?">
        <Dialog.Action onClick={onCancelClick}>Cancel</Dialog.Action>
        <Dialog.Action onClick={onDeleteClick} variant={DIALOG_VARIANTS.DESTRUCTIVE}>
          Delete
        </Dialog.Action>
      </Dialog>
    )
  }

  const renderItems = () => {
    let prevTime
    const total = messageHistory.length - 1
    return messageHistory.map((item, index) => {
      const { text, media, id, createdAt, inbound, status } = item
      if (!text?.trim() && !(media && media.url) && status === 'success') return undefined
      const itemTime = dayjs(createdAt)
      const showTime = !prevTime || itemTime.diff(prevTime, 'hours', true) > 1
      prevTime = itemTime
      const onBodyClick = status === 'error' ? () => confirmDelete(id) : undefined
      const nextMessageSame = messageHistory?.[index + 1]?.inbound === item.inbound

      return (
        <ConvoBubble
          body={text}
          id={id}
          incoming={inbound}
          key={id}
          last={total === index}
          media={media}
          onBodyClick={onBodyClick}
          onImageLoad={scrollToBottom}
          showTime={showTime}
          sourceType={item.sourceType}
          sourceTypeId={item.sourceTypeId}
          status={status}
          tail={!nextMessageSame}
          ts={createdAt}
        />
      )
    })
  }

  return (
    <Measure onResize={(bounds) => setBounds(bounds.entry)}>
      {({ measureRef }) => (
        <StyledConvo key={fanId} ref={measureRef}>
          <StyledMain
            $isFetchingNextPage={isFetchingNextPage}
            $loaded={loaded}
            data-testid="convo"
            onTouchMove={onWheel}
            onWheel={onWheel}
            ref={scrollerElement}
          >
            {!isInitialLoading && renderItems()}
            <ConvoDMSuggestions fanId={fanId} />
          </StyledMain>
          <StyledReply>
            <ReplyForm
              communicationChannel={fan?.communicationChannel || CommunicationChannel.SMS}
              disabled={!canReceiveReply}
              disabledReason={disabledReason}
              fanId={fanId}
              ref={dropzoneElement}
              scrollConvoBottom={scrollToBottom}
              showUsage={!isWhatsAppConversation}
            />
          </StyledReply>
          {renderDeleteDialog()}
        </StyledConvo>
      )}
    </Measure>
  )
}
