import { HTTP } from '@community_dev/requests'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'
import { route } from 'utils/router'

export type SearchQuery = {
  search_query: string
  exact_match: boolean
  start_datetime: string
  end_datetime: string
  communication_channel: CommunicationChannel
  sort_by: string
  timezone: string
  with_timestamp_histogram: boolean
}

export type SearchResult = {
  countForSubscription: number
  campaignId: string | null
  city: string
  clientId: string
  countryCode: string
  dateOfBirth: string
  fanId: string
  gender: string
  textHighlight: string[]
  location: string
  mediaType: string | null
  mediaUrl: string | null
  messageId: string
  messageTags: string[]
  messageText: string
  messageTimestamp: string
  name: string
  nameHighlight: string[]
  score: number | null
  stateCode: string
  subscriptionId: string
  subscriptionState: string
}

export type Bucket = {
  messagesCount: number
  messageTimestamp: number
}

type Histogram = {
  buckets: Bucket[]
  interval: string
}

export type SearchResults = {
  data: {
    results: SearchResult[]
    timestampHistogram?: Histogram
    totalResults: number
    totalSubscriptions: number
  }
  paginationData: {
    hasNextPage: boolean
    pageNumber: number
    pageSize: number
  }
}

export const postSearchIndexByQuery = (query: Partial<SearchQuery> = {}, page = 1): Promise<SearchResults> => {
  const clientId = sessionStorage.getItem('clientId')
  return request(route(ENDPOINTS.SEARCH.MESSAGES, { clientId }, { page_number: page }), {
    method: HTTP.POST,
    body: query,
  })
}
