import { Button, BUTTON_VARIANTS } from '@community_dev/pixels'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ResponsiveWrapper = styled.div`
  padding: 0;
  width: 100%;
  margin-right: auto;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: auto;
`
export const DoneButton = styled(Button).attrs({ variant: BUTTON_VARIANTS.SOLID_PILL })`
  width: 200px;
  height: 40px;
`

export const CancelLink = styled(Link)`
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  font-weight: 700;
  font-size: 16px;
`
