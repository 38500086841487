import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const StyledFilterOption = styled.div`
  display: flex;
  align-items: center;
`

const StyledFilterDotIcon = styled(FontAwesomeIcon)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
`

const StyledFilterDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
`

const StyledFilterLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
`

type FilterOptionsProps = {
  label: string
  color?: string
  icon?: IconName
  className?: string
}

export function FilterOption({ label, color, icon, className, ...rest }: FilterOptionsProps): JSX.Element {
  if (icon !== undefined) {
    return (
      <StyledFilterOption className={className}>
        <StyledFilterDotIcon icon={icon} style={{ color: color, width: 11 }} />
        <StyledFilterLabel>Favorites</StyledFilterLabel>
      </StyledFilterOption>
    )
  } else if (color !== undefined) {
    return (
      <StyledFilterOption className={className} data-testid={rest['data-testid']}>
        <StyledFilterDot style={{ background: color }} />
        <StyledFilterLabel>{label}</StyledFilterLabel>
      </StyledFilterOption>
    )
  } else {
    return (
      <span className={className} data-testid={rest['data-testid']}>
        {label}
      </span>
    )
  }
}
