import { WorkflowEditorCardTypes, WORKFLOW_CARDS } from '@community_dev/flexible-canvas'
import { AddToCommunityAction, updateAction } from '@community_dev/workflow-manifest'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { WorkflowCommunitySelect } from '../WorkflowCommunitySelect'

import { useWorkflowProvider } from 'components/WorkflowEditor/context/WorkflowProvider'
import { useManifestAction } from 'components/WorkflowEditor/hooks/useManifestAction'
import { ROUTES } from 'constants/routes'
import { useToastMessage } from 'hooks/useToastMessage'
import { route } from 'utils/router'

const WorkflowCardConfig = WORKFLOW_CARDS[WorkflowEditorCardTypes.ADD_TO_COMMUNITY]

export function WorkflowSidebarEditCommunity(): JSX.Element | null {
  const { t } = useTranslation()
  const history = useHistory()
  const { showToastMessage } = useToastMessage()

  const { workflowId, functionKey, functionIndex } =
    useParams<{ workflowId: string; functionKey: string; functionIndex: string }>()
  const { manifest, dispatch } = useWorkflowProvider()
  const { action } = useManifestAction<AddToCommunityAction>()

  const [tagId, setTagId] = useState<string | null>(action?.params?.tag_id || null)

  const handleSubmit = async () => {
    if (!action || !tagId) {
      return
    }

    const nextAction = cloneDeep(action)
    set(nextAction, 'params.tag_id', tagId)

    const nextManifest = updateAction(manifest, functionKey, Number(functionIndex), nextAction)

    try {
      dispatch({
        type: 'update',
        state: {
          manifest: nextManifest,
        },
      })

      history.push(route(ROUTES.AUTOMATIONS.FLOW_EDIT, { workflowId }))
    } catch (e) {
      showToastMessage({
        message: (e as Error).message,
        success: false,
      })
    }
  }

  return (
    <WorkflowCommunitySelect
      onSubmit={handleSubmit}
      setTagId={setTagId}
      tagId={tagId}
      workflowCardConfig={{ ...WorkflowCardConfig, subtitle: t('automations.selectACommunity') }}
    />
  )
}
