import { Typography, FONT_WEIGHT, SPACING } from '@community_dev/pixels'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { MessageLimitRow } from '../../MessageLimitRow'

import { InvoicesDetails } from './InvoicesDetails/InvoicesDetails'
import { PaymentSection } from './PaymentSection'
import { StyledSetting } from './styled'
import { UsageBasedActivitySection } from './UsageBasedActivitySection'

import { StyledContent } from 'components/Settings'
import { usePlanColor } from 'hooks/billing/usePlanColor'
import { useSubscription } from 'hooks/billing/useSubscription'
import { SettingsSection } from 'layouts/SettingsLayout'

const StyledEmail = styled.div`
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  font-size: 12px;
  text-align: right;
`

const StyledSettings = styled(SettingsSection)`
  ${StyledContent} {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
`

const PlanInfo = (): JSX.Element => {
  const { data: subscriptionData } = useSubscription()
  const { COLORS } = useTheme()
  const { color } = usePlanColor()
  const { t } = useTranslation()

  const planName = subscriptionData?.subscription?.planName

  return (
    <>
      <Typography color={color || COLORS?.TEXT} component="span" fontWeight={FONT_WEIGHT[6]} variant="h5">
        {planName}
      </Typography>
      <Typography color={COLORS?.TEXT} component="span" fontWeight={FONT_WEIGHT[6]} variant="h5">
        {` ${t('settings.billing.termedInvoice.plan')}`}
      </Typography>
    </>
  )
}

export const SegmentBasedSubscription = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <InvoicesDetails />
      <StyledSettings>
        <Typography component="h2" marginBottom={SPACING[4]} marginTop="0" variant="h3">
          {t('settings.billing.termedInvoice.yourPlan')}
        </Typography>
        <StyledSetting label={<PlanInfo />}></StyledSetting>
      </StyledSettings>
      <PaymentSection />
      <UsageBasedActivitySection />
      <MessageLimitRow />
      <SettingsSection>
        <StyledEmail>{t('settings.billing.termedInvoice.toCancel')}</StyledEmail>
      </SettingsSection>
    </>
  )
}
