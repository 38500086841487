import { SPACING, SummaryCards, Typography, FONT_SIZE, BORDER_RADIUS } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { GrowYourCommunityCard } from './GrowYourCommunityCard'
import { ShareYourCommunityCard } from './ShareYourCommunityCard'
import { ShareYourNumberCard } from './ShareYourNumberCard'

import { useClient } from 'hooks/useClient'

const MAX_CENTER_COLUMN_WIDTH = '720px'

const StyledProductEducation = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 ${SPACING[4]};

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
    gap: 1rem;
    width: 100%;
    max-width: ${MAX_CENTER_COLUMN_WIDTH};
  }

  li {
    background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
    margin: 0;
    transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    &:hover {
      transform: translateZ(0) scale(1.0175);
      box-shadow: ${({ theme }) => theme.SHADOWS.CARD};

      @media (hover: none) {
        transform: translateZ(0) scale(1);
      }
    }
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
    border-radius: ${BORDER_RADIUS[1]};
    height: 100%;
    button {
      height: 100%;
    }
  }
`

const StyledHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: ${MAX_CENTER_COLUMN_WIDTH};
`

export function ProductEducation(): JSX.Element {
  const { data: client } = useClient()
  const { t } = useTranslation(undefined, { keyPrefix: 'productEducation' })
  const shouldShowShareNumberCard = client?.communicationChannels?.includes(CommunicationChannel.SMS)

  return (
    <StyledProductEducation>
      <StyledHeaderContainer>
        <Typography fontSize={FONT_SIZE[6]} variant="h2">
          {t('getStarted')}
        </Typography>
      </StyledHeaderContainer>
      <SummaryCards>
        {shouldShowShareNumberCard && <ShareYourNumberCard />}
        <ShareYourCommunityCard />
        <GrowYourCommunityCard />
      </SummaryCards>
    </StyledProductEducation>
  )
}
