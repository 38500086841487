import { HTTP } from '@community_dev/requests'

import { CapabilityName } from 'constants/capabilities'
import { ENDPOINTS } from 'constants/endpoints'
import {
  AddOnsActionType,
  AddOnsContentType,
  FormstackActionNames,
  LINK_CLIENT_ID_PARAM,
  LINK_SEAT_EMAIL_PARAM,
} from 'hooks/useAddons/constants'
import { request } from 'utils/api'
import { route } from 'utils/router'

type LinkParam = typeof LINK_CLIENT_ID_PARAM | typeof LINK_SEAT_EMAIL_PARAM

export type CapabilitiesAndFeatureFlags = {
  capabilities?: CapabilityName[]
  featureFlags?: string[]
}

export type Disabled = CapabilitiesAndFeatureFlags & {
  tooltip?: string
}

export type RouteAction = {
  disabled?: Disabled
  text: string
  type: AddOnsActionType.route
  to: string
}

export type LinkAction = {
  disabled?: Disabled
  text: string
  type: AddOnsActionType.link
  url: string
  params?: LinkParam[]
}

export type FormstackField = {
  fieldId: number
  fieldName: `${FormstackActionNames}`
}

export type FormstackAction = {
  disabled?: Disabled
  text: string
  type: AddOnsActionType.formstack
  formId: number
  fields: FormstackField[]
}

type ConditionalAction = CapabilitiesAndFeatureFlags & {
  type: AddOnsActionType.conditional
  whenTrue: RouteAction | LinkAction | FormstackAction
  whenFalse: RouteAction | LinkAction | FormstackAction
}

export type AddOnAction = ConditionalAction | RouteAction | LinkAction | FormstackAction

export type TextContent = {
  type: AddOnsContentType.text
  value: string
}

export type ImageContent = {
  type: AddOnsContentType.image
  imageSrc: string[]
}

export type HeadingContent = {
  type: AddOnsContentType.heading
  value: string
}

export type Content = TextContent | ImageContent | HeadingContent

type Details = {
  action: AddOnAction
  content: Content[]
}

export type AddOn = {
  type: string
  title: string
  featureRank?: number
  visible?: CapabilitiesAndFeatureFlags
  description: string
  imageSrc: string[]
  details: Details
}

export type PopulatedField = FormstackField & { fieldValue: string }

type FeatureRequest = {
  clientId: string
  fields: PopulatedField[]
  formId: number
}

export const requestFeature = ({ clientId, fields, formId }: FeatureRequest): Promise<any> => {
  return request(route(ENDPOINTS.ADD_ONS.REQUEST_FEATURE, { clientId }), {
    method: HTTP.POST,
    body: { formId, formFields: fields },
  })
}
