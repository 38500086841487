import { BUTTON_VARIANTS, Button } from '@community_dev/pixels'
import styled from 'styled-components'

import { StyledOutlineButton } from 'screens/SettingsScreen/components/styled'

type CreateActionsProps = {
  isSaving: boolean
  isValid: boolean
  onBack(): any
  onSave(): any
}

const StyledSaveButton = styled(Button)`
  margin-left: auto;
`
export const CreateActions = (props: CreateActionsProps): JSX.Element => {
  const { isSaving, isValid, onSave, onBack } = props
  return (
    <>
      <StyledOutlineButton disabled={isSaving || !isValid} onClick={onBack} variant={BUTTON_VARIANTS.OUTLINE}>
        Back
      </StyledOutlineButton>
      <StyledSaveButton disabled={isSaving || !isValid} onClick={onSave}>
        Save
      </StyledSaveButton>
    </>
  )
}
