import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

const { VITE_SENTRY_ENABLED, VITE_SENTRY_DSN, VITE_SENTRY_ENVIRONMENT, VITE_TAG, VITE_SHA1 } = import.meta.env

Sentry.init({
  sampleRate: 0.25,
  dsn: VITE_SENTRY_DSN,
  environment: VITE_SENTRY_ENVIRONMENT || 'unknown',
  release: VITE_TAG || VITE_SHA1,
  integrations: [new BrowserTracing()],
  normalizeDepth: 10,
  tracesSampleRate: 0.02,
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications'],
  beforeSend: (event, hint) => {
    if (window.Cypress) {
      return null
    }

    if (import.meta.env.NODE_ENV === 'test') {
      return null
    }

    console.error(hint?.originalException || hint?.syntheticException)

    if (VITE_SENTRY_ENABLED === 'true') {
      return event
    } else {
      return null
    }
  },
})

export default Sentry
