import { useQuery } from '@tanstack/react-query'
import { useRef, useState } from 'react'

import { Communities, getAllCommunities, getCommunities } from 'api/community'
import { COMMUNITY_SORT_BY_MEMBER_COUNT } from 'constants/launch-darkly-flags'
import { QUERY_CACHE, REFETCH_INTERVAL_TIME } from 'constants/query-cache'
import { useClientId } from 'hooks/useClient'
import { userHasFlag } from 'hooks/useLaunchDarkly'

type UseUpdatingCommunities = {
  isLoading: boolean
  communities?: Communities
  isFetching: boolean
  willRefresh: boolean
}

type CommunityUpdating = {
  [id: string]: number
}

type UseUpdatingCommunitiesOptions = {
  maxRetries?: number
  refetchInterval?: number
}

const MAX_RETRIES = 10
/**
 * This hook will poll for update flag to change on communities
 * with a max retry of 10 per community. Sometimes a job will not be
 * handled in the back end and then it will stay in an updating state
 * forever. We don't want to keep polling for those.
 */
export function useUpdatingCommunities(options?: UseUpdatingCommunitiesOptions): UseUpdatingCommunities {
  const maxRetries = options?.maxRetries || MAX_RETRIES
  const refetchInterval = options?.refetchInterval || REFETCH_INTERVAL_TIME.FIVE_SECONDS
  const updatingRef = useRef<CommunityUpdating>({})
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const clientId = useClientId()
  const isSortByMemberCountEnabled = userHasFlag(COMMUNITY_SORT_BY_MEMBER_COUNT)
  const queryFn = () => (isSortByMemberCountEnabled ? getAllCommunities({ clientId }) : getCommunities({ clientId }))
  const {
    isLoading,
    data: communities,
    isFetching,
  } = useQuery<Communities>([QUERY_CACHE.TAGS], queryFn, {
    ...(shouldRefetch && {
      refetchInterval,
    }),
    onSuccess(communities) {
      // Increase the counts of all pending communities
      const updatedCount = {}
      Object.keys(updatingRef.current).forEach((key) => {
        updatedCount[key] = updatingRef.current[key] === maxRetries ? maxRetries : updatingRef.current[key] + 1
      })
      let shouldUpdate = false
      // Add all updating communities
      const updatingCommunities = communities.all.reduce((accum: CommunityUpdating, community): CommunityUpdating => {
        if (!community.updateInProgress) return accum
        // If we have an updating count, preserve it
        if (updatedCount[community.id] !== undefined) {
          accum[community.id] = updatedCount[community.id]
        } else {
          // Start the counter
          accum[community.id] = 0
        }

        shouldUpdate = shouldUpdate || accum[community.id] < maxRetries

        return accum
      }, {})
      updatingRef.current = updatingCommunities
      // Check if there are any that are updating that have less than maxRetries tries
      setShouldRefetch(shouldUpdate)
    },
  })

  return { isLoading, communities, isFetching, willRefresh: shouldRefetch }
}
