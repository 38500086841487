import * as React from "react";
const SvgKeywordResponse = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 90, height: 44, viewBox: "0 0 89 44", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M88.652 20.762h-6.3l-6.086-4.407c-2.153-1.55-7.305-2.203-11.489-1.433L4.836 25.855c-.316.067-.617.118-.918.2V23.8H.184v4.926c.035.449.351.898.933 1.332l11.438 8.28c2.152 1.552 7.304 2.204 11.488 1.434h.086l.082 3.973 14.539-6.644 45.234-8.243c2.868-.52 4.551-1.57 4.653-2.672h.015Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M88.652 20.762v-.184h-6.234l-6.035-4.371c-.801-.586-1.969-1.02-3.336-1.32-1.367-.301-2.934-.465-4.52-.465-1.265 0-2.566.098-3.765.316L4.82 25.652c-.32.067-.636.137-.933.203l.047.165h.183v-2.418H0v5.289h.184v-.184l-.184.02c.016.25.117.515.3.75a2.6 2.6 0 0 0 .716.699l11.441 8.281c.797.582 1.965 1.016 3.332 1.316 1.367.301 2.934.47 4.52.47 1.265 0 2.566-.102 3.77-.317l-.036-.184v.184h.082v-.184h-.184l.086 4.238 14.774-6.742 45.219-8.23c1.449-.266 2.597-.668 3.418-1.133.414-.234.734-.504.964-.785a1.7 1.7 0 0 0 .403-.918l-.184-.016v.184h.2v-5.762h-.184v.184h-.184v5.41h.184v-.184h-.184l-.016.168c-.015.23-.117.465-.316.715-.3.367-.8.719-1.52 1.035-.699.317-1.601.586-2.667.77l-45.235 8.226-14.304 6.543-.086-3.87h-.282a21.045 21.045 0 0 1-3.718.316 22.19 22.19 0 0 1-4.454-.45c-1.332-.3-2.464-.734-3.199-1.27L1.22 29.896a2.921 2.921 0 0 1-.637-.618c-.133-.203-.215-.402-.23-.586l-.02-.168H.168v.184h.184v-4.738h3.367v2.3l.215-.046c.285-.067.586-.137.902-.203l59.941-10.915a20.652 20.652 0 0 1 3.72-.316c1.566 0 3.1.164 4.448.45 1.336.3 2.47.734 3.203 1.269l6.137 4.437h6.352v-.183h-.184Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M64.895 10.066 4.835 21.012c-4.202.77-5.87 2.656-3.718 4.207l11.453 8.297c2.153 1.55 7.305 2.203 11.524 1.433l3.133-.566-2.965 4.539 14.57-6.66L84.152 24c4.2-.766 5.868-2.652 3.72-4.203L76.413 11.5c-2.168-1.55-7.32-2.188-11.52-1.434Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#fff",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m64.895 10.063-.036-.184L4.801 20.813c-1.5.269-2.684.683-3.5 1.187-.418.25-.735.516-.95.816-.218.301-.351.618-.351.95 0 .285.082.55.266.82.168.266.418.516.75.75l11.457 8.297c.8.582 1.964 1.015 3.332 1.336 1.367.297 2.937.465 4.535.465 1.285 0 2.57-.098 3.785-.332l2.719-.5-3.082 4.703 15.105-6.907 45.301-8.246c1.5-.27 2.684-.683 3.5-1.187.418-.25.734-.516.953-.817.215-.3.348-.617.348-.953 0-.28-.082-.55-.266-.816-.168-.266-.418-.516-.75-.75L76.5 11.332c-.8-.582-1.969-1.016-3.336-1.336-1.367-.3-2.934-.465-4.535-.465-1.285 0-2.57.098-3.785.332l.05.2.032.183a21.173 21.173 0 0 1 3.719-.316c1.566 0 3.117.168 4.453.453 1.332.3 2.465.734 3.199 1.265l11.457 8.297c.3.22.531.45.668.668.133.215.2.418.2.633 0 .234-.087.484-.286.734-.281.368-.781.754-1.5 1.07-.719.317-1.633.602-2.734.802L38.75 32.113l-14.023 6.41 2.851-4.375-3.535.653a21.173 21.173 0 0 1-3.719.316c-1.566 0-3.117-.168-4.449-.449-1.336-.3-2.469-.734-3.203-1.27L1.219 25.102c-.301-.215-.535-.45-.668-.668a1.17 1.17 0 0 1-.2-.633c0-.235.083-.485.282-.735.285-.367.785-.75 1.5-1.066.719-.32 1.637-.602 2.734-.805l60.059-10.93Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m27.629 23.852-5.969 1.101L4.852 13.836l-.2-2.852 5.97 1.836 6.667 4.438 6.152-1.117-6.75-4.407-.234-2.82 5.934 1.816 17.41 8.098v2.805l-5.903 1.066-6.304-3.922-3.75-.117 3.918 2.488Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m27.629 23.852-.016-.102-5.937 1.102L4.953 13.785l-.183-2.668 5.82 1.785 6.683 4.457 6.438-1.168-6.922-4.507-.23-2.637 5.8 1.785 17.34 8.063v2.652l-5.785 1.05-6.285-3.921-4.152-.117 4.183 2.636-.133 2.657h.102l-.016-.102.016.102.098.015.152-2.77-3.668-2.32 3.352.102 6.32 3.922 6.015-1.086v-2.953l-17.472-8.13-6.086-1.855.25 3.024 6.586 4.289-5.852 1.05-6.652-4.421-6.121-1.871.203 3.054L21.625 25.07l6.102-1.136v-.067Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m34.45 12.035 2.25 1.402 12.886-1.753v2.605l-8.688 1.719 2.57 1.535 14.106-1.785-.148 2.683-15.324 2.774-17.493-10.867-.148-2.754h18.926l-.086 2.77Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m34.45 12.035-.052.082 2.266 1.418 12.824-1.734v2.402l-8.855 1.754 2.82 1.684 14.02-1.786-.149 2.504-15.207 2.739L24.711 10.28l-.152-2.586h18.726l-.082 2.586-9.023 1.703.218.133.051-.082.016.098 8.922-1.684.097-2.953H24.36l.153 2.902 17.554 10.915 15.457-2.786.153-2.886-14.192 1.8-2.3-1.386 8.504-1.684v-2.805l-12.973 1.77-2.219-1.387-.047.086.016.098Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m65.895 12.066 6.886 1.036.031 2.652-5.882 1.004-6.606-3.707-18.992-5.809-.098-2.484 6.485 1.586 10.105 3.218-2.785-4.457-.25-2.636 6.469 1.601Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m65.895 12.066-.016.102 6.8 1.016.036 2.488-5.77.984-6.586-3.703L41.434 7.16l-.102-2.285 6.371 1.566 10.356 3.29-2.938-4.657-.215-2.469 6.285 1.551 4.637 7.996.05.016.017-.102.082-.05-4.653-8.028-6.652-1.652.266 2.805 2.667 4.238-9.851-3.121-6.621-1.633.117 2.684 19.023 5.828 6.637 3.722 6.004-1.02-.035-2.82-6.984-1.05v.097l.082-.05ZM67.996 6.309l-4.601-2.606L63.242.97l5.102 1.918 4.652 2.586 7.52 1.922-.149 2.636-3.453.582Zm19.39 4.14-.198 2.77-4.672.8-4.602-2.453.184-2.82Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m67.996 6.309.05-.082-4.554-2.57-.133-2.52 4.953 1.851 4.653 2.586 7.453 1.903-.133 2.488-3.351.566-8.887-4.304-.05.082.05-.082-.102.164 8.938 4.324 3.566-.602.153-2.804-7.586-1.934-4.637-2.57-5.254-1.97.168 2.938 4.652 2.618Zm19.39 4.14h-.1l-.2 2.688-4.55.781-4.52-2.402.183-2.653 9.172 1.684.016-.098h-.102.102l.015-.101-9.386-1.72-.2 3.005 4.684 2.504 4.77-.817.218-2.937-.086-.02Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m27.629 21.063-5.969 1.101L4.852 11.051l5.77-1.035 6.667 4.437 6.156-1.101-6.754-4.407 5.704-1L39.8 18.828l-5.903 1.082-6.304-3.922-6.2 1.121Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#fff",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m27.629 21.063-.016-.098-5.937 1.101-16.59-10.968 5.52-.985 6.667 4.442 6.418-1.153-6.75-4.406 5.434-.969 17.176 10.75-5.637 1.035-6.3-3.921-6.473 1.183 6.437 4.055.05-.067-.015-.097.016.098.05-.083-6.038-3.824 5.937-1.066 6.3 3.906 6.173-1.117L22.41 7.844l-5.97 1.05 6.77 4.407-5.887 1.054-6.668-4.44L4.617 11l17.008 11.266 6.254-1.153-.2-.133Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m34.45 9.246 2.25 1.387 8.417-1.469 4.285 2.535-8.5 1.504 2.567 1.55 9.468-1.683 4.489 2.586-15.324 2.754L24.609 7.543l14.809-2.57 4.332 2.656Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#fff",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m34.45 9.246-.052.082 2.286 1.402 8.418-1.484 4.035 2.402-8.485 1.504 2.801 1.684 9.469-1.684 4.234 2.453-15.039 2.704L24.86 7.594l14.54-2.52 4.085 2.504L34.2 9.195l.2.133.05-.082.016.082L44 7.676 39.434 4.89 24.359 7.492l17.727 11.016 15.605-2.82-4.734-2.72-9.473 1.684-2.316-1.398 8.484-1.504-4.515-2.688-8.422 1.485-2.219-1.383-.047.082.016.082Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m65.895 9.262 6.703 3.707-5.668 1-6.606-3.723-18.789-5.64 6.184-1.051 10.105 3.203-2.953-4.406 6.387-1.067Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#fff",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m65.895 9.262-.051.086 6.484 3.57-5.383.953-6.586-3.707-18.441-5.523 5.8-.985 10.34 3.27-3.035-4.504 6.184-1.035 4.621 7.941.016.02.05-.086.067-.032-4.652-8.062-6.586 1.117 2.882 4.309-9.87-3.14-6.602 1.136 19.16 5.742 6.617 3.723 5.953-1.035-6.937-3.84-.031.082.066-.032Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M67.996 3.523 63.395.918l4.933-.816 4.652 2.586 7.372 4.554-3.454.586Zm14.54 4.473 4.651 2.434-4.671.804-4.602-2.457Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#fff",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m67.996 3.523.05-.086L63.677.97l4.652-.77 4.617 2.57 7.172 4.442-3.183.55-8.887-4.308-.05.07.05-.086-.05.086-.036.082 8.937 4.325 3.72-.637-7.587-4.688L68.344 0l-5.235.867 4.836 2.723Zm14.54 4.473-.052.082 4.403 2.305-4.371.75-4.332-2.305 4.351-.75v-.082l-.05.082.05-.082-.02-.086-4.882.836 4.867 2.586 4.953-.852-4.902-2.57h-.035Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m4.203 25.855 8.137 5.739c.664.468 1.933 1.187 2.7 1.468 2.269.82 6.187 1.22 12.003-.082", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#fff",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.203 25.855c1.8 1.102 3.55 2.25 5.3 3.422.884.582 1.735 1.184 2.602 1.766.215.152.434.3.633.437l.653.399c.449.25.898.5 1.367.703.914.383 1.933.617 2.949.766 1.016.152 2.05.183 3.102.183.265 0 .515 0 .785-.015.265-.016.515-.016.781-.036.27-.015.52-.03.785-.05l.781-.067c1.036-.097 2.086-.25 3.122-.398-1.02.3-2.036.535-3.07.734l-.782.133c-.27.05-.52.066-.785.102-.266.03-.535.046-.785.082-.266.035-.532.035-.797.05-2.121.098-4.285-.035-6.32-.867-.5-.219-.97-.484-1.434-.754-.235-.133-.453-.28-.684-.414a9.868 9.868 0 0 1-.652-.468c-.848-.618-1.7-1.235-2.535-1.872-1.7-1.234-3.383-2.52-5.016-3.836Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "m75.148 24.57 10.52-1.988s3.418-.934 1.152-2.586c-2.254-1.652-4.902-3.355-4.902-3.355", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#fff",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M75.148 24.57c.868-.203 1.75-.386 2.618-.586l2.617-.55c1.75-.352 3.5-.7 5.254-1.02h-.02c.387-.117.77-.266 1.102-.465.168-.101.332-.219.469-.351.132-.133.23-.285.25-.45a.743.743 0 0 0-.118-.484 2.328 2.328 0 0 0-.382-.434c-.153-.117-.32-.25-.485-.367l-.5-.367c-.668-.484-1.336-.969-2-1.453l-2.02-1.438c.72.418 1.419.872 2.102 1.305.7.45 1.383.899 2.067 1.367l.515.352c.168.117.336.234.504.367.164.133.317.3.45.484a.983.983 0 0 1 .183.684c-.035.25-.168.469-.336.617a2.748 2.748 0 0 1-.516.403 5.036 5.036 0 0 1-1.183.5c-1.75.332-3.5.648-5.27.968-.883.149-1.75.297-2.633.45-.902.183-1.785.332-2.668.468Zm0 0", style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "#000",
  fillOpacity: 1
} }));
export default SvgKeywordResponse;
