import { useMemo } from 'react'

import useColorScheme from './useColorScheme'

import { useSettings } from 'contexts/SettingsProvider/SettingsProvider'
import { Theme, themes } from 'contexts/ThemeProvider'

export default function useCurrentTheme(): Theme {
  const colorScheme = useColorScheme()
  const { settings } = useSettings()

  const key = useMemo(() => {
    if (settings.colorMode === 'auto') {
      if (colorScheme === 'light') {
        return settings.lightTheme
      } else if (colorScheme === 'dark') {
        return settings.darkTheme
      }
    } else if (settings.colorMode === 'dark') {
      return settings.darkTheme
    }
    return settings.lightTheme
  }, [settings, colorScheme])

  return themes[key] as Theme
}
