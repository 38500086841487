import { useEffect, useState } from 'react'

type ColorScheme = 'light' | 'dark' | 'none'

const makeEffectHandler = (colorScheme: ColorScheme, onMatch: (colorScheme: ColorScheme) => void) => () => {
  if ('matchMedia' in window) {
    const handleMqChange = (e) => {
      if (e.matches) {
        onMatch(colorScheme)
      }
    }

    const mq = window.matchMedia(`(prefers-color-scheme: ${colorScheme})`)

    if (mq.matches) {
      onMatch(colorScheme)
    }

    mq.addEventListener('change', handleMqChange)

    return () => {
      mq.removeEventListener('change', handleMqChange)
    }
  }
}

export default function useColorScheme(): ColorScheme {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('none')

  useEffect(makeEffectHandler('dark', setColorScheme), []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(makeEffectHandler('light', setColorScheme), []) // eslint-disable-line react-hooks/exhaustive-deps

  return colorScheme
}
