import { getRoot, removeNode, serializeFilters } from '@community_dev/filter-dsl/lib/subscription-data'
import { useContainer, useRefRect, useWindowSize } from '@community_dev/hooks'
import { BORDER_RADIUS, Layout } from '@community_dev/pixels'
import { Modal as ModalBase } from '@restart/ui'
import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { RecipientPane, RecipientPaneHandle } from '../RecipientPane'

import { useRecipientField } from './RecipientFieldContext'
import { RecommendationsAge } from './RecommendationsAge'
import { RecommendationsCommunities } from './RecommendationsCommunities'
import { RecommendationsCustomFields } from './RecommendationsCustomFields'
import { RecommendationsGenderIdentity } from './RecommendationsGenderIdentity'
import { RecommendationsJoin } from './RecommendationsJoin'
import { RecommendationsLocation } from './RecommendationsLocation'
import { RecommendationsTimezone } from './RecommendationsTimezone'
import { StyledAddRecipientButton, StyledFilterPills, StyledLabel, StyledRecipientField } from './styled'
import RemoveIcon from './svg/circle-minus.svg?react'

import { FilterPane } from 'containers/RecipientRecommendations/recommendation-constants'
import { FilterSelectionType, useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useCommunityLookup } from 'hooks/useCommunities'
import analytics from 'utils/analytics'
const StyledWrapper = styled.div`
  position: relative;
`

type StyledModalBaseProps = {
  $top?: React.CSSProperties['top']
  $height?: React.CSSProperties['height']
}

const StyledModalBase = styled(ModalBase)<StyledModalBaseProps>`
  position: absolute;
  box-shadow: ${({ theme }) => theme.SHADOWS.CARD};
  top: ${({ $top }) => $top};
  height: ${({ $height }) => $height};
  width: 100%;
  left: 0;
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-bottom-left-radius: ${BORDER_RADIUS[1]};
  border-bottom-right-radius: ${BORDER_RADIUS[1]};
  z-index: 2;
  background: transparent;
  overflow: hidden;
`

const StyledBackdrop = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
`
const PANES: Record<string, any> = {
  [FilterPane.COMMUNITY]: (type: FilterSelectionType) => () => <RecommendationsCommunities type={type} />,
  [FilterPane.LOCATION]: (type: FilterSelectionType) => () => <RecommendationsLocation type={type} />,
  [FilterPane.CUSTOM_FIELDS]: (type: FilterSelectionType) => () => <RecommendationsCustomFields type={type} />,
  [FilterPane.GENDER]: (type: FilterSelectionType) => () => <RecommendationsGenderIdentity type={type} />,
  [FilterPane.JOIN]: (type: FilterSelectionType) => () => <RecommendationsJoin type={type} />,
  [FilterPane.TIMEZONE]: (type: FilterSelectionType) => () => <RecommendationsTimezone type={type} />,
  [FilterPane.AGE]: (type: FilterSelectionType) => () => <RecommendationsAge type={type} />,
}

export const ExclusionField = (): JSX.Element => {
  const { t } = useTranslation()
  const { isOpen, setIsOpen } = useRecipientField()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const mouseDownRef = useRef()
  const recipientPaneRef = useRef<RecipientPaneHandle>(null)
  const { filters, excludedFilters, excludedFiltersAst, includedFilters, setActiveSubtree, setExcludedFilters } =
    useFilters()

  // included filters are required for adding exclusions, so if they are removed,
  // we should remove the exclusions as well
  useEffect(() => {
    if (!includedFilters) {
      setExcludedFilters(null)
    }
  }, [includedFilters, setExcludedFilters])

  const openRecipientPane = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const closeRecipientPane = useCallback(() => {
    setIsOpen(false)
    setActiveSubtree(null)
  }, [setIsOpen, setActiveSubtree])

  const toggleRecipientPane = useCallback(() => {
    if (isOpen) {
      closeRecipientPane()
    } else {
      openRecipientPane()
    }
  }, [isOpen, closeRecipientPane, openRecipientPane])

  useEffect(() => {
    if (!isOpen) return
    function handleClickRecipientField(event) {
      event.stopPropagation()
      if (recipientPaneRef.current?.contains(event) || wrapperRef.current?.contains(event.target)) {
        return
      }

      toggleRecipientPane()
    }

    function onMouseDown(event) {
      mouseDownRef.current = event.target
    }

    function onMouseUp(event) {
      const downElement = mouseDownRef.current
      mouseDownRef.current = undefined
      if (downElement !== recipientPaneRef.current) return
      handleClickRecipientField(event)
    }

    document.body.addEventListener('mousedown', onMouseDown)
    document.body.addEventListener('mouseup', onMouseUp)

    return () => {
      document.body.removeEventListener('mousedown', onMouseDown)
      document.body.removeEventListener('mouseup', onMouseUp)
    }
  }, [isOpen, toggleRecipientPane])

  const communityLookup = useCommunityLookup()

  const [, windowHeight] = useWindowSize()
  const { y: fieldTop = 0, height: fieldHeight = 0 } = useRefRect(wrapperRef, [filters])
  const modalHeight = Math.max(Math.min(windowHeight - fieldTop - fieldHeight - 16, 600), 300)

  const containerId = 'exclusion-pane-container'
  const container = useContainer(containerId)

  return (
    <StyledWrapper ref={wrapperRef}>
      <StyledRecipientField
        data-testid="exclusion-field"
        onClick={
          !excludedFilters
            ? () => {
                toggleRecipientPane()
                setActiveSubtree(excludedFiltersAst)
              }
            : undefined
        }
      >
        <StyledLabel>
          <StyledLabel>Excluding: </StyledLabel>
          {!excludedFilters && (
            <StyledAddRecipientButton aria-label={t('compose.addExcludedFilter')}>
              <RemoveIcon />
            </StyledAddRecipientButton>
          )}
        </StyledLabel>
        {excludedFilters && (
          <StyledFilterPills
            communityLookup={communityLookup}
            filters={excludedFilters}
            onAddAndClick={(_, subtree) => {
              setActiveSubtree(subtree)
              toggleRecipientPane()

              analytics.track(analytics.events.PressANDFilter())
            }}
            onAddOrClick={(_, subtree) => {
              setActiveSubtree(subtree)
              toggleRecipientPane()

              analytics.track(analytics.events.PressORFilter())
            }}
            onPillClick={(_, subtree) => {
              setActiveSubtree(subtree)
              toggleRecipientPane()
            }}
            onRemove={(_, node) => {
              setActiveSubtree(excludedFiltersAst)
              const newSubtree = removeNode(node)
              const root = getRoot(newSubtree)
              setExcludedFilters(serializeFilters(root))
            }}
          />
        )}
      </StyledRecipientField>

      <StyledModalBase
        $height={modalHeight}
        $top="-19px"
        container={container}
        onHide={() => closeRecipientPane()}
        renderBackdrop={() => (
          <StyledBackdrop data-testid={`${containerId}-backdrop`} onClick={() => closeRecipientPane()} />
        )}
        role="menu"
        show={isOpen}
      >
        <RecipientPane
          initialPane={FilterPane.COMMUNITY}
          panes={PANES}
          ref={recipientPaneRef}
          style={{ height: modalHeight }}
          type="excludes"
        />
      </StyledModalBase>

      <Layout id={containerId} position="relative" />
    </StyledWrapper>
  )
}
