import noop from 'lodash/noop'
import React, { useContext, useEffect, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'

import { CACHE } from 'constants/cache'
import { FILTER_STATE } from 'constants/filter-states'
import { SearchTabType } from 'constants/messages'
import { useInboxMessages } from 'hooks/useInboxMessages'

export const initialState: InboxContextValue = {
  filters: [],
  setFilters: noop,
  search: '',
  setSearch: noop,
  searchTab: SearchTabType.LATEST_MESSAGE,
  setSearchTab: noop,
  bounds: undefined,
  setBounds: noop,
  items: [],
  empty: false,
} as unknown as InboxContextValue

export type InboxContextValue = {
  filters: InboxFilter[]
  setFilters: React.Dispatch<React.SetStateAction<InboxFilter[]>>
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  searchTab: SearchTabType
  setSearchTab: React.Dispatch<React.SetStateAction<SearchTabType>>
  empty: boolean
} & ReturnType<typeof useInboxMessages>

export const InboxContext = React.createContext<InboxContextValue>(initialState)

InboxContext.displayName = 'InboxContext'

export function useInbox(): InboxContextValue {
  const context = useContext(InboxContext)

  if (!context) {
    throw new Error('useInbox must be used within a InboxProvider')
  }

  return context
}

type InboxProviderProps = {
  children?: React.ReactNode
}

export type InboxFilter = {
  field: string
  key: string
  label: string
  value: string
}

export function InboxProvider({ children }: InboxProviderProps): JSX.Element {
  const [persistedFilters, saveFilters] = useLocalStorageState(CACHE.INBOX_FILTERS, {
    defaultValue: {
      filters: FILTER_STATE.INITIAL,
      search: '',
      searchTab: SearchTabType.LATEST_MESSAGE,
    },
  })
  const [filters, setFilters] = useState<InboxFilter[]>(persistedFilters.filters)
  const [searchTerm, setSearchTerm] = useState(persistedFilters.search)
  const [searchTab, setSearchTab] = useState(persistedFilters.searchTab)

  const { items = [], ...rest } = useInboxMessages({
    filters,
    searchTerm,
    searchTab,
  })

  const empty = items.length === 0 && !rest.isLoading

  useEffect(() => {
    saveFilters({ filters, search: searchTerm, searchTab })
  }, [filters, saveFilters, searchTerm, searchTab])

  return (
    <InboxContext.Provider
      value={{
        filters,
        setFilters,
        searchTerm,
        setSearchTerm,
        searchTab,
        setSearchTab,
        items,
        empty,
        ...rest,
      }}
    >
      {children}
    </InboxContext.Provider>
  )
}
