import {
  Layout,
  SPACING,
  BORDER_RADIUS,
  Button,
  BUTTON_VARIANTS,
  TYPOGRAPHY,
  FONT_WEIGHT,
  FONT_SIZE,
} from '@community_dev/pixels'
import { transparentize } from 'polished'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { formatCurrency } from '../utils/formatCurrency'

import {
  StyledCell,
  StyledHeaderCell,
  StyledHeaderRow,
  StyledRow,
  StyledSecondaryRow,
  StyledTable,
} from 'components/SettingsTable/SettingsTable.style'
import { useCreditBundlesQuery } from 'hooks/useCreditBundlesQuery'
import { ChevronDownIcon } from 'screens/SettingsScreen/components/EmbedSettings/Icons'
import dayjs from 'utils/dayjs'

const CreditBundlesContainer = styled.div`
  width: 100%;
  table {
    margin-top: ${SPACING[4]};
  }
`

const StyledButton = styled(Button)`
  ${TYPOGRAPHY.VARIANT.BODY1};
  color: ${({ theme }) => theme?.COLORS?.TEXT};

  &:hover {
    color: ${({ theme }) => theme?.COLORS?.TEXT};
  }
  display: flex;
  align-items: center;
  text-transform: none;
  font-weight: ${FONT_WEIGHT[6]};
  font-size: ${FONT_SIZE[2]};
`

const StyledAnimatedIcon = styled.div<{ isOpen: boolean }>`
  display: inline-block;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`

const RateRenderer = ({ label, rate }: { label: string; rate: number }): JSX.Element => {
  return (
    <Layout display="flex" justifyContent="space-between" padding={`${SPACING[1]} 0`}>
      <span>{label}:</span>
      <span>{formatCurrency(rate)}</span>
    </Layout>
  )
}

const StyledRateCell = styled(StyledCell)`
  padding: ${SPACING[3]} ${SPACING[6]} ${SPACING[3]} ${SPACING[4]};
  div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  }
`

const StyledBoldedCell = styled(StyledCell)`
  font-weight: ${FONT_WEIGHT[6]};
  text-wrap: nowrap;
`
export const CreditBundles = ({ className }: { className?: string }): JSX.Element | null => {
  const { COLORS } = useTheme()
  const { data: creditBundles } = useCreditBundlesQuery()

  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation(undefined, { keyPrefix: 'settings.billing.termedInvoice.segmentBasedBilling' })

  if (!creditBundles) return null

  return (
    <CreditBundlesContainer className={className}>
      <Layout
        backgroundColor={transparentize(0.92, COLORS.BILLING_CREDIT_BUNDLES_BACKGROUND)}
        borderRadius={BORDER_RADIUS[1]}
        display="flex"
        fontSize="14px"
        justifyContent="space-between"
        padding={`${SPACING[4]}`}
        width="100%"
      >
        {t('prePaidCreditsAvailable', {
          credits: formatCurrency(creditBundles?.creditsRemainingSum),
        })}
        <StyledButton onClick={() => setIsOpen((prev) => !prev)} variant={BUTTON_VARIANTS.TEXT}>
          {isOpen ? t('hideDetails') : t('viewDetails')}
          <StyledAnimatedIcon isOpen={isOpen}>
            <ChevronDownIcon size={24} />
          </StyledAnimatedIcon>
        </StyledButton>
      </Layout>
      {isOpen ? (
        <StyledTable style={{ width: '100%', textAlign: 'left' }}>
          <thead>
            <StyledHeaderRow>
              <StyledHeaderCell></StyledHeaderCell>
              <StyledHeaderCell $align="right">{t('creditBundleTable.header.bundleStartDate')}</StyledHeaderCell>
              <StyledHeaderCell $align="right">{t('creditBundleTable.header.expirationDate')}</StyledHeaderCell>
              <StyledHeaderCell $align="right">{t('creditBundleTable.header.creditPurchased')}</StyledHeaderCell>
              <StyledHeaderCell $align="right">{t('creditBundleTable.header.creditRemaining')}</StyledHeaderCell>
            </StyledHeaderRow>
          </thead>
          <tbody>
            {creditBundles?.creditBundles.map((creditBundle, index) => (
              <>
                <StyledRow key={index}>
                  <StyledBoldedCell>{t('creditBundleTable.row.creditBundle')}</StyledBoldedCell>
                  <StyledCell $align="right">{dayjs(creditBundle.startDate).format('MM/DD/YYYY')}</StyledCell>
                  <StyledCell $align="right">{dayjs(creditBundle.expirationDate).format('MM/DD/YYYY')}</StyledCell>
                  <StyledCell $align="right">{formatCurrency(creditBundle.creditsPurchased)}</StyledCell>
                  <StyledCell $align="right">{formatCurrency(creditBundle.creditsRemaining)}</StyledCell>
                </StyledRow>
                <StyledSecondaryRow key={index}>
                  <StyledRateCell colSpan={2}>
                    {creditBundle?.rateSmsUsage ? (
                      <RateRenderer
                        label={t('creditBundleTable.row.smsSegmentRate')}
                        rate={creditBundle.rateSmsUsage}
                      />
                    ) : null}
                    {creditBundle?.rateMmsUsage ? (
                      <RateRenderer
                        label={t('creditBundleTable.row.mmsMessageRate')}
                        rate={creditBundle.rateMmsUsage}
                      />
                    ) : null}
                    {creditBundle?.rateWhatsappUsage ? (
                      <RateRenderer
                        label={t('creditBundleTable.row.whatsAppMessageRate')}
                        rate={creditBundle.rateWhatsappUsage}
                      />
                    ) : null}
                  </StyledRateCell>
                  <StyledCell colSpan={3}></StyledCell>
                </StyledSecondaryRow>
              </>
            ))}
          </tbody>
        </StyledTable>
      ) : null}
    </CreditBundlesContainer>
  )
}
