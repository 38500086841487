import { SPACING, Tags } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { CommunicationChannelSelect } from 'components/ComposeMessage/components/CommunicationChannelSelect'
import { Filter } from 'components/MessageSearch/Filter'
import { SearchInput } from 'components/SearchInput'
import { FILTER_STATE } from 'constants/filter-states'
import { ROUTES } from 'constants/routes'
import { useCommunities } from 'hooks/useCommunities'

const StyledSearch = styled.div`
  display: flex;
`

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
`

const StyledTags = styled.div`
  margin: 0 0 ${SPACING[3]};
`

export type InboxFilter = {
  field: string
  key: string
  label: string
  value: string
  color?: string
  icon?: IconProp
}

export function FullTextSearch({
  filters,
  onFilterChange,
}: {
  filters: InboxFilter[]
  onFilterChange: (...args: any[]) => any
}): JSX.Element {
  const [redirect, setRedirect] = useState<string>()
  const filterTagList = useMemo(
    () =>
      filters
        ? filters
            .map((filter) => {
              return filter.field !== 'search[communication_channel]'
                ? {
                    id: filter.key,
                    title: filter.label,
                    color: filter.color || '',
                    icon: filter.icon && (
                      <FontAwesomeIcon icon={filter.icon} style={{ color: filter.color, width: 10 }} />
                    ),
                    key: filter.key,
                  }
                : undefined
            })
            .filter((e): e is Exclude<typeof e, undefined> => e !== undefined)
        : [],
    [filters],
  )

  const communicationChannel = useMemo(() => {
    return (
      (filters?.find((filter) => filter.field === 'search[communication_channel]')?.value as CommunicationChannel) ||
      CommunicationChannel.SMS
    )
  }, [filters])

  const { data: communitiesWithCommunicationChannel } = useCommunities({
    select: ({ all }) => {
      return all.filter((community) => community.communicationChannels?.includes(communicationChannel))
    },
  })

  const handleSearch = useCallback(
    (value) => {
      setRedirect(`${ROUTES.SEARCH}?term=${value}&exact=true&communicationChannel=${communicationChannel}`)
    },
    [communicationChannel],
  )

  const onClearFilter = useCallback(
    (filterId) => {
      const filterToRemove = filterTagList.find((filter) => filter?.id === filterId)
      const newFilters = filters.filter((filter) => filter.key !== filterToRemove?.key)
      onFilterChange(!newFilters.length ? FILTER_STATE.INITIAL : newFilters)
    },
    [filterTagList, filters, onFilterChange],
  )

  if (redirect) return <Redirect push to={redirect} />

  return (
    <>
      <StyledSearch>
        <StyledSearchInput onSearch={handleSearch} />
        <Filter communities={communitiesWithCommunicationChannel} filters={filters} setFilter={onFilterChange} />
        <CommunicationChannelSelect
          compact
          css={{ marginLeft: SPACING[1] }}
          data-testid="full-text-search-communication-channel-select"
          inline
          onChange={(value) => {
            if (value !== communicationChannel) {
              onFilterChange([{ field: 'search[communication_channel]', key: value, value }])
            }
          }}
          value={communicationChannel}
          width={60}
        />
      </StyledSearch>
      {filterTagList.length > 0 && (
        <StyledTags data-testid="message-filters">
          <Tags onRemove={(id) => onClearFilter(id)} tags={filterTagList} />
        </StyledTags>
      )}
    </>
  )
}
