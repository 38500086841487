import { BORDER_RADIUS, Image, MissingLinkImageIcon, SPACING } from '@community_dev/pixels'
import { ellipsis } from 'polished'
import React, { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'

const StyledDetailListLinkImg = styled(Image)`
  width: 40px;
  height: 100%;
  object-fit: cover;
`

const StyledDetailListPreview = styled.a`
  display: flex;
  height: 40px;
  margin: 0 0 ${SPACING[4]};
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  overflow: hidden;
  text-decoration: none;
  border-radius: ${BORDER_RADIUS[1]};
`
const StyledDetailListLinkCopy = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 4px 8px;
  overflow: hidden;
`
const StyledDetailListLinkCopyHeader = styled.div`
  ${ellipsis('100%')}
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  margin: 0;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`
const StyledDetailListLinkCopySubHeader = styled.div`
  ${ellipsis('100%')}
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION2};
  margin: 0;
`

const StyledMissingImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  min-height: 40px;
  background: ${({ theme }) => theme?.COLORS?.BORDERS};
`

export type LinkPreviewProps = {
  metric: {
    url?: string
    img?: string
    title?: React.ReactNode
    subtitle?: React.ReactNode
  }
}
export const LinkPreview = ({ metric }: LinkPreviewProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const altText = metric.title ? `Link preview image for ${metric.title}` : 'Link preview image'

  const url = useMemo(() => {
    if (metric.img) {
      try {
        const url = new URL(metric.img)
        return url.href
      } catch (e) {
        // invalid url
      }
    }
    return ''
  }, [metric.img])

  return (
    <StyledDetailListPreview href={metric.url} target="_blank">
      <StyledDetailListLinkCopy>
        <StyledDetailListLinkCopyHeader>{metric.title || 'No Title'}</StyledDetailListLinkCopyHeader>
        <StyledDetailListLinkCopySubHeader>{metric.subtitle || metric.url}</StyledDetailListLinkCopySubHeader>
      </StyledDetailListLinkCopy>
      {url ? (
        <StyledDetailListLinkImg alt={altText} src={url} />
      ) : (
        <StyledMissingImage>
          <MissingLinkImageIcon aria-label="missing-link-image-icon" color={COLORS?.APP_BACKGROUND_LEVEL_3} />
        </StyledMissingImage>
      )}
    </StyledDetailListPreview>
  )
}
