import { DIALOG_VARIANTS, Dialog } from '@community_dev/pixels'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import uniq from 'lodash/uniq'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useCompose } from '../ComposeContext'

import LinkWarningModal from 'components/ComposeMessage/components/LinkWarningModal'
import { MessagesLimitsModal, useMessagesLimitsModal } from 'components/ComposeMessage/components/MessagesLimits'
import { PT_TIME_ZONE } from 'constants/date'
import { WarningTypes } from 'hooks/useSendMessage/useCheckMessageContent'
import { CheckTypes, ErrorTypes } from 'hooks/useSendMessage/useSendMessage'
import analytics from 'utils/analytics'
import { screens } from 'utils/analytics/events'
import dayjs from 'utils/dayjs'

type ComposeDialogsProps = {
  error: any
  hasViolation: boolean
  isSentReply?: boolean
  mediaDisposition: MediaDisposition
  warning: any
  onSchedule(): void
  onSend(type: CheckTypes[]): void
}

export type ComposeDialogsHandle = {
  showMessageLimitsDialog: () => void
}

export const ComposeDialogs = forwardRef<ComposeDialogsHandle, ComposeDialogsProps>(
  (
    { error, hasViolation, isSentReply = false, mediaDisposition, warning, onSchedule, onSend }: ComposeDialogsProps,
    ref: any,
  ): JSX.Element | null => {
    const { t } = useTranslation()
    const [isMessagesLimitDialogVisible, setIsMessagesLimitDialogVisible] = useState(false)
    const [isBirthdayDialogVisible, setIsBirthdayDialogVisible] = useState(false)
    const [isBadWordsDialogVisible, setIsBadWordsDialogVisible] = useState(false)
    const [isQuietTimeDialogVisible, setIsQuietTimeDialogVisible] = useState(false)
    const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false)
    const [isMultipleLinksDialogVisible, setIsMultipleLinksDialogVisible] = useState(false)
    const [bypassWarnings, setBypassWarnings] = useState<CheckTypes[]>([])
    const tz = dayjs.tz.guess()
    const startTimeLA = dayjs().tz(PT_TIME_ZONE).startOf('day').add(8, 'hours')
    const endTimeLA = dayjs().tz(PT_TIME_ZONE).startOf('day').add(17, 'hours')
    const startTimeLocal = dayjs(startTimeLA).tz(tz).format('H:00 A z')
    const endTimeLocal = dayjs(endTimeLA).tz(tz).format('h:00 A z')
    const badWords = useMemo(
      () =>
        uniq([
          ...(warning?.badWords?.general || []),
          ...(warning?.badWords?.alcohol || []),
          ...(warning?.badWords?.tobacco || []),
          ...(warning?.badWords?.violations || []),
        ]).reduce((words, word, index, array) => {
          const isLastWord = array.length - 1 === index
          const nextWord = `"${word}"${isLastWord ? '' : ', '}`

          return `${words}${nextWord}`
        }, ''),

      [warning],
    )
    const { text, scheduledAt } = useCompose()
    const { hasLimitReached, monthlyRemaining, monthlyThreshold } = useMessagesLimitsModal({
      enabled: !isSentReply,
      scheduledAt,
    })

    useEffect(() => {
      const warningType = warning?.type
      const errorType = error?.type

      if (errorType === ErrorTypes.MESSAGES_LIMIT) {
        analytics.track(analytics.events.MessageLimitAlertTriggered('Info'))
        return setIsMessagesLimitDialogVisible(true)
      }

      if (warningType === WarningTypes.QUIET_TIME) {
        return setIsQuietTimeDialogVisible(true)
      }

      if (warningType === WarningTypes.BAD_WORDS) {
        analytics.track(
          analytics.events.ContentWarningTriggered({
            content: text,
            page: screens.Compose,
            triggers: badWords,
          }),
        )
        return setIsBadWordsDialogVisible(true)
      }

      if (warningType === WarningTypes.LINK_PREVIEW) {
        analytics.track(analytics.events.LinkWarningDisplayed())
        return setIsMultipleLinksDialogVisible(true)
      }

      if (warningType === WarningTypes.BIRTHDAY_FILTER) {
        return setIsBirthdayDialogVisible(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, warning])

    useImperativeHandle(ref, () => ({
      showMessageLimitsDialog: () => {
        setIsMessagesLimitDialogVisible(true)
      },
    }))

    function handleCancelBadWordsDialog() {
      setIsBadWordsDialogVisible(false)
      analytics.track(
        analytics.events.ContentWarningMessageEdited({
          content: text,
          page: screens.Compose,
          triggers: badWords,
        }),
      )
    }

    function handleCancelConfirmationDialog() {
      setIsConfirmationDialogVisible(false)
    }

    function handleClickSendAnywayBadWords() {
      setIsBadWordsDialogVisible(false)
      setIsConfirmationDialogVisible(true)
      analytics.track(
        analytics.events.ContentWarningOverridden({
          content: text,
          page: screens.Compose,
          triggers: badWords,
        }),
      )
    }

    function handleClickIUnderstandSendBadWords() {
      setBypassWarnings([...bypassWarnings, WarningTypes.BAD_WORDS])
      onSend([...bypassWarnings, WarningTypes.BAD_WORDS])
    }

    function handleClickSendAnywayBirthday() {
      setBypassWarnings([...bypassWarnings, WarningTypes.BIRTHDAY_FILTER])
      onSend([...bypassWarnings, WarningTypes.BIRTHDAY_FILTER])
    }

    function handleCancelBirthdayDialog() {
      setIsBirthdayDialogVisible(false)
    }

    function handleSendMultipleLinksDialog() {
      analytics.track(analytics.events.LinkWarningBypassed())
      setIsMultipleLinksDialogVisible(false)
      setBypassWarnings([...bypassWarnings, WarningTypes.LINK_PREVIEW])
      onSend([...bypassWarnings, WarningTypes.LINK_PREVIEW])
    }

    function handleCancelMultipleLinksDialog() {
      setIsMultipleLinksDialogVisible(false)
    }

    function handleCancelQuietTimeDialog() {
      setIsQuietTimeDialogVisible(false)
    }

    function handleClickScheduleQuietTime() {
      setIsQuietTimeDialogVisible(false)
      onSchedule()
    }

    function handleClickSendAnywayQuietTime() {
      setIsQuietTimeDialogVisible(false)
      setBypassWarnings([...bypassWarnings, WarningTypes.QUIET_TIME])
      onSend([...bypassWarnings, WarningTypes.QUIET_TIME])
    }

    if (isMessagesLimitDialogVisible) {
      return (
        <MessagesLimitsModal
          hasLimitReached={hasLimitReached}
          monthlyRemaining={monthlyRemaining}
          monthlyThreshold={monthlyThreshold}
          onClose={() => setIsMessagesLimitDialogVisible(false)}
          visible
        />
      )
    }

    if (isBirthdayDialogVisible)
      return (
        <Dialog
          message={t('compose.birthdayDialogDescription')}
          onCancel={handleCancelBirthdayDialog}
          title={t('compose.birthdayDialogTitle')}
        >
          <Dialog.Action onClick={handleClickSendAnywayBirthday}>{t('compose.sendAnyway')}</Dialog.Action>
          <Dialog.Action onClick={handleCancelBirthdayDialog} variant={DIALOG_VARIANTS.EMPHASIZED}>
            {t('compose.editMessage')}
          </Dialog.Action>
        </Dialog>
      )

    if (isMultipleLinksDialogVisible)
      return (
        <LinkWarningModal
          isOpen
          mediaDisposition={mediaDisposition}
          onCancel={handleCancelMultipleLinksDialog}
          onSend={handleSendMultipleLinksDialog}
        />
      )

    if (isQuietTimeDialogVisible)
      return (
        <Dialog
          message={<Trans i18nKey="compose.quietTimeDialogDescription" values={{ startTimeLocal, endTimeLocal }} />}
          onCancel={handleCancelQuietTimeDialog}
          title={t('compose.quietTimeDialogTitle')}
        >
          <Dialog.Action
            onClick={handleCancelQuietTimeDialog}
            variant={scheduledAt ? DIALOG_VARIANTS.EMPHASIZED : undefined}
          >
            {scheduledAt ? t('edit') : t('cancel')}
          </Dialog.Action>
          <Dialog.Action onClick={handleClickSendAnywayQuietTime}>
            {scheduledAt ? t('compose.scheduleAnyway') : t('compose.sendItNow')}
          </Dialog.Action>
          {!scheduledAt && (
            <Dialog.Action onClick={handleClickScheduleQuietTime} variant={DIALOG_VARIANTS.EMPHASIZED}>
              {t('compose.schedule')}
            </Dialog.Action>
          )}
        </Dialog>
      )

    if (isBadWordsDialogVisible)
      return (
        <Dialog
          message={
            <Trans
              i18nKey={hasViolation ? 'compose.violationDialogDescription' : 'compose.badWordsDialogDescription'}
              values={{ badWords }}
            >
              <a
                href="https://www.community.com/legal/aup"
                onClick={() => {
                  analytics.track(
                    analytics.events.ContentWarningPolicyViewed({
                      content: text,
                      page: screens.Compose,
                      triggers: badWords,
                    }),
                  )
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                &nbsp;
              </a>
              <a
                href="https://r.community.com/messaging-guidelines"
                onClick={() => {
                  analytics.track(
                    analytics.events.ContentWarningGuidelinesViewed({
                      content: text,
                      page: screens.Compose,
                      triggers: badWords,
                    }),
                  )
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                &nbsp;
              </a>
            </Trans>
          }
          onCancel={handleCancelBadWordsDialog}
          title={t('compose.badWordsDialogTitle')}
        >
          {!hasViolation && (
            <Dialog.Action onClick={handleClickSendAnywayBadWords}>{t('compose.sendAnyway')}</Dialog.Action>
          )}
          <Dialog.Action onClick={handleCancelBadWordsDialog} variant={DIALOG_VARIANTS.EMPHASIZED}>
            {t('compose.editMessage')}
          </Dialog.Action>
        </Dialog>
      )

    if (isConfirmationDialogVisible)
      return (
        <Dialog
          message={<Trans i18nKey="compose.confirmationDialogDescription" />}
          onCancel={handleCancelConfirmationDialog}
          title={t('compose.confirmationDialogTitle')}
        >
          <Dialog.Action onClick={handleClickIUnderstandSendBadWords}>{t('compose.iUnderstandSend')}</Dialog.Action>
          <Dialog.Action onClick={handleCancelConfirmationDialog} variant={DIALOG_VARIANTS.EMPHASIZED}>
            {t('compose.editMessage')}
          </Dialog.Action>
        </Dialog>
      )

    return null
  },
)
