import { useEffect } from 'react'

import { RefreshToken } from 'api/login'
import { useAuth } from 'hooks/useAuth'
import { useClient } from 'hooks/useClient'
import Sentry from 'integrations/Sentry'
import { formatFullName } from 'utils/formatters'

export function useCapabilities(): RefreshToken['capabilities'] {
  const { capabilities = [] } = useAuth()
  const { data: client } = useClient()

  useEffect(() => {
    if (client || capabilities) {
      Sentry.setContext('client', {
        client_id: client?.id,
        capabilities: capabilities,
      })

      Sentry.setTags({
        'client.id': client?.id,
        'client.name': formatFullName(client),
      })
    } else {
      Sentry.setContext('client', null)
      Sentry.setTags({
        'client.id': null,
        'client.name': null,
      })
    }
  }, [client, capabilities])

  return capabilities
}
