import {
  FilterAst,
  findOne,
  MemberDataFilter,
  Placeholder,
  RelationshipOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { Api } from '@community_dev/types'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { ClusterRepliedState } from '@community_dev/types/lib/api/v2/Clusters'
import { MediaDisposition } from '@community_dev/types/lib/api/v2/Media'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { normalizeReplyTo } from './normalizeReplyTo'
import { WarningTypes, Warning, useCheckMessageContent } from './useCheckMessageContent'

import { Fan } from 'api/fans'
import { postScheduleMessage, postSendMediaMessage, postSendMessage, SuggestionCard } from 'api/message'
import { useTimezones } from 'components/ComposeMessage/components/useTimezones'
import { ScheduledState, useCompose } from 'components/ComposeMessage/ComposeContext'
import { QUERY_CACHE } from 'constants/query-cache'
import { useAiMessageGenerator } from 'contexts/AIMessageGeneratorProvider'
import { useCampaignResponseFilterState } from 'contexts/CampaignResponseFilterProvider'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useIncludeExcludeContext } from 'contexts/IncludeExcludeProvider'
import { ErrorData, useUpload } from 'contexts/UploadProvider'
import analytics from 'utils/analytics'
import media from 'utils/media'
import { parseRecurrenceRule, RecurrenceFrequency, RecurrenceRule } from 'utils/recurrence'

export type CheckTypes = WarningTypes

type SendMessageArgs = {
  bypass?: CheckTypes[]
  communicationChannel: CommunicationChannel
  estimatedRecipientCount: number
  filters: MemberDataFilter | null
  hasDropdownFilter?: boolean
  individuals?: Fan[]
  isRemessage?: boolean
  mediaDisposition?: MediaDisposition
  placeholders: Placeholder[]
  replyTo?: Api.V2.ReplyTo
  scheduledAt?: ScheduledState
  shouldShortenLinks: boolean
  suggestionCard?: SuggestionCard
  text?: string
  threadId?: string
  upload?: File
  testCampaign?: boolean
}

const mediaCache = media.cache

export enum ErrorTypes {
  CONTENT_CHECK_FAILED = 'CONTENT_CHECK_FAILED',
  DUPLICATE_CAMPAIGN = 'DUPLICATE_CAMPAIGN',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  MESSAGES_LIMIT = 'MESSAGES_LIMIT',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
}

export type SendMessageError = {
  type: ErrorTypes
  error?: ErrorData
  monthlyThreshold?: number
  monthlyRemaining?: number
  details?: string
}

type UseSendMessageProps = {
  clientId: string
  remessageUnresponsiveMembers?: boolean
  mediaDisposition: MediaDisposition
}

type UseSendMessageReturn = {
  error: SendMessageError | null
  fileUploadError: ErrorData | null
  fileUploadProgress: number
  hasViolation: boolean
  loading: boolean
  success: boolean
  warning: Warning | null
  sendMessage: (message: SendMessageArgs) => Promise<any>
}

function sendAnalytics({
  aiInterfaceRequestID,
  body,
  campaignId,
  excludedFiltersLogic,
  frequency,
  hasDropdownFilter,
  includedFiltersLogic,
  isRemessage,
  mediaDisposition,
  name,
  parentSmsCampaignId,
  placeholders,
  remessageUnresponsiveMembers,
  suggested,
  suggestionCard,
  suggestMode,
  text,
  analyticsOnSentEvent,
  testCampaign,
}) {
  const analyticsEvent = {
    filters: body.filters,
    hasDropdownFilter,
    campaignId,
    mediaDisposition,
    parentSmsCampaignId,
    placeholders,
    remessage: isRemessage,
    remessageUnresponsiveMembers,
    suggestionCardType: suggestionCard?.type,
    suggestionCardTitle: suggestionCard?.title,
    suggestionCardUsed: Boolean(suggestionCard),
    includeTags: body.fan_subscription_tags?.any,
    excludeTags: body.fan_subscription_tags?.not,
    giphySent: Boolean(body?.text?.includes?.('giphy.com')),
    aiInterfaceRequestID,
    suggested,
    suggestMode,
    frequency,
    includedFiltersLogic,
    excludedFiltersLogic,
    testCampaign,
  }

  analytics.track(analytics.events.BlastSent(analyticsEvent))

  if (name) {
    analytics.track(analytics.events.CreateCampaignName())
  }

  if (suggested) {
    analytics.track(
      analytics.events.GPT.CampaignSentFromSuggestedCampaign({
        campaignID: campaignId,
        suggested: suggested,
        suggestMode: suggestMode,
        text,
      }),
    )
  }

  if (frequency) {
    const recurrenceParams = parseRecurrenceRule(frequency as RecurrenceRule)

    if (recurrenceParams?.frequency) {
      analytics.track(
        analytics.events.RecurringBirthdayCampaignScheduled({
          frequency: recurrenceParams?.frequency === RecurrenceFrequency.WEEKLY ? 'weekly' : 'daily',
        }),
      )
    }
  }

  if (analyticsOnSentEvent) {
    analytics.track(analyticsOnSentEvent)
  }
}

function getFiltersLogic(filters: FilterAst | null): 'AND' | 'OR' | 'Both AND and OR' | null {
  if (!filters) return null

  const hasAnd = findOne(filters, { operator: RelationshipOperators.AND })
  const hasOr = findOne(filters, { operator: RelationshipOperators.OR })

  if (hasAnd && hasOr) return 'Both AND and OR'
  if (hasAnd) return 'AND'
  if (hasOr) return 'OR'

  return null
}

export const useSendMessage = ({
  clientId,
  remessageUnresponsiveMembers = false,
  mediaDisposition,
}: UseSendMessageProps): UseSendMessageReturn => {
  const { t } = useTranslation()
  const {
    requestId: aiInterfaceRequestId,
    analyticsEventSuggested: aiInterfaceSuggested,
    analyticsEventSuggestMode: aiInterfaceSuggestMode,
  } = useAiMessageGenerator()
  const { id: campaignId }: any = useParams()
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<SendMessageError | null>(null)
  const { uploadFile, progress: fileUploadProgress, error: fileUploadError } = useUpload()
  const { tagFilters }: any = useCampaignResponseFilterState()
  const { hasViolation, warning, checkMessage } = useCheckMessageContent({
    clientId,
    mediaDisposition,
  })
  const {
    includeExcludeState: { mode, includes, excludes, typeMap },
  } = useIncludeExcludeContext()
  const { localTimezone } = useTimezones()
  const { frequency, name, setFile, analyticsOnSentEvent } = useCompose()
  const { includedFiltersAst, excludedFiltersAst } = useFilters()

  useEffect(() => {
    if (fileUploadError) {
      setLoading(false)
      setError({
        type: ErrorTypes.UPLOAD_FAILED,
        error: fileUploadError,
      })
      setFile(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadError])

  const handleError = useCallback((err: any, variable: Api.V2.SendMessageBody): void => {
    if (err.status === 429) {
      const monthlyThreshold = err?.error?.data?.campaign_messages_monthly_threshold
      const monthlyRemaining = err?.error?.data?.campaign_messages_monthly_remaining

      setError({
        type: ErrorTypes.MESSAGES_LIMIT,
        monthlyThreshold,
        monthlyRemaining,
        details: err?.message,
      })
      return
    }

    if (err.status === 422) {
      setError({
        type: ErrorTypes.DUPLICATE_CAMPAIGN,
        details: variable.test_campaign ? t('compose.duplicateTestCampaignError') : t('compose.duplicateCampaignError'),
      })
      return
    }

    setError({
      type: ErrorTypes.INTERNAL_ERROR,
      details: err?.message,
    })
  }, [])

  const queryKey = [QUERY_CACHE.CLUSTER.LIST, { clientId, campaignId, tagFilters }]
  const { mutate: mutateSendMessage } = useMutation(postSendMessage, {
    onMutate: async () => {
      setSuccess(false)
      setError(null)

      // NOTE: we will perform an optimistic update for clusters
      // https://react-query.tanstack.com/guides/optimistic-updates
      await queryClient.cancelQueries(queryKey)

      const previousClusters = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old: any) => {
        if (mode === 'excludeAll') {
          const includedIds = [...Array.from(includes.keys())]
          const selectedClusterIds = includedIds.filter((id) => typeMap.get(id) === 'cluster')
          const selectedFanSubscriptionsClustersIds = includedIds
            .filter((id) => typeMap.get(id) === 'fanSubscription')
            .map((fanSubscriptionId) => includes.get(fanSubscriptionId)?.[0])

          return {
            data: old?.data?.map((cluster: any) => {
              if (selectedClusterIds.includes(cluster.id)) {
                return {
                  ...cluster,
                  replied_info: ClusterRepliedState.REPLIED,
                }
              }

              if (selectedFanSubscriptionsClustersIds.includes(cluster.id)) {
                return {
                  ...cluster,
                  replied_info: ClusterRepliedState.PARTIALLY_REPLIED,
                }
              }

              return cluster
            }),
          }
        }

        if (mode === 'includeAll') {
          const excludedIds = [...Array.from(excludes.keys())]
          const deselectedClusterIds = excludedIds.filter((id) => typeMap.get(id) === 'cluster')
          const deselectedFanSubscriptionsClusterIds = excludedIds
            .filter((id) => typeMap.get(id) === 'fanSubscription')
            .map((fanSubscriptionId) => excludes.get(fanSubscriptionId)?.[0])

          return {
            data: old?.data?.map((cluster: any) => {
              if (deselectedClusterIds.includes(cluster.id)) {
                return cluster
              }

              if (deselectedFanSubscriptionsClusterIds.includes(cluster.id)) {
                return {
                  ...cluster,
                  replied_info: ClusterRepliedState.PARTIALLY_REPLIED,
                }
              }

              return {
                ...cluster,
                replied_info: ClusterRepliedState.REPLIED,
              }
            }),
          }
        }
      })

      return { previousClusters }
    },
    onError(err: any, variables, context: any) {
      setLoading(false)

      queryClient.setQueryData(queryKey, context.previousClusters)

      handleError(err, variables.body)
    },
    onSuccess(response, { suggestionCard, body, isRemessage, hasDropdownFilter }) {
      setLoading(false)
      setSuccess(true)
      sendAnalytics({
        body,
        hasDropdownFilter,
        isRemessage,
        mediaDisposition,
        name,
        campaignId: response?.data?.id,
        parentSmsCampaignId: body?.reply_to?.parent_sms_campaign_id,
        text: body?.text,
        placeholders: body?.placeholders || [],
        remessageUnresponsiveMembers,
        suggestionCard,
        aiInterfaceRequestID: aiInterfaceRequestId,
        suggested: aiInterfaceSuggested,
        suggestMode: aiInterfaceSuggestMode,
        frequency,
        includedFiltersLogic: getFiltersLogic(includedFiltersAst),
        excludedFiltersLogic: getFiltersLogic(excludedFiltersAst),
        analyticsOnSentEvent,
        testCampaign: body.test_campaign,
      })
    },
    onSettled() {
      queryClient.invalidateQueries(queryKey)
    },
  })

  const { mutate: mutateScheduleMessage } = useMutation(postScheduleMessage, {
    onMutate() {
      setSuccess(false)
      setError(null)
    },
    onError(err: any, variables) {
      setLoading(false)
      handleError(err, variables.body)
    },
    onSuccess(response, { suggestionCard, body, isRemessage, hasDropdownFilter }) {
      setLoading(false)
      setSuccess(true)
      sendAnalytics({
        body,
        hasDropdownFilter,
        isRemessage,
        mediaDisposition,
        name,
        campaignId: response?.data?.id,
        parentSmsCampaignId: body?.reply_to?.parent_sms_campaign_id,
        text: body?.text,
        placeholders: body?.placeholders || [],
        remessageUnresponsiveMembers,
        suggestionCard,
        aiInterfaceRequestID: aiInterfaceRequestId,
        suggested: aiInterfaceSuggested,
        suggestMode: aiInterfaceSuggestMode,
        frequency,
        includedFiltersLogic: getFiltersLogic(includedFiltersAst),
        excludedFiltersLogic: getFiltersLogic(excludedFiltersAst),
        analyticsOnSentEvent,
        testCampaign: body.test_campaign,
      })
    },
    onSettled() {
      queryClient.invalidateQueries([QUERY_CACHE.SCHEDULED.LIST, { clientId }])
    },
  })

  const { mutateAsync: mutateSendMediaMessage } = useMutation(postSendMediaMessage)

  const sendMessage = useCallback(
    async ({
      bypass = [],
      communicationChannel,
      estimatedRecipientCount,
      filters,
      hasDropdownFilter,
      individuals,
      isRemessage,
      replyTo,
      scheduledAt,
      shouldShortenLinks,
      suggestionCard,
      placeholders,
      text,
      threadId,
      upload,
      testCampaign,
    }: SendMessageArgs) => {
      const media = upload?.name ? upload : null
      const hasMedia = !!media?.type

      setLoading(true)

      const { error, valid } = await checkMessage(
        { text: text as string, filters: filters as MemberDataFilter, scheduledAt, hasMedia },
        // checks can be overridden
        { bypass },
      )

      if (error) {
        setError({ type: ErrorTypes.CONTENT_CHECK_FAILED })
      }

      if (!valid) {
        setLoading(false)

        return
      }

      const normalizedReplyTo = !isEmpty(replyTo) ? replyTo : normalizeReplyTo(threadId as string, individuals)
      const recurrence = frequency
        ? {
            local_time_zone: localTimezone.representativeTzid,
            frequency,
          }
        : undefined

      if (hasMedia) {
        let mediaData
        let extension

        setSuccess(false)

        if (media.type.includes('image')) {
          mediaData = await uploadFile({ file: upload })
        } else {
          extension = media.name.substring(media.name.lastIndexOf('.') + 1).toLowerCase()

          try {
            const { data } = await mutateSendMediaMessage({
              clientId,
              hasDropdownFilter,
              body: {
                communication_channel: communicationChannel,
                estimated_recipient_count: estimatedRecipientCount,
                filters: {
                  subscription_data: filters as MemberDataFilter,
                },
                media: mediaData,
                media_disposition: mediaDisposition,
                media_file_extension: extension,
                message_body: text,
                name,
                placeholders,
                recurrence,
                reply_to: normalizedReplyTo,
                scheduled_at: scheduledAt?.toISOString(),
                shorten_links: shouldShortenLinks,
                test_campaign: testCampaign,
              },
            })

            const { messageId, uploadUrl: url } = data

            // non-image media gets uploaded after the message is sent
            if (url) {
              mediaCache.setItem(messageId, media)
              await uploadFile({ url, file: media })
            }

            setSuccess(true)
          } catch (e: any) {
            setError({
              type: ErrorTypes.UPLOAD_FAILED,
              details: e.details,
            })
          } finally {
            setLoading(false)
          }

          return
        }

        // schedule media message
        if (scheduledAt) {
          return mutateScheduleMessage({
            clientId,
            body: {
              communication_channel: communicationChannel,
              estimated_recipient_count: estimatedRecipientCount,
              filters: {
                subscription_data: filters as MemberDataFilter,
              },
              media: mediaData.data,
              media_disposition: mediaDisposition,
              name,
              placeholders,
              recurrence,
              reply_to: normalizedReplyTo,
              scheduled_at: scheduledAt.toISOString(),
              shorten_links: shouldShortenLinks,
              text,
            },
            hasDropdownFilter,
            isRemessage,
            suggestionCard,
          })
        }

        // sending media message
        return mutateSendMessage({
          clientId,
          body: {
            communication_channel: communicationChannel,
            estimated_recipient_count: estimatedRecipientCount,
            filters: {
              subscription_data: filters as MemberDataFilter,
            },
            media: mediaData.data,
            media_disposition: mediaDisposition,
            name,
            placeholders,
            reply_to: normalizedReplyTo,
            shorten_links: shouldShortenLinks,
            text,
            test_campaign: testCampaign,
          },
          hasDropdownFilter,
          isRemessage,
          suggestionCard,
        })
      }

      // schedule text-only message
      if (scheduledAt) {
        return mutateScheduleMessage({
          clientId,
          body: {
            communication_channel: communicationChannel,
            estimated_recipient_count: estimatedRecipientCount,
            filters: {
              subscription_data: filters as MemberDataFilter,
            },
            name,
            placeholders,
            recurrence,
            reply_to: normalizedReplyTo,
            scheduled_at: scheduledAt.toISOString(),
            shorten_links: shouldShortenLinks,
            text,
          },
          hasDropdownFilter,
          isRemessage,
          suggestionCard,
        })
      }

      // sending text-only message
      return mutateSendMessage({
        clientId,
        body: {
          communication_channel: communicationChannel,
          estimated_recipient_count: estimatedRecipientCount,
          filters: {
            subscription_data: filters as MemberDataFilter,
          },
          name,
          placeholders,
          reply_to: normalizedReplyTo,
          shorten_links: shouldShortenLinks,
          test_campaign: testCampaign,
          text,
        },
        hasDropdownFilter,
        isRemessage,
        suggestionCard,
      })
    },
    [
      checkMessage,
      clientId,
      frequency,
      localTimezone.representativeTzid,
      mediaDisposition,
      mutateScheduleMessage,
      mutateSendMediaMessage,
      mutateSendMessage,
      name,
      t,
      uploadFile,
    ],
  )

  return {
    error,
    fileUploadError,
    fileUploadProgress,
    hasViolation,
    loading,
    success,
    warning,
    sendMessage,
  }
}
