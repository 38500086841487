import {
  BUTTON_VARIANTS,
  Box,
  Button,
  SPACING,
  TextInput,
  Toast,
  composeValidators,
  email,
  required,
  Typography,
} from '@community_dev/pixels'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useForgotPassword } from './use-forgot-password'

import { ROUTES } from 'constants/routes'

const StyledForm = styled.form`
  text-align: left;
  margin-top: ${SPACING[5]};

  ${Button} {
    margin-top: ${SPACING[4]};
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
const StyledSubmitButton = styled(Button)`
  width: 100%;
  margin-left: ${SPACING[5]};
  margin-right: ${SPACING[5]};
`

const PasswordForgotForm = (): JSX.Element => {
  const { success, error, submit, isLoading, passwordFieldRef } = useForgotPassword()

  const onSubmit = ({ email }, { restart }) => {
    submit({ email, restart })
  }

  return (
    <div className="PasswordForgot">
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, valid, pristine }) => (
          <Box>
            {error && (
              <Box.ToastArea>
                <Toast icon message={error} success={false} />
              </Box.ToastArea>
            )}
            {success && (
              <Box.ToastArea>
                <Toast icon message={success} success={true} />
              </Box.ToastArea>
            )}
            <Box.Content>
              <Typography variant="h1">Forgot your password?</Typography>
              <StyledForm onSubmit={handleSubmit}>
                <Field name="email" validate={composeValidators(required, email)} validateOnBlur>
                  {({ input, meta }) => (
                    <TextInput
                      autoComplete="email"
                      error={(!meta.active && meta.touched && meta.modified && meta.error) || ''}
                      label="Email"
                      ref={passwordFieldRef}
                      type="email"
                      {...input}
                    />
                  )}
                </Field>
                <StyledButtonContainer>
                  <StyledSubmitButton
                    disabled={!valid || isLoading || pristine}
                    type="submit"
                    variant={BUTTON_VARIANTS.ACTION}
                  >
                    {isLoading ? 'Loading' : 'Recover Password'}
                  </StyledSubmitButton>
                </StyledButtonContainer>
              </StyledForm>
            </Box.Content>
            <Box.Footer>
              <Button forwardedAs={Link} to={ROUTES.LOGIN} variant={BUTTON_VARIANTS.LINK}>
                Back to Login
              </Button>
            </Box.Footer>
          </Box>
        )}
      />
    </div>
  )
}

export default PasswordForgotForm
