import { InfoIcon, LockIcon, SPACING, Tooltip } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UseQueryResult } from '@tanstack/react-query'
import isFunction from 'lodash/isFunction'
import React from 'react'
import { Code } from 'react-content-loader'
import styled, { css } from 'styled-components'

import useCurrentTheme from 'hooks/useCurrentTheme'
import Sentry from 'integrations/Sentry'

type BoxProps = {
  $height: string
}

const Box = styled.div<BoxProps>`
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border-radius: 12px;
  overflow: hidden;
  padding: 16px;
  height: ${({ $height }) => $height};
  position: relative;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 ${SPACING[2]} 0;
`

// this appears to be a one-off "signpost" text style in the figma file
export const SignpostText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
`

const padding = '32px'

const StyledContent = styled.div<{ $locked: boolean }>`
  height: calc(100% - ${padding});
  ${({ $locked }) =>
    $locked &&
    css`
      filter: blur(6px);
      @supports (backdrop-filter: none) {
        filter: none;
      }
    `}
`
const ErrorText = styled.div`
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 20px - 32px);
`

const StyledBlur = styled.div<{ $dark: boolean; $headerHeight: string }>`
  position: absolute;
  ${({ $headerHeight }) => css`
    top: calc(${$headerHeight} + ${padding});
  `}
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(4px);
  background: ${({ $dark }) => ($dark ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.8)')};
`

type InsightsBoxProps = {
  children: React.ReactNode
  tabs?: React.ReactNode
  title: React.ReactNode
  headerHeight?: string
  height?: string
  infoText?: string
  locked?: boolean
  [x: string]: any
}

// Presentational component - no logic, very reusable
export const InsightsBox = ({
  title,
  children,
  tabs,
  locked,
  noBlur,
  infoText,
  height = '332px',
  headerHeight = '20px',
  ...props
}: InsightsBoxProps): JSX.Element => {
  const currentTheme = useCurrentTheme()

  return (
    <Box $height={height} {...props}>
      <Header>
        <SignpostText>
          {title}
          {Boolean(locked) && (
            <>
              {' '}
              <LockIcon />
            </>
          )}
          {!locked && infoText !== undefined && (
            <>
              {' '}
              <Tooltip content={infoText}>
                <span>
                  <InfoIcon size={20} />
                </span>
              </Tooltip>
            </>
          )}
        </SignpostText>
        {tabs}
      </Header>
      <StyledContent $locked={Boolean(locked)}>{children}</StyledContent>
      {!noBlur && Boolean(locked) && (
        <StyledBlur $dark={currentTheme?.type === 'dark'} $headerHeight={headerHeight} data-testid="chart-blur" />
      )}
    </Box>
  )
}

export const ErrorMessage = ({
  msg,
  className,
}: {
  msg: string | React.ReactNode
  className?: string
}): JSX.Element => (
  <ErrorText className={className}>
    <div style={{ textAlign: 'center' }}>
      <FontAwesomeIcon icon="exclamation-circle" style={{ marginBottom: '4px', height: '20px', width: '20px' }} />
      <br />
      {msg}
    </div>
  </ErrorText>
)

type InsightsBoxManagedProps = {
  title: React.ReactNode
  children: React.ReactNode | (() => React.ReactNode)
  tabs?: React.ReactNode
  queries: UseQueryResult[]
  isEmpty?: boolean
  loadingView?: React.ReactNode
  errorView?: React.ReactNode
  emptyView?: React.ReactNode
  locked?: boolean
  isLoading?: boolean
  [x: string]: any
}

class InsightsBoxManaged extends React.PureComponent<InsightsBoxManagedProps> {
  state = {
    renderError: null,
  }

  static getDerivedStateFromError(renderError) {
    // Update state so the next render will show the fallback UI.
    return { renderError }
  }

  componentDidCatch(renderError) {
    Sentry.captureException(renderError)
  }

  static defaultProps = {
    loadingView: <Code aria-label="loading" />,
    errorView: <ErrorMessage msg="Failed to load data" />,
    emptyView: <ErrorText>No data yet</ErrorText>,
    queries: [],
  }

  render() {
    const { title, children, tabs, queries, isEmpty, loadingView, errorView, emptyView, isLoading, ...props } =
      this.props
    const { renderError } = this.state

    if (renderError) {
      return (
        <InsightsBox title={title} {...props}>
          <ErrorMessage
            msg={
              <>
                Application Error
                <br /> please reload the page and try again
              </>
            }
          />
        </InsightsBox>
      )
    }

    if (queries.some((q) => q.isLoading) || isLoading) {
      return (
        <InsightsBox title={title} {...props}>
          {loadingView}
        </InsightsBox>
      )
    }

    if (isEmpty) {
      return (
        <InsightsBox tabs={tabs} title={title} {...props}>
          {emptyView}
        </InsightsBox>
      )
    }

    if (queries.some((q) => q.isError)) {
      return (
        <InsightsBox title={title} {...props}>
          {errorView}
        </InsightsBox>
      )
    }

    return (
      <InsightsBox tabs={tabs} title={title} {...props}>
        {isFunction(children) ? children() : children}
      </InsightsBox>
    )
  }
}
InsightsBox.Managed = InsightsBoxManaged

const Tabs = styled.div`
  margin: 0 -8px;
`

type TabProps = {
  $selected?: boolean
  $noop?: boolean
  theme?: string
}

const Tab = styled.button<TabProps>`
  all: unset;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.08px;
  // using padding instead of margin so that each tab has a much larger
  // click target
  padding: 0 8px;
  color: ${({ $selected, theme }) => ($selected ? theme?.COLORS?.TEXT : theme?.COLORS?.SUBTEXT)};
  ${({ $noop }) =>
    !$noop &&
    css`
      cursor: pointer;
      &:hover {
        color: ${({ $selected }: TabProps) => ($selected ? '#000' : '#aaa')};
      }
      transition: color 100ms ease;
    `}
`

InsightsBox.Tabs = ({
  tabs,
  selected,
  onChange,
  noop = false,
}: {
  tabs: Record<string, string> | string[]
  selected?: string
  onChange: (k: string) => any
  noop?: boolean
}) => (
  <Tabs>
    {Object.keys(tabs).map((key) => (
      <Tab $noop={noop} $selected={selected === key} key={key} onClick={() => onChange(key)}>
        {tabs[key]}
      </Tab>
    ))}
  </Tabs>
)
