import { SPACING } from '@community_dev/pixels'
import { useMutation } from '@tanstack/react-query'
import { History, Location } from 'history'
import { useMemo } from 'react'
import styled from 'styled-components'

import { putSeatPassword } from 'api/seat'
import { PasswordForm } from 'components/form/PasswordForm'
import { EditContainer } from 'containers/Settings'
import { useToastMessage } from 'hooks/useToastMessage'
import notifs from 'utils/notifs'

const StyledPasswordForm = styled(PasswordForm)`
  > div:first-child {
    margin-top: ${SPACING[5]};
  }
`

type EditSecurityProps = {
  error: any
  loading: boolean
  location: Location
  history: History
}

export function EditSecurity(props: EditSecurityProps): JSX.Element {
  const { showToastMessage } = useToastMessage()
  const { mutateAsync: onSubmit } = useMutation({
    async mutationFn({ password }: { password: string }) {
      await putSeatPassword(password)
    },
    onError() {
      showToastMessage({
        message: 'There was problem. Password was not changed.',
        success: false,
      })
    },
    onSuccess() {
      showToastMessage({
        message: 'Password changed successfully.',
      })
    },
  })
  const notification = useMemo(() => {
    if (props.error) {
      return notifs.passwordUnchanged
    } else {
      return {}
    }
  }, [props.error])
  return (
    <EditContainer title="Change Password">
      <StyledPasswordForm
        buttonText="Save Password"
        labelText="New Password"
        notif={notification}
        onSubmit={onSubmit}
        titleText="Enter New Password"
      />
    </EditContainer>
  )
}
