import { route } from '@community_dev/requests'
import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/api'

export type KeywordResponders = CamelCasedPropertiesDeep<Api.KeywordResponder.KeywordResponders>

export function getKeywordResponders(): Promise<KeywordResponders> {
  return request(route(ENDPOINTS.SETTINGS.KEYWORD_MESSAGES))
}

export type AddKeywordResponderBody = Api.KeywordResponder.PostKeywordBody

export type AddKeywordResponderResponse = CamelCasedPropertiesDeep<Api.KeywordResponder.PostKeywordResponse>

export function addKeywordResponder({ keyword, text }: AddKeywordResponderBody): Promise<AddKeywordResponderResponse> {
  return request(route(ENDPOINTS.SETTINGS.KEYWORD_MESSAGES), { method: HTTP.POST, body: { keyword, text } })
}

export type UpdateKeywordResponderParams = Api.KeywordResponder.PutKeywordBody & {
  id: string
}

export type UpdateKeywordResponderResponse = CamelCasedPropertiesDeep<Api.KeywordResponder.PutKeywordResponse>

export function updateKeywordResponder({
  keyword,
  text,
  id,
}: UpdateKeywordResponderParams): Promise<UpdateKeywordResponderResponse> {
  return request(route(ENDPOINTS.SETTINGS.KEYWORD_MESSAGES_UPDATE, { keywordId: id }), {
    method: HTTP.PUT,
    body: { keyword, text },
  })
}

export type DeleteKeywordResponderParams = {
  id: string
}

export type DeleteKeywordResponderResponse = CamelCasedPropertiesDeep<Api.KeywordResponder.DeleteKeywordResponse>

export function deleteKeywordResponder({ id }: DeleteKeywordResponderParams): Promise<DeleteKeywordResponderResponse> {
  return request(route(ENDPOINTS.SETTINGS.KEYWORD_MESSAGES_UPDATE, { keywordId: id }), {
    method: HTTP.DELETE,
  })
}
