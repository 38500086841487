import { FONT_SIZE, SPACING } from '@community_dev/pixels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import noop from 'lodash/noop'
import React, { ChangeEvent, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { PasswordInput } from 'components/input'
import validatePassword from 'utils/validatePassword'

const StyledRequirementContainer = styled.div`
  margin: ${SPACING[2]} 0;
  width: 100%;
  max-width: 350px;
`

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const StyledPasswordRequirement = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION2};
  font-size: ${FONT_SIZE[2]};
  width: 100%;
  max-width: 150px;
  margin-bottom: ${SPACING[2]};
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: ${SPACING[1]};
`

type PasswordVerificationProps = {
  id?: string
  name?: string
  error?: string
  labelText?: string
  hasHints?: boolean
  disabled?: boolean
  onChange: (...args: any[]) => any
}

export default function PasswordVerification({
  id,
  name,
  error,
  labelText = '',
  hasHints = true,
  disabled = false,
  onChange = noop,
}: PasswordVerificationProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const [password, setPassword] = useState('')
  const [validations, setValidations] = useState({
    length: false,
    space: false,
    letter: false,
    number: false,
    symbol: false,
    valid: false,
  })

  function handleChange(e: ChangeEvent<HTMLInputElement>): void {
    const password = e.currentTarget.value
    const validations = validatePassword(password)
    setValidations(validations)
    setPassword(password)
    onChange({ password, validations })
  }

  const { length, letter, number, symbol } = validations

  return (
    <>
      <PasswordInput
        disabled={disabled}
        error={error}
        id={id}
        label={labelText}
        name={name}
        onChange={handleChange}
        value={password}
      />

      {hasHints && (
        <StyledRequirementContainer>
          <StyledRow>
            <StyledPasswordRequirement>
              <StyledIcon color={length ? COLORS?.SUCCESS : COLORS?.BORDERS} icon="check-circle" />
              <span>8+ characters</span>
            </StyledPasswordRequirement>
            <StyledPasswordRequirement>
              <StyledIcon color={number ? COLORS?.SUCCESS : COLORS?.BORDERS} icon="check-circle" />
              <span>Number</span>
            </StyledPasswordRequirement>
          </StyledRow>
          <StyledRow>
            <StyledPasswordRequirement>
              <StyledIcon color={letter ? COLORS?.SUCCESS : COLORS?.BORDERS} icon="check-circle" />
              <span>Letter</span>
            </StyledPasswordRequirement>
            <StyledPasswordRequirement>
              <StyledIcon color={symbol ? COLORS?.SUCCESS : COLORS?.BORDERS} icon="check-circle" />
              <span>Symbol (!, @, #, $)</span>
            </StyledPasswordRequirement>
          </StyledRow>
        </StyledRequirementContainer>
      )}
    </>
  )
}
