import { SPACING } from '@community_dev/pixels'
import noop from 'lodash/noop'
import React from 'react'
import styled, { css } from 'styled-components'

enum Types {
  DEFAULT = 'default',
  REMOVE = 'remove',
  ADD = 'add',
}

const StyledLabel = styled.span`
  display: inline;
`

const StyledRemove = styled.span`
  display: none;
`

type StyledAddRemoveProps = {
  $type: string
}

export const StyledAddRemove = styled.button<StyledAddRemoveProps>`
  border-radius: 12px;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
  font-weight: 600;
  user-select: none;
  background-color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  border: ${({ theme }) => `1px solid ${theme?.COLORS?.BORDERS}`};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  padding: 0 ${SPACING[2]};
  min-width: 72px;
  min-height: 24px;

  ${({ $type }) => {
    switch ($type) {
      case Types.REMOVE:
        return css`
          cursor: pointer;

          &:hover {
            background-color: ${({ theme }) => theme?.COLORS?.ERRORS};
            border-color: transparent;
            color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};

            ${StyledLabel} {
              display: none;
            }

            ${StyledRemove} {
              display: inline;
            }
          }
        `
      case Types.ADD:
        return css`
          cursor: pointer;
          background-color: ${({ theme }) => theme?.COLORS?.LINKS};
          border: ${({ theme }) => `1px solid ${theme?.COLORS?.LINKS}`};
          color: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
        `
      default:
        return css``
    }
  }}
`

type AddedButtonProps = {
  id?: string
  added?: boolean
  label?: string
  onAdd?: (...args: any[]) => any
  onRemove?: (...args: any[]) => any
}

function AddedButton({ added, label, onRemove, onAdd }: AddedButtonProps): JSX.Element {
  let type = Types.DEFAULT
  let displayLabel = label
  if (added && onRemove) {
    type = Types.REMOVE
  } else if (onAdd && !added) {
    type = Types.ADD
    displayLabel = 'Add'
  }
  return (
    <StyledAddRemove
      $type={type}
      data-testid="added-button"
      onClickCapture={(evt) => {
        const method = (added ? onRemove : onAdd) || noop
        method(evt)
      }}
    >
      <StyledLabel>{displayLabel}</StyledLabel>
      {added && onRemove && <StyledRemove>Remove</StyledRemove>}
    </StyledAddRemove>
  )
}

AddedButton.defaultProps = {
  added: false,
  label: 'Member',
  removeDefault: false,
}

export default AddedButton
