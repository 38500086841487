import {
  FieldSources,
  BuiltInFields,
  SelectorOperators,
  fieldLabelFor,
  fieldTypeFor,
  SUBSCRIPTION_DATA_FILTER_DATE_FORMAT,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { Dayjs } from 'dayjs'
import { CLEAR_EDITOR_COMMAND } from 'lexical'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react'

import { useTimezones } from './components/useTimezones'
import { ActiveFilters, useActiveFilterTypes } from './hooks/useActiveFilterTypes'
import { ComposeRole, useComposeRole } from './hooks/useComposeRole'

import { Communities } from 'api/community'
import { Giphy } from 'api/giphy'
import { REPLACE_TEXT_COMMAND } from 'components/ComposeEditor/hooks/useReplaceTextPlugin'
import { DATE_FILTER_FORMAT } from 'constants/date'
import { useFilters } from 'contexts/FilterProvider/FilterProvider'
import { useClient, useClientId } from 'hooks/useClient'
import { useCommunities } from 'hooks/useCommunities'
import { useCountByQuery } from 'hooks/useCountByQuery'
import { useCustomMemberDataFields } from 'hooks/useCustomDataFields'
import { useMemberDataPermissions } from 'hooks/useMemberDataPermissions'
import analytics from 'utils/analytics'
import { AnalyticsEvent } from 'utils/analytics/events'
import dayjs from 'utils/dayjs'
import { hasDropdownFilter as hasDropdown } from 'utils/memberDataFilters/memberDataFiltersUtils'
import { RecurrenceRule } from 'utils/recurrence'

export type ScheduledState = Dayjs | null

export type ComposeContextValue = {
  activeFilterTypes: ActiveFilters
  communities?: Communities
  file?: File
  frequency?: RecurrenceRule
  gif?: Giphy
  handleScheduledAtChange: (datetime: Dayjs) => void
  hasDropdownFilter?: boolean
  hasMediaLink: boolean
  hasPlaceholders: boolean
  isCountLoading: boolean
  isLoading: boolean
  isMediaAttached: boolean
  recipientCount: number
  scheduledAt: ScheduledState
  maxConsecutiveLineBreaks?: number
  name?: string
  setFile(file: File | undefined): void
  setFrequency: Dispatch<SetStateAction<RecurrenceRule | undefined>>
  setGif: (gif: Giphy | undefined) => void
  setHasPlaceholders: Dispatch<SetStateAction<boolean>>
  setName: Dispatch<SetStateAction<string | undefined>>
  setScheduledAt: Dispatch<SetStateAction<ScheduledState>>
  setText: (text: string) => void
  setAnalyticsOnSentEvent: Dispatch<SetStateAction<AnalyticsEvent | undefined>>
  analyticsOnSentEvent?: AnalyticsEvent
  shouldShowScheduled: boolean
  text: string
  toggleScheduled: () => void
  setComposeMessage(text: string): void
  composeModalOpen: boolean
  shouldShortenLinks: boolean
  setShouldShortenLinks: Dispatch<SetStateAction<boolean>>
  setComposeModalOpen: Dispatch<SetStateAction<boolean>>
  reset(): void
}

export const ComposeContext = createContext<ComposeContextValue | null>(null)

ComposeContext.displayName = 'ComposeContext'

type ComposeProviderProps = {
  children?: ReactNode
}

export function ComposeProvider({ children }: ComposeProviderProps): JSX.Element {
  const {
    filters,
    updateFilter,
    setIncludedFilters,
    setExcludedFilters,
    setActiveSubtree,
    includedFiltersAst,
    communicationChannel,
    setCommunicationChannel,
  } = useFilters()
  const [editor] = useLexicalComposerContext()
  const [composeModalOpen, setComposeModalOpen] = useState(false)
  const [text, setTextState] = useState('')
  const [file, setFile] = useState<File | undefined>()
  const [gif, setGif] = useState<Giphy | undefined>()
  const [frequency, setFrequency] = useState<RecurrenceRule | undefined>()
  const [name, setName] = useState<string | undefined>()
  const { data: client } = useClient()
  const clientId = useClientId()
  const [shouldShortenLinks, setShouldShortenLinks] = useState(true)
  const [analyticsOnSentEvent, setAnalyticsOnSentEvent] = useState<AnalyticsEvent | undefined>()

  const { isInitialLoading: isCountLoading, data: { count: recipientCount = 0 } = {} } = useCountByQuery({
    communicationChannel,
    filters,
    traceId: 'recipient-count',
  })

  const { communityLocalTimezone, localTimezone, isLoading: timezoneIsLoading } = useTimezones()

  const [scheduledAt, setScheduledAt] = useState<ScheduledState>(null)
  const [hasPlaceholders, setHasPlaceholders] = useState(false)
  const { isLoading, data: communities } = useCommunities()
  const { canFilter: canFilterMemberData } = useMemberDataPermissions()
  const maxConsecutiveLineBreaks = useMemo(
    () => (communicationChannel === CommunicationChannel.WHATS_APP ? 2 : undefined),
    [communicationChannel],
  )
  const hasMediaLink = useMemo(() => Boolean(gif?.original), [gif?.original])
  const isMediaAttached = useMemo(() => Boolean(file), [file])
  const { data: fields } = useCustomMemberDataFields({
    options: { enabled: Boolean(canFilterMemberData && !!clientId) },
    clientId,
  })

  useEffect(() => {
    if (!text) {
      analytics.track(analytics.events.ComposeMessageTextCleared())
    }
  }, [text])

  // if SMS communication channel was omitted due to TCR registration, we default to WhatsApp if it exists
  useEffect(() => {
    if (
      !client?.communicationChannels?.includes(CommunicationChannel.SMS) &&
      client?.communicationChannels?.includes(CommunicationChannel.WHATS_APP)
    ) {
      setCommunicationChannel(CommunicationChannel.WHATS_APP)
    }
  }, [client?.communicationChannels, setCommunicationChannel])

  const hasDropdownFilter = useMemo(() => {
    if (!fields || !fields.length || !filters) {
      return
    }

    return hasDropdown(filters, fields)
  }, [fields, filters])

  const collapseConsecutiveLineBreaks = useCallback(
    (text: string): string => {
      if (maxConsecutiveLineBreaks !== undefined) {
        return text.replaceAll('\n'.repeat(maxConsecutiveLineBreaks + 1), '\n'.repeat(maxConsecutiveLineBreaks))
      } else {
        return text
      }
    },
    [maxConsecutiveLineBreaks],
  )

  const setText = useCallback(
    (text: string | null = '') => {
      const cleanText = collapseConsecutiveLineBreaks(text ?? '')
      return setTextState(cleanText)
    },
    [collapseConsecutiveLineBreaks],
  )

  const setComposeMessage = useCallback(
    (text = '') => {
      // null values are not covered by default arguments
      // bad things happen if compose text is set to null
      const _text = text ?? ''

      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
      editor.dispatchCommand(REPLACE_TEXT_COMMAND, { text: _text })

      setText(_text)
      setFile(undefined)
      setGif(undefined)
    },
    [editor, setText, setFile, setGif],
  )

  const reset = useCallback(() => {
    setComposeMessage('')
    setScheduledAt(null)
    setHasPlaceholders(false)
    setIncludedFilters(null)
    setExcludedFilters(null)
    setActiveSubtree(null)
    setName(undefined)
    setAnalyticsOnSentEvent(undefined)
  }, [setComposeMessage, setScheduledAt, setHasPlaceholders, setIncludedFilters, setExcludedFilters, setActiveSubtree])

  const handleSetGif = useCallback((gif: Giphy | undefined) => {
    setGif(gif)
    setFile(undefined)
  }, [])

  function handleSetFile(file: File | undefined) {
    setGif(undefined)
    setFile(file)
  }

  const activeFilterTypes = useActiveFilterTypes({
    filters,
  })

  const handleScheduledAtChange = useCallback(
    (datetime) => {
      setScheduledAt(datetime)
      if (activeFilterTypes.birthday) {
        updateFilter(
          {
            operand: {
              field_key: BuiltInFields.BIRTHDAY,
            },
          },
          {
            operator: SelectorOperators.EQUALS,
            operand: {
              field_key: BuiltInFields.BIRTHDAY,
              field_label: fieldLabelFor(BuiltInFields.BIRTHDAY),
              source: FieldSources.BUILT_IN,
              type: fieldTypeFor(BuiltInFields.BIRTHDAY),
              value: datetime.format(SUBSCRIPTION_DATA_FILTER_DATE_FORMAT),
            },
          },
          includedFiltersAst,
        )
      }
    },
    [activeFilterTypes, updateFilter, includedFiltersAst],
  )

  const toggleScheduled = useCallback(() => {
    // If they are using the timezone filter, then use that timezone to
    // schedule messages. Otherwise use their local timezone
    const timezone = activeFilterTypes.timezone
      ? communityLocalTimezone?.representativeTzid || localTimezone.representativeTzid
      : localTimezone.representativeTzid

    setScheduledAt((scheduledAt) =>
      scheduledAt
        ? null
        : communityLocalTimezone?.representativeTzid
        ? dayjs().tz(timezone).add(5, 'minutes')
        : dayjs().add(5, 'minutes'),
    )

    // reset birthday filter value whenever schedule UI is closed
    if (activeFilterTypes.birthday && scheduledAt) {
      updateFilter(
        {
          operand: {
            field_key: BuiltInFields.BIRTHDAY,
          },
        },
        {
          operator: SelectorOperators.EQUALS,
          operand: {
            field_key: BuiltInFields.BIRTHDAY,
            field_label: fieldLabelFor(BuiltInFields.BIRTHDAY),
            source: FieldSources.BUILT_IN,
            type: fieldTypeFor(BuiltInFields.BIRTHDAY),
            value: dayjs().format(DATE_FILTER_FORMAT),
          },
        },
        includedFiltersAst,
      )
    }
  }, [communityLocalTimezone, activeFilterTypes, localTimezone, scheduledAt, updateFilter, includedFiltersAst])

  return (
    <ComposeContext.Provider
      value={{
        activeFilterTypes,
        communities,
        file,
        frequency,
        gif,
        handleScheduledAtChange,
        hasDropdownFilter,
        hasMediaLink,
        hasPlaceholders,
        isCountLoading,
        isLoading: isLoading || timezoneIsLoading,
        isMediaAttached,
        maxConsecutiveLineBreaks,
        name,
        recipientCount,
        scheduledAt,
        setFile: handleSetFile,
        setFrequency,
        setGif: handleSetGif,
        setHasPlaceholders,
        setName,
        analyticsOnSentEvent,
        setAnalyticsOnSentEvent,
        setScheduledAt,
        setText,
        shouldShowScheduled: !!scheduledAt,
        text,
        toggleScheduled,
        setComposeMessage,
        composeModalOpen,
        setComposeModalOpen,
        shouldShortenLinks,
        setShouldShortenLinks,
        reset,
      }}
    >
      {children}
    </ComposeContext.Provider>
  )
}

export type UseComposeProps = {
  initialState?: {
    scheduledAt?: Dayjs
    isScheduling?: boolean
    text?: string
  }
}

export function useCompose({ initialState = {} }: UseComposeProps = {}): ComposeContextValue {
  const context = useContext(ComposeContext)
  const composeRole = useComposeRole()

  if (!context) {
    throw new Error('useCompose must be used within a ComposeProvider')
  }

  const { setScheduledAt, toggleScheduled, setText, scheduledAt } = context as ComposeContextValue

  // load initial state
  useEffect(() => {
    if (initialState.scheduledAt) {
      setScheduledAt(initialState.scheduledAt)
    }

    if (composeRole === ComposeRole.CAMPAIGN_DRAFTER && !initialState.scheduledAt && !scheduledAt) {
      setScheduledAt(dayjs().add(5, 'minutes'))
    }

    if (initialState.isScheduling) {
      toggleScheduled()
    }

    if (initialState.text) {
      setText(initialState.text)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return context
}
