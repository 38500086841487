import {
  FieldDefinition,
  FieldSources,
  FieldTypes,
  findOne,
  SelectorFilter,
  serializeFilters,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { Button, BUTTON_VARIANTS, DisclosureLeftIcon } from '@community_dev/pixels'
import { useMemo } from 'react'
import { Form } from 'react-final-form'
import { useTheme } from 'styled-components'
import styled from 'styled-components'

import { useRecipientField } from '../RecipientFieldContext'
import { StyledHeading } from '../styled-recipients'

import { StyledHeader, StyledBackButton, StyledButtonWrapper, StyledFieldEditor, StyledFields } from './FieldPane.style'
import { BooleanField, DateField, PlainTextField, NumberField } from './fields'
import { SelectField } from './fields/Select'

import { FilterSelectionType, useFilters } from 'contexts/FilterProvider/FilterProvider'

const numberFieldTypes = [FieldTypes.INTEGER, FieldTypes.DECIMAL_2, FieldTypes.DECIMAL_4] as FieldTypes[]

const StyledForm = styled.form`
  height: 100%;
`

type FieldPaneProps = {
  field: FieldDefinition
  onBackClick: () => void
  type: FilterSelectionType
}

export const FieldPane = ({ field, onBackClick, type }: FieldPaneProps): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { addFilter, activeSubtree, updateFilter } = useFilters()
  const { setIsOpen } = useRecipientField()

  const selectedFilterNode = useMemo(() => {
    return findOne(activeSubtree, {
      operand: {
        field_key: field.key,
        source: FieldSources.CLIENT,
      },
    })
  }, [activeSubtree, field])

  const selectedFilter = useMemo(() => {
    if (selectedFilterNode) {
      return serializeFilters(selectedFilterNode)
    }
  }, [selectedFilterNode])

  const isSelected = !!selectedFilter
  const initialValues = isSelected ? { [`custom-field-${field.key}`]: selectedFilter } : {}

  function handleSubmit(values: Record<string, SelectorFilter>): void {
    const [, filter] = Object.entries(values)[0]
    setIsOpen(false)

    if (selectedFilter) {
      updateFilter(selectedFilter, filter, activeSubtree, type)
    } else {
      addFilter(filter, type)
    }
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, valid }) => (
        <StyledForm onSubmit={handleSubmit}>
          <StyledFields>
            <div>
              <StyledHeader>
                <StyledBackButton onClick={onBackClick} variant={BUTTON_VARIANTS.LINK}>
                  <DisclosureLeftIcon color={COLORS.BORDERS} size={34} />
                </StyledBackButton>
                <StyledHeading>{field.name}</StyledHeading>
              </StyledHeader>
            </div>
            <StyledFieldEditor>
              {field.value_type === FieldTypes.STRING && !!field.value_options && <SelectField field={field} />}
              {field.value_type === FieldTypes.STRING && !field.value_options && <PlainTextField field={field} />}
              {numberFieldTypes.includes(field.value_type) && <NumberField field={field} />}
              {field.value_type === FieldTypes.DATETIME && <DateField field={field} />}
              {field.value_type === FieldTypes.BOOLEAN && <BooleanField field={field} />}
              <StyledButtonWrapper>
                <Button disabled={!valid} onClick={handleSubmit} type="submit">
                  Apply
                </Button>
              </StyledButtonWrapper>
            </StyledFieldEditor>
          </StyledFields>
        </StyledForm>
      )}
    />
  )
}
