import { Block, SPACING, WarningIcon } from '@community_dev/pixels'
import styled from 'styled-components'

const StyledErrorContent = styled.div`
  text-align: center;
  width: 100%;
  height: 190px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
`

const StyledWarningIcon = styled(WarningIcon)`
  margin: 0 auto;
`

const StyledErrorMessage = styled.p`
  margin-top: ${SPACING[2]};
  margin-bottom: 0;
`

export const ErrorCard = (props: Partial<HTMLDivElement>): JSX.Element => (
  <Block {...props} id="response-rate" title="Response Rate">
    <StyledErrorContent aria-label="Loading error">
      <StyledWarningIcon />
      <StyledErrorMessage>Failed to load data</StyledErrorMessage>
    </StyledErrorContent>
  </Block>
)
