import { ENDPOINTS } from 'constants/endpoints'
import {
  DAY_KEY,
  MOCKED_ACTIVE_SUBSCRIPTIONS,
  MONTH_KEY,
  WEEK_KEY,
} from 'screens/InsightsScreen/InsightsMasthead/constants'
import { createMemberGrowthData } from 'screens/InsightsScreen/InsightsMasthead/TotalMemberGrowthChart/createMemberGrowthData'
import { createNewMemberGrowthData } from 'screens/InsightsScreen/InsightsMasthead/TotalNewMembersChart/createNewMemberGrowthData'
import { request } from 'utils/api'
import { route } from 'utils/router'

type InsightsProps = {
  clientId: string
  startTime?: number
  endTime?: number
}

// startTime and endTime are optional. They accept unix epoch time in
// *seconds* (NOT milliseconds)
export const getAgeAndGenderInsights = ({ clientId, startTime, endTime }: InsightsProps): Promise<any> =>
  request(route(ENDPOINTS.INSIGHTS.AGE_AND_GENDER, { clientId }, { start_time: startTime, end_time: endTime }), {
    camelCase: false,
  })

export const getStateInsights = ({ clientId, startTime, endTime }: InsightsProps): Promise<any> =>
  request(route(ENDPOINTS.INSIGHTS.GEO.STATE, { clientId }, { start_time: startTime, end_time: endTime }), {
    camelCase: false,
  })

export const getMSAInsights = ({ clientId, startTime, endTime }: InsightsProps): Promise<any> =>
  request(route(ENDPOINTS.INSIGHTS.GEO.MSA, { clientId }, { start_time: startTime, end_time: endTime }), {
    camelCase: false,
  })

export const getCountryInsights = ({ clientId, startTime, endTime }: InsightsProps): Promise<any> =>
  request(route(ENDPOINTS.INSIGHTS.GEO.COUNTRY, { clientId }, { start_time: startTime, end_time: endTime }), {
    camelCase: false,
  })

type GetBestHourOfWeekArgs = {
  clientId: string
}

export const getBestHourOfWeek = ({ clientId }: GetBestHourOfWeekArgs): Promise<any> =>
  request(route(ENDPOINTS.INSIGHTS.BEST_HOUR_OF_WEEK, { clientId }))

type GetMemberGrowthOverTimeProps = {
  clientId: string
  timeRange: string
  timezone: string
  mocked?: boolean
}

export type MemberCount = {
  count: number
  timestamp: string
}

const mockedData = {
  [DAY_KEY]: createMemberGrowthData(1, {
    maxMembers: MOCKED_ACTIVE_SUBSCRIPTIONS,
  }),
  [WEEK_KEY]: createMemberGrowthData(7, {
    maxMembers: MOCKED_ACTIVE_SUBSCRIPTIONS,
  }),
  [MONTH_KEY]: createMemberGrowthData(30, {
    maxMembers: MOCKED_ACTIVE_SUBSCRIPTIONS,
  }),
}

export function getMemberGrowthOverTime({
  clientId,
  timeRange,
  timezone,
  mocked = false,
}: GetMemberGrowthOverTimeProps): Promise<{ data: MemberCount[] }> {
  if (mocked) {
    return Promise.resolve(mockedData[timeRange])
  }

  return request(
    route(
      ENDPOINTS.INSIGHTS.GROWTH,
      {
        clientId: clientId,
        timeRange: timeRange,
      },
      {
        timezone: timezone,
      },
    ),
  )
}

const mockedNewMemberData = {
  [DAY_KEY]: createNewMemberGrowthData(1),
  [WEEK_KEY]: createNewMemberGrowthData(7),
  [MONTH_KEY]: createNewMemberGrowthData(31),
}

export function getNewMembersOverTime({
  clientId,
  timeRange,
  timezone,
  mocked = false,
}: GetMemberGrowthOverTimeProps): Promise<{ data: MemberCount[] }> {
  if (mocked) {
    return Promise.resolve(mockedNewMemberData[timeRange])
  }

  return request(
    route(
      ENDPOINTS.INSIGHTS.NEW_MEMBERS,
      {
        clientId: clientId,
        timeRange: timeRange,
      },
      {
        timezone: timezone,
      },
    ),
  )
}

// these delays are different on purpose, to simulate what the backend would
// be responding with.
// export const mockedInsights = {
//   getAgeAndGenderInsights: respondWithDelay(fixtures.ageAndGenderInsights, 2500),
//   getStateInsights: respondWithDelay(fixtures.stateInsights, 1000),
//   getMSAInsights: respondWithDelay(fixtures.msaInsights, 1700),
// }
