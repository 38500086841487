import {
  FieldDefinition,
  FieldOption,
  SelectorFilter,
  SelectorOperators,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { required, Select } from '@community_dev/pixels'
import sortBy from 'lodash/sortBy'
import { Field } from 'react-final-form'

const validateText = (value: SelectorFilter | null): void | string => {
  return required(value?.operand?.value)
}

type SelectFieldProps = {
  field: FieldDefinition
}

export function SelectField({ field }: SelectFieldProps): JSX.Element {
  const options = field.value_options?.map(({ value, status }: FieldOption) => ({ value, label: value, status }))
  const selectOptions = sortBy(options, ['status'])

  return (
    <Field name={`custom-field-${field.key}`} validate={validateText}>
      {({ input }) => (
        <Select
          id={`select-field-${field.key}`}
          isSearchable
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onChange={(option) => {
            input.onChange({
              operator: SelectorOperators.EQUALS,
              operand: {
                field_key: field.key,
                field_label: field.name,
                source: field.source,
                type: field.value_type,
                value: option?.value,
              },
            })
          }}
          options={selectOptions}
          value={selectOptions?.find(({ value }) => value === input.value?.operand?.value)}
          width="100%"
        />
      )}
    </Field>
  )
}
