import {
  BUTTON_VARIANTS,
  Button,
  CommunitiesIcon,
  MessagesIcon,
  ModalGuidedTour,
  QuestionMark,
  SPACING,
} from '@community_dev/pixels'
import isNumber from 'lodash/isNumber'
import noop from 'lodash/noop'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { MessagesLimits } from 'constants/messages'
import { ROUTES } from 'constants/routes'
import analytics from 'utils/analytics'
import { formatNumeral } from 'utils/general'

const BlockStyled = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 12px;
  margin-bottom: ${SPACING[5]};
`

const TitleStyled = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
`

const LinkStyled = styled(Link)`
  display: block;
  margin-top: ${SPACING[4]};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  text-decoration: none;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
`

const FooterStyled = styled.div`
  text-align: center;
`

const Content = styled.div`
  padding: 0 40px ${SPACING[2]};
`

const ContentTitle = styled.h1`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  text-align: center;
  margin-bottom: ${SPACING[5]};
`

const IconStyled = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme?.COLORS?.DEPRECATED_SELECTED};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
`

const VerticalAlignStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const EmailLinkStyled = styled.a`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  text-decoration: none;
`

const StyledQuestion = () => {
  const { COLORS } = useTheme() || {}
  return <QuestionMark innerFill={COLORS?.TEXT} outerFill="transparent" size={35} />
}
const icons = {
  messages: <MessagesIcon size={35} />,
  communities: <CommunitiesIcon size={35} />,
  question: <StyledQuestion />,
}

const Icon = ({ type }) => {
  const IconSVG = icons[type]

  return (
    <IconStyled>
      <VerticalAlignStyled>{IconSVG}</VerticalAlignStyled>
    </IconStyled>
  )
}

const ContentBlock = ({ title, icon, children }) => {
  return (
    <BlockStyled>
      <div>
        <Icon type={icon} />
      </div>
      <div>
        <TitleStyled>{title}</TitleStyled>
        {children}
      </div>
    </BlockStyled>
  )
}

const EmailLink = () => {
  const email = 'yourfriends@community.com'
  const subject = 'Questions about my Monthly Message Limit'

  return <EmailLinkStyled href={`mailto:${email}?subject=${subject}`}>{email}</EmailLinkStyled>
}

type MessagesLimitsModalProps = {
  hasLimitReached?: boolean
  monthlyRemaining: number | null
  monthlyThreshold: number
  visible?: boolean
  onClose?: () => void
}

export const MessagesLimitsModal = ({
  hasLimitReached,
  monthlyRemaining,
  monthlyThreshold,
  visible,
  onClose = noop,
}: MessagesLimitsModalProps): JSX.Element => {
  const remainingMessagesText = isNumber(monthlyRemaining) && formatNumeral(monthlyRemaining)

  const monthlyThresholdText = formatNumeral(monthlyThreshold)

  const headingCount = hasLimitReached ? MessagesLimits.THROTTLED_LIMIT_TEXT : remainingMessagesText

  const heading = `Message up to ${headingCount} recipients`

  const handleGotIt = () => {
    analytics.track(analytics.events.MessageLimitsPrimaryCTAClicked())
    onClose()
  }

  const handleLearnMore = () => {
    analytics.track(analytics.events.MessageLimitsSecondaryCTAClicked())
  }

  return (
    <ModalGuidedTour height="563px" isOpen={visible} onClose={onClose}>
      <ModalGuidedTour.Slide
        footer={() => {
          return (
            <FooterStyled>
              <Button onClick={handleGotIt} variant={BUTTON_VARIANTS.ACTION}>
                Got it
              </Button>
              <LinkStyled onClick={handleLearnMore} to={ROUTES.SETTINGS.BILLING}>
                Learn more
              </LinkStyled>
            </FooterStyled>
          )
        }}
        heading={''}
      >
        <Content>
          <ContentTitle>{heading}</ContentTitle>
          <ContentBlock icon="messages" title="How many messages do I have left?">
            You have <strong>{remainingMessagesText}</strong> messages left of this month's message limit of{' '}
            {monthlyThresholdText}.
          </ContentBlock>
          <ContentBlock icon="communities" title="How can I send this message?">
            Please update the recipient count on this message to under <strong>{headingCount} recipients</strong>.
          </ContentBlock>
          <ContentBlock icon="question" title="How do I increase my limit?">
            Contact your partnership manager or email us at <EmailLink />
          </ContentBlock>
        </Content>
      </ModalGuidedTour.Slide>
    </ModalGuidedTour>
  )
}
