import { BOX_CONTENT_VARIANTS, Box, Carousel } from '@community_dev/pixels'
import React from 'react'
import styled from 'styled-components'

import { Tips } from 'screens/Onboarding/components/Tips/Tips'
import { StyledBox } from 'screens/Onboarding/styled'

const StyledCarouselContainer = styled.div`
  padding: 0 0 16px;
`

const StyledSlide = styled.div`
  display: flex;
  justify-content: center;
`

type ExamplesProps = {
  arrowVerticalOffset?: number
  examples: any // TODO: T.shape()
  tips: string[]
}

export function Examples({ arrowVerticalOffset, tips, examples }: ExamplesProps): JSX.Element {
  const handleChange = () => {
    const elements = document.querySelectorAll('audio')
    Array.from(elements).forEach((el) => el.pause())
  }

  return (
    <StyledBox>
      <Box>
        <Box.Content variant={BOX_CONTENT_VARIANTS.THIN}>
          <div>
            <h2>Tips &amp; Examples</h2>
            <Tips items={tips} />
            <StyledCarouselContainer>
              <Carousel
                arrowVerticalOffset={arrowVerticalOffset}
                arrows={Object.keys(examples).length > 1}
                beforeChange={handleChange}
              >
                {Object.keys(examples).map((key) => (
                  <Carousel.Slide key={key}>
                    <StyledSlide>{examples[key]}</StyledSlide>
                  </Carousel.Slide>
                ))}
              </Carousel>
            </StyledCarouselContainer>
          </div>
        </Box.Content>
      </Box>
    </StyledBox>
  )
}
