import * as qr from 'qrcode'
import { QRCodeToDataURLOptions } from 'qrcode'
import React, { useCallback, useEffect, useRef } from 'react'

type QRCodeProps = {
  id?: string
  text: string
  // css dimensions for canvas element
  width?: number
  height?: number
  // size to render canvas contents
  size: number
  options?: QRCodeToDataURLOptions
  style?: React.CSSProperties
  className?: string
}

const loadImage = (url: string): Promise<HTMLImageElement> =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = document.createElement('img')

    img.onload = () => resolve(img)
    img.onerror = (e) => reject(e)
    img.src = url
  })

const QRCode = (props: QRCodeProps): JSX.Element => {
  const { id, text, options, style, className, width, height, size } = props

  const dpi = window.devicePixelRatio || 1

  const canvas = useRef<HTMLCanvasElement>(null)

  const drawQRCodeDataURL = useCallback(
    (url: string): Promise<void> => {
      return loadImage(url).then((image) => {
        if (canvas.current) {
          const context = canvas.current.getContext('2d')

          if (context) {
            context.drawImage(image, 0, 0, size, size)
          }
        }
      })
    },
    [size],
  )

  useEffect(() => {
    if (!canvas?.current) {
      return
    }

    const context = canvas.current.getContext('2d')

    if (context) {
      context.scale(dpi, dpi)
    }
  }, [canvas, dpi])

  useEffect(() => {
    if (!canvas?.current) {
      return
    }

    qr.toDataURL(text, {
      width: size,
      margin: 2,
      color: {
        dark: '#000000',
        light: '#ffffff',
      },
      errorCorrectionLevel: 'quartile',
      ...options,
    }).then((url) => drawQRCodeDataURL(url))
  }, [drawQRCodeDataURL, options, size, text, dpi])

  return (
    <canvas
      className={className}
      height={size * dpi}
      id={id}
      ref={canvas}
      style={{ ...style, width: width || size, height: height || size }}
      width={size * dpi}
    />
  )
}

QRCode.defaultProps = {
  text: '',
  size: 200,
  options: {},
  style: {},
}

export default QRCode
