import {
  BUTTON_VARIANTS,
  Button,
  CheckIcon2,
  Info,
  InfoIcon,
  SPACING,
  SalesforceLogo,
  WarningIcon,
} from '@community_dev/pixels'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import 'styled-components'
import { BetaBadge } from 'components/BetaBadge'
import { useClientId } from 'hooks/useClient'
import { useSalesforceInstances } from 'hooks/useSalesforce'
import { Setting } from 'layouts/SettingsLayout'

export function Salesforce(): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const clientId = useClientId()
  const { data: salesForceInstances = [] } = useSalesforceInstances(clientId)

  return (
    <>
      <Setting
        action={t('settings.integrations.instructions')}
        icon={<SalesforceLogo size={24} />}
        label={
          <>
            Salesforce <BetaBadge />
          </>
        }
        onClickAction={() => {
          window.open('https://incommunity.zendesk.com/hc/en-us/articles/4405062400403-Sync-Members-to-Salesforce')
        }}
        text={salesForceInstances.map((salesForceInstance) => (
          <div
            css={`
              ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
            `}
            key={`${salesForceInstance.instanceUrl}${salesForceInstance.instanceType}`}
          >
            {salesForceInstance.instanceUrl}
            {' ('}
            {t(`settings.integrations.salesforce.instanceType.${salesForceInstance.instanceType.toLowerCase()}`) ||
              salesForceInstance.instanceType}
            {') '}
            {salesForceInstance.deletedAt ? (
              <WarningIcon
                css={`
                  path {
                    fill: ${({ theme }) => theme?.COLORS?.ERRORS};
                  }
                `}
                size={18}
              />
            ) : (
              <CheckIcon2 color={COLORS?.SUCCESS} size={18} />
            )}{' '}
            <span
              css={`
                ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
                font-weight: 600;
                color: ${darken(0.24, salesForceInstance.deletedAt ? COLORS?.ERRORS : COLORS?.SUCCESS)};
              `}
            >
              {t(
                `settings.integrations.salesforce.instanceType.${
                  salesForceInstance.deletedAt ? 'disconnected' : 'connected'
                }`,
              )}
            </span>
          </div>
        ))}
      />

      {salesForceInstances?.some((instance) => Boolean(instance.deletedAt)) && (
        <Info icon={<InfoIcon />}>
          <div
            css={`
              ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
              font-weight: 600;
            `}
          >
            {t('settings.integrations.salesforce.disconnectedDetails.title')}
          </div>
          <p
            css={`
              ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
              color: ${({ theme }) => theme?.COLORS?.TEXT};
              margin: ${SPACING[1]} 0;
            `}
          >
            {t('settings.integrations.salesforce.disconnectedDetails.description')}
          </p>
          <div>
            <Button
              color={COLORS?.LINKS}
              forwardedAs="a"
              href="https://incommunity.zendesk.com/hc/en-us/articles/4405062400403-Sync-Members-to-Salesforce"
              target="_blank"
              variant={BUTTON_VARIANTS.LINK}
            >
              {t('settings.integrations.salesforce.disconnectedDetails.link')}
            </Button>
          </div>
        </Info>
      )}
    </>
  )
}
