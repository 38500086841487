import { Box, Toast, Typography } from '@community_dev/pixels'
import * as filestack from 'filestack-js'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Description } from '../components/Description'
import { ONBOARDING_TASKS, ONBOARDING_TASK_COMPLETE } from '../constants'
import { useCompleteClientTask, useUpdateContactPhoto } from '../queries'

import { Preview } from './components/Preview'

import { useClient } from 'hooks/useClient'
import Sentry from 'integrations/Sentry'
import texts from 'texts.json'
import { baseConfig } from 'utils/config'
import { getFileFromUrl } from 'utils/file'

export function ContactPhoto(): JSX.Element {
  const pickerRef = useRef<filestack.PickerInstance>()
  const { data: client } = useClient()
  const task = client?.onboardingTasks?.tasks[ONBOARDING_TASKS.CONTACT_PHOTO]
  const profileImage = client?.profileImage
  const isTaskComplete = task?.status === ONBOARDING_TASK_COMPLETE && Boolean(profileImage?.url)
  const [error, setError] = useState<string>()
  const [isUploading, setIsUploading] = useState(false)
  const { t } = useTranslation()
  const { mutate: updateTask } = useCompleteClientTask({
    onError(e) {
      setIsUploading(false)
      setError(texts.errors.generic)
      Sentry.captureException(e)
    },
    onSuccess() {
      setIsUploading(false)
    },
  })
  const { mutate: uploadPhoto, isSuccess } = useUpdateContactPhoto({
    onMutate() {
      setError(undefined)
    },
    onError(e) {
      setIsUploading(false)
      setError(texts.errors.generic)
      Sentry.captureException(e)
    },
    onSuccess() {
      updateTask({ id: task!.id })
    },
  })

  useEffect(() => {
    const instance = filestack.init(baseConfig.filestackApiKey)
    const options: filestack.PickerOptions = {
      accept: ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/gif'],
      fromSources: ['local_file_system'],
      maxFiles: 1,
      maxSize: 50 * 1024 * 1024,
      transformations: {
        crop: {
          aspectRatio: 1,
          force: true,
        },
      },
      uploadInBackground: false,
      onCancel: () => setIsUploading(false),
      onFileUploadFailed: (fileMetadata, e) => {
        setIsUploading(false)
        Sentry.captureException(e)
      },
      onFileUploadFinished: async (fileMetadata) => {
        try {
          if (!fileMetadata) return
          const image = await getFileFromUrl(fileMetadata.url, fileMetadata.filename, {
            type: fileMetadata.mimetype,
          })
          const body = new FormData()
          body.append('profile_image', image)
          await uploadPhoto({ body })
        } catch (e: any) {
          setError(e.message)
          Sentry.captureException(e)
        }
      },
      onOpen: () => setError(undefined),
      onUploadStarted: () => setIsUploading(true),
    }

    pickerRef.current = instance.picker(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      {error && (
        <Box.ToastArea>
          <Toast icon message={error} success={false} />
        </Box.ToastArea>
      )}
      <Box.Content>
        {isTaskComplete ? (
          <Preview
            image={profileImage!.url}
            isSuccess={isSuccess}
            isUploading={isUploading}
            onEdit={() => pickerRef.current?.open()}
          />
        ) : (
          <Description
            actionLabel={isUploading ? 'Uploading' : 'Upload Photo'}
            alt={t('onboarding.contactPhoto.gifAltText')}
            animation="animation-contact-photo.gif"
            disabled={isUploading}
            onAction={() => pickerRef.current?.open()}
          >
            <Typography variant="h1">{t('onboarding.contactPhoto.title')}</Typography>
            <p>{t('onboarding.contactPhoto.initialBody')}</p>
          </Description>
        )}
      </Box.Content>
    </Box>
  )
}
