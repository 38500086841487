// Global
export const MIN_WIDTH_DESKTOP = '1080px'

export const MOBILE_BREAK = '472px'
export const TABLET_BREAK = '720px'
export const DESKTOP_BREAK = '1080px'
export const DESKTOP_LARGE_WITH_SIDEBAR_BREAK = '1136px'
export const DESKTOP_XL_WITH_SIDEBAR_BREAK = '1440px'

// Floating Container
export const FLOATING_CONTAINER_WIDTH = '876px'
export const FLOATING_CONTAINER_LEFT_SPACING = '160px'

// Navbar
export const NAVBAR_HEIGHT = '44px'

// Sidebar
export const SIDEBAR_WIDTH = '224px'
export const SIDEBAR_COLLAPSED_WIDTH = '56px'
export const SIDEBAR_MOBILE_WIDTH = '260px'
export const SIDEBAR_TRANSITION_SPEED = '.3s'
export const SIDEBAR_EASING = 'ease'

// Pane Width
export const PANE_WIDTH = '336px'

export const MOBILE_HEADER_HEIGHT = '48px'

// TCR
export const TCR_SEGMENTS_WIDTH = 410
