import { LightBulbIcon } from '@community_dev/pixels'
import { useState } from 'react'

import { ProductEducationCard } from '../components/ProductEducationCard'

import { GrowYourCommunityModal } from './GrowYourCommunityModal'

import { useInitialAction } from 'hooks/useInitialAction'

export function GrowYourCommunityCard(): JSX.Element {
  const isInitiallyOpen = useInitialAction('how-others-launched')
  const [isOpen, setIsOpen] = useState(isInitiallyOpen)

  return (
    <>
      <ProductEducationCard
        Icon={LightBulbIcon}
        body="Connect with your audience in your unique voice"
        header="Ideas for getting started"
        onClick={() => setIsOpen(true)}
        subHeader="See how others have launched"
      />
      {isOpen ? (
        <GrowYourCommunityModal
          initialSlide={isInitiallyOpen ? 1 : 0}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
    </>
  )
}
