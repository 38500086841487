import { BUTTON_VARIANTS, Block, Button, ChevronRightIcon, CloseIcon, Modal, SPACING } from '@community_dev/pixels'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { putUnsubscribeFan } from 'api/fans'
import analytics from 'utils/analytics'

type UnsubscribeFanProps = {
  fanId: string
  fanName: string
}

const StyledHeading = styled.h4`
  color: ${({ theme }) => theme?.COLORS?.ERRORS};
`

const StyledHeader = styled.div`
  display: flex;
  margin-top: 0;
  align-items: center;
  padding: 0 ${SPACING[4]} ${SPACING[4]} 0;
  justify-content: space-between;

  ${StyledHeading} {
    margin: 0;
  }
`

const StyledBody = styled.div`
  padding: ${SPACING[4]} ${SPACING[4]} ${SPACING[4]} 0;
  border-top: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  text-align: left;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION1};
`

const StyledContainer = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
`

const StyledModalBody = styled.div`
  text-align: center;
  padding: ${SPACING[5]};
`

const StyledModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export function UnsubscribeFan({ fanId, fanName }: UnsubscribeFanProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { mutate } = useMutation(putUnsubscribeFan, {
    onError: () => {
      toast.error(t('toasts.unsubscribeError', { name: fanName || 'Member' }))
    },
    onSuccess: () => {
      toast.success(t('toasts.unsubscribeSuccess', { name: fanName || 'Member' }))
      analytics.track(analytics.events.UnsubscribeFan(fanId))
    },
  })

  function handleUnsubscribe() {
    mutate(fanId)
    setIsModalOpen(false)
  }

  function handleClose() {
    setIsModalOpen(false)
  }

  return (
    <>
      <StyledContainer onClick={() => setIsModalOpen(true)}>
        <Block flush id="unsubscribeFan">
          <StyledHeader>
            <StyledHeading>{t('memberActions.unsubscribe')}</StyledHeading>
            <ChevronRightIcon color={COLORS?.DIVIDERS} />
          </StyledHeader>
          <StyledBody>{t('memberActions.unsubscribePrompt')}</StyledBody>
        </Block>
      </StyledContainer>
      {isModalOpen && (
        <Modal maxWidth={420} minHeight="initial" onClose={handleClose} open={isModalOpen}>
          <Modal.Header>
            <Modal.Header.Center>
              <span>{`Unsubscribe ${fanName || 'Member'}?`}</span>
            </Modal.Header.Center>
            <Modal.Header.Right onClose={handleClose}>
              <CloseIcon color={COLORS?.SUBTEXT} size={12} />
            </Modal.Header.Right>
          </Modal.Header>
          <Modal.Body>
            <StyledModalBody>
              <span>{t('memberActions.unsubscribePrompt')}</span>
            </StyledModalBody>
          </Modal.Body>
          <Modal.Footer>
            <StyledModalFooter>
              <Button onClick={handleClose} variant={BUTTON_VARIANTS.OUTLINE}>
                {t('memberActions.cancel')}
              </Button>
              <Button color={COLORS?.ERRORS} hoverColor={COLORS?.ERRORS_HOVER} onClick={handleUnsubscribe}>
                {t('memberActions.unsubscribe')}
              </Button>
            </StyledModalFooter>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
