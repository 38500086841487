import { Api } from '@community_dev/types'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { useAuth } from './useAuth'

import { Client, getClient } from 'api/client'
import { getContexts } from 'api/contexts'
import { Context } from 'api/contexts'
import { QUERY_CACHE } from 'constants/query-cache'
import { AuthStates } from 'contexts/AuthProvider'
import { useSeat } from 'contexts/bootstrap/useSeat'
import analytics from 'utils/analytics'
import { removeSessionCache } from 'utils/cache'
import { formatFullName } from 'utils/formatters'

export function useClientContexts(queryOptions?: UseQueryOptions<Context[]>): UseQueryResult<Context[]> {
  const { token, state, clientId, setClientId } = useAuth()
  const result = useQuery({
    queryKey: [QUERY_CACHE.CONTEXTS],
    queryFn: () => getContexts(),
    enabled: state !== AuthStates.UNAUTHENTICATED && !!token,
    ...queryOptions,
  })

  useEffect(() => {
    if (result?.isSuccess) {
      const activeContext = result.data.find((context) => context.currentContext)
      if (activeContext && !clientId) {
        setClientId(activeContext.clientId)
      }
    }
  }, [result?.isSuccess, clientId, setClientId, result.data])

  return result
}

export function useSelectedClientContext(queryOptions?: UseQueryOptions<Context[]>): Context | undefined {
  const { clientId } = useAuth()
  const { data: clientContexts } = useClientContexts(queryOptions)
  return clientContexts?.find((context) => context.clientId === clientId)
}

export function useClient(queryOptions?: UseQueryOptions<Client>): UseQueryResult<Client> {
  const { token, state, clientId } = useAuth()
  return useQuery({
    queryKey: [QUERY_CACHE.CLIENT, clientId],
    queryFn: () => getClient(),
    enabled: state !== AuthStates.UNAUTHENTICATED && !!token,
    ...queryOptions,
  })
}

export const useClientId = (): string => {
  const { clientId } = useAuth()
  return clientId || ''
}

// initial client selection. Does not perform reload.
export const useSelectClient = (): ((clientId: string) => void) => {
  const seat = useSeat()
  const { setClientId, clientId, state } = useAuth()
  return useCallback(
    async (value: string) => {
      if (state === AuthStates.CONTEXT_SELECTION || value !== clientId) {
        if (seat.data) {
          const name = formatFullName(seat?.data)
          const { id, email } = seat?.data as CamelCasedPropertiesDeep<Api.V1.Seat>
          analytics.track(analytics.events.ContextExited(id, name, email))
        }
        await setClientId(value)
      }
    },
    [clientId, seat.data, setClientId, state],
  )
}

// switch/change the current client selection.
export const useSwitchClient = (): ((clientId: string) => void) => {
  const { clientId } = useAuth()
  const selectClient = useSelectClient()
  return useCallback(
    async (value: string) => {
      await selectClient(value)
      if (clientId && value !== clientId) {
        removeSessionCache()
      }
    },
    [selectClient, clientId],
  )
}
