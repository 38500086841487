import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { FanStateFlag } from '@community_dev/types/lib/api/Fan'

import { useClient } from './useClient'

import dayjs from 'utils/dayjs'

type CanSendMessageParams = {
  communicationChannel?: CommunicationChannel
  conversationWindowEndAt?: string | null
  stateFlags?: FanStateFlag[]
}

export const canReceiveDms = ({
  communicationChannel,
  conversationWindowEndAt,
  stateFlags = [],
}: CanSendMessageParams): boolean => {
  const isAmbConversation = communicationChannel === CommunicationChannel.AMB

  if (isAmbConversation) {
    if (!stateFlags?.includes(FanStateFlag.CAN_RECEIVE_DMS)) return false
  }

  if (conversationWindowEndAt) {
    return dayjs().isBefore(dayjs(conversationWindowEndAt))
  }

  return true
}

export const canReceiveCampaigns = ({ communicationChannel, stateFlags = [] }: CanSendMessageParams): boolean => {
  const isAmbConversation = communicationChannel === CommunicationChannel.AMB

  if (isAmbConversation) {
    if (!stateFlags?.includes(FanStateFlag.CAN_RECEIVE_CAMPAIGNS)) return false
  }

  return true
}

// This hook is used to determine if the client can send a message to a fan
export function useCanSendDms({
  communicationChannel,
  conversationWindowEndAt,
  stateFlags = [],
}: CanSendMessageParams): boolean {
  const { data: client } = useClient()

  const clientCommunicationChannels = client?.communicationChannels
  const isCommunicationChannelEmpty = !clientCommunicationChannels?.length
  const clientHasCommunicationChannel =
    communicationChannel && clientCommunicationChannels?.includes(communicationChannel)

  // if the client communication channels is empty we cant send a reply
  if (isCommunicationChannelEmpty) return false

  // communication channel is not in the client communication channels we cant reply
  if (!clientHasCommunicationChannel) return false

  // We now check if the fan can receive a reply
  return canReceiveDms({ communicationChannel, conversationWindowEndAt, stateFlags })
}
