import {
  DropdownArrowIcon,
  BUTTON_VARIANTS,
  Button,
  CheckIcon2,
  Ellipsis,
  Popover,
  SPACING,
} from '@community_dev/pixels'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Context } from 'api/contexts'
import ClientAvatar from 'components/ClientAvatar'
import { ROUTES } from 'constants/routes'
import { useSeat } from 'contexts/bootstrap/useSeat'
import { useSidebar } from 'contexts/SidebarProvider'
import { useAuth } from 'hooks/useAuth'
import { useClient, useClientContexts, useSwitchClient } from 'hooks/useClient'
import { CONTACT_US_EMAIL } from 'screens/ContactUsScreen'
import { formatPhone } from 'utils/normalize'

type StyledContextProps = {
  $multiple?: boolean
}

const StyledContext = styled.div<StyledContextProps>`
  display: flex;
  align-items: center;

  ${({ $multiple }) =>
    $multiple &&
    `
      display: flex;
      align-items: center;
      cursor: pointer;
    `}
`

const StyledDropdownButton = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`

const StyledDropdown = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledAvatar = styled.div`
  padding-right: ${SPACING[4]};
`

const StyledFooter = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding: ${SPACING[5]} ${SPACING[4]};
`

const StyledEmail = styled.span`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  line-height: 32px;
`

type StyledAccountProps = {
  $disabled: boolean
  $selected: boolean
}

const StyledAccount = styled.div<StyledAccountProps>`
  cursor: pointer;
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  padding-left: ${SPACING[4]};
  padding-right: 0;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  transition: background 0.1s ease-in;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  height: 64px;

  &:hover,
  &:active {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_2};
  }

  ${({ $disabled }) =>
    $disabled
      ? `
  background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  color: ${({ theme }) => theme?.COLORS?.BORDERS};

  &:hover {
    background: ${({ theme }) => theme?.COLORS?.APP_BACKGROUND_LEVEL_3};
  }
  `
      : ''}
  ${({ $selected }) =>
    $selected
      ? `
  background: ${({ theme }) => theme?.COLORS?.DEPRECATED_SELECTED};
  pointer-events: none;
  `
      : ''}
`

type StyledAccountContentProps = {
  $bottomBorder: boolean
}

const StyledAccountContent = styled.div<StyledAccountContentProps>`
  padding-right: ${SPACING[4]};
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  ${({ $bottomBorder, theme }) => ($bottomBorder ? `border-bottom: 1px solid ${theme?.COLORS?.BORDERS}` : '')}
`

const StyledName = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
`

const StyledNumber = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
`

const StyledHeader = styled.h1`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  margin: 0;
  padding: ${SPACING[4]} ${SPACING[5]};
`

const StyledContactUsLink = styled(Button).attrs({
  variant: BUTTON_VARIANTS.LINK,
  as: 'a',
})`
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  font-weight: 900;
  text-decoration: none;
  margin-left: 3px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.08px;
`

type SwitchAccountsDropdownProps = {
  size?: number
  allowSingleContext?: boolean
  children?: React.ReactNode
  popoverProps?: Partial<React.ComponentProps<typeof Popover>>
}

const SwitchAccountsDropdown = ({
  allowSingleContext = false,
  children,
  size = 36,
  popoverProps = {},
}: SwitchAccountsDropdownProps): JSX.Element => {
  const history = useHistory()

  const { collapsed } = useSidebar()
  const [isOpen, setOpen] = useState(false)
  const toggleRef = useRef<HTMLDivElement>(null)

  const { clientId: currentContext } = useAuth()
  const switchClientContext = useSwitchClient()
  const { data: contexts = [] } = useClientContexts()
  const { data: client } = useClient()
  const { data: { email } = {} } = useSeat()

  const { logout: resetAuth } = useAuth()

  const multipleContexts = contexts?.length > 1

  const toggleDropdown = (): void => {
    if (!allowSingleContext && contexts.length <= 1) {
      return
    }

    setOpen((isOpen) => !isOpen)
  }

  const handleSwitchAccount = (context: Context): void => {
    toggleDropdown()
    switchClientContext(context.clientId)
    history.replace(ROUTES.DASHBOARD)
  }

  return (
    <>
      <div aria-label="Switch account" onClick={toggleDropdown} ref={toggleRef}>
        {children || (
          <StyledContext $multiple={multipleContexts}>
            <ClientAvatar graphicUrl={client?.profileImage?.url} size={size} />
            {multipleContexts && !collapsed && (
              <StyledDropdownButton>
                <DropdownArrowIcon />
              </StyledDropdownButton>
            )}
          </StyledContext>
        )}
      </div>
      <Popover
        container="switch-account-dropdown"
        isOpen={isOpen}
        maxHeight="calc(100% - 80px)"
        onClose={() => setOpen(false)}
        showBorder={true}
        targetRef={toggleRef}
        {...popoverProps}
      >
        <AccountDropdown
          accounts={contexts}
          resetAuth={resetAuth}
          seatEmail={email}
          selectedId={currentContext}
          setUserContext={handleSwitchAccount}
        />
      </Popover>
    </>
  )
}

const AccountDropdown = ({ accounts, selectedId, seatEmail, resetAuth, setUserContext }) => (
  <StyledDropdown>
    {accounts.length > 1 && <StyledHeader>Switch account</StyledHeader>}
    {accounts.map((account, i) => (
      <Account
        {...account}
        bottomBorder={i + 1 !== accounts.length}
        currentContext={account.clientId === selectedId}
        key={account.clientId}
        onClick={() => setUserContext(account)}
      />
    ))}
    <StyledFooter>
      <StyledEmail title={seatEmail}>{seatEmail}</StyledEmail>
      <Button onClick={resetAuth} variant={BUTTON_VARIANTS.OUTLINE}>
        Logout
      </Button>
    </StyledFooter>
  </StyledDropdown>
)

const Account = ({ isBillingActive, clientImage, name, phoneNumber, currentContext, bottomBorder, onClick }) => {
  const { COLORS } = useTheme() || {}
  const { t } = useTranslation()
  return (
    <StyledAccount
      $disabled={!isBillingActive}
      $selected={currentContext}
      aria-labelledby={`account-${name}`}
      onClick={onClick}
    >
      <StyledAvatar>
        <ClientAvatar graphicUrl={clientImage} size={48} />
      </StyledAvatar>
      <StyledAccountContent $bottomBorder={bottomBorder}>
        <div>
          <StyledName id={`account-${name}`}>
            <Ellipsis>{name}</Ellipsis>
          </StyledName>
          <StyledNumber>
            {isBillingActive ? (
              formatPhone(phoneNumber)
            ) : (
              <>
                Inactive
                <StyledContactUsLink href={CONTACT_US_EMAIL}> {t('contactUs.contactUsButton')}</StyledContactUsLink>
              </>
            )}
          </StyledNumber>
        </div>
        {currentContext && <CheckIcon2 color={COLORS?.LINKS} style={{ marginLeft: '4px' }} />}
      </StyledAccountContent>
    </StyledAccount>
  )
}

export default SwitchAccountsDropdown
