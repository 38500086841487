import { Filter, Menu, MenuWrapper, SPACING, Ellipsis } from '@community_dev/pixels'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { TABLET_BREAK } from 'constants/theme'

export const ResponsiveWrapper = styled.div`
  width: 100%;
  height: 100vh;
  margin-right: auto;
  position: relative;
  overflow-x: hidden;
`

export const StyledHeader = styled.div`
  background: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_3};
  padding: ${SPACING[5]} ${SPACING[5]} 0px;
  width: 100%;
`

export const StyledFilter = styled(Filter)`
  height: 32px;
  margin-right: 16px;

  &:last-of-type {
    margin-right: 0;
  }
`

export const TableHorizontalScrollWrapper = styled.div`
  overflow-x: auto;
  overflow-y: visible;
`

export const StyledTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  position: relative;
  width: 100%;
  @media (min-width: ${TABLET_BREAK}) {
    table-layout: fixed;
  }
  /* the last menu in the table should appear above the row */
  tr:last-child [role='menu'] {
    transform: translateY(-100%);
    top: -100%;
  }
`

export const StyledEllipsis = styled(Ellipsis)`
  @media (max-width: ${TABLET_BREAK}) {
    max-width: 350px;
  }
`

export const StyledTHead = styled.thead`
  tr {
    border-top: 1px solid rgba(162, 162, 162, 0.1);
    top: 0;
    height: 52px;
    background: ${({ theme }) => theme.COLORS.APP_BACKGROUND_LEVEL_3};
    box-shadow: 0px 4px 5px rgba(162, 162, 162, 0.1);
    z-index: 1;
  }

  th {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    padding-right: 32px;
    letter-spacing: -0.096px;
    text-align: left;

    &:first-of-type {
      padding-left: ${SPACING[5]};
    }

    &:last-of-type {
      padding-right: ${SPACING[5]};
    }
  }
`

export const StyledTBody = styled.tbody`
  tr {
  }

  td {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    padding-right: 32px;
    letter-spacing: -0.176px;
    height: 90px;
    border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};

    &:first-of-type {
      padding-left: ${SPACING[5]};
    }

    &:last-of-type {
      padding-right: ${SPACING[5]};
    }
  }
`

export const StyledWorkflowLink = styled(Link)`
  color: ${({ theme }) => theme.COLORS.TEXT};
  line-height: 24px;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.COLORS.LINKS};
  }
`

export const StyledWorkflowRowMenu = styled(Menu)`
  ${MenuWrapper} {
    margin-top: 20px;
  }
`
