import { FONT_WEIGHT, SPACING } from '@community_dev/pixels'
import styled from 'styled-components'

import { Setting } from 'components/Settings'
import { TCR_SEGMENTS_WIDTH } from 'constants/theme'

export const StyledSettings = styled(Setting)`
  border: none;
`

export const StyledRegistrationSection = styled.div<{ $showTopMargin: boolean }>`
  margin-top: ${({ $showTopMargin }) => ($showTopMargin ? SPACING[8] : '0')};
  margin-bottom: ${SPACING[5]};
`

export const StyledSegmentSection = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${TCR_SEGMENTS_WIDTH}px;
`

export const StyledLink = styled.a`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  color: ${({ theme }) => theme?.COLORS?.LINKS};
  display: block;
  font-weight: ${FONT_WEIGHT[4]};
  margin-top: ${SPACING[1]};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

export const ContentWrapper = styled.div`
  flex-direction: column;
`

export const Subtitle = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: 600;
`

export const Description = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: normal;
  margin-top: ${SPACING[1]};
`
