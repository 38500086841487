import { WorkflowCardConfig } from '@community_dev/flexible-canvas'
import { Layout, Typography } from '@community_dev/pixels'

import { HeaderWrapper, HeaderIconWrapper } from './WorkflowSidebarHeader.style'

export type WorkflowSidebarHeaderProps = {
  cta?: React.ReactNode
  workflowAction: WorkflowCardConfig
}

export function WorkflowSidebarHeader({ cta, workflowAction }: WorkflowSidebarHeaderProps): JSX.Element {
  const { icon: WorkflowActionIcon, title, subtitle, iconColor, backgroundColor, borderColor } = workflowAction

  return (
    <Layout>
      <HeaderWrapper>
        <Layout alignItems="center" display="flex">
          <HeaderIconWrapper backgroundColor={backgroundColor} borderColor={borderColor} iconColor={iconColor}>
            <WorkflowActionIcon />
          </HeaderIconWrapper>
          <Typography component="div" fontSize="18px" fontWeight="bold" variant="body1">
            {title}
          </Typography>
        </Layout>
        {cta}
      </HeaderWrapper>
      <Typography component="div" fontSize="12px" marginTop="6px" variant="body2">
        {subtitle}
      </Typography>
    </Layout>
  )
}
